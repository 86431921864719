import React from 'react'
import { useNavigate } from 'react-router-dom';
import {Box, Button,Text,IconButton } from '@primer/react'
import SpaceTheme from './SpaceTheme';
import { ChevronRightIcon, PlusIcon } from '@radix-ui/react-icons';
import { useDispatch,useSelector } from 'react-redux';
import {rightSidebarOpenClose} from "../redux/features/headerSlice"


const googleClandarIcon = "https://cdn.cdnlogo.com/logos/g/96/google-calendar.svg"
const googleKeepIcon = "https://cdn.cdnlogo.com/logos/g/35/google-keep-icon.svg"
const googleDriveIcon = "https://cdn.cdnlogo.com/logos/g/44/google-drive.svg"
 
function SpaceRightSideNav(){

    const dispatch = useDispatch()
    const headerData = useSelector((state)=> state.headerData )
     

    const hideRightSideBar = (props) => {

         dispatch(rightSidebarOpenClose({
             openClose:true
         }))


    }


    return(<Box 
          style={{display:headerData.openClose===true?'none':'flex'}}
          sx={SpaceTheme.sideRightNavMain}
         
       >   

           <Box sx={SpaceTheme.righSidebarBox}>

             <Button sx={SpaceTheme.rightSideBarBtn}>
              <img  style={SpaceTheme.rightSideBarBtn.iconImg} src={googleClandarIcon}></img>
            </Button>

              <Button sx={SpaceTheme.rightSideBarBtn}>
              <img style={SpaceTheme.rightSideBarBtn.iconImg} src={googleKeepIcon}></img>
            </Button>

             <Button sx={SpaceTheme.rightSideBarBtn}>
              <img style={SpaceTheme.rightSideBarBtn.iconImg} src={googleDriveIcon}></img>
            </Button> 

            <Box 
            borderColor="border.default" 
            borderBottomWidth={1} 
            borderBottomStyle="solid"
            sx={SpaceTheme.rightSideBarDivider}
            >
            </Box>  

            <Button sx={SpaceTheme.rightSideBarPlusBtn}>
                <PlusIcon 
                style={SpaceTheme.rightSideBarPlusBtn.plusIcon}
                 />
             </Button>


            

           </Box>


           <IconButton 
           sx={{
            position:"absolute",
            height:'30px',
            width:'30px',
            left:'0px',
            right:'0px',
            bottom:'20px',
            margin:'auto',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            color:'#000000'
           }}
           aria-label="Search" 
           icon={()=><ChevronRightIcon style={{display:'flex'}} />} 
           onClick={()=> hideRightSideBar("")}
           size="small" 
           variant="invisible"
           />

          
          

            

    </Box>)

}

export default SpaceRightSideNav