import Header from "../Header"
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Box, Text, TextInput, Button, Heading } from "@primer/react"
import SignIntheme from "./SignIntheme"
import React from "react"
import  { Auth } from 'aws-amplify';
import LoadingSpinner from "../LoadingSpinner";
import { CognitoIdentityServiceProvider } from 'aws-sdk'; 
import { Toaster, Intent,Position } from "@blueprintjs/core";
 
 
const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID
const cognitoIdp = new CognitoIdentityServiceProvider({
    region: process.env.REACT_APP_COGNITO_REGION,
    accessKeyId:process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY  
})
 
function SignIn() {

    const navigate = useNavigate();
    const history = createBrowserHistory()

    
    const [showSpinner,setshowSpinner] = React.useState(false)
    const [passwordInputVisible,setpasswordInputVisible] = React.useState(false)

    const [signInError,setsignInError]  = React.useState("")

    const [userEmail, setUserEmail] = React.useState("")
    const [userPassword, setuserPassword] = React.useState("")
    const [validationStatus, setValidationStatus] = React.useState({
        userEmail: "",
        userPassword: ""
    })


     


    const handleContinueBtn =async (userEmail) => {

             if(userEmail===""){
             
                setValidationStatus({
                    userEmail: "error",
                    userPassword: validationStatus.userPassword
                })

             }else{

                const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!userEmail || regex.test(userEmail) === false) {
    
                    setValidationStatus((prevState) => {
                        return { ...prevState, userEmail: "error" }
                    }
                    )
    
                    return false;
                } else {
                   
                    setshowSpinner(true)

                    setValidationStatus((prevState) => {
                        return { ...prevState, userEmail: "" }
                    })

                           //  alert(userEmail)
                           var params = {
                            UserPoolId: cognitoUserPoolId,
                            AttributesToGet: ['email'],
                            Filter: "email = \"" + userEmail + "\""
                          };

                          cognitoIdp.listUsers(params,async function (err, data) {
                            if (err) {
                      
                            
                              setshowSpinner(false)
                      
                            } else {
                      
                                 
                                 if(data.Users.length > 0){

                                        setsignInError("")

                                       for(var i=0;i<data.Users.length;i++){

                                             
                                            const confirmationStatus = data.Users[i].UserStatus //CONFIRMED
                                          
                                            if(confirmationStatus==="CONFIRMED"){
                                                  setshowSpinner(false)
                                                  setpasswordInputVisible(true)
                                            }else if(confirmationStatus==="UNCONFIRMED"){

                                                  setshowSpinner(false)
                                                  
                                                  localStorage.setItem("email",userEmail)
                                                  const resenCode =  await Auth.resendSignUp(userEmail);
                                                  navigate('/emailVerification')
                                            }
                                            


                                       }

                                      
                                 }else{

                                    setsignInError("User not exists")
                                    Toaster.create({position: Position.TOP}).show({
                                        message: "User account does not exist",
                                        intent:Intent.DANGER
                                    });
                                    setshowSpinner(false)
                                 }
                      
                            }
                      
                      
                          })

                            
                }


                 
               
             }
    }

    const handleSubmitBtn = (userEmail, userPassword) => {

        if ((userEmail !== null && userPassword !== null) && (userEmail !== "" && userPassword !== "")) {

            setValidationStatus({
                userEmail: "",
                userPassword: ""
            })

            setshowSpinner(true)

            Auth.signIn(userEmail,userPassword).then(user=>{
                    
                    history.push('/initialization') 
                    setshowSpinner(false)

                    


                    window.location.reload()

            }).catch(error => {

                    
                    if(error.name==="NotAuthorizedException"){

                        setsignInError("Incorrect username or password")
                        Toaster.create({position: Position.TOP}).show({
                            message: "The password you entered is incorrect.",
                            intent:Intent.DANGER
                        });
                        
                    } 
                    setshowSpinner(false)



            })

          //  alert(userEmail + " " + userPassword)
        } else {


            if (userEmail === "" && userPassword === "") {

                setValidationStatus({
                    userEmail: "error",
                    userPassword: "error"
                })


            } else {

                  if(userEmail===""){

                      setValidationStatus(prevState=>{
                          
                        return{...prevState,userEmail:"error"}
                      })


                  }else{

                    setValidationStatus(prevState=>{
                          
                        return{...prevState,userEmail:""}
                    })
                  }

                  if(userPassword===""){

                    setValidationStatus(prevState=>{
                        
                      return{...prevState,userPassword:"error"}
                    })


                }else{

                    setValidationStatus(prevState=>{
                        
                      return{...prevState,userPassword:""}
                    })
                }

            }
        }


    }

    const handleLoginWithGoogle = (props) => {

          Auth.federatedSignIn({ provider: 'Google' })


    }


    const handleLoginWithMicrosoftAd =async (props) => {

           await  Auth.federatedSignIn({ provider: 'azuread' })

        
    }

    


    React.useState(()=>{

       


        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          }).then(user=> {
      
              
              history.replace('/initialization') 
              window.location.reload() 
             
      
          }).catch(error=>{
      
              
              
          })


    })

    
   

    return (

        <Box  
        
        position={"relative"} 
        display="flex" 
        height="100vh"
        width={"100vw"} 
        overflowY={"auto"}
        >
            <Helmet>
            <title>
            Sign in - deski
            </title>
            </Helmet>
             
            <LoadingSpinner showSpinner={showSpinner} />
            <Header />
           
            <Box sx={SignIntheme.loginMain}>
                <Box
                    sx={SignIntheme.loginPageBox}
                >
                    <Heading sx={SignIntheme.loginPageBoxHeading}>Sign in to your workspace</Heading>
                     
                   
                   
                    <TextInput
                        validationStatus={validationStatus.userEmail}
                        sx={SignIntheme.loginPageEmailInput}
                        name="email"
                        placeholder="Enter email"
                        type={"email"}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />

                    {passwordInputVisible===true ?
                    <TextInput
                    validationStatus={validationStatus.userPassword}
                    sx={SignIntheme.loginPagePasswordInput}
                    name="password"
                    type={"password"}
                    placeholder="Enter your password"
                    onChange={(e) => setuserPassword(e.target.value)}
                /> :"" }

                    {

                        passwordInputVisible === true ?

                            <Button
                                variant="outline"
                                type="button"
                                aria-selected={true}
                                sx={SignIntheme.continueBtn}
                                onClick={() => 
                                    
                                    {
                                        handleSubmitBtn(userEmail, userPassword)
                                       
                                    }
                                   
                                
                                }
                                
                            >
                                <Text
                                    as="p"
                                    color="#FFFFFF"
                                    sx={SignIntheme.continueBtnText}
                                >
                                    Login
                                    
                              </Text>




                            </Button> :

                            <Button
                                variant="outline"
                                type="button"
                                aria-selected={true}
                                sx={SignIntheme.continueBtn}
                                onClick={() => handleContinueBtn(userEmail)}
                            >
                                <Text
                                    as="p"
                                    color="#FFFFFF"
                                    sx={SignIntheme.continueBtnText}
                                >
                                    

                                    Continue
                                </Text>




                            </Button>

                    }

                    

                    <Box
                        sx={SignIntheme.dividerBox}
                    >
                        <Box
                            sx={SignIntheme.dividerBox.firstDivider}
                        >

                        </Box>
                        <Text
                            as="P"
                            position="relative"
                            margin="0px 27px 0px 27px"
                            fontFamily="Segoe UI"
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="52px"
                            color="#586069"
                        >
                            OR
                        </Text>
                        <Box
                            sx={SignIntheme.dividerBox.secondDivider}
                        >

                        </Box>
                    </Box>

                    <Button
                        variant="default"
                        type="button"
                        leadingIcon={() => <img height="16" width="16" src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" />}
                        sx={SignIntheme.googlBtn}
                        onClick={()=>handleLoginWithGoogle("") }
                    >
                        <Text
                            as="p"
                            sx={SignIntheme.googlBtn.text}
                        >
                            Continue with google
                        </Text>

                    </Button>

                    <Button
                        variant="default"
                        type="button"
                        leadingIcon={() => <img height="16" width="16" src="https://cdn.cdnlogo.com/logos/m/95/microsoft.svg" />}
                        sx={SignIntheme.microSoftBtn}
                        onClick={()=>{
                              handleLoginWithMicrosoftAd("") 
                        }}
                    >
                        <Text
                            as="p"
                            sx={SignIntheme.microSoftBtn.text}

                        >
                            Continue with microsoft
                        </Text>

                    </Button>


                    <Box
                        sx={SignIntheme.loginBoxBottomBox}

                    >
                        <Text
                            as="p"
                            sx={SignIntheme.loginBoxBottomBox.signUpText}
                            onClick={() =>

                                navigate('/signup')
                            }
                        >
                            Sign up for an account
                        </Text>
                        <Text
                            as="p"
                            sx={SignIntheme.loginBoxBottomBox.forgotPasswordText}
                            onClick={() =>

                                navigate('/forgetpassword')
                            }
                        >
                            Forgot password
                        </Text>


                    </Box>


                </Box>
            </Box>

        </Box>

    )

}
export default SignIn
