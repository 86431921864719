import React from "react"
import { Box, Button, Avatar, Dialog, Text, ActionMenu, ActionList, TextInput, Select, Textarea, Portal, Spinner, } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import { createTaskDialogOpenClose, postcreateIssue } from "../../redux/features/taskSlice"
import { PlusIcon, TriangleDownIcon, FeedPersonIcon } from "@primer/octicons-react"
import { getComplianceTaksByProjectId,getSpaceCollaborators } from "../../redux/features/spaceSlice"
import { getIndiviualsCompanies } from "../../redux/features/contactSlice"
import { Toaster, Intent,Position } from "@blueprintjs/core";


export default function CreateTaskDialog() {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskData = useSelector((state) => state.taskData)
    
    const initialFocusRef = React.createRef()  // references input
    const dueDatecalandarRef = React.createRef()

    const [showLoadingSubmitBtn,setshowLoadingSubmitBtn] = React.useState(false)

    const [taskInpuError,settaskInpuError] = React.useState('')
    const [openOrgList, setOpenOrgList] = React.useState(false)
    const [dueDate, setdueDate] = React.useState("")
    const [companyIndiviual, setcompanyIndiviual] = React.useState([])
    const [copycompanyIndiviual, setcopycompanyIndiviual] = React.useState([])
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [selectedAssigne, setselectedAssigne] = React.useState({user_id: '',user_name: ''})
    const [selectedOrg, setselectedOrg] = React.useState({org_id: '',org_name: ''})
    const [selectedOrgArray,setselectedOrgArray] = React.useState([])
    const [orgInput, setorgInput] = React.useState({
           id:'',
           value:'',
           validationStatus:''
    })
    const [enableDescription, setenableDescription] = React.useState(true)
    const [descriptionInput, setdescriptionInput] = React.useState("")

   
    const [showActionList, setshowActionList] = React.useState(false)
    const [taskInput, settaskInput] = React.useState("")
    const [taskArray, settaskArray] = React.useState([])
    const [selectedComplianceId,setselectedComplianceId] = React.useState("")


    const closeCreateTaskDialog = (props) => {


         dispatch(createTaskDialogOpenClose({
            taskDialogOpenClose: false,
           
        }))
        settaskInput('')
        setdueDate("")
        setselectedAssigne({
            user_id:'',
            user_name:''
        })
        setselectedOrg({
            org_id:'',
            org_name:''
        })
        setorgInput({
            id:'',
            value:'',
            validationStatus:''
        })
        setdescriptionInput('')
        setOpenOrgList(false)
        setshowActionList(false)

    }

    const onChangeOrgInput = (e,props) => {


        setorgInput({
            id:props,
            value:e.target.value
        })
        // setcompanyIndiviual((prevState) => {

        //     const newState = copycompanyIndiviual.filter((cData) => cData.contact_data?.org_name.toLowerCase().includes(e.target.value.toLowerCase())).
        //         sort((a, b) => a.contact_data?.org_name.localeCompare(b.contact_data?.org_name))

        //     if (newState.length > 0) {

        //         setOpenOrgList(true)
        //     } else {

        //         setOpenOrgList(false)
        //     }


        //     return newState

        // })


        
         
    }



    const getSpaceCollaboratorFromDb = (props) => {


        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)




        })

    }

    const getAllCompanyIndiviuals = async (props) => {


        dispatch(getIndiviualsCompanies({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


           

            setcompanyIndiviual(data.payload.data)
            setcopycompanyIndiviual(data.payload.data)
        })

    }


    const postTaskToDb = (props) => {

           if(taskInput===""){

                 settaskInpuError('error')

           }
        //    else if(selectedOrgArray.length <=0){

        //          setorgInput((prevState)=>{
        //               const newState = {...prevState,validationStatus:'error'}
        //               return newState
        //          })

        //    }
           else{

  
                   setshowLoadingSubmitBtn(true)

                   const projectId = localStorage.getItem("projectId")
                   const taskPriority = "Normal"
                   dispatch(postcreateIssue({
                   userId: userId,
                   userEmail: userEmail,
                   projectId: projectId,
                   taskName: taskInput,
                   taskPriority: taskPriority,
                   assigneId:selectedAssigne.user_id,
                   contact:selectedOrgArray,
                   dueDate:""+dueDate+"",
                   description:descriptionInput,
                   complianceId:selectedComplianceId
                   })).then((data)=>{

                       Toaster.create({ position: Position.TOP }).show({
                       message: "The task has been created.",
                       intent: Intent.SUCCESS
                        });
                       closeCreateTaskDialog("")
                       setshowLoadingSubmitBtn(false)
                   })
 
                   
                
              

           }


    }

    const getAllTasks = (props) => {

       
        dispatch(getComplianceTaksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {
             settaskArray(data.payload.data)
        })


    }


    React.useEffect(() => {

        if (taskData.taskDialogOpenClose === true) {

            getSpaceCollaboratorFromDb("")
            getAllCompanyIndiviuals("")
            getAllTasks("")
        }

    }, [taskData.taskDialogOpenClose])


    return (
        <Portal>
            <Dialog
                initialFocusRef={initialFocusRef}
                isOpen={taskData.taskDialogOpenClose}
                onDismiss={() => {
                    closeCreateTaskDialog("")
                }}
                sx={{
                    minHeight: '455px',
                    height: '455px',
                    minWidth: '564px',
                    width: '564px',
                    maxWidth: '564px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>






                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '30px',
                    top: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px'
                }}>

                    <Text
                        ref={initialFocusRef}
                        sx={{
                            position: 'absolute',
                            left: '16px',
                            top: '16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'
                        }}>
                        Create a task
                    </Text>

                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '52px',
                    bottom: '52px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Text

                        sx={{
                            position: 'relative',
                            margin: '8px 16px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#24292E'
                        }}>
                        Select a task or compliance to start or create a new task with the 'Add Task' button.
                    </Text>

                    <TextInput
                        sx={{
                            position: 'relative',
                            margin: '20px 16px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292E',
                            minHeight: '40px'
                        }}
                        value={taskInput}
                        onChange={(e) => {

                            settaskInput(e.target.value)
                            if (e.target.value.length > 0) {
                                setshowActionList(true)
                                setOpenOrgList(false)
                                settaskInpuError('')
                            } else {
                                setshowActionList(false)
                            }
                        }}
                        placeholder="Select service/compliance to start or create new task"
                        validationStatus={taskInpuError}
                       
                    />

                    <Box
                       
                        sx={{
                            visibility: showActionList === true ? 'visible' : 'hidden',
                            zIndex: '1',
                            position: 'absolute',
                            left: '6px',
                            right: '6px',
                            top: '92px',
                            minHeight: 'auto',
                            maxHeight: '244px',
                            height: 'auto',
                            boxShadow: '0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)',
                            borderRadius: '6px',
                            background: '#FFFFFF',
                            overflowX:'hidden',
                            overflowY:'auto'
                        }} >

                        <ActionList sx={{

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '18px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'

                        }}>
                            {taskArray
                                .filter((tData) => tData.subcategory.compliance_name?.toLowerCase().includes(taskInput?.toLowerCase()))
                                .map((row, index) => {
                                    return (
                                        <ActionList.Item
                                            onSelect={() => {

                                                 
                                                   
                                                   if(selectedOrgArray.length>0){
                                                      const conId = selectedOrgArray[0]?.contact_id
                                                      const filteredCompany = companyIndiviual
                                                      .filter((cpData)=> cpData.contact_id===conId )
                                                      .filter((cpData)=> 
                                                      
                                                         cpData.compliances.filter((cData)=> cData.compliance_id===row.service_id && cData.isChecked===true)[0]?.compliance_id===row.service_id
                                                          
                                                      ).length 
                                                      
                                                      
                                                      if(filteredCompany <= 0){
                                   
                                                          setselectedOrgArray([])
                                                          setselectedOrg({
                                                              org_id: '',
                                                              org_name: ''
                                                          })
                                                          setorgInput({
                                                              id: '',
                                                              value: '',
                                                              validationStatus: ''
                                                          })
                                                          setselectedComplianceId(row.service_id)
                                                          settaskInput(row.subcategory.compliance_name)
                                                          setshowActionList(false)

                                                      }else{

                                                         setselectedOrgArray([])
                                                         setselectedOrg({
                                                            org_id: '',
                                                            org_name: ''
                                                         })
                                                         setorgInput({
                                                            id: '',
                                                            value: '',
                                                             validationStatus: ''
                                                         })
                                                         setselectedComplianceId(row.service_id)
                                                         settaskInput(row.subcategory.compliance_name)
                                                         setshowActionList(false)
                                                            
                                                           

                                                      }
                                                       


                                                   }else{

                                                       
                                             
                                                       setselectedComplianceId(row.service_id)
                                                       settaskInput(row.subcategory.compliance_name)
                                                       setshowActionList(false)
                                                       

                                                   }
                                                  
                                              

                                                // setselectedComplianceId(row.service_id)
                                                // settaskInput(row.subcategory.compliance_name)
                                                // setshowActionList(false)
                                            }}
                                        >
                                            {row.subcategory.compliance_name}
                                        </ActionList.Item>
                                    )
                                })}


                            <Box
                                hidden={taskArray.filter((tData) => tData.subcategory.compliance_name?.toLowerCase().includes(taskInput?.toLowerCase())).length === 0 ? true : false}
                                sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#D0D7DE', width: '100%', marginTop: '1px' }}
                            ></Box>
                            <Button variant="invisible"
                                sx={{
                                    position: 'relative',
                                    margin: '8px auto 0px -8px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',


                                }}
                                onClick={()=>{
                                    setshowActionList(false)
                                }}
                            >Create as new</Button>
                        </ActionList>

                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '20px 16px 0px 16px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        minHeight: '40px',
                        alignItems: 'center'
                    }}>

                        <TextInput

                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292E',
                                minHeight: '40px',
                                minWidth: '352px'
                            }}
                            placeholder="Please select organization or individual "
                            value={orgInput.value}
                            validationStatus={orgInput.validationStatus}
                            onChange={(e) => { onChangeOrgInput(e) }}
                            onClick={()=> {  setOpenOrgList(true) }}
                            
                        />

                        <Box
                              
                            sx={{
                                visibility: openOrgList === true ? 'visible' : 'hidden',
                                zIndex: '1',
                                position: 'absolute',
                                left: '0px',
                                right: '6px',
                                top: '43px',
                                minHeight: 'auto',
                                maxHeight: '144px',
                                height: 'auto',
                                minWidth: '352px',
                                maxWidth: '352px',
                                width: '352px',
                                boxShadow: openOrgList === true && companyIndiviual.length > 0 ? '0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)' : 'none',
                                borderRadius: '12px',
                                background: '#ffffff',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }} >

                            <ActionList sx={{

                                width: '342px',
                                height: 'auto',
                                minWidth: '342px',
                                minHeight: 'auto',
                                maxWidth: '342px',
                                maxHeight: '144px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingLeft: '4px',
                                paddingRight: '4px',
                                // display:companyIndiviual
                                // .filter((cpData)=>
                                //     cpData.compliances.filter((cData)=> cData.compliance_id===selectedComplianceId && cData.isChecked===true)[0]?.compliance_id===selectedComplianceId
                                // ).
                                // filter((cData) => cData.contact_data?.org_name.toLowerCase().includes(orgInput.value.toLowerCase())).
                                // sort((a, b) => a.contact_data?.org_name.localeCompare(b.contact_data?.org_name)).length > 0 ?'flex':'none'
                            }}>

                                {companyIndiviual
                                    .filter((cpData)=> 
                                    
                                       selectedComplianceId!==""?
                                       cpData.compliances.filter((cData)=> cData.compliance_id===selectedComplianceId && cData.isChecked===true)[0]?.compliance_id===selectedComplianceId
                                       :companyIndiviual
                                    ).
                                    filter((cData) => cData.contact_data?.org_name.toLowerCase().includes(orgInput.value.toLowerCase())).
                                    sort((a, b) => a.contact_data?.org_name.localeCompare(b.contact_data?.org_name))
                                    .map((row, index) => {


                                        return (
                                            <ActionList.Item
                                                sx={{ minHeight: '20px', maxHeight: '20px', height: '20px' }}
                                                onSelect={(e) => {
                                                    setselectedOrg({
                                                        org_id: row.contact_id,
                                                        org_name: row.contact_data?.org_name
                                                    })
                                                    setorgInput({
                                                        id:row.contact_id,
                                                        value:row.contact_data?.org_name,
                                                        validationStatus:''
                                                    })
                                                    setOpenOrgList(false)
                                                     
                                                    setselectedOrgArray((prevState)=>{
                                                          const newState = [{contact_id:row.contact_id}]
                                                          return newState
                                                    })  


                                                }}
                                            >
                                                <Text sx={{
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#24292F',
                                                    width: 'auto',
                                                    maxWidth: '197px',
                                                    minWidth: 'auto',
                                                    height: '15px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    display: 'flex'

                                                }}>{row.contact_data?.org_name}</Text>
                                                <ActionList.Description>
                                                    <Text sx={{
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: '#57606A',
                                                        width: 'auto',
                                                        maxWidth: '61px',
                                                        minWidth: 'auto',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'flex'
                                                    }}>
                                                        {row?.directors_info[0]?.firstName}
                                                    </Text>
                                                </ActionList.Description>
                                            </ActionList.Item>
                                        )
                                    })}

                                {/* <ActionList.Item
                                    sx={{ minHeight: '20px', maxHeight: '20px', height: '20px' }}
                                    onSelect={(e) => {

                                              const newCompanyIndiviuals = companyIndiviual
                                              .filter((cpData)=>
                                              cpData.compliances.filter((cData)=> cData.compliance_id===selectedComplianceId && cData.isChecked===true)[0]?.compliance_id===selectedComplianceId
                                              )
                                              .map((row)=> {
                                                   return({contact_id:row.contact_id})
                                              }) 

                                              setselectedOrgArray(newCompanyIndiviuals)
                                              setorgInput((prevState)=>{
                                                  const newState = {...prevState,value:'All',validationStatus:''}
                                                  return newState
                                              })
                                              setOpenOrgList(false)
                                    }}
                                >
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292F',
                                        width: 'auto',
                                        maxWidth: '197px',
                                        minWidth: 'auto',
                                        height: '15px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'flex'

                                    }}>{"All"}</Text>
                                    <ActionList.Description>
                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#57606A',
                                            width: 'auto',
                                            maxWidth: '61px',
                                            minWidth: 'auto',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            display: 'flex'
                                        }}>
                                            {"All"}
                                        </Text>
                                    </ActionList.Description>
                                </ActionList.Item> */}


                            </ActionList>

                        </Box>




                        <Box sx={{
                            minWidth: '170px',
                            minHeight: '40px',
                            width: '170px',
                            height: '40px',

                        }}>



                            <ActionMenu >

                                <ActionMenu.Anchor >

                                    <Button sx={{
                                        minWidth: '170px',
                                        minHeight: '40px',
                                        width: '170px',
                                        height: '40px',
                                        background: '#ffffff',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '8px',


                                    }}
                                        trailingIcon={() => <Box sx={{ position: 'absolute', right: '8px', top: '8px', bottom: '8px' }}><TriangleDownIcon /></Box>}
                                    >
                                        {selectedAssigne.user_id === "" ? "Assignee" : selectedAssigne.user_name}
                                    </Button>

                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList sx={{
                                        width: '280px',
                                        height: 'auto',
                                        minWidth: '280px',
                                        minHeight: 'auto',
                                        maxWidth: '280px',
                                        maxHeight: '180px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    }}
                                        showDividers
                                    >
                                        {spaceCollaborators?.map((row, index) => {


                                            return (
                                                <ActionList.Item onSelect={event =>
                                                    setselectedAssigne({
                                                        user_id: row.user_id,
                                                        user_name: row.user_first_name + "" + row.user_last_name
                                                    })
                                                }
                                                    sx={{ minHeight: '20px', maxHeight: '20px', height: '20px' }}
                                                >

                                                    <ActionList.LeadingVisual>
                                                        {row?.user_image_url === "" || row?.user_image_url == null ?

                                                            <FeedPersonIcon size={20} fill="#586069" /> :

                                                            <Avatar sx={{
                                                                height: '20px',
                                                                width: '20px',
                                                                minHeight: '20px',
                                                                minWidth: '20px',


                                                            }}
                                                                src={row.user_image_url} />

                                                        }

                                                    </ActionList.LeadingVisual>
                                                    <Text sx={{
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: '#24292F',
                                                        width: 'auto',
                                                        maxWidth: '80px',
                                                        minWidth: 'auto',
                                                        height: '15px',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'flex'

                                                    }}>{row?.user_first_name + " " + row?.user_last_name}</Text>
                                                    <ActionList.Description>
                                                        <Text sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            color: '#57606A',
                                                            width: 'auto',
                                                            maxWidth: '130px',
                                                            minWidth: 'auto',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            display: 'flex'
                                                        }}>{row?.user_email}</Text>
                                                    </ActionList.Description>
                                                </ActionList.Item>
                                            )

                                        })}





                                    </ActionList>




                                </ActionMenu.Overlay>

                            </ActionMenu>




                        </Box>
                    </Box>


                    <Box sx={{
                        position: 'relative',
                        margin: '20px 16px 0px 16px',

                    }}
                        onClick={() => setenableDescription(false)}
                    >

                        <Textarea
                           // disabled={enableDescription}
                            resize="none"
                            sx={{
                                width: '530px',
                                minWidth: '530px',
                                maxWidth: '530px',
                                minHeight: '100px',
                                maxHeight: '100px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292E',
                                background:enableDescription===true?'#F6F8FA':'#ffffff'
                            }}
                            placeholder="Please enter task description (optional)"
                            value={descriptionInput}
                            onChange={(e) => { setdescriptionInput(e.target.value) }}
                            onBlur={() => setenableDescription(true)}
                        />

                    </Box>


                    {dueDate === "" ?

                        <Button
                            sx={{
                                position: 'relative',
                                margin: '8px 0px 0px 10px',
                                background: '#FFFFFF',
                                color: '#57606A',
                                width: '110px',
                                height: '32px',
                                minWidth: '110px',
                                maxWidth: '110px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                lineHeight: '20px',
                                fontWeight: '400',
                                border: 'none'
                            }}
                            leadingIcon={() => <PlusIcon />}
                            onClick={() => {
                                dueDatecalandarRef.current.showPicker()
                            }}
                        >

                            Add due date
                        </Button>


                        :

                        <Button
                            sx={{

                                position: 'relative',
                                margin: '8px 0px 0px 8px',
                                background: '#FFFFFF',
                                color: '#24292E',
                                width: '130px',
                                height: '32px',
                                minWidth: '130px',
                                maxWidth: '130px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                lineHeight: '20px',
                                fontWeight: '400',
                                border: 'none',
                                paddingLeft: '8px'
                            }}
                            onClick={() => {
                                dueDatecalandarRef.current.showPicker()
                            }}
                        >
                            Due date :  {new Date(dueDate).getDate() + "/" + (new Date(dueDate).getMonth() + 1) + "/" + new Date(dueDate).getFullYear()}
                        </Button>



                    }



                    <input
                        type={"date"}
                        ref={dueDatecalandarRef}
                        onChange={(e) => {
                            if (e.target.value === "" || e.target.value == null) { setdueDate("") }
                            else { setdueDate(new Date(e.target.value)) }
                        }}
                        style={{
                            position: 'absolute',
                            left: '16px',
                            bottom: '25px',
                            height: '32px',
                            width: '20%',
                            zIndex: '0',
                            visibility: 'hidden'
                        }}

                    />



 

                </Box>

               


                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    {showLoadingSubmitBtn===true?
                    <Button variant="outline" sx={{
                        position: 'relative',
                        margin: 'auto 16px 10px auto',
                        background: '#0965CE',
                        color: '#ffffff',
                        width: '78px',
                        height: '32px',
                        minWidth: '78px',
                        maxWidth: '78px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',

                    }}
                    onClick={()=>{
                       
                    }}
                    >
                        
                       <Spinner size="small" sx={{display:'flex'}} />
                    </Button>
                    :
                    <Button variant="outline" sx={{
                        position: 'relative',
                        margin: 'auto 16px 10px auto',
                        background: '#0965CE',
                        color: '#ffffff',
                        width: '78px',
                        height: '32px',
                        minWidth: '78px',
                        maxWidth: '78px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',

                    }}
                    onClick={()=>{
                        postTaskToDb("")
                    }}
                    >
                        Create
                    </Button>
                    }

                </Box>

            </Dialog>
        </Portal>

    )

}