import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Auth } from 'aws-amplify';


export const getUserProfileData = createAsyncThunk("getUserProfileData", async (data) => {

    return Auth.currentAuthenticatedUser({ bypassCache: false }).then(user => user).catch(error => error)

})


export const getUserIdInLocalDb = createAsyncThunk("getUserIdInLocalDb", async (data) => {

    return localStorage.getItem("userId")
})

export const getUserEmailInLocalDb = createAsyncThunk("getUserEmailInLocalDb", async (data) => {

    return localStorage.getItem("userEmail")
})


export const getUserSubscriptionData = createAsyncThunk("getUserSubscriptionData", async (data) => {

    const userEmail = data.userEmail
    const userId = '123'
    const result = await fetch(`${process.env.REACT_APP_API_URL}/getUserSubscription?userId=` + userId + `&userEmail=` + userEmail)
        .then(response => response.json())

    return result


})

export const getTaxheroUserSubscription = createAsyncThunk("getTaxheroUserSubscription", async (data) => {

    //getTaxheroUserSubscription
    const userEmail = data.userEmail
    const userId = ''
    const result = await fetch(`${process.env.REACT_APP_API_URL}/getTaxheroUserSubscription?userId=` + userId + `&userEmail=` + userEmail)
        .then(response => response.json())

    return result
})



export const getSubscriptionbyProjectsId = createAsyncThunk("getSubscriptionbyProjectsId", async (data) => {

    const userEmail = data.userEmail
    const userId = '123'
    const result = await fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionbyProjectsId?userId=` + userId + `&userEmail=` + userEmail)
        .then(response => response.json())

    return result


})

export const getSubscriptionInvoice = createAsyncThunk("getSubscriptionInvoice", async (data) => {

    //getSubscriptionInvoice
    const url = `${process.env.REACT_APP_API_URL}/getSubscriptionInvoice?userId=${data.userId}&userEmail=${data.userEmail}&invoiceId=${data.invoiceId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result


})

export const getChargebeePlan = createAsyncThunk("getChargebeePlan", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getChargebeePlan?userId=${data.userId}&userEmail=${data.userEmail}&planId=${data.planId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const checkUserSubscriptionStatus = createAsyncThunk("checkUserSubscriptionStatus", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/checkUserSubscriptionStatus?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})



export const getSubscriptionbyOrganizationId = createAsyncThunk("getSubscriptionbyOrganizationId", async (data) => {

    const userEmail = data.userEmail
    const userId = '123'
    const projectId = data.projectId
    const result = await fetch(`${process.env.REACT_APP_API_URL}/getSubscriptionbyProjectsId?userId=` + userId + `&userEmail=` + userEmail + `&projectId=` + projectId)
        .then(response => response.json())

    return result


})







export const updateUserPassword = createAsyncThunk("updateUserPassword", async (data) => {

    const userId = data.userId
    const userName = data.userName
    const userEmail = data.userEmail
    const newPassword = data.newPassword

    const bodyParams = "userPoolId=" + userId + "&" + "userName" + userName + "&" + "newPassword" + newPassword


    const result = await

        fetch(`${process.env.REACT_APP_API_URL}/updateUserPassword?`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),

            body: bodyParams
        }).then(response => response.json())

    return result



})

export const getUserProfileFromDatabase = createAsyncThunk("getUserProfileFromDatabase", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,

    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getUserProfile`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateUserProfileInDb = createAsyncThunk("updateUserProfileInDb", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "organization": data.organization,
        "userRole": data.userRole,
        "userLocation": data.userLocation
    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateUserProfile`, requestOptions)
        .then(response => response.json())

    return result


})


export const clearUserNotification = createAsyncThunk("clearUserNotification", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "userNotification": data.userNotification
    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/clearUserNotification`, requestOptions)
        .then(response => response.json())

    return result


})




export const updateProfileImage = createAsyncThunk("updateProfileImage", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userImageUrl": data.userImageUrl
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateUserProfileImage`, requestOptions)
        .then(response => response.json())

    return result





})


export const getUserProfileImageByUserId = createAsyncThunk("getUserProfileImageByUserId", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getProfileImageByUserId`, requestOptions)
        .then(response => response.json())

    return result


})


export const taxHeroSubscriptionAddon = createAsyncThunk("taxHeroSubscriptionAddon", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/taxHeroSubscriptionAddon`, requestOptions)
        .then(response => response.json())

    return result

})


export const taxHeroSubscriptionUpgrade = createAsyncThunk("taxHeroSubscriptionUpgrade", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "selectedPlan": data.selectedPlan
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/taxHeroSubscriptionUpgrade`, requestOptions)
        .then(response => response.json())

    return result

})


export const chargebeesubscriptionReniew = createAsyncThunk("chargebeesubscriptionReniew", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "subscriptionId": data.subscriptionId,
        "planType": data.planType,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/chargebeesubscriptionReniew`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateProfileImageRealTimeLocal = createAsyncThunk("updateProfileImageRealTimeLocal", async (data) => {


    return { userImageUrl: data.userImageUrl }

})

export const logoutFromCognitoUser = createAsyncThunk("logoutFromCognitoUser", async (data) => {

    return { isLogout: data.isLogout }
})


export const openCloseFreeTrialEndDialog = createAsyncThunk("openCloseFreeTrialEndDialog", async (data) => {

    return { freetrialEndOpen: data.freetrialEndOpen, freeTrialCustomerId: data.freeTrialCustomerId }

})

export const openCloseTaxheroFreeTrialEndDialog = createAsyncThunk("openCloseTaxheroFreeTrialEndDialog", async (data) => {

    return { taxherofreetrialEndOpen: data.taxherofreetrialEndOpen }

})

export const openCloseSubscriptionPlanDialog = createAsyncThunk("openCloseSubscriptionPlanDialog", async (data) => {

    return { openCloseSubscriptionPlan: data.openCloseSubscriptionPlan }

})



export const profileSlice = createSlice({

    name: 'profileSlice',
    initialState: {
        profileData: null,
        loading: false,
        subscriptionData: null,
        userId: localStorage.getItem("userId"),
        userEmail: localStorage.getItem("userEmail"),
        userImageUrl: "",
        isLogout: false,
        freetrialEndOpen: false,
        freeTrialCustomerId: '',
        taxherofreetrialEndOpen: false,
        growsubscriptionstatus: '',
        taxHerosubscriptionstatus: '',
        openCloseSubscriptionPlan: false,
        taxHeroPlanPrice: [],
    },
    extraReducers: {

        [getUserProfileData.pending]: (state, action) => {
            state.loading = true;


        },
        [getUserProfileData.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.profileData = action.payload

        },
        [getUserProfileData.rejected]: (state, action) => {
            state.loading = false

        },
        [getUserSubscriptionData.pending]: (state, action) => {
            state.loading = true;


        },
        [getUserSubscriptionData.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.subscriptionData = action.payload

        },
        [getUserSubscriptionData.rejected]: (state, action) => {
            state.loading = false

        },
        [getUserIdInLocalDb.pending]: (state, action) => {
            state.loading = true;


        },
        [getUserIdInLocalDb.fulfilled]: (state, action) => {
            state.loading = false //action.payload.loading;
            state.userId = action.payload

        },
        [getUserIdInLocalDb.rejected]: (state, action) => {
            state.loading = false

        },
        [getUserEmailInLocalDb.pending]: (state, action) => {
            state.loading = true;


        },
        [getUserEmailInLocalDb.fulfilled]: (state, action) => {
            state.loading = false //action.payload.loading;
            state.userEmail = action.payload

        },
        [getUserEmailInLocalDb.rejected]: (state, action) => {
            state.loading = false

        },
        [updateProfileImageRealTimeLocal.pending]: (state, action) => {
            state.loading = true;


        },
        [updateProfileImageRealTimeLocal.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.userImageUrl = action.payload.userImageUrl

        },
        [updateProfileImageRealTimeLocal.rejected]: (state, action) => {
            state.loading = false

        },
        [getUserProfileFromDatabase.pending]: (state, action) => {
            state.loading = true;


        },
        [getUserProfileFromDatabase.fulfilled]: (state, action) => {
            state.loading = false;
            state.userImageUrl = action.payload.data[0]?.user_image_url

        },
        [getUserProfileFromDatabase.rejected]: (state, action) => {
            state.loading = false

        },
        [logoutFromCognitoUser.pending]: (state, action) => {
            state.isLogout = false; // old code it was  state.isLogout = true

        },
        [logoutFromCognitoUser.fulfilled]: (state, action) => {

            state.isLogout = action.payload.isLogout

        },
        [logoutFromCognitoUser.rejected]: (state, action) => {
            state.isLogout = false

        },
        [openCloseFreeTrialEndDialog.pending]: (state, action) => {
            state.freetrialEndOpen = true;
            state.freeTrialCustomerId = ''

        },
        [openCloseFreeTrialEndDialog.fulfilled]: (state, action) => {

            state.freetrialEndOpen = action.payload.freetrialEndOpen
            state.freeTrialCustomerId = action.payload.freeTrialCustomerId

        },
        [openCloseFreeTrialEndDialog.rejected]: (state, action) => {
            state.freetrialEndOpen = false
            state.freeTrialCustomerId = ''

        },
        //------- free trial dialog taxHero -------------
        [openCloseTaxheroFreeTrialEndDialog.pending]: (state, action) => {

            state.taxherofreetrialEndOpen = true;
        },
        [openCloseTaxheroFreeTrialEndDialog.fulfilled]: (state, action) => {

            state.taxherofreetrialEndOpen = action.payload.taxherofreetrialEndOpen

        },
        [openCloseTaxheroFreeTrialEndDialog.rejected]: (state, action) => {

            state.taxherofreetrialEndOpen = false

        },
        //-----------------------------------------------
        [checkUserSubscriptionStatus.pending]: (state, action) => {

            state.growsubscriptionstatus = ''
            state.taxHerosubscriptionstatus = ''
        },
        [checkUserSubscriptionStatus.fulfilled]: (state, action) => {

            const growsubscriptionstatus = action?.payload?.growsubscription?.list[0]?.subscription?.status
            const taxHerosubscriptionstatus = action?.payload?.taxHerosubscription?.list[0]?.subscription?.status || "0"
            state.growsubscriptionstatus = growsubscriptionstatus
            state.taxHerosubscriptionstatus = taxHerosubscriptionstatus
        },
        [checkUserSubscriptionStatus.rejected]: (state, action) => {

            state.growsubscriptionstatus = ''
            state.taxHerosubscriptionstatus = ''

        },
        //------ subscription dialog ---------- openCloseSubscriptionPlanDialog
        [openCloseSubscriptionPlanDialog.pending]: (state, action) => {

            state.openCloseSubscriptionPlan = false

        },
        [openCloseSubscriptionPlanDialog.fulfilled]: (state, action) => {

            state.openCloseSubscriptionPlan = action.payload.openCloseSubscriptionPlan
        },
        [openCloseSubscriptionPlanDialog.rejected]: (state, action) => {

            state.openCloseSubscriptionPlan = false

        },
        //get taxHero PlanPrice
        [getChargebeePlan.pending]: (state, action) => {

            state.taxHeroPlanPrice = []

        },
        [getChargebeePlan.fulfilled]: (state, action) => {

            state.taxHeroPlanPrice = action?.payload?.prices
        },
        [getChargebeePlan.rejected]: (state, action) => {

            state.taxHeroPlanPrice = []

        },
    }


})


export default profileSlice.reducer