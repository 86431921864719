export default {
    parentBox: {
    //   position: 'absolute',
    //   top: '48px',
    //   bottom: '0px',
    //   left: '64px',
    //   right: '0px',
      

       

    display:'flex', 
    position: 'relative',
    margin:'0px auto 0px auto',
    width:'1030px',
    minWidth:'1030px',
    maxWidth:'1030px',
    minHeight:'100vh',
    

      // Responsive styles
      '@media (max-width: 768px)': {
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        padding: '10px',
       
      },
    },

    orgHeading:{
        position:'absolute',
        left:'0px',
       // left:'124px',
        top:'33px',
        fontFamily:'Avenir LT Std',
        fontStyle:'normal',
        fontWeight:'700',
        fontSize:'20px',
        lineHeight:'24px',
        color:'#24292E',
        zIndex:'1',
        '@media (max-width: 768px)':{
            position:'absolute',
            left:'0px',
            right:'0px',
            margin:'auto',
            height:'fit-content',
            width:'fit-content',
            top:'87px',
            fontFamily:'Avenir LT Std',
            fontStyle:'normal',
            fontWeight:'700',
            fontSize:'20px',
            lineHeight:'24px',
            color:'#24292E',
        }
    },

    subsciptionDay:{
        position:'absolute',
        left:'1007px',
        top:'22px',
        width:'fit-content',
        height:'24px',
        background:'#DDF4FF',
        borderRadius:'3px',
        color:'#0969DA',
        border:'none',
        fontFamily:'Segoe UI',
        fontStyle:'normal',
        fontWeight:'600',
        fontSize:'10px',
        lineHeight:'16px',
        '@media (max-width: 768px)':{

            position:'absolute',
            left:'0px',
            right:'0px',
            margin:'auto',
            width:'fit-content',
            top:'130px',
            height:'24px',
            background:'#DDF4FF',
            borderRadius:'3px',
            color:'#0969DA',
            border:'none',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontWeight:'600',
            fontSize:'10px',
            lineHeight:'16px',

        }
    },

    headerRightBox:{
        position: 'absolute',
        left: '1007px',
        top: '51px',
        display: 'flex',
        flexDirection: 'row',
        gap: '11px',
        heading: {
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#24292E',
            display:'flex',
            alignItems:'center'
        },
        button: {
            background: '#2155A4',
            borderRadius: '3px',
            width: '109px',
            height: '22px',
            minWidth:'109px',
            height:'22px',
            maxWidth:'109px',
            maxHeight:'22px',
            minHeight:'22px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '12px',
            lineHeight: '18px',
        },

        '@media (max-width: 768px)':{
            position:'absolute',
            left:'0px',
            right:'0px',
            top:'170px',
            margin:'auto',
            width:'fit-content',
            display: 'flex',
            flexDirection: 'row',
            gap: '11px',
            heading: {
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#24292E',
                display:'flex',
                alignItems:'center'
            },
            button: {
                background: '#2155A4',
                borderRadius: '3px',
                width: '109px',
                height: '22px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFFFFF',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '12px',
                lineHeight: '18px',
            },

        },

       
    },

    projectBox:{
        position:'absolute',
        left:'0px',
      //  left:'124px',
        top:'74px',
      //  right:'228px',
        display:'flex',
        flexDirection:'row',
        gap:'20px',
        height:'205px',

        '@media (max-width: 768px)':{
            position:'absolute',
            left:'0px',
            top:'206px',
            right:'0px',
            margin:'auto',
            display:'flex',
            flexDirection:'column',
            gap:'20px',
            height:'405px',
            width:'fit-content',
            
        }
    },
    projectBoxChild:{

         width:'328px',
         minWidth:'328px',
         maxWidth:'328px',
         height:'205px',
         display:'flex',
         flexDirection:'column',
         background:'#FFFFFF',
         border:'1px solid #D0D7DE',
         borderRadius:'6px',
        '@media (max-width: 768px)':{

            width:'328px',
            minWidth:'328px',
            height:'205px',
         

        }
    },

    projectBoxChildInnerBox1:{

        position:'relative',
        margin:'25px 0px 0px 24px',
        display:'flex',
        flexDirection:'row',
        '@media (max-width: 768px)':{

            position:'relative',
            margin:'25px 0px 0px 24px',
            display:'flex',
            flexDirection:'row'

        }

    },

    projectBoxChildInnerBox2:{
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        background: '#F0FDE8',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    projectBoxChildInnerBox3:{
        height: '40px',
        width: '40px',
        background: '#F0FDE8',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'6px',
        border:'1px solid rgba(27, 31, 36, 0.15)',
        marginTop:'-1px'
    }











  };