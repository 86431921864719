import React from "react"
import { useNavigate } from 'react-router-dom'
import {Box, Button, Heading, TextInput,FormControl,Text,Radio,Checkbox, Dialog } from "@primer/react"
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { getUserOrganizationById, getWorkplaceProject } from "../redux/features/spaceSlice";
import { getAllWorkplace } from "../redux/features/workplaceSlice";
import { accountSetupSuccessOpenClose } from "../redux/features/contactSlice";

const successImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/success.svg`    
 
export default function AccountSetupSuccess(){

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const setupSuccessOpenClose = useSelector((state)=> state.contactProfileData.setupSuccessOpenClose)   

    const [workplaceProjects,setworkplaceProjects] = React.useState([])

    const reloadProjects = (props) => {

        
        dispatch(getUserOrganizationById({
            userId:userId,
            userEmail:userEmail
         })).then((data)=>{

               setworkplaceProjects(data.payload?.data)
         })
        
    }

    const skipBtnClick = (props) => {


         
        dispatch(accountSetupSuccessOpenClose({
           setupSuccessOpenClose:false
        }))
         

    }

    React.useEffect(()=>{

       if(setupSuccessOpenClose===true){

             reloadProjects("")
           
       }

    },[setupSuccessOpenClose])


    return(


        <Dialog
        isOpen={setupSuccessOpenClose}
        sx={{
          height: '526px',
          maxHeight: '526px',
          maxWidth: '921px',
          minWidth: '921px',
          minHeight: '526px',
          width: '921px',
          marginTop:'91px'
        }}
        onDismiss={()=>{
            dispatch(accountSetupSuccessOpenClose({
                setupSuccessOpenClose:false
            }))
        }}
      >

        <Box
          
           sx={{
            // position:'fixed',
            // left:'0px',
            // right:'0px',
            // top:'91px',
            // margin:'auto',
            height:'526px',
            maxHeight:'526px',
            maxWidth:'921px',
            minWidth:'921px',
            minHeight:'526px',
            width:'921px',
            overflow:'auto',
            background:'#FFFFFF',
            border:'1px solid #D0D7DE',
            borderRadius:'3px'
           }}
    >

           <Box sx={{
            position:'absolute',
            top:'35px',
            left:'40px',
            right:'40px',
            height:'fit-content',
            display:'flex',
            flexDirection:'column',
            gap:'5px',
           
           }}>
           
              <Heading sx={{
                  fontFamily:'Segoe UI',
                  fontStyle:'normal',
                  fontWeight:'600',
                  fontSize:'20px',
                  lineHeight:'30px',
                  color:'#24292E',
                  letterSpacing:'0.3px'
              }}>
               Your account has been successfully set up.
               
              </Heading>
               

           </Box>

           

            <Box sx={{
                  height:'fit-content',
                  width:'fit-content',
                  position:'relative',
                  margin:'82px auto 0px auto'
            }}>
                <img src={successImg}  />
            </Box>

            <Box sx={{
                  height:'fit-content',
                  width: '660px',
                  minWidth:'660px',
                  maxWidth:'660px',
                  position: 'relative',
                  margin: '24px auto 0px auto',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center'
            }}>
                <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#24292E',
                textAlign:'center',     
            }}>
                You're all set up and ready to start managing your work more efficiently than ever. Streamline your
                workflow, delegate tasks, and monitor progress in real-time. Collaborate with ease
            </Text>
              
            </Box>

 

           <Box sx={{
            position: 'relative',
            margin: '41px auto 0px auto',
            height:'fit-content',
            width:'fit-content',
            display:'flex',
            flexDirection:'row'
           }}>
           
              <Button 
              
              variant="outline"
              sx={{
                  width:'250px',
                  height:'40px',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  background:'#0969DA',
                  borderRadius:'3px',
                  fontFamily:'Segoe UI',
                  fontStyle:'normal',
                  fontWeight:'600',
                  fontSize:'14px',
                  lineHeight:'20px', 
                  border:'none',
                  color:'#FFFFFF'
              }}
             
              onClick={() => {

                skipBtnClick("")
                localStorage.setItem("projectId", workplaceProjects[0]?.project_id)
                localStorage.setItem("projectName", workplaceProjects[0]?.project_name)
                localStorage.setItem("workplaceId", workplaceProjects[0]?.workplace_id)
                localStorage.setItem("projectCreatorId", workplaceProjects[0]?.creator_id)
                navigate('/space/' + workplaceProjects[0]?.project_name)

              }}
              
              >
              Go to workspace
              </Button>
             
           </Box>

       

    </Box>
    </Dialog>
    )

}
