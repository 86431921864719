import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"


export const rightSidebarOpenClose = createAsyncThunk("rightSidebarOpenClose", async (data) => {


    return ({ openClose: data.openClose })

})


export const getnotitificationData = createAsyncThunk("getnotitificationData", async (data) => {

    return ({ taskId: data.taskId })

})

export const postSheduleDemo = createAsyncThunk("postSheduleDemo", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "formData": data.formData
    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    //REACT_APP_API_URL
    const result = await fetch(`${process.env.REACT_APP_API_URL}/postScheduleDemo`, requestOptions)
        .then(response => response.json())

    return result

})





export const rightSidebarOpenCloseSlice = createSlice({

    name: 'rightSidebarOpenClose',
    initialState: {
        openClose: true,//false,
        loading: false,
        taskId: ""

    },
    extraReducers: {

        [rightSidebarOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [rightSidebarOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose

        },
        [rightSidebarOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [getnotitificationData.pending]: (state, action) => {
            state.taskId = ""

        },
        [getnotitificationData.fulfilled]: (state, action) => {
            state.taskId = action.payload.taskId

        },
        [getnotitificationData.rejected]: (state, action) => {
            state.taskId = ""
        }

    }


})


export default rightSidebarOpenCloseSlice.reducer

