import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';


export const createTaskOpenClose = createAsyncThunk("createTaskOpenClose", async (data) => {


    // return ({ openClose: data.openClose, expandWidth: data.expandWidth,status:data.status,taskId:data.taskId })
    return ({ openClose: data.openClose, expandWidth: true, status: data.status, taskId: data.taskId })

})

export const openCloseTaskExpansion = createAsyncThunk("openCloseTaskExpansion", async (data) => {

    return ({ openCloseExpansion: data.openCloseExpansion, expansionTaskId: data.expansionTaskId })
})


export const createTaskDialogOpenClose = createAsyncThunk("createTaskDialogOpenClose", async (data) => {

    return ({ taskDialogOpenClose: data.taskDialogOpenClose })
})

export const createBulkTaskDialogOpenClose = createAsyncThunk("createBulkTaskDialogOpenClose", async (data) => {

    return ({ bulktaskDialogOpenClose: data.bulktaskDialogOpenClose })
})

export const createRecurringBulkTaskDialogOpenClose = createAsyncThunk("createRecurringBulkTaskDialogOpenClose", async (data) => {

    return ({ recurringbulktaskDialogOpenClose: data.recurringbulktaskDialogOpenClose, recurringCompId: data.recurringCompId })
})


export const subtaskCompletedDialogOpenClose = createAsyncThunk("subtaskCompletedDialogOpenClose", async (data) => {

    return ({ subtaskCompletedOpenClose: data.subtaskCompletedOpenClose })
})





export const addContactOpenClose = createAsyncThunk("addContactOpenClose", async (data) => {

    return ({
        addContactisOpen: data.addContactisOpen
    })
})

export const getIssueConatctData = createAsyncThunk("getIssueConatctData", async (data) => {


    const contactData = {

        companyAvatar: data.companyAvatar,
        selectedContact: data.selectedContact,
        contactId: data.contactId
    }

    return ({ contactData: contactData })
})

export const postcreateIssue = createAsyncThunk("postcreateIssue", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const description = {
        "blocks": [
            {
                "key": "" + Math.floor(Math.random() * 900000) + 100000 + "",
                "data": {},
                "text": "" + data.description + "",
                "type": "unstyled",
                "depth": 0,
                "entityRanges": [],
                "inlineStyleRanges": []
            }
        ],
        "entityMap": {}
    }

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "taskName": data.taskName,
        "workflowId": data.workflowId,
        "taskPriority": data.taskPriority,
        "description": description,//convertToRaw(EditorState.createEmpty().getCurrentContent()),
        "assigneId": data.assigneId || "",
        "contact": data.contact || "",
        "dueDate": data.dueDate || "",
        "complianceId": data.complianceId || ""
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/postTask`, requestOptions)
        .then(response => response.json())

    return result


})


export const postSubtaskPrimary = createAsyncThunk("postSubtaskPrimary", async (data) => {

    //postSubtaskPrimary
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const description = {
        "blocks": [
            {
                "key": "" + Math.floor(Math.random() * 900000) + 100000 + "",
                "data": {},
                "text": "" + data.description + "",
                "type": "unstyled",
                "depth": 0,
                "entityRanges": [],
                "inlineStyleRanges": []
            }
        ],
        "entityMap": {}
    }

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "taskId": data.taskId,
        "taskName": data.taskName,
        "workflowId": data.workflowId,
        "taskPriority": data.taskPriority,
        "description": description,
        "assigneId": data.assigneId || "",
        "contact": data.contact || "",
        "dueDate": data.dueDate || "",
        "complianceId": data.complianceId || ""
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/postSubtaskPrimary`, requestOptions)
        .then(response => response.json())

    return result

})


export const postRecurringTask = createAsyncThunk("postRecurringTask", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')
    const description = {
        "blocks": [
            {
                "key": "" + Math.floor(Math.random() * 900000) + 100000 + "",
                "data": {},
                "text": "" + data.description + "",
                "type": "unstyled",
                "depth": 0,
                "entityRanges": [],
                "inlineStyleRanges": []
            }
        ],
        "entityMap": {}
    }

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "taskName": data.taskName,
        "workflowId": data.workflowId,
        "taskPriority": data.taskPriority,
        "description": description,
        "assigneId": data.assigneId || "",
        "contact": data.contact || "",
        "dueDate": data.dueDate || "",
        "complianceId": data.complianceId || ""
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/postRecurringTask`, requestOptions)
        .then(response => response.json())

    return result


})

export const duplicateTask = createAsyncThunk("duplicateTask", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "duplicateTaskId": data.duplicateTaskId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result


})

export const markasVerified = createAsyncThunk("markasVerified", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "completedStatus": data.completedStatus,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})



export const deleteTaskById = createAsyncThunk("deleteTask", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteTask`, requestOptions)
        .then(response => response.json())

    return result

})


export const getSingleSpaceTasks = createAsyncThunk("getSingleSpaceTasks", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getTaskByTaskId`, requestOptions)
        .then(response => response.json())

    return result


})


export const getTaskExpansionDetails = createAsyncThunk("getTaskExpansionDetails", async (data) => {


    const url = `${process.env.REACT_APP_API_URL}/getTaskExpansionDetails?userId=${data.userId}&userEmail=${data.userEmail}&taskId=${data.taskId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result


})

export const getZohoInvoice = createAsyncThunk("getZohoInvoice", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "invoiceNumber": data.invoiceNumber,
        "zohoToken": data.zohoToken,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getZohoInvoice`, requestOptions)
        .then(response => response.json())

    return result



})

export const getAllSpaceTasks = createAsyncThunk("getAllSpaceTasks", async (data) => {



    // var myHeaders = new Headers();
    // myHeaders.append('Content-type', 'application/json')


    // const urlencoded = JSON.stringify({
    //     "userId": data.userId,
    //     "userEmail": data.userEmail,
    //     "projectId": data.projectId,
    // })

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: urlencoded,
    //     redirect: 'follow'
    // };

    // const result = await fetch("https://go.deskiplatforms.com/getTaskByProject", requestOptions)
    //     .then(response => response.json())

    // return result

    const url = new URL(`${process.env.REACT_APP_API_URL}/getTaskByProject`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);

    const result = await fetch(url).then(response => response.json())

    return result

})


export const getSubtaskPrimary = createAsyncThunk("getSubtaskPrimary", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getSubtaskPrimary`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId, taskId: data.taskId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const updateTaskName = createAsyncThunk("updateTaskName", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskName": data.taskName,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateWorkflowId = createAsyncThunk("updateWorkflowId", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "workflowId": data.workflowId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateTaskDueDate = createAsyncThunk("updateTaskDueDate", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "dueDate": data.dueDate,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateTaskPriority = createAsyncThunk("updateTaskPriority", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "priority": data.priority,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateTaskContact = createAsyncThunk("updateTaskContact", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskContact": data.taskContact,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateTaskRoadMap = createAsyncThunk("updateTaskRoadMap", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "roadMap": data.roadMap,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateTaskAssigne = createAsyncThunk("updateTaskAssigne", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "assigneId": data.assigneId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateTaskLabels = createAsyncThunk("updateTaskLabels", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "labelName": data.labelName,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const removeTaskLabels = createAsyncThunk("removeTaskLabels", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "labelId": data.labelId,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateTaskActivity = createAsyncThunk("updateTaskActivity", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskActivity": data.taskActivity,
        "updateField": "Activity"
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteTaskActivity = createAsyncThunk("deleteTaskActivity", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "deleteActivityId": data.deleteActivityId,
        "updateField": "DeleteActivity"
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const addTaskComments = createAsyncThunk("addTaskComments", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskComment": data.taskComment,
        "updateField": "Comments"
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteTaskComments = createAsyncThunk("deleteTaskComments", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "deleteCommentId": data.deleteCommentId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const addProjectRoadmap = createAsyncThunk("addProjectRoadmap", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "roadMapData": data.roadMapData,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateTaskDescription = createAsyncThunk("updateTaskDescription", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskDescription": data.taskDescription,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateTaskSubtask = createAsyncThunk("updateTaskSubtask", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "subtaskData": data.subtaskData,
        "updateField": data.updateField
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateTaskSortOrder = createAsyncThunk("updateTaskSortOrder", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "sortedTask": data.sortedTask,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())

    return result



})

export const updateTaskAttachments = createAsyncThunk("updateTaskAttachments", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId,
        "taskAttachments": data.taskAttachments,
        "deleteTaskAttachmentId": data.deleteTaskAttachmentId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateTaskAttachments`, requestOptions)
        .then(response => response.json())

    return result


})


export const postContactDirectory = createAsyncThunk("postContactDirectory", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactType": data.contactType,
        "contactData": data.contactData,
        "directorsInfo": data.directorsInfo,
        "categories": data.categories,
        "compliances": data.compliances,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postContactDirectory`, requestOptions)
        .then(response => response.json())

    return result


})

export const postContactDirectoryBulk = createAsyncThunk("postContactDirectoryBulk", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactType": data.contactType,
        "contactData": data.contactData,
        "directorsInfo": data.directorsInfo,
        "categories": data.categories,
        "compliances": data.compliances,
        "projectId": data.projectId,
        "bulkCompanyData": data.bulkCompanyData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postContactDirectoryBulk`, requestOptions)
        .then(response => response.json())

    return result

})

export const postContactDirectoryProprietaryBulk = createAsyncThunk("postContactDirectoryProprietaryBulk", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactType": data.contactType,
        "contactData": data.contactData,
        "directorsInfo": data.directorsInfo,
        "categories": data.categories,
        "compliances": data.compliances,
        "projectId": data.projectId,
        "bulkCompanyData": data.bulkCompanyData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postContactDirectoryProprietaryBulk`, requestOptions)
        .then(response => response.json())

    return result



})


export const getDropboxAuth = createAsyncThunk("getDropboxAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getDropboxAuth`, requestOptions)
        .then(response => response.json())

    return result


})

export const getGoogleDriveAuth = createAsyncThunk("getGoogleDriveAuth", async (data) => {

    //getGoogleDriveAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getGoogleDriveAuth`, requestOptions)
        .then(response => response.json())

    return result
})


export const getGoogleMailAuth = createAsyncThunk("getGoogleMailAuth", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getGoogleMailAuth`, requestOptions)
        .then(response => response.json())

    return result


})

export const getGoogleCalanderAuth = createAsyncThunk("getGoogleCalanderAuth", async (data) => {


    //getGoogleCalanderAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getGoogleCalanderAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const getGoogleAuth = createAsyncThunk("getGoogleAuth", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getGoogleAuth`, requestOptions)
        .then(response => response.json())

    return result


})


export const getZohoAuth = createAsyncThunk("getZohoAuth", async (data) => {

    //getZohoAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getZohoAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteDropboxAuth = createAsyncThunk("deleteDropboxAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteDropboxAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteGoogleDriveAuth = createAsyncThunk("deleteGoogleDriveAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteGoogleDriveAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteGoogleMailAuth = createAsyncThunk("deleteGoogleMailAuth", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteGoogleMailAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteGoogleCalanderAuth = createAsyncThunk("deleteGoogleCalanderAuth", async (data) => {

    //deleteGoogleCalanderAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteGoogleCalanderAuth`, requestOptions)
        .then(response => response.json())

    return result


})

export const deleteGoogleMeetAuth = createAsyncThunk("deleteGoogleMeetAuth", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteGoogleMeetAuth`, requestOptions)
        .then(response => response.json())

    return result

})


export const deleteZohoAuth = createAsyncThunk("deleteZohoAuth", async (data) => {

    //deleteZohoAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteZohoAuth`, requestOptions)
        .then(response => response.json())

    return result

})


export const postDropboxAuth = createAsyncThunk("postDropboxAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postDropboxAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const postGoogleDriveAuth = createAsyncThunk("postGoogleDriveAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGoogleDriveAuth`, requestOptions)
        .then(response => response.json())

    return result


})

export const postGoogleMailAuth = createAsyncThunk("postGoogleMailAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGoogleMailAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const postGoogleCalanderAuth = createAsyncThunk("postGoogleCalanderAuth", async (data) => {

    //postGoogleCalanderAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGoogleCalanderAuth`, requestOptions)
        .then(response => response.json())

    return result

})

export const postGoogleAuth = createAsyncThunk("postGoogleAuth", async (data) => {


    //postGoogleAuth
    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postGoogleAuth`, requestOptions)
        .then(response => response.json())

    return result



})

export const postZohoAuth = createAsyncThunk("postZohoAuth", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "authCode": data.authCode,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postZohoAuth`, requestOptions)
        .then(response => response.json())

    return result



})


export const postZohoInvoice = createAsyncThunk("postZohoInvoice", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "zohoToken": data.zohoToken,
        "customerName": data.customerName,
        "itemName": data.itemName,
        "invoiceId": data.invoiceId,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postZohoInvoice`, requestOptions)
        .then(response => response.json())

    return result


})

export const getContactDirectory = createAsyncThunk("getContactDirectory", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectCreatorId": data.projectCreatorId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getContactDirectory`, requestOptions)
        .then(response => response.json())

    return result


})

export const getTaskProjectHistory = createAsyncThunk("getTaskProjectHistory", async (data) => {


    const url = `${process.env.REACT_APP_API_URL}/getProjecthistory?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result


})

export const reloadTaskTrigger = createAsyncThunk("reloadTaskTrigger", async (data) => {

    return ({ taskTrigger: data.taskTrigger })

})




export const createTaskOpenCloseSlice = createSlice({

    name: 'createTaskOpenClose',
    initialState: {
        bulktaskDialogOpenClose: false,
        recurringbulktaskDialogOpenClose: false,
        recurringCompId: '',
        taskDialogOpenClose: false,
        openCloseExpansion: false,
        openClose: false,
        expandWidth: false,
        addContactisOpen: false,
        contactData: {
            companyAvatar: '',
            selectedContact: 'Add contact',
            contactId: ''
        },
        loading: false,
        status: "",
        taskId: "",
        expansionTaskId: "",
        taskTrigger: false,
        taskDeleteTrigger: false,
        taskDuplicateTrigger: false,
        taskUpdateTrigger: false,
        isTaskLoading: false,
        spaceTask: [],
        createContactTrigger: false,
        subtaskCompletedOpenClose: false,
        taskRecurringTrigger: false
    },
    extraReducers: {

        [createTaskDialogOpenClose.pending]: (state, action) => {

            state.taskDialogOpenClose = false

        },
        [createTaskDialogOpenClose.fulfilled]: (state, action) => {

            state.taskDialogOpenClose = action.payload.taskDialogOpenClose

        },
        [createTaskDialogOpenClose.rejected]: (state, action) => {

            state.taskDialogOpenClose = false

        },
        [createBulkTaskDialogOpenClose.pending]: (state, action) => {

            state.bulktaskDialogOpenClose = false

        },
        [createBulkTaskDialogOpenClose.fulfilled]: (state, action) => {

            state.bulktaskDialogOpenClose = action.payload.bulktaskDialogOpenClose

        },
        [createBulkTaskDialogOpenClose.rejected]: (state, action) => {

            state.bulktaskDialogOpenClose = false

        },
        //--------recurring bulk task dialog  recurringbulktaskDialogOpenClose
        [createRecurringBulkTaskDialogOpenClose.pending]: (state, action) => {

            state.recurringbulktaskDialogOpenClose = false
            state.recurringCompId = ''

        },
        [createRecurringBulkTaskDialogOpenClose.fulfilled]: (state, action) => {

            state.recurringbulktaskDialogOpenClose = action.payload.recurringbulktaskDialogOpenClose
            state.recurringCompId = action.payload.recurringCompId
        },
        [createRecurringBulkTaskDialogOpenClose.rejected]: (state, action) => {

            state.recurringbulktaskDialogOpenClose = false
            state.recurringCompId = ''
        },

        [createTaskOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false
            state.expandWidth = false
            state.taskTrigger = false
        },
        [createTaskOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose
            state.expandWidth = action.payload.expandWidth
            state.status = action.payload.status
            state.taskId = action.payload.taskId
            state.taskTrigger = true
        },
        [createTaskOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
            state.expandWidth = false
            state.taskTrigger = false
        },
        [addContactOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.addContactisOpen = false

        },
        [addContactOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.addContactisOpen = action.payload.addContactisOpen
        },
        [addContactOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.addContactisOpen = false
        },
        [getIssueConatctData.pending]: (state, action) => {
            state.loading = true;


        },
        [getIssueConatctData.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.contactData = action.payload.contactData
        },
        [getIssueConatctData.rejected]: (state, action) => {
            state.loading = false

        },
        [getAllSpaceTasks.pending]: (state, action) => {
            state.loading = true;


        },
        [getAllSpaceTasks.fulfilled]: (state, action) => {

            state.loading = action.payload.loading;
            state.spaceTask = [...action.payload?.data]
            state.taskTrigger = true

        },
        [getAllSpaceTasks.rejected]: (state, action) => {
            state.loading = false

        },
        [deleteTaskById.pending]: (state, action) => {
            state.taskDeleteTrigger = false


        },
        [deleteTaskById.fulfilled]: (state, action) => {

            state.taskDeleteTrigger = true
        },
        [deleteTaskById.rejected]: (state, action) => {
            state.taskDeleteTrigger = false

        },
        [duplicateTask.pending]: (state, action) => {
            state.taskDuplicateTrigger = false


        },
        [duplicateTask.fulfilled]: (state, action) => {

            state.taskDuplicateTrigger = true
        },
        [duplicateTask.rejected]: (state, action) => {
            state.taskDuplicateTrigger = false

        },
        [updateTaskLabels.pending]: (state, action) => {
            state.taskUpdateTrigger = false


        },
        [updateTaskLabels.fulfilled]: (state, action) => {

            state.taskUpdateTrigger = true
        },
        [updateTaskLabels.rejected]: (state, action) => {
            state.taskUpdateTrigger = false

        },
        [getSingleSpaceTasks.pending]: (state, action) => {
            state.isTaskLoading = true


        },
        [getSingleSpaceTasks.fulfilled]: (state, action) => {

            state.isTaskLoading = false
        },
        [getSingleSpaceTasks.rejected]: (state, action) => {
            state.isTaskLoading = false

        },
        // reload task trigger
        [reloadTaskTrigger.pending]: (state, action) => {
            state.taskTrigger = false


        },
        [reloadTaskTrigger.fulfilled]: (state, action) => {

            state.taskTrigger = action.payload.taskTrigger
        },
        [reloadTaskTrigger.rejected]: (state, action) => {
            state.taskTrigger = false

        },
        [openCloseTaskExpansion.pending]: (state, action) => {
            state.loading = true;
            state.openCloseExpansion = false
            state.expansionTaskId = ""
        },
        [openCloseTaskExpansion.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openCloseExpansion = action.payload.openCloseExpansion
            state.expansionTaskId = action.payload.expansionTaskId
        },
        [openCloseTaskExpansion.rejected]: (state, action) => {
            state.loading = false
            state.openCloseExpansion = false
            state.expansionTaskId = ""
        },
        //post task trigger
        [postcreateIssue.pending]: (state, action) => {

            state.taskTrigger = false

        },
        [postcreateIssue.fulfilled]: (state, action) => {

            state.taskTrigger = true

        },
        [postcreateIssue.rejected]: (state, action) => {

            state.taskTrigger = false

        },
        //post recuring task trigger   taskRecurringTrigger
        [postRecurringTask.pending]: (state, action) => {

            state.taskRecurringTrigger = false

        },
        [postRecurringTask.fulfilled]: (state, action) => {

            state.taskRecurringTrigger = true

        },
        [postRecurringTask.rejected]: (state, action) => {

            state.taskRecurringTrigger = false

        },

        //post compnay add trigger 
        [postContactDirectory.pending]: (state, action) => {

            state.createContactTrigger = false

        },
        [postContactDirectory.fulfilled]: (state, action) => {

            state.createContactTrigger = true

        },
        [postContactDirectory.rejected]: (state, action) => {

            state.createContactTrigger = false

        },
        //subtask completed dailog
        [subtaskCompletedDialogOpenClose.pending]: (state, action) => {

            state.subtaskCompletedOpenClose = false

        },
        [subtaskCompletedDialogOpenClose.fulfilled]: (state, action) => {

            state.subtaskCompletedOpenClose = action.payload.subtaskCompletedOpenClose

        },
        [subtaskCompletedDialogOpenClose.rejected]: (state, action) => {

            state.subtaskCompletedOpenClose = false

        }

    }


})


export default createTaskOpenCloseSlice.reducer

