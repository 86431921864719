import React from "react"
import { StackIcon, ZapIcon, NorthStarIcon, ChevronRightIcon, PencilIcon, ArchiveIcon, TrashIcon, FeedPersonIcon } from "@primer/octicons-react"
//MailIcon
import { PlusIcon, CalendarIcon, EnvelopeClosedIcon, LightningBoltIcon, FilePlusIcon, ChevronDownIcon, MixerHorizontalIcon, CaretSortIcon, GearIcon, DownloadIcon } from "@radix-ui/react-icons"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import { Box, ActionList, Checkbox, Text, IconButton, StyledOcticon, Button, ActionMenu, Label, Tooltip, Avatar } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../Space/EmptyData"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch, useSelector } from "react-redux"
import { getDataByTaskId, getFeedData } from "../redux/features/feedSlice"
import SpaceTheme from "../Space/SpaceTheme"
import CreateTask from "../Space/CreateTask";
import { createTaskOpenClose, openCloseTaskExpansion } from "../redux/features/taskSlice";
import { getAllWorksByProjectId, getHomeStatics, getProjectServices, getSingleWorkplaceProject, getUserOrganizationById } from "../redux/features/spaceSlice";
import TaskExpansion from "../Space/TaskExpansion";

const normalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/normal.svg"
const mediumIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/medium.svg"
const highIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/high.svg"
const criticalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/critical.svg"
const lowIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/low.svg"

const loadingToast = Toaster.create({ position: Position.TOP })

function Feed(props) {

    const dispatch = useDispatch()
    const feedData = useSelector((state) => state.feedData)
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)  
    const headerData = useSelector((state) => state.headerData)
    const taskTrigger = useSelector((state) => state.taskData.taskTrigger)
    const taskDuplicateTrigger = useSelector((state) => state.taskData.taskDuplicateTrigger)
    const taskDeleteTrigger = useSelector((state)=> state.taskData.taskDeleteTrigger)
    

    const [filterType,setfilterType] = React.useState(localStorage.getItem("filterType"))
    const [showTaskComponent, setshowTaskComponent] = React.useState(false)
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [showLoadingToast, setshowLoadingToast] = React.useState(false)

    const [allTasks, setallTasks] = React.useState([...feedData?.tasks])
     
    const [compliances, setcompliances] = React.useState([])
    const [priorityArray, setpriorityArray] = React.useState([
        { icon: normalIcon, name: 'Normal' },
        { icon: mediumIcon, name: 'Medium' },
        { icon: highIcon, name: 'High' },
        { icon: criticalIcon, name: 'Critical' },
        { icon: lowIcon, name: 'Low' }
    ])


    const handleOpenTask = (props) => {

        setshowTaskComponent(true)
        const taskId = props.task_id


        dispatch(getDataByTaskId({
            userId: userId,
            userEmail: userEmail,
            taskId: taskId,
        })).then((data) => {

            const taskId = data?.payload?.data?.taskId
            const projectId = data?.payload?.data?.projectId
            const workplaceId = data?.payload?.data?.workplaceId
            dispatch(getSingleWorkplaceProject({
                projectId: projectId
            })).then((data) => {

            })



            localStorage.setItem("projectId", projectId)
            localStorage.setItem("workplaceId", workplaceId)
            showCreateTaskPanel(taskId)




        })



    }

    const showCreateTaskPanel = (props) => {

        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props
        })).then((data) => {

            setshowLoadingToast(false)
            loadingToast.clear()
            localStorage.removeItem("notificationData")

        })

    }

    const getAllTaskFromDb = (props) => {

        dispatch(getAllWorksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: props
        })).then((data) => {

            const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {

                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
            })
            setallTasks(sortedTaskArray)

        })

    }

    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: props
        })).then((data) => {

            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)

        })

    }

    
    React.useEffect(() => {

         
        
       if(filterType==="Clear"){

           dispatch(getUserOrganizationById({
               userId: userId,
               userEmail: userEmail
           })).then((data) => {
               console.log("UserDefual org", data.payload)
               const projectId = data?.payload?.data[0]?.project_id
               getAllTaskFromDb(projectId)
               getAllCategories(projectId)

           })
             
        }else if(filterType==="OpenTask"){


            dispatch(getUserOrganizationById({
                userId: userId,
                userEmail: userEmail
            })).then((data) => {
                console.log("UserDefual org", data.payload)
                const projectId = data?.payload?.data[0]?.project_id
                 
                dispatch(getAllWorksByProjectId({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: projectId
                })).then((data) => {
        
                    const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
        
                        return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                    })



                    dispatch(getHomeStatics({
                        userId: userId,
                        userEmail: userEmail
                    })).then((data) => {

                        const openTaskIds = [...data.payload.openTaskIds]
                        const filteredArray = sortedTaskArray.filter(item => {
                            return openTaskIds.some(filterItem => filterItem.task_id === item.task_id);
                        });
                        console.log("filteredArray.length",filteredArray.length)
                        setallTasks(filteredArray)
                        
                    })



                    
        
                })


 
            })
            
          



        }else if(filterType==="DueTask"){

            dispatch(getUserOrganizationById({
                userId: userId,
                userEmail: userEmail
            })).then((data) => {
                console.log("UserDefual org", data.payload)
                const projectId = data?.payload?.data[0]?.project_id
                 
                dispatch(getAllWorksByProjectId({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: projectId
                })).then((data) => {
        
                    const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
        
                        return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                    })

                    dispatch(getHomeStatics({
                        userId: userId,
                        userEmail: userEmail
                    })).then((data) => {

                        const dueTaskIds = [...data.payload.dueTaskIds]
                        const filteredArray = sortedTaskArray.filter(item => {
                            return dueTaskIds.some(filterItem => filterItem.task_id === item.task_id);
                        });
                        console.log("filteredArray.length", filteredArray.length)
                        setallTasks(filteredArray)

                    })



                    
        
                })


 
            })

        }
       
       
       
    }, [filterType]);

    React.useEffect(()=>{

             if(taskTrigger){

                if(filterType==="Clear"){

                    dispatch(getUserOrganizationById({
                        userId: userId,
                        userEmail: userEmail
                    })).then((data) => {
                        console.log("UserDefual org", data.payload)
                        const projectId = data?.payload?.data[0]?.project_id
                        getAllTaskFromDb(projectId)
                        getAllCategories(projectId)
         
                    })
                      
                 }else if(filterType==="OpenTask"){
         
         
                     dispatch(getUserOrganizationById({
                         userId: userId,
                         userEmail: userEmail
                     })).then((data) => {
                         console.log("UserDefual org", data.payload)
                         const projectId = data?.payload?.data[0]?.project_id
                          
                         dispatch(getAllWorksByProjectId({
                             userId: userId,
                             userEmail: userEmail,
                             projectId: projectId
                         })).then((data) => {
                 
                             const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                 
                                 return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                             })
         
         
         
                             dispatch(getHomeStatics({
                                 userId: userId,
                                 userEmail: userEmail
                             })).then((data) => {
         
                                 const openTaskIds = [...data.payload.openTaskIds]
                                 const filteredArray = sortedTaskArray.filter(item => {
                                     return openTaskIds.some(filterItem => filterItem.task_id === item.task_id);
                                 });
                                 console.log("filteredArray.length",filteredArray.length)
                                 setallTasks(filteredArray)
                                 
                             })
         
         
         
                             
                 
                         })
         
         
          
                     })
                     
                   
         
         
         
                 }else if(filterType==="DueTask"){
         
                     dispatch(getUserOrganizationById({
                         userId: userId,
                         userEmail: userEmail
                     })).then((data) => {
                         console.log("UserDefual org", data.payload)
                         const projectId = data?.payload?.data[0]?.project_id
                          
                         dispatch(getAllWorksByProjectId({
                             userId: userId,
                             userEmail: userEmail,
                             projectId: projectId
                         })).then((data) => {
                 
                             const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                 
                                 return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                             })
         
         
         
                             dispatch(getHomeStatics({
                                 userId: userId,
                                 userEmail: userEmail
                             })).then((data) => {
         
                                 const dueTaskIds = [...data.payload.dueTaskIds]
                                 const filteredArray = sortedTaskArray.filter(item => {
                                     return dueTaskIds.some(filterItem => filterItem.task_id === item.task_id);
                                 });
                                 console.log("filteredArray.length", filteredArray.length)
                                 setallTasks(filteredArray)
         
                             })
         
         
         
                             
                 
                         })
         
         
          
                     })
         
                 }
                   
             }else{

             }


    },[taskTrigger,taskDuplicateTrigger,taskDeleteTrigger])


    React.useEffect(() => {


        if (localStorage.getItem("notificationData") !== null) {



            setshowLoadingToast(true)
            if (showLoadingToast === true) {



                loadingToast.show({

                    isCloseButtonShown: false,
                    icon: "hand",
                    message: "Hold tight, we're getting things set up for you.",
                    intent: Intent.PRIMARY
                });


                const notificationData = JSON.parse(localStorage.getItem("notificationData"))
                const taskId = notificationData.n_task_id
                const path = notificationData.path

                handleOpenTask({ task_id: taskId })


            }



        }





    }, [localStorage.getItem("notificationData"), showLoadingToast])



    React.useState(() => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {
            console.log("UserDefual org", data.payload)
            const projectId = data?.payload?.data[0]?.project_id
            getAllTaskFromDb(projectId)
            getAllCategories(projectId)

        })


        // dispatch(getFeedData({
        //     userId: userId,
        //     userEmail: userEmail
        // })).then((data) => {

        //     setallTasks(data.payload.data)

        // })


    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>Feed - deski</title>
            </Helmet>
            <Header/>
            <SideNav/>

            <Box sx={{
                position: 'absolute',
                left: '64px',
                right: '0px',
                top: '48px',
                bottom: '0px',
                margin: 'auto',
                background: 'white',
            }}>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '374px', minWidth: '374px', height: '32px', position: 'absolute', left: '20px', top: '12px', fontFamily: 'Segoe UI' }}>

                    <Box sx={{
                        width: '192px',
                        height: '32px',
                        border: 'none',
                        background: '#fdfdfd',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        borderRadius: '8px',
                        boxShadow: '0 0 0 1px rgba(204,204,204,0), 0px 0px 0px 1px rgba(0,0,0,0.04),0px 1px 4px 0px rgba(0,0,0,0.12)'
                    }}>
                        <Button
                            leadingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', marginRight: '-2px' }}><FilePlusIcon size={12} /></Box>}
                            sx={{
                                width: '192px',
                                height: '32px',
                                border: 'none',
                                background: '#fdfdfd',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                borderRadius: '8px',
                                color: '#24292E',
                            }}
                            trailingIcon={() => <Box sx={{ display: 'flex', marginRight: '8px' }}><ChevronDownIcon size={12} /></Box>}
                        >Recently Contacted</Button>
                    </Box>
                    <Box sx={{
                        width: '192px',
                        height: '32px',
                        border: 'none',
                        background: '#fdfdfd',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        borderRadius: '8px',
                        boxShadow: '0 0 0 1px rgba(204,204,204,0), 0px 0px 0px 1px rgba(0,0,0,0.04),0px 1px 4px 0px rgba(0,0,0,0.12)'
                    }}>
                        <Button
                            leadingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', marginRight: '-2px' }}><MixerHorizontalIcon size={12} /></Box>}
                            sx={{
                                width: '72px',
                                height: '32px',
                                border: 'none',
                                background: '#fdfdfd',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                borderRadius: '8px',
                                color: '#24292E',
                            }}>Filter</Button>
                    </Box>
                    <Box sx={{
                        width: '192px',
                        height: '32px',
                        border: 'none',
                        background: '#fdfdfd',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        borderRadius: '8px',
                        boxShadow: '0 0 0 1px rgba(204,204,204,0), 0px 0px 0px 1px rgba(0,0,0,0.04),0px 1px 4px 0px rgba(0,0,0,0.12)'
                    }}>
                        <Button
                            leadingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', marginRight: '-2px' }}><CaretSortIcon size={12} /></Box>}
                            sx={{
                                width: '86px',
                                height: '32px',
                                border: 'none',
                                background: '#fdfdfd',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                borderRadius: '8px',
                                color: '#24292E',
                            }}
                            trailingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', alignItems: 'center', justifyContent: 'center' }}>

                                <Label sx={{ bg: '#DDF4FF', border: 'none', color: '#0969da', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '6px' }}>
                                    1
                                </Label>
                            </Box>}
                        >Sort</Button>

                       

                    </Box>
                  
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', width: '237px', minWidth: '237px', height: '32px', position: 'absolute', right: '20px', top: '12px', fontFamily: 'Segoe UI' }}>

                    <Box sx={{
                        width: '127px',
                        height: '32px',
                        border: 'none',
                        background: '#fdfdfd',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        borderRadius: '8px',
                        boxShadow: '0 0 0 1px rgba(204,204,204,0), 0px 0px 0px 1px rgba(0,0,0,0.04),0px 1px 4px 0px rgba(0,0,0,0.12)'
                    }}
                    onClick={()=>{
                        
                          localStorage.setItem("filterType","Clear")
                          setfilterType("Clear")
                    }}
                    >
                        <Button
                            leadingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', marginRight: '-2px' }}><GearIcon size={12} /></Box>}
                            sx={{
                                width: '127px',
                                height: '32px',
                                border: 'none',
                                background: '#fdfdfd',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                borderRadius: '8px',
                                color: '#24292E',
                            }}
                           
                        >View Settings</Button>
                    </Box>
                    <Box sx={{
                        width: '98px',
                        height: '32px',
                        border: 'none',
                        background: '#fdfdfd',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        borderRadius: '8px',
                        boxShadow: '0 0 0 1px rgba(204,204,204,0), 0px 0px 0px 1px rgba(0,0,0,0.04),0px 1px 4px 0px rgba(0,0,0,0.12)'
                    }}>
                        <Button
                            leadingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px', marginRight: '-2px' }}><DownloadIcon size={12} /></Box>}
                            sx={{
                                width: '98px',
                                height: '32px',
                                border: 'none',
                                background: '#fdfdfd',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                borderRadius: '8px',
                                color: '#24292E',
                            }}
                            trailingIcon={() => <Box sx={{ display: 'flex', marginLeft: '-8px' }}><ChevronDownIcon size={12} /></Box>}
                        >Export</Button>
                    </Box>

                </Box>

                <ActionList sx={{
                    overflow: 'scroll',
                    background: 'white',
                    position: 'absolute',
                    left: '20px',
                    right: '0px',
                    top: '56px',
                    bottom: '0px',
                    margin: 'auto',
                }}>
                    <Box sx={{ display: 'flex', margin: '0px', fontFamily: 'Segoe UI', fontSize: '14px' }}>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderLeft: 0, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', alignItems: 'center', gap: '4px' }}>

                            <Checkbox />
                            <Text sx={{ fontWeight: '600', color: '#24292E', lineHeight: '20px' }}>Task</Text>
                            <IconButton size="small" sx={{ background: 'white', border: 'none', position: 'relative', margin: '0px 10px 0px auto', height: '24px', width: '24px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={() => <Box sx={{ display: 'flex' }}><PlusIcon size={12} /></Box>} />

                        </Box>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderLeft: 1, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>

                            <Box sx={{ marginLeft: '6px', height: '24px', width: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <LightningBoltIcon size={12} />
                            </Box>
                            <Text sx={{ fontWeight: '600', color: '#24292E', lineHeight: '20px', marginLeft: '-3px' }}>Compliance</Text>
                            <Box sx={{ position: 'relative', margin: '0px 6px 0px auto', height: '24px', width: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <StyledOcticon icon={NorthStarIcon} size={12} color="#a37bfa" />
                            </Box>
                        </Box>
                        <Box sx={{ borderBottom: 1, borderTop: 1, borderLeft: 1, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ marginLeft: '6px', height: '24px', width: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <EnvelopeClosedIcon size={12} />
                            </Box>
                            <Text sx={{ fontWeight: '600', color: '#24292E', lineHeight: '20px', marginLeft: '2px' }}>Client</Text>



                        </Box>
                        <Box sx={{
                            borderBottom: 1,
                            borderTop: 1,
                            borderLeft: 1,
                            borderRight: 0,
                            borderStyle: 'solid',
                            borderColor: '#e7e7e7',
                            height: '37px',
                            width: '288px',
                            minWidth: '288px',
                            maxWidth: '288px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>


                            <Box sx={{
                                marginLeft: '6px',
                                height: '24px',
                                width: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <CalendarIcon size={12} />
                            </Box>
                            <Text
                                sx={{
                                    fontWeight: '600',
                                    color: '#24292E',
                                    lineHeight: '20px'
                                }}>Assignee </Text>





                        </Box>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderTop: 1,
                                borderLeft: 1,
                                borderRight: 0,
                                borderStyle: 'solid',
                                borderColor: '#e7e7e7',
                                height: '37px',
                                width: '288px',
                                minWidth: '288px',
                                display: 'flex',
                                alignItems: 'center',

                            }}
                        >

                            <ActionMenu>
                                <ActionMenu.Anchor>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            position: 'relative',
                                            margin: '0px 0px 0px 6px',
                                            height: '24px',
                                            width: '24px',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        icon={() => <Box sx={{ display: 'flex' }}>
                                            <PlusIcon size={12} />
                                        </Box>} />

                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList sx={{ fontFamily: 'Segoe UI', minWidth: '210px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '4px' }}>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <PencilIcon />
                                            </ActionList.LeadingVisual>
                                            Rename
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Archive all cards
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Sample action list Content
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Test action list content
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Description action list
                                        </ActionList.Item>
                                        <ActionList.Item variant="danger">
                                            <ActionList.LeadingVisual>
                                                <TrashIcon />
                                            </ActionList.LeadingVisual>
                                            Delete all data from feed
                                        </ActionList.Item>
                                    </ActionList>
                                </ActionMenu.Overlay>
                            </ActionMenu>

                            <Text
                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 1px',
                                    fontWeight: '600',
                                    color: '#24292E',
                                    lineHeight: '20px'
                                }}>Due date </Text>


                        </Box>

                        <Box
                            sx={{
                                borderBottom: 1,
                                borderTop: 1,
                                borderLeft: 1,
                                borderRight: 1,
                                borderStyle: 'solid',
                                borderColor: '#e7e7e7',
                                height: '37px',
                                width: '288px',
                                minWidth: '288px',
                                display: 'flex',
                                alignItems: 'center',

                            }}
                        >

                            <ActionMenu>
                                <ActionMenu.Anchor>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            position: 'relative',
                                            margin: '0px 0px 0px 6px',
                                            height: '24px',
                                            width: '24px',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        icon={() => <Box sx={{ display: 'flex' }}>
                                            <PlusIcon size={12} />
                                        </Box>} />

                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList sx={{ fontFamily: 'Segoe UI', minWidth: '210px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '4px' }}>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <PencilIcon />
                                            </ActionList.LeadingVisual>
                                            Rename
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Archive all cards
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Sample action list Content
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Test action list content
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            <ActionList.LeadingVisual>
                                                <ArchiveIcon />
                                            </ActionList.LeadingVisual>
                                            Description action list
                                        </ActionList.Item>
                                        <ActionList.Item variant="danger">
                                            <ActionList.LeadingVisual>
                                                <TrashIcon />
                                            </ActionList.LeadingVisual>
                                            Delete all data from feed
                                        </ActionList.Item>
                                    </ActionList>
                                </ActionMenu.Overlay>
                            </ActionMenu>

                            <Text
                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 1px',
                                    fontWeight: '600',
                                    color: '#24292E',
                                    lineHeight: '20px'
                                }}>Priority </Text>


                        </Box>



                    </Box>

                    {allTasks.map((row, index) => {

                        return (

                            <Box sx={{
                                display: 'flex',
                                margin: '0px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px'
                            }}>
                                <Box sx={{
                                    borderBottom: 1,
                                    borderTop: 0,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    borderStyle: 'solid',
                                    borderColor: '#e7e7e7',
                                    height: '37px',
                                    width: '288px',
                                    minWidth: '288px',
                                    maxWidth: '288px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '4px'
                                }}>
                                    <Text
                                        onClick={() => { handleOpenTask(row) }}
                                        sx={{
                                            fontWeight: '600',
                                            color: '#24292E',
                                            lineHeight: '20px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                            maxWidth: '270px',
                                            minWidth: '270px',
                                            minHeight: '19px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',

                                        }}>
                                        {row.task_name}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    borderBottom: 1,
                                    borderTop: 0,
                                    borderLeft: 1,
                                    borderRight: 0,
                                    borderStyle: 'solid',
                                    borderColor: '#e7e7e7',
                                    height: '37px',
                                    width: '288px',
                                    minWidth: '288px',
                                    maxWidth: '288px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>

                                    <ActionMenu>
                                        <ActionMenu.Anchor>
                                            <Button

                                                sx={{
                                                    border: 'none',
                                                    background: 'white',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'start'
                                                }}>
                                                {

                                                    row.compliance_id !== null && row.compliance_id !== "" ?

                                                        <Label variant="accent"
                                                            style={{
                                                                minWidth: 'fit-content',
                                                                background: '#6F42C1',
                                                                color: '#ffffff',
                                                                borderRadius: '3px',
                                                                maxWidth: ''
                                                            }}
                                                            sx={SpaceTheme.taskBox.taskLabelBox.label}
                                                        >
                                                            <Text
                                                                sx={{
                                                                    minWidth: 'auto',
                                                                    width: 'auto',
                                                                    maxWidth: '250px',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                }}>

                                                                {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}

                                                            </Text>

                                                        </Label> : ""


                                                }
                                            </Button>
                                        </ActionMenu.Anchor>

                                        <ActionMenu.Overlay>
                                            <ActionList sx={{ fontFamily: 'Segoe UI', minWidth: '304px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '4px' }}>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <PencilIcon />
                                                    </ActionList.LeadingVisual>
                                                    Rename
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    Archive all cards
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACACACA
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACCAC
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACCCACA
                                                </ActionList.Item>
                                                <ActionList.Item variant="danger">
                                                    <ActionList.LeadingVisual>
                                                        <TrashIcon />
                                                    </ActionList.LeadingVisual>
                                                    Delete
                                                </ActionList.Item>
                                            </ActionList>
                                        </ActionMenu.Overlay>
                                    </ActionMenu>


                                </Box>

                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderTop: 0,
                                        borderLeft: 1,
                                        borderRight: 0,
                                        borderStyle: 'solid',
                                        borderColor: '#e7e7e7',
                                        height: '37px',
                                        width: '288px',
                                        minWidth: '288px',
                                        maxWidth: '288px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>


                                    <Box sx={{
                                        width: '100%',
                                        height: '37px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 8px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '8px',
                                        alignItems: 'center',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        color: '#000000',
                                        cursor: 'pointer'
                                    }}
                                    >
                                        {row.contact_data !== null && row.contact_data !== "" ?
                                            <Label variant="accent" style={{ minWidth: 'fit-content' }} sx={SpaceTheme.taskBox.taskLabelBox.label}>
                                                <Text sx={{
                                                    minWidth: 'auto',
                                                    width: 'auto',
                                                    maxWidth: '250px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}>
                                                    {row?.contact_data?.org_name}
                                                </Text>
                                            </Label> : ""
                                        }
                                    </Box>





                                </Box>


                                <Box sx={{ borderBottom: 1, borderTop: 0, borderLeft: 1, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', alignItems: 'center' }}>


                                    <ActionMenu>
                                        <ActionMenu.Anchor>
                                            <Button
                                                leadingIcon={() =>

                                                    row.assigne_id === "" ?
                                                        <Box>

                                                            <FeedPersonIcon size={20} fill="#586069" />
                                                            <Text sx={{ 
                                                                position: 'relative', 
                                                                margin: '0px 0px 0px 5px', 
                                                                fontSize: '12px',
                                                                color: '#24292E',
                                                                }}>
                                                                Unassigned
                                                            </Text>
                                                        </Box>
                                                        :
                                                        <Box>
                                                            <Tooltip aria-label={row.user_first_name + ' ' + row.user_last_name} direction="e">
                                                                {row.user_image_url === "" || row.user_image_url == null ?

                                                                    <FeedPersonIcon size={20} fill="#586069" /> :

                                                                    <Avatar src={row.user_image_url} />

                                                                }
                                                            </Tooltip>
                                                            <Text sx={{ 
                                                                position: 'relative', 
                                                                margin: '0px 0px 0px 5px', 
                                                                fontSize: '12px', 
                                                                color: '#24292E',
                                                                }}>
                                                                {row.user_first_name + " " + row.user_last_name}
                                                            </Text>
                                                        </Box>


                                                }
                                                sx={{
                                                    border: 'none',
                                                    background: 'white',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'start'
                                                }}>

                                            </Button>
                                        </ActionMenu.Anchor>

                                        <ActionMenu.Overlay>
                                            <ActionList sx={{ fontFamily: 'Segoe UI', minWidth: '280px', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '4px' }}>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <PencilIcon />
                                                    </ActionList.LeadingVisual>
                                                    Rename
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    Archive all cards
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACACACA
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACCAC
                                                </ActionList.Item>
                                                <ActionList.Item>
                                                    <ActionList.LeadingVisual>
                                                        <ArchiveIcon />
                                                    </ActionList.LeadingVisual>
                                                    ACCCACA
                                                </ActionList.Item>
                                                <ActionList.Item variant="danger">
                                                    <ActionList.LeadingVisual>
                                                        <TrashIcon />
                                                    </ActionList.LeadingVisual>
                                                    Delete
                                                </ActionList.Item>
                                            </ActionList>
                                        </ActionMenu.Overlay>
                                    </ActionMenu>



                                </Box>
                                <Box sx={{ borderBottom: 1, borderTop: 0, borderLeft: 1, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', alignItems: 'center' }}>


                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '37px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            color: '#000000',
                                            cursor: 'pointer',
                                        }}

                                    >
                                        {row.due_date === "" ?
                                            ""
                                            :
                                            <Label variant="accent" style={{ minWidth: 'fit-content', background: '#28A745', color: '#ffffff', borderRadius: '3px' }} sx={SpaceTheme.taskBox.taskLabelBox.label}>
                                                {new Date(row.due_date).getDate() + "/" + (new Date(row.due_date).getMonth() + 1) + '/' + new Date(row.due_date).getFullYear()}
                                            </Label>

                                        }
                                    </Box>




                                </Box>

                                <Box sx={{ borderBottom: 1, borderTop: 0, borderLeft: 1, borderRight: 0, borderStyle: 'solid', borderColor: '#e7e7e7', height: '37px', width: '288px', minWidth: '288px', maxWidth: '288px', display: 'flex', alignItems: 'center' }}>

                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '37px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 8px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '8px',
                                            alignItems: 'center',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            color: '#000000',
                                            cursor: 'pointer'
                                        }}

                                    >
                                        <Button
                                            variant="invisible"
                                            sx={{
                                                height: '30px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 1px',
                                                color: '#24292E',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                lineHeight: '16px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            leadingIcon={() =>

                                                row.tasks_priority === "Normal" ?
                                                    <img src={normalIcon} style={{ display: 'flex' }} /> :
                                                    row.tasks_priority === "Medium" ?
                                                        <img src={mediumIcon} style={{ display: 'flex' }} /> :
                                                        row.tasks_priority === "High" ?
                                                            <img src={highIcon} style={{ display: 'flex' }} /> :
                                                            row.tasks_priority === "Critical" ?
                                                                <img src={criticalIcon} style={{ display: 'flex' }} /> :
                                                                row.tasks_priority === "Low" ?
                                                                    <img src={lowIcon} style={{ display: 'flex' }} />
                                                                    : ""

                                            }

                                        >
                                            {
                                                row.tasks_priority
                                            }

                                        </Button>
                                    </Box>


                                </Box>


                            </Box>


                        )
                    })}


                </ActionList>


            </Box>

            {showTaskComponent === true ?
                <TaskExpansion />

                : ""}




        </Box>
    )

}

export default Feed