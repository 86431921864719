import React from "react";
import { Box } from "@primer/react"
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import ImageTool from '@editorjs/image';
import Raw from '@editorjs/raw'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import { useSelector,useDispatch } from "react-redux";
import {getSingleWorkplaceProject, updateProjectOverView} from "../redux/features/spaceSlice"
import AWS from 'aws-sdk'
const EDITTOR_HOLDER_ID =  "editorjs"

  
const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION


AWS.config.update({
  accessKeyId: IAM_USER_KEY,
  secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
  params: { Bucket: BUCKET_NAME},
  region: REGION,
})


function OverView(props){

      const dispatch = useDispatch()
      const userId = useSelector((state) => state.profileData.userId)
      const userEmail = useSelector((state) => state.profileData.userEmail)


      const DEFAULTEDITOR_DATA = {
        "time" : new Date().getTime(),
        "blocks" : [
            {
                 
                "type" : "header",
                "data" : {
                    "text" : "🚀"+localStorage.getItem("projectName"),
                     
                }
            },
            {
                 
                "type" : "paragraph",
                "data" : {
                    "text" : "Add meeting details communication channels and other important information . Be Transparent in the Workplace ",
                     
                }
            },
            
             
        ],
        "version" : "2.8.1"
      }

      const [editorData,seteditorData] = React.useState({
        "time" : new Date().getTime(),
        "blocks" : [
            {
                 
                "type" : "header",
                "data" : {
                    "text" : "🚀"+localStorage.getItem("projectName"),
                     
                }
            },
            {
                 
                "type" : "paragraph",
                "data" : {
                    "text" : "Add meeting details communication channels and other important information . Be Transparent in the Workplace ",
                     
                }
            },
            
             
        ],
        "version" : "2.8.1"
    })
      const ejInstance = React.useRef();
      const updateProjectOverViewIndb =async (props) => {


           dispatch(updateProjectOverView({
                 userId:userId,
                 userEmail:userEmail,
                 projectId:localStorage.getItem("projectId"),
                 projectOverView:props
           })).then((data)=>{

                 
                  const overViewData = data?.payload?.data[0]?.project_overview
                  seteditorData(overViewData)
           })   
  

      }

      const getEditorDataFromDb =async (props) => {

        dispatch(getSingleWorkplaceProject({
          userId:userId,
          userEmail:userEmail,
          projectId:localStorage.getItem("projectId")
         })).then((data)=>{


          
          
           const projectOverView = data.payload?.data[0]?.project_overview
           seteditorData(projectOverView)
           initEditor(editorData)
           
        })
      }

  const deleteOverViewFileToS3 = async (props) => {
   
    const projectId = localStorage.getItem("projectId")
    const fileName = props
    const params = {
      'Bucket': BUCKET_NAME,
      'Key': 'workspaceprojects/' + projectId + '/' + fileName,
      
    };

    const deletedFile = await deskiplatformBucket.deleteObject(params,async function(err, data) {
        if (err);
        else return "File deleted successfully";


    }).promise()
    return deletedFile

  }    


  const uploadOverViewFileToS3 = async (props) => {

    const projectId = localStorage.getItem("projectId")
    const fileName = props.name
    const fileType = props.type
   
    const params = {
      'Body': props,
      'Bucket': BUCKET_NAME,
      'Key': 'workspaceprojects/' + projectId + '/' + fileName,
      'ContentType': fileType
    };


    const fileUrl = await deskiplatformBucket.upload(params,async function (err, data) {

      if (err) {
        
      } else {

        



      };



    }).promise()
    const s3FileIUrl = {url:fileUrl.Location,fileName:props.name,fileType:props.type}
    return s3FileIUrl


  }

   
      

 
      React.useEffect(() => {

         
    
        if (!ejInstance.current) {


           dispatch(getSingleWorkplaceProject({
            userId:userId,
            userEmail:userEmail,
            projectId:localStorage.getItem("projectId")
           })).then((data)=>{
           
             const projectOverView = data.payload?.data[0]?.project_overview || DEFAULTEDITOR_DATA

             
             if(projectOverView.length==0){

                initEditor(DEFAULTEDITOR_DATA)

             }else if(projectOverView.blocks.length==0){

                initEditor(DEFAULTEDITOR_DATA)
             }else{

                initEditor(projectOverView)
             }


           
            
            

             
           
          })

          

                
                  // initEditor(editorData)
      
    
        }else{
    
           
    
            ejInstance.current.destroy();
            ejInstance.current = null;
           
        }
       
     
       
         
    
      }, [
        
        
       // localStorage.getItem("projectId")
      ]);


       



      const initEditor = (data) => {
    
        const copyBlocks = [data]
        
        const editor =  new EditorJS({
          
            
          holder:ejInstance.current, //EDITTOR_HOLDER_ID,
          logLevel: "ERROR",
          data:data,
           
          onReady:async () => {
            
              
               ejInstance.current = editor;
               
             //  await getEditorDataFromDb("")
               

     
          },
          onChange: async (api,data) => {
 
                  

                   if(data.type==="block-removed"&& data.detail.target.name==="image"){

                      const blockId = data.detail.target.id
                      
                      const deletedImageBlock = copyBlocks[0]?.blocks.filter((bData)=> bData.id==blockId)
                      const deleteFileName = deletedImageBlock[0].data.file.fileName
                    
                   
                      const deleteOverViewFileToS3Response = await deleteOverViewFileToS3(deleteFileName)
                     
                      let content = await api.saver.save();
                      await updateProjectOverViewIndb(content)
                   }else{

                     let content = await api.saver.save();
                     await updateProjectOverViewIndb(content)
                   }  


                  
             
                    
            
            
             
          },
          autofocus: true,
          
          tools: { 
            
            embed: Embed,
            table: Table,
            marker: Marker,
            list: List,
            warning: Warning,
            code: Code,
            linkTool: LinkTool,
            image: {
                class: ImageTool,
                config: {
                 
                  uploader: {
                    /**
                     * Upload file to the server and return an uploaded image data
                     * @param {File} file - file selected from the device or pasted by drag-n-drop
                     * @return {Promise.<{success, file: {url}}>}
                     */
                    async uploadByFile(file){
                      // your own uploading logic here

                       
                       const uploadOverViewFileToS3Response = await uploadOverViewFileToS3(file)
                      

                       
                        return {
                        success: 1,
                        file: {
                          url: uploadOverViewFileToS3Response.url,
                          fileName: uploadOverViewFileToS3Response.fileName,
                          fileType: uploadOverViewFileToS3Response.fileType
                          // any other image data you want to store, such as width, height, color, extension, etc
                        }};
                           
                    },
          
                    /**
                     * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
                     * @param {string} url - pasted image URL
                     * @return {Promise.<{success, file: {url}}>}
                     */
                    uploadByUrl(url){
                      // your ajax request for uploading
                          
                    },
                    
                  },
                  
                   
                }
              },
            raw: Raw,
            header: Header,
            quote: Quote,
            checklist: CheckList,
            delimiter: Delimiter,
            inlineCode: InlineCode,
            simpleImage: SimpleImage,
            
          }, 
        });
      };

      return(

        <Box 
         sx={{
                position:'absolute',
                display:'flex',
                flexDirection:'column',
                left:'210px',
                right:'210px',
                top:'156px',
                height:'auto',
               
            }}
        >


          



        <Box 
            
           
        sx={{
            position:'relative',
            margin:'0px 0px 0px 0px',
            background:'#ffffff',
            border:'1px solid #D0D7DE',
            borderRadius:'6px',
            display:'flex',
            flexDirection:'column',
            
           
            
        }}>

              <Box
                  
                  sx={{
                      position: 'relative',
                      margin: '0px 54px 0px 56px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'left',
                      gap: '0px',
                      fontFamily: 'Segoe UI',
                      textAlign: 'left',
                      zIndex:'0'
                  }}
                 // key={EDITTOR_HOLDER_ID}
                   id={EDITTOR_HOLDER_ID}
                   // ref={ejInstance.current}
                    

              >
              </Box>
              

        </Box>

        <Box sx={{
            minHeight:'78px',
            height:'78px',
            width:'100%',
            display:'flex'
        }}>
          
        </Box>
         
        </Box>
      )

}

export default OverView