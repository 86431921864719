import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const contactProfileOpenClose = createAsyncThunk("contactProfileOpenClose", async (data) => {

    return ({ openClose: data.openClose, title: data.title, data: data.data })

})


export const deleteOrganizationDialogOpenClose = createAsyncThunk("deleteOrganizationDialogOpenClose", async (data) => {


    return ({ deleteOrganization: data.deleteOrganization })
})


export const addCredentialOpenClose = createAsyncThunk("addCredentialOpenClose", async (data) => {


    return ({ credentialOpenClose: data.credentialOpenClose, contactId: data.contactId, editCredentialId: data.editCredentialId })
})


export const addCompanyTaskOpenClose = createAsyncThunk("addCompanyTaskOpenClose", async (data) => {

    return ({ companyTaskOpenClose: data.companyTaskOpenClose })
})

export const addPanOpenClose = createAsyncThunk("addPanOpenClose", async (data) => {

    return ({ panOpenClose: data.panOpenClose })
})

export const uploadPanOpenClose = createAsyncThunk("uploadPanOpenClose", async (data) => {

    return ({ panUploadOpenClose: data.panUploadOpenClose })
})

export const gstDataSyncOpenClose = createAsyncThunk("gstDataSyncOpenClose", async (data) => {

    return ({ dataSyncOpenClose: data.dataSyncOpenClose })
})


export const accountSetupOpenClose = createAsyncThunk("accountSetupOpenClose", async (data) => {

    return ({ accountSetupisOpen: data.accountSetupisOpen })
})

export const defaultOrgOpenClose = createAsyncThunk("defaultOrgOpenClose", async (data) => {

    return ({ defaultOrgisOpen: data.defaultOrgisOpen })
})

// shared org dialog open close 

export const sharedOrgOpenClose = createAsyncThunk("sharedOrgOpenClose", async (data) => {

    return ({ sharedOrgisOpen: data.sharedOrgisOpen })
})

export const defaultSuccessDialogOpenClose = createAsyncThunk("defaultSuccessDialogOpenClose", async (data) => {

    //
    return ({ defaultSuccessisOpen: data.defaultSuccessisOpen })

})


export const accountSetupSuccessOpenClose = createAsyncThunk("accountSetupSuccessOpenClose", async (data) => {

    return ({ setupSuccessOpenClose: data.setupSuccessOpenClose })
})

export const upgradePlanOpenClose = createAsyncThunk("upgradePlanOpenClose", async (data) => {

    return ({ upgradeisOpen: data.upgradeisOpen })
})

export const taxHeroupgradePlanOpenClose = createAsyncThunk("taxHeroupgradePlanOpenClose", async (data) => {

    return ({ taxheroupgradeisOpen: data.taxheroupgradeisOpen })
})

export const addOrganizationOpenClose = createAsyncThunk("addOrganizationOpenClose", async (data) => {

    return ({ isAddOrganization: data.isAddOrganization })

})

export const addProprietorOpenClose = createAsyncThunk("addProprietorOpenClose", async (data) => {

    return ({ isAddProprietor: data.isAddProprietor })

})

export const addOrganizationDirectorsOpenClose = createAsyncThunk("addOrganizationDirectorsOpenClose", async (data) => {

    return ({ isAddOrganizationDirectors: data.isAddOrganizationDirectors })

})

export const addProprietorDirectorsOpenClose = createAsyncThunk("addProprietorDirectorsOpenClose", async (data) => {

    return ({ isAddProprietorDirectors: data.isAddProprietorDirectors })

})

export const addOrganizationServicesOpenClose = createAsyncThunk("addOrganizationServicesOpenClose", async (data) => {

    return ({ isAddOrganizationServices: data.isAddOrganizationServices })

})

export const addProprietorServicesOpenClose = createAsyncThunk("addProprietorServicesOpenClose", async (data) => {

    return ({ isAddProprietorServices: data.isAddProprietorServices })

})


export const uploadBulkComanyOpenClose = createAsyncThunk("uploadBulkComanyOpenClose", async (data) => {

    return ({ bulkCompanyOpenClose: data.bulkCompanyOpenClose })
})

export const uploadBulkProprietaryOpenClose = createAsyncThunk("uploadBulkProprietaryOpenClose", async (data) => {

    return ({ bulkProprietaryOpenClose: data.bulkProprietaryOpenClose })

})


export const reloadCompaniesTrigger = createAsyncThunk("reloadCompaniesTrigger", async (data) => {

    return ({ reloadCompanies: data.reloadCompanies })
})


export const postZohoInvCustomer = createAsyncThunk("postZohoInvCustomer", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "zohoToken": data.zohoToken,
        "customerId": data.customerId,
        "customerName": data.customerName,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postZohoInvCustomer`, requestOptions)
        .then(response => response.json())

    return result

})

export const postZohoInvItem = createAsyncThunk("postZohoInvItem", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "zohoToken": data.zohoToken,
        "itemId": data.itemId,
        "itemName": data.itemName,
        "rate": data.rate,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postZohoInvItem`, requestOptions)
        .then(response => response.json())

    return result




})

export const getZohoInvoiceByCustomer = createAsyncThunk("getZohoInvoiceByCustomer", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "customerName": data.customerName
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getZohoInvoiceByCustomer`, requestOptions)
        .then(response => response.json())

    return result


})

export const downloadZohoInvoice = createAsyncThunk("downloadZohoInvoice", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "invoiceId": data.invoiceId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/downloadZohoInvoice`, requestOptions)
        .then(response => response.json())

    return result


})


export const postCompanyTask = createAsyncThunk("postCompanyTask", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskName": data.taskName,
        "taskDescription": data.taskDescription,
        "dueDate": data.dueDate,
        "isRecurring": data.isRecurring
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postCompanyTask`, requestOptions)
        .then(response => response.json())

    return result

})

export const deleteContactDirectory = createAsyncThunk("deleteContactDirectory", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "deleteContactId": data.deleteContactId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})


export const getCompanyTask = createAsyncThunk("getCompanyTask", async (data) => {


    const url = new URL(`${process.env.REACT_APP_API_URL}/getCompanyTask`);
    const params = { userId: data.userId, userEmail: data.userEmail };
    url.search = new URLSearchParams(params);

    const result = await fetch(url).then(response => response.json())
    return result


})


export const postBatchCompanyTasks = createAsyncThunk("postBatchCompanyTasks", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "selectedTasks": data.selectedTasks,
        "projectId": data.projectId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postBatchCompanyTasks`, requestOptions)
        .then(response => response.json())

    return result


})


export const updateUserOrganizationArray = createAsyncThunk("updateUserOrganizationArray", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "organizationArray": data.organizationArray,
        "projectId": data.projectId,
        "selectedCategory": data.selectedCategory,
        "selectedSubcategory": data.selectedSubcategory
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateUserProfile`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateUserDefaultOrgId = createAsyncThunk("updateUserDefaultOrgId", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "organizationId": data.organizationId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateUserProfile`, requestOptions)
        .then(response => response.json())

    return result

})


export const getComplianceServices = createAsyncThunk("getComplianceServices", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getComplianceServices`);
    const params = { userId: data.userId, userEmail: data.userEmail };
    url.search = new URLSearchParams(params);

    const result = await fetch(url).then(response => response.json())
    return result

})


export const updateContactDirectory = createAsyncThunk("updateContactDirectory", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "orgImgUrl": data.orgImgUrl
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateContactCompliance = createAsyncThunk("updateContactCompliance", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "compliance": data.compliance
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})

export const updatecomplianceCategory = createAsyncThunk("updatecomplianceCategory", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "complianceCategory": data.complianceCategory
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result


})

export const postCompanyBulk = createAsyncThunk("postCompanyBulk", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "bulkCompanyData": data.bulkCompanyData,
        "contactType": data.contactType
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postCompanyBulk`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateContactDirectoryOrgData = createAsyncThunk("updateContactDirectory", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "orgData": data.orgData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`https://api.deski.co/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateContactDirectoryDirectors = createAsyncThunk("updateContactDirectoryDirectors", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "directors": data.directors
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})


export const uploadOrganizationFiles = createAsyncThunk("uploadOrganizationFiles", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "orgFiles": data.orgFiles
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result


})

export const deleteOrganizationFiles = createAsyncThunk("deleteOrganizationFiles", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "deleteAttachmentId": data.deleteAttachmentId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})

export const addOrgCredentials = createAsyncThunk("addOrgCredentials", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "credentialData": data.credentialData
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})

export const editOrgCredentials = createAsyncThunk("addOrgCredentials", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "editCredentialId": data.editCredentialId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result

})


export const removeOrgCredentials = createAsyncThunk("removeOrgCredentials", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "contactId": data.contactId,
        "deleteCredentialId": data.deleteCredentialId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateContactDirectory`, requestOptions)
        .then(response => response.json())

    return result


})


export const getIndiviualsCompanies = createAsyncThunk("getIndiviualsCompanies", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getIndiviualsCompanies`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId, page: data.page };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})


export const getIndiviualsCompaniesById = createAsyncThunk("getIndiviualsCompaniesById", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getIndiviualsCompaniesById`);
    const params = { userId: data.userId, userEmail: data.userEmail, contactId: data.contactId, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const getContactByProjectId = createAsyncThunk("getContactByProjectId", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getContactByProjectId`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const getOrganization = createAsyncThunk("getOrganization", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getOrganization`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})



export const updateTempOrgData = createAsyncThunk("updateTempOrgData", async (data) => {


    return ({ orgData: data.orgData })

})

export const updateTempDirectorSData = createAsyncThunk("updateTempDirectorSData", async (data) => {

    return ({ directors: data.directors })


})




export const contactProfileOpenCloseSlice = createSlice({

    name: 'contactProfileOpenClose',
    initialState: {
        openClose: false,
        title: '',
        loading: false,
        deleteOrganization: false,
        credentialOpenClose: false,
        companyTaskOpenClose: false,
        panOpenClose: false,
        panUploadOpenClose: false,
        dataSyncOpenClose: false,
        accountSetupisOpen: false,
        setupSuccessOpenClose: false,
        upgradeisOpen: false,
        taxheroupgradeisOpen: false,
        defaultOrgisOpen: false,
        defaultSuccessisOpen: false,
        sharedOrgisOpen: false,//added newly
        isAddOrganization: false,
        isAddOrganizationDirectors: false,
        isAddOrganizationServices: false,
        isAddProprietor: false,
        isAddProprietorDirectors: false,
        isAddProprietorServices: false,
        orgData: {},
        directors: [],
        contactId: '',
        credentialArray: [],
        editCredentialId: null,
        reloadCredentialTrigger: false,
        bulkCompanyOpenClose: false,
        bulkProprietaryOpenClose: false,
        reloadCompanies: false
    },
    extraReducers: {

        [contactProfileOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [contactProfileOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose
            state.title = action.payload.title
            state.data = action.payload.data

        },
        [contactProfileOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [deleteOrganizationDialogOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.deleteOrganization = false

        },
        [deleteOrganizationDialogOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.deleteOrganization = action.payload.deleteOrganization


        },
        [deleteOrganizationDialogOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.deleteOrganization = false
        },
        [addCredentialOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.credentialOpenClose = false
            state.contactId = ''
            state.editCredentialId = ''
        },
        [addCredentialOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.credentialOpenClose = action.payload.credentialOpenClose
            state.contactId = action.payload.contactId
            state.editCredentialId = action.payload.editCredentialId

        },
        [addCredentialOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.credentialOpenClose = false
            state.contactId = ''
            state.editCredentialId = ''
        },
        [addCompanyTaskOpenClose.pending]: (state, action) => {

            state.companyTaskOpenClose = false

        },
        [addCompanyTaskOpenClose.fulfilled]: (state, action) => {

            state.companyTaskOpenClose = action.payload.companyTaskOpenClose


        },
        [addCompanyTaskOpenClose.rejected]: (state, action) => {

            state.companyTaskOpenClose = false
        },
        [addPanOpenClose.pending]: (state, action) => {

            state.panOpenClose = false

        },
        [addPanOpenClose.fulfilled]: (state, action) => {

            state.panOpenClose = action.payload.panOpenClose


        },
        [addPanOpenClose.rejected]: (state, action) => {

            state.panOpenClose = false
        },
        // bulk company open close
        [uploadBulkComanyOpenClose.pending]: (state, action) => {

            state.bulkCompanyOpenClose = false

        },
        [uploadBulkComanyOpenClose.fulfilled]: (state, action) => {

            state.bulkCompanyOpenClose = action.payload.bulkCompanyOpenClose


        },
        [uploadBulkComanyOpenClose.rejected]: (state, action) => {

            state.bulkCompanyOpenClose = false
        },
        // bulk proprietary open close
        [uploadBulkProprietaryOpenClose.pending]: (state, action) => {

            state.bulkProprietaryOpenClose = false

        },
        [uploadBulkProprietaryOpenClose.fulfilled]: (state, action) => {

            state.bulkProprietaryOpenClose = action.payload.bulkProprietaryOpenClose


        },
        [uploadBulkProprietaryOpenClose.rejected]: (state, action) => {

            state.bulkProprietaryOpenClose = false
        },
        //---- reload companies
        [reloadCompaniesTrigger.pending]: (state, action) => {

            state.reloadCompanies = false

        },
        [reloadCompaniesTrigger.fulfilled]: (state, action) => {

            state.reloadCompanies = action.payload.reloadCompanies


        },
        [reloadCompaniesTrigger.rejected]: (state, action) => {

            state.reloadCompanies = false
        },
        //------------------------- 
        [uploadPanOpenClose.pending]: (state, action) => {

            state.panUploadOpenClose = false

        },
        [uploadPanOpenClose.fulfilled]: (state, action) => {

            state.panUploadOpenClose = action.payload.panUploadOpenClose


        },
        [uploadPanOpenClose.rejected]: (state, action) => {

            state.panUploadOpenClose = false
        },
        [gstDataSyncOpenClose.pending]: (state, action) => {

            state.dataSyncOpenClose = false

        },
        [gstDataSyncOpenClose.fulfilled]: (state, action) => {

            state.dataSyncOpenClose = action.payload.dataSyncOpenClose


        },
        [gstDataSyncOpenClose.rejected]: (state, action) => {

            state.dataSyncOpenClose = false
        },
        [accountSetupSuccessOpenClose.pending]: (state, action) => {

            state.setupSuccessOpenClose = false

        },
        [accountSetupSuccessOpenClose.fulfilled]: (state, action) => {

            state.setupSuccessOpenClose = action.payload.setupSuccessOpenClose


        },
        [accountSetupSuccessOpenClose.rejected]: (state, action) => {

            state.setupSuccessOpenClose = false
        },
        [upgradePlanOpenClose.pending]: (state, action) => {

            state.upgradeisOpen = false

        },
        [upgradePlanOpenClose.fulfilled]: (state, action) => {

            state.upgradeisOpen = action.payload.upgradeisOpen


        },
        [upgradePlanOpenClose.rejected]: (state, action) => {

            state.upgradeisOpen = false
        },
        //---taxHero ----
        [taxHeroupgradePlanOpenClose.pending]: (state, action) => {

            state.taxheroupgradeisOpen = false

        },
        [taxHeroupgradePlanOpenClose.fulfilled]: (state, action) => {

            state.taxheroupgradeisOpen = action.payload.taxheroupgradeisOpen


        },
        [taxHeroupgradePlanOpenClose.rejected]: (state, action) => {

            state.taxheroupgradeisOpen = false
        },
        //---------
        [accountSetupOpenClose.pending]: (state, action) => {

            state.accountSetupisOpen = false

        },
        [accountSetupOpenClose.fulfilled]: (state, action) => {

            state.accountSetupisOpen = action.payload.accountSetupisOpen


        },
        [accountSetupOpenClose.rejected]: (state, action) => {

            state.accountSetupisOpen = false
        },
        [defaultOrgOpenClose.pending]: (state, action) => {

            state.defaultOrgisOpen = false

        },
        [defaultOrgOpenClose.fulfilled]: (state, action) => {

            state.defaultOrgisOpen = action.payload.defaultOrgisOpen


        },
        [defaultOrgOpenClose.rejected]: (state, action) => {

            state.defaultOrgisOpen = false
        },
        [addOrganizationOpenClose.pending]: (state, action) => {

            state.isAddOrganization = false

        },
        [addOrganizationOpenClose.fulfilled]: (state, action) => {

            state.isAddOrganization = action.payload.isAddOrganization


        },
        [addOrganizationOpenClose.rejected]: (state, action) => {

            state.isAddOrganization = false
        },
        //---- add addProprietorOpenClose --------
        [addProprietorOpenClose.pending]: (state, action) => {

            state.isAddProprietor = false

        },
        [addProprietorOpenClose.fulfilled]: (state, action) => {

            state.isAddProprietor = action.payload.isAddProprietor


        },
        [addProprietorOpenClose.rejected]: (state, action) => {

            state.isAddProprietor = false
        },

        //addProprietorDirectorsOpenClose
        [addProprietorDirectorsOpenClose.pending]: (state, action) => {

            state.isAddProprietorDirectors = false

        },
        [addProprietorDirectorsOpenClose.fulfilled]: (state, action) => {

            state.isAddProprietorDirectors = action.payload.isAddProprietorDirectors


        },
        [addProprietorDirectorsOpenClose.rejected]: (state, action) => {

            state.isAddProprietorDirectors = false
        },

        //addProprietorServicesOpenClose

        [addProprietorServicesOpenClose.pending]: (state, action) => {

            state.isAddProprietorServices = false

        },
        [addProprietorServicesOpenClose.fulfilled]: (state, action) => {

            state.isAddProprietorServices = action.payload.isAddProprietorServices


        },
        [addProprietorServicesOpenClose.rejected]: (state, action) => {

            state.isAddProprietorServices = false
        },

        //----------------------------------------
        [addOrganizationDirectorsOpenClose.pending]: (state, action) => {

            state.isAddOrganizationDirectors = false

        },
        [addOrganizationDirectorsOpenClose.fulfilled]: (state, action) => {

            state.isAddOrganizationDirectors = action.payload.isAddOrganizationDirectors


        },
        [addOrganizationDirectorsOpenClose.rejected]: (state, action) => {

            state.isAddOrganizationDirectors = false
        },

        [addOrganizationServicesOpenClose.pending]: (state, action) => {

            state.isAddOrganizationServices = false

        },
        [addOrganizationServicesOpenClose.fulfilled]: (state, action) => {

            state.isAddOrganizationServices = action.payload.isAddOrganizationServices


        },
        [addOrganizationServicesOpenClose.rejected]: (state, action) => {

            state.isAddOrganizationServices = false
        },

        [updateTempOrgData.pending]: (state, action) => {

            state.orgData = {}

        },
        [updateTempOrgData.fulfilled]: (state, action) => {

            state.orgData = action.payload.orgData


        },
        [updateTempOrgData.rejected]: (state, action) => {

            state.orgData = {}
        },
        [updateTempDirectorSData.pending]: (state, action) => {

            state.directors = []

        },
        [updateTempDirectorSData.fulfilled]: (state, action) => {

            state.directors = action.payload.directors


        },
        [updateTempDirectorSData.rejected]: (state, action) => {

            state.directors = []
        },
        [getIndiviualsCompaniesById.pending]: (state, action) => {

            state.credentialArray = []

        },
        [getIndiviualsCompaniesById.fulfilled]: (state, action) => {

            state.credentialArray = action.payload.data[0]?.credentials


        },
        [getIndiviualsCompaniesById.rejected]: (state, action) => {

            state.credentialArray = []
        },
        [addOrgCredentials.pending]: (state, action) => {

            state.reloadCredentialTrigger = false

        },
        [addOrgCredentials.fulfilled]: (state, action) => {

            state.reloadCredentialTrigger = true


        },
        [addOrgCredentials.rejected]: (state, action) => {

            state.reloadCredentialTrigger = false
        },
        [editOrgCredentials.pending]: (state, action) => {

            state.reloadCredentialTrigger = false

        },
        [editOrgCredentials.fulfilled]: (state, action) => {

            state.reloadCredentialTrigger = true


        },
        [editOrgCredentials.rejected]: (state, action) => {

            state.reloadCredentialTrigger = false
        },
        //shared org open close added 
        [sharedOrgOpenClose.pending]: (state, action) => {

            state.sharedOrgisOpen = false

        },
        [sharedOrgOpenClose.fulfilled]: (state, action) => {

            state.sharedOrgisOpen = action.payload.sharedOrgisOpen


        },
        [sharedOrgOpenClose.rejected]: (state, action) => {

            state.sharedOrgisOpen = false
        },
        //defaul success is Open  defaultSuccessisOpen
        [defaultSuccessDialogOpenClose.pending]: (state, action) => {

            state.defaultSuccessisOpen = false

        },
        [defaultSuccessDialogOpenClose.fulfilled]: (state, action) => {

            state.defaultSuccessisOpen = action.payload.defaultSuccessisOpen


        },
        [defaultSuccessDialogOpenClose.rejected]: (state, action) => {

            state.defaultSuccessisOpen = false
        },


    }


})


export default contactProfileOpenCloseSlice.reducer