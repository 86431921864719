import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const moveWorkplaceOpenClose = createAsyncThunk("moveWorkplaceOpenClose", async (data) => {


    return ({ openClose: data.openClose })

})


export const createWorkplace = createAsyncThunk("createWorkplace", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workspaceName": data.workspaceName,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postWorkplace`, requestOptions)
        .then(response => response.json())

    return result

})


export const getSingleWorkplace = createAsyncThunk("getSingleWorkplace", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workplaceId": data.workplaceId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getSingleWorkplace`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateWorkplaceName = createAsyncThunk("updateWorkplaceName", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workplaceId": data.workplaceId,
        "workplaceName": data.workplaceName
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceName`, requestOptions)
        .then(response => response.json())

    return result


})

export const getAllWorkplace = createAsyncThunk("getAllWorkplace", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,

    })


    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getAllworkplace`, requestOptions)
        .then(response => response.json())

    return result







})

export const deleteWorkplace = createAsyncThunk("deleteWorkplace", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workplaceId": data.workplaceId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteWorkplace`, requestOptions)
        .then(response => response.json())

    return result

})

export const updateWorkplaceSortTrigger = createAsyncThunk("updateWorkplaceSortTrigger", async (data) => {

    return ({ workplaceSortTrigger: data.workplaceSortTrigger })
})






export const moveWorkplaceOpenCloseSlice = createSlice({

    name: 'moveWorkplaceOpenClose',
    initialState: {
        openClose: false,
        loading: false,
        workplace: [],
        workspaceDeleteTrigger: false,
        workplaceSortTrigger: false
    },
    extraReducers: {

        [moveWorkplaceOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [moveWorkplaceOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose

        },
        [moveWorkplaceOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [getAllWorkplace.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [getAllWorkplace.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.workplace = action.payload.data

        },
        [getAllWorkplace.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [deleteWorkplace.pending]: (state, action) => {
            state.loading = true;
            state.workspaceDeleteTrigger = false

        },
        [deleteWorkplace.fulfilled]: (state, action) => {
            state.loading = false
            state.workspaceDeleteTrigger = true

        },
        [deleteWorkplace.rejected]: (state, action) => {
            state.loading = false
            state.workspaceDeleteTrigger = false
        },
        [updateWorkplaceSortTrigger.pending]: (state, action) => {
            state.loading = true;
            state.workplaceSortTrigger = false

        },
        [updateWorkplaceSortTrigger.fulfilled]: (state, action) => {
            state.loading = false
            state.workplaceSortTrigger = action.payload.workplaceSortTrigger

        },
        [updateWorkplaceSortTrigger.rejected]: (state, action) => {
            state.loading = false
            state.workplaceSortTrigger = false
        },

    }


})


export default moveWorkplaceOpenCloseSlice.reducer
