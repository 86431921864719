import { Box, Button, Text, Dialog, FormControl, Checkbox, Spinner } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./TaxHeroHeaderSanbox"
import SideNav from "./SideNavSanbox"
import { useDispatch, useSelector } from "react-redux"
import AddPanDialog from "../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../Workplace/Dialogs/UploadPanDialog"
import { useNavigate } from "react-router-dom"
import React from "react"
import Workplacetheme from "../Workplace/Workplacetheme"
import { getSingleItportal, syncITPortal, updateItPortal } from "../redux/features/portalSlice"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import { ChevronRightIcon } from "@primer/octicons-react"
import TaxHeroHeader from "./TaxHeroHeaderSanbox"

const synchingToast = Toaster.create({ position: Position.TOP })


function ItSanbox(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const initialFocusRef = React.createRef()
    const [dataSyncOpenClose, setdataSyncOpenClose] = React.useState(false)
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const [showSyncProgress, setshowSyncProgress] = React.useState(false)
    const [progressPercentage, setprogressPercentage] = React.useState(0)
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);

    const [profileData, setProfileData] = React.useState(null)
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [checkBoxArray, setcheckBoxArray] = React.useState([
        { id: '1', name: 'Filed ITR', isChecked: false },
        { id: '2', name: 'Notices', isChecked: false },
        { id: '3', name: 'Refund', isChecked: false },
        { id: '4', name: 'Notices files', isChecked: false },
        { id: '5', name: 'Refund cases', isChecked: false },
        { id: '6', name: 'Additional notices', isChecked: false },
        { id: '7', name: 'Returns', isChecked: false },
        { id: '8', name: 'Notices files', isChecked: false },
        { id: '9', name: 'Returns files', isChecked: false },
        { id: '10', name: 'Challan', isChecked: false },
        { id: '11', name: 'Electronic Ledger', isChecked: false },
        { id: '12', name: 'Profile', isChecked: false },
    ])

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            30 * 60 * 1000
        );
    };


    const getSingleItportalFromDb = (props) => {

        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleItportal", data.payload)
            setallDataFeilds(data.payload.data)
          
            setProfileData(data?.payload?.data[0]?.itr_profile_synzy)
       


          //  setProfileData(data.payload.data[0]?.itr_profile?.data?.data)

        })

    }


    const loadingToast =async (progressPercentage, key) => {



        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },


        }, key);

    }


    const updateItPortalInDb = async (props) => {


           disableSyncButton("")
           setshowSyncProgress(true)
           
           var profileSynched = false
           var returnSynched = false
         
           await dispatch(syncITPortal({
                userId:userId,
                userEmail:userEmail,
                userName: profileData?.profile_details?.pan?.pan,
                userPassword: allDataFeilds[0]?.pan_password,
                insertId: insertId,
           })).then(async(data)=>{
                   await loadingToast(20, "1")
                   console.log("syncITPortal",data?.payload?.data)
                   await loadingToast(50, "1")
                   if(data?.payload?.data!==null){

                       profileSynched = true
                       returnSynched = true
                       await  loadingToast(100, "1")
                   }else{

                       Toaster.create({ position: Position.TOP }).show({
                           message: "Sync Error or invalid credential",
                           intent: Intent.PRIMARY
                       });

                   }




           })

        if (profileSynched && returnSynched) {


            await  loadingToast(100, "1")
            getSingleItportalFromDb("")


        }





        // disableSyncButton("")
        // setshowSyncProgress(true)
        // var profileSynched = false
        // var returnSynched = false
       
        // await dispatch(updateItPortal({
        //     userId: userId,
        //     userEmail: userEmail,
        //     clientId: clientId,
        //     insertId: insertId,
        //     syncField: 'Profile'
        // })).then((data) => {

        //     console.log("Profile", data.payload)
  
        //     const responseObj = { ...data?.payload?.data.data }
        //     console.log("responseObj",responseObj)
        //     if (responseObj?.status_code==200) {
        //           loadingToast(50, "1")
        //          // loadingToast(100, "1")
        //           profileSynched = true
        //     } else {

        //         Toaster.create({ position: Position.TOP }).show({
        //             message: "Sync Error or invalid credential",
        //             intent: Intent.PRIMARY
        //         });
        //         profileSynched = false
        //     }         

 
        //     setcheckBoxArray((prevState) => {
        //         const newState = prevState.map((row) => {
        //             return ({ ...row, isChecked: false })
        //         })
        //         return newState
        //     })



        // })

        // await dispatch(updateItPortal({
        //     userId: userId,
        //     userEmail: userEmail,
        //     clientId: clientId,
        //     insertId: insertId,
        //     syncField: 'Filed ITR'
        // })).then((data) => {

        //     console.log("Filed ITR", data.payload)

        //     const responseObj = { ...data?.payload?.data.data }
        //     if (responseObj?.status_code == 200) {
        //          returnSynched = true
        //     } else {
        //          returnSynched = false
        //     }   

        //     // if (data.payload.statusCode == 200 && data?.status !== 500) {
        //     //     returnSynched = true
        //     //    // loadingToast(90, "1")
        //     // } else {
        //     //     // loadingToast(0)
        //     //     returnSynched = false
        //     // }

        //     setcheckBoxArray((prevState) => {
        //         const newState = prevState.map((row) => {
        //             return ({ ...row, isChecked: false })
        //         })

        //         return newState
        //     })

        // })


        // //---------------- working code ------------------------ 

        // if (profileSynched && returnSynched) {


        //     loadingToast(100, "1")
        //     getSingleItportalFromDb("")


        // }
 
    }




    const syncDataClick = (props) => {

        const selectedChecBox = checkBoxArray.filter((cData) => cData.isChecked === true)
        const checkProfileChecked = selectedChecBox.some(obj => obj.name === "Profile")
        const filedITR = selectedChecBox.some(obj => obj.name === "Filed ITR")

        if (checkProfileChecked) {

            updateItPortalInDb("Profile")

        }
        if (filedITR) {

            updateItPortalInDb("Filed ITR")


        }
        closeDataSyncDialog("")


    }


    const showSyncDialog = async (props) => {

      //  loadingToast(0, "1")

        await updateItPortalInDb("Profile")
        await updateItPortalInDb("Filed ITR")



    }

    const closeDataSyncDialog = (props) => {

        setdataSyncOpenClose(false)

    }

    const ITSyncDialog = (props) => {

        return (
            <Dialog
                sx={{
                    height: '439px',
                    width: '564px',
                }}
                isOpen={dataSyncOpenClose}
                onDismiss={() =>
                    closeDataSyncDialog("")
                }
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text
                        ref={initialFocusRef}
                        sx={Workplacetheme.scheduleDalogeHeading}>Data Sync</Text>

                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '15px 20px 0px 15px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Once synced for a GST account, you'll have to wait for the next 8 hrs to sync again.</Text>


                    <Box sx={{
                        position: 'relative',
                        margin: '40px 40px 0px 23px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>


                        {checkBoxArray.map((row, index) => {

                            return (
                                <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>
                                    <FormControl>
                                        <Checkbox
                                            id="default-checkbox"
                                            checked={row.isChecked}
                                            onChange={(e) => {
                                                setcheckBoxArray((prevState) => {
                                                    const newState = prevState.map((row2, index) => {
                                                        if (row2.id === row.id) {
                                                            return ({ ...row2, isChecked: e.target.checked })
                                                        } else {
                                                            return (row2)
                                                        }
                                                    })
                                                    return newState
                                                })
                                            }}
                                        />
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}>{row.name}</FormControl.Label>
                                    </FormControl>
                                </Box>
                            )

                        })}






                    </Box>



                    <Box

                        sx={{
                            position: 'absolute',
                            bottom: '52px',
                            left: '0px',
                            right: '0px',
                        }}
                        borderColor="border.default"
                        borderBottomWidth={1}
                        borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '20px',
                        right: '20px',

                    }}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >


                    {
                        showLoadingBtn === true ?

                            <Button
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '92px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {

                                    syncDataClick("Profile")
                                }
                                }
                            >

                                Sync now
                            </Button>


                    }






                </Box>





            </Dialog>
        )

    }


    React.useEffect(() => {

        if (dataSyncOpenClose === true) {

        }



    }, [dataSyncOpenClose])



    React.useState(() => {

        getSingleItportalFromDb("")

    })


    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" ? 'IT - info - taxhero' : 'IT - info - deski'}
                </title>
            </Helmet>


            {
                hostName === "www.app.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }




            <Box

                sx={{
                    top: '48px',
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" ? '0px' : '64px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1'
                }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com" ?
                            navigate('/taxherosanbox')
                            :
                            navigate('/taxherosanbox')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/itpanlistsanbox')
                    }}
                >
                    IT
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {allDataFeilds[0]?.pan_name.substring(0, 100)}

                </Text>





            </Box>




            <Box sx={{
                position: 'absolute',
                left: '89px',
                top: '112px',
                right: '80px',
                minHeight: '368px',
                background: '#FFFFFF',
                borderRadius: '3px',
                border: '1px solid #D0D7DE'
            }}>

                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        {profileData?.profile_details?.pan?.pan}
                    </Text>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {allDataFeilds[0]?.pan_name.substring(0, 7)}

                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>

                            {profileData?.profile_details?.pan?.pan}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>

                            {allDataFeilds.length > 0 ? allDataFeilds[0]?.pan_password : ""}
                        </Text>

                    </Box>


                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark
                        }}
                    >
                        Last synced on : {

                            allDataFeilds.length > 0 ?
                                profileData == undefined ? "Not Synched" :
                                    "" + new Date(allDataFeilds[0]?.updated_timestamp).toDateString() + " " + new Date(allDataFeilds[0]?.updated_timestamp).toLocaleTimeString() + ""

                                : ""}
                    </Text>

                    <Button
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => {
                            showSyncDialog("")
                        }}
                    >
                        SYNC
                    </Button>

                </Box>







                <Box sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Date of birth
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Last return filed
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aadhaar status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Central Jurisdiction
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        State Jurisdiction
                    </Text>

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {/* {profileData?.profile_details?.pan?.name} */}
                        {
                           profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['AssesseeName']['FirstName'] || ""
                           
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['AssesseeName']['SurNameOrOrgName'] || ""
                        }
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {


                            profileData !== undefined && profileData !== null ?
                                new Date(profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['DOB']).getDate() + "-" + (new Date(profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['DOB']).getMonth() + 1) + "-" + new Date(profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['DOB']).getFullYear()
                                : ""
                        }

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['ResidenceNo']+" " || " "
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['ResidenceName']+" " || " "
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['RoadOrStreet']+" " || " "
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['CityOrTownOrDistrict']+" " || " "
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['LocalityOrArea']+" " || " "
                        }
                        {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['PinCode']+" " || " "
                        }


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                         {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['EmailAddress']+" " || " "
                         }
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                         {
                            profileData?.['result']['2020-2021']['ITR']['ITR1']['PersonalInfo']['Address']['MobileNo']+" " || " "
                         }
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgreen,
                        minHeight: '20px'
                    }}>

                        
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>

                        
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                         

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px'
                    }}>
                        
                    </Text>

                </Box>





            </Box>







            {ITSyncDialog("")}

            <AddPanDialog />
            <UploadPanDialog />
         
        </Box>
    )

}

export default ItSanbox