import React from "react";
import { Box, Button, IconButton, Text, Avatar, TextInput, Textarea, Label, ActionMenu, ActionList, Heading, FormControl, Checkbox, Select, Spinner, Tooltip, StyledOcticon, Dialog } from "@primer/react"
import { CheckCircledIcon, Cross2Icon, UploadIcon, ViewGridIcon, ViewHorizontalIcon, InfoCircledIcon, MagnifyingGlassIcon, DotIcon, DotsHorizontalIcon, EnvelopeClosedIcon, FileIcon, HomeIcon, LightningBoltIcon, LockClosedIcon, Pencil1Icon, PersonIcon, PlusIcon, ReaderIcon, EyeOpenIcon, ExternalLinkIcon, DotsVerticalIcon } from "@radix-ui/react-icons";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { addCredentialOpenClose, contactProfileOpenClose, deleteOrganizationDialogOpenClose, updateContactDirectory, updateContactDirectoryOrgData, updateContactDirectoryDirectors, uploadOrganizationFiles, deleteOrganizationFiles, getIndiviualsCompaniesById, removeOrgCredentials, getOrganization, postZohoInvCustomer, getZohoInvoiceByCustomer, downloadZohoInvoice, updateContactCompliance, updatecomplianceCategory } from "../redux/features/contactSlice";
import { UnderlineNav } from '@primer/react/drafts'
import { OrganizationIcon, FeedPersonIcon, XCircleFillIcon, CheckCircleFillIcon } from "@primer/octicons-react";
import SpaceTheme from "./SpaceTheme";
import { getProjectServices, getSpaceCollaborators } from "../redux/features/spaceSlice";
import AWS from 'aws-sdk'
import { getDropboxAuth, getGoogleDriveAuth, getGoogleMailAuth, getZohoAuth, openCloseTaskExpansion } from "../redux/features/taskSlice";
import TaskExpansion from "./TaskExpansion";
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import DeleteOrganization from "../Workplace/Dialogs/DeleteOrganization";


const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"
const testAvatar = "https://s3.us-east-2.amazonaws.com/account.info/Testing/_Base+Avatar.svg"
const emptyImg = "https://d2fcqkt9srr3ti.cloudfront.net/fileEmptyImg.svg"
const docIcon = "https://d2fcqkt9srr3ti.cloudfront.net/docIcon.svg"
const imgIcon = "https://d2fcqkt9srr3ti.cloudfront.net/imgIcon.svg"

const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET = process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION

AWS.config.update({
    accessKeyId: IAM_USER_KEY,
    secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
    region: REGION,
})

function formatSize(size) {
    if (size < 1024) {
        return size + ' B';
    } else if (size < (1024 ** 2)) {
        return (size / 1024).toFixed(2) + ' KB';
    } else if (size < (1024 ** 3)) {
        return (size / (1024 ** 2)).toFixed(2) + ' MB';
    } else if (size < (1024 ** 4)) {
        return (size / (1024 ** 3)).toFixed(2) + ' GB';
    } else if (size < (1024 ** 5)) {
        return (size / (1024 ** 4)).toFixed(2) + ' TB';
    } else {
        return (size / (1024 ** 5)).toFixed(2) + ' PB';
    }
}

function formatURL(urlString) {
    let url;
    try {
        url = new URL(urlString);
    } catch (e) {
        // The URL is invalid, return null
        return null;
    }

    // Ensure the URL has the correct protocol and domain
    if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return null;
    }
    if (!url.hostname || !url.hostname.match(/\./)) {
        return null;
    }

    // Add "www." to the beginning of the hostname if it's not already present
    if (!url.hostname.match(/^www\./)) {
        url.hostname = 'www.' + url.hostname;
    }

    return url.toString();
}



function ContactProfile(props) {

    const gapi = window?.gapi
    const google = window?.google

    const zohoTokens = JSON.parse(localStorage.getItem("zohoTokens")) || ""

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const contactProfileData = useSelector((state) => state.contactProfileData)
    const reloadCredentialTrigger = useSelector((state) => state.contactProfileData.reloadCredentialTrigger)
    const taskTrigger = useSelector((state) => state.taskData.taskTrigger)
    const credentialOpenClose = useSelector((state) => state.contactProfileData.credentialOpenClose)
    const fileRef = React.useRef()
    const computerFileRef = React.useRef()
    const emailFileRef = React.useRef()
    const initialFocusRef1 = React.useRef()

    const [selectedComplianceCheckBox, setselectedComplianceCheckBox] = React.useState("")
    const [selectedEmailFile, setselectedEmailFile] = React.useState(null)
    const [orgTypeArray, setorgTypeArray] = React.useState([])
    const [showComplianceActionList, setshowComplianceActionList] = React.useState({
        isShown: false,
        inputValue: '',
        selectedCompId: '',
        selectedCategory: ''
    })

    const [activityCounts, setactivityCounts] = React.useState({
        openTaskCount: 0,
        inProgressCount: 0,
        overdueCount: 0,
        completedCount: 0
    })

    const [showloadingSpinner, setshowloadingSpinner] = React.useState(false)
    const [orgImage, setorgImage] = React.useState("")
    const [projectFileSize, setprojectFileSize] = React.useState("0.0")
    const [filesArray, setfilesArray] = React.useState([])
    const [credentialArray, setcredentialArray] = React.useState([])
    const [visiblePassword, setvisiblePassword] = React.useState("")

    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [showUploadingComponent, setshowUploadingComponent] = React.useState(false)
    const [showEmptyPage, setshowEmptyPage] = React.useState(false)
    const [isGridView, setisGridView] = React.useState(false)
    const [showAddComplianceDialog, setshowAddComplianceDialog] = React.useState(false)

    const [companyDataDb, setcompanyDataDb] = React.useState({
        org_name: "",
        org_name_validation: '',
        org_type: "PLC",
        org_no: "",
        org_no_validation: "",
        org_state: "",
        org_state_validation: "",
        org_incorporation_date: "",
        org_incorporation_date_validation: "",
        org_pan: "",
        org_pan_validation: "",
        org_gstNo: "",
        org_additional_info: "",
        org_additional_info_validation: "",
        org_address_line1: "",
        org_address_line1_validation: "",
        org_address_line2: "",
        org_address_line3: "",
        org_city: "",
        org_state_province: "",
        org_postal_zipcode: "",
    })

    const [isZohoInstalled, setisZohoInstalled] = React.useState(false)


    const [items, setItems] = React.useState([
        { navigation: 'Activity', icon: LightningBoltIcon, counter: 1, isSelected: true },
        { navigation: 'Compliance', icon: ReaderIcon, isSelected: false },
        { navigation: 'Profile', icon: HomeIcon, isSelected: false },
        { navigation: 'Email', icon: EnvelopeClosedIcon, counter: 1, isSelected: false },
        { navigation: 'Credentials', icon: LockClosedIcon, isSelected: false },
        { navigation: 'Task', icon: CheckCircledIcon, counter: 1, isSelected: false },
        { navigation: 'Files', icon: FileIcon, isSelected: false },
        { navigation: 'Team', icon: PersonIcon, counter: 0, isSelected: false },
        { navigation: 'Invoice', icon: ReaderIcon, counter: 0, isSelected: false },
    ])

    const [emailData, setemailData] = React.useState({
        emailInput: '',
        emailInputValidation: '',
        subjectInput: '',
        descriptonInput: ''
    })


    const [selectedIndex, setSelectedIndex] = React.useState(0)

    const [companyData, setcompanyData] = React.useState([

        { label: "Company", value: "Hexcode think private Limited" },
        { label: "CIN", value: "U25200DL2009PTC189293" },
        { label: "Status", value: "Active" },
        { label: "RoC", value: "RoC-Delhi" },
        { label: "Reg No", value: "Reg No : 73585" },
        { label: "Incorporated", value: "13 April 2009" },
        { label: "Age", value: "13 years, 11 month, 3 days" },
        { label: "Category", value: "Company limited by Shares" },
        { label: "Sub Category", value: "Non-govt company" },
        { label: "Class", value: "Private" },
        { label: "Activity", value: "Manufacture of plastic products" }

    ])

    const [category, setcategory] = React.useState([])
    const [categories, setcategories] = React.useState([])
    const [compliance, setcompliance] = React.useState([])
    const [copycompliance, setcopycompliance] = React.useState([])
    const [companyTasks, setcompanyTasks] = React.useState([])
    const [compliances, setcompliances] = React.useState([])
    const [invoices, setinvoices] = React.useState([])

    const [directorsDb, setdirectorsDb] = React.useState([])

    const onCheckCompliance = (props, index, comp) => {


        setselectedComplianceCheckBox(comp.category_id)
        setcompliance((prevState) => {

            const newState = [...prevState]; // create a copy of the state
            newState[index] = {
                ...newState[index], // create a copy of the item at the specified index
                isChecked: props,
            };
            return newState;

        })

    }

    const showAddCredentialDialog = (props) => {


        dispatch(addCredentialOpenClose({
            credentialOpenClose: true,
            contactId: contactProfileData?.data?.contact_id,
            editCredentialId: ""
        }))

    }

    const showEditCredentialDialog = (props) => {

        dispatch(addCredentialOpenClose({
            credentialOpenClose: true,
            contactId: contactProfileData?.data?.contact_id,
            editCredentialId: props
        }))
    }


    const addToZohoCustomer = (props) => {

        const customerId = contactProfileData?.data?.contact_id
        const customerName = contactProfileData?.title

        dispatch(postZohoInvCustomer({
            userId: userId,
            userEmail: userEmail,
            zohoToken: zohoTokens,
            customerId: customerId,
            customerName: customerName,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("postZohoInvCustomer", data.payload)
            const contactName = data?.payload?.createContactResponse?.data?.contact?.contact_name
            const message = data?.payload?.createContactResponse?.data.message

            const code = data?.payload?.createContactResponse?.data?.code
            if (contactName === customerName) {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Sucessfully created",
                    intent: Intent.SUCCESS
                });


            } else {

                if (code == 9017) {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Zoho :" + message,
                        intent: Intent.PRIMARY
                    });

                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Already created " + customerName,
                        intent: Intent.PRIMARY
                    });

                }




            }





        })




    }

    const showDeleteOrgDailog = (props) => {


        dispatch(deleteOrganizationDialogOpenClose({
            deleteOrganization: true,
        }))

    }


    const closeContactProfile = (props) => {

        dispatch(contactProfileOpenClose({
            openClose: false,
            title: ""
        }))

        setinvoices([])
    }


    const showTaskExpansion = (props) => {


        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props.taskId
        }))

    }


    const handleRemoveDirectors = (props, index) => {



        setdirectorsDb((prevState) => {

            const newState = [...prevState]
            newState.splice(index, 1)

            dispatch(updateContactDirectoryDirectors({
                userId: userId,
                userEmail: userEmail,
                contactId: contactProfileData?.data?.contact_id,
                directors: newState
            })).then((data) => {

                console.log("update company DIREXTORS data", data.payload)

            })



            return newState

        })





    }

    const deleteCredntials = (props) => {

        dispatch(removeOrgCredentials({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            deleteCredentialId: props.credentialId
        })).then((data) => {

            getIndiviualsCompaniesByIdFromDb("")
        })



    }

    const updateOrgData = (props) => {

        dispatch(updateContactDirectoryOrgData({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            orgData: companyDataDb
        })).then((data) => {

            console.log("update company data", data.payload)

        })
    }

    const updateDirectorsInfoInDb = (props) => {

        dispatch(updateContactDirectoryDirectors({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            directors: directorsDb
        })).then((data) => {

            console.log("update company DIREXTORS data", data.payload)

        })

    }


    const updateProfileDataInDb = (props, e) => {

        if (props === "orgName") {
            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_name: e.target.value }
                return newState
            })


        } else if (props === "orgNo") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_no: e.target.value }
                return newState
            })


        } else if (props === "orgGstNo") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_gstNo: e.target.value }
                return newState
            })


        } else if (props === "orgPan") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_pan: e.target.value }
                return newState
            })


        } else if (props === "orgIncorpDate") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_incorporation_date: e.target.value }
                return newState
            })


        } else if (props === "orgState") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_state: e.target.value }
                return newState
            })


        } else if (props === "orgAdditionalInfo") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_additional_info: e.target.value }
                return newState
            })


        } else if (props === "orgAddressline1") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_address_line1: e.target.value }
                return newState
            })


        } else if (props === "orgAddressline2") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_address_line2: e.target.value }
                return newState
            })


        } else if (props === "orgAddressline3") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_address_line3: e.target.value }
                return newState
            })


        } else if (props === "orgCity") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_city: e.target.value }
                return newState
            })

        } else if (props === "orgState") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_state: e.target.value }
                return newState
            })

        } else if (props === "orgStateProvince") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_state_province: e.target.value }
                return newState
            })


        } else if (props === "orgPostalZipCode") {

            setcompanyDataDb((prevState) => {
                const newState = { ...prevState, org_postal_zipcode: e.target.value }
                return newState
            })




        }



    }


    const handlePickEmailFile = (props) => {


        setselectedEmailFile(props)
    }


    async function pickerCallback(data) {

        const google = window.google
        let url = 'nothing';
        if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            let doc = data[google.picker.Response.DOCUMENTS][0];
            url = doc[google.picker.Document.URL];


            let message = `You picked: ${url}`;
            console.log("selected file ", doc)
            console.log("selected google drive folder", data)


            const updateData = {
                ...doc,
                attachment_file_name: doc.name,
                attachment_size: '' + doc.sizeBytes + '',
                attachment_url: doc.embedUrl,
                creator_id: userId,
                attachment_timestamp: "" + new Date() + "",
                attachment_type: "Google Drive"
            }
            setshowUploadingComponent(!showUploadingComponent)
            uploadOrgFilesInDb(updateData)


        }

    }

    const handleSelectDropboxPicker = (props) => {


        dispatch(getDropboxAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then(async (data) => {


            console.log("getGoogleAuth", data.payload)
            const code = data?.payload?.checkApp?.code
            const googleTokenData = data?.payload?.data
            const message = data?.payload?.data?.message

            console.log("code", code)
            console.log("message", message)

            if (code == 404) {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{"App not installed"}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Open Apps.</Text>,
                        onClick: () => navigate('/apps')
                    }
                });


            } else if (code == 200) {

                if (message === "Already authenticated") {

                    // const refreshToken = googleTokenData?.refresh_token;
                    // const accessToken = googleTokenData?.access_token

                    //   const redirectUrl = window.location.origin
                    //   const appKey = 'r1ex80p99zrn57f'
                    //   const appSecret = 'tp2l1u3cfgsxyc8'


                    //   const clientId = 'r1ex80p99zrn57f'
                    //   const clientSecret = 'tp2l1u3cfgsxyc8'


                    //   const requestOptions = {
                    //     method: 'POST',
                    //     headers: {
                    //       'Content-Type': 'application/x-www-form-urlencoded',
                    //     },
                    //     body: `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}&client_secret=${clientSecret}`,
                    //   };

                    //   // Make the fetch request
                    //   const authTokens =await fetch('https://api.dropboxapi.com/oauth2/token', requestOptions)
                    //     .then(response => response.json())

                    //   console.log("authTokens drop box",authTokens)

                    //   const newAccessToken = authTokens?.access_token


                    //   const chooserUrl = `https://www.dropbox.com/chooser?access_token=${newAccessToken}&app_key=${appKey}&extensions=&folderselect=true&from_login=true&iframe=false&link_type=preview&multiselect=false&origin=${redirectUrl}&trigger=js&version=2`;
                    //   window.open(chooserUrl);


                    //   const dbx = new window.Dropbox({
                    //       clientId: appKey,
                    //       clientSecret: appSecret,
                    //       refreshToken: refreshToken
                    //   });

                    //  console.log("dbx", window.Dropbox)


                    const dropBoxoptions = {


                        success: async function (files) {



                            const fileUrl = files[0].link
                            const fileName = files[0].name
                            const fileSize = files[0].bytes


                            const updateData = {
                                ...files[0],
                                attachment_file_name: fileName,
                                attachment_size: '' + fileSize + '',
                                attachment_url: fileUrl,
                                creator_id: userId,
                                attachment_timestamp: "" + new Date() + "",
                                attachment_type: "Dropbox"
                            }

                            //  uploadGdriveFolderInDb(updateData)
                            setshowUploadingComponent(!showUploadingComponent)
                            uploadOrgFilesInDb(updateData)

                        },
                        cancel: function () {

                        },

                        linkType: "preview",//"preview", // or "direct"
                        folderselect: true, // or true


                    };
                    window.Dropbox.choose(dropBoxoptions);

                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message:
                            <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '14px',

                                }}>{"App not authenticated"}</Text>
                            </Box>
                        , intent: Intent.PRIMARY, action: {

                            text: <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>Open Apps.</Text>,
                            onClick: () => navigate('/apps')
                        }
                    });

                }



            }



        })


    }

    const handleSelectDrivePicker = (props) => {


        dispatch(getGoogleDriveAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then(async (data) => {


            console.log("getGoogleAuth", data.payload)
            const code = data?.payload?.checkApp?.code
            const googleTokenData = data?.payload?.data
            const message = data?.payload?.data?.message

            console.log("code", code)
            console.log("message", message)

            if (code == 404) {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{"App not installed"}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Open Apps.</Text>,
                        onClick: () => navigate('/apps')
                    }
                });


            } else if (code == 200) {

                if (message === "Already authenticated") {

                    const refreshToken = googleTokenData?.refresh_token;

                    const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                    const clientSecret = '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'
                    const tokenUrl = 'https://oauth2.googleapis.com/token';
                    const payload = {
                        grant_type: 'refresh_token',
                        client_id: clientId,
                        client_secret: clientSecret,
                        refresh_token: refreshToken,
                    };
                    const newTokenResult = await fetch(tokenUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams(payload).toString(),
                    }).then(response => response.json())
                    const newAccessToken = newTokenResult?.access_token
                    console.log("newAccessToken", newAccessToken)

                    if (newAccessToken !== null && newAccessToken !== "" && newAccessToken !== undefined) {


                        const google = window.google
                        const API_KEY = 'AIzaSyD8VZ6ObpUM8ic0ZmLdElVXtgQ0l6QoKcM'
                        const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                        const APP_ID = '686241795638'

                        let docsView =
                            new google.picker.DocsView(google.picker.ViewId.FOLDERS)
                                .setParent('root')
                                .setSelectFolderEnabled(true);

                        const picker = new google.picker.PickerBuilder()
                            .addView(google.picker.ViewId.DOCS)
                            .addView(docsView)
                            .setOAuthToken(newAccessToken)
                            .setDeveloperKey(API_KEY)
                            .setCallback(pickerCallback)
                            .build();
                        picker.setVisible(true);



                    } else {

                    }



                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message:
                            <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '14px',

                                }}>{"App not authenticated"}</Text>
                            </Box>
                        , intent: Intent.PRIMARY, action: {

                            text: <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>Open Apps.</Text>,
                            onClick: () => navigate('/apps')
                        }
                    });

                }



            }



        })



    }

    const uploadOrgFilesInDb = (props) => {

        //uploadOrganizationFiles

        dispatch(uploadOrganizationFiles({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            orgFiles: props
        })).then((data) => {

            setfilesArray(data.payload.data[0].org_files)
            setshowUploadingComponent(false)

            if (data.payload.data[0].org_files.length <= 0) {
                setshowEmptyPage(true)
            } else {
                setshowEmptyPage(false)
            }

        })


    }

    const calculateProjectFolderSize = async (props) => {

        let folderName = 'Organizations/clientDirectory/' + localStorage.getItem("projectId")
        let totalSize = 0;
        let data = await deskiplatformBucket.listObjectsV2({ Bucket: BUCKET_NAME, Prefix: folderName }).promise();
        for (let i = 0; i < data.Contents.length; i++) {
            totalSize += data.Contents[i].Size;
        }

        const totalSizegb = formatSize(totalSize)
        setprojectFileSize(totalSizegb)


    }


    const uploadOrganizationFilesPick = (props) => {

        setshowUploadingComponent(!showUploadingComponent)


        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'Organizations/clientDirectory/' + localStorage.getItem("projectId") + '/' + props.name,
            'ContentType': props.type
        };

        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {
                console.log(err, err.stack)
            } else {

                console.log(data)

                const updateData = {
                    attachment_file_name: props.name,
                    attachment_size: '' + props.size + '',
                    attachment_url: data.Location,
                    creator_id: userId,
                    attachment_timestamp: "" + new Date() + ""
                }

                uploadOrgFilesInDb(updateData)


            };



        })


    }


    const updateOrganizationImage = (props) => {



        setshowloadingSpinner(true)

        setorgImage(URL.createObjectURL(props))

        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'Organizations/' + contactProfileData?.data?.contact_id,
            'ContentType': props.type
        };


        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {
                console.log(err, err.stack)
                setshowloadingSpinner(false)
            } else {

                console.log(data)



                setshowloadingSpinner(false)


                dispatch(updateContactDirectory({
                    orgImgUrl: data.Location,
                    userId: userId,
                    userEmail: userEmail,
                    contactId: contactProfileData?.data?.contact_id
                })).then((data) => {



                })




            };



        })






    }

    const handleRemoveOrgFiles = (props) => {

        dispatch(deleteOrganizationFiles({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            deleteAttachmentId: props.attachment_id
        })).then((data) => {
            console.log("delete response org file", data.payload)
            setfilesArray(data.payload.data[0].org_files)





            if (data.payload.data[0].org_files.length <= 0) {
                setshowEmptyPage(true)
            } else {
                setshowEmptyPage(false)
            }


        })


    }

    const getSpaceCollaboratorFromDb = (props) => {



        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
            setItems((prevState) => {

                const newState = prevState.map((row, index) => {

                    if (row.navigation === "Team") {

                        return { ...row, counter: data?.payload?.collaboratorArray?.length }
                    } else {
                        return row
                    }
                })

                return newState

            })


        })

    }

    const getIndiviualsCompaniesByIdFromDb = (props) => {

        dispatch(getIndiviualsCompaniesById({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id
        })).then((data) => {

            setcredentialArray(data.payload.data[0].credentials)

        })

    }

    const getIndiviualsCompaniesActivityFromDb = (props) => {

        dispatch(getIndiviualsCompaniesById({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setactivityCounts(data?.payload?.activityCounts)

            setItems((prevState) => {
                const newState = prevState.map((row, index) => {

                    if (row.navigation === "Task") {

                        return ({ ...row, counter: data?.payload?.activityCounts?.allTaskCount })

                    } else {
                        return row
                    }

                })

                return newState
            })





        })


    }

    const getZohoInvoiceByCustomerFromApi = (props) => {




        dispatch(getZohoInvoiceByCustomer({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            customerName: props
        })).then((data) => {

            console.log("getZohoInvoiceByCustomer", data.payload)
            const invoices = [...data?.payload?.data]
            setinvoices(invoices)
            setItems((prevState) => {

                const newState = prevState.map((tab) => {

                    if (tab.navigation === "Invoice") {
                        return ({ ...tab, counter: invoices.length })
                    } else {
                        return ({ ...tab })
                    }

                })
                return newState


            })




        })

    }

    const handleDownloadInvoicePdf = (props) => {

        dispatch(downloadZohoInvoice({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            invoiceId: props
        })).then((data) => {

            console.log("downloadZohoInvoice", data?.payload)

            const respHtml = data?.payload?.data?.data
            const newWindow = window.open('', '_blank');
            newWindow.document.open();
            newWindow.document.write(respHtml);
            newWindow.document.close();
            newWindow.onload = function () {
                newWindow.print();
                newWindow.onafterprint = function () {
                    newWindow.close();
                };
            };

            // const resPdf =  data?.payload?.pdf?.data
            // // var bin = window.atob(resPdf);
            // // console.log('File Size:', Math.round(bin.length / 1024), 'KB');

            // var file = new File([resPdf], "anc.pdf", {type:"application/pdf"});
            // var url  = window.URL.createObjectURL(file);
            // console.log(url)
            // window.open(url)
        })


    }

    const getIndiviualsCompaniesTasksFromDb = (props) => {

        dispatch(getIndiviualsCompaniesById({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const companyTasks = [...data.payload.companyTasks]
            const companySubatsk = [...data.payload.companySubtasks]

            const totalTasks = companyTasks.length + companySubatsk.length

            setItems((prevState) => {
                const newState = prevState.map((row, index) => {

                    if (row.navigation === "Task") {

                        return ({ ...row, counter: totalTasks })

                    } else {
                        return row
                    }

                })

                return newState
            })

            setcompanyTasks(companyTasks.concat(companySubatsk))

        })

    }


    const getOrganizationFromDb = (props) => {

        dispatch(getOrganization({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getOrganization", data.payload)
            var uniqueArray = []
            const orgTypeArray = [...data?.payload?.data].map((row, index) => {

                const newArray = [...row.org_types].map((row2, index) => {

                    uniqueArray.push(row2)
                    return ({ ...row2 })
                })
                return newArray
            })

            setorgTypeArray(uniqueArray)



        })

    }

    const getZohoAuthFromDb = (props) => {

        dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            console.log("getZohoAuth In Org Dialog", data.payload)
            const appCode = data?.payload?.checkApp?.code
            const refreshToken = data?.payload?.data?.refresh_token
            if (appCode === 200) {

                setisZohoInstalled(true)

            } else if (appCode === 404) {

                setisZohoInstalled(false)
            }



        })

    }


    const getAllCategories = (props) => {




        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setcategories(data?.payload.category)
            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)
            setcopycompliance(sortedArray)

        })

    }

    const toBase64 = async file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    function unicodeEscape(str) {
        return str.replace(/[\u007F-\uFFFF]/g, function (chr) {
            return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).slice(-4);
        });
    }

    const handleSendEmail = async (props) => {


        if (emailData.emailInput === "") {

            setemailData((prevState) => {

                return ({ ...prevState, emailInputValidation: 'error' })
            })


        } else {






            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (regex.test(emailData.emailInput.trim()) === true) {


                dispatch(getGoogleMailAuth({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: localStorage.getItem("projectId")
                })).then(async (data) => {


                    console.log("getGoogleAuth", data.payload)
                    const code = data?.payload?.checkApp?.code
                    const googleTokenData = data?.payload?.data
                    const message = data?.payload?.data?.message

                    console.log("code", code)
                    console.log("message", message)

                    if (code == 404) {

                        Toaster.create({ position: Position.TOP }).show({
                            message:
                                <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '14px',

                                    }}>{"App not installed"}</Text>
                                </Box>
                            , intent: Intent.PRIMARY, action: {

                                text: <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '14px',

                                }}>Open Apps.</Text>,
                                onClick: () => navigate('/apps')
                            }
                        });


                    } else if (code == 200) {

                        if (message === "Already authenticated") {

                            const refreshToken = googleTokenData?.refresh_token;
                            const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                            const clientSecret = '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'
                            const tokenUrl = 'https://oauth2.googleapis.com/token';
                            const payload = {
                                grant_type: 'refresh_token',
                                client_id: clientId,
                                client_secret: clientSecret,
                                refresh_token: refreshToken,
                            };
                            const newTokenResult = await fetch(tokenUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                body: new URLSearchParams(payload).toString(),
                            }).then(response => response.json())
                            const newAccessToken = newTokenResult?.access_token
                            console.log("newAccessToken", newAccessToken)

                            if (newAccessToken !== null && newAccessToken !== "" && newAccessToken !== undefined) {





                                if (selectedEmailFile == null) {

                                    const userId = 'me'
                                    const token = newAccessToken;


                                    const message =
                                        "To: " + emailData.emailInput + "\r\n" +
                                        "Subject: " + emailData.subjectInput + "\r\n\r\n" +
                                        emailData.descriptonInput;
                                    const encodedMessage = window.btoa(message)
                                    const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')



                                    const emailContent = {
                                        raw: reallyEncodedMessage
                                    };



                                    const apiUrl = `https://gmail.googleapis.com/gmail/v1/users/${userId}/messages/send`;
                                    const mailResponse = await fetch(apiUrl, {
                                        method: 'POST',
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(emailContent),
                                    }).then((response) => response.json())
                                    console.log("mail response", mailResponse)
                                    if (mailResponse?.id !== null && mailResponse.id !== "" && mailResponse.id !== undefined) {


                                        Toaster.create({ position: Position.TOP }).show({
                                            message: "Mail send",
                                            intent: Intent.PRIMARY
                                        });

                                        setemailData((prevState) => {
                                            return ({
                                                ...prevState,
                                                emailInput: '',
                                                emailInputValidation: '',
                                                descriptonInput: '',
                                                subjectInput: ''

                                            })
                                        })

                                    }


                                } else {


                                    const userId = 'me';
                                    const token = newAccessToken;
                                    const attachmentContent = await toBase64(selectedEmailFile);
                                    const boundary = '' + Math.floor(Math.random() * (1000000 - 9999999 + 1)) + 1000000 + '' //'foo_bar_baz134234';
                                    const message =
                                        `To: ${emailData.emailInput}\r\n` +
                                        `Subject: ${emailData.subjectInput}\r\n` +
                                        'MIME-Version: 1.0\r\n' +
                                        `Content-Type: multipart/mixed; boundary=${boundary}\r\n\r\n` +
                                        `--${boundary}\r\n` +
                                        `Content-Type: text/plain; charset="UTF-8"\r\n` +
                                        'MIME-Version: 1.0\r\n\r\n' +
                                        emailData.descriptonInput + '\r\n\r\n' +
                                        `--${boundary}\r\n` +
                                        `Content-Type: ${selectedEmailFile.type}\r\n` +
                                        'MIME-Version: 1.0\r\n' +
                                        'Content-Transfer-Encoding: base64\r\n' +
                                        `Content-Disposition: attachment; filename=${selectedEmailFile.name}\r\n\r\n` +
                                        attachmentContent.split(",")[1] + '\r\n' +
                                        `--${boundary}--`;

                                    // Encode the entire message
                                    const encodedMessage = window.btoa(unicodeEscape(message));

                                    const emailContent = {
                                        raw: encodedMessage,
                                    };

                                    const apiUrl = `https://gmail.googleapis.com/gmail/v1/users/${userId}/messages/send?uploadType=media`;
                                    const mailResponse = await fetch(apiUrl, {
                                        method: 'POST',
                                        headers: {
                                            'Authorization': `Bearer ${token}`,
                                            'Content-Type': 'message/rfc822',
                                        },
                                        body: JSON.stringify(emailContent),
                                    }).then((response) => response.json());

                                    console.log("mail response", mailResponse)
                                    if (mailResponse?.id !== null && mailResponse.id !== "" && mailResponse.id !== undefined) {


                                        Toaster.create({ position: Position.TOP }).show({
                                            message: "Mail send",
                                            intent: Intent.PRIMARY
                                        });

                                        setemailData((prevState) => {
                                            return ({
                                                ...prevState,
                                                emailInput: '',
                                                emailInputValidation: '',
                                                descriptonInput: '',
                                                subjectInput: ''

                                            })
                                        })

                                    }


                                }






                            } else {



                            }



                        } else {

                            Toaster.create({ position: Position.TOP }).show({
                                message:
                                    <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '14px',

                                        }}>{"App not authenticated"}</Text>
                                    </Box>
                                , intent: Intent.PRIMARY, action: {

                                    text: <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '14px',

                                    }}>Open Apps.</Text>,
                                    onClick: () => navigate('/apps')
                                }
                            });

                        }



                    }



                })



            } else {

                setemailData((prevState) => {
                    return ({
                        ...prevState,
                        emailInputValidation: 'error'
                    })
                })



            }







        }







    }

    const updateContactComplianceIndB = (props) => {

        dispatch(updateContactCompliance({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            compliance: props

        })).then((data) => {


            console.log("updateContactCompliance", data.payload)
            const newCompliance = [...data.payload.data]
            setcompliance(newCompliance)
            if (newCompliance.length > 0) {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Successfully  Updated",
                    intent: Intent.SUCCESS
                });
            }


        })

    }

    const updateContactComplinaceCategoryInDb = (props) => {

        dispatch(updatecomplianceCategory({
            userId: userId,
            userEmail: userEmail,
            contactId: contactProfileData?.data?.contact_id,
            complianceCategory: props
        })).then((data) => {

            console.log("updatecomplianceCategory", data.payload)
            const newComplianceCategory = [...data.payload.data]

            if (newComplianceCategory.length > 0) {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Successfully  Updated",
                    intent: Intent.SUCCESS
                });
            }

        })

    }



    const updateCompanyComplianceInDb = (props) => {


        if (showComplianceActionList.selectedCompId !== "") {

            if (compliance.find((cData) => cData.compliance_id === props.selectedCompId)) {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Already added",
                    intent: Intent.PRIMARY
                });


            } else {

                console.log("copycompliance", copycompliance)
                const newprops = copycompliance.find((cData) => cData.service_id === props.selectedCompId)

                console.log("newprops", newprops)

                setcompliance((prevState) => {
                    const newState = [...prevState,
                    {
                        due_date: "",
                        isChecked: true,
                        timestamp: +"" + new Date() + "",
                        creator_id: userId,
                        category_id: newprops?.category?.category_id,
                        billing_type: "",
                        filingPeriod: "",
                        remindMeType: "",
                        amountPayable: "",
                        compliance_id: newprops?.subcategory?.compliance_id,
                        complianceType: newprops?.subcategory?.complianceType,
                        compliance_name: newprops?.subcategory?.compliance_name
                    }]


                    updateContactComplianceIndB(newState)

                    return newState
                })


                if (category.find((cat) => cat.category_id === props.selectedCategory)) {



                } else {

                    const newCategory = categories.find((cData) => cData.category_id === props.selectedCategory)

                    setcategory((prevState) => {

                        const newState = [...prevState, {
                            "isChecked": true,
                            "timestamp": "" + new Date() + "",
                            "creator_id": userId,
                            "category_id": newCategory.category_id,
                            "category_name": newCategory.category_name,
                            "additionalInfo": ""
                        }]
                        updateContactComplinaceCategoryInDb(newState)
                        return newState
                    })

                }




                setshowComplianceActionList(
                    {
                        isShown: false,
                        inputValue: '',
                        selectedCompId: '',
                        selectedCategory: ''
                    }
                )
                setshowAddComplianceDialog(false)

            }


        } else {

            Toaster.create({ position: Position.TOP }).show({
                message: "Select compliance",
                intent: Intent.PRIMARY
            });

        }





    }



    const gmailComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px'

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Email</Text>


                {/* {JSON.stringify(gapi?.client?.getToken())} */}

                <TextInput sx={{
                    width: '100%',
                    height: '40px',
                    position: 'relative',
                    margin: '10px 0px 0px 0px',
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}
                    placeholder="Email adddress"
                    value={emailData.emailInput}
                    validationStatus={emailData.emailInputValidation}
                    onChange={(e) => {
                        setemailData((prevState) => {
                            return ({ ...prevState, emailInput: e.target.value, emailInputValidation: '' })
                        })
                    }}
                />

                <TextInput sx={{
                    width: '100%',
                    height: '40px',
                    position: 'relative',
                    margin: '10px 0px 0px 0px',
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}
                    placeholder="Subject"
                    value={emailData.subjectInput}

                    onChange={(e) => {
                        setemailData((prevState) => {
                            return ({ ...prevState, subjectInput: e.target.value })
                        })
                    }}
                />

                <Textarea
                    sx={{
                        width: '100%',
                        height: '150px',
                        position: 'relative',
                        margin: '10px 0px 0px 0px',
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        color: '#000000'
                    }}
                    placeholder="Description"
                    value={emailData.descriptonInput}
                    onChange={(e) => {
                        setemailData((prevState) => {
                            return ({ ...prevState, descriptonInput: e.target.value })
                        })
                    }}
                />

                <IconButton
                    sx={{
                        position: 'relative',
                        margin: '10px 0px 0px 0px',
                        height: '28px',
                        width: '28px',
                        minHeight: '28px',
                        minWidth: '28px',
                        maxHeight: '28px',
                        maxWidth: '28px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    icon={() => <Box sx={{ display: 'flex' }}> <UploadIcon /> </Box>}
                    onClick={() => { emailFileRef.current.click() }}
                />
                <input ref={emailFileRef} type={"file"} onChange={(e) => handlePickEmailFile(e.target.files[0])} hidden />


                <Button
                    variant="outline"
                    sx={{
                        width: '62px',
                        height: '32px',
                        background: '#0965CE',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        margin: '10px 0px 0px auto',
                        color: '#ffffff',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',

                    }}
                    onClick={() => {
                        handleSendEmail("")
                    }}
                >
                    Send
                </Button>




            </Box>

        )
    }


    const credentialComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px'

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Credentials password</Text>


                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Button
                    leadingIcon={() => <PlusIcon style={{ display: 'flex' }} />}
                    sx={{
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#586069',
                        border: 'none',
                        background: '#ffffff',
                        minHeight: '25px',
                        maxHeight: '25px',
                        display: 'flex',
                        alignItems: 'center'
                    }}

                    onClick={() => {

                        showAddCredentialDialog("")

                    }}
                >Add
                </Button>

                <Box sx={{
                    position: 'absolute',
                    top: '58px',
                    left: '0px',
                    background: '#FFFFFF',
                    right: '0px',
                    bottom: '10px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '23px',

                }}>


                    {credentialArray.map((row, index) => {
                        return (

                            <Box sx={{
                                minWidth: '724px',
                                maxWidth: '724px',
                                width: '724px',
                                height: '185px',
                                minHeight: '185px',
                                marginRight: '54px',
                                background: '#FFFFFF',
                                border: '1px solid #E1E4E8',
                                borderRadius: '8px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>

                                <Box sx={{
                                    position: 'relative',
                                    margin: '20px 18px 0px 25px',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#586069',
                                        height: 'fit-content',
                                        width: 'fit-content',
                                    }}>
                                        {/* GST Portal */}
                                        {row.credentialTyp === "Website" ? "Website" : "DSC"}
                                    </Text>



                                    <ActionMenu>
                                        <ActionMenu.Anchor>
                                            <IconButton
                                                size="small"
                                                variant="invisible"
                                                sx={
                                                    {
                                                        background: '#ffffff',
                                                        color: '#000000',
                                                        border: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        position: 'relative',
                                                        margin: '-18px -6px 0px auto',
                                                    }
                                                }
                                                icon={() => <Pencil1Icon style={{ display: 'flex' }} />}

                                            />
                                        </ActionMenu.Anchor>
                                        <ActionMenu.Overlay>
                                            <ActionList sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                margin: '4px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#24292F',
                                                gap: '2px'
                                            }}>
                                                <ActionList.Item onSelect={() => {
                                                    showEditCredentialDialog(row)
                                                }}>
                                                    Edit
                                                </ActionList.Item>
                                                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: 'border.default', width: '100%', minHeight: '1px' }}>

                                                </Box>
                                                <ActionList.Item variant="danger" onSelect={() => {

                                                    deleteCredntials(row)
                                                }}>
                                                    Delete
                                                </ActionList.Item>
                                            </ActionList>

                                        </ActionMenu.Overlay>

                                    </ActionMenu>







                                </Box>


                                <Box sx={{ position: 'relative', margin: '26px 60px 0px 25px', display: 'flex', flexDirection: 'row', gap: '48px' }}>

                                    <FormControl sx={{
                                        width: '191px',
                                        maxWidth: '191px',
                                        minWidth: '191px',
                                    }}>
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            fontStyle: 'normal',
                                            color: '#24292E',
                                            lineHeight: '18px'
                                        }}>Username</FormControl.Label>
                                        <TextInput
                                            sx={{

                                                fontFamily: 'Segoe UI',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                color: '#24292E',
                                                lineHeight: '20px',
                                                borderRadius: '3px'
                                            }}
                                            trailingAction={
                                                <TextInput.Action
                                                    onClick={() => {

                                                    }}
                                                    aria-label="Clear input"
                                                    sx={{ color: 'fg.subtle' }}
                                                />
                                            }
                                            placeholder="monalisa@gmail.com"
                                            value={row.userName}
                                        />
                                    </FormControl>

                                    <FormControl sx={{
                                        width: '191px',
                                        maxWidth: '191px',
                                        minWidth: '191px',
                                    }}>
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            fontStyle: 'normal',
                                            color: '#24292E',
                                            lineHeight: '18px'
                                        }}>Password</FormControl.Label>
                                        <TextInput
                                            sx={{

                                                fontFamily: 'Segoe UI',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                color: '#24292E',
                                                lineHeight: '20px',
                                                borderRadius: '3px'
                                            }}
                                            trailingAction={
                                                <TextInput.Action
                                                    onClick={() => {
                                                        if (visiblePassword === row.credentialId) {
                                                            setvisiblePassword("")
                                                        } else {
                                                            setvisiblePassword(row.credentialId)
                                                        }

                                                    }}
                                                    icon={() => <EyeOpenIcon style={{ display: 'flex' }} />}
                                                    aria-label="Show passsword"
                                                    sx={{ color: 'fg.subtle', display: 'flex' }}

                                                />
                                            }
                                            validationStatus="warning"
                                            type={visiblePassword === row.credentialId ? "text" : 'password'}
                                            value={row.userPassword}
                                        />
                                    </FormControl>

                                    <FormControl sx={{
                                        width: '161px',
                                        maxWidth: '161px',
                                        minWidth: '161px',
                                    }}>
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            fontStyle: 'normal',
                                            color: '#24292E',
                                            lineHeight: '18px'
                                        }}>Status</FormControl.Label>

                                        <Select validationStatus="success"
                                            style={{
                                                borderRadius: '6px',
                                                minWidth: '161px',
                                                width: '161px',
                                                maxWidth: '161px',
                                                fontFamily: 'Segoe UI',
                                                lineHeight: '20px',

                                            }}

                                        >
                                            <Select.Option value="Website" selected={row.dscType === "Website" ? true : false}>Website</Select.Option>
                                            <Select.Option value="Intheoffice" selected={row.dscType === "In the office" ? true : false}>In the office</Select.Option>

                                        </Select>

                                    </FormControl>


                                </Box>



                                <Box sx={{
                                    position: 'relative',
                                    margin: '29px 49px 0px auto',
                                    width: '161px',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>

                                    {
                                        row.credentialTyp === "Website" ?
                                            <Text
                                                sx={{
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#586069',
                                                    position: 'relative',
                                                    margin: '0px -61px 0px auto',
                                                }}
                                            >Open website : </Text>
                                            :
                                            <Text
                                                sx={{
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#586069',
                                                    position: 'relative',
                                                    margin: '0px -15px 0px auto',
                                                }}
                                            >Last update : </Text>
                                    }


                                    {row.credentialTyp === "Website" ?

                                        <IconButton
                                            size="small"
                                            variant="invisible"
                                            sx={
                                                {
                                                    background: '#ffffff',
                                                    color: '#000000',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    margin: '-2px -6px 0px auto',
                                                }
                                            }
                                            icon={() => <ExternalLinkIcon style={{ display: 'flex' }} />}
                                            onClick={() => {


                                                var url = row.credentialTypeValue
                                                url = url.replace(/^https?:\/\//i, ''); // Remove "http://" or "https://"
                                                url = url.replace(/^www\./i, '');

                                                var site = 'https://www.' + url;
                                                window.open(site, '_blank');







                                            }}
                                        />
                                        :
                                        <Text
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#586069',
                                                position: 'relative',
                                                margin: '0px 0px 0px auto',
                                            }}
                                        >
                                            {new Date(row.updatedTimeStamp).getDate() + "-" + (new Date(row.updatedTimeStamp).getMonth() + 1) + "-" + new Date(row.updatedTimeStamp).getFullYear()}
                                        </Text>

                                    }






                                </Box>






                            </Box>

                        )
                    })}

                    <Box sx={{ minHeight: '90px' }}>

                    </Box>





                </Box>





            </Box>
        )


    }

    const TaskComponents = (props) => {


        return (


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                //  right: '482px',
                right: '86px'
            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Task</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Box sx={{
                    position: 'absolute',
                    top: '65px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto'
                }}>


                    <Box sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        minHeight: 'fit-content',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                        {companyTasks.map((row, index) => {

                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '35px',
                                    borderBottomWidth: 1,
                                    borderBottomStyle: 'solid',
                                    borderColor: '#E0E0E0',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    ':hover': {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    }
                                }}
                                    onClick={() => {


                                        showTaskExpansion({
                                            taskId: row.task_id
                                        })
                                    }}
                                >

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        width: '370x',
                                        maxWidth: '370px',
                                        minWidth: '370px',
                                        minHeight: '19px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        {row.task_name}
                                    </Text>


                                    {row.compliance_id !== null && row.compliance_id !== "" ?

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            width: '62px',
                                            minWidth: '62px',
                                            maxWidth: '62px',

                                        }}>
                                            <Label sx={{
                                                width: 'fit-content',
                                                minWidth: 'fit-content',
                                                maxWidth: 'fit-content',
                                                height: '20px',
                                                background: '#2155A4',
                                                borderRadius: '6px',
                                                border: 'none',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#FFFFFF',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>


                                                <Text sx={{
                                                    minWidth: 'auto',
                                                    width: 'auto',
                                                    maxWidth: '49px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}>


                                                    {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}

                                                </Text>


                                            </Label>
                                        </Box>
                                        :

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            width: '62px',
                                            minWidth: '62px',
                                            maxWidth: '62px',
                                        }}>
                                            <Text sx={{
                                                //  position: 'relative',
                                                //  margin: '0px 0px 0px 50px',
                                                minWidth: '49px',
                                                width: '49px',
                                                maxWidth: '49px',
                                                minWidth: '49px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>

                                            </Text>
                                        </Box>

                                    }


                                    <Box sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 50px',
                                        minWidth: '20px',
                                        maxWidth: '20px',
                                        minHeight: '20px',
                                        maxHeight: '20px',
                                        height: '20px',
                                        width: '20px'
                                    }}>
                                        <Tooltip aria-label={row.user_first_name !== null ? row.user_first_name + " " + row.user_last_name : ""} direction="se" >
                                            {row.user_image_url === "" || row.user_image_url == null ?

                                                <FeedPersonIcon size={20} fill="#586069" /> :

                                                <Avatar sx={{
                                                    height: '20px',
                                                    width: '20px',
                                                    minHeight: '20px',
                                                    minWidth: '20px',


                                                }}
                                                    src={row.user_image_url} />

                                            }
                                        </Tooltip>
                                    </Box>


                                    {row.contact_data !== null ?

                                        <Label sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#24292F',
                                            borderRadius: '3px',
                                            minWidth: 'auto',
                                            width: 'auto',
                                            maxWidth: '250px',


                                        }}>
                                            <Text sx={{
                                                minWidth: 'auto',
                                                width: 'auto',
                                                maxWidth: '250px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>
                                                {row.contact_data.org_name}
                                            </Text>

                                        </Label>

                                        : <Text sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            minWidth: '250px',
                                            width: '250px',
                                            maxWidth: '250px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}>
                                            {""}
                                        </Text>


                                    }




                                    <Box sx={{
                                        position: 'absolute',
                                        right: '29px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>
                                        {row.due_date !== null && row.due_date !== "" ?

                                            <Label sx={{
                                                position: 'relative',
                                                margin: '0px 35px 0px 0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: (Math.ceil((new Date(row.due_date) - new Date()) / (1000 * 60 * 60 * 24))) === 3 ? '#FFFFFF' : '#0969DA',
                                                borderRadius: '6px',
                                                minWidth: '79px',
                                                width: '79px',
                                                maxWidth: '79px',
                                                background: (Math.ceil((new Date(row.due_date) - new Date()) / (1000 * 60 * 60 * 24))) === 3 ? '#CF222E' : '#DDF4FF',
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {new Date(row.due_date).getDate() + "-" + (new Date(row.due_date).getMonth() + 1) + "-" + new Date(row.due_date).getFullYear()}


                                            </Label>

                                            :


                                            <Label sx={{
                                                position: 'relative',
                                                margin: '0px 35px 0px 0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#0969DA',
                                                borderRadius: '6px',
                                                minWidth: '79px',
                                                width: '79px',
                                                maxWidth: '79px',
                                                background: '#DDF4FF',
                                                border: 'none',
                                                display: 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {"24-5-2023"}

                                            </Label>

                                        }

                                        <Label sx={{
                                            position: 'relative',
                                            margin: '0px 27px 0px 0px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#24292F',
                                            borderRadius: '6px',
                                            minWidth: '78px',
                                            width: '78px',
                                            maxWidth: '78px',
                                            background: 'rgba(175, 184, 193, 0.2)',
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            {new Date(row.created_timestamp).getDate() + "-" + (new Date(row.created_timestamp).getMonth() + 1) + "-" + new Date(row.created_timestamp).getFullYear()}

                                        </Label>



                                        <StyledOcticon size={12} color="#79CE17" icon={CheckCircleFillIcon} />


                                    </Box>













                                </Box>
                            )
                        })}
                    </Box>



                </Box>
            </Box>

        )

    }


    const filesEmptyComponents = (props) => {

        return (
            <Box sx={{
                height: '184px',
                width: '434px',
                border: '1px solid #D0D7DE',
                borderRadius: '6px',
                position: 'absolute',
                left: '0px',
                right: '0px',
                top: '41px',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

            }}>

                <Box sx={{
                    width: '16px',
                    height: '16px',
                    marginTop: '41px'
                }}>

                    <UploadIcon />

                </Box>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    fontSize: '17px',
                    lineHeight: '36px',
                    color: '#24292F',
                    marginTop: '17px'
                }}>
                    Upload or drag and drop a file
                </Text>
                <Text sx={{
                    marginTop: '4px',
                    minWidth: '370px',
                    maxWidth: '370px',
                    width: '370px',
                    fontFamily: 'Segoe UI',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#57606A',
                    textAlign: 'center'
                }}>
                    Attach organization's MCA documents, incorporation certificates, and other valid docs to easily manage them.
                </Text>



            </Box>
        )

    }

    const UploadComponent = (props) => {
        return (
            <Box sx={{
                width: '236px',
                height: '204px',
                background: 'white',
                position: 'absolute',
                top: '135px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                border: '1px solid #D0D7DE',
                boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '1'
            }}>
                <Spinner sx={{
                    height: '60px',
                    width: '60px',
                    position: 'relative',
                    margin: '34px auto 0px auto'
                }} size="large" />

                <Heading sx={{
                    position: 'relative',
                    margin: '18px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    fontSize: '17px',
                    lineHeight: '36px',
                    color: '#24292F'
                }}>
                    Uploading 1 item
                </Heading>
                <Text sx={{
                    position: 'relative',
                    margin: '5px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '18px',
                    cursor: 'pointer'
                }}
                    onClick={() => setshowEmptyPage(!showEmptyPage)}
                >
                    Upload anything you want
                </Text>


            </Box>
        )
    }


    const FilesComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px'

            }}>


                {showUploadingComponent === true ? <UploadComponent /> : ""}

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Organization files</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Box
                    position={"absolute"}
                    sx={{
                        position: 'absolute',
                        top: '60px',
                        right: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'fit-content',
                        width: 'fit-content',
                        gap: '20px',

                    }}>

                    <IconButton
                        size="small"
                        variant="invisible"
                        sx={{
                            color: '#000000',
                            height: '15px',
                            width: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',


                        }}
                        icon={() => <MagnifyingGlassIcon style={{ display: 'flex' }} size={15} />}

                    />



                    <ActionMenu>

                        <ActionMenu.Anchor >

                            <IconButton
                                size="small"
                                variant="invisible"
                                sx={{
                                    color: '#000000',
                                    height: 15,
                                    width: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',


                                }}
                                icon={() => <UploadIcon style={{ display: 'flex' }} size={15} />} />



                        </ActionMenu.Anchor>


                        <ActionMenu.Overlay align="end">
                            <ActionList sx={SpaceTheme.filesActionMenuList}>
                                <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                    onSelect={event => {


                                        computerFileRef.current.click()



                                    }
                                    }
                                >

                                    Computer
                                </ActionList.Item>
                                <ActionList.Item
                                    sx={SpaceTheme.filesActionMenuListItem}
                                    onSelect={() => {
                                        handleSelectDrivePicker("")
                                    }}
                                >

                                    Google drive
                                </ActionList.Item>
                                <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                    onSelect={() => {
                                        handleSelectDropboxPicker("")
                                    }}
                                >

                                    Dropbox
                                </ActionList.Item>
                                {/* <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}>

                                    Box
                                </ActionList.Item>
                                <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}>

                                    Onedrive
                                </ActionList.Item> */}

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>

                    <input
                        ref={computerFileRef}
                        style={{ display: 'none', position: 'absolute', width: '0px', height: '0px' }}
                        type={"file"}
                        // accept="image/png,image/jpeg"
                        onChange={(e) => { uploadOrganizationFilesPick(e.target.files[0]) }}
                        hidden
                    />



                    {isGridView === false ?

                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                height: 15,
                                width: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}
                            icon={() => <ViewGridIcon style={{ display: 'flex' }} size={15} />}
                            onClick={() => {
                                setisGridView(true)
                            }
                            }
                        /> :

                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                height: 15,
                                width: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}
                            icon={() => <ViewHorizontalIcon style={{ display: 'flex' }} size={15} />}
                            onClick={() => {
                                setisGridView(false)
                            }
                            }
                        />
                    }


                    <ActionMenu>

                        <ActionMenu.Anchor>

                            <IconButton
                                size="small"
                                variant="invisible"
                                sx={{
                                    color: '#000000',
                                    height: 15,
                                    width: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',


                                }}

                                icon={() => <InfoCircledIcon style={{ display: 'flex' }} size={15} />} />
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay align="end">
                            <Box sx={{
                                width: 236,
                                height: 212,
                                display: 'flex',
                                flexDirection: 'column',

                            }}>


                                <Box sx={{
                                    height: '60px',
                                    width: '60px',
                                    border: '2px solid #24292F',
                                    borderRadius: '50%',
                                    position: 'relative',
                                    background: 'white',
                                    opacity: '0.25',
                                    margin: '34px auto 0px auto'
                                }} />

                                <Heading sx={{
                                    position: 'relative',
                                    margin: '18px auto 0px auto',
                                    fontFamily: 'Segoe UI',
                                    fontWeight: '600',
                                    fontStyle: 'normal',
                                    fontSize: '17px',
                                    lineHeight: '36px',
                                    color: '#24292F'
                                }}>
                                    {projectFileSize} of 2 GB used
                                </Heading>

                                <Button
                                    variant="invisible"
                                    sx={{
                                        width: '120px',
                                        height: '28px',
                                        position: 'relative',
                                        margin: '0px auto 0px auto',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        cursor: 'pointer',
                                        color: '#24292F'
                                    }}
                                    onClick={() => {
                                        setshowEmptyPage(!showEmptyPage)
                                        setfilesArray([])
                                    }

                                    }
                                >
                                    Get more storage
                                </Button>

                            </Box>
                        </ActionMenu.Overlay>
                    </ActionMenu>


                </Box>


                <Box
                    position={"absolute"}
                    left="0px"
                    right={'0px'}
                    paddingRight={'0px'}
                    bottom={"0px"}
                    top={isGridView === false ? "92px" : "100px"}
                    overflowY={"auto"}

                >

                    {showEmptyPage === true ?
                        filesEmptyComponents("")
                        : ""}



                    {isGridView === false ?

                        <ActionList
                            showDividers
                            sx={{
                                marginBottom: '50px',
                                padding: '0px'
                            }}
                        >

                            {filesArray?.map((row, index) => {
                                return (
                                    <ActionList.Item
                                        sx={{
                                            background: 'white',
                                            height: '44px',
                                            minHeight: '44px',
                                            maxHeight: '44px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            position: 'relative',
                                            margin: '0px',

                                        }}

                                    >

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            height: '44px',
                                            minHeight: '44px',
                                        }}>

                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    right: '-3px',
                                                    top: '0px',
                                                    bottom: '0px',
                                                    margin: 'auto',
                                                    color: '#000000',
                                                    border: 'none',
                                                    background: 'transparent',
                                                    zIndex: '1',
                                                    height: '18px',
                                                    width: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                icon={() => <XCircleFillIcon sx={{ display: 'flex' }} />}
                                                onClick={() => handleRemoveOrgFiles(row)}
                                            />


                                            <Box sx={
                                                {
                                                    height: '16px',
                                                    width: '16px',
                                                    color: '#6B6C7E',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 12px',

                                                }
                                            }>



                                                {
                                                    (
                                                        row.attachment_file_name?.endsWith('.png') ||
                                                        row.attachment_file_name?.endsWith('.jpg') ||
                                                        row.attachment_file_name?.endsWith('.jpeg') ||
                                                        row.attachment_file_name?.endsWith('.svg')
                                                    )
                                                        ?

                                                        <img
                                                            style={{
                                                                height: '16px',
                                                                width: '16px',
                                                                position: 'relative',
                                                                margin: '0px 0px 0px 0px',
                                                                objectFit: "cover",

                                                            }}
                                                            src={imgIcon}

                                                        />
                                                        :
                                                        (
                                                            row.attachment_file_name?.endsWith('.doc')
                                                        ) ?

                                                            <Box sx={{
                                                                height: '16px',
                                                                width: '16px',
                                                                position: 'relative',
                                                                margin: '0px 0px 0px 0px',
                                                                objectFit: "cover",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img src={docIcon} style={{
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    objectFit: "cover",


                                                                }} />
                                                            </Box>
                                                            :

                                                            <Box sx={{
                                                                height: '16px',
                                                                width: '16px',
                                                                position: 'relative',
                                                                margin: '0px 0px 0px 0px',
                                                                objectFit: "cover",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img src={imgIcon} style={{
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    objectFit: "cover",


                                                                }} />
                                                            </Box>


                                                }


                                            </Box>

                                            <Text sx={{
                                                position: 'absolute',
                                                left: '60px',
                                                right: '152px',
                                                top: '0px',
                                                bottom: '0px',
                                                margin: 'auto',
                                                height: 'fit-content',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400px',
                                                fontSize: '14px',
                                                color: '#272833',
                                                whiteSpace: "nowrap",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',

                                            }}
                                                onClick={() => {


                                                    console.log("clickuploaded file", row)
                                                    const attachmentType = row?.attachment_type

                                                    if (attachmentType === "Google Drive") {

                                                        window.open(row.attachment_url)

                                                    } else {

                                                        window.open(row.attachment_url)

                                                    }

                                                }}
                                            >
                                                {row.attachment_file_name}
                                            </Text>




                                            <Text sx={{
                                                position: 'relative',
                                                margin: '0px 52px 0px auto',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400px',
                                                fontSize: '14px',
                                                color: '#303030',
                                                whiteSpace: "nowrap",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                width: '70px'
                                            }}>
                                                {new Date(row.attachment_timestamp).getDate() + "/" + (new Date(row.attachment_timestamp).getMonth() + 1) + "/" + new Date(row.attachment_timestamp).getFullYear()}
                                            </Text>

                                        </Box>


                                    </ActionList.Item>
                                )
                            })

                            }


                        </ActionList>

                        : <Box sx={{
                            height: 'auto',
                            minHeight: '200px',
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            position: 'absolute',
                            left: '0px',
                            right: '0px',
                            paddingBottom: '35px',

                        }}>

                            {filesArray?.map((row, index) => {
                                return (
                                    <Box
                                        borderColor="border.default"
                                        borderWidth={1}
                                        borderStyle="solid"
                                        borderRadius={6}
                                        sx={{
                                            height: '180px',
                                            width: '170px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            position: 'relative',
                                            margin: '0px 16px 16px 0px',
                                            background: '#FFFFFF',

                                        }}
                                    >

                                        <Box
                                            sx={{
                                                height: '12px',
                                                width: '12px',
                                                position: 'absolute',
                                                right: '9px',
                                                top: '6px',
                                                color: '#000000',
                                                border: 'none',
                                                background: 'transparent',
                                                zIndex: '1',
                                                ":hover": {
                                                    background: 'transparent'
                                                },
                                                cursor: 'pointer'
                                            }}
                                            // icon={()=> }
                                            onClick={() => handleRemoveOrgFiles(row)}
                                        >
                                            <XCircleFillIcon />
                                        </Box>

                                        {
                                            (
                                                row.attachment_file_name?.endsWith('.png') ||
                                                row.attachment_file_name?.endsWith('.jpg') ||
                                                row.attachment_file_name?.endsWith('.jpeg') ||
                                                row.attachment_file_name?.endsWith('.svg')
                                            )
                                                ?

                                                <img
                                                    style={{
                                                        height: '139px',
                                                        width: '162px',
                                                        position: 'relative',
                                                        margin: '4px 4px 0px 4px',
                                                        objectFit: "cover",
                                                        borderRadius: "6px",
                                                    }}
                                                    src={row.attachment_url}
                                                />
                                                :
                                                (
                                                    row.attachment_file_name?.endsWith('.doc')
                                                ) ?

                                                    <Box sx={{
                                                        height: '139px',
                                                        width: '162px',
                                                        position: 'relative',
                                                        margin: '4px 4px 0px 4px',
                                                        objectFit: "cover",
                                                        borderRadius: "6px",
                                                        display: 'flex',

                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={docIcon} style={{
                                                            height: '69.5px',
                                                            width: '69.5px',
                                                            objectFit: "cover",
                                                            borderRadius: "6px",

                                                        }} />
                                                    </Box>
                                                    :

                                                    <Box sx={{
                                                        height: '139px',
                                                        width: '162px',
                                                        position: 'relative',
                                                        margin: '4px 4px 0px 4px',
                                                        objectFit: "cover",
                                                        borderRadius: "6px",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={imgIcon} style={{
                                                            height: '69.5px',
                                                            width: '69.5px',
                                                            objectFit: "cover",
                                                            borderRadius: "6px",

                                                        }} />
                                                    </Box>


                                        }



                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            position: 'relative',
                                            margin: '7px 0px 9px 9px',

                                        }}>

                                            <Box sx={
                                                {

                                                    height: '16px',
                                                    width: '16px',
                                                    color: '#6B6C7E',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px',


                                                }
                                            }>

                                                {
                                                    (
                                                        row.attachment_file_name?.endsWith('.png') ||
                                                        row.attachment_file_name?.endsWith('.jpg') ||
                                                        row.attachment_file_name?.endsWith('.jpeg')

                                                    ) ?
                                                        <img src={imgIcon} height="16" width="16" />
                                                        :
                                                        (
                                                            row.attachment_file_name?.endsWith('.doc')
                                                        )
                                                            ?
                                                            <img src={docIcon} height="16" width="16" />
                                                            :
                                                            <img src={imgIcon} height="16" width="16" />
                                                }



                                            </Box>

                                            <Text sx={{
                                                width: '114px',
                                                height: '21px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 15px',
                                                fontFamily: 'Segoe UI',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                color: '#303030',
                                                whiteSpace: "nowrap",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}>
                                                {row.attachment_file_name}
                                            </Text>



                                        </Box>

                                    </Box>
                                )
                            })}

                        </Box>




                    }






                </Box>









            </Box>
        )

    }


    const ComplianceComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                //   right: '482px',
                right: '350px',
                overflowX: 'hidden',

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Provided Compliances</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Button
                    leadingIcon={() => <PlusIcon style={{ display: 'flex' }} />}
                    sx={{
                        position: 'absolute',
                        right: '0px',
                        top: '0px',
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#586069',
                        border: 'none',
                        background: '#ffffff',
                        minHeight: '25px',
                        maxHeight: '25px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        setshowAddComplianceDialog(true)
                    }}
                >Add
                </Button>

                <Box sx={{
                    position: 'absolute',
                    top: '60px',
                    left: '0px',
                    background: '#FFFFFF',
                    right: '0px',
                    bottom: '10px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    // border: '1px solid #D0D7DE',
                    borderRadius: '6px'
                }}>


                    <Box sx={{
                        top: '0px',
                        left: '0px',
                        position: 'absolute',
                        width: '240px',
                        minWidth: '240px',
                        maxWidth: '240px',
                        minHeight: '249px',
                        height: '249px',
                        maxHeight: '249px',
                        border: '1px solid #BFBFBF',
                        borderRadius: '4px',
                        overflowY: 'auto',
                        paddingBottom: '20px'
                    }}>


                        <Box sx={{
                            position: 'relative',
                            margin: '25px 0px 0px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                        }}>

                            {categories.map((row, index) => {

                                return (


                                    <FormControl sx={{
                                        height: 'fit-content',
                                        minWidth: '200px',
                                        maxWidth: '200px',
                                        width: '200px',
                                    }}>
                                        <Checkbox
                                            id="always-checked-checkbox"
                                            checked={
                                                category?.filter((cData) => cData.category_id === row.category_id).length > 0 ? true : row.category_name === "Other" ? true :
                                                    row.category_id === selectedComplianceCheckBox ? true
                                                        :
                                                        false
                                            }
                                            onChange={(e) =>

                                                onCheckCompliance(e.target.checked, index, row)

                                            }
                                        />
                                        <FormControl.Label
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E'
                                            }}
                                        >
                                            {row.category_name}
                                        </FormControl.Label>
                                    </FormControl>
                                )


                            })}

                        </Box>





                    </Box>



                    <Text sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '314px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#24292E'
                    }}>
                        Income tax services
                    </Text>



                    <Box sx={{

                        top: '34px',
                        left: '314px',
                        position: 'absolute',
                        right: '1px',
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',

                    }}>

                        {/* {compliance.filter((cData) => cData.isChecked === true).map((row, index) => {

                            return (

                                <FormControl key={row.compliance_id}  sx={{
                                    height: 'fit-content',
                                    minWidth: '225px',
                                    maxWidth: '225px',
                                    width: '225px',
                                    marginRight: '92px',
                                    marginBottom: '20px'
                                }}>
                                    <Checkbox id="always-checked-checkbox" checked={row.isChecked} onChange={(e) => onCheckCompliance(e.target.checked, index)} />
                                    <FormControl.Label
                                        sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#24292E'
                                        }}
                                    >
                                        {row.compliance_name}

                                    </FormControl.Label>
                                </FormControl>
                            )


                        })} */}


                        {copycompliance
                            .filter((compData) => compData?.category?.category_id === selectedComplianceCheckBox)
                            .map((row, index) => {

                                return (
                                    <FormControl sx={{
                                        height: 'fit-content',
                                        minWidth: '225px',
                                        maxWidth: '225px',
                                        width: '225px',
                                        marginRight: '92px',
                                        marginBottom: '20px'
                                    }}>
                                        <Checkbox id="always-checked-checkbox"

                                            checked={compliance.some((subItem) => row.subcategory.compliance_id === subItem.compliance_id)}
                                            onChange={(e) => {

                                            }}
                                        />
                                        <FormControl.Label
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E'
                                            }}
                                        >
                                            {row?.subcategory?.compliance_name}

                                        </FormControl.Label>
                                    </FormControl>
                                )


                            })}



                    </Box>







                </Box>








            </Box>
        )



    }

    const ActivityComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px',

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Task Insight</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>




                <Box sx={{
                    position: 'absolute',
                    top: '65px',
                    left: '0px',
                    // background: '#F1F8FF',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto'
                }}>




                    <Box sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        background: '#F1F8FF',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        minHeight: '366px',
                        maxHeight: '366px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'absolute',
                            left: '25px',
                            right: '25px',
                            top: '0px',
                            height: '106px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flex: '1 1 25%',


                            }}>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '15px',

                                }}>

                                    <Text sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        position: 'relative',
                                        margin: '18px 0px 0px 0px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#000000'

                                    }}>
                                        Open tasks
                                    </Text>
                                    <Heading sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '27px',
                                        lineHeight: '35px',
                                        color: '#000000'
                                    }}>
                                        {activityCounts.openTaskCount}
                                    </Heading>

                                </Box>

                                <Box sx={{ background: '#D1D5DA', width: '1px', position: 'relative', margin: '28px 15px 10px auto' }}>

                                </Box>










                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flex: '1 1 25%',


                            }}>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '15px',

                                }}>

                                    <Text sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        position: 'relative',
                                        margin: '18px 0px 0px 0px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#000000'

                                    }}>
                                        Tasks in progress
                                    </Text>
                                    <Heading sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '27px',
                                        lineHeight: '35px',
                                        color: '#000000'
                                    }}>
                                        {activityCounts.inProgressCount}
                                    </Heading>

                                </Box>

                                <Box sx={{ background: '#D1D5DA', width: '1px', position: 'relative', margin: '28px 15px 10px auto' }}>

                                </Box>










                            </Box>


                            <Box sx={{
                                display: 'flex',
                                flex: '1 1 25%',


                            }}>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '15px',

                                }}>

                                    <Text sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        position: 'relative',
                                        margin: '18px 0px 0px 0px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#000000'

                                    }}>
                                        Overdue
                                    </Text>
                                    <Heading sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '27px',
                                        lineHeight: '35px',
                                        color: '#CB2431'
                                    }}>
                                        {activityCounts.overdueCount}
                                    </Heading>

                                </Box>

                                <Box sx={{ background: '#D1D5DA', width: '1px', position: 'relative', margin: '28px 15px 10px auto' }}>

                                </Box>










                            </Box>


                            <Box sx={{
                                display: 'flex',
                                flex: '1 1 25%',


                            }}>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '15px',

                                }}>

                                    <Text sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        position: 'relative',
                                        margin: '18px 0px 0px 0px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#000000'

                                    }}>
                                        Completed
                                    </Text>
                                    <Heading sx={{
                                        display: 'flex',
                                        width: 'fit-content',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontSize: '27px',
                                        lineHeight: '35px',
                                        color: '#000000'
                                    }}>
                                        {activityCounts.completedCount}
                                    </Heading>

                                </Box>











                            </Box>


                        </Box>
                    </Box>


                    <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', position: 'absolute', top: '106px', left: '25px', right: '25px' }}></Box>





                </Box>





            </Box>
        )



    }


    const TeamComponent = (props) => {

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px'

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Team Members</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Box sx={{
                    position: 'absolute',
                    top: '55px',
                    left: '-18px',
                    right: '0px',
                    bottom: '10px',
                    overflowY: 'auto'
                }}>

                    <ActionList
                        sx={{ padding: '0px' }}

                    >

                        {
                            spaceCollaborators.map((row) => {
                                return (
                                    <ActionList.Item sx={{
                                        padding: '0px',
                                        margin: '0px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '56px',
                                        borderBottom: '1px solid #E7E7ED'
                                    }}>

                                        <Box sx={{
                                            // width:'100%',
                                            position: 'absolute',
                                            left: '18px',
                                            right: '0px',
                                            top: '0px',
                                            bottom: '0px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingTop: '10px',
                                            paddingBottom: '10px'
                                        }}>



                                            {row.user_image_url === "" || row.user_image_url == null ?

                                                <FeedPersonIcon size={32} fill="#586069" /> :

                                                <Avatar sx={{
                                                    height: '32px',
                                                    width: '32px',
                                                    minHeight: '32px',
                                                    minWidth: '32px',


                                                }}
                                                    src={row.user_image_url} />

                                            }



                                            <Text sx={{
                                                position: 'absolute',
                                                left: '40px',
                                                fontFamily: 'Segoe UI',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                fontSize: '14px',
                                                lineHeight: '21px',
                                                color: '#303030',
                                                width: '116px',
                                                maxWidth: '116px',
                                                overflow: 'hidden',
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",

                                            }}>

                                                {row.user_first_name + " " + row.user_last_name}
                                            </Text>

                                            <Text sx={{
                                                position: 'absolute',
                                                left: '200px',
                                                fontFamily: 'Segoe UI',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#272833',
                                                width: '390px',
                                                maxWidth: '390px',
                                                overflow: 'hidden',
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",

                                            }}>
                                                {row.user_email}
                                            </Text>



                                        </Box>




                                    </ActionList.Item>
                                )
                            })
                        }

                    </ActionList>

                </Box>



            </Box>
        )

    }

    const InvoiceComponent = (props) => {

        return (


            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '86px'
            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Invoice</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>

                <Box sx={{
                    position: 'absolute',
                    top: '65px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    overflowY: 'auto'
                }}>


                    <Box sx={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        minHeight: 'fit-content',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                        {invoices?.map((row, index) => {

                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '35px',
                                    borderBottomWidth: 1,
                                    borderBottomStyle: 'solid',
                                    borderColor: '#E0E0E0',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    ':hover': {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    }
                                }}
                                    onClick={() => { }}
                                >

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        minHeight: '19px',
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        Date : {new Date(row.created_time).getDate() + "-" + (new Date(row.created_time).getMonth() + 1) + "-" + new Date(row.created_time).getFullYear()}
                                    </Text>


                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        minHeight: '19px',
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        Invoice Number : {row.invoice_number}
                                    </Text>

                                    <Box sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px',
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px',

                                    }}>



                                        <Label sx={{
                                            width: 'fit-content',
                                            minWidth: 'fit-content',
                                            maxWidth: 'fit-content',
                                            height: '20px',
                                            background: '#2155A4',
                                            borderRadius: '6px',
                                            border: 'none',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#FFFFFF',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>


                                            <Text sx={{
                                                minWidth: 'auto',
                                                width: 'auto',
                                                maxWidth: '49px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>


                                                {row?.line_items[0]?.name}

                                            </Text>


                                        </Label>
                                    </Box>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        minHeight: '19px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        Amount : {row.sub_total}
                                    </Text>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        minHeight: '19px',
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        Amount Received: {row?.payment_made}
                                    </Text>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        minHeight: '19px',
                                        minWidth: '180px',
                                        maxWidth: '180px',
                                        width: '180px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        Status: {row?.status}
                                    </Text>


                                    <Box sx={{
                                        position: 'absolute',
                                        right: '29px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>

                                        <ActionMenu>
                                            <ActionMenu.Anchor>
                                                <IconButton
                                                    size="small"
                                                    variant="invisible"
                                                    sx={
                                                        {
                                                            // background: '#ffffff',
                                                            color: '#000000',
                                                            border: 'none',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'relative',
                                                            margin: '0px 0px 0px 5px',
                                                        }
                                                    }
                                                    icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}

                                                />
                                            </ActionMenu.Anchor>

                                            <ActionMenu.Overlay align="start"
                                                sx={{
                                                    width: '181px',
                                                    minWidth: '181px',
                                                }}

                                            >
                                                <ActionList showDividers sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    margin: '4px',
                                                    alignItems: 'center',
                                                    fontFamily: 'Segoe UI',
                                                    fontSize: '14px',
                                                    color: '#24292F',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                    fontStyle: 'normal'
                                                }}>
                                                    <ActionList.Item
                                                        sx={{
                                                            display: isZohoInstalled ? 'flex' : 'none'
                                                        }}
                                                        onSelect={() => {
                                                            handleDownloadInvoicePdf(row.invoice_id)
                                                        }}
                                                    >
                                                        Download
                                                    </ActionList.Item>
                                                </ActionList>
                                            </ActionMenu.Overlay>
                                        </ActionMenu>




                                    </Box>













                                </Box>
                            )
                        })}
                    </Box>



                </Box>
            </Box>

        )


    }



    const ProfileComponent = (props) => {



        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '134px',
                left: '38px',
                bottom: '0px',
                right: '482px',
                overflowX: 'hidden',
                overflowY: 'auto',

            }}>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '19px',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    color: '#000000'
                }}>Organization information</Text>

                <Box sx={{ borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#E7E7ED', marginTop: '20px' }}></Box>


                <Label
                    variant="attention"
                    sx={{
                        position: 'absolute',
                        top: '47px',
                        right: '0px',
                        width: 'fit-content',
                        height: '22px',
                        border: 'none',
                        background: '#DCF487',
                        borderRadius: '0px',
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '18px',
                        color: '#24292F',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >

                    {orgTypeArray?.filter((oData) => oData?.id === companyDataDb?.org_type)[0]?.name}
                    {companyDataDb.org_type === "OPC" ? "One Person Company" :
                        companyDataDb.org_type === "PLC" ? "Private Limited Company" :
                            companyDataDb.org_type === "LLP" ? "Limited Liability Partnership"
                                : ""}
                </Label>



                <Box sx={{
                    position: 'absolute',
                    top: '82px',
                    left: '541px',
                    height: '150px',
                    width: '150px',
                    background: '#F6F8FA',
                    border: '1px solid rgba(27, 31, 36, 0.15)',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#6A737D'
                }}>

                    {showloadingSpinner === true ?
                        <Spinner sx={{
                            position: 'absolute',
                            top: '0px',
                            bottom: '0px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto'
                        }} />
                        : ""}

                    {orgImage !== "" && orgImage !== null ?
                        <img
                            style={{
                                height: '149px',
                                width: '149px',
                                minWidth: '149px',
                                minHeight: '149px',
                                maxHeight: '149px',
                                maxWidth: '149px'
                            }}
                            src={orgImage}
                        />

                        :
                        <OrganizationIcon color="red" size={82} />

                    }




                </Box>




                <ActionMenu>
                    <ActionMenu.Anchor>
                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={
                                {
                                    background: '#ffffff',
                                    color: '#000000',
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    top: '245px',
                                    left: '671px',
                                }
                            }
                            icon={() => <Pencil1Icon style={{ display: 'flex' }} />}

                        />
                    </ActionMenu.Anchor>

                    <ActionMenu.Overlay align="end"
                        sx={{
                            width: '161px',
                            minWidth: '161px',
                        }}

                    >
                        <ActionList sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '8px',
                            alignItems: 'center',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            color: '#24292F',
                            fontWeight: '400',
                            lineHeight: '20px',
                            fontStyle: 'normal'
                        }}>
                            <ActionList.Item
                                onSelect={() => fileRef.current.click()}
                            >

                                Upload logo
                            </ActionList.Item>
                            <ActionList.Item>

                                Download
                            </ActionList.Item>

                        </ActionList>
                    </ActionMenu.Overlay>
                </ActionMenu>

                <input
                    ref={fileRef}
                    style={{ display: 'none', position: 'absolute', width: '0px', height: '0px' }}
                    type={"file"}
                    accept="image/png,image/jpeg"
                    onChange={(e) => { updateOrganizationImage(e.target.files[0]) }}
                    hidden
                />


                <Box sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '23px', width: '516px' }}>


                    <TextInput
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '0px 0px 0px 0px'
                        }}
                        value={companyDataDb.org_name}
                        onChange={(e) => { updateProfileDataInDb("orgName", e) }}
                        onBlur={() => updateOrgData("")}
                    />

                    <TextInput
                        contrast
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '0px 0px 0px 0px'
                        }}
                        placeholder={"Enter your organization's CIN (Corporate Identification Number)"}
                        value={companyDataDb.org_no}
                        onChange={(e) => { updateProfileDataInDb("orgNo", e) }}
                        onBlur={() => updateOrgData("")}
                    />
                    <TextInput
                        contrast
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '0px 0px 0px 0px'
                        }}
                        placeholder={"Organization's GSTIN "}
                        value={companyDataDb.org_gstNo}
                        onChange={(e) => { updateProfileDataInDb("orgGstNo", e) }}
                        onBlur={() => updateOrgData("")}
                    />

                    <Box sx={{
                        width: '516px',
                        height: '40px',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',

                    }}>

                        <TextInput
                            contrast
                            sx={{
                                width: '248px',

                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder={"PAN (Permanent Account Number)"}
                            value={companyDataDb.org_pan}
                            onChange={(e) => { updateProfileDataInDb("orgPan", e) }}
                            onBlur={() => updateOrgData("")}
                        />
                        <TextInput
                            contrast
                            sx={{
                                width: '248px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder={"Incorporation date"}
                            value={companyDataDb.org_incorporation_date}
                            onChange={(e) => { updateProfileDataInDb("orgIncorpDate", e) }}
                            onBlur={() => updateOrgData("")}
                        />




                    </Box>

                    <Box sx={{
                        width: '516px',
                        height: '40px',
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',

                    }}>

                        <TextInput
                            contrast
                            sx={{
                                width: '248px',

                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder={"State"}
                            value={companyDataDb.org_state}
                            onChange={(e) => { updateProfileDataInDb("orgState", e) }}
                            onBlur={() => updateOrgData("")}
                        />
                        <TextInput
                            contrast
                            sx={{
                                width: '248px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder={"Location"}

                        />




                    </Box>

                    <Textarea
                        resize={"none"}
                        sx={{
                            width: '100%',
                            height: '88px',
                            minHeight: '88px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            borderRadius: '6px',
                            position: 'relative',
                            margin: '12px 0px 0px 0px'
                        }}
                        placeholder="Additional information"
                        value={companyDataDb.org_additional_info}
                        onChange={(e) => { updateProfileDataInDb("orgAdditionalInfo", e) }}
                        onBlur={() => updateOrgData("")}
                    />

                    <TextInput
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '11px 0px 0px 0px'
                        }}
                        placeholder="Address line 1 "
                        value={companyDataDb.org_address_line1}
                        onChange={(e) => { updateProfileDataInDb("orgAddressline1", e) }}
                        onBlur={() => updateOrgData("")}
                    />


                    <TextInput
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '-10px 0px 0px 0px'
                        }}
                        placeholder="Address line 2"
                        value={companyDataDb.org_address_line2}
                        onChange={(e) => { updateProfileDataInDb("orgAddressline2", e) }}
                        onBlur={() => updateOrgData("")}
                    />

                    <TextInput
                        sx={{
                            width: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'relative',
                            margin: '-10px 0px 0px 0px'
                        }}
                        placeholder="Address line 3"
                        value={companyDataDb.org_address_line3}
                        onChange={(e) => { updateProfileDataInDb("orgAddressline3", e) }}
                        onBlur={() => updateOrgData("")}
                    />

                    <Box sx={{
                        position: 'relative',
                        margin: '-9px 0px 0px 0px',
                        width: '517px',
                        height: '40px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center'
                    }}>

                        <TextInput
                            sx={{
                                width: '170px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder="City"
                            value={companyDataDb.org_city}
                            onChange={(e) => { updateProfileDataInDb("orgCity", e) }}
                            onBlur={() => updateOrgData("")}
                        />

                        <TextInput
                            sx={{
                                width: '170px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder="State /province"
                            value={companyDataDb.org_state_province}
                            onChange={(e) => { updateProfileDataInDb("orgStateProvince", e) }}
                            onBlur={() => updateOrgData("")}
                        />

                        <TextInput
                            sx={{
                                width: '152px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px'
                            }}
                            placeholder="Postal / zip code"
                            value={companyDataDb.org_postal_zipcode}
                            onChange={(e) => { updateProfileDataInDb("orgPostalZipCode", e) }}
                            onBlur={() => updateOrgData("")}
                        />


                    </Box>


                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        color: '#24292E',
                        position: 'relative',
                        margin: '1px 0px 0px 0px'
                    }}>Directors information</Text>

                    {
                        directorsDb.map((row, index) => {

                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'fit-content',
                                    width: 'fit-content',
                                    gap: '25px',
                                }}>

                                    <Box sx={{
                                        width: '516px',
                                        height: '40px',
                                        position: 'relative',
                                        margin: '-6px 0px 0px 0px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '20px',

                                    }}>

                                        <TextInput

                                            sx={{
                                                width: '248px',

                                                height: '40px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px'
                                            }}
                                            placeholder={"First name"}
                                            value={row.firstName}
                                            onChange={(e) => {

                                                setdirectorsDb(prevState => {
                                                    const newState = prevState.map((dData, index2) => {

                                                        if (index2 === index) {

                                                            return { ...dData, firstName: e.target.value }
                                                        } else {
                                                            return { ...dData }
                                                        }

                                                    })
                                                    return newState
                                                })
                                            }}
                                            onBlur={(e) => {


                                                if (e.target.value.length > 0) {
                                                    updateDirectorsInfoInDb("")
                                                }

                                            }}
                                        />
                                        <TextInput

                                            sx={{
                                                width: '248px',
                                                height: '40px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px'
                                            }}
                                            placeholder={"Last name"}
                                            value={row.lastName}
                                            onChange={(e) => {

                                                setdirectorsDb(prevState => {
                                                    const newState = prevState.map((dData, index2) => {

                                                        if (index2 === index) {

                                                            return { ...dData, lastName: e.target.value }
                                                        } else {
                                                            return { ...dData }
                                                        }

                                                    })
                                                    return newState
                                                })
                                            }}
                                            onBlur={() => { updateDirectorsInfoInDb("") }}
                                        />


                                        <ActionMenu>

                                            <ActionMenu.Anchor>
                                                <IconButton sx={{
                                                    position: 'absolute',
                                                    right: '-30px',
                                                    top: '0px',
                                                    bottom: '0px',
                                                    margin: 'auto',
                                                    height: '25px',
                                                    width: '25px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: 'none',
                                                    background: 'white'
                                                }}
                                                    icon={() => <DotsVerticalIcon style={{ display: 'flex' }} />}
                                                >

                                                </IconButton>
                                            </ActionMenu.Anchor>
                                            <ActionMenu.Overlay>
                                                <ActionList
                                                    sx={{
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        margin: '2px'
                                                    }}
                                                >
                                                    <ActionList.Item variant="danger"
                                                        onSelect={() => {
                                                            handleRemoveDirectors(row, index)
                                                        }}
                                                    >
                                                        Remove director
                                                    </ActionList.Item>


                                                </ActionList>
                                            </ActionMenu.Overlay>

                                        </ActionMenu>





                                    </Box>

                                    <TextInput

                                        sx={{
                                            width: '514px',
                                            height: '40px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            position: 'relative',
                                            margin: '-7px 0px 0px 0px'
                                        }}
                                        placeholder={"DIN/PAN"}
                                        value={row.pan}
                                        onChange={(e) => {

                                            setdirectorsDb(prevState => {
                                                const newState = prevState.map((dData, index2) => {

                                                    if (index2 === index) {

                                                        return { ...dData, pan: e.target.value }
                                                    } else {
                                                        return { ...dData }
                                                    }

                                                })
                                                return newState
                                            })
                                        }}
                                        onBlur={() => { updateDirectorsInfoInDb("") }}
                                    />

                                    <Box sx={{
                                        width: '516px',
                                        height: '40px',
                                        position: 'relative',
                                        margin: '-9px 0px 0px 0px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '20px',

                                    }}>

                                        <TextInput

                                            sx={{
                                                width: '248px',

                                                height: '40px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px'
                                            }}
                                            placeholder={"Email address"}
                                            value={row.emailAddress}
                                            onChange={(e) => {

                                                setdirectorsDb(prevState => {
                                                    const newState = prevState.map((dData, index2) => {

                                                        if (index2 === index) {

                                                            return { ...dData, emailAddress: e.target.value }
                                                        } else {
                                                            return { ...dData }
                                                        }

                                                    })
                                                    return newState
                                                })
                                            }}
                                            onBlur={() => { updateDirectorsInfoInDb("") }}
                                        />
                                        <TextInput

                                            sx={{
                                                width: '248px',
                                                height: '40px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px'
                                            }}
                                            placeholder={"Role  (eg: CEO , Director , Owner) "}
                                            value={row.role}
                                            onChange={(e) => {

                                                setdirectorsDb(prevState => {
                                                    const newState = prevState.map((dData, index2) => {

                                                        if (index2 === index) {

                                                            return { ...dData, role: e.target.value }
                                                        } else {
                                                            return { ...dData }
                                                        }

                                                    })
                                                    return newState
                                                })
                                            }}
                                            onBlur={() => { updateDirectorsInfoInDb("") }}
                                        />




                                    </Box>

                                    <TextInput

                                        sx={{
                                            width: '514px',
                                            height: '40px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            position: 'relative',
                                            margin: '-8px 0px 0px 0px'
                                        }}
                                        placeholder={"Address line 1 "}
                                        value={row.addressline1}
                                        onChange={(e) => {

                                            setdirectorsDb(prevState => {
                                                const newState = prevState.map((dData, index2) => {

                                                    if (index2 === index) {

                                                        return { ...dData, addressline1: e.target.value }
                                                    } else {
                                                        return { ...dData }
                                                    }

                                                })
                                                return newState
                                            })
                                        }}
                                        onBlur={() => { updateDirectorsInfoInDb("") }}
                                    />

                                    <TextInput

                                        sx={{
                                            width: '514px',
                                            height: '40px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            position: 'relative',
                                            margin: '-10px 0px 0px 0px'
                                        }}
                                        placeholder={"Address line 2 "}
                                        value={row.addressline2}
                                        onChange={(e) => {

                                            setdirectorsDb(prevState => {
                                                const newState = prevState.map((dData, index2) => {

                                                    if (index2 === index) {

                                                        return { ...dData, addressline2: e.target.value }
                                                    } else {
                                                        return { ...dData }
                                                    }

                                                })
                                                return newState
                                            })
                                        }}
                                        onBlur={() => { updateDirectorsInfoInDb("") }}
                                    />



                                </Box>
                            )
                        })
                    }

                    <Button
                        leadingIcon={() => <PlusIcon style={{ display: 'flex' }} />}
                        sx={{
                            position: 'relative',
                            margin: '-3px 0px 30px -16px',
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '17px',
                            color: '#586069',
                            border: 'none',
                            background: '#ffffff',
                            minHeight: '25px',
                            maxHeight: '25px',
                            display: 'flex',
                            alignItems: 'center'
                        }}

                        onClick={() => {


                            setdirectorsDb((prevState) => {

                                const newState = [...prevState, {
                                    dob: "",
                                    pan: "",
                                    pin: "",
                                    role: "",
                                    state: "",
                                    street: "",
                                    lastName: "",
                                    firstName: "",
                                    addressline1: "",
                                    addressline2: "",
                                    emailAddress: "",
                                    panValidation: "",
                                    lastNameValidation: "",
                                    firstNameValidation: "",
                                    emailAddressValidation: ""
                                }]

                                return newState

                            })



                            //   setdirectors((prevState)=>{

                            //        const newState = [...prevState,{id:prevState.length+1}]
                            //        return newState
                            //   })

                        }}
                    >Add director information
                    </Button>

                    <Box sx={{ minHeight: '60px', width: '100%' }}>

                    </Box>





                </Box>



            </Box>
        )

    }

    const AddCompanyCompliance = (props) => {

        return (<Dialog
            isOpen={showAddComplianceDialog}
            onDismiss={() => {
                setshowComplianceActionList(
                    {
                        isShown: false,
                        inputValue: '',
                        selectedCompId: '',
                        selectedCategory: ''
                    }
                )

                setshowAddComplianceDialog(false)
            }}
            sx={{
                width: '800px',
                height: '300px',
                // height: '508px',
            }}
            initialFocusRef={initialFocusRef1}
        >
            <Dialog.Header id="header-id">

                <Text
                    ref={initialFocusRef1}
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '600',
                        color: '#24292F'
                    }}
                >
                    Create such a service
                </Text>


            </Dialog.Header>

            <Box sx={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                bottom: '52px',
                top: '52px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Box sx={{
                    position: 'relative',
                    margin: '13px 27px 0px 27px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: '10px',
                }}>

                    <TextInput
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minWidth: '488px',
                            maxWidth: '488px',
                            width: '488px',
                            minHeight: '42px',
                            height: '42px',
                            maxHeight: '42px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder="Provided compliance / Services name"
                        value={showComplianceActionList.inputValue}
                        onChange={(e) => {

                            if (e.target.value.length <= 0) {

                                setshowComplianceActionList((prevState) => {
                                    const newState = { ...prevState, isShown: false, inputValue: '', selectedCompId: '' }
                                    return newState
                                })

                            } else {

                                setshowComplianceActionList((prevState) => {
                                    const newState = { ...prevState, isShown: true, inputValue: e.target.value }
                                    return newState
                                })

                            }

                        }}
                    />

                    <Box sx={{
                        width: '248px',
                        height: '42px',
                        minWidth: '248px',
                        maxWidth: '248px',
                        minHeight: '42px',
                        maxHeight: '40px',
                    }}>
                        <Select block style={{
                            width: '248px',
                            height: '40px',
                            minWidth: '248px',
                            maxWidth: '248px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                            placeholder="Select category"
                            onChange={(e) => {

                                setshowComplianceActionList((prevState) => {
                                    const newState = { ...prevState, selectedCategory: e.target.value }
                                    return newState
                                })

                            }}
                        >

                            {categories.map((row, index) => {
                                return (
                                    <Select.Option value={row?.category_id}

                                    >{row?.category_name}</Select.Option>
                                )
                            })}


                        </Select>
                    </Box>
                </Box>

                <Box

                    sx={{
                        visibility: showComplianceActionList.isShown === true ? 'visible' : 'hidden',
                        zIndex: '10',
                        position: 'absolute',
                        right: '27px',
                        top: '58px',
                        minHeight: 'auto',
                        maxHeight: '240px',
                        maxWidth: '488px',
                        minWidth: '488px',
                        width: '488px',
                        height: 'auto',
                        boxShadow: '0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)',
                        borderRadius: '6px',
                        background: '#FFFFFF',
                        overflowX: 'hidden',
                        overflowY: 'auto',

                    }} >

                    <ActionList sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '2px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292F'

                    }}>
                        {copycompliance
                            .filter((compData) => compData?.category?.category_id === showComplianceActionList.selectedCategory)
                            .filter((tData) => tData.subcategory.compliance_name?.toLowerCase().includes(showComplianceActionList.inputValue?.toLowerCase()))
                            .map((row, index) => {
                                return (
                                    <ActionList.Item
                                        key={row.service_id}
                                        onSelect={() => {

                                            setshowComplianceActionList((prevState) => {
                                                const newState = {
                                                    ...prevState,
                                                    inputValue: row.subcategory.compliance_name,
                                                    isShown: false,
                                                    selectedCompId: row.service_id
                                                }
                                                return newState
                                            })

                                        }}
                                    >
                                        {row.subcategory.compliance_name}
                                    </ActionList.Item>
                                )
                            })}




                    </ActionList>

                </Box>





            </Box>

            <Box sx={{
                position: 'absolute',
                bottom: '0px',
                left: '0px',
                right: '0px',
                minHeight: '52px',
                maxHeight: '52px',
                height: '52px',
                borderTop: '1px solid #E1E4E8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right'
            }}>

                <Button
                    variant="outline"
                    sx={{
                        position: 'relative',
                        margin: '0px 27px 0px 0px',
                        height: '32px',
                        width: '73px',
                        minWidth: '73px',
                        maxWidth: '73px',
                        minHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#FFFFFF',
                        background: '#0965CE',
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '600',
                    }}
                    onClick={() => {
                        updateCompanyComplianceInDb(showComplianceActionList)
                    }}
                >
                    Save
                </Button>

            </Box>




        </Dialog>)

    }

    const resetFilledData = (props) => {

        setorgImage("")
        setfilesArray([])
        setactivityCounts({
            openTaskCount: 0,
            inProgressCount: 0,
            overdueCount: 0,
            completedCount: 0
        })
    }


    React.useEffect(() => {

        if (contactProfileData.openClose === true) {


            setcompanyDataDb(contactProfileData?.data?.contact_data)
            setdirectorsDb(contactProfileData?.data?.directors_info)
            setorgImage(contactProfileData?.data?.image_url)
            setcategory(contactProfileData?.data?.category)
            setcompliance(contactProfileData?.data?.compliances)
            setfilesArray(contactProfileData?.data?.org_files)





            if (contactProfileData?.data?.org_files.length <= 0) {
                setshowEmptyPage(true)
            } else {
                setshowEmptyPage(false)
            }


            // setcredentialArray(contactProfileData?.data?.credentials)
            getIndiviualsCompaniesByIdFromDb("")
            getIndiviualsCompaniesActivityFromDb("")
            getIndiviualsCompaniesTasksFromDb("")
            calculateProjectFolderSize("")
            getAllCategories("")
            getOrganizationFromDb("")
            getZohoAuthFromDb("")
            setSelectedIndex(0)
            getZohoInvoiceByCustomerFromApi(contactProfileData?.title)
        } else {

            resetFilledData("")
        }

        getSpaceCollaboratorFromDb("")

    }, [contactProfileData.openClose])


    React.useEffect(() => {

        getIndiviualsCompaniesTasksFromDb("")  // Get All Company Tasks from Db 

    }, [taskTrigger])


    React.useEffect(() => {

        if (reloadCredentialTrigger === true) {
            getIndiviualsCompaniesByIdFromDb("")

        }


    }, [reloadCredentialTrigger])






    return (
        <Box
            position={"absolute"}
            display={contactProfileData.openClose === true ? "flex" : "none"}
            background={"#FFFFFF"}
            left={"77px"}
            top={"56px"}
            bottom={"0px"}
            right={"0px"}
            overflowY={"auto"}

        >
            <Helmet>

                <title>{localStorage.getItem("projectName")} - Clients</title>
                <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="r1ex80p99zrn57f"></script>


            </Helmet>


            <Box sx={{
                position: 'absolute',
                left: '38px',
                top: '23px',
                width: 'fit-content',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>

                {/* <Avatar
                    square
                    size={32}
                    src={testAvatar}
                /> */}

                <Box sx={{
                    height: '32px',
                    width: '32px',
                    minHeight: '32px',
                    minWidth: '32px',
                    maxHeight: '32px',
                    maxWidth: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid rgba(27, 31, 36, 0.15)',
                    borderRadius: '6px',
                    fontFamily: 'Avenir LT Std',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    color: '#24292F',
                    background: '#F1F8FF'
                }}>

                    {contactProfileData?.title?.substring(0, 1)}
                </Box>



                <Text sx={{
                    display: 'flex',
                    position: 'relative',
                    margin: '0px 0px 0px 16px',
                    fontFamily: 'Avenir LT Std',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    fontSize: '17px',
                    lineHeight: '20px',
                    color: '#24292F',
                    maxWidth: '233px',
                    width: 'auto',
                    minWidth: 'auto',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: "ellipsis"

                }}>
                    {contactProfileData?.title}

                </Text>


                <ActionMenu>
                    <ActionMenu.Anchor>
                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={
                                {
                                    background: '#ffffff',
                                    color: '#000000',
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    margin: '0px 0px 0px 5px',
                                }
                            }
                            icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}

                        />
                    </ActionMenu.Anchor>

                    <ActionMenu.Overlay align="start"
                        sx={{
                            width: '181px',
                            minWidth: '181px',
                        }}

                    >
                        <ActionList showDividers sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '4px',
                            alignItems: 'center',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            color: '#24292F',
                            fontWeight: '400',
                            lineHeight: '20px',
                            fontStyle: 'normal'
                        }}>
                            <ActionList.Item
                                sx={{
                                    display: isZohoInstalled ? 'flex' : 'none'
                                }}
                                onSelect={() => {
                                    addToZohoCustomer("")
                                }}
                            >

                                {/* Add to Zoho as a customer */}
                                Add as Zoho customer
                            </ActionList.Item>
                            <ActionList.Item
                                onSelect={() => {
                                    closeContactProfile("")
                                }}
                            >

                                Back to contact
                            </ActionList.Item>

                            <ActionList.Item variant="danger" onSelect={() => showDeleteOrgDailog("")}>

                                Delete organization
                            </ActionList.Item>

                        </ActionList>
                    </ActionMenu.Overlay>
                </ActionMenu>





            </Box>

            <IconButton
                size="small"
                variant="invisible"
                sx={
                    {
                        background: '#ffffff',
                        color: '#000000',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '31px',
                        right: '48px',
                    }
                }
                onClick={() => closeContactProfile("")}
                icon={() => <Cross2Icon style={{ display: 'flex' }} />}

            />

            <Box sx={{
                position: 'absolute',
                left: '38px',
                top: '64px',
                width: 'fit-content',
                maxWidth: 'fit-content',
                height: 'fit-content',
                fontFamily: 'Segoe UI',
                display: 'flex',
                justifyContent: 'flex-start',

            }}>

                <UnderlineNav
                    key={"" + Math.random(100, 20000) + ""}
                    sx={{ border: 'none', paddingLeft: '0px', marginLeft: '0px', minWidth: '1000px' }}
                >
                    {items.map((item, index) => {
                        return (
                            <UnderlineNav.Item
                                style={{
                                    fontFamily: 'Avenir LT Std',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: item.isSelected == true ? "#000000" : "#586069"
                                }}

                                key={item.navigation}
                                icon={item.icon}
                                onSelect={e => {
                                    setSelectedIndex(index)
                                    e.preventDefault()
                                }}
                                //  selected={item.isSelected}
                                selected={index === selectedIndex ? true : false}
                                counter={item.counter}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        )
                    })}
                </UnderlineNav>
            </Box>



            <Box sx={{
                display: 'none',
                //  display:selectedIndex===5?"none":"flex",
                width: '386px',
                height: '539px',
                position: 'absolute',
                top: '134px',
                right: '69px',
                border: '1px solid #D0D7DE',
                borderRadius: '3px',

            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: '35px',
                    bottom: '35px',
                    left: '40px',
                    gap: '26px',

                }}>
                    {
                        companyData.map((row, index) => {

                            return (

                                <Box sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '19px',
                                    fontStyle: 'normal',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '25px',
                                }}>
                                    <Text sx={{
                                        minWidth: '84px',
                                        fontFamily: 'Segoe UI',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '19px',
                                        fontStyle: 'normal',
                                        color: '#000000'
                                    }}>{row.label}</Text>
                                    <Text
                                        sx={{ color: index === 2 ? '#28A745' : '#24292E' }}
                                    >{row.value}</Text>
                                </Box>
                            )

                        })
                    }
                </Box>






            </Box>



            {
                selectedIndex === 0 ? ActivityComponent("") :
                    selectedIndex === 1 ? ComplianceComponent("") :
                        selectedIndex === 2 ? ProfileComponent("") :
                            selectedIndex === 3 ? gmailComponent("") :
                                selectedIndex === 4 ? credentialComponent("") :
                                    selectedIndex === 5 ? TaskComponents("") :
                                        selectedIndex === 6 ? FilesComponent("") :
                                            selectedIndex === 7 ? TeamComponent("") :
                                                selectedIndex === 8 ? InvoiceComponent("") :
                                                    ""
            }


            {/* {AddCompanyCompliance("")} */}

            {selectedIndex === 1 ? AddCompanyCompliance("") : ""}

            <DeleteOrganization contactId={contactProfileData?.data?.contact_id} />

        </Box>
    )


}

export default ContactProfile






