
import React from "react";
import { Box, Text, ActionList, ActionMenu, Button, IconButton, Avatar, Label, Dialog, Heading, TextInput, Portal, Select, Spinner } from "@primer/react"
import { ArrowDownIcon, ArrowUpIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import { FeedPersonIcon, KebabHorizontalIcon, PersonAddIcon, XIcon } from "@primer/octicons-react";
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import ShareDialog from "../Workplace/Dialogs/ShareDialog";
import { useDispatch, useSelector } from "react-redux";
import { sharingOpenClose } from "../redux/features/sharingSlice";
import { filteredTeamUserId, teamsearchOpenClose } from "../redux/features/searchSlice"
import TeamSearchDialogs from "../Workplace/Dialogs/TeamSearchDialogs";
import CreateTask from "./CreateTask";
import RightSideBarOpen from "./RightSideBarOpen";
import { deleteProjectInvitations, deleteTeamMember, getProjectInvitations, getSpaceCollaborators, getTeamLog, getTimesheetData, resendInvitation, updateCollaboratorWorkingHours } from "../redux/features/spaceSlice";
import InviteTeamMembers from "../Workplace/Dialogs/InviteTeamMembers";
import { Toaster, Intent,Position,ProgressBar } from "@blueprintjs/core";
import Papa from "papaparse"; 
import customTheme from "../../Theme.module.scss"   
import customThemVariable from "../../ThemeVariable.module.scss"
import CustomCircleProgress from "./CustomCircleProgress";

const downloadToast = Toaster.create({ position: Position.TOP })
function Team(props) {

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const teamUserId = useSelector((state) => state.searchData.teamUserId)
    const reloadTeamTrigger = useSelector((state) => state.sharingData.reloadTeamTrigger)

    const dispatch = useDispatch()
    const [showWorkingHourDialog,setshowWorkingHourDialog] = React.useState({userId:'',isShown:false,startingHour:'',endingHour:''})
    const [showLogDialog, setshowLogDialog] = React.useState({userId:'',isShown:false}) 
    const [showdeleteDialog, setshowdeleteDialog] = React.useState(false)
    const [showEmptyComp, setshowEmptyComp] = React.useState(false)
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [logTaskArray,setlogTaskArray] = React.useState([])
    const [copyLogTaskArray,setcopyLogTaskArray] = React.useState([])
    const [taskWithDueDate,settaskWithDueDate] = React.useState([])
    const [copytaskWithDueDate,setcopytaskWithDueDate] = React.useState([])
    const [taskwithinDuedate,settaskwithinDuedate] = React.useState([])
    const [copytaskwithinDuedate,setcopytaskwithinDuedate] = React.useState([])
    const [overdueTasks,setoverdueTasks] = React.useState([])
    const [copyoverdueTasks,setcopyoverdueTasks] = React.useState([])
    const [selectedOption,setselectedOption] = React.useState([])
    const [companyWorkingHours,setcompanyWorkingHours] = React.useState()
    

    const [logHeaderds, setlogHeaderds] = React.useState({
        totalNoOfTask: 0,
        totalCompTask: 0,
        percentage: 0,
        averageTime: 0,
        overdueRation: 0,
        overAllEffiency:0,
        timelydeliveryIndex:0
    })

    const [previousHeads,setpreviousHeads] = React.useState({
         
         averageTimePrev:0,
         prevtimelydeliveryIndex:0,
         prevoverdueRation:0,
    })
     


    const [deletTeamData, setdeletTeamData] = React.useState({
        userId: '',
        userRole: '',
        projectId: ''
    })
    const [currentUserRole,setcurrentUserRole] = React.useState("")
    const [pendingInvitations,setpendingInvitations] = React.useState([])
    const [downloadtimesheetDialog,setdownloadtimesheetDialog] = React.useState(false)
    const [timesheetDates,settimesheetDates] = React.useState({
          startDate:"",
          startDateValidation:"",
          endDate:"",
          endDateValidation:"",
          collboratorId:''
    })
    const timesheetDatesstartcalandarRef = React.createRef()
    const timesheetDatesendcalandarRef = React.createRef()


    const showSharingDialog = (props) => {

        dispatch(sharingOpenClose({
            openClose: true,
            workplaceId: localStorage.getItem("workplaceId"),
            shareType: "space",
            projectId: localStorage.getItem("projectId"),
            title: localStorage.getItem("projectName")
        }))

    }

    const showDeleteMemberDialog = (props) => {

        setshowdeleteDialog(true)
    }

    const closeDeleteMemberDialog = (props) => {

        setdeletTeamData((prevState) => {
            return ({ ...prevState, userId: '', userRole: '', projectId: '' })
        })

        setshowdeleteDialog(false)
    }

    const showSearchDialog = (props) => {
        dispatch(teamsearchOpenClose({
            teamopenClose: true,
            projectId: localStorage.getItem("projectId")
        }))
    }


 

    function calculateAverageTaskTime(tasks,companyWorkingHours) {
        
        console.log("completedtasks",tasks)
        console.log("companyWorkingHours",companyWorkingHours)

        let totalDuration = 0;
        let tasksWithinWorkingHours = 0;
        tasks.forEach(task => {

            const taskName = task?.task_name
            const taskCreatedTimeStamp = task.created_timestamp
            const taskCompletedTimeStamp = task.completed_timestamp


            const createdTimestamp = new Date(taskCreatedTimeStamp).getTime();
            const completedTimestamp = new Date(taskCompletedTimeStamp).getTime();
            const duration = completedTimestamp - createdTimestamp;
            const completedTimestampHour = new Date(taskCompletedTimeStamp).getUTCHours()

            console.log("taskName", taskName, completedTimestampHour, duration, completedTimestamp, createdTimestamp)
            if ((completedTimestampHour >= companyWorkingHours?.startingHour && completedTimestampHour < companyWorkingHours?.endingHour) && duration <= (task.workingHours * 60 * 60 * 1000)) {

                totalDuration += duration;
                tasksWithinWorkingHours++;

            } else {


            }

            


        });
        const result = tasksWithinWorkingHours > 0 ? totalDuration / tasksWithinWorkingHours : 0
        return result / (1000 * 60 * 60);
            
    }

    
    const getTeamLogFromDb = (props) => {

        dispatch(getTeamLog({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            collaboratorId: props,
            selectedPeriod:''
        })).then(async (data) => {

            console.log("getTeamLog", data.payload)
            const newtaskWithDueDate = [...data.payload.taskWithDueDate]
            const newtaskwithinDuedate = [...data.payload.taskwithinDuedate]
            const overdueTaskArray = [...data.payload.overdueTasks]
            const logTaskArray = [...data.payload.taskWithDueDate]
            const newcompanyWorkingHour = data.payload.companyWorkingHour
            setcompanyWorkingHours(newcompanyWorkingHour)

            setcopyLogTaskArray(newtaskWithDueDate)
            // setlogTaskArray(newtaskWithDueDate)
            settaskWithDueDate(newtaskWithDueDate)
            setcopytaskWithDueDate(newtaskWithDueDate)
            settaskwithinDuedate(newtaskwithinDuedate)
            setcopytaskwithinDuedate(newtaskwithinDuedate)
            setoverdueTasks(overdueTaskArray)
            setcopyoverdueTasks(overdueTaskArray)

            const completedCount = [...newtaskwithinDuedate]
            const percentage = parseFloat((completedCount.length / logTaskArray.length) * 100).toFixed(1)
            const overDuePercentage = parseFloat((overdueTaskArray.length / logTaskArray.length) * 100).toFixed(1)
            // const averageTime = { averageHours: parseFloat((completedCount.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCount.length).toFixed(2) }    //await calculateAverageTimeTaken(compTask)
            const timeDelIndex = parseFloat((newtaskwithinDuedate.length / newtaskWithDueDate.length) * 100).toFixed(2)
            const averageTime = { averageHours: parseFloat(calculateAverageTaskTime(completedCount,newcompanyWorkingHour)).toFixed(2) }
            setlogTaskArray(completedCount)
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, overAllEffiency: percentage, totalNoOfTask: logTaskArray.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })
 
        })

    }

    

    // handleFilter Dropdown  
    // 
    const handleFilterLogTask =async (props) => {

        console.log("handleFilterLogTask", props)
        setselectedOption(props)
        if (props === "7days") { // 7days 

            const now = new Date();  
            const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // Seven days ago
            const sevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("7DayTask", sevenDayTask)
            const completedCount = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("7dayscompleted", completedCount)
            const percentage = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(1)
            setlogTaskArray(completedCount)

           // const averageTime = { averageHours: parseFloat((completedCount.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCount.length).toFixed(2) }    //await calculateAverageTimeTaken(compTask)
 
            console.log("abcavgCount",completedCount)
            const averageTime = { averageHours: parseFloat(calculateAverageTaskTime(completedCount,companyWorkingHours)).toFixed(2) }


            console.log("averageTime", averageTime)

            const overDueCount = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("overDueCount", overDueCount)
            const overDuePercentage = parseFloat((overDueCount.length / sevenDayTask.length) * 100).toFixed(1)
            const timeDelIndex = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(2)
         
         
         
         
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, totalNoOfTask: sevenDayTask.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })

                
           


            //------------ prevoius 7 days calculations------------------------

            const nowprev = new Date(sevenDaysAgo) //new Date(new Date(sevenDaysAgo.getTime() - 7 * 24 * 60 * 60 * 1000));
            console.log("nowprev", nowprev)
            const prevsevenDaysAgo = new Date(nowprev.getTime() - 7 * 24 * 60 * 60 * 1000); // Seven days ago
            const prevsevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("7DayTaskprev", prevsevenDayTask)
            const completedCountPrev = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("7dayscompletedPrev", completedCountPrev)
            const percentagePrev = parseFloat((completedCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)
            const averageTimePrev = { averageHours: parseFloat((completedCountPrev.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCountPrev.length).toFixed(1) }    //await calculateAverageTimeTaken(compTask)
          
          
            console.log("averageTimePrev", averageTimePrev)
            const overDueCountPrev = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("overDueCount", overDueCountPrev)
            const overDuePercentagePrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)
            const timeDelIndexPrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(2)
            setpreviousHeads((prevState) => {
                return ({
                    ...prevState,
                    averageTimePrev: isNaN(averageTimePrev.averageHours) ? "0" : parseFloat(averageTimePrev.averageHours).toFixed(0),
                    prevtimelydeliveryIndex: isNaN(timeDelIndexPrev) ? "0" : parseFloat(timeDelIndexPrev).toFixed(0),
                    prevoverdueRation: isNaN(overDuePercentagePrev) ? "0" : parseFloat(overDuePercentagePrev).toFixed(0),
                })
            })
            //-----------------------------------------------------------------

  
        } else if (props === "5days") {
            
            const now = new Date();  
            const sevenDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000); // Seven days ago
            const sevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("5DayTask", sevenDayTask)
            const completedCount = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })

            console.log("5dayscompleted", completedCount)
            setlogTaskArray(completedCount)
            const percentage = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(1)
           
           // const averageTime = { averageHours: parseFloat((completedCount.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCount.length).toFixed(2) }    //await calculateAverageTimeTaken(compTask)
           
           
            console.log("abcavgCount",completedCount)
            const averageTime = { averageHours: parseFloat(calculateAverageTaskTime(completedCount,companyWorkingHours)).toFixed(2) }

           
            console.log("averageTime", averageTime)
           
           
           
            const overDueCount = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("overDueCount", overDueCount)
            const overDuePercentage = parseFloat((overDueCount.length / sevenDayTask.length) * 100).toFixed(1)
            const timeDelIndex = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(2)
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, totalNoOfTask: sevenDayTask.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })


            //---------------

            const nowprev = new Date(sevenDaysAgo) //new Date(new Date(sevenDaysAgo.getTime() - 7 * 24 * 60 * 60 * 1000));
            console.log("nowprev", nowprev)
            const prevsevenDaysAgo = new Date(nowprev.getTime() - 5 * 24 * 60 * 60 * 1000); // Seven days ago
            const prevsevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("7DayTaskprev", prevsevenDayTask)
            const completedCountPrev = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("7dayscompletedPrev", completedCountPrev)
            const percentagePrev = parseFloat((completedCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)

            const averageTimePrev = { averageHours: parseFloat((completedCountPrev.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCountPrev.length).toFixed(1) }    //await calculateAverageTimeTaken(compTask)
            console.log("averageTimePrev", averageTimePrev)
            const overDueCountPrev = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
            })
            console.log("overDueCount", overDueCountPrev)
            const overDuePercentagePrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)
            const timeDelIndexPrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(2)
            setpreviousHeads((prevState) => {
                return ({
                    ...prevState,
                    averageTimePrev: isNaN(averageTimePrev.averageHours) ? "0" : parseFloat(averageTimePrev.averageHours).toFixed(0),
                    prevtimelydeliveryIndex:isNaN(timeDelIndexPrev) ? "0" : parseFloat(timeDelIndexPrev).toFixed(0) ,
                    prevoverdueRation:isNaN(overDuePercentagePrev) ? "0" : parseFloat(overDuePercentagePrev).toFixed(0),
                })
            })
            //-----------------------------------------------------------------
            

 
        } else if (props === "30days") {
            
            const now = new Date(); // Current date and time
            const sevenDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // Seven days ago
            const sevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("30DayTask", sevenDayTask)
            const completedCount = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })

            console.log("30dayscompleted", completedCount)

            setlogTaskArray(completedCount)
            const percentage = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(1)
           
           // const averageTime = { averageHours: parseFloat((completedCount.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCount.length).toFixed(2) }    //await calculateAverageTimeTaken(compTask)
          
            console.log("abcavgCount",completedCount)
            const averageTime = { averageHours: parseFloat(calculateAverageTaskTime(completedCount,companyWorkingHours)).toFixed(2) }

          
            console.log("averageTime", averageTime)
            const overDueCount = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate >= sevenDaysAgo && taskDate <= now;
            })
            console.log("overDueCount", overDueCount)
            const overDuePercentage = parseFloat((overDueCount.length / sevenDayTask.length) * 100).toFixed(1)
            const timeDelIndex = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(2)
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, totalNoOfTask: sevenDayTask.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })


           //-------------------------- 


           const nowprev = new Date(sevenDaysAgo) //new Date(new Date(sevenDaysAgo.getTime() - 7 * 24 * 60 * 60 * 1000));
           console.log("nowprev", nowprev)
           const prevsevenDaysAgo = new Date(nowprev.getTime() - 30 * 24 * 60 * 60 * 1000); // Seven days ago
           const prevsevenDayTask = [...copytaskWithDueDate].filter(task => {
               const taskDate = new Date(task.created_timestamp);
               return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
           })
           console.log("7DayTaskprev", prevsevenDayTask)
           const completedCountPrev = [...copytaskwithinDuedate].filter(task => {
               const taskDate = new Date(task.created_timestamp);
               return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
           })
           console.log("7dayscompletedPrev", completedCountPrev)
           const percentagePrev = parseFloat((completedCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)

           const averageTimePrev = { averageHours: parseFloat((completedCountPrev.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCountPrev.length).toFixed(1) }    //await calculateAverageTimeTaken(compTask)
           console.log("averageTimePrev", averageTimePrev)
           const overDueCountPrev = [...copyoverdueTasks].filter(task => {
               const taskDate = new Date(task.created_timestamp);
               return taskDate >= prevsevenDaysAgo && taskDate <= nowprev;
           })
           console.log("overDueCount", overDueCountPrev)
           const overDuePercentagePrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(1)
           const timeDelIndexPrev = parseFloat((overDueCountPrev.length / prevsevenDayTask.length) * 100).toFixed(2)
           setpreviousHeads((prevState) => {
               return ({
                   ...prevState,
                   averageTimePrev: isNaN(averageTimePrev.averageHours) ? "0" : parseFloat(averageTimePrev.averageHours).toFixed(0),
                   prevtimelydeliveryIndex:isNaN(timeDelIndexPrev) ? "0" : parseFloat(timeDelIndexPrev).toFixed(0) ,
                   prevoverdueRation:isNaN(overDuePercentagePrev) ? "0" : parseFloat(overDuePercentagePrev).toFixed(0),
               })
           })
           //-----------------------------------------------------------------
           


            
          
        } else if (props === "yesterday") {

            const now = new Date();
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1);
            const sevenDayTask = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterday.toDateString();
            })
            console.log("yesterdayTaskyest", sevenDayTask)
            const completedCount = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterday.toDateString() && taskDate <= now;

            })
            console.log("yestayscompleted", completedCount)
            setlogTaskArray(completedCount)
            console.log("completedCountyestday", completedCount)
            const percentage = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(1)
          //  const averageTime = { averageHours: parseFloat((completedCount.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCount.length).toFixed(2) }    //await calculateAverageTimeTaken(compTask)
          //  console.log("averageTime", averageTime)
           
            console.log("abcavgCount",completedCount)
            const averageTime = { averageHours: parseFloat(calculateAverageTaskTime(completedCount,companyWorkingHours)).toFixed(2) }

           
           
            const overDueCount = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterday.toDateString() && taskDate <= now;
            })
            console.log("overDueCountyest", overDueCount)
            const overDuePercentage = parseFloat((overDueCount.length / sevenDayTask.length) * 100).toFixed(1)
            const timeDelIndex = parseFloat((completedCount.length / sevenDayTask.length) * 100).toFixed(2)
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, totalNoOfTask: sevenDayTask.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })


            //------------- code for previous yesterday ---------------

            const nowPrev = new Date(yesterday);
            const yesterdayPrev = new Date(now);
            yesterdayPrev.setDate(nowPrev.getDate() - 1);
            const sevenDayTaskPrev = [...copytaskWithDueDate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterdayPrev.toDateString();
            })
            console.log("yesterdayTaskyest", sevenDayTaskPrev)
            const completedCountPrev = [...copytaskwithinDuedate].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterdayPrev.toDateString() && taskDate <= nowPrev;

            })
            console.log("yestayscompletedPrev", completedCountPrev)
            console.log("completedCountyestdayPrev", completedCountPrev)
            const percentagePrev = parseFloat((completedCountPrev.length / sevenDayTaskPrev.length) * 100).toFixed(1)
            const averageTimePrev = { averageHours: parseFloat((completedCountPrev.reduce((total, task) => total + parseFloat(task.totalHours), 0)) / completedCountPrev.length).toFixed(2) }    
            console.log("averageTimePrev", averageTimePrev)
            const overDueCountPrev = [...copyoverdueTasks].filter(task => {
                const taskDate = new Date(task.created_timestamp);
                return taskDate.toDateString() === yesterdayPrev.toDateString() && taskDate <= nowPrev;
            })
            console.log("overDueCountyestPrev", overDueCountPrev)
            const overDuePercentagePrev = parseFloat((overDueCountPrev.length / sevenDayTaskPrev.length) * 100).toFixed(1)
            const timeDelIndexPrev = parseFloat((completedCountPrev.length / sevenDayTaskPrev.length) * 100).toFixed(2)
          
          
            setlogHeaderds((prevState) => {
                return ({ ...prevState, timelydeliveryIndex: timeDelIndex, totalNoOfTask: sevenDayTask.length, totalCompTask: completedCount.length, percentage: percentage, averageTime: averageTime.averageHours, overdueRation: overDuePercentage })
            })

            setpreviousHeads((prevState) => {
                return ({
                    ...prevState,
                    averageTimePrev: isNaN(averageTimePrev.averageHours) ? "0" : parseFloat(averageTimePrev.averageHours).toFixed(0),
                    prevtimelydeliveryIndex: isNaN(timeDelIndexPrev) ? "0" : parseFloat(timeDelIndexPrev).toFixed(0),
                    prevoverdueRation: isNaN(overDuePercentagePrev) ? "0" : parseFloat(overDuePercentagePrev).toFixed(0),
                })
            })

            //---------------------------------------------------------
    

        } else if (props === "overall") {

            setpreviousHeads((prevState) => {
                return ({
                    ...prevState,
                    averageTimePrev: 0,
                    prevtimelydeliveryIndex: 0,
                    prevoverdueRation: 0,
                })
            })

            getTeamLogFromDb(showLogDialog.userId)

        } else if (props === "today") {

            dispatch(getTeamLog({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                collaboratorId: showLogDialog.userId,
                selectedPeriod: props
            })).then((data) => {
                console.log("todayresponse", data.payload)
            })

        }


    }

    const openWorkingHourDialog = (props) => {

          setshowWorkingHourDialog((prevState)=>{
             return({...prevState,isShown:true,userId:props,startingHour:'',endingHour:''})
          })

    }
    const closeWorkingHourDialog = (props) => {

          setshowWorkingHourDialog((prevState)=>{
           return({...prevState,isShown:false,userId:'',startingHour:'',endingHour:''})
          })

    }

    const handleChangeWorkingHours = (props,value) => {

        if (props === "startingHour") {

            setshowWorkingHourDialog((prevState) => {
                return ({ ...prevState, startingHour: value })
            })


        } else if (props === "endingHour") {

            setshowWorkingHourDialog((prevState) => {
                return ({ ...prevState, endingHour: value })
            })

        }

    }

    const updateCollabWorkingHourInDb = (props) => {

             if(showWorkingHourDialog.startingHour!==''&&showWorkingHourDialog.endingHour!==''){

                 if (parseInt(showWorkingHourDialog.endingHour.split(":")[0]) < parseInt(showWorkingHourDialog.startingHour.split(":")[0])) {
               
                       Toaster.create({ position: Position.TOP }).show({
                        message: "Starting Hour should be greater than Ending  Hours",
                        intent: Intent.PRIMARY
                       });
    
                 }else{

                        const newworkingHoursCollaborator = {
                               collaboratorId:showWorkingHourDialog.userId,
                               startTime:showWorkingHourDialog.startingHour,
                               endTime:showWorkingHourDialog.endingHour
                        }

                        dispatch(updateCollaboratorWorkingHours({
                               userId:userId,
                               userEmail:userEmail,
                               projectId:localStorage.getItem("projectId"),
                               workingHoursCollaborator:newworkingHoursCollaborator
                        })).then((data)=>{

                            console.log("updateCollaboratorWorkingHours", data.payload)

                            if (data.payload.data.length > 0) {

                                Toaster.create({ position: Position.TOP }).show({
                                    message: "Sucessfully Updated",
                                    intent: Intent.SUCCESS
                                });
                                closeWorkingHourDialog("")

                            }


                        })



                 }

                 
             }

    }

    const openLogDialog = (props) => {

          setshowLogDialog((prevState)=>{
               return({...prevState,isShown:true,userId:props})
          })
          getTeamLogFromDb(props)


    }

    const closeLogDialog = (props) => {

        setshowLogDialog((prevState) => {
            return ({ ...prevState, isShown: false, userId: '' })
        })
        setlogTaskArray([])
        setlogHeaderds(
            {
                totalNoOfTask: 0,
                totalCompTask: 0,
                percentage: 0,
                averageTime: 0,
                overdueRation: 0,
                overAllEffiency: 0,
                timelydeliveryIndex: 0
            }
        )
        setselectedOption([])
        setpreviousHeads({
                averageTimePrev: 0,
                prevtimelydeliveryIndex: 0,
                prevoverdueRation: 0,
        })
    }



    const deleteTeamMemberIndb = (props) => {


        const userRole = spaceCollaborators?.filter((sData)=> sData.user_id===userId)[0]?.user_role 
        if (userRole === "Owner") {
            if (userId === deletTeamData.userId) {

                closeDeleteMemberDialog("")
            } else {

                dispatch(deleteTeamMember({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: localStorage.getItem("projectId"),
                    deleteTeamData: deletTeamData
                })).then((data) => {

                    closeDeleteMemberDialog("")
                    Toaster.create({ position: Position.TOP }).show({ message: "Team member has been removed", intent: Intent.SUCCESS });
                    getSpaceCollaboratorFromDb("")

                })

            }
        } else {
            closeDeleteMemberDialog("")
            Toaster.create({ position: Position.TOP }).show({ message: "Nil", intent: Intent.PRIMARY });
        }
        
    }


    const getSpaceCollaboratorFromDb = (props) => {


        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const collabArray = [...data.payload.collaboratorArray]
            const currentUserRole = collabArray.filter((cData)=> cData.user_id===userId)[0]?.user_role
            setcurrentUserRole(currentUserRole) 
           



            setspaceCollaborators(data.payload.collaboratorArray)
            if (data?.payload?.collaboratorArray?.length <= 0) {
                setshowEmptyComp(true)
            } else {

                setshowEmptyComp(false)
                

            }



        })

    }


    const getProjectInvitationsFromDb = (props) => {

        
        dispatch(getProjectInvitations({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            console.log("getProjectInvitations",data.payload)
            setpendingInvitations(data.payload.inviations)

        })

    }

    const handleRevokeInvitation = (props) => {

        const invitationId = props.invitation_id
        dispatch(deleteProjectInvitations({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            invitationId: invitationId
        })).then((data) => {

            Toaster.create({ position: Position.TOP }).show({ message: data.payload.data, intent: Intent.SUCCESS });
            getProjectInvitationsFromDb("")
            
        })
         
    }

    const handleResendInvitation = (props) => {

        // alert(JSON.stringify(props))
        //  Toaster.create({ position: Position.TOP }).show({ message: "Feature not enabled yet", intent: Intent.PRIMARY });
        const invitationId = props.invitation_id
        dispatch(resendInvitation({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            invitationId: invitationId
        })).then((data) => {


            console.log("resendInvitation", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: data.payload.message, intent: Intent.SUCCESS })
        })



    }



    const loadingToast = (progressPercentage, key) => {

        downloadToast.show({
            icon: "cloud-download",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    downloadToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 2000,


        }, key);

    }

    const showdownloadtimesheetDialog = (props) => {

        setdownloadtimesheetDialog(true)
        settimesheetDates((prevState) => {
            return ({ ...prevState, collboratorId: props.user_id })
        })

    }

    const closedownloadtimesheetDialog = (props) => {
       
        setdownloadtimesheetDialog(false)
        settimesheetDates((prevState)=>{
             return({...prevState,startDate:'',endDate:'',startDateValidation:'',endDateValidation:'',collboratorId:''})
        })

    }

    const downloadTimesheetExcel = (props) => {



        const sixDigitRandomNumber = Math.floor(100000 + Math.random() * 900000);
        const tArray = [...props]
        const csv = Papa.unparse(tArray);
        const newCsvData = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const csvData = newCsvData;
        const link = document.createElement("a");
        link.setAttribute("href", csvData);
        link.setAttribute("download", sixDigitRandomNumber + 'timesheet.csv');
        link.click();
    }
    

    const getTimesheetDataFromDb = (props, startDate, endDate) => {

        dispatch(getTimesheetData({
            userId: userId,
            userEmail: userEmail || localStorage.getItem("userEmail"),
            projectId: localStorage.getItem("projectId"),
            collaboratorId: props,
            startDate: startDate,
            endDate: endDate
        })).then((data) => {


            console.log("getTimesheetData", data.payload)
            const timeSheetArray = [...data?.payload?.timeSheetArray]
            console.log("timeSheetArray", timeSheetArray)
            if (timeSheetArray.length > 0) {
                loadingToast(100, "1")

                downloadTimesheetExcel(timeSheetArray)
            } else {

                downloadToast.clear()
                Toaster.create({ position: Position.TOP }).show({ message: "No Data Found", intent: Intent.SUCCESS });
            }


        })

    }

    const handleDownloadTimeSheet = (props) => {

        if (timesheetDates.startDate === "" && timesheetDates.endDate === "") {

            settimesheetDates((prevState) => {
                return { ...prevState, startDateValidation: 'error', endDateValidation: 'error' }
            })

        } else if (timesheetDates.startDate === "") {

            settimesheetDates((prevState) => {
                return { ...prevState, startDateValidation: 'error' }
            })

        } else if (timesheetDates.endDate === "") {

            settimesheetDates((prevState) => {
                return { ...prevState, endDateValidation: 'error' }
            })
        } else {

            settimesheetDates((prevState) => {
                return { ...prevState, startDateValidation: '', endDateValidation: '' }
            })
            closedownloadtimesheetDialog("")
            loadingToast(10, "1")
            getTimesheetDataFromDb(timesheetDates.collboratorId, timesheetDates.startDate, timesheetDates.endDate)
        }      

    }

    


    const EmptyTeamComp = (props) => {

        return (
            <Box sx={{
                width: '434px',
                height: '184px',
                minWidth: '434px',
                minHeight: '184px',
                maxWidth: '434px',
                maxHeight: '184px',
                borderRadius: '6px',
                border: '1px solid #D0D7DE',
                background: '#FFFFFF',
                position: 'absolute',
                top: '130px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>

                <Box sx={{
                    width: '16px',
                    height: '16px',
                    minWidth: '16px',
                    minHeight: '16px',
                    maxWidth: '16px',
                    maxHeight: '16px',
                    position: 'relative',
                    margin: '40px 0px 0px 0px'
                }}>

                    <PersonAddIcon />

                </Box>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '36px',
                    textAlign: 'center',
                    color: '#24292F',
                    position: 'relative',
                    margin: '16px 0px 0px 0px'
                }}>
                    Invite Your Teammates
                </Text>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: '#57606A',
                    position: 'relative',
                    margin: '4px 32px 0px 32px'
                }}>
                    Invite your organization's team members and use the add button to streamline your practices more effectively.
                </Text>





            </Box>
        )

    }


    const WorkingHourDialog = (props) => {
        
          return (
              <Dialog
                  isOpen={showWorkingHourDialog.isShown}
                  onDismiss={() => closeWorkingHourDialog("")}
                  aria-labelledby="header-id"
                  sx={{
                      width: '550px',
                      minWidth: '550px',
                      maxWidth: '550px',
                      height: '182px',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center'
                  }}
              >

                  <Box sx={{
                      position: 'absolute',
                      height: '20px',
                      minHeight: '20px',
                      left: '35px',
                      right: '35px',
                      top: '15px',
                      display: 'flex',
                  }}>
                      <Text className={customTheme.H5} >
                          Working Hour
                      </Text>
                  </Box>

                  <Box
                      sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                           
                      }}
                  >
                      <TextInput
                          sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', fontStyle: 'normal' }}
                          placeholder="Starting Hour"
                          onChange={(e) => {

                              handleChangeWorkingHours("startingHour", e.target.value)
                          }}
                         // validationStatus={showWorkingHourDialog.startingHour}
                          value={showWorkingHourDialog.startingHour}
                          type="time"
                      />
                      <TextInput
                          sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', fontStyle: 'normal' }}
                          placeholder="Closing Hour"
                          onChange={(e) => {
                               handleChangeWorkingHours("endingHour", e.target.value)
                          }}
                          value={showWorkingHourDialog.endingHour}
                        //  validationStatus={workingHours.endTimeError}
                          type="time"
                      />
                      <Button variant="outline"
                          sx={{
                              position: 'relative',
                              margin: 'auto 0px auto 18px',
                              background: '#0965CE',
                              color: '#ffffff',
                              display:'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontSize: '14px',
                              lineHeight: '20px',
                              fontWeight: '600',

                          }}
                          onClick={() => {
                               updateCollabWorkingHourInDb("")
                          }}
                      >
                          Update
                      </Button>


                  </Box>
                    
              </Dialog>
          )

    }


    const LogDialog = (props) => {
 
        return (
        
            <Dialog
                isOpen={showLogDialog.isShown}
                onDismiss={() => closeLogDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '950px',
                    minWidth:'950px',
                    maxWidth:'950px',
                    height: '582px',
                }}
            >

                {/* new Design here */}

                 
                <Box sx={{
                    position: 'absolute',
                    height: '20px',
                    minHeight: '20px',
                    left: '35px',
                    right: '35px',
                    top: '35px',
                    display: 'flex',
                }}>
                    <Text className={customTheme.H5} >
                        Workforce Performance Monitor
                    </Text>
                </Box>


                <Box sx={{
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    height: '56px',
                    width: '65px',
                    background: 'white',
                }}>
                    <IconButton
                        sx={{ border: 'none', background: 'white', marginTop: '28px' }}
                        icon={XIcon}
                        onClick={() => {
                            closeLogDialog("")
                        }}
                    />
                </Box>
               
                <Box sx={{
                    position: 'absolute',
                    height: '150px',
                    minHeight: '150px',
                    maxHeight:'150px',
                    left:'35px',
                    right:'35px',
                    top:'72px',
                    display: 'flex',
                    flexDirection:'row',
                    gap:'50px',
                    alignItems:'flex-end'
                }}>

                    <Box
                        sx={{
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            height: '20px',
                            '& span': {
                                minHeight: '20px',
                                border: 'none',
                                fontSize: '14px',
                                fontFamily: 'Inter',
                            },
                        }}>
                        <Select
                            onChange={(e) => {
                                handleFilterLogTask(e.target.value)
                            }}
                            style={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                            }}
                        >
                            <Select.Option className={customTheme.textnormal} value="all" selected={selectedOption === "" ? true : false}>Select date</Select.Option>
                            <Select.Option className={customTheme.textnormal} value="7days" selected={selectedOption === "7days" ? true : false}>7 days</Select.Option>
                            <Select.Option className={customTheme.textnormal} value="5days" selected={selectedOption === "5days" ? true : false}>5 days</Select.Option>
                            <Select.Option className={customTheme.textnormal} value="yesterday" selected={selectedOption === "yesterday" ? true : false}>Yesterday</Select.Option>
                            <Select.Option className={customTheme.textnormal} value="30days" selected={selectedOption === "30days" ? true : false}>30 days</Select.Option>
                            <Select.Option className={customTheme.textnormal} value="overall" selected={selectedOption === "overall" ? true : false}>Overall</Select.Option>
                            {/* <Select.Option className={customTheme.textnormal} value="today" selected={selectedOption === "today" ? true : false}>Today</Select.Option> */}
                           
                        </Select>
                    </Box>

               
                    <Box 
                     sx={{
                         height:'110px',
                         minHeight:'110px',
                         maxHeight:'110px',
                         width:'fit-content',
                         maxWidth:'fit-content',
                         minWidth:'fit-content',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         gap:'10px',
                         position:'relative'
                     }}
                    >

                        <Box sx={{
                            position: 'absolute',
                            width: 'auto',
                            height: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            right: '0px',
                            top: '0px'
                        }}>

                            {parseFloat(previousHeads?.averageTimePrev) > parseFloat(logHeaderds.averageTime) ?
                                <>
                                    <ArrowDownIcon color="#CB2431"></ArrowDownIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {parseFloat(parseFloat(previousHeads?.averageTimePrev) - parseFloat(logHeaderds.averageTime)).toFixed(1) === "NaN" ? "0" :
                                            parseFloat(parseFloat(previousHeads?.averageTimePrev) - parseFloat(logHeaderds.averageTime)).toFixed(1)
                                        } Hr
                                    </Text>
                                </>
                                :
                                <>
                                    <ArrowUpIcon color="#34B53A"></ArrowUpIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {parseFloat(parseFloat(logHeaderds.averageTime) - parseFloat(previousHeads?.averageTimePrev)).toFixed(1) === "NaN" ? "0" :
                                            parseFloat(parseFloat(logHeaderds.averageTime) - parseFloat(previousHeads?.averageTimePrev)).toFixed(1)
                                        } Hr
                                    </Text>
                                </>

                            }
                            
                            
                        </Box>

                        <Box sx={{
                            height: '80px',
                            minHeight: '80px',
                            maxHeight: '80px',
                            width: '80px',
                            maxWidth: '80px',
                            minWidth: '80px',
                            borderRadius: '50%',
                            background: '#E2FBD7'
                        }}>

                            <CustomCircleProgress
                                progress={logHeaderds.averageTime === "NaN" ? "0" : logHeaderds.averageTime}
                                unit={logHeaderds.averageTime === "NaN" ? "0 Hr" : logHeaderds.averageTime + " Hr"}
                                stroke="#34B53A"
                                fill="#E2FBD7"
                            />
                        </Box>
                         
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 'fit-content',
                            height: 'fit-content',
                            gap: '6px',
                            alignItems: 'center'
                        }}>

                            <Box sx={{ background: '#34B53A', height: '14px', width: '14px', borderRadius: '50%' }}></Box>
                            <Text className={customTheme.customfontsmall}>Task Time Utilization Average (TTUA)</Text>
                        </Box> 
                     </Box>
                         
                   
                     <Box 
                     sx={{
                         height:'110px',
                         minHeight:'110px',
                         maxHeight:'110px',
                         width:'180px',
                         maxWidth:'180px',
                         minWidth:'180px',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         gap:'10px',
                         position:'relative' 
                     }}
                    >

                        <Box sx={{
                            position: 'absolute',
                            width: 'auto',
                            height: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            right: '0px',
                            top: '0px'
                        }}>

                            {parseFloat(previousHeads?.prevtimelydeliveryIndex) < parseFloat(logHeaderds.timelydeliveryIndex)

                                ?
                                <>
                                    <ArrowDownIcon color="#CB2431"></ArrowDownIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {parseFloat(parseFloat(logHeaderds.timelydeliveryIndex) - parseFloat(previousHeads?.prevtimelydeliveryIndex)).toFixed(1) === "NaN" ? "0" :
                                            parseFloat(parseFloat(logHeaderds.timelydeliveryIndex) - parseFloat(previousHeads?.prevtimelydeliveryIndex)).toFixed(1)
                                        }%
                                    </Text>
                                </>
                                :
                                <>
                                    <ArrowUpIcon color="#34B53A"></ArrowUpIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {parseFloat(parseFloat(previousHeads?.prevtimelydeliveryIndex) - parseFloat(logHeaderds.timelydeliveryIndex)).toFixed(1) === "NaN" ? "0" :
                                            parseFloat(parseFloat(previousHeads?.prevtimelydeliveryIndex) - parseFloat(logHeaderds.timelydeliveryIndex)).toFixed(1)
                                        }%
                                    </Text>
                                </>

                            }

                           
                        </Box>

                        <Box sx={{
                               height:'80px',
                               minHeight:'80px',
                               maxHeight:'80px',
                               width:'80px',
                               maxWidth:'80px',
                               minWidth:'80px',
                               borderRadius:'50%',
                               background:'#DAD7FE'
                        }}>
                            <CustomCircleProgress
                                progress={logHeaderds.timelydeliveryIndex === "NaN" ? "0" : logHeaderds.timelydeliveryIndex}
                                unit={logHeaderds.timelydeliveryIndex === "NaN" ? "0%" : logHeaderds.timelydeliveryIndex + "%"}
                                stroke="#4339F2"
                                fill="#DAD7FE"
                            />

                        </Box>
                         
                        <Box sx={{
                             display:'flex',
                             flexDirection:'row',
                             width:'fit-content',
                             height:'fit-content',
                             gap:'6px',
                             alignItems:'center'
                        }}>

                             <Box sx={{background:'#4339F2',height:'14px',width:'14px',borderRadius:'50%'}}></Box>
                             <Text className={customTheme.customfontsmall}>Timely Delivery Index</Text>
                        </Box> 
                     </Box>


                     <Box 
                     sx={{
                         height:'110px',
                         minHeight:'110px',
                         maxHeight:'110px',
                         width:'fit-content',
                         maxWidth:'fit-content',
                         minWidth:'fit-content',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         gap:'10px',
                         position:'relative' 
                     }}
                    >

                        <Box sx={{
                            position: 'absolute',
                            width: '35px',
                            height: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            right: '0px',
                            top: '0px'
                        }}>

                           
                            {parseFloat(logHeaderds.overdueRation) > parseFloat(previousHeads?.prevoverdueRation) ?

                                <>
                                    <ArrowUpIcon color="#34B53A"></ArrowUpIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {
                                            parseFloat(parseFloat(logHeaderds.overdueRation) - parseFloat(previousHeads?.prevoverdueRation)).toFixed(1) === "NaN" ? "0"
                                                :
                                                parseFloat(parseFloat(logHeaderds.overdueRation) - parseFloat(previousHeads?.prevoverdueRation)).toFixed(1)
                                        }
                                    </Text>
                                </>
                                :
                                <>
                                    <ArrowDownIcon color="#CB2431"></ArrowDownIcon>
                                    <Text className={customTheme.customfontsmall}>
                                        {
                                            parseFloat(parseFloat(previousHeads?.prevoverdueRation) - parseFloat(logHeaderds.overdueRation)).toFixed(1) === "NaN" ? "0"
                                                :
                                                parseFloat(parseFloat(previousHeads?.prevoverdueRation) - parseFloat(logHeaderds.overdueRation)).toFixed(1)
                                        }
                                    </Text>
                                </>

                            }

                           
                        </Box>


                        <Box sx={{
                               height:'80px',
                               minHeight:'80px',
                               maxHeight:'80px',
                               width:'80px',
                               maxWidth:'80px',
                               minWidth:'80px',
                               borderRadius:'50%',
                               background:'#FFE5D3'
                        }}>
                           <CustomCircleProgress 
                            progress={logHeaderds.overdueRation === "NaN" ? "0" : logHeaderds.overdueRation}
                            unit={logHeaderds.overdueRation === "NaN" ? "0%" : logHeaderds.overdueRation+"%"}
                            stroke="#CB2431"
                            fill="#FFE5D3"
                            />

                        </Box>
                         
                        <Box sx={{
                             display:'flex',
                             flexDirection:'row',
                             width:'fit-content',
                             height:'fit-content',
                             gap:'6px',
                             alignItems:'center'
                        }}>

                             <Box sx={{background:'#CB2431',height:'14px',width:'14px',borderRadius:'50%'}}></Box>
                             <Text className={customTheme.customfontsmall}>Deadline Adherence Ratio</Text>
                        </Box> 
                     </Box>

                     <Box 
                     sx={{
                         height:'110px',
                         minHeight:'110px',
                         maxHeight:'110px',
                         width:'fit-content',
                         maxWidth:'fit-content',
                         minWidth:'fit-content',
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         justifyContent:'center',
                         gap:'10px',
                         position:'relative' 
                     }}
                    >

                         
                        <Box sx={{
                               height:'80px',
                               minHeight:'80px',
                               maxHeight:'80px',
                               width:'80px',
                               maxWidth:'80px',
                               minWidth:'80px',
                               borderRadius:'50%',
                               background:'#CCF8FE'
                        }}>
                           <CustomCircleProgress 
                            progress={logHeaderds.overAllEffiency === "NaN" ? "0" : logHeaderds.overAllEffiency} 
                            unit={logHeaderds.overAllEffiency === "NaN" ? "0%" : logHeaderds.overAllEffiency+"%"}
                            stroke="#02A0FC"
                            fill="#CCF8FE"
                            />

                        </Box>
                         
                        <Box sx={{
                             display:'flex',
                             flexDirection:'row',
                             width:'fit-content',
                             height:'fit-content',
                             gap:'6px',
                             alignItems:'center'
                        }}>

                             <Box sx={{background:'#02A0FC',height:'14px',width:'14px',borderRadius:'50%'}}></Box>
                             <Text className={customTheme.customfontsmall}> Overall Efficiency Rate</Text>
                        </Box> 
                     </Box>

                </Box>

                <Box sx={{
                    position: 'absolute',
                    top: '263px',
                    left: '35px',
                    right: '35px',
                    bottom: '35px',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Box sx={{
                        position: 'sticky',
                        top: '0px',
                        zIndex: '1000',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '40px',
                        borderBottom: '1px solid #E5E9EB',
                        background: 'white'
                    }}>
                        <Box sx={{
                            width: '703px',
                            height: '40px',
                            paddingLeft: '16px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center'
                        }}>
                            <Text className={customTheme.H6}>Task</Text>
                            <Label className={customTheme.customfontsmall}
                                sx={{
                                    border: 'none',
                                    background: '#AFB8C133'
                                }}

                            >{copytaskWithDueDate.length}</Label>
                        </Box>
                        <Box sx={{
                            width: '160px',
                            height: '40px',
                            paddingLeft: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center'
                        }}>
                            <Text className={customTheme.H6} sx={{ color: '#000000' }}>Task Time Utilization</Text>

                        </Box>
                    </Box>


                    {logTaskArray.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                height: '40px',
                                borderBottom: '1px solid #E5E9EB'
                            }}>
                                <Box sx={{
                                    width: '703px',
                                    height: '40px',
                                    paddingLeft: '16px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '8px',
                                    alignItems: 'center'
                                }}>
                                    <Text className={customTheme.textnormal}>{row.task_name}</Text>

                                </Box>
                                <Box sx={{
                                    width: '360px',
                                    height: '40px',
                                    paddingLeft: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '8px',
                                    alignItems: 'center'
                                }}>
                                    {/* <Text className={customTheme.textnormal}>{(row?.totalHours === "NaN" ? "0" : row?.totalHours) + " Hr"}</Text> */}
                                    <Text className={customTheme.textnormal}>
                                        {   row?.totaltimetaken}
                                         
                                    </Text>
                                </Box>
                            </Box>
                        )
                    })}


                </Box>

 


            </Dialog>
       
        )
    }

    const DeleteTeamDialogComp = (props) => {


        return (<Dialog
            isOpen={showdeleteDialog}
            onDismiss={() => closeDeleteMemberDialog("")}
            aria-labelledby="header-id"
            sx={{
                width: '564px',
                height: '200px',
            }}
        >

            <Dialog.Header
                id="header-id"

            >

                <Box
                    position={"absolute"}
                    background="#f6f8fa"
                    height={"50px"}
                    width={"50px"}
                    right={"0px"}
                    top={"0px"}>

                </Box>

                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '600',
                        color: '#24292F'
                    }}
                >
                    Are you sure you want to delete the team member?
                </Text>


            </Dialog.Header>

            <Box sx={{
                position: 'absolute',
                top: '73px',
                left: '20px',
                right: '24px',
                display: 'flex'
            }}>
                <Text sx={{
                    minWidth: '520px',
                    height: '40px',
                    fontFamily: 'Segoe UI',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    color: '#24292E',
                    letterSpacing: '-0.15px'
                }}>
                    When you delete a member from your organization, their tasks will be removed.
                    You'll need to re-assign the tasks to another member, and this action can't be undone.
                     
                </Text>
            </Box>

            <Box sx={{
                position: 'absolute',
                bottom: '0px',
                left: '0px',
                right: '0px',
                minHeight: '52px',
                maxHeight: '52px',
                height: '52px',
                borderTop: '1px solid #E1E4E8',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right'
            }}>

                <Button variant="invisible" sx={{
                    position: 'relative',
                    margin: 'auto 8px 10px 0px',
                    minWidth: '73px',
                    maxWidth: '73px',
                    width: '73px',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '600',
                    border: 'none',
                    borderRadius: '3px'
                }}
                    onClick={() => {
                        closeDeleteMemberDialog("")
                    }}
                >
                    Cancel
                </Button>
                <Button variant="danger" sx={{
                    position: 'relative',
                    margin: 'auto 24px 10px 0px',
                    minWidth: '73px',
                    maxWidth: '73px',
                    width: '73px',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '600',
                    color: '#FFFFFF',
                    background: '#A40E26',
                    // background:'#C11F2A',
                    border: 'none',
                    borderRadius: '3px'
                }}
                    onClick={() => {
                        deleteTeamMemberIndb("")
                    }}
                >
                    Delete
                </Button>


            </Box>



        </Dialog>)

    }

    const DownLoadTimeSheetDialog = (props) => {

        return (<>
            <Dialog
                isOpen={downloadtimesheetDialog}
                sx={{
                    width: '530px',
                    height: '200px'
                }}>
                <Box
                    sx={{
                        position: 'absolute',
                        left: '0px',
                        right: '0px',
                        top: '0px',
                        bottom: '0px',
                        background: 'white',
                        borderRadius: '6px',
                        display:'flex',
                        flexDirection:'column'
                    }}>
                    <Text
                        sx={{
                            position: 'relative',
                            margin: '16px 0px 0px 16px',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#24292E'
                        }}
                    >Download timesheet</Text>

                    <Box sx={{
                        position: 'relative',
                        margin: '27px 0px 0px 16px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px'
                    }}>
                        <TextInput
                            sx={{
                                width: '256px',
                                minWidth: '256px',
                                maxWidth: '256px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                                color: '#24292E'
                            }}
                            placeholder="DD/MM/YY"
                            value={timesheetDates.startDate !== "" && timesheetDates.startDate !== null ?
                                new Date(timesheetDates.startDate).getDate() + "/" + (new Date(timesheetDates.startDate).getMonth() + 1) + "/" + new Date(timesheetDates.startDate).getFullYear()
                                : ""}
                            validationStatus={timesheetDates.startDateValidation}
                            onChange={(e) =>

                                 timesheetDatesstartcalandarRef.current.showPicker()
                            }
                            onFocus={(e) => {
                                 timesheetDatesstartcalandarRef.current.showPicker()
                            }}

                        />
                        <input
                            type={"date"}
                            ref={timesheetDatesstartcalandarRef}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {


                                    
                                      settimesheetDates((prevState)=>{
                                          return({...prevState,startDate:new Date()})
                                      })

                                } else {

                                   
                                    settimesheetDates((prevState)=>{
                                     return({...prevState,startDate:new Date(e.target.value)})
                                    })
                                }


                            }}
                            style={{
                                position: 'absolute',
                                left: '1px',
                                top: '2px',
                                height: '40px',
                                width: '50%',
                                zIndex: '0',
                                visibility: 'hidden'
                            }}

                        />

                       <TextInput
                        sx={{
                            width:'224px',
                            minWidth:'224px',
                            maxWidth:'224px',
                            height:'40px',
                            minHeight:'40px',
                            maxHeight:'40px',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#24292E'
                        }}
                        placeholder="DD/MM/YY"
                            value={timesheetDates.endDate !== "" && timesheetDates.endDate !== null ?
                                new Date(timesheetDates.endDate).getDate() + "/" + (new Date(timesheetDates.endDate).getMonth() + 1) + "/" + new Date(timesheetDates.endDate).getFullYear()
                                : ""}
                            validationStatus={timesheetDates.endDateValidation}
                            onChange={(e) =>

                                timesheetDatesendcalandarRef.current.showPicker()
                            }
                            onFocus={(e) => {
                                timesheetDatesendcalandarRef.current.showPicker()
                            }}
                       />

                        <input
                            type={"date"}
                            ref={timesheetDatesendcalandarRef}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {

                                    settimesheetDates((prevState) => {
                                        return ({ ...prevState, endDate: new Date(), endDateValidation: '' })
                                    })

                                } else {

                                    settimesheetDates((prevState) => {
                                        return ({ ...prevState, endDate: new Date(e.target.value),endDateValidation:'' })
                                    })
                                }


                            }}
                            style={{
                                position: 'absolute',
                                right: '15px',
                                top: '1px',
                                height: '40px',
                                width: '42%',
                                zIndex: '0',
                                visibility: 'hidden'
                            }}

                        />
 
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        minHeight: '52px',
                        maxHeight: '52px',
                        height: '52px',
                        //borderTop: '1px solid #E1E4E8',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right'
                    }}>

                        <Button variant="invisible" sx={{
                            position: 'relative',
                            margin: 'auto 8px 10px 0px',
                            minWidth: '73px',
                            maxWidth: '73px',
                            width: '73px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',
                            border: 'none',
                            borderRadius: '3px'
                        }}
                            onClick={() => {
                                closedownloadtimesheetDialog("")
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            sx={{
                                position: 'relative',
                                margin: 'auto 15px 10px 0px',
                                minWidth: '73px',
                                maxWidth: '73px',
                                width: '73px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                lineHeight: '20px',
                                fontWeight: '600',
                                color: '#FFFFFF',
                                background: '#0965CE',
                                border: 'none',
                                borderRadius: '3px'
                            }}
                            onClick={() => {
                                handleDownloadTimeSheet("")
                            }}
                        >
                            Create
                        </Button>


                    </Box>


                </Box>

            </Dialog>
        </>)
    }


    React.useEffect(() => {


        if (reloadTeamTrigger === true) {
            getSpaceCollaboratorFromDb("")
            getProjectInvitationsFromDb("")
        }
    }, [reloadTeamTrigger])


    React.useState(() => {

        getSpaceCollaboratorFromDb("")
        getProjectInvitationsFromDb("")

        dispatch(filteredTeamUserId({
            teamUserId: ''
        }))

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >

            <Helmet>

                <title>{localStorage.getItem("projectName") || ""} - Team</title>

            </Helmet>

           
            <SpaceHeader />
            <SpaceLeftSideNav />
            {/* <SpaceRightSideNav /> */}


            <Box sx={{
                position: 'absolute',
                left: '90px',
                top: '78px'
            }}>
                <Text sx={{
                    fontFamily: 'Avenir LT Std',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#24292E'
                }}>
                    Team Members
                </Text>

            </Box>


            <Box sx={{
                position: 'absolute',
                right: '81px',
                top: '70px',
                display: 'flex',
                flexDirection: 'row',
                gap: '21px'
            }}>

                <IconButton
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', boxShadow: 'none' }}
                    icon={() => <MagnifyingGlassIcon style={{ display: 'flex' }} />}
                    onClick={() => {
                        showSearchDialog("")
                    }}
                />

                <Button sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F'
                }}
                    onClick={() => {
                        showSharingDialog("")
                    }}
                >
                    Invite
                </Button>
            </Box>




            <Box
                borderColor="border.default"
                borderBottomWidth={1}
                borderBottomStyle="solid"
                sx={{
                    position: 'absolute',
                    left: '90px',
                    right: '81px',
                    top: '112px'
                }}>

            </Box>
            

            <Box sx={{
                  position: 'absolute',
                  top: '130px',
                  left: '90px',
                  right: '81px',
                  display:'flex',
                  flexDirection:'column'
            }}>
            <Box sx={{
                // position: 'absolute',
                // top: '130px',
                // left: '90px',
                // right: '81px',
                minHeight: 'fit-content',
                background: 'white',
               // display: spaceCollaborators.length > 1 ? 'flex' : 'none',
                display:'flex', 
                flexDirection: 'column',

            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '35px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}

                >


                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#121212',
                        width: '370px',
                        maxWidth: '370px',
                        minWidth: '370px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        position: 'relative',
                        margin: '0px 0px 0px 23px'
                    }}>
                        Member
                    </Text>


                    <Box sx={{
                        position: 'absolute',
                        right: '150px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '120px'

                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            position: 'relative',
                            margin: '0px 0px 0px 0px'
                        }}>
                            Role
                        </Text>
                    </Box>




                </Box>
                {spaceCollaborators
                ?.filter((sData) => teamUserId !== "" ? sData.user_id === teamUserId : sData)
                .map((row, index) => {

                    return (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '35px',
                            borderBottomWidth: 1,
                            borderBottomStyle: 'solid',
                            borderColor: '#E0E0E0',
                            alignItems: 'center',
                            cursor: 'pointer',
                            ':hover': {
                                background: 'rgba(234, 238, 242, 0.5)'
                            }
                        }}
                        >

                            <Box sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 20px',
                               
                                
                            }}>

                                {row.user_image_url === "" || row.user_image_url == null ?

                                    <FeedPersonIcon size={24} fill="#586069" /> :

                                    <Avatar sx={{
                                        height: '24px',
                                        width: '24px',
                                        minHeight: '24px',
                                        minWidth: '24px',


                                    }}
                                        src={row.user_image_url} />

                                }

                            </Box>


                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#121212',
                                width: '276px',
                                maxWidth: '276px',
                                minWidth: '276px',
                                minHeight: '15px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                position: 'relative',
                                margin: '0px 0px 0px 15px'
                            }}>
                                {row.user_first_name + " " + row.user_last_name}
                            </Text>

                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                color: '#121212',
                                width: '370px',
                                maxWidth: '370px',
                                minWidth: '370px',
                                minHeight: '15px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                position: 'relative',
                                margin: '0px 0px 0px 50px'
                            }}>
                                {row.user_email}
                            </Text>


                            <Box sx={{
                                position: 'absolute',
                                right: '155px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '115px'

                            }}>

                                <Label sx={{
                                    width: 'fit-content',
                                    minWidth: 'fit-content',
                                    maxWidth: 'fit-content',
                                    height: '20px',
                                    borderRadius: '3px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#24292F',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {row.user_role}
                                    
                                </Label>

                            </Box>


                            <ActionMenu>

                                <ActionMenu.Anchor>
                                    <IconButton
                                        sx={{
                                          
                                            visibility:spaceCollaborators.filter((sData)=> sData.user_id===userId&&sData.user_role==="Collaborator").length > 0 ?"hidden":'visible',
                                            position: 'absolute',
                                            right: '32px',
                                            margin: 'auto',
                                            border: 'none',
                                            boxShadow: 'none',
                                            background: 'transparent',
                                            
                                        }}
                                        icon={() => <KebabHorizontalIcon />}
                                    />
                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '6px',
                                        alignItems: 'center',
                                        gap: '1px'
                                    }}>
                                        <ActionList.Item>
                                            Manage
                                        </ActionList.Item>
                                        <ActionList.Item
                                          onSelect={()=>{
                                              openLogDialog(row.user_id)
                                          }}
                                        >
                                            Log
                                        </ActionList.Item>
                                        <ActionList.Item
                                          onSelect={()=>{
                                              openWorkingHourDialog(row.user_id)
                                          }}
                                        >
                                            Working Hour
                                        </ActionList.Item>
                                        <ActionList.Item>
                                            Worksheet
                                        </ActionList.Item>
                                        <ActionList.Item 
                                        onSelect={()=> {
                                              showdownloadtimesheetDialog(row)
                                        }}
                                        >
                                            Download Timesheet
                                        </ActionList.Item>
                                        <Box sx={{ borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: '#D0D7DE', minHeight: '1px', width: '104%' }}></Box>
                                        <ActionList.Item
                                            variant="danger"
                                            onSelect={() => {
                                                setdeletTeamData((prevState) => { return ({ ...prevState, userId: row.user_id, userRole: row.user_role, projectId: localStorage.getItem("projectId") }) })
                                                showDeleteMemberDialog("")
                                            }}
                                            disabled={userId === row.user_id && row.user_role === "Owner" ? true : row.user_role === "Read only" ? true : false}
                                            sx={{ visibility: currentUserRole === "Read only" ? "hidden" : "visible" }}
                                        >
                                            Delete
                                        </ActionList.Item>
                                    </ActionList>
                                </ActionMenu.Overlay>

                            </ActionMenu>





                        </Box>
                    )
                })}

                    {pendingInvitations?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '35px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                cursor: 'pointer',
                                ':hover': {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                }
                            }}
                            >

                                <Box sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 20px',
                                }}>

                                    {row.user_image_url === "" || row.user_image_url == null ?

                                        <FeedPersonIcon size={24} fill="#586069" /> :

                                        <Avatar sx={{
                                            height: '24px',
                                            width: '24px',
                                            minHeight: '24px',
                                            minWidth: '24px',


                                        }}
                                            src={row.user_image_url} />

                                    }

                                   
                                 </Box>

                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: '#121212',
                                    width: '276px',
                                    maxWidth: '276px',
                                    minWidth: '276px',
                                    minHeight: '15px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    margin: '0px 0px 0px 15px'
                                }}>
                                    {row.user_first_name + " " + row.user_last_name}
                                </Text>

                                <Box sx={{
                                     position: 'relative',
                                     margin: '0px 0px 0px 50px',
                                     display:'flex',
                                     width: '42%',
                                     maxWidth: '42%',
                                     minWidth: '42%',
                                     
                                }}>
                                <Text sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: '#121212',
                                    // width: '370px',
                                    // maxWidth: '370px',
                                    // minWidth: '370px',
                                    // minHeight: '15px',
                                    width: '65%',
                                    maxWidth: '65%',
                                    minWidth: '65%',
                                    minHeight: '15px',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                   
                                }}>
                                    {row.user_email}
                                </Text>
                                    <Label
                                        
                                        sx={{
                                            width: 'fit-content',
                                            minWidth: 'fit-content',
                                            maxWidth: 'fit-content',
                                            height: '20px',
                                            borderRadius: '3px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            background:'#0366d6',
                                            color:'white',
                                             // color: '#24292F',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                        }}>
                                        {"Invitation not accepted yet"}
                                    </Label>
                                </Box>
                                


                                <Box sx={{
                                    position: 'absolute',
                                    right: '155px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '115px'

                                }}>

                                    <Label sx={{
                                        width: 'fit-content',
                                        minWidth: 'fit-content',
                                        maxWidth: 'fit-content',
                                        height: '20px',
                                        borderRadius: '3px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#24292F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {row.user_role}

                                    </Label>
                                    
                                </Box>


                                <ActionMenu>

                                    <ActionMenu.Anchor>
                                        <IconButton
                                            sx={{

                                                visibility: spaceCollaborators.filter((sData) => sData.user_id === userId && sData.user_role === "Collaborator").length > 0 ? "hidden" : 'visible',
                                                position: 'absolute',
                                                right: '32px',
                                                margin: 'auto',
                                                border: 'none',
                                                boxShadow: 'none',
                                                background: 'transparent',

                                            }}
                                            icon={() => <KebabHorizontalIcon />}
                                        />
                                    </ActionMenu.Anchor>

                                    <ActionMenu.Overlay>
                                        <ActionList sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '6px',
                                            alignItems: 'center',
                                            gap: '1px'
                                        }}>
                                              
                                            <ActionList.Item
                                                onSelect={() => {
                                                    handleResendInvitation(row)
                                                }}
                                                // disabled={userId === row.user_id && row.user_role === "Owner" ? true : row.user_role === "Read only" ? true : false}
                                                // sx={{ visibility: currentUserRole === "Read only" ? "hidden" : "visible" }}
                                                sx={{
                                                    visibility: currentUserRole !== "Owner" ? "hidden" : 'visible',
                                                }}
                                            >
                                                Resend Invitation
                                            </ActionList.Item>

                                            <ActionList.Item
                                                variant="danger"
                                                onSelect={() => {
                                                    handleRevokeInvitation(row)
                                                }}
                                                //  disabled={userId === row.user_id && row.user_role === "Owner" ? true : row.user_role === "Read only" ? true : false}
                                                //  sx={{ visibility: currentUserRole === "Read only" ? "hidden" : "visible" }}
                                                sx={{
                                                    visibility: currentUserRole !== "Owner" ? "hidden" : 'visible',
                                                }}

                                            >
                                                Revoke Invitation
                                            </ActionList.Item>
                                           

                                        </ActionList>
                                    </ActionMenu.Overlay>

                                </ActionMenu>





                            </Box>
                        )
                    })}


               


                </Box>


                <Box sx={{display:'flex',flexDirection:'column',minHeight:'80px'}}></Box>

                 

                 

            </Box>

           

           



            {showEmptyComp === true ? <EmptyTeamComp /> : ""}


             
            <LogDialog /> 

            <WorkingHourDialog/>
           
         
            <DeleteTeamDialogComp  />
            
            {/* <RightSideBarOpen /> */}
            <ScheduleDemoDialog />
            <SearchDialog />
            {/* <ShareDialog /> */}
            <InviteTeamMembers/>
            <TeamSearchDialogs />
            {/* <DownLoadTimeSheetDialog/> */}
            {DownLoadTimeSheetDialog("")}

        </Box>
    )

}

export default Team