import { Box,Text,Button, Heading, Avatar, Label, ProgressBar } from "@primer/react"
import React from "react";
import {Helmet} from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import EmptyData from "./EmptyData";
import CreateTask from "./CreateTask";
import RightSideBarOpen from "./RightSideBarOpen";
import { FeedPersonIcon, IssueOpenedIcon } from "@primer/octicons-react";
import { useDispatch,useSelector} from "react-redux";
import { getInsightData } from "../redux/features/spaceSlice";
import GraphInsight from "./GraphInsight";


function Insight(props){

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskTrigger = useSelector((state)=> state.taskData.taskTrigger)


    const [insiteCounts,setinsiteCounts] = React.useState({
           openToDo:0,
           inprocess:0,
           completed:0,
           overDue:0  
    })

    const [workloads,setworkloads] = React.useState([])
    const [compliances,setcompliances] = React.useState([])

    
    const getInsightDataFromDb = (props) => {

            dispatch(getInsightData({
                userId:userId,
                userEmail:userEmail,
                projectId:localStorage.getItem("projectId")
            })).then((data)=>{

                     setinsiteCounts((prevState)=>{
                        return({
                            ...prevState,
                            openToDo:data.payload.openToDoCount,
                            inprocess:data.payload.inprocessCount,
                            completed:data.payload.completedCount,
                            overDue:data.payload.overDueCount
                        })
                     })  // setting up all counts

                     setworkloads(data.payload.teamWorkloadData)            // Team member and count json
                     setcompliances(data?.payload?.insightCompliaceArray)   //compliance by all tasks 
                 

            })
          

    }


    React.useEffect(() => {

        if (taskTrigger === true) {
            getInsightDataFromDb("")
        }

    }, [taskTrigger])


    React.useState(() => {


        getInsightDataFromDb("")


    })

    return(
        <Box
         position={"relative"} 
         display="flex" 
         background={"#F6F8FA"} 
         height="100vh"
         width={"100vw"} 
         overflowY={"auto"}
        >

            <Helmet>

                <title>{localStorage.getItem("projectName") || ""} - Insight</title>

            </Helmet>

        

            <SpaceHeader/>
            <SpaceLeftSideNav/>
            {/* <SpaceRightSideNav/> */}

            <Box
            position={"absolute"}
            display="flex"
            background={"#F6F8FA"}
            left={"64px"}
            top={"48px"}
            right={"0px"}
            bottom={"0px"}
            overflowY={"scroll"}
            overflowX={"hidden"}
            
           > 

               <Box sx={{
                  position:'absolute',
                  left:'26px',
                  top:'34px',
                  width:'fit-content',
                  height:'fit-content',
                  display:'flex',
                  flexDirection:'row',
                  gap:'14px',
                  
               }}>

                    <Box sx={{
                        width:'220px',
                        minWidth:'220px',
                        maxWidth:'220px',
                        height:'96px',
                        minHeight:'96px',
                        maxHeight:'96px',
                        background:'#FFFFFF',
                        border:'1px solid #D0D7DE',
                        borderRadius:'6px',
                        display:'flex',
                        flexDirection:'column'
                    }}>

                         <Text sx={{
                            position:'relative',
                            margin:'12px 0px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                         }}>
                         Open to do
                         </Text>

                         <Heading sx={{
                              position:'relative',
                              margin:'1px auto 0px auto',
                              fontFamily: 'Segoe UI',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '35px',
                              lineHeight: '36px',
                              color: '#24292E',
                              maxWidth:'222px',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                         }}>
                           {insiteCounts.openToDo}
                         </Heading>


                    </Box>

                    <Box sx={{
                        width: '220px',
                        minWidth: '220px',
                        maxWidth: '220px',
                        height: '96px',
                        minHeight: '96px',
                        maxHeight: '96px',
                        background: '#FFFFFF',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        <Text sx={{
                            position: 'relative',
                            margin: '12px 0px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                        }}>
                            In process
                        </Text>

                        <Heading sx={{
                            position: 'relative',
                            margin: '1px auto 0px auto',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '35px',
                            lineHeight: '36px',
                            color: '#2C974B',
                            maxWidth: '222px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                             {insiteCounts.inprocess}
                        </Heading>


                    </Box>

                    <Box sx={{
                        width: '220px',
                        minWidth: '220px',
                        maxWidth: '220px',
                        height: '96px',
                        minHeight: '96px',
                        maxHeight: '96px',
                        background: '#FFFFFF',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        <Text sx={{
                            position: 'relative',
                            margin: '12px 0px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                        }}>
                            Completed
                        </Text>

                        <Heading sx={{
                            position: 'relative',
                            margin: '1px auto 0px auto',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '35px',
                            lineHeight: '36px',
                            color: '#24292E',
                            maxWidth: '222px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                             {insiteCounts.completed}
                        </Heading>


                    </Box>


                    <Box sx={{
                       width: '220px',
                       minWidth: '220px',
                       maxWidth: '220px',
                       height: '96px',
                       minHeight: '96px',
                       maxHeight: '96px',
                        background: '#FFFFFF',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        <Text sx={{
                            position: 'relative',
                            margin: '12px 0px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                        }}>
                            Over due
                        </Text>

                        <Heading sx={{
                            position: 'relative',
                            margin: '1px auto 0px auto',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '35px',
                            lineHeight: '36px',
                            color: '#D73A49',
                            maxWidth: '222px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                             {insiteCounts.overDue}
                        </Heading>


                    </Box>

                 </Box>


                 <Box sx={{
                     position:'absolute',
                     top:'153px',
                     left:'26px',
                     width:'927px',
                     minWidth:'927px',
                     maxWidth:'927px',
                     height:'auto',
                     maxHeight:'auto',
                     display:'flex',
                     flexDirection:'column'
                 }}>
                 <Box sx={{
                    //  position:'absolute',
                    //  top:'153px',
                    //  left:'26px',
                    //  width:'927px',
                    //  minWidth:'927px',
                    //  maxWidth:'927px',
                    //  height:'auto',
                    //  maxHeight:'auto',
                     background:'#FFFFFF',
                     border:'1px solid #D0D7DE',
                     borderRadius:'6px',
                     display:'flex',
                     flexDirection:'column',
                     paddingBottom:'13px'
                 }}>
                   
                    <Box sx={{
                        height:'36px',
                        minHeight:'36px',
                        maxHeight:'36px',
                        display:'flex',
                        flexDirection:'row',
                        width:'927px',
                        minWidth:'927px',
                        maxWidth:'927px',
                        alignItems:'center',
                        borderBottom:'1px solid #D0D7DE'
                    }}>
                         <Text sx={{
                            position:'relative',
                            margin:'0px 0px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                         }}>
                         Team members workload 
                         </Text>
                         <Text sx={{
                            position:'relative',
                            margin:'0px 0px 0px 7px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#24292E',
                         }}>
                         : Track Status by Member
                         </Text>
                    </Box>


                    {   workloads?.
                        sort((a, b) => {
                            if (a.user_id === userId && b.user_id === userId) {
                                return 0
                            } else if (a.user_id === userId) {
                                return -1
                            } else if (b.user_id === userId) {
                                return 1
                            } else {
                                return a.user_id - b.user_id
                            }
                        }).map((row,index)=>{
  
                          const isEven = index % 2 === 0;

                          return(

                            <Box sx={{
                                height:'36px',
                                minHeight:'36px',
                                maxHeight:'36px',
                                display:'flex',
                                flexDirection:'row',
                                width:'927px',
                                minWidth:'927px',
                                maxWidth:'927px',
                                alignItems:'center',
                                borderBottom:'1px solid #D0D7DE',
                                background:isEven?'rgba(234, 238, 242, 0.5)':'#ffffff'
                            }}>
        
                                <Box sx={{
                                    width:'352px',
                                    minWidth:'352px',
                                    maxWidth:'352px',
                                    height:'36px',
                                    minHeight:'36px',
                                    maxHeight:'36px',
                                    borderRight:'1px solid #D0D7DE',
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center'
                                }}>

                                      {row.user_image_url === "" || row.user_image_url == null ?

                                          <Box sx={{
                                              display: 'flex',
                                              position: 'relative',
                                              margin: '0px 0px 0px 14px',
                                              height: '20px',
                                              width: '20px',
                                              minHeight: '20px',
                                              minWidth: '20px',
                                              maxWidth: '20px',
                                              maxHeight: '20px'
                                          }}>
                                              <FeedPersonIcon size={20} fill="#586069" />
                                          </Box>

                                          :

                                          <Avatar
                                              size={20}
                                              src={row.user_image_url}
                                              sx={{
                                                  display: 'flex',
                                                  position: 'relative',
                                                  margin: '0px 0px 0px 14px'
                                              }}
                                          />

                                      }
 
                                      


                                    <Text sx={{
                                           display:'flex',
                                           position:'relative',
                                           margin:'0px 0px 0px 12px',
                                           fontFamily: 'Segoe UI',
                                           fontStyle: 'normal',
                                           fontWeight: '400',
                                           fontSize: '14px',
                                           lineHeight: '24px',
                                           color: '#24292F',
                                           maxWidth:'280px',
                                           textOverflow: 'ellipsis',
                                           whiteSpace: 'nowrap',
                                           overflow: 'hidden',
                                    }}>
                                       {row.user_first_name+" "+row.user_last_name}
                                    </Text>
        
        
                                </Box>
                                <Box sx={{
                                    width:'264px',
                                    minWidth:'264px',
                                    maxWidth:'264px',
                                    height:'36px',
                                    minHeight:'36px',
                                    maxHeight:'36px',
                                    borderRight:'1px solid #D0D7DE',
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center'
                                }}>

                                      <Text sx={{
                                          display: 'flex',
                                          position: 'relative',
                                          margin: '0px 12px 0px 12px',
                                          fontFamily: 'Segoe UI',
                                          fontStyle: 'normal',
                                          fontWeight: '400',
                                          fontSize: '14px',
                                          lineHeight: '24px',
                                          color: '#24292F',
                                          maxWidth: '280px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                      }}>
                                          {row?.user_compliances?.join(' | ')}
                                      </Text>
        
        
                                </Box>
                                <Box sx={{
                                    width:'309px',
                                    minWidth:'309px',
                                    maxWidth:'309px',
                                    height:'36px',
                                    minHeight:'36px',
                                    maxHeight:'36px',
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center'
                                }}>

                                      <Box sx={{
                                          position: 'relative',
                                          margin: '0px 0px 0px 29px',
                                          minWidth: '83px',
                                          width: '83px',
                                          maxWidth: '83px',
                                          
                                      }}>
                                          <Label sx={{

                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              lineHeight: '18px',
                                              color: '#FFFFFF',
                                              borderRadius: '3px',
                                              background: '#2DA44E',
                                              border: 'none',
                                              display: parseInt(row.openCount)>0?'flex':'none',
                                              alignItems: 'center',
                                              minHeight:'24px',
                                          }}>
                                              <Box sx={{
                                                   marginRight:'4px'
                                                   
                                              }}>
                                                  <IssueOpenedIcon size={16} />
                                              </Box>
                                              {(row.openCount+" Open")}
                                          </Label>

                                      </Box>
                                      <Box sx={{
                                          position: 'relative',
                                          margin: '0px 0px 0px 11px',
                                          minWidth: '63px',
                                          width: '63px',
                                          maxWidth: '63px',
                                          minHeight:'24px',
                                          maxHeight:'24px',
                                          height:'24px',
                                      }}>
                                          <Label sx={{

                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              lineHeight: '18px',
                                              color: '#FFFFFF',
                                              borderRadius: '3px',
                                              background: '#E36209',
                                              border: 'none',
                                              display: parseInt(row.stuckCount)>0?'flex':'none',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              minHeight:'24px',
                                          }}>
                                              {row.stuckCount} Stuck
                                          </Label>

                                      </Box>
                                      <Box sx={{
                                          position: 'relative',
                                          margin: '0px 0px 0px 11px',
                                          minWidth: '88px',
                                          width: '88px',
                                          maxWidth: '88px',
                                          minHeight:'24px',
                                          maxHeight:'24px',
                                          height:'24px',
                                      }}>
                                          <Label sx={{

                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              fontSize: '12px',
                                              lineHeight: '18px',
                                              color: '#FFFFFF',
                                              borderRadius: '3px',
                                              background: '#CF222E',
                                              border: 'none',
                                              display: parseInt(row.overdueCount)>0?'flex':'none',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              minHeight:'24px',
                                          }}>
                                              {row.overdueCount} Over due
                                          </Label>

                                      </Box>
                                    
                                       
                                       
        
                                </Box>
        
                            </Box>

                          )
                    })}

                   
                   
                 </Box>
 
                 {/* Graph Section */}
                    <Box
                        sx={{
                            minHeight: '400px',
                            maxHeight: '400px',
                            marginTop: '22px',
                            
                        }}
                    >
                         <GraphInsight/>
                    </Box>
                 {/* ------- */}
                 </Box>


                 


                 <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    position:'absolute',
                    left:'970px',
                    right:'14px',
                    top:'34px',
                    paddingBottom:'371px'
                    }}>

                 <Box sx={{
                    minHeight:'571px',
                    height:'auto',
                    maxHeight:'auto',
                    background:'#FFFFFF',
                    border:'1px solid #D0D7DE',
                    borderRadius:'6px',
                    display:'flex',
                    flexDirection:'column',
                    
                 }}>

                    <Heading sx={{
                        position: 'relative',
                        margin: '12px 0px 0px 23px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#24292E',
                    }}>
                        Compliances workload
                    </Heading>
                    <Text sx={{
                        position: 'relative',
                        margin: '5px 0px 0px 23px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#24292E',
                       
                    }}>
                        Track Progress and Stay Organized. 
                      
                    </Text>


                    

                    <Box  sx={{
                        position:'relative',
                        margin:'20px 35px 20px 23px',
                        minHeight:'453px',
                        maxHeight:'auto',
                        height:'auto',
                        display:'flex',
                        flexDirection:'column',
                        gap:'25px',

                    }}>


                        {compliances?.sort((a, b) => {
                            const nameA = a.compliance_name.toLowerCase();
                            const nameB = b.compliance_name.toLowerCase();
                            if (nameA < nameB) {
                              return -1;  
                            } else if (nameA > nameB) {
                              return 1;  
                            } else {
                              return 0;  
                            }
                         })
                        .map((row,index)=>{
                              return(
                                <Box 
                                sx={{
                                   minHeight:'35px',
                                   maxHeight:'auto',
                                   height:'auto',
                                   display:'flex',
                                   flexDirection:'column'  
                                }}
                                >
                                    <Text sx={{
                                         position:'relative',
                                         margin:'0px 0px 0px 0px',
                                         fontFamily: 'Segoe UI',
                                         fontStyle: 'normal',
                                         fontWeight: '600',
                                         fontSize: '14px',
                                         lineHeight: '20px',
                                         color: '#24292E',
                                    }}>
                                        {row.compliance_name}
                                      
                                    </Text>

                                          

                                    
                                       {row.progresscount===100?
                                       
                                          <ProgressBar 
                                             
                                           sx={{
                                              width: '100%',
                                              minWidth: '100%',
                                              height: '8px',
                                              position: 'relative',
                                              margin: '7px 0px 0px 0px'
                                          }}
                                              progress={row.progresscount}
                                              
                                          />

                                       :
                                       
                                          <ProgressBar sx={{
                                              width: '100%',
                                              minWidth: '100%',
                                              height: '8px',
                                              position: 'relative',
                                              margin: '7px 0px 0px 0px'
                                          }}
                                              bg={'#1F75CB'}
                                              progress={row.progresscount}
                                          />
                                       
                                       }

                                      



                                </Box>
                              )
                        })}



                    </Box>
                   


                    

                 </Box>

                 </Box>


                
                  
                
                  




           </Box>

           
            
             
             
              
            {/* <RightSideBarOpen/> */}
            <ScheduleDemoDialog/>
            <SearchDialog/>
        </Box>
    )

}

export default Insight