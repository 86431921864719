import { Box, Button, Text, IconButton, Dialog, TextInput, Link, Spinner, ActionMenu, ActionList } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import UploadPanDialog from "../../Workplace/Dialogs/UploadPanDialog"
import GSTSyncDialog from "../../Workplace/Dialogs/GSTSyncDialog"
import React from "react"
import { useNavigate } from "react-router-dom"
import { ChevronRightIcon, KebabHorizontalIcon } from "@primer/octicons-react";
import Workplacetheme from "../../Workplace/Workplacetheme"
import { deleteItPortal, getItPortal, getProductTourTaxhero, postItPortal, postItPortalBulk, updateProductTourTaxhero } from "../../redux/features/portalSlice"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { UploadIcon } from "@radix-ui/react-icons";
import Papa from "papaparse";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { getTaxheroUserSubscription, getUserSubscriptionData } from "../../redux/features/profileSlice"
import ITSearchDialogs from "../../Workplace/Dialogs/ITSearchDialogs"
import axios from "axios"

const synchingToast = Toaster.create({ position: Position.TOP })

function ITListViewPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const computerFileRef = React.useRef()
    const [tartanToken, settartanToken] = React.useState("")
    const [selectedFile, setselectedFile] = React.useState(null)
    const [showLoadingBtn, setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()
    const [panOpenClose, setpanOpenClose] = React.useState(false)
    const [panBulkOpenClose, setpanBulkOpenClose] = React.useState(false)
    const [currentRef, setcurrentRef] = React.useState("panName")
    const [panNameInput, setpanNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userNameInput, setuserNameInput] = React.useState({ value: '', validationStatus: '' })
    const [userPassword, setuserPassword] = React.useState({ value: '', validationStatus: '' })
    const [ItList, setItList] = React.useState([])
    const [bulkPanData, setbulkPanData] = React.useState([])
    const [addonsCount, setaddonsCount] = React.useState(0)
    const [planMetaDataQty, setplanMetaDataQty] = React.useState(0)


    //Post Hog Events-----------------------------------
    const postHogTrackingITAddPanClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'ITRAddPANClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`; //'https://app.posthog.com/capture/';

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }



    //--------------------------------------------------

    //---------- Product tour section --------------
    const updateProductTourInDb = (props) => {


        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#addPanBtn', popover: { title: 'ADD PAN', description: `Add your client's PAN details for automated data fetching from Income Tax portal and stay up to date with e-tracking.`, side: "top", align: 'start' } },
                { element: '#panListing', popover: { title: 'Clent Listing', description: `List all your clients here, stay updated, and expand the list to get all the details about their returns.`, side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {
                console.log("driverObj", driverObj)
                if (driverObj.getActiveIndex() == 1) {
                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }
                }

            },
        });

        driverObj.drive();

    }



    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                showProductTour(tourPaths)
            }
        })

    }

    const downloadBulkExcel = (props) => {

        var csvContent = 'Name,PAN,Password'
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'panbulk.csv';
        link.click();
        window.URL.revokeObjectURL(link.href);

    }

    const handlePickComputerFile = (props) => {

        Papa.parse(props, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {

                console.log("results", results.data)
                setbulkPanData(results.data)

            },
        })

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 2000,


        }, key);

    }

    const handleDeleteItrPan = (props) => {

        //deleteItPortal
        dispatch(deleteItPortal({
            userId: userId,
            userEmail: userEmail,
            insertId: props,
        })).then((data) => {


            const response = data?.payload?.data
            Toaster.create({ position: Position.TOP }).show({
                message: response,
                intent: Intent.SUCCESS
            });

            getItPortalFromDb('')

        })

    }


    const handleUploadBulkExcelPan = (props) => {

        if (props.length > 0) {

            const addonCountChargebee = addonsCount
            const addedItrCount = ItList.length
            const excelCount = props.length

            if (addedItrCount == addonCountChargebee) {

                closeBulkDialog(false)
                Toaster.create({ position: Position.TOP }).show({
                    message: <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                        Beta trial limit exceeds the {addonCountChargebee} Pan limit.
                        <Text
                            onClick={() => {

                            }}
                            sx={{
                                marginLeft: '8px',
                                cursor: 'pointer',
                                color: 'white',
                                textDecoration: 'underline'
                            }}>
                            Upgrade
                        </Text>
                    </Box>,
                    intent: Intent.DANGER
                });


            } else {

                closeBulkDialog(false)
                loadingToast(10, "1")
                dispatch(postItPortalBulk({
                    userId: userId,
                    userEmail: userEmail,
                    panName: '',
                    panNumber: '',
                    panPassword: '',
                    bulkPanData: props
                })).then((data) => {

                    console.log("postItPortalBulk", data.payload)
                    console.log("postItPortalBulk", data.payload.data.length)
                    console.log("postItPortalBulk", props.length)

                    const insertedArray = [...data.payload.data]
                    const duplicatePans = [...data.payload.duplicatePan]



                    setbulkPanData([])
                    getItPortalFromDb("")

                    if (data.payload.statusCode == 200 && data.payload.data.length > 0) {



                        loadingToast(100, "1")
                        getItPortalFromDb("")

                        if (excelCount > insertedArray.length) {


                            Toaster.create({ position: Position.TOP }).show({
                                message: `Free trial allows up to ${addonCountChargebee} syncs`,
                                intent: Intent.PRIMARY
                            });
                        }

                        if (duplicatePans.length > 0) {

                            Toaster.create({ position: Position.TOP }).show({
                                message: duplicatePans.length + "duplicates were found and automatically removed",
                                intent: Intent.PRIMARY
                            });

                        }




                    }

                    if (duplicatePans.length > 0 && data.payload.data.length == 0) {


                        synchingToast.clear()
                        Toaster.create({ position: Position.TOP }).show({
                            message: duplicatePans.length + "duplicates were found and automatically removed",
                            intent: Intent.PRIMARY
                        });

                    }





                })




            }



            //------------------ old code -----------------------

            // if (excelCount > addonCountChargebee) {

            //     closeBulkDialog(false)
            //     Toaster.create({ position: Position.TOP }).show({
            //         message: <Box sx={{
            //             display: 'flex',
            //             flexDirection: 'row',
            //             alignItems: 'center',
            //         }}>
            //             Maximum PAN limit reached
            //             <Text
            //                 onClick={() => {

            //                 }}
            //                 sx={{
            //                     marginLeft: '8px',
            //                     cursor: 'pointer',
            //                     color: 'white',
            //                     textDecoration: 'underline'
            //                 }}>
            //                 Upgrade
            //             </Text>
            //         </Box>,
            //         intent: Intent.DANGER
            //     });


            // } else {


            //     if (addedItrCount < addonCountChargebee) {

            //         closeBulkDialog(false)
            //         loadingToast(10, "1")
            //         dispatch(postItPortalBulk({
            //             userId: userId,
            //             userEmail: userEmail,
            //             panName: '',
            //             panNumber: '',
            //             panPassword: '',
            //             bulkPanData: props
            //         })).then((data) => {

            //             console.log("postItPortalBulk", data.payload)
            //             console.log("postItPortalBulk", data.payload.data.length)
            //             console.log("postItPortalBulk", props.length)

            //             const insertedArray = [...data.payload.data]
            //             const duplicatePans = [...data.payload.duplicatePan] 



            //             setbulkPanData([])
            //             getItPortalFromDb("")

            //             if (data.payload.statusCode == 200 && data.payload.data.length > 0) {

            //                 loadingToast(100, "1")
            //                 getItPortalFromDb("")

            //                 if (duplicatePans.length > 0) {

            //                     Toaster.create({ position: Position.TOP }).show({
            //                         message: duplicatePans.length + "duplicates were found and automatically removed",
            //                         intent: Intent.PRIMARY
            //                     });

            //                 }


            //             }

            //             if (duplicatePans.length > 0 && data.payload.data.length == 0) {

            //                 //  synchingToast.dismiss()
            //                 synchingToast.clear()
            //                 Toaster.create({ position: Position.TOP }).show({
            //                     message: duplicatePans.length+"duplicates were found and automatically removed",
            //                     intent: Intent.PRIMARY
            //                 });

            //             }


            //         })


            //     } else {

            //         closeBulkDialog(false)
            //         Toaster.create({ position: Position.TOP }).show({
            //             message: <Box sx={{
            //                 display: 'flex',
            //                 flexDirection: 'row',
            //                 alignItems: 'center',
            //             }}>
            //                 Maximum PAN limit reached
            //                 <Text
            //                     onClick={() => {

            //                     }}
            //                     sx={{
            //                         marginLeft: '8px',
            //                         cursor: 'pointer',
            //                         color: 'white',
            //                         textDecoration: 'underline'
            //                     }}>
            //                     Upgrade
            //                 </Text>
            //             </Box>,
            //             intent: Intent.DANGER
            //         });





            //     }





            // }





        } else {
            closeBulkDialog(false)
            Toaster.create({ position: Position.TOP }).show({
                message: "No data found in uploaded file",
                intent: Intent.PRIMARY
            });
        }


    }

    const getGrowUserSubscriptionFromDb = async (props) => {

        dispatch(getUserSubscriptionData({
            userEmail: userEmail
        })).then((data) => {

            console.log("getGrowUserSubscriptionFromDb", data.payload)

            //CANCELLED
            if (data?.payload?.body[0]?.subscription?.status === "cancelled") {

                setaddonsCount(0)
                setplanMetaDataQty(0)

            } else {

                setaddonsCount(props)
                setplanMetaDataQty(props)
            }


            // if (data?.payload?.body[0]?.subscription?.status === "in_trial") {

            //     const freeQty = data?.payload?.plan?.item?.metadata?.freequantity
            //     setaddonsCount(freeQty)
            //     setplanMetaDataQty(freeQty)

            // } else {

            //     setaddonsCount(0)
            //     setplanMetaDataQty(0)

            // }

        })

    }

    const getTaxHeroSubscriptionFromDb = async (props) => {



        dispatch(getTaxheroUserSubscription({
            userEmail: userEmail
        })).then(async (data) => {

            console.log("subscription details", data.payload)




            if (data?.payload?.body[0]?.subscription?.status === "active" || data?.payload?.body[0]?.subscription?.status === "in_trial") {

                const addonCountArray = [...data?.payload?.body[0]?.subscription?.subscription_items]
                const totalQuantity = addonCountArray.reduce((acc, item) => acc + item.quantity, 0);

                const freeQty = data?.payload?.plan?.item?.metadata?.freequantity
                setaddonsCount(data?.payload?.plan?.item?.metadata?.totalquantity)
                setplanMetaDataQty(data?.payload?.plan?.item?.metadata?.totalquantity)


            } else {


                // setaddonsCount(data?.payload?.plan?.item?.metadata?.totalquantity)
                // setplanMetaDataQty(data?.payload?.plan?.item?.metadata?.totalquantity)

                const totalQuantity = data?.payload?.plan?.item?.metadata?.totalquantity
                await getGrowUserSubscriptionFromDb(totalQuantity)

            }


        })

    }


    const addItPanToDb = async (props) => {


        const addonCountChargebee = addonsCount
        const addedItrCount = ItList.length

        if (panNameInput.value === "") {

            setpanNameInput((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else if (userNameInput.value === "") {

            setuserNameInput((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else if (userPassword.value === "") {

            setuserPassword((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })

        } else {

            console.log("addedItrCount", addedItrCount)
            console.log("addonCountChargebee", addonCountChargebee)



            if (addedItrCount < addonCountChargebee) {

                dispatch(postItPortal({
                    userId: userId,
                    userEmail: userEmail,
                    panName: panNameInput.value,
                    panNumber: userNameInput.value,
                    panPassword: userPassword.value,
                })).then((data) => {


                    console.log("postItPortal data", data)

                    if (data.payload.statusCode === 409) {

                        Toaster.create({ position: Position.TOP }).show({
                            message: "Already added PAN",
                            intent: Intent.PRIMARY
                        });

                    } else {

                        Toaster.create({ position: Position.TOP }).show({
                            message: "Successfully  added PAN",
                            intent: Intent.SUCCESS
                        });


                        setItList((prevState) => {

                            const newState = [...prevState,
                            data.payload.data[0]]
                            return newState

                        })

                    }

                    closeAddPanDialog("")


                })




            } else {


                closeAddPanDialog("")
                Toaster.create({ position: Position.TOP }).show({
                    message: <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>

                        Beta trial limit exceeds the 25 Pan limit
                        <Text
                            onClick={() => {

                            }}
                            sx={{
                                marginLeft: '8px',
                                cursor: 'pointer',
                                color: 'white',
                                textDecoration: 'underline'
                            }}>
                            Upgrade
                        </Text>
                    </Box>,
                    intent: Intent.DANGER
                });




            }







        }




    }


    const showItPanDialog = (props) => {
        setpanOpenClose(true)

        postHogTrackingITAddPanClick(userId, userEmail)

    }

    const closeAddPanDialog = (props) => {
        setpanOpenClose(false)
        setpanNameInput((prevState) => {
            return ({ ...prevState, value: '', validationStatus: '' })
        })
        setuserNameInput((prevState) => {
            return ({ ...prevState, value: '', validationStatus: '' })
        })
        setuserPassword((prevState) => {
            return ({ ...prevState, value: '', validationStatus: '' })
        })
    }


    const showBulkDialog = (props) => {

        setpanBulkOpenClose(true)
        closeAddPanDialog("")
    }

    const closeBulkDialog = (props) => {

        setpanBulkOpenClose(false)
        setselectedFile(null)
    }


    const BulkPanDialog = (props) => {

        return (
            <Dialog
                sx={{
                    height: '315px',
                    width: '564px',

                }}
                isOpen={panBulkOpenClose}
                onDismiss={() =>

                    closeBulkDialog("")
                }
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text sx={Workplacetheme.scheduleDalogeHeading}>Upload PANs in bulk using an Excel sheet.</Text>

                </Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>


                    <Box sx={{
                        position: 'relative',
                        margin: '25px auto 0px auto',
                        height: '100px',
                        width: '100px',
                        minHeight: '100px',
                        minWidth: '100px',
                        background: '#FAFBFC'
                    }}>

                        <Box sx={{
                            width: '100px',
                            minHeight: '100px',
                            minWidth: '100px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid rgba(27, 31, 36, 0.15)',
                            cursor: 'pointer'
                        }}
                            onClick={() => { computerFileRef.current.click() }}
                        >
                            <UploadIcon scale={32} style={{ display: 'flex', height: '32px', width: '32px' }} />
                            <input ref={computerFileRef} type={"file"} accept=".csv, text/csv" onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />
                        </Box>

                    </Box>

                    <Text
                        sx={{
                            position: 'relative',
                            margin: '34px auto 0px auto',
                            fontFamily: 'Segoe UI',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '36px'
                        }}
                    >
                        Upload or drag and drop a file
                    </Text>





                    <Link
                        sx={{
                            position: 'relative',
                            margin: '-19px 20px 10px 20px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'
                        }}
                        underline={true}
                        href="#"
                        onClick={() => {
                            downloadBulkExcel("")
                        }}
                    >Download Excel template</Link>

                    <Box

                        sx={Workplacetheme.createSpaceDialogFooterLine}

                        borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    ml={24}
                    mr={24}
                    mb={10}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >
                    {
                        showLoadingBtn === true ?

                            <Button
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '78px' }}
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => { handleUploadBulkExcelPan(bulkPanData) }}
                            >
                                Upload

                            </Button>


                    }






                </Box>





            </Dialog>
        )

    }


    const AddItPanDialog = (props) => {

        return (
            <Dialog
                sx={{ height: '315px', width: '564px' }}
                isOpen={panOpenClose}
                onDismiss={() => closeAddPanDialog("")}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id"><Text sx={Workplacetheme.scheduleDalogeHeading}>Add new PAN</Text></Dialog.Header>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 564,
                    height: 'fit-content',

                }}>

                    <Text sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '20px 20px 0px 20px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#6A737D',
                    }}>Please enter data to fetch information.</Text>

                    <Box sx={{
                        position: 'relative',
                        margin: '23px 20px 0px 20px',
                        fontFamily: 'Segoe UI'
                    }}>

                        <TextInput
                            sx={{
                                minHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px'
                            }}
                            ref={currentRef === "panName" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("panName")}
                            onBlur={() => setcurrentRef("")}
                            width={'100%'}
                            aria-label="panName"
                            name="panName"
                            placeholder="Enter name"
                            value={panNameInput.value}
                            validationStatus={panNameInput.validationStatus}
                            onChange={(e) => {
                                setpanNameInput((prevState) => {
                                    const newState = { ...prevState, value: e.target.value, validationStatus: '' }
                                    return newState
                                })
                            }}
                        />



                    </Box>



                    <Box sx={{
                        position: 'relative',
                        margin: '20px 20px 0px 20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px'
                    }}>
                        <TextInput
                            sx={{
                                width: '255px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            ref={currentRef === "userName" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("userName")}
                            onBlur={() => setcurrentRef("")}
                            placeholder={"Username"}
                            value={userNameInput.value}
                            validationStatus={userNameInput.validationStatus}
                            onChange={(e) => {
                                setuserNameInput((prevState) => {
                                    return ({ ...prevState, value: e.target.value, validationStatus: '' })
                                })
                            }}

                        />
                        <TextInput

                            sx={{
                                width: '255px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            type="password"
                            ref={currentRef === "userPassword" ? initialFocusRef : null}
                            onFocus={() => setcurrentRef("userPassword")}
                            onBlur={() => setcurrentRef("")}
                            placeholder={"Enter password "}
                            value={userPassword.value}
                            validationStatus={userPassword.validationStatus}
                            onChange={(e) => {
                                setuserPassword((prevState) => {
                                    return ({ ...prevState, value: e.target.value, validationStatus: '' })
                                })
                            }}

                        />




                    </Box>

                    <Link
                        sx={{
                            position: 'relative',
                            margin: '13px 20px 10px 20px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F',
                            cursor: 'pointer'
                        }}
                        underline={true}
                        //  href=""
                        onClick={() => {
                            showBulkDialog("")
                        }}
                    >Upload bulk PAN</Link>

                    <Box

                        sx={Workplacetheme.createSpaceDialogFooterLine}

                        borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">


                    </Box>





                </Box>

                <Box
                    ml={24}
                    mr={24}
                    mb={10}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >

                    <Button variant="invisible"
                        sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                        onClick={() => closeAddPanDialog("")}
                    >Cancel</Button>

                    {
                        showLoadingBtn === true ?

                            <Button
                                variant="outline"
                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            >
                                <Spinner sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    right: '0px',
                                    margin: 'auto',
                                    top: '0px',
                                    bottom: '0px',
                                }} size="small" />
                            </Button>

                            :

                            <Button
                                style={{ width: '58px' }}
                                variant="outline"

                                sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                                onClick={() => {
                                    addItPanToDb("")
                                }
                                }
                            >
                                Add

                            </Button>


                    }






                </Box>





            </Dialog>
        )
    }


    const getItPortalFromDb = (props) => {

        dispatch(getItPortal({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("itList", data.payload.data)

            setItList(data.payload.data)

        })


    }



    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    React.useEffect(() => {

        if (panOpenClose === true) {

            setcurrentRef((prevState) => { return "panName" })
            getTaxHeroSubscriptionFromDb("")
        }



    }, [panOpenClose])


    React.useState(() => {
        getTaxHeroSubscriptionFromDb("")
        getItPortalFromDb("")
        getUserProductTour("")
    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F6F6"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'IT List - taxhero' : 'IT List - taxhero'}
                </title>
            </Helmet>


            <TaxHeroHeader />


            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden'

            }}>


                <Box sx={{
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '0px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1'
                }}>

                    <Text sx={{
                        marginTop: '12px',
                        marginLeft: '32px',
                        marginBottom: '15px',
                        color: customThemVariable.textgray,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                        onClick={() => {

                            hostName === "www.app.taxherohq.com" ||
                            hostName === "www.get.taxherohq.com" 
                            ?
                                navigate('/taxheroportal')
                                :
                                navigate('/portal')
                        }}
                    >
                        Home
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>
                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/itpanlist')
                        }}
                    >
                        IT
                    </Text>

                    <Box sx={{
                        top: '8px',
                        position: 'absolute',
                        right: '76px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px'
                    }}>
                        <Button
                            id="addPanBtn"
                            variant="invisible"
                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                fontSize: '14px',
                            }}
                            onClick={() => {
                                showItPanDialog("")
                            }}
                        >
                            Add new PAN
                        </Button>

                        <IconButton
                            variant="invisible"
                            sx={{
                                border: 'none',
                                color: customThemVariable.textblack
                            }}
                            icon={() => <KebabHorizontalIcon />}
                        />


                    </Box>

                </Box>


                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '81px',
                        right: '61px',
                        display: 'flex',
                        flexDirection: 'column',
                        bottom: '0px',
                    }}
                >

                    <Box sx={{
                        height: 'auto',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        {/* table header */}
                        <Box
                            id="panListing"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                height: '40px',
                                maxHeight: '40px',
                                borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                background: `${customThemVariable.whiteBgColor}`
                            }}>

                            <Box sx={{
                                width: '30.0%',
                                minWidth: '30.0%',
                                maxWidth: '30.0%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Name
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '18.2%',
                                minWidth: '18.2%',
                                maxWidth: '18.2%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        position: 'absolute',
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    PAN
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '30.75%',
                                minWidth: '30.75%',
                                maxWidth: '30.75%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Legal name
                                </Text>
                            </Box>

                            <Box sx={{
                                width: '12.44%',
                                minWidth: '12.44%',
                                maxWidth: '12.44%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >
                                    Last update
                                </Text>
                            </Box>
                            <Box sx={{
                                width: '8.5%',
                                minWidth: '8.5%',
                                maxWidth: '8.5%',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.H6}
                                    sx={{
                                        marginLeft: '16px',
                                        textTransform: 'uppercase',
                                        color: `${customThemVariable.midgreyText}`
                                    }}
                                >

                                </Text>
                            </Box>


                        </Box>
                        {/* ------------ */}
                        {
                            ItList.map((row, index) => {

                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '40px',
                                        height: '40px',
                                        maxHeight: '40px',
                                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                        background: `${customThemVariable.whiteBgColor}`,
                                        cursor: 'pointer',
                                        ":hover": {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        },
                                    }}
                                        onClick={() => {




                                            // localStorage.setItem("client_id",row.client_id)
                                            // localStorage.setItem("insert_id",row.insert_id)
                                            // localStorage.setItem("panName",row.pan_name)
                                            // navigate('/It')




                                        }}
                                    >

                                        <Box sx={{
                                            width: '30.0%',
                                            minWidth: '30.0%',
                                            maxWidth: '30.0%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                            onClick={() => {
                                                localStorage.setItem("client_id", row.client_id)
                                                localStorage.setItem("insert_id", row.insert_id)
                                                localStorage.setItem("panName", row.pan_name)
                                                navigate('/It')
                                            }}
                                        >
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row.pan_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '18.2%',
                                            minWidth: '18.2%',
                                            maxWidth: '18.2%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    position: 'absolute',
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row.pan_number}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '30.75%',
                                            minWidth: '30.75%',
                                            maxWidth: '30.75%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {row?.itr_profile?.data?.data?.profile_details?.pan?.name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '12.44%',
                                            minWidth: '12.44%',
                                            maxWidth: '12.44%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {new Date(row.updated_timestamp).toDateString().substring(3, 10) + ", "}
                                                {new Date(row.updated_timestamp).toLocaleTimeString().substring(0, 20)}
                                            </Text>
                                        </Box>
                                        <Box

                                            sx={{
                                                width: '8.5%',
                                                minWidth: '8.5%',
                                                maxWidth: '8.5%',
                                                minHeight: '40px',
                                                maxHeight: '40px',
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',

                                            }}>

                                            <ActionMenu>
                                                <ActionMenu.Anchor>
                                                    <IconButton
                                                        sx={{
                                                            display: 'flex',
                                                            background: 'transparent',
                                                            border: 'none'
                                                        }}
                                                        icon={() => <KebabHorizontalIcon />}
                                                    />
                                                </ActionMenu.Anchor>
                                                <ActionMenu.Overlay>
                                                    <ActionList sx={{ fontFamily: 'Inter' }}>
                                                        <ActionList.Item variant="danger"
                                                            onSelect={() => handleDeleteItrPan(row.insert_id)}
                                                        >Delete</ActionList.Item>
                                                    </ActionList>
                                                </ActionMenu.Overlay>
                                            </ActionMenu>


                                        </Box>


                                    </Box>
                                )
                            })
                        }
                        <Box sx={{ minHeight: '177px' }}></Box>

                    </Box>


                </Box>




            </Box>



            {AddItPanDialog("")}
            {BulkPanDialog("")}
            <UploadPanDialog />
            <GSTSyncDialog />
            <ITSearchDialogs />


        </Box>
    )

}

export default ITListViewPlaywright