import { Text } from "@primer/react";
import React from "react";

export default function RefundAmount(props){


    const {jsonUrl, formType} = props
    const [refundAmount,setrefundAmount] = React.useState("")
     
    React.useState(async()=>{

           if(jsonUrl!==null&& formType!==null){
    
                const response = await fetch(jsonUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/xml')) {
                    const responseData = await response.text();
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(responseData, 'text/xml');

                    if (formType === 'ITR-1') {
                        const refundElement = xmlDoc.getElementsByTagName('RefundDue')[0];
                        if (refundElement) {
                            setrefundAmount(refundElement.textContent)
                            
                        } else {
                            console.log('Refund amount not found');
                        }
                    } else if (formType === 'ITR-2') {
                        const refundElement = xmlDoc.getElementsByTagName('ITRForm:RefundDue')[0];
                        if (refundElement) {
                            setrefundAmount(refundElement.textContent)
                        } else {
                            console.log('Refund amount not found');
                        }
                    }
                } else {
                    const responseData = await response.json();
                    if (formType === 'ITR-1') {
                        setrefundAmount(responseData?.ITR?.ITR1?.Refund?.RefundDue || '')
                    } else if (formType === 'ITR-5') {
                        setrefundAmount(responseData?.ITR?.ITR5?.PartB_TTI?.Refund?.RefundDue || '')
                    }
                }
             
                
           }

    })



    return(<Text>
            {refundAmount}
    </Text>)

}