import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getFeedData = createAsyncThunk("getFeedData", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getFeedData?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}&workflowId=${data.workflowId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result
})

export const getDataByTaskId = createAsyncThunk("", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "taskId": data.taskId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getDataByTaskId`, requestOptions)
        .then(response => response.json())

    return result

})


export const isFeedDueTasksStatus = createAsyncThunk("isFeedDueTasksStatus", async (data) => {

    return ({ isFeedDueTasks: data.isFeedDueTasks })

})

export const isFeedOpenTasksStatus = createAsyncThunk("isFeedOpenTasksStatus", async (data) => {

    return ({ isFeedOpenTasks: data.isFeedOpenTasks })

})



export const feedSlice = createSlice({

    name: 'moveWorkplaceOpenClose',
    initialState: {
        loading: false,
        tasks: [],
        isFeedDueTasks: false,
        isFeedOpenTasks: false
    },
    extraReducers: {

        [getFeedData.pending]: (state, action) => {
            state.loading = true;


        },
        [getFeedData.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.tasks = action.payload.data

        },
        [getFeedData.rejected]: (state, action) => {
            state.loading = false
        },
        [isFeedDueTasksStatus.pending]: (state, action) => {
            state.isFeedDueTasks = false
        },
        [isFeedDueTasksStatus.fulfilled]: (state, action) => {
            state.isFeedDueTasks = action.payload.isFeedDueTasks
        },
        [isFeedDueTasksStatus.rejected]: (state, action) => {
            state.isFeedDueTasks = false
        },
        [isFeedOpenTasksStatus.pending]: (state, action) => {
            state.isFeedOpenTasks = false
        },
        [isFeedOpenTasksStatus.fulfilled]: (state, action) => {
            state.isFeedOpenTasks = action.payload.isFeedOpenTasks
        },
        [isFeedOpenTasksStatus.rejected]: (state, action) => {
            state.isFeedOpenTasks = false
        }


    }


})


export default feedSlice.reducer


