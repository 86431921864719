import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"



export const updateHeaderSelectedIndex = createAsyncThunk("updateHeaderSelectedIndex", async (data) => {

    return ({ selectedIndex: data.index })

})




export const spaceHeaderSlice = createSlice({

    name: 'spaceHeaderSlice',
    initialState: {
        selectedIndex: 0,
        loading: false,

    },
    extraReducers: {

        [updateHeaderSelectedIndex.pending]: (state, action) => {
            state.loading = true;
            state.selectedIndex = 0

        },
        [updateHeaderSelectedIndex.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.selectedIndex = action.payload.selectedIndex

        },
        [updateHeaderSelectedIndex.rejected]: (state, action) => {
            state.loading = false
            state.selectedIndex = 0
        },

    }


})


export default spaceHeaderSlice.reducer

