import { Box, Button, Breadcrumbs, Text, Heading, StyledOcticon, IconButton, Dialog, TextInput, Link, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { ArrowLeftIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, CopyIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getItPortalByItrId, getSingleGstportal } from "../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import TaxHeroHeader from "./TaxHeroHeader"

function GSTCashLederExpansion(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const itrId = localStorage.getItem("itr_id") || ""

    const passwordRef = React.useRef(null)
    const [allDetails, setallDetails] = React.useState([])
    const [itrDetails, setitrDetails] = React.useState([])
    const [fileditrs, setfileditrs] = React.useState([])
    const [infoArray, setinfoArray] = React.useState([
        { id: 1, taxType: "SGST", fee: "0", penalty: "0", interest: "208", tax: "9391", other: "0", total: "9590" },
        { id: 2, taxType: "CGST", fee: "0", penalty: "0", interest: "208", tax: "9391", other: "0", total: "9590" },
        { id: 3, taxType: "IGST", fee: "0", penalty: "0", interest: "0", tax: "0", other: "0", total: "0" },
        { id: 4, taxType: "CESS", fee: "0", penalty: "0", interest: "0", tax: "0", other: "0", total: "0" },
        { id: 5, taxType: "TOTAL", fee: "0", penalty: "0", interest: "416", tax: "18782", other: "0", total: "19198" },

    ])

    const getGstProfiles = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setallDetails(data.payload.data)


        })


    }


    React.useState(() => {

        getGstProfiles("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" 
                     ||
                     hostName === "www.get.taxherohq.com" 
                    ? 'GST cash ledger expansion - taxhero' : 'GST cash ledger - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"
                ||
                hostName === "www.get.taxherohq.com" 
                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {allDetails[0]?.pan_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {allDetails[0]?.user_name}
                        </Text>


                        <Label sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: customThemVariable.labelTextColorsecondary,
                            borderRadius: '4px',
                            minHeight: '18px',
                            maxHeight: '18px',
                            height: '18px',
                            background: customThemVariable.labelBgSecondary,
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <Text sx={{ display: 'flex' }}>
                                Verified
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    color: customThemVariable.labelTertiaryTextColor,
                                    marginLeft: '4px'
                                }}>
                                <CheckIcon size={12} />
                            </Box>



                        </Label>

                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            navigate('/ledger')
                        }}
                    >
                        Return


                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>

                {/* -------------------- */}


                {/* GST Cash ledger Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            CPIN
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            22083200022779
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            BRN Number
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            R2222036710230
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Name
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {allDetails[0]?.pan_name}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            CIN
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            HDFC22083200022779
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            Deposit Date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            24 Jan 2023
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Total Challan Amount
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            ₹59,844
                        </Text>
                    </Box>




                </Box>
                {/* ---------------------- */}


                {/* GST Cash ledger Section 2 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '182px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '174px',
                        maxWidth: '174px',
                        width: '174px',
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Receipt
                        </Text>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.primaryTextColor,
                                    minHeight: '20px'
                                }}
                            >
                                GST Challan-Receipt

                            </Text>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '-3px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17} /></Box>}
                            >

                            </IconButton>

                        </Box>


                    </Box>






                </Box>

                {/* ----------------------- */}

                {/* Info section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '276px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Info
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>


                    {/* ----------------- -------------- */}
                    <Box sx={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '36px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        minWidth: 'fit-content',
                        maxWidth: 'fit-content',
                        width: 'fit-content',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                TAX TYPE
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                FEE
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '16px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                PENALTY
                            </Text>
                        </Box>


                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                INTREST
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                TAX
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                OTHER
                            </Text>
                        </Box>

                        <Box sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            minWidth: '119px',
                            maxWidth: '119px',
                            width: '119px',

                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#414552',
                                position: 'relative',
                                margin: '0px 12px 0px 18px',
                                minWidth: '69px',
                                maxWidth: '69px',
                                width: '69px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                TOTAL
                            </Text>
                        </Box>


                    </Box>

                    {infoArray.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '36px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: '#FFFFFF',
                                minWidth: 'fit-content',
                                maxWidth: 'fit-content',
                                width: 'fit-content',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.taxType}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.fee}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.penalty}
                                    </Text>
                                </Box>


                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.interest}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.tax}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.other}
                                    </Text>
                                </Box>

                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '119px',
                                    maxWidth: '119px',
                                    width: '119px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: customThemVariable.primaryTextColor,
                                        position: 'relative',
                                        margin: '0px 12px 0px 18px',
                                        minWidth: '69px',
                                        maxWidth: '69px',
                                        width: '69px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {row.total}
                                    </Text>
                                </Box>


                            </Box>
                        )
                    })}





                </Box>


                {/* --------------------- */}


            </Box>



        </Box>


    )


}


export default GSTCashLederExpansion