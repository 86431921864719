import React from "react"
import { Box, Button, Breadcrumbs, Text,Label } from "@primer/react"
import { UnderlineNav } from '@primer/react/drafts'
import { Helmet } from "react-helmet"
import EmptyData from "../../Space/EmptyData"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import { useDispatch, useSelector } from "react-redux"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@primer/octicons-react"
import { ArrowUpIcon} from "@primer/octicons-react"
import { getSingleGstportal } from "../../redux/features/portalSlice"

function GstNoticePlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const insertId = localStorage.getItem("insert_id") || ""
    const [items, setitems] = React.useState([{ navigation: 'Notice' }])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [allDataFeilds,setallDataFeilds] = React.useState([])
    const [noticeData,setnoticeData] = React.useState([])

    const NoticeListComponent = (props) => {

        return (
            <Box sx={{
                position: 'absolute',
                top: '162px',
                left: '26px',
                right: '81px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px'
                        }}>
                            ACCOUNT
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '15%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '15%',
                        }}>
                            Notice Order Id
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',
                        
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Issued By
                        </Text>
                    </Box>
                   
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '18%',
                        
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '18%',
                        }}>
                            Description
                        </Text>
                    </Box>
                    <Box sx={{

                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '12%',

                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '12%',
                        }}>
                            Folder Type
                        </Text>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '10%',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Issued date
                        </Text>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '10%',
                        
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '10%',
                        }}>
                            Due Date
                        </Text>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '5%',
                        
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '5%',
                        }}>
                            File
                        </Text>
                    </Box>
                    

                </Box>
                {
                    noticeData?.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                   
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                    width:'15%',
                                    maxWidth:'15%'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap'
                                    }}>
                                        {allDataFeilds.length > 0 ? allDataFeilds[0].pan_name : ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '15%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '15%',
                                    }}>
                                        {row?.noticeOrderId}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '12%',
                                    }}>
                                        {row?.issuedBy}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '18%',
                                    maxWidth:'18%',
                                    
                                    
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        width: '100%',
                                        overflow:'hidden',
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap'
                                    }}>
                                        {row?.description}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '12%',
                                }}> <Label sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 12px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#119C2B',
                                    borderRadius: '6px',
                                    minWidth: 'auto',
                                    width: 'auto',
                                    maxWidth: 'auto',
                                    background: '#EBFFF1',
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Text sx={{
                                        color: row?.transactionType === "Credit" ? '#252C32' : row?.transactionType === "Debit" ? '#7f1d1d' : '',
                                    }}>
                                        {row?.type}
                                    </Text>
                                </Label>
                                   
                                </Box>
                               
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '10%',
                                   
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '10%',
                                    }}>
                                       {row?.dateOfIssuance}
                                    </Text>

                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '10%',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '10%',
                                    }}>
                                        {row?.dueDate}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '5%',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '5%',
                                    }}
                                        onClick={() => {
                                            window.open(row?.noticeUrl)
                                        }}
                                    >
                                        View
                                    </Text>

                                </Box>

                               








                            </Box>
                        )
                    })
                }





            </Box>
        )

    }

    const getGstNoticeData = (props) => {

          
        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getGstLedgerData", data.payload)
            setallDataFeilds(data?.payload?.data)
            setnoticeData(data?.payload.data[0]?.playwright_gst_notices)
            

        })

    }




    React.useState(()=>{
        getGstNoticeData("")
    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'GST - Notice - taxhero' : 'GST - Notice - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"||
                 hostName === "www.get.taxherohq.com"
               
                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"
                        ||
                        hostName === "www.get.taxherohq.com"
                        ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {localStorage.getItem("gstpanName")}
                </Text>

 
            </Box>

            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                overflowX: 'hidden'

                
            }}>

                <Text sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '47px',
                    fontFamily: 'Inter',
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '48px',
                    color: '#252C32'
                }}>
                    Notice
                </Text>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '97px',
                        right: '81px',
                        minHeight: 48,
                        maxHeight: 48,
                        height: 48,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#252C32',
                        paddingLeft: '0px',

                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {



                                setSelectedIndex(index)
                                e.preventDefault()


                            }}

                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                <Box sx={{
                    position: 'absolute',
                    right: '81px',
                    top: '67px',
                    display: 'none',
                    flexDirection: 'row',
                    gap: '8px'
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <Button
                            leadingIcon={() => <ArrowUpIcon />}
                            sx={{
                                width: '85px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                borderRadius: '6px',
                                border: '1px solid #DDE2E4',
                                background: '#ffffff',
                                fontFamily: 'Inter',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '24px',
                                color: '#252C32',
                            }}>
                            Filter
                        </Button>
                        <Label
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: '70px',
                                background: '#F76659',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '1px 8px 1px 8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',

                            }}

                        >
                            <Box
                                sx={{
                                    minWidth: '5px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',

                                }}
                            >
                                {"1"}
                            </Box>

                        </Label>
                    </Box>

                    <Button
                        leadingIcon={() => <ArrowUpIcon />}
                        sx={{
                            width: '96px',
                            maxWidth: '96px',
                            minWidth: '96px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            borderRadius: '6px',
                            border: '1px solid #DDE2E4',
                            background: '#ffffff',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#252C32',
                        }}>
                        Export
                    </Button>
                </Box>


                {selectedIndex == 0 ? <NoticeListComponent /> : ""}
                



            </Box>

 




        </Box>
    )

}

export default GstNoticePlaywright