import { Box, Button, Breadcrumbs, Text, Heading, StyledOcticon, IconButton, Dialog, TextInput, Link, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { ArrowLeftIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, CopyIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getItPortalByItrId, getSingleGstportal } from "../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import TaxHeroHeader from "./TaxHeroHeader"

function GstExpansion(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const itrId = localStorage.getItem("itr_id") || ""
    const pageRoute = localStorage.getItem("pageRoute") || ""

    const passwordRef = React.useRef(null)
    const [allDetails, setallDetails] = React.useState([])
    const [itrDetails, setitrDetails] = React.useState([])
    const [fileditrs, setfileditrs] = React.useState([])
    const [timeLineArray, settimeLineArray] = React.useState([])

    const handledCopyPassword = (props) => {

        if (passwordRef.current) {

            navigator.clipboard.writeText(passwordRef.current.innerText)
                .then(() => {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Copied",
                        intent: Intent.SUCCESS
                    });

                }).catch((error) => {

                });

        }
    }

    const getGstProfiles = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setallDetails(data.payload.data)


        })


    }


    React.useState(() => {

        getGstProfiles("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" 
                     ||
                     hostName === "www.get.taxherohq.com"
                    ? 'GST expansion - taxhero' : 'GST expansion - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" ||  hostName === "www.get.taxherohq.com"
                   
                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {allDetails[0]?.pan_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {allDetails[0]?.user_name}
                        </Text>


                        <Label sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: customThemVariable.labelTextColorsecondary,
                            borderRadius: '4px',
                            minHeight: '18px',
                            maxHeight: '18px',
                            height: '18px',
                            background: customThemVariable.labelBgSecondary,
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <Text sx={{ display: 'flex' }}>
                                Verified
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    color: customThemVariable.labelTertiaryTextColor,
                                    marginLeft: '4px'
                                }}>
                                <CheckIcon size={12} />
                            </Box>



                        </Label>

                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {

                            if (pageRoute === "taxheroPortalGst") {
                                localStorage.setItem("pageRoute", "")
                                navigate('/taxheroportal')
                            } else {
                                localStorage.setItem("pageRoute", "")
                                navigate('/return')
                            }
                        }}
                    >
                        Return


                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>

                {/* -------------------- */}


                {/* ITR Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement no
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            908764261458473
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Assessment year
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            AY 22-23
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Tax period
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            June
                        </Text>
                    </Box>



                </Box>
                {/* ---------------------- */}


                {/* Timeline section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '213px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>


                    {/* ITR Files Section -------------- */}
                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.primaryTextColor,

                        }}
                    >
                        Files
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>

                    <Box sx={{
                        marginTop: '13px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', minHeight: '20px', maxHeight: '20px', height: '20px', alignItems: 'center' }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                01 Jan 2023
                            </Text>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                CPC/2223/A3/322053893
                            </Text>

                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17} /></Box>}
                            >

                            </IconButton>

                        </Box>



                    </Box>

                    {/* ----------------- -------------- */}

                    <Box
                        sx={{
                            minHeight: '100px'
                        }}
                    >

                    </Box>




                </Box>


                {/* --------------------- */}







            </Box>



        </Box>


    )


}


export default GstExpansion