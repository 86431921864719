import React from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Text, Button, Heading, ActionList, ActionMenu, IconButton, TextInput, Spinner } from "@primer/react"
import { ChevronDownIcon, DotsHorizontalIcon, Share2Icon, PlusIcon, CheckIcon, Pencil1Icon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from "react-redux";
import { sharingOpenClose } from "../redux/features/sharingSlice"
import { createSpaceOpenClose, deleteWorkplaceOpenClose, duplicateProject, getWorkplaceProject } from "../redux/features/spaceSlice"
import { moveWorkplaceOpenClose } from "../redux/features/workplaceSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import workplaceTheme from './Workplacetheme';
import CreateSpaceDialog from "./Dialogs/CreateSpaceDialog";

function SpaceComponent(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const spaceDeleteTrigger = useSelector((state) => state.spaceData.spaceDeleteTrigger)
    const spaceReloadTrigger = useSelector((state) => state.spaceData.spaceReloadTrigger)
    const workplaceSortTrigger = useSelector((state) => state.workplaceData.workplaceSortTrigger)
    const workplaceId = props.workplaceId
    const spaceProjects = useSelector((state) => state.spaceData?.spaceProjects || [])

    const [showLoading, setshowLoading] = React.useState(false)
    const [workplaceProjects, setworkplaceProjects] = React.useState([])
    const [isSettingsIconHovered, setisSettingsIconHovered] = React.useState({
        id: "",
        isHovered: false
    })
    const [spaceBoxHovered, setspaceBoxHovered] = React.useState({
        id: "",
        isHovered: false
    })

    const showSharingDialog = (props) => {

        dispatch(sharingOpenClose({
            openClose: true,
            workplaceId: props.workplaceId,
            shareType: props.shareType,
            projectId: props.projectId,
            title: props.title
        }))

    }

    const showMoveWorkplaceDialog = (props) => {

        dispatch(moveWorkplaceOpenClose({
            openClose: true
        }))

    }

    const showDeleteSpaceDialog = (props) => {

        dispatch(deleteWorkplaceOpenClose({
            openClose: true,
            deleteWorkplaceData: {
                workplaceId: '',
                workplaceName: ''
            },
            deleteSpaceData: {
                projectId: props.project_id,
                projectName: props.project_name
            }
        })).then((data) => {

        })
    }

    const showCreateSpaceDialog = (props) => {



        dispatch(createSpaceOpenClose({
            openClose: true,
            workplaceId: props
        })).then((data) => {

        })
    }


    const duplicateProjectIndb = (props) => {

        dispatch(duplicateProject({
            userId: userId,
            userEmail: userEmail,
            projectId: props,
            duplicateProjectId: props,
        })).then((data) => {


            Toaster.create({ position: Position.TOP }).show({
                message: "Project Duplicated.",
                intent: Intent.PRIMARY
            });



        })



    }

    const reloadProjects = (props) => {

        setshowLoading(true)

        dispatch(getWorkplaceProject({
            userId: userId,
            userEmail: userEmail,
            workplaceId: props.workplaceId
        })).then((data) => {

            setworkplaceProjects(data.payload?.data)
            setshowLoading(false)
        })
    }



    React.useEffect(() => {

        reloadProjects(props)


    }, [])


    React.useEffect(() => {

        reloadProjects(props)

    }, [workplaceSortTrigger])




    React.useEffect(() => {

        reloadProjects(props)

    }, [spaceDeleteTrigger])

    React.useEffect(() => {

        reloadProjects(props)

    }, [spaceReloadTrigger])

    return (

        <Box
            sx={workplaceTheme.spaceList}

        >

            <Box sx={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                top: '0px',
                bottom: '0px',
                minHeight: '350px',
                display: showLoading === true ? 'flex' : "none",
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Spinner size="small" />
            </Box>



            {workplaceProjects?.map((row, index) => {
                return (

                    <Box
                        onClick={() => {
                            localStorage.setItem("projectId", row.project_id)
                            localStorage.setItem("projectName", row.project_name)
                            localStorage.setItem("workplaceId", row.workplace_id)
                            localStorage.setItem("projectCreatorId", row.creator_id)
                            isSettingsIconHovered.isHovered === true ? console.log('evne') :
                                navigate('/space/' + row.project_name)

                        }

                        }
                        sx={workplaceTheme.spaceBox}
                        onMouseEnter={() =>

                            setspaceBoxHovered((prevState) => {

                                const newState = { id: row.project_id, isHovered: true }
                                return newState

                            })
                        }
                        onMouseLeave={() =>

                            setspaceBoxHovered((prevState) => {
                                const newState = { id: row.project_id, isHovered: false }
                                return newState
                            })


                        }
                        style={{ background: spaceBoxHovered.isHovered === true && spaceBoxHovered.id === row.project_id ? '#f3f4f6' : '#ffffff' }}
                    >

                        <ActionMenu>
                            <ActionMenu.Anchor  >

                                <Box

                                    onMouseEnter={() => {
                                        setisSettingsIconHovered((prevState) => {

                                            const newState = { id: "1", isHovered: true }
                                            return newState

                                        })
                                    }
                                    }
                                    onMouseLeave={() => {
                                        setisSettingsIconHovered((prevState) => {
                                            const newState = { id: "", isHovered: false }
                                            return newState
                                        })
                                    }


                                    }

                                    sx={spaceBoxHovered.isHovered === true && spaceBoxHovered.id === row.project_id ? workplaceTheme.spaceSettingsIconHover : workplaceTheme.spaceSettingsIcon}

                                >

                                    <IconButton

                                        size="small"
                                        variant="invisible"
                                        sx={{ position: 'relative', margin: 'auto auto auto auto', height: 15, width: 15, padding: 0, color: '#000000' }}
                                        icon={() => <DotsHorizontalIcon />}

                                    />
                                </Box>


                            </ActionMenu.Anchor>

                            <ActionMenu.Overlay>
                                <ActionList sx={workplaceTheme.profileActionMenuList}>
                                    <ActionList.Item onSelect={event => {
                                        event.stopPropagation()
                                        showSharingDialog({
                                            workplaceId: row.workplace_id,
                                            shareType: "space",
                                            projectId: row.project_id,
                                            title: row.project_name
                                        })
                                    }
                                    } sx={workplaceTheme.profileActionMenuListItem}>Share</ActionList.Item>
                                    <ActionList.Item onSelect={event => {
                                        event.stopPropagation()
                                        showMoveWorkplaceDialog("")
                                    }} sx={workplaceTheme.profileActionMenuListItem}>Move</ActionList.Item>
                                    <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                                        onSelect={event => {

                                            duplicateProjectIndb(row.project_id)
                                            event.stopPropagation()
                                        }}
                                    >Duplicate</ActionList.Item>
                                    <ActionList.Divider />
                                    <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                                        onSelect={event => {
                                            event.stopPropagation()
                                            navigate('/settings')
                                        }}
                                    >Settings</ActionList.Item>
                                    <ActionList.Item
                                        onSelect={event => {
                                            event.stopPropagation()
                                            showDeleteSpaceDialog(row)
                                        }
                                        }
                                        variant="danger" sx={workplaceTheme.profileActionMenuListItemDanger}>Delete</ActionList.Item>

                                </ActionList>
                            </ActionMenu.Overlay>
                        </ActionMenu>


                        <Heading color='#DE2424' sx={workplaceTheme.spaceBoxHeading}>
                            {row.project_name.substring(0, 1).toUpperCase()}
                        </Heading>
                        <Text

                            sx={workplaceTheme.spaceBoxText}
                        >{row.project_name}</Text>
                    </Box>


                )
            })}





            <Button
                style={{ display: props.isPlusButton === true ? "flex" : "none" }}
                sx={workplaceTheme.spaceAddBox}
                onClick={() => showCreateSpaceDialog(props.workplaceId)}
            >
                <PlusIcon style={workplaceTheme.spaceBoxAddBoxIcon} />
            </Button>









        </Box>

    )

}

export default SpaceComponent 