import { Box,Text } from "@primer/react"
import {Helmet} from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import RightSideBarOpen from "./RightSideBarOpen";
import EmptyData from "./EmptyData";
import CreateTask from "./CreateTask";
 



function Calander(props){



    return(
        <Box
         position={"relative"} 
         display="flex" 
         background={"#F6F8FA"} 
         height="100vh"
         width={"100vw"} 
         overflowY={"auto"}
        >
            <Helmet>

                <title>{localStorage.getItem("projectName")} - Calander</title>

            </Helmet>


            <SpaceHeader/>
            <SpaceLeftSideNav/>
            {/* <SpaceRightSideNav/> */}

            <EmptyData 
            position="fixed"
            top="158px"
            left="0px"
            right="0px"
            />
 
            {/* <RightSideBarOpen/> */}
             
            <ScheduleDemoDialog/>
            <SearchDialog/>
        </Box>
    )

}

export default Calander