import { Box, Text, Button, Heading, Avatar, Label, ProgressBar, IconButton, StyledOcticon, Checkbox, ActionMenu,ActionList } from "@primer/react"
import React from "react";
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import { ArrowDownIcon, ArrowUpIcon, CheckIcon, FileDirectoryFillIcon, FoldIcon, GraphIcon, KebabHorizontalIcon, SearchIcon, SyncIcon, ZapIcon } from "@primer/octicons-react";
import { useDispatch, useSelector } from "react-redux";
import { UnderlineNav } from '@primer/react/drafts'
import { CalendarIcon, CaretSortIcon, ChevronDownIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import { getAllWorksByProjectId, getInsightData, getProjectServices, getProjectWorkflow, getSpaceCollaborators } from "../redux/features/spaceSlice";
 



function calculateDaysBetweenDates(date1, date2) {
    
    const oneDay = 24 * 60 * 60 * 1000; 
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const durationMs = endDate-startDate  
    const numberOfDays = Math.round(durationMs / oneDay);
  
    return numberOfDays;
  
}

function Dashboard(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskTrigger = useSelector((state)=> state.taskData.taskTrigger)


    const [insiteCounts, setinsiteCounts] = React.useState({
        openToDo: 0,
        inprocess: 0,
        completed: 0,
        overDue: 0
    })

    const calanderDateRef = React.createRef()
    const [filerDateValue,setfilerDateValue] = React.useState("")
    
    const [items, setitems] = React.useState([
        { navigation: 'Overview' },
        { navigation: 'Whitepaper' },
        { navigation: 'Workflow report' },
        { navigation: 'Team review' },
        { navigation: 'Governance' },
        { navigation: 'Data types' },
        { navigation: 'Keys' }
    ])

    const [workflow,setworkflow] = React.useState([])
    const [spaceCollaborators,setspaceCollaborators] = React.useState([])
    const [compliances,setcompliances] = React.useState([])
    const [copyAllTask,setcopyAllTask] = React.useState([])
    const [allTasks,setallTasks] = React.useState([])
    const [teamReviewTasks,setteamReviewTasks] = React.useState([])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [selectedDayFilter,setselectedDayFilter] = React.useState("Last 7 days")
     
    
    const getInsightDataFromDb = (props) => {

        dispatch(getInsightData({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            const openToDoCount = data.payload.openToDoCount || 0
            const inprocessCount = data.payload.inprocessCount || 0
            const completedCount = data.payload.completedCount || 0
            const overDueCount = data.payload.overDueCount || 0

            setinsiteCounts((prevState) => {
                return ({
                    ...prevState,
                    openToDo: openToDoCount,
                    inprocess: inprocessCount,
                    completed: completedCount,
                    overDue: overDueCount
                })
            })


        })

    }

    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)

        })

    }

    const getAllTaskFromDb = (props) => {

        
        dispatch(getAllWorksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
            })
            console.log("sortedTaskArray",sortedTaskArray)
            setcopyAllTask(sortedTaskArray)
            setallTasks(sortedTaskArray)
            setteamReviewTasks(sortedTaskArray)

        })

    }

    const getSpaceCollaboratorFromDb = (props) => {

        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
        })

    }

    const getWorkflowHeads = (props) => {


        dispatch(getProjectWorkflow({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setworkflow(data?.payload?.navArray)
        })

    }

    const onChangeFilterDate = (props) => {

        if (props === "") {

               setallTasks(copyAllTask)

        } else {
           
               const filteredTasks = copyAllTask.filter((fData)=> new Date(fData.created_timestamp).getDate()==new Date(props).getDate())
               setallTasks(filteredTasks)

        }
        

    }

    const onChangeDayFilter = (props) => {

        setselectedDayFilter(props)

        if(props=== "All"){

          
            setallTasks(copyAllTask)
            //----- Filter Section Count
            const todoWorkflowId = workflow[1]?.workflow_id
            const completedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Completed")[0].workflow_id
            const ignorWorkflowId = workflow.filter((wData) => wData.workflow_name === "Ignore")[0].workflow_id
            const verifiedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Verified")[0].workflow_id

            const openToDoCount = copyAllTask.filter((tData)=> tData.workflow_id==todoWorkflowId && tData.completed_status==="false").length
            const completedCount = copyAllTask.filter((tData) => tData.completed_status === "true").length
            
            const inprocessCount = copyAllTask.filter((tData) => tData.workflow_id===todoWorkflowId&&tData.workflow_id!==completedWorkflowId&&tData.workflow_id!==ignorWorkflowId&&tData.workflow_id!==verifiedWorkflowId&&tData.completed_status==="false").length

            setinsiteCounts((prevState) => {
                return ({
                    ...prevState,
                    openToDo: openToDoCount,
                    inprocess: inprocessCount,
                    completed: completedCount,
                    overDue: 0
                })
            })




        } else if (props === "Last 7 days") {


            const currentDate = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(currentDate.getDate() - 7);
            const filteredTask = copyAllTask.filter((fData) => new Date(fData.created_timestamp) >= new Date(sevenDaysAgo) && new Date(fData.created_timestamp) <= new Date())
           
             //----- Filter Section Count
             const todoWorkflowId = workflow[1]?.workflow_id
             const completedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Completed")[0].workflow_id
             const ignorWorkflowId = workflow.filter((wData) => wData.workflow_name === "Ignore")[0].workflow_id
             const verifiedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Verified")[0].workflow_id
 
             const openToDoCount = filteredTask.filter((tData)=> tData.workflow_id==todoWorkflowId && tData.completed_status==="false").length
             const completedCount = filteredTask.filter((tData) => tData.completed_status === "true").length
             const inprocessCount = filteredTask.filter((tData) => tData.workflow_id===todoWorkflowId&&tData.workflow_id!==completedWorkflowId&&tData.workflow_id!==ignorWorkflowId&&tData.workflow_id!==verifiedWorkflowId&&tData.completed_status==="false").length
 
             setinsiteCounts((prevState) => {
                 return ({
                     ...prevState,
                     openToDo: openToDoCount,
                     inprocess: inprocessCount,
                     completed: completedCount,
                     overDue: 0
                 })
             }) 
           
             setallTasks(filteredTask)
           
            
           
           
           
           


        } else if (props === "Last 14 days") {

            const currentDate = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(currentDate.getDate() - 14);
            const filteredTask = copyAllTask.filter((fData)=>   new Date(fData.created_timestamp) >= new Date(sevenDaysAgo) && new Date(fData.created_timestamp) <= new Date())
           
            //----- Filter Section Count
            const todoWorkflowId = workflow[1]?.workflow_id
            const completedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Completed")[0].workflow_id
            const ignorWorkflowId = workflow.filter((wData) => wData.workflow_name === "Ignore")[0].workflow_id
            const verifiedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Verified")[0].workflow_id

            const openToDoCount = filteredTask.filter((tData) => tData.workflow_id == todoWorkflowId && tData.completed_status === "false").length
            const completedCount = filteredTask.filter((tData) => tData.completed_status === "true").length
            const inprocessCount = filteredTask.filter((tData) => tData.workflow_id === todoWorkflowId && tData.workflow_id !== completedWorkflowId && tData.workflow_id !== ignorWorkflowId && tData.workflow_id !== verifiedWorkflowId && tData.completed_status === "false").length

            setinsiteCounts((prevState) => {
                return ({
                    ...prevState,
                    openToDo: openToDoCount,
                    inprocess: inprocessCount,
                    completed: completedCount,
                    overDue: 0
                })
            }) 

            setallTasks(filteredTask)


        } else if (props === "Last 30 days") {

            const currentDate = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(currentDate.getDate() - 30);
            const filteredTask = copyAllTask.filter((fData)=>   new Date(fData.created_timestamp) >= new Date(sevenDaysAgo) && new Date(fData.created_timestamp) <= new Date())
           
            //----- Filter Section Count
            const todoWorkflowId = workflow[1]?.workflow_id
            const completedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Completed")[0].workflow_id
            const ignorWorkflowId = workflow.filter((wData) => wData.workflow_name === "Ignore")[0].workflow_id
            const verifiedWorkflowId = workflow.filter((wData) => wData.workflow_name === "Verified")[0].workflow_id

            const openToDoCount = filteredTask.filter((tData) => tData.workflow_id == todoWorkflowId && tData.completed_status === "false").length
            const completedCount = filteredTask.filter((tData) => tData.completed_status === "true").length
            const inprocessCount = filteredTask.filter((tData) => tData.workflow_id === todoWorkflowId && tData.workflow_id !== completedWorkflowId && tData.workflow_id !== ignorWorkflowId && tData.workflow_id !== verifiedWorkflowId && tData.completed_status === "false").length

            setinsiteCounts((prevState) => {
                return ({
                    ...prevState,
                    openToDo: openToDoCount,
                    inprocess: inprocessCount,
                    completed: completedCount,
                    overDue: 0
                })
            })


            setallTasks(filteredTask)

        }

    }



    const OverviewComp = (props) => {

         return(
            <>
            <Box sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '194px',
                        right: '61px',
                        minHeight: '176px',
                        maxHeight: '176px',
                        height: '176px',
                        background: '#ffffff',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '6px',
                        border: '1px solid #E5E9EB'
                    }}>

                        <Box sx={{
                            position: 'absolute',
                            left: '24px',
                            top: '24px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px'
                        }}>
                            <IconButton
                                variant="invisible"
                                sx={{
                                    height: '32px',
                                    width: '32px',
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    minWidth: '32px',
                                    maxWidth: '32px'
                                }}
                                icon={() => <ChevronDownIcon />}
                            />

                            <Heading
                                sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '24px',
                                    fontWeight: '600',
                                    lineHeight: '32px'
                                }}
                            >Quality score change</Heading>

                        </Box>


                        <ActionMenu>
                            <ActionMenu.Anchor>
                                <Button
                                    variant="invisible"
                                    sx={{
                                        position: 'absolute',
                                        top: '24px',
                                        right: '32px',
                                        height: '32px',
                                        minHeight: '32px',
                                        maxHeight: '32px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        fontWeight: '400',
                                        color: '#252C32'
                                    }}
                                    trailingIcon={() => <ChevronDownIcon />}
                                >
                                    {selectedDayFilter}
                                </Button>
                            </ActionMenu.Anchor>

                            <ActionMenu.Overlay>
                                <ActionList
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: '4px'
                                    }}
                                >
                                    <ActionList.Item
                                        onSelect={(e) => {
                                           onChangeDayFilter("All")
                                          
                                        }}
                                    >All</ActionList.Item>
                                    <ActionList.Item
                                        onSelect={(e) => {
                                           onChangeDayFilter("Last 7 days")
                                          
                                        }}
                                    >Last 7 days</ActionList.Item>
                                    <ActionList.Item
                                        onSelect={(e) => {
                                            onChangeDayFilter("Last 14 days")
                                            
                                        }}
                                    >Last 14 days</ActionList.Item>
                                    <ActionList.Item
                                        onSelect={(e) => {
                                            onChangeDayFilter("Last 30 days")
                                            
                                        }}
                                    >Last 30 days</ActionList.Item>
                                </ActionList>
                            </ActionMenu.Overlay>
                        </ActionMenu>

                         
                        <Box sx={{
                            position: 'absolute',
                            top: '72px',
                            left: '72px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '128px',
                                maxWidth: '128px',
                                width: '128px'
                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: '#252C32'
                                }}>Open task</Text>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Heading sx={{
                                        fontSize: '36px',
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: '48px',
                                        color: '#252C32'
                                    }}>{insiteCounts.openToDo}</Heading>
                                    
                                </Box>
                            </Box>

                            {/* vertical line */}

                            <Box sx={{
                                width: '1px',
                                height: '54px',
                                background: '#E5E9EB',
                                marginTop: '7px'
                            }}>

                            </Box>

                            {/* ------- */}

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '126px',
                                maxWidth: '126px',
                                width: '126px',
                                marginLeft: '15px'
                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: '#252C32',
                                    width: 'fit-content'
                                }}>Completed</Text>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >

                                    <StyledOcticon
                                        color="#47D16C"
                                        icon={ArrowUpIcon}
                                        size={24}
                                        sx={{
                                            marginTop: '10px',
                                            marginLeft: '-4px'
                                        }}
                                    > </StyledOcticon>

                                    <Heading sx={{
                                        fontSize: '36px',
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: '48px',
                                        color: '#252C32'
                                    }}>{insiteCounts.completed}</Heading>
                                    
                                </Box>
                            </Box>

                            {/* vertical line */}

                            <Box sx={{
                                width: '1px',
                                height: '54px',
                                background: '#E5E9EB',
                                marginTop: '7px'
                            }}>

                            </Box>

                            {/* ------- */}

                            <Box sx={{
                                width: '1px',
                                height: '54px',
                                background: '#E5E9EB',
                                marginTop: '7px'
                            }}>

                            </Box>

                            {/* ------- */}

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '126px',
                                maxWidth: '126px',
                                width: '126px',
                                marginLeft: '15px'
                            }}>
                                <Text sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: '#252C32',
                                    width: 'fit-content'
                                }}>Runs</Text>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                    }}
                                >
                                    <Heading sx={{
                                        fontSize: '36px',
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: '48px',
                                        color: '#252C32'
                                    }}>{insiteCounts.inprocess}</Heading>

                                </Box>
                            </Box>


                        </Box>


                    </Box>
                     {/*Section two  */}
                    <Box sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '377px',
                        right: '61px',
                        minHeight: 'auto',
                        maxHeight: 'auto',
                        height: 'auto',
                        paddingBottom: '116px',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                        <Box sx={{
                            minHeight: 'auto',
                            maxHeight: 'auto',
                            height: 'auto',
                            background: '#ffffff',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '6px',
                            border: '1px solid #E5E9EB'
                        }}>

                            <Box sx={{
                                minHeight: '48px',
                                maxHeight: '48px',
                                height: '48px',
                                width: '100%',
                                borderBottom: '1px solid #E5E9EB',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <Text
                                    sx={{
                                        display: 'flex',
                                        position: 'relative',
                                        margin: 'auto 0px auto 16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        color: '#252C32'
                                    }}
                                >Quality and data rule violations</Text>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    position: 'relative',
                                    margin: 'auto 8px auto auto',
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',

                                }}>

                                    <IconButton
                                        variant="invisible"
                                        icon={() => <SearchIcon size={18} />}
                                        sx={{
                                            color: '#48535B'
                                        }}
                                    >

                                    </IconButton>

                                    <Box sx={{
                                        width: '1px',
                                        height: '32px',
                                        minWidth: '1px',
                                        maxWidth: '1px',
                                        minHeight: '32px',
                                        maxHeight: '32px',
                                        background: '#E5E9EB',
                                        marginLeft: '16px'
                                    }}> </Box>

                                    <IconButton
                                        variant="invisible"
                                        icon={() => <ZapIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '16px'
                                        }}
                                    >

                                    </IconButton>
                                    <IconButton
                                        variant="invisible"
                                        icon={() => <ZapIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '4px'
                                        }}
                                    >

                                    </IconButton>
                                    <IconButton
                                        variant="invisible"
                                        icon={() => <SyncIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '4px'
                                        }}
                                    >

                                    </IconButton>

                                    <Box sx={{
                                        width: '1px',
                                        height: '32px',
                                        minWidth: '1px',
                                        maxWidth: '1px',
                                        minHeight: '32px',
                                        maxHeight: '32px',
                                        background: '#E5E9EB',
                                        marginLeft: '16px'
                                    }}> </Box>


                                    <Button
                                        variant="invisible"
                                        sx={{
                                            marginLeft: '8px',
                                            height: '32px',
                                            minHeight: '32px',
                                            maxHeight: '32px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            lineHeight: '24px',
                                            fontWeight: '400',
                                            color: '#252C32'
                                        }}
                                        trailingIcon={() => <ZapIcon />}
                                    >
                                        Chart view
                                    </Button>



                                </Box>


                            </Box>

                            <Box sx={{
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                width: '100%',
                                borderBottom: '1px solid #E5E9EB',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '3.77%',
                                    minWidth: '3.77%',
                                    maxWidth: '3.77%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Checkbox 
                                    
                                        onChange={(e) => {

                                            setallTasks((prevState) => {
                                                return prevState.map((row) => {
                                                    return ({ ...row, isChecked: e.target.checked })
                                                })
                                            })
                                        }}

                                        sx={{
                                            height: '16px',
                                            width: '16px',
                                        }} 
                                    
                                    />
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.66%',
                                    minWidth: '14.66%',
                                    maxWidth: '14.66%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Task</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Compliance</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Assigne</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '8.7%',
                                    minWidth: '8.7%',
                                    maxWidth: '8.7%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Status</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Due date</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        position: 'relative',
                                        margin: '0px 16px 0px auto',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>
                                        {/* Work percentage (%) */}
                                        Subtasks 
                                    </Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '8.33%',
                                    minWidth: '8.33%',
                                    maxWidth: '8.33%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Accuracy</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '7.0%',
                                    minWidth: '7.0%',
                                    maxWidth: '7.0%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>

                                </Box>

                            </Box>


                            {allTasks.map((row, index) => {
                                return (

                                    <Box sx={{
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        width: '100%',
                                        borderBottom: '1px solid #E5E9EB',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '3.77%',
                                            minWidth: '3.77%',
                                            maxWidth: '3.77%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Checkbox 
                                            
                                            checked={row?.isChecked===true}
                                            onChange={(e)=>{
                                                
                                                setallTasks((prevState)=> {
                                                         return prevState.map((row2)=>{
                                                             
                                                               if(row2.task_id===row.task_id){
                                                                  return {...row2,isChecked:e.target.checked} 
                                                               }else{
                                                                  return {...row2}
                                                               }

                                                         })
                                                })
                                                
                                            }}
                                            sx={{
                                                height: '16px',
                                                width: '16px',

                                            }} />
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.66%',
                                            minWidth: '14.66%',
                                            maxWidth: '14.66%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>

                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {row.task_name}
                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                               {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                {row?.assigne_id !== "" && row?.assigne_id !== null ?
                                                    row.user_first_name + " " + row.user_last_name + ""
                                                    : ""}
                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '8.7%',
                                            minWidth: '8.7%',
                                            maxWidth: '8.7%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {workflow.filter((cData) => cData.workflow_id === row.workflow_id)[0]?.workflow_name }
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32'
                                            }}>
                                                {
                                                    row.due_date!==""&&row.due_date!==null ?
                                                    new Date(row?.due_date).toLocaleDateString("en-US", { day: "numeric", year: "numeric", month: "short", })
                                                    :
                                                    ""
                                                }

                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>

                                            <Text sx={{
                                                position: 'relative',
                                                margin: '0px 16px 0px auto',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                            }}>
                                                {
                                                    allTasks.filter((tData) => tData.subtask_parent === row.task_id).length || 0
                                                }


                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '8.33%',
                                            minWidth: '8.33%',
                                            maxWidth: '8.33%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>


                                            {

                                                row.due_date !== null && row.due_date !== "" ?
                                                    <>
                                                        {
                                                            calculateDaysBetweenDates(new Date(), row.due_date) > 0 ?

                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '24px',
                                                                    width: '24px',
                                                                    color: '#22863A',
                                                                }}>
                                                                    <ArrowUpIcon

                                                                        size={24}

                                                                    />
                                                                </Box>
                                                                :
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '24px',
                                                                    width: '24px',
                                                                    color: '#E36209',
                                                                }}>
                                                                    <ArrowDownIcon size={24} />
                                                                </Box>
                                                        }



                                                        <Text
                                                            sx={{

                                                                display: 'flex',
                                                                fontSize: '14px',
                                                                fontFamily: 'Inter',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                color: '#6E7C87',
                                                                lineHeight: '24px',
                                                                width: '100%',
                                                                minWidth: '100%',
                                                                maxWidth: '100%',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {
                                                                calculateDaysBetweenDates(new Date(), row.due_date) > 0 ? calculateDaysBetweenDates(new Date(), row.due_date) + " days left"
                                                                    :
                                                                    Math.abs(parseInt(calculateDaysBetweenDates(new Date(), row.due_date)))
                                                                    +
                                                                    " days due "
                                                            }

                                                        </Text>

                                                    </>

                                                    : ""


                                            }

 

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '7.0%',
                                            minWidth: '7.0%',
                                            maxWidth: '7.0%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <FileDirectoryFillIcon />
                                        </Box>

                                    </Box>

                                )
                            })}




                        </Box>


                    </Box>


            </>
         )

    }

    const SecondComp = (props) => {

        return (<>
            <Box sx={{
                position: 'absolute',
                left: '26px',
                top: '177px',
                right: '61px',
                minHeight: '29px',
                maxHeight: '29px',
                height: '29px',
                display: 'flex',
                flexDirection: 'row',
            }}>

                <Box sx={{
                    height: '28px',
                    minHeight: '28px',
                    maxHeight: '28px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Button sx={{
                        height: '28px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        paddingLeft: '8px',
                        color: '#3C4257'
                    }}
                        trailingIcon={() => <Box sx={{ display: 'flex' }}><ChevronDownIcon /></Box>}
                    >
                        Last 12 months
                    </Button>
                    <Button sx={{
                        height: '28px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        minWidth: '136px',
                        maxWidth: '136px',
                        width: '136px',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        paddingLeft: '8px',
                        marginLeft: '-1px',
                        color: '#3C4257'
                    }}
                        leadingIcon={() => <Box sx={{ display: 'flex', color: '#4F566B' }}><CalendarIcon /></Box>}
                        onClick={() => {
                            calanderDateRef.current.showPicker()
                        }}
                    >

                        <input
                            type={"date"}
                            ref={calanderDateRef}
                            value={filerDateValue}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {

                                    setfilerDateValue("")
                                    onChangeFilterDate("")

                                } else {

                                    setfilerDateValue(e.target.value)
                                    onChangeFilterDate(e.target.value)


                                }


                            }}
                            style={{
                                position: 'absolute',
                                marginLeft: '-31px',
                                marginTop: '-4px',
                                height: '28px',
                                minHeight: '28px',
                                maxHeight: '28px',
                                width: '155px',
                                minWidth: '155px',
                                zIndex: '0',
                                visibility: 'hidden'
                            }}

                        />

                        {/* 1 Aug 2020-7 Jul */}
                        {filerDateValue === "" ? "Select date" : "" +

                            new Date(filerDateValue).toLocaleDateString("en-US", {

                                day: "numeric",
                                year: "numeric",
                                month: "short",
                            })

                            + ""}
                    </Button>



                    <Text sx={{
                        marginLeft: '12px',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: '#1A1F36'
                    }}>
                        Assignee
                    </Text>
                    <Button sx={{

                        height: '28px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        marginLeft: '12px',
                        color: '#3C4257'
                    }}
                        trailingIcon={() => <Box sx={{ display: 'flex' }}><ChevronDownIcon /></Box>}
                    >
                        Previous period
                    </Button>

                </Box>


                <Box sx={{
                    position: 'absolute',
                    right: '0px',
                    height: '28px',
                    minHeight: '28px',
                    maxHeight: '28px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Button sx={{
                        height: '28px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        color: '#3C4257'
                    }}
                        trailingIcon={() => <Box sx={{ display: 'flex' }}><CaretSortIcon /></Box>}
                    >
                        Monthly
                    </Button>
                    <Button sx={{
                        height: '28px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: '500',
                        paddingLeft: '8px',
                        marginLeft: '12px',
                        color: '#3C4257'
                    }}
                        leadingIcon={() => <Box sx={{ display: 'flex', color: '#4F566B' }}><ZapIcon /></Box>}
                    >
                        Edit charts
                    </Button>




                </Box>


            </Box>
            <Box sx={{
                position: 'absolute',
                top: '232px',
                left: '26px',
                right: '61px',
                minHeight: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '80px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '40px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: '#E0E0E0',
                    alignItems: 'center',

                }}>
                    <Box sx={{
                        position: 'absolute',
                        left: '0px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '80px',
                            textTransform: 'uppercase',
                            // textAlign: 'right'
                        }}>
                            Service
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        left: '237px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '353px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px',
                            textTransform: 'uppercase'
                        }}>
                            Description
                        </Text>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        right: '252px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px',
                            textTransform: 'uppercase'
                        }}>
                            Client
                        </Text>
                    </Box>


                    <Box sx={{
                        position: 'absolute',
                        right: '0px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: '244px',
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#84919A',
                            position: 'relative',
                            margin: '0px 12px 0px 12px',
                            minWidth: '220px',
                            textTransform: 'uppercase'
                        }}>
                            Date
                        </Text>
                    </Box>




                </Box>

                {
                    allTasks.map((row, index) => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',

                            }}>
                                <Box sx={{
                                    position: 'absolute',
                                    left: '0px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '80px',
                                        maxWidth: '80px',
                                        overflow: 'hidden',
                                        width: '80px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}>
                                        {row.task_name}
                                    </Text>
                                    <Label sx={{
                                        position: 'relative',
                                        margin: '0px 27px 0px 21px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#119C2B',
                                        borderRadius: '6px',
                                        minWidth: '78px',
                                        width: '78px',
                                        maxWidth: '78px',
                                        background: '#EBFFF1',
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <CheckIcon />
                                        <Text sx={{
                                            marginLeft: '4px'
                                        }}>
                                            Succeeded
                                        </Text>

                                    </Label>

                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    left: '237px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '353px',
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '353px',
                                        maxWidth: '353px',
                                        width: '353px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {row?.description?.blocks[0]?.text}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    position: 'absolute',
                                    right: '252px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '64px'
                                    }}>
                                        {row?.contact_data?.org_name}
                                    </Text>






                                </Box>


                                <Box sx={{
                                    position: 'absolute',
                                    right: '0px',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '244px',

                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#252C32',
                                        position: 'relative',
                                        margin: '0px 12px 0px 12px',
                                        minWidth: '220px'
                                    }}>
                                        {
                                            new Date(row?.created_timestamp).toLocaleDateString("en-US", {

                                                day: "numeric",
                                                year: "numeric",
                                                month: "short",
                                            })
                                        }
                                    </Text>

                                    <IconButton
                                        icon={() => <KebabHorizontalIcon />}
                                        sx={{
                                            position: 'absolute',
                                            right: '0px',
                                            height: '24px',
                                            minHeight: '24px',
                                            maxHeight: '24px',
                                            border: 'none'
                                        }}
                                    />

                                </Box>




                            </Box>
                        )
                    })
                }





            </Box>

        </>)

    }


    const ThirdComp = (props) => {

          return(<>
           <Box sx={{
                                position: 'absolute',
                                left: '26px',
                                top: '194px',
                                right: '61px',
                                minHeight: '226px',
                                maxHeight: '226px',
                                height: '226px',
                                display: 'flex',
                                flexDirection: 'row',
                                borderBottom: '1px solid #E3E8EE'
                            }}>

                                <Box sx={{
                                    minWidth: '58.27%',
                                    minHeight: '226px',
                                    maxHeight: '226px',
                                    height: '226px',
                                    display: 'flex',
                                    borderRight: '1px solid #E3E8EE',
                                    flexDirection: 'column'
                                }}>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '66px',
                                        maxHeight: '66px',
                                        height: '66px',
                                        marginLeft: '40px',
                                        marginTop: '20px',
                                        marginRight: '20px',

                                    }}>
                                        {/* gross volume */}

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            
                                            <Button
                                                variant="invisible"
                                                sx={{
                                                    right: '32px',
                                                    height: '17px',
                                                    minHeight: '17px',
                                                    maxHeight: '17px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontSize: '14px',
                                                    lineHeight: '24px',
                                                    fontWeight: '400',
                                                    color: '#4F566B',
                                                    paddingLeft: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                trailingIcon={() => <ChevronDownIcon />}
                                            >
                                                Gross volume
                                            </Button>
                                            <Text sx={{
                                                marginTop: '5px',
                                                fontFamily: 'Inter',
                                                fontSize: '20px',
                                                color: '#1A1F36',
                                                fontWeight: '400'
                                            }}>
                                                1,452
                                            </Text>
                                            <Text sx={{
                                                marginTop: '4px',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                color: '#4F566B',
                                                fontWeight: '400'
                                            }}>
                                                04:48
                                            </Text>

                                        </Box>

                                        {/* ------ */}
                                              
                                           
                                        {/* past week */}


                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '84px'
                                        }}>

                                            <Button
                                                variant="invisible"
                                                sx={{
                                                    right: '32px',
                                                    height: '17px',
                                                    minHeight: '17px',
                                                    maxHeight: '17px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontSize: '14px',
                                                    lineHeight: '24px',
                                                    fontWeight: '400',
                                                    color: '#4F566B',
                                                    paddingLeft: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                trailingIcon={() => <ChevronDownIcon />}
                                            >
                                                Past week
                                            </Button>
                                            <Text sx={{
                                                marginTop: '5px',
                                                fontFamily: 'Inter',
                                                fontSize: '20px',
                                                color: '#697386',
                                                fontWeight: '400'
                                            }}>
                                                1,452
                                            </Text>


                                        </Box>


                                        {/* ---- */}

                                    </Box>

                                    {/* graph box */}

                                    <Box sx={{

                                        marginLeft: '40px',
                                        marginTop: '16px',
                                        marginRight: '20px',
                                        minHeight: '104px',
                                        maxHeight: '104px',
                                        height: '104px',

                                    }}>

                                    </Box>


                                    {/* ----- */}

                                </Box>
                                <Box sx={{
                                    minWidth: '41.73%',
                                    minHeight: '226px',
                                    maxHeight: '226px',
                                    height: '226px',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>

                                    <Box sx={{
                                        marginTop: '20px',
                                        marginRight: '40px',
                                        minHeight: '90px',
                                        maxHeight: '90px',
                                        height: '90px',
                                        borderBottom: '1px solid #E3E8EE',
                                        paddingLeft: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',

                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minHeight:'17px',
                                            maxHeight:'17px',
                                            height:'17px'
                                        }}>
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                color: '#586069',
                                                fontWeight: '500',
                                                minWidth: '97px',
                                                maxWidth: '97px',
                                                width: '97px'
                                            }}
                                            >Ongoing tasks
                                            </Text>
                                            <Button variant="invisible"
                                                sx={{

                                                    position: 'relative',
                                                    margin:'-6px -16px 0px auto',
                                                    minHeight:'32px',
                                                    maxHeight:'32px',
                                                    height:'32px',
                                                    color: '#0366D6',
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                View
                                            </Button>
                                        </Box>
                                        <Text sx={{ fontFamily: 'Inter', fontSize: '20px', color: '#1A1F36', fontWeight: '500', marginTop: '7px' }}>3582</Text>
                                        <Text sx={{ fontFamily: 'Inter', fontSize: '12px', color: '#586069', fontWeight: '500', marginTop: '10px' }}>Estimated future payouts</Text>


                                    </Box>

                                    <Box sx={{
                                        marginTop: '20px',
                                        marginRight: '40px',
                                        minHeight: '90px',
                                        maxHeight: '90px',
                                        height: '90px',
                                        paddingLeft: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',

                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minHeight:'17px',
                                            maxHeight:'17px',
                                            height:'17px'
                                        }}>
                                            <Text sx={{ fontFamily: 'Inter', fontSize: '14px', color: '#586069', fontWeight: '500', minWidth: '97px', maxWidth: '97px', width: '97px' }}>Overdue tasks</Text>
                                            <Button variant="invisible"
                                                sx={{

                                                    position:'relative',
                                                    margin:'-5px -16px 0px auto',
                                                    minHeight:'32px',
                                                    maxHeight:'32px',
                                                    height:'32px',
                                                    color: '#0366D6',
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                View
                                            </Button>
                                        </Box>
                                        <Text sx={{ fontFamily: 'Inter', fontSize: '20px', color: '#D73A49', fontWeight: '500', marginTop: '7px' }}>4130</Text>
                                        <Text sx={{ fontFamily: 'Inter', fontSize: '12px', color: '#586069', fontWeight: '500', marginTop: '10px' }}>Deposited on 8 Jun</Text>


                                    </Box>


                                </Box>
                            </Box>
                            <Box sx={{
                                position: 'absolute',
                                left: '26px',
                                top: '452px',
                                right: '61px',
                                minHeight: 'auto',
                                maxHeight: 'auto',
                                height: 'auto',
                                paddingBottom: '116px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    paddingLeft: '40px',
                                    flexDirection: 'column'
                                }}>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        color: '#24292E'
                                    }}>Reports overview</Text>

                                    <Box sx={{
                                        marginTop: '16px',
                                        height: '28px',
                                        minHeight: '28px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>

                                        <Button sx={{
                                            height: '28px',
                                            minHeight: '28px',
                                            maxHeight: '28px',
                                            background: '#ffffff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            paddingLeft: '8px',
                                            color: '#3C4257'
                                        }}
                                            trailingIcon={() => <Box sx={{ display: 'flex' }}><ChevronDownIcon /></Box>}
                                        >
                                            Last 12 months
                                        </Button>
                                        <Button sx={{
                                            height: '28px',
                                            minHeight: '28px',
                                            maxHeight: '28px',
                                            background: '#ffffff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            paddingLeft: '8px',
                                            marginLeft: '-1px',
                                            color: '#3C4257'
                                        }}
                                            leadingIcon={() => <Box sx={{ display: 'flex', color: '#4F566B' }}><CalendarIcon /></Box>}
                                        >
                                            1 Aug 2020-7 Jul
                                        </Button>

                                        <Text sx={{
                                            marginLeft: '12px',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            color: '#1A1F36'
                                        }}>
                                            compared to
                                        </Text>
                                        <Button sx={{

                                            height: '28px',
                                            minHeight: '28px',
                                            maxHeight: '28px',
                                            background: '#ffffff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            marginLeft: '12px',
                                            color: '#3C4257'
                                        }}
                                            trailingIcon={() => <Box sx={{ display: 'flex' }}><ChevronDownIcon /></Box>}
                                        >
                                            Previous period
                                        </Button>

                                        <Button sx={{
                                            position: 'absolute',
                                            right: '40px',
                                            height: '28px',
                                            minHeight: '28px',
                                            maxHeight: '28px',
                                            background: '#ffffff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            color: '#3C4257'
                                        }}
                                            trailingIcon={() => <Box sx={{ display: 'flex' }}><CaretSortIcon /></Box>}
                                        >
                                            Monthly
                                        </Button>

                                    </Box>

                                    <Box sx={{
                                        height: '1px',
                                        marginTop: '16px',
                                        background: '#E3E8EE',
                                        marginRight: '40px'
                                    }}></Box>


                                </Box>

                                {/* 1st grid start */}

                                <Box sx={{
                                    marginTop: '12px',
                                    minHeight: '200px',
                                    maxHeight: '200px',
                                    height: '200px',
                                    borderBottom: '1px solid #E3E8EE',
                                    display: 'grid',
                                    gridTemplateColumns: '33.33% 33.33% 33.33%',    //'repeat(3, 1fr)',
                                    gap: '0px'
                                }}>
                                    <Box sx={{
                                        //  background:'#f2f2f2',
                                        borderRight: '1px solid #E3E8EE',
                                        display: 'flex',
                                        overflowX: 'hidden',
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >Verified compliance</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#0E6245',
                                                    borderRadius: '4px',
                                                    background: '#CBF4C9',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    3.2%


                                                </Label>
                                                <Button
                                                 variant="invisible"
                                                    sx={{

                                                        position: 'relative',
                                                        margin: '-6px -17px 0px auto',
                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>4130</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>125392</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>

                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        // background:'#f2f2f2',
                                        borderRight: '1px solid #E3E8EE',
                                        display: 'flex',
                                        overflowX: 'hidden'
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',

                                        }}>


                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >Work success rate</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#4F566B',
                                                    borderRadius: '4px',
                                                    background: '#E3E8EE',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    0.0%


                                                </Label>
                                                <Button variant="invisible"
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '-6px -17px 0px auto',

                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>37%</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>27%</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>



                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        // background:'#f2f2f2',
                                        display: 'flex',
                                        overflowX: 'hidden'
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',
                                            // background:'yellow'
                                        }}>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >Job progress</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#9C3F0F',
                                                    borderRadius: '4px',
                                                    background: '#F8E5BA',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    -60.3%

                                                </Label>
                                                <Button variant="invisible"
                                                    sx={{

                                                        position: 'relative',
                                                        margin: '-6px -16px 0px auto',
                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>413099</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>1253</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>



                                        </Box>

                                    </Box>






                                </Box>
                                {/*-------------- 1st grid end */}
                                {/* 2nd grid start */}


                                <Box sx={{
                                    marginTop: '0px',
                                    minHeight: '200px',
                                    maxHeight: '200px',
                                    height: '200px',
                                    borderBottom: '1px solid #E3E8EE',
                                    display: 'grid',
                                    gridTemplateColumns: '33.33% 33.33% 33.33%',    //'repeat(3, 1fr)',
                                    gap: '0px'
                                }}>
                                    <Box sx={{
                                        //  background:'#f2f2f2',
                                        borderRight: '1px solid #E3E8EE',
                                        display: 'flex',
                                        overflowX: 'hidden',
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >New client</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#4F566B',
                                                    borderRadius: '4px',
                                                    background: '#E3E8EE',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    0.0%


                                                </Label>
                                                <Button variant="invisible"
                                                    sx={{

                                                        position: 'relative',
                                                        margin: '-6px -17px 0px auto',
                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>23</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>12</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>

                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        // background:'#f2f2f2',
                                        borderRight: '1px solid #E3E8EE',
                                        display: 'flex',
                                        overflowX: 'hidden'
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',

                                        }}>


                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >New task initiation</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#0E6245',
                                                    borderRadius: '4px',
                                                    background: '#CBF4C9',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    10.0%


                                                </Label>
                                                <Button variant="invisible"
                                                    sx={{

                                                        position: 'relative',
                                                        margin: '-6px -17px 0px auto',
                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>41</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>37</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>



                                        </Box>

                                    </Box>
                                    <Box sx={{
                                        // background:'#f2f2f2',
                                        display: 'flex',
                                        overflowX: 'hidden'
                                    }}>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '20px 40px 32px 40px',
                                            width: '100%',
                                            // background:'yellow'
                                        }}>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight:'20px',
                                                maxHeight:'20px',
                                                height:'20px'
                                            }}>
                                                <Text
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        color: '#586069'
                                                    }}
                                                >Resource allocation</Text>
                                                <Box sx={{ marginLeft: '8px' }}><ZapIcon /></Box>

                                                <Label sx={{
                                                    marginLeft: '8px',
                                                    fontFamily: 'Inter',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#0E6245',
                                                    borderRadius: '4px',
                                                    background: '#CBF4C9',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    23.4%

                                                </Label>
                                                <Button variant="invisible"
                                                    sx={{

                                                        position: 'relative',
                                                        margin: '-6px -16px 0px auto',
                                                        height: '32px',
                                                        minHeight: '32px',
                                                        maxHeight: '32px',
                                                        color: '#0366D6',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        fontWeight: '500',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    View
                                                </Button>



                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginTop: '7px',
                                                alignItems: 'center'
                                            }}>
                                                <Text sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '20px',
                                                    color: '#24292E'
                                                }}>312</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    fontFamily: 'Inter',
                                                    fontSize: '16px',
                                                    color: '#697386'
                                                }}>0</Text>
                                            </Box>

                                            {/* graph box */}
                                            <Box sx={{
                                                marginTop: '7px',
                                                minWidth: '100%',
                                                height: '72px',
                                                minHeight: '72px',
                                                maxHeight: '72px',
                                            }}>

                                            </Box>
                                            {/* --- */}

                                            <Box sx={{
                                                marginTop: '6px',
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Text sx={{
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Aug 2020</Text>
                                                <Text sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px auto',
                                                    color: '#697386',
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: '400'
                                                }}>Jul 2021</Text>
                                            </Box>



                                        </Box>

                                    </Box>






                                </Box>



                                {/* ----- 2nd grid end */}


                            </Box>
          
          </>)

    }


    const TeamReviewComp = (props) => {

            return(
                <>
                    <Box sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '194px',
                        right: '61px',
                        minHeight: 'auto',
                        maxHeight: 'auto',
                        height: 'auto',
                        paddingBottom: '116px',
                        display: 'flex',
                        flexDirection: 'column',

                    }}>

                        <Box sx={{
                            minHeight: 'auto',
                            maxHeight: 'auto',
                            height: 'auto',
                            background: '#ffffff',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '6px',
                            border: '1px solid #E5E9EB'
                        }}>

                            <Box sx={{
                                minHeight: '48px',
                                maxHeight: '48px',
                                height: '48px',
                                width: '100%',
                                borderBottom: '1px solid #E5E9EB',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <Text
                                    sx={{
                                        display: 'flex',
                                        position: 'relative',
                                        margin: 'auto 0px auto 16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        color: '#252C32'
                                    }}
                                >Quality and data rule violations</Text>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    position: 'relative',
                                    margin: 'auto 8px auto auto',
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    height: '32px',

                                }}>

                                    <IconButton
                                        variant="invisible"
                                        icon={() => <SearchIcon size={18} />}
                                        sx={{
                                            color: '#48535B'
                                        }}
                                    >

                                    </IconButton>

                                    <Box sx={{
                                        width: '1px',
                                        height: '32px',
                                        minWidth: '1px',
                                        maxWidth: '1px',
                                        minHeight: '32px',
                                        maxHeight: '32px',
                                        background: '#E5E9EB',
                                        marginLeft: '16px'
                                    }}> </Box>

                                    <IconButton
                                        variant="invisible"
                                        icon={() => <ZapIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '16px'
                                        }}
                                    >

                                    </IconButton>
                                    <IconButton
                                        variant="invisible"
                                        icon={() => <ZapIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '4px'
                                        }}
                                    >

                                    </IconButton>
                                    <IconButton
                                        variant="invisible"
                                        icon={() => <SyncIcon size={18} />}
                                        sx={{
                                            color: '#48535B',
                                            marginLeft: '4px'
                                        }}
                                    >

                                    </IconButton>

                                    <Box sx={{
                                        width: '1px',
                                        height: '32px',
                                        minWidth: '1px',
                                        maxWidth: '1px',
                                        minHeight: '32px',
                                        maxHeight: '32px',
                                        background: '#E5E9EB',
                                        marginLeft: '16px'
                                    }}> </Box>


                                    <Button
                                        variant="invisible"
                                        sx={{
                                            marginLeft: '8px',
                                            height: '32px',
                                            minHeight: '32px',
                                            maxHeight: '32px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            lineHeight: '24px',
                                            fontWeight: '400',
                                            color: '#252C32'
                                        }}
                                        trailingIcon={() => <ZapIcon />}
                                    >
                                        Chart view
                                    </Button>



                                </Box>


                            </Box>

                            <Box sx={{
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                width: '100%',
                                borderBottom: '1px solid #E5E9EB',
                                display: 'flex',
                                flexDirection: 'row'
                            }}>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '3.77%',
                                    minWidth: '3.77%',
                                    maxWidth: '3.77%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Checkbox

                                        onChange={(e) => {

                                            setteamReviewTasks((prevState) => {
                                                return prevState.map((row) => {
                                                    return ({ ...row, isChecked: e.target.checked })
                                                })
                                            })
                                        }}

                                        sx={{
                                            height: '16px',
                                            width: '16px',
                                        }}

                                    />
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.66%',
                                    minWidth: '14.66%',
                                    maxWidth: '14.66%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Task</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Compliance</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Assigne</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '8.7%',
                                    minWidth: '8.7%',
                                    maxWidth: '8.7%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Status</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        marginLeft: '16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Due date</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '14.4%',
                                    minWidth: '14.4%',
                                    maxWidth: '14.4%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        position: 'relative',
                                        margin: '0px 16px 0px auto',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>
                                       
                                        Subtasks
                                    </Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '8.33%',
                                    minWidth: '8.33%',
                                    maxWidth: '8.33%',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 16px',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        color: '#6E7C87'
                                    }}>Accuracy</Text>
                                </Box>

                                <Box sx={{
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    width: '7.0%',
                                    minWidth: '7.0%',
                                    maxWidth: '7.0%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>

                                </Box>

                            </Box>


                            {teamReviewTasks.map((row, index) => {
                                return (

                                    <Box sx={{
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        width: '100%',
                                        borderBottom: '1px solid #E5E9EB',
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '3.77%',
                                            minWidth: '3.77%',
                                            maxWidth: '3.77%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Checkbox

                                                checked={row?.isChecked === true}
                                                onChange={(e) => {

                                                    setteamReviewTasks((prevState) => {
                                                        return prevState.map((row2) => {

                                                            if (row2.task_id === row.task_id) {
                                                                return { ...row2, isChecked: e.target.checked }
                                                            } else {
                                                                return { ...row2 }
                                                            }

                                                        })
                                                    })

                                                }}
                                                sx={{
                                                    height: '16px',
                                                    width: '16px',

                                                }} />
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.66%',
                                            minWidth: '14.66%',
                                            maxWidth: '14.66%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>

                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {row.task_name}
                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>

                                                {row?.assigne_id !== "" && row?.assigne_id !== null ?
                                                    row.user_first_name + " " + row.user_last_name + ""
                                                    : ""}
                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '8.7%',
                                            minWidth: '8.7%',
                                            maxWidth: '8.7%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                                width: '97%',
                                                minWidth: '97%',
                                                maxWidth: '97%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {workflow.filter((cData) => cData.workflow_id === row.workflow_id)[0]?.workflow_name}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text sx={{
                                                marginLeft: '16px',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32'
                                            }}>
                                                {
                                                    row.due_date !== "" && row.due_date !== null ?
                                                        new Date(row?.due_date).toLocaleDateString("en-US", { day: "numeric", year: "numeric", month: "short", })
                                                        :
                                                        ""
                                                }

                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '14.4%',
                                            minWidth: '14.4%',
                                            maxWidth: '14.4%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>

                                            <Text sx={{
                                                position: 'relative',
                                                margin: '0px 16px 0px auto',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                color: '#252C32',
                                            }}>
                                                {
                                                    allTasks.filter((tData) => tData.subtask_parent === row.task_id).length || 0
                                                }


                                            </Text>

                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '8.33%',
                                            minWidth: '8.33%',
                                            maxWidth: '8.33%',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>


                                            {

                                                row.due_date !== null && row.due_date !== "" ?
                                                    <>
                                                        {
                                                            calculateDaysBetweenDates(new Date(), row.due_date) > 0 ?

                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '24px',
                                                                    width: '24px',
                                                                    color: '#22863A',
                                                                }}>
                                                                    <ArrowUpIcon

                                                                        size={24}

                                                                    />
                                                                </Box>
                                                                :
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    height: '24px',
                                                                    width: '24px',
                                                                    color: '#E36209',
                                                                }}>
                                                                    <ArrowDownIcon size={24} />
                                                                </Box>
                                                        }



                                                        <Text
                                                            sx={{

                                                                display: 'flex',
                                                                fontSize: '14px',
                                                                fontFamily: 'Inter',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                color: '#6E7C87',
                                                                lineHeight: '24px',
                                                                width: '100%',
                                                                minWidth: '100%',
                                                                maxWidth: '100%',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {
                                                                calculateDaysBetweenDates(new Date(), row.due_date) > 0 ? calculateDaysBetweenDates(new Date(), row.due_date) + " days left"
                                                                    :
                                                                    Math.abs(parseInt(calculateDaysBetweenDates(new Date(), row.due_date)))
                                                                    +
                                                                    " days due "
                                                            }

                                                        </Text>

                                                    </>

                                                    : ""


                                            }



                                        </Box>

                                        <Box sx={{
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            width: '7.0%',
                                            minWidth: '7.0%',
                                            maxWidth: '7.0%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <FileDirectoryFillIcon />
                                        </Box>

                                    </Box>

                                )
                            })}




                        </Box>


                    </Box>
                   
                </>
            )

    }


    const GovernenceComp = (props) => {

        return (<>

        </>)
    }
    
    const DataTypeComp = (props) => {
        return (
            <>
            </>
        )
    }

    const KeysComp = (props) => {
         return(<>
         </>)
    }


 
    React.useState(() => {

        getInsightDataFromDb("")
        getAllTaskFromDb("")
        getSpaceCollaboratorFromDb("")
        getAllCategories("")
        getWorkflowHeads("")
    })




    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet><title>{localStorage.getItem("projectName")} - Insight</title></Helmet>



            <SpaceHeader />
            <SpaceLeftSideNav />


            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
                overflowX={"hidden"}

            >

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '23px',
                    right: '61px',
                    minHeight: '145px',
                    maxHeight: '145px',
                    height: '145px',
                    background: '#ffffff'
                }}>


                    <Heading sx={{
                        position: 'absolute',
                        top: '32px',
                        left: '32px',
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        fontWeight: '600',
                        lineHeight: '48px',
                        color: '#24292E'
                    }}>
                        {/* ACH Fincorp */}
                        {localStorage.getItem("projectName")}
                    </Heading>

                    <Button
                        leadingIcon={() => <ZapIcon />}
                        variant="outline"
                        sx={{
                            position: 'absolute',
                            right: '32px',
                            top: '32px',
                            background: '#0965CE',
                            color: '#ffffff',
                            width: '110px',
                            height: '40px',
                            minWidth: '110px',
                            maxWidth: '110px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',

                        }}
                    >
                        Analyze
                    </Button>


                    <UnderlineNav
                        key={"" + Math.random(100, 10000) + ""}
                        aria-label="Repository"
                        sx={{
                            position: 'absolute',
                            left: '32px',
                            bottom: '0px',
                            right: '81px',
                            minHeight: 48,
                            maxHeight: 48,
                            height: 48,
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#252C32',
                            paddingLeft: '0px',
                            border: 'none'
                        }} >
                        {items.map((item, index) => (

                            <UnderlineNav.Item
                                key={item.navigation}
                                aria-current={index === selectedIndex ? 'page' : undefined}
                                onSelect={e => {

                                    setSelectedIndex(index)
                                    e.preventDefault()


                                }}

                                selected={index === selectedIndex}
                            >
                                {item.navigation}
                            </UnderlineNav.Item>

                        ))}
                    </UnderlineNav>



                </Box>


                 {
                 selectedIndex == 0 ? OverviewComp("")  :
                 selectedIndex == 1 ? SecondComp("")    : 
                 selectedIndex == 2 ? ThirdComp("")     : 
                 selectedIndex == 3 ? TeamReviewComp(""):
                 selectedIndex == 4 ? GovernenceComp(""):
                 selectedIndex == 5 ? DataTypeComp("")  :
                 selectedIndex == 6 ? KeysComp(""):
                 ""
                 }


                 




            </Box>







            <ScheduleDemoDialog />
            <SearchDialog />
        </Box>
    )

}

export default Dashboard