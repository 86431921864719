import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, GlobeIcon, BookIcon, FileBadgeIcon } from '@primer/octicons-react'
import { Box, Button, Text, IconButton } from '@primer/react'
import Workplacetheme from "../Workplace/Workplacetheme"
const selectedHomeIcon = "https://d2fcqkt9srr3ti.cloudfront.net/selectedHomeIcon.svg"
const templateIcon = "https://d2fcqkt9srr3ti.cloudfront.net/template_icon.svg"
const feedIcon = "https://d2fcqkt9srr3ti.cloudfront.net/feedIcon.svg"
const portfolioIcon = "https://d2fcqkt9srr3ti.cloudfront.net/portfolioIcon.svg"
const settingsIcon = "https://d2fcqkt9srr3ti.cloudfront.net/settingsIcon.svg"

function SideNavSanbox() {

    let host = window.location.host
    const domainName = window.location.hostname

    const navigate = useNavigate()
    const location = useLocation()

    const [selectedSideMenu, setselectedSideMenu] = React.useState("3")

    return (<Box sx={Workplacetheme.sideNavMain}>

        <Box sx={Workplacetheme.sideNavIconset}>

            <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={() => {
                setselectedSideMenu("0")
                navigate('/workplace')
            }} 
            onMouseEnter={() => { }} onMouseLeave={() => { }}
            >
                
                <IconButton 
                sx={
                 location.pathname==="/workplace"?
                 Workplacetheme.sideNavIconsetIconBox.iconButtonSelected:
                 Workplacetheme.sideNavIconsetIconBox.iconButton }
                
                icon={()=> <HomeIcon size={14} /> }>
                </IconButton>

                <Text sx={
                location.pathname==="/workplace"? 
                Workplacetheme.sideNavIconsetIconBox.textSelected:Workplacetheme.sideNavIconsetIconBox.text}
                >Home</Text>

               
            </Box>

            <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={() => 
            
                {
                    setselectedSideMenu("1")
                    navigate('/feed')
                }
            }>

            <IconButton 
                 sx={
                 location.pathname==="/feed"? 
                 Workplacetheme.sideNavIconsetIconBox.iconButtonSelected:Workplacetheme.sideNavIconsetIconBox.iconButton }
                
                icon={()=> <GlobeIcon size={14} /> }>

                    
                </IconButton>

                <Text 
                sx={
                location.pathname==="/feed"? 
                Workplacetheme.sideNavIconsetIconBox.textSelected:Workplacetheme.sideNavIconsetIconBox.text}
                >Feed</Text>
 
            </Box>

            <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={() => 
               
                {
                    setselectedSideMenu("2")
                  //  navigate('/template')
                    navigate('/portal')
                }
        
            }>

                   <IconButton 
                   sx={
                   location.pathname==="/template" ||
                   location.pathname==="/portal" ||
                   location.pathname==="/gstpanlist" ||
                   location.pathname==="/gst" ||
                   location.pathname==="/notice" ||
                   location.pathname==="/demand" ||
                   location.pathname==="/additionalnotice" ||
                   location.pathname==="/return" ||
                   location.pathname==="/refund" ||
                   location.pathname==="/ledger" ||
                   location.pathname==="/challan" ||
                   location.pathname==="/itpanlist"  ||
                   location.pathname==="/It" ||
                   location.pathname==="/ItReturn" ||
                   location.pathname==="/proceeding" ||
                   location.pathname==="/proceedingNotice" ||
                   location.pathname==="/ItDemand" ||
                   location.pathname==="/ItLedger" ||
                   location.pathname==="/ItChallan" ||
                   location.pathname==="/activity" 
                   ? 
                   Workplacetheme.sideNavIconsetIconBox.iconButtonSelected:Workplacetheme.sideNavIconsetIconBox.iconButton }
                   icon={()=> 
                   
                    //   <BookIcon size={14} /> 
                         <FileBadgeIcon size={16} />
                   
                   }>

                    
                </IconButton>

                <Text 
                 sx={
                    location.pathname==="/template" ||
                    location.pathname==="/portal" ||
                    location.pathname==="/gstpanlist" ||
                    location.pathname==="/gst" ||
                    location.pathname==="/notice" ||
                    location.pathname==="/demand" ||
                    location.pathname==="/additionalnotice" ||
                    location.pathname==="/return" ||
                    location.pathname==="/refund" ||
                    location.pathname==="/ledger" ||
                    location.pathname==="/challan" ||
                    location.pathname==="/itpanlist"  ||
                    location.pathname==="/It" ||
                    location.pathname==="/ItReturn" ||
                    location.pathname==="/proceeding" ||
                    location.pathname==="/proceedingNotice" ||
                    location.pathname==="/ItDemand" ||
                    location.pathname==="/ItLedger" ||
                    location.pathname==="/ItChallan" ||
                    location.pathname==="/activity" 
                  ? 
                  Workplacetheme.sideNavIconsetIconBox.textSelected:Workplacetheme.sideNavIconsetIconBox.text}
                >Portal</Text>

                

            </Box>

             



        </Box>


    </Box>)

}

export default SideNavSanbox