import React from "react";
import { Box, Text, Button, Heading, IconButton, Label, Tooltip, Portal, ActionMenu, ActionList } from "@primer/react"
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import { useDispatch, useSelector } from "react-redux";
import { FilterIcon, OrganizationIcon, SortAscIcon, SortDescIcon } from "@primer/octicons-react";
import { addOrganizationDirectorsOpenClose, addOrganizationOpenClose, addOrganizationServicesOpenClose, addProprietorOpenClose, contactProfileOpenClose, getIndiviualsCompanies, getIndiviualsCompaniesById } from "../redux/features/contactSlice";
import ContactProfile from "./ContactProfile";
import DeleteOrganization from "../Workplace/Dialogs/DeleteOrganization";
import AddCredntials from "../Workplace/Dialogs/AddCredntials";
import AddOrganization from "../Workplace/Dialogs/AddOrganization";
import AddOrganizationDirectors from "../Workplace/Dialogs/AddOrganizationDirectors";
import AddOrganizationServices from "../Workplace/Dialogs/AddOrganizationServices";
import { getProjectServices, getSpaceCollaborators } from "../redux/features/spaceSlice";
import { CaretSortIcon } from "@radix-ui/react-icons";
import TaskExpansion from "./TaskExpansion";
import { Toaster, Intent,Position,ProgressBar } from "@blueprintjs/core";
import BulkCompanyUploadDialog from "../Workplace/Dialogs/BulkCompanyUploadDialog";
import AddProprietor from "../Workplace/Dialogs/AddProprietor";
import AddProprietorDirectors from "../Workplace/Dialogs/AddProprietorDirectors";
import AddProprietorServices from "../Workplace/Dialogs/AddProprietorServices";
import BulkProprietaryUploadDialog from "../Workplace/Dialogs/BulkProprietaryUploadDialog";


function Contact(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
  
    const zohoTokens   = JSON.parse(localStorage.getItem("zohoTokens")) || ""

    const projectName = localStorage.getItem("projectName") || ""
    const createContactTrigger = useSelector((state) => state.taskData.createContactTrigger)
    const reloadCompanies = useSelector((state) => state.contactProfileData.reloadCompanies)

    const [companyIndiviual, setcompanyIndiviual] = React.useState([])
    const isAddOrganization = useSelector((state) => state.contactProfileData.isAddOrganization)
    const contactProfileDataOpenClose = useSelector((state) => state.contactProfileData.openClose)
    
    const [isAscending, setisAscending] = React.useState(false)
    const [showEmptyPageComponent, setshowEmptyPageComponent] = React.useState(false)
    const [compliances, setcompliances] = React.useState([])
  
    const getAllCompanyIndiviuals = async (props) => {


        dispatch(getIndiviualsCompanies({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            page: 'Contact'
        })).then((data) => {


            console.log("getIndiviualsCompaniesContact", data?.payload.data)
            const sortedArray = [...data?.payload?.data].sort(function (a, b) {

                return new Date(b.timestamp) - new Date(a.timestamp);
            })
            if (sortedArray.length <= 0) {
                setshowEmptyPageComponent(true)
            } else {
                setshowEmptyPageComponent(false)
            }
            setcompanyIndiviual(sortedArray)


        })

 
    }

    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setcompliances(data?.payload.data)


        })

    }


     const handleSortAscendingDecending = (props) => {

        setisAscending(!isAscending)
        if (isAscending === true) {

            setcompanyIndiviual((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.contact_data.org_name < b.contact_data.org_name) { return -1; }

                })

                return newState
            })


        } else {

            setcompanyIndiviual((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.contact_data.org_name > b.contact_data.org_name) { return -1; }

                })

                return newState
            })
        }






    }

    const onclickCompanyIndiviuals = (props) => {

        dispatch(contactProfileOpenClose({
            openClose: true,
            title: props.contact_data?.org_name,
            data: props
        }))

    }

    const showAddOrganizationDailog = (props) => {

        dispatch(addOrganizationOpenClose({
            isAddOrganization: true
        }))

        //------------

        dispatch(addOrganizationDirectorsOpenClose({
            isAddOrganizationDirectors: false
        }))


        dispatch(addOrganizationServicesOpenClose({
            isAddOrganizationServices: false
        }))



    }


    const showAddProprietorDailog = (props) => {
     
         dispatch(addProprietorOpenClose({
            isAddProprietor:true
         }))   


    }



    const EmptyOrganizationComp = (props) => {

        return (
            <Box sx={{
                width: '434px',
                height: '184px',
                minWidth: '434px',
                maxWidth: '434px',
                minHeight: '184px',
                maxHeight: '184px',
                borderRadius: '6px',
                background: '#FFFFFF',
                border: '1px solid #D0D7DE',
                display: showEmptyPageComponent === true ? 'flex' : 'none',
                flexDirection: 'column',
                position: 'absolute',
                top: '115px',
                left: '0px',
                right: '0px',
                margin: 'auto'
            }}>


                <Box sx={{
                    position: 'relative',
                    margin: '38px auto 0px auto'
                }}>
                    <OrganizationIcon />
                </Box>

                <Heading sx={{
                    position: 'relative',
                    margin: '15px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '36px',
                    color: '#24292F'
                }}>
                    No organizations found
                </Heading>

                <Text sx={{
                    width: '370px',
                    height: '40px',
                    position: 'relative',
                    margin: '4px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#57606A',
                    textAlign: 'center'
                }}>
                    We're sorry, but we couldn't find any organizations in the directory. Please add one to get started.
                </Text>


            </Box>
        )

    }


    React.useEffect(() => {
        getAllCompanyIndiviuals("")
        getAllCategories("")
       // alert(zohoTokens.refresh_token)


    }, [isAddOrganization])


    React.useEffect(() => {
        getAllCompanyIndiviuals("")
        getAllCategories("")
    }, [contactProfileDataOpenClose])


    React.useEffect(() => {
        if (createContactTrigger === true) {

            getAllCompanyIndiviuals("")
        }
    }, [createContactTrigger])


    React.useEffect(() => {
        if(reloadCompanies===true){

            getAllCompanyIndiviuals("")
        }
    },[reloadCompanies])


    React.useState(() => {
        dispatch(contactProfileOpenClose({
            openClose: false,
            title: ""
        }))
        getAllCompanyIndiviuals("")
        getAllCategories("")
        // alert(localStorage.getItem("zohoTokens"))  


    })

    return (
        <Box

            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"hidden"}
        >
            <Helmet>
                <title>
                    {projectName} - Clients
                </title>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.16.0/xlsx.full.min.js"></script>
            </Helmet>

            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
            >


                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '13px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',

                }}>

                    <Heading sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '18px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 0px'
                    }}>
                        Companies and individuals
                    </Heading>


                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>

                        <IconButton
                            sx={{
                                position: 'relative',
                                margin: '0px 14px 0px auto',
                                border: 'none',
                                color: '#57606A',
                                boxShadow: 'none',
                            }}

                            icon={FilterIcon}
                        />
                        <IconButton
                            sx={{
                                position: 'relative',
                                margin: '0px 20px 0px auto',
                                border: 'none',
                                color: '#57606A',
                                boxShadow: 'none',
                            }}
                            icon={isAscending === true ? SortDescIcon : SortAscIcon}
                            onClick={() => handleSortAscendingDecending("")}
                        />

                        <ActionMenu>
                            <ActionMenu.Anchor>
                                <Button sx={{
                                    width: '58px',
                                    height: '32px',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#24292F',
                                    boxShadow: 'none',
                                }}
                                    onClick={() => { }}
                                >
                                    Add
                                </Button>
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay>
                                <ActionList sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '6px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    minWidth:'250px'
                                }}>
                                    <ActionList.Item
                                        onSelect={() => {
                                            showAddOrganizationDailog("")
                                        }}
                                    >
                                        Add company
                                    </ActionList.Item>
                                    <ActionList.Item
                                        onSelect={() => {
                                            showAddProprietorDailog("")
                                        }}
                                    >
                                        Add Proprietor / partnership firm
                                    </ActionList.Item>

                                </ActionList>
                            </ActionMenu.Overlay>
                        </ActionMenu>
                        
                       


                    </Box>



                </Box>


                <Box sx={{
                    position: 'absolute',
                    left: '28px',
                    top: '53px',
                    right: '61px',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#E0E0E0',
                    height: '43px',
                    minHeight: '43px',
                    maxHeight: '43px',
                    borderTop: 1,
                    borderTopStyle: 'solid',
                    borderTopColor: '#E0E0E0',
                    display: 'flex',
                    flexDirection: 'row',
                }}>

                    <Button sx={{
                        width: '107px',
                        height: '24px',
                        minWidth: '107px',
                        minHeight: '24px',
                        maxWidth: '107px',
                        maxHeight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        margin: '7px 0px 0px 0px',
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#121212',
                        border: 'none'
                    }}
                        trailingIcon={() => <CaretSortIcon style={{ display: 'flex', marginLeft: '-7px' }} />}
                    >
                        Organization
                    </Button>

                    <Text sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#121212',
                        position: 'relative',
                        margin: '7px 0px 0px 328px',
                        width: '133px',
                        minWidth: '133px',
                        maxWidth: '133px',
                        minHeight: '24px',
                        maxHeight: '24px',
                        height: '24px',
                    }}>
                        Provided compliance
                    </Text>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '35px',
                        position: 'relative',
                        margin: '7px 9px 0px auto'
                    }}>


                        <Text sx={{
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#121212',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            width: '71px',
                            minWidth: '71px',
                            maxWidth: '71px',
                            minHeight: '24px',
                            maxHeight: '24px',
                            height: '24px',
                        }}>
                            Open tasks
                        </Text>

                        <Text sx={{
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#121212',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            width: '71px',
                            minWidth: '71px',
                            maxWidth: '71px',
                            minHeight: '24px',
                            maxHeight: '24px',
                            height: '24px',
                        }}>
                            In progress
                        </Text>

                        <Text sx={{
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#121212',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '24px',
                            maxHeight: '24px',
                            height: '24px',
                        }}>
                            Overdue
                        </Text>

                        <Text sx={{
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#121212',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '24px',
                            maxHeight: '24px',
                            height: '24px',
                        }}>
                            Completed
                        </Text>
                    </Box>

                </Box>


                {showEmptyPageComponent === true ?
                    <EmptyOrganizationComp />
                    : ""}



                <Box sx={{
                    position: 'absolute',
                    top: '115px',
                    left: '26px',
                    right: '61px',
                    minHeight: 'fit-content',
                    background: 'white',
                    display: companyIndiviual.length > 0 ? 'flex' : 'none',
                    flexDirection: 'column',

                }}>

                    {companyIndiviual.map((row, index) => {

                        return (

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '35px',
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderColor: '#E0E0E0',
                                alignItems: 'center',
                                cursor: 'pointer',
                                ':hover': {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                }
                            }}
                                onClick={() => {
                                    onclickCompanyIndiviuals(row)
                                }}
                            >


                                <Box sx={{
                                    width: '370x',
                                    maxWidth: '370px',
                                    minWidth: '370px',
                                    position: 'relative',
                                    margin: '0px 0px 0px 15px'
                                }}>



                                    <Text
                                        sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '19px',
                                            color: '#121212',
                                            width: '370x',
                                            maxWidth: '370px',
                                            minWidth: '370px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',

                                        }}>
                                        {index + 1}.{row.contact_data?.org_name}

                                    </Text>


                                </Box>

                                <Box sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    minWidth: '330px',
                                    maxWidth: '330px',
                                    width: '330px',
                                    flexWrap: 'nowrap',
                                    overflow: 'hidden',
                                }}>
                                    {row.compliances.map((row2, index2) => {
                                        return (
                                             
                                            
                                            <Tooltip
                                                aria-label={compliances?.filter((cData) => cData?.subcategory.compliance_id === row2.compliance_id)[0]?.subcategory.compliance_name}
                                                direction="e"
                                            >
                                                <Label
                                                    sx={{
                                                        width: 'fit-content',
                                                        minWidth: 'fit-content',
                                                        maxWidth: 'fit-content',
                                                        height: '20px',
                                                        background: '#2155A4',
                                                        borderRadius: '6px',
                                                        border: 'none',
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: '#FFFFFF',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',

                                                    }}>
                                                    {compliances?.filter((cData) => cData?.subcategory.compliance_id === row2.compliance_id)[0]?.subcategory.compliance_name}
                                                </Label>
                                            </Tooltip>
                                            

                                        )
                                    })}




                                </Box>


                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '62px',
                                    position: 'relative',
                                    margin: '0px 9px 0px auto',
                                    width: '373px',
                                    gap: '35px'
                                }}>

                                    <Box sx={{
                                        minWidth: '71px',
                                        maxWidth: '71px',
                                        width: '71px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Label
                                            sx={{
                                                background: '#2DA44E',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '1px 8px 1px 8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',

                                            }}

                                        >

                                            <Box
                                                sx={{
                                                    minWidth: '5px',
                                                    height: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    color: '#FFFFFF',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',

                                                }}
                                            >
                                                {row.openTaskCount}
                                            </Box>

                                        </Label>

                                    </Box>


                                    <Box sx={{
                                        minWidth: '71px',
                                        maxWidth: '71px',
                                        width: '71px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Label
                                            sx={{
                                                background: '#8250DF',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '1px 8px 1px 8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',

                                            }}

                                        >
                                            <Box
                                                sx={{
                                                    minWidth: '5px',
                                                    height: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    color: '#FFFFFF',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',

                                                }}
                                            >
                                                {row.inProgressCount}
                                            </Box>

                                        </Label>

                                    </Box>




                                    <Box sx={{
                                        minWidth: '55px',
                                        maxWidth: '55px',
                                        width: '55px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Label
                                            sx={{
                                                background: '#CF222E',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '1px 8px 1px 8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',

                                            }}

                                        >
                                            <Box
                                                sx={{
                                                    minWidth: '5px',
                                                    height: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    color: '#FFFFFF',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',

                                                }}
                                            >
                                                {row.overdueCount}
                                            </Box>

                                        </Label>

                                    </Box>

                                    <Box sx={{
                                        minWidth: '71px',
                                        maxWidth: '71px',
                                        width: '71px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Label
                                            sx={{
                                                background: '#6E7781',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '1px 8px 1px 8px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: 'none',

                                            }}

                                        >
                                            <Box
                                                sx={{
                                                    minWidth: '5px',
                                                    height: '18px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    color: '#FFFFFF',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',

                                                }}
                                            >
                                                {row.completedCount}
                                            </Box>

                                        </Label>

                                    </Box>






                                </Box>

                            </Box>

                        )

                    })}

                    <Box sx={{ minHeight: '50x', height: '50px', minWidth: '100%', background: '#F6F8FA' }}>

                    </Box>





                </Box>







            </Box>


            <Portal>
               
                {AddOrganization("")}
                {AddProprietor("")}
            </Portal>

           

            {AddOrganizationDirectors("")}
            {AddOrganizationServices("")}
            {BulkCompanyUploadDialog("")}
            {BulkProprietaryUploadDialog("")}

            {/* Proportory Director ---- */}

            {AddProprietorDirectors("")}
            {AddProprietorServices("")}
            {/* ------------------------ */}


            <TaskExpansion />
            <ContactProfile />
            {/* <DeleteOrganization /> */}
            <AddCredntials />

        </Box>
    )

}

export default Contact