import React from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Button, Heading, TextInput, FormControl, Text, Radio, Checkbox, Dialog } from "@primer/react"
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { accountSetupOpenClose, accountSetupSuccessOpenClose, defaultOrgOpenClose, getComplianceServices, sharedOrgOpenClose, updateUserOrganizationArray } from "../redux/features/contactSlice";
import { getUserOrganizationById } from "../redux/features/spaceSlice";
import { shareWorkplaceProject } from "../redux/features/sharingSlice";

const stepOneImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/step-one.svg` 
const stepThreeImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/step-three.svg`

export default function AccountSetup() {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const accountSetupisOpen = useSelector((state) => state.contactProfileData.accountSetupisOpen)

    const [radioBtns, setradioBtns] = React.useState(["one", "two", "three"])
    const [selectedRadio, setselectedRadio] = React.useState(0)
    const [workplaceProjects, setworkplaceProjects] = React.useState([])

    const orgnNameinputRef = React.useRef(null)
    const inviteMemberinputRef = React.useRef(null)

    const [organizationData, setorganizationData] = React.useState({
        orgName: "",
        orgValidationStatus: "",
        gstIn: "",
        addressLine1: "",
        addressLine1ValidationStatus: "",
        addressLine2: "",
        pin: "",
        pinValidationStatus: "",
        phone: "",
        phoneValidationStatus: ""

    })

    const [teamMembers, setteamMembers] = React.useState({
        email1Value: "",
        email1ValidationStatus: "",
        email2Value: "",
        email2ValidationStatus: "",
        email3Value: "",
        email3ValidationStatus: "",

    })

    const [complianceServices,setcomplianceServices] = React.useState([])
    const [selectedComplianceServices,setselectedComplianceServices] = React.useState([])

    const [selectedSubcategory, setselectedSubcategory] = React.useState([])

    const getComplianceServicesFromDb = (props) => {

           
        dispatch(getComplianceServices({
              userId:userId,
              userEmail:userEmail
        })).then((data)=>{

             setcomplianceServices(data.payload.data)
        })

    }

    const reloadProjects = (props) => {


        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            setworkplaceProjects(data.payload?.data)
        })

    }

    const shareOrganization = (props) => {

        dispatch(shareWorkplaceProject({
            userId: "",
            userEmail: teamMembers.email1Value,
            inviteEmail: userEmail,
            workplaceId: workplaceProjects[0]?.workplace_id,
            shareType: "space",
            projectId: workplaceProjects[0]?.project_id,
            inviteId: userId,
            shareRole: "Collaborator"

        }))


    }

    const onChangeIncomTaxChecked = (props, isChecked) => {

          
        setselectedSubcategory((prevState)=>{

            const newState = prevState.map((services) => 
            
               
               services.subcategory_id===props.subcategory_id?
               ({ ...services, isChecked: isChecked }):services
            
            
            )
            return newState
        })

 

    }

    const onChaneOrgInputs = (props, e) => {

        if (props === "orgName") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, orgName: e.target.value, orgValidationStatus: '' }
                return newState
            })

        } else if (props === "gstIn") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, gstIn: e.target.value }
                return newState
            })

        } else if (props === "addressLine1") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, addressLine1: e.target.value, addressLine1ValidationStatus: '' }
                return newState
            })

        } else if (props === "addressLine2") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, addressLine2: e.target.value }
                return newState
            })

        } else if (props === "pin") {

            const inputValue = e.target.value.replace(/[^0-9]/g, '');

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, pin: inputValue, pinValidationStatus: '' }
                return newState
            })

        } else if (props === "phone") {

            const inputValue = e.target.value.replace(/[^0-9]/g, '');

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, phone: inputValue, phoneValidationStatus: '' }
                return newState
            })

        }

    }

    const onChangeTeamInput = (props, e) => {

        if (props === "email1Value") {


            setteamMembers((prevState) => {

                const newState = { ...prevState, email1Value: e.target.value, email1ValidationStatus: '' }
                return newState
            })

        } else if (props === "email2Value") {

            setteamMembers((prevState) => {

                const newState = { ...prevState, email2Value: e.target.value, email2ValidationStatus: '' }
                return newState
            })

        } else if (props === "email3Value") {

            setteamMembers((prevState) => {

                const newState = { ...prevState, email3Value: e.target.value, email3ValidationStatus: '' }
                return newState
            })

        }

    }

    const skipBtnClick = (props) => {

        dispatch(accountSetupOpenClose({
            accountSetupisOpen: false
        }))

        dispatch(accountSetupSuccessOpenClose({
            setupSuccessOpenClose: true
        }))

        updateOrgDataInDb([{orgName: organizationData.orgName,gstIn: organizationData.gstIn,
            addressLine1: organizationData.addressLine1,addressLine2: organizationData.addressLine2,
            pin: organizationData.pin,phone: organizationData.phone,organizationId: workplaceProjects[0]?.project_id}
        ])

    }

    const nextBtnClick = (props) => {

         if (selectedRadio === 2) {

            if (teamMembers.email1Value === "") {


                setteamMembers((prevState) => {

                    const newState = { ...prevState, email1ValidationStatus: 'error' }
                    return newState
                })


            } else {


                const inputValue = teamMembers.email1Value;
                const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

                updateOrgDataInDb([{orgName: organizationData.orgName,gstIn: organizationData.gstIn,
                    addressLine1: organizationData.addressLine1,addressLine2: organizationData.addressLine2,
                    pin: organizationData.pin,phone: organizationData.phone,organizationId: workplaceProjects[0]?.project_id}
                ])


                if (isValidEmail) {


                    shareOrganization("")

                    dispatch(accountSetupSuccessOpenClose({
                        setupSuccessOpenClose: true
                    }))


                    dispatch(accountSetupOpenClose({
                        accountSetupisOpen: false
                    }))



                    if (teamMembers.email2Value !== null && teamMembers.email2Value !== "") {

                        const inputValue2 = teamMembers.email2Value;
                        const isValidEmail2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue2);

                        if (isValidEmail2) {


                            if (teamMembers.email3Value !== null && teamMembers.email3Value !== "") {

                                const inputValue3 = teamMembers.email3Value;
                                const isValidEmail3 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue3);


                                if (isValidEmail3) {



                                } else {

                                    setteamMembers((prevState) => {

                                        const newState = { ...prevState, email3ValidationStatus: 'error' }
                                        return newState
                                    })

                                }



                            }



                        } else {


                            setteamMembers((prevState) => {

                                const newState = { ...prevState, email2ValidationStatus: 'error' }
                                return newState
                            })

                        }


                    }




                } else {

                    setteamMembers((prevState) => {

                        const newState = { ...prevState, email1ValidationStatus: 'error' }
                        return newState
                    })

                }


            }



        } else if (organizationData.orgName === "") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, orgValidationStatus: 'error' }
                return newState
            })

        } else if (organizationData.addressLine1 === "") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, addressLine1ValidationStatus: 'error' }
                return newState
            })

        } else if (organizationData.pin === "") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, pinValidationStatus: 'error' }
                return newState
            })

        } else if (organizationData.phone === "") {

            setorganizationData((prevSate) => {
                const newState = { ...prevSate, phoneValidationStatus: 'error' }
                return newState
            })

        } else {


            if (organizationData.phone.length < 10) {

                setorganizationData((prevSate) => {
                    const newState = { ...prevSate, phoneValidationStatus: 'error' }
                    return newState
                })

            } else {



                // updateOrgDataInDb([{orgName: organizationData.orgName,gstIn: organizationData.gstIn,
                //         addressLine1: organizationData.addressLine1,addressLine2: organizationData.addressLine2,
                //         pin: organizationData.pin,phone: organizationData.phone,organizationId: workplaceProjects[0]?.project_id}
                // ])



                if (selectedRadio < 2) {


                     if(selectedRadio <1){

                        setselectedRadio((prevState) => {

                            const newState = prevState + 1
                            return newState
                        })

                     }else{

                        if(selectedComplianceServices.length > 0 ){

                       
                            setselectedRadio((prevState) => {

                                const newState = prevState + 1
                                return newState
                            })
                 
                        }


                     }

                   


                    

                   




                }







            }





        }









    }


    const updateOrgDataInDb = (props) => {


        dispatch(updateUserOrganizationArray({
            userId: userId,
            userEmail: userEmail,
            organizationArray: props,
            projectId:workplaceProjects[0]?.project_id,
            selectedCategory:selectedComplianceServices,
            selectedSubcategory:selectedSubcategory
            
        })).then((data) => {

            console.log("org update response", data)


        })

    }



    const StepOneComponent = (props) => {

        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '40px',
                    right: '40px',
                    top: '120px',
                    bottom: '126px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '21px'
                }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '18px'
                }}>

                    <TextInput
                        ref={orgnNameinputRef}
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.orgName}
                        validationStatus={organizationData.orgValidationStatus}
                        onChange={(e) => onChaneOrgInputs("orgName", e)}
                        placeholder="Enter your organization's name."
                    />

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.gstIn}
                        onChange={(e) => onChaneOrgInputs("gstIn", e)}
                        placeholder="GSTIN"
                    />

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        validationStatus={organizationData.addressLine1ValidationStatus}
                        value={organizationData.addressLine1}
                        onChange={(e) => onChaneOrgInputs("addressLine1", e)}
                        placeholder="Address line 1"
                    />

                    <TextInput
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={organizationData.addressLine2}
                        onChange={(e) => onChaneOrgInputs("addressLine2", e)}
                        placeholder="Address line 2"
                    />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px'
                    }}>

                        <TextInput
                            sx={{
                                width: '215px',
                                minWidth: '215px',
                                maxWidth: '215px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            placeholder="PIN"
                            onChange={(e) => onChaneOrgInputs("pin", e)}
                            validationStatus={organizationData.pinValidationStatus}
                            value={organizationData.pin}
                        />

                        <TextInput
                            sx={{
                                width: '215px',
                                minWidth: '215px',
                                maxWidth: '215px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                height: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}
                            placeholder="Phone"
                            value={organizationData.phone}
                            validationStatus={organizationData.phoneValidationStatus}
                            onChange={(e) => onChaneOrgInputs("phone", e)}
                            maxLength={10}
                            minLength={10}
                        />

                    </Box>


                </Box>

                <Box>
                    <img src={stepOneImg} />
                </Box>



            </Box>
        )

    }



    const StepTwoComponent = (props) => {

        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '40px',
                    right: '40px',
                    top: '120px',
                    bottom: '126px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0px'
                }}
            >

                <Box sx={{
                    width: '240px',
                    height: '249px',
                    minWidth: '240px',
                    maxWidth: '240px',
                    minHeight: '249px',
                    maxHeight: '249px',
                    background: '#FFFFFF',
                    border: '1px solid #BFBFBF',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                    borderRadius: '4px',

                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '25px 0px 0px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>
                         
                        {complianceServices.map((row,index)=>{
                              return(
                                  <FormControl>
                                      <Checkbox id="default-checkbox" onChange={(e) => 
                                         {
                                         
                                         if(e.target.checked===true){

                                            setselectedComplianceServices((prevState)=>{
                                              
                                                const newState = [...prevState,row]
                                                return newState
 
                                            })
               

                                           row.subcategory.map((sData)=>{

                                            setselectedSubcategory((prevState)=>{

                                                    const newState = [...prevState,{...sData,isChecked:true,category_id:row.category_id}] 

                                                   // console.log("selected subcategories",newState)

                                                    return newState
    
                                                 })


                                            })
                                            
                                            


                                         }else{

                                            setselectedComplianceServices((prevState)=>{
                                                const newState = prevState.filter((sData)=> sData.category_id!==row.category_id)
                                                return newState
 
                                            })

                                            row.subcategory.map((sData)=>{

                                                setselectedSubcategory((prevState)=>{

                                                   const newState = prevState.filter((data)=> data.subcategory_id!==sData.subcategory_id)
                                                   return newState
   
                                                })


                                           })


                                         }
                                         
                                         }
                                      } />
                                      <FormControl.Label
                                          sx={{ fontFamily: 'Segoe UI', fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '20px', color: '#24292E' }}
                                      >{row.category_name}</FormControl.Label>
                                  </FormControl>

                              )
                        })}

                         

                    </Box>



                </Box>


                <Box sx={{
                    //  background:'red',
                    marginLeft: '73px'
                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>

                        <Text sx={{
                            minWidth: '300px',
                            display: 'flex',
                            position: 'relative',
                            margin: '0px 0px -3px 0px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#24292E',
                        }}>{selectedComplianceServices[selectedComplianceServices?.length-1]?.category_name}</Text>

                           

                         {selectedSubcategory
                         .filter((subCat)=> subCat.category_id===selectedComplianceServices[selectedComplianceServices?.length-1]?.category_id)
                         .map((row, index) => {
                                 
                            return (

                                <Box sx={{ flexBasis: 'calc(50% - 20px)' }}>

                                    <FormControl

                                    >
                                        <Checkbox id="default-checkbox" checked={row.isChecked}
                                            onChange={(e) => {
                                                onChangeIncomTaxChecked(row, e.target.checked)
                                            }}
                                        />
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#24292E'
                                        }}>{row.subcategory_name}</FormControl.Label>
                                    </FormControl>

                                </Box>
                            )

                        })}






                    </Box>

                </Box>


            </Box>)

    }


    const StepThreeComponent = (props) => {


        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '40px',
                    right: '40px',
                    top: '120px',
                    bottom: '126px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '23px'
                }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '33px'
                }}>

                    <TextInput
                        ref={inviteMemberinputRef}
                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={teamMembers.email1Value}
                        validationStatus={teamMembers.email1ValidationStatus}
                        onChange={(e) => onChangeTeamInput("email1Value", e)}
                        placeholder="Enter team mate's email address"
                        type="email"
                    />

                    <TextInput

                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={teamMembers.email2Value}
                        onChange={(e) => onChangeTeamInput("email2Value", e)}
                        validationStatus={teamMembers.email2ValidationStatus}
                        placeholder="Enter team mate's email address"
                        type="email"
                    />

                    <TextInput

                        sx={{
                            width: '440px',
                            minWidth: '440px',
                            maxWidth: '440px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        value={teamMembers.email3Value}
                        validationStatus={teamMembers.email3ValidationStatus}
                        onChange={(e) => onChangeTeamInput("email3Value", e)}
                        placeholder="Enter team mate's email address"
                        type="email"
                    />


                </Box>

                <Box>
                    <img src={stepThreeImg} />
                </Box>



            </Box>
        )

    }


    React.useEffect(() => {

        if (selectedRadio === 0) {

            orgnNameinputRef.current.focus();
        }

        if (selectedRadio === 2) {

            inviteMemberinputRef.current.focus();
        }


    }, [selectedRadio])


    React.useEffect(() => {

        if (accountSetupisOpen === true) {

            reloadProjects("")
            getComplianceServicesFromDb("")
        }


    }, [accountSetupisOpen])


    return (

        <Dialog
          isOpen={accountSetupisOpen}
          sx={{
            height: '526px',
            maxHeight: '526px',
            maxWidth: '921px',
            minWidth: '921px',
            minHeight: '526px',
            width: '921px',
            marginTop:'91px'
          }}
          onDismiss={()=>{
            dispatch(accountSetupOpenClose({
                accountSetupisOpen: false
            }))
          }}
        >
        <Box
 
            sx={{
                height: '526px',
                maxHeight: '526px',
                maxWidth: '921px',
                minWidth: '921px',
                minHeight: '526px',
                width: '921px',
                overflow: 'auto',
                background: '#FFFFFF',
                border: '1px solid #D0D7DE',
                borderRadius: '3px',
                zIndex:'1'
            }}
        >

                <Button 
                variant="invisible"
                sx={{
                    display:selectedRadio===0?'flex':'none',
                    position: 'absolute',
                    right: '120px',
                    bottom: '30px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    padding: '10px',
                    zIndex:'10'
                }}
                    onClick={() => {
                        dispatch(accountSetupOpenClose({
                            accountSetupisOpen: false
                        }))
                        dispatch(sharedOrgOpenClose({
                            sharedOrgisOpen: true
                        })) 

                    }}
                >
                    Join Your Company
                </Button>

            <Box sx={{
                position: 'absolute',
                top: '35px',
                left: '40px',
                right: '40px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',

            }}>

                <Heading sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: '#24292E',
                    letterSpacing: '0.3px'
                }}>
                    {
                        selectedRadio === 0 ? "Setup your account" :
                            selectedRadio === 1 ? "Select firm-provided compliances" :
                                selectedRadio === 2 ? "Invite team members." :
                                    selectedRadio === 3 ? "Invite team members." :
                                        ""
                    }
                </Heading>
                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#24292E',

                }}>
                    {
                        selectedRadio === 0 ? "Get started with your account setup in just a few easy steps." :
                            selectedRadio === 1 ? "You can add, remove, and customize these settings at any time." :
                                selectedRadio === 2 ? "Please enter the email address of the person you would like to invite as a collaborator:" :
                                    selectedRadio === 3 ? "Please enter the email address of the person you would like to invite as a collaborator:" :
                                        ""
                    }

                </Text>

               

            </Box>

            {/* component render here */}

            {
                selectedRadio === 0 ? StepOneComponent("") :
                    selectedRadio === 1 ? StepTwoComponent("") :
                        selectedRadio === 2 ? StepThreeComponent("") :
                            selectedRadio === 3 ? "" :
                                ""
            }

            
           
       


            {/* -------------------------------------- */}


            <Box sx={{
                position: 'absolute',
                bottom: '30px',
                left: '30px',
                right: '30px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'row'
            }}>


                <Box sx={{

                    position: 'absolute',
                    top: '0px',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '12px',
                    height: 'fit-content',
                    width: 'fit-content',
                }}>

                    {radioBtns.map((row, index) => {

                        return (
                            <Radio checked={selectedRadio === index ? true : false} />
                        )
                    })}

                </Box>


                {selectedRadio === 2 ?

                    <Button

                        variant="invisible"
                        sx={{
                            position: 'absolute',
                            right: '90px',

                            width: '70px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '3px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            border: 'none',

                        }}
                        onClick={() => skipBtnClick("")}
                    >
                        Skip
                    </Button>



                    : ""}


                <Button

                    variant="outline"
                    sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '70px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0969DA',
                        borderRadius: '3px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        border: 'none',
                        color: '#FFFFFF'
                    }}
                    onClick={() => nextBtnClick("")}
                >
                    Next
                </Button>

            </Box>



        </Box>
        </Dialog>
    )

}
