import React from "react";
import { Helmet } from "react-helmet";
import { Box, Text, Button, Heading, IconButton, Label, Tooltip, Portal, ActionMenu, ActionList, FormControl, Checkbox, RadioGroup, Radio, Dialog } from "@primer/react"
import SpaceHeader from "./SpaceHeader"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import { useDispatch, useSelector } from "react-redux";
import Papa from 'papaparse';
import { getProjectServices } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position,ProgressBar } from "@blueprintjs/core";
import { getOrganization, postCompanyBulk } from "../redux/features/contactSlice";
import { CheckCircleFillIcon, FilterIcon, SortAscIcon, SortDescIcon } from "@primer/octicons-react";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss" 
import { useNavigate } from "react-router-dom";

 
const synchingToast = Toaster.create({ position: Position.TOP })
 
function ContactBulkCompany(props){
  
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const bulkType = localStorage.getItem("bulkType") || "" 

    const [clientData,setclientData] = React.useState([])
    const [selectedClient,setselectedClient] = React.useState(null)
    const [companyTypes, setcompanyTypes] = React.useState([])
    
    const [category, setcategories] = React.useState([])
    const [compliances,setcompliances] = React.useState([])
    const [isAscending,setisAscending] = React.useState(false)

    const [selectedCompanyType,setselectedCompanyType] = React.useState("")
    const [selectedComplialces,setselectedComplialces] = React.useState([])
    const [showConfirmationDialog,setshowConfirmationDialog] = React.useState({
        isShown:false,
        totalCompany:0,
        savedCompany:0
    })

    const [showSuccessDialog,setshowSuccessDialog] = React.useState({
        isShown:false,
        totalCompany:0,
        savedCompany:0
    })

    const handleSortAscendingDecending = (props) => {

        setisAscending(!isAscending) 
    }

    

    // loading toast method from with progress bar
    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }

     
    // get all project compliances
    const getAllCategories = async () => {
        try {
            const { payload } = await dispatch(getProjectServices({
                userId,
                userEmail,
                projectId: localStorage.getItem("projectId")
            }));
            
            console.log("getProjectServices", payload);
            setcategories(payload.category)
            //  const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            //  setcompliances(sortedArray)
            const complianceArray = payload.data.map(row => ({
                due_date: "",
                isChecked: false,
                timestamp: row.subcategory.timestamp,
                creator_id: row.subcategory.creator_id,
                category_id: row.subcategory.category_id,
                billing_type: row.subcategory.billing_type,
                filingPeriod: row.subcategory.filingPeriod,
                remindMeType: row.subcategory.filingPeriod, // Note: Should this be row.subcategory.remindMeType?
                amountPayable: "",
                compliance_id: row.subcategory.compliance_id,
                complianceType: row.subcategory.complianceType,
                compliance_name: row.subcategory.compliance_name,
            }));
    
            console.log("complianceArray", complianceArray);
            setcompliances(complianceArray);
        } catch (error) {

            console.error("Error fetching project services:", error);
            
        }
    };

    
    const getOrganizationFromDb = (props) => {

        dispatch(getOrganization({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {
          
            //Company   Proprietor
            const orgTypeArray = [...data?.payload?.data.filter((oData) => oData.org_name === props)[0]?.org_types]
            setcompanyTypes(orgTypeArray)
        })

    }

    const handleSaveSingleCompanyData = (selectedClient,selectedCompanyType,selectedComplialces) => {

          const newSelectedCompanyType = companyTypes.find((cData)=> cData.id===selectedCompanyType)?.id || ""
         
          console.log("abcnsddmd",selectedComplialces)  

          if(newSelectedCompanyType===""){
             
              
                    

          }else if(selectedComplialces.length <= 0){

               

          }else{

                  setclientData((prevState) => {

                  const newState = prevState.map((row, index) =>  

                      row.id === selectedClient.id ?
                          { ...row, OrganizationType: selectedCompanyType, compliances: selectedComplialces,isValidate:true }
                          : { ...row }
                   )

                  return newState
                  })
 
                 const currentIndex = clientData.findIndex((cData)=> cData.id=== selectedClient.id)
                 const nextIndex = currentIndex+1
              if (nextIndex < (clientData.length - 1)) {
                  setselectedClient(clientData[nextIndex])
                  setselectedCompanyType(clientData[nextIndex].OrganizationType)
                  setselectedComplialces(clientData[nextIndex].compliances)
              } else {
                  setselectedClient(clientData[0])
                  setselectedCompanyType(clientData[0].OrganizationType)
                  setselectedComplialces(clientData[0].compliances)
              }

             

              Toaster.create({ position: Position.TOP }).show({
                  message: "Client services saved",
                  intent: Intent.SUCCESS,
              });
              console.log("savedclientData", clientData) 
              
          }


    }

    const handleSumbitAllCompany = (props) => {

           const totalEntries = [...props].length
           const newValidCompanies = [...props].filter((cData)=> cData.isValidate ==true)
           if(newValidCompanies.length > 0){

                setshowConfirmationDialog((prevState)=>{
                      return({...prevState,isShown:true,totalCompany:totalEntries,savedCompany:newValidCompanies.length})
                }) 

           }else{

           }
             

    }

    const UploadConfirmationDialog = (props) => {
          return (
              <Dialog
               isOpen={showConfirmationDialog.isShown}
               onDismiss={()=>{
                     setshowConfirmationDialog((prevState)=>{
                           return({...prevState,isShown:false,totalCompany:0,savedCompany:0})
                     })
               }}
               sx={{
                  width:'564px',
                  height:'195px',
                  minWidth:'594px',
                  maxWidth:'594px',
                  minHeight:'195px',
                  maxHeight:'195px'  
               }}
              >

                  <Box sx={{
                      position: 'absolute',
                      left: '0px',
                      right: '0px',
                      top: '0px',
                      bottom: '0px',
                      background: 'white',
                      borderRadius: '6px',
                  }}>

                      {/*Header */}
                      <Box sx={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          right: '0px',
                          display: 'flex',
                          flexDirection: 'row',
                          height: '52px',
                          minHeight: '52px',
                          maxHeight: '52px',
                          background: '#F6F8FA'
                      }}>
                          <Text
                              sx={{
                                  position: 'relative',
                                  margin: '16px 0px 0px 16px',
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: '20px',
                                  color: customThemVariable.primaryTextColor
                              }}
                          >Ready to Upload</Text>

                      </Box>

                      {/* ----- */}
                      {/* Body part */}
                       <Box sx={{
                          position: 'absolute',
                          left: '0px',
                          right: '0px',
                          top: '52px',
                          bottom: '52px',
                          display: 'flex',
                          flexDirection: 'column'
                      }}>
                          <Text
                              sx={{
                                  fontFamily:customThemVariable.primartFont,
                                  fontStyle: customThemVariable.fontStyleNormal,
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                  color: customThemVariable.primaryTextColor,
                                  position: 'relative',
                                  margin: '20px 20px 0px 20px'
                              }}
                          >
                              {showConfirmationDialog.savedCompany} client information entries have been saved. 
                              There are {showConfirmationDialog.totalCompany} data entries ready for upload. 
                              Would you like to proceed with creating the client data?
                          </Text>
                      </Box>
                      {/* --------- */}
                      {/* Footer */}
                      <Box sx={{
                          position: 'absolute',
                          bottom: '0px',
                          left: '0px',
                          right: '0px',
                          display: 'flex',
                          flexDirection: 'row',
                          height: '52px',
                          minHeight: '52px',
                          maxHeight: '52px',
                          borderTop: '1px solid #D0D7DE',
                          justifyContent: 'flex-end'
                      }}>

                          <Button 
                          variant="invisible" 
                              sx={{
                                  position: 'relative',
                                  margin: 'auto 8px 10px 0px',
                                  minWidth: '73px',
                                  maxWidth: '73px',
                                  width: '73px',
                                  minHeight: '32px',
                                  maxHeight: '32px',
                                  height: '32px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  fontWeight: '600',
                                  border: 'none',
                                  borderRadius: '3px'
                              }}
                              onClick={() => {
                                  setshowConfirmationDialog((prevState) => {
                                      return ({ ...prevState, isShown: false, totalCompany: 0, savedCompany: 0 })
                                  })
                              }}
                          >
                              Cancel
                          </Button>
                          <Button
                              variant="outline"
                              sx={{
                                  position: 'relative',
                                  margin: 'auto 15px 10px 0px',
                                  minWidth: '73px',
                                  maxWidth: '73px',
                                  width: '73px',
                                  minHeight: '32px',
                                  maxHeight: '32px',
                                  height: '32px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  fontWeight: '600',
                                  color: '#FFFFFF',
                                  background: '#0965CE',
                                  border: 'none',
                                  borderRadius: '3px'
                              }}
                              onClick={() => {

                                   const newClientData = clientData.filter((cData)=> cData.isValidate==true)
                                   handleSubmitBulkContactData(newClientData)
                                  
                              }}
                          >
                              Proceed
                          </Button>

                      </Box>
                      {/* ------ */}


                  </Box>


              </Dialog>
          )
    }

    const UploadSuccessDialog = (props) => {

        return (
        <Dialog
            isOpen={showSuccessDialog.isShown}
           // isOpen={true}
            onDismiss={() => {
                setshowSuccessDialog((prevState) => {
                    return ({ ...prevState, isShown: false, totalCompany: 0, savedCompany: 0 })
                })
            }}
            sx={{
                width: '499px',
                height: '182px',
                minWidth: '499px',
                maxWidth: '499px',
                minHeight: '182px',
                maxHeight: '182px'
            }}
        >

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    background: 'white',
                    borderRadius: '6px',
                    display:'flex',
                    flexDirection:'column'
                }}>

                    <Text
                        sx={{
                            position: 'relative',
                            margin: '23px auto 0px auto',
                            fontFamily: 'Inter',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '36px',
                            color: customThemVariable.primaryTextColor
                        }}
                    >Client Data Successfully Uploaded</Text>
                    <Text
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#57606A',
                            position: 'relative',
                            margin: '0px auto 0px auto',
                            textAlign: 'center'

                        }}
                    >
                        {showSuccessDialog.savedCompany} Entries Added, {showSuccessDialog.totalCompany} Data's Processed.
                        Start creating tasks using client information now.
                        Explore detailed client data on the client page
                    </Text>
                    <Button
                        variant="outline"
                        sx={{
                            position: 'relative',
                            margin: '24px auto 0px auto',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#FFFFFF',
                            background: '#0965CE',
                            border: 'none',
                            borderRadius: '3px'
                        }}
                        onClick={() => {
                           navigate('/contact')
                        }}
                    >
                        Continue to client page
                    </Button>
                    


                </Box>


        </Dialog>)
    }

      
    // handleSubmitBulkContactData to db
    const handleSubmitBulkContactData = async (selectedData) => {

        setshowConfirmationDialog((prevState)=>{
            return({...prevState,isShown:false,totalCompany:0,savedCompany:0})
        })
 
        try {

            if (selectedData.length === 0) {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Empty data",
                    intent: Intent.PRIMARY,
                });

            } else {

                loadingToast(10, "1");
                console.log("handleSubmitBulkContactData", selectedData)
                const { payload } = await dispatch(postCompanyBulk({
                    userId,
                    userEmail,
                    projectId: localStorage.getItem("projectId"),
                    contactType:bulkType==="Company" ? "Organization":'Proprietor',
                    bulkCompanyData: selectedData,
                }));

                console.log("postCompanyBulk", payload.data);

                const responseData = payload.data;
                if (responseData.length > 0) {
                    Toaster.create({ position: Position.TOP }).show({
                        message: "Successfully Saved",
                        intent: Intent.SUCCESS,
                    });

                    setshowSuccessDialog((prevState) => {
                        return ({ ...prevState, isShown: true, totalCompany: clientData.length, savedCompany: responseData.length })
                    })

                    setclientData([]);
                    loadingToast(100, "1");

                  


                }


               


            }
    
        } catch (error) {
            console.error("Error handling bulk contact data:", error);
        }
    };
    
    React.useEffect(() => {


           
           // const contactType = 'Proprietor'
           getAllCategories("")
           getOrganizationFromDb(bulkType) 
           
           const newBulkCompanies = JSON.parse(localStorage.getItem("newBulkCompanies")) || []
           console.log("newBulkCompanies",newBulkCompanies)
          
           if(newBulkCompanies.length > 0){
              setselectedClient(newBulkCompanies[0])
           }


           setclientData(newBulkCompanies)


    }, [])

    return (
        <Box
           
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"hidden"}
        >
             <Helmet>
                <title>
                    {localStorage.getItem("projectName")||""} - Clients Bulk
                </title>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.16.0/xlsx.full.min.js"></script>
            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />


            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
            >

                <Box sx={{
                position: 'absolute',
                left: '26px',
                right: '61px',
                top: '28px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                
                }}>

                    <Heading sx={{
                        fontFamily:customThemVariable.primartFont,
                        fontStyle: customThemVariable.fontStyleNormal,
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 0px'
                    }}>
                        Uploaded data as listed
                    </Heading>
                    <Heading sx={{
                        fontFamily:customThemVariable.primartFont,
                        fontStyle: customThemVariable.fontStyleNormal,
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 10px'
                    }}>
                        {clientData.length}
                    </Heading>

                    <Box sx={{
                        position: 'absolute',
                        right: '103px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>

                        
                        <IconButton
                            sx={{
                                position: 'relative',
                                margin: '0px 14px 0px auto',
                                border: 'none',
                                color: '#57606A',
                                boxShadow: 'none',
                            }}

                            icon={FilterIcon}
                        />
                        <IconButton
                            sx={{
                                position: 'relative',
                                margin: '0px 20px 0px auto',
                                border: 'none',
                                color: '#57606A',
                                boxShadow: 'none',
                            }}
                            icon={isAscending === true ? SortDescIcon : SortAscIcon}
                            onClick={() => handleSortAscendingDecending("")}
                        />
                    </Box>


                    <Button
                        variant="outline"
                        sx={{
                            height: '32px',
                            fontFamily:customThemVariable.primartFont,
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292E',
                            position: 'absolute',
                            right: '0px',
                            background: '#0965CE',
                            color: 'white',
                            paddingLeft:'16px',
                            paddingRight:'16px'
                        }}
                        onClick={() => { 
                            handleSumbitAllCompany(clientData)
                        }}
                    >
                        Submit all
                    </Button>
                    
                

                </Box>

                  
                {/* Client Section  */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '76px',
                    minWidth: '396px',
                    maxWidth: '396px',
                    width: '396px',
                    minHeight: '80vh',
                    maxHeight: '80vh',
                    height: '80vh',
                    background: '#ffffff',
                    boxShadow: '1px 0px 0px 0px rgba(108, 140, 171, 0.15) inset',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    overflowY:'auto',

                }}>
                    
                    <Box sx={{ borderLeft: '2px solid #635BFF' }}>
                        <Text sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontSize: '14px',
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: '600',
                            lineHeight: '20px',
                            color: '#625AFA',
                            margin: '0px 0px 0px 16px'
                        }}>
                            All Clients
                        </Text>

                    </Box>

                    {clientData.map((row,index)=>{
                        return (
                            <Box sx={{
                                width: '100%',
                                height: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    setselectedClient(row)
                                    setselectedCompanyType(row.OrganizationType)
                                    setselectedComplialces(row.compliances)
                                }}
                            >
                                <Text sx={{
                                    fontFamily:customThemVariable.primartFont,
                                    fontSize: '14px',
                                    fontStyle: customThemVariable.fontStyleNormal,
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: row.id===selectedClient?.id?'#0969DA':'#414552',
                                    marginLeft: '24px',
                                    maxWidth:'320px',
                                    minWidth:'auto',
                                    width:'auto',
                                    overflow:'hidden',
                                    whiteSpace:'nowrap',
                                    textOverflow:'ellipsis'
                                }}>
                                    {row.Organization} 
                                </Text>

                                <Box sx={{
                                    color: '#22863A',
                                    marginLeft: '15px',
                                    display: row?.isValidate ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <CheckCircleFillIcon size={12} />

                                </Box>

                            </Box>
                        )
                    })}

                    

                     

                </Box>

                {/* --------------- */}

                {/* Company details section */}
                  
                <Box sx={{
                    position: 'absolute',
                    left: '433px',
                    top: '74px',
                    right: '60px',
                    minHeight: '80vh',
                    maxHeight: '80vh',
                    height: '80vh',
                    background: '#ffffff',
                    boxShadow: '1px 0px 0px 0px rgba(108, 140, 171, 0.15) inset',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    overflowY:'auto'
                }}>

                    <Text
                        sx={{
                            fontFamily:customThemVariable.primartFont,
                            fontSize: '12px',
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: '400',
                            lineHeight: '20px',
                            color: '#586069',
                            position: 'relative',
                            margin: '17px 0px 0px 13px'
                        }}
                    >
                        Select the provided services for your client ; it helps to manage and automate tasks.
                    </Text>

                    <Button
                        variant="invisible"
                        sx={{
                            position: 'absolute',
                            right: '41px',
                            top: '17px',
                            fontFamily:customThemVariable.primartFont,
                            fontSize: '14px',
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight: '600',
                            lineHeight: '20px',
                        }}
                        onClick={()=>{
                            
                            handleSaveSingleCompanyData(selectedClient,selectedCompanyType,selectedComplialces)
                              
                        }}
                        >
                        SAVE
                    </Button>

                    {/* Company type section */}
                    <Box sx={{
                        display:'flex',
                        flexDirection:'row',
                        gap:'8px',
                        position: 'relative',
                        margin: '17px 0px 0px 13px'
                        }}>
                        
                        {companyTypes.map((row,index)=>{
                              return(
                                  <>
                                      <RadioGroup name="defaultRadioGroup"
                                      
                                      >
                                          <FormControl>
                                              <Radio value={row.name}
                                                  checked={row.id===selectedClient?.OrganizationType} 
                                                  onChange={(e) => {
                                                      setselectedCompanyType(row.id)
                                                      setselectedClient((prevState)=> {
                                                           const newState = {...prevState,OrganizationType:row.id}
                                                           return newState
                                                      })
                                                  }}
                                              />
                                              <FormControl.Label
                                                  sx={{
                                                      fontFamily: customThemVariable.primartFont,
                                                      fontSize: '14px',
                                                      fontStyle: 'normal',
                                                      fontWeight: '400',
                                                      lineHeight: '20px',
                                                      color: customThemVariable.primaryTextColor
                                                  }}
                                              >{row.name}</FormControl.Label>
                                          </FormControl>
                                      </RadioGroup>
                                  </>
                              )
                        })}
                      
                    </Box>
                    {/* -------------------- */}

                    {/* categories with compliance */}

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        margin: '17px 0px 0px 13px',
                        gap:'13px'
                    }}>
                        {category.map((row, index) => {

                            return (
                                <>
                                  {/* categories */}
                                  <Box>
                                      <Text sx={{
                                          fontFamily:customThemVariable.primartFont,
                                          fontSize:'14px',
                                          fontStyle:'normal',
                                          fontWeight:'600',
                                          lineHeight:'20px',
                                          color: customThemVariable.primaryTextColor
                                      }}>
                                          {row.category_name}
                                          
                                      </Text>
                                  </Box>
                                    <Box sx={{
                                       display:'flex',
                                       flexDirection:'row',
                                       flexWrap:'wrap',
                                       gap:'20px',
                                       minWidth:'100%',
                                       maxWidth:'100%',
                                       width:'100%',
                                        
                                    }}>

                                    {
                                    compliances.filter((cData)=> cData.category_id===row.category_id)
                                    .map((row2,index2)=>{
                                        return (
                                            <>
                                                <Box 
                                                
                                                as="form"
                                                    sx={{
                                                        fontFamily: customThemVariable.primartFont,
                                                        fontStyle: customThemVariable.fontStyleNormal,
                                                        fontWeight: '400',
                                                        lineHeight: '20px',
                                                        fontSize: '14px',
                                                        minWidth: '240px',
                                                        maxWidth: '240px',
                                                        width: '240px'
                                                    }}
                                                >
                                                    <FormControl
                                                    >
                                                        <Checkbox 
                                                         checked={selectedClient?.compliances?.find((cData)=> cData?.compliance_id===row2?.compliance_id)} 
                                                         value={row2.compliance_name} 
                                                            onChange={(e) => {

                                                                if(e.target.checked){
                                                                    setselectedComplialces((prevState) => {
                                                                        const newState = [...prevState, row2]
                                                                        return newState
                                                                    })
                      
                                                                    if(selectedClient!==null){
                                                                        setselectedClient((prevState)=>{
                                                                            const newState = {...prevState,
                                                                                compliances:[...prevState?.compliances,row2]
                                                                            }
                                                                            return newState
                                                                      })

                                                                    }
                                                                   


                                                                }else{

                                                                    setselectedComplialces((prevState) => {
                                                                        const newState = prevState.filter((cData) => cData.compliance_id !== row2.compliance_id)
                                                                        return newState
                                                                    })

                                                                    if (selectedClient !== null) {


                                                                        setselectedClient((prevState) => {
                                                                            const newState = {
                                                                                ...prevState,
                                                                                compliances: [...prevState?.compliances].filter((cData) => cData.compliance_id !== row2.compliance_id)
                                                                            }
                                                                            return newState
                                                                        })

                                                                    }

                                                                    

                                                                }
                                                                }}
                                                         />
                                                        <FormControl.Label
                                                            sx={{
                                                                fontFamily: customThemVariable.primartFont,
                                                                fontStyle: customThemVariable.fontStyleNormal,
                                                                fontWeight: '400',
                                                                lineHeight: '20px',
                                                                fontSize: '14px',
                                                                color: customThemVariable.primaryTextColor,
                                                                width: '220px',
                                                                maxWidth: '220px',
                                                                minWidth: '220px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >{row2.compliance_name}
                                                        </FormControl.Label>
                                                    </FormControl>
                                                </Box>
                                            </>
                                        )
                                     })}
                                    </Box>

                                </>
                            )


                        })}
                    </Box> 
                    {/* -------------------------- */}

                    {/* --- Company details------- */}

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        margin: '63px 0px 0px 13px',
                        gap: '100px'
                    }}>
                                
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>{selectedClient?.Organization}</Text>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>PAN {selectedClient?.PAN}</Text>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>CIN {selectedClient?.CIN}</Text>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>{companyTypes?.find((cData)=> cData?.id===selectedClient?.OrganizationType)?.name}</Text>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>GSTIN {selectedClient?.GSTIN}</Text>
                            <Text className={customTheme.textnormal} sx={{ color: '#121212' }}>{selectedClient?.State}</Text>
                        </Box>

                    </Box>
                    {/* -------------------------- */}
                    
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        minHeight: '66px',
                        maxHeight: '66px',
                        height: '66px'
                    }}>

                    </Box>


                </Box>  



                {/* ----------------------- */}

            </Box>

            {UploadConfirmationDialog("")}
            {UploadSuccessDialog("")}

        </Box>

    )

}

export default ContactBulkCompany