import React from "react";
import { Box, Text, Button, Heading, Avatar, Label, ProgressBar, IconButton, StyledOcticon, Checkbox, ActionMenu, ActionList, Portal } from "@primer/react"
import { FilterIcon, PlusIcon, SortAscIcon, SortDescIcon } from '@primer/octicons-react';
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import { useDispatch, useSelector } from "react-redux";
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { getGanttData, getProjectServices } from "../redux/features/spaceSlice";
import TaskExpansion from "./TaskExpansion";
import { openCloseTaskExpansion } from "../redux/features/taskSlice";



export default function TimeLine(props) {

    const currentDate = new Date()
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskDataopenClose = useSelector((state) => state.taskData.openClose)
    const openCloseExpansion = useSelector((state) => state.taskData.openCloseExpansion)
    const taskTrigger = useSelector((state) => state.taskData.taskTrigger)

    const monthNames = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    const [compliances,setcompliances] = React.useState([])
    const [isAscending,setisAscending] = React.useState(false)
    const [dateList, setdateList] = React.useState([])
    const [monthList, setmonthList] = React.useState([])
    const [quaterMonthList, setquaterMonthList] = React.useState([])
    const [selectedCalType, setselectedCalType] = React.useState("Weeks")
    const [taskArray, settaskArray] = React.useState([])
    const [copyTaskArray,setcopyTaskArray] = React.useState([])
     
    const todayRef = React.useRef()
    const monthlyTodayRef = React.useRef()
    const quartersTodayRef = React.useRef()
    const ganttParentRef = React.useRef()

    function getNumberOfDaysBetweenDates(startDate, endDate) {

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), new Date(startDate).getDate());
        const secondDate = new Date(new Date(endDate).getFullYear(), new Date(endDate).getMonth(), new Date(endDate).getDate());
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays
    
    }
    function getDatesOfMonth(inputDate) {
        const date = new Date(inputDate); // Create a Date object from the input date
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const dates = [];
        for (let day = firstDay.getDate(); day <= lastDay.getDate(); day++) {
            const currentDate = new Date(year, month, day);
            dates.push(currentDate);
        }
    
        return dates;
    }
    
    function getDatesOfQuaterMonths(nstartDate, nendDate) {
    
        const startDate = new Date(nstartDate);
        const endDate = new Date(nendDate);
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth() + 1, 0);
        const dates = [];
        let currentDate = new Date(startDate);
    
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }
    
    function getRandomColor() {
    
         return "#586069"
    }
    


    function isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    }

    const generateDates = (props) => {

        const currentDate = new Date()  //new Date(new Date().setFullYear(new Date().getFullYear()-1)) 
        currentDate.setDate(1)
        currentDate.setMonth(currentDate.getMonth())
        currentDate.setFullYear(currentDate.getFullYear() - 1)

        const nextYearDate = new Date()
        nextYearDate.setDate(1)
        nextYearDate.setFullYear(nextYearDate.getFullYear() + 1)
        nextYearDate.setMonth(nextYearDate.getMonth())

        const dateList = [];

        for (let i = 0; i < 728 + (isLeapYear(currentDate.getFullYear()) ? 1 : 0); i++) {
            const newDate = new Date(currentDate);
            newDate.setDate(currentDate.getDate() + i);
            dateList.push(newDate);
        }

        console.log("dateList", dateList)

        return dateList

    }



    const generateMonthList = (props) => {

        const a = new Date()
        const currentDate = new Date(a.getFullYear() - 1, a.getMonth(), 1)
        const nextYearDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
        const datesArray = [];
        for (let i = 0; i <= 24; i++) {
            const currentMonthName = monthNames[currentDate.getMonth()];
            const currentDay = currentDate.getDate();
            const currentYear = currentDate.getFullYear();
            datesArray.push(`${currentMonthName} ${currentDay}, ${currentYear}`);
            currentDate.setMonth(currentDate.getMonth() + 1);

        }

        const newDateArray = datesArray.sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);

            return dateA - dateB;
        });


        return newDateArray

    }

    const generateQuarterMonthList = () => {


        const monthNames = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        const a = new Date();
        const currentDate = new Date(a.getFullYear() - 1, a.getMonth(), 1);
        const datesArray = [];

        for (let i = 0; i < 24; i += 3) {
            const currentQuarter = [];

            for (let j = 0; j < 3; j++) {
                const currentMonthName = monthNames[currentDate.getMonth()];
                const currentDay = currentDate.getDate();
                const currentYear = currentDate.getFullYear();
                currentQuarter.push(`${currentMonthName} ${currentDay}, ${currentYear}`);
                currentDate.setMonth(currentDate.getMonth() + 1);
            }

            datesArray.push(currentQuarter);
        }

        return datesArray;
    };

    const showEditTask = (props) => {


        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props.task_id
        }))


    }

    const handleSortAscendingDecending = (props) => {

  
        if(isAscending){


            const sortByDueDate = taskArray.sort(function (a, b) {

                if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
            })
            setisAscending(!isAscending)
            settaskArray(sortByDueDate)
            
        }else{

            const sortByDueDate = taskArray.sort(function (a, b) {

                if (new Date(b.due_date) > new Date(a.due_date)) { return -1; }
            })
            setisAscending(!isAscending)
            settaskArray(sortByDueDate)
        } 

         


    }

    const handleFilterTask = (props) => {

            

            if(props==="All"){

                   settaskArray(copyTaskArray)
            }else{

                   settaskArray((prevState)=>{
                       
                        const newState = prevState.filter((tData)=> tData.compliance_id === props)
                        return newState
                   })    
            }

    }

    

    const getGanttDataFromDb = (props) => {

        dispatch(getGanttData({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {
            console.log("getGanttData", data.payload)

            const tasks = [...data.payload.data]
            settaskArray(tasks)
            setcopyTaskArray(tasks)
           // settaskArray(data.payload.data)
        })

    }

    const getAllCategories = (props) => {

        

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setcompliances(data?.payload.data)


        })

    }


    React.useEffect(()=>{

        const portalRoot = document.getElementById('__primerPortalRoot__');

        if (portalRoot) {
          portalRoot.style.zIndex = 5;
        }

    },[])
 

    React.useState(async() => {


        

        getAllCategories("")
        getGanttDataFromDb("")
        setdateList(generateDates(""))
        setmonthList(generateMonthList(""))
        setquaterMonthList(generateQuarterMonthList(""))


    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet><title>{localStorage.getItem("projectName") || ""} - Roadmap</title></Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
               // overflowY={"scroll"}
                overflowX={"hidden"}
            >


                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '24px',
                        height: '30px',
                        minHeight: '30px',
                        maxHeight: '30px',
                        fontFamily: 'Inter',
                        display: 'flex',
                        flexDirection: 'row',

                    }}
                     
                >

                    <Text sx={{
                        fontFamily: 'Inter',
                        color: '#24292E',
                        fontSize: '16px',
                        fontWeight: '600',
                        fontStyle:'normal',
                        lineHeight: '24px',
                    }}>
                        Timeline
                    </Text>

                     
                </Box>

                <Box 
                    sx={{
                        position: 'absolute',
                        right: '100px',
                        top: '24px',
                        display: 'flex',
                        flexDirection: 'row',
                        
                    }}
                
                >

                      
                 
                  
                   
                        <ActionMenu>
                            <ActionMenu.Anchor>

                                <IconButton
                                    sx={{
                                        position: 'relative',
                                        margin: '0px 14px 0px auto',
                                        border: 'none',
                                        color: '#57606A',
                                        boxShadow: 'none',
                                    }}
                                    icon={FilterIcon}
                                />
                            </ActionMenu.Anchor>


                            <ActionMenu.Overlay align="end" >

                                <ActionList

                                    sx={{
                                        maxHeight: '400px',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400',
                                    }}
                                >
                                    <ActionList.Item onSelect={() => handleFilterTask("All")}>
                                        {"All"}
                                    </ActionList.Item>
                                    {compliances.map((row, index) => {
                                        return (
                                            <>
                                                <ActionList.Item
                                                    onSelect={() => handleFilterTask(row.subcategory.compliance_id)}

                                                >
                                                    {row.subcategory.compliance_name}
                                                </ActionList.Item>
                                            </>

                                        )
                                    })}
                                </ActionList>

                            </ActionMenu.Overlay>


                        </ActionMenu>
                   
                    
                    
                    <IconButton
                        sx={{
                            position: 'relative',
                            margin: '0px 20px 0px auto',
                            border: 'none',
                            color: '#57606A',
                            boxShadow: 'none',
                        }}
                        icon={isAscending === true ? SortDescIcon : SortAscIcon}
                        onClick={() => handleSortAscendingDecending("")}
                    />
  
                </Box>



                <Box
                    ref={ganttParentRef}
                    sx={{
                        fontFamily: 'Inter',
                        position: 'absolute',
                        left: '26px',
                        top: '64px',
                        bottom: '0px',
                        right: '0px',
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                        borderRadius: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        background: '#EBEBEB',
                      
                    }}
                >

                    <Box
                       
                        sx={{
                            position: 'fixed',
                            right: '50px',
                            bottom: '14px',
                            display: 'flex',
                            zIndex: '2',
                            boxShadow: 'shadow.medium',
                            height:'38px',
                            minHeight:'38px',
                            maxHeight:'38px',
                            width:'324px',
                            minWidth:'324px',
                            maxWidth:'324px',
                            alignItems:'center',
                            paddingLeft:'29px',
                            paddingRight:'29px',
                            paddingTop:'10px',
                            paddingBottom:'10px'
                        }}
                    >

                        <Button 
                        
                        sx={{
                            background:"white",
                            color:"#24292E",
                            fontFamily:'Inter',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:'500',
                            lineHeight:'18px' ,
                            border:'none'      
                        }}

                            onClick={() => {

                                if(selectedCalType==="Weeks"){

                                    if (todayRef.current) {


                                        todayRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
            
                                    }


                                }else if(selectedCalType==="Months"){

                                    if (monthlyTodayRef.current) {


                                        monthlyTodayRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
            
                                    }
                                    
                                }else if(selectedCalType==="Quarters"){

                                    if(quartersTodayRef.current){

                                        quartersTodayRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
                                    }

                                }

                               
                            }}
                        >Today
                        </Button>
                        
                        <Button sx={{
                            background: selectedCalType === "Weeks" ? "#DDF4FF !important" : "white",
                            color: selectedCalType === "Weeks" ? "#0969DA !important" : "#24292E",
                            ':hover': {
                                background: '#DDF4FF !important',
                                color: '#0969DA !important',
                            },
                            fontFamily:'Inter',
                            fontSize:'12px',
                            fontStyle:'normal',
                            fontWeight:'500',
                            lineHeight:'18px',
                            border:'none'    
                        }}

                            onClick={() => {
                                setselectedCalType("Weeks")
                            }}
                        >Weeks
                        </Button>

                        <Button
                            sx={{
                                background: selectedCalType === "Months" ? "#DDF4FF" : "white",
                                color: selectedCalType === "Months" ? "#0969DA" : "#24292E",
                                ':hover': {
                                    background: '#DDF4FF !important',
                                    color: '#0969DA !important',
                                },
                                fontFamily:'Inter',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:'500',
                                lineHeight:'18px',
                                border:'none'        
                            }}
                            onClick={() => {
                                setselectedCalType("Months")
                            }}
                        >Months
                        </Button>
                        <Button
                            sx={{
                                background: selectedCalType === "Quarters" ? "#DDF4FF" : "white",
                                color: selectedCalType === "Quarters" ? "#0969DA" : "#24292E",
                                ':hover': {
                                    background: '#DDF4FF !important',
                                    color: '#0969DA !important',
                                },
                                fontFamily:'Inter',
                                fontSize:'12px',
                                fontStyle:'normal',
                                fontWeight:'500',
                                lineHeight:'18px',
                                border:'none'        
                            }}
                            onClick={() => {
                                setselectedCalType("Quarters")
                            }}
                        >Quarters
                        </Button>
                    </Box>


                    <Box 
                   
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        overflow: 'auto',
                        
                    }}>
                        {/* Weekly  Componet */}

                        <Box
                            sx={{
                                display: selectedCalType === "Weeks" ? 'flex' : 'none',
                                flexDirection: 'row',
                                gap: '1px',
                                maxHeight: '55px',
                                minHeight: '55px',
                                height: '55px',
                                width: 'fit-content',
                                position:'sticky',
                                top:'0px',
                                background:'#EBEBEB',
                                zIndex:openCloseExpansion===true ? '1':'3'
                              
                            }}
                        >

                            {dateList.map((row, index) => {
                                return (
                                    <>
                                        <Box
                                            ref={new Date().toDateString() === new Date(row).toDateString() ? todayRef : null}
                                            sx={{
                                                background: 'orange',
                                                position: 'relative',
                                                left: '16px',
                                                top: '0px',
                                                margin: '0px 0px 0px 0px',
                                                // height: taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight}px` :`${taskArray.length * 38}px`,
                                                // minHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight}px` : `${taskArray.length * 38}px`,
                                                // maxHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight}px` : `${taskArray.length * 38}px`,


                                                height:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                minHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                maxHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,

                                                minWidth: '2px',
                                                maxWidth: '2px',
                                                width: '2px',
                                                display: new Date().toDateString() === new Date(row).toDateString() ? 'flex' : 'none'
                                            }}
                                        >
                                        </Box>
                                           
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            width: '30px',
                                            minWidth: '30px',
                                            maxWidth: '30px',

                                        }}>
                                            <Box
                                                sx={{
                                                    display: index % 7 === 0 ? 'flex' : 'none',
                                                    width: `${216}px`,
                                                    position: 'relative',
                                                    margin: '0px 0px auto 0px',
                                                    height: '100%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRight: index % 7 === 0 ? '1px solid #DFE1E6' : '0px solid red',
                                                    //  borderBottom: index % 7 === 0 ? '1px solid #DFE1E6' : '0px solid red',
                                                    fontFamily: 'Inter',
                                                    fontSize: '11px'
                                                }}
                                            >
                                                {/* {monthNames[new Date(dateList[index + 6]).getMonth()] + " '" + new Date(dateList[index + 6]).getFullYear()} */}
                                                {(monthNames[new Date(dateList[index + 6]).getMonth()])?.substring(0, 3).toUpperCase()}
                                            </Box>


                                            <Box
                                                //  ref={new Date().toDateString() === new Date(row).toDateString() ? todayRef:null}
                                                sx={{
                                                    minHeight: '50%',
                                                    position: 'relative',
                                                    margin: 'auto 0px 0px 0px',
                                                    borderBottom: '1px solid #DFE1E6',
                                                    fontFamily: 'Inter',
                                                    fontSize: '11px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {new Date(row).getDate()}
                                            </Box>

                                        </Box>
                                    </>
                                )
                            })}


                        </Box>

                        <Box
                            sx={{
                                display: selectedCalType === "Weeks" ? 'flex' : 'none',
                                flexDirection: 'column',
                                gap: '6px',
                            }}>
                            {taskArray.map((tData, tIndex) => {

                                return (

                                    <Box
                                        onClick={() => showEditTask(tData)}
                                        sx={{

                                            zIndex: '1',
                                            cursor: 'pointer',
                                            minHeight: '24px',
                                            maxHeight: '24px',
                                            height: '24px',
                                            background: getRandomColor(""),
                                            marginLeft: `${parseInt(getNumberOfDaysBetweenDates(new Date(dateList[0]), new Date(tData.created_timestamp))) * 31}px`,
                                            width: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date))) * 31}px`,
                                            maxWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date))) * 31}px`,
                                            minWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date))) * 31}px`,
                                            borderRadius: '3px',
                                            color: 'white',
                                            paddingLeft: '12px',
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px'
                                        }}
                                    >
                                        <Text sx={{ width: '99%', maxWidth: '99%', minWidth: '99%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                            {tData.task_name + "  "}
                                        </Text> 
                                         
                                       
                                    </Box>
                                )

                            })}
                        </Box>
                    
                        

                        {/* ------------------ */}


                        {/* Monthly components */}

                        <Box
                            sx={{
                                display: selectedCalType === "Months" ? 'flex' : 'none',
                                flexDirection: 'row',
                                gap: '1px',
                                maxHeight: '55px',
                                minHeight: '55px',
                                height: '55px',
                                width: 'fit-content',
                                position:'sticky',
                                top:'0px',
                                background:'#EBEBEB',
                                zIndex:openCloseExpansion===true ? '1':'3'
                            }}
                        >

                            {monthList.map((row, index) => {
                                return (
                                    <>
                                        
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '263px',
                                            height: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            minWidth: '263px',
                                            maxWidth: '263px',
                                            borderRight: '1px solid #DFE1E6',
                                            borderBottom: '1px solid #DFE1E6',
                                            position:'relative'
                                        }}>
                                            <Text sx={{  fontFamily: 'Inter',position: 'absolute', left: '35%', right: '35%', margin: 'auto',fontSize:'12px' }}>

                                                {
                                                    new Date().getFullYear().toString().trim()  === row.split(",")[1].substring(0, 5).trim() 
                                                    ? 
                                                    row.substring(0, 3).toUpperCase()+""
                                                    :
                                                    row.substring(0, 3).toUpperCase() + " '" + row.split(",")[1].substring(3, 5)
                                                }

                                                
                                            </Text>
                                            {
                                                [...getDatesOfMonth(new Date(row))].map((row2, index2) => {

                                                                 
                                                        return(
                                                            <Box
                                                                
                                                                sx={{
                                                                    display:new Date(row2).toDateString()===new Date().toDateString()?'flex':'none',
                                                                    width:`${263/[...getDatesOfMonth(new Date(row))].length}px`,
                                                                    minWidth:`${263/[...getDatesOfMonth(new Date(row))].length}px`,
                                                                    maxWidth:`${263/[...getDatesOfMonth(new Date(row))].length}px`,
                                                                }}
                                                            >
                                                                <Box
                                                                    ref={new Date(row2).toDateString()===new Date().toDateString()?monthlyTodayRef:null}
                                                                    sx={{
                                                                        background: 'orange',
                                                                    
                                                                        // height: taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` :`${taskArray.length * 130}px`,
                                                                        // minHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` : `${taskArray.length * 130}px`,
                                                                        // maxHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` : `${taskArray.length * 130}px`,
                                                                       

                                                                        position:'absolute',
                                                                        top:'0px',

                                                                        height:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                                        minHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                                        maxHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                                       
                                                                        minWidth: '2px',
                                                                        maxWidth: '2px',
                                                                        width: '2px',
                                                                        display:'flex'
                                                                    }}
                                                                >

                                                                </Box> 
                                                            </Box>
                                                        )
                                                   
                                                
                                                })
                                            }
                                        </Box>
                                    </>
                                )
                            })}
                        </Box>

                        <Box sx={{
                            display: selectedCalType === "Months" ? 'flex' : 'none',
                            flexDirection: 'column',
                            gap: '8px',
                            width:'fit-content',
                          
                        }}>

                            
                            {taskArray.map((tData, tIndex) => {


                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '1px',
                                            maxHeight: '55px',
                                            minHeight: '55px',
                                            height: '55px',
                                            
                                        }}
                                    >

                                        {monthList.map((row, index) => {


                                              if(new Date(tData.created_timestamp).getMonth()===new Date(row).getMonth()&&new Date(tData.created_timestamp).getFullYear()===new Date(row).getFullYear())  
                                              {

                                                return (

                                                  

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '263px',
                                                        height: '55px',
                                                        minHeight: '55px',
                                                        maxHeight: '55px',
                                                        minWidth: '263px',
                                                        maxWidth: '263px',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        paddingTop: '8px',
                                                        borderRight: '1px solid #DDE6ED',
                                                        borderBottom: '1px solid #DDE6ED',
                                                    }}>
                                                      
                                                        {
                                                            [...getDatesOfMonth(new Date(row))].map((row2, index2) => {

                                                                 
                                                                 if(new Date(row2).getDate()===new Date(tData.created_timestamp).getDate()){
                                                                    return(
                                                                        <Box sx={{
                                                                            fontSize: '8px',
                                                                            minWidth: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            maxWidth: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            width: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            minHeight: '100%',
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            alignItems:'center',
                                                                           
                                                                        }}>
                                                                          
                                                                            <Box
                                                                                onClick={() => showEditTask(tData)}
                                                                                sx={{
                                                                                    zIndex: '1',
                                                                                    cursor: 'pointer',
                                                                                    minHeight: '24px',
                                                                                    maxHeight: '24px',
                                                                                    height: '24px',
                                                                                    background: getRandomColor(""),
                                                                                    width: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfMonth(new Date(row))].length)}px`,
                                                                                    maxWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfMonth(new Date(row))].length)}px`,
                                                                                    minWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfMonth(new Date(row))].length)}px`,
                                                                                    borderRadius: '3px',
                                                                                    color: 'white',
                                                                                    paddingLeft: '8px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    
                                                                                }}
                                                                            >
                                                                                <Text sx={{
                                                                                    minHeight: '20px',
                                                                                    maxHeight: '20px',
                                                                                    height: '20px',
                                                                                    width: '95%', 
                                                                                    maxWidth: '95%', 
                                                                                    minWidth: '95%', 
                                                                                    overflow: 'hidden', 
                                                                                    whiteSpace: 'nowrap', 
                                                                                    textOverflow: 'ellipsis',
                                                                                    display:'flex',
                                                                                    alignItems:'center',
                                                                                    fontFamily: 'Inter',
                                                                                    fontSize: '12px',
                                                                                }}>
                                                                                    {tData.task_name}
                                                                                </Text>


                                                                            </Box>
                                                                            
                                                                           
                                                                        </Box>
                                                                     )
                                                                 }else{
                                                                    return(
                                                                        <Box sx={{
                                                                            fontSize: '8px',
                                                                            minWidth: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            maxWidth: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            width: `${263 / [...getDatesOfMonth(new Date(row))].length}px`,
                                                                            minHeight: '100%',
                                                                            height: '100%',
                                                                            display: 'flex',
                                                                            alignItems:'center',
                                                                            
                                                                        }}>
                                                                            
                                                                            
                                                                           
                                                                        </Box>
                                                                     )
                                                                 }
                                                                  
                                                                

                                                                

                                                            })
                                                        }


                                                    </Box>
                                                    )

                                              }else{
                                                   return(
                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '263px',
                                                        height: '55px',
                                                        minHeight: '55px',
                                                        maxHeight: '55px',
                                                        minWidth: '263px',
                                                        maxWidth: '263px',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        paddingTop: '8px',
                                                        borderRight: '1px solid #DDE6ED',
                                                        borderBottom: '1px solid #DDE6ED',
                                                    }}>
                                                        
                                                    </Box>
                                                   )
                                              }
                                               
 
 
                                            
                                        })}
                                    </Box>
                                 )

                                
                         })}

                        </Box>   

                        

                        {/* Quarters components */}

                        <Box
                            sx={{
                                display: selectedCalType === "Quarters" ? 'flex' : 'none',
                                flexDirection: 'row',
                                gap: '1px',
                                maxHeight: '55px',
                                minHeight: '55px',
                                height: '55px',
                                width: 'fit-content',
                                position:'sticky',
                                top:'0px',
                                background:'#EBEBEB',
                                zIndex:openCloseExpansion===true ? '1':'3'
                            }}
                        >

                            {quaterMonthList.map((row, index) => {
                                return (
                                    <>

                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '263px',
                                            height: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            minWidth: '263px',
                                            maxWidth: '263px',
                                            borderRight: '1px solid #DDE6ED',
                                            borderBottom: '1px solid #DDE6ED',
                                            position:'relative'
                                        }}>
                                             <Text
                                             sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                position: 'absolute', 
                                                left: '20%', 
                                                right: '20%', 
                                                margin: 'auto' 
                                                
                                             }}
                                             >
                                            
                                             {/* {row[0].substring(0, 3) +" "+new Date(row[0]).getFullYear()+" - " + row[2].substring(0, 3)+" "+new Date(row[2]).getFullYear()}
                                            */}

                                             {
                                             
                                             new Date().getFullYear()===new Date(row[0]).getFullYear() ?
                                             row[0].substring(0, 3).toUpperCase() + " - " + row[2].substring(0, 3).toUpperCase()
                                             :
                                             row[0].substring(0, 3).toUpperCase() + " - " + row[2].substring(0, 3).toUpperCase() + "  '" + new Date(row[0]).getFullYear().toString().substring(2,4)
                                             }
                                             


                                             </Text>  
                                            {
                                                [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].map((row2, index2) => {

                                                       
                                                    if (new Date(row2).toDateString() === new Date().toDateString()) {

                                                        return (
                                                            <Box sx={{
                                                                width: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}`,
                                                                 
                                                            }}>
                                                               <Box
                                                                    ref={new Date(row2).toDateString() === new Date().toDateString()?quartersTodayRef:null}
                                                                    sx={{
                                                                        background: 'orange',
                                                                       // height: '200px',
                                                                       // minHeight: `${taskArray.length * 130}px`,
                                                                       
                                                                        // height: taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` :`${taskArray.length * 130}px`,
                                                                        // minHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` : `${taskArray.length * 130}px`,
                                                                        // maxHeight:taskArray.length <= 16 ? `${ganttParentRef?.current?.clientHeight+800}px` : `${taskArray.length * 130}px`,
                                                                       
                                                                        position:'absolute',
                                                                        top:'0px',

                                                                        height:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                                        minHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,
                                                                        maxHeight:`${ganttParentRef?.current?.getBoundingClientRect()?.height}px`,

                                                                        minWidth: '2px',
                                                                        maxWidth: '2px',
                                                                        width: '2px',
                                                                        display:'flex'
                                                                    }}
                                                                >

                                                                </Box> 
                                                            </Box>
                                                        )

                                                    } 
                                                      
                                                       


                                                })

                                            } 


                                        </Box>
                                    </>
                                )
                            })}
                        </Box>


                        <Box
                            sx={{
                                display: selectedCalType === "Quarters" ? 'flex' : 'none',
                                flexDirection:'column',
                                gap: '8px',
                                width:'fit-content',
                                
                            }}
                        >
                            {taskArray.map((tData, tIndex) => {

                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '1px',
                                            maxHeight: '55px',
                                            minHeight: '55px',
                                            height: '55px',
                                            width: 'fit-content'
                                        }}
                                    >

                                        {quaterMonthList.map((row, index) => {


                                            if (
                                              (new Date(tData.created_timestamp).getFullYear() > new Date(row[0]).getFullYear() || (new Date(tData.created_timestamp).getFullYear() === new Date(row[0]).getFullYear() && new Date(tData.created_timestamp).getMonth() >= new Date(row[0]).getMonth())) &&
                                              (new Date(tData.created_timestamp).getFullYear() < new Date(row[2]).getFullYear() || (new Date(tData.created_timestamp).getFullYear() === new Date(row[2]).getFullYear() && new Date(tData.created_timestamp).getMonth() <= new Date(row[2]).getMonth()))
                                             ) {

                                                return (
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '263px',
                                                        height: '100%',
                                                        minHeight: '100%',
                                                        maxHeight: '100%',
                                                        minWidth: '263px',
                                                        maxWidth: '263px',
                                                        borderRight: '1px solid #DDE6ED',
                                                        borderBottom: '1px solid #DDE6ED',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                        
                                                    }}>

                                                        {[...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].map((row2, index2) => {


                                                            if (new Date(row2).toLocaleDateString() === new Date(tData.created_timestamp).toLocaleDateString()) {

                                                                return (
                                                                    <Box sx={{
                                                                        fontSize: '8px',
                                                                        minWidth: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                        maxWidth: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                        width: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                      
                                                                        minHeight: 'auto',
                                                                        height: 'auto',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: '8px',
                                                                        
                                                                    }}>
                                                                        
                                                                        <Box
                                                                            onClick={() => showEditTask(tData)}
                                                                            sx={{
                                                                                zIndex: '1',
                                                                                cursor: 'pointer',
                                                                                minHeight: '24px',
                                                                                maxHeight: '24px',
                                                                                height: '24px',
                                                                                background: getRandomColor(""),
                                                                                width: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                maxWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                minWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                borderRadius: '3px',
                                                                                color: 'white',
                                                                                paddingLeft: '8px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',

                                                                            }}
                                                                        >
                                                                            <Text sx={{
                                                                                minHeight: '20px',
                                                                                maxHeight: '20px',
                                                                                height: '20px',
                                                                                width: '95%',
                                                                                maxWidth: '95%',
                                                                                minWidth: '95%',
                                                                                overflow: 'hidden',
                                                                                whiteSpace: 'nowrap',
                                                                                textOverflow: 'ellipsis',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                fontFamily: 'Inter',
                                                                                fontSize: '12px',
                                                                            }}>
                                                                                {tData.task_name}
                                                                            </Text>


                                                                        </Box>
                                                                        
                                                                    </Box>
                                                                )

                                                            } else {

                                                                return (
                                                                    <Box sx={{
                                                                        fontSize: '8px',
                                                                        minWidth: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                        maxWidth: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                        width: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                                        minHeight: 'auto',
                                                                        height: 'auto',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: '8px',
                                                                        
                                                                    }}>
                                                                         
                                                                    </Box>
                                                                )
                                                            }



                                                        })}
                                                        
                                                    </Box>
                                                )

                                            } else {

                                                return (
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '263px',
                                                        height: '100%',
                                                        minHeight: '100%',
                                                        maxHeight: '100%',
                                                        minWidth: '263px',
                                                        maxWidth: '263px',
                                                        borderRight: '1px solid #DDE6ED',
                                                        borderBottom: '1px solid #DDE6ED',
                                                        fontFamily: 'Inter',
                                                        fontSize: '14px',
                                                    }}>
                                                           
                                                   
                                                    </Box>
                                                )
                                            }    
                                           
                                                    
                                                    
                                                 
                                                
                                              
                                                
                                        })
                                        
                                        }
                                    </Box>
                                )


                            })}

                        </Box>


                        <Box
                            style={{display:'none'}}
                            sx={{
                                display: selectedCalType === "Quarters" ? 'flex' : 'none',
                                flexDirection: 'row',
                                gap: '1px',
                                maxHeight: 'auto',
                                minHeight: 'auto',
                                height: 'auto',
                                width: 'fit-content',
                                padding: '8px 0px 0px 0px'
                            }}
                        >

                            {quaterMonthList.map((row, index) => {
                                return (
                                    <>

                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '263px',
                                            height: 'auto',
                                            minHeight: 'auto',
                                            maxHeight: 'auto',
                                            minWidth: '263px',
                                            maxWidth: '263px',
                                            borderRight: '1px solid #DDE6ED',
                                            borderBottom: '1px solid #DDE6ED',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',

                                        }}>
                                            {/* {new Date(row[0]).toDateString()+ " - " +new Date(row[2]).toDateString()} */}
                                            {[...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].map((row2, index2) => {


                                                return (
                                                    <Box sx={{
                                                        fontSize: '8px',
                                                        minWidth: `${263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length}px`,
                                                        minHeight: 'auto',
                                                        height: 'auto',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '8px'
                                                    }}>

                                                        {
                                                            taskArray
                                                                .filter((tData) => new Date(row2).toDateString() === new Date(tData.created_timestamp).toDateString())
                                                                .map((tData, tIndex) => {

                                                                    return (
                                                                        <Box
                                                                            onClick={() => showEditTask(tData)}
                                                                            sx={{
                                                                                zIndex: '1',
                                                                                cursor: 'pointer',
                                                                                minHeight: '24px',
                                                                                maxHeight: '24px',
                                                                                height: '24px',
                                                                                background: getRandomColor(""),
                                                                                width: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                maxWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                minWidth: `${parseInt(getNumberOfDaysBetweenDates(new Date(tData.created_timestamp), new Date(tData.due_date)) + 1) * (263 / [...getDatesOfQuaterMonths(new Date(row[0]), new Date(row[2]))].length)}px`,
                                                                                borderRadius: '3px',
                                                                                color: 'white',
                                                                                paddingLeft: '8px',
                                                                                fontFamily: 'Inter',
                                                                                fontSize: '12px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',

                                                                            }}
                                                                        >
                                                                            <Text sx={{
                                                                                minHeight: '20px',
                                                                                maxHeight: '20px',
                                                                                height: '20px',
                                                                                width: '95%',
                                                                                maxWidth: '95%',
                                                                                minWidth: '95%',
                                                                                overflow: 'hidden',
                                                                                whiteSpace: 'nowrap',
                                                                                textOverflow: 'ellipsis',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                fontFamily: 'Inter',
                                                                                fontSize: '12px',
                                                                            }}>
                                                                                {tData.task_name}
                                                                            </Text>


                                                                        </Box>
                                                                    )
                                                                })


                                                        }



                                                    </Box>
                                                )


                                            })}



                                        </Box>
                                    </>
                                )
                            })}


                        </Box>



                        {/* ------------------ */}

                    </Box>


                </Box>



            </Box>

            <TaskExpansion />

        </Box>
    )


}