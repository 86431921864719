
import {Box,Spinner} from "@primer/react"

function LoadingSpinner(props){

   if (props.showSpinner === true) {

      return (<Box sx={{
         position: 'absolute',
         left: '50%',
         top: '50%',
         transform: 'translate(-50%, -50%)',
         zIndex: 100
      }}>
         <Spinner />
      </Box>)


   } else {

      return (<Box sx={{
         display: 'none',
         position: 'absolute',
         left: '50%',
         top: '50%',
         transform: 'translate(-50%, -50%)',
         zIndex: 100
      }}>
         <Spinner />
      </Box>)
   }
      
    
     
}

export default LoadingSpinner




 