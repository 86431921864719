import React from "react"
import { CheckIcon, KebabHorizontalIcon } from "@primer/octicons-react"
import { Box,Checkbox,IconButton,Label,Text } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch, useSelector } from "react-redux"
import {getProjectServices, getUserOrganizationById } from "../redux/features/spaceSlice"
import { UnderlineNav } from '@primer/react/drafts'
import { useNavigate } from "react-router-dom"
import { getFeedData, isFeedDueTasksStatus, isFeedOpenTasksStatus } from "../redux/features/feedSlice"

function FeedLatest(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail) 
    const isFeedDueTasks = useSelector((state) => state.feedData.isFeedDueTasks)
    const isFeedOpenTasks = useSelector((state) => state.feedData.isFeedOpenTasks) 
    
   
    const [navListKey,setnavListKey] = React.useState("1")
    const [compliances, setcompliances] = React.useState([])
    const [selectedIndex,setSelectedIndex] = React.useState(0)
    const [selectedComplianceId,setselectedComplianceId] = React.useState("All")
    const [allTasks,setallTasks] = React.useState([])
    const [openTaskArray,setopenTaskArray] = React.useState([])
    const [overdueTaskArray,setoverdueTaskArray] = React.useState([])
    
    const getAllCategories = (props) => {

       
        //get All Compliances By Project id
        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: props
        })).then((data) => {

           
            const sortedArray = data?.payload.data?.sort((a, b) => {
                const nameA = a.subcategory.compliance_name.toLowerCase();
                const nameB = b.subcategory.compliance_name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                } else if (nameA > nameB) {
                    return 1;
                } else {
                    return 0;
                }
            })
            console.log("sortedArray",sortedArray)
            setcompliances(sortedArray)
            setnavListKey("1")
        })

    }

    const getAllTaskFromDb = (props) => {

        //get All Works by Project Id
        dispatch(getFeedData({
            userId: userId,
            userEmail: userEmail,
            projectId: '',
            workflowId:''
        })).then((data) => {

            
             const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
             })
             const newSortedTasks = [...sortedTaskArray].map((row, index) => {
                return ({ ...row, isChecked: false })
             })
             console.log("allTasksFeed", newSortedTasks)
             console.log("getFeedData",data.payload)
             const overdueTaskIds = [...data.payload.overdueTaskIds]
             const openTaskIds = [...data.payload.openTaskIds]
            
          //   setoverdueTaskArray(overdueTaskIds)
           //  setopenTaskArray(openTaskIds)
             
            if(props==="DueTask"){

                setallTasks(overdueTaskIds)
                setnavListKey("2")
 
            }else if(props==="OpenTask"){

                setallTasks(openTaskIds)
                setnavListKey("2")
 
            } else {

                setallTasks(newSortedTasks)
                setnavListKey("2")


            }

            
        })

    }

    


    React.useState(() => {

        getAllTaskFromDb("")
        //get User Selected Organization id
         

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            const projectId = data?.payload?.data[0]?.project_id
            //get Compliance from Database
            getAllCategories(projectId)
            //get All Feed task from Database


        })

        
      
    })

    React.useEffect(() => {


       
     
        if (isFeedDueTasks === true) {
           
            getAllTaskFromDb("DueTask")
            setSelectedIndex(1)
 
        } else {
            
        }


    }, [isFeedDueTasks])

    React.useEffect(()=> {

         if (isFeedOpenTasks === true) {

            getAllTaskFromDb("OpenTask")
            setSelectedIndex(1)

         }else {
            

         }



    },[isFeedOpenTasks])

    
    

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    Feed - deski
                </title>
            </Helmet>

            <Header />
            <SideNav />

            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden',
                background:'#ffffff'
                
            }}>
         
                <Box sx={{
                    position:'absolute',
                    left:'26px',
                    top:'18px',
                    minHeight:'48px',
                    maxHeight:'48px',
                    height:'48px',
                    right:'61px',
                    display:'flex',
                    flexDirection:'row'
                }}>
                    <Text sx={{
                         fontFamily:'Inter',
                         fontSize:'32px',
                         fontWeight:'700',
                         color:'#24292E',
                         lineHeight:'48px'
                    }}>Feed
                    
                    </Text>

                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '74px',
                        right: '61px',
                        minHeight: '40px',
                        maxHeight: '40px',
                        height: '40px',
                        borderBottom: '1px solid #E5E9EB',
                        zIndex: '1',
                        "li": {
                            display: 'flex',
                            alignItems: 'center'
                        },
                    }}>
                   
                    

                    <UnderlineNav
                      //  key={"" + Math.random(100, 10000) + ""}
                        key={navListKey}
                        aria-label="Repository"
                        sx={{
                            minHeight: 40,
                            maxHeight: 40,
                            height: 40,
                            marginTop: '-3px',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#24292E',
                            paddingLeft: '0px',
                            border: 'none',
                            
                        }}
                    >

                        <UnderlineNav.Item
                            sx={{
                                minWidth: '89px',
                                maxWidth: '89px',
                                width: '89px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                            }}
                            key={"all"}
                            aria-current={0 === selectedIndex ? 'page' : undefined}
                            onSelect={e => {
                                setSelectedIndex(0)
                                setnavListKey("1")
                                e.preventDefault()
                                setselectedComplianceId("All")
                                getAllTaskFromDb("")
                            }}
                            selected={0 === selectedIndex}
                        >
                            {"All"}
                        </UnderlineNav.Item>

                        
                        <>
                        
                       
                        <UnderlineNav.Item
                            sx={{

                                minWidth: '89px',
                                maxWidth: '89px',
                                width: '89px',
                                display: isFeedDueTasks===true || isFeedOpenTasks===true ? 'flex' : 'none',
                                alignItems: 'center',
                                justifyContent: 'center',

                            }}
                            key={"duetask"}
                            aria-current={1 === selectedIndex ? 'page' : undefined}
                            onSelect={e => {
                                setSelectedIndex(1)
                                setnavListKey("2")
                                e.preventDefault()
                                setselectedComplianceId("All")

                                if(isFeedDueTasks===true){

                                    dispatch(isFeedDueTasksStatus({
                                        isFeedDueTasks: true
                                    }))

                                }else if(isFeedOpenTasks===true){

                                    dispatch(isFeedOpenTasksStatus({
                                        isFeedOpenTasks: true
                                    }))

                                }

                                
                               
                            }}
                            selected={1 === selectedIndex}
                        >
                           {isFeedDueTasks===true ? "Due task" :"Open task"}  
                        </UnderlineNav.Item>
                        </>
                        

                       
                         

                        {
                        
                        compliances.map((item,index) => (

                             isFeedOpenTasks===true ? 
                            
                                <UnderlineNav.Item
                                    sx={{
                                        minWidth: 'auto',
                                        maxWidth: 'auto',
                                        width: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                    key={item.subcategory.compliance_id}
                                    aria-current={index + 2 === selectedIndex ? 'page' : undefined}
                                    onSelect={e => {
                                        setSelectedIndex(index + 2)
                                        setselectedComplianceId(item.subcategory.compliance_id)
                                        e.preventDefault()
                                        getAllTaskFromDb("")
                                    }}
                                    selected={index + 2 === selectedIndex}
                                >
                                    {item.subcategory.compliance_name}
                                </UnderlineNav.Item>
                             :
                             isFeedDueTasks===true ? 
                            
                                <UnderlineNav.Item
                                    sx={{
                                        minWidth: 'auto',
                                        maxWidth: 'auto',
                                        width: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                    key={item.subcategory.compliance_id}
                                    aria-current={index + 2 === selectedIndex ? 'page' : undefined}
                                    onSelect={e => {
                                        setSelectedIndex(index + 2)
                                        setselectedComplianceId(item.subcategory.compliance_id)
                                        e.preventDefault()
                                        getAllTaskFromDb("")
                                    }}
                                    selected={index + 2 === selectedIndex}
                                >
                                    {item.subcategory.compliance_name}
                                </UnderlineNav.Item>
                             : 
                             
                             

                                <UnderlineNav.Item
                                    sx={{
                                        minWidth: 'auto',
                                        maxWidth: 'auto',
                                        width: 'auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                    key={item.subcategory.compliance_id}
                                    aria-current={index + 1 === selectedIndex ? 'page' : undefined}
                                    onSelect={e => {
                                        setSelectedIndex(index + 1)
                                        setselectedComplianceId(item.subcategory.compliance_id)
                                        e.preventDefault()
                                        getAllTaskFromDb("")
                                    }}
                                    selected={index + 1 === selectedIndex}
                                >
                                    {item.subcategory.compliance_name}

                                </UnderlineNav.Item>

                        ))
                        
                        }
                    </UnderlineNav>
                </Box>

                 <Box sx={{
                       position:'absolute',
                       left:'26px',
                       top:'130px',
                       right:'61px',
                       display:'flex',
                       flexDirection:'column',
                       paddingBottom:'90px'
                 }}>

                       <Box sx={{
                          
                          display:'flex',
                          flexDirection:'row',
                          minHeight:'40px',
                          maxHeight:'40px',
                          height:'40px',
                          width:'100%',
                          minWidth:'100%',
                          maxWidth:'100%',  
                          background:'white',
                          borderBottom:'1px solid #E5E9EB'
                       }}>
                        <Box sx={{
                            width: '3.73%',
                            minWidth: '3.73%',
                            maxWidth: '3.73%',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center'
                        }}>
                            <Checkbox
                                onChange={(e) => {
                                    setallTasks((prevState) => {
                                        const newState = prevState.map((row) => {
                                            return ({ ...row, isChecked: e.target.checked })
                                        })

                                        return (newState)
                                    })
                                }}
                            />
                        </Box> 
                        <Box sx={{
                            width: '28.1%',
                            minWidth: '28.1%',
                            maxWidth: '28.1%',
                            display:'flex',
                            alignItems:'center'
                        }}>
                            <Text sx={{
                                marginLeft:'12px',
                                fontFamily:'Inter',
                                fontWeight:'600',
                                fontSize:'12px',
                                textTransform:'uppercase', 
                                color:'#586069'
                            }}>Task</Text>
                        </Box> 
                        <Box sx={{
                            width: '24.8%',
                            minWidth: '24.8%',
                            maxWidth: '24.8%',
                            display:'flex',
                            alignItems:'center'
                        }}>
                             <Text sx={{
                                marginLeft:'16px',
                                fontFamily:'Inter',
                                fontWeight:'600',
                                fontSize:'12px',
                                textTransform:'uppercase', 
                                color:'#586069'
                            }}>Client</Text>
                        </Box> 
                        <Box sx={{
                            width: '14.4%',
                            minWidth: '14.4%',
                            maxWidth: '14.4%',
                            display:'flex',
                            alignItems:'center'
                        }}>
                           <Text sx={{
                                marginLeft:'12px',
                                fontFamily:'Inter',
                                fontWeight:'600',
                                fontSize:'12px',
                                textTransform:'uppercase', 
                                color:'#586069'
                            }}>assignee</Text>
                        </Box> 
                        <Box sx={{
                            width: '11.54%',
                            minWidth: '11.54%',
                            maxWidth: '11.54%',
                            display:'flex',
                            alignItems:'center'
                        }}>
                             <Text sx={{
                                marginLeft:'12px',
                                fontFamily:'Inter',
                                fontWeight:'600',
                                fontSize:'12px',
                                textTransform:'uppercase', 
                                color:'#586069'
                            }}>status</Text>
                        </Box>
                        <Box sx={{
                            width: '9.9%',
                            minWidth: '9.9%',
                            maxWidth: '9.9%',
                            display:'flex',
                            alignItems:'center'
                        }}>
                             <Text sx={{
                                marginLeft:'12px',
                                fontFamily:'Inter',
                                fontWeight:'600',
                                fontSize:'12px',
                                textTransform:'uppercase', 
                                color:'#586069'
                            }}>Due date</Text>
                        </Box> 
                        <Box sx={{
                            width: '5.6%',
                            minWidth: '5.6%',
                            maxWidth: '5.6%',
                        }}>
                             
                        </Box> 
                       </Box>


                       {allTasks
                       .filter((tData)=> selectedComplianceId=="All"? tData : tData.compliance_id===selectedComplianceId)
                       .map((row,index)=>{
                           
                              return(
                                <Box sx={{
                          
                                    display:'flex',
                                    flexDirection:'row',
                                    minHeight:'40px',
                                    maxHeight:'40px',
                                    height:'40px',
                                    width:'100%',
                                    minWidth:'100%',
                                    maxWidth:'100%',  
                                    background:'white',
                                    borderBottom:'1px solid #E5E9EB'
                                 }}>
                                      <Box sx={{
                                          width: '3.73%',
                                          minWidth: '3.73%',
                                          maxWidth: '3.73%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                      }}>
                                          <Checkbox checked={row.isChecked}

                                              onChange={(e) => {
                                                  setallTasks((prevState) => {
                                                      const newState = prevState.map((row2) => {
                                                          if (row2.task_id === row.task_id) {
                                                              return ({ ...row2, isChecked: e.target.checked })
                                                          } else {
                                                              return ({ ...row2 })
                                                          }
                                                      })

                                                      return (newState)
                                                  })
                                              }}

                                          />
                                      </Box> 
                                      <Box sx={{
                                          width: '28.1%',
                                          minWidth: '28.1%',
                                          maxWidth: '28.1%',
                                          display: 'flex',
                                          alignItems: 'center'
                                      }}>
                                          <Text sx={{
                                              marginLeft: '12px',
                                              fontFamily: 'Inter',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              color: '#24292E',
                                              cursor:'pointer',
                                              minWidth:'93%',
                                              maxWidth:'93%',
                                              width:'93%',
                                              overflow:'hidden',
                                              whiteSpace:'nowrap',
                                              textOverflow:'ellipsis'
                                          }}
                                          onClick={()=>{
                                            localStorage.setItem("taskId",row.task_id)
                                            navigate('/taskexpansionDetails')
                                          }}
                                          >{row.task_name}</Text>
                                      </Box> 
                                      <Box sx={{
                                          width: '24.8%',
                                          minWidth: '24.8%',
                                          maxWidth: '24.8%',
                                          display: 'flex',
                                          alignItems: 'center'
                                      }}>
                                          <Text sx={{
                                              marginLeft: '16px',
                                              fontFamily: 'Inter',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              color: '#24292E',
                                              minWidth: '93%',
                                              maxWidth: '93%',
                                              width: '93%',
                                              overflow: 'hidden',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis'
                                          }}>{row?.contact_data !== null || row.contact_data !== ""
                                              ?
                                              row?.contact_data?.org_name
                                              : ""
                                              }</Text>

                                          {/* <Text sx={{
                                              marginLeft: '16px',
                                              fontFamily: 'Inter',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              color: '#24292E'
                                          }}>{row?.client}</Text> */}

                                      </Box> 
                                      <Box sx={{
                                          width: '14.4%',
                                          minWidth: '14.4%',
                                          maxWidth: '14.4%',
                                          display: 'flex',
                                          alignItems: 'center'
                                      }}>
                                          <Text sx={{
                                              marginLeft: '12px',
                                              fontFamily: 'Inter',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              color: '#24292E',
                                              minWidth: '93%',
                                              maxWidth: '93%',
                                              width: '93%',
                                              overflow: 'hidden',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis'
                                          }}>

                                              {row.assigne_id === "" || row.assigne_id == null ?
                                                  "Unassigned"
                                                  :
                                               row?.user_first_name + " " + row?.user_last_name
                                              }
                                              {/* {row.assigne} */}

                                          </Text>
                                      </Box> 
                                  <Box sx={{
                                      width: '11.54%',
                                      minWidth: '11.54%',
                                      maxWidth: '11.54%',
                                      display:'flex',
                                      alignItems:'center'
                                  }}>

                                          <Label sx={{
                                              position: 'relative',
                                              margin: '0px 27px 0px 12px',
                                              fontFamily: 'Inter',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              fontSize: '14px',
                                              lineHeight: '18px',
                                              color: '#119C2B',
                                              borderRadius: '6px',
                                              minWidth: 'auto',
                                              width: 'auto',
                                              maxWidth: 'auto',
                                              minHeight: '24px',
                                              maxHeight: '24px',
                                              height: '24px',
                                              background: '#EBFFF1',
                                              border: 'none',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                          }}>

                                              <CheckIcon />
                                              <Text sx={{
                                                  marginLeft: '4px'
                                              }}>
                                                  Succeeded
                                              </Text>
                                          </Label>
                                        
                                  </Box>
                                  <Box sx={{
                                      width: '9.9%',
                                      minWidth: '9.9%',
                                      maxWidth: '9.9%',
                                      display:'flex',
                                      alignItems:'center'
                                  }}>
                                          <Text sx={{
                                              marginLeft: '12px',
                                              fontFamily: 'Inter',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              color: '#24292E',
                                              minWidth: '93%',
                                              maxWidth: '93%',
                                              width: '93%',
                                              overflow: 'hidden',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis'
                                          }}>
                                              {
                                                  row?.due_date.length > 0 ? "" + new Date(row?.due_date).toLocaleDateString("en-US", {

                                                      day: "numeric",
                                                      year: "numeric",
                                                      month: "short",
                                                  }) + "" : ""
                                              }

                                          </Text>
                                  </Box> 
                                  <Box sx={{
                                      width: '5.6%',
                                      minWidth: '5.6%',
                                      maxWidth: '5.6%',
                                      display:'flex',
                                      alignItems:'center',
                                      justifyContent:'center'
                                  }}>
                                      
                                      <IconButton 
                                       variant="invisible"
                                       sx={{
                                          display:'flex',
                                          alignItems:'center',
                                          justifyContent:'center',
                                          color:'#5B6871',
                                          height:'32px',
                                          width:'32px',
                                          minHeight:'32px',
                                          minWidth:'32px'
                                       }} 
                                       icon={()=> <Box sx={{display:'flex'}}><KebabHorizontalIcon/></Box>}
                                      /> 
                                          
                                  </Box> 
                                 </Box>
                              )
                       })}

                     
                 </Box>

      



            </Box>

          


        </Box>
    )

}

export default FeedLatest