import React, { lazy } from 'react';
import { createBrowserHistory } from 'history';
import { Box, Button,Tooltip,Avatar, Dialog,Text,Spinner, Radio,RadioGroup,FormControl } from "@primer/react"
import {UnderlineNav} from '@primer/react/drafts'
import { useNavigate,useLocation, Link } from "react-router-dom"
import workplaceTheme from "../Workplace/Workplacetheme"
import HeaderSubComponent from "../Workplace/HeaderSubComponent"
import SpaceTheme from "./SpaceTheme"
import { useDispatch,useSelector } from "react-redux"
import { updateHeaderSelectedIndex } from "../redux/features/spaceHeaderSlice"
import { createBulkTaskDialogOpenClose, createTaskDialogOpenClose, createTaskOpenClose,openCloseTaskExpansion,postcreateIssue } from "../redux/features/taskSlice"
import { getSingleWorkplaceProject, getSpaceCollaborators } from "../redux/features/spaceSlice";
import { FeedPersonIcon } from "@primer/octicons-react";
import { Toaster, Intent,Position } from "@blueprintjs/core";
import CreateRoadDialog from '../Workplace/Dialogs/CreateRoadDialog';
import CreateTaskDialog from '../Workplace/Dialogs/CreateTaskDialog';
import CreateBulkTaskDialog from '../Workplace/Dialogs/CreateBulkTaskDialog';
 
const logoUrl = "https://d2fcqkt9srr3ti.cloudfront.net/logo.png"
 
//function SpaceHeader()
const SpaceHeader = React.memo(props =>
{

    const history = createBrowserHistory()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();

    const userId = useSelector((state)=> state.profileData.userId)
    const userEmail = useSelector((state)=> state.profileData.userEmail)
    const spaceCollabs = useSelector((state)=> state.spaceData.spaceCollabs) || []
    const taskData = useSelector((state)=> state.taskData )
    const subtaskCompletedOpenClose = useSelector((state)=> state.taskData.subtaskCompletedOpenClose)
    const isAddOrganization = useSelector((state)=> state.contactProfileData.isAddOrganization )
    const isAddProprietor = useSelector((state)=> state.contactProfileData.isAddProprietor )

    const initialDialogFocusRef = React.createRef()
    const [selectedTaskType,setselectedTaskType] = React.useState("Custom")
    const [showLoadingSubmitBtn,setshowLoadingSubmitBtn] = React.useState(false)
    const [showCreateTaskSelectionialog,setshowCreateTaskSelectionialog] = React.useState(false)
    const [spaceCollaborators,setspaceCollaborators] = React.useState([...spaceCollabs])
   // const [workflowArray, setworkflowArray] = React.useState([])

    const [items, setItems] = React.useState([
        { navigation: 'Board', route: "/space/" + localStorage.getItem("projectName"), isSelected: false },
        { navigation: 'List', route: "/list", isSelected: false },
        { navigation: 'Clients', route: "/contact", isSelected: false },
        { navigation: 'Timeline', route: "/timeline", isSelected: false },
        { navigation: 'Files', route: "/files", isSelected: false },
        { navigation: 'Team', route: "/team", isSelected: false },
        { navigation: 'Action', route: "/action", isSelected: false },

    ])  

    const [insightHeaderItems, setinsightHeaderItems] = React.useState([
        { navigation: 'Overview', route: "/roadmap/overview", isSelected: false },
        { navigation: 'Whitepaper', route: "/roadmap/whitepaper", isSelected: false },
        { navigation: 'Workflow report', route: "/roadmap/workflowreport", isSelected: false },
        { navigation: 'Team insight', route: "/roadmap/teaminsight", isSelected: false },
        { navigation: 'Files', route: "/roadmap/files", isSelected: false },
        { navigation: 'Team', route: "/roadmap/team", isSelected: false },
    ])
    const [selectedInsightHeader,setselectedInsightHeader] = React.useState(0) 
    const [underlineNavKey,setunderlineNavKey] = React.useState("1")
    
    const showCreateTaskPanel = (props) => {

        const projectId = localStorage.getItem("projectId")
        const taskPriority = "Normal"

        dispatch(createTaskDialogOpenClose({
            taskDialogOpenClose: true
        }))
    }

    const showCreateBulkTaskPanel = (props) => {

        dispatch(createBulkTaskDialogOpenClose({
            bulktaskDialogOpenClose:true
        }))
    
    }


    const CreateTaskSelectionDialog = (props) => {
          return(
            <Dialog 
            initialFocusRef={initialDialogFocusRef}
            isOpen={showCreateTaskSelectionialog}
            onDismiss={() => {
                setshowCreateTaskSelectionialog(false)
                
            }}
            sx={{
                minHeight: '455px',
                height: '455px',
                minWidth: '564px',
                width: '564px',
                maxWidth: '564px',
                display: 'flex',
                flexDirection: 'column',

            }}>

                  <Box sx={{
                      position: 'absolute',
                      left: '0px',
                      right: '30px',
                      top: '0px',
                      minHeight: '52px',
                      maxHeight: '52px',
                      height: '52px'
                  }}>

                      <Text
                          ref={initialDialogFocusRef}
                          sx={{
                              position: 'absolute',
                              left: '16px',
                              top: '16px',
                              fontFamily: 'Segoe UI',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '20px',
                              color: '#24292F'
                          }}>
                          Create a task
                      </Text>

                  </Box>

                  <Box sx={{
                      position: 'absolute',
                      left: '0px',
                      right: '0px',
                      top: '52px',
                      bottom: '52px',
                      display: 'flex',
                      flexDirection: 'column',
                     
                  }}>

                      <Text

                          sx={{
                              position: 'relative',
                              margin: '8px 16px 0px 16px',
                              fontFamily: 'Segoe UI',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              color: '#24292E'
                          }}>
                          Creating tasks, you can keep track of your work and stay on top of your deadlines
                      </Text>

                      <Box sx={{
                          position: 'relative',
                          margin: '25px 17px 0px 17px',
                          minHeight:'100px',
                          height:'100px',
                          maxHeight:'100px',
                          background:'#FFFFFF',
                          border:'1px solid #D0D7DE',
                          borderRadius:'6px',
                          display:'flex',
                          flexDirection:'column',
                          cursor:'pointer',
                          ":hover":{
                            background: 'rgba(234, 238, 242, 0.5)'
                          }
                      }}
                      onClick={()=>{
                         setselectedTaskType("Custom")
                      }}
                      >

                          <Box sx={{
                              display:'flex',
                              flexDirection:'row',
                              gap:'8px',
                              position:'relative',
                              margin:'17px 17px 0px 17px',
                              
                          }}>

                              <RadioGroup name="radioGroup-example">
                                    <FormControl>
                                      <Radio value="Custom"  checked={selectedTaskType==="Custom"?true:false} onChange={(e)=>setselectedTaskType("Custom")} />
                                    </FormControl>
                              </RadioGroup>
                                <Text sx={{
                                      
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      fontSize: '14px',
                                      lineHeight: '20px',
                                      color: '#24292E'
                                }}>
                                Custom or blank task.
                                </Text>

                          </Box>

                          <Text sx={{
                             position:'relative',
                             margin:'6px 17px 0px 39px',
                             fontFamily: 'Segoe UI',
                             fontStyle: 'normal',
                             fontWeight: '400',
                             fontSize: '12px',
                             lineHeight: '18px',
                             color: '#24292E',
                             textAlign:'left'
                          }}>
                              You can create custom tasks for your organization's compliance or service needs. You
                              can also create a blank task for any other purpose.
                          </Text>



                      </Box>

                      <Box sx={{
                          position: 'relative',
                          margin: '20px 17px 0px 17px',
                          minHeight:'100px',
                          height:'100px',
                          maxHeight:'100px',
                          background:'#FFFFFF',
                          border:'1px solid #D0D7DE',
                          borderRadius:'6px',
                          display:'flex',
                          flexDirection:'column',
                          cursor:'pointer',
                          ":hover":{
                            background: 'rgba(234, 238, 242, 0.5)'
                          }
                      }}
                      onClick={()=>{
                         setselectedTaskType("Bulk")
                      }}
                      >

                          <Box sx={{
                              display:'flex',
                              flexDirection:'row',
                              gap:'8px',
                              position:'relative',
                              margin:'17px 17px 0px 17px',
                              
                          }}>

                              <RadioGroup name="radioGroup-example">
                                    <FormControl>
                                      <Radio value="Bulk task" checked={selectedTaskType==="Bulk"?true:false} onChange={(e)=>setselectedTaskType("Bulk")} />
                                    </FormControl>
                              </RadioGroup>
                                <Text sx={{
                                      
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      fontSize: '14px',
                                      lineHeight: '20px',
                                      color: '#24292E'
                                }}>
                                Bulk task creation
                                </Text>

                          </Box>

                          <Text sx={{
                             position:'relative',
                             margin:'6px 17px 0px 39px',
                             fontFamily: 'Segoe UI',
                             fontStyle: 'normal',
                             fontWeight: '400',
                             fontSize: '12px',
                             lineHeight: '18px',
                             color: '#24292E',
                             textAlign:'left'
                          }}>
                               Create a single task that will be assigned to multiple companies. This can save you time and effort by eliminating the need to create separate tasks for each company
                          </Text>



                      </Box>


                  </Box>


                  


                  <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    {showLoadingSubmitBtn===true?
                    <Button variant="outline" sx={{
                        position: 'relative',
                        margin: 'auto 16px 10px auto',
                        background: '#0965CE',
                        color: '#ffffff',
                        width: '78px',
                        height: '32px',
                        minWidth: '78px',
                        maxWidth: '78px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',

                    }}
                    onClick={()=>{
                       
                    }}
                    >
                        
                       <Spinner size="small" sx={{display:'flex'}} />
                    </Button>
                    :
                    <Button variant="outline" sx={{
                        position: 'relative',
                        margin: 'auto 16px 10px auto',
                        background: '#0965CE',
                        color: '#ffffff',
                        width: '78px',
                        height: '32px',
                        minWidth: '78px',
                        maxWidth: '78px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontWeight: '600',

                    }}
                    onClick={()=>{
                          if(selectedTaskType==="Custom"){
                               showCreateTaskPanel("") 
                               setshowCreateTaskSelectionialog(false) 
                          }else if(selectedTaskType==="Bulk"){
                               showCreateBulkTaskPanel("")
                               setshowCreateTaskSelectionialog(false) 
                          } 
                    }}
                    >
                        Create
                    </Button>
                    }

                </Box>



            </Dialog>
          )
    }

 
    const updateSelectedIndex = (props) => {

        setItems(prevState => {

             const newState = prevState.map((item, index) => {
                if (index == props) {
                    return { ...item, isSelected: true };
                }
                return item;

             });

             return newState

              })


              dispatch(updateHeaderSelectedIndex({
                  index:props
              })).then((data)=>{

                     

            })     
    }

    const getSpaceCollaboratorFromDb = (props) => {


        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

           
            setspaceCollaborators(data.payload.collaboratorArray)
            
        })

    }

    React.useState(()=>{

        if(location.pathname==="/space/"+localStorage.getItem("projectName")){

            updateSelectedIndex(0)
     

      }else if(location.pathname==="/list"){

            updateSelectedIndex(1)

          
      }else if(location.pathname==="/contact"){

            updateSelectedIndex(2)


          
      }else if(location.pathname==="/timeline"){
            updateSelectedIndex(3)
          
      }else if(location.pathname==="/files"){
            updateSelectedIndex(4)
          
      }else if(location.pathname==="/team"){
            updateSelectedIndex(5)
          
      }else if(location.pathname==="/action"){
            updateSelectedIndex(6)
      
      }   else{
            updateSelectedIndex(0)
      }

    })


    React.useEffect(()=>{

         getSpaceCollaboratorFromDb("")

    },[localStorage.getItem("projectId")])


    React.useEffect(()=>{

       
        dispatch(openCloseTaskExpansion({
            openCloseExpansion: false,
            expansionTaskId: ""
        }))

         

    },[location.pathname])

    React.useEffect(()=>{
 
         if(showCreateTaskSelectionialog===true){

              setselectedTaskType("Custom")
         }


    },[showCreateTaskSelectionialog])
     
    

    return(
        <Box
            position="fixed"
            width={'100%'}
            height={48}
            background="#FFFFFF"
            borderColor="border.default"
            borderWidth={1}
            borderStyle="solid"
            p={0}
            zIndex={taskData.taskDialogOpenClose===true || 
                taskData.bulktaskDialogOpenClose || 
                subtaskCompletedOpenClose || 
                taskData.recurringbulktaskDialogOpenClose ||
                isAddOrganization ||
                isAddProprietor
                ?0:10}
        >
           
            <Box sx={workplaceTheme.headerInnerStyle}>
 
                <img
                    style={workplaceTheme.logoStyle}
                    src={logoUrl}
                    onClick={() => { navigate('/workplace') }}
                />
 
              
                <Box 
               // style={{ display: location.pathname === '/roadmap' ? 'flex' : 'none' }} 
                style={{ display:'none' }}
                sx={SpaceTheme.spaceHeaderNavBox}
                >

                    <UnderlineNav
                        key={underlineNavKey}
                        sx={{ ...SpaceTheme.spaceBoxUnderlineNav, minWidth: '600px' }}
                    >
                        {insightHeaderItems.map((item, index) => {
                            return (
                                <UnderlineNav.Item
                                    as={Link}
                                    to={item.route}
                                    key={item.navigation}
                                    selected={selectedInsightHeader === index}
                                    onSelect={e => {
                                        setunderlineNavKey("1")
                                        setselectedInsightHeader(index)
                                        e.preventDefault()
                                    }}
                                    onClick={() => {
                                        // navigate(item.route) 
                                    }}
                                >
                                    {item.navigation}
                                </UnderlineNav.Item>
                            )
                        })}

                    </UnderlineNav>

                </Box>


                <Box 
                style={{display:
                    location.pathname==='/account' ||
                    location.pathname==='/roadmap' ||
                    location.pathname==='/apps' ||
                    location.pathname==='/rules' ||
                    location.pathname==='/settings'
                    
                ?
                "none"
                :"flex"}}
                sx={SpaceTheme.spaceHeaderNavBox}>
                    <UnderlineNav  sx={{...SpaceTheme.spaceBoxUnderlineNav,minWidth:'650px'}}>
                        {items.map((item, index) => (
                            <UnderlineNav.Item
                                
                                as={Link} 
                                to={item.route}
                                key={item.navigation}
                                selected={item.isSelected}
                                onSelect={e => {
                                  
                                 
                                    e.preventDefault()
                                }}
                                onClick={()=>{
                                    updateSelectedIndex(index)
                                    navigate(item.route) 
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>

 
                <Button 
                style={{display:
                    location.pathname==='/account' ||
                    location.pathname==='/roadmap' ||
                    location.pathname==='/apps' ||
                    location.pathname==='/rules' ||
                    location.pathname==='/settings'
                    
                ?
                "none"
                :"flex"}}
                variant="invisible"
                sx={SpaceTheme.spaceHeaderCreateBtn}
                onClick={()=> 
                    
                     
                      setshowCreateTaskSelectionialog(true)
                }
                >
                   
                    Create
                </Button>



                <Box 
                  style={{display:
                    location.pathname==='/account' ||
                    location.pathname==='/roadmap' ||
                    location.pathname==='/apps' ||
                    location.pathname==='/rules' ||
                    location.pathname==='/settings'
                    
                ?
                "none"
                :"flex"}}
                sx={{
                    minWidth:'96px',
                    maxWidth:'96px',
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top:'7px',
                    bottom:'0px',
                    right:'389px',
                    margin:'auto',
                    width: 'fit-content',
                    cursor:'pointer'
                }}>

                    

                    {spaceCollaborators
                    ?.slice(0,4)
                    .map((row,index)=>{
                         return(

                             row?.user_image_url===""||row?.user_image_url==null?

                             <Box
                             sx={{
                                position:'relative',
                                margin:'0px 0px 0px -6px',
                                zIndex: spaceCollaborators?.length - index
                                
                             }}
                             >
                              <Tooltip aria-label={row?.user_first_name+" "+row?.user_last_name} direction="s">
                             <FeedPersonIcon 
                             size={24}  
                             fill="#586069"
                             />
                             </Tooltip>
                             </Box>
                             :
                             <Tooltip aria-label={row.user_first_name+" "+row.user_last_name} direction="s">
                             <Avatar
                                  
                                 size={24}
                                 src={row.user_image_url}
                                 style=
                                 {{
                                     width: '24px',
                                     height: '24px',
                                     minHeight: '24px',
                                     minWidth: '24px',
                                     maxWidth: '24px',
                                     maxHeight: '24px',
                                     borderRadius: '50%',
                                     border: '1px solid #FFFFFF',
                                     position:'relative',
                                     margin:'0px 0px 0px -6px',
                                     zIndex: spaceCollaborators?.length - index
                                   
                                 }}
                                 
                                
                             />
                             </Tooltip>
                         )
                    })}

                     


                    {(spaceCollaborators?.length - 4) > 0 ?  
                    
                    <Box sx={{
                        minHeight:'24px',
                        minWidth:'24px',
                        maxWidth:'24px',
                        maxHeight:'24px',
                        borderRadius: '12px',
                        background: '#DFE1E6',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '11px',
                        lineHeight: '20px',
                        color: '#42526E',
                        marginLeft: '-6px',
                        zIndex: '0'
                    }}>

                       {(spaceCollaborators.length - 4)}
                        
                    </Box>
                    
                    :
                    
                    ""}
                    

                </Box>

                 
                 
                <HeaderSubComponent />

               


            </Box>

            
            <CreateTaskDialog/>
            <CreateBulkTaskDialog/>
            <CreateTaskSelectionDialog/>
           

        </Box>
    )
})

export default SpaceHeader 