import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Box, Text, Button, IconButton, Label, AvatarStack, Avatar,ActionMenu,ActionList,Tooltip,StyledOcticon } from "@primer/react"
import { ChatBubbleIcon, CircleIcon, DotsVerticalIcon, DropdownMenuIcon, FileTextIcon, InfoCircledIcon, LayoutIcon, Link1Icon, Link2Icon, PlusIcon, ReaderIcon, SizeIcon } from "@radix-ui/react-icons"
import { createTaskOpenClose, deleteTaskById, duplicateTask, openCloseTaskExpansion, reloadTaskTrigger } from "../redux/features/taskSlice"
import { useDispatch,useSelector } from "react-redux";
import workplaceTheme from "../Workplace/Workplacetheme"
import { CheckCircleFillIcon, FeedPersonIcon } from "@primer/octicons-react";
import { Toaster, Intent,Position } from "@blueprintjs/core";

const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"
const normalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/normal.svg"
const mediumIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/medium.svg"
const highIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/high.svg"
const criticalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/critical.svg"
const lowIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/low.svg"


const SpaceTheme = {

  taskBox:{
    width:'264px',
    minWidth:'264px',
    height:'auto',
    minHeight:'auto',
    maxHeight:'auto',
    background:'#FFFFFF',
    position:'relative',
    margin:'0px 0px 5px 0px',
    border:'1px solid #D0D7DE',
    borderRadius:'6px',
    boxSizing:'border-box',
    taskBoxHeader:{
      width: '234px',
      minWidth: '234px',
      maxWidth: '234px',
      height: '18px',
      minHeight: '18px',
      maxHeight: '18px',
      position: 'relative',
      margin: '15px auto 0px auto',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      dateText: {
        fontFamily: 'Segoe UI',
        fontWeight: '400',
        fontSize: '12px',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#000000',
       
        display: 'flex',
        position: 'relative',
        margin: '0px 0px 0px 5px'
      },
      iconsetBox: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        margin: '0px -3px 0px auto',
        icon: {
          display: 'flex',
          color:'#000000'
        },
        iconBtn1: {
          height: '25px',
          width: '25px',
          color: '#000000',
          margin: '0px 0px 0px -5px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        iconBtn2: {
          height: '25px',
          width: '25px',
          color: '#000000',
          position: 'relative',
          margin: '0px 0px 0px 3px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      }
    },
    taskName: {
      display: 'flex',
      position: 'relative',
      margin: '7px 15px 0px 15px',
      minWidth: '234px',
      maxWidth: '234px',
      width: '234px',
      height:'auto',
      minHeight:'auto',
      maxHeight: 'auto',
      fontFamily: 'Segoe UI',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '19px',
      color: '#121212',
      textAlign: 'left',
      whiteSpace: "pre-line",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    taskDescription: {
      display: 'flex',
      position: 'relative',
      margin: '7px 15px 0px 15px',
      minWidth: '234px',
      maxWidth: '234px',
      width: '234px',
      height:'auto',
      minHeight:'auto',
      maxHeight: 'auto',
      fontFamily: 'Segoe UI',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: '350',
      lineHeight: '19px',
      color: '#121212',
      whiteSpace: "pre-line",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'left'
    },
    taskLabelBox:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        position:'relative',
        margin:'7px 15px 0px 15px',
        color:'#0969DA',
        gap:'7px',
        label:{
            height:'24px',
            border:'none',
            background:'#DDF4FF',
            borderRadius:'3px',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontSize:'12px',
            fontWeight:'400',
            lineHeight:'16px',
            textOverflow:"ellipsis",
            overflow:'hidden',
            color:'#0969DA'
        }
    },
    roadMapLabelBox:{
       
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        position:'relative',
        margin:'7px 15px 0px 15px',
        gap:'7px',
        label:{
            height:'22px',
            border:'none',
           // background:'#DCF487',
            background:'#DAFBE1',
            borderRadius:'0px',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontSize:'12px',
            fontWeight:'400',
            lineHeight:'16px',
            textOverflow:"ellipsis",
            overflow:'hidden',
           // color:'#121212',
           color:'#24292F'
        }

    }
 },

 taskBoxHover:{

    width:'264px',
    minWidth:'264px',
    height:'auto',
    minHeight:'auto',
    maxHeight:'auto',
    background:'#FFFFFF',
    position:'relative',
    margin:'0px 0px 5px 0px',
    filter:'drop-shadow(0px 8px 24px rgba(140, 149, 159, 0.2))',//hover effect
    border:'1px solid #D0D7DE',
    borderRadius:'6px',
    boxSizing:'border-box',
    taskBoxHeader:{

        width:'234px',
        minWidth:'234px',
        maxWidth:'234px',
        height:'18px',
        minHeight:'18px',
        maxHeight:'18px',
        position:'relative',
        margin:'15px auto 0px auto',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        dateText:{
            fontFamily:'Segoe UI',
            fontWeight:'400',
            fontSize:'12px',
            fontStyle:'normal',
            fontSize:'12px',
            lineHeight:'18px',
            color:'#586069',
            display:'flex',
            position:'relative',
            margin:'0px 0px 0px 5px'
        },
         iconsetBox:{
            display:'flex',
            flexDirection:'row',
            position:'relative',
            margin:'0px 0px 0px auto',
            icon:{
                display:'flex'
            },
            iconBtn1:{
                height:'15px',
                width:'15px',
                color: '#000000',
                margin: '0px 0px 0px 0px',
                position:'relative',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            },
            iconBtn2:{
                height:'15px',
                width:'15px',
                color: '#000000',
                position:'relative',
                margin:'0px 0px 0px 12px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }
        }
    },
    taskName: {
      display: 'flex',
      position: 'relative',
      margin: '7px 15px 0px 15px',
      minWidth: '234px',
      maxWidth: '234px',
      width: '234px',
      height:'auto',
      minHeight:'auto',
      maxHeight: 'auto',
      fontFamily: 'Segoe UI',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '19px',
      color: '#121212',
      textAlign: 'left',
      whiteSpace: "pre-line",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    taskDescription:{
        display: 'flex',
        position: 'relative',
        margin: '7px 15px 0px 15px',
        minWidth: '234px',
        maxWidth: '234px',
        width: '234px',
        height:'auto',
        minHeight:'auto',
        maxHeight: 'auto',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: '350',
         lineHeight: '19px',
        color: '#121212',
        textAlign:'left',
        whiteSpace: "pre-line",
        textOverflow: 'ellipsis',
        overflow: 'hidden',  
    },
    taskLabelBox:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        position:'relative',
        margin:'7px 15px 0px 15px',
        color:'#0969DA',
        gap:'7px',
        label:{
            height:'24px',
            border:'none',
            background:'#DDF4FF',
            borderRadius:'3px',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontSize:'12px',
            fontWeight:'400',
            lineHeight:'16px',
            textOverflow:"ellipsis",
            overflow:'hidden',
            color:'#0969DA'
        }
    },
    roadMapLabelBox:{
       
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        position:'relative',
        margin:'7px 15px 0px 15px',
        color:'red',
        gap:'7px',
        label:{
            height:'22px',
            border:'none',
            background:'#DCF487',
            borderRadius:'0px',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontSize:'12px',
            fontWeight:'400',
            lineHeight:'16px',
            textOverflow:"ellipsis",
            overflow:'hidden',
           
        }

    }
 },


}


 
const TaskComplianceCard = ({ item, index,tData,comp,collabs }) => {

  const userId = useSelector((state) => state.profileData.userId) 
  const userEmail = useSelector((state) => state.profileData.userEmail) 

  const [taskHover, settaskHover] = React.useState(false)

  const [isExpandIconHovered,setisExpandIconHovered] = React.useState(false)
  const dispatch = useDispatch()
  const showCreateTaskPanel = (props) => {
 
       dispatch(openCloseTaskExpansion({

           openCloseExpansion: true,
           expansionTaskId: props.taskId
       }))

 

  }
  
  const reloadTasks = (props) => {

      dispatch(reloadTaskTrigger({
         taskTrigger:props
      }))
  }

  

  const deleteTaskInDb = (props) => {


        const userRole = collabs?.collabs?.filter(sData=> sData.user_id===userId)[0]?.user_role
        if(userRole==="Owner" || userRole==="Read only"){

          dispatch(deleteTaskById({
            userId: userId,
            userEmail: userEmail,
            taskId: props
          })).then((data) => {


            Toaster.create({ position: Position.TOP }).show({ message: "Task deleted.", intent: Intent.DANGER });


          })

        }else{

          Toaster.create({ position: Position.TOP }).show({
            message: "Don't have permission",
            intent: Intent.PRIMARY
          });

        }

    



  }

  const duplicateTaskInDb = (props) => {

    const userRole = collabs?.collabs?.filter(sData=> sData.user_id===userId)[0]?.user_role
    if (userRole === "Owner" || userRole === "Read only") {

      dispatch(duplicateTask({
        userId: userId,
        userEmail: userEmail,
        taskId: props,
        duplicateTaskId: props,
      })).then((data) => {

          Toaster.create({ position: Position.TOP }).show({ message: "Duplicated.", intent: Intent.PRIMARY });

      })


    } else{


      Toaster.create({ position: Position.TOP }).show({
        message: "Don't have permission",
        intent: Intent.PRIMARY
      });
         
    }

    


  }
  

  
   

  return (
    <Draggable 
    draggableId={item.id} 
    index={index}
    key={item.id}
    >
      {(provided, snapshot) => {
        return (

          <Box
           
            onClick={()=>{
              isExpandIconHovered===true ? console.log('k'):
              showCreateTaskPanel({
                expandWidth:true,
                taskId:tData?.task_id
              })

            }}

            ref={provided.innerRef}
            snapshot={snapshot}
            {...provided.draggableProps}
            {...provided.dragHandleProps}

           // style={taskHover===true?hoverStyle:defaulStyle}
             
            sx={taskHover===true?SpaceTheme.taskBoxHover:SpaceTheme.taskBox}
          
            onMouseEnter={() =>

              settaskHover(true)
            }
            onMouseLeave={() =>

               settaskHover(false)
            }
          >

            <Box
               sx={SpaceTheme.taskBox.taskBoxHeader}>
              <Box sx={{
                height: '15px',
                width: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>

                {
               
                  tData.completed_status==="true"?
                      <Tooltip aria-label="Verified">
                      <StyledOcticon size={12} color="#79CE17" icon={CheckCircleFillIcon} />
                      </Tooltip>
                       
                  :
                  tData?.tasks_priority === "Normal" ?
                  // <img src={normalIcon} style={{ display: 'flex' }} /> 
                    // <StyledOcticon sx={{fill:'#428FDC'}}   size={12} color="#428FDC"   icon={CircleIcon} />
                    
                    <Tooltip aria-label="Normal">
                    <Box sx={{
                         width:'12px',
                         height:'12px',
                         minWidth:'12px',
                         maxWidth:'12px',
                         minHeight:'12px',
                         maxHeight:'12px',
                         borderRadius:'50%',
                         background:'#428FDC',
                        // border:'1px solid #428FDC' 
                     }}>
                      
                     </Box>
                     </Tooltip>
                    :
                  tData?.tasks_priority === "Medium" ?
                        <Tooltip aria-label="Medium">
                          <img src={mediumIcon} style={{ display: 'flex' }} />
                        </Tooltip>
                  :
                  tData?.tasks_priority === "High" ?
                  <Tooltip aria-label="High">
                  <img src={highIcon} style={{ display: 'flex' }} /> 
                  </Tooltip>
                  :
                  tData?.tasks_priority === "Critical" ?
                  <Tooltip aria-label="Critical">
                  <img src={criticalIcon} style={{ display: 'flex' }} /> 
                  </Tooltip>
                  :
                  tData?.tasks_priority === "Critical" ?
                  <Tooltip aria-label="Critical">
                  <img src={lowIcon} style={{ display: 'flex' }} />
                  </Tooltip>
                  : ""
                }



              </Box>

                

              

               
              <Text sx={SpaceTheme.taskBox.taskBoxHeader.dateText}>
                 
                {tData.due_date!==null&&tData.due_date!==""?
                    new Date(tData.due_date).toDateString().substring(3,11)
                :""}
              </Text>

              <Box
                onMouseEnter={()=>setisExpandIconHovered(true)}
                onMouseLeave={()=>setisExpandIconHovered(false)}
                style={{ display: taskHover===true ? "flex" : "none" }}
                sx={SpaceTheme.taskBox.taskBoxHeader.iconsetBox}
              >
                <IconButton 
                  variant="invisible"
                  sx={SpaceTheme.taskBox.taskBoxHeader.iconsetBox.iconBtn1}
                  icon={() => <SizeIcon style={SpaceTheme.taskBox.taskBoxHeader.iconsetBox.icon} />}
                  onClick={()=> showCreateTaskPanel({
                  expandWidth:true,
                  taskId:tData?.task_id
                  })}  
                />


                <ActionMenu>
                  <ActionMenu.Anchor >
                  <IconButton 
                   
                   variant="invisible"
                   sx={SpaceTheme.taskBox.taskBoxHeader.iconsetBox.iconBtn2}
                  icon={() => <DotsVerticalIcon style={SpaceTheme.taskBox.taskBoxHeader.iconsetBox.icon} />} />

                  </ActionMenu.Anchor>

                  <ActionMenu.Overlay>
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                      <ActionList.Item  sx={workplaceTheme.profileActionMenuListItem}>Copy link</ActionList.Item>
                      <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                      onSelect={()=>{
                        duplicateTaskInDb(tData?.task_id)
                      }}
                      >Duplicate</ActionList.Item>
                      <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Convert to</ActionList.Item>
                      
                      <ActionList.Divider />
                      <ActionList.Item
                         variant="danger" 
                         sx={workplaceTheme.profileActionMenuListItemDanger}
                         onSelect={()=>{

                             
                                deleteTaskInDb(tData?.task_id)
                         }}
                         >Delete task
                         </ActionList.Item>

                    </ActionList>
                  </ActionMenu.Overlay>
                </ActionMenu>

                 
                




              </Box>
            </Box>


            <Text
              sx={SpaceTheme.taskBox.taskName}>
              {tData?.task_name}
            </Text>
            <Text
              style={{ display: tData?.description?.blocks[0]?.text.length <= 0 ? 'none' : 'flex' }}
              sx={SpaceTheme.taskBox.taskDescription}>
              {tData?.description?.blocks[0]?.text}

            </Text>

            <Box sx={SpaceTheme.taskBox.taskLabelBox}>


              {tData.contact_data !== null ?
                <Label
                  variant="accent"
                  sx={SpaceTheme.taskBox.taskLabelBox.label}
                >{tData.contact_data.org_name}</Label>
                :
                ""}

  
                
            </Box>

            {tData?.compliance_id === "" ?

              ""
              :

              <Box sx={SpaceTheme.taskBox.roadMapLabelBox}>



                <Label
                  variant="attention"
                  sx={SpaceTheme.taskBox.roadMapLabelBox.label}
                >
                   {comp.comp.filter((comp) => comp.service_id === tData.compliance_id)[0]?.subcategory.compliance_name}
                </Label>

              </Box>

            }

           


            <Box sx={{
              height: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
              margin: '7px 15px 15px 15px',
              
            }}>
              <Tooltip aria-label={tData?.user_first_name+" "+tData?.user_last_name} direction="ne">
              <AvatarStack sx={{zIndex:0}}>
               
                {tData?.assigne_id === "" ?
                 ""
                  :


                  tData?.user_image_url === "" || tData?.user_image_url == null ?

                   
                      <FeedPersonIcon size={24} fill="#586069" />
                   
                    
                    :
                   
                      <Avatar size={24} src={tData?.user_image_url} />
                   



                }

               
              </AvatarStack>
              </Tooltip>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '13px',
                position: 'relative',
                margin: '0px 0px 0px auto',
                height: '24px'
              }}>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',

                }}>

                  <Link1Icon color="#000000" style={{ display: 'flex' }} />
                  <Text sx={{
                    display: 'flex',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#000000',
                    position: 'relative',
                    margin: '0px 0px 0px 6px'
                  }}>
                     
                    {tData?.attachments.length}
                  </Text>

                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',

                }}>

                  <ChatBubbleIcon color="#000000" style={{ display: 'flex' }} />
                  <Text sx={{
                    display: 'flex',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#000000',
                    position: 'relative',
                    margin: '0px 0px 0px 6px'
                  }}>
                    
                    {tData?.comments?.length || 0}
                  </Text>

                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',

                }}>

                  <ReaderIcon color="#000000" style={{ display: 'flex' }} />
                  <Text sx={{
                    display: 'flex',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#000000',
                    position: 'relative',
                    margin: '0px 0px 0px 6px'
                  }}>
                    {tData?.compliance_subtasks.length}
                  </Text>

                </Box>

              </Box>


            </Box>



          </Box>


           
        );
      }}
    </Draggable>
  );
};

export default TaskComplianceCard;
