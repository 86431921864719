import { useNavigate } from 'react-router-dom';
import { Box, Text, Button, Heading, ActionList, ActionMenu, IconButton, Avatar, TextInput } from "@primer/react"
import { ChevronDownIcon, DotsHorizontalIcon, Share2Icon, PlusIcon, PersonIcon,Pencil1Icon } from '@radix-ui/react-icons';
import { createBrowserHistory } from 'history';
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import SideNav from './SideNav'
import { Auth } from 'aws-amplify';
import workplaceTheme from './Workplacetheme';
import Header from './Header';
import Workplacetheme from './Workplacetheme';
import { createSpaceOpenClose, deleteWorkplaceOpenClose,getWorkplaceProject } from "../redux/features/spaceSlice"
import { sharingOpenClose } from "../redux/features/sharingSlice"
import { moveWorkplaceOpenClose,getSingleWorkplace,updateWorkplaceName } from "../redux/features/workplaceSlice"
import CreateSpaceDialog from './Dialogs/CreateSpaceDialog';
import DeleteWorkplaceDialog from './Dialogs/DeleteWorkplaceDialog';
import ShareDialog from './Dialogs/ShareDialog';
import MoveSpaceDialog from './Dialogs/MoveSpaceDialog'
import SearchDialog from './Dialogs/SearchDialog';
import { CheckIcon } from '@primer/octicons-react';
import SpaceComponent from './SpaceComponent';

function CreateWorkplace() {

    const history = createBrowserHistory()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = useSelector((state)=> state.profileData.userId)
    const userEmail = useSelector((state)=> state.profileData.userEmail)

    const [workplaceId,setworkplaceId] = React.useState("")

    

    const [isWorkplaceNameInputVisible,setisWorkplaceNameInputVisible] = React.useState(true)

    const [workplaceNameHovered,setworkplaceNameHovered] = React.useState(false)


    const [workplaceName,setworkplaceName] = React.useState({
          "value":"",
          "isLoading":false,
          "trailingVistual":""
    })
 
    const [spaceBoxHovered, setspaceBoxHovered] = React.useState({
        id: "",
        isHovered: false
    })

    const [workplaceProjects,setworkplaceProjects] = React.useState([])

 
    const handleChangeWorkplaceName = (e)=>{

          updateWorkplaceNames(e.target.value)

          setworkplaceName((prevState)=> { return({...prevState,value:e.target.value})})


          setTimeout(()=>{  

            setworkplaceName((prevState)=> { return({...prevState,isLoading:true})})


          },500)

          setTimeout(()=>{  

            setworkplaceName((prevState)=> { return({...prevState,isLoading:false})})
            setworkplaceName((prevState)=> { return({...prevState,trailingVistual:<CheckIcon/>})})

          },3000)

          setTimeout(()=>{  

            
            setworkplaceName((prevState)=> { return({...prevState,trailingVistual:''})})

          },4000)

    }

    const handleChangeWorkplaceNameBlur = (e) =>{

             setworkplaceName((prevState)=> { 
               
                  const newState = {...prevState,value:e.target.value,isLoading:false,trailingVistual:''}
                  return(newState)

                  }
                
            )
            setisWorkplaceNameInputVisible(false)
           
    }


    const showMoveWorkplaceDialog = (props) => {

        dispatch(moveWorkplaceOpenClose({
            openClose: true
        }))

    }

    const showSharingDialog = (props) => {

        dispatch(sharingOpenClose({
            openClose: true,
            workplaceId:localStorage.getItem("workplaceId"),
            shareType:"workplace",
            projectId:"",
            title:workplaceName.value
        }))

    }


    const showCreateSpaceDialog = (props) => {

        dispatch(createSpaceOpenClose({
            openClose: true,
            workplaceId:localStorage.getItem("workplaceId")
        })).then((data) => {

        })
    }

    const showDeleteSpaceDialog = (props) => {

        dispatch(deleteWorkplaceOpenClose({
            openClose: true
        })).then((data) => {

        })
    }

    
    const updateWorkplaceNames = (props) => {

          

          dispatch(updateWorkplaceName({
              
               userId:userId,
               userEmail:userEmail,
               workplaceId:workplaceId,
               workplaceName:props      
          })).then((data)=>{

                
          })


    }

    React.useEffect(()=>{
 
        dispatch(getWorkplaceProject({
              userId:userId,
              userEmail:userEmail,
              workplaceId:localStorage.getItem("workplaceId")
        })).then((data)=>{

               setworkplaceProjects(data.payload?.data)
        })
        //workplaceProjects
        
  },[workplaceProjects])


    React.useState(() => {

         
        if(localStorage.getItem("workplaceStatus")==="view"){

            setisWorkplaceNameInputVisible(false)


        } 

      
        setworkplaceId(localStorage.getItem("workplaceId"))


        dispatch(getSingleWorkplace({
               userId:userId,
               userEmail:userEmail,
               workplaceId:localStorage.getItem("workplaceId")
        })).then((data)=>{
             
               

                setworkplaceId(data.payload.body.data[0]?.workplace_id)
                
                setworkplaceName((prevState)=>{

                         const newState = {...prevState,value:data.payload.body.data[0]?.workplace_name}
                         return newState
                })
                   

        })



         
    })

    return (<Box 
    position={"relative"} 
    display="flex" 
    background={"#F6F8FA"} 
   
    height="100vh"
    width={"100vw"} 
    overflowY={"auto"}
    
    >


        <Header />
        <SideNav />


        <Box sx={Workplacetheme.workplaceMain}

        >

            <Box sx={workplaceTheme.createworkplaceListMainBox}>

                

                <Box sx={workplaceTheme.workplaceListItemBox}>

                    {isWorkplaceNameInputVisible===true ?
                      <TextInput
                      style={{visibility:isWorkplaceNameInputVisible===true?'visible':'hidden'}}
                      sx={workplaceTheme.createWorkplaceNameInput}
                      autoFocus
                      placeholder="Add Workplace name"
                      value={workplaceName.value}
                      onChange={(e)=> handleChangeWorkplaceName(e) }
                      onBlur={(e)=> handleChangeWorkplaceNameBlur(e) }
                      trailingVisual={workplaceName.trailingVistual}
                      loading={workplaceName.isLoading}
                      />:

                      <Box 
                      onMouseEnter={()=> setworkplaceNameHovered(true)}
                      onMouseLeave={()=> setworkplaceNameHovered(false)}
                      sx={{
                        width:'fit-content',
                        height:'fit-content',
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        position:'relative',
                        margin:'11px 0px 0px 30px',
                        }}>
                      <Heading  
                      
                      onClick={()=> setisWorkplaceNameInputVisible(true)  } 
                      style={{cursor:'pointer'}}  
                      sx={workplaceTheme.workplaceNameTxt}>
                        {workplaceName.value}
                      </Heading>

                      <IconButton
                      sx={{
                      height:'30px',
                      width:'30px',
                      display:workplaceNameHovered===true?'flex':'none',
                      alignItems:'center',
                      justifyContent:'center',
                      position:'relative',
                      margin:'0px 0px 0px 10px',
                      border:'none',
                      boxShadow:'none',
                      borderRadius:'3px',
                      }}
                      icon={()=> <Pencil1Icon style={{display:'flex'}} />}
                      onClick={()=>setisWorkplaceNameInputVisible(true)}
                 
                      />


                      
                      </Box>
                   }



                  
                   

                    <ActionMenu>
                        <ActionMenu.Anchor >
                            <Button
                                variant="invisible"
                                trailingIcon={() => <ChevronDownIcon style={workplaceTheme.createWorkplaceOpenByBtn.dropDownIcon} />}
                                sx={workplaceTheme.createWorkplaceOpenByBtn}
                               
                            >
                              Opened by you
                            </Button>
                        </ActionMenu.Anchor>

                        <ActionMenu.Overlay align="start">
                            <ActionList sx={workplaceTheme.profileActionMenuList}>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>
                                <ActionList.LeadingVisual>
                                    <CheckIcon/>   
                                 </ActionList.LeadingVisual>
                                    Opened by you
                                </ActionList.Item>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>
                                <ActionList.LeadingVisual>
                                </ActionList.LeadingVisual>
                                    Alphabetical
                                </ActionList.Item>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>
                                <ActionList.LeadingVisual>
                                </ActionList.LeadingVisual>
                                    Oldest to newest 
                                </ActionList.Item>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>
                                <ActionList.LeadingVisual>
                                </ActionList.LeadingVisual>
                                    Newest to oldest
                                </ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>

                    <ActionMenu>
                        <ActionMenu.Anchor >
                            <IconButton
                                size="small"
                                variant="invisible"
                                sx={workplaceTheme.workplaceSettingsIcon}
                                icon={() => <DotsHorizontalIcon />} />

                        </ActionMenu.Anchor>

                        <ActionMenu.Overlay>
                            <ActionList sx={workplaceTheme.profileActionMenuList}>
                                <ActionList.Item onSelect={event => showSharingDialog("")} sx={workplaceTheme.profileActionMenuListItem}>Share workspace</ActionList.Item>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Settings</ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                    onSelect={event => showDeleteSpaceDialog("")}
                                    variant="danger" sx={workplaceTheme.profileActionMenuListItemDanger}>Delete</ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>

                    <IconButton
                        size="small"
                        variant="invisible"
                        sx={workplaceTheme.workplaceShareIcon}
                        icon={() => <Share2Icon />}
                        onClick={() => showSharingDialog("")}
                    />

                    {/* <ActionMenu>
                        <ActionMenu.Anchor >
                            <IconButton
                                size="small"
                                variant="invisible"
                                sx={workplaceTheme.workplaceShareIcon}
                                icon={() => <Share2Icon />}
                                onClick={()=> showSharingDialog("")}
                                />

                        </ActionMenu.Anchor>

                        <ActionMenu.Overlay>
                            <ActionList sx={workplaceTheme.profileActionMenuList}>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Share workspace</ActionList.Item>
                                <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Settings</ActionList.Item>
                                <ActionList.Divider />
                                <ActionList.Item
                                    onSelect={event => showDeleteSpaceDialog("")}
                                    variant="danger" sx={workplaceTheme.profileActionMenuListItemDanger}>Delete</ActionList.Item>

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu> */}

                    <ActionMenu>
                        <ActionMenu.Anchor >
                            <IconButton
                                size="small"
                                variant="invisible"
                                sx={workplaceTheme.workplacePeopleIcon}
                                icon={() => <PersonIcon />} />

                        </ActionMenu.Anchor>

                        <ActionMenu.Overlay align="end">

                            <ActionList showDividers
                                sx={Workplacetheme.listCollabActionList}
                            >
                                <ActionList.Item sx={Workplacetheme.listCollabActionList.actionListItem}>
                                    <ActionList.LeadingVisual>
                                        <Avatar src="https://github.com/mona.png" />
                                    </ActionList.LeadingVisual>
                                    <Text sx={Workplacetheme.listCollabActionList.actionListItem.textField}>jonrohan</Text>

                                </ActionList.Item>
                                <ActionList.Item sx={Workplacetheme.listCollabActionList.actionListItem}>
                                    <ActionList.LeadingVisual>
                                        <Avatar src="https://github.com/hubot.png" />
                                    </ActionList.LeadingVisual>

                                    <Text sx={Workplacetheme.listCollabActionList.actionListItem.textField}>vdepizzol</Text>

                                </ActionList.Item>
                                <ActionList.Item sx={Workplacetheme.listCollabActionList.actionListItem}>
                                    <ActionList.LeadingVisual>
                                        <Avatar src="https://github.com/mona.png" />
                                    </ActionList.LeadingVisual>

                                    <Text sx={Workplacetheme.listCollabActionList.actionListItem.textField}>colebemis</Text>

                                </ActionList.Item>
                                <ActionList.Item sx={Workplacetheme.listCollabActionList.actionListItem}>
                                    <ActionList.LeadingVisual>
                                        <Avatar src="https://github.com/hubot.png" />
                                    </ActionList.LeadingVisual>

                                    <Text sx={Workplacetheme.listCollabActionList.actionListItem.textField}>ashygee</Text>

                                </ActionList.Item>
                                <ActionList.Item sx={Workplacetheme.listCollabActionList.actionListItem}>
                                    <ActionList.LeadingVisual>
                                        <Avatar src="https://github.com/mona.png" />
                                    </ActionList.LeadingVisual>

                                    <Text sx={Workplacetheme.listCollabActionList.actionListItem.textField}>langermank</Text>

                                </ActionList.Item>






                            </ActionList>

                        </ActionMenu.Overlay>
                    </ActionMenu>


                    <Box sx={{
                        width:203,
                        height:123,
                        top:'115px',
                        left:0,
                        right:0,
                        margin:'auto',
                        position:'absolute',
                        display:workplaceProjects.length > 0?'none':'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        zIndex:1
                     }}>

                        <Text sx={{

                              fontFamily:'Segoe UI',
                              fontSize:'14px',
                              fontStyle:'normal',
                              fontWeight:'400',
                              lineHeight:'30px',
                              position:'relative',
                              margin:'0px 0px 0px 0x',
                              color:'#586069'
                            


                        }}>This workspace is empty</Text>
                        <Text sx={{
                            width:203,
                            fontFamily: 'Segoe UI',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '30px',
                            position: 'relative',
                            margin: '-32px 0px 0px 0x',
                            textAlign:'center',
                            color:'#586069'
                            


                        }}>
                            Get started by creating a new project !</Text>

                        <Button 
                        variant="outline"
                        sx={{
                            display:'flex',
                            position:'relative',
                            margin:'30px 0px 0px 0px',
                            width:120,
                            height:32,
                            fontFamily: 'Segoe UI',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            background:'#0965CE',
                            color:'#FFFFFF'

                        }}
                        onClick={()=> showCreateSpaceDialog("")}
                        >
                            Create Project
                        </Button>

                    </Box>

                   
                    <Box minHeight={"30px"}>

                    </Box>
                   
                    <SpaceComponent workplaceId={localStorage.getItem("workplaceId")} isPlusButton={workplaceProjects.length<=0?false:true} />
                    


                    {/* <Box

                        sx={workplaceTheme.createWorkplaceSpaceList}
                        style={{
                            display:'none'
                        }}
                    >


                        <Box sx={workplaceTheme.spaceBox}
                            onMouseEnter={() =>

                                setspaceBoxHovered((prevState) => {

                                    const newState = { id: "1", isHovered: true }
                                    return newState

                                })
                            }
                            onMouseLeave={() =>

                                setspaceBoxHovered((prevState) => {
                                    const newState = { id: "", isHovered: false }
                                    return newState
                                })


                            } >

                            <ActionMenu>
                                <ActionMenu.Anchor  >

                                    <Box sx={spaceBoxHovered.isHovered === false ? workplaceTheme.spaceSettingsIcon : workplaceTheme.spaceSettingsIconHover}>

                                        <IconButton
                                            size="small"
                                            variant="invisible"
                                            sx={{ position: 'relative', margin: 'auto auto auto auto', height: 15, width: 15, padding: 0, color: '#000000' }}
                                            icon={() => <DotsHorizontalIcon />} />
                                    </Box>


                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                                        <ActionList.Item onSelect={event => showSharingDialog("")} sx={workplaceTheme.profileActionMenuListItem}>Share</ActionList.Item>
                                        <ActionList.Item onSelect={event => showMoveWorkplaceDialog("")} sx={workplaceTheme.profileActionMenuListItem}>Move</ActionList.Item>
                                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Duplicate</ActionList.Item>
                                        <ActionList.Divider />
                                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Settings</ActionList.Item>
                                        <ActionList.Item
                                            onSelect={event => showDeleteSpaceDialog("")}
                                            variant="danger" sx={workplaceTheme.profileActionMenuListItemDanger}>Delete</ActionList.Item>

                                    </ActionList>
                                </ActionMenu.Overlay>
                            </ActionMenu>


                            <Heading color='#DE2424' sx={workplaceTheme.spaceBoxHeading}>A

                            </Heading>
                            <Text sx={workplaceTheme.spaceBoxText}>Tax Filing and ot..</Text>
                        </Box>




                        <Box sx={workplaceTheme.spaceAddBox} onClick={() => showCreateSpaceDialog("")} >
                            <PlusIcon style={workplaceTheme.spaceBoxAddBoxIcon} />
                        </Box>






                    </Box> */}



                </Box>







            </Box>






            <Box>





            </Box>





        </Box>


        <CreateSpaceDialog />
        <DeleteWorkplaceDialog />
        <ShareDialog />
        <MoveSpaceDialog />
        <SearchDialog/>

    </Box>)

}

export default CreateWorkplace
