import React from 'react';
import { Dialog, Text, TextInput, Button, Box, ActionList,FormControl,Radio,Heading } from '@primer/react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { addOrganizationDirectorsOpenClose, addOrganizationServicesOpenClose, updateTempDirectorSData } from '../../redux/features/contactSlice';



export default function AddOrganizationDirectors(){

    const dispatch = useDispatch()
     
    const isAddOrganizationDirectors = useSelector((state)=> state.contactProfileData.isAddOrganizationDirectors )
    const initialFocusRef = React.createRef(null)

    const firstNameInputRef = React.createRef(null)

    const dobCalandarRef = React.createRef(null)
   
     
    const [directorsInfo0,setdirectorsInfo0] = React.useState([
        
        {
        firstName:"",
        firstNameValidation:"",
        lastName:"",
        lastNameValidation:"",
        emailAddress:"",
        emailAddressValidation:"",
        dob:"",
        pan:"",
        panValidation:"",
        role:"",
        addressline1:"",
        addressline2:"",
        street:"",
        state:"",
        pin:""
    }

])

    const [directorsInfo,setdirectorsInfo] = React.useState([])
    const dobCalandarRefArray = React.useRef([])

    const closeDialog = (props) => {
        
         dispatch(addOrganizationDirectorsOpenClose({
            isAddOrganizationDirectors:false
         }))

         setdirectorsInfo0({
            firstName:"",
            firstNameValidation:"",
            lastName:"",
            lastNameValidation:"",
            emailAddress:"",
            emailAddressValidation:"",
            dob:"",
            pan:"",
            panValidation:"",
            role:"",
            addressline1:"",
            addressline2:"",
            street:"",
            state:"",
            pin:""
        })
        setdirectorsInfo([])

    }

    const nextBtnClick = (props) => {
     
         
 
        if(directorsInfo0[0].firstName === "") {

            setdirectorsInfo0((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === 0) {

                        return { ...dData, firstNameValidation:'error'}
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })



        }else if(directorsInfo0[0].lastName === ""){
            setdirectorsInfo0((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === 0) {

                        return { ...dData, lastNameValidation:'error'}
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })

        }else if(directorsInfo0[0].emailAddress === ""){
            setdirectorsInfo0((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === 0) {

                        return { ...dData, emailAddressValidation:'error'}
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })

        }else if(directorsInfo0[0].pan === ""){
            setdirectorsInfo0((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === 0) {

                        return { ...dData, panValidation:'error'}
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })

        }
        else {


            if(directorsInfo.length > 0){

               

                const isValid = directorsInfo.every(obj => 
                    obj.firstName.trim() !== '' && 
                    obj.lastName.trim() !== '' && 
                    obj.emailAddress.trim() !== '' && 
                    obj.pan.trim() !== ''
                  );

                    



                if(isValid){

                    dispatch(updateTempDirectorSData({
                        directors:directorsInfo0.concat(directorsInfo) 
                    }))
        
                    dispatch(addOrganizationServicesOpenClose({
                        isAddOrganizationServices: true
                    }))


                }else{

                      setdirectorsInfo((prevState)=>{
                   
                             const newState = prevState.map((dData)=>{
                                 
                                     if(dData.firstName===""){

                                         return({...dData,firstNameValidation:'error'})

                                     }else if(dData.lastName===""){

                                        return({...dData,lastNameValidation:'error'})

                                     }else if(dData.emailAddress===""){

                                        return({...dData,emailAddressValidation:'error'})

                                     }else if(dData.pan===""){

                                        return({...dData,panValidation:'error'})
                                     }
                             })
             

                             return newState 


                      })


                }  


            }else{

                dispatch(updateTempDirectorSData({
                    directors:directorsInfo0.concat(directorsInfo) 
                }))
    
              
    
    
                dispatch(addOrganizationServicesOpenClose({
                    isAddOrganizationServices: true
                }))


            }


          
             

        }
        


         
    }

    
    const handleChangeInput = (props,e,index) => {
       
            if(props==="firstName"){

                setdirectorsInfo0((prevState)=>{

                        const newState = prevState.map((dData,oldIndex)=>{

                            if(oldIndex===index){

                                return {...dData,firstName:e.target.value,firstNameValidation:''}
                            }else{
                                return {...dData}
                            }

                       })
                      return newState

                    }) 

            }else if (props === "lastName") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, lastName: e.target.value,lastNameValidation:'' }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "emailAddress") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, emailAddress: e.target.value,emailAddressValidation:'' }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "dob") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, dob: ""+new Date(e.target.value)+"" }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "pan") {


                if(e.target.value.length <=10){


                    setdirectorsInfo0((prevState) => {

                        const newState = prevState.map((dData, oldIndex) => {
    
                            if (oldIndex === index) {
    
                                return { ...dData, pan: e.target.value,panValidation:'' }
                            } else {
                                return { ...dData }
                            }
    
                        })
                        return newState
    
                    })


                }

               


            }else if (props === "role") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, role: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "addressline1") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, addressline1: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "addressline2") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, addressline2: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "street") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, street: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "state") {

                setdirectorsInfo0((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, state: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }else if (props === "pin") {

                if (e.target.value.length <= 6) {


                    setdirectorsInfo0((prevState) => {

                        const newState = prevState.map((dData, oldIndex) => {

                            if (oldIndex === index) {

                                return { ...dData, pin: e.target.value }
                            } else {
                                return { ...dData }
                            }

                        })
                        return newState

                    })


                }

                


            }
     

    }



    const handleChangeInput1 = (props,e,index) => {

        if(props==="firstName"){

           

            setdirectorsInfo((prevState)=>{

                    const newState = prevState.map((dData,oldIndex)=>{

                        if(oldIndex===index){

                            return {...dData,firstName:e.target.value,firstNameValidation:''}
                        }else{
                            return {...dData}
                        }

                   })
                  return newState

                }) 

        }else if (props === "lastName") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, lastName: e.target.value,lastNameValidation:'' }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "emailAddress") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, emailAddress: e.target.value,emailAddressValidation:'' }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "dob") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, dob: ""+new Date(e.target.value)+"" }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "pan") {


            if (e.target.value.length <= 10) {


                setdirectorsInfo((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, pan: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })



            }

            


        }else if (props === "role") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, role: e.target.value }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "addressline1") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, addressline1: e.target.value }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "addressline2") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, addressline2: e.target.value }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "street") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, street: e.target.value }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "state") {

            setdirectorsInfo((prevState) => {

                const newState = prevState.map((dData, oldIndex) => {

                    if (oldIndex === index) {

                        return { ...dData, state: e.target.value }
                    } else {
                        return { ...dData }
                    }

                })
                return newState

            })


        }else if (props === "pin") {


            if (e.target.value <= 6) {


                setdirectorsInfo((prevState) => {

                    const newState = prevState.map((dData, oldIndex) => {

                        if (oldIndex === index) {

                            return { ...dData, pin: e.target.value }
                        } else {
                            return { ...dData }
                        }

                    })
                    return newState

                })


            }

            


        }





    } 



    React.useEffect(()=>{


        if(firstNameInputRef.current!==null){
            firstNameInputRef.current.focus();
        }


    },[isAddOrganizationDirectors])




    return(
        <Dialog
            sx={{height:'508px',width:'820px'}}
            isOpen={isAddOrganizationDirectors}
            onDismiss={() => {
                closeDialog("")
            }}
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                

                <Heading 
                ref={initialFocusRef}
                sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F',
                  
                }}>
                    Add organization
                </Heading>

            </Dialog.Header>


            <Box sx={{
                position:'absolute',
                top:'52px',
                left:'0px',
                right:'0px',
                bottom:'52px',
                display:'flex',
                flexDirection:'column',
                overflowY:'auto',
                overflowX:'hidden'
            }}>

                <Text
                sx={{
                    position:'relative',
                    margin:'10px 25px 0px 25px',   
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    color:'#24292E'
                }}
              

                >Directors information</Text>

                 
 

                <Box
                sx={{
                    position:'relative',
                    margin:'17px 25px 0px 25px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'8px'
                }}
                >

                    <TextInput
                        ref={firstNameInputRef} 
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth:'370px',
                            maxWidth:'370px',
                            width:'370px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='First name'
                        value={directorsInfo0[0]?.firstName}
                        validationStatus={directorsInfo0[0]?.firstNameValidation}
                        onChange={(e)=>handleChangeInput("firstName",e,0)}
                         
                    />

                    <TextInput
                        
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '370px',
                            maxWidth: '370px',
                            width: '370px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Last name'
                        value={directorsInfo0[0]?.lastName}
                        validationStatus={directorsInfo0[0]?.lastNameValidation}
                        onChange={(e)=>handleChangeInput("lastName",e,0)}
                        
                    />

                    


                </Box>

                <Box
                sx={{
                    position:'relative',
                    margin:'14px 25px 0px 25px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'8px'
                }}
                >

                    <TextInput
                         
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth:'514px',
                            maxWidth:'514px',
                            width:'514px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Email address'
                        value={directorsInfo0[0]?.emailAddress}
                        onChange={(e)=>handleChangeInput("emailAddress",e,0)}
                        validationStatus={directorsInfo0[0]?.emailAddressValidation}
                    />

                    <TextInput
                        
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '226px',
                            maxWidth: '226px',
                            width: '226px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='DOB'
                        value={directorsInfo0[0]?.dob!==null&&directorsInfo0[0]?.dob!==""?
                        
                          new Date(directorsInfo0[0]?.dob).getDate()+"/"+(new Date(directorsInfo0[0]?.dob).getMonth()+1)+"/"+new Date(directorsInfo0[0]?.dob).getFullYear()
                        
                        :""}
                        onChange={(e)=>{
                          //  handleChangeInput("dob",e,0)
                            dobCalandarRef.current.showPicker()
                        }}
                        onFocus={()=>{
                              dobCalandarRef.current.showPicker()
                        }}
                        onClick={()=>{
                              dobCalandarRef.current.showPicker()
                        }}
                        
                    />

                    <input
                        type={"date"}
                        ref={dobCalandarRef}
                        onChange={(e) => {

                            if (e.target.value === "" || e.target.value == null) {


                               


                            } else {
                                handleChangeInput("dob", e,0)
                            }


                        }}
                        style={{
                            position: 'absolute',
                            right: '5px',
                            top: '2px',
                            height: '35px',
                            width: '29%',
                            zIndex: '0',
                            visibility: 'hidden'
                        }}

                    />

                    


                </Box>

                <Box
                    sx={{
                        position: 'relative',
                        margin: '14px 25px 0px 25px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px'
                    }}
                >

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '365px',
                            maxWidth: '365px',
                            width: '356px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='PAN'
                        value={directorsInfo0[0]?.pan}
                        onChange={(e)=>handleChangeInput("pan",e,0)}
                        validationStatus={directorsInfo0[0]?.panValidation}
                    />

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '370px',
                            maxWidth: '370px',
                            width: '370px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Role  (eg: CEO , Director , Owner) '
                        value={directorsInfo0[0]?.role}
                        onChange={(e)=>handleChangeInput("role",e,0)}
                        
                    />




                </Box>

                <TextInput
                   
                    sx={{
                        position: 'relative',
                        margin: '14px 25px 0px 25px',
                        minHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        minWidth:'750px',
                        maxWidth:'750px',
                        width:'750px',
                    }}
                    placeholder='Address line 1 '
                    value={directorsInfo0[0]?.addressline1}
                    onChange={(e)=>handleChangeInput("addressline1",e,0)}
                    
                />
                 <TextInput
                   
                   sx={{
                       position: 'relative',
                       margin: '14px 25px 0px 25px',
                       minHeight: '40px',
                       fontFamily: 'Segoe UI',
                       fontStyle: 'normal',
                       fontWeight: '400',
                       fontSize: '14px',
                       lineHeight: '20px',
                       minWidth:'750px',
                       maxWidth:'750px',
                       width:'750px',
                   }}
                   placeholder='Address line 2'
                   value={directorsInfo0[0]?.addressline2}
                   onChange={(e)=>handleChangeInput("addressline2",e,0)}
                   
               />

                <Box
                    sx={{
                        display:'flex',
                        position: 'relative',
                        margin: '14px 25px 0px 25px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '28px'
                    }}
                >

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '230px',
                            maxWidth: '230px',
                            width: '230px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Street'
                        value={directorsInfo0[0]?.street}
                        onChange={(e)=>handleChangeInput("street",e,0)}
                        
                    />

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '230px',
                            maxWidth: '230px',
                            width: '230px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='State'
                        value={directorsInfo0[0]?.state}
                        onChange={(e)=>handleChangeInput("state",e,0)}
                         
                    />

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '230px',
                            maxWidth: '230px',
                            width: '230px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='PIN'
                        value={directorsInfo0[0]?.pin}
                        onChange={(e)=>handleChangeInput("pin",e,0)}
                         
                    />




                </Box>


                {
                    directorsInfo.map((row,index)=>{

                          

                          return(

                              <Box>

                                  <Box
                                      sx={{
                                          position: 'relative',
                                          margin: '15px 25px 0px 25px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          gap: '8px'
                                      }}
                                  >

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '370px',
                                              maxWidth: '370px',
                                              width: '370px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='First name'
                                          value={row.firstName}
                                          validationStatus={row.firstNameValidation}
                                          onChange={(e)=>handleChangeInput1("firstName",e,index)}
                                      />

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '370px',
                                              maxWidth: '370px',
                                              width: '370px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='Last name'
                                          value={row.lastName}
                                          validationStatus={row.lastNameValidation}
                                          onChange={(e)=>handleChangeInput1("lastName",e,index)}
                                      />




                                  </Box>

                                  <Box
                                      sx={{
                                          position: 'relative',
                                          margin: '14px 25px 0px 25px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          gap: '8px'
                                      }}
                                  >

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '514px',
                                              maxWidth: '514px',
                                              width: '514px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='Email address'
                                          value={row.emailAddress}
                                          validationStatus={row.emailAddressValidation}
                                          onChange={(e)=>handleChangeInput1("emailAddress",e,index)}
                                      />

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '226px',
                                              maxWidth: '226px',
                                              width: '226px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='DOB'
                                         // value={row.dob}
                                          value={row.dob !== null && row.dob !== "" ?

                                              new Date(row.dob).getDate() + "/" + (new Date(row.dob).getMonth() + 1) + "/" + new Date(row.dob).getFullYear()

                                              : ""}
                                          onChange={(e)=>
                                            
                                             
                                              dobCalandarRefArray.current[index].showPicker()
                                          }
                                          onClick={()=>{
                                              dobCalandarRefArray.current[index].showPicker()
                                          }}
                                          onFocus={()=>{
                                              dobCalandarRefArray.current[index].showPicker()
                                          }}
                                      />
                                      <input
                                          type={"date"}
                                          ref={(ref)=> dobCalandarRefArray.current[index]=ref}
                                          onChange={(e) => {

                                              if (e.target.value === "" || e.target.value == null) {

      
                                              } else {
                                                  handleChangeInput1("dob",e,index)
                                              }


                                          }}
                                          style={{
                                              position: 'absolute',
                                              right: '5px',
                                              top: '2px',
                                              height: '35px',
                                              width: '29%',
                                              zIndex: '0',
                                              visibility: 'hidden'
                                          }}

                                      />



                                  </Box>

                                  <Box
                                      sx={{
                                          position: 'relative',
                                          margin: '14px 25px 0px 25px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          gap: '13px'
                                      }}
                                  >

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '365px',
                                              maxWidth: '365px',
                                              width: '356px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='PAN'
                                          value={row.pan}
                                          validationStatus={row.panValidation}
                                          onChange={(e)=>handleChangeInput1("pan",e,index)}
                                      />

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '370px',
                                              maxWidth: '370px',
                                              width: '370px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='Role  (eg: CEO , Director , Owner) '
                                          value={row.role}
                                          onChange={(e)=>handleChangeInput1("role",e,index)}
                                      />




                                  </Box>

                                  <TextInput

                                      sx={{
                                          position: 'relative',
                                          margin: '14px 25px 0px 25px',
                                          minHeight: '40px',
                                          fontFamily: 'Segoe UI',
                                          fontStyle: 'normal',
                                          fontWeight: '400',
                                          fontSize: '14px',
                                          lineHeight: '20px',
                                          minWidth: '750px',
                                          maxWidth: '750px',
                                          width: '750px',
                                      }}
                                      placeholder='Address line 1 '
                                      value={row.addressline1}
                                      onChange={(e)=>handleChangeInput1("addressline1",e,index)}
                                  />
                                  <TextInput

                                      sx={{
                                          position: 'relative',
                                          margin: '14px 25px 0px 25px',
                                          minHeight: '40px',
                                          fontFamily: 'Segoe UI',
                                          fontStyle: 'normal',
                                          fontWeight: '400',
                                          fontSize: '14px',
                                          lineHeight: '20px',
                                          minWidth: '750px',
                                          maxWidth: '750px',
                                          width: '750px',
                                      }}
                                      placeholder='Address line 2'
                                      value={row.addressline2}
                                      onChange={(e)=>handleChangeInput1("addressline2",e,index)}
                                  />

                                  <Box
                                      sx={{
                                          display: 'flex',
                                          position: 'relative',
                                          margin: '14px 25px 0px 25px',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          gap: '28px'
                                      }}
                                  >

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '230px',
                                              maxWidth: '230px',
                                              width: '230px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='Street'
                                          value={row.street}
                                          onChange={(e)=>handleChangeInput1("street",e,index)}
                                      />

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '230px',
                                              maxWidth: '230px',
                                              width: '230px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='State'
                                          value={row.state}
                                          onChange={(e)=>handleChangeInput1("state",e,index)}
                                      />

                                      <TextInput

                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              minHeight: '40px',
                                              minWidth: '230px',
                                              maxWidth: '230px',
                                              width: '230px',
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                          }}
                                          placeholder='PIN'
                                          value={row.pin}
                                          onChange={(e)=>handleChangeInput1("pin",e,index)}
                                      />




                                  </Box>

                              </Box>

                          )

                    })
                }
               

                <Button
                    variant="invisible"
                    sx={{
                        
                        position: 'relative',
                        margin: '10px 0px 0px 20px',
                        width:'192px',
                        height:'32px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                    onClick={()=>{
                        setdirectorsInfo((prevState)=>{

                            const newState = [...prevState, {
                                firstName:"",
                                firstNameValidation:"",
                                lastName:"",
                                lastNameValidation:"",
                                emailAddress:"",
                                emailAddressValidation:"",
                                dob:"",
                                pan:"",
                                panValidation:"",
                                role:"",
                                addressline1:"",
                                addressline2:"",
                                street:"",
                                state:"",
                                pin:""
                            }]
                            return newState
                        })
                    }}
                    >
                    Add directors information
                </Button>
 
              
            </Box>

            



            <Box sx={{
                 borderTopWidth: 1,
                 borderTopStyle: 'solid', 
                 borderColor: 'border.default', 
                 position:'absolute',
                 bottom:'0px',
                 left:'0px',
                 right:'0px',
                 height:'52px',
                 display:'flex',
                 flexDirection:'row',

                 }}>
                <Button 
                variant="outline"
                sx={{
                    width:'62px',
                    height:'32px',
                    background:'#0965CE',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center' ,
                    position:'relative',
                    margin:'10px 25px auto auto',
                    color:'#ffffff',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                   
                }}
                onClick={()=>{
                   nextBtnClick("")
                }}
                >
                    Next
                </Button>
            </Box>


        </Dialog>
    )

}