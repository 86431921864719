import React from "react";
import { Helmet } from "react-helmet";
import { Box, Text, Button, Heading, IconButton, Label, Tooltip, Portal, ActionMenu, ActionList, FormControl, Checkbox } from "@primer/react"
import SpaceHeader from "./SpaceHeader"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import { useDispatch, useSelector } from "react-redux";
import Papa from 'papaparse';
import { getProjectServices } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position,ProgressBar } from "@blueprintjs/core";
import { postCompanyBulk } from "../redux/features/contactSlice";
//import * as XLSX from 'https://unpkg.com/xlsx/xlsx.mjs';



const tableColumn = {
      width:'200px',
      minWidth:'150px',
      fontFamily:'Inter',
      fontSize:'14px',
}

const synchingToast = Toaster.create({ position: Position.TOP })

function ContactBulk(props){
  
    const XLSX = window.XLSX

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
     
    const computerFileRef = React.useRef()
    const [selectedFileData,setselectedFileData] = React.useState([])
    const [compliances,setcompliances] = React.useState([])

    // loading toast method from with progress bar
    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }

    // process excel sheet file 
    function processWorkbook(workbook) {
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = window.XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // console.log(jsonData);

        const dataObjects = jsonData.map((row) => {
            const obj = {};
            row.forEach((cell, index) => {
                const header = jsonData[0][index]; // Assuming first row contains headers
                obj[header] = cell;
            });
            return obj;
        });

        return dataObjects
    }
 
 
    // pick excel file from local computer
    const handlePickComputerFile = (selectedFile) => {

        console.log("selectedFile", selectedFile)
        const file = selectedFile;
        if (file) {
            setselectedFileData([])
            loadingToast(10, "1");
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    // Optimize the processWorkbook function if needed
                    const results = [...processWorkbook(workbook)]
                        .filter(org => Object.keys(org).length > 0)
                        .map((row) => ({
                            ...row,
                            id: Math.floor(10000000 + Math.random() * 90000000),
                            compliances: [],
                        }));

                    console.log("results", results);

                    // Use slice(1) to skip the header if it exists
                    const processesArray = results.slice(1);
                    console.log("processesArray", processesArray);
                    setselectedFileData(processesArray);
                    loadingToast(100, "1");



                } catch (error) {
                    console.error("Error processing the file:", error);
                }
            };
            reader.readAsArrayBuffer(file);
        }


    }
 
    // get all project compliances
    const getAllCategories = async () => {
        try {
            const { payload } = await dispatch(getProjectServices({
                userId,
                userEmail,
                projectId: localStorage.getItem("projectId")
            }));
    
            console.log("getProjectServices", payload.data);
    
            const complianceArray = payload.data.map(row => ({
                due_date: "",
                isChecked: false,
                timestamp: row.subcategory.timestamp,
                creator_id: row.subcategory.creator_id,
                category_id: row.subcategory.category_id,
                billing_type: row.subcategory.billing_type,
                filingPeriod: row.subcategory.filingPeriod,
                remindMeType: row.subcategory.filingPeriod, // Note: Should this be row.subcategory.remindMeType?
                amountPayable: "",
                compliance_id: row.subcategory.compliance_id,
                complianceType: row.subcategory.complianceType,
                compliance_name: row.subcategory.compliance_name,
            }));
    
            console.log("complianceArray", complianceArray);
            setcompliances(complianceArray);
        } catch (error) {

            console.error("Error fetching project services:", error);
            
        }
    };

    // updateCompliace into existing array
    const updateComplianceIntoArray = (com, fData, isChecked) => {

        console.log("beforeupdating", selectedFileData)
        setselectedFileData((prevState) =>
            prevState.map((row) =>
                row.id === fData.id
                    ? {
                        ...row,
                        compliances: isChecked
                            ? [...row.compliances, com]
                            : row.compliances.filter((cData) => cData.compliance_id !== com.compliance_id),
                    }
                    : row
            )
        );
       
        
    };
      
    // handleSubmitBulkContactData to db
    const handleSubmitBulkContactData = async (selectedData) => {
        try {
            if (selectedData.length === 0) {
                Toaster.create({ position: Position.TOP }).show({
                    message: "Empty data",
                    intent: Intent.PRIMARY,
                });
            } else {
                const allHaveComplianceData = selectedData.every(
                    (organization) => organization.compliances && organization.compliances.length > 0
                );
    
                if (allHaveComplianceData) {
                    loadingToast(10, "1");

                    const { payload } = await dispatch(postCompanyBulk({
                        userId,
                        userEmail,
                        projectId: localStorage.getItem("projectId"),
                        bulkCompanyData: selectedData,
                    }));

                    console.log("postCompanyBulk", payload.data);

                    const responseData = payload.data;
                    if (responseData.length > 0) {
                        Toaster.create({ position: Position.TOP }).show({
                            message: "Successfully Saved",
                            intent: Intent.SUCCESS,
                        });
                        setselectedFileData([]);
                        loadingToast(100, "1");
                    }
                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Select compliance in all companies",
                        intent: Intent.PRIMARY,
                    });

                }
            }
        } catch (error) {
            console.error("Error handling bulk contact data:", error);
            
        }
    };
    
    React.useEffect(() => {

           getAllCategories("")

    }, [])

    return (
        <Box
           
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"hidden"}
        >
             <Helmet>
                <title>
                    {localStorage.getItem("projectName")||""} - Clients Bulk
                </title>
                {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.16.0/xlsx.full.min.js"></script> */}
            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />


            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
            >

                <Box sx={{
                position: 'absolute',
                left: '26px',
                right: '61px',
                top: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                }}>

                    <Heading sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '18px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 0px'
                    }}>
                        Companies and individuals
                    </Heading>

                    <Button sx={{
                        height: '32px',
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#24292E',
                        position: 'absolute',
                        right: '105px'
                    }}
                        onClick={() => {

                            computerFileRef.current.click()
                        }}
                    >
                        Select File
                    </Button>

                    <Button
                        variant="outline"
                        sx={{
                            height: '32px',
                            fontFamily: 'Avenir LT Std',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#24292E',
                            position: 'absolute',
                            right: '26px',
                            background: '#0965CE',
                            color: 'white',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}
                        onClick={() => {
                            handleSubmitBulkContactData(selectedFileData)
                        }}
                    >
                        Save
                    </Button>
                    <input
                        ref={computerFileRef}
                        type={"file"}
                        accept=".xlsx"
                        onChange={(e) => handlePickComputerFile(e.target.files[0])}
                        hidden
                    />
                

                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '26px',
                    top: '55px',
                    display: 'flex',
                    flexDirection:'column',
                    height: '200px',
                    maxHeight: 'auto'

                }}>
                    {/* Header  */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        height: '30px',
                        minHeight: '30px',
                        fontWeight: 'bold'
                    }}>
                        <Box sx={tableColumn}>
                            Organization
                        </Box>
                        <Box sx={tableColumn}>
                            OrganizationType
                        </Box>
                        <Box sx={tableColumn}>
                            Compliance
                        </Box>
                        <Box sx={tableColumn}>
                            CIN
                        </Box>
                        <Box sx={tableColumn}>
                            State
                        </Box>
                        <Box sx={tableColumn}>
                            IncorporationDate
                        </Box>
                        <Box sx={tableColumn}>
                            PAN
                        </Box>
                        <Box sx={tableColumn}>
                            GSTIN
                        </Box>
                        <Box sx={tableColumn}>
                            AdditionalInformation
                        </Box>
                        <Box sx={tableColumn}>
                            Addressline1
                        </Box>
                        <Box sx={tableColumn}>
                            Addressline2
                        </Box>
                        <Box sx={tableColumn}>
                            Addressline3
                        </Box>
                        <Box sx={tableColumn}>
                            City
                        </Box>
                        <Box sx={tableColumn}>
                            StateProvince
                        </Box>
                        <Box sx={tableColumn}>
                            Postal
                        </Box>
                    </Box>
                    {selectedFileData.map((fData, findex) => {
                        return (
                            <Box
                                key={fData.id}
                                sx={{

                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    height: 'auto',
                                    minHeight: 'auto',
                                    maxHeight: 'auto',
                                    margin: '10px 0px 10px 0px',

                                }}>
                                <Box sx={tableColumn}>
                                    {(findex + 1) + "."}{fData?.Organization}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.OrganizationType}
                                </Box>
                                <Box
                                    sx={tableColumn}
                                    style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
                                >
                                    {
                                        compliances
                                            .map((com, comindex) => {
                                                return (
                                                    <Box as="form"
                                                        key={fData.id + "" + com.compliance_id}
                                                        sx={{ fontSize: '12px' }}
                                                    >
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={[...fData.compliances].find((cData) => cData.compliance_id === com.compliance_id)}
                                                                value={com.compliance_name}
                                                                onChange={(e) => { updateComplianceIntoArray(com, fData, e.target.checked) }}
                                                            />
                                                            <FormControl.Label>{com.compliance_name}</FormControl.Label>
                                                        </FormControl>
                                                    </Box>
                                                )
                                            })
                                    }
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.CIN}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.State}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.IncorporationDate}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.PAN}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.GSTIN}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.AdditionalInformation}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.Addressline1}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.Addressline2}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.Addressline3}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.City}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.StateProvince}
                                </Box>
                                <Box sx={tableColumn}>
                                    {fData?.Postal}
                                </Box>
                            </Box>
                        )
                    })}

                </Box> 
                
           
                  

            </Box>

        </Box>

    )

}

export default ContactBulk