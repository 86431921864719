import {configureStore} from "@reduxjs/toolkit"
import scheduDemoReducer from "./features/scheduleDemoSlice"
import spaceReducer from "./features/spaceSlice"
import sharingReducer from "./features/sharingSlice"
import workplaceReducer from "./features/workplaceSlice"
import searchReducer from "./features/searchSlice"
import spaceHeaderReducer from "./features/spaceHeaderSlice"
import profileReducer from "./features/profileSlice"
import taskReducer from "./features/taskSlice"
import headerReducer from "./features/headerSlice"
import feedReducer from "./features/feedSlice"
import contactProfileReducer from "./features/contactSlice"
import portalReducer from "./features/portalSlice"

export default configureStore({

    reducer:{
         openCloseScheduleDemo:scheduDemoReducer,
         spaceData:spaceReducer,
         sharingData:sharingReducer,
         workplaceData:workplaceReducer,
         searchData:searchReducer,
         spaceHeaderData:spaceHeaderReducer,
         profileData:profileReducer,
         taskData:taskReducer,
         headerData:headerReducer,
         feedData:feedReducer,
         contactProfileData:contactProfileReducer,
         portalData:portalReducer
    }

})