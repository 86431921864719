import React from 'react'
import { useNavigate,useLocation } from 'react-router-dom';
import {TasklistIcon,TelescopeIcon,AppsIcon,StackIcon,GearIcon } from '@primer/octicons-react'
import {Box, Button,Text,IconButton } from '@primer/react'
import Workplacetheme from '../Workplace/Workplacetheme';

 
function SpaceLeftSideNav(){

        const navigate = useNavigate()
        const location = useLocation();

        const [selectedSideMenu,setselectedSideMenu] = React.useState("5")

        
        





        React.useEffect(()=>{

             if(location.pathname==="/space/"+localStorage.getItem("projectName")){

                  setselectedSideMenu("0")
             }else if(location.pathname==="/report"){

                  setselectedSideMenu("1")
             }else if(location.pathname==="/apps"){
                
                  setselectedSideMenu("2")
             }else if(location.pathname==="/rules"){
                
                setselectedSideMenu("3")
             }else if(location.pathname==="/settings"){
                
                setselectedSideMenu("4")
             }else{

                setselectedSideMenu("0")
             }     


        },[location])



        

     
        return(<Box
                sx={Workplacetheme.sideNavMain}
            >

            

            <Box sx={Workplacetheme.sideNavIconset}>
                
                <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={()=> 
                
                {
                    setselectedSideMenu("0")
                    navigate('/space/'+localStorage.getItem("projectName"))
                }
                
               } 
               onMouseEnter={() => {}} 
               onMouseLeave={() => {}}
                  
                >

                    <IconButton
                        sx={selectedSideMenu === "0" ? Workplacetheme.sideNavIconsetIconBox.iconButtonSelected : Workplacetheme.sideNavIconsetIconBox.iconButton}

                        icon={() => <TasklistIcon  size={14} />}>
                    </IconButton>

                    <Text sx={selectedSideMenu === "0" ? Workplacetheme.sideNavIconsetIconBox.textSelected : Workplacetheme.sideNavIconsetIconBox.text}
                    >Works</Text>

                </Box>

                   <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={()=> {
                    setselectedSideMenu("1")
                    navigate('/report')
                   } }>

                   <IconButton
                        sx={selectedSideMenu === "1" ? Workplacetheme.sideNavIconsetIconBox.iconButtonSelected : Workplacetheme.sideNavIconsetIconBox.iconButton}

                        icon={() => <TelescopeIcon   size={14} />}>
                    </IconButton>

                    <Text sx={selectedSideMenu === "1" ? Workplacetheme.sideNavIconsetIconBox.textSelected : Workplacetheme.sideNavIconsetIconBox.text}
                    >
                        {/* Roadmap */}
                        Report
                    </Text>

                    

                </Box>

                <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={()=> {
                    setselectedSideMenu("2")
                    navigate('/apps')
                } }>

                    <IconButton
                        sx={selectedSideMenu === "2" ? Workplacetheme.sideNavIconsetIconBox.iconButtonSelected : Workplacetheme.sideNavIconsetIconBox.iconButton}

                        icon={() => <AppsIcon   size={14} />}>
                    </IconButton>

                    <Text sx={selectedSideMenu === "2" ? Workplacetheme.sideNavIconsetIconBox.textSelected : Workplacetheme.sideNavIconsetIconBox.text}
                    >Apps</Text>

                </Box>

                <Box sx={Workplacetheme.sideNavIconsetIconBox} onClick={()=> {
                    setselectedSideMenu("3")
                    navigate('/rules')
                } }>

                     <IconButton
                        sx={selectedSideMenu === "3" ? Workplacetheme.sideNavIconsetIconBox.iconButtonSelected : Workplacetheme.sideNavIconsetIconBox.iconButton}

                        icon={() => <StackIcon   size={14} />}>
                    </IconButton>

                    <Text sx={selectedSideMenu === "3" ? Workplacetheme.sideNavIconsetIconBox.textSelected : Workplacetheme.sideNavIconsetIconBox.text}
                    >
                        {/* Rules */}
                        Service
                    </Text>

                </Box>

                <Box 
                sx={Workplacetheme.sideNavIconsetIconBox} 
                onClick={()=> 
                    {
                        setselectedSideMenu("4")
                        navigate('/settings')
                    }
                    
                }
                >

                    <IconButton
                        sx={selectedSideMenu === "4" ? Workplacetheme.sideNavIconsetIconBox.iconButtonSelected : Workplacetheme.sideNavIconsetIconBox.iconButton}

                        icon={() => <GearIcon   size={14} />}>
                    </IconButton>

                    <Text sx={selectedSideMenu === "4" ? Workplacetheme.sideNavIconsetIconBox.textSelected : Workplacetheme.sideNavIconsetIconBox.text}
                    >Settings</Text>

                </Box>

                

            </Box>
            

        </Box>)

}

export default SpaceLeftSideNav