import React from 'react';
import { Dialog, Text, TextInput, Button, Box, ActionList,FormControl,Radio,Heading,Checkbox,Spinner } from '@primer/react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, Intent,Position } from "@blueprintjs/core";
import {addOrganizationDirectorsOpenClose, addOrganizationOpenClose, addOrganizationServicesOpenClose,updateTempOrgData,updateTempDirectorSData, postZohoInvCustomer } from '../../redux/features/contactSlice';
import { getProjectServices } from '../../redux/features/spaceSlice';
import { postContactDirectory } from '../../redux/features/taskSlice';



export default function AddOrganizationServices(){

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const zohoTokens   = JSON.parse(localStorage.getItem("zohoTokens")) || ""
        
    
    const orgData = useSelector((state)=> state.contactProfileData.orgData) 
    const directors = useSelector((state)=> state.contactProfileData.directors)

    const [showLoading,setshowLoading] = React.useState(false)
    const isAddOrganizationServices = useSelector((state)=> state.contactProfileData.isAddOrganizationServices )
    const initialFocusRef = React.createRef(null)
    const [projectServices,setprojectServices] = React.useState([])
    const [compliances,setcompliances] = React.useState([])

    const [selectedprojectServices,setselectedprojectServices] = React.useState([])
    const [selectedCompliace,setselectedCompliace] = React.useState([])
   
     

    const handleChangeService = (props, isChecked) => {

        setprojectServices((prevState) => {

            const newState = prevState.map((services) =>
                services.category_id === props.category_id ?
                    ({ ...services, isChecked: isChecked }) : services

            )
            return newState

        })

         
        
         if(isChecked===true){
              
              
          
             setselectedprojectServices((prevState)=>{

                 console.log("setselectedprojectServices prevState",prevState)

                const newState = [...prevState,{...props,isChecked:true}]

                console.log("setselectedprojectServices newState",newState)

                return newState
             })

          
   
             setselectedCompliace((prevState)=>{

                   const newCompliance = compliances.filter((cData)=> cData.category_id===props.category_id).map((cData,index)=> {
                       return ({...cData,isChecked:true})
                   })

                    const newState =   prevState.concat(newCompliance)
                    return newState

             })

             


         }else{

              
         
             setselectedprojectServices((prevState)=>{

                   const newState = prevState.filter((services)=> services.category_id!==props.category_id)
                   return newState

             })

             setselectedCompliace((prevState)=>{

                   const newState = prevState.filter((cData)=> cData.category_id!==props.category_id)
                   return newState
                   
             })

             


         } 

         
 

    }

    const onChangeIncomTaxChecked = (props, isChecked) => {

           

        setselectedCompliace((prevState) => {

            const newState = prevState.map((services) =>


                services.compliance_id === props.compliance_id ?
                    ({ ...services, isChecked: isChecked }) : services


            )
            return newState

        })
    }

    const closeDialog = (props) => {

        dispatch(addOrganizationServicesOpenClose({
            isAddOrganizationServices: false
        }))
    }

    const getProjectServicesFromDb = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


               const newServices = data.payload?.category?.map((services) => ({ ...services, isChecked: false }))
               setprojectServices(newServices)
               
               const newSubcategory = data.payload?.data?.map((services) => ({ ...services.subcategory, isChecked: false }))
               setcompliances(newSubcategory)

           


        })

    }

    const addToZohoCustomer = (props) => {

        const customerId = orgData.org_name
        const customerName = orgData.org_name
       
         
        dispatch(postZohoInvCustomer({
            userId: userId,
            userEmail: userEmail,
            zohoToken: zohoTokens,
            customerId: customerId,
            customerName: customerName,
            projectId:localStorage.getItem("projectId")
        })).then((data) => {

            console.log("postZohoInvCustomer", data.payload)
            
            const code = data?.payload?.createContactResponse?.data?.code
            const message = data?.payload?.createContactResponse?.data?.message
            if(code==9017){

                Toaster.create({ position: Position.TOP }).show({
                    message: "Zoho :" + message,
                    intent: Intent.PRIMARY
                });

            }else if(code==57){



            }else{


                setTimeout(() => {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Zoho : " + message,
                        intent: Intent.PRIMARY
                    });

                }, 2000)

              

            } 




 

        })  
           



    }

    const handleCreateBtnClick = (props) => {

         
       
        if (orgData.isAddtoZohoCustomer === true) {
      
            addToZohoCustomer("")

        }  


        setshowLoading(true)
         
        dispatch(postContactDirectory({
            userId: userId,
            userEmail: userEmail,
            contactType: "Organization",
            contactData: orgData,
            directorsInfo: directors,
            categories: selectedprojectServices,
            compliances: selectedCompliace,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            
            Toaster.create({ position: Position.TOP }).show({
                message: "Successfully created",
                intent: Intent.SUCCESS
            });


            setshowLoading(false)
            dispatch(updateTempOrgData({
                orgData: {}
            }))
            dispatch(updateTempDirectorSData({
                directors: []
            }))


            dispatch(addOrganizationOpenClose({
                isAddOrganization: false
            }))

            dispatch(addOrganizationDirectorsOpenClose({
                isAddOrganizationDirectors: false
            }))

            closeDialog("")

        })



    }


    React.useEffect(()=>{

     
        getProjectServicesFromDb("")


    },[isAddOrganizationServices])


    return(
        <Dialog
            sx={{height:'508px',width:'820px'}}
           // isOpen={true} 
            isOpen={isAddOrganizationServices}
            onDismiss={() => {
                closeDialog("")
            }}
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                

                <Heading 
               
                sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F',
                  
                }}>
                    Add organization
                </Heading>

            </Dialog.Header>


             
            <Box sx={{
                position:'absolute',
                top:'52px',
                left:'0px',
                right:'0px',
                bottom:'52px',
                display:'flex',
                flexDirection:'column',
                overflowY:'auto',
                overflowX:'hidden'
            }}>

                <Text
                sx={{
                    position:'relative',
                    margin:'10px 25px 0px 25px',   
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    color:'#24292E'
                }}
                ref={initialFocusRef}
                >Compliance services provided
                
                </Text>
                
                
                </Box>

                <Box
                sx={{
                    position: 'absolute',
                    left: '25px',
                    right: '40px',
                    top: '110px',
                    bottom: '126px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0px',
                   
                }}
            >

                <Box sx={{
                    width: '240px',
                    height: '249px',
                    minWidth: '240px',
                    maxWidth: '240px',
                    minHeight: '249px',
                    maxHeight: '249px',
                    background: '#FFFFFF',
                    border: '1px solid #BFBFBF',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                    borderRadius: '4px',
                    overflowX:'hidden',
                    overflowY:'auto'
                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '25px 0px 0px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>

                        {
                            
                            projectServices
                            .map((row, index) => {

                                return (

                                    <FormControl>
                                        <Checkbox id="default-checkbox" checked={row.isChecked} onChange={(e)=> handleChangeService(row,e.target.checked) } />
                                        <FormControl.Label
                                            sx={{ fontFamily: 'Segoe UI', fontStyle: 'normal', fontWeight: '600', fontSize: '14px', lineHeight: '20px', color: '#24292E' }}
                                        >{row.category_name}</FormControl.Label>
                                    </FormControl>
                                )

                            })


                        }

                         

                    </Box>



                </Box>

               


                <Box sx={{
                  //  background:'red',
                    marginLeft: '73px',
                    
                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px'
                    }}>

                        <Text sx={{
                            minWidth: '480px',
                            display: 'flex',
                            position: 'relative',
                            margin: '0px 0px -3px 0px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#24292E',
                        }}>
                            
                            {selectedprojectServices[selectedprojectServices?.length-1]?.category_name}
                        </Text>
                         
 

                        {
                            selectedCompliace?.filter((subCat) => subCat?.category_id===selectedprojectServices[selectedprojectServices?.length-1]?.category_id ).map((row, index) => {

                                return (
                                    <Box sx={{ flexBasis: 'calc(50% - 10px)' }}>

                                        <FormControl>
                                            <Checkbox id="default-checkbox" checked={row.isChecked}
                                                onChange={(e) => {
                                                    onChangeIncomTaxChecked(row, e.target.checked)
                                                }}
                                            />
                                            <FormControl.Label sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E'
                                            }}>{row.compliance_name}</FormControl.Label>
                                        </FormControl>

                                    </Box>
                                )
                            })
                        }






                    </Box>

                </Box>

                

            </Box>


            
 
            <Box sx={{
                 borderTopWidth: 1,
                 borderTopStyle: 'solid', 
                 borderColor: 'border.default', 
                 position:'absolute',
                 bottom:'0px',
                 left:'0px',
                 right:'0px',
                 height:'52px',
                 display:'flex',
                 flexDirection:'row',

                 }}>

                 {showLoading===true?
                    <Button
                        variant="outline"
                        sx={{
                            width: '62px',
                            height: '32px',
                            background: '#0965CE',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            margin: '10px 25px auto auto',
                            color: '#ffffff',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        
                    >
                       <Spinner sx={{
                            position: 'absolute',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            top: '0px',
                            bottom: '0px',
                        }} size="small" />
                    </Button>
                 
                  :
                    <Button
                        variant="outline"
                        sx={{
                            width: '62px',
                            height: '32px',
                            background: '#0965CE',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            margin: '10px 25px auto auto',
                            color: '#ffffff',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',

                        }}
                        onClick={() => handleCreateBtnClick("")}
                    >
                        Create
                        
                    </Button>

                  }

                
            </Box>


        </Dialog>
    )

}