import { Box, Button,Breadcrumbs,Text } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../Space/EmptyData"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch,useSelector } from "react-redux"


function TemplateActivity(props){

    let dispatch = useDispatch()

    return(
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title> Activity - info - deski </title>
            </Helmet>

            <Header />
            <SideNav />


            <EmptyData
                position="fixed"
                top="158px"
                left="0px"
                right="0px"
            />




        </Box>
    )

}

export default TemplateActivity