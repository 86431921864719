import React from "react"
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Text, TextInput, Button, Spinner,Link,FormControl,Checkbox } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import Workplacetheme from "../Workplacetheme";
 
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { gstDataSyncOpenClose } from "../../redux/features/contactSlice";

function GSTSyncDialog(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const dataSyncOpenClose = useSelector((state) => state.contactProfileData)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()
    
  
    const [currentRef, setcurrentRef] = React.useState("panName")

    const [panNameInput, setpanNameInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    
    const closeDataSyncDialog = (props) => {

         dispatch(gstDataSyncOpenClose({
            dataSyncOpenClose:false
         }))

       

    }

    


  


    React.useEffect(()=>{

        
       
            setcurrentRef((prevState)=>{
                   
                    return "panName"
            })
        



    },[dataSyncOpenClose])


    return (

        <Dialog
            sx={{
                height:'439px',
                width:'564px',
                 
            }}
            
            isOpen={dataSyncOpenClose.dataSyncOpenClose}
            onDismiss={() =>

                closeDataSyncDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text sx={Workplacetheme.scheduleDalogeHeading}>Data Sync</Text>

            </Dialog.Header>

            <Box sx={{
                  display:'flex',
                  flexDirection:'column',
                  width:564,
                  height:'fit-content',
                  
            }}>

                <Text sx={{
                        display:'flex',
                        position:'relative',
                        margin:'15px 20px 0px 15px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight:'400',
                        fontSize: '14px',
                        lineHeight:'20px',
                        color:'#6A737D',
                    }}>Once synced for a GST account, you'll have to wait for the next 8 hrs to sync again.</Text>

                    
                    <Box sx={{
                         position:'relative',
                         margin:'40px 40px 0px 23px',
                         display:'flex',
                         flexWrap:'wrap',
                         gap:'20px'
                    }}>

                    <Box sx={{flexBasis:'calc(50% - 20px)'}}>

                        <FormControl
                        
                        >
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Demand</FormControl.Label>
                        </FormControl>

                    </Box>
                     <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Notices</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Refund</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label
                              sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            >Notices files</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label
                              sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            >Refund cases</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label
                              sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            >Additional notices</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label
                              sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            >Returns</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Notices files</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Returns files</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Challan</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl
                           
                        >
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label   sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}>Electronic Ledger</FormControl.Label>
                        </FormControl>


                      </Box>
                      <Box sx={{flexBasis:'calc(50% - 20px)'}}>
                         <FormControl>
                            <Checkbox id="default-checkbox" />
                            <FormControl.Label
                            sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            >Profile</FormControl.Label>
                        </FormControl>


                      </Box>

                          
                        

                    </Box>

                

                <Box
                   
                    sx={{
                    position:'absolute',
                    bottom:'52px',
                    left:'0px',
                    right:'0px',
                    }}
                    borderColor="border.default"
                    borderBottomWidth={1} 
                    borderBottomStyle="solid">

                        
                    </Box>





            </Box>

            <Box
                sx={{
                    position:'absolute',
                    bottom:'10px',
                    left:'20px',
                    right:'20px',
                    
                }}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >
 

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button
                            style={{width:'92px'}}
                            variant="outline"
                          
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() =>  
                             {
                                closeDataSyncDialog("")
                             }
                            }
                        >
                             
                             Sync now
                        </Button>


                }

               

                


            </Box>





        </Dialog>

    )

}

export default GSTSyncDialog