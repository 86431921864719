import React from "react"
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Text, TextInput, Button, Spinner,Link } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import Workplacetheme from "../Workplacetheme";
 
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { addPanOpenClose, uploadPanOpenClose } from "../../redux/features/contactSlice";
import { UploadIcon } from "@radix-ui/react-icons";

function UploadPanDialog(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const panUploadOpenClose = useSelector((state) => state.contactProfileData)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()
    
  
    const [currentRef, setcurrentRef] = React.useState("panName")

    const [panNameInput, setpanNameInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    
    const closeUploadPanDialog = (props) => {

         dispatch(uploadPanOpenClose({
            panUploadOpenClose:false
         }))

       

    }


    
    React.useState(() => {

           setcurrentRef("panName")
    })



    return (

        <Dialog
            sx={{
                height:'315px',
                width:'564px',
                 
            }}
            isOpen={panUploadOpenClose.panUploadOpenClose}
            onDismiss={() =>

                closeUploadPanDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text sx={Workplacetheme.scheduleDalogeHeading}>Upload PANs in bulk using an Excel sheet.</Text>

            </Dialog.Header>

            <Box sx={{
                  display:'flex',
                  flexDirection:'column',
                  width:564,
                  height:'fit-content',
                  
            }}>

               
                  <Box sx={{
                    position:'relative',
                    margin:'25px auto 0px auto',
                    height:'100px',
                    width:'100px',
                    minHeight:'100px',
                    minWidth:'100px',
                    background:'#FAFBFC'
                  }}>

                    <Box sx={{
                          width:'100px',
                          minHeight:'100px',
                          minWidth:'100px',
                          borderRadius:'50%',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          border:'1px solid rgba(27, 31, 36, 0.15)',
                          cursor:'pointer'
                    }}>
                          <UploadIcon scale={32} style={{display:'flex',height:'32px',width:'32px'}} />
                    </Box>

                  </Box>

                  <Text
                  sx={{
                      position:'relative',
                      margin:'34px auto 0px auto',
                      fontFamily:'Segoe UI',
                      fontSize:'12px',
                      fontStyle:'normal',
                      fontWeight:'600',
                      lineHeight:'36px'
                  }}
                  >
                      Upload or drag and drop a file
                  </Text>

               

                

                <Link 
                sx={{
                    position:'relative',
                    margin:'-19px 20px 10px 20px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'400',
                    fontSize:'14px',
                    lineHeight:'20px',
                    color:'#24292F'
                }}
                underline={true}
                href="#"
                >Download Excel template</Link>

                <Box

                    sx={Workplacetheme.createSpaceDialogFooterLine}

                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">

                        
                    </Box>





            </Box>

            <Box
                ml={24}
                mr={24}
                mb={10}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >

                {/* <Button variant="invisible"
                    sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                    onClick={() => closeUploadPanDialog("")}
                >Cancel</Button> */}

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button
                            style={{width:'78px'}}
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() =>  
                             {
                               
                             }
                            }
                        >
                            Upload

                        </Button>


                }

               

                


            </Box>





        </Dialog>

    )

}

export default UploadPanDialog