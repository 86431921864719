
import React from "react"
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, ActionList, Avatar } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { filteredTeamUserId, teamsearchOpenClose } from '../../redux/features/searchSlice';
import Workplacetheme from "../Workplacetheme";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { getSpaceCollaborators } from "../../redux/features/spaceSlice";




function TeamSearchDialogs(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId) 
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const searchData = useSelector((state)=> state.searchData )
    const initialFocusRef = React.createRef()
   
    const [showActionList,setshowActionList] = React.useState(false)
    const [spaceCollaborators,setspaceCollaborators] = React.useState([])
    const [searchInput,setsearchInput]  = React.useState("") 
    const [showListCount,setshowListCount] = React.useState(0)
 
    const handleSearchInput = (e) =>{
              setsearchInput(e.target.value)
              if(e.target.value.length > 0){

                  setshowActionList(true)
                  if(e.target.value.length ===1){
                    setshowListCount(1)
                  }else if(e.target.value.length ===2){
                    setshowListCount(2)
                  }else if(e.target.value.length ===3){
                    setshowListCount(3)
                  }else if(e.target.value.length ===4){
                    setshowListCount(4)
                  }else{
                    setshowListCount(0)
                  }  

              }else{
                  
                  setshowListCount(0)

                  setshowActionList(false)
              }



    }

    const closeSearchDialog = (props) => {

           dispatch(teamsearchOpenClose({
            teamopenClose:false,
            projectId:""
           }))
           setsearchInput("")
    }

  const getSpaceCollaboratorFromDb = (props) => {


    dispatch(getSpaceCollaborators({
      userEmail: userEmail,
      userId: userId,
      projectId: localStorage.getItem("projectId")
    })).then((data) => {

      setspaceCollaborators(data.payload.collaboratorArray)
    })

  }

   const searchUserClick = (props) => {

    
       dispatch(filteredTeamUserId({
           teamUserId:props
       }))
       closeSearchDialog("")



   }
  


    React.useState(()=>{

             getSpaceCollaboratorFromDb("") 

    })

    React.useEffect(()=>{

           if(searchData.teamopenClose===false){
              
                 
                setshowListCount(0)
                setshowActionList(false)
           }

    },[searchData.teamopenClose])


    return (
        <Dialog
            sx={Workplacetheme.filessearchDialogMain}
            isOpen={searchData.teamopenClose}
            style={{height:showListCount===0? 51:170}}
            onDismiss={() =>
                closeSearchDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
            
        >

            <Box sx={Workplacetheme.hideDialogCloseBtn}></Box>

            <TextInput
                ref={initialFocusRef}
                leadingVisual={
                    <MagnifyingGlassIcon
                        style={{
                            height: 20,
                            width: 16,
                            display: "flex",
                            alignContent: 'center'
                        }} />}
                sx={Workplacetheme.filesearchDialogInput}
                aria-label="SearchInput"
                name="searchInput"
                placeholder={"Search your teams"}
                onChange={(e)=> handleSearchInput(e)}

            />
                   
         
            {showActionList && (

          <ActionList showDividers sx={Workplacetheme.filesearchDialogActionList}>
           
            {spaceCollaborators
              .filter((user) => (user.user_first_name + " " + user.user_last_name).toLocaleUpperCase().includes(searchInput.toLocaleUpperCase()))
              .map((row, index) => {

                return (
                  <ActionList.Item 
                  sx={Workplacetheme.filesearchDialogActionList.actionListItem}
                  onSelect={()=>{
                        searchUserClick(row.user_id) 
                  }}
                  >
                    <Text sx={Workplacetheme.filesearchDialogActionList.actionListItem.textField}>{row.user_first_name + " " + row.user_last_name}</Text>
                  
                  </ActionList.Item>
                )


              })}
          </ActionList>




        )}

 

        </Dialog>
    )
}

export default TeamSearchDialogs


