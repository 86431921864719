import { useNavigate } from 'react-router-dom'
import { ActionList, Box, Button, Heading,Text, Dialog } from "@primer/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import {defaultSuccessDialogOpenClose} from '../redux/features/contactSlice';
import { getUserOrganizationById } from '../redux/features/spaceSlice';
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"

const successImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/success.svg`    

export default function DefaultOrganizationSucess(props){

    const userId = useSelector((state) => state.profileData.userId) 
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const defaultSuccessisOpen = useSelector((state)=> state.contactProfileData.defaultSuccessisOpen)
    const defaultDialogFocus = React.useRef()
    const [workplaceProjects,setworkplaceProjects] = React.useState([])

    const reloadProjects = (props) => {

          
        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            setworkplaceProjects(data.payload?.data)
        })
        
    }
    
    //closeDefaultOrgDialog
    const scloseDefaultOrgDialog = (props) => {

        dispatch(defaultSuccessDialogOpenClose({
            defaultSuccessisOpen: false
        }))

    }

    React.useEffect(()=>{

         if(defaultSuccessisOpen===true){

                reloadProjects("")
              
         }


    },[defaultSuccessisOpen])

     
    return(
        <Dialog
            isOpen={defaultSuccessisOpen}
            onDismiss={() => {
                scloseDefaultOrgDialog("")
            }}
            sx={{
                position: 'absolute',
                width: '921px',
                height: '526px',
                background: customThemVariable.whiteBgColor,
                border: '1px solid #D0D7DE',
                borderRadius: '3px'
            }}
            initialFocusRef={defaultDialogFocus}
        >

            <Box

                sx={{
                   
                    height: '526px',
                    maxHeight: '526px',
                    maxWidth: '921px',
                    minWidth: '921px',
                    minHeight: '526px',
                    width: '921px',
                    overflow: 'auto',
                    background: customThemVariable.whiteBgColor,
                    border: '1px solid #D0D7DE',
                    borderRadius: '3px'
                }}
            >

                <Box sx={{
                    position: 'absolute',
                    top: '35px',
                    left: '40px',
                    right: '40px',
                    height: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',

                }}>

                    <Heading sx={{
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: customThemVariable.fontStyleNormal,
                        fontWeight: customThemVariable.fontWeightSemiBold,
                        fontSize: '20px',
                        lineHeight: '30px',
                        color: customThemVariable.primaryTextColor,
                        letterSpacing: '0.3px'
                    }}>
                        Your account has been successfully set up.

                    </Heading>


                </Box>



                <Box sx={{
                    height: 'fit-content',
                    width: 'fit-content',
                    position: 'relative',
                    margin: '82px auto 0px auto'
                }}>
                    <img src={successImg} />
                </Box>

                <Box sx={{
                    height: 'fit-content',
                    width: '660px',
                    minWidth: '660px',
                    maxWidth: '660px',
                    position: 'relative',
                    margin: '24px auto 0px auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Text sx={{
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: customThemVariable.fontStyleNormal,
                        fontWeight: customThemVariable.fontWeightSemiBold,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: customThemVariable.primaryTextColor,
                        textAlign: 'center',
                    }}>
                        You're all set up and ready to start managing your work more efficiently than ever. Streamline your
                        workflow, delegate tasks, and monitor progress in real-time. Collaborate with ease
                    </Text>

                </Box>



                <Box sx={{
                    position: 'relative',
                    margin: '41px auto 0px auto',
                    height: 'fit-content',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    <Button

                        variant="outline"
                        sx={{
                            width: '250px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#0969DA',
                            borderRadius: '3px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: customThemVariable.fontStyleNormal,
                            fontWeight:customThemVariable.fontWeightSemiBold,
                            fontSize: '14px',
                            lineHeight: '20px',
                            border: 'none',
                            color:customThemVariable.whiteBgColor
                        }}

                        onClick={() => {

                            scloseDefaultOrgDialog("")
                            localStorage.setItem("projectId", workplaceProjects[0]?.project_id)
                            localStorage.setItem("projectName", workplaceProjects[0]?.project_name)
                            localStorage.setItem("workplaceId", workplaceProjects[0]?.workplace_id)
                            localStorage.setItem("projectCreatorId", workplaceProjects[0]?.creator_id)
                            navigate('/space/' + workplaceProjects[0]?.project_name)

                        }}

                    >
                        Go to workspace
                    </Button>

                </Box>



            </Box>


             


        </Dialog>
    )


}