import React from "react"
import { Box, Button, Breadcrumbs, Text } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../Space/EmptyData"
import Header from "../Workplace/Header"
import SideNav from "../Workplace/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { addPanOpenClose, gstDataSyncOpenClose } from "../redux/features/contactSlice"
import AddPanDialog from "../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../Workplace/Dialogs/UploadPanDialog"
import GSTSyncDialog from "../Workplace/Dialogs/GSTSyncDialog"
import { useNavigate } from "react-router-dom"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { ChevronRightIcon } from "@primer/octicons-react"
import { getJwtToken, getProductTourTaxhero, getSingleGstportal, updateGstPortal, updateProductTourTaxhero } from "../redux/features/portalSlice"
import { Auth } from 'aws-amplify';
import TaxHeroHeader from "./TaxHeroHeader"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import axios from "axios"

const synchingToast = Toaster.create({ position: Position.TOP })

function Gst(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    const driver = window.driver.js.driver;

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const gstIn = localStorage.getItem("gstIn") || ""

    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);

    const [profileData, setProfileData] = React.useState(null)
    const [gstData, setgstData] = React.useState(null)

    //Post Hog Events-----------------------------------
    const postHogTrackingGSTSyncBtnClick = async (userId, userEmail) => {

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY_TAXHERO}`,
                event: 'GSTSyncClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/` 

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }


    }

    //------Product tour section ----------------
    const updateProductTourInDb = (props) => {

        const locationPath = [...props, { pathname: window.location.pathname, tour: true }]
        dispatch(updateProductTourTaxhero({
            userId: userId,
            userEmail: userEmail,
            locationPath: locationPath
        })).then((data) => {

            console.log("updateProductTourTaxhero", data.payload)
            const newLocationPath = data.payload.data
            console.log("newLocationPath", newLocationPath)
        })

    }

    const showProductTour = (props) => {

        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            showProgress: true,
            allowClose: false,
            steps: [
                { element: '#gstsyncBtn', popover: { title: 'Sync data', description: `Use the sync button to update all client's latest compliance reports in the GSTIN portal.`, side: "top", align: 'start' } },
                { element: '#gstprofileDetails', popover: { title: 'GST Profile', description: `View detailed profile information about your client for quick insights.`, side: "top", align: 'start' } },

            ],
            onDestroyStarted: () => {
                console.log("driverObj", driverObj)

                if (driverObj.getActiveIndex() == 1) {
                    updateProductTourInDb(props)
                    if (!driverObj.hasNextStep() || driverObj.confirm("Are you sure?")) {
                        driverObj.destroy();

                    }

                }


            },
        });

        driverObj.drive();

    }

    const getUserProductTour = (props) => {


        dispatch(getProductTourTaxhero({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getProductTourTaxhero", data.payload.data)
            const tourPaths = [...data.payload.data]
            const checkProductTour = tourPaths?.find((pData) => pData.pathname === window.location.pathname)?.tour
            console.log("checkProductTour", checkProductTour)
            if (!checkProductTour) {
                showProductTour(tourPaths)
            }
        })

    }



    //------------------------------------------


    const getSingleGSTportalFromDb = (props) => {

        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setProfileData(data?.payload?.data[0])
            setgstData(data?.payload.data[0]?.gst_profile)
        })

    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            //  10000
            30 * 60 * 1000
        );
    };

    const showGstSyncDialog = (props) => {

        postHogTrackingGSTSyncBtnClick(userId, userEmail)
        disableSyncButton("")
        loadingToast(10, "1")

        dispatch(updateGstPortal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            gstIn: gstIn,
        })).then((data) => {

            console.log("updateGstPortal", data.payload)
            setgstData(data?.payload?.data)
            const responseObj = { ...data?.payload?.data }
            console.log("responseObj", responseObj)
            if (responseObj?.status_code == 200) {
                loadingToast(100, "1")

            } else {

                synchingToast.dismiss()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });

            }

        })

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },


        }, key);

    }

    React.useState(() => {

        getSingleGSTportalFromDb("")
        getUserProductTour("")
    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com"
                    ||
                     hostName === "www.get.taxherohq.com"
                    ? 'GST - info - taxhero' : 'GST - info - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }


            <Box sx={{
                top: '48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {

                        hostName === "www.app.taxherohq.com" 
                        ||
                        hostName === "www.get.taxherohq.com"
                        ?
                            navigate('/taxheroportal')
                            :
                            navigate('/portal')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlist')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>

                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {/* Eva floor */}
                    {profileData?.pan_name}
                </Text>





            </Box>


            <Box
                id="gstprofileDetails"
                sx={{
                    position: 'absolute',
                    left: '93px',
                    top: '112px',
                    right: '80px',
                    minHeight: '433px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE',
                    paddingBottom:'16px'
                }}>


                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        {/* 32BUMPR7308R1ZT */}
                        {gstData?.data?.gstin}
                    </Text>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {/* Eva floor */}
                            {profileData?.pan_name}
                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>

                            {/* Hexcode */}
                            {profileData?.user_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>
                            {profileData?.password}
                            {/* Hexcode123* */}
                        </Text>

                    </Box>

                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark

                        }}
                    >

                        Last synced on :
                        {
                            profileData == null ? "Not Synched" :
                                "" + new Date(profileData?.updated_timestamp).toDateString() + " " + new Date(profileData?.updated_timestamp).toLocaleTimeString() + ""
                        }


                    </Text>

                    <Button
                        id="gstsyncBtn"
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { showGstSyncDialog("") }}
                    >
                        SYNC
                    </Button>


                </Box>



                <Box sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        GSTIN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aggregate Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Type
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Registration Date
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Central Jurisdiction
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        State Jurisdiction
                    </Text>

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData?.data?.legal_name} ({gstData?.data?.constitution_of_business})
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* evafloorworld@gmail.com */}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* 8129664664 */}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        maxHeight: '20px',
                        height: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* 8/32C, KOZHIPPILLIYIL BUILDING, KEEZHILLAM, PERUMBAVOOR, Ernakulam, Kerala, 683541 */}
                        {gstData?.data?.address}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* Slab: Rs. 1.5 Cr. to 5 Cr. (2021-2022) */}

                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* Regular  */}
                        {gstData?.data?.taxpayer_type}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: gstData?.data?.gstin_status === "Active" ? customThemVariable.textgreen : 'red',
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {/* ACTIVE */}
                        {gstData?.data?.gstin_status}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData !== null ?

                            new Date(gstData?.data?.date_of_registration).getDate() + "/" +
                            (new Date(gstData?.data?.date_of_registration).getMonth() + 1) + "/" +
                            new Date(gstData?.data?.date_of_registration).getFullYear()
                            : ""}


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {gstData !== null ?
                            gstData?.data?.center_jurisdiction

                            : ""}


                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {
                            gstData !== null ?
                                gstData?.data?.state_jurisdiction
                                : ""
                        }

                    </Text>
                </Box>






            </Box>



            <AddPanDialog />
            <UploadPanDialog />
            <GSTSyncDialog />


        </Box>
    )

}

export default Gst