import React from "react"
import { Avatar, Box, Text, Button, Label, IconButton, FormControl, Radio, ActionMenu, ActionList, Spinner } from "@primer/react"
import { Pencil1Icon, CheckIcon } from "@radix-ui/react-icons"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import WorkplaceHeader from "../Workplace/Header"
import WorkplaceLeftSideNav from "../Workplace/SideNav"
import SpaceHeader from "../Space/SpaceHeader"
import SpaceLeftSideNav from "../Space/SpaceLeftSideNav"
import SpaceRightSideNav from "../Space/SpaceRightSideNav"
import customTheme from  "../../Theme.module.scss"    
import customThemVariable from  "../../ThemeVariable.module.scss" 
import { FeedPersonIcon, KebabHorizontalIcon, SquareFillIcon } from "@primer/octicons-react"
import { getSubscriptionInvoice, getUserProfileFromDatabase, getUserSubscriptionData, updateProfileImage, updateProfileImageRealTimeLocal } from "../redux/features/profileSlice"
import AWS from 'aws-sdk'
import TaxHeroHeader from "../Portal/TaxHeroHeader"
const planLogo = "https://d2fcqkt9srr3ti.cloudfront.net/Account/planlogo.svg"
const visacard = "https://d2fcqkt9srr3ti.cloudfront.net/Account/visa.svg"
var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION
AWS.config.update({
    accessKeyId: IAM_USER_KEY,
    secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
    region: REGION,
})

function AccountLatest() {

    let hostName = window.location.hostname

    const dispatch = useDispatch()
    const fileRef = React.useRef()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const [showloadingSpinner, setshowloadingSpinner] = React.useState(false)
    const [userProfileData, setuserProfileData] = React.useState([])
    const [userOrgData,setuserOrgData] = React.useState(null)
    const [selectedFile, setselectedFile] = React.useState("") 
    const [subscriptionData,setsubscriptionData] = React.useState([])
    const [subscriptionPlan,setsubscriptionPlan] = React.useState([])
    const [organizationData,setorganizationData] = React.useState([])  

    const [notitficationEmail,setnotitficationEmail] = React.useState(true)
    const [showworkplaceHeader, setshowworkplaceHeader] = React.useState(false)
    const [isSaveBtnVisibility,setisSaveBtnVisibility] = React.useState(false)
    const [invoices, setinvoices] = React.useState([]) 

    const updateUserProfileImageIndb = (props) => {

       
        setselectedFile(URL.createObjectURL(props))

        dispatch(updateProfileImageRealTimeLocal({
            userImageUrl: URL.createObjectURL(props)
        }))

        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'Userprofileimage/' + userId,
            'ContentType': props.type
        };

        setshowloadingSpinner(true)
        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {
                console.log(err, err.stack)
            } else {

                console.log(data)

                setselectedFile(data.Location)
                setshowloadingSpinner(false)
                
                dispatch(updateProfileImage({
                    userImageUrl: data.Location,
                    userId: userId
                })).then((data) => {



                })

                dispatch(updateProfileImageRealTimeLocal({
                    userImageUrl: data.Location
                }))


            };



        })







    }


    const getUserProfileFromUsersDb = (props) => {

        dispatch(getUserProfileFromDatabase({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getUserProfileFromDatabase", data.payload)
            setorganizationData(data.payload.data[0]?.organization)
            setuserProfileData(data.payload.data)
            setselectedFile(data.payload.data[0]?.user_image_url)
            setuserOrgData(data.payload.userOrganizationData)
        })


    }

    const getUserSubscriptionDataFromDb = (props) => {
        
          dispatch(getUserSubscriptionData({
            userEmail: userEmail
          })).then((data)=>{
               console.log("subscription details",data.payload)
               setinvoices(data?.payload?.invoices)
               setsubscriptionData(data?.payload?.body)
               setsubscriptionPlan((prevState) => { 
                      const newState = [...prevState,data?.payload?.plan?.item]
                      return newState
               })
          })
    
    }

    const downloadInvoice =async (props) => {
    
             
             dispatch(getSubscriptionInvoice({
                  userId:userId,
                  userEmail:userEmail,
                  invoiceId:props,
             })).then((data)=>{

                  const downloadUrl = data?.payload?.data?.download?.download_url
                  const link = document.createElement('a');
                  link.href = downloadUrl
                  link.click();
                  

             })



    }



    React.useState(() => {

        getUserProfileFromUsersDb("")
        getUserSubscriptionDataFromDb("")

        if (localStorage.getItem("locationPath") === "/workplace") {

            setshowworkplaceHeader(true)

        } else {

            setshowworkplaceHeader(false)
        }

    })


    return (<Box
        position={"relative"}
        display="flex"
        background={"#F6F8FA"}
        height="100vh"
        width={"100vw"}
        overflowY={"auto"}
    >

        <Helmet>
            <title>
            {hostName === "www.app.taxherohq.com" ? 'Account - taxhero' : 'Account - deski'}
            </title>
        </Helmet>

        {
            hostName === "www.app.taxherohq.com"

                ?

                <>
                    <TaxHeroHeader />
                </>
                :
                <>
                    {showworkplaceHeader === true ? <WorkplaceHeader /> : <SpaceHeader />}
                    {showworkplaceHeader === true ? <WorkplaceLeftSideNav /> : <SpaceLeftSideNav />}
                    {showworkplaceHeader === true ? "" : <SpaceRightSideNav />}
                </>
        }
           

     

        <Box
            position={"absolute"}
            display="flex"
            background={"#F6F8FA"}
            left={"64px"}
            top={"48px"}
            right={"0px"}
            bottom={"0px"}
            overflowY={"scroll"}
            overflowX={"hidden"}

        >

            <Box sx={{
                display:'flex',
                flexDirection:'column',
                width:'340px',
                maxWidth:'340px', 
                minWidth:'340px',
                position:'absolute',
                left:'27px',
                top:'27px',
            }}>

                <Box
                sx={{
                    cursor:'pointer',
                    height:'64px',
                    minHeight:'64px',
                    maxHeight:'64px',
                    width:'64px',
                    minWidth:'64px',
                    maxWidth:'64px',
                     display:'flex',
                    alignItems:'center',
                    justifyItems:'center'
                }}
                onClick={() => fileRef.current.click()}
                >
                    {selectedFile === "" || selectedFile == null ?


                        <FeedPersonIcon size={64} fill="#586069" />
                        :
                        <Avatar size={64} src={selectedFile}

                        />
                    }


                    <input
                        ref={fileRef}
                        style={{ display: 'none', position: 'absolute', width: '0px', height: '0px' }}
                        type={"file"}
                        accept="image/png,image/jpeg"
                        onChange={async (e) => {

                             
                           updateUserProfileImageIndb(e.target.files[0])

                        }

                        }
                        hidden
                    />

                    {showloadingSpinner === true ?

                        
                        <Spinner
                        size="medium"
                        sx={{
                            display:'flex',
                            position: 'absolute',
                            left: '15px',
                        }} />

                        :

                         ""
                        
                        }


                </Box> 
                {/* Section one */}

                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '22px' }}>
                    <Text
                        className={customTheme.H4}
                        sx={{
                            minWidth: '340px',
                            maxWidth: '340px',
                            width: '340px',
                            minHeight: '20px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: customThemVariable.textgraydark
                        }}
                    >
                        {userProfileData.length > 0 ? userProfileData[0]?.user_first_name + " " + userProfileData[0]?.user_last_name : ""}
                    </Text>
                    <Text
                        className={customTheme.customfontmedium}
                        sx={{
                            minWidth: '340px',
                            maxWidth: '340px',
                            width: '340px',
                            minHeight: '20px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            marginTop: '8px',
                            color: customThemVariable.textgraydark
                        }}
                    >
                        {userProfileData.length > 0 ? userProfileData[0]?.user_email : ""}
                    </Text>
                </Box>

                {/* ----------- */}
                
                {/* Section two */}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
                    <Text
                        className={customTheme.textnormal}
                        sx={{ color: customThemVariable.textgray }}
                    >
                        Organization
                    </Text>
                    <Text
                        className={customTheme.H5}
                        sx={{ 
                            minWidth:'340px',
                            maxWidth:'340px',
                            width:'340px',
                            minHeight:'20px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: customThemVariable.textgraydark, 
                          //  fontWeight: '500'
                        }}
                    >
                       {userOrgData?.project_name}
                    </Text>
                    <Text
                        className={customTheme.customfontsmall}
                        sx={{ 
                            minWidth:'340px',
                            maxWidth:'340px',
                            width:'340px',
                            minHeight:'20px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: customThemVariable.textgray,
                            minHeight:'20px' 
                        }}
                    >
                        {organizationData?.length > 0 ?
                            organizationData[0]?.addressLine1
                            : ""}<br></br>
                        {organizationData?.length > 0 ?
                            organizationData[0]?.addressLine2
                            : ""}
                    </Text>

                </Box>
                {/* ----------- */}

                {/* Section three */}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '26px' }}>
                    <Text
                        className={customTheme.textbold}
                        sx={{ color: customThemVariable.textPrimaryColor }}
                    >
                        Details
                    </Text>

                    


                </Box>
                {/* ----------- */}

                {/* Section four */}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                    <Text
                        className={customTheme.textnormal}
                        sx={{ color: customThemVariable.textgray }}
                    >
                        Role
                    </Text>

                    <Text
                        className={customTheme.textnormal}
                        sx={{
                            color: customThemVariable.textPrimaryColor
                        }}
                    >
                        Owner
                        {/* Lead Programmer  */}
                    </Text>


                </Box>
                {/* ------------ */}

                {/* section five  */}
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                    <Text
                        className={customTheme.textnormal}
                        sx={{ color: customThemVariable.textgray }}
                    >
                        Change password
                    </Text>
                    <Text
                        className={customTheme.textnormal}
                        sx={{ color: customThemVariable.textgray }}
                    >
                        ******************
                    </Text>
                </Box>
                {/* ------------- */}




            </Box> 

 
             <Box
                sx={{
                    position: 'absolute',
                    top: '27px',
                    left: '386px',
                    right: '165px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
             >

                {/* Section one  */}
                 <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '102px',
                        maxHeight: '102px',
                        height: '102px',
                        
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }} >
                        <Text
                            className={customTheme.H5}
                            sx={{ color: customThemVariable.textgraydark, marginTop: '3px' }}
                        >
                            Subscriptions
                        </Text>

                        <Button
                            variant="invisible"
                            sx={{
                                position: 'absolute',
                                right: '-16px',
                                top: '-3px',
                                fontFamily: customThemVariable.primartFont,
                                fontSize: '14px',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: customThemVariable.navlistBorderColor
                            }}
                        >
                            Upgrade
                        </Button>
                    </Box>

                    {/* Horizontal divider */}

                     <Box
                     sx={{
                        marginTop:'8px',
                        minWidth:'100%',
                        maxWidth:'100%',
                        width:'100%',
                        height:'1px',
                        minHeight:'1px',
                        maxHeight:'1px',
                        background:customThemVariable.borderColor
                     }}
                     ></Box>

                    {/* ------------------ */}


                    {/* -----  Plan Details */}

                     <Box sx={{
                        marginTop:'11px',
                        display:'flex',
                        flexDirection:'row',
                        minHeight:'40px',
                        maxHeight:'40px',
                        alignItems:'center'
                     }}>
                     
                         <Box
                         sx={{marginLeft:'4px'}}
                         >
                         <img src={planLogo} /> 
                         </Box>

                        <Box sx={{
                           marginLeft:'9px',
                           display:'flex',
                           flexDirection:'column',

                        }}>

                            <Box sx={{display:'flex',flexDirection:'row'}}>
                              
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        minWidth: '140px',
                                        maxWidth: '140px',
                                        width: '140px',
                                        fontWeight: '500',
                                        minHeight: '24px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >
                                    {/* Professional × 2 */}
                                    {
                                        subscriptionPlan.length > 0 ?
                                            subscriptionPlan[0]?.name
                                            : ""
                                    }
                                </Text>

                               
                                <Label sx={{
                                    marginLeft: '4px',
                                    fontFamily: customThemVariable.primartFont,
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: customThemVariable.labelTextTertiary,
                                    borderRadius: '6px',
                                    minHeight: '24px',
                                    maxHeight: '24px',
                                    height: '24px',
                                    background: customThemVariable.labelBgColorTertiary,
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>

                                    <Text sx={{ display: 'flex', marginLeft: '0px',textTransform:'capitalize' }}>
                                        {/* Active */}
                                        {subscriptionData?.length > 0 ?
                                            subscriptionData[0].subscription.status
                                            : ""}
                                    </Text>
                                  </Label>

                             </Box>

                             <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'1px'}}>

                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        minWidth: '81px',
                                        maxWidth: '81px',
                                        width: '81px',
                                        color:customThemVariable.textgray
                                    }}
                                    

                                >{
                                       subscriptionData?.length > 0 ?
                                           "Billed "+subscriptionData[0]?.subscription?.billing_period_unit+"ly"
                                       :""
                                }</Text>

                                <Box sx={{ height:'3px',width:'3px',minHeight: '3px', minWidth: '3px', background: '#000000', marginLeft: '15px', borderRadius: '1.5px' }}>

                                </Box>

                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        marginLeft: '11px',
                                        color: customThemVariable.textgray
                                    }}
                                >
                                    {/* Next invoice on Jun 1 for $24.00 */}
                                    {subscriptionData.length > 0 ?
                                        "Next invoice on " + new Date(subscriptionData[0].subscription.next_billing_at * 1000).toDateString().substring(3, 10) + " for ₹" +
                                        subscriptionData[0].subscription.subscription_items[0].amount / 100
                                        : ""}
                                </Text>



                             </Box>



                             




                        </Box>     
                          


                     </Box>
                     {/*------------------  */}

                    {/* Diveider */}

                    <Box
                        sx={{
                            marginTop: '12px',
                            minWidth: '100%',
                            maxWidth: '100%',
                            width: '100%',
                            height: '1px',
                            minHeight: '1px',
                            maxHeight: '1px',
                            background: customThemVariable.borderColor
                        }}
                    ></Box>
                    {/* ---------- */}


                     {/* Invoice table   */}

                    <Box sx={{
                        marginTop:'40px',
                        minWidth:'100%',
                        maxWidth:'100%',
                        width:'100%',
                        display:'flex',
                        flexDirection:'column',
                        background:customThemVariable.whiteBgColor
                    }}>

                        <Box
                            sx={{
                                minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                background: '#F6F8FA',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Text
                                className={customTheme.H5}
                                sx={{
                                    minHeight: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // marginTop: '2px',
                                    color: customThemVariable.textgraydark
                                }}
                            >
                                Invoices
                            </Text>

                        </Box>
                        <Box
                        sx={{
                            minHeight:'36px',
                            maxHeight:'36px',
                            height:'36px',
                            display:'flex',
                            flexDirection:'row',
                            borderBottom:'1px solid #E6EBF1'
                        }}
                        >

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '33.3%',
                                maxWidth: '33.3%',
                                width: '33.3%',
                                minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        textTransform: 'uppercase',
                                        marginTop: '2px',
                                        marginLeft: '5px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >
                                    AMOUNT
                                </Text>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '30.3%',
                                maxWidth: '30.3%',
                                width: '30.3%',
                                minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        textTransform: 'uppercase',
                                        marginTop: '2px',
                                        marginLeft: '0px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >
                                    NUMBER
                                </Text>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '15.3%',
                                maxWidth: '15.3%',
                                width: '15.3%',

                                minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                alignItems:'center'
                            }}>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        textTransform: 'uppercase',
                                        marginTop: '2px',
                                        marginLeft: '0px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >
                                    due
                                </Text>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '14.7%',
                                maxWidth: '14.7%',
                                width: '14.7%',
                                minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                alignItems:'center'
                            }}>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        textTransform: 'uppercase',
                                        marginTop: '2px',
                                        marginLeft: '0px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >
                                    created
                                </Text>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minWidth: '6.3%',
                                maxWidth: '6.3%',
                                width: '6.3%',
                                 minHeight: '36px',
                                maxHeight: '36px',
                                height: '36px',
                                alignItems: 'center'
                            }}>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        textTransform: 'uppercase',
                                        marginTop: '2px',
                                        marginLeft: '0px',
                                        color: customThemVariable.textgraydark
                                    }}
                                >

                                </Text>

                            </Box>

                            

                        </Box>

                        {/* dynmic invoice data */}

                         {invoices.map((row,index)=>{
                              
                               return(
                                <Box
                                sx={{
                                    minHeight:'36px',
                                    maxHeight:'36px',
                                    height:'36px',
                                    display:'flex',
                                    flexDirection:'row',
                                    borderBottom:'1px solid #E6EBF1'
                                }}
                                >
        
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minWidth: '33.3%',
                                        maxWidth: '33.3%',
                                        width: '33.3%',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        alignItems:'center',
                                        
                                    }}>
                                           <Text
                                               className={customTheme.textnormal}
                                               sx={{
                                                   minWidth: '70px',
                                                   maxWidth: '70px',
                                                   width: '70px',
                                                   marginTop: '2px',
                                                   marginLeft: '5px',
                                                   color: customThemVariable.textgraydark
                                               }}
                                           >
                                               {/* {"$24.00"} */}
                                               
                                               {invoices.length > 0 ? "₹"+(row.invoice.sub_total/100)+"" :""}
                                              

                                           </Text>

                                           {index == 0 ?

                                               <Label sx={{
                                                   marginLeft: '37px',
                                                   fontFamily: customThemVariable.primartFont,
                                                   fontStyle: 'normal',
                                                   fontWeight: '500',
                                                   fontSize: '12px',
                                                   lineHeight: '20px',
                                                   color: customThemVariable.labelTextTertiary,
                                                   borderRadius: '6px',
                                                   minHeight: '24px',
                                                   maxHeight: '24px',
                                                   height: '24px',
                                                   background: customThemVariable.labelBgColorTertiary,
                                                   border: 'none',
                                                   display: 'flex',
                                                   alignItems: 'center',
                                               }}>
                                                   <Text sx={{ display: 'flex', marginLeft: '0px', textTransform: 'capitalize' }}>
                                                      Active
                                                   </Text>
                                                   <Box sx={{ color: customThemVariable.labelTextColorgreen, display: 'flex' }}>

                                                       <SquareFillIcon size={18} />
                                                   </Box>
                                               </Label>

                                               :


                                               ""}

                                           

                                        

        
                                    </Box>
        
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minWidth: '30.3%',
                                        maxWidth: '30.3%',
                                        width: '30.3%',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        alignItems:'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                
                                                marginTop: '2px',
                                                marginLeft:'0px',
                                                color: customThemVariable.textgraydark
                                            }}
                                        >
                                            {/* {"ACME-0022"} */}

                                               {invoices.length > 0 ?
                                                   "" + (row.invoice.id) + ""
                                                   : ""}

                                        </Text>
        
                                    </Box>
        
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minWidth: '15.3%',
                                        maxWidth: '15.3%',
                                        width: '15.3%',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        alignItems:'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginTop: '2px',
                                                marginLeft:'0px',
                                                color: customThemVariable.textgraydark
                                            }}
                                        >
                                            {/* {"---"} */}

                                                  {invoices.length > 0 ?
                                                   "" + new Date(row.invoice.due_date * 1000).toDateString().substring(3, 10) + ""
                                                   : ""}


                                        </Text>
        
                                    </Box>
        
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minWidth: '14.7%',
                                        maxWidth: '14.7%',
                                        width: '14.7%',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        alignItems:'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginTop: '2px',
                                                marginLeft:'0px',
                                                color: customThemVariable.textgraydark
                                            }}
                                        >
                                            {/* {"May 1"} */}
                                            
                                             {invoices.length > 0 ?
                                                   "" + new Date(row.invoice.generated_at*1000).toDateString().substring(3,10) + ""
                                                   : ""}


                                        </Text>
        
                                    </Box>
        
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minWidth: '6.3%',
                                        maxWidth: '6.3%',
                                        width: '6.3%',
                                        minHeight: '36px',
                                        maxHeight: '36px',
                                        height: '36px',
                                        alignItems: 'center',
                                        justifyContent:'end'
                                    }}>
                                         
                                           <ActionMenu>
                                               <ActionMenu.Anchor>
                                                   <IconButton
                                                       sx={{
                                                           display: 'flex',
                                                           border: 'none',
                                                           background: 'transparent',
                                                           color: '#697386'
                                                       }}
                                                       icon={() => <KebabHorizontalIcon />}
                                                       onClick={() => {
                                                           
                                                       }}
                                                   />
                                               </ActionMenu.Anchor>
                                               <ActionMenu.Overlay>
                                                   <ActionList sx={{
                                                       display: 'flex',
                                                       flexDirection: 'column',
                                                       alignItems: 'center',
                                                       padding: '6px',
                                                       fontFamily: 'Inter',
                                                       fontStyle: 'normal',
                                                       fontWeight: '400',
                                                       fontSize: '14px',
                                                       lineHeight: '20px',
                                                       minWidth: '200px'
                                                   }}>
                                                       <ActionList.Item
                                                           onSelect={() => {
                                                               downloadInvoice(row.invoice.id)
                                                           }}
                                                       >
                                                           Download Invoice
                                                       </ActionList.Item>
                                                   </ActionList>
                                               </ActionMenu.Overlay>


                                           </ActionMenu>

                                          
                                    </Box>
        
                                    
        
                                </Box>
                               )

                         })}


                        {/* ------------------- */}

                        </Box> 


                      {/* --------------- */}

                    {/* Payment methods */}

                    <Box sx={{
                        marginTop: '43px',
                        minWidth: '100%',
                        maxWidth: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '152px',
                        maxHeight: '152px',
                        height: '152px',
                    }}>


                        <Box
                            sx={{
                                minHeight: '32px',
                                maxHeight: '32px',
                                height: '32px',
                                background: '#F6F8FA',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >

                            <Text
                                className={customTheme.H5}
                                sx={{
                                    marginTop: '2px',
                                    color: customThemVariable.textgraydark
                                }}
                            >
                                Payment methods
                            </Text>
                            <Button
                                variant="invisible"
                                sx={{
                                    marginTop: '-8px',
                                    marginRight: '-15px',
                                    position: 'absolute',
                                    right: '0px',
                                    fontFamily: customThemVariable.primartFont,
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    color: customThemVariable.navlistBorderColor
                                }}
                            >
                                Add
                            </Button>


                        </Box>

                        {/* horizontal line */}

                        <Box
                            sx={{
                                marginTop: '1px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                height: '1px',
                                minHeight: '1px',
                                maxHeight: '1px',
                                background: customThemVariable.borderColor
                            }}
                        ></Box>

                         {/* Card details -------- */}
                     
                        <Box sx={{
                            minHeight: '66px',
                            maxHeight: '66px',
                            height: '66px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems:'center'
                        }}>

                            <Box sx={{
                                display: 'flex',
                                minHeight: '32px',
                                maxHeight: '32px',
                                height: '32px',
                                width: '32px',
                                minWidth: '32px',
                                maxWidth: '32px'
                            }}>
                                <img src={visacard} />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '13px',
                                height: 'fit-content',
                                minHeight: 'fit-content',
                                maxHeight: 'fit-content',
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{ color: customThemVariable.textgraydark,minHeight:'24px',textTransform:'capitalize' }}
                                    >
                                         {/* Visa •••• 4242 */}
                                        {subscriptionData.length>0 ? 
                                         subscriptionData[0]?.customer?.card_status !== "no_card"?
                                         subscriptionData[0]?.card?.card_type+" "+subscriptionData[0]?.card?.masked_number
                                         :""
                                        :""}
                                       
                                    
                                    </Text>

                                    <Label sx={{
                                        marginLeft: '8px',
                                        fontFamily: customThemVariable.primartFont,
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#545969',
                                        borderRadius: '3px',
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        height: '24px',
                                        background: customThemVariable.borderColor,
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>

                                        <Text sx={{ display: 'flex', marginLeft: '0px', textTransform: 'capitalize' }}>
                                            {/* Badge */}
                                            {subscriptionData.length > 0 ?
                                                subscriptionData[0]?.customer?.card_status !== "no_card" ?
                                                    subscriptionData[0]?.card?.status + " "
                                                    : ""
                                                : ""}
                                        </Text>

                                    </Label>
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{ color: customThemVariable.textgray }}
                                >
                                    {/* Expires Sep 2020 */}
                                    {subscriptionData.length > 0 ?
                                     subscriptionData[0].customer.card_status === "valid" ?
                                     "Expires " + months[subscriptionData[0]?.card?.expiry_month - 1] + " " + subscriptionData[0].card.expiry_year
                                     : "" :
                                    ""}
                                </Text>
                            </Box>


                          </Box> 
                        {/* horizontal line */}
                        <Box
                            sx={{
                                marginTop: '1px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                height: '1px',
                                minHeight: '1px',
                                maxHeight: '1px',
                                background: customThemVariable.borderColor
                            }}
                        ></Box>
                        {/* Notification section */}
                    
                        <Box
                            sx={{
                                marginTop:'73px',
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                background: '#F6F8FA',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >

                            <Text
                                className={customTheme.H5}
                                sx={{
                                    position:'relative',
                                    margin:'0px 0px 0px 0px',
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    justifyContent:'center',
                                   // marginTop: '2px',
                                    color: customThemVariable.textgraydark
                                }}
                            >
                                Notification
                            </Text>

                            <Box sx={{
                                position: 'absolute',
                                right: '0px',
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                cursor:'pointer',
                                   
                            }}
                            // onClick={()=>{
                            //        setisSaveBtnVisibility(true)
                            // }}
                            onBlur={()=>{
                                   setisSaveBtnVisibility(false)
                            }}
                            >
                            <Button
                                
                                disabled={!isSaveBtnVisibility}
                                variant="primary"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    fontFamily: customThemVariable.primartFont,
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    color: customThemVariable.whiteBgColor,
                                    display:!isSaveBtnVisibility?'none':'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    paddingLeft:'8px',
                                    paddingRight:'8px'
                                }}
                                onClick={()=>{
                                    setisSaveBtnVisibility(false)
                                }}

                            >
                                Save changes
                            </Button>
                            </Box>


                        </Box>
                        
                        {/* horizontal line */}
                        <Box
                            sx={{
                                marginTop: '12px',
                                minWidth: '100%',
                                maxWidth: '100%',
                                width: '100%',
                                height: '1px',
                                minHeight: '1px',
                                maxHeight: '1px',
                                background: customThemVariable.borderColor
                            }}
                        ></Box>

                        {/* Notification content section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                            <Text
                                className={customTheme.H5}
                                sx={{ color: customThemVariable.textgraydark }}
                            >Send a confirmation email
                            </Text>
                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.textgraydark, maxWidth: '407px', minWidth: '407px', width: '407px' }}
                            >Organization  notification feature keeps you informed by sending updates directly to your email. Never miss an important message and deadlines
                            </Text>
                            <Box sx={{ display: 'flex', flexDirection: 'row',gap:'32px',marginTop:'8px' }}>

                                <FormControl>
                                    <Radio values="Yes" 
                                    checked={notitficationEmail}
                                    onChange={(e)=>{
                                          setnotitficationEmail(true)
                                          setisSaveBtnVisibility(true)
                                    }} />
                                    <FormControl.Label
                                     sx={{
                                       fontFamily:customThemVariable.primartFont,
                                       fontSize:'14px',
                                       fontStyle:'normal',
                                       fontWeight:'600',
                                       lineHeight:'20px',
                                       color:'#30313D'
                                     }}
                                    >Yes</FormControl.Label>
                                </FormControl>

                                <FormControl>
                                    <Radio values="No" 
                                    checked={!notitficationEmail}
                                    onChange={(e)=>{
                                          setnotitficationEmail(false)
                                          setisSaveBtnVisibility(true)
                                    }} />
                                    <FormControl.Label
                                     sx={{
                                        fontFamily:customThemVariable.primartFont,
                                        fontSize:'14px',
                                        fontStyle:'normal',
                                        fontWeight:'600',
                                        lineHeight:'20px',
                                        color:'#30313D'
                                     }}
                                    >No</FormControl.Label>
                                </FormControl>

                            </Box>

                           

                        </Box> 


                        {/* Bottom space */}
                        <Box sx={{
                            minHeight: '164px',
                            maxHeight: '164px',
                            height: '164px'
                        }}>
                        </Box> 
                        {/* ---------------------------- */}

                        


                    </Box>


                {/*---------------  */}


                </Box>

                {/* -------- */}

           </Box>



           







        </Box>
 


    </Box>)
}

export default AccountLatest