import React from "react"
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, Avatar, TextInputWithTokens, IssueLabelToken, ActionMenu, ActionList, Autocomplete, Radio,FormControl } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { sharingOpenClose,shareWorkplaceProject,flashMessageShowHide } from '../../redux/features/sharingSlice';
import Workplacetheme from "../Workplacetheme";
import { CheckIcon, Cross2Icon, Pencil1Icon } from "@radix-ui/react-icons";
import { FeedPersonIcon } from "@primer/octicons-react";
import { getChargebeePlan, getSubscriptionbyProjectsId, getUserProfileFromDatabase } from "../../redux/features/profileSlice";
import { getSingleWorkplace } from "../../redux/features/workplaceSlice";
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { getProjectInvitations, getSpaceCollaborators } from "../../redux/features/spaceSlice";
import axios from 'axios';
import mixpanel from "mixpanel-browser";
mixpanel.init('42f38a9f4804449b88177fb8bbfec5c6', { debug: true, ip: true });
const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"

 
function InviteTeamMembers(props) {

    const dispatch = useDispatch()
    const sharingData = useSelector((state) => state.sharingData)
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail) 
    
    const subscriptionData = useSelector((state) => state.profileData?.subscriptionData)
    const planName = subscriptionData?.plan?.item.name

   // const maxinvitationCount = 15  
    
    const [maxinvitationCount, setmaxinvitationCount] = React.useState(0)
     
   
    const [pendingCounts, setpendingCounts] = React.useState(0)
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])

    const initialFocusRef = React.createRef()
    const [shareRole, setshareRole] = React.useState("Collaborator")
    const [emailAddress, setemailAddress] = React.useState({
        value: '',
        validationStatus: ''
    })
   
    const closesharingDialog = (props) => {

        dispatch(sharingOpenClose({
            openClose: false,
            workplaceId: "",
            shareType: "",
            projectId: "",
            title: "",
        }))

        setshareRole("Collaborator")
        setemailAddress((prevState) => {
            return ({ ...prevState, value: '', validationStatus: '' })
        })


    }

    const trackInvitationSend = async (userId, userEmail) => {

        const res = await axios.get('https://geolocation-db.com/json/')
        const sourceIp = res.data.IPv4

        const trackInvitations = mixpanel.track('InvitationSend', {
            distinct_id: userId,
            ip: sourceIp,
            time: new Date().getTime(),
        })
      
    }

    const handleShareToDb = (props) => {

          const totalinvitationCount = spaceCollaborators.filter((sData)=> sData.user_id!==userId).length+parseInt(pendingCounts)
          // check number of total users in totalInvitation Count in the given time
          if(emailAddress.value===""){

                setemailAddress((prevState)=>{
                     return({...prevState,validationStatus:'error'})
                })

          }else{

               const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
               if(regex.test(emailAddress.value)===true){

                if (totalinvitationCount < maxinvitationCount) {

                     dispatch(shareWorkplaceProject({
                        userId: "",
                        userEmail: emailAddress.value,
                        inviteEmail: userEmail,
                        workplaceId: sharingData?.workplaceId,
                        shareType: sharingData?.shareType,
                        projectId: sharingData?.projectId,
                        inviteId: userId,
                        shareRole: shareRole

                    })).then((data) => {

                        if (data.payload?.message === "Already shared space with same Role") {

                             Toaster.create({ position: Position.TOP }).show({
                                message: "User already in project, role change possible.",
                                intent: Intent.PRIMARY
                             });


                        } else if (data.payload?.message === "Space invitation sent successfully") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });
                            trackInvitationSend(userId,userEmail)

                        } else if (data.payload?.message === "Already shared workspace with same Role") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in workspace, role change possible.",
                                intent: Intent.PRIMARY
                            });


                        } else if (data.payload?.message === "Invitation send with new role") {

                             Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                             });
                             trackInvitationSend(userId,userEmail)

                        }else if(data.payload?.message.replace(/\s/g, "") === "Invitationsentwithnewrole"){

                             Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                             });
                             trackInvitationSend(userId,userEmail)

                        } else if (data.payload?.message === "Invitation send with new workspace") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });
                            trackInvitationSend(userId,userEmail)

                        } else if (data.payload?.message === "Worspace invitation already sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in workspace, role change possible.",
                                intent: Intent.PRIMARY
                            });

                        } else if (data.payload?.message === "Workspace shared invitation sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });
                            trackInvitationSend(userId,userEmail)

                        } else if (data.payload?.message === "Project Invitation already sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in project, role change possible.",
                                intent: Intent.PRIMARY
                            });
                            trackInvitationSend(userId,userEmail)

                        } else if (data.payload?.message === "Project shared invitation sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });

                            trackInvitationSend(userId,userEmail)

                        }


                        closesharingDialog("")
                        setshareRole("Collaborator")
                        
                    })

                   



                  } else {

                    Toaster.create({
                        position: Position.TOP,
                    }).show({
                        message: "Maximum collaborators reached " + maxinvitationCount,
                        intent: Intent.PRIMARY
                    });


                   }


               }else{

                    setemailAddress((prevState)=>{
                    return({...prevState,validationStatus:'error'})
                   })

               }
               
          }

 
    }


    const getSpaceCollaboratorFromDb = (props) => {

          dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
         })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
           
        })

    }

    const getProjectInvitationsFromDb = (props) => {

        dispatch(getProjectInvitations({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

             setpendingCounts(data.payload.data)
        })

    }


    const getSubscriptionbyProjectsIdFromDb = async (props) => {

        const data = await dispatch(getSubscriptionbyProjectsId({
            userEmail: userEmail || localStorage.getItem("userEmail")
        }))

        return data?.payload?.data[0]
    }

    const getChargebeePlanFromApi = async (props) => {
        const data = await dispatch(getChargebeePlan({
            userId: userId,
            userEmail: userEmail || localStorage.getItem("userEmail"),
            planId: 'Grow'
        }))
        return data?.payload?.data?.metadata
    }

 
    const getChargebeeTotalQuantity =async (props) => {

        const pSubscription = await getSubscriptionbyProjectsIdFromDb("")
        const subscriptionStatus = pSubscription?.subscription?.status
        console.log("pSubscription", subscriptionStatus)
        const chargebeePlan = await getChargebeePlanFromApi("")
        console.log("chargebeePlan", chargebeePlan)
        const freeQuantity = chargebeePlan?.freequantity || 0
        const totalquantity = chargebeePlan?.totalquantity || 0

        if (subscriptionStatus === "active") {

            setmaxinvitationCount(totalquantity)

        } else if (subscriptionStatus === "in_trial") {

            setmaxinvitationCount(freeQuantity)

        }


   

    }

    
    React.useEffect(()=>{

       

        if(sharingData.openClose===true){


            initialFocusRef.current.focus()

            getSpaceCollaboratorFromDb("")
            getProjectInvitationsFromDb("")
           
        }
        
 
    },[sharingData])

    React.useState(()=>{

           getChargebeeTotalQuantity("")  
    })
     


    return (
        <Dialog
            sx={{
                width:'453px',
                height:'455px',
                minWidth:'453px',
                maxWidth:'453px',
                minHeight:'455px',
                maxHeight:'455px',
                display:'flex',
                flexDirection:'column'
            }}
            isOpen={sharingData.openClose}
            onDismiss={() =>
                  closesharingDialog("")
            }
            aria-labelledby="header-id"
        >
            
            <Box sx={{
                position: 'absolute',
                left: '0px',
                right: '0px',
                top: '0px',
                bottom: '0px',
                display: 'flex',
                flexDirection: 'column',
                background: '#FFFFFF',
                borderRadius:'6px',
                boxShadow:'0px 4px 12px rgba(27, 31, 35, 0.15)'
            }}>

                <Text sx={{
                    display: 'flex',
                    position: 'relative',
                    margin: '16px 16px 0px 16px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F'
                }}>
                    Add Team Member
                </Text>

                <TextInput
                    ref={initialFocusRef}
                    sx={{
                        position: 'relative',
                        margin: '26px 20px 0px 20px',
                        minHeight: '40px',
                        maxHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',

                    }}
                    value={emailAddress.value}
                    validationStatus={emailAddress.validationStatus}
                    onChange={(e) => {
                        setemailAddress((prevState) => {
                            return ({ ...prevState, value: e.target.value, validationStatus: '' })
                        })
                    }}
                    placeholder="Enter team member email address"
                />

                <Box sx={{
                    position: 'relative',
                    margin: '17px 20px 0px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '7px'
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection:'column',
                        minHeight: '80px',
                        maxHeight: '80px',
                        height: '80px',
                        background: '#FFFFFF',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        cursor:'pointer',
                        ':hover': {
                            background: 'rgba(234, 238, 242, 0.5)'
                        }
                    }}
                    onClick={()=>{ setshareRole("Collaborator") }}
                    >

                        <Box sx={{
                            position: 'relative',
                            margin: '12px 14px 0px 14px',
                            display: 'flex',

                        }}>
                            <FormControl sx={{

                            }}>
                                <Radio 
                                 value="Collaborator"
                                 checked={shareRole==="Collaborator"?true:false}
                                 
                                />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292E'
                                    }}
                                >Collaborator</FormControl.Label>
                            </FormControl>

                        </Box>

                        <Text sx={{
                            position:'relative',
                            margin:'5px 15px 0px 35px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#24292E'
                        }}>
                            Assign tasks, share files, and communicate seamlessly to know who is doing what, when, why, and how.
                        </Text>



                          


                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection:'column',
                        minHeight: '80px',
                        maxHeight: '80px',
                        height: '80px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        cursor:'pointer',
                        ':hover': {
                            background: 'rgba(234, 238, 242, 0.5)'
                        }
                    }}
                    onClick={()=>{ setshareRole("Read only") }}
                    >

                        <Box sx={{
                            position: 'relative',
                            margin: '12px 14px 0px 14px',
                            display: 'flex',
                             
                        }}>
                            <FormControl sx={{

                            }}>
                                <Radio 
                                 value="Collaborator"
                                 checked={shareRole==="Read only"?true:false}
                                
                                />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292E'
                                    }}
                                >Admin</FormControl.Label>
                            </FormControl>

                        </Box>

                        <Text sx={{
                            position: 'relative',
                            margin: '5px 15px 0px 35px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#24292E'
                        }}>
                            Access to task  and team management(without billing or organization wide settings).
                        </Text>


                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection:'column',
                        minHeight: '80px',
                        maxHeight: '80px',
                        height: '80px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        cursor:'pointer',
                        ':hover': {
                            background: 'rgba(234, 238, 242, 0.5)'
                        }
                    }}
                    onClick={()=>{ setshareRole("Owner") }}
                    >


                        <Box sx={{
                            position: 'relative',
                            margin: '12px 14px 0px 14px',
                            display: 'flex',

                        }}>
                            <FormControl sx={{}}>
                                <Radio
                                    value="Owner"
                                    checked={shareRole === "Owner" ? true : false}

                                />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292E'
                                    }}
                                >Owner</FormControl.Label>
                            </FormControl>

                        </Box>

                        <Text sx={{
                            position: 'relative',
                            margin: '5px 15px 0px 35px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#24292E'
                        }}>
                            Full access to the entire organization
                        </Text>


                    </Box>


                </Box>


                <Box sx={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                }}>

                    <Button
                        variant="invisible"
                        sx={{
                            width: '72px',
                            height: '32px',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}
                        onClick={()=>{
                            closesharingDialog("")
                        }}
                    >
                        cancel
                    </Button>
                    <Button
                        variant="outline"
                        sx={{
                            width: '78px',
                            height: '32px',
                            background: '#0965CE',
                            color: 'white',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}
                        onClick={() => {
                            handleShareToDb("")
                        }}
                    >
                        Create
                    </Button>

                </Box>





            </Box>

            

             



        </Dialog>
    )

}

export default InviteTeamMembers