
import React from 'react'
import { useLocation, useNavigate,Link } from 'react-router-dom';
import { Box, ActionMenu, ActionList, Button, Avatar, AnchoredOverlay, IconButton, Text } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { scheduleDemoOpenClose } from '../redux/features/scheduleDemoSlice';
import { searchOpenClose } from "../redux/features/searchSlice"
import workplaceTheme from './Workplacetheme'
import { BellIcon, QuestionMarkCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import ScheduleDemoDialog from './Dialogs/ScheduleDemoDialog';
import { Auth } from 'aws-amplify';
import HeaderSubComponent from './HeaderSubComponent';
import {UnderlineNav} from '@primer/react/drafts'



const logoUrl = "https://d2fcqkt9srr3ti.cloudfront.net/logo.png"
const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"



function Header() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const schedulereturnFocusRef = React.useRef(null)
    const [showUnderlineNav,setshowUnderlineNav] = React.useState(false)
    const [showItHeaderNav,setshowItHeaderNav] = React.useState(false)

    const [selectedIndex,setselectedIndex] = React.useState(0)
    const [selectedItHeadIndex,setselectedItHeadIndex] = React.useState(0)
     

    const [items, setItems] = React.useState([
        { navigation: 'Profile', route: "/gst", isSelected: false },
        { navigation: 'Return', route: "/return", isSelected: false },
        { navigation: 'Demand', route: "/demand", isSelected: false },
        { navigation: 'Ledger', route: "/ledger", isSelected: false },
        { navigation: 'Additional Notice', route: "/additionalnotice", isSelected: false },
        { navigation: 'Notice', route: "/notice", isSelected: false },
        { navigation: 'Refund', route: "/refund", isSelected: false },
        { navigation: 'Challan', route: "/challan", isSelected: false },
    ]) 

    const [itHeads, setitHeads] = React.useState([
        { navigation: 'Profile', route: "/It", isSelected: false },
        { navigation: 'Return', route: "/ItReturn", isSelected: false },
        { navigation: 'Demand', route: "/ItDemand", isSelected: false },
        { navigation: 'Ledger', route: "/ItLedger", isSelected: false },
        { navigation: 'Proceeding', route: "/proceeding", isSelected: false },
        { navigation: 'Proceeding notice', route: "/proceedingNotice", isSelected: false },
        { navigation: 'Challan', route: "/ItChallan", isSelected: false },
    ])


 


    return (


        <Box
            position="fixed"
            width={'100%'}
            height={48}
            background="#FFFFFF"
            borderColor="border.default"
            borderWidth={1}
            borderStyle="solid"
            p={0}
            zIndex={10}

        >
            <Box sx={workplaceTheme.headerInnerStyle}>

                <img
                    style={workplaceTheme.logoStyle}
                    src={logoUrl}
                    onClick={() => { navigate('/workplace') }}
                />


                <Box
                   
                     style={{
                           display:
                           location.pathname==="/notice"||
                           location.pathname==="/gst"||
                           location.pathname==="/demand"||
                           location.pathname==="/additionalnotice"||
                           location.pathname==="/return"||
                           location.pathname==="/refund"||
                           location.pathname==="/ledger"||
                           location.pathname==="/challan"

                           ?"flex":"none"
                     }}
                     sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left:'314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',
                        
                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        }}

                    >
                        {items.map((item, index) => (


                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>

                 
                <Box
                    style={{
                        display:
                            location.pathname === "/It" ||
                                location.pathname === "/ItDemand" ||
                                location.pathname === "/proceeding" ||
                                location.pathname === "/proceedingNotice" ||
                                location.pathname === "/ItReturn" ||
                                location.pathname === "/ItLedger" ||
                                location.pathname === "/ItChallan"
                                ? "flex" : "none"
                    }}
                    sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left: '314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',

                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        }}

                    >
                        {itHeads.map((item, index) => (
                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedItHeadIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedItHeadIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedItHeadIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>


                <HeaderSubComponent />



            </Box>


            <ScheduleDemoDialog returnFocusRef={schedulereturnFocusRef} />


        </Box>

    )


}

export default Header
