import React from "react"
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Text, TextInput, Button, Spinner,Link } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import Workplacetheme from "../Workplacetheme";
 
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { addPanOpenClose, uploadPanOpenClose } from "../../redux/features/contactSlice";

function AddPanDialog(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const panOpenClose = useSelector((state) => state.contactProfileData)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()
    
    const [currentRef, setcurrentRef] = React.useState("panName")
    const [panNameInput, setpanNameInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    const closeAddPanDialog = (props) => {
        dispatch(addPanOpenClose({
            panOpenClose: false
        }))
    }

    const showUploadPanDialog = (props) => {
        dispatch(uploadPanOpenClose({panUploadOpenClose:true}))
    }

    React.useEffect(()=>{
      setcurrentRef((prevState)=>{return "panName"})
    },[panOpenClose])


    return (

        <Dialog
            sx={{
                height:'315px',
                width:'564px',
                 
            }}
            
            isOpen={panOpenClose.panOpenClose}
            onDismiss={() =>

                closeAddPanDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text sx={Workplacetheme.scheduleDalogeHeading}>Add new PAN</Text>
               
            </Dialog.Header>

            <Box sx={{
                  display:'flex',
                  flexDirection:'column',
                  width:564,
                  height:'fit-content',
                  
            }}>

                <Text sx={{
                        display:'flex',
                        position:'relative',
                        margin:'20px 20px 0px 20px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight:'400',
                        fontSize: '14px',
                        lineHeight:'20px',
                        color:'#6A737D',
                    }}>Please enter data to fetch information.</Text>

                <Box sx={{
                      position:'relative',
                      margin:'23px 20px 0px 20px',
                      fontFamily:'Segoe UI'
                }}>

                    <TextInput
                        sx={{
                            minHeight:'40px',
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontStyle:'normal',
                            fontWeight:'400',
                            lineHeight:'20px'
                        }}
                        ref={currentRef === "panName" ? initialFocusRef : null}
                        onFocus={() => setcurrentRef("panName")}
                        onBlur={() => setcurrentRef("")}
                        width={'100%'}
                        aria-label="panName"
                        name="panName"
                        placeholder="Enter name"
                        value={panNameInput.value}
                        validationStatus={panNameInput.validationStatus}
                        onChange={(e) => {
                            setpanNameInput((prevState) => {
                                const newState = { ...prevState, value: e.target.value,validationStatus:'' }
                                return newState
                            })
                        }}
                    />



                </Box>

               

                <Box sx={{
                    position:'relative',
                    margin:'20px 20px 0px 20px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'15px'
                }}>
                    <TextInput
                     sx={{
                        width:'255px',
                        height:'40px',
                        minHeight:'40px',
                        maxHeight:'40px',
                        fontFamily:'Segoe UI',
                        fontStyle:'normal',
                        fontWeight:'400',
                        fontSize:'14px',
                        lineHeight:'20px',

                     }}
                     placeholder={"PAN"}
                    
                     
                     /> 
                      <TextInput
                    
                    sx={{
                        width:'255px',
                        height:'40px',
                        minHeight:'40px',
                        maxHeight:'40px',
                        fontFamily:'Segoe UI',
                        fontStyle:'normal',
                        fontWeight:'400',
                        fontSize:'14px',
                        lineHeight:'20px',

                     }}
                     type="password"
                     placeholder={"Enter password "}
                    
                     
                     /> 

                     
                     
                    
                </Box>

                <Link 
                sx={{
                    position:'relative',
                    margin:'13px 20px 10px 20px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'400',
                    fontSize:'14px',
                    lineHeight:'20px',
                    color:'#24292F',
                    cursor:'pointer'
                }}
                underline={true}
                onClick={()=>{
                    showUploadPanDialog("")
                }}
                >Upload bulk PAN</Link>

                <Box

                    sx={Workplacetheme.createSpaceDialogFooterLine}

                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">

                        
                    </Box>





            </Box>

            <Box
                ml={24}
                mr={24}
                mb={10}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >

                <Button variant="invisible"
                    sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                    onClick={() => closeAddPanDialog("")}
                >Cancel</Button>

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button
                            style={{width:'58px'}}
                            variant="outline"
                          
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() =>  
                             {
                                   
                             }
                            }
                        >
                            Add

                        </Button>


                }

               

                


            </Box>





        </Dialog>

    )

}

export default AddPanDialog