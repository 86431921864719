 
import React from 'react';
import customTheme from "../../Theme.module.scss"   
import customThemVariable from "../../ThemeVariable.module.scss"
const CustomCircleProgress = ({ progress,unit,stroke,fill }) => {
  const radius = 36;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - progress / 100 * circumference;

  return (
    <svg height="80" width="80">
      <circle
        stroke={stroke}
        fill={fill}
        strokeWidth="6"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={radius}
        cx="40"
        cy="40"
        strokeLinecap="round"
      />
      <text 
      className={customTheme.textbold} 
      x="52%" 
      y="56%" 
      textAnchor="middle" 
     
      >
        {unit}
      </text>
    </svg>
  );
};

export default CustomCircleProgress;
