import { Box } from '@primer/react'
import HeaderStyle from "./Header.module.scss"
import { useNavigate } from 'react-router-dom'
const logoUrl = "https://d2fcqkt9srr3ti.cloudfront.net/logo.png"
const portalHeaderLogo = 'https://d2fcqkt9srr3ti.cloudfront.net/AccountSetup/portalLogo.svg'

function Header() {

       const domainName = window.location.hostname
       const navigate = useNavigate()
       return (

              <Box position="fixed"
                     width={'100%'}
                     height={48}
                     background="#FFFFFF"
                     borderColor="border.default"
                     borderWidth={1}
                     borderStyle="solid"
                     p={0}
                     zIndex={1}
              >

                     <Box sx={{
                            display: 'flex',
                            border: 'none',
                            position: 'absolute',
                            left: '50px',
                            right: '80px',
                            top: '0px',
                            bottom: '0px',
                            margin: 'auto',
                            height: 'fit-content'

                     }}
                     >

                            {domainName === "www.app.taxherohq.com" ?

                                   <img
                                          className={HeaderStyle.logoStyle}
                                          src={portalHeaderLogo}
                                          onClick={() => {
                                                 window.location.href = 'https://www.taxherohq.com/'
                                          }}
                                   />:
                             domainName === "www.get.taxherohq.com" ?
                                    <img
                                          className={HeaderStyle.logoStyle}
                                          src={portalHeaderLogo}
                                          onClick={() => {
                                                 window.location.href = 'https://get.taxherohq.com/'
                                          }}
                                   />
                                   :
                                   <img
                                          className={HeaderStyle.logoStyle}
                                          src={logoUrl}
                                          onClick={() => {
                                                 window.location.href = 'https://www.deski.co/'
                                          }}
                                   />

                            }



                     </Box>


              </Box>

       )


}

export default Header
