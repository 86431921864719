import React from "react"
import { Box, ActionMenu, ActionList, Avatar,IconButton,AnchoredOverlay,Tooltip, Text,Link, Dialog, Button } from "@primer/react"
 
import { useNavigate, useLocation } from "react-router-dom"
import { MagnifyingGlassIcon, QuestionMarkCircledIcon, BellIcon, Cross2Icon, FileIcon } from "@radix-ui/react-icons"
import { XCircleIcon, LinkIcon, VerifiedIcon, AlertIcon, FeedPersonIcon} from '@primer/octicons-react'
import { useDispatch, useSelector } from "react-redux"
import { gstSearchDialogOpenClose, itSearchDialogOpenClose, searchOpenClose } from "../redux/features/searchSlice"
import { scheduleDemoOpenClose } from "../redux/features/scheduleDemoSlice"
import { Auth } from "aws-amplify"
import { Toaster, Intent,Position } from "@blueprintjs/core";
import workplaceTheme from "./Workplacetheme"
import ScheduleDemoDialog from "./Dialogs/ScheduleDemoDialog"
import { getUserProfileFromDatabase, updateProfileImageRealTimeLocal,clearUserNotification, logoutFromCognitoUser } from "../redux/features/profileSlice"
import { getDataByTaskId } from "../redux/features/feedSlice"
import { getSingleWorkplaceProject, postManualComplianceTasks } from "../redux/features/spaceSlice"
import { createTaskOpenClose, openCloseTaskExpansion } from "../redux/features/taskSlice"
import { getnotitificationData } from "../redux/features/headerSlice"
import { upgradePlanOpenClose } from "../redux/features/contactSlice"
import UpgradePlan from "../Home/UpgradePlan"
import axios from 'axios';
import mixpanel from "mixpanel-browser"
import TaskExpansion from "../Space/TaskExpansion"
mixpanel.init('42f38a9f4804449b88177fb8bbfec5c6', { debug: true, ip: true });

const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"

function HeaderSubComponent(props) {

    

    const [notification,setnotification] = React.useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const upgradeisOpen = useSelector((state) => state.contactProfileData.upgradeisOpen)
    const scheduleDemoStatus = useSelector((state) => state.openCloseScheduleDemo)
    const profileData = useSelector((state) => state.profileData)
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const userImageUrl = useSelector((state) => state.profileData.userImageUrl ) 

    const [userEmailId, setUserEmailId] = React.useState(userEmail)
    const [userProfileImage,setuserProfileImage] = React.useState(userImageUrl)
    const [profileopen, setprofileopen] = React.useState(false)
    const [questionopen, setquestionopen] = React.useState(false)

    const profileRef = React.createRef()
    const questionRef = React.createRef()
    const schedulereturnFocusRef = React.useRef(null)

    const notificationBtnRef = React.useRef(null)
  
    const [notificationActionMenu,setnotificationActionMenu] = React.useState(false)
   
    const notificationOpenOverlay = React.useCallback(() => setnotificationActionMenu(true), [setnotificationActionMenu])
    const notificationCloseOverlay = React.useCallback(() => setnotificationActionMenu(false), [setnotificationActionMenu])


    
    const clearUserNotificationIndb = (props) => {

         dispatch(clearUserNotification({
              userId:userId,
              userEmail:userEmail,
              userNotification:'Clear'
         })).then((data)=>{
    
                  
                   Toaster.create({ position: Position.TOP }).show({
                    message: "Success! All notifications have been cleared.",
                    intent: Intent.PRIMARY
                  });
                   setnotification(data.payload.data[0].notification)
         })

    }

    const showUpgradePlan = (props) => {

          
        dispatch(upgradePlanOpenClose({
            upgradeisOpen:true
         }))

    }


    const showSearchDialog = (props) => {

        
       

        if(location.pathname==="/space/"+localStorage.getItem("projectName").replace(/ /g, "%20") || location.pathname==="/list"){


             dispatch(searchOpenClose({
                openClose: true
             }))
           

        }else {

            if (location.pathname === "/gstpanlist") {

                dispatch(gstSearchDialogOpenClose({
                    gstsearchopenClose: true
                }))

            }

            if (location.pathname === "/itpanlist") {

                dispatch(itSearchDialogOpenClose({
                    itsearchopenClose: true
                }))
            }

           
            
        }

      
    }

    const handleLogout = async (props) => {

        

             dispatch(logoutFromCognitoUser({
                isLogout:true
             }))
 
    }

    const trackNotiticationClick =async (props) => {


        const res = await axios.get('https://api.ipify.org?format=json')
        const sourceIp = res.data.ip;
         
         const notificationClickByUser = mixpanel.track('Notification', {
             distinct_id: userId,
             ip: sourceIp,
             time: new Date().getTime(),
         })

        //--------- Posthog event ---------------------

        if (userId !== null && userEmail !== null) {

            const postData = {
                api_key: 'phc_xVkSTHqo3r9DfkSr2gZFn2JhWpgCnk9yE5VyUHp4e9v',
                event: 'NotificationClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                    email:`${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = 'https://app.posthog.com/capture/';

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }

          
    }




    const goToAccountPage = (props) => {

         
        localStorage.setItem("locationPath", location.pathname)

    }

    const handleClickNotification = (props) => {

           dispatch(getDataByTaskId({
            userId: userId,
            userEmail: userEmail,
            taskId: props.n_task_id,
           })).then((data)=>{

                 console.log("notification response",data.payload)
                 const taskId = data?.payload?.data?.taskId
                  
                 if(taskId==="null"){

                       Toaster.create({ position: Position.TOP }).show({
                        message: "The task is unavailable",
                        intent: Intent.DANGER
                       });

                 }else{

                    notificationCloseOverlay()
                    localStorage.setItem("taskId",taskId)
                    navigate('/taskexpansionDetails')
  

                 }

                
           })

       
            

    }

    const handleAcceptManualRecurringTask = (props) => {

        dispatch(postManualComplianceTasks({
            userId: userId,
            userEmail: userEmail,
            notificationData: props
        })).then((data) => {

            console.log("postManualComplianceTasks", data.payload)
            const noArray = [...data?.payload?.notification] || []
            setnotification(noArray)

        })

    }

     
    React.useState(()=>{

        dispatch(getUserProfileFromDatabase({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

           
           
            setUserEmailId(data.payload.data[0]?.user_email)
            setuserProfileImage(data.payload.data[0]?.user_image_url)
            
        })
    }) 


    React.useEffect(()=>{

        setuserProfileImage(userImageUrl)
 
    },[userImageUrl])

    React.useEffect(()=>{

         
           dispatch(getUserProfileFromDatabase({
              userId:userId,
              userEmail:userEmail
           })).then((data)=>{

                const noArray = [...data?.payload?.data[0]?.notification] || []
                setnotification(noArray)
           })

 
    
    },[])

    
    



    return (
        <Box sx={workplaceTheme.headerProfileBox}>

         

            <IconButton
             
             sx={{
                border:'none',
                background:'#ffffff', 
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderRadius:'3px',
                position:'relative',
                margin:'0px 4px 0px 0px',
                boxShadow:'none',   
             }}
             icon={()=> 
             
                 <Tooltip aria-label="Search" direction="s">
                     <MagnifyingGlassIcon style={{ display: 'flex' }} />
                 </Tooltip>
               }
             onClick={() => showSearchDialog("")}
            />

           

            <IconButton
                ref={questionRef}
                aria-haspopup="true"
                aria-expanded={questionopen}
                sx={{
                    border: 'none',
                    background: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    position:'relative',
                    margin:'0px 4px 0px 0px',
                    boxShadow:'none', 
                }}
                icon={() => 
                
                <Tooltip aria-label="Help center" direction="s">
                <QuestionMarkCircledIcon style={{ display: 'flex' }} />
                </Tooltip>
                }
                onClick={() => setquestionopen(!questionopen)}
            />



             
            <ActionMenu open={questionopen} onOpenChange={setquestionopen} anchorRef={questionRef}>
                <ActionMenu.Overlay sx={workplaceTheme.questionActionMenuOverlay}>
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Help center</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Message support</ActionList.Item>
                        <ActionList.Item
                            ref={schedulereturnFocusRef}
                            onSelect={event =>

                                dispatch(scheduleDemoOpenClose({
                                    openClose: true,
                                    title:'Schedule demo'
                                })).then((data) => {

                                     
                                })

                            }
                            sx={workplaceTheme.profileActionMenuListItem}>Schedule demo</ActionList.Item>
                        <ActionList.Divider />
                        <ActionList.Item 
                        sx={workplaceTheme.profileActionMenuListItem}
                        onSelect={event =>

                            dispatch(scheduleDemoOpenClose({
                                openClose: true,
                                title:'Contact sales'
                            })).then((data) => {

                                 
                            })

                        }
                        >Contact sales</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                        onSelect={(e)=>showUpgradePlan("")}
                        >Plans and pricing</ActionList.Item>
                    </ActionList>
                </ActionMenu.Overlay>
            </ActionMenu>


            <ActionMenu open={notificationActionMenu} onOpenChange={setnotificationActionMenu}  anchorRef={notificationBtnRef}>
                <ActionMenu.Anchor 
                
                >
                    <IconButton
                         sx={{
                            border: 'none',
                            background: '#ffffff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '3px',
                            position: 'relative',
                            margin: '0px 24px 0px 0px',
                            boxShadow:'none',
                        }}
                        icon={() => 
                        
                            <Tooltip aria-label="Notification" direction="s">
                                <BellIcon onClick={() => 
                                   {
                                    notificationOpenOverlay()
                                    trackNotiticationClick("")
                                   }
                                  
                                } style={{ display: 'flex' }} />
                            </Tooltip>
                        }
                        onClick={()=>  {
                            notificationOpenOverlay()
                            trackNotiticationClick("")
                           } } 
                    />
                </ActionMenu.Anchor>

                <ActionMenu.Overlay
                  sx={{marginTop:'12px',borderRadius:'0px',width:'516px',minWidth:'516px',maxWidth:'516px',minHeight:'495px',maxHeight:'495px',height:'495px',borderRadius:'3px'}}
                >



                    <ActionList
                        
                        sx={{
                            width:'516px',
                            minWidth:'516px',
                            maxWidth:'516px',
                            minHeight:'495px',
                            maxHeight:'495px',
                            height:'495px',
                            display: 'flex',
                            flexDirection: 'column',
                            fontFamily:'Segoe UI',
                            borderRadius:'0px',
                           
                        }}

                    >

                        <Text
                      
                        sx={{
                            position:'absolute',
                            left:'17px',
                            top:'9px',
                            width:'76px',
                            height:'36px',
                            lineHeight:'36px',
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontWeight:'400'
                        }}>Notification</Text>

                      
                         
                         <IconButton sx={{
                            width:'15px',
                            height:'15px',
                            background:'white',
                            position:'absolute',
                            right:'17px',
                            top:'18px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            border:'none',
                         }} 
                         size="small" 
                         icon={()=> <Box sx={{display:'flex',height:'15px',width:'15px'}}><Cross2Icon scale={9} /></Box> }
                         onClick={()=>{

                             notificationCloseOverlay()

                         }} 
                         />


                        <Box sx={{ position: 'absolute', left: '0px', right: '0px', margin: 'auto', top: '174px', height: '15px', width: '15px',zIndex:1,display:notification.length>0?"none":"flex" }}><FileIcon />
                        </Box>
                        <Box sx={{ position: 'absolute', left: '0px', right: '0px', margin: 'auto', top: '206px', height: '40px', width: '420px',zIndex:1,display:notification.length>0?"none":"flex" }}>

                            <Text sx={{
                                display: 'flex',
                                height: '40px',
                                width: '420px',
                                fontFamily: 'Segoe UI',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',

                            }}>
                                We will show your future @mentions, assignments, and relevant comments here.
                            </Text>
                        </Box>

                        
                        
                         <Box sx={{position:'absolute',left:'17px',right:'0px',top:'62px',bottom:'56px',left:'17px',background:'white',overflowY:'auto',overflowX:'hidden',paddingRight:'8px'}}>
                           
                              
                                    {notification
                                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                    .map((row,index)=>{

                                     return(
                                        <ActionList.Item
                                             sx={{
                                                 width: '100%',
                                                 margin: 'auto',
                                                 padding: '0px'
                                             }}
                                             onClick={() => {

                                                 if (row?.row.n_icon === "RecurringTask") {

                                                 } else {
                                                     handleClickNotification(row)
                                                 }


                                             }}
                                        
                                    >
                                        <ActionList.LeadingVisual>
                                            <Box
                                             sx={{
                                                height:'8px',
                                                width:'8px',
                                                minHeight:'8px',
                                                minWidth:'8px',
                                                background:
                                                row.n_icon==="DueDate2Days"?"#CF222E":
                                                row.n_icon==="DueDate"?"#CF222E":
                                                row.n_icon==="Activity"?"#AFB8C1":
                                                row.n_icon==="Priority"?"#CF222E":
                                                row.n_icon==="Attachment"?"#28A745":
                                                row.n_icon==="Assigne"?"#28A745":
                                                row.n_icon==="Comment"?"#AFB8C1":
                                                row.n_icon==="Workflow"?"#CF222E":
                                                row.n_icon==="DueDateUpdated"?"#AFB8C1":
                                                "#AFB8C1",  
                                                marginLeft:'-8px'
                                                
                                                }}></Box>
                                        </ActionList.LeadingVisual>
                                           <Text sx={{marginLeft:'-8px',fontFamily:'Segoe UI',fontStyle:'normal',fontSize:'12px',fontWeight:'400',lineHeight:'20px',color:'#57606A'}}>
                                            {
                                            row.n_icon==="DueDate2Days"?"Due in 2 days":
                                            row.n_icon==="DueDate"?"Due today":
                                            row.n_icon==="Activity"?"Record activity":
                                            row.n_icon==="Priority"?"Priority updated":
                                            row.n_icon==="Attachment"?"Attachment":
                                            row.n_icon==="Assigne"?"Task Assigned":
                                            row.n_icon==="Comment"?"Comment added":
                                            row.n_icon==="Workflow"?"Workflow updated":
                                            row.n_icon==="RecurringTask"?"Recurring Task":
                                            row.n_icon==="DueDateUpdated"?"Revised Compliance Due Date":
                                            row.n_icon==="RecurringTaskManual"? "Recurring Task" :
                                            ""
                                            }
                                           </Text>
                                          
                                             <ActionList.Description
                                                 variant="block"
                                                 sx={{
                                                     marginLeft: '-8px',
                                                     marginTop: '7px',
                                                     fontFamily: 'Segoe UI',
                                                     fontStyle: 'normal',
                                                     fontWeight: '400',
                                                     fontSize: '14px',
                                                     lineHeight: '20px',
                                                     color: '#202420',
                                                     marginBottom: '20px'
                                                 }}
                                             >

                                                 {row.n_content}

                                             </ActionList.Description>

                                             <Button
                                                 variant="outline"
                                                 sx={{
                                                     display: row.n_icon === "RecurringTask" ? "flex" : 'none',
                                                     position: 'absolute',
                                                     right: '10px',
                                                     background: '#0965CE',
                                                     color: '#ffffff',
                                                     margin: 'auto'

                                                 }}
                                                 onClick={() => {
                                                    handleAcceptManualRecurringTask(row)        
                                                 }}
                                             >
                                                 Accept
                                             </Button>


                                    </ActionList.Item>
                                     )

                             })}

                            



                         </Box>



                        

                        <Link
                         sx={{
                            display:notification.length>0?"flex":"none",
                            position:'absolute',
                            right:'20px',
                            bottom:'17px',
                            width:'fit-content',
                            height:'20px',
                            lineHeight:'20px',
                            fontStyle:'normal',
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontWeight:'400',
                            color:'#0969DA',
                            cursor:'pointer'
                         }}
                         
                         onClick={()=>{
                             notificationCloseOverlay()
                             clearUserNotificationIndb("")
                         }}
                         >Clear all</Link>

                         
 
                        
                    </ActionList>



                </ActionMenu.Overlay>
            </ActionMenu>
         


          

 
          
            {userProfileImage === "" || userProfileImage == null ?

                <Box
                    ref={profileRef}
                    aria-haspopup="true"
                    aria-expanded={profileopen}
                    onClick={() => setprofileopen(!profileopen)}
                    sx={workplaceTheme.headerProfileAvatar}
                    
                >
                    <Tooltip aria-label={userEmail} direction="s">
                    <FeedPersonIcon
                        size={32}
                        fill="#586069"

                    />
                    </Tooltip>
                </Box>
               
                :
                <Tooltip aria-label={userEmail} direction="s">
                <Avatar
                    ref={profileRef}
                    aria-haspopup="true"
                    aria-expanded={profileopen}
                    onClick={() => setprofileopen(!profileopen)}
                    sx={workplaceTheme.headerProfileAvatar}
                    size="32"
                    src={userProfileImage}

                />
                </Tooltip>


            }

            

            <ActionMenu open={profileopen} onOpenChange={setprofileopen} anchorRef={profileRef}>
                <ActionMenu.Overlay sx={workplaceTheme.profileActionMenuOverlay}>
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem} >
                            {/* {profileData?.profileData?.signInUserSession?.idToken?.payload.email.substring(0, 22) || "abc@gmail.com"} */}
                            {userEmail?.substring(0, 21) || ""}
                        </ActionList.Item>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onClick={() => {

                                navigate('/account')
                                goToAccountPage("")
                            }}
                        >Account</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                        
                        onSelect={(e)=> showUpgradePlan("") }
                        >Upgrade</ActionList.Item>
                        <ActionList.Divider />
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                          onSelect={event =>

                            dispatch(scheduleDemoOpenClose({
                                openClose: true,
                                title:'Contact sales'
                            })).then((data) => {

                                 
                            })

                        }
                        
                        >Contact sales</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => { handleLogout("") }}
                            
                        >Logout</ActionList.Item>
                    </ActionList>
                </ActionMenu.Overlay>
            </ActionMenu>


            
          
            {ScheduleDemoDialog("")}


            {/* {upgradeisOpen==true?UpgradePlan(""):""} */}
              
             
            {upgradeisOpen==true?<UpgradePlan/>:""}

            {/* <TaskExpansion/> */}
          
           
        </Box>
    )

}

export default HeaderSubComponent