import React from "react"
import { ActionList, ActionMenu, Box, Button, Heading, IconButton, Text, ProgressBar, FormControl, Checkbox, TextInput,Timeline,Link, Select, Avatar, Textarea, Dialog, Portal,Token,Tooltip, AnchoredOverlay } from "@primer/react"
import { ChecklistIcon, GrabberIcon, IssueOpenedIcon, TriangleDownIcon, FeedPersonIcon } from "@primer/octicons-react"
import { CopyIcon, CountdownTimerIcon, Cross2Icon, DotsHorizontalIcon, Pencil2Icon, LapTimerIcon, PlusIcon, DotsVerticalIcon, PersonIcon, InputIcon, CalendarIcon, ReaderIcon, LightningBoltIcon, DrawingPinIcon, UploadIcon, CheckIcon,Pencil1Icon,TrashIcon,ClockIcon,CameraIcon,EnvelopeClosedIcon,SewingPinIcon, HamburgerMenuIcon } from "@radix-ui/react-icons"
import { useDispatch } from "react-redux"
import { addTaskComments, deleteTaskActivity, deleteTaskById, deleteTaskComments, duplicateTask, getGoogleAuth, getGoogleCalanderAuth, getSingleSpaceTasks, getSubtaskPrimary, getTaskProjectHistory, getZohoAuth, getZohoInvoice, markasVerified, openCloseTaskExpansion, postSubtaskPrimary, postZohoInvoice, reloadTaskTrigger, subtaskCompletedDialogOpenClose, updateTaskActivity, updateTaskAssigne, updateTaskAttachments, updateTaskDescription, updateTaskDueDate, updateTaskName, updateTaskPriority, updateTaskSubtask, updateWorkflowId } from "../redux/features/taskSlice"
import { useSelector } from "react-redux"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { UnderlineNav } from '@primer/react/drafts'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getSpaceCollaborators } from "../redux/features/spaceSlice"
import EmptyData from "./EmptyData"
import AWS from 'aws-sdk'
import { useNavigate } from "react-router-dom"
 
const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION


AWS.config.update({
    accessKeyId: IAM_USER_KEY,
    secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
    region: REGION,
})

const docIcon = "https://d2fcqkt9srr3ti.cloudfront.net/docIcon.svg"
const imgIcon = "https://d2fcqkt9srr3ti.cloudfront.net/imgIcon.svg"
const normalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/normal.svg"
const mediumIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/medium.svg"
const highIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/high.svg"
const criticalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/critical.svg"
const lowIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/low.svg"




function generateUUID() {
    let uuid = '', i, random;

    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }

        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random))
            .toString(16);
    }

    return uuid;
}

function generateMeetingID(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let meetingID = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      meetingID += characters.charAt(randomIndex);
    }
  
    return meetingID;
  }

function getNumberofDays(date1, date2) {

    const oneDay = 24 * 60 * 60 * 1000
    const firstDate = date1
    const secondDate = date2
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
    return diffDays;

}

function getAMPMfromTime(time) {


    var hours = time.split(':')[0];
    var minutes = time.split(':')[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;

}


export default function TaskExpansion() {

    const gapi = window?.gapi
    const google = window?.google

    const zohoTokens   = JSON.parse(localStorage.getItem("zohoTokens")) || ""

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const taskData = useSelector((state) => state.taskData)
    const openCloseExpansion = useSelector((state) => state.taskData.openCloseExpansion)
    const subtaskCompletedOpenClose = useSelector((state)=> state.taskData.subtaskCompletedOpenClose)
    const [items, setitems] = React.useState([{ navigation: 'Subtask' },{ navigation: 'To do', counter: 0 },{navigation: 'Activity',counter:0},{ navigation: 'Comment', counter: 0 }, { navigation: 'Attachments', counter: 0 }, { navigation: 'Invoice'}])

    const [taskKey,settaskKey] = React.useState("") 
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [contact, setContact] = React.useState("")
    const [complianceName, setcomplianceName] = React.useState("")
    const [completedStatus, setcompletedStatus] = React.useState("false")
    const [selectedAssigne, setselectedAssigne] = React.useState({ user_id: '', user_name: '',user_email:''})
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [compliaceSubtasks, setcompliaceSubtasks] = React.useState([])
    const [showworflowDropDownIcon, setshowworflowDropDownIcon] = React.useState(false)
    const [selectedWorkflow, setSelectedWorkFlow] = React.useState({workflow_id: '',workflow_name: ''})
    const [workflow, setworkflow] = React.useState([])
    //-------- subtask primary
    const [subtaskTabVisiblity,setsubtaskTabVisiblity] = React.useState(true)
    const [subtaskPrimaryArray,setsubtaskPrimaryArray] = React.useState([])
    const [showSubtaskInputPrimary,setshowSubtaskInputPrimary] = React.useState(false)
    const [subtaskInputPrimary,setsubtaskInputPrimary] = React.useState({
          value:'',
          validationStatus:''
    })

    const [subtaskPrimaryPercentage,setsubtaskPrimaryPercentage] = React.useState(0)

    const [subtaskAssigneActionMenu, setsubtaskAssigneActionMenu] = React.useState(false)
    const [selectedSubtaskAssigne,setselectedSubtaskAssigne] = React.useState("")
    const [searchsubtaskCollaborators,setsearchsubtaskCollaborators] = React.useState("")
    const openSubtaskAssigneActionMenuOverlay = React.useCallback(() => setsubtaskAssigneActionMenu(true), [setsubtaskAssigneActionMenu])
    const closeSubtaskAssigneWorkflowActionMenuOverlay = React.useCallback(() => setsubtaskAssigneActionMenu(false), [setsubtaskAssigneActionMenu])
  

    //-----------------------
    const [showSubtaskInput, setshowSubtaskInput] = React.useState(false)
    const [historyArray, sethistoryArray] = React.useState([])
    const [priorityArray, setpriorityArray] = React.useState([

        { name: 'Normal' },
        { name: 'Medium' },
        { name: 'High' },
        { name: 'Critical' },
        { name: 'Low' }
    
      ])
    const [selectedPriority, setselectedPriority] = React.useState(0)

    const [taskNameInput, settaskNameInput] = React.useState({value: '',validationStatus: ''})
    const [taskDescriptionInput, settaskDescriptionInput] = React.useState({value: "",validationStatus: ''})

    const [attachmentArray, setattachmentArray] = React.useState([])
    const [attachmentHoverId, setattachmentHoverId] = React.useState("")

    const computerFileRef = React.useRef()


    const [subtaskInput, setsubtaskInput] = React.useState({value: '',validationStatus: ''})
    const subtaskInputRef = React.createRef()
    const taskNameRef = React.createRef()
    const taskDescriptionRef = React.createRef()
    const dueDateRef = React.createRef()


    const [subtaskHoverId, setsubtaskHoverId] = React.useState("")
    const [subtaskPercent, setsubtaskPercent] = React.useState(0)
    const [isDataEditable, setisDataEditable] = React.useState(false)
    const [dueDate, setdueDate] = React.useState("")
    //------ comment section ----------
    const commentInputRef = React.createRef()
    const [commentInput, setcommentInput] = React.useState({value:'',validationStatus:''})
    const [commentEditId, setcommentEditId] = React.useState("")
    const [commentArray,setcommentArray] = React.useState([])
    //-------taskToDoSection ------------
    const activityInputRef = React.createRef()
    const activitycalandarRef = React.createRef()
    const activityTimeRef = React.createRef()
    const [activityArray, setactivityArray] = React.useState([])
    const [activityInput, setactivityInput] = React.useState({
        activityId: '',
        value: '',
        placeholder: 'Meeting',
        validationStatus: ''
    })
    const [selectedActivityDate, setselectedActivityDate] = React.useState("")
    const [selectedActivityTime, setselectedActivityTime] = React.useState("")
    const [selectedVideoCallType,setselectedVideoCallType] = React.useState("Google Meet")

    //---------------------------------------------
    const [showActivityGroupIcons, setshowActivityGroupIcons] = React.useState({
        id: "",
        visibility: false
      })
    const [showCommentGroupIcons, setshowCommentGroupIcons] = React.useState({
        id: "",
        visibility: false
    })

    const [invoices,setinvoices] = React.useState([])
    const [isZohoInstalled,setisZohoInstalled] = React.useState([])

    //------- google meet paramas --------
    const [googleMeetToken,setgoogleMeetToken] = React.useState("")



    //------------------------------------------------------
 
    const handleChangeSubtaskChecked = (e, taskId) => {

        setcompliaceSubtasks((prevState) => {
            const newState = prevState.map((row, index) => {
                if (row.task_id === taskId) {
                    return ({ ...row, isChecked: e })
                } else {
                    return row
                }
            })

            const totalTaskCount = compliaceSubtasks.length
            const totalCheckedTask = newState.filter((cData) => cData.isChecked === true).length
            const totalPercentage = (totalCheckedTask / totalTaskCount) * 100
            setsubtaskPercent(parseFloat(totalPercentage).toFixed(0))
            updateSubtaskInDb(newState)
            return newState
        })



    }


    const showconfirmCompletedSubtaskDialog = (props) => {

          dispatch(subtaskCompletedDialogOpenClose({
            subtaskCompletedOpenClose:true
          }))
          
    }
    
    


    const closeconfirmCompletedSubtaskDialog = (props) => {

         dispatch(subtaskCompletedDialogOpenClose({
            subtaskCompletedOpenClose:false
         }))
    }

    const moveTaskToCompleted = (props) => {

          const completedworkflowId = workflow.filter((wData)=> wData?.workflow_name==="Completed")[0]?.workflow_id
          const completedWorkflowName = workflow.filter((wData)=> wData.workflow_name==="Completed")[0].workflow_name
          if(completedworkflowId!==null&&completedworkflowId!==""){

            updateWorkflowIdToDb(completedworkflowId)
            closeconfirmCompletedSubtaskDialog("")
            setSelectedWorkFlow({
                workflow_id: completedworkflowId,
                workflow_name: completedWorkflowName
            })

          }
        

    }


    const closeTaskExpansion = (props) => {

        setisDataEditable(false)
        setSelectedWorkFlow({ workflow_id: '', workflow_name: '' })
        setselectedAssigne({ user_id: '', user_name: '',user_email:'' })
        settaskNameInput({ value: '', validationStatus: '' })
        settaskDescriptionInput({ value: '', validationStatus: '' })
        setcompliaceSubtasks([])
        setSelectedIndex(0)
        setdueDate("")
        setContact("")
        setattachmentArray([])
        setsubtaskInput({ value: '', validationStatus: '' })
        setshowSubtaskInput(false)
        setcomplianceName("")
        setinvoices([])
        settaskKey('')
        setsubtaskPrimaryPercentage(0)

        dispatch(openCloseTaskExpansion({
            openCloseExpansion: false,
            expansionTaskId: ""
        }))

        setitems((prevState) => {
            const newState = prevState.map((item) => { return ({ ...item, counter: 0 }) })
            return newState
        })
        setcommentInput((prevState)=>{
             return({...prevState,value:'',validationStatus:''})
        })
        setactivityInput( { activityId: '', value: '', placeholder: 'Meeting', validationStatus: '' })


    }


    const getGoogleMeetAuthToken = (props) => {

        dispatch(getGoogleAuth({
            userId:userId,
            userEmail:userEmail,
            projectId:localStorage.getItem("projectId")
        })).then(async(data)=>{

             console.log("getGoogleAuth",data.payload)
             const code = data?.payload?.checkApp?.code
             const googleTokenData = data?.payload?.data 
             const message = data?.payload?.data?.message

             console.log("code",code)
             console.log("message",message)

             if(code==404){

                Toaster.create({ position: Position.TOP }).show({
                    message: 
                    <Box sx={{minHeight:'100%',marginTop:'4px'}}>
                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '14px',
                        
                    }}>{"App not installed"}</Text>
                    </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Open Apps.</Text>,
                        onClick: () => navigate('/apps')
                    }
                });


             }else if(code==200){

                   if(message==="Already authenticated"){

                       const refreshToken = googleTokenData?.refresh_token;

                       const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                       const clientSecret = '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'
                       const tokenUrl = 'https://oauth2.googleapis.com/token';
                       const payload = {
                           grant_type: 'refresh_token',
                           client_id: clientId,
                           client_secret: clientSecret,
                           refresh_token: refreshToken,
                       };
                       const newTokenResult =await fetch(tokenUrl, {
                           method: 'POST',
                           headers: {
                               'Content-Type': 'application/x-www-form-urlencoded',
                           },
                           body: new URLSearchParams(payload).toString(),
                       }).then(response => response.json())
                       const newAccessToken = newTokenResult?.access_token
                       console.log("newAccessToken",newAccessToken)
               
                       if(newAccessToken!==null&&newAccessToken!==""&&newAccessToken!==undefined){
                
                                setgoogleMeetToken(newAccessToken)
                        }else{
                                setgoogleMeetToken("")
                        }
                      
                   
                   
                    }else{

                       Toaster.create({ position: Position.TOP }).show({
                           message:
                               <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                   <Text sx={{
                                       fontFamily: 'Segoe UI',
                                       fontStyle: 'normal',
                                       fontSize: '14px',
                                       fontWeight: '400',
                                       lineHeight: '14px',

                                   }}>{"App not authenticated"}</Text>
                               </Box>
                           , intent: Intent.PRIMARY, action: {

                               text: <Text sx={{
                                   fontFamily: 'Segoe UI',
                                   fontStyle: 'normal',
                                   fontSize: '14px',
                                   fontWeight: '400',
                                   lineHeight: '14px',

                               }}>Open Apps.</Text>,
                               onClick: () => navigate('/apps')
                           }
                       });

                   }



             }
             


        })

    }


    const handleDeleteSubtask = (props) => {

         const userRole = spaceCollaborators?.filter(sData=> sData.user_id===userId)[0]?.user_role
        if (userRole === "Owner" || userRole === "Read only") {

            dispatch(deleteTaskById({
                userId: userId,
                userEmail: userEmail,
                taskId: props
            })).then((data) => {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Task deleted.",
                    intent: Intent.DANGER
                });
                getSubtaskPrimaryFromDb(taskData.expansionTaskId)
               
            })

        } else {

            Toaster.create({ position: Position.TOP }).show({
                message: "Don't have permission",
                intent: Intent.PRIMARY
            });
        }


    } 


    const handleDeleteTask = (props) => {

           const userRole = spaceCollaborators?.filter(sData=> sData.user_id===userId)[0]?.user_role
          
           if(userRole==="Owner" || userRole==="Read only"){

            dispatch(deleteTaskById({
                userId: userId,
                userEmail: userEmail,
                taskId: taskData.expansionTaskId
            })).then((data) => {
    
                Toaster.create({ position: Position.TOP }).show({
                    message: "Task deleted.",
                    intent: Intent.DANGER
                });
    
                closeTaskExpansion("")
            })

           }else{
               
               Toaster.create({ position: Position.TOP }).show({
                   message: "Don't have permission",
                   intent: Intent.PRIMARY
               });
           }

        






    }

    const duplicateTaskInDb = (props) => {

        const userRole = spaceCollaborators?.filter(sData=> sData.user_id===userId)[0]?.user_role
          
        if(userRole==="Owner" || userRole==="Read only"){

            dispatch(duplicateTask({
                userId: userId,
                userEmail: userEmail,
                taskId: taskData.expansionTaskId,
                duplicateTaskId: taskData.expansionTaskId,
            })).then((data) => {
    
    
    
                Toaster.create({ position: Position.TOP }).show({
                    message: "Duplicated.",
                    intent: Intent.PRIMARY
                });
    
    
    
            })

        }else{

            Toaster.create({ position: Position.TOP }).show({
                message: "Don't have permission",
                intent: Intent.PRIMARY
            });

        }


        

    }

    const getZohoAuthFromDb = (props) => {

        dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            console.log("getZohoAuth In Compliance exp Dialog", data.payload)
            const appCode = data?.payload?.checkApp?.code
            const refreshToken = data?.payload?.data?.refresh_token

            console.log("refreshToken", refreshToken)
            if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {

                

            } else {

                 

            }

            if (appCode === 200) {

                setisZohoInstalled(true)

            } else if (appCode === 404) {

                setisZohoInstalled(false)
            }



        })

    }

    
    const downloadZohoInvoice = (props) => {

        dispatch(getZohoInvoice({
            userId: userId,
            userEmail: userEmail,
            zohoToken: zohoTokens,
            invoiceNumber:taskKey,
            projectId:localStorage.getItem("projectId")
        })).then(async(data)=>{

            console.log("downloadInvoice", data.payload)

            const invoiceId = data.payload.invoiceId
            const accessToken = data.payload.newAccessToken
            const orgId = data.payload.orgId
            const pdfContent = await data.payload.data.data
            const content = data?.payload?.getInvoiceAsHtmlResponse?.data;

            
            const options = {
                method: 'GET',
                headers: {
                    'X-com-zoho-invoice-organizationid': `${orgId}`,
                    Authorization: `Zoho-oauthtoken ${accessToken}`
                }
            };
              
            fetch(`https://www.zohoapis.com/invoice/v3/invoices/${invoiceId}?accept=pdf`, options)
            .then(pdfResponse => pdfResponse.blob())
            .then(pdfBlob => {
              // Create a link element to download the PDF
              const url = window.URL.createObjectURL(pdfBlob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = taskKey+'.pdf';
  
              // Trigger a click on the link to start the download
              document.body.appendChild(a);
              a.click();
  
              // Clean up after the download
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            })
            .catch(err => console.error('Error fetching PDF:', err));
 
            // if (content) {
            //     const printWindow = window.open('', '', 'width=600,height=600');
            //     printWindow.document.open();
            //     printWindow.document.write(content);
            //     printWindow.document.close();
            //     printWindow.print();
            //     printWindow.onafterprint = () => {
            //         printWindow.close();
            //     };
            // }

           // const invoicePdfFile = new File(pdfContent,{fileName:pdfContent})
           // console.log("invoicePdfFile",invoicePdfFile)
           


          //  const fileURL = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }));
            //  console.log("fileURL", fileURL)
            // window.open(fileURL)
           
        })


    }


    const getZohoInvoiceFromApi = (props) => {

             dispatch(getZohoInvoice({
                userId: userId,
                userEmail: userEmail,
                zohoToken: zohoTokens,
                invoiceNumber:props,
                projectId:localStorage.getItem("projectId")
            })).then((data)=>{

                 console.log("getZohoInvoice",data.payload)
                 const pdfData = data?.payload?.data?.data
                 const pdfString = data?.payload?.getInvoiceAsHtmlResponse?.data
                 
                 if(data?.payload?.getInvoiceAsJsonResponse?.data?.code==5){
                    
                    
                 }else if(data?.payload?.getInvoiceAsJsonResponse?.data?.code==0){

                    const invoiceObjArray = [data?.payload?.getInvoiceAsJsonResponse?.data?.invoice]
                    console.log("invoiceObjArray",invoiceObjArray)
                    setinvoices(invoiceObjArray)

                    setitems((prevState) => {
                        const newState = prevState.map((item) => {
                            if (item.navigation === "Invoice") {
                                return ({ ...item, counter: 1 })
                            }else {
                                return (item)
                            }
        
        
        
                        })
        
                        return newState
                    })




                 }
                

               
 
                
              
                

              
                
          

            })

       

       

    }

    const createZohoInvoice = (props) => {

        if (contact === "" || contact === undefined) {

            Toaster.create({ position: Position.TOP }).show({
                message: "Customer not found",
                intent: Intent.PRIMARY
            });

        } else if (complianceName === "" || complianceName ===undefined) {

            Toaster.create({ position: Position.TOP }).show({
                message: "Item not found",
                intent: Intent.PRIMARY
            });

        } else {

            const customerName = contact
            const itemName = complianceName
            const invoiceId = taskKey


            dispatch(postZohoInvoice({
                userId: userId,
                userEmail: userEmail,
                zohoToken: zohoTokens,
                customerName: customerName,
                itemName: itemName,
                invoiceId: invoiceId,
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postZohoInvoice", data.payload);
                const codeToMessage = {
                    1001: "Invoice already created",
                    4091: "Item not Found Zoho invoice",
                    3008: "Customer not Found Zoho invoice",
                    57:"Zoho invoice not authenticated"
                };

                const codeToIntent = {
                    1001: Intent.PRIMARY,
                    4091: Intent.PRIMARY,
                    3008: Intent.PRIMARY,
                    9017: Intent.PRIMARY,
                    57:Intent.PRIMARY
                };

                const message = data?.payload?.createInvoiceResponse?.data?.message;
                const code = data?.payload?.createInvoiceResponse?.data?.code;
                const invoiceResponse = data?.payload?.createInvoiceResponse?.data?.invoice?.invoice_id;

                if (code in codeToMessage) {
                    Toaster.create({ position: Position.TOP }).show({
                        message: code === 9017 ? "Zoho: " + message : codeToMessage[code],
                        intent: codeToIntent[code],
                    });
                }

                if (invoiceResponse !== undefined && invoiceResponse !== null) {
                    Toaster.create({ position: Position.TOP }).show({
                        message: "Invoice created",
                        intent: Intent.SUCCESS,
                    });
                    getZohoInvoiceFromApi(taskKey);
                }

  
            })

        }

 
    }

    const markasVerifiedInDb = (props) => {

       
        dispatch(markasVerified({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            completedStatus: "true",
        })).then((data) => {


            const workflowId = workflow?.filter((wData) => wData.workflow_name === "Verified")[0]?.workflow_id
            updateWorkflowIdToDb(workflowId)
            Toaster.create({ position: Position.TOP }).show({
                message: "Mark as Verified.",
                intent: Intent.PRIMARY
            });


        })


    }

    //--------- google calandar ---------------
 
    const insertConferenceToGoogleCalander = async (actcityName,activityDate,activityTime) => {

        console.log("googleMeetToken insertConferenceToGoogleCalander",googleMeetToken)
        if(googleMeetToken===""){

            return ""

        }else{

        const calendarId =  'primary'
        const assigneEmail = spaceCollaborators?.filter((sData)=> sData.user_id===selectedAssigne.user_id)[0]?.user_email
        const requestId = ""+generateMeetingID(8)+""
        const token = googleMeetToken;

        var attendees 
        if(assigneEmail!==null&&assigneEmail!==""&&assigneEmail!==undefined){

            attendees = [{ 'email': localStorage.getItem("userEmail") }, { 'email': assigneEmail }]
        }else{
            attendees = [{ 'email': localStorage.getItem("userEmail") }]

        }


        const eventData = {
            summary: actcityName,
            description: actcityName,
            start: { dateTime: new Date(activityDate) },
            end: { dateTime: new Date(activityDate) },
            attendees: attendees,
            conferenceData: {
                createRequest: {
                    requestId: requestId,
                    conferenceSolutionKey: { type: "hangoutsMeet" },
                },
            },
        };

        console.log("eventData",eventData)

        const queryParams = {
            'calendarId': 'primary',
            "conferenceDataVersion": 1,
            'resource': eventData,
            "sendUpdates": "all",
            "sendNotifications":true

        };
        const queryString = new URLSearchParams(queryParams).toString();
        const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?${queryString}`;
        const meetResponse = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(eventData),
        }).then((response) => response.json())
          
        return meetResponse.hangoutLink


        }
        


 
    }
      

    const insertEventToGoogleCalander = (props) => {

        if(dueDate===""){

            Toaster.create({ position: Position.TOP }).show({
                message: "Select due date",
                intent: Intent.PRIMARY
            });


        }else{


                dispatch(getGoogleCalanderAuth({
                    userId:userId,
                    userEmail:userEmail,
                    projectId:localStorage.getItem("projectId")
                })).then(async (data)=>{


                    console.log("getGoogleAuth",data.payload)
                    const code = data?.payload?.checkApp?.code
                    const googleTokenData = data?.payload?.data 
                    const message = data?.payload?.data?.message
       
                    console.log("code",code)
                    console.log("message",message)
       
                    if(code==404){
       
                       Toaster.create({ position: Position.TOP }).show({
                           message: 
                           <Box sx={{minHeight:'100%',marginTop:'4px'}}>
                           <Text sx={{
                               fontFamily: 'Segoe UI',
                               fontStyle: 'normal',
                               fontSize: '14px',
                               fontWeight: '400',
                               lineHeight: '14px',
                               
                           }}>{"App not installed"}</Text>
                           </Box>
                           , intent: Intent.PRIMARY, action: {
       
                               text: <Text sx={{
                                   fontFamily: 'Segoe UI',
                                   fontStyle: 'normal',
                                   fontSize: '14px',
                                   fontWeight: '400',
                                   lineHeight: '14px',
       
                               }}>Open Apps.</Text>,
                               onClick: () => navigate('/apps')
                           }
                       });
       
       
                    }else if(code==200){
       
                          if(message==="Already authenticated"){
       
                              const refreshToken = googleTokenData?.refresh_token;
       
                              const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                              const clientSecret = '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'
                              const tokenUrl = 'https://oauth2.googleapis.com/token';
                              const payload = {
                                  grant_type: 'refresh_token',
                                  client_id: clientId,
                                  client_secret: clientSecret,
                                  refresh_token: refreshToken,
                              };
                              const newTokenResult =await fetch(tokenUrl, {
                                  method: 'POST',
                                  headers: {
                                      'Content-Type': 'application/x-www-form-urlencoded',
                                  },
                                  body: new URLSearchParams(payload).toString(),
                              }).then(response => response.json())
                              const newAccessToken = newTokenResult?.access_token
                              console.log("newAccessToken",newAccessToken)
                      
                              if(newAccessToken!==null&&newAccessToken!==""&&newAccessToken!==undefined){
                       
                                  const calendarId = 'primary' 
                                  const token = newAccessToken;
                                  const assigneEmail = spaceCollaborators?.filter((sData) => sData.user_id === selectedAssigne.user_id)[0]?.user_email
                                  

                                
                                  var attendees
                                  if (assigneEmail !== null && assigneEmail !== "" && assigneEmail !== undefined) {

                                      attendees = [{ 'email': localStorage.getItem("userEmail") }, { 'email': assigneEmail }]
                                  } else {
                                      attendees = [{ 'email': localStorage.getItem("userEmail") }]

                                  }
                                  const eventData = {
                                    'summary': taskNameInput.value,
                                    'location': '',
                                    'description': taskDescriptionInput.value,
                                    'start': {

                                        'dateTime': new Date(dueDate),
                                        'timeZone': 'UTC'
                                    },
                                    'end': {

                                        'dateTime': new Date(dueDate),
                                        'timeZone': 'UTC'
                                    },
                                    'recurrence': [
                                        // 'RRULE:FREQ=DAILY;COUNT=2'
                                    ],
                                    'attendees': attendees,
                                    'reminders': {
                                        'useDefault': false,
                                        'overrides': [
                                            { 'method': 'email', 'minutes': 24 * 60 },
                                            { 'method': 'popup', 'minutes': 10 }
                                        ]
                                    }
                                  };

                                  console.log("eventData", eventData)

                                  const queryParams = {
                                      'calendarId': 'primary',
                                      "conferenceDataVersion": 1,
                                      'resource': eventData,
                                      "sendUpdates": "all",
                                    

                                  };
                                  const queryString = new URLSearchParams(queryParams).toString();
                                  const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?${queryString}`;
                                  const meetResponse = await fetch(apiUrl, {
                                      method: 'POST',
                                      headers: {
                                          'Authorization': `Bearer ${token}`,
                                          'Content-Type': 'application/json',
                                      },
                                      body: JSON.stringify(eventData),
                                  }).then((response) => response.json())
                                  console.log("calander response",meetResponse)
                                  const insertLink = meetResponse?.htmlLink
                                  if(insertLink!==null&&insertLink!==""&&insertLink!==undefined){

                                      console.log("calander response event", insertLink)
                                      Toaster.create({ position: Position.TOP }).show({
                                          message: "Task added to calander.",
                                          intent: Intent.PRIMARY
                                      });
                                      window.open(insertLink)


                                  }
                                   


                               }else{
                                       
                               }
                             
                          
                          
                           }else{
       
                              Toaster.create({ position: Position.TOP }).show({
                                  message:
                                      <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                          <Text sx={{
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontSize: '14px',
                                              fontWeight: '400',
                                              lineHeight: '14px',
       
                                          }}>{"App not authenticated"}</Text>
                                      </Box>
                                  , intent: Intent.PRIMARY, action: {
       
                                      text: <Text sx={{
                                          fontFamily: 'Segoe UI',
                                          fontStyle: 'normal',
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '14px',
       
                                      }}>Open Apps.</Text>,
                                      onClick: () => navigate('/apps')
                                  }
                              });
       
                          }
       
       
       
                    }



                })
               

             
            //  const assigneEmail = spaceCollaborators?.filter((sData)=> sData.user_id===selectedAssigne.user_id)[0]?.user_email


            // var event = {
            //     'summary': taskNameInput.value,
            //     'location': '',
            //     'description': taskDescriptionInput.value,
            //     'start': {
                
            //        'dateTime': new Date(dueDate),
            //        'timeZone': 'UTC'
            //     },
            //     'end': {
                  
            //       'dateTime': new Date(dueDate),
            //       'timeZone': 'UTC'
            //     },
            //     'recurrence': [
            //       // 'RRULE:FREQ=DAILY;COUNT=2'
            //     ],
            //     'attendees': [
            //       {'email': userEmail},
            //       {'email': assigneEmail!==undefined?assigneEmail:''},
                  
            //     ],
            //     'reminders': {
            //       'useDefault': false,
            //       'overrides': [
            //         {'method': 'email', 'minutes': 24 * 60},
            //         {'method': 'popup', 'minutes': 10}
            //       ]
            //     }
                
            //   };
    
            //   var request = gapi.client.calendar.events.insert({
            //     'calendarId': 'primary',
            //     'resource': event,
            //     "sendUpdates":"all"
            //   });
              
            //   request.execute(function(event) {
    
    
            //       console.log("calander response event",event)
            //       Toaster.create({ position: Position.TOP }).show({
            //           message: "Task added to calander.",
            //           intent: Intent.PRIMARY
            //       });
            //       window.open(event.htmlLink)
                   
                  
            //   });


        }


        


    }  

    

    //------------------------------------------------------


    const updateTaskNameAndDescription = (taskName, taskDescription) => {


        if (taskNameRef.current.innerHTML === "") {

        } else {


            const newTaskInputValue = taskNameRef.current.innerHTML.replace(/<\/?[^>]+(>|$)|&nbsp;/gi, '')
            const newTaskDescription = taskDescriptionRef.current.innerHTML.replace(/<\/?[^>]+(>|$)|&nbsp;/gi, '')

            settaskNameInput((prevState) => {
                return ({ ...prevState, value: newTaskInputValue })
            })
            settaskDescriptionInput((prevState) => {
                return ({ ...prevState, value: newTaskDescription })
            })


            dispatch(updateTaskName({
                userId: userId,
                userEmail: userEmail,
                taskId: taskData.expansionTaskId,
                taskName: taskNameRef.current.innerHTML
            })).then((data) => {

                dispatch(reloadTaskTrigger({
                    taskTrigger:true
                }))

                Toaster.create({ position: Position.TOP }).show({
                    message: "Task data successfully updated.",
                    intent: Intent.PRIMARY
                });

            })

            const newDescription = {
                "blocks": [
                    {
                        "key": "" + Math.floor(Math.random() * 900000) + 100000 + "",
                        "data": {},
                        "text": taskDescriptionRef.current.innerHTML,
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            }


            dispatch(updateTaskDescription({
                userId: userId,
                userEmail: userEmail,
                taskId: taskData.expansionTaskId,
                taskDescription: newDescription
            })).then((data) => {


            })

            setisDataEditable(false)

        }


    }

    const updateWorkflowIdToDb = (props) => {

        dispatch(updateWorkflowId({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            workflowId: props
        })).then((data) => {


            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))

        })

    }

    const updateTaskPriorityToDb = (props) => {

          
         dispatch(updateTaskPriority({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            priority: "" + props + ""
        })).then((data) => {

            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))


        })

    }

    const handlePickComputerFile = (props) => {

        const projectId = localStorage.getItem("projectId")
        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'workspaceprojects/' + projectId + '/' + props.name,
            'ContentType': props.type
        };


        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {



            } else {



                const updateData = {
                    attachment_file_name: props.name,
                    attachment_size: '' + props.size + '',
                    attachment_url: data.Location,
                    creator_id: userId,
                    attachment_timestamp: "" + new Date() + ""
                }


                updateTaskAttachmentsIndb(updateData)


            };



        })


    }

    const updateTaskAttachmentsIndb = (props) => {


        dispatch(updateTaskAttachments({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            taskAttachments: props
        })).then((data) => {

            setattachmentArray(data.payload.data[0].attachments)
            setitems((prevState) => {
                const newState = prevState.map((row, index) => {
                    if (row.navigation === "Attachments") {

                        return ({ ...row, counter: data.payload.data[0].attachments.length })
                    } else {
                        return (row)
                    }
                })
                return newState
            })

            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))


        })

    }


    const removeTaskAttachments = (props) => {



        dispatch(updateTaskAttachments({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            taskAttachments: '',
            deleteTaskAttachmentId: props.attachment_id
        })).then((data) => {


            setattachmentArray(data.payload.data[0].attachments)
            setitems((prevState) => {
                const newState = prevState.map((row, index) => {
                    if (row.navigation === "Attachments") {

                        return ({ ...row, counter: data.payload.data[0].attachments.length })
                    } else {
                        return (row)
                    }
                })
                return newState
            })

            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))



        })


    }

    const updateTaskAssigneToDb = (props) => {

        dispatch(updateTaskAssigne({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            assigneId: "" + props + ""
        })).then((data) => {

            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))

        })

    }

    const updateTaskDueDateToDb = (props) => {

        dispatch(updateTaskDueDate({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            dueDate: "" + props + ""
        })).then((data) => {

            dispatch(reloadTaskTrigger({
                taskTrigger: true
            }))

        })

    }

    const postSubTaskPrimaryToDb = (props) => {

         
           const projectId = localStorage.getItem("projectId")
           const taskPriority = "Normal"
           const taskInput = subtaskInputPrimary.value

            setshowSubtaskInputPrimary(false)

            dispatch(postSubtaskPrimary({
                userId: userId,
                userEmail: userEmail,
                projectId: projectId,
                taskId:taskData.expansionTaskId,
                taskName: taskInput,
                taskPriority: taskPriority,
                assigneId:selectedSubtaskAssigne,
                contact:[],
                dueDate:"",
                description:'',
                complianceId:'' 
            })).then((data)=>{

                console.log("postSubtaskPrimary",data.payload)

                if(data?.payload?.data?.length > 0){

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Successfully created subtask",
                        intent: Intent.SUCCESS
                    });

                    
                    setsubtaskInputPrimary((prevState)=>{
                         return({...prevState,value:'',validationStatus:''})
                    })
                    setselectedSubtaskAssigne("")

                    getSubtaskPrimaryFromDb(taskData.expansionTaskId)

                }

                
                   
            }) 
 



    }

    const getSubtaskPrimaryFromDb = (props) => {

        dispatch(getSubtaskPrimary({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            taskId: props
        })).then((data) => {

            const newSubtaskPrimaryArray = [...data.payload.data].sort((a, b) => new Date(b.created_timestamp) - new Date(a.created_timestamp))
            console.log("getSubtaskPrimary", newSubtaskPrimaryArray)


            var totalCount = newSubtaskPrimaryArray.length
            var completedCount = newSubtaskPrimaryArray.length > 0 ? newSubtaskPrimaryArray?.filter((wData) => wData?.workflow_id === wData?.workflow?.filter(wiData => wiData?.workflow_name === "Completed")[0]?.workflow_id).length
                : 0

            if (!isNaN(completedCount) && !isNaN(totalCount) && totalCount !== 0) {
                const subtaskPercentage = (completedCount / totalCount) * 100;
                setsubtaskPrimaryPercentage(subtaskPercentage);
            } else {
                setsubtaskPrimaryPercentage(0)
            }
            setsubtaskPrimaryArray(newSubtaskPrimaryArray)


            setitems((prevState) => {
                const newState = prevState.map((item) => {
                   
                    if (item.navigation === "Subtask") {
                        return ({ ...item, counter: totalCount })
                    }else {
                        return (item)
                    }

                })

                return newState
            })


        })

    }


    const addSubtask = (props) => {

        if (subtaskInput.value === "") {
            setsubtaskInput((prevState) => {
                return ({ ...prevState, validationStatus: 'error' })
            })
        } else {

            setcompliaceSubtasks((prevState) => {
                const newState = [...prevState, { task_id: "" + generateUUID("") + "", task_name: subtaskInput.value, isChecked: false }]

                const totalTaskCount = newState.length
                const totalCheckedTask = newState.filter((cData) => cData.isChecked === true).length
                const totalPercentage = (totalCheckedTask / totalTaskCount) * 100
                setsubtaskPercent(parseFloat(totalPercentage).toFixed(0))
                updateSubtaskInDb(newState)
                return newState
            })
            setshowSubtaskInput(false)
            setsubtaskInput((prevState) => {
                return ({ ...prevState, validationStatus: '', value: '' })
            })

        }
    }

    const updateSubtaskInDb = (props) => {

      
        dispatch(updateTaskSubtask({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            subtaskData: props,
            updateField: 'Subtask'
        })).then((data) => {
            setcompliaceSubtasks(data.payload.subtaskData)

            dispatch(reloadTaskTrigger({
                taskTrigger:true
            }))


        })



    }

    const deleteTaskCommentsToDb = (props) => {
  

        dispatch(deleteTaskComments({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            deleteCommentId: props,
        })).then((data) => {
            const tComments = data.payload.taskComments
            setcommentArray(tComments)
            if (commentEditId === "") {

                setitems((prevState) => {
                    const newState = prevState.map((item) => {
                        if (item.navigation === "Comment") {
                            return ({ ...item, counter: tComments.length })
                        } else {
                            return (item)
                        }



                    })

                    return newState
                })

                dispatch(reloadTaskTrigger({
                    taskTrigger: true
                }))


            }

        })

    }

    const postCommentToDb = (props) => {

           if(commentInput.value===""){

                  setcommentInput((prevState)=>{
                       return({...prevState,validationStatus:'error'})
                  })

           }else{

                 if(commentEditId===""){


                     const commentData = {
                         creator_id: userId,
                         comment_message: commentInput.value,
                         comment_timestamp: "" + new Date() + ""
                     }

                     dispatch(addTaskComments({
                         userId: userId,
                         userEmail: userEmail,
                         taskId: taskData.expansionTaskId,
                         taskComment: commentData
                     })).then((data) => {

                         Toaster.create({ position: Position.TOP }).show({
                             message: "Comment posted",
                             intent: Intent.PRIMARY
                         });
                         const  tComments = data.payload.taskComments
                         setcommentArray(tComments)
                         setcommentInput((prevState) => {
                             return ({ ...prevState, value: '', validationStatus: '' })
                         })
                         setitems((prevState) => {
                            const newState = prevState.map((item) => {
                                if (item.navigation === "Comment") {
                                    return ({ ...item, counter: tComments.length })
                                }else {
                                    return (item)
                                }
            
            
            
                            })
            
                            return newState
                        })


                        dispatch(reloadTaskTrigger({
                            taskTrigger:true
                        }))



                     })



                 }else{


                   //  deleteTaskCommentsToDb(commentEditId)

                     dispatch(deleteTaskComments({
                        userId: userId,
                        userEmail: userEmail,
                        taskId: taskData.expansionTaskId,
                        deleteCommentId: commentEditId,
                    })).then((data) => {

                        const commentData = {
                            creator_id: userId,
                            comment_message: commentInput.value,
                            comment_timestamp: "" + new Date() + ""
                        }
   
                        dispatch(addTaskComments({
                            userId: userId,
                            userEmail: userEmail,
                            taskId: taskData.expansionTaskId,
                            taskComment: commentData
                        })).then((data) => {
   
                            const  tComments = data.payload.taskComments
                            setcommentArray(tComments)
                            setcommentInput((prevState) => {
                               return ({ ...prevState, value: '', validationStatus: '' })
                            })
                            setcommentEditId("")
                            setitems((prevState) => {
                               const newState = prevState.map((item) => {
                                   if (item.navigation === "Comment") {
                                       return ({ ...item, counter: tComments.length })
                                   }else {
                                       return (item)
                                   }
               
               
               
                               })
               
                               return newState
                           })
   
   
   
                        })
                         
                         
                    })




                     




                 }

              
                

           }

    }

    const deleteTaskActivityToDb = (props) => {


       dispatch(deleteTaskActivity({

            userId: userId,
            userEmail: userEmail,
            taskId: taskData.expansionTaskId,
            deleteActivityId: props,

        })).then((data) => {

               setactivityArray(data.payload.projectActivity)
               
        })

    }

    const clearActivityInput = (props) => {
 
        setactivityInput((prevState) => {

            const newState = {
                ...prevState, activityId: '',
                value: '',
                placeholder: 'Meeting',
                validationStatus: ''
            }
            return newState
        })

        setselectedActivityDate("")
        setselectedActivityTime("")
        setgoogleMeetToken([])
  
    }

    const handlePostActivity =async (props) => {


        if (activityInput.value === "") {

            setactivityInput((prevState) => {

                const newState = { ...prevState, validationStatus: 'error' }
                return newState
            })
        }else{


             if (activityInput.activityId === "" && activityInput.activityId.length <=0) {

                  if (selectedVideoCallType === "Google Meet" && selectedActivityDate!==""&&selectedActivityTime!=="") {


                           
                          
                         const meetingLink = await insertConferenceToGoogleCalander(activityInput.value,selectedActivityDate,selectedActivityTime) 
                         console.log("meetingLink",meetingLink) 
                    
                         const activityData = {
                            creator_id: userId,
                            activity_type: activityInput.placeholder,
                            activity_name: activityInput.value,
                            activity_date: "" + new Date(selectedActivityDate) + "",
                            activity_time: "" + selectedActivityTime + "",
                            videocall_type: selectedVideoCallType,
                            videocall_link: meetingLink,
                            activity_description: '',
                            activity_timestamp: "" + new Date() + ""
                        }
  
  
                        dispatch(updateTaskActivity({
  
                            userId: userId,
                            userEmail: userEmail,
                            taskId: taskData.expansionTaskId,
                            taskActivity: activityData,
  
                        })).then((data) => {
  
                            Toaster.create({ position: Position.TOP }).show({
                                message: "Activity recorded.",
                                intent: Intent.PRIMARY
                            });
                            clearActivityInput("")
                            setactivityArray(data.payload.projectActivity)
  
                        })

                     
 



                  } else {
 
                          

                      const activityData = {
                          creator_id: userId,
                          activity_type: activityInput.placeholder,
                          activity_name: activityInput.value,
                          activity_date: "" + new Date(selectedActivityDate) + "",
                          activity_time: "" + selectedActivityTime + "",
                          videocall_type: 'Google Meet',
                          videocall_link: '',
                          activity_description: '',
                          activity_timestamp: "" + new Date() + ""
                      }


                      dispatch(updateTaskActivity({

                          userId: userId,
                          userEmail: userEmail,
                          taskId: taskData.expansionTaskId,
                          taskActivity: activityData,

                      })).then((data) => {

                          Toaster.create({ position: Position.TOP }).show({
                              message: "Activity recorded.",
                              intent: Intent.PRIMARY
                          });
                          clearActivityInput("")
                          setactivityArray(data.payload.projectActivity)

                      })



                  }
                   

                    

 

            }else{

                   
             

                 dispatch(deleteTaskActivity({

                     userId: userId,
                     userEmail: userEmail,
                     taskId: taskData.expansionTaskId,
                     deleteActivityId: activityInput.activityId,

                 })).then((data) => {

                    const activityData = {
                        creator_id: userId,
                        activity_type: activityInput.placeholder,
                        activity_name: activityInput.value,
                        activity_date: "" + new Date(selectedActivityDate) + "",
                        activity_time: "" + selectedActivityTime + "",
                        videocall_type: 'Google Meet',
                        videocall_link: '',
                        activity_description: '',
                        activity_timestamp: "" + new Date() + ""
                    }
   
   
                    dispatch(updateTaskActivity({
   
                        userId: userId,
                        userEmail: userEmail,
                        taskId: taskData.expansionTaskId,
                        taskActivity: activityData,
   
                    })).then((data) => {
   
                        clearActivityInput("")
                        setactivityArray(data.payload.projectActivity)
                       
   
                    })

                     

                 })
 


            }



        }



    }


    const onDragEnd = (result) => {

        console.log(result)


        if (!result.destination) return;
        const { source, destination } = result;


        if (source.droppableId !== destination.droppableId) {

        } else {


            const newItems = Array.from(compliaceSubtasks);
            const [removed] = newItems.splice(result.source.index, 1);
            newItems.splice(result.destination.index, 0, removed);
            setcompliaceSubtasks(newItems);
            updateSubtaskInDb(newItems)

        }

    }


    const StaticHeader = (props) => {

        return (
            <Box sx={{
                position: 'fixed',
                width: '1090px',
                minHeight: '63px',
                maxHeight: '63px',
                height: '63px',
                display: 'flex',
                flexDirection: 'row'
            }}>


                <Box sx={{
                    display: 'flex',
                    position: 'relative',
                    margin: '21px 0px 0px 37px',

                }}

                    onMouseEnter={() => {
                        setshowworflowDropDownIcon(true)
                    }}
                    onMouseLeave={() => {
                        setshowworflowDropDownIcon(false)
                    }}
                >

                    <ActionMenu>


                        <ActionMenu.Anchor>
                            <Box

                                sx={{

                                    display: 'flex',
                                    position: 'relative',
                                    minHeight: '24px',
                                    maxHeight: '24px',
                                }}

                            >
                                <Box

                                    sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px',
                                        height: '24px',
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        minWidth: '66px',
                                        width: 'fit-content',
                                        background: workflow[workflow.length - 1]?.workflow_id === selectedWorkflow?.workflow_id ? '#2DA44E' : '#562276',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '3px',
                                        color: '#ffffff',
                                        cursor: 'pointer'
                                    }}



                                >
                                    <Box sx={{

                                        position: 'absolute',
                                        left: '8px'
                                    }}>
                                        <IssueOpenedIcon />
                                    </Box>
                                    <Box sx={{
                                        marginLeft: '28px',
                                        marginRight: '8px'
                                    }}>

                                        {selectedWorkflow.workflow_name}
                                    </Box>

                                </Box>
                                <IconButton
                                     sx={{
                                        display: showworflowDropDownIcon === true ? 'flex' : 'none',
                                        border: 'none',
                                        boxShadow: 'none',
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px',
                                        height: '24px',
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        background: 'transparent'
                                    }}
                                    icon={() => <Box sx={{ display: 'flex' }}><TriangleDownIcon /></Box>}
                                />
                            </Box>

                        </ActionMenu.Anchor>

                        <ActionMenu.Overlay>
                            <ActionList sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '5px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',

                            }}>
                                {workflow.map((row, index) => {
                                    return (
                                        <ActionList.Item onSelect={(e) => {
                                            setSelectedWorkFlow({
                                                workflow_id: row.workflow_id,
                                                workflow_name: row.workflow_name
                                            })
                                            updateWorkflowIdToDb(row.workflow_id)



                                        }}>
                                            {row.workflow_name}
                                        </ActionList.Item>
                                    )
                                })}

                            </ActionList>
                        </ActionMenu.Overlay>

                    </ActionMenu>
                </Box>


                <Box sx={{
                    position: 'absolute',
                    right: '47px',
                    top: '17px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px'
                }}>

                    {isDataEditable === false ?
                        <IconButton
                            
                            sx={{
                                border: 'none',
                                color: '#000000',
                                boxShadow: 'none',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#ffffff'
                            }}
                            icon={() => <Tooltip aria-label="Edit" direction="w"><Pencil2Icon  style={{ display: 'flex' }} /></Tooltip>}
                            onClick={() => {
                                setisDataEditable(true)
                            }}
                        />
                        :
                        <IconButton
                            sx={{
                                border: 'none',
                                color: '#000000',
                                boxShadow: 'none',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#ffffff'
                            }}
                            icon={() => <Tooltip aria-label="Save" direction="w"><CheckIcon style={{ display: 'flex' }} /></Tooltip>}
                            onClick={() => {
                                setisDataEditable(false)
                            }}
                        />

                    }




                    <IconButton
                        sx={{
                            border: 'none',
                            color: '#000000',
                            boxShadow: 'none',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#ffffff'
                        }}
                        icon={() => <Tooltip aria-label="Copy Task" direction="w"><CopyIcon style={{ display: 'flex' }} /></Tooltip>}

                    />


                    <ActionMenu>
                        <ActionMenu.Anchor>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    boxShadow: 'none',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#ffffff'
                                }}
                                icon={() => <Tooltip aria-label="History" direction="w"><CountdownTimerIcon style={{ display: 'flex' }} /></Tooltip>}

                            />
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay
                            sx={{
                                border: '1px solid #D0D7DE',
                                borderRadius: '6px',


                            }}
                        >

                            <Box
                                sx={{
                                    width: '328px',
                                    minWidth: '328px',
                                    maxWidth: '328px',
                                    height: 'auto',
                                    minHeight: 'auto',
                                    maxHeight: '534px',
                                    background: '#ffffff',
                                    boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                                    borderRadius: '6px',
                                    zIndex: '10',
                                    display: "flex",
                                    flexDirection: 'column',

                                }}
                            >
                                <Box
                                    borderColor="border.default"
                                    borderBottomWidth={1}
                                    borderBottomStyle="solid"
                                    sx={{
                                        height: '38px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px'
                                    }}
                                >
                                    <Text
                                        sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 13px',
                                            fontFamily: 'Segoe UI',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '18px',
                                            color: '#000000'
                                        }}
                                    >
                                        {/* Activity */}
                                        History
                                    </Text>


                                </Box>

                                <Box
                                    sx={{
                                        height: 'auto',
                                        minHeight: 'auto',
                                        maxHeight: '496px',
                                        width: '328px',
                                        position: 'relative',
                                        overflowY: 'auto'
                                    }}
                                >

                                    <Timeline
                                        sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 10px'
                                        }}
                                    >

                                        {historyArray
                                            ?.filter((hData) => hData.history.history_type === "Task")
                                            ?.filter((hData) => hData.history.task_id === taskData.expansionTaskId)
                                            ?.slice(0, 4)
                                            ?.map((row, index) => {

                                                return (

                                                    <Timeline.Item>
                                                        <Timeline.Badge sx={{
                                                            background: 'white',
                                                            position: 'relative',
                                                            margin: '0px 0px 0px -11px',
                                                            height: '20px',
                                                            width: '20px',
                                                        }}>

                                                            {row?.user_image_url === "" || row?.user_image_url == null ?

                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    position: 'relative',
                                                                    margin: '0px auto 0px auto',

                                                                }}>
                                                                    <FeedPersonIcon size={25} fill="#586069" />
                                                                </Box>
                                                                :

                                                                <Avatar size={25} sx={{
                                                                    display: 'flex',
                                                                    position: 'relative',
                                                                    margin: '0px auto 0px auto',

                                                                }} src={row.user_image_url} />
                                                            }

                                                             
                                                        </Timeline.Badge>
                                                        <Timeline.Body
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'left',
                                                                position: 'relative',
                                                                margin: '0px 0px 0px 8px',
                                                                fontFamily: 'Segoe UI',
                                                            }}
                                                        >
                                                            <Tooltip
                                                                aria-label={row.user_first_name + row.user_last_name}
                                                                direction={index === 0 ? "s" : "n"}
                                                            >
                                                                <Link
                                                                    href="#"
                                                                    sx={{
                                                                        fontWeight: 'bold',
                                                                        color: 'fg.default',
                                                                        mr: 1,
                                                                        fontFamily: 'Segoe UI',
                                                                        fontSize: '12px'
                                                                    }}
                                                                    muted>
                                                                    {(row.user_first_name + row.user_last_name).substring(0, 8)}
                                                                </Link>
                                                            </Tooltip>


                                                           
                                                            <Link
                                                                href="#"
                                                                sx={{
                                                                    fontWeight: 'bold',
                                                                    color: 'fg.default',
                                                                    position: 'relative',
                                                                    margin: '0px 0px 0px 4px',
                                                                    fontFamily: 'Segoe UI',
                                                                    fontSize: '12px',

                                                                }}
                                                                muted
                                                                
                                                            >

                                                               

                                                                <Tooltip
                                                                    aria-label={row.history.history_data.substring(0, 150)}
                                                                    wrap={true}
                                                                    direction={index===0?"s":"n"}
                                                                 //   direction={index == (historyArray.length - 1) ? "n" : "s"}
                                                                    sx={{
                                                                        maxWidth: '150px',
                                                                        width: '150px',
                                                                        minWidth: '150px',
                                                                        minHeight: '20px',
                                                                       
                                                                        // position: 'absolute',
                                                                        // left: '0px',
                                                                        // right: '0px',
                                                                        // top: '0px',
                                                                        // margin: 'auto',
                                                                    }}

                                                                >
                                                                    {row.history.history_data.substring(0, 25)}
                                                                </Tooltip>


                                                                {/* <Box sx={{
                                                                    minWidth: '150px',
                                                                    minHeight: '20px',
                                                                    position: 'absolute',
                                                                    left: '0px',
                                                                    top: '4px',
                                                                    zIndex: '100'
                                                                }}>
                                                                    <Tooltip
                                                                        aria-label={row.history.history_data}
                                                                    //  wrap={true}
                                                                    //  direction="w"
                                                                    >
                                                                    </Tooltip>
                                                                </Box> */}

                                                            </Link>
                                                           


                                                            <Link
                                                                href="#"
                                                                color="fg.muted"
                                                                muted
                                                                sx={{
                                                                    fontFamily: 'Segoe UI',
                                                                    fontSize: '12px',
                                                                    position: 'relative',
                                                                    margin: '0px 0px 0px 9px',
                                                                }}
                                                            >

                                                                {

                                                                    "" + getNumberofDays(new Date(), new Date(row.history.history_timestamp)) == 0 ? "today" :

                                                                        getNumberofDays(new Date(), new Date(row.history.history_timestamp)) + " days ago"
                                                                        + ""
                                                                }
                                                            </Link>
                                                        </Timeline.Body>
                                                    </Timeline.Item>

                                                )


                                            })}





                                    </Timeline>


                                </Box>


                            </Box>



                        </ActionMenu.Overlay>



                    </ActionMenu>
                    {/* <IconButton
                        sx={{
                            border: 'none',
                            color: '#000000',
                            boxShadow: 'none',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#ffffff'
                        }}
                        icon={() => <CountdownTimerIcon style={{ display: 'flex' }} />}

                    /> */}


                    <ActionMenu>
                        <ActionMenu.Anchor>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    boxShadow: 'none',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#ffffff'
                                }}
                                icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}

                            />
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay>
                            <ActionList
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '2px',
                                    padding: '8px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    color: '##24292F'
                                }}
                            >

                                <ActionList.Item
                                    sx={{ display: isZohoInstalled ? 'flex' : 'none' }}
                                    onSelect={e => {
                                        createZohoInvoice("")
                                    }}
                                >Create Zoho Invoice</ActionList.Item>

                                
                                <ActionList.Item
                                    onSelect={e => {
                                        markasVerifiedInDb("")
                                    }}
                                >Mark as Verified</ActionList.Item>
                                <ActionList.Item
                                    onSelect={e => {
                                        duplicateTaskInDb("")
                                    }}
                                >Duplicate task</ActionList.Item>
                                <ActionList.Item
                                    onSelect={e => {
                                        insertEventToGoogleCalander("")
                                    }}
                                >Add to calandar</ActionList.Item>


                                <Box
                                    borderColor="border.default"
                                    borderBottomWidth={1}
                                    borderBottomStyle="solid"
                                    width={"100%"}
                                    height={"1px"}
                                >

                                </Box>
                                <ActionList.Item
                                    variant="danger"
                                    onSelect={() => handleDeleteTask("")}
                                >Delete task</ActionList.Item>
                            </ActionList>
                        </ActionMenu.Overlay>

                    </ActionMenu>


                    <IconButton
                        sx={{
                            border: 'none',
                            color: '#000000',
                            boxShadow: 'none',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#ffffff'
                        }}
                        icon={() => <Cross2Icon style={{ display: 'flex' }} />}
                        onClick={() => {
                            closeTaskExpansion("")
                        }}
                    />


                </Box>



            </Box>
        )
    }

    
    const NewSubtaskComp = (props) => {
   
          return (
              <Box
                  sx={{
                      position: 'relative',
                      margin: '0px 0px 0px 0px',
                      display: 'flex',
                      flexDirection: 'column',

                  }}
              >
                   
                      <Box
                      sx={{
                          position: 'relative',
                          margin: '14px 0px 0px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                      }}>

                      <LapTimerIcon style={{ position: 'relative', margin: '0px 0px 0px 0px' }} />
                      <Text sx={{
                          position: 'relative',
                          margin: '0px 0px 0px 16px',
                          display: 'flex',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '20px',
                          color: '#000000'
                      }}>
                          Subtask
                      </Text>

                      <ProgressBar sx={{
                          width: '520px',
                          minWidth: '520px',
                          height: '4px',
                          position: 'relative',
                          margin: '0px 0px 0px 16px'
                      }}
                          bg="#1F75CB" 
                          progress={parseInt(subtaskPrimaryPercentage)}
                      />
                      <Text sx={{
                          position: 'relative',
                          margin: '0px 0px 0px 13px',
                          display: 'flex',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontSize: '12px',
                          fontWeight: '600',
                          lineHeight: '20px',
                          color: '#000000'
                      }}>
                         
                          {parseInt(subtaskPrimaryPercentage)}%
                      </Text>

                      <IconButton
                          size="small"
                          variant="invisible"
                          sx={
                              {
                                  background: '#ffffff',
                                  color: '#000000',
                                  border: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  margin: '0px 0px 0px 16px',
                                 // width: '19px'
                              }
                          }
                          icon={() => <PlusIcon style={{ display: 'flex' }} />}
                          onClick={() => setshowSubtaskInputPrimary(true)}
                      />




                  </Box>

                  {showSubtaskInputPrimary === true ?

                      <Box sx={{
                          width: '665px',
                          minWidth: '665px',
                          maxWidth: '665px',
                          position: 'relative',
                          margin: '0px 0px 0px 0px',
                          display: 'flex',
                          flexDirection: 'column',
                      }}>


                          <Box sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              position: 'relative',
                              margin: '25px 0px 0px 0px'
                          }}>

                              <TextInput
                                  autoFocus={showSubtaskInputPrimary === true ? true : false}
                                  sx={{
                                      width: '643px',
                                      position: 'relative',
                                      minHeight: '40px',
                                      maxHeight: '40px',
                                      height: '40px',
                                      borderRadius: '3px',
                                      fontFamily: 'Segoe UI',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '20px'
                                  }}
                                  placeholder="What need to be done ?"
                                  validationStatus={subtaskInputPrimary.validationStatus}
                                  value={subtaskInputPrimary.value}
                                  onChange={(e) => {

                                        setsubtaskInputPrimary((prevState)=> {
                                                return{...prevState,value:e.target.value,validationStatus:''}
                                        })
                                  }}
                              />

                             

                              <AnchoredOverlay
                                  align="end"
                                  side="outside-bottom"
                                  renderAnchor={anchorProps => (


                                      selectedSubtaskAssigne === "" ?

                                          <Box
                                              {...anchorProps}
                                              sx={{
                                                  display: 'flex',
                                                  position: 'relative',
                                                  margin: '0px 0px 0px 6px',
                                                  cursor: 'pointer',
                                              }}
                                          >
                                              <FeedPersonIcon
                                                  size={40}
                                                  fill="#586069"

                                              />
                                          </Box> :


                                              spaceCollaborators?.filter((cData) => cData?.user_id === selectedSubtaskAssigne)[0]?.user_image_url === ""
                                              ||
                                              spaceCollaborators?.filter((cData) => cData?.user_id === selectedSubtaskAssigne)[0]?.user_image_url == null
                                              ?

                                              <Box
                                                  {...anchorProps}
                                                  sx={{
                                                      display: 'flex',
                                                      position: 'relative',
                                                      margin: '0px 0px 0px 6px',
                                                      cursor: 'pointer',
                                                  }}
                                              >
                                                  <FeedPersonIcon
                                                      size={40}
                                                      fill="#586069"

                                                  />
                                              </Box>

                                              :

                                              <Avatar
                                                  {...anchorProps}
                                                  size={40}
                                                  sx={{
                                                      display: 'flex',
                                                      position: 'relative',
                                                      margin: '0px 0px 0px 6px',
                                                      cursor: 'pointer',
                                                  }}
                                                  src={spaceCollaborators?.filter((cData) => cData?.user_id === selectedSubtaskAssigne)[0]?.user_image_url} />








                                  )}
                                  open={subtaskAssigneActionMenu}
                                  onOpen={openSubtaskAssigneActionMenuOverlay}
                                  onClose={closeSubtaskAssigneWorkflowActionMenuOverlay}

                              >

                                  <ActionList
                                      sx={{
                                          width: '280px',
                                      }}

                                  >
                                      <ActionList.Group
                                          sx={
                                              {
                                                  background: '#f6f8fa'
                                              }
                                          }
                                      >
                                          <ActionList.Item
                                              sx={{
                                                  paddingLeft: '0px',
                                                  paddingRight: '0px',
                                                  background: '#f6f8fa'
                                              }}
                                          >

                                              <TextInput
                                                  sx={{
                                                      width: '93%',
                                                      marginLeft: '8px',
                                                      marginRight: '8px',
                                                      borderRadius: '3px',
                                                      fontFamily: 'Segoe UI',


                                                  }}
                                                  placeholder='Search users'
                                                  onChange={(e) => {

                                                      setsearchsubtaskCollaborators(e.target.value)
                                                  }}
                                              />

                                          </ActionList.Item>
                                      </ActionList.Group>
                                      <ActionList.Divider />
                                      <ActionList.Group
                                          variant="subtle"
                                          sx={{
                                              maxHeight: '245px',
                                              overflowX: 'hidden',
                                              overflowY: 'auto'
                                          }}
                                      >

                                          {spaceCollaborators
                                              ?.filter((cData) => cData.user_first_name.toLocaleUpperCase().includes(searchsubtaskCollaborators.toUpperCase()))
                                              .map((row, index) => {

                                                  return (
                                                      <ActionList.Item
                                                          sx={{
                                                              paddingLeft: '5px',
                                                              paddingRight: '0px',
                                                              fontFamily: 'Segoe UI'
                                                          }}

                                                          onSelect={() => {
                                                              setselectedSubtaskAssigne(row.user_id)
                                                              closeSubtaskAssigneWorkflowActionMenuOverlay()
                                                          }
                                                          }
                                                      >
                                                          <ActionList.LeadingVisual>

                                                              {
                                                                  row.user_image_url === "" || row.user_image_url == null ?
                                                                      <FeedPersonIcon size={20} fill="#586069" /> :
                                                                      <Avatar src={row.user_image_url} />
                                                              }


                                                          </ActionList.LeadingVisual>
                                                          {row.user_first_name + " " + row.user_last_name}
                                                          <ActionList.Description
                                                              variant="block"
                                                          >
                                                              {row.user_email}
                                                          </ActionList.Description>
                                                      </ActionList.Item>

                                                  )

                                              })}


                                      </ActionList.Group>


                                  </ActionList>

                              </AnchoredOverlay>



                          </Box>


                          <Box sx={{
                              position: 'relative',
                              margin: '20px 0px 0px auto',
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '5px'
                          }}>
                              <Button

                                  sx={{
                                      height: '28px',
                                      width: '60px',
                                      border: 'none',
                                      borderRadius: '3px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      color: '#24292E',
                                      background: '#ffffff'
                                  }}
                                  onClick={() => 
                                    
                                      { setshowSubtaskInputPrimary(false)
                                        setsubtaskInputPrimary((prevState)=>{
                                            return({...prevState,value:'',validationStatus:''})
                                       })
                                      }
                                
                                  }
                              >
                                  Cancel
                              </Button>
                              <Button
                                  variant="outline"
                                  sx={{
                                      height: '28px',
                                      width: '60px',
                                      border: 'none',
                                      borderRadius: '3px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      color: '#ffffff',
                                      background: '#0969DA'
                                  }}
                                  onClick={() => {

                                      if (subtaskInputPrimary.value === "") {
                                          setsubtaskInputPrimary((prevState)=> {
                                              return{...prevState,validationStatus:'error'}    
                                          })
                                           
                                      } else {
                                            postSubTaskPrimaryToDb("")
                                      }
                                  }}
                              >
                                  Create
                              </Button>

                          </Box>


                      </Box>


                      : ""}


                  <Box


                      sx={{
                          width: '665px',
                          minWidth: '665px',
                          maxWidth: '665px',
                          position: 'relative',
                          margin:'9px 0px 0px 0px',  
                      }}>

                      <ActionList
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              gap: '13px'
                          }}
                      > 
                          {subtaskPrimaryArray.map((row, index) => {
                              return ( 

                                  <ActionList.Item
                                      onClick={() => {

                                        dispatch(openCloseTaskExpansion({
                                            openCloseExpansion: true,
                                            expansionTaskId: row.task_id
                                        }))


                                      } }
                                      key={"a" + index}
                                  >

                                      <ActionList.LeadingVisual
                                          sx={{

                                              position: 'relative',
                                              margin: '0px 0px 0px 7px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center'
                                          }}
                                      >
                                           
                                          <HamburgerMenuIcon style={{ display: 'flex' }} />




                                      </ActionList.LeadingVisual>

                                      <Text sx={{
                                          fontFamily: 'Segoe UI',
                                          fontStyle: 'normal',
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          lineHeight: '20px',
                                          color: '#202428',
                                          position: 'absolute',
                                          left:'15px',
                                          top: '0px',
                                          width:'434px',
                                          overflow: 'hidden',
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          textAlign: 'left',


                                      }}>
                                          {row.task_name}

                                      </Text>
                                      <ActionList.TrailingVisual
                                          sx={{
                                              position: 'relative',
                                              margin: '0px 0px 0px 0px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              
                                          }}
                                      >
                                          {row?.user_image_url === "" || row?.user_image_url == null ?

                                              <FeedPersonIcon size={28} fill="#586069" /> :

                                              <Avatar alt={row.user_first_name} size={28} src={row.user_image_url} />

                                          }


                                          <ActionMenu>
                                              <ActionMenu.Anchor>
                                                  <IconButton
                                                      sx={{
                                                          marginRight:'-19px',
                                                          border: 'none',
                                                          boxShadow: 'none',
                                                          background: 'transparent'
                                                      }}
                                                      icon={() => <DotsVerticalIcon />}

                                                  />
                                              </ActionMenu.Anchor>
                                              <ActionMenu.Overlay>
                                                  <ActionList sx={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      alignItems: 'center',
                                                      margin: '3px',
                                                      fontFamily: 'Segoe UI',
                                                      fontStyle: 'normal',
                                                      fontSize: '14px',
                                                      fontWeight: '400'
                                                  }}>
                                                      <ActionList.Item variant="danger"
                                                          onSelect={() => { 
                                                             handleDeleteSubtask(row.task_id)
                                                          }}
                                                      >
                                                          Delete Tasks
                                                      </ActionList.Item>
                                                  </ActionList>
                                              </ActionMenu.Overlay>
                                          </ActionMenu>

                                      </ActionList.TrailingVisual>
                                  </ActionList.Item>


                              )
                          })}

                      </ActionList>


                      </Box>

                      





              </Box>
          )

    }

    const DetailsComp = (props) => {
        return (
            <Box sx={{
                position: 'relative',
                margin: '0px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'column',

            }}>

                <Box

                    sx={{
                        position: 'relative',
                        margin: '14px 0px 0px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: 'fit-content',

                    }}>

                    <LapTimerIcon style={{ position: 'relative', margin: '0px 0px 0px 0px' }} />
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        display: 'flex',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Subtask
                    </Text>

                    <ProgressBar sx={{
                        width: '499px',
                        minWidth: '499px',
                        height: '4px',
                        position: 'relative',
                        margin: '0px 0px 0px 16px'
                    }}
                        bg="#1F75CB" progress={subtaskPercent}
                    />
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 27px',
                        display: 'flex',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '12px',
                        fontWeight: '600',
                        lineHeight: '20px',
                        color: '#000000',
                        minWidth: '28px',
                        maxWidth: '28px',
                        width: '28px'
                    }}>
                        {subtaskPercent === "NaN" ? "0" : subtaskPercent}%
                    </Text>

                    <IconButton
                        size="small"
                        variant="invisible"
                        sx={
                            {

                                background: '#ffffff',
                                color: '#000000',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '0px 0px 0px 8px',
                                width: '19px'
                            }
                        }

                        icon={() => <PlusIcon style={{ display: 'flex' }} />}
                        onClick={() => {

                            setshowSubtaskInput(true)
                        }}
                    />




                </Box>

                <Box sx={{
                    position: 'absolute',
                    width: '706px',
                    height: '0px',
                    left: '-40px',
                    top: '50px',
                    borderBottom: '1px solid #E1E6EA'
                }}>

                </Box>


                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: '15px 0px 0px 0px'
                }}>

                    <DragDropContext onDragEnd={onDragEnd}
                        onDragStart={() => {
                            setshowSubtaskInput(false)
                        }}
                    >
                        <Droppable droppableId="droppable" >
                            {(provided, snapshot) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',

                                    }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {compliaceSubtasks.map((row, index) => (
                                        <Draggable
                                            draggableId={row.task_id}
                                            index={index}
                                            key={row.task_id}
                                        >
                                            {(provided, snapshot) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}

                                                    sx={{
                                                        width: '706px',
                                                        minWidth: '706px',
                                                        maxWidth: '706px',
                                                        marginLeft: '-37px',
                                                        height: '50px',
                                                        minHeight: '50px',
                                                        maxHeight: '50px',
                                                        // borderBottom: '1px solid #E1E6EA',

                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        ':hover': {
                                                            background: 'rgba(234, 238, 242, 0.5)'
                                                        }

                                                    }}
                                                    onMouseEnter={() => {
                                                        setsubtaskHoverId(row.task_id)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setsubtaskHoverId("")
                                                    }}
                                                >

                                                    <Box
                                                        sx={{

                                                            position: 'absolute',
                                                            minHeight: '50px',
                                                            maxHeight: '50px',
                                                            height: '50px',
                                                            marginLeft: '37px',
                                                            width: '669px',
                                                            minWidth: '669px',
                                                            //height:'1px',
                                                            borderBottom: '1px solid #E1E6EA',
                                                        }}
                                                    >

                                                    </Box>

                                                    <IconButton


                                                        sx={{
                                                            display: subtaskHoverId === row.task_id ? 'flex' : 'none',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'absolute',
                                                            left: '-33px',
                                                            height: '30px',
                                                            width: '30px',
                                                            minWidth: '30px',
                                                            maxWidth: '30px',
                                                            minHeight: '30px',
                                                            maxHeight: '30px',
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            background: 'transparent'
                                                        }}
                                                        icon={() =>
                                                            <Box sx={{
                                                                display: 'flex'
                                                            }}>
                                                                <GrabberIcon />
                                                            </Box>
                                                        }

                                                    />





                                                    <Box sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 38px',
                                                        height: '16px',
                                                        width: '16px',
                                                        minHeight: '16px',
                                                        minWidth: '16px',
                                                        maxWidth: '16px',
                                                        maxHeight: '16px',
                                                        color: '#586069'
                                                    }}>
                                                        <ChecklistIcon />
                                                    </Box>
                                                    <Text sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 18px',
                                                        width: '529px',
                                                        minWidth: '529px',
                                                        maxWidth: '529px',
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        color: '#24292E',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}>
                                                        {row.task_name}
                                                    </Text>

                                                    <FormControl
                                                        sx={{
                                                            position: 'relative',
                                                            margin: '0px 40px 0px auto',
                                                          
                                                        }}
                                                        
                                                    >
                                                        <Checkbox
                                                            checked={row.isChecked}
                                                            sx={{
                                                                height:'16px',
                                                                width:'16px'
                                                            }}
                                                            onChange={(e) => { handleChangeSubtaskChecked(e.target.checked, row.task_id) }}
                                                        />

                                                    </FormControl>

                                                    <Box sx={{
                                                        display: subtaskHoverId === row.task_id ? "flex" : 'none',
                                                        position: 'absolute',
                                                        right: '-6px',
                                                        height: '30px',
                                                        width: '30px',
                                                        minWidth: '30px',
                                                        maxWidth: '30px',
                                                        minHeight: '30px',
                                                        maxHeight: '30px'
                                                    }}>

                                                        <ActionMenu>
                                                            <ActionMenu.Anchor>
                                                                <IconButton
                                                                    sx={{

                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        background: 'transparent'
                                                                    }}
                                                                    icon={() => <DotsVerticalIcon />}

                                                                />
                                                            </ActionMenu.Anchor>
                                                            <ActionMenu.Overlay>
                                                                <ActionList sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    margin: '3px',
                                                                    fontFamily: 'Segoe UI',
                                                                    fontStyle: 'normal',
                                                                    fontSize: '14px',
                                                                    fontWeight: '400'
                                                                }}>
                                                                    <ActionList.Item variant="danger"
                                                                        onSelect={() => {
                                                                            setcompliaceSubtasks((prevState) => {
                                                                                const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                                                const totalTaskCount = newState.length
                                                                                const totalCheckedTask = newState.filter((cData) => cData.isChecked === true).length
                                                                                const totalPercentage = (totalCheckedTask / totalTaskCount) * 100
                                                                                setsubtaskPercent(parseFloat(totalPercentage).toFixed(0))
                                                                                updateSubtaskInDb(newState)

                                                                                return newState
                                                                            })
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </ActionList.Item>
                                                                </ActionList>
                                                            </ActionMenu.Overlay>
                                                        </ActionMenu>


                                                    </Box>


                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                </Box>
                            )}
                        </Droppable>
                    </DragDropContext>




                    {showSubtaskInput === true ?

                        <Box sx={{
                            width: '669px',
                            minWidth: '669px',
                            maxWidth: '669px',
                            height: '50px',
                            minHeight: '50px',
                            maxHeight: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            margin: '8px 0px 0px 0px',

                        }}>
                            <TextInput
                                ref={subtaskInputRef}
                                sx={{
                                    width: '490px',
                                    minWidth: '490px',
                                    maxWidth: '490px',
                                    height: '40px',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                }}
                                placeholder="Enter your own subtask stages"
                                value={subtaskInput.value}
                                validationStatus={subtaskInput.validationStatus}
                                onChange={(e) => {
                                    setsubtaskInput((prevState) => {
                                        return ({ ...prevState, value: e.target.value, validationStatus: '' })
                                    })
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        addSubtask("")
                                    }
                                }}
                            />
                            <Button
                                variant="outline" sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 15px',
                                    background: '#0965CE',
                                    color: '#ffffff',
                                    width: '66px',
                                    height: '40px',
                                    minWidth: '66px',
                                    maxWidth: '66px',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: '600',

                                }}
                                onClick={() => {
                                    addSubtask("")
                                }}
                            >
                                Add
                            </Button>

                            <Button
                                variant="invisible" sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 10px',
                                    width: '87px',
                                    height: '40px',
                                    minWidth: '87px',
                                    maxWidth: '87px',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: '600',

                                }}
                                onClick={() => {
                                    setshowSubtaskInput(false)
                                }}
                            >
                                Discard
                            </Button>
                        </Box>
                        : ''}




                </Box>




            </Box>
        )
    }

    const AttachmentComp = (props) => {

        return (
            <Box sx={{
                position: 'relative',
                margin: '0px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'column',

            }}>

                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '0px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {attachmentArray
                        .sort((a, b) => new Date(b.attachment_timestamp) - new Date(a.attachment_timestamp))
                        .map((row, index) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '56px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        //  cursor: 'pointer',
                                        ':hover': {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        }
                                    }}

                                    onMouseEnter={() => {
                                        setattachmentHoverId(row.attachment_id)
                                    }}
                                    onMouseLeave={() => {
                                        setattachmentHoverId("")
                                    }}

                                >

                                    <Box sx={{
                                        minWidth: '38px',
                                        maxWidth: '38px',
                                        width: '38px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        {
                                            (
                                                row.attachment_file_name?.endsWith('.png') ||
                                                row.attachment_file_name?.endsWith('.jpg') ||
                                                row.attachment_file_name?.endsWith('.jpeg') ||
                                                row.attachment_file_name?.endsWith('.svg')
                                            )
                                                ?

                                                <img
                                                    style={{
                                                        height: '16px',
                                                        width: '16px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px',
                                                        objectFit: "cover",

                                                    }}
                                                    src={imgIcon}

                                                />
                                                :
                                                (
                                                    row.attachment_file_name?.endsWith('.doc')
                                                ) ?

                                                    <Box sx={{
                                                        height: '16px',
                                                        width: '16px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px',
                                                        objectFit: "cover",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={docIcon} style={{
                                                            height: '16px',
                                                            width: '16px',
                                                            objectFit: "cover",


                                                        }} />
                                                    </Box>
                                                    :

                                                    <Box sx={{
                                                        height: '16px',
                                                        width: '16px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px',
                                                        objectFit: "cover",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={imgIcon} style={{
                                                            height: '16px',
                                                            width: '16px',
                                                            objectFit: "cover",


                                                        }} />
                                                    </Box>


                                        }



                                    </Box>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: '#272833',
                                        width: '444px',
                                        maxWidth: '444px',
                                        minWidth: '444px',
                                        minHeight: '21px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        margin: '0px 0px 0px 21px',
                                        cursor: 'pointer'
                                    }}
                                        onClick={async () => {
       
                                             //  alert(row.attachment_url)
                                            const response = await fetch(row.attachment_url);
                                            const blob = await response.blob();
                                            const link = document.createElement("a");
                                            link.href = URL.createObjectURL(blob);
                                            link.download = row.attachment_file_name;
                                            document.body.appendChild(link);
                                            link.click();


                                        }}
                                    >
                                        {row.attachment_file_name}
                                    </Text>

                                    <Box sx={{
                                        position: 'absolute',
                                        width: '120px',
                                        height: '32px',
                                        right: '12px',
                                        display: 'flex',
                                        alignItems: 'center',

                                    }}>
                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            color: '#303030',
                                            whiteSpace: "nowrap",
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            width: '120px',
                                            minWidth: '80px',
                                            minHeight: '21px',
                                            maxWidth: '80px',
                                            height: '21px'
                                        }}>
                                            {new Date(row.attachment_timestamp).getDate() + "/" + (new Date(row.attachment_timestamp).getMonth() + 1) + "/" + new Date(row.attachment_timestamp).getFullYear()}
                                        </Text>
                                    </Box>

                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            right: '1px',
                                            display: row.attachment_id === attachmentHoverId ? 'flex' : 'none',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: 'none',
                                            boxShadow: 'none',
                                            background: 'transparent',
                                            height: '25px',
                                            width: '25px'
                                        }}
                                        icon={() => <Box sx={{ display: 'flex' }}><Cross2Icon /></Box>}
                                        onClick={() => {
                                            removeTaskAttachments(row)
                                        }}
                                    />



                                </Box>
                            )
                        })}

                </Box>




            </Box>
        )
    }

    const InvoiceComp = (props) => {


        return (
            <Box sx={{
                position: 'relative',
                margin: '0px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'column',

            }}>

                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '0px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {invoices.map((row, index) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '56px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        ':hover': {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        }
                                    }}
                                    onClick={() => {

                                           window.open(row.invoice_url)
                                           downloadZohoInvoice("") 
                                    }}

                                >

                                    <Box sx={{
                                        minWidth: '38px',
                                        maxWidth: '38px',
                                        width: '38px',
                                        minHeight: '56px',
                                        maxHeight: '56px',
                                        height: '56px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        <Box sx={{
                                            height: '16px',
                                            width: '16px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            objectFit: "cover",
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor:'pointer'
                                        }}>
                                            <img src={docIcon} style={{
                                                height: '16px',
                                                width: '16px',
                                                objectFit: "cover",


                                            }} />
                                        </Box>



                                    </Box>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: '#272833',
                                        minHeight: '21px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 21px',
                                        cursor: 'pointer'
                                    }}
                                         
                                    >
                                        {"Invoice Number : "+row.invoice_number}
                                    </Text>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: '#272833',
                                        minHeight: '21px',
                                        position: 'relative',
                                        margin: '0px 0px 0px 21px',
                                        cursor: 'pointer'
                                    }}
                                        
                                    >
                                        {"Status : "+row.status}
                                    </Text>

                                    <Text sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 21px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            color: '#303030',
                                            minHeight: '21px',
                                            height: '21px'
                                        }}>
                                            {"Created Date : "+new Date(row.created_date).getDate() + "/" + (new Date(row.created_date).getMonth() + 1) + "/" + new Date(row.created_date).getFullYear()}
                                        </Text>

                                     

                                </Box>
                            )
                        })}

                </Box>
            </Box>
        )


    }
 

    const CommentComp = (props) => {

        return (
            <Box sx={{
                position: 'relative',
                margin: '0px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'column',
                
            }}>

                <Box sx={{
                    position:'relative',
                    margin:'17px 0px 0px 0px',
                    minHeight:'40px',
                    maxHeight:'40px',
                    height:'40px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'15px'
                }}>

                  <TextInput
                  ref={commentInputRef}
                  sx={{
                    width:'539px',
                    minWidth:'539px',
                    maxWidth:'539px',
                    minHeight:'40px',
                    height:'40px',
                    maxHeight:'40px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontSize:'14px',
                    lineHeight:'20px',
                      
                  }}
                  placeholder="Post your comment here"
                  value={commentInput.value}
                  validationStatus={commentInput.validationStatus}
                  onChange={(e) => {
                            setcommentInput((prevState) => {
                                return ({ ...prevState, value: e.target.value, validationStatus: '' })
                            })
                  }}
                  />
                    <Button
                        variant="outline"
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            width: '67px',
                            minWidth: '67px',
                            maxWidth: '67px',
                            height: '40px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            background: '#0965CE',
                            color: 'white',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}
                        onClick={()=>{
                            postCommentToDb("") 
                        }} 
                    >
                        Post
                    </Button>  

                </Box>

                 <ActionList sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    position: 'relative',
                    margin: '20px 0px 0px 0px',
                }}>


                    {commentArray
                        ?.sort(function (a, b) {

                            return new Date(b.comments.comment_timestamp) - new Date(a.comments.comment_timestamp);
                        })
                        .map((row, index) => {

                            return (

                                <Box

                                    onMouseEnter={() => {
                                        setshowCommentGroupIcons(() => {
                                            return ({ id: row.comments.comment_id, visibility: true })
                                        })
                                    }}
                                    onMouseLeave={() =>
                                        setshowCommentGroupIcons(() => {
                                            return ({ id: "", visibility: false })
                                        })
                                    }
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px',
                                        width: '100%',
                                        cursor: 'pointer'

                                    }}>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        position: 'relative',
                                        margin: '0px 46px 0px 0px'
                                    }}>



                                        {row?.user_image_url === "" || row?.user_image_url == null ?

                                            <Box sx={{
                                                display: 'flex',
                                                height: '20px',
                                                minWidth: '20px',
                                                minHeight: '20px',
                                                width: '20px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px'
                                            }}>
                                                <FeedPersonIcon size={20} fill="#586069" />
                                            </Box>
                                            :

                                            <Avatar size={20} src={row.user_image_url} />
                                        }




                                        <Text sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 10px',
                                            fontFamily: 'Segoe UI',
                                            fontWeight: '600',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#000000',
                                            fontWeight: '600'
                                        }}>
                                            {row.user_first_name + " " + row.user_last_name}

                                        </Text>

                                        <Box
                                            sx={{
                                                position: 'relative',
                                                margin: '0px 0px 0px auto',
                                                display: showCommentGroupIcons.id === row.comments.comment_id ? 'flex' : 'none',
                                                flexDirection: 'row',
                                                gap: '11px'
                                            }}>

                                            <IconButton

                                                variant="invisible"
                                                sx={{
                                                    height: '20px',
                                                    width: '20px',
                                                    color: '#000000',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                icon={() => <TrashIcon style={{ display: 'flex' }} scale={15} />}
                                                onClick={() => {
                                                     deleteTaskCommentsToDb(row.comments.comment_id)
                                                }}
                                            />

                                            <IconButton

                                                variant="invisible"
                                                sx={{
                                                    height: '20px',
                                                    width: '20px',
                                                    color: '#000000',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                onClick={() => {
                                                      setcommentInput((prevState)=>{
                                                          return({...prevState,value:row.comments.comment_message,validationStatus:''})
                                                      })
                                                      setcommentEditId(row.comments.comment_id)
                                                }
                                                }
                                                icon={() => <Pencil1Icon style={{ display: 'flex' }} scale={15} />}
                                            />

                                            <Text
                                                sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 10px',
                                                    fontFamily: 'Segoe UI',
                                                    fontWeight: '400',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#24292E',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                {

                                                    "" + getNumberofDays(new Date(), new Date(row.comments.comment_timestamp)) == 0 ? "today" :

                                                        getNumberofDays(new Date(), new Date(row.comments.comment_timestamp)) + " days ago"
                                                        + ""
                                                }

                                            </Text>

                                        </Box>



                                    </Box>

                                    <Text sx={{
                                        position: 'relative',
                                        margin: '6px 0px 0px 30px',
                                        width: '586px',
                                        textAlign: 'left',
                                        fontFamily: 'Segoe UI',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#000000'
                                    }}>
                                        {row.comments.comment_message}
                                    </Text>


                                </Box>


                            )

                        })}











                </ActionList>



            </Box>
        )
    }

    const ActivityComp = (props) => {

        return (
            <Box sx={{
                position: 'relative',
                margin: '0px 0px 0px 0px',
                display: 'flex',
                flexDirection: 'column',
            }}>
 
                <Box sx={{
                    position: 'relative',
                    margin: '17px 0px 0px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap:'13px'
                }}>

                    <TextInput
                        ref={activityInputRef}
                        sx={{
                            width: '621px',
                            minWidth: '621px',
                            maxWidth: '621px',
                            minHeight: '40px',
                            height: '40px',
                            maxHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder="Add your task or reminders "
                        value={activityInput.value}
                        validationStatus={activityInput.validationStatus}
                        onChange={(e) => {
                            setactivityInput((prevState) => {
                                return ({ ...prevState, value: e.target.value, validationStatus: '' })
                            })
                        }}
                    />

                    <Box sx={{
                           position:'relative',
                           margin:'0px 0px 0px 0px',
                           width: '621px',
                           minWidth: '621px',
                           maxWidth: '621px',
                           minHeight:'32px',
                           maxHeight:'32px',
                           height:'32px',
                           display:'flex',
                           flexDirection:'row'
                    }}>

                        <Button
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '0px 0px 0px -15px',
                                width: '117px',
                                height: '32px',
                            }}
                            leadingIcon={() => <CalendarIcon style={{ display: 'flex', color: '#000000', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto', left: '16px' }} />}
                            onClick={() => activitycalandarRef.current.showPicker()}
                        >
                            <Text
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: selectedActivityDate === "" ? '#6A737D' : '#121212',
                                    textAlign: 'left',
                                    height: 'fit-content',
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '0px',
                                    bottom: '0px',
                                    margin: 'auto',
                                    left: '47px',


                                }}
                            >

                                {selectedActivityDate === "" ? "Add date" :
                                    // selectedActivityDate
                                    new Date(selectedActivityDate).getDate() + "/" + (new Date(selectedActivityDate).getMonth() + 1) + "/" + new Date(selectedActivityDate).getFullYear()
                                }
                            </Text>

                            <input
                                type={"date"}
                                ref={activitycalandarRef}
                                onChange={(e) => {
                                    setselectedActivityDate(
                                        new Date(e.target.value)

                                    )
                                }}
                                style={{

                                    position: 'absolute',
                                    left: '1px',
                                    top: '2px',
                                    height: '30px',
                                    width: '80%',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />

                        </Button>  

                        <Button
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '0px 0px 0px -7px',
                                width: '117px',
                                height: '32px',
                            }}
                            leadingIcon={() => <ClockIcon style={{ display: 'flex', color: '#000000', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto', left: '16px' }} />}
                            onClick={() => activityTimeRef.current.showPicker()}
                        >
                            <Text
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: selectedActivityTime === "" ? '#6A737D' : '#121212',
                                    textAlign: 'left',
                                    height: 'fit-content',
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '0px',
                                    bottom: '0px',
                                    margin: 'auto',
                                    left: '47px',

                                }}
                            >
                                {selectedActivityTime === "" ? "Add time" : getAMPMfromTime(selectedActivityTime)}

                            </Text>
                            <input
                                type={"time"}
                                ref={activityTimeRef}
                                onChange={(e) => {
                                    setselectedActivityTime(e.target.value)

                                }}
                                style={{

                                    position: 'absolute',
                                    left: '1px',
                                    top: '2px',
                                    height: '30px',
                                    width: '80%',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />

                        </Button> 

                        <ActionMenu>

                            <ActionMenu.Anchor>

                                <Button
                                    variant="invisible"
                                    sx={{
                                        color: '#000000',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        margin: '0px 0px 0px -7px',

                                    }}
                                    leadingIcon={() => <CameraIcon style={{ display: 'flex', color: '#000000' }} />}
                                >
                                    <Text
                                        sx={{
                                            fontFamily: 'Segoe UI',
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                            color: '#6A737D',
                                            position: 'relative',
                                            margin: '0px 0px 0px 8px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        Schedule video call
                                    </Text>

                                </Button>
                            </ActionMenu.Anchor>
                            <ActionMenu.Overlay>

                                <ActionList
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '4px',
                                        margin: '8px'
                                    }}
                                >
                                    <ActionList.Item
                                      onSelect={()=>{
                                        getGoogleMeetAuthToken("")
                                      }}
                                    >

                                        <Token
                                            sx={{
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',
                                                height: '22px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '3px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                            leadingVisual={() =>


                                                <img
                                                    style={{
                                                        display: 'flex',
                                                        minWidth: '18px',
                                                        maxWidth: '18px',
                                                        minHeight: '14px',
                                                        maxHeight: '14px',
                                                    }}
                                                    src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />

                                            }
                                            text={
                                                <Text
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 3px',

                                                    }}
                                                >Google Meet
                                                </Text>
                                            }
                                        />

                                    </ActionList.Item>
                                    {/* <ActionList.Item>

                                        <Token
                                            sx={{
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',
                                                height: '22px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '3px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                            leadingVisual={() =>


                                                <img  style={{ display: 'flex', minWidth: '15px', maxWidth: '15px', minHeight: '14px', maxHeight: '14px',}}
                                                    src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg" />

                                            }
                                            text={
                                                <Text
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 5px',

                                                    }}
                                                >Microsoft Meet
                                                </Text>
                                            }
                                        />

                                    </ActionList.Item> */}
                                    {/* <ActionList.Item>

                                        <Token
                                            sx={{
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',
                                                height: '22px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '3px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'

                                            }}
                                            leadingVisual={() =>


                                                <img
                                                    style={{
                                                        display: 'flex',
                                                        minWidth: '18px',
                                                        maxWidth: '18px',
                                                        minHeight: '18px',
                                                        maxHeight: '18px',
                                                        objectFit: 'fill',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px -2px'
                                                    }}
                                                    src="https://www.vectorlogo.zone/logos/zoomus/zoomus-icon.svg" />

                                            }
                                            text={
                                                <Text
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 4px',
                                                    }}
                                                >Zoom Meet

                                                </Text>
                                            }
                                        />

                                    </ActionList.Item> */}



                                </ActionList>

                            </ActionMenu.Overlay>

                        </ActionMenu>


                        <Box sx={{
                            position:'absolute',
                            right:'0px',
                            width:'140px',
                            minWidth:'140px',
                            maxWidth:'140px',
                            minHeight:'32px',
                            maxHeight:'32px',
                            height:'32px',
                            display:'flex',
                            flexDirection:'row',
                            gap:'8px'
                        }}>

                            <Button
                                variant="invisible"
                                sx={{
                                    width: '74px',
                                    height: '32px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '20px'
                                }}
                                onClick={()=>{
                                    clearActivityInput("")
                                }}
                            >
                                Cancel
                            </Button>

                            <Button

                                onClick={() => { handlePostActivity("") }}
                                variant="outline"
                                sx={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    width: '58px',
                                    minWidth: '58px',
                                    maxWidth: '58px',
                                    height: '32px',
                                    minHeight: '32px',
                                    maxHeight: '32px',
                                    background: '#0965CE',
                                    color: 'white',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    lineHeight: '20px'



                                }}>
                                Add
                            </Button>
                            
                        </Box>
                        
                     </Box>
                    <Box sx={{
                        display: 'flex',
                        width: '621px',
                        minWidth: '621px',
                        maxWidth: '621px',
                        height: '0px',
                        borderBottom: '0.5px solid #E1E6EA'
                    }}>

                    </Box>
                    



                </Box> 


                <Box sx={{
                    position: 'relative',
                    margin: '26px 0px 0px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                     gap: '12px',
                  
                }}>


                    {
                        activityArray?.sort(function (a, b) {
                            return new Date(b.activity.activity_timestamp) - new Date(a.activity.activity_timestamp);
                        }).map((row, index) => {

                            return (

                                <Box

                                    onMouseEnter={() => {
                                        setshowActivityGroupIcons(() => {
                                            return ({ id: row.activity.activity_id, visibility: true })
                                        })
                                    }}
                                    onMouseLeave={() =>
                                        setshowActivityGroupIcons(() => {
                                            return ({ id: "", visibility: false })
                                        })
                                    }

                                    borderBottomColor="border.default"
                                    borderBottomWidth={1}
                                    borderBottomStyle="solid"
                                    sx={{
                                        height: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth:'621px',
                                        minWidth:'621px',
                                        width:'621px'
                                        
                                    }}>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            alignItems:'start',
                                            minHeight:'23px',
                                            maxHeight:'23px',
                                            height:'23px',
                                        }}
                                    >

    
                                        {row.activity.activity_date === "Invalid Date" ? "" :

                                            <Token

                                                sx={{
                                                    width: '127px',
                                                    minWidth: '127px',
                                                    maxWidth: '127px',
                                                    height: '22px',
                                                    padding: '0px',
                                                    background: '#AFB8C1',
                                                    borderRadius: '3px',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: '#202428',
                                                    border: 'none',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '12px',
                                                    lineHeight: '18px'
                                                }}
                                                text={
                                                    <Text
                                                        sx={{
                                                            width: '127px',
                                                            marginLeft: '13px'
                                                        }}
                                                    >
                                                        {new Date(row.activity.activity_date).getDate() + "-" + (new Date(row.activity.activity_date).getMonth() + 1) + "-" + new Date(row.activity.activity_date).getFullYear()}
                                                        {row.activity.activity_time === "" ? "" : " " + getAMPMfromTime(row.activity.activity_time) + " "}

                                                    </Text>
                                                }
                                            />

                                        }

                                        <Token
                                            sx={{
                                                position: 'absolute',
                                                left: '152px',
                                                height: '22px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '3px',
                                                background: '#F6F8FA',
                                                border: 'none',

                                            }}
                                            leadingVisual={() =>

                                                index === 0 ?
                                                    <img
                                                        style={{
                                                            display: 'flex',
                                                            minWidth: '18px',
                                                            maxWidth: '18px',
                                                            minHeight: '14px',
                                                            maxHeight: '14px',
                                                        }}
                                                        src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />
                                                    :
                                                    index === 1 ?
                                                        <img
                                                            style={{
                                                                display: 'flex',
                                                                minWidth: '15px',
                                                                maxWidth: '15px',
                                                                minHeight: '14px',
                                                                maxHeight: '14px',
                                                            }}
                                                            src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg" />
                                                        :
                                                        index === 2 ?
                                                            <img
                                                                style={{
                                                                    display: 'flex',
                                                                    minWidth: '18px',
                                                                    maxWidth: '18px',
                                                                    minHeight: '18px',
                                                                    maxHeight: '18px',

                                                                }}
                                                                src="https://www.vectorlogo.zone/logos/zoomus/zoomus-icon.svg" />
                                                            :
                                                            <img
                                                                style={{
                                                                    display: 'flex',
                                                                    minWidth: '18px',
                                                                    maxWidth: '18px',
                                                                    minHeight: '14px',
                                                                    maxHeight: '14px',
                                                                    objectFit: 'fill'
                                                                }}
                                                                src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />

                                            }
                                            text={
                                                <Text
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 3px',

                                                    }}
                                                >
                                                    {
                                                        index === 0 ?
                                                            "Google Meet Meeting scheduled" :
                                                            index === 1 ?
                                                                "Microsoft Meet meeting scheduled" :
                                                                index === 2 ?
                                                                    "Zoom Meet meeting scheduled" :
                                                                    "Google Meet Meeting scheduled"
                                                    }

                                                </Text>
                                            }
                                        />


                                        <Box
                                            sx={{
                                                display: showActivityGroupIcons.id === row.activity.activity_id ? 'flex' : 'none',
                                                flexDirection: 'row',
                                                gap: '5px',
                                                position: 'relative',
                                                margin: '0px 0px 0px auto'
                                            }}
                                        >

                                            <IconButton
                                                variant="invisible"
                                                aria-label="Search"
                                                sx={{
                                                    height: '25px',
                                                    width: '25px',
                                                    background: '#FFFFFF',
                                                    color: '#000000',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}

                                                onClick={() => {

                                                    activityInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                                                    setactivityInput((preState) => {

                                                        return ({
                                                            activityId: row.activity.activity_id,
                                                            value: row.activity.activity_name,
                                                            placeholder: row.activity.activity_type
                                                            
                                                        })

                                                    })
                                                   if (row.activity.activity_date !== "Invalid Date") {

                                                        setselectedActivityDate(new Date(row.activity.activity_date))
                                                    } else {

                                                        setselectedActivityDate("")
                                                    }

                                                    if (row.activity.activity_time !== "") {

                                                        setselectedActivityTime(row.activity.activity_time)
                                                    } else {
                                                        setselectedActivityTime("")

                                                    }








                                                }}

                                                icon={() => <Pencil1Icon style={{ display: 'flex' }} />}
                                            />

                                            <ActionMenu>
                                                <ActionMenu.Anchor>
                                                    <IconButton
                                                        variant="invisible"
                                                        aria-label="Search"
                                                        sx={{
                                                            height: '25px',
                                                            width: '25px',
                                                            background: '#FFFFFF',
                                                            color: '#000000',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                        icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}
                                                    />
                                                </ActionMenu.Anchor>
                                                <ActionMenu.Overlay
                                                    align="end"

                                                >
                                                    <ActionList
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            margin: '6px',
                                                            gap: '4px',
                                                            fontFamily: 'Segoe UI'
                                                        }}
                                                    >
                                                         <ActionList.Item
                                                            sx={{

                                                            }}
                                                            onSelect={()=>{
                                                                 window.open(row.activity.videocall_link)
                                                            }}
                                                        >
                                                            Meeting Link
                                                        </ActionList.Item>
                                                        <ActionList.Item
                                                            sx={{

                                                            }}
                                                        >
                                                            Copy
                                                        </ActionList.Item>
                                                        <Box borderColor="border.default"
                                                            borderBottomWidth={1}
                                                            borderBottomStyle="solid"
                                                            sx={{
                                                                width: '100%'
                                                            }}
                                                        >

                                                        </Box>
                                                        <ActionList.Item variant="danger"
                                                            onSelect={() =>


                                                                deleteTaskActivityToDb(row.activity.activity_id)

                                                            }
                                                        >
                                                            Delete
                                                        </ActionList.Item>
                                                    </ActionList>
                                                </ActionMenu.Overlay>


                                            </ActionMenu>



                                        </Box>


                                    </Box>


                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: 'relative',
                                            margin: '12px 0px 7px 0px',
 
                                        }}
                                    >


                                            <Tooltip aria-label={row.user_first_name + ' ' + row.user_last_name} direction="ne">
                                            {row?.user_image_url === "" || row?.user_image_url == null ?

                                                <Box sx={{
                                                    display: 'flex',
                                                    height: '20px',
                                                    minWidth: '20px',
                                                    minHeight: '20px',
                                                    width: '20px',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px'
                                                }}><FeedPersonIcon size={20} fill="#586069" /></Box>

                                                :

                                                <Avatar
                                                    size={20}
                                                    sx={{
                                                        display: 'flex',
                                                        height: '20px',
                                                        minWidth: '20px',
                                                        minHeight: '20px',
                                                        width: '20px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px'
                                                    }}
                                                    src={row.user_image_url} />

                                            }

                                        </Tooltip>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                position: 'relative',
                                                margin: '0px 0px 0px 20px',

                                            }}
                                        >
                                            <Text
                                                sx={{
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 0px',
                                                    height: 'fit-content',
                                                    minHeight: 'fit-content',
                                                    maxHeight: 'fit-content',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '17px',
                                                    lineHeight: '20px',
                                                    color: '#121212',
                                                    textAlign: 'left',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {row.activity.activity_name} 
                                                
                                            </Text>

                                            

                                        </Box>

                                    </Box>



                                </Box>
                            )

                        })
                    }





                </Box>
                 


 
            </Box>
        )
    }


    const getSingleSpaceTasksFromDb = (props) => {

        dispatch(getSingleSpaceTasks({
            taskId: props
        })).then((data) => {

            const tKey = data.payload.data[0].task_key
            const tName = data.payload.data[0].task_name
            const tDescription = data.payload.data[0].description
            const tSubtasks = data.payload.data[0].compliance_subtasks
            const tDueDate = data.payload.data[0].due_date
            const tAssigne = data.payload.data[0].assigne_id
            const taskContactData = data.payload?.taskContactData
            const compliaceData = data.payload?.compliaceData
            const tAttachments = data.payload.data[0].attachments
            const completedStatus = data.payload.data[0].completed_status
            const tComments = data.payload.taskComments
            const tActivity = data.payload.taskActivity
            const tPriority = data.payload.data[0].tasks_priority
            const tPrioRityIndex = priorityArray.findIndex((pData)=> pData.name===tPriority)
            const tSubtaskParent = data.payload.data[0].subtask_parent
          
           
            settaskKey(tKey)
            getZohoInvoiceFromApi(tKey)

            if(tSubtaskParent!=="0"){

                 setsubtaskTabVisiblity(true)

                //  setitems((prevState)=>{
                //        const newState = prevState.filter((tab)=> tab.navigation!=="Subtask" )
                //        return newState
                      
                //  })
             

            }else{
 
                //  setitems([
                //     { navigation: 'Subtask' },{ navigation: 'To do', counter: 0 },{navigation: 'Activity',counter:0},{ navigation: 'Comment', counter: 0 }, { navigation: 'Attachments', counter: 0 }
                //  ])

                 setsubtaskTabVisiblity(false)
               
            }
           

            setselectedPriority(tPrioRityIndex)
            setcommentArray(tComments)
            setactivityArray(tActivity)
            setcompletedStatus(completedStatus)

            //-- set methods

            settaskNameInput((prevState) => {
                return ({ ...prevState, value: tName, validationStatus: '' })
            })
            settaskDescriptionInput((prevState) => {
                const newDes = tDescription?.blocks[0].text || ''
                return ({ ...prevState, value: newDes, validationStatus: '' })
            })
            const newtSubtasks = tSubtasks //?.map((row) => { return ({ ...row, isChecked: false }) })
            setcompliaceSubtasks(newtSubtasks)
            if (tDueDate !== null && tDueDate !== null) {

                setdueDate(tDueDate)
            } else {
                setdueDate("")
            }

            if (tAssigne !== null && tAssigne !== "") {

                const tAssigneName = data.payload.data[0].user_first_name + " " + data.payload.data[0].user_last_name
                
                setselectedAssigne({
                    user_id: tAssigne,
                    user_name: tAssigneName,
                    user_email:''
                })
            } else {
                setselectedAssigne({
                    user_id: '',
                    user_name: '',
                    user_email:''
                })
            }


            setContact(taskContactData[0]?.contact_data?.org_name)
            setcomplianceName(compliaceData[0]?.subcategory?.compliance_name)
            setworkflow(data.payload.data[0]?.workflow)
            const workflowName = data.payload.data[0]?.workflow?.filter((wData) => wData.workflow_id === data.payload.data[0].workflow_id)[0]?.workflow_name
            setSelectedWorkFlow({
                workflow_id: data.payload.data[0].workflow_id,
                workflow_name: workflowName
            })


            setattachmentArray(tAttachments)
            setitems((prevState) => {
                const newState = prevState.map((item) => {
                    if (item.navigation === "Attachments") {
                        return ({ ...item, counter: tAttachments.length })
                    }else {
                        return (item)
                    }



                })

                return newState
            })
            setitems((prevState) => {
                const newState = prevState.map((item) => {
                    if (item.navigation === "Comment") {
                        return ({ ...item, counter: tComments.length })
                    }else {
                        return (item)
                    }



                })

                return newState
            })
            setitems((prevState) => {
                const newState = prevState.map((item) => {
                    if (item.navigation === "Activity") {
                   // if (item.navigation === "To do") {
                        return ({ ...item, counter: tActivity.length })
                    }else {
                        return (item)
                    }

                })

                return newState
            })
            setitems((prevState) => {
                const newState = prevState.map((item) => {
                   
                    if (item.navigation === "To do") {
                        return ({ ...item, counter: tSubtasks.length })
                    }else {
                        return (item)
                    }

                })

                return newState
            })



        })


    }

    const getSpaceCollaboratorFromDb = (props) => {


        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)




        })

    }

    const getTaskProjectHistoryFromDb = (props)=>{
    
        
        dispatch(getTaskProjectHistory({
          userId:userId,
          userEmail:userEmail,
          projectId:localStorage.getItem("projectId")
        })).then((data)=>{
  
             sethistoryArray(data.payload.data)  
  
        })
  
  
    }


    React.useEffect(() => {

        if (selectedIndex === 2) {
            activityInputRef.current.focus()
        }

        if (selectedIndex === 3) {

            commentInputRef.current.focus()
        }


    }, [selectedIndex])



    React.useEffect(()=>{

        if(parseInt(subtaskPercent)==100){
            showconfirmCompletedSubtaskDialog("")
        }


    },[subtaskPercent])



    React.useEffect(() => {

        if (showSubtaskInput === true) {
            subtaskInputRef.current.focus()
        }


    }, [showSubtaskInput])

    React.useEffect(() => {

        if (isDataEditable === true) {
            taskDescriptionRef.current.focus()
            taskNameRef.current.focus()
            const range = document.createRange();
            range.selectNodeContents(taskNameRef.current);
            range.collapse(false);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

        }


    }, [isDataEditable])

    React.useEffect(() => {

        if (openCloseExpansion === true) {

            getSingleSpaceTasksFromDb(taskData?.expansionTaskId)
            getSpaceCollaboratorFromDb("")
            getTaskProjectHistoryFromDb("")
            getSubtaskPrimaryFromDb(taskData?.expansionTaskId)
            getZohoAuthFromDb("")
        } else {


        }


    }, [openCloseExpansion])
 

    return (
    
       
        <Box
        zIndex={1}
        sx={{
            position: 'fixed',
            bottom: '0px',
            right: '0px',
            top: '50px',
            width: '1090px',
            minWidth: '1090px',
            maxWidth: '1090px',
            background: 'white',
            display: openCloseExpansion === true ? 'flex' : 'none',
            flexDirection: 'column',
            overflowY: 'hidden',
            overflowX: 'hidden',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#E1E6EA'

        }}
        
        >



        <StaticHeader />

        <Box sx={{
            position: 'absolute',
            top: '63px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            background: '#ffffff',
            display: 'flex',
            overflowX: 'hidden',
            overflowY: 'auto'
        }}>

            <Box sx={{
                position: 'absolute',
                top: '1px',
                left: '37px',
                width: '669px',
                minWidth: '669px',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {isDataEditable === false ?
                    <Heading

                        sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 0px 0px 0px'
                        }}>
                        {taskNameInput.value}
                    </Heading>
                    :
                    <Heading
                        ref={taskNameRef}
                        contentEditable={true}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            outline: 'none',
                            border: '2px solid #0969DA',
                            borderRadius: '3px',
                            padding: '6px 8px 6px 8px'
                        }}
                        onPaste={(event) => {
                            event.preventDefault();
                            const text = event.clipboardData.getData('text/plain');
                            const cleanText = text.replace(/<\/?[^>]+(>|$)|&nbsp;/gi, '');
                            document.execCommand('insertText', false, cleanText);
                        }}
                    // defaultValue={taskNameInput.value}
                    // onInput={(e)=>{
                    //      settaskNameInput((prevState)=>{

                    //           return({...prevState,value:e.target.value,validationStatus:''})
                    //      })
                    // }}


                    >
                        {taskNameInput.value}
                    </Heading>

                }

                {isDataEditable === false ?

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#24292E',
                        position: 'relative',
                        margin: taskDescriptionInput.value.length ? '15px 0px 15px 0px' : '15px 0px 0px 0px'
                    }}>
                        {taskDescriptionInput.value}


                    </Text>


                    :



                    <Text
                        ref={taskDescriptionRef}
                        contentEditable
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '24px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '15px 0px 0px 0px',
                            outline: 'none',
                            border: '1px solid #0969DA',
                            borderRadius: '6px',
                            padding: '8px 12px 14px 12px'
                        }}
                        onPaste={(event) => {

                            event.preventDefault();
                            const text = event.clipboardData.getData('text/plain');
                            const cleanText = text.replace(/<\/?[^>]+(>|$)|&nbsp;/gi, '');
                            document.execCommand('insertText', false, cleanText);

                        }}


                    >
                        {taskDescriptionInput.value}


                    </Text>

                }



                {isDataEditable === true ?

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                        position: 'relative',
                        margin: '15px 0px 0px auto',
                    }}>
                        <Button
                            variant="invisible" sx={{
                                position: 'relative',
                                width: '60px',
                                height: '28px',
                                minWidth: '60px',
                                maxWidth: '60px',
                                minHeight: '28px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                lineHeight: '20px',
                                fontWeight: '600',
                                zIndex: '1'
                            }}
                            onClick={() => {
                                setisDataEditable(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline" sx={{
                                position: 'relative',
                                background: '#0965CE',
                                color: '#ffffff',
                                width: '50px',
                                height: '28px',
                                minWidth: '50px',
                                maxWidth: '50px',
                                minHeight: '28px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                lineHeight: '20px',
                                fontWeight: '600',
                                zIndex: '1'
                            }}
                            onClick={() => {
                                updateTaskNameAndDescription(taskNameInput.value, taskDescriptionInput.value)
                            }}
                        >
                            Save
                        </Button>
                    </Box>

                    : ""}


                <Box
                    sx={{
                        width: 669,
                        minWidth: 669,
                        minHeight: 48,
                        maxHeight: 48,
                        height: 48,
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        position: 'relative',
                        margin: isDataEditable === false ? '0px 0px 0px 0px' : '-28px 0px 0px 0px',
                        padding: '0px',
                     
                    }}
                >
                    <UnderlineNav
                        key={"" + Math.random(100, 10000) + ""}
                        aria-label="Repository"
                        sx={{
                            position: 'relative',
                            padding: '0px 0px 0px 3px',
                            width: 669,
                            minWidth: 669,
                            minHeight: 48,
                            maxHeight: 48,
                            height: 48,
                            border: 'none',
                           
                        }} >
                        {items.map((item, index) => (

                            item.navigation === "Details" ?

                                <UnderlineNav.Item
                                    key={item.navigation}
                                    aria-current={index === selectedIndex ? 'page' : undefined}
                                    onSelect={e => {
                                        setSelectedIndex(index)
                                        e.preventDefault()
                                    }}
                                    selected={index === selectedIndex}
                                >
                                    {item.navigation}
                                </UnderlineNav.Item>
                                :
                                <UnderlineNav.Item
                                    sx={{ display: subtaskTabVisiblity === true && item.navigation === "Subtask" ? 'none' : 'flex' }}
                                    key={item.navigation}
                                    aria-current={index === selectedIndex ? 'page' : undefined}
                                    onSelect={e => {
                                        setSelectedIndex(index)
                                        e.preventDefault()
                                    }}
                                    counter={item.counter}
                                    selected={index === selectedIndex}
                                >
                                    {item.navigation}
                                </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>

                    <Box sx={{
                        position: 'absolute',
                        width: '706px',
                        height: '0px',
                        left: '-40px',
                        bottom: '2px',
                        borderBottom: '1px solid #E1E6EA'
                    }}>

                    </Box>

                        <IconButton
                            sx={{
                                position: 'absolute',
                                height: '28px',
                                width: '28px',
                                minHeight: '28px',
                                minWidth: '28px',
                                maxHeight: '28px',
                                maxWidth: '28px',
                                right: '0px',
                                bottom: '10px',
                                display: items.length === 6 ? selectedIndex === 4 ? isDataEditable === false ? 'flex' : 'none' : 'none' : selectedIndex === 3 ? isDataEditable === false ? 'flex' : 'none' : 'none',
                                // display: selectedIndex === 3 ? isDataEditable === false ? 'flex' : 'none' : 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            icon={() => <Box sx={{ display: 'flex' }}> <UploadIcon /> </Box>}
                            onClick={() => { computerFileRef.current.click() }}
                        />
                        <input ref={computerFileRef} type={"file"} onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />

                </Box>

                {
                selectedIndex === 0 ? 
                    NewSubtaskComp("")
                    :
                
                selectedIndex === 1 ?

                    DetailsComp("")
                    :
                    ""}

                {selectedIndex === 2 ?

                    ActivityComp("")
                    :
                    ""}

                {selectedIndex === 3 ?

                    CommentComp("")
                    :
                    ""}

                {selectedIndex === 4 ?

                    AttachmentComp("")
                    :

                 

                    ""}

                {selectedIndex === 5 ?

                        InvoiceComp("")
                        :



                        ""}

            </Box>

            <Box sx={{
                minWidth: '316px',
                minHeight: '292px',
                height: '292px',
                width: '316px',
                position: 'absolute',
                top: '24px',
                right: '38px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <PersonIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Assignee
                    </Text>

                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '170px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        minWidth: '170px',
                        maxWidth: '170px',

                    }}>

                        <ActionMenu >

                            <ActionMenu.Anchor >

                                <Button

                                    disabled={!isDataEditable}
                                    sx={{
                                        minWidth: '170px',
                                        minHeight: '32px',
                                        width: '170px',
                                        height: '32px',
                                        background: '#ffffff',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingLeft: '8px',
                                        borderRadius: '6px',
                                        ":disabled":{
                                            color:'#24292F'
                                        }

                                    }}
                                    trailingIcon={() => <Box sx={{ position: 'absolute', right: '8px', top: '4px', bottom: '0px' }}><TriangleDownIcon /></Box>}
                               
                               >
                                    {selectedAssigne.user_id === "" ? "Selected assignee" : selectedAssigne.user_name}
                                </Button>

                            </ActionMenu.Anchor>

                            <ActionMenu.Overlay>
                                <ActionList sx={{
                                    width: '280px',
                                    height: 'auto',
                                    minWidth: '280px',
                                    minHeight: 'auto',
                                    maxWidth: '280px',
                                    maxHeight: '180px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden'
                                }}
                                    showDividers
                                >
                                    {spaceCollaborators.map((row, index) => {


                                        return (
                                            <ActionList.Item onSelect={event => {
                                                setselectedAssigne({
                                                    user_id: row.user_id,
                                                    user_name: row.user_first_name + "" + row.user_last_name,
                                                    user_email:''
                                                })
                                                updateTaskAssigneToDb(row.user_id)
                                            }
                                            }
                                                sx={{ minHeight: '20px', maxHeight: '20px', height: '20px' }}
                                            >

                                                <ActionList.LeadingVisual>
                                                    {row?.user_image_url === "" || row?.user_image_url == null ?

                                                        <FeedPersonIcon size={20} fill="#586069" /> :

                                                        <Avatar sx={{
                                                            height: '20px',
                                                            width: '20px',
                                                            minHeight: '20px',
                                                            minWidth: '20px',


                                                        }}
                                                            src={row.user_image_url} />

                                                    }

                                                </ActionList.LeadingVisual>
                                                <Text sx={{
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    color: '#24292F',
                                                    width: 'auto',
                                                    maxWidth: '80px',
                                                    minWidth: 'auto',
                                                    height: '15px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    display: 'flex'

                                                }}>{row?.user_first_name + " " + row?.user_last_name}</Text>
                                                <ActionList.Description>
                                                    <Text sx={{
                                                        fontFamily: 'Segoe UI',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        color: '#57606A',
                                                        width: 'auto',
                                                        maxWidth: '130px',
                                                        minWidth: 'auto',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        display: 'flex'
                                                    }}>{row?.user_email}</Text>
                                                </ActionList.Description>
                                            </ActionList.Item>
                                        )

                                    })}





                                </ActionList>




                            </ActionMenu.Overlay>

                        </ActionMenu>



                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <InputIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Contact
                    </Text>

                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '170px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        minWidth: '170px',
                        maxWidth: '170px',

                    }}
                        onClick={() => { }}
                    >

                        <TextInput
                            disabled={!isDataEditable}
                            sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                borderRadius: '6px',
                                width: '170px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                minWidth: '170px',
                                maxWidth: '170px',
                                color:'#24292F',
                                ":disabled":{
                                    color:'#24292F'
                                }
                            }}

                            value={contact}
                        />



                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <CalendarIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Due date
                    </Text>



                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '170px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        minWidth: '170px',
                        maxWidth: '170px',
                        color:'#24292F'
                    }}

                    >
                        <TextInput
                            disabled={!isDataEditable}
                            sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                borderRadius: '6px',
                                width: '170px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                minWidth: '170px',
                                maxWidth: '170px',
                                color:'#24292F',
                                ":disabled":{
                                    color:'#24292F'
                                }
                                 
                                
                            }}
                            
                            placeholder="Select due date"
                            value={
                                dueDate !== "" && dueDate !== null ?
                                    new Date(dueDate).getDate() + "-" + (new Date(dueDate).getMonth() + 1) + "-" + new Date(dueDate).getFullYear()
                                    : "Select due date"
                            }
                            onChange={(e) => {
                                dueDateRef.current.showPicker()
                            }}
                            onFocus={() => {
                                dueDateRef.current.showPicker()
                            }}
                            onClick={() => {
                                dueDateRef.current.showPicker()
                            }}
                        />

                        <input
                            type={"date"}
                            ref={dueDateRef}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {


                                    setdueDate("")


                                } else {

                                    setdueDate(new Date(e.target.value))
                                    updateTaskDueDateToDb(new Date(e.target.value))
                                }


                            }}
                            style={{
                                position: 'absolute',
                                right: '0px',
                                top: '0px',
                                height: '30px',
                                width: '100%',
                                zIndex: '0',
                                visibility: 'hidden'
                            }}

                        />

                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <ReaderIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Compliance
                    </Text>
                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '170px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        minWidth: '170px',
                        maxWidth: '170px',

                    }}>

                        <TextInput
                            disabled
                            sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                borderRadius: '6px',
                                width: '170px',
                                height: '32px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                minWidth: '170px',
                                maxWidth: '170px',
                                color:'#24292F',
                                ":disabled":{
                                    color:'#24292F'
                                }
                            }}
                            placeholder=""
                            value={complianceName}
                        />

                    </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <LightningBoltIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Roadmap
                    </Text>
                        <Box sx={{
                            position: 'relative',
                            margin: '0px 0px 0px auto',
                            width: '170px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            minWidth: '170px',
                            maxWidth: '170px',
                            background: '#ffffff',
                            backgroundColor: '#ffffff'
                        }}>

                            {/* <Select
                                className="custom-select-roadmap"
                                disabled={!isDataEditable}
                                block
                                style={{
                                    borderRadius: '6px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: !isDataEditable ? '#000000' : '#24292F',
                                    
                                }}
                                placeholder={"Selected roadmap"}
                            >
                                <Select.Option value="Roadmap" >Road map</Select.Option>

                            </Select> */}
                            <ActionMenu >

                                <ActionMenu.Anchor >

                                    <Button

                                        disabled={!isDataEditable}
                                        sx={{
                                            minWidth: '170px',
                                            minHeight: '32px',
                                            width: '170px',
                                            height: '32px',
                                            background: '#ffffff',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#24292F',
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingLeft: '8px',
                                            borderRadius: '6px',
                                            ":disabled": {
                                                color: '#24292F'
                                            }

                                        }}
                                        trailingIcon={() => <Box sx={{ position: 'absolute', right: '8px', top: '4px', bottom: '0px' }}><TriangleDownIcon /></Box>}

                                    >
                                        {"Selected roadmap"}
                                    </Button>

                                </ActionMenu.Anchor>

                                <ActionMenu.Overlay>
                                    <ActionList
                                        sx={{
                                            width: '170px',
                                            height: 'auto',
                                            minWidth: '170px',
                                            minHeight: 'auto',
                                            maxWidth: '170px',
                                            maxHeight: '170px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}
                                        showDividers
                                    >
                                        <ActionList.Item sx={{ minHeight: '20px', maxHeight: '20px', height: '20px' }} >

                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292F',
                                                width: 'auto',
                                                maxWidth: '80px',
                                                minWidth: 'auto',
                                                height: '15px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                display: 'flex'
                                            }}>
                                                {"Road map"}
                                            </Text>

                                        </ActionList.Item>


                                    </ActionList>




                                </ActionMenu.Overlay>

                            </ActionMenu>

                        </Box>

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    alignItems: 'center',

                }}>
                    <Box sx={{ display: 'flex' }}>
                        <DrawingPinIcon />
                    </Box>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212',

                    }}>
                        Priority
                    </Text>
                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        width: '170px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        minWidth: '32px',
                        maxWidth: '170px',

                    }}>

                          
                            <ActionMenu>

                                <ActionMenu.Anchor>

                                    <Button
                                        disabled={!isDataEditable}
                                        sx={{
                                            borderRadius: '6px',
                                            width: '170px',
                                            height: '32px',
                                            minHeight: '32px',
                                            maxHeight: '32px',
                                            minWidth: '170px',
                                            maxWidth: '170px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            color: '#121212',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            ":disabled": {
                                                color: '#24292F',
                                                background: '#ffffff'
                                            }

                                        }}

                                        leadingIcon={() =>

                                            priorityArray[selectedPriority].name === "Normal" ?
                                                // <img src={normalIcon} style={{ display: 'flex' }} />
                                                <Box sx={{
                                                    width: '12px',
                                                    height: '12px',
                                                    minWidth: '12px',
                                                    maxWidth: '12px',
                                                    minHeight: '12px',
                                                    maxHeight: '12px',
                                                    borderRadius: '50%',
                                                    background: '#428FDC',
                                                    // border:'1px solid #428FDC' 
                                                }}></Box>

                                                :
                                                priorityArray[selectedPriority].name === "Medium" ?
                                                    <img src={mediumIcon} style={{ display: 'flex' }} /> :
                                                    priorityArray[selectedPriority].name === "High" ?
                                                        <img src={highIcon} style={{ display: 'flex' }} /> :
                                                        priorityArray[selectedPriority].name === "Critical" ?
                                                            <img src={criticalIcon} style={{ display: 'flex' }} /> :
                                                            priorityArray[selectedPriority].name === "Low" ?
                                                                <img src={lowIcon} style={{ display: 'flex' }} />
                                                                : ""
                                        }
                                        trailingIcon={() => <Box sx={{ position: 'absolute', right: '8px', top: '6px', bottom: '0px' }}><TriangleDownIcon /></Box>}

                                    >
                                        {priorityArray[selectedPriority].name}
                                    </Button>

                                    {/* <TextInput
                                        disabled={!isDataEditable}
                                        sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            borderRadius: '6px',
                                            width: '170px',
                                            height: '32px',
                                            minHeight: '32px',
                                            maxHeight: '32px',
                                            minWidth: '170px',
                                            maxWidth: '170px',
                                        }}
                                        placeholder="Select Priority"
                                        value={priorityArray[selectedPriority].name}
                                    /> */}


                                    {/* <Button
                                        disabled={!isDataEditable}
                                        variant="invisible"
                                        sx={{
                                            height: '20px',
                                            position: 'relative',
                                            margin: '0px 0px 0px 0px',
                                            color: '#121212',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            lineHeight: '16px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}

                                        leadingIcon={() =>

                                            priorityArray[selectedPriority].name === "Normal" ?
                                                <img src={normalIcon} style={{ display: 'flex' }} /> :
                                                priorityArray[selectedPriority].name === "Medium" ?
                                                    <img src={mediumIcon} style={{ display: 'flex' }} /> :
                                                    priorityArray[selectedPriority].name === "High" ?
                                                        <img src={highIcon} style={{ display: 'flex' }} /> :
                                                        priorityArray[selectedPriority].name === "Critical" ?
                                                            <img src={criticalIcon} style={{ display: 'flex' }} /> :
                                                            priorityArray[selectedPriority].name === "Low" ?
                                                                <img src={lowIcon} style={{ display: 'flex' }} />
                                                                : ""
                                        }
                                    >
                                        {priorityArray[selectedPriority].name}
                                    </Button> */}
                                </ActionMenu.Anchor>
                                <ActionMenu.Overlay>
                                    <ActionList
                                        selectionVariant="single"
                                        sx={{
                                            width: '215px'
                                        }}

                                    >
                                        {priorityArray.map((type, index) => (
                                            <ActionList.Item

                                                sx={{
                                                    width: '184px',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    lineHeight: '16px',
                                                    color: '#333238'
                                                }}
                                                key={index}

                                                onSelect={() => {
                                                    setselectedPriority(index)
                                                    updateTaskPriorityToDb(type.name)
                                                }
                                                }>
                                                <ActionList.LeadingVisual>


                                                    {type.name === "Normal" ?
                                                        // <img src={normalIcon} /> 
                                                        <Box sx={{
                                                            width: '12px',
                                                            height: '12px',
                                                            minWidth: '12px',
                                                            maxWidth: '12px',
                                                            minHeight: '12px',
                                                            maxHeight: '12px',
                                                            borderRadius: '50%',
                                                            background: '#428FDC',
                                                            // border:'1px solid #428FDC' 
                                                        }}></Box>
                                                        :
                                                        type.name === "Medium" ?
                                                            <img src={mediumIcon} /> :
                                                            type.name === "High" ?
                                                                <img src={highIcon} /> :
                                                                type.name === "Critical" ?
                                                                    <img src={criticalIcon} /> :
                                                                    type.name === "Low" ?
                                                                        <img src={lowIcon} />
                                                                        : ""}

                                                </ActionList.LeadingVisual>
                                                {type.name}
                                            </ActionList.Item>
                                        ))}
                                    </ActionList>
                                </ActionMenu.Overlay>
                            </ActionMenu>

                    </Box>

                </Box>

            </Box>

        </Box>

            <Dialog
                isOpen={subtaskCompletedOpenClose}
                onDismiss={() => { }}
                sx={{
                    height: '100px',
                    minHeight: '100px',
                    maxHeight: '100px',
                    width: '554px',
                    minWidth: '554px',
                    maxWidth: '554px',
                    background: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop:'98px',
                  
                }}
            >

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    background: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow:'0px 4px 12px rgba(27, 31, 35, 0.15)',
                    borderRadius:'3px'
                }}>


                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            top: '13px',
                            left: '20px',
                            right: '29px',
                            margin: 'auto',
                            width: '505px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292E',
                        }}>
                        Subtask completed!. If you need to mark it as complete, please use the button
                    </Text>

                    <Box sx={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                    }}>

                        <Button
                            variant="invisible"
                            sx={{
                                width: '72px',
                                height: '32px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={()=>{
                                 closeconfirmCompletedSubtaskDialog("")
                            }}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="outline"
                            sx={{
                                width: '107px',
                                height: '32px',
                                background: '#0965CE',
                                color: 'white',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={()=>{  moveTaskToCompleted("") }}
                        >
                            Completed
                        </Button>

                    </Box>

                </Box>






            </Dialog>


    </Box>

    



    
    )
}