import Header from "../Header"
import { useNavigate } from 'react-router-dom';
import { Box, Text, TextInput, Button, Heading, Select } from "@primer/react"
import SignIntheme from "./SignIntheme"
import React from "react"
import { Auth,Hub } from 'aws-amplify';
import LoadingSpinner from "../LoadingSpinner";

function EmailVerification() {

    const navigate = useNavigate();

    const [showSpinner,setshowSpinner] = React.useState(false)

    const [verificationCodeError,setverificationCodeError]  = React.useState("")

    const [authEmail,setauthEmail] = React.useState("")
    const [authPassword,setAuthPassword] = React.useState("")

    const [verificationCodeValidation,setverificationCodeValidation] = React.useState("")
    const [verificationCode, setverificationCode] = React.useState("")
    const [isEmailVerified,setisEmailVerified] = React.useState(false)

    
    const resendSignUp =async (authEmail) => {

         const resenCode =  await Auth.resendSignUp(authEmail);
          
    }

    const handleVerificationCodeSubmitBtn = async (userEmail, verificationCode) => {
 
           if(verificationCode===""){
            setverificationCodeValidation("error")
           }else{
          
          
              setshowSpinner(true) 
 
               const confirmSignUp = await Auth.confirmSignUp(authEmail,verificationCode)
               console.log("confirmSignup",confirmSignUp)
               if(confirmSignUp==="SUCCESS"){

                  setverificationCodeError("")

                  //---------- redirect to initialization --------------

                  const confirmSignIn = await Auth.signIn(authEmail,authPassword)
                  
                  navigate('/initialization')

                  

               
               }else{

                   setverificationCodeError("Invalid verification code")
               }

           }       
    }



    const handleSubmitBtn =async (userEmail, verificationCode) => {

        const confirmSignIn = await Auth.signIn(authEmail,authPassword)

        navigate('/workplace')

       
       

    }
    const emailVerificationComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Heading sx={SignIntheme.loginPageBoxHeading}>Check your email</Heading>
        {verificationCodeError==="" ? "": 
                    
                    <Text 
                     sx={SignIntheme.signupUserExits} 
                     color="danger.fg">
                         {verificationCodeError}
                          
                     </Text>
                 
       }
        <Text
            sx={SignIntheme.emailverificationSubpara}
            as="p">
            We've sent you an email with a 6-digit verification code.
            Please check your inbox at
            <Text
                sx={SignIntheme.emailverificationSubpara.email}
            >{authEmail}</Text>
        </Text>
        <TextInput
            validationStatus={verificationCodeValidation}
            sx={SignIntheme.verificationCodeInput}
            name="verificationCode"
            placeholder="Enter verification code"
            onChange={(e)=> setverificationCode(e.target.value)}
            type="number"
        />
        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handleVerificationCodeSubmitBtn("", verificationCode)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}
            >

                Continue with verification code
            </Text>
        </Button>

        <Box
            sx={SignIntheme.verificationloginBoxBottomBox}

        >
            <Text
                as="p"
                sx={SignIntheme.loginBoxBottomBox.signUpText}
                onClick={()=> resendSignUp(authEmail) }
            >
                Resend code
            </Text>



        </Box>


    </Box>

    const emailVerificationSuccessComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
    <Text sx={SignIntheme.successpageImoge} >🎉</Text>
    <Heading sx={SignIntheme.EmailVerificationPageBoxHeading}>Your account successfully verified</Heading>

    <Text
        sx={SignIntheme.emailverificationSuccessSubpara}
        as="p">Tell us a bit about yourself.</Text>



    <Box
        sx={SignIntheme.workDropDown}
    >

        <Select
            style={SignIntheme.workDropDown.select}
            block="true"
            placeholder="What kind of work do you do?"
        >
            <Select.Option value="Finance">Finance </Select.Option>
            <Select.Option value="Architecture">Architecture</Select.Option>
            <Select.Option value="Sales & Marketing">Sales & Marketing</Select.Option>
            <Select.Option value="Customer service">Customer service</Select.Option>
            <Select.Option value="Product">Product</Select.Option>
            <Select.Option value="Engineering">Engineering</Select.Option>
            <Select.Option value="Business Administration">Business Administration</Select.Option>
            <Select.Option value="Operations">Operations</Select.Option>
            <Select.Option value="Design">Design</Select.Option>
            <Select.Option value="Law & Public Policy">Law & Public Policy</Select.Option>
            <Select.Option value="Human Resource">Human Resource</Select.Option>
            <Select.Option value="IT">IT</Select.Option>
            <Select.Option value="Government">Government</Select.Option>
            <Select.Option value="Educator">Educator</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
       
        </Select>

    </Box>
    <Box
        sx={SignIntheme.discoverDropDown}
    >

        <Select
            style={SignIntheme.discoverDropDown.select}
            block="true"
            placeholder="How did you discover deski?"
        >
            <Select.Option value="Google">Google</Select.Option>
            <Select.Option value="Facebook">Facebook</Select.Option>
            <Select.Option value="Twitter">Twitter</Select.Option>
            <Select.Option value="Product Hunt">Product Hunt</Select.Option>
            <Select.Option value="Youtube">Youtube</Select.Option>
            <Select.Option value="Instagram">Instagram</Select.Option>
            <Select.Option value="Linkedin">Linkedin</Select.Option>
            <Select.Option value="Online search">Online search</Select.Option>
            <Select.Option value="Recommended ( Friend, Co-worker, Community )">Recommended ( Friend, Co-worker, Community )</Select.Option>
            <Select.Option value="Newsletter">Newsletter</Select.Option>
            <Select.Option value="Blog post">Blog post</Select.Option>
           
        
        </Select>

    </Box>



    <Button
        variant="outline"
        type="button"
        aria-selected={true}
        sx={SignIntheme.continueBtn}
        onClick={() => handleSubmitBtn("", "")}
    >
        <Text
            as="p"
            color="#FFFFFF"
            sx={SignIntheme.continueBtnText}

        >

            Continue to workspace
        </Text>
    </Button>




</Box>

    React.useState(()=>{

          setauthEmail(localStorage.getItem("email"))
          setAuthPassword(localStorage.getItem("password")||"")
                    
    })

    return (

        <Box>
           <LoadingSpinner showSpinner={showSpinner} />
            <Header />

            <Box sx={SignIntheme.loginMain}>
                 {isEmailVerified===false ? emailVerificationComponent: emailVerificationSuccessComponent}
            </Box>

        </Box>

    )

}
export default EmailVerification
