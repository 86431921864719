import React from "react";
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, Text, TextInput, Link, Button, Dialog, Select, Tooltip, PointerBox, FormControl, Checkbox, Avatar, Autocomplete, ActionList } from "@primer/react"
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import EmptyData from "./EmptyData";
import CreateTask from "./CreateTask";
import { InfoCircledIcon, Pencil1Icon, UploadIcon } from "@radix-ui/react-icons";
import { CheckIcon, FeedPersonIcon } from "@primer/octicons-react";
import { useDispatch, useSelector } from "react-redux";
import { bulkComplianceDeadLineUpdate, getProjectServices, getSingleWorkplaceProject, postNewProjectWorkFlow, updateProjectCompliaceTrigger, updateProjectName, updateProjectWorkflowName, updateWorkingHours, uploadWorkflowImport } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position,ProgressBar } from "@blueprintjs/core";
import Papa from 'papaparse';

const uploadingToaster = Toaster.create({position: Position.TOP })

function Settings(props) {

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const dispatch = useDispatch()

    const [showWorkingHourBtn,setshowWorkingHourBtn] = React.useState(false)
    const [workingHours,setworkingHours] = React.useState({
          startTime:'',
          startTimeError:'',
          endTime:'',
          endTimeError:''
    })
    const [showEditBtn,setshowEditBtn] = React.useState(false)
    const [creatorData,setcreatorData] = React.useState([])
    const [showComplianceActionList,setshowComplianceActionList] = React.useState({
         isShown:false,
         inputValue:'',
         selectedCompId:''
    })
    const dueDatecalandarRef = React.createRef()
    const [dueDate,setdueDate] = React.useState("")
    const [showUpdateBtn,setshowUpdateBtn] = React.useState(false)

    const [triggerType,settriggerType] = React.useState("")
    const [compliances,setcompliances] = React.useState([])
    //------  Autocomplete -------------------------------
    
    //----------------------------------------------------
    const workflowNameInputRef = React.createRef()
    const spaceInputRef = React.createRef(null)
    const [addWorkflowDialog,setaddWorkflowDialog] = React.useState(false)
    const [workflow,setworkflow] = React.useState([])

    const [hoveredId,setHoveredId] = React.useState("")

    const [spaceInput, setSpaceInput] = React.useState({
        disableInput: true,
        isFocus: false,
        placeholder: '',
        isLoading: false,
        showTrailingVisual: false,
        validationStatus: '',
        value: localStorage.getItem("projectName") || ''
    })

    const [workflowNameInput,setworkflowNameInput] = React.useState({
        value:'',
        validationStatus:'',
        workflowEditId:''
    })

    const [selectedPosition,setselectedPosition] = React.useState("")

    const onChangeSelectCompliance = (props) => {

         setshowComplianceActionList((prevState)=>{
              
              const newState = {...prevState,isShown:true,inputValue:props}
              return newState
         })
        
    }

    const bulkComplaiceDeadLineUpdateInDb = (complianceId,dueDate) => {

        if (complianceId === "") {
            Toaster.create({ position: Position.TOP }).show({
                message: "Select compliance",
                intent: Intent.PRIMARY
            });

        } else if (dueDate === "") {

            Toaster.create({ position: Position.TOP }).show({
                message: "Select duedate",
                intent: Intent.PRIMARY
            });

        } else {

               
            dispatch(bulkComplianceDeadLineUpdate({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                complianceId: complianceId,
                dueDate: dueDate
            })).then((data) => {

                console.log("bulkComplianceDeadLineUpdate", data.payload)
                setshowComplianceActionList({
                    isShown: false,
                    inputValue: '',
                    selectedCompId: ''
                })
                setshowUpdateBtn(false)
                setdueDate("")

                if (data?.payload?.data?.length > 0) {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Bulk Compliance deadline update successfully",
                        intent: Intent.SUCCESS
                    });

                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "No Data Found",
                        intent: Intent.PRIMARY
                    });

                }

            })



           

        }
            
        
    } 

    const showAddWorkflowDialog = (props) => {

        setaddWorkflowDialog(true)

    }

    const hideAddWorkflowDialog = (props) => {

        setaddWorkflowDialog(false)
        setworkflowNameInput((prevState)=>{
            return({...prevState,value:'',validationStatus:'',workflowEditId:''})
        })
        setselectedPosition("")
    }


    const handleChangeSpaceName = (e) => {

        setSpaceInput((prevInput) => {

            const newInput = { ...prevInput, value: e.target.value, isLoading: true }
            return newInput
        })

        updateSpaceNameInDb(e.target.value)

 

    }

    const handleChangeWorkingHours = (props,value) => {
 
            
             if(props==="startingHour"){
                    setshowWorkingHourBtn(true)
                    setworkingHours((prevState)=>{
                        return({...prevState,startTime:value,startTimeError:''})
                    })
             }else if(props==="endingHour"){
                    setshowWorkingHourBtn(true)
                    setworkingHours((prevState)=>{
                        return({...prevState,endTime:value,endTimeError:''})
                    })
             }

    }

    const updateWorkingHourInDb = (props) => {

        if (workingHours.startTime === '') {

            setworkingHours((prevState) => {
                return ({ ...prevState, startTimeError: 'error' })
            })

        } else if (workingHours.endTime === '') {

            setworkingHours((prevState) => {
                return ({ ...prevState, endTimeError: 'error' })
            })

        } else {

            setworkingHours((prevState) => {
                return ({ ...prevState, startTimeError: '',endTimeError:'' })
            })
            setshowWorkingHourBtn(false)
             
            if (parseInt(workingHours.endTime.split(":")[0]) < parseInt(workingHours.startTime.split(":")[0])) {
               
                Toaster.create({ position: Position.TOP }).show({
                    message: "Starting Hour should be greater than Ending  Hours",
                    intent: Intent.PRIMARY
                });

            }else{

              
               const newWorkingHour = {
                startTime:workingHours.startTime,
                endTime:workingHours.endTime,
               } 


               dispatch(updateWorkingHours({
                   userId:userId,
                   userEmail:userEmail,
                   projectId:localStorage.getItem("projectId"),
                   workingHours:[newWorkingHour]
               })).then((data)=>{

                       console.log("updateWorkingHours",data.payload)
                       if(data.payload.data.length > 0 ){


                          Toaster.create({ position: Position.TOP }).show({
                            message: "Sucessfully Updated",
                            intent: Intent.SUCCESS
                          });
                       }        

               }) 
 


                 
            }

        }

    }

    const postNewWorkflowIndb = (props) => {

              if(workflowNameInput.value===""){

                    setworkflowNameInput((prevState)=>{
                        return({...prevState,validationStatus:'error'})
                    })

              }
              else if(workflow.length===10){

                  hideAddWorkflowDialog("")
                  Toaster.create({ position: Position.TOP }).show({
                      message: "Maximum 10 workflow",
                      intent: Intent.PRIMARY
                  });
     
              }
              else if(workflow.filter((data)=> data.workflow_name===workflowNameInput.value).length>0){

                    

                    hideAddWorkflowDialog("")
                    if(workflowNameInput.workflowEditId===""){


                    }else{

                         const oldFlowStatge = workflow.filter((wData)=> wData.workflow_id===workflowNameInput.workflowEditId )[0]?.flow_stage
                     

                         dispatch(updateProjectWorkflowName({
                             userId: userId,
                             userEmail: userEmail,
                             projectId: localStorage.getItem("projectId"),
                             workflowId: workflowNameInput.workflowEditId,
                             workflowName: workflowNameInput.value,
                             flowStage: selectedPosition
                         })).then((data) => {
                             setworkflow(data.payload.data)
                             hideAddWorkflowDialog("")

                             if (oldFlowStatge !== selectedPosition) {
                                 Toaster.create({ position: Position.TOP }).show({
                                     message: "Workflow stages have been changed.",
                                     intent: Intent.PRIMARY
                                 });

                             } else {
                                //  Toaster.create({ position: Position.TOP }).show({
                                //      message: "Workflow successfully updated.",
                                //      intent: Intent.PRIMARY
                                //  });

                             }


                         })

                     }



              }else{





                    if(workflowNameInput.workflowEditId===""){

                        dispatch(postNewProjectWorkFlow({
                            userId: userId,
                            userEmail: userEmail,
                            projectId: localStorage.getItem("projectId"),
                            workflowName: workflowNameInput.value
                        })).then((data) => {


                            Toaster.create({ position: Position.TOP }).show({
                                message: "A new workflow has been created.",
                                intent: Intent.SUCCESS
                            });
                            setworkflow(data.payload?.result)
                            hideAddWorkflowDialog("")

                        })

                    }else{


                        const oldFlowStatge = workflow.filter((wData)=> wData.workflow_id===workflowNameInput.workflowEditId )[0]?.flow_stage
                        
                        

                        dispatch(updateProjectWorkflowName({
                            userId: userId,
                            userEmail: userEmail,
                            projectId: localStorage.getItem("projectId"),
                            workflowId: workflowNameInput.workflowEditId,
                            workflowName: workflowNameInput.value,
                            flowStage: selectedPosition
                        })).then((data) => {

                              // alert(oldFlowStatge)


                            if(oldFlowStatge!==selectedPosition){
                                Toaster.create({ position: Position.TOP }).show({
                                    message: "Workflow stages have been changed.",
                                    intent: Intent.PRIMARY
                                });

                            }else{
                                Toaster.create({ position: Position.TOP }).show({
                                    message: "Workflow successfully updated.",
                                    intent: Intent.PRIMARY
                                });

                            }

                           

                           
                            setworkflow(data.payload.data)
                            hideAddWorkflowDialog("")
                        })
          


                          
                    }

                     

                   



              }


    }
    
    
      

     

    const updateSpaceNameInDb = (props) => {

        dispatch(updateProjectName({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            projectName: props
        })).then((data) => {

            localStorage.setItem("projectName", props)

            setTimeout(() => {

                setSpaceInput((prevInput) => {

                    const newInput = { ...prevInput, showTrailingVisual: true, isLoading: false }
                    return newInput
                })

            }, 1000)

            setTimeout(() => {
                setSpaceInput((prevInput) => {

                    const newInput = { ...prevInput, showTrailingVisual: false }
                    return newInput
                })
            }, 5000)

            Toaster.create({ position: Position.TOP }).show({
                message: "Successfully updated project name.",
                intent: Intent.PRIMARY
            });

            
        })



    }

    const getCreatorDataFromDb = async (props) => {

        var myHeaders = new Headers();
        myHeaders.append('Content-type', 'application/json')

        const urlencoded = JSON.stringify({
            "userId": props,
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const result = await fetch("https://api.deski.co/getUserProfile", requestOptions)
            .then(response => response.json())
        setcreatorData(result?.data)
    }

    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)

        })

    }

    const updateProjectCompliaceTriggerInDb = (props) => {

        dispatch(updateProjectCompliaceTrigger({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            complianceTriggerType: props
        })).then((data) => {

            const message = data?.payload?.message
            const resCompArray = [...data?.payload?.resCompArray ]
            console.log("resCompArray",resCompArray)
            if (resCompArray.length > 0) {
                Toaster.create({ position: Position.TOP }).show({
                    message: message,
                    intent: Intent.PRIMARY
                });
            } else {

            }
            

        })

    }


    React.useEffect(()=>{

         if(addWorkflowDialog===true){

              workflowNameInputRef.current.focus()

              



         }


    },[addWorkflowDialog])


    React.useEffect(() => {

        if (spaceInput.disableInput === false) {

            spaceInputRef?.current.focus()



        }


    }, [spaceInput.disableInput])

    React.useEffect(() => {

        if (spaceInput.value.length > 70) {
            setSpaceInput((prevInput) => {

                const newInput = { ...prevInput, validationStatus: 'error' }
                return newInput
            })
        } else {
            setSpaceInput((prevInput) => {

                const newInput = { ...prevInput, validationStatus: '' }
                return newInput
            })
        }

    }, [spaceInput.value])


    React.useState(() => {

        getAllCategories("")
        const projectId = localStorage.getItem("projectId")
        dispatch(getSingleWorkplaceProject({
            userId: userId,
            userEmail: userEmail,
            projectId: projectId,
        })).then((data) => {

            console.log("settings getSingleWorkplaceProject", data.payload)
            setworkflow(data.payload?.data[0]?.workflow)
            setSpaceInput((prevState) => {

                const newState = { ...prevState, value: data.payload.data[0].project_name }
                return newState

            })

            //------- get WorkingHours from db
            const workingHoursArray = [...data.payload?.data[0]?.working_hours]
            setworkingHours((prevState) => {
                return ({ ...prevState, startTime: workingHoursArray[0]?.startTime || '', endTime: workingHoursArray[0]?.endTime || '' })
            })

            const creatorId = data?.payload?.data[0]?.creator_id
            getCreatorDataFromDb(creatorId)
            

        })

    })




    return (
        <Box
            position={"relative"}
            display="flex"
            flexDirection={"column"}
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet>
                <title>
                   Organization settings - deski
                </title>
            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />

           
            <Box sx={{
                position: 'relative',
                margin: '29px 65px 0px 65px',
                minHeight: '68px',
                maxHeight: '68px',
                height: '68px',
                display: 'flex',

            }}>

                <Text
                    sx={{
                        position: 'relative',
                        margin: '48px 0px 0px 26px',
                        fontFamily: 'Segoe UI',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        fontSize: '17px',
                        lineHeight: '20px',
                        color: '#24292F'
                    }}

                >
                    Organization settings 
                   
                </Text>
                

            </Box>


            <Box sx={{
                position: 'relative',
                margin: '0px 65px 0px 65px',
                display: 'flex',

            }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        position: 'relative',
                        margin: '31px 0px 0px 26px'
                    }}
                    onMouseEnter={()=>{
                         setshowEditBtn(true)
                    }}
                    onMouseLeave={()=>{
                         setshowEditBtn(false)
                    }}
                >

                    <TextInput
                        ref={spaceInputRef}
                        autoFocus={spaceInput.isFocus}
                        disabled={spaceInput.disableInput}
                        sx={{
                            display: 'flex',
                            width: '516px',
                            maxWidth: '516px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#121212'
                        }}
                        value={spaceInput.value}
                        onChange={(e) => handleChangeSpaceName(e)}
                        onBlur={(e) => {
                            updateSpaceNameInDb(e.target.value)

                            setSpaceInput((prevInput) => {
                                const newInput = { ...prevInput, disableInput: true, isFocus: false, isLoading: false }
                                return newInput
                            })
                        }

                        }
                        loading={spaceInput.isLoading}
                        trailingVisual={spaceInput.showTrailingVisual === true ? CheckIcon : ''}
                        validationStatus={spaceInput.validationStatus}
                    />


                    {showEditBtn === true ?

                        <IconButton
                            sx={{
                                height: '30px',
                                width: '30px',
                                display: spaceInput.disableInput === true ? 'flex' : 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                border: 'none',
                                boxShadow: 'none'
                            }}
                            icon={() => <Pencil1Icon style={{ display: 'flex' }} />}
                            onClick={() => {
                                setSpaceInput((prevInput) => {

                                    const newInput = { ...prevInput, disableInput: false, isFocus: true }
                                    return newInput
                                })
                                

                            }}
                        />

                        : ""}
 
                    



                </Box>




            </Box>

            <Box sx={{
                position:'relative',
                margin:'40px 0px 0px 90px',
                minHeight:'600px',
                minWidth:'717px',
                maxWidth:'717px',
                width:'717px'
            }}>

                <Box 
                sx={{
                    display:'flex',
                    flexDirection:'row',
                    minHeight:'32px',
                    maxHeight:'32px',
                    height:'32px',
                    width:'717px',
                    minWidth:'717px',
                    maxWidth:'717px',
                    
                }}>
                    <Text sx={{
                        position: 'relative',
                        margin: 'auto 0px -7px 2px',
                        fontFamily:'Avenir LT Std',
                        fontStyle:'normal',
                        fontWeight:'600',
                        fontSize:'16px',
                        color:'#24292F',
                        height:'fit-content'
                    }}>
                        Workflow
                    </Text>

                    <Button sx={{
                        width: '58px',
                        height: '32px',
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#24292F',
                        boxShadow: 'none',
                        background: '#F6F8FA'
                    }}
                        onClick={() => { 
                            showAddWorkflowDialog("")
                        }}
                    >
                        Add
                    </Button>

                </Box>


                <Box sx={{
                    position:'relative',
                    margin:'14px 0px 0px 0px',
                    minHeight:'1px',
                    maxHeight:'1px',
                    height:'1px',
                    width:'717px',
                    minWidth:'717px',
                    maxWidth:'717px',
                    borderBottom: 1, 
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#E7E7ED'}}>
                    
                </Box>

                <Box sx={{
                    position:'relative',
                    margin:'14px 0px 0px 0px',
                    width:'717px',
                    minWidth:'717px',
                    maxWidth:'717px',
                    display:'flex',
                    flexDirection:'column',
                     
                }}>
                    {
                        workflow.map((row,index)=>{
                             return(
                                <Box sx={{
                                    width:'717px',
                                    minWidth:'717px',
                                    maxWidth:'717px',
                                    minHeight:'50px',
                                    maxHeight:'50px',
                                    height:'50px',
                                    display:'flex',
                                    flexDirection:'column',
                                    ":hover":{
                                         cursor:'pointer'
                                    }
                                    }}
                                    onMouseEnter={()=>{
                                         setHoveredId(row.workflow_id)
                                    }}
                                    onMouseLeave={()=>{
                                         setHoveredId("")
                                    }}
                                >

                                     <Box sx={{
                                        minHeight:'30px',
                                        height:'30px',
                                        maxHeight:'30px',
                                        width:'717px',
                                        minWidth:'717px',
                                        maxWidth:'717px',
                                        display:'flex',
                                        flexDirection:'row',
                                        alignItems:'center',
                                        marginTop:'10px'
                                     }}>
                                        <Text sx={{
                                            position:'relative',
                                            margin:'0px 0px 0px 8px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color:'#24292E',
                                            height:'fit-content'
                                        }}>
                                          {index+1}
                                        </Text>
                                        <Text sx={{
                                            minWidth:'511px',
                                            maxWidth:'511px',
                                            width:'511px',
                                            position:'relative',
                                            margin:'0px 0px 0px 30px',
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color:'#24292E',
                                            height:'fit-content'
                                        }}>
                                          {row.workflow_name}
                                          
                                        </Text>


                                          {row?.status==="Custom"?

                                             
                                                 <IconButton


                                                     sx={{
                                                         height: '30px',
                                                         width: '30px',
                                                         display: hoveredId === row.workflow_id ? 'flex' : 'none',
                                                         alignItems: 'center',
                                                         justifyContent: 'center',
                                                         position: 'relative',
                                                         margin: '0px 0px 0px auto',
                                                         border: 'none',
                                                         boxShadow: 'none'
                                                     }}
                                                     icon={() => <Pencil1Icon style={{ display: 'flex' }} />}
                                                     onClick={() => {

                                                          
                                                         setworkflowNameInput((prevState) => {
                                                             return ({ ...prevState, value: row.workflow_name, validationStatus: '', workflowEditId: row.workflow_id })
                                                         })
                                                         setselectedPosition(row.flow_stage)
                                                         showAddWorkflowDialog("")

                                                     }}
                                                 />
                                            
                                          
                                          :
                                             <Tooltip

                                                 sx={{
                                                     position: 'relative',
                                                     margin: '0px 0px 0px auto',
                                                     borderRadius:'6px',
                                                     ":before":{
                                                        border:'none'
                                                      },
                                                     ":after": {
                                                         color: '#24292F',
                                                         background: '#FFF8C5',
                                                         borderRadius:'6px',
                                                         fontFamily: 'Segoe UI',
                                                         fontStyle: 'normal',
                                                         fontWeight: '400',
                                                         fontSize: '12px',
                                                         
                                                     },


                                                 }}
                                                 aria-label="This workflow cannot be edited as any changes will affect the system"
                                                 direction="se"

                                             >
                                                 <IconButton
                                                     sx={{
                                                         height: '30px',
                                                         width: '30px',
                                                         display: hoveredId === row.workflow_id ? 'flex' : 'none',
                                                         alignItems: 'center',
                                                         justifyContent: 'center',
                                                         // position: 'relative',
                                                         // margin: '0px 0px 0px auto',
                                                         border: 'none',
                                                         boxShadow: 'none'
                                                     }}
                                                     icon={() => <InfoCircledIcon style={{ display: 'flex' }} />}
                                                     onClick={() => { }}
                                                 />
                                             </Tooltip>
                                          }

                                        
                                        
                                     </Box>

                                    <Box sx={{
                                        position:'relative',
                                        margin:'auto 0px 0px 48px',
                                        borderBottom:'1px solid #E1E6EA',
                                       
                                        
                                    }}>

                                    </Box>
                                    
                                </Box>
                             )
                        })
                    }

                </Box>

                <Box sx={{
                    position: 'relative',
                    margin: '80px 0px 0px 0px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth:'1200px',
                    minHeight:'200px'
                }}>

                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily:'Inter',
                        minHeight:'32px',
                        alignItems:'center'
                    }}>
                        <Text sx={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#24292F'
                        }}>
                            Recurring Task automation settings
                        </Text>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            position:'absolute',
                            left:'359px',
                            alignItems:'center'
                        }}>
                            <Box as="form" sx={{
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}>
                                <FormControl>
                                    <Checkbox value="default" checked={triggerType === "automatic" ? true : false}
                                        onChange={(e) => {
                                            settriggerType("automatic")
                                            updateProjectCompliaceTriggerInDb("automatic")
                                        }} />
                                    <FormControl.Label>Automatic</FormControl.Label>
                                </FormControl>
                            </Box>
                            <Box as="form" sx={{
                                marginLeft: '82px',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#57606A'
                            }}>
                                <FormControl>
                                    <Checkbox value="default" checked={triggerType === "manual" ? true : false}
                                        onChange={(e) => {
                                            settriggerType("manual")
                                            updateProjectCompliaceTriggerInDb("manual")
                                        }} />
                                    <FormControl.Label>Manual</FormControl.Label>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginLeft: '36px' }}>
                                {creatorData.length > 0 && creatorData[0]?.user_image_url !== null ?
                                    <Avatar
                                        src={creatorData[0]?.user_image_url}
                                    />

                                    :

                                    <FeedPersonIcon
                                        size={32}
                                        fill="#586069"

                                    />
                                }
                            </Box>
                             
                            <Text sx={{ fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: '20px', marginLeft: '13px' }}>
                              
                                {creatorData.length>0? creatorData[0].user_first_name+" "+creatorData[0].user_last_name :""}
                            </Text>
                        </Box>

                    </Box>
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        fontFamily:'Inter',
                        marginTop:'75px'
                    }}>
                        <Text sx={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: '#24292F'
                        }}>
                            Bulk Complince deadline update
                        </Text>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            position:'absolute',
                            left:'359px',
                         }}>
                            <Box as="form" sx={{
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}>
                                <TextInput sx={{fontSize:'14px',fontWeight:'400',lineHeight:'20px',fontStyle:'normal'}} placeholder="Select Compliance"
                                value={showComplianceActionList.inputValue}
                                onChange={(e)=>{
                                      onChangeSelectCompliance(e.target.value)
                                }}
                                />
                                <Box

                                    sx={{
                                        visibility: showComplianceActionList.isShown === true ? 'visible' : 'hidden',
                                        zIndex: '1',
                                        position: 'absolute',
                                        left: '0px',
                                        top: '40px',
                                        minHeight: 'auto',
                                        maxHeight: '244px',
                                        maxWidth:'225px',
                                        minWidth:'225px',
                                        width:'225px',
                                        height: 'auto',
                                        boxShadow: '0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)',
                                        borderRadius: '6px',
                                        background: '#FFFFFF',
                                        overflowX: 'hidden',
                                        overflowY: 'auto',
                                       
                                    }} >
                                    
                                    <ActionList sx={{

                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '2px',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292F'

                                    }}>
                                        {compliances
                                            .filter((tData) => tData.subcategory.compliance_name?.toLowerCase().includes(showComplianceActionList.inputValue?.toLowerCase()))
                                            .map((row, index) => {
                                                return (
                                                    <ActionList.Item
                                                        onSelect={() => {

                                                            setshowComplianceActionList((prevState)=>{
                                                                  const newState = {...prevState,
                                                                    inputValue:row.subcategory.compliance_name,
                                                                    isShown:false,
                                                                    selectedCompId:row.service_id
                                                                   }
                                                                  return newState
                                                            })

                                                        }}
                                                    >
                                                        {row.subcategory.compliance_name}
                                                    </ActionList.Item>
                                                )
                                            })}




                                    </ActionList>

                                </Box>   
                            </Box>
                            <Box as="form" sx={{
                                marginLeft: '24px',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#57606A'
                            }}>
                                <TextInput sx={{fontSize:'14px',fontWeight:'400',lineHeight:'20px',fontStyle:'normal'}} placeholder="Select date"
                                    onChange={(e) => {

                                        dueDatecalandarRef.current.showPicker()
                                    }}
                                    onClick={() => {
                                        dueDatecalandarRef.current.showPicker()
                                    }}
                                    value={dueDate !== null && dueDate !== "" ?
                                        new Date(dueDate).getDate() + "/" + (new Date(dueDate).getMonth() + 1) + "/" + new Date(dueDate).getFullYear()
                                        : ''}
                                />
                                <input
                                    type={"date"}
                                    ref={dueDatecalandarRef}
                                    onChange={(e) => {
                                        if (e.target.value === "" || e.target.value == null) {
                                            setdueDate("")
                                            setshowUpdateBtn(false)
                                        }
                                        else {
                                            setdueDate(new Date(e.target.value))
                                            setshowUpdateBtn(true)
                                        }
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: '0px',
                                        right: '0px',
                                        bottom: '25px',
                                        height: '29px',
                                        width: '46%',
                                        zIndex: '0',
                                        visibility: 'hidden'
                                    }}

                                />

                            </Box>
                            <Button variant="outline"
                                sx={{
                                    position: 'relative',
                                    margin: 'auto 0px auto 18px',
                                    background: '#0965CE',
                                    color: '#ffffff',
                                    display: showUpdateBtn===true?'flex':'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: '600',

                                }}
                                 onClick={()=>{
                                      bulkComplaiceDeadLineUpdateInDb(showComplianceActionList.selectedCompId,dueDate)  
                                 }}
                            >
                                Update
                            </Button> 
                             
                           
                        </Box>

                    </Box>

                    <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Inter',
                    marginTop: '79px',
                   
                }}>
                    <Text sx={{
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        color: '#24292F'
                    }}>
                        Working Hour
                    </Text>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                position:'absolute',
                                left:'358px'
                            }}
                        >
                            <TextInput 
                            sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', fontStyle: 'normal' }} 
                            placeholder="Starting Hour"
                            onChange={(e) => { 
                                 
                                handleChangeWorkingHours("startingHour",e.target.value)
                              
                            }}
                            validationStatus={workingHours.startTimeError}
                            value={workingHours.startTime}
                            type="time"
                            />
                             <TextInput 
                            sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', fontStyle: 'normal' }} 
                            placeholder="Closing Hour"
                            onChange={(e) => { 
                                handleChangeWorkingHours("endingHour",e.target.value)
                                
                            }}
                            value={workingHours.endTime}
                            validationStatus={workingHours.endTimeError}
                            type="time"
                            />
                            <Button variant="outline"
                                sx={{
                                    position: 'relative',
                                    margin: 'auto 0px auto 18px',
                                    background: '#0965CE',
                                    color: '#ffffff',
                                    display: showWorkingHourBtn?'flex':'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    fontWeight: '600',

                                }}
                                onClick={() => {
                                    updateWorkingHourInDb("")
                                }}
                            >
                                Update
                            </Button> 


                        </Box>
                    
                    
                </Box>


                </Box>

                


               
            </Box>


           



             

           
            <ScheduleDemoDialog />
            <SearchDialog />

            <Dialog 
            isOpen={addWorkflowDialog}
            sx={{
                width:'564px',
                height:'300px',
                minWidth:'564px',
                maxWidth:'564px',
                minHeight:'300px',
                maxHeight:'300px',

            }}>

              <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    width: '564px',
                    height: '300px',
                    minWidth: '564px',
                    maxWidth: '564px',
                    minHeight: '300px',
                    maxHeight: '300px',
                    boxShadow:'0px 4px 12px rgba(27, 31, 35, 0.15)',
                    borderRadius:'6px',
                    background:'#ffffff'
              }}>

                    <Text sx={{
                        position:'absolute',
                        left:'16px',
                        top:'16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292E',
                    }}>
                        Add workflow
                    </Text>

                    <TextInput
                    ref={workflowNameInputRef}
                    sx={{
                       position:'absolute',
                       left:'16px',
                       top:'67px',
                       right:'16px',
                       minHeight:'40px',
                       height:'40px',
                       maxHeight:'40px',
                       fontFamily: 'Segoe UI',
                       fontStyle: 'normal',
                       fontWeight: '400',
                       fontSize: '14px',
                       lineHeight: '20px',
                    }}
                    placeholder="Enter the workflow name"
                    value={workflowNameInput.value}
                    validationStatus={workflowNameInput.validationStatus}
                    onChange={(e)=>{
                          setworkflowNameInput((prevState)=>{
                            return({...prevState,value:e.target.value,validationStatus:''})
                          })
                    }}
                    />

                    <Text sx={{
                        position: 'absolute',
                        left: '16px',
                        top: '133px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#24292E',
                    }}>
                        Workflow Position
                    </Text>

                    <Box sx={{
                        position: 'absolute',
                        left: '16px',
                        top: '159px',
                        width: '102px',
                        minWidth: '102px',
                        maxWidth: '102px',
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        
                    }}>
                    <Select
                        block
                        sx={{
                            width: '102px',
                            minWidth: '102px',
                            maxWidth: '102px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                        }}
                        onChange={(e)=>{
                            setselectedPosition(e.target.value)
                        }}
                        placeholder={workflowNameInput.workflowEditId===""?+(workflow.length-4+1)+"":""+selectedPosition+""}
                                        
                    >
                            {workflow.map((row, index) => {
                                return (

                                    <Select.Option
                                        value={index + 1}
                                        disabled={row?.status !== "Custom"}
                                        selected={row.flow_stage === selectedPosition}
                                    //  placeholder={workflowNameInput.workflowEditId===""?+(workflow.length-4+1)+"ass":""}

                                    >
                                        {index + 1}
                                    </Select.Option>
                                )
                            })}
                        
                        
                    </Select>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        bottom: '15px',
                        right: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                    }}>

                        <Button
                            variant="invisible"
                            sx={{
                                width: '72px',
                                height: '32px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={() => {
                                hideAddWorkflowDialog("")
                            }}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="outline"
                            sx={{
                                width: '78px',
                                height: '32px',
                                background: '#0965CE',
                                color: 'white',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={() => {
                                postNewWorkflowIndb("")
                            }}
                        >
                            Save
                        </Button>

                    </Box>





              </Box>

            </Dialog>



        </Box>
    )
}

export default Settings