import { Box, Heading, Label, StyledOcticon, Text} from "@primer/react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ArrowUpIcon, BookIcon } from "@primer/octicons-react"
import TaxHeroHeader from "./TaxHeroHeaderSanbox"
 
import { getUserOrganizationById } from "../redux/features/spaceSlice"
import React from "react"
import { checkUserSubscriptionStatus, getTaxheroUserSubscription, getUserSubscriptionData, openCloseTaxheroFreeTrialEndDialog } from "../redux/features/profileSlice"
const rightImage = "https://d2fcqkt9srr3ti.cloudfront.net/Template/rightColumnImg.svg"
const itImg = "https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Template/itImg.svg"
const treeImg = "https://d2fcqkt9srr3ti.cloudfront.net/Template/treeImg.svg"
const flyingImg = "https://d2fcqkt9srr3ti.cloudfront.net/Template/flyingImg.svg"
function TaxHeroPortalSanbox(props) {

    let host = window.location.host
    let hostName = window.location.hostname

    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const growsubscriptionstatus = useSelector((state) => state.profileData.growsubscriptionstatus)
    const taxHerosubscriptionstatus = useSelector((state) => state.profileData.taxHerosubscriptionstatus)

    const [subscriptionStatus, setsubscriptionStatus] = React.useState({
        grow: growsubscriptionstatus,
        taxHero: taxHerosubscriptionstatus
    })

    const [isOrgSetup, setisOrgSetup] = React.useState(false)

    const getUserDefaults = (props) => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getUserOrganizationById", data.payload)

            if (data?.payload?.data[0]?.verification === "true") {
                setisOrgSetup(true)
            } else {
                setisOrgSetup(false)
                navigate('/accountsetup')
            }

        })

    }

 
    const showFreeTrialEnd = (props) => {

        dispatch(openCloseTaxheroFreeTrialEndDialog({
            taxherofreetrialEndOpen: true,
        }))

    }

 

    const checkUserSubscriptionStatusFromApi = (props) => {

           dispatch(checkUserSubscriptionStatus({
               userId:userId,
               userEmail:userEmail
           })).then((data)=>{
               console.log("checkUserSubscriptionStatus",data.payload)

               const growsubscriptionstatus = data?.payload?.growsubscription?.list[0]?.subscription?.status
               const taxHerosubscriptionstatus = data?.payload?.taxHerosubscription?.list[0]?.subscription?.status || "0"
               setsubscriptionStatus((prevState) => {
                   return ({ ...prevState, grow: growsubscriptionstatus,taxHero:taxHerosubscriptionstatus })
               })

           
           })

    } 

 
    React.useState(() => {

        

        checkUserSubscriptionStatusFromApi("")

        getUserDefaults("")
       // getSubscriptionData("")
       // checkTaxHeroSubscription("")
        
    })

     

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


 
    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F6F6"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>

                

                <title>
                    {hostName === "www.app.taxherohq.com" ? 'Portal - taxhero' : 'Portal - deski'}
                </title>
            </Helmet>

            <TaxHeroHeader />

            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '64px',
                display: isOrgSetup?'flex':'none',
                flexDirection: 'column',


            }}>


                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: '0px auto 0px auto',
                    width: '1144px',
                    minWidth: '1144px',
                    maxWidth: '1144px',
                    minHeight: '100vh',

                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                        width: '1144px',
                        minWidth: '1144px',
                        maxWidth: '1144px',
                        minHeight: '225px',
                        maxHeight: '225px',
                        height: '225px',
                        marginTop: '28px'
                    }}>

                        <Box sx={{
                            width: '720px',
                            minWidth: '720px',
                            maxWidth: '720px',
                            minHeight: '225px',
                            maxHeight: '225px',
                            height: '225px',
                            background: '#2BEBC8',
                            border: '1px solid #D0D7DE',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'row',

                        }}>

                            <Box sx={{
                                width: '348px',
                                minWidth: '348px',
                                maxWidth: '348px',
                                minHeight: '201px',
                                maxHeight: '201px',
                                height: '201px',
                                position: 'relative',
                                margin: '12px 0px 12px 12px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px'
                            }}>

                                <Heading sx={{
                                    position: 'relative',
                                    margin: '24px 24px 0px 24px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '26px',
                                    color: '#24292E',
                                }}>
                                    Welcome to Taxhero
                                </Heading>
                                <Text sx={{
                                    position: 'relative',
                                    margin: '0px 24px 0px 24px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    Automates data fetching from Income tax, GST, and TDS portals. It offers a single dashboard where you can easily view and manage all your GST, Income Tax, and TDS notices, demand status, and return status.
                                </Text>


                            </Box>


                            <Box sx={{
                                width: '348px',
                                minWidth: '348px',
                                maxWidth: '348px',
                                minHeight: '201px',
                                maxHeight: '201px',
                                height: '201px',
                                position: 'relative',
                                margin: '12px 0px 12px 0px',

                            }}>
                                <img src={rightImage} >
                                </img>
                            </Box>






                        </Box>

                        <Box sx={{
                            width: '397px',
                            minWidth: '397px',
                            maxWidth: '397px',
                            minHeight: '225px',
                            maxHeight: '225px',
                            height: '225px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            border: '3px solid #ffffff',
                            cursor: 'pointer',
                            ":hover": {
                                border: '3px solid #0969DA'
                            }
                        }}
                            onClick={() => {
                                // navigate('/activity')
                            }}

                        >

                            <Box

                                sx={{
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    margin: '57px  auto auto auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '106px',
                                    width: '337px',
                                    borderRadius: '6px',

                                }}>

                                <Box sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px'
                                }}>
                                    <BookIcon size={24} />
                                </Box>



                                <Heading
                                    sx={{
                                        position: 'relative',
                                        margin: '16px 0px 0px 0px',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        color: '#24292E',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    Activity
                                </Heading>
                                <Text sx={{
                                    width: '337px',
                                    height: '40px',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: '#586069',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}>
                                    This feature has not been released yet. It will be available very soon.
                                </Text>


                            </Box>



                        </Box>



                    </Box>



                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                        width: '1144px',
                        minWidth: '1144px',
                        maxWidth: '1144px',
                        minHeight: '146px',
                        maxHeight: '146px',
                        height: '146px',
                        marginTop: '24px',


                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '343px',
                            minWidth: '343px',
                            maxWidth: '343px',
                            minHeight: '146px',
                            maxHeight: '146px',
                            height: '146px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            border: '3px solid #ffffff',
                            ":hover": {
                                border: '3px solid #0969DA'
                            }

                        }}
                            onClick={() => {

                                 
                                switch (subscriptionStatus.grow) {
                                    case "cancelled":
                                        if (subscriptionStatus.taxHero === "0" || subscriptionStatus.taxHero === "cancelled") {
                                            showFreeTrialEnd("");
                                        } else if (subscriptionStatus.taxHero === "active") {
                                            navigate('/itpanlistsanbox');
                                        }
                                        break;

                                    case "in_trial":
                                        if (subscriptionStatus.taxHero === "0") {
                                            navigate('/itpanlistsanbox');
                                        }
                                        break;

                                    case "active":
                                        if (subscriptionStatus.taxHero === "0") {
                                            showFreeTrialEnd("");
                                        } else if (subscriptionStatus.taxHero === "active") {
                                            navigate('/itpanlistsanbox');
                                        }
                                        break;

                                    default:
                                        break;
                                }

                                  

                                 
                            }}
                        >

                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    1450
                                </Text>
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    IT
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>


                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',

                            }}>

                                <img
                                    src={itImg}
                                >
                                </img>

                            </Box>


                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '343px',
                            minWidth: '343px',
                            maxWidth: '343px',
                            minHeight: '146px',
                            maxHeight: '146px',
                            height: '146px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            border: '3px solid #ffffff',
                            ":hover": {
                                border: '3px solid #0969DA'
                            }
                        }}
                            onClick={() => {


                                switch (subscriptionStatus.grow) {
                                    case "cancelled":
                                        if (subscriptionStatus.taxHero === "0" || subscriptionStatus.taxHero === "cancelled") {
                                            showFreeTrialEnd("");
                                        } else if (subscriptionStatus.taxHero === "active") {
                                            navigate('/gstpanlistsanbox');
                                        }
                                        break;

                                    case "in_trial":
                                        if (subscriptionStatus.taxHero === "0") {
                                            navigate('/gstpanlistsanbox');
                                        }
                                        break;

                                    case "active":
                                        if (subscriptionStatus.taxHero === "0") {
                                            showFreeTrialEnd("");
                                        } else if (subscriptionStatus.taxHero === "active") {
                                            navigate('/gstpanlistsanbox');
                                        }
                                        break;

                                    default:
                                        break;
                                }
                                  


                                

                                
                            }}
                        >


                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    2643
                                </Text>
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    GST
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>

                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',

                            }}>

                                <img
                                    src={treeImg}
                                >
                                </img>

                            </Box>






                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '394px',
                            minWidth: '397px',
                            maxWidth: '397px',
                            minHeight: '146px',
                            maxHeight: '146px',
                            height: '146px',
                            background: '#FFFFFF',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            border: '3px solid #ffffff',
                            ":hover": {
                                border: '3px solid #0969DA'
                            }
                        }}>

                            <Box sx={{
                                width: '168px',
                                minWidth: '168px',
                                maxWidth: '168px',
                                minHeight: '98px',
                                maxHeight: '98px',
                                height: '98px',
                                position: 'relative',
                                margin: '21px 0px 21px 21px',
                                display: 'flex',
                                flexDirection: 'column',

                            }}>

                                <Box sx={{

                                    minHeight: '22px',
                                    maxHeight: '22px',
                                    height: '22px',
                                    minWidth: '112px',
                                    width: '112px',
                                    maxWidth: '112px',
                                }}>
                                    <Label
                                        sx={{
                                            background: '#2155A4',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            borderRadius: '3px'
                                        }}
                                    >
                                        Not released yet
                                    </Label>

                                </Box>



                                {/* <Text sx={{

                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: '#24292E',
                                }}>
                                    550
                                </Text> */}
                                <Text sx={{
                                    width: '152px',
                                    height: '22px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    lineHeight: '22px',
                                    color: '#24292F',
                                    marginTop: '4px'
                                }}>
                                    TDS
                                </Text>

                                <Box sx={{
                                    marginTop: '18px',
                                    width: '174px',
                                    height: '22px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>

                                    <StyledOcticon size={15} color="#06AA8D" icon={ArrowUpIcon}></StyledOcticon>
                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '22px',
                                        color: '#000000',
                                        marginLeft: '8px',
                                    }}>
                                        1.10% Synced 4 days ago
                                    </Text>

                                </Box>


                            </Box>

                            <Box sx={{
                                width: '126px',
                                minWidth: '126px',
                                maxWidth: '126px',
                                minHeight: '126px',
                                maxHeight: '126px',
                                height: '126px',
                                position: 'relative',
                                margin: '10px 0px 10px 7px',

                            }}>

                                <img src={flyingImg} >
                                </img>

                            </Box>



                        </Box>


                    </Box>







                </Box>





            </Box>

           





        </Box>
    )

}

export default TaxHeroPortalSanbox