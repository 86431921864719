import React from "react"
import {Box,Dialog,Text,TextInput,Select,Textarea, Button } from '@primer/react'
import { useDispatch,useSelector } from "react-redux";
import { scheduleDemoOpenClose } from '../../redux/features/scheduleDemoSlice';
import Workplacetheme from "../Workplacetheme";
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { postSheduleDemo } from "../../redux/features/headerSlice";


function ScheduleDemoDialog(props){

    const dispatch = useDispatch() 
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const scheduleDemoStatus = useSelector((state)=> state.openCloseScheduleDemo)
    const initialFocusRef = React.createRef()

    const [formDatas,setformData] = React.useState({
          firstName:"",
          firstNameError:"",
          lastName:"",
          emailAddress:"",
          emailAddressError:"",
          phoneNumber:"",
          industry:"",
          teamSize:"",
          description:""
    })


    const closescheduledDialog = (props) => {

         dispatch(scheduleDemoOpenClose({
            openClose:false
         })).then((data)=>{

              // alert(JSON.stringify(data.payload))
         })
    }


    const sendDemoToEmailProvider = (props) => {

           if(formDatas.firstName===""){

               setformData((prevState) => {
                   const newState = { ...prevState, firstNameError: "error" }
                   return newState
               })



           }else if(formDatas.emailAddress===""){

                setformData((prevState) => {
                const newState = { ...prevState, emailAddressError: "error",firstNameError:'' }
                return newState
                })


           }else{

                 setformData((prevState) => {
                const newState = { ...prevState, firstNameError: "",emailAddressError:"" }
                return newState
                })


                dispatch(postSheduleDemo({
                    userId:userId,
                    userEmail:userEmail,
                    formData:formDatas
                })).then((data)=>{

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Successfully scheduled demo",
                        intent: Intent.SUCCESS
                    });

                }) 


              

                     
           }
     

    }



    return(
        <Dialog
            sx={Workplacetheme.sheduleDialog}
            isOpen={scheduleDemoStatus.openClose}
            onDismiss={() => 
            
                closescheduledDialog("")
            }
            aria-labelledby="header-id"
           // initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text  sx={Workplacetheme.scheduleDalogeHeading}>
                    {/* Schedule demo */}
                     {scheduleDemoStatus.title}
                </Text>
            </Dialog.Header>
            <Box sx={Workplacetheme.scheduleDialogContentBox}>
                
               <Box sx={Workplacetheme.schedulefirstNameLastNameBox}>
                 <TextInput
               //  ref={initialFocusRef}
                 sx={Workplacetheme.schedulefirstNameLastNameBox.firstNameInput} contrast aria-label="firstName" name="firstName" placeholder="First name" 
                 validationStatus={formDatas.firstNameError}
                 onChange={(e)=> setformData((prevState)=>{
                             const newState = {...prevState,firstName:e.target.value,firstNameError:''}
                             return newState
                 }) } 
                 value={formDatas.firstName} 
                 />
                 <TextInput 
               
                 sx={Workplacetheme.schedulefirstNameLastNameBox.lastNameInput} contrast aria-label="lastName" name="lastName" placeholder="Last name"
                 
                 onChange={(e) => setformData((prevState) => {
                            const newState = { ...prevState, lastName: e.target.value }
                            return newState
                 })} 
                 value={formDatas.lastName} 
                 />
                </Box> 

                <Box sx={Workplacetheme.scheduleEmailPhoneBox}>
                 <TextInput sx={Workplacetheme.scheduleEmailPhoneBox.emailInput} contrast aria-label="emailAddress" name="emailAddress" placeholder="E-mail adress" 
                 validationStatus={formDatas.emailAddressError}
                 onChange={(e) => setformData((prevState) => {
                    const newState = { ...prevState, emailAddress: e.target.value }
                    return newState
                 })}
                 value={formDatas.emailAddress} 
                 />
                 <TextInput sx={Workplacetheme.scheduleEmailPhoneBox.phoneInput} contrast aria-label="phoneNumber" name="phoneNumber" placeholder="+91 Phone number"
                 
                 onChange={(e) => setformData((prevState) => {
                    const newState = { ...prevState, phoneNumber: e.target.value }
                    return newState
                 })} 
                 value={formDatas.phoneNumber} 
                 />
                </Box> 

                <Box sx={Workplacetheme.scheduleDropdownBox}>

                    <Box
                        sx={Workplacetheme.scheduleDropdownBox.firstBox}
                    >

                        <Select
                            style={Workplacetheme.scheduleDropdownBox.selectComponent}
                            block="true"
                            placeholder="Choose your industry"
                            onChange={(e)=> 
                            
                                  setformData((prevState) => {
                                    const newState = { ...prevState, industry: e.target.value }
                                    return newState
                                  })
                            
                            }
                        >
                            <Select.Option value="Finance">Finance </Select.Option>
                            <Select.Option value="Architecture">Architecture</Select.Option>
                            <Select.Option value="Sales & Marketing">Sales & Marketing</Select.Option>
                            <Select.Option value="Customer service">Customer service</Select.Option>
                            <Select.Option value="Product">Product</Select.Option>
                            <Select.Option value="Engineering">Engineering</Select.Option>
                            <Select.Option value="Business Administration">Business Administration</Select.Option>
                            <Select.Option value="Operations">Operations</Select.Option>
                            <Select.Option value="Design">Design</Select.Option>
                            <Select.Option value="Law & Public Policy">Law & Public Policy</Select.Option>
                            <Select.Option value="Human Resource">Human Resource</Select.Option>
                            <Select.Option value="IT">IT</Select.Option>
                            <Select.Option value="Government">Government</Select.Option>
                            <Select.Option value="Educator">Educator</Select.Option>
                            <Select.Option value="Other">Other</Select.Option>

                        </Select>

                    </Box>

                    <Box
                        sx={Workplacetheme.scheduleDropdownBox.secondBox}
                    >

                        <Select
                            style={Workplacetheme.scheduleDropdownBox.selectComponent}
                            block="true"
                            placeholder="Select your team size"
                            onChange={(e)=> 
                            
                                setformData((prevState) => {
                                  const newState = { ...prevState, teamSize: e.target.value }
                                  return newState
                                })
                          
                          }
                        >
                            <Select.Option value="Finance">2-10 </Select.Option>
                            <Select.Option value="Architecture">10-50</Select.Option>
                            <Select.Option value="Sales & Marketing">5-70</Select.Option>
                            <Select.Option value="Customer service">100+</Select.Option>
                            

                        </Select>

                    </Box>


                   
                
                </Box> 

                <Box sx={Workplacetheme.scheduleTextAreaBox}>

                       <Textarea  
                       resize="horizontal" 
                       sx={Workplacetheme.scheduleTextAreaBox.textAreaInput} 
                       placeholder="Specify your requirement to help us prepare better"
                       onChange={(e) => setformData((prevState) => {
                        const newState = { ...prevState, description: e.target.value }
                        return newState
                       })}
                       value={formDatas.description}  
                        />

                </Box>


                    <Box mt={20} width={564} borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>

                    <Box  
                    ml={24} 
                    mr={24} 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent={"right"}
                    >

                            <Button variant="invisible" 
                            sx={Workplacetheme.scheduleFooterBox.cancelBtn}
                            onClick={()=> closescheduledDialog("") }
                            >Cancel</Button>
                            <Button
                          
                            variant="outline" 
                            sx={Workplacetheme.scheduleFooterBox.submitBtn}
                            onClick={()=> sendDemoToEmailProvider("") }
                            >Submit</Button>
                  
                  
                    </Box>

                    


              
                 
            </Box>
        </Dialog>
    )
   

   

}

export default ScheduleDemoDialog