import React from "react"
import { useLocation } from "react-router-dom"
import { TextInput, Box, Button, Text, IconButton, Label, ActionList, ActionMenu, Link, Timeline, Avatar, AnchoredOverlay, FilterList, Textarea, Token, ProgressBar,Tooltip } from '@primer/react'
import { useDispatch, useSelector } from "react-redux"
import { createTaskOpenClose, addContactOpenClose, getIssueConatctData, deleteTaskById, updateTaskName, updateTaskDueDate, updateTaskPriority, updateTaskRoadMap, updateTaskAssigne, updateTaskLabels, removeTaskLabels, updateTaskActivity, deleteTaskActivity, addTaskComments, deleteTaskComments, updateTaskDescription, postcreateIssue, updateTaskSubtask,updateWorkflowId, getSingleSpaceTasks, updateTaskAttachments, updateTaskContact, duplicateTask, reloadTaskTrigger, getTaskProjectHistory } from "../redux/features/taskSlice"
import {
  CalendarIcon, CountdownTimerIcon, Cross2Icon, ChatBubbleIcon, DotsHorizontalIcon, EnterIcon, PersonIcon, TextAlignJustifyIcon, CopyIcon, WidthIcon, RocketIcon, TriangleDownIcon, ChevronLeftIcon, InputIcon, LightningBoltIcon, BookmarkIcon
  , ActivityLogIcon, FontItalicIcon, CodeIcon, LapTimerIcon, HamburgerMenuIcon, DrawingPinIcon, ClockIcon, DownloadIcon, CameraIcon, FontBoldIcon, Link1Icon, HeadingIcon, DividerVerticalIcon, ListBulletIcon, MagnifyingGlassIcon, Pencil1Icon, EnvelopeClosedIcon, PlusIcon, SewingPinIcon, SketchLogoIcon, TextIcon, TrashIcon, UnderlineIcon, ViewVerticalIcon, FileTextIcon
} from "@radix-ui/react-icons"
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import './css/dist/Draft.css'
import { UnderlineNav } from '@primer/react/drafts'
import AddContactDialog from "../Workplace/Dialogs/AddContactDialog"
import { createRoadmapOpenClose, getSingleWorkplaceProject, getSpaceCollaborators } from "../redux/features/spaceSlice"
import { FeedPersonIcon } from "@primer/octicons-react"
import ImageAvatar from "../Workplace/ImageAvatar"
import GetUsernameById from "../Workplace/GetUsernameById"
import CreateRoadDialog from "../Workplace/Dialogs/CreateRoadDialog"
import { Toaster, Intent,Position } from "@blueprintjs/core";
import AWS from 'aws-sdk'
import { Helmet } from "react-helmet"
import AddOrganization from "../Workplace/Dialogs/AddOrganization"
import AddOrganizationDirectors from "../Workplace/Dialogs/AddOrganizationDirectors"
import AddOrganizationServices from "../Workplace/Dialogs/AddOrganizationServices"
import { addOrganizationOpenClose } from "../redux/features/contactSlice"
 
 
const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION

AWS.config.update({
  accessKeyId: IAM_USER_KEY,
  secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
  params: { Bucket: BUCKET_NAME},
  region: REGION,
})

//getSingleWorkplaceProject

const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"
const randomPara = "Hello is a song recorded by English singer-songwriter songwriter Adele, released on 23 October 2015 by XL Recordingsasswqswqdwe as the lead single from her third studio released on 23 October d2dqsqwedefr3frgtgrtgrtgrtgyhyhythreleased on 23 October sdfwf3f3"
const companyAvatar = "https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png"
const normalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/normal.svg"
const mediumIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/medium.svg"
const highIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/high.svg"
const criticalIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/critical.svg"
const lowIcon = "https://d2fcqkt9srr3ti.cloudfront.net/Priority/low.svg"
 



function getAMPMfromTime(time) {


  var hours = time.split(':')[0];
  var minutes = time.split(':')[1];
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;

}

function getNumberofDays(date1, date2) {

  const oneDay = 24 * 60 * 60 * 1000
  const firstDate = date1
  const secondDate = date2
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
  return diffDays;

}

function CreateTask() {

  const dispatch = useDispatch()
  const location = useLocation();

  const userId = useSelector((state) => state.profileData.userId)
  const userEmail = useSelector((state) => state.profileData.userEmail)
  const roadMap =   useSelector((state)=> state.spaceData.projectRoadMaps)
  const taskUpdateTrigger = useSelector((state)=> state.taskData.taskUpdateTrigger)
 
  const [showSkeleton,setshowSkeleton] = React.useState(false)


  const taskData = useSelector((state) => state.taskData)
  const headerData = useSelector((state) => state.headerData)


  const [subTaskParentTaskId,setsubTaskParentTaskId] = React.useState("")



  const [taskId, settaskId] = React.useState("")
  const issueTextRef = React.useRef()
  const activitycalandarRef = React.createRef()
  const activityTimeRef = React.createRef()
  const [selectedActivityDate, setselectedActivityDate] = React.useState("")
  const [selectedActivityTime, setselectedActivityTime] = React.useState("")
  const [showContactDialog, setshowContactDialog] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const items = [
    { navigation: 'Details', icon: FileTextIcon },
    { navigation: 'Activity', icon: ActivityLogIcon, counter: 120 },
    { navigation: 'Comments', icon: ChatBubbleIcon, counter: 13 },

  ]

  // details section -------------------------------------------------  
  const [subTaskInput, setsubTaskInput] = React.useState("")
  const [showSubtaskInput, setshowSubtaskInput] = React.useState(false)
  const [subtaskInputValidation, setsubtaskInputValidation] = React.useState('')
  const [subtaskArray, setsubtaskArray] = React.useState([])
  const [compliaceSubtasks,setcompliaceSubtasks] = React.useState([])
  const [attachmentArray, setattachmentArray] = React.useState([])


  const [backgroundColor, setbackgroundColor] = React.useState("white")
  const computerFileRef = React.useRef()


  const updateTaskAttachmentsIndb = (props) => {


          dispatch(updateTaskAttachments({
              userId:userId,
              userEmail:userEmail,
              taskId:taskData.taskId,
              taskAttachments:props
          })).then((data)=>{
              
                 
                 setattachmentArray(data.payload.data[0].attachments)
          })
       
      
  }

  const deleteTaskAttachmentIndb = (props) => {

   
 
    dispatch(updateTaskAttachments({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      taskAttachments: '',
      deleteTaskAttachmentId:props
    })).then((data) => {

     
      setattachmentArray(data.payload.data[0].attachments)
    })
        

  }


  const handlePickComputerFile = (props) => {

   

    const workplaceId = localStorage.getItem("workplaceId")
    const projectId = localStorage.getItem("projectId")
    
   

    

    const params = {
      'Body': props,
      'Bucket': BUCKET_NAME,
      'Key': 'workspaceprojects/'+projectId+'/' + props.name,
      'ContentType': props.type
    };


    deskiplatformBucket.upload(params,function(err,data){

      if (err){

       

      }else {

            const updateData = {
              attachment_file_name:props.name,
              attachment_size:''+props.size+'',
              attachment_url:data.Location,
              creator_id:userId,
              attachment_timestamp:""+new Date()+""
            }

            updateTaskAttachmentsIndb(updateData)


      };           
       


    })
      



  }

  //------------ google drive file picker ------------------------

    
  
  
  const google = window.google
  const gapi = window.gapi 
   
  const SCOPES = "https://www.googleapis.com/auth/drive.file";
  const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyD8VZ6ObpUM8ic0ZmLdElVXtgQ0l6QoKcM';
  const APP_ID = '686241795638';

   
  let tokenClient;
  let accessToken = null;
  let pickerInited = false;
  let gisInited = false;

  

  function gapiLoaded() {
    gapi.load('client:picker', intializePicker);
  }

   
  async function intializePicker() {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    pickerInited = true;
    maybeEnableButtons();
  }

   
  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });
    gisInited = true;
    maybeEnableButtons();
  }

   
  function maybeEnableButtons() {
    if (pickerInited && gisInited) {
     // document.getElementById('authorize_button').style.visibility = 'visible';
    }
  }

  
  function handleAuthClick() {
    tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw (response);
      }
      accessToken = response.access_token;
    //  document.getElementById('signout_button').style.visibility = 'visible';
    //  document.getElementById('authorize_button').innerText = 'Refresh';
      await createPicker();
    };

    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({prompt: ''});
    }
  }

   
  function handleSignoutClick() {
    if (accessToken) {
      accessToken = null;
      google.accounts.oauth2.revoke(accessToken);
    
    }
  }

  
  function createPicker() {
    const view = new google.picker.View(google.picker.ViewId.DOCS);
   
    const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setDeveloperKey(API_KEY)
        .setAppId(APP_ID)
        .setOAuthToken(accessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setCallback(pickerCallback)
        .build();
    picker.setVisible(true);
  }

  
  async function pickerCallback(data) {
    if (data.action === google.picker.Action.PICKED) {
      let text = `Picker response: \n${JSON.stringify(data, null, 2)}\n`;
      const document = data[google.picker.Response.DOCUMENTS][0];
      const fileId = document[google.picker.Document.ID];
    
      const res0 = await gapi.client.drive.files.get({
          'fileId': fileId,
          'fields': '*',
          // alt: 'media',
      })
      
      const res = await gapi.client.drive.files.get({
           fileId,
           alt: 'media',

      },{ responseType: 'blob' });
      

       
         const blob = new Blob([res.body], { type: res.headers['content-type'] });
         
         let file123 = new File([blob], res0.result.name, { type: res0.result.mimeType });

         
        const fileUrl = "https://drive.google.com/uc?id="+fileId+"&export=download" // "https://drive.google.com/uc?id="+fileId+"&export=download&key="+API_KEY+""
        //  const response = await fetch(fileUrl,{
        //     mode:'no-cors',
        //     // headers: {
        //     // Authorization: 'Bearer '+accessToken+'',
        //     // },
        //  });
        //  const blob2 = await response.blob();
        

      
      const fileName = res0.result.name
      const fileSize = blob.size
      const contentType = res0.result.mimeType //blob.type 
      const projectId = localStorage.getItem("projectId")
     

      

    //  // alert(contentType)

      
      const params = {
        'Body': file123,
        'Bucket': BUCKET_NAME,
        'Key': 'workspaceprojects/'+projectId+'/' + fileName,
        'ContentType': contentType
      };
  
  
      deskiplatformBucket.upload(params,function(err,data){
  
        if (err){
          
        }else {
  
               
  
              const updateData = {
                attachment_file_name:fileName,
                attachment_size:''+fileSize+'',
                attachment_url:data.Location,
                creator_id:userId,
                attachment_timestamp:""+new Date()+""
              }
  
              
              updateTaskAttachmentsIndb(updateData)
  
  
        };           
         
  
  
      })
     

    }
  }

  const pickGoogleDriveFilePicker = (props) =>{

    if(accessToken==null){

           handleAuthClick()
      }else{

       const view = new google.picker.View(google.picker.ViewId.DOCS);
     
      
       const picker = new google.picker.PickerBuilder()
           .enableFeature(google.picker.Feature.NAV_HIDDEN)
           .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
           .setDeveloperKey(API_KEY)
           .setAppId(APP_ID)
           .setOAuthToken(accessToken)
           .addView(view)
           .addView(new google.picker.DocsUploadView())
           .setCallback(pickerCallback)
           .build();
           picker.setVisible(true);
          
      }

  }
   

   
 
   

  //------------ google picker code -------------

 
  //-------------- one drive file picker  -------------------
  const  OneDrive = window.OneDrive

  var ondriveOptions = {
    clientId: "4d72e000-9a1c-4d78-95ee-a6432e7726c5",
    // action: "query",
   // action: "download",
    action:"share",
   
    multiSelect: false,
    advanced: {
    //  queryParameters: "select=id,name,size,file,folder,photo,@microsoft.graph.downloadUrl",
      
    },
    success: async function(files) { 

         var selectedFile = files.value[0];
         var fileDownloadUrl = selectedFile["@microsoft.graph.downloadUrl"]
         let response = await fetch(fileDownloadUrl);
         let data = await response.blob();
         let metadata = {
         type: data.type
         };
         let file = new File([data], selectedFile.name, metadata);

      
   
     


     },
    cancel: function() { /* cancel handler */ },
    error: function(error) { 
      
          alert(JSON.stringify(error))
     }
  }

  

  function launchOneDrivePicker(){
        
        OneDrive.open(ondriveOptions);
    
  }


   
  //----------------------------------------------------------
  
  
  //------------- drop box -----------

  const dropBoxoptions = {

    // Required. Called when a user selects an item in the Chooser.
    success:async function(files) {
        
      
      // alert(JSON.stringify(files))
        const fileUrl = files[0].link
        const fileName = files[0].name
        const fileSize = files[0].bytes
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const headers = response.headers;
        const contentType = headers.get('Content-Type');
        const projectId = localStorage.getItem("projectId")
       
      //  // alert(contentType)

        
        const params = {
          'Body': blob,
          'Bucket': BUCKET_NAME,
          'Key': 'workspaceprojects/'+projectId+'/' + fileName,
          'ContentType': contentType
        };
    
    
        deskiplatformBucket.upload(params,function(err,data){
    
          if (err){
            
          }else {
    
               
    
                const updateData = {
                  attachment_file_name:fileName,
                  attachment_size:''+fileSize+'',
                  attachment_url:data.Location,
                  creator_id:userId,
                  attachment_timestamp:""+new Date()+""
                }
    
                
                updateTaskAttachmentsIndb(updateData)
    
    
          };           
           
    
    
        })



      

    },

    // Optional. Called when the user closes the dialog without selecting a file
    // and does not include any parameters.
    cancel: function() {
            
          // alert('canceled')
    },

    // Optional. "preview" (default) is a preview link to the document for sharing,
    // "direct" is an expiring link to download the contents of the file. For more
    // information about link types, see Link types below.
    linkType: "direct",//"preview", // or "direct"

    // Optional. A value of false (default) limits selection to a single file, while
    // true enables multiple file selection.
    multiselect: false, // or true

    // Optional. This is a list of file extensions. If specified, the user will
    // only be able to select files with these extensions. You may also specify
    // file types, such as "video" or "images" in the list. For more information,
    // see File types below. By default, all extensions are allowed.
  //  extensions: ['.pdf', '.doc', '.docx','.png','.svg'],

    // Optional. A value of false (default) limits selection to files,
    // while true allows the user to select both folders and files.
    // You cannot specify `linkType: "direct"` when using `folderselect: true`.
    folderselect: false, // or true

    // Optional. A limit on the size of each file that may be selected, in bytes.
    // If specified, the user will only be able to select files with size
    // less than or equal to this limit.
    // For the purposes of this option, folders have size zero.
   // sizeLimit: 1024, // or any positive number
};




  const launchDropboxPicker = (props) => {

    // const dbx = new Dropbox({ accessToken: "YOUR_ACCESS_TOKEN" });
    // const { entries } = await dbx.filesListFolder({ path: "" });


        window.Dropbox.choose(dropBoxoptions);


  }



  //--------------------------------
   

   




  //--------------------------------------------------------------

  const [detailInputFocus, setdetailInputFocus] = React.useState(false)
  const [detailInputOutline, setdetailInputOutline] = React.useState(false)
  const [detailInputIncreaseHeight, setdetailInputIncreaseHeight] = React.useState(false)

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createEmpty(),
  );

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      onChange(newState);

      return 'handled';
    }
    return 'not-handled';
  }

  const onChange = (editorState) => {

       setEditorState(editorState)
       updateTaskDescriptionToDb(convertToRaw(editorState.getCurrentContent()))
  }

  const handleBeforeInput = (props) => {

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText("");

    if (currentContentLength - selectedTextLength > 500 - 1) {
      

      return 'handled';
    }

  }

  const handlePastedText = (props) => {

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText("");

    if (currentContentLength - selectedTextLength > 500 - 1) {
      

      return 'handled';
    }

  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
     
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  const makeBoldInputText = (props) => {


    setdetailInputFocus(true)

    if (props === "H1") {

      setdetailInputFocus(true)

      // getBlockStyle("header-one")
      onChange(RichUtils.toggleBlockType(editorState, 'header-one'))
      setbackgroundColor('white')

    } else if (props === "UL") {

      //blockquote
      onChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
      setbackgroundColor('white')
    }
    else if (props === "CODEBLOCK") {

      //code-block
      onChange(RichUtils.toggleBlockType(editorState, 'code-block'))
      setbackgroundColor('rgba(0, 0, 0, 0.05)')

    } else if (props === "paragraph") {

      onChange(RichUtils.toggleBlockType(editorState, 'paragraph'))
      setbackgroundColor('white')
    } else if (props === "BOLD") {


      setEditorState((prevState) => {




        return (RichUtils.toggleInlineStyle(prevState, 'BOLD'))


      })





    }
    else {

      onChange(RichUtils.toggleInlineStyle(editorState, props))
      setbackgroundColor('white')
    }



  }

  //--------------------------------------------------------------------------------------


  //-------------------  add roadmap dialog -------------

  const showAddRoadMapDialog = (props) => {
 
      
     dispatch(createRoadmapOpenClose({
       roadmapOpenClose: true,
       roadMapProjectId:localStorage.getItem("projectId"),
       roadMapTaskId:taskData.taskId   
     }))
     

  }


  //

  //--------------------- add conatct ------

  const showAddContactDialog = (props) => {

     dispatch(addContactOpenClose({
      addContactisOpen: true
     }))

    
    //  dispatch(addOrganizationOpenClose({
    //   isAddOrganization:true
    //  }))


  }


  const clearContactData = (props) => {



    dispatch(getIssueConatctData({
      companyAvatar: '',
      selectedContact: 'Add contact',
      contactId:'',
    }))
  }


  //---------------------------------------


  const [historyDialog, sethistoryDialog] = React.useState(false)
  const [commentInput, setcommentInput] = React.useState("")
  const [commentEditId, setcommentEditId] = React.useState("")
  const [activityInput, setactivityInput] = React.useState({
    activityId: '',
    value: '',
    placeholder: 'Meeting',
    validationStatus: ''
  })



  const [activityDescription, setactivityDescription] = React.useState({
    value: "",
    placeholder: "",

  })

  const activityDescriptionRef = React.createRef()
  const [isSubTaskVisible, setisSubTaskVisible] = React.useState(false)
  const [selectedLabels, setselectedLabels] = React.useState([])
  const [randomText, setRandomText] = React.useState("")
  const taskNameRef = React.createRef()
  const [randomTextEditable, setrandomTextEditable] = React.useState(false)
  const [historyArray, sethistoryArray] = React.useState([
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "created",
    //   spaceName: "graphic digxdcv",
    //   date: 'Just now'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "commented",
    //   spaceName: "gicsdddfss",
    //   date: '15-02-2022'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "attachment",
    //   spaceName: "graphicss.p",
    //   date: '15-02-2022'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "change status",
    //   spaceName: "Process",
    //   date: '15-02-2022'
    // },
    // ,
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "attachment",
    //   spaceName: "graphicss.p",
    //   date: '15-02-2022'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "change status",
    //   spaceName: "Process",
    //   date: '15-02-2022'
    // },
    // ,
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "attachment",
    //   spaceName: "graphicss.p",
    //   date: '15-02-2022'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "change status",
    //   spaceName: "Process",
    //   date: '15-02-2022'
    // },
    // ,
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "attachment",
    //   spaceName: "graphicss.p",
    //   date: '15-02-2022'
    // },
    // {
    //   image: placeHolderProfile,
    //   name: 'Monalisa',
    //   activity: "change status",
    //   spaceName: "Process",
    //   date: '15-02-2022'
    // }
  ])

  const [showActivityGroupIcons, setshowActivityGroupIcons] = React.useState({
    id: "",
    visibility: false
  })

  const [showCommentGroupIcons, setshowCommentGroupIcons] = React.useState({
    id: "",
    visibility: false
  })

  const [activityArray, setactivityArray] = React.useState([])

  const [commentArray, setcommentArray] = React.useState([])

  const [workflowArray, setworkflowArray] = React.useState([])

  const [priorityArray, setpriorityArray] = React.useState([

    { icon: CalendarIcon, name: 'Normal' },
    { icon: CalendarIcon, name: 'Medium' },
    { icon: CalendarIcon, name: 'High' },
    { icon: CalendarIcon, name: 'Critical' },
    { icon: CalendarIcon, name: 'Low' }

  ])

 // const [roadMap, setroadMap] = React.useState([])

  const [selectedPriority, setselectedPriority] = React.useState(0)
 
  const [selectedAssigne, setselectedAssigne] = React.useState("Add assignee")
  const [selectedAssigneImage, setselectedAssigneImage] = React.useState("")
  const [selectedRoadmap, setselectedRoadmap] = React.useState("")

  const [labels, setLabels] = React.useState([])

  const [showActionMenu, setshowActionMenu] = React.useState(false)
  const [selectedWorkflow, setselectedWorkflow] = React.useState("")
  const [showWorkflowActionMenu, setshowWorkflowActionMenu] = React.useState(false)
  const [subtaskAssigneActionMenu, setsubtaskAssigneActionMenu] = React.useState(false)
  const [labelsActionMenu, setlabelsActionMenu] = React.useState(false)
  const [createLabel, setcreateLabel] = React.useState("")

  const dueDateCalandarRef = React.createRef()

  const [selectedDueDate, setselectedDueDate] = React.useState("")


  const [spaceCollaborators, setspaceCollaborators] = React.useState([])
  const [searchCollaborators, setsearchCollaborators] = React.useState("")
  const [searchsubtaskCollaborators,setsearchsubtaskCollaborators] = React.useState("")
  const [selectedSubtaskAssigne,setselectedSubtaskAssigne] = React.useState("")
  





  const openOverlay = React.useCallback(() => setshowActionMenu(true), [setshowActionMenu])
  const closeOverlay = React.useCallback(() => setshowActionMenu(false), [setshowActionMenu])

  const openSubtaskAssigneActionMenuOverlay = React.useCallback(() => setsubtaskAssigneActionMenu(true), [setsubtaskAssigneActionMenu])
  const closeSubtaskAssigneWorkflowActionMenuOverlay = React.useCallback(() => setsubtaskAssigneActionMenu(false), [setsubtaskAssigneActionMenu])

  const openLablsActionMenuOverlay = React.useCallback(() => setlabelsActionMenu(true), [setlabelsActionMenu])
  const closeLabelsActionMenuOverlay = React.useCallback(() => setlabelsActionMenu(false), [setlabelsActionMenu])


  const openWorkflowActionMenuOverlay = React.useCallback(() => setshowWorkflowActionMenu(true), [setshowWorkflowActionMenu])
  const closeWorkflowActionMenuOverlay = React.useCallback(() => setshowWorkflowActionMenu(false), [setshowWorkflowActionMenu])




  const closeCreateTask = (props) => {

   
     if(taskData.status === "Create") {
       
       dispatch(getSingleSpaceTasks({
         taskId: taskData.taskId
       })).then((data) => {

            

             if(data.payload.data[0]?.task_name===""|| data.payload.data[0]?.task_name==null){

               dispatch(deleteTaskById({
                 userId: userId,
                 userEmail: userEmail,
                 taskId: taskData.taskId
               })).then((data) => {

                     dispatch(reloadTaskTrigger({
                      taskTrigger:true
                     }))

                   
               })


             } 
              
       })
           

     }


     
 
    dispatch(createTaskOpenClose({
      openClose: false,
      expandWidth: false,
      status: "",
      taskId: "",
    }))

    setRandomText("")
    setselectedAssigne("Add assignee")
    setselectedAssigneImage("")
    setselectedDueDate("")
    clearContactData("")
    setselectedRoadmap("")
    setselectedPriority(0)
    setselectedLabels([])
    setactivityArray([])
    setcommentArray([])
    setactivityInput(
      {
        activityId: '',
        value: '',
        placeholder: 'Meeting',
        validationStatus: ''
      }
    )
    setselectedActivityDate("")
    setselectedActivityTime("")
    setcommentInput("")
    setcommentEditId("")
    setselectedWorkflow(workflowArray[0]?.workflow_id)
    setEditorState(EditorState.createEmpty())
    setsubtaskArray([])
    setattachmentArray([])
   
  }

  const duplicateTaskInDb = (props) => {
      
      dispatch(duplicateTask({
        userId: userId,
        userEmail: userEmail,
        taskId: taskData.taskId,
        duplicateTaskId:taskData.taskId,
      })).then((data)=>{

        Toaster.create({ position: Position.TOP }).show({
          message: "Duplicated.",
          intent: Intent.PRIMARY
        });


           
      })


  }

  const handleDeleteTask = (props) => {

    setRandomText("")

    dispatch(deleteTaskById({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId
    })).then((data)=>{
        
      Toaster.create({ position: Position.TOP }).show({
        message: "Task deleted.",
        intent: Intent.DANGER
      });
    })


    dispatch(createTaskOpenClose({
      openClose: false,
      expandWidth: false,
      status: "",
      taskId: "",
    }))

    

  }

  const miniMizeExpandWidth = (props) => {

    dispatch(createTaskOpenClose({
      openClose: true,
      expandWidth: !taskData.expandWidth,
      status: taskData.status
    }))
  }



  const handleCommentInputChange = event => {

        setcommentInput(event.target.value)
         
  }

  const handleCommentInputOnkeyDown = (e) => {

    if (e.key == 'Enter') {

      if (e.target.value === '') {


      } else {

        //  alert('post to db  '+e.target.value)

        if (commentEditId === "") {

           const commentData = {
            creator_id: userId,
            comment_message: e.target.value,
            comment_timestamp: "" + new Date() + ""
           }

          dispatch(addTaskComments({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.taskId,
            taskComment: commentData
          })).then((data) => {

            Toaster.create({ position: Position.TOP }).show({
              message: "Comment posted",
              intent: Intent.PRIMARY
            });

            
            setcommentArray(data.payload.taskComments)
            setcommentInput("")
          })


        } else {

          deleteTaskCommentsToDb(commentEditId)
          const commentData = {
            creator_id: userId,
            comment_message: e.target.value,
            comment_timestamp: "" + new Date() + ""
          }

          dispatch(addTaskComments({
            userId: userId,
            userEmail: userEmail,
            taskId: taskData.taskId,
            taskComment: commentData
          })).then((data) => {

            
            setcommentArray(data.payload.taskComments)
            setcommentInput("")
            setcommentEditId("")
          })


        }






      }


    }
  }



  const removeLabelToDb = (props) => {





    dispatch(removeTaskLabels({

      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      labelId: "" + props.label_id + "",
      projectId: localStorage.getItem("projectId")
    })).then((data) => {
      
      setselectedLabels(data.payload.taskLabels)
    })
 
  }

  const getSpaceCollaboratorFromDb = (props) => {


    dispatch(getSpaceCollaborators({
      userEmail: userEmail,
      userId: userId,
      projectId: localStorage.getItem("projectId")
    })).then((data) => {

      setspaceCollaborators(data.payload.collaboratorArray)
    })

  }

  const updateTaskNameToDb = (props) => {
 
    setRandomText(props)

    dispatch(updateTaskName({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      taskName: props
    })).then((data) => {

      

    })


  }

  const updateWorkflowIdToDb = (props) => {

      
      // alert(JSON.stringify(taskData))

    
    dispatch(updateWorkflowId({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      workflowId: props
    })).then((data) => {

      

    })

  }

  const updateTaskDueDateToDb = (props) => {

    

    dispatch(updateTaskDueDate({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      dueDate: "" + props + ""
    })).then((data) => {

      
    })

  }

  const updateTaskPriorityToDb = (props) => {


    dispatch(updateTaskPriority({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      priority: "" + props + ""
    })).then((data) => {

      
    })

  }

  const updateTaskContactToDb = (props) => {

    dispatch(updateTaskContact({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      taskContact: "" + props + ""
    })).then((data) => {

      
    })
  }

  const updateRoadMapToDb = (props) => {


    dispatch(updateTaskRoadMap({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      roadMap: "" + props + ""
    })).then((data) => {

      
    })


  }

  const updateTaskAssigneToDb = (props) => {



    dispatch(updateTaskAssigne({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      assigneId: "" + props + ""
    })).then((data) => {

      Toaster.create({ position: Position.TOP }).show({
        message: "Task assigned.",
        intent: Intent.PRIMARY
      });

       
    })
  }


  const updateTaskLabelsToDb = (props) => {

    dispatch(updateTaskLabels({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      labelName: "" + props + "",
      projectId: localStorage.getItem("projectId")
    })).then((data) => {

     

      setselectedLabels(data.payload.taskLabels)


    })

  }


  const handlePostActivity = (props) => {


    if (activityInput.value === "") {

      setactivityInput((prevState) => {

        const newState = { ...prevState, validationStatus: 'error' }
        return newState
      })
    } else {

      if (activityInput.activityId === "") {

        setactivityInput((prevState) => {

          const newState = { ...prevState, validationStatus: '' }
          return newState
        })


        const activityData = {

          creator_id: userId,
          activity_type: activityInput.placeholder,
          activity_name: activityInput.value,
          activity_date: "" + new Date(selectedActivityDate) + "",
          activity_time: "" + selectedActivityTime + "",
          videocall_type: 'Google Meet',
          videocall_link: '',
          activity_description: activityDescription.value,
          activity_timestamp: "" + new Date() + ""
        }


        dispatch(updateTaskActivity({

          userId: userId,
          userEmail: userEmail,
          taskId: taskData.taskId,
          taskActivity: activityData,

        })).then((data) => {

          Toaster.create({ position: Position.TOP }).show({
            message: "Activity recorded.",
            intent: Intent.PRIMARY
          });

         
          setactivityArray(data.payload.projectActivity)
          setactivityInput({
            activityId: '',
            value: '',
            placeholder: 'Meeting',
            validationStatus: ''
          })

          setactivityDescription({
            value: "",
            placeholder: "",
          })
          setselectedActivityDate("")
          setselectedActivityTime("")


        })


      } else {


        deleteTaskActivityToDb(activityInput.activityId)

        setactivityInput((prevState) => {

          const newState = { ...prevState, validationStatus: '', activityId: '' }
          return newState
        })


        const activityData = {

          creator_id: userId,
          activity_type: activityInput.placeholder,
          activity_name: activityInput.value,
          activity_date: "" + new Date(selectedActivityDate) + "",
          activity_time: "" + selectedActivityTime + "",
          videocall_type: 'Google Meet',
          videocall_link: '',
          activity_description: activityDescription.value,
          activity_timestamp: "" + new Date() + ""
        }


        dispatch(updateTaskActivity({

          userId: userId,
          userEmail: userEmail,
          taskId: taskData.taskId,
          taskActivity: activityData,

        })).then((data) => {

          
          setactivityArray(data.payload.projectActivity)
          setactivityInput({
            activityId: '',
            value: '',
            placeholder: 'Meeting',
            validationStatus: ''
          })

          setactivityDescription({
            value: "",
            placeholder: "",
          })
          setselectedActivityDate("")
          setselectedActivityTime("")


        })
      }




    }


  }


  const deleteTaskActivityToDb = (props) => {


    dispatch(deleteTaskActivity({

      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      deleteActivityId: props,

    })).then((data) => {

      setactivityArray(data.payload.projectActivity)

    })

  }

  const deleteTaskCommentsToDb = (props) => {

    dispatch(deleteTaskComments({
      userId: userId,
      userEmail: userEmail,
      taskId: taskData.taskId,
      deleteCommentId: props,
    })).then((data) => {

      setcommentArray(data.payload.taskComments)
    })

  }

  const updateTaskDescriptionToDb = (props) => {

      dispatch(updateTaskDescription({
         userId:userId,
         userEmail:userEmail,
         taskId:taskData.taskId,
         taskDescription:props   
      })).then((data)=>{

           
      })
  }

  const postSubTaskToDb = (props) => {

        if(randomText===""){


        }else{

             const wId = workflowArray[0]?.workflow_id
              


          dispatch(postcreateIssue({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            taskName: subTaskInput,
            workflowId:wId,
           // workflowId: selectedWorkflow,
            taskPriority: "Normal",
            assigneId:selectedSubtaskAssigne
          })).then((data) => {

           

             const subtaskId = data.payload.data[0].task_id
              
             const subtaskData = {
                 subtaskId:""+subtaskId+""
             }


             dispatch(updateTaskSubtask({
                userId:userId,
                userEmail:userEmail,
                taskId:taskData.taskId,
                subtaskData:subtaskData
             })).then((data)=>{

               Toaster.create({ position: Position.TOP }).show({
                 message: "Subtask created.",
                 intent: Intent.SUCCESS
               });

                     
                     setsubtaskArray(data.payload.subtaskData)
             })

             
             setselectedSubtaskAssigne("")
             setsubTaskInput("")
             setsubtaskInputValidation("")
          })

           setshowSubtaskInput(false)

            
        }
      
  }


  const getSpaceTaskFromDb = (props) => {

    
        setshowSkeleton(true)
        dispatch(getSingleSpaceTasks({
            taskId:props
        })).then((data)=>{
            
               
              
              const tName = data.payload.data[0].task_name
              const tAssigne = data.payload.data[0].assigne_id  
              const tworkflowId = data.payload.data[0].workflow_id
              const tAssigneName = data.payload.data[0].user_first_name+" "+data.payload.data[0].user_last_name
              const tAssigneImgUrl = data.payload.data[0].user_image_url
              const tDueDate = data.payload.data[0].due_date
              const roadMap = data.payload.data[0].roadmap
              const projectRoadMap = data.payload.data[0].project_roadmap
              const tPriority = data.payload.data[0].tasks_priority
              const selectedPriorityIndex = priorityArray.findIndex(pData=> pData.name===tPriority)
              const tLabels = data.payload.data[0].tasks_label
              const tDescription = data.payload.data[0].description
              const tActivity = data.payload.taskActivity
              const tComments = data.payload.taskComments
              const tSubtasks = data.payload.subtaskData
              const tParentTaskId = data.payload.data[0].subtask
              const tAttachments = data.payload.data[0].attachments
              const cSubtasks = data.payload.data[0].compliance_subtasks

              if(tParentTaskId.length>0){
                  const newSubtaskParentId = tParentTaskId[0].data.split("|")[0]
                  setsubTaskParentTaskId(newSubtaskParentId)
              }

              setattachmentArray(tAttachments)
              setselectedWorkflow(tworkflowId)
              setactivityArray(tActivity)
              setcommentArray(tComments)
              setsubtaskArray(tSubtasks)
              setcompliaceSubtasks(cSubtasks)
              setEditorState(EditorState.createWithContent(convertFromRaw(tDescription)))

              setselectedPriority(selectedPriorityIndex)
              setRandomText(tName)
              setselectedLabels(tLabels)

              if(tAssigne!==null && tAssigne!==""){

                setselectedAssigne(tAssigneName)
            
                if(tAssigneImgUrl!==null&&tAssigneImgUrl!==""){

                    setselectedAssigneImage(tAssigneImgUrl)
                }else{

                    setselectedAssigneImage("")
                }


              }else{
                  setselectedAssigne("Add assignee")
                  setselectedAssigneImage("")
              } 

              if(tDueDate!==null&&tDueDate!==""){

                  const newDueDate = new Date(tDueDate).getDate()+"/"+(new Date(tDueDate).getMonth()+1)+"/"+new Date(tDueDate).getFullYear()
                  setselectedDueDate(newDueDate)
              }else{

                  setselectedDueDate("")
              }

              if(roadMap!==null&&roadMap!==""){

                  const newselectedRoadMap = projectRoadMap.filter((rData)=> rData.roadmap_id===roadMap)[0].roadmap_name 

                  setselectedRoadmap(newselectedRoadMap)
              }else{

                  setselectedRoadmap("")
              }

              if(data.payload?.taskContactData?.length > 0){

                const companyAvatar = 'https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916_960_720.png'
                const contactType = data.payload?.taskContactData[0]?.contact_type 
                const contactId = data.payload?.taskContactData[0]?.contact_id
                if(contactType==="Organization"){

                  dispatch(getIssueConatctData({
                    companyAvatar: companyAvatar,
                    selectedContact: data.payload?.taskContactData[0]?.contact_data?.org_name,
                    contactId:contactId,
                  }))

                }else if(contactType==="Person"){

                   dispatch(getIssueConatctData({
                    companyAvatar: companyAvatar,
                    selectedContact: data.payload?.taskContactData[0]?.contact_data?.first_name+' '+data.payload?.taskContactData[0]?.contact_data?.last_name,
                    contactId:contactId,
                   }))
                }


                

                  
              }else{

                dispatch(getIssueConatctData({
                  companyAvatar: '',
                  selectedContact: 'Add contact',
                  contactId:'',
                }))

              }  


               
              setshowSkeleton(false)
        })

  }

  const getTaskProjectHistoryFromDb = (props)=>{
    
      dispatch(getTaskProjectHistory({
        userId:userId,
        userEmail:userEmail,
        projectId:localStorage.getItem("projectId")
      })).then((data)=>{

           sethistoryArray(data.payload.data)  

      })


  }

  const reloadLabels = (props) => {


    const projectId = localStorage.getItem("projectId")
    dispatch(getSingleWorkplaceProject({
      projectId: projectId
    })).then((data) => {

      
      setLabels(data.payload.data[0]?.project_labels)
     

    })

  }


  const StaticHead = (props) => {

    return (
      <Box
        position={"fixed"}
        marginTop={"0px"}
        marginLeft={"0px"}
        marginRight={"0px"}
        sx={{
          width: taskData.expandWidth === true ? '1090px' : '600px',
          background: 'white',
          height: '50px',
          minHeight: '50px'
        }}
        zIndex={10}
      >

        <Box
          sx={{
            position: 'relative',
            margin: '14px 40px 0px 40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: 'white',
            width: taskData.expandWidth === true ? '998px' : '530px'
          }}

        >

          <RocketIcon style={{ marginLeft: '2px' }} />


          <AnchoredOverlay
            align="end"
            renderAnchor={anchorProps => (

              <Button
                {...anchorProps}
                variant="primary"
                sx={{
                  height: '22px',
                  minHeight: '22px',
                  position: 'relative',
                  margin: '0px 0px 0px 16px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  background: '#2DA44E',
                  border: 'none',
                  borderRadius: '3px'
                }}
              >

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '22px',
                  alignItems: 'center',
                  width: 'auto'
                }}>
                  <Text
                    sx={{
                      height: '22px',
                      display: 'flex',
                      position: 'relative',
                      margin: '0px 0px 0px 5px',
                      fontFamily: 'Segoe UI',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '12px',
                      lineHeight: '18px',
                      alignItems: 'center'
                    }}
                  >
                    {workflowArray?.filter((wData) => wData.workflow_id === selectedWorkflow)[0]?.workflow_name}
                  </Text>
                  <TriangleDownIcon
                    style={{
                      display: 'flex',
                      position: 'relative',
                      margin: '0px 0px 0px 7px'
                    }}
                  />

                </Box>




              </Button>


            )}
            open={showWorkflowActionMenu}
            onOpen={openWorkflowActionMenuOverlay}
            onClose={closeWorkflowActionMenuOverlay}

          >


            <ActionList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                position: 'relative',
                margin: '8px',

              }}

            >
              {
                workflowArray?.map((row, index) => {
                  return (
                    <ActionList.Item

                      style={{

                        backgroundColor: row.workflow_id === selectedWorkflow ? '#2DA44E' : '',
                        color: row.workflow_id === selectedWorkflow ? '#ffffff' : '#24292F',
                      }}
                      sx={{
                        position: 'relative',
                        margin: 'auto',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        borderRadius: '6px',
                        color: row.workflow_id === selectedWorkflow ? '#ffffff' : '#24292F',
                      }}



                      onClick={() => {
                        updateWorkflowIdToDb(row.workflow_id)
                        setselectedWorkflow(row.workflow_id)
                        closeWorkflowActionMenuOverlay()

                      }}

                    >
                      {row.workflow_name}
                    </ActionList.Item>
                  )
                })

              }

            </ActionList>



            {/* <FilterList
               sx={{
                position: 'relative',
                margin: '8px',

               }}
            >

              {
                workflowArray.map((row, index) => {
                  return (
                    <FilterList.Item
                       
                      style={{
                        
                        backgroundColor: row.workflow_id === selectedWorkflow ? '#2DA44E' : '#ffffff',
                        color: row.workflow_id === selectedWorkflow ? '#ffffff' : '#24292F',
                      }}

                      sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        borderRadius: '6px',
                        color: row.workflow_id === selectedWorkflow ? '#ffffff' : '#24292F'
                      }}

                      onClick={() => {
                        setselectedWorkflow(row.workflow_id)
                        closeWorkflowActionMenuOverlay()
                      }}
                     
                    >
                      {row.workflow_name}
                    </FilterList.Item>
                  )
                })
              }


            </FilterList> */}

          </AnchoredOverlay>



          <Box sx={{
            position: 'relative',
            margin: '0px 0px 0px auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '6px',
          }} >

            <IconButton
              size="small"
              variant="invisible"
              sx={
                {
                  background: '#ffffff',
                  color: '#000000',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  margin: '0px 0px 0px 0px'
                }
              }
              onClick={() => miniMizeExpandWidth("")}
              icon={() => taskData.expandWidth === true ? <EnterIcon style={{ display: 'flex' }} /> : <WidthIcon style={{ display: 'flex' }} />}

            />

            <IconButton
              size="small"
              variant="invisible"
              sx={
                {
                  background: '#ffffff',
                  color: '#000000',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  margin: '0px 0px 0px 0px'
                }
              }

              icon={() => <CopyIcon style={{ display: 'flex' }} />}

            />

            <ActionMenu>
              <ActionMenu.Anchor>

                <IconButton
                  size="small"
                  variant="invisible"
                  sx={
                    {
                      background: '#ffffff',
                      color: '#000000',
                      border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      margin: '0px 0px 0px 0px'
                    }
                  }

                  icon={() => <CountdownTimerIcon style={{ display: 'flex' }} />}
                //  onClick={() => sethistoryDialog(!historyDialog)}
                />

              </ActionMenu.Anchor>

              <ActionMenu.Overlay
                sx={{
                  border: '1px solid #D0D7DE',
                  borderRadius: '6px',


                }}
              >

                <Box
                  sx={{
                    width: '328px',
                    minWidth: '328px',
                    maxWidth: '328px',
                    height: 'auto',
                    minHeight: 'auto',
                    maxHeight: '534px',
                    background: '#ffffff',
                    //  border: '0px solid #D0D7DE',
                    boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                    borderRadius: '6px',
                    zIndex: '10',
                    display: "flex",
                    flexDirection: 'column',

                  }}
                >
                  <Box
                    borderColor="border.default"
                    borderBottomWidth={1}
                    borderBottomStyle="solid"
                    sx={{
                      height: '38px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      margin: '0px 0px 0px 0px'
                    }}
                  >
                    <Text
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 13px',
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '18px',
                        color: '#000000'
                      }}
                    >
                      Activity
                    </Text>


                  </Box>

                  <Box
                    sx={{
                      height: 'auto',
                      minHeight: 'auto',
                      maxHeight: '496px',
                      width: '328px',
                      position: 'relative',
                      overflowY: 'auto'
                    }}
                  >

                    <Timeline
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 10px'
                      }}
                    >

                      {historyArray
                        ?.filter((hData)=> hData.history.history_type==="Task")
                        ?.filter((hData)=> hData.history.task_id===taskData.taskId)
                        ?.slice(0, 4)
                        ?.map((row, index) => {

                          return (

                            <Timeline.Item>
                              <Timeline.Badge sx={{
                                background: 'white',
                                position: 'relative',
                                margin: '0px 0px 0px -11px',
                                height: '20px',
                                width: '20px',
                              }}>

                                {row.user_image_url === "" || row.user_image_url == null ?

                                  <Box sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    margin: '0px auto 0px auto',
  
                                  }}>
                                    <FeedPersonIcon size={25} fill="#586069" />
                                  </Box>
                                  :

                                  <Avatar size={25} sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    margin: '0px auto 0px auto',
  
                                  }} src={row.user_image_url} />
                                }

                                {/* <Avatar size={25} sx={{
                                  display: 'flex',
                                  position: 'relative',
                                  margin: '0px auto 0px auto',

                                }} src={row.image} /> */}
                              </Timeline.Badge>
                              <Timeline.Body
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'left',
                                  position: 'relative',
                                  margin: '0px 0px 0px 8px',
                                  fontFamily: 'Segoe UI',
                                }}
                              >
                                <Tooltip
                                aria-label={row.user_first_name+row.user_last_name}
                                direction={index===0?"s":"n"}
                                >
                                <Link href="#"
                                  sx={{
                                    fontWeight: 'bold',
                                    color: 'fg.default',
                                    mr: 1,
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px'
                                  }}
                                  muted>
                                  {(row.user_first_name+row.user_last_name).substring(0,8)}
                                </Link>
                                </Tooltip>
                               
                                <Link
                                  href="#"
                                  sx={{
                                    fontWeight: 'bold',
                                    color: 'fg.default',
                                    position: 'relative',
                                    margin: '0px 0px 0px 4px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',

                                  }}
                                  muted>
                                  <Tooltip
                                    aria-label={row.history.history_data}
                                    wrap={true}
                                    align="left"
                                    direction={index === 0 ? "s" : "n"}
                                    
                                  >
                                    {row.history.history_data.substring(0, 25)}
                                  </Tooltip>
                                </Link>
                               
                                 
                                <Link
                                  href="#"
                                  color="fg.muted"
                                  muted
                                  sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    position: 'relative',
                                    margin: '0px 0px 0px 9px',
                                  }}
                                >
                                 
                                  {

                                    "" + getNumberofDays(new Date(), new Date(row.history.history_timestamp)) == 0 ? "today" :

                                      getNumberofDays(new Date(), new Date(row.history.history_timestamp)) + " days ago"
                                      + ""
                                  }
                                </Link>
                              </Timeline.Body>
                            </Timeline.Item>

                          )


                        })}





                    </Timeline>


                  </Box>


                </Box>



              </ActionMenu.Overlay>

            </ActionMenu>





            <ActionMenu>
              <ActionMenu.Anchor>
                <IconButton
                  size="small"
                  variant="invisible"
                  sx={
                    {
                      background: '#ffffff',
                      color: '#000000',
                      border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      margin: '0px 0px 0px 0px'
                    }
                  }

                  icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}
                />

              </ActionMenu.Anchor>

              <ActionMenu.Overlay>

                <ActionList
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '2px',
                    padding: '8px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '##24292F'
                  }}
                >
                  <ActionList.Item

                  >Add to Roadmap</ActionList.Item>
                  <ActionList.Item>Go to Rules</ActionList.Item>

                  <ActionList.Item
                  onSelect={e=>{
                    duplicateTaskInDb("")
                  }}
                  >Duplicate task</ActionList.Item>
                  <Box
                    borderColor="border.default"
                    borderBottomWidth={1}
                    borderBottomStyle="solid"
                    width={"100%"}
                    height={"1px"}
                  >

                  </Box>
                  <ActionList.Item
                    variant="danger"
                    onSelect={() => handleDeleteTask("")}
                  >Delete task</ActionList.Item>
                </ActionList>

              </ActionMenu.Overlay>

            </ActionMenu>



            <IconButton
              size="small"
              variant="invisible"
              sx={
                {
                  background: '#ffffff',
                  color: '#000000',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  margin: '0px 0px 0px 0px'
                }
              }
              onClick={() => closeCreateTask("")}
              icon={() => <Cross2Icon style={{ display: 'flex' }} />}

            />



          </Box>
        </Box>
      </Box>
    )
  }


  const Details = (props) => {


    const styleMap = {
      'H1': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
      'BOLD': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
      'ITALIC': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
      'UNDERLINE': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
      'UL': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
      'CODEBLOCK': {
        color: '#202428',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
      },
    }


    return (<Box sx={{
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: '20px 0px 0px 0px',

    }}>

      <Box
        sx={{
          minHeight: detailInputFocus === true ? '90px' : '30px',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          color: '#202428'

        }}
        borderColor={detailInputOutline === true ? "#0969DA" : "border.default"}
        borderWidth={detailInputOutline === true ? 2 : 1}
        borderStyle="solid"
        borderRadius={"3px"}
        paddingTop={taskData.expandWidth === true ? "5px" : '10px'}
        paddingLeft={"12px"}
      >
        <Editor
          onFocus={() => {
            setdetailInputOutline(true)
            setdetailInputFocus(true)
            setdetailInputIncreaseHeight(true)
          }
          }


          onBlur={() => {

            const currentContent = editorState.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;

            if (currentContentLength > 0) {
              setdetailInputOutline(false)
              setdetailInputFocus(true)
            } else {
              setdetailInputOutline(false)
              setdetailInputFocus(false)
            }




          }}


          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          placeholder="Type Description here"
        />
      </Box>



      <Box style={{ display: 'flex', flexDirection: 'row', position: 'relative', margin: '20px auto 0px auto', gap: '16px' }}>

        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <HeadingIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("H1")}
        />
        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <TextIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("paragraph")}

        />
        <DividerVerticalIcon style={{
          position: 'relative',
          margin: 'auto 0px auto 0px',
          display: 'flex'
        }} />
        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <FontBoldIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("BOLD")}
        />
        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <FontItalicIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("ITALIC")}
        />


        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <UnderlineIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("UNDERLINE")}
        />

        <DividerVerticalIcon style={{
          position: 'relative',
          margin: 'auto 0px auto 0px',
          display: 'flex'
        }} />

        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <ListBulletIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("UL")}
        />

        <IconButton
          size="small"
          variant="invisible"
          sx={
            {
              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 0px'
            }
          }

          icon={() => <CodeIcon style={{ display: 'flex' }} />}
          onClick={() => makeBoldInputText("CODEBLOCK")}
        />







      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        position: 'relative',
        margin: '20px 0px 0px -5px',
        height: 'fit-content',
        //   background:'yellow'
      }}>

        {attachmentArray.map((attachment, index) => {

          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <IconButton
                size="small"
                variant="invisible"
                sx={
                  {
                    background: '#ffffff',
                    color: '#000000',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    margin: '0px 0px 0px 0px'
                  }
                }

                icon={() => <DownloadIcon style={{ display: 'flex' }} />}
                onClick={async()=>{
                  const response = await fetch(attachment.attachment_url);
                  const blob = await response.blob();
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = attachment.attachment_file_name;
                  document.body.appendChild(link);
                  link.click();
                }} 
              />


              <Text sx={{
                position: 'relative',
                margin: '0px 0px 0px 10px',
                display: 'flex',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#121212'
              }}>
                {attachment.attachment_file_name.substring(0,50)}
              </Text>
              <Text sx={{
                position: 'relative',
                margin: '0px 0px 0px 5px',
                display: 'flex',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontSize: '10px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#586069'
              }}>
                {attachment.attachment_size}
              </Text>

              <IconButton
                size="small"
                variant="invisible"
                sx={
                  {
                    background: '#ffffff',
                    color: '#000000',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    margin: '0px 0px 0px 0px'
                  }
                }

                icon={() => <Cross2Icon style={{ display: 'flex' }} />}
                onClick={() =>

                   deleteTaskAttachmentIndb(attachment.attachment_id)

                  

                }
              />
            </Box>
          )
        })}


        <ActionMenu>
          <ActionMenu.Anchor>

            <Button
              variant="invisible"
              sx={{
                width: '140px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#000000',
                position: 'relative',
                paddingLeft: '12px',

              }}
              leadingIcon={() => <Link1Icon style={{ display: 'flex', marginRight: '0px' }} />}>
              <Text sx={{ position: 'relative', margin: '0px 8px 0px 8px' }}>
                Add attachment
              </Text>
              <input ref={computerFileRef} type={"file"} onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />
            </Button>

          </ActionMenu.Anchor>

          <ActionMenu.Overlay>

            <ActionList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '8px',
                gap: '2px'
              }}
            >
              <ActionList.Item
                sx={{
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#24292F'
                }}
                onClick={() => { computerFileRef.current.click() }}
              >Your computer


              </ActionList.Item>
              <ActionList.Item
                sx={{
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#24292F'
                }}

                onClick={()=>{
                     pickGoogleDriveFilePicker()
                }}

              >Google drive</ActionList.Item>
              <ActionList.Item
                sx={{
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#24292F'
                }}
                onSelect={(e)=>{
                  launchDropboxPicker("")
                }}
              >Dropbox</ActionList.Item>
              <Box
                borderColor="border.default"
                borderBottomWidth={1}
                borderBottomStyle="solid"
                width={"100%"}
                height={"1px"}
              >

              </Box>
              <ActionList.Item
                sx={{
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#24292F'
                }}
                onSelect={()=>{
                  launchOneDrivePicker("")
                }}
              >One drive</ActionList.Item>
            </ActionList>

          </ActionMenu.Overlay>



        </ActionMenu>



      </Box>


      <Box
        borderColor="border.default"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        sx={{
          width: '100%',
          position: 'relative',
          margin: '20px 0px 0px 0px'
        }}>


      </Box>

      <Box

        sx={{
          position: 'relative',
          margin: '20px 0px 0px 0px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: 'fit-content'
        }}>

        <LapTimerIcon style={{ position: 'relative', margin: '0px 0px 0px 0px' }} />
        <Text sx={{
          position: 'relative',
          margin: '0px 0px 0px 16px',
          display: 'flex',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '20px',
          color: '#000000'
        }}>
          Subtask
        </Text>

        <ProgressBar sx={{
          width: taskData.expandWidth === true ? '527px' : '342px',
          minWidth: taskData.expandWidth === true ? '527px' : '342px',
          height: '4px',
          position: 'relative',
          margin: '0px 0px 0px 16px'
        }}
          bg="#1F75CB" progress={25}
        />
        <Text sx={{
          position: 'relative',
          margin: '0px 0px 0px 13px',
          display: 'flex',
          fontFamily: 'Segoe UI',
          fontStyle: 'normal',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '20px',
          color: '#000000'
        }}>
          25%
        </Text>

        <IconButton
          size="small"
          variant="invisible"
          sx={
            {

              background: '#ffffff',
              color: '#000000',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              margin: '0px 0px 0px 16px',
              width: '19px'
            }
          }

          icon={() => <PlusIcon style={{ display: 'flex' }} />}
          onClick={() => setshowSubtaskInput(true)}
        />




      </Box>


      {showSubtaskInput === true ?

        <Box sx={{
          width: taskData.expandWidth === true ? '685px' : '503px',
          minWidth: taskData.expandWidth === true ? '685px' : '503px',
          maxWidth: taskData.expandWidth === true ? '685px' : '503px',
          position: 'relative',
          margin: '0px 0px 0px 0px',
          display: 'flex',
          flexDirection: 'column',
        }}>


          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            margin: '25px 0px 0px 0px'
          }}>

            <TextInput
              autoFocus={showSubtaskInput === true ? true : false}
              sx={{
                width: taskData.expandWidth === true ? '643px' : '453px',
                position: 'relative',
                minHeight: '40px',
                maxHeight: '40px',
                height: '40px',
                borderRadius: '3px',
                fontFamily: 'Segoe UI',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px'
              }}
              placeholder="What need to be done ?"
              validationStatus={subtaskInputValidation}
              value={subTaskInput}
              onChange={(e) => {

                if (e.target.value.length > 0) {
                  setsubtaskInputValidation('')
                }

                setsubTaskInput(e.target.value)
              }}
            />


            <AnchoredOverlay
              align="end"
              side="outside-bottom"
              renderAnchor={anchorProps => (


                 selectedSubtaskAssigne===""?

                  <Box
                    {...anchorProps}
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      margin: '0px 0px 0px 6px',
                      cursor: 'pointer',
                    }}
                  >
                    <FeedPersonIcon
                      size={40}
                      fill="#586069"

                    />
                  </Box>:


                  spaceCollaborators?.filter((cData)=> cData?.user_id===selectedSubtaskAssigne)[0]?.user_image_url===""
                  ||
                  spaceCollaborators?.filter((cData)=> cData?.user_id===selectedSubtaskAssigne)[0]?.user_image_url==null
                  ?
                  
                    <Box
                      {...anchorProps}
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '0px 0px 0px 6px',
                        cursor: 'pointer',
                      }}
                    >
                      <FeedPersonIcon
                        size={40}
                        fill="#586069"

                      />
                    </Box>

                  :

                    <Avatar
                      {...anchorProps}
                      size={40}
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '0px 0px 0px 6px',
                        cursor: 'pointer',
                      }}
                      src={spaceCollaborators?.filter((cData)=> cData?.user_id===selectedSubtaskAssigne)[0]?.user_image_url} />

               






              )}
              open={subtaskAssigneActionMenu}
              onOpen={openSubtaskAssigneActionMenuOverlay}
              onClose={closeSubtaskAssigneWorkflowActionMenuOverlay}
              
            >

              <ActionList
                sx={{
                  width: '280px',
                }}

              >
                <ActionList.Group
                  sx={
                    {
                      background: '#f6f8fa'
                    }
                  }
                >
                  <ActionList.Item
                    sx={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      background: '#f6f8fa'
                    }}
                  >

                    <TextInput
                      sx={{
                        width: '93%',
                        marginLeft: '8px',
                        marginRight: '8px',
                        borderRadius: '3px',
                        fontFamily: 'Segoe UI',


                      }}
                      placeholder='Search users'
                      onChange={(e) => {

                          setsearchsubtaskCollaborators(e.target.value)
                      }}
                    />

                  </ActionList.Item>
                </ActionList.Group>
                <ActionList.Divider />
                <ActionList.Group
                  variant="subtle"
                  sx={{
                    maxHeight: '245px',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                  }}
                >

                  {spaceCollaborators
                  ?.filter((cData) => cData.user_first_name.toLocaleUpperCase().includes(searchsubtaskCollaborators.toUpperCase()))
                  .map((row, index) => {

                      return (
                        <ActionList.Item
                          sx={{
                            paddingLeft: '5px',
                            paddingRight: '0px',
                            fontFamily: 'Segoe UI'
                          }}

                          onSelect={() => {
                            setselectedSubtaskAssigne(row.user_id)
                            closeSubtaskAssigneWorkflowActionMenuOverlay()
                          }
                          }
                        >
                          <ActionList.LeadingVisual>

                            {
                              row.user_image_url === "" || row.user_image_url == null ?
                                <FeedPersonIcon size={20} fill="#586069" /> :
                                <Avatar src={row.user_image_url} />
                            } 

                            
                          </ActionList.LeadingVisual>
                          {row.user_first_name+" "+row.user_last_name}
                          <ActionList.Description
                            variant="block"
                          >
                            {row.user_email}
                          </ActionList.Description>
                        </ActionList.Item>

                      )

                    })}


                </ActionList.Group>


              </ActionList>

            </AnchoredOverlay>



          </Box>


          <Box sx={{
            position: 'relative',
            margin: '20px 0px 0px auto',
            display: 'flex',
            flexDirection: 'row',
            gap: '5px'
          }}>
            <Button

              sx={{
                height: '28px',
                width: '60px',
                border: 'none',
                borderRadius: '3px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#24292E',
                background: '#ffffff'
              }}
              onClick={() => setshowSubtaskInput(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outline"
              sx={{
                height: '28px',
                width: '60px',
                border: 'none',
                borderRadius: '3px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#ffffff',
                background: '#0969DA'
              }}
              onClick={() => {

                 if (subTaskInput === "") {
                     setsubtaskInputValidation('error')
                 }else {
                     postSubTaskToDb("")
                 }
              }}
            >
              Create
            </Button>

          </Box>


        </Box>


        : ""}

      <Box


        sx={{
          width: taskData.expandWidth === true ? '685px' : '503px',
          minWidth: taskData.expandWidth === true ? '685px' : '503px',
          maxWidth: taskData.expandWidth === true ? '685px' : '503px',
          position: 'relative',
          margin: showSubtaskInput === true ? '18px 0px 0px -9px' : '12px 0px 0px -9px',

        }}>



        <ActionList
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '13px'
          }}
        >

          {compliaceSubtasks
          // ?.sort(function(a,b){
          
          //    return new Date(b.created_timestamp) - new Date(a.created_timestamp);
          //  })
          ?.map((subTasks, index) => {

            return (

              <ActionList.Item

                onClick={() => {
                  
                //  setisSubTaskVisible(true)
                //  issueTextRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                 

                  // dispatch(createTaskOpenClose({
                  //   openClose: true,
                  //   expandWidth: false,
                  //   status: "View",
                  //   taskId: subTasks.task_id
                  // }))
               

                }

                }

                key={"a" + index}
              >

                <ActionList.LeadingVisual
                  sx={{

                    position: 'relative',
                    margin: '0px 0px 0px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >

                  <HamburgerMenuIcon style={{ display: 'flex' }} />




                </ActionList.LeadingVisual>

                <Text sx={{
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#202428',
                  position: 'absolute',
                  left: taskData.expandWidth === true ? '15px' : '15px',
                  top: '0px',
                  width: taskData.expandWidth === true ? '618px' : '434px',
                  overflow: 'hidden',
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: 'left',


                }}>
                  {subTasks.task_name}

                </Text>
                {/* <ActionList.TrailingVisual
                  sx={{
                    position: 'relative',
                    margin: taskData.expandWidth === true ? '0px -14px 0px auto' : '0px -11px 0px auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                  }}
                >
                  {subTasks.user_image_url===""||subTasks.user_image_url==null?
                  
                  <FeedPersonIcon size={28} fill="#586069"  />:
                  
                  <Avatar alt={subTasks.user_first_name} size={28} src={subTasks.user_image_url} />
                
                  }
                 
                </ActionList.TrailingVisual> */}
              </ActionList.Item>

            )

          })}






        </ActionList>










      </Box>

      <Box
        borderColor="border.default"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        sx={{
          width: '100%',
          position: 'relative',
          margin: '20px 0px 50px 0px'
        }}>


      </Box>



    </Box>)
  }


  const Activity = (props) => {

    return (<Box
       sx={{
        width: '100%',
        height: 'fit-content',
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '20px 0px 20px 0px'

      }}>


      <Box

        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          margin: '13px 0px 0px 0px',

        }}>

        <TextInput
          aria-label='activity-input'
          sx={{
            width: taskData.expandWidth === true ? '510px' : '398px',
            height: '32px',
            minHeight: '32px',
            fontFamily: 'Segoe UI',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            borderRadius: '3px'
          }}
          placeholder={activityInput.placeholder}
          value={activityInput.value}
          validationStatus={activityInput.validationStatus}
          onChange={(e) => setactivityInput((prevSate) => {

            const newState = {
              ...prevSate,
              value: e.target.value,
              placeholder: activityInput.placeholder
            }
            return newState
          })}
        />

        <Box sx={{
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          position: 'relative',
          margin: '0px 0px 0px 20px'
        }}>

          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '16px',
              border: 'none',
              background: activityInput.placeholder === "Meeting" ? 'rgba(175, 184, 193, 0.2)' : '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            icon={() => <PersonIcon style={{ display: 'flex' }} />}
            onClick={() => {
              setactivityInput((prevState) => {
                return ({
                  ...prevState, placeholder: "Meeting"
                })
              })
            }}
          />

          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '16px',
              border: 'none',
              background: activityInput.placeholder === "Mail" ? 'rgba(175, 184, 193, 0.2)' : '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            icon={() => <EnvelopeClosedIcon style={{ display: 'flex' }} />}
            onClick={() => {
              setactivityInput((prevState) => {
                return ({
                  ...prevState, placeholder: "Mail"
                })
              })
            }}

          />

          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '16px',
              border: 'none',
              background: activityInput.placeholder === "Visit" ? 'rgba(175, 184, 193, 0.2)' : '#ffffff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            icon={() => <SewingPinIcon style={{ display: 'flex' }} />}
            onClick={() => {
              setactivityInput((prevState) => {
                return ({
                  ...prevState, placeholder: "Visit"
                })
              })
            }}

          />

        </Box>



      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        margin: '20px 0px 0px 0px',
        gap: '15px'
      }}>




        <Button
          variant="invisible"
          sx={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            margin: '0px 0px 0px -15px',
            width: '117px',
            height: '32px',
          }}
          leadingIcon={() => <CalendarIcon style={{ display: 'flex', color: '#000000', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto', left: '16px' }} />}
          onClick={() => activitycalandarRef.current.showPicker()}
        >
          <Text
            sx={{
              fontFamily: 'Segoe UI',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '20px',
              color: selectedActivityDate === "" ? '#6A737D' : '#121212',
              textAlign: 'left',
              height: 'fit-content',
              display: 'flex',
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              margin: 'auto',
              left: '47px',


            }}
          >

            {selectedActivityDate === "" ? "Add date" :
              // selectedActivityDate
              new Date(selectedActivityDate).getDate() + "/" + (new Date(selectedActivityDate).getMonth() + 1) + "/" + new Date(selectedActivityDate).getFullYear()
            }
          </Text>

          <input
            type={"date"}
            ref={activitycalandarRef}
            onChange={(e) => {
              setselectedActivityDate(
                new Date(e.target.value)

              )
            }}
            style={{

              position: 'absolute',
              left: '1px',
              top: '2px',
              height: '30px',
              width: '80%',
              zIndex: '0',
              visibility: 'hidden'
            }}

          />

        </Button>


        <Button
          variant="invisible"
          sx={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            margin: '0px 0px 0px -15px',
            width: '117px',
            height: '32px',
          }}
          leadingIcon={() => <ClockIcon style={{ display: 'flex', color: '#000000', position: 'absolute', top: '0px', bottom: '0px', margin: 'auto', left: '16px' }} />}
          onClick={() => activityTimeRef.current.showPicker()}
        >
          <Text
            sx={{
              fontFamily: 'Segoe UI',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '20px',
             
              color: selectedActivityTime === "" ? '#6A737D' : '#121212',
              //  position: 'relative',
              //  margin: '0px 0px 0px 8px',
              textAlign: 'left',

              height: 'fit-content',
              display: 'flex',
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              margin: 'auto',
              left: '47px',

            }}
          >
            {selectedActivityTime === "" ? "Add time" : getAMPMfromTime(selectedActivityTime)}

          </Text>
          <input
            type={"time"}
            ref={activityTimeRef}
            onChange={(e) => {
                setselectedActivityTime(e.target.value)
              
            }}
            style={{

              position: 'absolute',
              left: '1px',
              top: '2px',
              height: '30px',
              width: '80%',
              zIndex: '0',
              visibility: 'hidden'
            }}

          />

        </Button>


        <ActionMenu>

          <ActionMenu.Anchor>

            <Button
              variant="invisible"
              sx={{
                color: '#000000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                margin: '0px 0px 0px -15px',

              }}
              leadingIcon={() => <CameraIcon style={{ display: 'flex', color: '#000000' }} />}
            >
              <Text
                sx={{
                  fontFamily: 'Segoe UI',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '20px',
                  color: '#6A737D',
                  position: 'relative',
                  margin: '0px 0px 0px 8px',
                  textAlign: 'left'
                }}
              >
                Schedule video call
              </Text>

            </Button>
          </ActionMenu.Anchor>
          <ActionMenu.Overlay>

            <ActionList
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '4px',
                margin: '8px'
              }}
            >
              <ActionList.Item

              >

                <Token
                  sx={{
                    position: 'relative',
                    margin: '0px 0px 0px 0px',
                    height: '22px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#24292F',
                    borderRadius: '3px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                  leadingVisual={() =>


                    <img
                      style={{
                        display: 'flex',
                        minWidth: '18px',
                        maxWidth: '18px',
                        minHeight: '14px',
                        maxHeight: '14px',
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />

                  }
                  text={
                    <Text
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 3px',

                      }}
                    >Google Meet
                    </Text>
                  }
                />

              </ActionList.Item>
              <ActionList.Item>

                <Token
                  sx={{
                    position: 'relative',
                    margin: '0px 0px 0px 0px',
                    height: '22px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#24292F',
                    borderRadius: '3px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                  leadingVisual={() =>


                    <img
                      style={{
                        display: 'flex',
                        minWidth: '15px',
                        maxWidth: '15px',
                        minHeight: '14px',
                        maxHeight: '14px',
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg" />

                  }
                  text={
                    <Text
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 5px',

                      }}
                    >Microsoft Meet
                    </Text>
                  }
                />

              </ActionList.Item>
              <ActionList.Item>

                <Token
                  sx={{
                    position: 'relative',
                    margin: '0px 0px 0px 0px',
                    height: '22px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#24292F',
                    borderRadius: '3px',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer'

                  }}
                  leadingVisual={() =>


                    <img
                      style={{
                        display: 'flex',
                        minWidth: '18px',
                        maxWidth: '18px',
                        minHeight: '18px',
                        maxHeight: '18px',
                        objectFit: 'fill',
                        position: 'relative',
                        margin: '0px 0px 0px -2px'
                      }}
                      src="https://www.vectorlogo.zone/logos/zoomus/zoomus-icon.svg" />

                  }
                  text={
                    <Text
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 4px',
                      }}
                    >Zoom Meet

                    </Text>
                  }
                />

              </ActionList.Item>



            </ActionList>

          </ActionMenu.Overlay>

        </ActionMenu>

      </Box>


      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '20px 0px 0px 0px'
      }}>

        <Box sx={{
          display: 'flex',
          position: 'relative',
          margin: '0px 0px 0px 0px',
          height: 'auto',
          // height:'77px',
          width: taskData.expandWidth === true ? '654px' : '501px',
          minWidth: taskData.expandWidth === true ? '654px' : '501px',
          maxWidth: taskData.expandWidth === true ? '654px' : '501px',

        }}>

          <Textarea




            rows={activityDescription.value.length === 0 ? 3 :
              taskData.expandWidth === true ? activityDescription.value.length <= 66 ? 3 : parseInt(activityDescription.value.length / 90) + 1 :
                activityDescription.value.length <= 66 ? 3 : parseInt(activityDescription.value.length / 66) + 1
            }
            resize="vertical"
            sx={{
              width: '100%',
              fontFamily: 'Segoe UI',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              borderRadius: '3px',

            }}
            value={activityDescription.value}
            onChange={(e) => {

              setactivityDescription((prevState) => {

                return ({
                  value: e.target.value
                })
              })

            }}
          />

        </Box>


        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          position: 'relative',
          margin: '17px 0px 0px auto'
        }}>

          <Button
            variant="invisible"
            sx={{
              width: '77px',
              height: '32px',
              fontFamily: 'Segoe UI',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '20px'
            }}
          >
            Cancel
          </Button>

          <Button

            onClick={() => {


              handlePostActivity("")

            }}
            variant="primary"
            sx={{
              width: '77px',
              height: '32px',
              fontFamily: 'Segoe UI',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '20px',

            }}>
            Submit
          </Button>

        </Box>



      </Box>


      <Box sx={{

        position: 'relative',
        margin: '30px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>


        {
          activityArray?.sort(function(a,b){
            return new Date(b.activity.activity_timestamp) - new Date(a.activity.activity_timestamp);
          }).map((row, index) => {

              return (

                <Box

                  onMouseEnter={() => {
                    setshowActivityGroupIcons(() => {
                      return ({ id: row.activity.activity_id, visibility: true })
                    })
                  }}
                  onMouseLeave={() =>
                    setshowActivityGroupIcons(() => {
                      return ({ id: "", visibility: false })
                    })
                  }

                  borderColor="border.default"
                  borderBottomWidth={1}
                  borderBottomStyle="solid"
                  borderTopWidth={1}
                  borderTopStyle="solid"
                  sx={{
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',

                  }}>




                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      margin: '20px 0px 0px 0px',
                      alignItems: 'center'
                    }}
                  >




                    <Box
                      sx={{
                        height: '30px',
                        width: '30px',
                        minHeight: '30px',
                        minWidth: '30px',
                        maxHeight: '30px',
                        maxWidth: '30px',
                        background: '#F1F8FF',
                        color: '#000000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '6px'
                      }}
                    >

                      {row.activity.activity_type === "Meeting" ?
                        <PersonIcon style={{ display: 'flex' }} />
                        :
                        row.activity.activity_type === "Mail" ?
                          <EnvelopeClosedIcon style={{ display: 'flex' }} />
                          :
                          <SewingPinIcon style={{ display: 'flex' }} />
                      }







                    </Box>



                    {row.activity.activity_date === "Invalid Date" ? "" :

                      <Token

                        sx={{
                          width: '127px',
                          minWidth: '127px',
                          maxWidth: '127px',
                          height: '22px',
                          padding: '0px',
                          background: '#0366D6',
                          borderRadius: '3px',
                          position: 'relative',
                          margin: '0px 0px 0px 20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#FFFFFF',
                          border: 'none',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '18px'
                        }}
                        text={
                          <Text
                            sx={{
                              width: '127px',
                              marginLeft: '13px'
                            }}
                          >
                            {new Date(row.activity.activity_date).getDate() + "-" + (new Date(row.activity.activity_date).getMonth() + 1) + "-" + new Date(row.activity.activity_date).getFullYear()}
                            {row.activity.activity_time === "" ? "" : " " + getAMPMfromTime(row.activity.activity_time) + " "}

                          </Text>
                        }
                      />

                    }








                    <Token
                      sx={{
                        position: 'absolute',
                        left: '199px',
                        // position: 'relative',
                        // margin: '0px 0px 0px 22px',
                        height: '22px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: '#24292F',
                        borderRadius: '3px',
                        background: '#F6F8FA',
                        border: 'none',

                      }}
                      leadingVisual={() =>

                        index === 0 ?
                          <img
                            style={{
                              display: 'flex',
                              minWidth: '18px',
                              maxWidth: '18px',
                              minHeight: '14px',
                              maxHeight: '14px',
                            }}
                            src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />
                          :
                          index === 1 ?
                            <img
                              style={{
                                display: 'flex',
                                minWidth: '15px',
                                maxWidth: '15px',
                                minHeight: '14px',
                                maxHeight: '14px',
                              }}
                              src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg" />
                            :
                            index === 2 ?
                              <img
                                style={{
                                  display: 'flex',
                                  minWidth: '18px',
                                  maxWidth: '18px',
                                  minHeight: '18px',
                                  maxHeight: '18px',

                                }}
                                src="https://www.vectorlogo.zone/logos/zoomus/zoomus-icon.svg" />
                              :
                              <img
                                style={{
                                  display: 'flex',
                                  minWidth: '18px',
                                  maxWidth: '18px',
                                  minHeight: '14px',
                                  maxHeight: '14px',
                                  objectFit: 'fill'
                                }}
                                src="https://upload.wikimedia.org/wikipedia/commons/9/9b/Google_Meet_icon_%282020%29.svg" />

                      }
                      text={
                        <Text
                          sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 3px',

                          }}
                        >
                          {
                            index === 0 ?
                              "Google Meet Meeting scheduled" :
                              index === 1 ?
                                "Microsoft Meet meeting scheduled" :
                                index === 2 ?
                                  "Zoom Meet meeting scheduled" :
                                  "Google Meet Meeting scheduled"
                          }

                        </Text>
                      }
                    />


                    <Box
                      sx={{
                        // display:'flex',
                        display: showActivityGroupIcons.id === row.activity.activity_id ? 'flex' : 'none',
                        flexDirection: 'row',
                        gap: '5px',
                        position: 'relative',
                        margin: '0px 0px 0px auto'
                      }}
                    >

                      <IconButton
                        variant="invisible"
                        aria-label="Search"
                        sx={{
                          height: '25px',
                          width: '25px',
                          background: '#FFFFFF',
                          color: '#000000',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}

                        onClick={() => {

                          activityDescriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

                          setactivityInput((preState) => {

                            return ({
                              activityId: row.activity.activity_id,
                              value: row.activity.activity_name,
                              placeholder: row.activity.activity_type
                              //   placeholder: index === 0 ? 'Meeting' : index === 1 ? 'Mail' : index === 2 ? 'Visit' : ""
                            })

                          })

                          setactivityDescription((preState) => {
                            return ({
                              value: row.activity.activity_description,
                              placeholder: ""
                            })
                          })

                          if (row.activity.activity_date !== "Invalid Date") {

                            setselectedActivityDate(new Date(row.activity.activity_date))
                          } else {

                            setselectedActivityDate("")
                          }

                          if (row.activity.activity_time !== "") {

                            setselectedActivityTime(row.activity.activity_time)
                          } else {
                            setselectedActivityTime("")

                          }








                        }}

                        icon={() => <Pencil1Icon style={{ display: 'flex' }} />}
                      />

                      <ActionMenu>
                        <ActionMenu.Anchor>
                          <IconButton
                            variant="invisible"
                            aria-label="Search"
                            sx={{
                              height: '25px',
                              width: '25px',
                              background: '#FFFFFF',
                              color: '#000000',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                            icon={() => <DotsHorizontalIcon style={{ display: 'flex' }} />}
                          />
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay
                          align="end"

                        >
                          <ActionList
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '6px',
                              gap: '4px',
                              fontFamily: 'Segoe UI'
                            }}
                          >
                            <ActionList.Item
                              sx={{

                              }}
                            >
                              Copy
                            </ActionList.Item>
                            <Box borderColor="border.default"
                              borderBottomWidth={1}
                              borderBottomStyle="solid"
                              sx={{
                                width: '100%'
                              }}
                            >

                            </Box>
                            <ActionList.Item variant="danger"
                              onSelect={() =>


                                deleteTaskActivityToDb(row.activity.activity_id)

                              }
                            >
                              Delete
                            </ActionList.Item>
                          </ActionList>
                        </ActionMenu.Overlay>


                      </ActionMenu>



                    </Box>


                  </Box>


                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      margin: '19px 0px 0px 0px',

                    }}
                  >


                    <Tooltip aria-label={row.user_first_name+' '+row.user_last_name} direction="ne">
                    {row.user_image_url===""||row.user_image_url==null?
                    
                      <Box  sx={{
                        display: 'flex',
                        height: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                        width: '20px',
                        position: 'relative',
                        margin: '0px 0px 0px 8px'
                      }}><FeedPersonIcon size={20}  fill="#586069"/></Box>

                     :

                      <Avatar
                        size={20}
                        sx={{
                          display: 'flex',
                          height: '20px',
                          minWidth: '20px',
                          minHeight: '20px',
                          width: '20px',
                          position: 'relative',
                          margin: '0px 0px 0px 8px'
                        }}
                        src={row.user_image_url} />
                     
                     }

                     </Tooltip>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        margin: '0px 0px 0px 20px',

                      }}
                    >
                      <Text
                        sx={{
                          position: 'relative',
                          margin: '0px 0px 0px 0px',
                          height: 'fit-content',
                          minHeight: 'fit-content',
                          maxHeight: 'fit-content',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '17px',
                          lineHeight: '20px',
                          color: '#121212',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {row.activity.activity_name}
                      </Text>

                      <Text
                        sx={{
                          height: 'auto',
                          minHeight: 'auto',
                          maxHeight: 'auto',
                          position: 'relative',
                          margin: '20px 0px 20px 0px',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#202428',
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',

                        }}
                      >
                        {row.activity.activity_description}
                      </Text>

                    </Box>

                  </Box>



                </Box>
              )

            })
        }





      </Box>




    </Box>)
  }

  const Comments = (props) => {

    return (<Box sx={{
      width: '100%',
      height: 'fit-content',
      minHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: '20px 0px 0px 0px'
    }}>


      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        margin: '13px 0px 0px 0px',
        height: 'auto'
      }}>


        <Textarea



          rows={commentInput.length === 0 ? 1 :
            taskData.expandWidth === true ?
              commentInput.length <= 96 ? 1 :
                parseInt(commentInput.length / 96) + 1 :
              commentInput.length <= 66 ? 1 :
                parseInt(commentInput.length / 66) + 1
          }

          resize="vertical"
          sx={{
            width: taskData.expandWidth === true ? '651px' : '518px',
            minHeight: '40px',
            maxHeight: 'auto',
            fontFamily: 'Segoe UI',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            borderRadius: '3px'
          }}
          placeholder="Ask questions or post update"
          value={commentInput}
          onChange={handleCommentInputChange}
          onKeyDown={(e) => handleCommentInputOnkeyDown(e)}
        />



      </Box>

      <ActionList sx={{

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        position: 'relative',
        margin: '20px 0px 0px 0px',

      }}>


        {commentArray
          ?.sort(function(a,b){
            
            return new Date(b.comments.comment_timestamp) - new Date(a.comments.comment_timestamp);
          })
          .map((row, index) => {

          return (

            <Box

              onMouseEnter={() => {
                setshowCommentGroupIcons(() => {
                  return ({ id: row.comments.comment_id, visibility: true })
                })
              }}
              onMouseLeave={() =>
                setshowCommentGroupIcons(() => {
                  return ({ id: "", visibility: false })
                })
              }
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                margin: '0px 0px 0px 0px',
                width: '100%',
                cursor: 'pointer'
                // background:'red'
              }}>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                margin: '0px 0px 0px 0px'
              }}>

                 

                {row.user_image_url===""||row.user_image_url==null?
                  
                  <Box  sx={{
                    display: 'flex',
                    height: '20px',
                    minWidth: '20px',
                    minHeight: '20px',
                    width: '20px',
                    position: 'relative',
                    margin: '0px 0px 0px 0px'
                  }}>
                    <FeedPersonIcon size={20}  fill="#586069"/>
                    </Box>
                  :
                   
                  <Avatar size={20} src={row.user_image_url}  />
                 }

               

                
                <Text sx={{
                  position: 'relative',
                  margin: '0px 0px 0px 10px',
                  fontFamily: 'Segoe UI',
                  fontWeight: '600',
                  fontStyle: 'normal',
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#000000',
                  fontWeight: '600'
                }}>
                  {row.user_first_name+" "+row.user_last_name}
                   
                </Text>

                <Box
                  sx={{
                    position: 'relative',
                    margin: '0px 0px 0px auto',
                    display: showCommentGroupIcons.id === row.comments.comment_id ? 'flex' : 'none',
                    flexDirection: 'row',
                    gap: '11px'
                  }}>

                  <IconButton

                    variant="invisible"
                    sx={{
                      height: '20px',
                      width: '20px',
                      color: '#000000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    icon={() => <TrashIcon style={{ display: 'flex' }} scale={15} />}
                    onClick={() => {

                      deleteTaskCommentsToDb(row.comments.comment_id)
                    }}
                  />

                  <IconButton

                    variant="invisible"
                    sx={{
                      height: '20px',
                      width: '20px',
                      color: '#000000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={() => {
                      setcommentInput(row.comments.comment_message)
                      setcommentEditId(row.comments.comment_id)
                    }
                    }
                    icon={() => <Pencil1Icon style={{ display: 'flex' }} scale={15} />}
                  />

                  <Text
                    sx={{
                      position: 'relative',
                      margin: '0px 0px 0px 10px',
                      fontFamily: 'Segoe UI',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      lineHeight: '18px',
                      color: '#24292E',
                      fontWeight: '600'
                    }}
                  >
                    {

                      "" + getNumberofDays(new Date(), new Date(row.comments.comment_timestamp)) == 0 ? "today" :

                        getNumberofDays(new Date(), new Date(row.comments.comment_timestamp)) + " days ago"
                        + ""
                    }

                  </Text>

                </Box>



              </Box>

              <Text sx={{
                position: 'relative',
                margin: '6px 0px 0px 30px',
                width: taskData.expandWidth === true ? '619px' : '485px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                color: '#000000'
              }}>
                {row.comments.comment_message}
              </Text>


            </Box>


          )

        })}











      </ActionList>




    </Box>)
  }


  React.useEffect(() => {

    closeCreateTask("")

  }, [location.pathname])



   

  React.useEffect(() => {


    if (taskData.status === "Create") {

         setrandomTextEditable(true)
         taskNameRef.current.focus()

    }else {

      if (randomTextEditable === true) {

        setrandomTextEditable(false)
      }


    }

   

    //  else if(taskData.status==="View"){

    //       setrandomTextEditable(false)
    //  }


    //alert(taskData.status+randomTextEditable)

  }, [taskData.status])


  React.useEffect(()=>{

     if(randomTextEditable===true){

      taskNameRef.current.focus()
     }

  },[randomTextEditable])

  React.useEffect(() => {

    if(taskData.status === 'View'){

      setrandomTextEditable(false)
      getSpaceTaskFromDb(taskData.taskId)    
      getTaskProjectHistoryFromDb("") 
     // alert(randomTextEditable)
      // if(randomTextEditable===true){

      //    taskNameRef.current.focus()
      // }


     }



  },[taskData.taskId])


  React.useEffect(() => {

 
    reloadLabels("")


  }, [taskUpdateTrigger])


   
  React.useEffect(()=>{

    

    if(taskData.openClose===true){

      const projectId = localStorage.getItem("projectId")
      dispatch(getSingleWorkplaceProject({
        projectId: projectId
      })).then((data) => {
  
        
        setworkflowArray(data.payload.data[0]?.workflow)
       // setselectedWorkflow(data.payload.data[0]?.workflow[0]?.workflow_id)
        
      })

       
    }
    
     
    reloadLabels("")
    getSpaceCollaboratorFromDb("")


  },[taskData.openClose])


  React.useState(() => {


    getSpaceCollaboratorFromDb("")


    const projectId = localStorage.getItem("projectId")
    dispatch(getSingleWorkplaceProject({
      projectId: projectId
    })).then((data) => {

       
      setworkflowArray(data.payload.data[0]?.workflow)
      setselectedWorkflow(data.payload.data[0]?.workflow[0]?.workflow_id)
     // setroadMap(data.payload.data[0]?.project_roadmap)
    })

  })

 

  React.useEffect(()=>{


         if(taskData?.taskId!==null&&taskData.taskId!==""){

         // alert(taskData?.taskId+" "+taskData?.contactData?.contactId)
            if(taskData?.contactData?.contactId===''){
               updateTaskContactToDb("null")
            }else{

               updateTaskContactToDb(taskData?.contactData?.contactId)
            }

           
         }

      // 

  },[taskData.contactData.contactId])


  return (



    <Box
      zIndex={1}
      ref={issueTextRef}
      sx={{
        position: 'fixed',
        bottom: '0px',
        right: headerData.openClose === true ? '0px' : '65px',
        top: '50px',
        width: taskData.expandWidth === true ? '1090px' : '600px',
        background: 'white',
        display: taskData.openClose === true ? 'flex' : 'none',
        flexDirection: taskData.expandWidth === true ? 'row' : 'column',
        overflowY: 'auto',
        overflowX: 'hidden',


      }}>

       <Helmet>

       
       <script async defer src="https://apis.google.com/js/api.js"  onLoad={gapiLoaded()}></script>
       <script async defer src="https://accounts.google.com/gsi/client" onLoad={gisLoaded()}></script>
   


       <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="r1ex80p99zrn57f"></script>
       <script  type="text/javascript" src="https://js.live.net/v7.2/OneDrive.js"></script>
       </Helmet>


      <StaticHead />

      <Box

        
        position={"fixed"}
        right={191}
        top={100}
        bottom={0}


        //   marginLeft={173}
        // marginRight={98} 
        sx={{
          width: '328px',
          minWidth: '328px',
          maxWidth: '328px',
          height: 'auto',
          minHeight: 'auto',
          maxHeight: '534px',
          background: '#ffffff',
          border: '1px solid #D0D7DE',
          boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
          borderRadius: '6px',
          zIndex: '10',
          display: historyDialog === true ? "flex" : "none",
          flexDirection: 'column',

        }}
      >
        <Box borderColor="border.default"
          borderBottomWidth={1}
          borderBottomStyle="solid"
          sx={{
            height: '38px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: '0px 0px 0px 0px'
          }}
        >
          <Text
            sx={{
              position: 'relative',
              margin: '0px 0px 0px 13px',
              fontFamily: 'Segoe UI',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '18px',
              color: '#000000'
            }}
          >
            Activity
          </Text>


        </Box>

        <Box
          sx={{
            height: 'auto',
            minHeight: 'auto',
            maxHeight: '496px',
            width: '328px',
            position: 'relative',
            overflowY: 'auto'
          }}
        >

          <Timeline
            sx={{
              position: 'relative',
              margin: '0px 0px 0px 10px'
            }}
          >

            {historyArray.map((row, index) => {

              return (

                <Timeline.Item>
                  <Timeline.Badge sx={{
                    background: 'white',
                    position: 'relative',
                    margin: '0px 0px 0px -11px',
                    height: '20px',
                    width: '20px',
                  }}>
                    <Avatar size={25} sx={{
                      display: 'flex',
                      position: 'relative',
                      margin: '0px auto 0px auto',

                    }} src={row.image} />
                  </Timeline.Badge>
                  <Timeline.Body
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'left',
                      position: 'relative',
                      margin: '0px 0px 0px 8px',
                      fontFamily: 'Segoe UI',
                    }}
                  >
                    <Link href="#"
                      sx={{
                        fontWeight: 'bold',
                        color: 'fg.default',
                        mr: 1,
                        fontFamily: 'Segoe UI',
                        fontSize: '12px'
                      }}
                      muted>
                      {row.name}
                    </Link>
                    {row.activity} <Link
                      href="#"
                      sx={{
                        fontWeight: 'bold',
                        color: 'fg.default',
                        position: 'relative',
                        margin: '0px 0px 0px 4px',
                        fontFamily: 'Segoe UI',
                        fontSize: '12px',

                      }}
                      muted>
                      {row.spaceName}
                    </Link>
                    <Link
                      href="#"
                      color="fg.muted"
                      muted
                      sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '12px',
                        position: 'relative',
                        margin: '0px 0px 0px 9px',
                      }}
                    >
                      {row.date}
                    </Link>
                  </Timeline.Body>
                </Timeline.Item>

              )


            })}





          </Timeline>


        </Box>


      </Box>


      {/* <Dialog
             isOpen={true}
             onDismiss={() => sethistoryDialog(false)}
            // aria-labelledby="header-id"
             sx={{
                width: '320px',
                minWidth:'320px',
                maxWidth:'320px',
                height: '534px',
                minHeight:'534px',
                maxHeight:'534px',
               position: 'absolute',
               left:'104px',
               top: '40px',
               right:'0px',
               margin:'auto'
             }}
           >

             


           </Dialog> */}





      <Box

        sx={{
          position: 'absolute',
          top: '60px',
          left: '40px',
          right: '40px',
          display: 'grid',
          gap: '20px',
          gridTemplateColumns: taskData.expandWidth === true ? '655px 319px' : 'repeat(1, 522px)',
          // background:'red',




        }}>


        <Box

          sx={{
            gridColumn: taskData.expandWidth === true ? '1/2' : '1',
            gridRow: taskData.expandWidth === true ? '1' : '1',
            height: 'fit-content',
            width: taskData.expandWidth === true ? '655px' : '503px',

          }}

        >




          <Box

            sx={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              margin: '0px 0px 0px 0px',
              height: 'fit-content',
              //  background:'red' 
            }}>

            <IconButton
              sx={{
                height: '25px',
                width: '25px',
                position: 'relative',
                color: '#000000',
                margin: '0px 0px 0px -4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              variant="invisible"
              onClick={() => {

                setisSubTaskVisible(false)

                //alert(subTaskParentTaskId)
                dispatch(createTaskOpenClose({
                  openClose: true,
                  expandWidth: false,
                  status: "View",
                  taskId: subTaskParentTaskId
                }))
              
                 
             //   setRandomText(randomPara)
              }}
              icon={() =>

                isSubTaskVisible === true ? <ChevronLeftIcon style={{ display: 'flex' }} /> : <TextAlignJustifyIcon style={{ display: 'flex' }} />
              }
              size="small"

            />

            <Textarea


              rows={5}
              resize="none"
              sx={{
                position: 'relative',
                margin: '0px 0px 0px 22px',
                width: taskData.expandWidth === true ? '609px' : '470px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                color: '#121212',
                textAlign: 'left',
                borderRadius: '3px',
                display:randomTextEditable===true?"flex":'none'
              }}
              style={{
                width: taskData.expandWidth === true ? '609px' : '470px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                color: '#121212',
                textAlign: 'left',
                padding: '0px',

              }}

              ref={taskNameRef}
              value={randomText.substring(0, 250)}
              maxLength={250}
              //autoFocus={randomTextEditable}
            //  autoFocus={true}
              onChange={(e) => {
                updateTaskNameToDb(e.target.value)
                setRandomText(e.target.value)

              }}
              onPaste={(e) => {
                updateTaskNameToDb(e.target.value)
                setRandomText(e.target.value)
              }}
              onBlur={(e) => {

               // setrandomTextEditable(false)
                updateTaskNameToDb(e.target.value)
                setRandomText(e.target.value)
              }
              }
            />

            <Text

              sx={{
                position: 'relative',
                margin: '0px 0px 0px 15px',
                textAlign: 'left',
                width: taskData.expandWidth === true ? '620px' : '484px',
                minWidth: taskData.expandWidth === true ? '620px' : '484px',
                maxWidth: taskData.expandWidth === true ? '620px' : '484px',
                minHeight: 'auto',
                height: 'auto',
                maxHeight: 'auto',
                overflowWrap: 'break-word',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                color: '#121212',
                cursor: 'pointer',
                display:randomTextEditable===false?"flex":'none'
              }}
             
              onClick={() => {
                setrandomTextEditable(true)
                
              }}
             // className={showSkeleton===true?"bp4-skeleton":""}
            >
              {randomText.substring(0, 250)}  
           </Text>


            {/* {randomTextEditable === true ?

              <Textarea

                // rows={randomText.length === 0 ? 1 :
                //   taskData.expandWidth === true ? randomText.length <= 70 ? 1 : parseInt(randomText.length / 62) + 1 :
                //     randomText.length <= 42 ? 1 : parseInt(randomText.length / 42) + 1
                // }
                rows={5}
                resize="none"
                sx={{
                  position: 'relative',
                  margin: '0px 0px 0px 22px',
                  width: taskData.expandWidth === true ? '609px' : '470px',
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '20px',
                  color: '#121212',
                  textAlign: 'left',
                  borderRadius: '3px',

                }}
                style={{
                  width: taskData.expandWidth === true ? '609px' : '470px',
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '20px',
                  color: '#121212',
                  textAlign: 'left',
                  padding: '0px',

                }}

                value={randomText.substring(0, 250)}
                maxLength={250}
                autoFocus={randomTextEditable}
                onChange={(e) => {
                  updateTaskNameToDb(e.target.value)
                  setRandomText(e.target.value)

                }}
                onPaste={(e) => {
                  updateTaskNameToDb(e.target.value)
                  setRandomText(e.target.value)
                }}
                onBlur={(e) => {

                  setrandomTextEditable(false)
                  updateTaskNameToDb(e.target.value)
                  setRandomText(e.target.value)
                }
                }
              />
              :

              <Text

                sx={{
                  position: 'relative',
                  margin: '0px 0px 0px 15px',
                  textAlign: 'left',
                  width: taskData.expandWidth === true ? '620px' : '484px',
                  minWidth: taskData.expandWidth === true ? '620px' : '484px',
                  maxWidth: taskData.expandWidth === true ? '620px' : '484px',
                  minHeight: 'auto',
                  height: 'auto',
                  maxHeight: 'auto',
                  overflowWrap: 'break-word',
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '20px',
                  color: '#121212',
                  cursor: 'pointer',

                }}

                onClick={() => {
                  setrandomTextEditable(true)
                }}
              >
                {randomText.substring(0, 250)}
              </Text>

            } */}




          </Box>




        </Box>

        <Box sx={{
          gridColumn: taskData.expandWidth === true ? '2' : '1',
          gridRow: taskData.expandWidth === true ? '1/4' : '2',
          height: taskData.expandWidth === true ? 'fit-content' : 'fit-content',
          width: taskData.expandWidth === true ? '319px' : '503px',
          //  background:'green'
        }}>


          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            margin: '0px 0px 0px 0px',
            height: 'fit-content',
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            gap: '20px',
          }}>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: 'fit-content'
            }}>

              <PersonIcon style={{ position: 'relative', margin: 'auto 0px auto 0px' }} />
              <Text sx={{
                minWidth: '83px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212'
              }}>
                Assignee / User
              </Text>




              <AnchoredOverlay
                align="end"
                side="outside-bottom"
                renderAnchor={anchorProps => (

                  selectedAssigne === "Add assignee" ?

                    <Button
                      {...anchorProps}
                      sx={{
                        paddingRight: '27px',
                        position: 'relative',
                        margin: '0px 0px 0px 46px',
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#ffffff',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        color: '#6A737D',
                        border: 'none',
                        boxShadow: 'none'
                      }}

                    >
                      Assignee / User
                    </Button>

                    :
                    <Tooltip aria-label={selectedAssigne} direction="ne">
                      <Box
                        {...anchorProps}
                        sx={{
                          position: 'relative',
                          margin: '0px 0px 0px 64px',
                          height: '25px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}>

                        {selectedAssigneImage === "" || selectedAssigneImage == null ?


                          <FeedPersonIcon size={28} fill="#586069" />

                          :
                          <Avatar
                            size={28}
                            src={selectedAssigneImage} />
                        }

                        <Text
                          sx={{
                            display: 'flex',
                            height: '20px',
                            position: 'relative',
                            margin: '0px 0px 0px 7px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#24292E',
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}

                        >
                          {selectedAssigne.substring(0, 10)}
                        </Text>

                        <IconButton

                          sx={{
                            width: '15px',
                            height: '15px',
                            position: 'relative',
                            margin: 'auto 0px auto 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: 'none',
                            background: '#ffffff',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            borderTopRightRadius: '6px',
                            borderBottomRightRadius: '6px'

                          }}
                          icon={() => <TriangleDownIcon style={{ display: 'flex' }} />}
                        />



                      </Box>
                    </Tooltip> 






                )}
                open={showActionMenu}
                onOpen={openOverlay}
                onClose={closeOverlay}

              >

                <ActionList
                  sx={{
                    width: '280px',
                  }}

                >
                  <ActionList.Group
                    sx={
                      {
                        background: '#f6f8fa'
                      }
                    }
                  >
                    <ActionList.Item
                      sx={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        background: '#f6f8fa'
                      }}
                    >

                      <TextInput
                        sx={{
                          width: '93%',
                          marginLeft: '8px',
                          marginRight: '8px',
                          borderRadius: '3px',
                          fontFamily: 'Segoe UI',


                        }}
                        placeholder='Search users'
                        onChange={(e) => {


                          setsearchCollaborators(e.target.value)


                        }}
                      />

                    </ActionList.Item>
                  </ActionList.Group>
                  <ActionList.Divider />
                  <ActionList.Group
                    variant="subtle"
                    sx={{
                      maxHeight: '245px',
                      overflowX: 'hidden',
                      overflowY: 'auto'
                    }}
                  >

                    {spaceCollaborators
                      ?.filter((cData) => cData.user_first_name.toLocaleUpperCase().includes(searchCollaborators.toUpperCase()))
                      .map((row, index) => {

                        return (
                          <ActionList.Item
                            sx={{
                              paddingLeft: '5px',
                              paddingRight: '0px',
                              fontFamily: 'Segoe UI'
                            }}
                            // onClick={() => setselectedAssigne(row.name)}
                            onSelect={() => {

                              setselectedAssigne(row.user_first_name + " " + row.user_last_name)
                              setselectedAssigneImage(row.user_image_url)
                              updateTaskAssigneToDb(row.user_id)
                              setsearchCollaborators("")
                              closeOverlay()

                            }


                            }
                          >
                            <ActionList.LeadingVisual>
                              {row.user_image_url === "" || row.user_image_url == null ?
                                <FeedPersonIcon size={20} fill="#586069" />
                                :
                                <Avatar src={row.user_image_url} />
                              }

                            </ActionList.LeadingVisual>
                            {row.user_first_name + " " + row.user_last_name}
                            <ActionList.Description
                              variant="block"
                            >
                              {row.user_email}
                            </ActionList.Description>
                          </ActionList.Item>

                        )

                      })}


                  </ActionList.Group>


                </ActionList>

              </AnchoredOverlay>







            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: 'fit-content'
            }}>

              <CalendarIcon style={{ position: 'relative', margin: 'auto 0px auto 0px' }} />
              <Text sx={{
                minWidth: '83px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212',
                display: 'flex',
                alignItems: 'center'
              }}

              >
                Due date
              </Text>


              {selectedDueDate === "" ?

                <Button

                  sx={{
                    paddingRight: '27px',
                    position: 'relative',
                    margin: '0px 0px 0px 46px',
                    height: '25px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#ffffff',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    color: selectedDueDate === "" ? '#6A737D' : '#121212',
                    border: 'none',
                    boxShadow: 'none'
                  }}
                  onClick={() => dueDateCalandarRef.current.showPicker()}
                >
                  {selectedDueDate === "" ? "Add due date" : selectedDueDate}
                  <TextInput
                    ref={dueDateCalandarRef}
                    type={"date"}
                    sx={{
                      visibility: 'hidden',
                      height: '20px',
                      minHeight: '20px',
                      maxHeight: '20px',
                      position: 'absolute',
                      top: '5px',
                      left: '0px',

                      fontFamily: 'Segoe UI',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '20px',
                      borderRadius: '3px',
                      paddingTop: '4px'
                    }}
                    onChange={(e) => {



                      if (e.target.value === "") {

                        setselectedDueDate("")

                      } else {
                        setselectedDueDate(
                          new Date(e.target.value).getDate() + "/" + (new Date(e.target.value).getMonth() + 1) + "/" + new Date(e.target.value).getFullYear()
                        )
                        updateTaskDueDateToDb(new Date(e.target.value))

                      }


                    }}
                  />
                </Button>
                :

                <Box
                  sx={{
                    position: 'relative',
                    margin: '0px 0px 0px 64px',
                    height: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',

                  }}
                  onClick={() => dueDateCalandarRef.current.showPicker()}
                >


                  <Text
                    sx={{
                      display: 'flex',
                      height: '20px',
                      position: 'relative',
                      margin: '0px 0px 0px -1px',
                      fontFamily: 'Segoe UI',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '20px',
                      color: '#24292E',
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',

                    }}

                  >
                    {selectedDueDate}
                  </Text>

                  <IconButton

                    sx={{
                      width: '15px',
                      height: '15px',
                      position: 'relative',
                      margin: 'auto 0px auto 5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none',
                      background: '#ffffff',
                      borderTopLeftRadius: '0px',
                      borderBottomLeftRadius: '0px',
                      borderTopRightRadius: '6px',
                      borderBottomRightRadius: '6px'

                    }}
                    icon={() => <TriangleDownIcon style={{ display: 'flex' }} />}

                  />

                  <TextInput
                    ref={dueDateCalandarRef}
                    type={"date"}
                    sx={{
                      visibility: 'hidden',
                      height: '20px',
                      minHeight: '20px',
                      maxHeight: '20px',
                      position: 'absolute',
                      top: '5px',
                      left: '0px',

                      fontFamily: 'Segoe UI',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '20px',
                      borderRadius: '3px',
                      paddingTop: '4px'
                    }}
                    onChange={(e) => {


                      if (e.target.value === "") {

                        setselectedDueDate("")

                      } else {
                        setselectedDueDate(
                          new Date(e.target.value).getDate() + "/" + (new Date(e.target.value).getMonth() + 1) + "/" + new Date(e.target.value).getFullYear()
                        )
                        updateTaskDueDateToDb(new Date(e.target.value))
                      }




                    }}
                  />

                </Box>

              }







            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: 'fit-content'
            }}>

              <InputIcon style={{ position: 'relative', margin: 'auto 0px auto 0px' }} />
              <Text sx={{
                minWidth: '83px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212'
              }}>
                Contact
              </Text>

              {taskData.contactData.selectedContact === "Add contact" ?

                <Button

                  variant="invisible"
                  sx={{
                    height: '22px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    position: 'relative',
                    margin: '0px 0px 0px 46px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#6A737D',
                    // paddingLeft:'0px',
                  }}
                  onClick={() => {
                    showAddContactDialog("")
                     
                  }


                  }
                >
                  {taskData.contactData.selectedContact}
                </Button>

                :

                <Tooltip aria-label={taskData.contactData.selectedContact} direction="ne">
                <Box sx={{
                  height: '22px',
                  position: 'relative',
                  margin: '0px 0px 0px 62px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>

                  <Avatar square src={taskData.contactData.companyAvatar} />
                  <Text sx={{
                     
                    minWidth: 'auto',
                    maxWidth: taskData.expandWidth === true ? '80px' : '289px',
                    height: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: '#24292E',
                    position: 'relative',
                    margin: '0px 0px 0px 7px',
                    textAlign: 'left'
                  }}>
                    {taskData.contactData.selectedContact}

                  </Text>

                  <IconButton

                    sx={{
                      height: '15px',
                      width: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      margin: '0px 0px 0px 16px',
                      border: 'none',
                      background: '#ffffff'
                    }}
                    icon={() =>
                      <Cross2Icon
                        style={{ display: 'flex', color: '#000000' }}
                        onClick={() => clearContactData("")}
                      />
                    }
                  >

                  </IconButton>

                </Box>
                </Tooltip>


              }




            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: 'fit-content'
            }}>

              <LightningBoltIcon style={{ position: 'relative', margin: 'auto 0px auto 0px' }} />
              <Text sx={{
                minWidth: '83px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212'
              }}>
                Roadmap
              </Text>


              {roadMap?.length==0?
              
              <Button

              variant="invisible"
              sx={{
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                position: 'relative',
                margin: '0px 0px 0px 46px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#6A737D',
               
              }}
              onClick={() => {
                  showAddRoadMapDialog("")
              }


              }
            >
              {"Add Roadmap"}
            </Button>
              
              :

              <ActionMenu >

                <ActionMenu.Anchor >

                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      margin: '0px 0px 0px 62px',
                      cursor: 'pointer'
                    }}
                  >

                    {selectedRoadmap===""?
                      
                      <Button

                      variant="invisible"
                      sx={{
                        height: '25px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        position: 'relative',
                        margin: '0px 0px 0px -16px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#6A737D',
                       
                      }}
                      
                    >
                      {"Add Roadmap"}
                    </Button>
                    :
                    <Token
                      size="large"
                      sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        height: '25px',
                        border: 'none',
                        background:'#DCF487',
                        borderRadius: '0px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '12px',
                        fontWeight: '400',
                        lineHeight: '16px',
                        textOverflow: "ellipsis",
                        color: '#24292F',
                        overflow: 'hidden',
                        borderRadius: '3px',
                        cursor: 'pointer'
                      }}
                      text={selectedRoadmap}

                    >
                       </Token>
                    
                    }

                    

                   

                    <IconButton
                      sx={{
                        width: '15px',
                        height: '15px',
                        position: 'relative',
                        margin: 'auto 0px auto 2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none',
                        background: '#ffffff',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px'

                      }}
                      icon={() => <TriangleDownIcon style={{ display: 'flex' }} />}
                    />
                  </Box>

                </ActionMenu.Anchor>

                <ActionMenu.Overlay
                  align="end"
                >

                  <ActionList>

                    {roadMap?.map((row, index) => {

                      return (
                        <ActionList.Item

                          onSelect={() => {
                            setselectedRoadmap(row.roadmap_name)
                            updateRoadMapToDb(row.roadmap_id)
                          }}
                          sx={{
                            width: '170px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                          
                          }}
                        >
                         
                          <Text
                          sx={{
                            width:'170px',
                            fontSize:'14px',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                            whiteSpace:'nowrap',
                            display:'inline-block'
                          }}
                          >
                          {row.roadmap_name}
                          </Text>
                        </ActionList.Item>
                      )
                    })}
                    <ActionList.Divider />
                    <ActionList.Item

                          onSelect={() => {

                             showAddRoadMapDialog("")

                          }}
                          sx={{
                            width: '170px',
                            height:'19px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px'
                          }}
                        >
                          <ActionList.LeadingVisual>
                              <PlusIcon/>
                          </ActionList.LeadingVisual>
                               Add new
                        </ActionList.Item>
                     

                  </ActionList>

                </ActionMenu.Overlay>

              </ActionMenu>
              
              }

           
              





            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'fit-content',
              width: 'fit-content'
            }}>

              <BookmarkIcon style={{ position: 'relative', margin: 'auto 0px auto 0px' }} />
              <Text sx={{
                minWidth: '83px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212',

              }}>
                Priority
              </Text>


              <ActionMenu>

                <ActionMenu.Anchor>
                  <Button
                    variant="invisible"
                    sx={{
                      height: '20px',
                      position: 'relative',
                      margin: '0px 0px 0px 46px',
                      color: '#121212',
                      fontFamily: 'Segoe UI',
                      fontStyle: 'normal',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '16px',
                      display: 'flex',
                      alignItems: 'center'
                    }}

                    leadingIcon={() =>

                      priorityArray[selectedPriority].name === "Normal" ?
                        <img src={normalIcon} style={{ display: 'flex' }} /> :
                        priorityArray[selectedPriority].name === "Medium" ?
                          <img src={mediumIcon} style={{ display: 'flex' }} /> :
                          priorityArray[selectedPriority].name === "High" ?
                            <img src={highIcon} style={{ display: 'flex' }} /> :
                            priorityArray[selectedPriority].name === "Critical" ?
                              <img src={criticalIcon} style={{ display: 'flex' }} /> :
                              priorityArray[selectedPriority].name === "Low" ?
                                <img src={lowIcon} style={{ display: 'flex' }} />
                                : ""
                    }
                  >
                    {priorityArray[selectedPriority].name}
                  </Button>
                </ActionMenu.Anchor>
                <ActionMenu.Overlay>
                  <ActionList
                    selectionVariant="single"
                    sx={{
                      width: '215px'
                    }}

                  >
                    {priorityArray.map((type, index) => (
                      <ActionList.Item

                        sx={{
                          width: '184px',
                          fontFamily: 'Segoe UI',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '16px',
                          color: '#333238'
                        }}
                        key={index}

                        onSelect={() => {
                          setselectedPriority(index)
                          updateTaskPriorityToDb(type.name)
                        }
                        }>
                        <ActionList.LeadingVisual>


                          {type.name === "Normal" ?
                            <img src={normalIcon} /> :
                            type.name === "Medium" ?
                              <img src={mediumIcon} /> :
                              type.name === "High" ?
                                <img src={highIcon} /> :
                                type.name === "Critical" ?
                                  <img src={criticalIcon} /> :
                                  type.name === "Low" ?
                                    <img src={lowIcon} />
                                    : ""}

                        </ActionList.LeadingVisual>
                        {type.name}
                      </ActionList.Item>
                    ))}
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>






            </Box>

            <Box
              ref={activityDescriptionRef}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: 'fit-content',
                width: 'fit-content',



              }}>

              <DrawingPinIcon style={{ position: 'relative', margin: '4px 0px 0px 0px' }} />
              <Text sx={{
                minWidth: '83px',
                height: '22px',
                position: 'relative',
                margin: '0px 0px 0px 16px',
                textAlign: 'left',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#121212'
              }}>
                Label
              </Text>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                margin: '0px 0px 0px 62px',
                gap: '9px',

              }} >


                <AnchoredOverlay
                  renderAnchor={anchorProps => (
                    <Button
                      {...anchorProps}
                      sx={{
                        height: '20px',
                        width: '20px',
                        minWidth: '20px',
                        minHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#6A737D',
                        position: 'relative',
                        margin: '0px 0px 0px -5px',
                        border: "none",
                        boxShadow: 'none',
                        background: '#ffffff',
                        borderRadius: '3px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                  open={labelsActionMenu}
                  onOpen={openLablsActionMenuOverlay}
                  onClose={closeLabelsActionMenuOverlay}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    maxWidth="265px"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',

                    }}
                  >
                    <TextInput
                      sx={{
                        position: 'relative',
                        margin: '8px 8px 0px 8px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        height: '32px',
                        borderRadius: '3px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}
                      maxLength={20}
                      placeholder='Search label'
                      value={createLabel}
                      onChange={(e) => setcreateLabel(e.target.value)}
                    />
                    <Box
                      borderColor="border.default"
                      borderBottomWidth={1}
                      borderBottomStyle="solid"
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        margin: '8px 0px 0px 0px'
                      }}
                    />

                    <ActionList sx={{

                      display: createLabel.length > 0 ? "flex" : "flex",
                      flexDirection: 'column',
                      alignItems: 'center',
                      position: 'relative',
                      margin: '8px'

                    }}>

                      {labels?.filter((label) => label.label_name.toLocaleUpperCase().includes(createLabel.toLocaleUpperCase()))
                        ?.slice(0, 6)
                        .map((row, index) => {

                          const checkInSelectedLabels = selectedLabels?.find((lData) => lData.label_name === row.label_name)

                          if (checkInSelectedLabels) {



                          } else {

                            return (
                              <ActionList.Item
                                sx={{

                                  fontFamily: 'Segoe UI',
                                  fontWeight: '400',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  color: '#24292F'
                                }}
                                onClick={() => {

                                  updateTaskLabelsToDb(row.label_name)

                                  closeLabelsActionMenuOverlay()
                                }}
                              >

                                {row.label_name}
                              </ActionList.Item>
                            )

                          }







                        })

                      }


                      <ActionList.Item
                        sx={{
                          display:
                            labels
                              ?.filter((label) => label.label_name.toLocaleUpperCase().includes(createLabel.toLocaleUpperCase()))
                              .length === 0
                              ? 'flex' : 'none',
                          // marginTop:'6px'
                        }}
                        onClick={() => {

                          if (createLabel.length > 0) {

                            closeLabelsActionMenuOverlay()
                            updateTaskLabelsToDb(createLabel)
                            setcreateLabel("")

                          }
                        }}
                      >
                        <Text sx={{
                          position: 'relative',
                          //  margin:'0px 0px 0px 16px',
                          margin: '0px 0px 0px 0px',
                          fontFamily: 'Segoe UI',
                          fontWeight: '400',
                          fontSize: '14px',
                          lineHeight: '20px',
                          color: '#24292F'
                        }}>
                          {createLabel.length > 0 ? "Create " + createLabel.substring(0, 8) : ""}

                        </Text>
                        <ActionList.TrailingVisual>⌘ + N</ActionList.TrailingVisual>


                      </ActionList.Item>

                    </ActionList>







                  </Box>


                </AnchoredOverlay>


                <Box sx={{
                  display: 'flex',
                  flexDirection: "column",
                  position: 'relative',
                  margin: '0px 0px 0px 0px',
                  gap: '9px',

                }}>

                  {

                    selectedLabels
                      ?.slice(0, 4)
                      .map((row, index) => {

                        return (



                          <Token
                            size="large"
                            sx={{
                              height: '25px',
                              width: 'fit-content',
                              border: 'none',
                              background: '#DDF4FF',
                              borderRadius: '3px',
                              fontFamily: 'Segoe UI',
                              fontStyle: 'normal',
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '16px',
                              textOverflow: "ellipsis",
                              overflow: 'hidden',
                              color: '#0969DA',

                            }}

                            text={
                              row.label_name.substring(0, 19)
                            }

                            onRemove={() => removeLabelToDb(row)}
                          />

                        )

                      })}


                </Box>







              </Box>



            </Box>





          </Box>



        </Box>

        <Box sx={{

          gridColumn: taskData.expandWidth === true ? '1' : '1',
          gridRow: taskData.expandWidth === true ? '2' : '3',
          height: taskData.expandWidth === true ? 'auto' : 'auto',
          width: taskData.expandWidth === true ? '655px' : '503px',
        }}


        >
          <Box sx={{ width: '100%', position: 'relative', margin: '0px' }}>
            <UnderlineNav aria-label="Repository" sx={{ border: 'none', paddingLeft: '0px', marginLeft: '-8px', marginTop: '-8px' }}>
              {items.map((item, index) => (
                <UnderlineNav.Item
                  sx={{
                    fontFamily: 'Segoe UI',
                    fontWeight: '400',
                    fontSize: '13px',
                    lineHeight: '20px',
                    color: '#121212'
                  }}
                  key={item.navigation}
                  icon={item.icon}
                  selected={index === selectedIndex}
                  onSelect={e => {
                    setSelectedIndex(index)
                    e.preventDefault()
                  }}

                >
                  {item.navigation}
                </UnderlineNav.Item>
              ))}
            </UnderlineNav>

            <Box sx={{ minHeight: 'fit-content' }}>

              {selectedIndex === 0 ?
                Details("") :
                selectedIndex === 1 ?
                  Activity("") :
                  selectedIndex === 2 ?
                    Comments("")
                    : ""
              }

            </Box>







          </Box>
        </Box>







      </Box>

     
      <AddContactDialog />
      <AddOrganization/>
      <AddOrganizationDirectors/>
      <AddOrganizationServices/>

      <CreateRoadDialog />

    </Box>
  )
}

export default CreateTask