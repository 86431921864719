import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const searchOpenClose = createAsyncThunk("searchOpenClose", async (data) => {


    return ({ openClose: data.openClose })

})

export const filesearchOpenClose = createAsyncThunk("filesearchOpenClose", async (data) => {

    return ({ fopenClose: data.fopenClose })

})

export const teamsearchOpenClose = createAsyncThunk("teamsearchOpenClose", async (data) => {

    return ({ teamopenClose: data.teamopenClose, projectId: data.projectId })

})

export const filteredFilesId = createAsyncThunk("filteredFilesId", async (data) => {

    return ({ filteredAttachmentId: data.filteredAttachmentId })
})

export const filteredTeamUserId = createAsyncThunk("filteredTeamUserId", async (data) => {

    return ({ teamUserId: data.teamUserId })
})

export const gstSearchDialogOpenClose = createAsyncThunk("gstSearchDialogOpenClose", async (data) => {
    return ({ gstsearchopenClose: data.gstsearchopenClose })
})

export const itSearchDialogOpenClose = createAsyncThunk("itSearchDialogOpenClose", async (data) => {
    return ({ itsearchopenClose: data.itsearchopenClose })
})


export const searchOpenCloseSlice = createSlice({

    name: 'searchOpenClose',
    initialState: {
        openClose: false,
        loading: false,
        fopenClose: false,
        teamopenClose: false,
        projectId: "",
        filteredAttachmentId: "",
        teamUserId: "",
        gstsearchopenClose: false,
        itsearchopenClose: false
    },
    extraReducers: {

        [searchOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [searchOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose

        },
        [searchOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [filesearchOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.fopenClose = false

        },
        [filesearchOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.fopenClose = action.payload.fopenClose

        },
        [filesearchOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.fopenClose = false
        },
        [teamsearchOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.teamopenClose = false

        },
        [teamsearchOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.teamopenClose = action.payload.teamopenClose
            state.projectId = action.payload.projectId

        },
        [teamsearchOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.teamopenClose = false
        },
        [filteredFilesId.pending]: (state, action) => {
            state.loading = true;
            state.filteredAttachmentId = ''

        },
        [filteredFilesId.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.filteredAttachmentId = action.payload.filteredAttachmentId


        },
        [filteredFilesId.rejected]: (state, action) => {
            state.loading = false
            state.filteredAttachmentId = ''
        },
        [filteredTeamUserId.pending]: (state, action) => {

            state.teamUserId = ''

        },
        [filteredTeamUserId.fulfilled]: (state, action) => {

            state.teamUserId = action.payload.teamUserId


        },
        [filteredTeamUserId.rejected]: (state, action) => {

            state.teamUserId = ''
        },
        //- --gst search open close --- 
        [gstSearchDialogOpenClose.pending]: (state, action) => {

            state.gstsearchopenClose = false

        },
        [gstSearchDialogOpenClose.fulfilled]: (state, action) => {

            state.gstsearchopenClose = action.payload.gstsearchopenClose
        },
        [gstSearchDialogOpenClose.rejected]: (state, action) => {

            state.gstsearchopenClose = false
        },
        //- --it search open close ---
        [itSearchDialogOpenClose.pending]: (state, action) => {

            state.itsearchopenClose = false

        },
        [itSearchDialogOpenClose.fulfilled]: (state, action) => {

            state.itsearchopenClose = action.payload.itsearchopenClose
        },
        [itSearchDialogOpenClose.rejected]: (state, action) => {

            state.itsearchopenClose = false
        }
    }


})


export default searchOpenCloseSlice.reducer

