import { BookIcon } from "@primer/octicons-react"
import { Box,Heading,Text } from "@primer/react"

const emptyImg = "https://d2fcqkt9srr3ti.cloudfront.net/fileEmptyImg.svg"

const EmptyData = (props) => {

    return (<Box 
        
        borderColor="border.default" 
        borderWidth={1} 
        borderStyle="solid"
        sx={{
        width:'fit-content',
        height:'fit-content',
        position: props.position,
        top: props.top,
        left: props.left,
        right: props.right,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height:'184px',
        width:'434px',
        borderRadius:'6px',
        background:'#FFFFFF'
    }}>

        <Box sx={{
            position:'relative',
            margin:'32px 0px 0px 0px'
        }}>
          <BookIcon size={24} />
        </Box>
        

       
        <Heading
            sx={{
                position: 'relative',
                margin: '18px 0px 0px 0px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontSize: '17px',
                fontWeight: '600',
                lineHeight: '36px',
                color: '#24292F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            The page you are looking for is not yet released
        </Heading>
        <Text sx={{
            width:'370px',
            height:'40px',
            position: 'relative',
            margin: '4px 0px 0px 0px',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#57606A',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign:'center'
        }}>
            It may be in progress or waiting to be released. Please check back later for updates.
        </Text>


    </Box>)
}

export default EmptyData