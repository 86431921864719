import { Box, Button,Breadcrumbs,Text } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../Space/EmptyData"
import Header from "./HeaderSanbox"
import SideNav from "./SideNavSanbox"
import { useDispatch,useSelector } from "react-redux"
import customTheme from "../../Theme.module.scss"  
import customThemVariable from  "../../ThemeVariable.module.scss" 
import { useNavigate } from "react-router-dom"
import { ChevronRightIcon } from "@primer/octicons-react"
import React from "react"
import TaxHeroHeader from "./TaxHeroHeaderSanbox"

function ItDemandSanbox(props){

    let hostName = window.location.hostname
    let host = window.location.host
    
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail) 

    React.useState(()=>{ })


    return(
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" ? 'IT - Demand - taxhero' : 'IT - Demand - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box sx={{
                top:'48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com"?'0px':'64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {
                        hostName === "www.app.taxherohq.com"?
                        navigate('/taxherosanbox')
                        :
                        navigate('/taxherosanbox')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>
                      
                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/itpanlistsanbox')
                    }}
                >
                    IT
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>
                      
                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {localStorage.getItem("panName")}

                </Text>

               
                  


            </Box>

            <EmptyData
                position="fixed"
                top="158px"
                left="0px"
                right="0px"
            />




        </Box>
    )

}

export default ItDemandSanbox