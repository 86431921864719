import React from 'react';
import { Dialog, Text, TextInput, Button, Box, ActionList,FormControl,Radio,Heading, ActionMenu, Link, Checkbox } from '@primer/react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { addOrganizationDirectorsOpenClose, addOrganizationOpenClose, addOrganizationServicesOpenClose, getOrganization, updateTempOrgData, uploadBulkComanyOpenClose } from '../../redux/features/contactSlice';
import { getZohoAuth } from '../../redux/features/taskSlice';



export default function AddOrganization(){

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
     
    const isAddOrganization = useSelector((state)=> state.contactProfileData.isAddOrganization )
    const initialFocusRef = React.createRef(null)

    const incorporationDatecalandarRef = React.createRef()

    const companyNameInputRef = React.createRef()

    const statesInIndia = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
      ];
      

    const [companyData,setcompanyData] = React.useState({
        org_name:"",
        org_name_validation:'',
        org_type:"",
        org_no:"",
        org_no_validation:"",
        org_state:"",
        org_state_validation:"",
        org_incorporation_date:"",
        org_incorporation_date_validation:"",
        org_pan:"",
        org_pan_validation:"",
        org_gstNo:"",
        org_additional_info:"",
        org_additional_info_validation:"",
        org_address_line1:"",
        org_address_line1_validation:"",
        org_address_line2:"",
        org_address_line3:"",
        org_city:"",
        org_state_province:"",
        org_postal_zipcode:"",
        isAddtoZohoCustomer:false
    })

    const [isZohoInstalled,setisZohoInstalled] = React.useState(false)

    const [directorsInfo,setdirectorsInfo] = React.useState([])
     
    const [companyType,setcompanyType] = React.useState("")
    const [orgTypeArray,setorgTypeArray] = React.useState([])

    const [addOrgAddressBtnVisibility,setaddOrgAddressBtnVisibility] = React.useState(true)

    const closeDialog = (props) => {
        
        dispatch(addOrganizationOpenClose({
            isAddOrganization:false
        }))
        setaddOrgAddressBtnVisibility(true)

        setcompanyData({
            org_name:"",
            org_name_validation:'',
            org_type:"",
            org_no:"",
            org_no_validation:"",
            org_state:"",
            org_state_validation:"",
            org_incorporation_date:"",
            org_incorporation_date_validation:"",
            org_pan:"",
            org_pan_validation:"",
            org_gstNo:"",
            org_additional_info:"",
            org_additional_info_validation:"",
            org_address_line1:"",
            org_address_line1_validation:"",
            org_address_line2:"",
            org_address_line3:"",
            org_city:"",
            org_state_province:"",
            org_postal_zipcode:"",
            isAddtoZohoCustomer:false
        })


    }


    const handleChangeInput = (props,e) => {

          if(props==="orgName"){

              setcompanyData((prevState)=>{
                   
                     const newState = {...prevState,org_name:e.target.value,org_name_validation:''}
                     return newState

              }) 
             
          }else if(props==="orgNo"){

             setcompanyData((prevState)=>{
                   
                const newState = {...prevState,org_no:e.target.value,org_no_validation:''}
                return newState

              }) 

          }else if(props==="orgState"){

              setcompanyData((prevState) => {

                  const newState = { ...prevState, org_state: e.target.value, org_state_validation: '' }
                  return newState

              }) 

         }else if(props==="orgIncorpDate"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_incorporation_date: new Date(e.target.value), org_incorporation_date_validation: '' }
                return newState

            }) 

         }else if(props==="orgPan"){

      
            if(e.target.value.length <=10){

                setcompanyData((prevState) => {

                    const newState = { ...prevState, org_pan: e.target.value, org_pan_validation: '' }
                    return newState
    
                }) 

            }

            

         }else if(props==="orgGstNo"){

            
              if (e.target.value.length <= 15) {

                  setcompanyData((prevState) => {

                      const newState = { ...prevState, org_gstNo: e.target.value }
                      return newState

                  })


              }

          
            
         }else if(props==="orgAdditionalInfo"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_additional_info: e.target.value, org_additional_info_validation: '' }
                return newState

            }) 

         }else if(props==="orgAddressline1"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_address_line1: e.target.value, }
                return newState

            }) 

         }else if(props==="orgAddressline2"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_address_line2: e.target.value, }
                return newState

            }) 

         }else if(props==="orgAddressline3"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_address_line3: e.target.value, }
                return newState

            }) 

         }else if(props==="orgCity"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_city: e.target.value, }
                return newState

            }) 

         }else if(props==="orgState"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_state: e.target.value, }
                return newState

            }) 

         }else if(props==="orgStateProvince"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_state_province: e.target.value, }
                return newState

            }) 

         }else if(props==="orgPostalZipCode"){

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_postal_zipcode: e.target.value, }
                return newState

            }) 

         }    
 

    }

    const showBulkCompanyUploadDialog = (props) => {

           closeDialog("")
           dispatch(uploadBulkComanyOpenClose({
              bulkCompanyOpenClose:true
           }))  
    
    }



    const hnadleNextButtonClick = (props) => {

          
        if (companyData.org_name === "") {

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_name_validation: 'error' }
                return newState
            })

        } else if (companyData.org_no === "") {

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_no_validation: 'error' }
                return newState
            })


        }else if (companyData.org_state === "") {

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_state_validation: 'error' }
                return newState
            })


        }else if (companyData.org_incorporation_date === "") {

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_incorporation_date_validation: 'error' }
                return newState
            })


        }else if (companyData.org_pan === "") {

            setcompanyData((prevState) => {

                const newState = { ...prevState, org_pan_validation: 'error' }
                return newState
            })


        } else {

            dispatch(updateTempOrgData({
                orgData:companyData
            }))

            dispatch(addOrganizationDirectorsOpenClose({
                isAddOrganizationDirectors: true
            }))

        }


        

    }

    const getOrganizationFromDb = (props) => {
         
         dispatch(getOrganization({
            userId:userId,
            userEmail:userEmail,
            projectId:localStorage.getItem("projectId")
         })).then((data)=>{
            
                  console.log("getOrganization",data.payload)
                  const orgTypeArray = [...data?.payload?.data.filter((oData)=> oData.org_name==="Company")[0]?.org_types]
                  setorgTypeArray(orgTypeArray)
                  setcompanyType(orgTypeArray[0]?.id) 
                  setcompanyData((prevState)=>{
                        const newState = {...prevState,org_type:orgTypeArray[0]?.id}
                        return newState
                  })

         })

    }

    
    const getZohoAuthFromDb = (props) => {

        dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

           
            console.log("getZohoAuth In Org Dialog", data.payload)
            const appCode = data?.payload?.checkApp?.code
            const refreshToken = data?.payload?.data?.refresh_token

            console.log("refreshToken",refreshToken)
            if (refreshToken !== null && refreshToken !== "" && refreshToken !==undefined) {

                setcompanyData((prevState) => {

                    return ({ ...prevState, isAddtoZohoCustomer: true })

                })

            }else{

                setcompanyData((prevState) => {

                    return ({ ...prevState, isAddtoZohoCustomer: false })

                })

            }

            if(appCode===200){
     
                setisZohoInstalled(true)

            }else if(appCode===404){

                setisZohoInstalled(false)
            }



        })

    }



 
    React.useEffect(()=>{

           if(companyNameInputRef.current!==null){
               companyNameInputRef.current.focus();
           }

    },[isAddOrganization])


     

    React.useEffect(()=>{

        if(isAddOrganization===true){

            setcompanyData({
                org_name:"",
                org_name_validation:'',
                org_type:"",
                org_no:"",
                org_no_validation:"",
                org_state:"",
                org_state_validation:"",
                org_incorporation_date:"",
                org_incorporation_date_validation:"",
                org_pan:"",
                org_pan_validation:"",
                org_gstNo:"",
                org_additional_info:"",
                org_additional_info_validation:"",
                org_address_line1:"",
                org_address_line1_validation:"",
                org_address_line2:"",
                org_address_line3:"",
                org_city:"",
                org_state_province:"",
                org_postal_zipcode:"",
                isAddtoZohoCustomer:false
            })

            dispatch(addOrganizationDirectorsOpenClose({
                isAddOrganizationDirectors: false
            }))

            
            dispatch(addOrganizationServicesOpenClose({
                isAddOrganizationServices: false
            }))

          
            getZohoAuthFromDb("")
        }
       
        getOrganizationFromDb("")
      

        
    },[isAddOrganization])




    return(
        <Dialog
            sx={{height:'508px',width:addOrgAddressBtnVisibility===true?'800px':'820px'}}
            isOpen={isAddOrganization}
            onDismiss={() => {
                closeDialog("")
            }}
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                

                <Heading 
                ref={initialFocusRef}
                sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F',
                  
                }}>
                    Add organization
                </Heading>

            </Dialog.Header>


            <Box sx={{
                position:'absolute',
                top:'52px',
                left:'0px',
                right:'0px',
                bottom:'52px',
                display:'flex',
                flexDirection:'column',
                overflowY:'auto',
                overflowX:'hidden'
            }}>

                <TextInput 
                ref={companyNameInputRef}
                sx={{
                  position:'relative',
                  margin:'25px 25px 0px 25px',   
                  minHeight:'40px',
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  minWidth:'748px',
                  maxWidth:'748px',
                  width:'748px',
                }}
                placeholder='Enter the name of organization/firm'
                onChange={(e)=> handleChangeInput("orgName",e)}
                validationStatus={companyData.org_name_validation}
                value={companyData.org_name}
                />

                <Box
                sx={{
                    position:'relative',
                    margin:'25px 25px 0px 25px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'24px'
                }}
                >

                    {orgTypeArray.map((row, index) => {
                        return (

                            <FormControl>
                                <Radio value={row.id} checked={companyType === row.id ? true : false}
                                    onChange={(e) => {
                                        setcompanyType(row.id)
                                        setcompanyData((prevState) => {

                                            const newState = { ...prevState, org_type: row.id }
                                            return newState

                                        })
                                    }

                                    } />
                                <FormControl.Label
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#24292E'
                                    }}
                                >{row.name}</FormControl.Label>
                            </FormControl>

                        )
                    })}

                    
                 
                </Box>

                <Box
                sx={{
                    position:'relative',
                    margin:'17px 25px 0px 25px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'8px'
                }}
                >

                    <TextInput
                         
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth:'368px',
                            maxWidth:'368px',
                            width:'368px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='LLPIN/CIN/Form INC-1 Ref No'
                        onChange={(e)=> handleChangeInput("orgNo",e)}
                        validationStatus={companyData.org_no_validation}
                        value={companyData.org_no}
                    />


                    <ActionMenu>
                        <ActionMenu.Anchor>


                            {/* <Button sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                minHeight: '40px',
                                minWidth: '204px',
                                maxWidth: '204px',
                                width: '204px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                background:'#ffffff',
                                display:'flex',
                                alignItems:'center',
                                paddingLeft:'8px'
                            }}>
                                State
                            </Button> */}

                            <TextInput

                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px',
                                    minHeight: '40px',
                                    minWidth: '204px',
                                    maxWidth: '204px',
                                    width: '204px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}
                                placeholder='State'
                                onChange={(e) => handleChangeInput("orgState", e)}
                                validationStatus={companyData.org_state_validation}
                                value={companyData.org_state}
                            />
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay>
                            <ActionList sx={{
                                minWidth: '204px',
                                maxWidth: '204px',
                                width: '204px',
                                display: 'flex',
                                height:'300px',
                                minHeight:'300px',
                                maxHeight:'300px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '6px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                gap:'2px'
                            }}>

                                {statesInIndia
                                    .map((row, index) => {
                                        return (
                                            <ActionList.Item
                                              sx={{
                                                display:'flex',
                                                alignItems:'center',
                                                minHeight:'28px'
                                              }}
                                              onSelect={()=>{
                                                   
                                                  setcompanyData((prevState) => {

                                                      const newState = { ...prevState, org_state: row, org_state_validation: '' }
                                                      return newState

                                                  }) 
                                                   
                                                
                                              }}
                                            >{row}</ActionList.Item>
                                        )
                                    })}

                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>

                   

                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '156px',
                            maxWidth: '156px',
                            width: '156px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Incorporation Date'
                      //  onChange={(e)=> handleChangeInput("orgIncorpDate",e)}
                        validationStatus={companyData.org_incorporation_date_validation}
                        value={companyData.org_incorporation_date!==""&&companyData.org_incorporation_date!==null?
                          ""+new Date(companyData.org_incorporation_date).getDate()+"/"+(new Date(companyData.org_incorporation_date).getMonth()+1)+"/"+new Date(companyData.org_incorporation_date).getFullYear()
                        :""}
                        onChange={(e) => 
                             
                            incorporationDatecalandarRef.current.showPicker()
                        }
                        onFocus={(e)=>  {
                            incorporationDatecalandarRef.current.showPicker()
                          }}
                    />

                    <input
                        type={"date"}
                        ref={incorporationDatecalandarRef}
                        onChange={(e) => {

                            if (e.target.value === "" || e.target.value == null) {


                               // handleChangeInput("orgIncorpDate", new Date())


                            } else {

                                handleChangeInput("orgIncorpDate", e)
                            }


                        }}
                        style={{
                            position: 'absolute',
                            right: '1px',
                            top: '2px',
                            height: '40px',
                            width: '20%',
                            zIndex: '0',
                            visibility: 'hidden'
                        }}

                    />


                </Box>

                <Box
                sx={{
                    position:'relative',
                    margin:'20px 25px 0px 25px',
                    display:'flex',
                    flexDirection:'row',
                    gap:'8px'
                }}
                >

                    <TextInput
                         
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth:'353px',
                            maxWidth:'353px',
                            width:'353px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='PAN'
                        onChange={(e)=> handleChangeInput("orgPan",e)}
                        validationStatus={companyData.org_pan_validation}
                        value={companyData.org_pan}
                    />

                    <TextInput
                        
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '385px',
                            maxWidth: '385px',
                            width: '385px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='GSTIN (optional)'
                        value={companyData.org_gstNo}
                        onChange={(e)=> handleChangeInput("orgGstNo",e) }
                    />

                    


                </Box>

                <Box sx={{
                    display:isZohoInstalled?"flex":'none',
                    position: 'relative',
                    margin: addOrgAddressBtnVisibility === true ? '20px 25px 0px 25px' : '20px 28px 0px 25px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                }}>
                    <FormControl>

                        <Checkbox value="true"
                            checked={companyData.isAddtoZohoCustomer}
                            onChange={(e) => {
                                setcompanyData((prevState) => {
                                    return ({ ...prevState, isAddtoZohoCustomer: e.target.checked })
                                })
                            }}

                        />
                        <FormControl.Label sx={{ fontWeight: '400' }}>
                            {/* Add to Zoho as a customer */}
                            Add as Zoho customer
                            </FormControl.Label>
                    </FormControl>
                </Box>

                <Box
                 sx={{
                    borderTopWidth: 1,
                    borderTopStyle: 'solid', 
                    borderColor: 'border.default', 
                    position:'relative',
                    margin:addOrgAddressBtnVisibility===true?'20px 25px 0px 25px':'20px 28px 0px 25px'
                 }}
                >

                </Box>

                <TextInput

                    sx={{
                        position: 'relative',
                        margin: '22px 25px 0px 25px',
                        minHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        minWidth: addOrgAddressBtnVisibility === true ? '748px' : '748px',
                        maxWidth: addOrgAddressBtnVisibility === true ? '748px' : '748px',
                        width: addOrgAddressBtnVisibility === true ? '748px' : '748px',
                    }}
                    placeholder='Additional information'
                    onChange={(e) => handleChangeInput("orgAdditionalInfo", e)}
                    value={companyData.org_additional_info}

                />
                

                <Button
                    variant="invisible"
                    sx={{
                        display:addOrgAddressBtnVisibility===true?"flex":"none",
                        position: 'relative',
                        margin: '22px 0px 0px 20px',
                        width:'192px',
                        height:'32px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                    onClick={()=>{
                        setaddOrgAddressBtnVisibility(false)
                    }}
                    >
                    Add organization address
                </Button>

                <TextInput
                   
                    sx={{
                        display:addOrgAddressBtnVisibility===false?"flex":"none",
                        position: 'relative',
                        margin: '38px 25px 0px 25px',
                        minHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        minWidth:'748px',
                        maxWidth:'748px',
                        width:'748px',
                    }}
                    placeholder='Address line 1 '
                    value={companyData.org_address_line1}
                    onChange={(e)=> { handleChangeInput("orgAddressline1",e) }}
                />
                <TextInput

                    sx={{
                        display:addOrgAddressBtnVisibility===false?"flex":"none",
                        position: 'relative',
                        margin: '13px 25px 0px 25px',
                        minHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        minWidth:'748px',
                        maxWidth:'748px',
                        width:'748px',
                    }}
                    placeholder='Address line 2'
                    value={companyData.org_address_line2}
                    onChange={(e)=> { handleChangeInput("orgAddressline2",e) }}
                />
                <TextInput

                    sx={{
                        display:addOrgAddressBtnVisibility===false?"flex":"none",
                        position: 'relative',
                        margin: '13px 25px 0px 25px',
                        minHeight: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        minWidth:'748px',
                        maxWidth:'748px',
                        width:'748px',
                    }}
                    placeholder='Address line 3'
                    value={companyData.org_address_line3}
                    onChange={(e)=> { handleChangeInput("orgAddressline3",e) }}
                />

                <Box sx={{
                     display:addOrgAddressBtnVisibility===false?"flex":"none",
                     position: 'relative',
                     margin: '13px 25px 0px 25px',
                    
                     flexDirection:'row',
                     gap:'8px'
                }}>
                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '248px',
                            maxWidth: '248px',
                            width: '248px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='City'
                        value={companyData.org_city}
                        onChange={(e)=> { handleChangeInput("orgCity",e) }}
                    />
                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '230px',
                            maxWidth: '230px',
                            width: '230px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='State /province'
                        value={companyData.org_state_province}
                        onChange={(e)=> { handleChangeInput("orgStateProvince",e) }}
                    />
                    <TextInput

                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            minHeight: '40px',
                            minWidth: '249px',
                            maxWidth: '249px',
                            width: '249px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        placeholder='Postal / zip code'
                        value={companyData.org_postal_zipcode}
                        onChange={(e)=> { handleChangeInput("orgPostalZipCode",e) }}
                    />

                </Box>

                <Box sx={{
                    minHeight:'20px'
                }}>

                </Box>

              
            </Box>

            



            <Box sx={{
                 borderTopWidth: 1,
                 borderTopStyle: 'solid', 
                 borderColor: 'border.default', 
                 position:'absolute',
                 bottom:'0px',
                 left:'0px',
                 right:'0px',
                 height:'52px',
                 display:'flex',
                 flexDirection:'row',

                 }}>

             
                <Link
                    sx={{
                        position: 'relative',
                        margin: '13px 20px 10px 25px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292F',
                        cursor: 'pointer'
                    }}
                    onClick={() => { 
                        showBulkCompanyUploadDialog("")
                    }}
                >
                    Bulk company upload
                </Link>
                 
                
                <Button 
                variant="outline"
                sx={{
                    width:'62px',
                    height:'32px',
                    background:'#0965CE',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center' ,
                    position:'relative',
                    margin:'10px 25px auto auto',
                    color:'#ffffff',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                   
                }}
                onClick={()=>{
                    hnadleNextButtonClick("")
                }}
                >
                    Next
                </Button>
            </Box>


        </Dialog>
    )

}