import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import { ThemeProvider, Flash, IconButton, Box } from '@primer/react'
import theme from './theme'
import SignIn from './components/Authenticaton/SignIn'
import SignUp from './components/Authenticaton/SignUp'
import EmailVerification from './components/Authenticaton/EmailVerification';
import Workplace from './components/Workplace/Workplace';
import CreateWorkplace from './components/Workplace/CreateWorkplace';
import ForgetPassword from './components/Authenticaton/ForgetPassword';
import ResetPassword from './components/Authenticaton/ResetPassword';
import { Auth } from 'aws-amplify';
import React from 'react';
import LoadingSpinner from "./components/LoadingSpinner"
import Space from './components/Space/Space';
import Files from './components/Space/Files';
import List from './components/Space/List';
import Calander from './components/Space/Calander';
import Insight from './components/Space/Insight';
import Team from './components/Space/Team';
import Settings from './components/Space/Settings';
import Account from "./components/Account/Account"
import RoadMap from './components/Space/RoadMap'
import Rules from "./components/Space/Rules"
import Apps from './components/Space/Apps'
import Feed from './components/Workplace/Feed';
import Template from './components/Workplace/Template';
import Initialization from './components/Initialization';
import { getUserIdInLocalDb, getUserEmailInLocalDb, openCloseFreeTrialEndDialog, getUserSubscriptionData } from './components/redux/features/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import Gst from './components/Portal/Gst';
import Home from './components/Home/Home';
import Contact from './components/Space/Contact';
import Works from './components/Space/Works';
import FreeTrialEnd from './components/Home/FreeTrialEnd';
import GstListView from './components/Portal/GstListView';
import ITListView from './components/Portal/ITListView';
import It from './components/Portal/It';
import GstNotice from './components/Portal/GstNotice';
import GstDemand from './components/Portal/GstDemand';
import GstAdditionalNotice from './components/Portal/GstAdditionalNotice';
import GstReturn from './components/Portal/GstReturn';
import GstRefund from './components/Portal/GstRefund';
import GstLedger from './components/Portal/GstLedger';
import GstChallan from './components/Portal/GstChallan';
import ItDemand from './components/Portal/ItDemand';
import ItProceeding from './components/Portal/ItProceeding';
import ItAdditionalnotice from './components/Portal/ItProceedingNotice';
import ItProceedingNotice from './components/Portal/ItProceedingNotice';
import ItRefund from './components/Portal/ItReturn';
import ItLedger from './components/Portal/ItLedger';
import ItChallan from './components/Portal/ItChallan';
import ItReturn from './components/Portal/ItReturn';
import TemplateActivity from './components/Workplace/TemplateActivity';
import Dashboard from './components/Space/Dashboard';
import FeedLatest from './components/Workplace/FeedLatest';
import TaskExpansionDetails from './components/Space/TaskExpansionDetails';
import ItrExpansion from './components/Portal/ItrExpansion';
import AccountLatest from './components/Account/AccountLatest';
import GstExpansion from './components/Portal/GstExpansion';
import ListBeta from './components/Space/ListBeta';
import InsightBeta from './components/Space/InsightBeta';
import TaxHeroPortal from './components/Portal/TaxHeroPortal';
import AccountSetupPortal from './components/Portal/AccountSetupPortal';
import { Helmet } from 'react-helmet';
import AccountPortal from './components/Portal/AccountPortal';
import FreeTrialEndTaxHero from './components/Home/FreeTrialEndTaxHero';
import GSTCashLederExpansion from './components/Portal/GSTCashLederExpansion';
import TaxHeroPortalSanbox from './components/TaxheroSanbox/TaxHeroPortalSanbox';
import GstListViewSanbox from './components/TaxheroSanbox/GstListViewSanbox';
import ITListViewSanbox from './components/TaxheroSanbox/ITListViewSanbox';
import ItSanbox from './components/TaxheroSanbox/ItSanbox';
import GstSanbox from './components/TaxheroSanbox/GstSanbox';
import ItReturnSanbox from './components/TaxheroSanbox/ItReturnSanbox';
import ItDemandSanbox from './components/TaxheroSanbox/ItDemandSanbox';
import ItLedgerSanbox from './components/TaxheroSanbox/ItLedgerSanbox';
import ItProceedingSanbox from './components/TaxheroSanbox/ItProceedingSanbox';
import ItProceedingNoticeSanbox from './components/TaxheroSanbox/ItProceedingNoticeSanbox';
import ItChallanSanbox from './components/TaxheroSanbox/ItChallanSanbox';
import GstReturnSanbox from './components/TaxheroSanbox/GstReturnSanbox';
import GstDemandSanbox from './components/TaxheroSanbox/GstDemandSanbox';
import GstLedgerSanbox from './components/TaxheroSanbox/GstLedgerSanbox';
import GstAdditionalNoticeSanbox from './components/TaxheroSanbox/GstAdditionalNoticeSanbox';
import GstNoticeSanbox from './components/TaxheroSanbox/GstNoticeSanbox';
import GstRefundSanbox from './components/TaxheroSanbox/GstRefundSanbox';
import GstChallanSanbox from './components/TaxheroSanbox/GstChallanSanbox';
import GstExpansionSanbox from './components/TaxheroSanbox/GstExpansionSanbox';
import GSTCashLederExpansionSanbox from './components/TaxheroSanbox/GSTCashLederExpansionSanbox';
import ItrExpansionSanbox from './components/TaxheroSanbox/ItrExpansionSanbox';
import TimeLine from './components/Space/TimeLine';
import Action from './components/Space/Action';
import ContactBulk from './components/Space/ContactBulk';
import ContactBulkCompany from './components/Space/ContactBulkCompany';
import TaxHeroPortalPlaywright from './components/TaxheroPlaywright/TaxHeroPortalPlaywright';
import ItPlaywright from './components/TaxheroPlaywright/ITR/ItPlaywright';
import ItrExpansionPlaywright from './components/TaxheroPlaywright/ITR/ItrExpansionPlaywright';
import ITListViewPlaywright from './components/TaxheroPlaywright/ITR/ITListViewPlaywright';
import ItDemandPlaywright from './components/TaxheroPlaywright/ITR/ItDemandPlaywright';
import ItProceedingPlaywright from './components/TaxheroPlaywright/ITR/ItProceedingPlaywright';
import ItProceedingNoticePlaywright from './components/TaxheroPlaywright/ITR/ItProceedingNoticePlaywright';
import ItReturnPlaywright from './components/TaxheroPlaywright/ITR/ItReturnPlaywright';
import ItLedgerPlaywright from './components/TaxheroPlaywright/ITR/ItLedgerPlaywright';
import ItChallanPlaywright from './components/TaxheroPlaywright/ITR/ItChallanPlaywright';
import GstListViewPlaywright from './components/TaxheroPlaywright/GST/GstListViewPlaywright';
import GstPlaywright from './components/TaxheroPlaywright/GST/GstPlaywright';
import GstReturnPlaywright from './components/TaxheroPlaywright/GST/GstReturnPlaywright';
import GstExpansionPlaywright from './components/TaxheroPlaywright/GST/GstExpansionPlaywright';
import GstLedgerPlaywright from './components/TaxheroPlaywright/GST/GstLedgerPlaywright';
import GSTCashLederExpansionPlaywright from './components/TaxheroPlaywright/GST/GSTCashLederExpansionPlaywright';
import GstChallanPlaywright from './components/TaxheroPlaywright/GST/GstChallanPlaywright';
import GstNoticePlaywright from './components/TaxheroPlaywright/GST/GstNoticePlaywright';
import GstAdditionalNoticePlaywright from './components/TaxheroPlaywright/GST/GstAdditionalNoticePlaywright';

Auth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
  // Cognito Hosted UI configuration
  oauth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'token',
  },
});


function App() {

  const dispatch = useDispatch()

  const isLogout = useSelector((state) => state.profileData.isLogout)
  const [isAuthenticated, setAuthenticated] = React.useState(false)

  const handleLogout = async (props) => {

    await Auth.signOut({ global: true }).then(result => {


      sessionStorage.clear();
      localStorage.clear();
      document.cookie = "";
      window.location.href = '/'

    }).catch(error => {
      console.log("logout error", error)
    });


  }



  React.useState(() => {

    console.log("current Domain", window.location)

    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {


      dispatch(getUserIdInLocalDb({
        userId: localStorage.getItem("userId") || ""
      }))

      dispatch(getUserEmailInLocalDb({
        userId: localStorage.getItem("userEmail") || ""
      }))

      setAuthenticated(true)

    }).catch(error => {

      setAuthenticated(false)
      if (!isLogout) {
        sessionStorage.clear();
        localStorage.clear();
        document.cookie = "";
      }

    })

  })


  React.useEffect(() => {

    // alert(isLogout)

    if (isLogout === true) {

      handleLogout(isLogout)


    }

    console.log("local storage", localStorage.getItem("userEmail"))


  }, [isLogout])



  return (
    <ThemeProvider
      theme={theme}
    >

      <Helmet>
        {window.location.hostname === "www.app.taxherohq.com" || "www.get.taxherohq.com"  ?

          <link rel="icon" href="https://d2fcqkt9srr3ti.cloudfront.net/AccountSetup/TaxheroFav.svg" />
          :
          <link rel="icon" href="https://www.app.deski.co/deski-favIcon.png" />
        }

        

      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path='/initialization' element={<Initialization />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/emailVerification" element={<EmailVerification />} />
          <Route path="/workplace" element={<Home />} />
          {/* <Route   path="/workplace" element={<Workplace />}/>  */}
          <Route path="/createWorkplace" element={<CreateWorkplace />} />
          {/* <Route   path='/feed' element={<Feed/>} /> */}
          <Route path='/feed' element={<FeedLatest />} />
          <Route path='/taskexpansionDetails' element={<TaskExpansionDetails />} />
          <Route path='/template' element={<Template />} />
          {/* <Route   path='/portal' element={<Template/>} /> */}
          <Route path='/portal' element={<Template />} />




          {/* Login portal for tax */}
          <Route path='/taxheroportal' element={window.location.host === "www.get.taxherohq.com" ? <TaxHeroPortalPlaywright /> : <TaxHeroPortal />} />
          <Route path='/accountsetup' element={<AccountSetupPortal />} />
          <Route path='/activity' element={<TemplateActivity />} />
          <Route path='/gst' element={window.location.host === "www.get.taxherohq.com" ? <GstPlaywright/> : <Gst />} />
          <Route path='/It' element={window.location.host === "www.get.taxherohq.com" ? <ItPlaywright /> : <It />} />
          <Route path="/Itrexpansion" element={window.location.host === "www.get.taxherohq.com" ? <ItrExpansionPlaywright /> : <ItrExpansion />} />
          <Route path='/gstexpansion' element={window.location.host === "www.get.taxherohq.com" ? <GstExpansionPlaywright/> :<GstExpansion />} />
          <Route path='/gstcashledgerexpansion' element={window.location.host === "www.get.taxherohq.com" ? <GSTCashLederExpansionPlaywright/> :<GSTCashLederExpansion />} />
          <Route path='/gstpanlist' element={window.location.host === "www.get.taxherohq.com" ? <GstListViewPlaywright/> : <GstListView />} />
          <Route path='/itpanlist' element={window.location.host === "www.get.taxherohq.com" ? <ITListViewPlaywright /> : <ITListView />} />
          <Route path='/notice' element={window.location.host === "www.get.taxherohq.com" ? <GstNoticePlaywright/> :<GstNotice />} />
          <Route path='/demand' element={<GstDemand />} />
          <Route path='/additionalNotice' element={window.location.host === "www.get.taxherohq.com" ? <GstAdditionalNoticePlaywright/> : <GstAdditionalNotice />} />
          <Route path='/return' element={window.location.host === "www.get.taxherohq.com" ? <GstReturnPlaywright/> :<GstReturn />} />
          <Route path='/refund' element={<GstRefund />} />
          <Route path='/ledger' element={window.location.host === "www.get.taxherohq.com" ? <GstLedgerPlaywright/> :<GstLedger />} />
          <Route path="/challan" element={window.location.host === "www.get.taxherohq.com" ? <GstChallanPlaywright/> : <GstChallan />} />
          <Route path="/ItDemand" element={window.location.host === "www.get.taxherohq.com" ? <ItDemandPlaywright /> : <ItDemand />} />
          <Route path="/proceeding" element={window.location.host === "www.get.taxherohq.com" ? <ItProceedingPlaywright /> : <ItProceeding />} />
          <Route path='/proceedingNotice' element={window.location.host === "www.get.taxherohq.com" ? <ItProceedingNoticePlaywright /> : <ItProceedingNotice />} />
          <Route path='/ItReturn' element={window.location.host === "www.get.taxherohq.com" ? <ItReturnPlaywright /> : <ItReturn />} />
          <Route path='/ItLedger' element={window.location.host === "www.get.taxherohq.com" ? <ItLedgerPlaywright /> : <ItLedger />} />
          <Route path='/ItChallan' element={window.location.host === "www.get.taxherohq.com" ? <ItChallanPlaywright /> : <ItChallan />} />
         
 
          {/* <Route   path="/space/:name" element={<Space/>}  /> */}
          {/* <Route   path="/space/:name" element={<Space/>}  /> */}
          <Route path="/space/:name" element={<Works />} />
          <Route path="/files" element={<Files />} />
          {/* <Route   path="/list" element={<List/>}/> */}
          <Route path="/list" element={<ListBeta />} />
          <Route path="/calander" element={<Calander />} />
          <Route path="/insight" element={<Insight />} />
          <Route path="/insightold" element={<Dashboard />} />
          <Route path="/team" element={<Team />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route   path="/account" element={<Account/>}/> */}

          {window.location.hostname === "www.app.taxherohq.com" || "www.get.taxherohq.com" ?   //"www.app.taxherohq.com"
            <Route path="/account" element={<AccountPortal />} />
            :
            <Route path="/account" element={<AccountLatest />} />
          }

          <Route path="/accountlatest" element={<AccountLatest />} />
          {/* <Route   path="/roadmap" element={<RoadMap/>}/> */}
          {/* <Route   path="/roadmap" element={<InsightBeta/>}/> */}
          <Route path='/roadmap' element={<TimeLine />} />
          <Route path='/report' element={<Insight />} />
          <Route path='/timeline' element={<TimeLine />} />
          <Route path='/action' element={<Action />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/contactbulk' element={<ContactBulk />} />
          <Route path='/clientbulk' element={<ContactBulkCompany />} />
          {/* <Route   path="/works" element={<Works/>} /> */}
          <Route path="/rules" element={<Rules />} />


          {/* Taxhero Sanbox Routes ----------------- */}

          <Route path="/taxherosanbox" element={<TaxHeroPortalSanbox />} />
          <Route path='/itpanlistsanbox' element={<ITListViewSanbox />} />
          <Route path='/gstpanlistsanbox' element={<GstListViewSanbox />} />
          <Route path='/Itsanbox' element={<ItSanbox />} />
          <Route path='/Itrexpansionsanbox' element={<ItrExpansionSanbox />} />
          <Route path="/gstsanbox" element={<GstSanbox />} />
          <Route path='/ItReturnsanbox' element={<ItReturnSanbox />} />
          <Route path='/ItDemandsanbox' element={<ItDemandSanbox />} />
          <Route path='/ItLedgersanbox' element={<ItLedgerSanbox />} />
          <Route path='/proceedingsanbox' element={<ItProceedingSanbox />} />
          <Route path='/proceedingNoticesanbox' element={<ItProceedingNoticeSanbox />} />
          <Route path='/ItChallansanbox' element={<ItChallanSanbox />} />
          <Route path='/returnsanbox' element={<GstReturnSanbox />} />
          <Route path='/demandsanbox' element={<GstDemandSanbox />} />
          <Route path='/ledgersanbox' element={<GstLedgerSanbox />} />
          <Route path='/additionalnoticesanbox' element={<GstAdditionalNoticeSanbox />} />
          <Route path='/noticesanbox' element={<GstNoticeSanbox />} />
          <Route path='/refundsanbox' element={<GstRefundSanbox />} />
          <Route path='/challansanbox' element={<GstChallanSanbox />} />
          <Route path='/gstexpansionsanbox' element={<GstExpansionSanbox />} />
          <Route path='/gstcashledgerexpansionsanbox' element={<GSTCashLederExpansionSanbox />} />

          {/* --------------------- */}  

          {/*--------------------- taxheroplaywrite  */}

          <Route path='/taxheroportal0' element={<TaxHeroPortalPlaywright />} />
          <Route path='/accountsetup0' element={<AccountSetupPortal />} />
          <Route path='/It0' element={<ItPlaywright />} />
          <Route path="/Itrexpansion0" element={<ItrExpansionPlaywright />} />
          <Route path='/itpanlist0' element={<ITListViewPlaywright />} />
          <Route path="/ItDemand0" element={<ItDemandPlaywright />} />
          <Route path="/proceeding0" element={<ItProceedingPlaywright />} />
          <Route path='/proceedingNotice0' element={<ItProceedingNoticePlaywright />} />
          <Route path='/ItReturn0' element={<ItReturnPlaywright />} />
          <Route path='/ItLedger0' element={<ItLedgerPlaywright />} />
          <Route path='/ItChallan0' element={<ItChallanPlaywright />} />
 
          {/*GST Playwright----------------------   integrated  */}
          <Route path='/gstpanlist0' element={<GstListViewPlaywright />} />
          <Route path='/gst0' element={<GstPlaywright />} />
          <Route path='/return0' element={<GstReturnPlaywright />} />
          <Route path='/gstexpansion0' element={<GstExpansionPlaywright />} />
          <Route path='/ledger0' element={<GstLedgerPlaywright />} />
          <Route path='/gstcashledgerexpansion0' element={<GSTCashLederExpansionPlaywright />} /> 
          <Route path="/challan0" element={<GstChallanPlaywright />} />
          <Route path="/notice0" element={<GstNoticePlaywright />} />
          <Route path="/additionalnotice0" element={<GstAdditionalNoticePlaywright />} />
          {/* -------------------------------------- */}


        </Routes>
        {
          window.location.hostname !== "www.app.taxherohq.com" || "www.get.taxherohq.com" ?  //www.app.taxherohq.com
            <FreeTrialEnd />
            :
            ""
        }
        <FreeTrialEndTaxHero />



      </BrowserRouter>




    </ThemeProvider>
  );
}

export default App;
