
import React from "react"
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, ActionList, Avatar } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { searchOpenClose } from '../../redux/features/searchSlice';
import Workplacetheme from "../Workplacetheme";
import { ArrowRightIcon, ExclamationTriangleIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { getAllWorksByProjectId, getProjectServices } from "../../redux/features/spaceSlice";
import { openCloseTaskExpansion } from "../../redux/features/taskSlice";





function SearchDialog(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const searchData = useSelector((state) => state.searchData)
    const workplaces = useSelector((state) => state.workplaceData.workplace)
    const workplaceProjects = useSelector((state) => state.spaceData.searchProjects)
    const [copyAllTask,setcopyAllTask] = React.useState([])
    const [allTasks,setallTasks] = React.useState([])
    const [compliances,setcompliances] = React.useState([])

    const initialFocusRef = React.createRef()
    const [searchInput, setsearchInput] = React.useState("")
    const [showActionList, setshowActionList] = React.useState(false)

    const [showArrayIcon, setshowArrayIcon] = React.useState("")
    const handleSearchInput = (e) => {

        setsearchInput(e.target.value)

        if (e.target.value.length > 0) {

            setshowActionList(true)

            const filteredTasks = allTasks 
            .filter(tData => tData.task_name.toLowerCase().startsWith(e.target.value.toLowerCase()) )
            .filter(tData=> tData.task_name.toUpperCase().includes(e.target.value.toUpperCase()))

            if(filteredTasks.length>0){

                setshowActionList(true)

            }else{

                setshowActionList(false)
            }



        } else {

            setshowActionList(false)

           


        }
 
    }

    const closeSearchDialog = (props) => {

        dispatch(searchOpenClose({
            openClose: false
        }))
        setsearchInput("")
        setallTasks(copyAllTask)
        setshowActionList(false)
    }

    const showTaskExpansion = (props) => {

        
        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props.taskId
        }))

    }

    const getAllTaskFromDb = (props) => {

        dispatch(getAllWorksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
            })

            setcopyAllTask(sortedTaskArray)
            setallTasks(sortedTaskArray)
    
        })

    }

    const getAllCategories = (props) => {

         
        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)




        })

    }

    
    React.useEffect(()=>{


        if(searchData.openClose===true){

              getAllCategories("")
              getAllTaskFromDb("")
        }



    },[searchData.openClose])


    return (
        <Dialog
            sx={Workplacetheme.searchDialogMain}
            style={{ height: showActionList === true ? 350 : 51 }}
            isOpen={searchData.openClose}
            onDismiss={() =>
                closeSearchDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}

        >

            <Box sx={Workplacetheme.hideDialogCloseBtn}></Box>

            <TextInput
                ref={initialFocusRef}
                leadingVisual={
                    <MagnifyingGlassIcon
                        style={{
                            height: 20,
                            width: 16,
                            display: "flex",
                            alignContent: 'center'
                        }} />}
                sx={Workplacetheme.searchDialogInput}
                aria-label="SearchInput"
                name="searchInput"
                placeholder="Search task"
                onChange={(e) => handleSearchInput(e)}
                value={searchInput}
            />

            {showActionList && (

                <Text sx={Workplacetheme.searchDialogSmallText}>Results
                 
                </Text>

            )}

            {showActionList && (

                <ActionList showDividers sx={Workplacetheme.searchDialogActionList}>

                    {allTasks
                     
                       .filter(tData => tData.task_name.toLowerCase().startsWith(searchInput.toLowerCase()) )
                       .filter(tData=> tData.task_name.toUpperCase().includes(searchInput.toUpperCase()))
                       .slice(0, 4)
                        .map((row, index) => {

                            return (
                                <ActionList.Item
                                    onMouseEnter={() => {
                                        setshowArrayIcon(row.task_id)
                                    }}
                                    onMouseLeave={() => {
                                        setshowArrayIcon("")
                                    }}

                                    sx={Workplacetheme.searchDialogActionList.actionListItem}
                                    onClick={() => {
                                          
                                        
                                          showTaskExpansion({taskId:row.task_id})
                                          closeSearchDialog("")


                                    }}
                                >
                                    {/* <ActionList.LeadingVisual sx={Workplacetheme.searchDialogActionList.actionListItem.leadingVisual}>
                                         <Box
                                            sx={Workplacetheme.searchDialogActionList.actionListItem.leadingVisual}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#de2424',
                                                fontFamily: 'Segoe UI',
                                                fontSize: '30px',
                                                fontWeight: '600'

                                            }}
                                        >
                                            {row.task_name.substring(0, 1).toUpperCase()}
                                        </Box>
                                    </ActionList.LeadingVisual> */}

                                    <Text
                                        sx={Workplacetheme.searchDialogActionList.actionListItem.textField}

                                    >{row.task_name}</Text>
                                    <ActionList.Description sx={Workplacetheme.searchDialogActionList.actionListItem.descriptionText} >
                                    {/* {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name} */}
                                    {row?.contact_data?.org_name}
                                    </ActionList.Description>

                                    {showArrayIcon === row.task_id ?
                                        <ActionList.TrailingVisual sx={Workplacetheme.searchDialogActionList.actionListItem.trailingVisual}><ArrowRightIcon /></ActionList.TrailingVisual>

                                        :
                                        ""}

                                </ActionList.Item>
                            )
                        })}
                     


                    {/* {workplaces
                        .filter((wData) => wData.workplace_name.toUpperCase().includes(searchInput.toLocaleUpperCase()))
                        .map((row, index) => {

                            return (

                                <ActionList.Item
                                    onMouseEnter={() => {
                                        setshowArrayIcon(row.workplace_id)
                                    }}
                                    onMouseLeave={() => {
                                        setshowArrayIcon("")
                                    }}

                                    sx={Workplacetheme.searchDialogActionList.actionListItem}
                                    onClick={() => {
                                        localStorage.setItem("workplaceId", row.workplace_id)
                                        localStorage.setItem("workplaceStatus", "view")
                                        navigate('/createWorkplace')
                                        closeSearchDialog("")
                                    }}
                                >
                                    <ActionList.LeadingVisual sx={Workplacetheme.searchDialogActionList.actionListItem.leadingVisual}>

                                         <Box
                                            sx={Workplacetheme.searchDialogActionList.actionListItem.leadingVisual}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: '#de2424',
                                                fontFamily: 'Segoe UI',
                                                fontSize: '30px',
                                                fontWeight: '600'
                                            }}
                                        >
                                            {row.workplace_name.substring(0, 1).toUpperCase()}
                                        </Box>

                                    </ActionList.LeadingVisual>

                                    <Text
                                        sx={Workplacetheme.searchDialogActionList.actionListItem.textField}

                                    >{row.workplace_name}</Text>
                                    <ActionList.Description sx={Workplacetheme.searchDialogActionList.actionListItem.descriptionText} >Workplace</ActionList.Description>

                                    {showArrayIcon === row.workplace_id ?
                                        <ActionList.TrailingVisual sx={Workplacetheme.searchDialogActionList.actionListItem.trailingVisual}><ArrowRightIcon /></ActionList.TrailingVisual>

                                        :
                                        ""}

                                </ActionList.Item>

                            )
                        })
                        .slice(0, 2)
                    } */}

 



                </ActionList>

            )}


           </Dialog>
    )
}

export default SearchDialog


