import React from "react"
import {Box,Dialog,Text,TextInput,Select,Textarea, Button } from '@primer/react'
import { useDispatch,useSelector } from "react-redux";
import { scheduleDemoOpenClose } from '../../redux/features/scheduleDemoSlice';
import Workplacetheme from "../Workplacetheme";
import {deleteSpace, deleteWorkplaceOpenClose,getSpaceCollaborators} from "../../redux/features/spaceSlice"
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { deleteWorkplace, getSingleWorkplace } from "../../redux/features/workplaceSlice";

function DeleteWorkplaceDialog(props){

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const deleteWorkplaceOpenCloseData = useSelector((state)=> state.spaceData) 

    const deleteSpaceData = useSelector((state)=> state.spaceData.deleteSpaceData )
    const deleteWorkSpaceData = useSelector((state)=> state.spaceData.deleteWorkplaceData )
    

    const [checkDeleteRole,setcheckDeleteRole] = React.useState(false) 


    const initialFocusRef = React.createRef()

    const closeDeleteSpaceDialog = (props) => {
        
        dispatch(deleteWorkplaceOpenClose({
             openClose:false,
             deleteWorkplaceData:{
                workplaceId:'',
                workplaceName:''
             },
             deleteSpaceData:{
                projectId:'',
                projectName:''
             }  
        }))
        setcheckDeleteRole(false)

    }


     



    const deleteProjectInDb = (props) => {

        if(checkDeleteRole===true){

            closeDeleteSpaceDialog("")
            dispatch( deleteSpace({
                userId:userId,
                userEmail:userEmail,
                projectId:props
            })).then((data)=>{
    
               
    
                Toaster.create({ position: Position.TOP }).show({
                    message: "The project has been deleted.",
                    intent: Intent.DANGER
                });
               
    
            }) 
            
        }else{

            Toaster.create({ position: Position.TOP }).show({
                message: "You Dont have permission to delete project",
                intent: Intent.DANGER
            });
            closeDeleteSpaceDialog("")
        }


       
         




    }

    const deleteWorkplaceInDb = (props) => {


        if(checkDeleteRole===true){

            dispatch(deleteWorkplace({
                userId:userId,
                userEmail:userEmail,
                workplaceId:props
             })).then((data)=> {
    
                    
    
                     Toaster.create({ position: Position.TOP }).show({
                        message: "The workplace has been deleted.",
                        intent: Intent.DANGER
                     });
    
             })
    
    
             closeDeleteSpaceDialog("")

            
        }else{

            Toaster.create({ position: Position.TOP }).show({
                message: "You Dont have permission to delete workspace",
                intent: Intent.DANGER
            });
            closeDeleteSpaceDialog("")
        }

       

        
        
    }


    React.useEffect(()=>{

          //alert(deleteWorkplaceOpenCloseData.deleteOpenClose)
          if(deleteWorkplaceOpenCloseData.deleteOpenClose===true){

            if(deleteSpaceData.projectId!==''){

               //  alert(deleteSpaceData.projectId)

                dispatch(getSpaceCollaborators({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: deleteSpaceData.projectId
                })).then((data) => {

                    //  console.log("abc",data.payload)

                      const collaboratorArray = [...data.payload.collaboratorArray]
                      const checkUserRole = collaboratorArray.some((cData)=> cData.user_id===userId&&cData.user_role==="Owner")
                     setcheckDeleteRole(checkUserRole)
                     
     

                })

               

            }else if(deleteWorkSpaceData.workplaceId!==''){
                //  alert(deleteWorkSpaceData.workplaceId)
                
                dispatch(getSingleWorkplace({
                    userId: userId,
                    userEmail: userEmail,
                    workplaceId: deleteWorkSpaceData.workplaceId
                })).then((data) => {


                   

                   
                    const collaboratorArray = [...data.payload.workplaceCollaborators]


                    

                    const checkUserRole = collaboratorArray.some((cData) => cData.user_id === userId && cData.user_role === "Owner")
                    setcheckDeleteRole(checkUserRole)
                    


                })      

            }
             
          }
 

    },[deleteWorkplaceOpenCloseData.deleteOpenClose])


    return(

        <Dialog
            sx={Workplacetheme.deleteWorkSpaceDialog}
           isOpen={deleteWorkplaceOpenCloseData.deleteOpenClose}
         //  isOpen={true}
            onDismiss={() =>

                closeDeleteSpaceDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
             <Dialog.Header id="header-id">
                <Text  sx={Workplacetheme.scheduleDalogeHeading}>Are you sure you want to delete {deleteSpaceData.projectName+""+deleteWorkSpaceData.workplaceName}  ?</Text>
            </Dialog.Header>

            <Box sx={Workplacetheme.deleteWorkplaceDialogContentBox}>

                <Text sx={Workplacetheme.createSpaceDialodBoxText}>Recently deleted project can be restored from trash.</Text>
               
                <Box 
                sx={Workplacetheme.deleteworkplaceDialogFooterLine}
                borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
                </Box>

                    <Box  
                    ml={24} 
                    mr={24} 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent={"right"}
                    >

                            <Button variant="invisible" 
                            sx={Workplacetheme.deleteworkplaceDialogFooterBox.cancelBtn}
                            onClick={()=> closeDeleteSpaceDialog("") }
                            >Cancel</Button>
                            <Button
                           ref={initialFocusRef}
                            variant="danger" 
                            sx={Workplacetheme.deleteworkplaceDialogFooterBox.submitBtn}
                            onClick={()=> 
                            
                                  {
                                    if(deleteSpaceData.projectId!==''){

                                           deleteProjectInDb(deleteSpaceData.projectId)

                                    }else if(deleteWorkSpaceData.workplaceId!==''){
                                           deleteWorkplaceInDb(deleteWorkSpaceData.workplaceId)
                                    }
                                  }
                            
                            
                            }
                            >Delete</Button>
                  
                  
                    </Box>





        </Dialog>

    )


}

export default DeleteWorkplaceDialog