import React from "react"
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, Avatar, TextInputWithTokens, IssueLabelToken, ActionMenu, ActionList, Autocomplete } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { sharingOpenClose,shareWorkplaceProject,flashMessageShowHide } from '../../redux/features/sharingSlice';
import Workplacetheme from "../Workplacetheme";
import { CheckIcon, Cross2Icon, Pencil1Icon } from "@radix-ui/react-icons";
import { FeedPersonIcon } from "@primer/octicons-react";
import { getUserProfileFromDatabase } from "../../redux/features/profileSlice";
import { getSingleWorkplace } from "../../redux/features/workplaceSlice";
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { getProjectInvitations, getSpaceCollaborators } from "../../redux/features/spaceSlice";

const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"


function getNumberofDays(date1, date2) {

    const oneDay = 24 * 60 * 60 * 1000
    const firstDate = date1
    const secondDate = date2
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
    return diffDays;
  
}


function ShareDialog(props) {

    const dispatch = useDispatch()
    const sharingData = useSelector((state) => state.sharingData)
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)  
    const subscriptionData = useSelector((state) => state.profileData?.subscriptionData)
    const planName = subscriptionData?.plan?.item.name
    const maxinvitationCount = 15
    const [pendingCounts,setpendingCounts] = React.useState(0)
    const [usersContacts,setusersContacts] = React.useState([])
    const [workplaceCollaborators,setworkplaceCollaborators] = React.useState([])
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])

    const initialFocusRef = React.createRef()
    const [userSearchInput, setuserSearchInput] = React.useState("")
    const [searchInputValidationStatus,setsearchInputValidationStatus] = React.useState("")
    const [shareRole,setshareRole] = React.useState("Collaborator")
    const [sharePeopleOpen, setsharePeopleOpen] = React.useState(false)
    const [tokens, setTokens] = React.useState([])

   
    const checkTokenDuplicate = (emailId) => {

            
           if(!tokens.some(el => el.text === emailId)){
                return true
           }else{
                return false
           }
    }


    const closesharingDialog = (props) => {

        dispatch(sharingOpenClose({
            openClose: false,
            workplaceId:"",
            shareType:"",
            projectId:"",
            title:"",
        }))

        setworkplaceCollaborators([])

    }


    const addToToken = (props) => {


        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(props) === true) {

            setTokens([
                {
                    text: props, id: props, fillColor: '#DDF4FF', size: "medium", color: '#0969DA'
                }
            ])
            setuserSearchInput("")
            setsearchInputValidationStatus("")
        } else {

            setsearchInputValidationStatus("error")
        }



    }


    const onTokenRemove = tokenId => {
        setTokens(tokens.filter(token => token.id !== tokenId))
    }

    const onTokenRemove2 = (tokenId) => {
        setTokens(tokens.filter(token => token.text !== tokenId))
    }


    const onSearchInputPaste = (props) => {

           

        // const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        // if (regex.test(props.trim()) === true) {
             
        //     if(checkTokenDuplicate(props.trim())){
              
              
        //         setuserSearchInput("") 
        //         addToToken(props.trim())
              
               
                 
               
        //     }else{
               
        //     }
           
        //        setsearchInputValidationStatus("")
        // } else {
                
        //        setsearchInputValidationStatus("error")
        // } 
          
    }


    const handleSearchKeyDown= (e) => {

        if(e.keyCode === 32 || e.which === 32){
           
            // alert("space key pressed"+e.target.value+" "+tokens.length)

            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (regex.test(e.target.value.trim()) === true) {
                 
                if(checkTokenDuplicate(e.target.value)){
                  
                    addToToken(e.target.value)
                }
                
                setsearchInputValidationStatus("")
            } else {

             
                setsearchInputValidationStatus("error")
            } 




        }

        if (e.key === 'Enter') {
           
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (regex.test(e.target.value.trim()) === true) {
                 
                if(checkTokenDuplicate(e.target.value)){
                  
                    addToToken(e.target.value)
                }
                
                setsearchInputValidationStatus("")
            } else {

             
                setsearchInputValidationStatus("error")
            } 

        }

    }

    const onEmailInputBlure = (props)=>{

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(props.trim()) === true) {
             
            if(checkTokenDuplicate(props.trim())){
              
                addToToken(props.trim())
            }else{
               // addToToken(props.trim())
            }
            
               setsearchInputValidationStatus("")
        } else {
                
               setsearchInputValidationStatus("error")
        } 

    }



    const handleSearchUsers = (e) => {

        if (e.target.value.length > 0) {

               const uContacts = usersContacts?.filter((cData)=> cData?.user_email.toUpperCase().includes(e.target.value.toUpperCase()))

               if(uContacts.length >0){

                  setsharePeopleOpen(true)
               }else{
                  setsharePeopleOpen(false)
               }
 

        } else {

             setsharePeopleOpen(false)
        }
        setuserSearchInput(e.target.value)
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(regex.test(e.target.value)===true){
            setsearchInputValidationStatus("")
        }else{
            setsearchInputValidationStatus("error")
        }   
    }

    const handleShareToDb = (props) => {

          const checkTokenArray = tokens.length 
        
          const totalinvitationCount = spaceCollaborators.filter((sData)=> sData.user_id!==userId).length+parseInt(pendingCounts)
          
          if(checkTokenArray> 0){
              
           
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if(regex.test(tokens[0].text)===true){


                if (totalinvitationCount < maxinvitationCount) {

                    dispatch(shareWorkplaceProject({
                        userId: "",
                        userEmail: tokens[0].text,
                        inviteEmail: userEmail,
                        workplaceId: sharingData?.workplaceId,
                        shareType: sharingData?.shareType,
                        projectId: sharingData?.projectId,
                        inviteId: userId,
                        shareRole: shareRole

                    })).then((data) => {




                        if (data.payload?.message === "Already shared space with same Role") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in project, role change possible.",
                                intent: Intent.PRIMARY
                            });


                        } else if (data.payload?.message === "Space invitation sent successfully") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });

                        } else if (data.payload?.message === "Already shared workspace with same Role") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in workspace, role change possible.",
                                intent: Intent.PRIMARY
                            });


                        } else if (data.payload?.message === "Invitation send with new role") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });
                        } else if (data.payload?.message === "Invitation send with new workspace") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });
                        } else if (data.payload?.message === "Worspace invitation already sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in workspace, role change possible.",
                                intent: Intent.PRIMARY
                            });

                        } else if (data.payload?.message === "Workspace shared invitation sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });


                        } else if (data.payload?.message === "Project Invitation already sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "User already in project, role change possible.",
                                intent: Intent.PRIMARY
                            });


                        } else if (data.payload?.message === "Project shared invitation sent") {

                            Toaster.create({ position: Position.TOP }).show({
                                message: "The invitation has been successfully sent.",
                                intent: Intent.SUCCESS
                            });

                        }


                        closesharingDialog("")
                        setuserSearchInput("")
                        setshareRole("Collaborator")
                        setTokens([])
                    })

                    setsearchInputValidationStatus("")



                } else {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Maximum collaborators " + maxinvitationCount,
                        intent: Intent.PRIMARY
                    });


                }



                   


                
            }else{

                setsearchInputValidationStatus("error")
               
            }



          }else{

               setsearchInputValidationStatus("error")

          }

 
    }


    const getSpaceCollaboratorFromDb = (props) => {


        
        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
           
        })

    }

    const getProjectInvitationsFromDb = (props) => {

        dispatch(getProjectInvitations({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setpendingCounts(data.payload.data)
        })

    }

    const getWorkplaceCollaboratorsFromDb = (props) => {

        dispatch(getSingleWorkplace({
            userId:userId,
            userEmail:userEmail,
            workplaceId:sharingData?.workplaceId
        })).then((data)=>{
  
               
              setworkplaceCollaborators(data.payload?.body?.workplaceCollaborators)
              
        })

    }




    const IssueLabelTokens = (props) => {



        return (
            <IssueLabelToken text={

                <Text sx={{
                    display: 'flex',
                    position: 'relative',
                    margin: 'auto 0px auto 8px',
                    fontFamily: 'Segoe UI',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#0969DA'
                }}>{props.text}</Text>


            } fillColor="#DDF4FF" size="small" onRemove={() => onTokenRemove2(props.text)} />
        )



    }

    React.useState(()=>{

      dispatch(getUserProfileFromDatabase({
          userId:userId,
          userEmail:userEmail,
      })).then((data)=>{

             const uArray = [...data.payload.userContactArray].filter((row,index)=> { if(row!==null) return row  })
             console.log("team mem array",uArray)
             setusersContacts(uArray)
            // setusersContacts(data.payload.userContactArray)
      })

 

    })

    React.useEffect(()=>{

       

        if(sharingData.openClose===true){
            getSpaceCollaboratorFromDb("")
            getProjectInvitationsFromDb("")
            getWorkplaceCollaboratorsFromDb("")
        }
        
 
    },[sharingData])


    return (
        <Dialog
            sx={Workplacetheme.shareDialogWorkplaceSpace}
            isOpen={sharingData.openClose}
            onDismiss={() =>

                closesharingDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text sx={Workplacetheme.scheduleDalogeHeading}>Share {sharingData?.title}</Text>
                
            </Dialog.Header>

            <Box sx={Workplacetheme.shareDialogContentBox}>

                <Text sx={Workplacetheme.shareDialogText}>Collaborator can see this on email or notification.
                
                </Text>



                <Box sx={Workplacetheme.shareDialogEmailBox}>

                    <TextInputWithTokens
                        visibleTokenCount={2} block 
                        ref={initialFocusRef}
                        sx={Workplacetheme.shareDialogTextInputWithToken}
                        aria-label="userEmail"
                        name="userEmail"
                        tokenComponent={IssueLabelTokens}
                        tokens={tokens}
                        onTokenRemove={onTokenRemove}
                        placeholder={tokens.length > 0 ? "" : "Add project members by name or email"}
                        onChange={(e) => handleSearchUsers(e)}
                        value={userSearchInput}
                        validationStatus={searchInputValidationStatus}
                        onKeyDown={(e)=>{
                            handleSearchKeyDown(e)
                        }}
                        onBlur={(e)=>{


                            onEmailInputBlure(e.target.value)

                            
                             
                        }}
                        onPaste={(e)=>{

                               
                               onSearchInputPaste(e.clipboardData.getData('text'))
                             
                           
                            
                        }}
                        
                    />

                    {sharePeopleOpen &&    (

                        <ActionList
                        
                         style={{visibility:usersContacts.length > 0 ? 'visible':'hidden' }}

                         showDividers sx={Workplacetheme.sharePeopleSearchActionList}>

                             {usersContacts
                             ?.filter((uData)=>
                                 uData?.user_email.toUpperCase().includes(userSearchInput.toUpperCase())
                                 ||
                                 (uData.user_first_name+' '+uData.user_last_name).toUpperCase().includes(userSearchInput.toUpperCase())
                             )
                             ?.map((row,index)=>{

                                 return(

                                     <ActionList.Item
                                         sx={Workplacetheme.sharePeopleSearchActionList.actionListItem}
                                         onClick={()=>{

                                            {
                                             
                                                
                                                if (checkTokenDuplicate(row.user_email)) {
                                                    addToToken(row.user_email)
                                                    setsharePeopleOpen(false)
                                                }
                                               
                                            }

                                         }}
                                         
                                     >
                                         <ActionList.LeadingVisual>

                                             {row.user_image_url === "" || row.user_image_url == null ?
                                                 <FeedPersonIcon size={20} fill="#586069" />
                                                 :
                                                 <Avatar src={row.user_image_url} />
                                             }

                                         </ActionList.LeadingVisual>
                                         <Text sx={Workplacetheme.sharePeopleSearchActionList.actionListItem.textField}>{row.user_first_name + " " + row.user_last_name}</Text>
                                         <ActionList.Description sx={Workplacetheme.sharePeopleSearchActionList.actionListItem.descriptionText} >{row.user_email}</ActionList.Description>
                                     </ActionList.Item>

                                 )

                             })}

 

                        </ActionList>

                    )}








                    <Box sx={Workplacetheme.shareDialogEmailBox.selectBox} >
                        <Select
                            style={Workplacetheme.shareDialogEmailBox.selectComponent}
                            block="true"
                            onChange={(e) => setshareRole(e.target.value)}
                        >
                            <Select.Option value="Collaborator">Collaborator</Select.Option>
                            <Select.Option value="Owner">Owner</Select.Option>
                            <Select.Option value="Read only">Read only</Select.Option>


                        </Select>

                    </Box>

                </Box>

                <Box sx={Workplacetheme.shareDialogTextAreaBox}>
                    <Textarea
                        resize="horizontal"
                        sx={Workplacetheme.shareDialogTextAreaBox.textAreaInput}
                        placeholder="Add a message  ( Optional )" />
                </Box>

                <Text
                    sx={Workplacetheme.shareDialogCollaboratorText}
                >
                    Workspace collaborator 
                </Text>

                {workplaceCollaborators
               // .filter((collabs)=>collabs.user_role !=='Creator')?
                ?.map((row,index)=>{


                       return(

                        <Box sx={Workplacetheme.sharePeopleBottomCollabBox}>

                        {

                          row.user_image_url==="" || row.user_image_url==null? <Box sx={Workplacetheme.sharePeopleBottomCollabBox.avatarComp}><FeedPersonIcon size={28} fill="#586069" /></Box> :
                          <Avatar sx={Workplacetheme.sharePeopleBottomCollabBox.avatarComp} src={row.user_image_url} />
                        }

{/* <Avatar sx={Workplacetheme.sharePeopleBottomCollabBox.avatarComp} src={placeHolderProfile} /> */}
    
                        <Box sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox}>
    
                            <Text
                                sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.nameText}
                            >
                                {(row.user_first_name+" "+row.user_last_name)}
                            </Text>
                            <Text sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.emailText}>
                                {row.user_email}
                            </Text>
    
                        </Box>
    
                        <Text sx={Workplacetheme.sharePeopleBottomCollabBox.collabDateText}>
                                   {

                                       "" + getNumberofDays(new Date(), new Date(row.timestamp)) == 0 ? "today" :

                                            getNumberofDays(new Date(), new Date(row.timestamp)) + " days ago"
                                           + ""
                                   }
                        </Text>
    
                        <Button sx={Workplacetheme.sharePeopleBottomCollabBox.collabstatusBtn}>
                            {/* <Box sx={{minWidth:'66px',maxWidth:'66px',width:'66px',minHeight:'18px',display:'flex',justifyContent:'left'}}> */}
                            { row.user_role}
                            {/* </Box> */}
                            </Button>
    
                    </Box>

                       )




                })}

                {/* <Box sx={Workplacetheme.sharePeopleBottomCollabBox}>

                    <Avatar sx={Workplacetheme.sharePeopleBottomCollabBox.avatarComp} src={placeHolderProfile} />

                    <Box sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox}>

                        <Text
                            sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.nameText}
                        >
                            Rabin baby N
                        </Text>
                        <Text sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.emailText}>
                            rabinbaby@gmail.com
                        </Text>

                    </Box>

                    <Text sx={Workplacetheme.sharePeopleBottomCollabBox.collabDateText}>
                        Joned 12 days ago
                    </Text>

                    <Button sx={Workplacetheme.sharePeopleBottomCollabBox.collabstatusBtn}>Collaborator</Button>

                </Box> */}

                <Box sx={{
                     minWidth:'516px',
                     minHeight:'60px',
                     
                }}>

                </Box>

                {/* <Box sx={Workplacetheme.sharePeopleBottomCollabBox}>

                    <Avatar sx={Workplacetheme.sharePeopleBottomCollabBox.avatarComp} src={placeHolderProfile} />

                    <Box sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox}>

                        <Text
                            sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.nameText}
                        >
                            Rabin baby N
                        </Text>
                        <Text sx={Workplacetheme.sharePeopleBottomCollabBox.nameEmailBox.emailText}>
                            rabinbaby@gmail.com
                        </Text>

                    </Box>

                    <Text sx={Workplacetheme.sharePeopleBottomCollabBox.collabDateText}>
                        Joned 12 days ago
                    </Text>

                    <Button sx={Workplacetheme.sharePeopleBottomCollabBox.collabstatusBtn}>Collaborator</Button>

                </Box> */}

                 








                <Box
                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"
                    sx={Workplacetheme.sharePeopleBottomCollabBox.bottomDivider}>

                </Box>





                <Box

                    position={"absolute"}
                    bottom={2}
                    left={0}
                    right={0}
                    paddingRight={24}
                    // left={24}
                    // right={24}
                    background="white"
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >

                    <Button variant="invisible"
                        sx={Workplacetheme.scheduleFooterBox.cancelBtn}
                        onClick={() => closesharingDialog("")}
                    >Cancel</Button>
                    <Button

                        variant="outline"
                        sx={Workplacetheme.scheduleFooterBox.submitBtn}
                        onClick={() => handleShareToDb("")}
                    >Submit</Button>


                </Box>








            </Box>







        </Dialog>
    )

}

export default ShareDialog