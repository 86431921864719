// import React from "react";
// import { Box,Text,Link,Button,ActionList, Checkbox, FormControl } from "@primer/react"
// import {Helmet} from "react-helmet";
// import SpaceHeader from "./SpaceHeader"
// import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
// import SpaceLeftSideNav from "./SpaceLeftSideNav"
// import SearchDialog from "../Workplace/Dialogs/SearchDialog"
// import CreateTask from "./CreateTask";
// import {UnderlineNav} from '@primer/react/drafts'
// import SpaceNameHeader from "./SpaceNameHeader";
// import CreateRoadDialog from "../Workplace/Dialogs/CreateRoadDialog";
// import { useDispatch,useSelector } from "react-redux";
// import { createRoadmapOpenClose } from "../redux/features/spaceSlice";
// import { addCompanyTaskOpenClose, getCompanyTask, postBatchCompanyTasks } from "../redux/features/contactSlice";
// import AddCompanyTask from "../Workplace/Dialogs/AddCompanyTask";
// import { Toaster, Intent,Position } from "@blueprintjs/core";
// import EmptyData from "./EmptyData";


// function RoadMap(props){

//     const dispatch = useDispatch()
//     const userId = useSelector((state) => state.profileData.userId)
//     const userEmail = useSelector((state) => state.profileData.userEmail)

//     const companyTaskOpenClose = useSelector((state) => state.contactProfileData)


//     const [items, setItems] = React.useState([
//         { navigation: 'Task', route: "/task", isSelected: false },
//         { navigation: 'Roadmap', route: "/roadmap", isSelected: false },
//         { navigation: 'Filter', route: "/filter", isSelected: false },
//         { navigation: 'Statics', route: "/statics", isSelected: false },
        

//     ]) 

//     const [companyTasks,setCompanyTask] = React.useState([])

//     const [selectedTab,setselectedTab] = React.useState(0)

//     const showAddRoadMapDialog = (props) => {

        
//         dispatch(createRoadmapOpenClose({
//             roadmapOpenClose: true,
//             roadMapProjectId: localStorage.getItem("projectId"),
//             roadMapTaskId: ""
//         }))


//     }


//     const showAddTaskDialog = (props) => {


//         dispatch(addCompanyTaskOpenClose({
//             companyTaskOpenClose:true
//         }))


//     }

//     const getAllCompanyTasks = (props) => {

//         dispatch(getCompanyTask({
//             userId:userId,
//             userEmail:userEmail
//        })).then((data)=>{

//               const allCompanyTask = [...data.payload?.data]

//              setCompanyTask(allCompanyTask.map(task => ({...task, isChecked: false})));
               

//        })
//     }


//     const pushSelectedTask = (props) => {


//          const selectedTask = companyTasks.filter((cTask)=> cTask.isChecked===true)
        
//          if(selectedTask.length==0){

              
//          }else{

//                //postBatchCompanyTasks
//                dispatch(postBatchCompanyTasks({
//                   userId:userId,
//                   userEmail:userEmail,
//                   selectedTasks:selectedTask,
//                   projectId:localStorage.getItem("projectId")
//                })).then((data)=>{

//                   Toaster.create({ position: Position.TOP }).show({
//                     message: "Task  pushed successfully.",
//                     intent: Intent.SUCCESS
//                   });
//                 //  setCompanyTask(companyTasks.map(task => ({...task, isChecked: false})));
//                    setCompanyTask((prevState)=>{
                       
//                          const newState = prevState.map(task => ({...task, isChecked: false}))
//                          return newState
//                    })   
//                })



                
//          }

//     }


    


//     const companyTaskComponent = (porps) => {


//         return(

//             <Box sx={{
//                 position: 'absolute',
//                 top: '100px',
//                 left: '0px',
//                 background: 'white',
//                 right: '225px',
//                 boxShadow: '0px 1px 3px rgba(27, 31, 36, 0.12)',
//                 //  borderRadius: '12px'
//             }}>

//                 <ActionList
//                   sx={{
//                     display:'flex',
//                     flexDirection:'column',
//                     alignItems:'center',
//                     paddingLeft:'1px',
//                     paddingRight:'1px',  
//                     borderRadius:'6px', 
//                     minHeight:'300px'
//                   }}
                   
//                 >

//                   <ActionList.Item 
                  
//                   >

//                         <ActionList.LeadingVisual>
//                         <Checkbox 
                        

//                         onChange={(e)=>{

//                                 e.target.checked === true ? 
//                                 setCompanyTask(companyTasks.map(task => ({...task, isChecked: true})))
//                                 : 
//                                 setCompanyTask(companyTasks.map(task => ({...task, isChecked: false})))
                              
//                         }}  /> 
//                         </ActionList.LeadingVisual>

                     
                    
                      
//                   </ActionList.Item>

//                   <Box sx={{borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#D8DEE4',width:'100%'}}></Box>


//                   {companyTasks.map((row,index)=>{

//                         return(

//                             <ActionList.Item 
//                              sx={{
//                                 borderBottom: '1px solid #D8DEE4'
//                              }}
//                             >
                              
//                                   <ActionList.LeadingVisual>
//                                       <Checkbox checked={row.isChecked}
                                      
//                                       onChange={(e)=>{


//                                         setCompanyTask((prevState)=>{
                                              
//                                             const updatedItems = [...prevState];
//                                             updatedItems[index] = { ...updatedItems[index], isChecked: e.target.checked };
//                                             return updatedItems

//                                            }) 

//                                       }}
                                      
//                                       />
//                                   </ActionList.LeadingVisual>
          
//                                   <Box sx={{
//                                       display:'flex',
//                                       flexDirection:'column',
//                                       position:'relative',
//                                       margin:'0px 0px 6px 38px'
//                                   }}>
                                    
//                                      <Text
//                                      sx={{
//                                         fontFamily:'Segoe UI',
//                                         fontStyle:'normal',
//                                         fontWeight:'600',
//                                         fontSize:'16px',
//                                         lineHeight:'24px',
//                                         color:'#24292F'
//                                      }}
//                                      >
//                                   {row.task_name}
//                                      </Text>
//                                      <Text
//                                       sx={{
//                                           fontFamily:'Segoe UI',
//                                           fontStyle:'normal',
//                                           fontWeight:'400',
//                                           fontSize:'12px',
//                                           lineHeight:'18px',
//                                           color:'#57606A'
//                                        }}
//                                      >
//                                      {row.task_description}
//                                      </Text>
          
          
//                                   </Box>

//                                   <Text
//                                      sx={{
//                                         fontFamily:'Segoe UI',
//                                         fontStyle:'normal',
//                                         fontWeight:'400',
//                                         fontSize:'14px',
//                                         lineHeight:'24px',
//                                         color:'#24292F',
//                                         position:'absolute',
//                                         top:'0px',
//                                         right:'120px',
//                                         bottom:'0px',
//                                         margin:'auto',
//                                         height:'fit-content',
//                                         width:'fit-content'
//                                      }}
//                                      >
//                                      {row.due_date!==""&&row.due_date!=="Invalid Date" ? new Date(row.due_date).getDate()+"/"+(new Date(row.due_date).getMonth()+1)+"/"+new Date(row.due_date).getFullYear() :""}
//                                      </Text>
//                                 <FormControl
//                                  sx={
//                                    {
//                                     position:'absolute',
//                                     top:'0px',
//                                     right:'20px',
//                                     fontFamily:'Segoe UI',
//                                     fontStyle:'normal',
//                                     fontWeight:'400',
//                                     color:'#24292F',
//                                     bottom:'0px',
//                                     margin:'auto',
//                                     height:'fit-content',
//                                     width:'fit-content'
//                                    }
//                                  }
//                                 >
//                                     <Checkbox id="default-checkbox"
//                                      checked={row.is_recurring}
//                                      onChange={(e)=>{ }}
//                                     />
//                                     <FormControl.Label>Recurring</FormControl.Label>
//                                 </FormControl>

                                 
                                
//                             </ActionList.Item>

//                         )

//                   })}

                 
                   


//                 </ActionList>

//             </Box>
//         )



//     }


//     React.useState(()=>{

        
//           getAllCompanyTasks("")

//     })
    

//     React.useEffect(()=>{

//         getAllCompanyTasks("")

//     },[companyTaskOpenClose])



//     return(
//         <Box
//          position={"relative"} 
//          display="flex" 
//          background={"#F6F8FA"} 
//          height="100vh"
//          width={"100vw"} 
//          overflowY={"auto"}
//         >
//             <Helmet>
//                 <title>
//                 Roadmap - deski
//                 </title>
//             </Helmet>
//             <SpaceHeader/>



//             <EmptyData
//                     position="fixed"
//                     top="158px"
//                     left="0px"
//                     right="0px"
//                 />



//             <SpaceLeftSideNav/>
//             {/* <SpaceRightSideNav/> */}

//             {/* <SpaceNameHeader /> */}

             

           

//             {/* <Box

//                 borderColor="border.default"
//                 borderBottomWidth={1}
//                 borderBottomStyle="solid"
//                 sx={{
//                     position: 'fixed',
//                     left: '101px',
//                     right: '101px',
//                     top: '148px'
//                 }}>
//             </Box>


//             <Box
//                 sx={{

//                     height: '48px',
//                     minHeight: '48px',
//                     position: 'absolute',
//                     left: '101px',
//                     right: '101px',
//                     top: '140px',
//                     fontFamily: 'Avenir LT Std',
//                     fontStyle: 'normal',
//                     fontWeight: '400',
//                     fontSize: '14px',
//                     color: '#121212'
//                 }}
//                 borderColor="border.default"
//                 borderBottomWidth={1}
//                 borderBottomStyle="solid"
//             >
//                 <UnderlineNav
//                     sx={{
//                         border: 'none',
//                         position: 'absolute',
//                         width: '280px',
//                         maxWidth: '280px',
//                         minWidth: '280px',
//                         bottom: 0,
//                         paddingLeft: '0px',
//                         paddingRight: '0px',

//                     }}
//                 >
//                     {items.map((item, index) => (
//                         <UnderlineNav.Item

//                             key={item.navigation}
//                            // selected={item.isSelected}
//                             selected={index===selectedTab?true:false}
//                             onSelect={e => {
                                

//                                 e.preventDefault()
//                                 setselectedTab(index)
//                             }}


//                         >
//                             {item.navigation}
//                         </UnderlineNav.Item>
//                     ))}



//                 </UnderlineNav>


//                 <Button

//                     sx={{
//                         position: 'absolute',
//                         left: '265px',
//                         top: '8px',
//                         bottom: '0px',
//                         margin: 'auto',
//                         height: '25px',
//                         minHeight: '25px',
//                         maxHeight: '25px',
//                         width: '80px',
//                         margin: 'auto',
//                         fontFamily: 'Avenir LT Std',
//                         fontSize: '14px',
//                         fontWeight: '400',
//                         fontStyle: 'normal',
//                         lineHeight: '20px',
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         border: 'none',

//                     }}

//                     onClick={()=>{
//                         showAddRoadMapDialog("")
                       
//                     }}

//                 >

//                     Add new
//                 </Button>




//             </Box>


//             <Box sx={{
//                 position:'absolute',
//                 top:'230px',
//                 left:'101px',
//                 right:'101px',
//                // background:'red',
//                 bottom:'1px',
//             }}
//             >


//                 <Box
//                   sx={{
//                     position:'absolute',
//                     left:'786px',
//                     top:'-20px',
//                     display:'flex',
//                     flexDirection:'row',
//                     gap:'16px'
                    
//                   }}
//                  >

//                     <Button sx={{
//                          width:'75px',
//                          height:'32px',
//                          fontFamily: 'Avenir LT Std',
//                          fontSize: '14px',
//                          fontWeight: '400',
//                          fontStyle: 'normal',
//                          lineHeight: '24px',
//                          border:'none',
//                          display:'flex',
//                          alignItems:'center',
//                          justifyContent:'center'
//                     }}
                    
//                     onClick={()=>{
//                         pushSelectedTask("")
//                     }}
//                     >
//                         Push task
//                     </Button>
//                     <Button sx={{
//                          width:'75px',
//                          height:'32px',
//                          fontFamily: 'Avenir LT Std',
//                          fontSize: '14px',
//                          fontWeight: '400',
//                          fontStyle: 'normal',
//                          lineHeight: '24px',
//                          border:'none',
//                          display:'flex',
//                          alignItems:'center',
//                          justifyContent:'center'
//                     }}
//                     onClick={()=>{
//                         showAddTaskDialog("")
//                     }}
//                     >
//                         Add task
//                     </Button>

//                 </Box>


//                 {companyTaskComponent("")}

                 

               

//             </Box> */}

            
            

            
 
            
//             <CreateRoadDialog />
//             <AddCompanyTask/>
            
//             <ScheduleDemoDialog/>
//             <SearchDialog/>
//         </Box>
//     )

// }

// export default RoadMap


import { Box, Button, IconButton, Text, Breadcrumbs, Label, ActionMenu, ActionList, Dialog, TextInput, Textarea, FormControl, Radio, Select } from "@primer/react"
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import EmptyData from "./EmptyData";
import CreateTask from "./CreateTask";
import { SortAscIcon, SortDescIcon, XIcon } from "@primer/octicons-react";
import React from "react";
import ComplianceExpansion from "./ComplianceExpansion";
import { useDispatch, useSelector } from "react-redux";
import { getProjectServices, getRecurringProjectServices, postServiceCompliance, postServiceComplianceCategory } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position } from "@blueprintjs/core";
import CreateRecurringBulkTaskDialog from "../Workplace/Dialogs/CreateRecurringBulkTaskDialog";
import { createRecurringBulkTaskDialogOpenClose } from "../redux/features/taskSlice";

function Roadmap(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const recurringbulktaskDialogOpenClose = useSelector((state)=> state.taskData.recurringbulktaskDialogOpenClose)
    const taskRecurringTrigger = useSelector((state)=> state.taskData.taskRecurringTrigger)
    
    const [isAscending,setisAscending] = React.useState(false)
    const [showComplianceExpansion, setshowComplianceExpansion] = React.useState(false)
    const [selectedBrudcom, setselectedBrudcom] = React.useState({
        category_name: '',
        subcategory_name: ''
    })


    const [expansionCatData, setexpansionCatData] = React.useState(null)
    const [expansionData, setexpansionData] = React.useState(null)
    const [expansionSubtask,setexpansionSubtask] = React.useState([])
    const [categories, setcategories] = React.useState([])
    const [compliances,setcompliances] = React.useState([])
    const [complianceType,setcomplianceType] = React.useState([])
    const [filingPeriods,setfilingPeriods] = React.useState([])
    const [billingTypes,setbillingTypes] = React.useState([])


    const initialFocusRef = React.createRef()
    const initialFocusRef1 = React.createRef()
    const dueDateCalandarRef = React.createRef()
    const serviceNameInputRef = React.createRef()
    const scrollRef = React.createRef()


    const initialInputRef = React.createRef()
    const [showCreateDialog, setshowCreateDialog] = React.useState(false)
    const [showCreateServiceDialog, setshowCreateServiceDialog] = React.useState(false)
    
    const [subtaskInputshown, setsubtaskInputshown] = React.useState(false)
    const [subtaskArray, setsubtaskArray] = React.useState([])
    const [subtaskInput, setsubtaskInput] = React.useState("")
    const [dueDateInput, setdueDateInput] = React.useState({
        value: '',
        validationStatus: ''
    })
    const [selectedRadio, setselectedRadio] = React.useState("0")

    //------------ category dialod forms
    const [categoryNameInput, setcategoryNameInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [additionalInfo, setadditionalInfo] = React.useState({
        value: '',
    })

    const [selectedCategory, setselectedCategory] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [selectedBillingType, setselectedBillingType] = React.useState("")
    const [amountPayable, setamountPayable] = React.useState("")
    const [serviceId, setserviceId] = React.useState("")


    // -----------------------------------------------
    // compliance dialog form ------------------------

    const [complianceInput, setcomplianceInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [selectedFilingPeriod,setselectedFilingPeriod] = React.useState("")


    const showRecurringBulkTaskDialog = (props) => {

          dispatch(createRecurringBulkTaskDialogOpenClose({
              recurringbulktaskDialogOpenClose:true,
              recurringCompId:props
          }))


    }

    



    const handleSortAscendingDecending = (props) => {

        setisAscending(!isAscending)
        if(isAscending===true){

            setcompliances((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.subcategory.compliance_name < b.subcategory.compliance_name) { return -1; }

                })

                return newState
            })


        }else{

            setcompliances((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.subcategory.compliance_name > b.subcategory.compliance_name) { return -1; }

                })

                return newState
            })
        }
       
     

  }

    // ------------------------------------------------
    const getAllCategories = (props) => {

         
        dispatch(getRecurringProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
             
        })).then((data) => {

             console.log("get recurring project compliance",data.payload)  
             setcategories(data.payload.category)
             const sortedArray = data?.payload.newRecurringComplianceArray?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
             setcompliances(sortedArray)
            // setcompliances(data.payload.newRecurringComplianceArray)

             setcomplianceType(data?.payload?.complianceType)
             setfilingPeriods(data?.payload?.filingPeriods)
             setbillingTypes(data?.payload?.billingTypes)

             if(showCreateServiceDialog===false){

                   const defaultRadio = data?.payload?.complianceType[0]?.id
                   setselectedRadio(defaultRadio)
             }


        })

    }

    const postServiceIndb = (props) => {

        if (complianceInput.value === "") {

            setcomplianceInput((prevState) => { return ({ ...prevState, validationStatus: 'error' }) })

        } else if (selectedCategory.value === "") {

            setselectedCategory((prevState) => { return ({ ...prevState, validationStatus: 'error' }) })

        } else {


               

            dispatch(postServiceCompliance({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                subcategoryName: complianceInput.value,
                dueDate: dueDateInput.value === "" ? "" : "" + new Date(dueDateInput.value) + "",
                billingType: selectedBillingType,
                amountPayable: amountPayable,
                subtasks: subtaskArray,
                serviceId: selectedCategory.value,
                complianceType: selectedRadio,
                filingPeriod:selectedFilingPeriod
            })).then((data) => {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Compliance / service successfully created.",
                    intent: Intent.SUCCESS
                });

                getAllCategories("")

            })

            closeCreateServiceDialog("")

        }


    }


    const postCategroyIndb = (props) => {

        if (categoryNameInput.value === "") {

            setcategoryNameInput((prevState) => {
                const newState = { ...prevState, validationStatus: 'error' }
                return newState
            })

        } else {



            dispatch(postServiceComplianceCategory({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                categoryName: categoryNameInput.value,
                additionalnfo: additionalInfo.value
            })).then((data) => {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Created successfully.",
                    intent: Intent.SUCCESS
                });

                setcategories(data.payload.data)
            })

            closeCategoryDialog("")


        }


    }

    const closeCategoryDialog = (props) => {

        setshowCreateDialog(false)
        setcategoryNameInput({
            value: '',
            validationStatus: ''
        })
        setadditionalInfo({
            value: ''
        })
    }

    const CreateCategoryDialog = (props) => {

        return (

            <Dialog
                isOpen={showCreateDialog}
                initialFocusRef={initialFocusRef}
                onDismiss={() => closeCategoryDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '800px',
                    height: '508px',

                }}
            >

                <Dialog.Header id="header-id">

                    <Text
                        ref={initialFocusRef}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#24292F'
                        }}
                    >
                        Create category
                    </Text>


                </Dialog.Header>

                <Box sx={{
                    position: 'absolute',
                    left: '27px',
                    right: '27px',
                    top: '88px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '400',
                        color: '#24292F'
                    }}

                    >
                        Create a category and then list your services and compliances under it to easily manage related tasks.
                    </Text>

                    <TextInput
                        ref={initialInputRef}
                        sx={{
                            position: 'relative',
                            margin: '25px 0px 0px 0px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '400',

                        }}
                        value={categoryNameInput.value}
                        validationStatus={categoryNameInput.validationStatus}
                        onChange={(e) => {
                            setcategoryNameInput((prevState) => { return ({ ...prevState, value: e.target.value, validationStatus: '' }) })
                        }}
                        placeholder="Please enter the name of the service/compliance category"
                    />

                    <Textarea
                        resize="none"
                        sx={{
                            position: 'relative',
                            margin: '17px 0px 0px 0px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '400',
                            minHeight: '100px',
                            maxHeight: '100px',
                            height: '100px'
                        }}
                        placeholder="Additional information( Optional )"
                        value={additionalInfo.value}
                        onChange={(e) => {
                            setadditionalInfo((prevState) => { return ({ ...prevState, value: e.target.value }) })
                        }}
                    >

                    </Textarea>

                </Box>


                <Box sx={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    borderTop: '1px solid #E1E4E8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    <Button
                        variant="outline"
                        sx={{
                            position: 'relative',
                            margin: '0px 27px 0px 0px',
                            height: '32px',
                            width: '58px',
                            minWidth: '58px',
                            maxWidth: '58px',
                            minHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            background: '#0965CE',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                        }}
                        onClick={() => {
                            postCategroyIndb("")
                        }}
                    >
                        Add
                    </Button>

                </Box>



            </Dialog>
        )
    }

    const closeCreateServiceDialog = (props) => {

        setshowCreateServiceDialog(false)
        setselectedRadio("0")
        setcomplianceInput({
            value: '',
            validationStatus: ''
        })
        setselectedCategory({
            value: '',
            validationStatus: ''
        })
        setdueDateInput({
            value: '',
            validationStatus: ''
        })
        setselectedBillingType('')
        setamountPayable('')
        setsubtaskArray([])

    }

    const CreateServiceDialog = (props) => {

        return (
            <Dialog
                isOpen={showCreateServiceDialog}
                initialFocusRef={initialFocusRef1}
                onDismiss={() => closeCreateServiceDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '800px',
                    height: '508px',
                }}
            >

                <Dialog.Header id="header-id">

                    <Text
                        ref={initialFocusRef1}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#24292F'
                        }}
                    >
                        Create such a service
                    </Text>


                </Dialog.Header>

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '52px',
                    top: '52px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '35px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px'
                    }}>

                        {complianceType.map((row,index)=>{
                             return(

                                 <FormControl>
                                     <Radio
                                         value={row.id}
                                         name={row.name}
                                         checked={selectedRadio === row.id ? true : false}
                                         onChange={(e) => { setselectedRadio(e.target.value) }}

                                     />
                                     <FormControl.Label sx={{
                                         fontFamily: 'Segoe UI',
                                         fontStyle: 'normal',
                                         fontWeight: '400',
                                         fontSize: '14px',
                                         lineHeight: '20px',
                                         color: '#24292E',
                                     }}>{row.name}</FormControl.Label>
                                 </FormControl>

                             )
                        })}

                       
                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <TextInput
                            ref={serviceNameInputRef}
                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                minWidth: '488px',
                                maxWidth: '488px',
                                width: '488px',
                                minHeight: '42px',
                                height: '42px',
                                maxHeight: '42px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            value={complianceInput.value}
                            validationStatus={complianceInput.validationStatus}
                            onChange={(e) => {
                                setcomplianceInput((prevState) => { return ({ ...prevState, value: e.target.value, validationStatus: '' }) })
                            }}
                            placeholder="Provided compliance / Services name"
                        />
                        <Box sx={{
                            width: '248px',
                            height: '42px',
                            minWidth: '248px',
                            maxWidth: '248px',
                            minHeight: '42px',
                            maxHeight: '40px',
                        }}>
                            <Select block style={{
                                width: '248px',
                                height: '40px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                                placeholder="Select category"
                                onChange={(e) => {

                                    if (e.target.value.split("|")[0] === "add") {
                                        setshowCreateDialog(true)
                                        setselectedCategory((prevState) => {
                                            return ({ ...prevState, value: '' })
                                        })
                                    } else {
                                        setselectedCategory((prevState) => {
                                            return ({ ...prevState, value: e.target.value.split("|")[0], validationStatus: '' })
                                        })
                                         

                                    }
                                }}
                                validationStatus={selectedCategory.validationStatus}

                            >

                                {categories.map((row, index) => {
                                    return (
                                        <Select.Option value={row?.category_id + "|" + ''}

                                        >{row?.category_name}</Select.Option>
                                    )
                                })}
                                <Select.Option value="add|''">Add new category</Select.Option>

                            </Select>
                        </Box>
                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <TextInput
                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                minWidth: '246px',
                                maxWidth: '246px',
                                width: '246px',
                                minHeight: '42px',
                                height: '42px',
                                maxHeight: '42px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}

                            placeholder="Due date"
                            value={dueDateInput.value !== null && dueDateInput.value !== "" ?
                                new Date(dueDateInput.value).getDate() + "/" + (new Date(dueDateInput.value).getMonth() + 1) + "/" + new Date(dueDateInput.value).getFullYear()
                                : ""}
                            onChange={(e) =>

                                dueDateCalandarRef.current.showPicker()
                            }
                            onFocus={(e) => {
                                dueDateCalandarRef.current.showPicker()
                            }}
                            validationStatus={dueDateInput.validationStatus}
                        />

                        <input
                            type={"date"}
                            ref={dueDateCalandarRef}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {


                                    setdueDateInput((prevState) => {
                                        const newState = { ...prevState, value: '' }
                                        return newState
                                    })


                                } else {

                                    setdueDateInput((prevState) => {
                                        const newState = { ...prevState, value: e.target.value, validationStatus: '' }
                                        return newState
                                    })

                                }


                            }}
                            style={{
                                position: 'absolute',
                                left: '1px',
                                top: '1px',
                                height: '40px',
                                width: '32%',
                                zIndex: '1',
                                visibility: 'hidden'
                            }}

                        />

                        <Box sx={{
                            width: '230px',
                            height: '40px',
                            minWidth: '230px',
                            maxWidth: '230px',
                            minHeight: '40px',
                            maxHeight: '40px',
                        }}>
                            <Select block style={{
                                width: '230px',
                                height: '40px',
                                minWidth: '230px',
                                maxWidth: '230px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                                placeholder="Billing Type"
                                onChange={(e) => {
                                    
                                    setselectedBillingType(e.target.value)
                                }}
                            >
                                 {billingTypes.map((row,index)=>{
                                      return(
                                        <Select.Option value={row.id}>{row.name}</Select.Option>
                                      )
                                 })}
                                 
                                
                            </Select>
                        </Box>

                        <TextInput
                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 2px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                width: '248px',
                                minHeight: '42px',
                                height: '42px',
                                maxHeight: '42px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            value={amountPayable}
                            onChange={(e) => {
                                setamountPayable(e.target.value)
                            }}
                            placeholder="amount payable (Optional)"
                        />



                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <Box sx={{
                            width: '246px',
                            height: '40px',
                            minWidth: '246px',
                            maxWidth: '246px',
                            minHeight: '40px',
                            maxHeight: '40px',
                        }}>
                            <Select block style={{
                                width: '246px',
                                height: '40px',
                                minWidth: '246px',
                                maxWidth: '246px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                                placeholder="Select filing period "
                                onChange={(e) => {
                                     
                                     setselectedFilingPeriod(e.target.value)
                                }}
                            >
                                {filingPeriods.map((row,index)=>{
                                       return(
                                        <Select.Option value={row.id}>{row.name}</Select.Option>
                                       )
                                })}

                              
                            </Select>
                        </Box>


                    </Box>


                    <Box sx={{
                        position: 'relative',
                        margin: '25px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}>
                            Subtask
                        </Text>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}>
                            : This stage could include the main tasks that need to be completed.
                        </Text>

                    </Box>



                    <Box sx={{
                        position: 'relative',
                        margin: '25px 27px 0px 27px',
                        display: subtaskArray.length > 0 ? 'flex' : 'none',
                        flexDirection: 'column',
                        gap: '15px',

                    }}>
                        {subtaskArray.map((row, index) => {
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '522px',
                                    maxWidth: '522px',
                                    width: '522px',
                                    alignItems: 'center',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px'
                                }}>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#24292E',
                                        width: '488px',
                                        maxWidth: '488px',
                                        minWidth: '488px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px'
                                    }}>
                                        {row.task_name}
                                    </Text>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            right: '0px',

                                            border: 'none',
                                            boxShadow: 'none',
                                            background: '#ffffff'
                                        }}
                                        icon={() => <XIcon />}
                                        onClick={() => {
                                            setsubtaskArray((prevState) => {
                                                const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                return newState
                                            })
                                        }}
                                    />


                                </Box>
                            )
                        })}
                    </Box>

                    {subtaskInputshown === true ?
                        <Box sx={{
                            position: 'relative',
                            margin: '25px 27px 0px 27px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            alignItems: 'center',
                            gap: '1px'
                        }}>

                            <TextInput
                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px -1px',
                                    minWidth: '488px',
                                    maxWidth: '488px',
                                    width: '488px',
                                    minHeight: '42px',
                                    height: '42px',
                                    maxHeight: '42px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}
                                value={subtaskInput}
                                onChange={(e) => setsubtaskInput(e.target.value)}
                                placeholder="Enter subtask"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setsubtaskArray((prevState) => {
                                            const newState = [...prevState, { task_id: '' + (Math.floor(Math.random() * 1000000) + 1) + '', task_name: subtaskInput }]
                                            setsubtaskInput("")
                                            setsubtaskInputshown(false)
                                            return newState
                                        })
                                    }
                                }}
                            />

                            <IconButton
                                sx={{
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: '#ffffff'
                                }}
                                icon={() => <XIcon />}
                                onClick={() => {
                                    setsubtaskInputshown(false)
                                }}
                            />


                        </Box>
                        : ""}


                    <Button variant="invisible" sx={{
                        position: 'relative',
                        margin: '17px 0px 0px 27px',
                        minHeight: '32px',
                        minWidth: '118px',
                        height: '32px',
                        width: '118px',
                        maxWidth: '118px',
                        maxHeight: '32px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                        onClick={() => {
                            setsubtaskInputshown(true)
                        }}
                    >

                        Enter Subtask
                    </Button>


                   



                </Box>


                

                <Box sx={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    borderTop: '1px solid #E1E4E8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    <Button
                        variant="outline"
                        sx={{
                            position: 'relative',
                            margin: '0px 27px 0px 0px',
                            height: '32px',
                            width: '73px',
                            minWidth: '73px',
                            maxWidth: '73px',
                            minHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            background: '#0965CE',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                        }}
                        onClick={() => {
                            postServiceIndb()
                        }}
                    >
                        Create
                    </Button>

                </Box>



            </Dialog>

        )
    }

  
     



    React.useEffect(() => {

        if (showCreateServiceDialog === true) {


            serviceNameInputRef.current.focus()





        } else {


        }

        getAllCategories("")


    }, [showCreateServiceDialog])


    React.useEffect(() => {

        if (showCreateDialog === true) {

            initialInputRef.current.focus()
        } else {


        }

        getAllCategories("")

    }, [showCreateDialog])

    React.useEffect(()=>{

         if(showComplianceExpansion===true){

            scrollRef.current.scrollIntoView({ behavior: "smooth" });
            
         }

    },[showComplianceExpansion])


    React.useEffect(()=>{
 
        if(taskRecurringTrigger===true){

            getAllCategories("")
        }



    },[taskRecurringTrigger])


    React.useState(() => {

        getAllCategories("")

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
          
        >
            <Helmet>
                <title>
                    Roadmap - deski
                </title>
            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
           

             <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
               
            >

                <Box
                    ref={scrollRef}
                    sx={{
                        position: 'absolute',
                        top: '1px',
                        right: '1px',
                        height: '10px',
                        width: '10px',
                        zIndex: '100',
                     }}>

                </Box>

                <Box 
               
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '28px',
                    minHeight: '18px',
                    minWidth: '280px',
                    maxWidth: '280px',
                    maxHeight: '18px',
                    height: '18px',
                    width: '280px'
                }}>
                    <Text 
                   
                    sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '18px'
                    }}>
                      Recurring Compliance and services
                    </Text>
                </Box>


                <Box sx={{

                    position: 'absolute',
                    top: '20px',
                    right: '61px',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    display: showComplianceExpansion===true?'none':'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right',
                    gap: '21px',

                }}>

                    <IconButton
                        sx={{
                            border: 'none',
                            display: 'flex',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            boxShadow: 'none'
                        }}
                        icon={() => isAscending===true? <SortDescIcon sx={{ display: 'flex', position: 'relative', margin: '9px 0px 0px 0px' }} size={16} />:<SortAscIcon sx={{ display: 'flex', position: 'relative', margin: '9px 0px 0px 0px' }} size={16} />}
                        onClick={()=>handleSortAscendingDecending("")}
                    />


                    <ActionMenu>
                        <ActionMenu.Anchor>
                        <Button
                            sx={{
                                width: '58px',
                                minWidth: '58px',
                                maxWidth: '58px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}

                        >
                            Add
                        </Button>
                        </ActionMenu.Anchor>
                        <ActionMenu.Overlay>
                            <ActionList sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '6px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}>
                                <ActionList.Item onSelect={() => {
                                    setshowCreateServiceDialog(true)
                                }}>
                                    Service / Compliance
                                </ActionList.Item>
                                <ActionList.Item onSelect={() => {
                                    setshowCreateDialog(true)
                                }}>
                                    Add category
                                </ActionList.Item>
                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>




                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '62px',
                    height: '1px',
                    background: '#E7E7ED'
                }}>

                </Box>


                
                {/* <Breadcrumbs sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '80px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}

                >
                    <Breadcrumbs.Item
                        onClick={() => { }}>Recurring Compliance and services
                    </Breadcrumbs.Item>
                </Breadcrumbs> */}
               



                <Box sx={{
                    position: 'absolute',
                    top: '117px',
                    left: '26px',
                    right: '61px',
                    minHeight: 'fit-content',
                    background: 'white',
                    display:showComplianceExpansion===false?'flex':'none',
                    flexDirection: 'column',

                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '35px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',

                    }}

                    >


                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '370px',
                            maxWidth: '370px',
                            minWidth: '370px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 15px'
                        }}>
                            Compliance
                        </Text>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '370x',
                            maxWidth: '370px',
                            minWidth: '370px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 20px'
                        }}>
                            Categories
                        </Text>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '370x',
                            maxWidth: '370px',
                            minWidth: '370px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 15px'
                        }}>
                            Filing periods
                        </Text>



                    </Box>
                 
                    {compliances.map((comp, comindex) => {

                        
                                  return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '35px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        ':hover': {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        }
                                    }}
                                        onClick={() => {
                                             showRecurringBulkTaskDialog(comp.service_id+"|"+comp.subcategory.compliance_name)
                                        }}
                                    >


                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '19px',
                                            color: '#121212',
                                            width: '370x',
                                            maxWidth: '370px',
                                            minWidth: '370px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            margin: '0px 0px 0px 15px'
                                        }}>
                                            {comp.subcategory.compliance_name}
                                             
                                        </Text>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            minWidth: '330px',
                                            maxWidth: '330px',
                                            width: '330px',
                                            flexWrap: 'nowrap',
                                            overflow: 'hidden',

                                        }}>

                                            <Label sx={{
                                                width: 'fit-content',
                                                minWidth: 'fit-content',
                                                maxWidth: 'fit-content',
                                                height: '20px',
                                                borderRadius: '3px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {categories?.filter((cat)=>cat?.category_id===comp?.subcategory?.category_id)[0]?.category_name}
                                                
                                            </Label>




                                        </Box>




                                    </Box>
                                )
                            
                       

                    })}



                    <Box sx={{ minHeight: '50x', height: '50px', minWidth: '100%', background: '#F6F8FA' }}>

                    </Box>





                </Box>


                 
 


            </Box>





            {CreateServiceDialog("")}
            {CreateCategoryDialog("")}
            <CreateRecurringBulkTaskDialog />
           
            <ScheduleDemoDialog />
            <SearchDialog />
        </Box>
    )

}

export default Roadmap