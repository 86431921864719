import React from "react"
import { Avatar, Box, Heading, Text, NavList, TextInput, Button, Spinner } from "@primer/react"
import { Pencil1Icon, CheckIcon } from "@radix-ui/react-icons"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import WorkplaceHeader from "../Workplace/Header"
import WorkplaceLeftSideNav from "../Workplace/SideNav"
import SpaceHeader from "../Space/SpaceHeader"
import SpaceLeftSideNav from "../Space/SpaceLeftSideNav"
import SpaceRightSideNav from "../Space/SpaceRightSideNav"
import { getUserProfileData, getUserProfileFromDatabase, updateUserProfileInDb, updateProfileImage, updateProfileImageRealTimeLocal } from "../redux/features/profileSlice"
import { BellIcon, CreditCardIcon, FeedPersonIcon, PeopleIcon, PersonIcon, TrashIcon } from "@primer/octicons-react"
import EmptyData from "../Space/EmptyData"
import BillingPlans from "./BillingPlans"
import ChangePassword from "./ChangePassword"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import AWS from 'aws-sdk'
import mixpanel from "mixpanel-browser"
mixpanel.init('42f38a9f4804449b88177fb8bbfec5c6', { debug: true, ip: true });
 
const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION
AWS.config.update({
    accessKeyId: IAM_USER_KEY,
    secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
    region: REGION,
})


function Account() {

    const dispatch = useDispatch()
    const fileRef = React.useRef()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const [userProfileData, setuserProfileData] = React.useState([])
    const [showInputLoading, setshowInputLoading] = React.useState({
        fieldName: '',
        isLoading: false,
        trailingVistual: ''
    })



    const [showloadingSpinner, setshowloadingSpinner] = React.useState(false)
    const [selectedIndex, setselectedIndex] = React.useState(0)
    const profileData = useSelector((state) => state.profileData)
    const [userOrgData,setuserOrgData] = React.useState(null)


    const [selectedFile, setselectedFile] = React.useState("")  //"https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"

    const [showworkplaceHeader, setshowworkplaceHeader] = React.useState(false)

    const deleteUser = async (props) => {
       
    }


    const updateUserProfileImageIndb = (props) => {

        setshowloadingSpinner(true)
        setselectedFile(URL.createObjectURL(props))


        dispatch(updateProfileImageRealTimeLocal({
            userImageUrl: URL.createObjectURL(props)
        }))

        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'Userprofileimage/' + userId,
            'ContentType': props.type
        };


        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {
                console.log(err, err.stack)
            } else {

                console.log(data)

                setselectedFile(data.Location)
                setshowloadingSpinner(false)
                dispatch(updateProfileImage({
                    userImageUrl: data.Location,
                    userId: userId
                })).then((data) => {



                })

                dispatch(updateProfileImageRealTimeLocal({
                    userImageUrl: data.Location
                }))


            };



        })







    }

    const updateLocationInMixpanel = (userId, userLocation) => {

          const identifyUser = mixpanel.identify(userId)
          const updateUserLocation = mixpanel.people.set_once('user_location',userLocation)
           
    }

    const handleUpdateProfile = (e, props) => {

        if (props === "firstName") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: props, isLoading: true }
                return newState
            })

            setuserProfileData((prevState) => {

                const newState = prevState.map(
                    wData => wData.user_id === userId ? { ...wData, user_first_name: e.target.value } : wData
                )



                return newState

            })

            dispatch(updateUserProfileInDb({
                userId: userId,
                userEmail: userEmail,
                firstName: e.target.value,
                lastName: '',
                organization: '',
                userRole: '',
                userLocation: ''
            })).then((data) => {

                setshowInputLoading((prevState) => {
                    const newState = { ...prevState, fieldName: props, isLoading: false, trailingVistual: <CheckIcon /> }
                    return newState
                })

                Toaster.create({ position: Position.TOP }).show({
                    message: "The first name has been updated.",
                    intent: Intent.PRIMARY
                });


            })






        } else if (props === "lastName") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: props, isLoading: true }
                return newState
            })

            setuserProfileData((prevState) => {

                const newState = prevState.map(
                    wData => wData.user_id === userId ? { ...wData, user_last_name: e.target.value } : wData
                )

                return newState

            })

            dispatch(updateUserProfileInDb({
                userId: userId,
                userEmail: userEmail,
                firstName: '',
                lastName: e.target.value,
                organization: '',
                userRole: '',
                userLocation: ''
            })).then((data) => {

                setshowInputLoading((prevState) => {
                    const newState = { ...prevState, fieldName: props, isLoading: false, trailingVistual: <CheckIcon /> }
                    return newState
                })

                Toaster.create({ position: Position.TOP }).show({
                    message: "The second name has been updated.",
                    intent: Intent.PRIMARY
                });


            })






        } else if (props === "organization") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: props, isLoading: true }
                return newState
            })

            setuserProfileData((prevState) => {

                const newState = prevState.map(
                    wData => wData.user_id === userId ? { ...wData, user_organization: e.target.value } : wData
                )



                return newState

            })

            dispatch(updateUserProfileInDb({
                userId: userId,
                userEmail: userEmail,
                firstName: '',
                lastName: '',
                organization: e.target.value,
                userRole: '',
                userLocation: ''
            })).then((data) => {

                setshowInputLoading((prevState) => {
                    const newState = { ...prevState, fieldName: props, isLoading: false, trailingVistual: <CheckIcon /> }
                    return newState
                })

                Toaster.create({ position: Position.TOP }).show({
                    message: "The organization name has been updated.",
                    intent: Intent.PRIMARY
                });


            })


        } else if (props === "userRole") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: props, isLoading: true }
                return newState
            })

            setuserProfileData((prevState) => {

                const newState = prevState.map(
                    wData => wData.user_id === userId ? { ...wData, user_role: e.target.value } : wData
                )



                return newState

            })

            dispatch(updateUserProfileInDb({
                userId: userId,
                userEmail: userEmail,
                firstName: '',
                lastName: '',
                organization: '',
                userRole: e.target.value,
                userLocation: ''
            })).then((data) => {

                setshowInputLoading((prevState) => {
                    const newState = { ...prevState, fieldName: props, isLoading: false, trailingVistual: <CheckIcon /> }
                    return newState
                })

                Toaster.create({ position: Position.TOP }).show({
                    message: "The role has been updated.",
                    intent: Intent.PRIMARY
                });



            })


        } else if (props === "userLocation") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: props, isLoading: true }
                return newState
            })

            setuserProfileData((prevState) => {

                const newState = prevState.map( wData => wData.user_id === userId ? { ...wData, user_location: e.target.value } : wData)
                return newState

            })

            

            dispatch(updateUserProfileInDb({
                userId: userId,
                userEmail: userEmail,
                firstName: '',
                lastName: '',
                organization: '',
                userRole: '',
                userLocation: e.target.value
            })).then((data) => {

                setshowInputLoading((prevState) => {
                    const newState = { ...prevState, fieldName: props, isLoading: false, trailingVistual: <CheckIcon /> }
                    return newState
                })

                Toaster.create({ position: Position.TOP }).show({
                    message: "The location has been updated",
                    intent: Intent.PRIMARY
                });


            })





        }


    }


    const handleBlurProfile = (props) => {

        if (props === "firstName") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: '', isLoading: false, trailingVistual: '' }
                return newState
            })
        } else if (props === "lastName") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: '', isLoading: false, trailingVistual: '' }
                return newState
            })
        } else if (props === "organization") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: '', isLoading: false, trailingVistual: '' }
                return newState
            })
        } else if (props === "userRole") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: '', isLoading: false, trailingVistual: '' }
                return newState
            })
        } else if (props === "userLocation") {

            setshowInputLoading((prevState) => {
                const newState = { ...prevState, fieldName: '', isLoading: false, trailingVistual: '' }
                return newState
            })
        }


    }



    React.useState(() => {
        dispatch(getUserProfileData({ id: "" }))

        dispatch(getUserProfileFromDatabase({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {


            setuserProfileData(data.payload.data)
            setselectedFile(data.payload.data[0]?.user_image_url)
            setuserOrgData(data.payload.userOrganizationData)



        })




        if (localStorage.getItem("locationPath") === "/workplace") {

            setshowworkplaceHeader(true)

        } else {

            setshowworkplaceHeader(false)
        }




    })


    const Profile = (props) => {

        return (<Box sx={{
            display: 'flex',
            flexDirection: "column",
            width: '780px',

        }}>
            <Helmet>

                <title>Account - deski</title>

            </Helmet>
            <Heading sx={{
                height: '32px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#000000',

            }}>{props.title}

            </Heading>

            <Box sx={{ position: 'relative', margin: '17px 0px 0px 0px', width: '780px' }} borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>


            <Box sx={{ display: 'flex', flexDirection: "row", position: 'relative', margin: '35px 0px 0px 0px' }}>

                <TextInput
                    onFocus={(e) => setshowInputLoading((prevState) => {
                        return ({ ...prevState, fieldName: "firstName" })
                    })}
                    contrast={showInputLoading.fieldName === "firstName" ? false : true}
                    sx={{

                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        width: '248px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="First name"
                    value={userProfileData[0]?.user_first_name}
                    onChange={(e) => handleUpdateProfile(e, "firstName")}
                    loading={showInputLoading.fieldName === "firstName" && showInputLoading.isLoading === true ? true : false}
                    trailingVisual={showInputLoading.fieldName === "firstName" && showInputLoading.isLoading === false ? showInputLoading.trailingVistual : ''}
                    onBlur={() => handleBlurProfile("firstName")}
                />

                <TextInput
                    onFocus={(e) => setshowInputLoading((prevState) => {
                        return ({ ...prevState, fieldName: "lastName" })
                    })}
                    contrast={showInputLoading.fieldName === "lastName" ? false : true}
                    sx={{

                        position: 'absolute',
                        left: '271px',
                        //  position:'relative',
                        //  margin:'0px 0px 0px 20px',
                        width: '248px',
                        minWidth: '248px',
                        maxWidth: '248px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="Last name"
                    value={userProfileData[0]?.user_last_name}
                    onChange={(e) => handleUpdateProfile(e, "lastName")}
                    loading={showInputLoading.fieldName === "lastName" && showInputLoading.isLoading === true ? true : false}
                    trailingVisual={showInputLoading.fieldName === "lastName" && showInputLoading.isLoading === false ? showInputLoading.trailingVistual : ''}
                    onBlur={() => handleBlurProfile("lastName")}
                />

            </Box>

            <Text sx={{
                width: '404px',
                height: '40px',
                position: 'relative',
                margin: '5px 0px 0px 0px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#586069'
            }}>
                Your name may appear around deski  where you contribute or are mentioned.
                You can remove it at any time
            </Text>

            <Box sx={{ display: 'flex', flexDirection: "row", position: 'relative', margin: '25px 0px 0px 0px' }}>

                <TextInput
                    contrast
                    sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        width: '516px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="Email"
                    disabled
                    value={userProfileData[0]?.user_email}

                />



            </Box>

            <Box sx={{ display: 'flex', flexDirection: "row", position: 'relative', margin: '25px 0px 0px 0px' }}>

                <TextInput
                    disabled
                    onFocus={(e) => setshowInputLoading((prevState) => {
                        return ({ ...prevState, fieldName: "organization" })
                    })}
                    contrast={showInputLoading.fieldName === "organization" ? false : true}
                    sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        width: '248px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="Organization"
                    value={userOrgData?.project_name}
                  //  value={userProfileData[0]?.user_organization}
                    onChange={(e) => handleUpdateProfile(e, "organization")}
                    loading={showInputLoading.fieldName === "organization" && showInputLoading.isLoading === true ? true : false}
                    trailingVisual={showInputLoading.fieldName === "organization" && showInputLoading.isLoading === false ? showInputLoading.trailingVistual : ''}
                    onBlur={() => handleBlurProfile("organization")}
                />

                <TextInput
                    onFocus={(e) => setshowInputLoading((prevState) => {
                        return ({ ...prevState, fieldName: "userRole" })
                    })}
                    contrast={showInputLoading.fieldName === "userRole" ? false : true}
                    sx={{
                        position: 'absolute',
                        left: '271px',
                        // position:'relative',
                        //  margin:'0px 0px 0px 20px',
                        width: '248px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="Your Role"
                    value={userProfileData[0]?.user_role}
                    onChange={(e) => handleUpdateProfile(e, "userRole")}
                    loading={showInputLoading.fieldName === "userRole" && showInputLoading.isLoading === true ? true : false}
                    trailingVisual={showInputLoading.fieldName === "userRole" && showInputLoading.isLoading === false ? showInputLoading.trailingVistual : ''}
                    onBlur={() => handleBlurProfile("userRole")}
                />

            </Box>

            <Box sx={{ display: 'flex', flexDirection: "row", position: 'relative', margin: '25px 0px 0px 0px' }}>

                <TextInput
                    onFocus={(e) => setshowInputLoading((prevState) => {
                        return ({ ...prevState, fieldName: "userLocation" })
                    })}
                    contrast={showInputLoading.fieldName === "userLocation" ? false : true}
                    sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        width: '248px',
                        height: '40px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#121212'
                    }}
                    placeholder="Location"
                    value={userProfileData[0]?.user_location}
                    onChange={(e) => handleUpdateProfile(e, "userLocation")}
                    loading={showInputLoading.fieldName === "userLocation" && showInputLoading.isLoading === true ? true : false}
                    trailingVisual={showInputLoading.fieldName === "userLocation" && showInputLoading.isLoading === false ? showInputLoading.trailingVistual : ''}
                    onBlur={() => 
                        
                        {
                            handleBlurProfile("userLocation")
                            updateLocationInMixpanel(userId,userProfileData[0]?.user_location)
                        }
                    }
                />



            </Box>


            <Box sx={{
                position: "absolute",
                left: '606px',
                top: '85px',

            }}>
                {showloadingSpinner === true ?

                    <Spinner sx={{
                        position: 'absolute',
                        top: '0px',
                        bottom: '28px',
                        left: '0px',
                        right: '0px',
                        margin: 'auto'
                    }} />

                    :

                    ""}

                {selectedFile === "" || selectedFile == null ?

                    <FeedPersonIcon size={150} fill="#586069" />

                    :
                    <Avatar size={150}
                        src={selectedFile}

                    />

                }




                <Button
                    sx={{
                        width: '64px',
                        height: '28px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        position: 'relative',
                        margin: '0px 0px 0px auto',
                        borderRadius: '6px'
                    }}
                    onClick={() => fileRef.current.click()}
                    leadingIcon={() => <Pencil1Icon style={{ display: 'flex' }} />}>Edit</Button>

                <input
                    ref={fileRef}
                    style={{ display: 'none', position: 'absolute', width: '0px', height: '0px' }}
                    type={"file"}
                    accept="image/png,image/jpeg"
                    onChange={async (e) => {
                        updateUserProfileImageIndb(e.target.files[0])

                    }

                    }
                    hidden
                />
            </Box>

            <Button
                sx={{
                    width: '204px',
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '12px',
                    lineHeight: '18px',
                    position: 'relative',
                    margin: '255px 0px 150px 0px'
                }}
                variant="danger"
                onClick={() => deleteUser("")}
            >
                Permanently delete your account
            </Button>


        </Box>)
    }



    const NotificationPlans = (props) => {

        return (<Box sx={{
            display: 'flex',
            flexDirection: "column",
            width: '780px',
        }}

        >
            <Helmet>

                <title>{props.title}</title>

            </Helmet>
            <Heading sx={{
                height: '32px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#000000',

            }}>{props.title}</Heading>
            <Box sx={{ position: 'relative', margin: '17px 0px 0px 0px', width: '780px' }} borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>

            <EmptyData
                position="relative"
                top="98px"
            />

        </Box>)
    }

    const Team = (props) => {

        return (<Box sx={{
            display: 'flex',
            flexDirection: "column",
            width: '780px',
        }}>
            <Helmet>

                <title>{props.title}</title>

            </Helmet>
            <Heading sx={{
                height: '32px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#000000',

            }}>{props.title}</Heading>
            <Box sx={{ position: 'relative', margin: '17px 0px 0px 0px', width: '780px' }} borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
            <EmptyData
                position="relative"
                top="98px"
            />
        </Box>)
    }

    const Bin = (props) => {

        return (<Box sx={{ display: 'flex', flexDirection: "column" }}>
            <Helmet>

                <title>{props.title}</title>

            </Helmet>
            <Heading sx={{
                height: '32px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#000000',

            }}>{props.title}</Heading>

            <Box sx={{ position: 'relative', margin: '17px 0px 0px 0px', width: '780px' }} borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                color: '#586069',
                position: 'relative',
                margin: '7px 0px 0px 0px'
            }} >
                Items in trash are deleted forever after 7 days
            </Text>

        </Box>)
    }


    return (<Box
        position={"relative"}
        display="flex"
        background={"#F6F8FA"}
        height="100vh"
        width={"100vw"}
        overflowY={"auto"}
    >

        {showworkplaceHeader === true ? <WorkplaceHeader /> : <SpaceHeader />}

        {showworkplaceHeader === true ? <WorkplaceLeftSideNav /> : <SpaceLeftSideNav />}

        {showworkplaceHeader === true ? "" : <SpaceRightSideNav />}


        {/* <SpaceHeader /> */}
        {/* <SpaceLeftSideNav />
        <SpaceRightSideNav /> */}

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            margin: '103px 0px 0px 103px',
            height: 'fit-content',
            width: 'fit-content'
        }}>

            {selectedFile === "" || selectedFile == null ?

                <FeedPersonIcon size={64} fill="#586069" />
                :
                <Avatar size={64} src={selectedFile} />
            }



            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    margin: 'auto 0px 8px 15px'
                }}

            >
                <Heading sx={{
                    width: '181px',
                    height: '20px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#24292F',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}
                >
                    {profileData?.profileData?.signInUserSession?.idToken?.payload.email.match(/^([^@]*)@/)[1]}
                </Heading>

                <Text sx={{
                    width: '181px',
                    height: '18px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#586069',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'

                }}>{profileData?.profileData?.signInUserSession?.idToken?.payload.email}</Text>
            </Box>

        </Box>




        <NavList
            sx={{
                position: 'absolute',
                left: '115px',
                top: '208px',
                width: '200px',

                // width:'139px',
                // height:'180px',

                // background:'#FFFFFF',
            }}
        >
            <NavList.Item
                aria-current={selectedIndex === 0 ? true : false}
                onClick={() => setselectedIndex(0)}

            >

                <NavList.LeadingVisual
                    sx={{
                        position: 'relative',
                        margin: 'auto 0px auto 0px'
                    }}
                >
                    <PersonIcon size={16} />
                </NavList.LeadingVisual>
                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        position: 'relative',
                        margin: 'auto 0px auto 11px',
                        display: 'flex'

                    }}
                >
                    Account
                </Text>

            </NavList.Item>
            <Box sx={{ height: '8px' }}>

            </Box>
            <NavList.Item
                aria-current={selectedIndex === 1 ? true : false}
                onClick={() => setselectedIndex(1)}
            >
                <NavList.LeadingVisual
                    sx={{
                        position: 'relative',
                        margin: 'auto 0px auto 0px'
                    }}
                >
                    <CreditCardIcon size={16} />
                </NavList.LeadingVisual>
                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        position: 'relative',
                        margin: 'auto 0px auto 11px',
                        display: 'flex'
                    }}
                >
                    Billing and plans
                </Text>

            </NavList.Item>
            <Box sx={{ height: '8px' }}>

            </Box>
            <NavList.Item
                aria-current={selectedIndex === 2 ? true : false}
                onClick={() => setselectedIndex(2)}
            >
                <NavList.LeadingVisual
                    sx={{
                        position: 'relative',
                        margin: 'auto 0px auto 0px'
                    }}
                >
                    <BellIcon size={16} />
                </NavList.LeadingVisual>
                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        position: 'relative',
                        margin: 'auto 0px auto 11px',
                        display: 'flex'
                    }}
                >
                    Notifications
                </Text>

            </NavList.Item>
            <Box sx={{ height: '8px' }}>

            </Box>
            <NavList.Item
                aria-current={selectedIndex === 3 ? true : false}
                onClick={() => setselectedIndex(3)}
            >
                <NavList.LeadingVisual
                    sx={{
                        position: 'relative',
                        margin: 'auto 0px auto 0px'
                    }}
                >
                    <PeopleIcon size={16} />
                </NavList.LeadingVisual>
                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        position: 'relative',
                        margin: 'auto 0px auto 11px',
                        display: 'flex'
                    }}
                >
                    Team
                </Text>

            </NavList.Item>
            <Box sx={{ height: '8px' }}>

            </Box>
            <NavList.Item
                aria-current={selectedIndex === 4 ? true : false}
                onClick={() => setselectedIndex(4)}
            >
                <NavList.LeadingVisual
                    sx={{
                        position: 'relative',
                        margin: 'auto 0px auto 0px'
                    }}
                >
                    <TrashIcon size={16} />
                </NavList.LeadingVisual>
                <Text
                    sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        position: 'relative',
                        margin: 'auto 0px auto 11px',
                        display: 'flex'
                    }}
                >
                    Recycle bin
                </Text>

            </NavList.Item>
        </NavList>



        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '158px',
            left: '407px',
            right: '277px',
            height: 'fit-content',

        }}>

            {selectedIndex === 0 ? Profile({ title: 'Profile' }) :
                selectedIndex === 1 ? <BillingPlans title="Billing and plans" /> :
                    // selectedIndex === 2 ? <NotificationPlans title="Notifications" />:
                    selectedIndex === 2 ? <ChangePassword title="ChangePassword" /> :
                        selectedIndex === 3 ? <Team title="Manage team" /> :
                            selectedIndex === 4 ? <Bin title="Deleted projects" /> :
                                ""}





        </Box>





    </Box>)
}

export default Account