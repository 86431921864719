import React from 'react';
import './CustomSwitch.css'; // Assuming you save the CSS code in a separate file called "Switch.css"

const CustomSwitch = (props) => {

  return (
    <label className="switch">
      <input type="checkbox" defaultChecked={props.checked==="Yearly"?true:false} />
      <span className="slider round"></span>
    </label>
  );
};

export default CustomSwitch;