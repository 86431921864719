import { useNavigate } from 'react-router-dom'
import { Box,Button,Heading,Text,Dialog,Spinner } from "@primer/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import {OrganizationIcon} from '@primer/octicons-react';
import {defaultSuccessDialogOpenClose, sharedOrgOpenClose, updateUserDefaultOrgId} from '../redux/features/contactSlice';
import { getSubscriptionbyOrganizationId, openCloseFreeTrialEndDialog } from '../redux/features/profileSlice';
import { deleteProjectInvitations, deleteSharedSpace, getUserInvitations, getUserOrganizations } from '../redux/features/spaceSlice';
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
 
function formatDate(inputString) {
    const date = new Date(inputString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
}

export default function SharedOrganization(props){

    const userId = useSelector((state) => state.profileData.userId) 
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const sharedOrgisOpen = useSelector((state)=> state.contactProfileData.sharedOrgisOpen)
    const defaultDialogFocus = React.useRef()

    const [allProjects,setallProjects] = React.useState([]) //props.allProjects
    const [invitations,setinvitations] = React.useState([]) 
    const [showLoading,setShowLoading] = React.useState("")

   
    //closeDefaultOrgDialog
    const scloseDefaultOrgDialog = (props) => {

        dispatch(sharedOrgOpenClose({
            sharedOrgisOpen: false
        }))

    }
   
    //accept Invitation In Db
    const acceptInvitationInDb = async (props) => {
            
        
        const url = new URL(`${process.env.REACT_APP_INVITATIONURL}/?invitationId=${props.invitation_id}`);
        const result = await fetch(url)
        console.log("acceptInvitationInDb", result)

        if (result.ok) {
            handleMarkasDefault(props.project_id)
            getUserInvitationsFromDb("")




        }

    }

    //delete Invitation In Db
    const deleteInvitationInDb = (props) => {

        dispatch(deleteProjectInvitations({
            userId: userId,
            userEmail: userEmail,
            projectId: props.project_id,
            invitationId: props.invitation_id
        })).then((data) => {

            if (data.payload.data !== null && data.payload.data !== "") {
                 getUserInvitationsFromDb("")
            }


        })


    }

    //get All invitation by userId
    const getUserInvitationsFromDb = (props) => {

        dispatch(getUserInvitations({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getUserInvitations", data.payload)
            setinvitations(data.payload.inviations)
            setallProjects(data.payload.sharedArray)
        })

    }

    //exit from Space if already accepted workspace
    const handleExitFromTeam = (props) => {

        const deletTeamData = { userId: userId }
        dispatch(deleteSharedSpace({
            userId: userId,
            userEmail: userEmail,
            projectId: props,
            deleteTeamData: deletTeamData
        })).then((data) => {

            console.log("deleteSharedSpace", data.payload)
            getUserInvitationsFromDb("")
            // scloseDefaultOrgDialog("")
            // navigate('/workplace')

        })

    }
     
 
    const handleMarkasDefault = (selectedProjectId) => {


         setShowLoading(selectedProjectId)
         if (selectedProjectId) {

          
            dispatch(getSubscriptionbyOrganizationId({
                userId: userId,
                userEmail: userEmail,
                projectId: selectedProjectId
            })).then((data) => {
                const customerId = data.payload.body[0]?.customer?.id;
                const subscriptionStatus = data.payload.subscriptionStatus;
                // Check if the subscription status is active or in_trial
                if (subscriptionStatus === "active" || subscriptionStatus === "in_trial") {
                    dispatch(updateUserDefaultOrgId({
                        userId: userId,
                        userEmail: userEmail,
                        organizationId: selectedProjectId
                    })).then(() => {

                        setShowLoading("")
                        scloseDefaultOrgDialog("");
                        dispatch(defaultSuccessDialogOpenClose({
                            defaultSuccessisOpen: true
                        }))
                        // navigate('/workplace');
                    });
                } else {
                       setShowLoading("")
                    scloseDefaultOrgDialog("");
                    dispatch(openCloseFreeTrialEndDialog({
                        freetrialEndOpen: true,
                        freeTrialCustomerId: customerId
                    }));
                }
            });
        }else{
              setShowLoading("")

        }
    };
    


    React.useEffect(() => {

        if (sharedOrgisOpen == true) {
             
            getUserInvitationsFromDb("")
        }

    }, [sharedOrgisOpen])


    return(
        <Dialog
            isOpen={sharedOrgisOpen}
            onDismiss={() => {
                scloseDefaultOrgDialog("")
            }}
            sx={{
                position: 'absolute',
                width: '921px',
                height: '526px',
                background:customThemVariable.whiteBgColor,
                border: '1px solid #D0D7DE',
                borderRadius: '3px'
            }}
            initialFocusRef={defaultDialogFocus}
        >

            <Box sx={{
                position: 'absolute',
                top: '35px',
                left: '40px',
                right: '40px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: '11px',

            }}>

                <Heading sx={{
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: customThemVariable.fontStyleNormal,
                    fontWeight:customThemVariable.fontWeightSemiBold,
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: customThemVariable.primaryTextColor,
                    letterSpacing: '0.3px'
                }}>
                    Collaboration invitations
                </Heading>

               
            </Box>


            <Box sx={{
                position: 'absolute',
                top: '105px',
                left: '40px',
                right: '40px',
                height: '351px',
                maxHeight: '351px',
                minHeight: '351px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>
         

                {allProjects?.map((pDta, index) => {

                    return (

                        <Box
                            sx={{
                                height: '78px',
                                minHeight: '78px',
                                maxHeight: '78px',
                                display: 'flex',
                                flexDirection: 'row',
                                cursor: 'pointer',
                                borderRadius:'3px',
                                border:'0.5px solid #D0D7DE',
                                background:customThemVariable.whiteBgColor,
                                
                            }}>


                            <Box sx={{
                                position: 'absolute',
                                margin: '13px 0px 0px 20px',
                                width: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                background: '#F0FDE8',
                                borderRadius: '50%',
                                color:'#79CE17',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center'
                            }}>
                                <OrganizationIcon size={16} />
                            </Box>

                            <Box sx={{
                                   position: 'absolute',
                                   margin: '13px 0px 0px 76px',
                                   width: '535px',
                                   minWidth: '535px',
                                   maxWidth: '535px',
                                   height: '52px',
                                   minHeight: '52px',
                                   maxHeight: '52px',
                                   display:'flex',
                                   flexDirection:'column'
                            }}>

                                 <Box sx={{ display: 'flex', flexDirection: 'row',gap:'17px' }}>
                                    <Text sx={{
                                        fontFamily:customThemVariable.primartFont,
                                        fontSize:'14px',
                                        fontStyle:customThemVariable.fontStyleNormal,
                                        fontWeight:customThemVariable.fontWeightRegular,
                                        lineHeight:'20px',
                                        color:customThemVariable.primaryTextColor
                                        }}>
                                        You've been invited to collaborate on
                                    </Text>
                                    <Text
                                        aria-label={pDta.project_name}
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: customThemVariable.fontStyleNormal,
                                            fontWeight:customThemVariable.fontWeightSemiBold,
                                            fontSize: '14px',
                                            lineHeight:'24px',
                                            color:customThemVariable.primaryTextColor,
                                            minWidth: '199px',
                                            maxWidth: '199px',
                                            width: '199px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}>

                                        {pDta.project_name}

                                    </Text>

                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px' }}>
                                    <Text sx={{
                                        fontFamily: customThemVariable.primartFont,
                                        fontSize: '14px',
                                        fontStyle: customThemVariable.fontStyleNormal,
                                        fontWeight:customThemVariable.fontWeightRegular,
                                        lineHeight: '20px',
                                        color: customThemVariable.secondaryTextColor,
                                        minWidth:'244px',
                                        maxWidth:'244px',
                                        width:'244px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }}>
                                        Invitee : {pDta.invity_email}
                                    </Text>
                                    <Text
                                        aria-label={pDta.project_name}
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: customThemVariable.fontStyleNormal,
                                            fontWeight:customThemVariable.fontWeightRegular,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: customThemVariable.secondaryTextColor,
                                            minWidth: '199px',
                                            maxWidth: '199px',
                                            width: '199px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}>

                                        Date : {formatDate(pDta.timestamp)}
                                        

                                    </Text>

                                </Box>


                            </Box>

                            

                            <Box sx={{
                                position: 'absolute',
                                right: '20px',
                                height:'fit-content',
                                margin: '21px 0px 0px 0px',
                                display:  'flex',
                                flexDirection: 'row',
                                gap:'10px'
                            }}>

                                {showLoading===pDta.project_id?
                                    <Button
                                        variant="outline"
                                        sx={{
                                            padding: '10px 20px 10px 20px',
                                            border: 'none',
                                            fontFamily: customThemVariable.primartFont,
                                            background: '#0969DA',
                                            fontSize: '14px',
                                            fontWeight:customThemVariable.fontWeightSemiBold,
                                            color:customThemVariable.whiteBgColor,
                                            minWidth: '88px',
                                            maxWidth: '88px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}

                                    >
                                        <Spinner sx={{ display: 'flex' }} size='small' />
                                    </Button>                               
                                :
                                
                                <Button
                                    variant="outline"
                                    sx={{
                                        padding: '10px 20px 10px 20px',
                                        border: 'none',
                                        fontFamily: customThemVariable.primartFont,
                                        background: '#0969DA',
                                        fontSize: '14px',
                                        fontWeight:customThemVariable.fontWeightSemiBold,
                                        color:customThemVariable.whiteBgColor
                                    }}
                                    onClick={() => {
                                        
                                        handleMarkasDefault(pDta.project_id)
                                    }}
                                >Accept</Button>
                                
                                
                                }

                                

                                <Button
                                    variant="invisible"
                                    onClick={() => {
                                        handleExitFromTeam(pDta.project_id)
                                    }}
                                    sx={{
                                        padding: '10px 20px 10px 20px',
                                        border: 'none',
                                        fontFamily: customThemVariable.primartFont,
                                        fontSize: '14px',
                                        fontWeight:customThemVariable.fontWeightSemiBold,
                                    }}
                                >Discard</Button>

                            </Box>




                        </Box>

                    )


                })}

                {invitations?.map((pDta, index) => {


                    return (

                        <Box
                            sx={{
                                height: '78px',
                                minHeight: '78px',
                                maxHeight: '78px',
                                display: 'flex',
                                flexDirection: 'row',
                                cursor: 'pointer',
                                borderRadius:'3px',
                                border:'0.5px solid #D0D7DE',
                                background:customThemVariable.whiteBgColor
                            }}>

                            <Box sx={{
                                position: 'absolute',
                                margin: '13px 0px 0px 20px',
                                width: '40px',
                                minWidth: '40px',
                                maxWidth: '40px',
                                height: '40px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                background: '#F0FDE8',
                                borderRadius: '50%',
                                color: '#79CE17',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <OrganizationIcon size={16} />
                            </Box>   

                            <Box sx={{
                                   position: 'absolute',
                                   margin: '13px 0px 0px 76px',
                                   width: '535px',
                                   minWidth: '535px',
                                   maxWidth: '535px',
                                   height: '52px',
                                   minHeight: '52px',
                                   maxHeight: '52px',
                                   display:'flex',
                                   flexDirection:'column'
                            }}>

                                 <Box sx={{ display: 'flex', flexDirection: 'row',gap:'17px' }}>
                                    <Text sx={{
                                        fontFamily:customThemVariable.primartFont,
                                        fontSize:'14px',
                                        fontStyle:customThemVariable.fontStyleNormal,
                                        fontWeight:customThemVariable.fontWeightRegular,
                                        lineHeight:'20px',
                                        color:customThemVariable.primaryTextColor}}>
                                        You've been invited to collaborate on
                                    </Text>
                                    <Text
                                        aria-label={pDta.project_name}
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: customThemVariable.fontStyleNormal,
                                            fontWeight: customThemVariable.fontWeightSemiBold,
                                            fontSize: '14px',
                                            lineHeight:'24px',
                                            color:customThemVariable.primaryTextColor,
                                            minWidth: '199px',
                                            maxWidth: '199px',
                                            width: '199px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}>

                                        {pDta.project_name}

                                    </Text>

                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '17px' }}>
                                    <Text sx={{
                                        fontFamily:customThemVariable.primartFont,
                                        fontSize: '14px',
                                        fontStyle: customThemVariable.fontStyleNormal,
                                        fontWeight:customThemVariable.fontWeightRegular,
                                        lineHeight: '20px',
                                        color: customThemVariable.secondaryTextColor,
                                        minWidth:'244px',
                                        maxWidth:'244px',
                                        width:'244px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }}>
                                        Invitee : {pDta.invity_email}
                                    </Text>
                                    <Text
                                        aria-label={pDta.project_name}
                                        sx={{
                                            fontFamily:customThemVariable.primartFont,
                                            fontStyle:customThemVariable.fontStyleNormal,
                                            fontWeight:customThemVariable.fontWeightRegular,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color:customThemVariable.secondaryTextColor,
                                            minWidth: '199px',
                                            maxWidth: '199px',
                                            width: '199px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}>

                                        Date : {formatDate(pDta.timestamp)}
                                        

                                    </Text>

                                </Box>


                            </Box>

                            <Box sx={{
                                position: 'absolute',
                                right: '20px',
                                height: 'fit-content',
                                margin: '21px 0px 0px 0px',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px'
                            }}>
                              
                                {showLoading===pDta.project_id ? 
                                    
                                    <Button
                                        variant="outline"
                                        sx={{
                                            padding: '10px 20px 10px 20px',
                                            border: 'none',
                                            fontFamily: customThemVariable.primartFont,
                                            background: '#0969DA',
                                            fontSize: '14px',
                                            fontWeight: customThemVariable.fontWeightSemiBold,
                                            color: customThemVariable.whiteBgColor,
                                            minWidth: '88px',
                                            maxWidth:'88px',
                                            display:'flex',
                                            justifyContent:'center',
                                            alignItems:'center'
                                        }}

                                    >
                                        <Spinner sx={{display:'flex'}} size='small' />
                                    </Button>
                                
                                :
                                <Button
                                    
                                variant="outline"
                                sx={{
                                    padding: '10px 20px 10px 20px',
                                    border: 'none',
                                    fontFamily: customThemVariable.primartFont,
                                    background: '#0969DA',
                                    fontSize: '14px',
                                    fontWeight:customThemVariable.fontWeightSemiBold,
                                    color: customThemVariable.whiteBgColor
                                }}
                                onClick={() => {
                                    setShowLoading(pDta.project_id)
                                    acceptInvitationInDb({ project_id: pDta.project_id, invitation_id: pDta.invitation_id })
                                }}
                            >Accept</Button>
                                
                                } 

                               

                                <Button
                                    variant="invisible"
                                    onClick={() => {
                                        deleteInvitationInDb({project_id:pDta.project_id,invitation_id:pDta.invitation_id})
                                    }}
                                    sx={{
                                        padding: '10px 20px 10px 20px',
                                        border: 'none',
                                        fontFamily:customThemVariable.primartFont,
                                        fontSize: '14px',
                                        fontWeight:customThemVariable.fontWeightSemiBold,
                                    }}
                                >Discard</Button>

                            </Box>

                             

                        </Box>

                    )


                })}





            </Box>



             


        </Dialog>
    )


}