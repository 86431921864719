import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const createSpaceOpenClose = createAsyncThunk("createSpaceOpenClose", async (data) => {


    return ({ openClose: data.openClose, workplaceId: data.workplaceId })

})





export const deleteWorkplaceOpenClose = createAsyncThunk("deleteSpaceOpenClose", async (data) => {



    return ({ deleteOpenClose: data.openClose, deleteWorkplaceData: data.deleteWorkplaceData, deleteSpaceData: data.deleteSpaceData })

})


export const createRoadmapOpenClose = createAsyncThunk("createRoadmapOpenClose", async (data) => {

    return ({ roadmapOpenClose: data.roadmapOpenClose, roadMapProjectId: data.roadMapProjectId, roadMapTaskId: data.roadMapTaskId })

})




export const createworkplaceProject = createAsyncThunk("createworkplaceProject", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workplaceId": data.workplaceId,
        "projectName": data.projectName,
        "projectDescription": data.projectDescription,

    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/postProject`, requestOptions)
        .then(response => response.json())

    return result



})


export const getUserOrganizationById = createAsyncThunk("getUserOrganizationById", async (data) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}/getUserOrganizationById?userId=${encodeURIComponent(data.userId)}&userEmail=${encodeURIComponent(data.userEmail)}`);
    const data2 = await response.json();
    return data2

})

export const getUserOrganizations = createAsyncThunk("getUserOrganizations", async (data) => {


    const response = await fetch(`${process.env.REACT_APP_API_URL}/getUserOrganizations?userId=${encodeURIComponent(data.userId)}&userEmail=${encodeURIComponent(data.userEmail)}`);
    const data2 = await response.json();
    return data2

})


export const getWorkplaceProject = createAsyncThunk("getWorkplaceProject", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "workplaceId": data.workplaceId,

    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getProjectByWorkplace`, requestOptions)
        .then(response => response.json())

    return result


    // const url = new URL('https://go.deskiplatforms.com/getProjectByWorkplace');
    // const params = { userId: data.userId, userEmail: data.userEmail, workplaceId: data.workplaceId };
    // url.search = new URLSearchParams(params);

    // const result = await fetch(url).then(response => response.json())
    // console.log("resultsample",result)
    // return result

    // const response = await fetch(`https://go.deskiplatforms.com/getProjectByWorkplace?userId=${encodeURIComponent(data.userId)}&userEmail=${encodeURIComponent(data.userEmail)}&workplaceId=${encodeURIComponent(data.workplaceId)}`);
    // const data2 = await response.json();
    // console.log("resultsample",data2)
    // return data2 

    // const response = await fetch(`https://api.deski.co/getProjectByWorkplace?userId=${encodeURIComponent(data.userId)}&userEmail=${encodeURIComponent(data.userEmail)}&workplaceId=${encodeURIComponent(data.workplaceId)}`);
    // const data2 = await response.json();
    // console.log("resultsample",data2)
    // return data2 


})

export const getSingleWorkplaceProject = createAsyncThunk("getSingleWorkplaceProject", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getSingleWorkplaceProject`, requestOptions)
        .then(response => response.json())

    return result

})

export const getAllWorksByProjectId = createAsyncThunk("getAllWorksByProjectId", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getAllWorksByProjectId`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})


export const getGanttData = createAsyncThunk("getGanttData", async (data) => {


    const url = new URL(`${process.env.REACT_APP_API_URL}/getGanttData`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})


export const getListData = createAsyncThunk("getListData", async (data) => {


    //getListData
    const url = new URL(`${process.env.REACT_APP_API_URL}/getListData`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})


export const getAllWorksByWorkflowId = createAsyncThunk("getAllWorksByWorkflowId", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getAllWorksByProjectId`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId, workflowId: data.workflowId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})


export const isworkflowNameInputEdit = createAsyncThunk("isworkflowNameInputEdit", async (data) => {

    const result = { workflowId: data.workflowId, workflowName: data.workflowName, workflowStatus: data.workflowStatus }

    return result
})

export const updateProjectWorkflowName = createAsyncThunk("updateProjectWorkflowName", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workflowId": data.workflowId,
        "workflowName": data.workflowName,
        "flowStage": data.flowStage
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateProjectWorkflow`, requestOptions)
        .then(response => response.json())

    return result


})

export const updateProjectWorkFlowStage = createAsyncThunk("updateProjectWorkFlowStage", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workflowId": data.workflowId,
        "workflowName": "",
        "flowStage": data.flowStage,
        "workflowHeads": data.workflowHeads
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateProjectWorkflow`, requestOptions)
        .then(response => response.json())

    return result


})


export const postNewProjectWorkFlow = createAsyncThunk("postNewProjectWorkFlow", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workflowName": data.workflowName,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/postNewWorkflow`, requestOptions)
        .then(response => response.json())

    return result

})

export const getAllWorkplaceProject = createAsyncThunk("getAllWorkplaceProject", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,


    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/searchWorkplaceProjects`, requestOptions)
        .then(response => response.json())

    return result



})


export const getSpaceCollaborators = createAsyncThunk("getSpaceCollaborators", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getProjectCollaborators?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result



})


export const addNewWorkflowHead = createAsyncThunk("addNewWorkflowHead", async (data) => {

    return ({ newArray: data.newWorkflowHead })

})


export const updateProjectName = createAsyncThunk("updateProjectName", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "projectName": data.projectName,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})


//workingHours
export const updateWorkingHours = createAsyncThunk("updateWorkingHours", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workingHours": data.workingHours,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})

//updateCollaboratorWorkingHours
export const updateCollaboratorWorkingHours = createAsyncThunk("updateCollaboratorWorkingHours", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workingHoursCollaborator": data.workingHoursCollaborator,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})


export const addNewProjectRoadmap = createAsyncThunk("addNewProjectRoadmap", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "roadMapData": data.roadMapData,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})

export const updateProjectOverView = createAsyncThunk("updateProjectOverView", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "projectOverView": data.projectOverView,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})

export const uploadProjectFiles = createAsyncThunk("uploadProjectFiles", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "projectAttachments": data.projectAttachments,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result


})


export const syncGoogleDriveFolder = createAsyncThunk("syncGoogleDriveFolder", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "gdriveData": data.gdriveData,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/syncGoogleDriveFolder`, requestOptions)
        .then(response => response.json())


    return result



})




export const getSpaceFiles = createAsyncThunk("getSpaceFiles", async (data) => {

    // var myHeaders = new Headers();
    // myHeaders.append('Content-type','application/json')

    // const urlencoded = JSON.stringify({
    //     "userId":data.userId,
    //     "userEmail":data.userEmail,
    //     "projectId":data.projectId,

    // })

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: urlencoded,
    //     redirect: 'follow'
    // };

    // const result = await fetch("https://go.deskiplatforms.com/getProjectFiles",requestOptions)
    // .then(response => response.json())


    // return result

    const url = `${process.env.REACT_APP_API_URL}/getProjectFiles?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result




})


export const getInsightData = createAsyncThunk("getInsightData", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getInsightData?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})




export const getProjectInvitations = createAsyncThunk("getProjectInvitations", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getProjectInvitations?userId=${data.userId}&userEmail=${data.userEmail}&projectId=${data.projectId}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})


export const getHomeStatics = createAsyncThunk("getHomeStatics", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getHomeStatics?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})


export const getPaidUsers = createAsyncThunk("getPaidUsers", async (data) => {

    const url = `${process.env.REACT_APP_API_URL}/getPaidUsers?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})

export const getS3Size = createAsyncThunk("getS3Size", async (data) => {


    const url = `${process.env.REACT_APP_API_URL}/getS3Size?userId=${data.userId}&userEmail=${data.userEmail}`;
    const response = await fetch(url);
    const result = await response.json();
    return result

})



export const deleSpaceWorkflow = createAsyncThunk("deleSpaceWorkflow", async (data) => {

    //https://jzu71apjbc.execute-api.us-east-2.amazonaws.com/deleteProjectWorkflow

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workflowId": data.workflowId
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteProjectWorkflow`, requestOptions)
        .then(response => response.json())


    return result


})

export const deleteSpace = createAsyncThunk("deleteSpace", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteProject`, requestOptions)
        .then(response => response.json())


    return result
})


export const duplicateProject = createAsyncThunk("duplicateProject", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "duplicateProjectId": data.duplicateProjectId,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateWorkplaceProject`, requestOptions)
        .then(response => response.json())


    return result




})


export const uploadWorkflowImport = createAsyncThunk("uploadWorkflowImport", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "workflowData": data.workflowData,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/uploadWorkflow/uploadWorkflow`, requestOptions)
        .then(response => response.json())


    return result


})

export const getProjectServices = createAsyncThunk("getProjectServices", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getProjectServices`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const getRecurringProjectServices = createAsyncThunk("getRecurringProjectServices", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getRecurringProjectServices`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})


export const getProjectWorkflow = createAsyncThunk("getProjectWorkflow", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getProjectWorkflow`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const postServiceComplianceCategory = createAsyncThunk("postServiceComplianceCategory", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "categoryName": data.categoryName,
        "additionalnfo": data.additionalnfo
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/postServiceComplianceCategory`, requestOptions)
        .then(response => response.json())


    return result

})

export const postServiceCompliance = createAsyncThunk("postServiceCompliance", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "subcategoryName": data.subcategoryName,
        "dueDate": data.dueDate,
        "billingType": data.billingType,
        "amountPayable": data.amountPayable,
        "subtasks": data.subtasks,
        "serviceId": data.serviceId,
        "complianceType": data.complianceType,
        "filingPeriod": data.filingPeriod,
        "selectedTriggerType": data.selectedTriggerType
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/postServiceCompliance`, requestOptions)
        .then(response => response.json())

    return result

})


export const updateComplianceSubtask = createAsyncThunk("updateComplianceSubtask", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "complianceId": data.complianceId,
        "mainSubtasks": data.mainSubtasks,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateComplianceSubtask`, requestOptions)
        .then(response => response.json())
    return result

})


export const getComplianceSubtasks = createAsyncThunk("getComplianceSubtasks", async (data) => {


    const url = new URL(`${process.env.REACT_APP_API_URL}/getComplianceSubtasks`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId, complianceId: data.complianceId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})

export const updateServiceCompliance = createAsyncThunk("updateServiceCompliance", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "subcategoryName": data.subcategoryName,
        "dueDate": data.dueDate,
        "billingType": data.billingType,
        "amountPayable": data.amountPayable,
        "subtasks": data.subtasks,
        "serviceId": data.serviceId,
        "complianceType": data.complianceType,
        "filingPeriod": data.filingPeriod,
        "categoryId": data.categoryId,
        "remindMeType": data.remindMeType,
        "selectedAssigne": data.selectedAssigne
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateServiceCompliance`, requestOptions)
        .then(response => response.json())


    return result

})




export const getComplianceTaksByProjectId = createAsyncThunk("getComplianceTaksByProjectId", async (data) => {

    const url = new URL(`${process.env.REACT_APP_API_URL}/getComplianceTaksByProjectId`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})

export const getRecurringComplianceTaksByProjectId = createAsyncThunk("getRecurringComplianceTaksByProjectId", async (data) => {


    const url = new URL(`${process.env.REACT_APP_API_URL}/getRecurringComplianceTaksByProjectId`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result


})

export const getAppIntegration = createAsyncThunk("getAppIntegration", async (data) => {


    const url = new URL(`${process.env.REACT_APP_API_URL}/getAppIntegration`);
    const params = { userId: data.userId, userEmail: data.userEmail, projectId: data.projectId };
    url.search = new URLSearchParams(params);
    const result = await fetch(url).then(response => response.json())
    return result

})

export const installAppIntegration = createAsyncThunk("installAppIntegration", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "appId": data.appId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/installAppIntegration`, requestOptions)
        .then(response => response.json())


    return result



})



export const deleteTeamMember = createAsyncThunk("deleteTeamMember", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "deleteTeamData": data.deleteTeamData,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteTeamMember`, requestOptions)
        .then(response => response.json())


    return result

})

export const deleteSharedSpace = createAsyncThunk("deleteSharedSpace", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "deleteTeamData": data.deleteTeamData,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteSharedSpace`, requestOptions)
        .then(response => response.json())


    return result

})

export const getUserInvitations = createAsyncThunk("getUserInvitations", async (data) => {

    //getUserInvitations

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/getUserInvitations`, requestOptions)
        .then(response => response.json())


    return result


})


export const deleteProjectInvitations = createAsyncThunk("deleteProjectInvitations", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "invitationId": data.invitationId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/deleteProjectInvitations`, requestOptions)
        .then(response => response.json())


    return result


})

export const getTeamLog = createAsyncThunk("getTeamLog", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "collaboratorId": data.collaboratorId,
        "selectedPeriod": data.selectedPeriod,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/getTeamLog`, requestOptions)
        .then(response => response.json())


    return result

})

export const getTimesheetData = createAsyncThunk("getTimesheetData", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "collaboratorId": data.collaboratorId,
        "startDate": data.startDate,
        "endDate": data.endDate
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };



    const result = await fetch(`${process.env.REACT_APP_API_URL}/getTimesheetData`, requestOptions)
        .then(response => response.json())


    return result


})

export const postManualComplianceTasks = createAsyncThunk("postManualComplianceTasks", async (data) => {


    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "notificationData": data.notificationData,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/postManualComplianceTasks`, requestOptions)
        .then(response => response.json())


    return result

})

export const updateProjectCompliaceTrigger = createAsyncThunk("updateProjectCompliaceTrigger", async (data) => {
    //updateProjectCompliaceTrigger 

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "complianceTriggerType": data.complianceTriggerType
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateProjectCompliaceTrigger`, requestOptions)
        .then(response => response.json())


    return result

})

export const updateSingleProjectCompliaceTrigger = createAsyncThunk("updateSingleProjectCompliaceTrigger", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "complianceTriggerType": data.complianceTriggerType,
        "complianceId": data.complianceId,
        "selectedCollab": data.selectedCollab
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/updateSingleProjectCompliaceTrigger`, requestOptions)
        .then(response => response.json())


    return result


})

export const bulkComplianceDeadLineUpdate = createAsyncThunk("bulkComplianceDeadLineUpdate", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "complianceId": data.complianceId,
        "dueDate": data.dueDate,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/bulkComplianceDeadLineUpdate`, requestOptions)
        .then(response => response.json())

    return result

})

//resend inviations --------------- resendInvitation

export const resendInvitation = createAsyncThunk("resendInvitation", async (data) => {

    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')

    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "projectId": data.projectId,
        "invitationId": data.invitationId,

    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };


    const result = await fetch(`${process.env.REACT_APP_API_URL}/resendInvitation`, requestOptions)
        .then(response => response.json())

    return result

})



//---------------------------------



export const createSpaceOpenCloseSlice = createSlice({

    name: 'createSpaceOpenClose',
    initialState: {
        openClose: false,
        loading: false,
        deleteOpenClose: false,
        roadmapOpenClose: false,
        roadMapTaskId: '',
        roadMapProjectId: '',
        workplaceId: '',
        workflowHead: [],
        workflowIEditData:
        {
            workflowId: '',
            workflowName: ''
        },
        searchProjects: [],
        projectRoadMaps: [],
        spacefilesArray: [],
        deleteWorkplaceData: {
            workplaceId: '',
            workplaceName: ''
        },
        deleteSpaceData: {
            projectId: '',
            projectName: ''
        },
        spaceDeleteTrigger: false,
        spaceCollabs: [],
        spaceReloadTrigger: false,
        spaceProjects: [],

    },
    extraReducers: {

        [createSpaceOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [createSpaceOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose
            state.workplaceId = action.payload.workplaceId
        },
        [createSpaceOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [deleteWorkplaceOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.deleteOpenClose = false

        },
        [deleteWorkplaceOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.deleteOpenClose = action.payload.deleteOpenClose
            state.deleteSpaceData = action.payload.deleteSpaceData
            state.deleteWorkplaceData = action.payload.deleteWorkplaceData

        },
        [deleteWorkplaceOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.deleteOpenClose = false
        }
        ,
        [isworkflowNameInputEdit.pending]: (state, action) => {
            state.loading = true;
            state.workflowIEditData = { workflowId: '', workflowName: '', workflowStatus: '' }

        },
        [isworkflowNameInputEdit.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.workflowIEditData = action.payload

        },
        [isworkflowNameInputEdit.rejected]: (state, action) => {
            state.loading = false
            state.workflowIEditData = { workflowId: '', workflowName: '', workflowStatus: '' }
        },

        [getSingleWorkplaceProject.pending]: (state, action) => {
            state.loading = true;


        },
        [getSingleWorkplaceProject.fulfilled]: (state, action) => {

            // alert(JSON.stringify(action.payload?.data[0]?.workflow))

            state.loading = false//action.payload.loading;
            state.workflowHead = action.payload?.data[0]?.workflow
            state.projectRoadMaps = action.payload.data[0]?.project_roadmap
        },
        [getSingleWorkplaceProject.rejected]: (state, action) => {
            state.loading = false

        },
        [getAllWorkplaceProject.pending]: (state, action) => {
            state.loading = true;


        },
        [getAllWorkplaceProject.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.searchProjects = action.payload.workplaceProjects

        },
        [getAllWorkplaceProject.rejected]: (state, action) => {
            state.loading = false

        },
        [addNewWorkflowHead.pending]: (state, action) => {
            state.loading = true;


        },
        [addNewWorkflowHead.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.workflowHead = action.payload.newArray

        },
        [addNewWorkflowHead.rejected]: (state, action) => {
            state.loading = false

        },
        [createRoadmapOpenClose.pending]: (state, action) => {
            state.loading = true;


        },
        [createRoadmapOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.roadmapOpenClose = action.payload.roadmapOpenClose
            state.roadMapTaskId = action.payload.roadMapTaskId
            state.roadMapProjectId = action.payload.roadMapProjectId

        },
        [createRoadmapOpenClose.rejected]: (state, action) => {
            state.loading = false

        },
        [getSpaceFiles.pending]: (state, action) => {
            state.loading = true;


        },
        [getSpaceFiles.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.spacefilesArray = action.payload.data

        },
        [getSpaceFiles.rejected]: (state, action) => {
            state.loading = false

        },
        [deleteSpace.pending]: (state, action) => {
            state.loading = true;
            state.spaceDeleteTrigger = false

        },
        [deleteSpace.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.spaceDeleteTrigger = true

        },
        [deleteSpace.rejected]: (state, action) => {
            state.loading = false
            state.spaceDeleteTrigger = false
        },
        [getSpaceCollaborators.pending]: (state, action) => {
            state.loading = true;


        },
        [getSpaceCollaborators.fulfilled]: (state, action) => {
            state.loading = false;
            state.spaceCollabs = action.payload?.collaboratorArray

        },
        [getSpaceCollaborators.rejected]: (state, action) => {
            state.loading = false

        },
        [duplicateProject.pending]: (state, action) => {
            state.loading = true;
            state.spaceReloadTrigger = false

        },
        [duplicateProject.fulfilled]: (state, action) => {
            state.loading = false;
            state.spaceReloadTrigger = true

        },
        [duplicateProject.rejected]: (state, action) => {
            state.loading = false
            state.spaceReloadTrigger = false
        },
        [getWorkplaceProject.pending]: (state, action) => {
            state.loading = true;


        },
        [getWorkplaceProject.fulfilled]: (state, action) => {
            state.loading = false;
            state.spaceProjects = action.payload?.data

        },
        [getWorkplaceProject.rejected]: (state, action) => {
            state.loading = false

        },



    }


})


export default createSpaceOpenCloseSlice.reducer

