import { Box } from "@primer/react";
import TaxHeroHeaderSubComponent from "./TaxHeroHeaderSubComponentSanbox";
import workplaceTheme from "../Workplace/Workplacetheme"
import React from "react";
import {UnderlineNav} from '@primer/react/drafts'
import { useLocation,Link, useNavigate } from "react-router-dom";
const portalHeaderLogo = 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/AccountSetup/portalLogo.svg'

export default function TaxHeroHeaderSanbox() {

    let host = window.location.host
    const domainName = window.location.hostname

    const navigate = useNavigate()
    const location = useLocation()
    const [selectedIndex, setselectedIndex] = React.useState(0)
    const [selectedItHeadIndex, setselectedItHeadIndex] = React.useState(0)

    const [items, setItems] = React.useState([
        { navigation: 'Profile', route: "/gstsanbox", isSelected: false },
        { navigation: 'Return', route: "/returnsanbox", isSelected: false },
        { navigation: 'Demand', route: "/demandsanbox", isSelected: false },
        { navigation: 'Ledger', route: "/ledgersanbox", isSelected: false },
        { navigation: 'Additional Notice', route: "/additionalnoticesanbox", isSelected: false },
        { navigation: 'Notice', route: "/noticesanbox", isSelected: false },
        { navigation: 'Refund', route: "/refundsanbox", isSelected: false },
        { navigation: 'Challan', route: "/challansanbox", isSelected: false },
    ]) 

    const [itHeads, setitHeads] = React.useState([
        { navigation: 'Profile', route: "/Itsanbox", isSelected: false },
        { navigation: 'Return', route: "/ItReturnsanbox", isSelected: false },
        { navigation: 'Demand', route: "/ItDemandsanbox", isSelected: false },
        { navigation: 'Ledger', route: "/ItLedgersanbox", isSelected: false },
        { navigation: 'Proceeding', route: "/proceedingsanbox", isSelected: false },
        { navigation: 'Proceeding notice', route: "/proceedingNoticesanbox", isSelected: false },
        { navigation: 'Challan', route: "/ItChallansanbox", isSelected: false },
    ])

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


     return (
         <Box
             borderColor="border.default"
             borderWidth={1}
             borderStyle="solid"
             sx={{
                 position: 'fixed',
                 top: '0px',
                 left: '0px',
                 right: '0px',
                 minHeight: '48px',
                 maxHeight: '48px',
                 height: '48px',
                 background: '#FFFFFF',
                 zIndex: '10'
             }}
         >

            <Box
                sx={{
                    display: 'flex',
                    border: 'none',
                    position: 'absolute',
                    left: 50,
                    right: 80,
                    top: 2,

                }}
            >
                 <img
                     style={workplaceTheme.logoStyle}
                     src={portalHeaderLogo}
                     onClick={() => { navigate('/taxheroportal') }}
                 />


                 <Box

                     style={{
                         display:
                                 location.pathname === "/noticesanbox" ||
                                 location.pathname === "/gstsanbox" ||
                                 location.pathname === "/demandsanbox" ||
                                 location.pathname === "/additionalnoticesanbox" ||
                                 location.pathname === "/returnsanbox" ||
                                 location.pathname === "/refundsanbox" ||
                                 location.pathname === "/ledgersanbox" ||
                                 location.pathname === "/challansanbox"

                                 ? "flex" : "none"
                     }}
                     sx={{
                         width: '650px',
                         minWidth: '650px',
                         height: 48,
                         minHeight: 48,
                         position: 'absolute',
                         left: '314px',
                         margin: 'auto',
                         top: 0,
                         bottom: 0,
                         fontFamily: 'Avenir LT Std',
                         fontStyle: 'normal',
                         fontWeight: '400',
                         fontSize: '14px',
                         color: '#121212',

                     }}>
                     <UnderlineNav
                         key={"" + Math.random(10, 10000) + ""}
                         sx={{
                             border: 'none',
                             position: 'absolute',
                             width: '650px',
                             maxWidth: '650px',
                             minWidth: '650px',
                             bottom: 0,
                             paddingLeft: '0px',
                             paddingRight: '0px'
                         }}

                     >
                         {items.map((item, index) => (


                             <UnderlineNav.Item
                                 as={Link}
                                 to={item.route}
                                 key={item.navigation}
                                 aria-current={index === selectedIndex ? 'page' : undefined}
                                 selected={item.route === location.pathname}
                                 onSelect={e => {
                                     setselectedIndex(index)
                                     e.preventDefault()
                                 }}
                                 onClick={() => {
                                     setselectedIndex(index)
                                     navigate(item.route)
                                 }}

                             >
                                 {item.navigation}
                             </UnderlineNav.Item>
                         ))}
                     </UnderlineNav>
                 </Box>

               
                 <Box
                     style={{
                         display:
                             location.pathname === "/Itsanbox" ||
                                 location.pathname === "/ItDemandsanbox" ||
                                 location.pathname === "/proceedingsanbox" ||
                                 location.pathname === "/proceedingNoticesanbox" ||
                                 location.pathname === "/ItReturnsanbox" ||
                                 location.pathname === "/ItLedgersanbox" ||
                                 location.pathname === "/ItChallansanbox"
                                 ? "flex" : "none"
                     }}
                     sx={{
                         width: '650px',
                         minWidth: '650px',
                         height: 48,
                         minHeight: 48,
                         position: 'absolute',
                         left: '314px',
                         margin: 'auto',
                         top: 0,
                         bottom: 0,
                         fontFamily: 'Avenir LT Std',
                         fontStyle: 'normal',
                         fontWeight: '400',
                         fontSize: '14px',
                         color: '#121212',

                     }}>
                     <UnderlineNav
                         key={"" + Math.random(10, 10000) + ""}
                         sx={{
                             border: 'none',
                             position: 'absolute',
                             width: '650px',
                             maxWidth: '650px',
                             minWidth: '650px',
                             bottom: 0,
                             paddingLeft: '0px',
                             paddingRight: '0px'
                         }}

                     >
                         {itHeads.map((item, index) => (
                             <UnderlineNav.Item
                                 as={Link}
                                 to={item.route}
                                 key={item.navigation}
                                 aria-current={index === selectedItHeadIndex ? 'page' : undefined}
                                 selected={item.route === location.pathname}
                                 onSelect={e => {
                                     setselectedItHeadIndex(index)
                                     e.preventDefault()
                                 }}
                                 onClick={() => {
                                     setselectedItHeadIndex(index)
                                     navigate(item.route)
                                 }}

                             >
                                 {item.navigation}
                             </UnderlineNav.Item>
                         ))}
                     </UnderlineNav>
                 </Box>


                

                <TaxHeroHeaderSubComponent />

            </Box>
      </Box>)

}