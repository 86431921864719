import { Box, Button,Text,StyledOcticon,IconButton,Dialog,TextInput,Link,Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./HeaderSanbox"
import SideNav from "./SideNavSanbox"
import { useDispatch,useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../Theme.module.scss"  
import customThemVariable from  "../../ThemeVariable.module.scss"
import { ArrowLeftIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, CopyIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getItPortalByItrId } from "../redux/features/portalSlice"
import { Toaster, Intent,Position } from "@blueprintjs/core";
import TaxHeroHeader from "./TaxHeroHeaderSanbox"

function ItrExpansionSanbox(props){

    let hostName = window.location.hostname 
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()
    
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)  
    
    const clientId = localStorage.getItem("client_id")|| ""
    const insertId = localStorage.getItem("insert_id") || ""
    const itrId = localStorage.getItem("itr_id") || ""
    const itrRurnSingleData = JSON.parse(localStorage.getItem("itrRurnSingleData")) || ""

    const passwordRef = React.useRef(null)
    const [allDetails,setallDetails] = React.useState([])
    const [timeLineArray,settimeLineArray] = React.useState([])

    const handledCopyPassword = (props) => {

        if (passwordRef.current) {

                navigator.clipboard.writeText(passwordRef.current.innerText)
                .then(() => {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Copied",
                        intent: Intent.SUCCESS
                    });

                }).catch((error) => {
                    
                });

        }
    }


    const getItPortalByItrIdFromDb = (props) => {

        dispatch(getItPortalByItrId({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            itrId: itrId
        })).then((data) => {

            console.log("getItPortalByItrId", data.payload)
            setallDetails(data?.payload?.data)

        })

    }

    React.useState(() => {

        getItPortalByItrIdFromDb("")

    })

     React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return(
        <Box
        position={"relative"} 
        display="flex" 
        background={"#F6F8FA"} 
        height="100vh"
        width={"100vw"} 
        overflowY={"auto"}
   
   >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" ? 'ITR expansion - taxhero' : 'ITR expansion - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }
    
            <Box 
            sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden',
               
            }}>
                   {/* Header section */}
                   <Box sx={{
                     position:'absolute',
                     left:'26px',
                     top:'37px',
                     right:'61px',
                     display:'flex',
                     flexDirection:'column',
                     minHeight:'60px'
                   }}>
                    <Text
                    className={customTheme.H5}
                    sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                      
                    {allDetails[0]?.pan_name}  
                    </Text>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'row',
                        gap:'13px',
                        marginTop:'4px',
                        minHeight:'36px',
                        maxHeight:'36px',
                        height:'36px',
                        alignItems:'center'
                    }}>
                        <Text
                        className={customTheme.H3}
                            sx={{
                               color: customThemVariable.primaryTextColor
                            }}
                        >
                            
                            {allDetails[0]?.pan_number}
                        </Text>
                        <Text
                        className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {allDetails[0]?.pan_password}
                        </Text>

                       
                        <Label sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: customThemVariable.labelTextColorsecondary,
                            borderRadius: '4px',
                            minHeight: '18px',
                            maxHeight: '18px',
                            height: '18px',
                            background: customThemVariable.labelBgSecondary,
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <Text sx={{ display: 'flex' }}>
                                Verified
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    color: customThemVariable.labelTertiaryTextColor, 
                                    marginLeft: '4px'
                                }}>
                                <CheckIcon size={12} />
                            </Box>



                        </Label>
                        
                     </Box>

                      
                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            navigate('/ItReturnsanbox')
                        }}
                    >
                        Return


                    </Button>
                      


                  </Box>

                  {/*  horizontal line */}

                <Box sx={{
                      position:'absolute',
                      left:'26px',
                      right:'61px',
                      top:'109px',
                      height:'1px',
                      background:customThemVariable.borderColor
                }}>

                </Box>


                  {/* -------------------- */}
 

                 {/* ITR Details Section 1 */}
                 <Box sx={{
                       position:'absolute',
                       left:'26px',
                       top:'120px',
                       right:'61px',
                       display:'flex',
                       flexDirection:'row',
                       alignItems:'center',
                       gap:'24px',
                 }}>

                      <Box  sx={{
                          display:'flex',
                          flexDirection:'column',
                          minWidth:'144px',
                          maxWidth:'144px',
                          width:'144px'
                       }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{ 
                               color:customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement no
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {/* Need to fetch from api */}
                        </Text>
                        </Box>
                        
                    <Box sx={{
                        height: '32px',
                        minHeight:'32px',
                        maxHeight:'32px',
                        width: '1px',
                        minWidth:'1px',
                        maxWidth:'1px',
                        background:customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                        maxWidth:'144px',
                        width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Assessment year
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            AY
                            {" " +itrRurnSingleData.year.split("-")[0]?.substring(2, 4)}-{itrRurnSingleData.year.split("-")[1]?.substring(2, 4)}

                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight:'32px',
                        maxHeight:'32px',
                        width: '1px',
                        minWidth:'1px',
                        maxWidth:'1px',
                        background:customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'158px',
                        maxWidth:'158px',
                        width:'158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight:'20px'
                            }}
                        >
                            {
                                new Date(itrRurnSingleData.data['ITR']['ITR1']['CreationInfo']['JSONCreationDate']).getDate().toString() + " " +
                                new Date(itrRurnSingleData.data['ITR']['ITR1']['CreationInfo']['JSONCreationDate']).toLocaleString('en-US', { month: 'short' }) + " " +
                                new Date(itrRurnSingleData.data['ITR']['ITR1']['CreationInfo']['JSONCreationDate']).getFullYear().toString()
                            }  
                       </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                        maxWidth:'144px',
                        width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Form type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {itrRurnSingleData.data['ITR']['ITR1']['Form_ITR1']['FormName']}

                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                        maxWidth:'144px',
                        width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight:'20px'
                            }}
                        >
                            Filing type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight:'20px'
                            }}
                        >
                            {/* Need to fetch from api */}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                        maxWidth:'144px',
                        width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Filing section
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {/* Need to fetch from api */}
                        </Text>
                    </Box>




                 </Box>
                 {/* ---------------------- */}


                {/* ITR Details Section 1 */}

                <Box sx={{
                       position:'absolute',
                       left:'26px',
                       top:'182px',
                       right:'61px',
                       display:'flex',
                       flexDirection:'row',
                       alignItems:'center',
                       gap:'24px',
                 }}>

                      <Box  sx={{
                          display:'flex',
                          flexDirection:'column',
                          minWidth:'144px',
                          maxWidth:'144px',
                          width:'144px'
                       }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{ 
                               color:customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund claimed
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {itrRurnSingleData.data['ITR']['ITR1']['Refund']['RefundDue']}

                        </Text>
                        </Box>
                        
                    <Box sx={{
                        height: '32px',
                        minHeight:'32px',
                        maxHeight:'32px',
                        width: '1px',
                        minWidth:'1px',
                        maxWidth:'1px',
                        background:customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                        maxWidth:'144px',
                        width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Computed refunds
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight:'20px'
                            }}
                        >
                            {/*  need to fetch from api */}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight:'32px',
                        maxHeight:'32px',
                        width: '1px',
                        minWidth:'1px',
                        maxWidth:'1px',
                        background:customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'158px',
                        maxWidth:'158px',
                        width:'158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Paid refund
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight:'20px'
                            }}
                        >
                            {/* need to fetch from api */}
                       </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:'144px',
                          maxWidth:'144px',
                          width:'144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund  date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            
                            {/* Need to fetch from api */}

                        </Text>
                    </Box>
                     

                    
                 </Box>

                 {/* ----------------------- */}

                 {/* Timeline section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '276px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Timeline
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box> 

                    <Box sx={{
                         display:'flex',
                         flexDirection:'column',
                         width: '964px',
                         minWidth: '964px',
                         maxWidth: '964px',
                    }}>

                          {timeLineArray?.map((row,index)=>{
                               return(
                                    <Box sx={{
                                        display:'flex',
                                        flexDirection:'row',
                                        minHeight:'60px',
                                        height:'60px',
                                        maxHeight:'60px',
                                        borderBottom:`1px solid ${customThemVariable.borderColor}`   
                                    }}>

                                       <Box sx={{
                                           marginTop:'11px' 
                                            
                                       }}>
                                        <StyledOcticon size={12} color="#1EA672" icon={CheckCircleFillIcon} />
                                       </Box>  

                                       <Box sx={{
                                          marginTop:'11px',
                                          marginLeft:'16px',
                                          display:'flex',
                                          flexDirection:'column'
                                       }}> 
                                           <Text
                                             className={customTheme.textnormal}
                                             sx={{
                                                 color:customThemVariable.primaryTextColor
                                             }}
                                           >{row.status}</Text>
                                            <Text
                                             className={customTheme.textnormal}
                                             sx={{
                                                 fontSize:'12px',
                                                 color:customThemVariable.secondaryTextColor
                                             }}
                                           >{new Date(row.date).toDateString()}</Text>
                                       </Box>
                                     
                                    </Box>
                               )
                          })}
            
                    </Box>
                    {/* ITR Files Section -------------- */}
                    <Text
                        className={customTheme.H3}
                        sx={{ 
                            color: customThemVariable.primaryTextColor,
                            marginTop:'35px'
                        }}
                    >
                        Files
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box> 

                    <Box sx={{
                        marginTop:'13px',
                        display:'flex',
                        flexDirection:'column',
                        gap:'15px'  
                    }}>
                       
                        <Box  sx={{display:'flex',flexDirection:'row',gap:'10px',minHeight:'20px',maxHeight:'20px',height:'20px',alignItems:'center'}}>

                            <Text 
                            className={customTheme.textnormal} 
                            sx={{color:customThemVariable.primaryTextColor}}
                            >
                             PDF Password
                            </Text>

                            <Text 
                            className={customTheme.textnormal} 
                            sx={{color:customThemVariable.primaryTextColor}}
                            ref={passwordRef}
                            >
                            4M6QOpkoYyQWcRFjGI
                            </Text>

                            <IconButton
                            sx={{
                                border:'none',
                                color:'#000000',
                                marginLeft:'-8px'
                            }}
                            icon={()=> <CopyIcon size={15} /> }
                            
                            onClick={()=>{
                                handledCopyPassword()
                            }}
                                
                            >
                               
                            </IconButton>

                          

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px',minHeight:'20px',maxHeight:'20px',height:'20px',alignItems:'center' }}>

                            <Text 
                            className={customTheme.textnormal} 
                            sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                 01 Jan 2023
                            </Text>

                            <Text 
                            className={customTheme.textnormal} 
                            sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                CPC/2223/A3/322053893
                            </Text>
                           
                            <IconButton
                            sx={{
                                border:'none',
                                color:'#000000',
                                display:'flex',
                                alignItems:'center',
                                marginLeft:'-8px'
                            }}
                            icon={()=> <Box sx={{display:'flex'}}><ExternalLinkIcon  height={17} width={17} /></Box> }
                            >

                            </IconButton>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px',minHeight:'20px',maxHeight:'20px',height:'20px',alignItems:'center'  }}>

                            <Text 
                            className={customTheme.textnormal} 
                            sx={{ color: customThemVariable.primaryTextColor,minWidth:'98px' }}>
                                ITR Form
                            </Text>
                            <IconButton
                            sx={{
                                border:'none',
                                color:'#000000',
                                display:'flex',
                                alignItems:'center',
                                marginLeft:'-8px'
                            }}
                            icon={()=> <Box sx={{display:'flex'}}><ExternalLinkIcon  height={17} width={17} /></Box> }
                            >

                            </IconButton>

                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                minHeight: '20px',
                                maxHeight: '20px',
                                height: '20px',
                                alignItems: 'center'
                            }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                ITR Receipt
                            </Text>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17} /></Box>}
                            >

                            </IconButton>

                        </Box>


                    </Box>

                    {/* ----------------- -------------- */}

                    <Box
                     sx={{
                        minHeight:'100px'
                     }}
                    >

                    </Box>




                </Box>   


                 {/* --------------------- */}


                 

                 


            </Box>    
    
   

       </Box>


    )


}


export default ItrExpansionSanbox