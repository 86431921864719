import React from "react";
import { Box, Text, Button, Heading, Avatar, Label, IconButton, StyledOcticon, Checkbox, ActionMenu, ActionList, Portal, FormControl, Dialog, Select } from "@primer/react"
 
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import { useDispatch, useSelector } from "react-redux";
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { EyeIcon, FeedPersonIcon,TasklistIcon } from "@primer/octicons-react";
import { getProjectServices, getSingleWorkplaceProject, getSpaceCollaborators, updateProjectCompliaceTrigger, updateSingleProjectCompliaceTrigger } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position,ProgressBar } from "@blueprintjs/core";

export default function Action(props) {

    const currentDate = new Date()
    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const [compliances,setcompliances] = React.useState([])
    const [creatorData,setcreatorData] = React.useState([])
    const [showEmptyComp, setshowEmptyComp] = React.useState(false)
    const [showAllComponent,setshowAllComponent] = React.useState(false)
    const [isEnableCustomCheckBox,setisEnableCustomCheckBox] = React.useState(false)
    const [showManualTriggerDialog,setshowManualTriggerDialog] = React.useState(false)
    const [showAutomaticTriggerDialog,setshowAutomaticTriggerDialog] = React.useState(false)
    const [enableAllCustomCheckbox,setenableAllCustomCheckbox] = React.useState(false)
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [selectedCollab,setselectedCollab] = React.useState("")
    
    const [selectedSingleTrigger,setselectedSingleTrigger] = React.useState([])
    const [selectedTriggerType,setselectedTriggerType] = React.useState("")
    const [seltriggerType,setseltriggerType] = React.useState("")

    const handleChangeTriggerType = (props) => {
        setselectedTriggerType(props)
        if (props === "manual") {
            // setshowManualTriggerDialog(true)
            setenableAllCustomCheckbox(true)
        } else {
            setshowManualTriggerDialog(false)
            setshowAutomaticTriggerDialog(true)
            //  updateProjectCompliaceTriggerInDb(props)

        }

    }


    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedArray = data?.payload.recComp?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)
            console.log("sortedArray", sortedArray)
            if (sortedArray.length <= 0) {
                setshowEmptyComp(true)
            } else {

                setshowEmptyComp(false)
                

            }



        })

    }

    const getSpaceCollaboratorFromDb = (props) => {

         
        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const collabArray = [...data.payload.collaboratorArray]
            setspaceCollaborators(collabArray)
           
        })

    }

    const getCreatorDataFromDb = async (props) => {

        var myHeaders = new Headers();
        myHeaders.append('Content-type', 'application/json')

        const urlencoded = JSON.stringify({
            "userId": props,
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const result = await fetch("https://api.deski.co/getUserProfile", requestOptions)
            .then(response => response.json())
        setcreatorData(result?.data)
    }

    const updateProjectCompliaceTriggerInDb = (props) => {

        dispatch(updateProjectCompliaceTrigger({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            complianceTriggerType: props
        })).then((data) => {

            const message = data?.payload?.message
            const resCompArray = [...data?.payload?.resCompArray ]
            console.log("resCompArray",resCompArray)
            if (resCompArray.length > 0) {
                Toaster.create({ position: Position.TOP }).show({
                    message: "Scheduled all tasks automatically",//message,
                    intent: Intent.PRIMARY
                });
                getAllCategories("")
               
            } else {

            }
        })

    }

    const updateComplanceTriggerType = (props,triggerType) => {

           setseltriggerType(triggerType)
           setshowManualTriggerDialog(true)
           setselectedSingleTrigger([props])
    }

    const updateSingleComplanceTriggerTypeInDb = (props) => {

           setshowManualTriggerDialog(false) 
           setselectedSingleTrigger([])

           dispatch(updateSingleProjectCompliaceTrigger({
               userId:userId,
               userEmail:userEmail,
               projectId:localStorage.getItem("projectId"),
               complianceTriggerType:seltriggerType,
               complianceId:props[0]?.service_id,
               selectedCollab:selectedCollab
           })).then((data)=>{

               console.log("updateSingleProjectCompliaceTrigger",data.payload)
               setcompliances((prevState) => {
                   const newState = prevState.map((row, index) => {
                       if (row.service_id === props[0]?.service_id) {
                           return ({ ...row, trigger_type:seltriggerType,manual_invity_id:selectedCollab })
                       } else {
                           return ({ ...row })
                       }
                   })
                   return newState
               })
               setseltriggerType("")
               Toaster.create({ position: Position.TOP }).show({
                message: "Tasks action updated",//message,
                intent: Intent.PRIMARY
               });

           })



        
    }

    const EmptyActionComp = (props) => {

        return (
            <Box sx={{
                width: '434px',
                height: '184px',
                minWidth: '434px',
                minHeight: '184px',
                maxWidth: '434px',
                maxHeight: '184px',
                borderRadius: '6px',
                border: '1px solid #D0D7DE',
                background: '#FFFFFF',
                position: 'absolute',
                top: '130px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>

                <Box sx={{
                    width: '16px',
                    height: '16px',
                    minWidth: '16px',
                    minHeight: '16px',
                    maxWidth: '16px',
                    maxHeight: '16px',
                    position: 'relative',
                    margin: '40px 0px 0px 0px'
                }}>

                    <TasklistIcon />

                </Box>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '17px',
                    lineHeight: '36px',
                    textAlign: 'center',
                    color: '#24292F',
                    position: 'relative',
                    margin: '16px 0px 0px 0px'
                }}>
                    Actions not activated yet!
                </Text>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textAlign: 'center',
                    color: '#57606A',
                    position: 'relative',
                    margin: '4px 32px 0px 32px'
                }}>
                    You need to set up your service automation first, then actions will be activated.
                </Text>





            </Box>
        )

    }

    const AutomaticTriggerDialog = (props) => {

         return(
             <Dialog
                 sx={{
                     height: '195px',
                     minHeight: '195px',
                     maxHeight: '195px',
                     width: '564px',
                     minWidth: '564px',
                     maxWidth: '564px',
                 }}
                 isOpen={showAutomaticTriggerDialog}
             >
                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    background:'white',
                    borderRadius:'6px',
                }}>
                     {/*Header */}
                    <Box sx={{
                         position:'absolute',
                         top:'0px',
                         left:'0px',
                         right:'0px',
                         display:'flex',
                         flexDirection:'row',
                         height:'52px',
                         minHeight:'52px',
                         maxHeight:'52px',
                         background:'#F6F8FA'
                    }}>
                        <Text
                            sx={{
                                position: 'relative',
                                margin: '16px 0px 0px 16px',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#24292E'
                            }}
                        >Update All Tasks Automatically?</Text>
                    </Box>

                     {/* ----- */}

                     {/* Body part */}
                      <Box sx={{
                         position:'absolute',
                         left:'0px',
                         right:'0px',
                         top:'52px',
                         bottom:'52px',
                         display:'flex',
                         flexDirection:'column'
                      }}>
                         <Text
                             sx={{
                                 fontFamily: 'Inter',
                                 fontSize: '12px',
                                 fontStyle: 'normal',
                                 fontWeight: '500',
                                 lineHeight: '20px',
                                 color: '#24292E',
                                 position: 'relative',
                                 margin: '33px 20px 0px 20px'
                             }}
                         >
                             By selecting 'OK,' you agree to update all tasks automatically. This will streamline
                             your workflow and enhance efficiency.
                         </Text>

                        

                      </Box>
     

                     {/* --------- */}

                      {/* Footer */}
                      <Box sx={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        height: '52px',
                        minHeight: '52px',
                        maxHeight: '52px',
                        borderTop:'1px solid #D0D7DE',
                        justifyContent:'flex-end'
                    }}>

                        <Button variant="invisible" sx={{
                            position: 'relative',
                            margin: 'auto 8px 10px 0px',
                            minWidth: '73px',
                            maxWidth: '73px',
                            width: '73px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',
                            border: 'none',
                            borderRadius: '3px'
                        }}
                            onClick={() => {
                                setshowAutomaticTriggerDialog(false)
                            }}
                        >
                            Cancel
                        </Button>
                         <Button
                             variant="outline"
                             sx={{
                                 position: 'relative',
                                 margin: 'auto 15px 10px 0px',
                                 minWidth: '73px',
                                 maxWidth: '73px',
                                 width: '73px',
                                 minHeight: '32px',
                                 maxHeight: '32px',
                                 height: '32px',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                                 fontFamily: 'Inter',
                                 fontStyle: 'normal',
                                 fontSize: '14px',
                                 lineHeight: '20px',
                                 fontWeight: '600',
                                 color: '#FFFFFF',
                                 background: '#0965CE',
                                 border: 'none',
                                 borderRadius: '3px'
                             }}
                             onClick={() => {
                                 updateProjectCompliaceTriggerInDb("automatic")
                                 setshowAutomaticTriggerDialog(false)
                             }}
                         >
                             Proceed
                         </Button>
                        
                    </Box>
                    {/* ------ */}

                </Box>


             </Dialog>
         )
    }

    const CustomTriggerDialog = (props) => {

        return(
            <Dialog
                sx={{ 
                    height: '306px', 
                    minHeight: '306px', 
                    maxHeight: '306px', 
                    width: '564px', 
                    minWidth: '564px', 
                    maxWidth: '564px',
                }}
                isOpen={showManualTriggerDialog}
            >
                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    background:'white',
                    borderRadius:'6px',
                }}>
                     {/*Header */}
                    <Box sx={{
                         position:'absolute',
                         top:'0px',
                         left:'0px',
                         right:'0px',
                         display:'flex',
                         flexDirection:'row',
                         height:'52px',
                         minHeight:'52px',
                         maxHeight:'52px',
                         background:'#F6F8FA'
                    }}>
                        <Text
                            sx={{
                                position: 'relative',
                                margin: '16px 0px 0px 16px',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '20px',
                                color: '#24292E'
                            }}
                        >Manual Task Invitation Management</Text>
                        
                    </Box>

                     {/* ----- */}

                     {/* Body part */}
                      <Box sx={{
                         position:'absolute',
                         left:'0px',
                         right:'0px',
                         top:'52px',
                         bottom:'52px',
                         display:'flex',
                         flexDirection:'column'
                      }}>
                        <Text
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                            color: '#24292E',
                            position:'relative',
                            margin:'33px 20px 0px 20px'
                        }}
                        >
                            Manual Task Invitation Management . You've received a task invitation. To proceed, please
                            accept the alert notification. Once accepted, the task will commence.
                        </Text>

                        <Box as="form" sx={{
                              position:'relative',
                              margin:'22px 20px 0px 20px',
                             
                        }}>
                            <FormControl>
                                <Select 
                                block
                                style={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                        color: '#24292E',
                                }}
                                placeholder="Select administrator"
                                onChange={(e)=>{
                                    setselectedCollab(e.target.value)
                                }}
                                >

                                    {
                                        spaceCollaborators.map((coll, index) => {
                                            return (
                                                <Select.Option value={coll.user_id}
                                                 style={{fontSize:'14px',fontFamily:'Inter'}}
                                                >{coll.user_first_name+" "+coll.user_last_name} - ({coll.user_email})</Select.Option>
                                            )
                                        })
                                    } 
                                    
                                </Select>
                            </FormControl>
                        </Box>

                      </Box>
     

                     {/* --------- */}

                      {/* Footer */}
                      <Box sx={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        height: '52px',
                        minHeight: '52px',
                        maxHeight: '52px',
                        borderTop:'1px solid #D0D7DE',
                        justifyContent:'flex-end'
                    }}>

                        <Button variant="invisible" sx={{
                            position: 'relative',
                            margin: 'auto 8px 10px 0px',
                            minWidth: '73px',
                            maxWidth: '73px',
                            width: '73px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',
                            border: 'none',
                            borderRadius: '3px'
                        }}
                            onClick={() => {
                                setselectedCollab("")
                                setshowManualTriggerDialog(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            sx={{
                                position: 'relative',
                                margin: 'auto 15px 10px 0px',
                                minWidth: '73px',
                                maxWidth: '73px',
                                width: '73px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                height: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                lineHeight: '20px',
                                fontWeight: '600',
                                color: '#FFFFFF',
                                background: '#0965CE',
                                border: 'none',
                                borderRadius: '3px'
                            }}
                            onClick={() => {
                                updateSingleComplanceTriggerTypeInDb(selectedSingleTrigger)
                            }}
                        >
                            Proceed
                        </Button>
                        
                    </Box>
                      {/* ------ */}

                </Box>


            </Dialog>
        )

    }

    React.useState(()=>{

        getAllCategories("")
        getSpaceCollaboratorFromDb("")
        const projectId = localStorage.getItem("projectId")
         
        dispatch(getSingleWorkplaceProject({
            userId: userId,
            userEmail: userEmail,
            projectId: projectId,
        })).then((data) => {

            
            const creatorId = data?.payload?.data[0]?.creator_id
            getCreatorDataFromDb(creatorId)
            if(creatorId===userId){
                setshowAllComponent(true)
            }else{
                setshowAllComponent(false)
            }

        })


    })
    
    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet><title>{localStorage.getItem("projectName") || ""} - Action</title></Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowX={"hidden"}
            >


                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '27px',
                        height: '30px',
                        minHeight: '30px',
                        maxHeight: '30px',
                        fontFamily: 'Inter',
                        display: 'flex',
                        flexDirection: 'row',

                    }}

                >

                    <Text sx={{
                        fontFamily: 'Inter',
                        color: '#24292E',
                        fontSize: '16px',
                        fontWeight: '600',
                        fontStyle: 'normal',
                        lineHeight: '24px',
                    }}>
                        Actions
                    </Text>


                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '87px',
                        height: '40px',
                        minHeight: '40px',
                        maxHeight: '40px',
                        minWidth: '964px',
                        fontFamily: 'Inter',
                        display: showAllComponent?'flex':'none',
                        flexDirection: 'row',
                        alignItems:'flex-start',
                        borderBottom:'1px solid #EBEEF1'
                    }}
                >
                    <Box sx={{ minWidth: '474px', maxWidth: '474px', width: '474px' }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            color: '#1A1F36',
                            fontSize: '12px',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            lineHeight: '18px',

                        }}>
                            Compliance
                        </Text>
                    </Box>
                    <Box sx={{ minWidth: '215px', maxWidth: '215px', width: '215px', }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            color: '#1A1F36',
                            fontSize: '12px',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            lineHeight: '18px',

                        }}>
                            Administered
                        </Text>
                    </Box>
                    <Box sx={{ minWidth: '254px', maxWidth: '254px', width: '254px', display: 'flex', flexDirection: 'row', gap: '11px' }}>
                        <Text sx={{
                            fontFamily: 'Inter',
                            color: '#1A1F36',
                            fontSize: '12px',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            lineHeight: '18px',

                        }}>
                            Action
                        </Text>
                        <Box as="form" sx={{fontFamily:'Inter'}}>
                            
                            <FormControl>
                                <Checkbox value="default" onChange={(e)=> handleChangeTriggerType("automatic")} checked={selectedTriggerType==="automatic"?true:false} />
                                <FormControl.Label sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' }}>Automatic</FormControl.Label>
                            </FormControl>
                        </Box>
                        <Box as="form" sx={{fontFamily:'Inter',paddingLeft:'2px'}} onClick={()=>{ setisEnableCustomCheckBox(true) }}>
                            
                            <FormControl disabled={!isEnableCustomCheckBox} >
                                <Checkbox value="default" onChange={(e)=> handleChangeTriggerType("manual")}  checked={selectedTriggerType==="manual"?true:false} />
                                <FormControl.Label sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' }}>Custom</FormControl.Label>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>


                {/*  Dynamic Data here */}

                  
                 

                


                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '129px',
                    display: showAllComponent?'flex':'none',
                    flexDirection: 'column',
                    height: 'auto',
                    minWidth: '964px',
                 }}>

                    {compliances.map((row, index) => {


                        return (
                            <Box
                                sx={{
                                    height: '61px',
                                    minHeight: '61px',
                                    maxHeight: '61px',
                                    minWidth: '964px',
                                    fontFamily: 'Inter',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderBottom: '1px solid #EBEEF1'
                                }}
                            >
                                <Box sx={{ minWidth: '474px', maxWidth: '474px', width: '474px', height: '100%', display: 'flex', flexDirection: 'column' }}>

                                    <Box sx={{
                                        position: 'absolute',
                                        marginTop:'16px',
                                      //  left: '0px',
                                      //  top: '16px',
                                        height: '12px',
                                        width: '12px',
                                        minHeight: '12px',
                                        maxHeight: '12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <EyeIcon size={12} />
                                    </Box>

                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        color: '#24292E',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        lineHeight: '20px',
                                        marginLeft: '28px',
                                        marginTop: '12px'
                                    }}>
                                        {row?.subcategory?.compliance_name}
                                    </Text>
                                    <Text sx={{
                                        fontFamily: 'Inter',
                                        color: '#24292E',
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        fontStyle: 'normal',
                                        lineHeight: '20px',
                                        marginLeft: '28px'
                                    }}>
                                        Task recurrence every 
                                        {row?.subcategory?.filingPeriod==="7ab0df55-5223-4227-af70-8e57a26a3316"?
                                         " Monthly " +" on "+new Date(row?.subcategory?.due_date).toDateString().substring(8,11)
                                         :
                                         row?.subcategory?.filingPeriod==="b8a16398-e851-4408-ab1c-7de7614909ea"?
                                         " Quarterly"+" on "+new Date(row?.subcategory?.due_date).toDateString().substring(8,11)
                                         :
                                         row?.subcategory?.filingPeriod==="fd67591d-e05d-46cd-8004-3fff50a08c16"?
                                         " Half-yearly"+" on "+new Date(row?.subcategory?.due_date).toDateString().substring(8,11)
                                         :
                                         row?.subcategory?.filingPeriod==="7a18ee19-3eee-4ce0-9f26-603201c75594"?
                                         " Financial year"+" on "+new Date(row?.subcategory?.due_date).toDateString().substring(8,11)
                                         :
                                         ""
                                        }
                                    </Text>
                                </Box>
                                <Box sx={{ minWidth: '215px', maxWidth: '215px', width: '215px', display: 'flex', flexDirection: 'row' }}>

                                     {row.manual_invity_id===""|| row.manual_invity_id==null ?
                                     
                                        <>
                                            {creatorData.length > 0 && creatorData[0]?.user_image_url !== null ?
                                                <Avatar
                                                    src={creatorData[0]?.user_image_url}
                                                />

                                                :

                                                <FeedPersonIcon
                                                    size={20}
                                                    fill="#586069"

                                                />
                                            }
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                color: '#1A1F36',
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                fontStyle: 'normal',
                                                lineHeight: '18px',
                                                marginLeft: '13px'
                                            }}>
                                                {creatorData.length > 0 ? creatorData[0].user_first_name + " " + creatorData[0].user_last_name : ""}
                                            </Text>
                                        </>
                                       :
                                      
                                          <>
                                            {spaceCollaborators.length > 0 && spaceCollaborators.find((sData)=> sData.user_id===row.manual_invity_id)?.user_image_url !== null ?
                                                <Avatar
                                                    src={spaceCollaborators.find((sData)=> sData.user_id===row.manual_invity_id)?.user_image_url}
                                                />

                                                :

                                                <FeedPersonIcon
                                                    size={20}
                                                    fill="#586069"

                                                />
                                            }
                                            <Text sx={{
                                                fontFamily: 'Inter',
                                                color: '#1A1F36',
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                fontStyle: 'normal',
                                                lineHeight: '18px',
                                                marginLeft: '13px'
                                            }}>
                                                {spaceCollaborators.length > 0 ? spaceCollaborators.find((sData)=> sData.user_id===row.manual_invity_id)?.user_first_name + " " + spaceCollaborators.find((sData)=> sData.user_id===row.manual_invity_id)?.user_last_name : ""}
                                            </Text>
                                          </>
                                     
                                     }
                                    

                                    




                                </Box>
                                <Box sx={{ minWidth: '254px', maxWidth: '254px', width: '254px', display: 'flex', flexDirection: 'row', gap: '11px' }}>
                                    <Box as="form" sx={{ fontFamily: 'Inter' }}>

                                        <FormControl>
                                            <Checkbox value="default" checked={row?.trigger_type==="automatic"?true:false}
                                               onChange={(e)=>{
                                                updateComplanceTriggerType(row,"automatic")
                                           }}    
                                            />
                                            <FormControl.Label sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' }}>Automatic</FormControl.Label>
                                        </FormControl>
                                    </Box>
                                    <Box as="form" sx={{ fontFamily: 'Inter' }}>

                                        <FormControl 
                                        disabled={!enableAllCustomCheckbox}
                                        >
                                            <Checkbox 
                                            value="default" 
                                            checked={row?.trigger_type==="manual"?true:false}
                                            onChange={(e)=>{
                                                 updateComplanceTriggerType(row,"manual")
                                            }}    
                                            />
                                            <FormControl.Label sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: '500', lineHeight: '20px' }}>Custom</FormControl.Label>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        )


                    })}

                    



                </Box>

               


                {/* ------------------ */}

                 



            </Box>

            {showEmptyComp === true ? <EmptyActionComp /> : ""}

            <CustomTriggerDialog/>
            <AutomaticTriggerDialog/>
          

        </Box>
    )


}