
export default {

    sideRightNavMain:{
        position:'fixed',
        right:0,
        top:0,
        width:64,
        height:'100%',
        background:'#FFFFFF'
    },
    spaceHeaderNavBox:{
        width: '368px',
        minWidth:'368px',
        height:48,
        minHeight:48,
        position:'absolute',
      //  left:'300px',
        right:'660px', //old it was 600
        margin:'auto',
        top:0,
        bottom:0,
        fontFamily:'Avenir LT Std',
        fontStyle:'normal',
        fontWeight:'400',
        fontSize:'14px',
        color:'#121212'
    },
    spaceBoxUnderlineNav:{
        border:'none',
        position:'absolute',
        width:'367px',
        maxWidth:'367px',
        minWidth:'367px',
        bottom:0,
        paddingLeft:'0px',
        paddingRight:'0px'
    },
    spaceHeaderCreateBtn:{
        position:'absolute',
      //  left:670,
        right:'535px',
        top:'14px',
        bottom:'6px',
        height:'28px',
        width:67,
        margin:'auto',
        fontFamily:'Avenir LT Std',
        fontSize:'14px',
        fontWeight:'400',
        fontStyle:'normal',
        lineHeight:'20px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        background:'#F2F5F7',
        border:'none',
        color:'#5243AA'
    },
    righSidebarBox:{
        width:'32px',
        height:'382px',
        position:'relative',
        margin:'48px auto 0px auto',
        display:'flex',
        flexDirection:'column'
    },
    rightSideBarBtn:{
        height:'32px',
        width:'32px',
        display:'flex',
        alignItem:'center',
        justifyContent:'center',
        border:'none',
        position:'relative',
        margin:'24px auto 0px auto',
        iconImg:{
            height:'24px',
            width:'24px',
            maxHeight:'24px',
            maxWidth:'24px',
        } 
    },
    rightSideBarDivider:{
        width:'32px',
        position:'relative',
        margin:'24px auto 0px auto' 
    },
    rightSideBarPlusBtn:{
        height:'32px',
        width:'32px',
        display:'flex',
        alignItem:'center',
        justifyContent:'center',
        border:'none',
        background:'white',
        position:'relative',
        margin:'24px auto 0px auto',
        plusIcon:{
            height:24,width:24,display:"flex",position:'relative',margin:'auto auto auto auto'
        }
    },
    actionListDropDownBtn:{
        width:'74px',
        height:'24px',
        padding:0,
        display:'flex',
        alignItems:'center',
        color: '#303030',
        fontFamily:'Avenir LT Std',
        fontStyle:'normal',
        fontWeight:'600',
        fontSize:'16px',
        lineHeight:'24px',
        dropDownIcon:{
            display:'flex',
            position:'relative',
            margin:'auto 0px auto -10px',
            color:'#00000'
        }

    },
    filesActionMenuList:{
         
        minWidth:165,
        maxWidth:165,
        position:'relative',
        margin:'0px auto 0px auto'
    },
    filesActionMenuListItem:{

        position:'relative',
        margin:0,
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"14px",
        fontWeight:"400",  
        color:"#24292F",
        lineHeight:"20px"
    },
    
    // folderComponent
    fileFolderBox:{
        width:'234px',
        height:'53px',
        minHeight:'53px',
        minWidth:'234px',
        background:'#ffffff',
        border:'1px solid #E7E7ED',
        borderRadius:'4px',
        display:'flex',
        flexDirection:'row',
        position:'relative',
        margin:'0px 24px 0px 0px',
        iconBox:{
            color:'#6B6C7E',
            height:'20px',
            width:'20px',
            position:'relative',
            margin:'auto 0px auto 16px'
        },
        textField:{
            position:'relative',
            margin:'auto 0px auto 16px',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontWeight:'400',
            fontSize:'14px',
            color:'#303030',
        }
    },

    //------------ space or board or work css
     boardRightSideIconBox:{
        display:'flex',
        flexDirection:'row',
        gap:'22px',
        position:'fixed',
        top:'92px',
        right:'222px',
        zIndex:'1',
        icon:{
            display:'flex',
        },
        allTaskBtn:{
            color:'#24292F',
            height:'24px',
            width:'72px',
            position:'relative',
            margin:'0px 0px 0px 0px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            fontFamily:'Avenir LT Std',
            fontStyle:'normal',
            fontSize:'14px',
            fontWeight:'400',
            lineHeight:'24px',
            
        },
        filterBtn:{
            color:'#24292F',
            height:'24px',
            width:'53px',
            position:'relative',
            margin:'0px 0px 0px 0px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            fontFamily:'Avenir LT Std',
            fontStyle:'normal',
            fontSize:'14px',
            fontWeight:'400',
            lineHeight:'24px'
        },
        overViewBtn:{
            color:'#24292F',
            height:'24px',
            width:'78px',
            position:'relative',
            margin:'0px 0px 0px 0px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            fontFamily:'Avenir LT Std',
            fontStyle:'normal',
            fontSize:'14px',
            fontWeight:'400',
            lineHeight:'24px'
        }
     }, 

     //------ kanban designs --------------
     kanbanMainBox:{
        display:'flex',
        flexDirection:'row',
       // height:'75vh',
       // minHeight:'75vh',
       // maxHeight:'75vh',
        position:'absolute',
        top:'163px',
        bottom:'0px',
        right:'64px',
        left:'103px',
        overflow:'scroll',
        singleBoardBox:{
            display:'flex',
            flexDirection:'column',
            position:'relative',
            margin:'0px 17px 0px 0px',
            width:'264px',
            minWidth:'264px',
            maxWidth:'264px',
            minHeight:'90vh',
            boardHeader:{
                display:'flex',
                flexDirection:'row',
                // position:'relative',
                // margin:'0px 0px 20px 0px',
                //--- new static design -----
                 zIndex:'1', 
                 alignItems:'center',
                 left:'0px',
                 right:'0px',
                 top:'0px',
                
                 paddingBottom:'20px',
                // margin:'auto auto 20px auto',
                 minHeight:'30px',
                 width:'100%',
                 background:'#F6F8FA',
          //---------
                height:'22px',
                minHeight:'22px',
                cursor:'pointer',
                textField:{
                    position:'relative',
                    margin:'0px 0px 0px 0px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'400',
                    fontSize:'15px',
                    fontWeight:'18px',
                    color:'#121212',
                   
                },
                iconsetBox:{
                    display:'flex',
                    flexDirection:'row',
                    position:'relative',
                    margin:'0px 0px 0px auto',
                    icon:{
                        display:'flex'
                    },
                    iconBtn1:{
                        height:'20px',
                        width:'20px',
                        color: '#000000',
                        margin: '0px 0px 0px 0px',
                        position:'relative',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                    },
                    iconBtn2:{
                        height:'20px',
                        width:'20px',
                        // height:'16px',
                        // width:'16px',
                        color: '#000000',
                        position:'relative',
                        margin:'0px 0px 0px 13px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                    }
                }
            }
       }
     },
     taskBox:{

        width:'264px',
        minWidth:'264px',
        height:'auto',
        minHeight:'auto',
        maxHeight:'269px',
        background:'#FFFFFF',
        position:'relative',
        margin:'0px 0px 5px 0px',
        
        border:'1px solid #D0D7DE',
        borderRadius:'6px',
        boxSizing:'border-box',
        taskBoxHeader:{

            width:'234px',
            minWidth:'234px',
            maxWidth:'234px',
            height:'18px',
            minHeight:'18px',
            maxHeight:'18px',
            position:'relative',
            margin:'15px auto 0px auto',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            dateText:{
                fontFamily:'Segoe UI',
                fontWeight:'400',
                fontSize:'12px',
                fontStyle:'normal',
                fontSize:'12px',
                lineHeight:'18px',
                color:'#586069',
                display:'flex',
                position:'relative',
                margin:'0px 0px 0px 5px'
            },
             iconsetBox:{
                display:'flex',
                flexDirection:'row',
                position:'relative',
                margin:'0px 0px 0px auto',
                icon:{
                    display:'flex'
                },
                iconBtn1:{
                    height:'15px',
                    width:'15px',
                    color: '#000000',
                    margin: '0px 0px 0px 0px',
                    position:'relative',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                },
                iconBtn2:{
                    height:'15px',
                    width:'15px',
                    color: '#000000',
                    position:'relative',
                    margin:'0px 0px 0px 12px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }
            }
        },
        taskDescription:{
            display: 'flex',
            position: 'relative',
            margin: '9px 15px 9px 15px',
            minWidth: '234px',
            maxWidth: '234px',
            width: '234px',
            maxHeight: '115px',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '350',
            lineHeight: '19px',
            color: '#121212',
            whiteSpace: "pre-line",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign:'left'  
        },
        taskLabelBox:{
            display:'flex',
            flexDirection:'row',
            flexWrap:'wrap',
            position:'relative',
            margin:'9px 15px 0px 15px',
            color:'red',
            gap:'7px',
            label:{
                height:'24px',
                border:'none',
                background:'#DDF4FF',
                borderRadius:'0px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontSize:'12px',
                fontWeight:'400',
                lineHeight:'16px',
                textOverflow:"ellipsis",
                overflow:'hidden'
            }
        },
        roadMapLabelBox:{
           
            display:'flex',
            flexDirection:'row',
            flexWrap:'wrap',
            position:'relative',
            margin:'9px 15px 0px 15px',
           // color:'red',
            gap:'7px',
            label:{
                height:'22px',
                border:'none',
                background:'#DCF487',
                borderRadius:'0px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontSize:'12px',
                fontWeight:'400',
                lineHeight:'16px',
                textOverflow:"ellipsis",
                overflow:'hidden',
                color:'#121212'
            }

        }
     },

     taskBoxHover:{

        width:'264px',
        minWidth:'264px',
        height:'auto',
        minHeight:'auto',
        maxHeight:'269px',
        background:'#FFFFFF',
        position:'relative',
        margin:'0px 0px 5px 0px',
        filter:'drop-shadow(0px 8px 24px rgba(140, 149, 159, 0.2))',//hover effect
        border:'1px solid #D0D7DE',
        borderRadius:'6px',
        boxSizing:'border-box',
        taskBoxHeader:{

            width:'234px',
            minWidth:'234px',
            maxWidth:'234px',
            height:'18px',
            minHeight:'18px',
            maxHeight:'18px',
            position:'relative',
            margin:'15px auto 0px auto',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            dateText:{
                fontFamily:'Segoe UI',
                fontWeight:'400',
                fontSize:'12px',
                fontStyle:'normal',
                fontSize:'12px',
                lineHeight:'18px',
                color:'#586069',
                display:'flex',
                position:'relative',
                margin:'0px 0px 0px 5px'
            },
             iconsetBox:{
                display:'flex',
                flexDirection:'row',
                position:'relative',
                margin:'0px 0px 0px auto',
                icon:{
                    display:'flex'
                },
                iconBtn1:{
                    height:'15px',
                    width:'15px',
                    color: '#000000',
                    margin: '0px 0px 0px 0px',
                    position:'relative',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                },
                iconBtn2:{
                    height:'15px',
                    width:'15px',
                    color: '#000000',
                    position:'relative',
                    margin:'0px 0px 0px 12px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }
            }
        },
        taskDescription:{
            display: 'flex',
            position: 'relative',
            margin: '9px 15px 9px 15px',
            minWidth: '234px',
            maxWidth: '234px',
            width: '234px',
            maxHeight: '115px',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '350',
            lineHeight: '19px',
            color: '#121212',
            whiteSpace: "pre-line",
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign:'left'  
        },
        taskLabelBox:{
            display:'flex',
            flexDirection:'row',
            flexWrap:'wrap',
            position:'relative',
            margin:'9px 15px 0px 15px',
            color:'red',
            gap:'7px',
            label:{
                height:'24px',
                border:'none',
                background:'#DDF4FF',
                borderRadius:'0px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontSize:'12px',
                fontWeight:'400',
                lineHeight:'16px',
                textOverflow:"ellipsis",
                overflow:'hidden'
            }
        },
        roadMapLabelBox:{
           
            display:'flex',
            flexDirection:'row',
            flexWrap:'wrap',
            position:'relative',
            margin:'9px 15px 0px 15px',
            color:'red',
            gap:'7px',
            label:{
                height:'22px',
                border:'none',
                background:'#DCF487',
                borderRadius:'0px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontSize:'12px',
                fontWeight:'400',
                lineHeight:'16px',
                textOverflow:"ellipsis",
                overflow:'hidden',
               
            }

        }
     },




     profileActionMenuListItemDanger:{
        position:'relative',
        margin:0,
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"14px",
        fontWeight:"400",  
        color:"#CF222E",
        lineHeight:"20px"
    }, 



}