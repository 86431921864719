import React from "react"
import { Box, ActionMenu, ActionList, Avatar,IconButton,Tooltip, Text,Link } from "@primer/react"
import { useNavigate, useLocation } from "react-router-dom"
import { MagnifyingGlassIcon, QuestionMarkCircledIcon, BellIcon, Cross2Icon, FileIcon } from "@radix-ui/react-icons"
import { FeedPersonIcon} from '@primer/octicons-react'
import { useDispatch, useSelector } from "react-redux"
import { gstSearchDialogOpenClose, itSearchDialogOpenClose, searchOpenClose } from "../redux/features/searchSlice"
import { scheduleDemoOpenClose } from "../redux/features/scheduleDemoSlice"
import { Toaster, Intent,Position } from "@blueprintjs/core";
import workplaceTheme from "../Workplace/Workplacetheme" 
import { getUserProfileFromDatabase,clearUserNotification, logoutFromCognitoUser, openCloseSubscriptionPlanDialog } from "../redux/features/profileSlice"
import { getDataByTaskId } from "../redux/features/feedSlice"
import { getnotitificationData } from "../redux/features/headerSlice"
import { taxHeroupgradePlanOpenClose, upgradePlanOpenClose } from "../redux/features/contactSlice"
import axios from 'axios';
import mixpanel from "mixpanel-browser"
import UpgradePortalPlan from "./UpgradePortalPlanSanbox"
import SubscriptionPlans from "./SubscriptionPlansSanbox"
mixpanel.init('42f38a9f4804449b88177fb8bbfec5c6', { debug: true, ip: true });

function TaxHeroHeaderSubComponentSanbox(props) {

    let host = window.location.host
    let hostName = window.location.hostname

    const [notification,setnotification] = React.useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const openCloseSubscriptionPlan = useSelector((state)=> state.profileData.openCloseSubscriptionPlan) 
    const taxheroupgradeisOpen = useSelector((state) => state.contactProfileData.taxheroupgradeisOpen)  
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const userImageUrl = useSelector((state) => state.profileData.userImageUrl ) 

    const [userEmailId, setUserEmailId] = React.useState(userEmail)
    const [userProfileImage,setuserProfileImage] = React.useState(userImageUrl)
    const [profileopen, setprofileopen] = React.useState(false)
    const [questionopen, setquestionopen] = React.useState(false)

    const profileRef = React.createRef()
    const questionRef = React.createRef()
    const schedulereturnFocusRef = React.useRef(null)

    const notificationBtnRef = React.useRef(null)
    const [notificationActionMenu,setnotificationActionMenu] = React.useState(false)
    const notificationOpenOverlay = React.useCallback(() => setnotificationActionMenu(true), [setnotificationActionMenu])
    const notificationCloseOverlay = React.useCallback(() => setnotificationActionMenu(false), [setnotificationActionMenu])

    const showSubscriptionPlans = (props) => {
      
        dispatch(openCloseSubscriptionPlanDialog({ openCloseSubscriptionPlan: true }))

    }

    const showSearchDialog = (props) => {

       // if(lo)
      
       

        if (location.pathname === "/gstpanlist") {

            dispatch(gstSearchDialogOpenClose({
                gstsearchopenClose: true
            }))
        }
        if (location.pathname === "/itpanlist") {

           
            dispatch(itSearchDialogOpenClose({
                itsearchopenClose: true
            }))
        }



     }

    const handleLogout = async (props) => {

        dispatch(logoutFromCognitoUser({
            isLogout: true
        }))

    }

    const trackNotiticationClick =async (props) => { }

  
    const goToAccountPage = (props) => {

        localStorage.setItem("locationPath", location.pathname)

    }
 
    React.useState(() => {

        dispatch(getUserProfileFromDatabase({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            setUserEmailId(data.payload.data[0]?.user_email)
            setuserProfileImage(data.payload.data[0]?.user_image_url)

        })
    }) 


    React.useEffect(()=>{

        setuserProfileImage(userImageUrl)
 
    },[userImageUrl])

    
    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

 
    return (
        <Box sx={workplaceTheme.headerProfileBox}>

            <IconButton
              sx={{
                border:'none',
                background:'#ffffff', 
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderRadius:'3px',
                position:'relative',
                margin:'0px 4px 0px 0px',
                boxShadow:'none',   
             }}
             icon={()=> 
             
                 <Tooltip aria-label="Search" direction="s">
                     <MagnifyingGlassIcon style={{ display: 'flex' }} />
                 </Tooltip>
               }
             onClick={() => showSearchDialog("")}
            />

           

            <IconButton
                ref={questionRef}
                aria-haspopup="true"
                aria-expanded={questionopen}
                sx={{
                    border: 'none',
                    background: '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '3px',
                    position:'relative',
                    margin:'0px 4px 0px 0px',
                    boxShadow:'none', 
                }}
                icon={() => 
                
                <Tooltip aria-label="Help center" direction="s">
                <QuestionMarkCircledIcon style={{ display: 'flex' }} />
                </Tooltip>
                }
                onClick={() => setquestionopen(!questionopen)}
            />



             
            <ActionMenu open={questionopen} onOpenChange={setquestionopen} anchorRef={questionRef}>
                <ActionMenu.Overlay sx={workplaceTheme.questionActionMenuOverlay}>
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                        onSelect={()=>{ }}
                        >Help center</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Message support</ActionList.Item>
                        <ActionList.Item
                            ref={schedulereturnFocusRef}
                            onSelect={event =>

                                dispatch(scheduleDemoOpenClose({
                                    openClose: true,
                                    title:'Schedule demo'
                                })).then((data) => {

                                     
                                })

                            }
                            sx={workplaceTheme.profileActionMenuListItem}>Schedule demo</ActionList.Item>
                        <ActionList.Divider />
                        <ActionList.Item 
                        sx={workplaceTheme.profileActionMenuListItem}
                        onSelect={event =>

                            dispatch(scheduleDemoOpenClose({
                                openClose: true,
                                title:'Contact sales'
                            })).then((data) => {

                                 
                            })

                        }
                        >Contact sales</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                        onSelect={(e)=>
                            
                              showSubscriptionPlans("")
                            //showUpgradePlan("")
                    
                        }
                        >Plans and pricing</ActionList.Item>
                    </ActionList>
                </ActionMenu.Overlay>
            </ActionMenu>


            <ActionMenu open={notificationActionMenu} onOpenChange={setnotificationActionMenu}  anchorRef={notificationBtnRef}>
                <ActionMenu.Anchor 
                
                >
                    <IconButton
                         sx={{
                            border: 'none',
                            background: '#ffffff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '3px',
                            position: 'relative',
                            margin: '0px 24px 0px 0px',
                            boxShadow:'none',
                        }}
                        icon={() => 
                        
                            <Tooltip aria-label="Notification" direction="s">
                                <BellIcon onClick={() => 
                                   {
                                    notificationOpenOverlay()
                                    trackNotiticationClick("")
                                   }
                                  
                                } style={{ display: 'flex' }} />
                            </Tooltip>
                        }
                        onClick={()=>  {
                            notificationOpenOverlay()
                            trackNotiticationClick("")
                           } } 
                    />
                </ActionMenu.Anchor>

                <ActionMenu.Overlay
                  sx={{marginTop:'12px',borderRadius:'0px',width:'516px',minWidth:'516px',maxWidth:'516px',minHeight:'495px',maxHeight:'495px',height:'495px',borderRadius:'3px'}}
                >



                    <ActionList
                        
                        sx={{
                            width:'516px',
                            minWidth:'516px',
                            maxWidth:'516px',
                            minHeight:'495px',
                            maxHeight:'495px',
                            height:'495px',
                            display: 'flex',
                            flexDirection: 'column',
                            fontFamily:'Segoe UI',
                            borderRadius:'0px',
                           
                        }}

                    >

                        <Text
                      
                        sx={{
                            position:'absolute',
                            left:'17px',
                            top:'9px',
                            width:'76px',
                            height:'36px',
                            lineHeight:'36px',
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontWeight:'400'
                        }}>Notification</Text>

                      
                         
                         <IconButton sx={{
                            width:'15px',
                            height:'15px',
                            background:'white',
                            position:'absolute',
                            right:'17px',
                            top:'18px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            border:'none',
                         }} 
                         size="small" 
                         icon={()=> <Box sx={{display:'flex',height:'15px',width:'15px'}}><Cross2Icon scale={9} /></Box> }
                         onClick={()=>{

                             notificationCloseOverlay()

                         }} 
                         />


                        <Box sx={{ position: 'absolute', left: '0px', right: '0px', margin: 'auto', top: '174px', height: '15px', width: '15px',zIndex:1,display:notification.length>0?"none":"flex" }}><FileIcon />
                        </Box>
                        <Box sx={{ position: 'absolute', left: '0px', right: '0px', margin: 'auto', top: '206px', height: '40px', width: '420px',zIndex:1,display:notification.length>0?"none":"flex" }}>

                            <Text sx={{
                                display: 'flex',
                                height: '40px',
                                width: '420px',
                                fontFamily: 'Segoe UI',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                textAlign: 'center',

                            }}>
                                We will show your future @mentions, assignments, and relevant comments here.
                            </Text>
                        </Box>

                         <Box sx={{position:'absolute',left:'17px',right:'0px',top:'62px',bottom:'56px',left:'17px',background:'white',overflowY:'auto',overflowX:'hidden',paddingRight:'8px'}}>
                         </Box>



                        

                        <Link
                         sx={{
                            display:notification.length>0?"flex":"none",
                            position:'absolute',
                            right:'20px',
                            bottom:'17px',
                            width:'fit-content',
                            height:'20px',
                            lineHeight:'20px',
                            fontStyle:'normal',
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontWeight:'400',
                            color:'#0969DA',
                            cursor:'pointer'
                         }}
                         
                         onClick={()=>{
                             notificationCloseOverlay()
                         }}
                         >Clear all</Link>

                         
 
                        
                    </ActionList>



                </ActionMenu.Overlay>
            </ActionMenu>
         


          

 
          
            {userProfileImage === "" || userProfileImage == null ?

                <Box
                    ref={profileRef}
                    aria-haspopup="true"
                    aria-expanded={profileopen}
                    onClick={() => setprofileopen(!profileopen)}
                    sx={workplaceTheme.headerProfileAvatar}
                    
                >
                    <Tooltip aria-label={userEmail} direction="s">
                    <FeedPersonIcon
                        size={32}
                        fill="#586069"

                    />
                    </Tooltip>
                </Box>
               
                :
                <Tooltip aria-label={userEmail} direction="s">
                <Avatar
                    ref={profileRef}
                    aria-haspopup="true"
                    aria-expanded={profileopen}
                    onClick={() => setprofileopen(!profileopen)}
                    sx={workplaceTheme.headerProfileAvatar}
                    size="32"
                    src={userProfileImage}

                />
                </Tooltip>


            }

            

            <ActionMenu open={profileopen} onOpenChange={setprofileopen} anchorRef={profileRef}>
                <ActionMenu.Overlay sx={workplaceTheme.profileActionMenuOverlay}>
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem} >
                            {userEmail?.substring(0, 21) || ""}
                        </ActionList.Item>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onClick={() => {

                                 navigate('/account')
                                 goToAccountPage("")
                            }}
                        >Account</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                        
                        onSelect={(e)=> 
                            
                           // showUpgradePlan("")
                              showSubscriptionPlans("")
                        }
                        >Upgrade</ActionList.Item>
                        <ActionList.Divider />
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={event => dispatch(scheduleDemoOpenClose({
                                openClose: true,
                                title: 'Contact sales'
                            })).then((data) => {


                            })

                        }
                        
                        >Contact sales</ActionList.Item>
                        <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => { handleLogout("") }}
                        >Logout</ActionList.Item>
                    </ActionList>
                </ActionMenu.Overlay>
            </ActionMenu>

            {taxheroupgradeisOpen==true?<UpgradePortalPlan/>:""}
            {openCloseSubscriptionPlan==true?<SubscriptionPlans/>:""}

        </Box>
    )

}

export default TaxHeroHeaderSubComponentSanbox