import { ActionList, ActionMenu, Box, Button, Heading, Text } from '@primer/react';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllWorksByProjectId, getProjectWorkflow } from '../redux/features/spaceSlice';
import { CalendarIcon } from '@radix-ui/react-icons';

const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const period = hours < 12 ? 'AM' : 'PM';

  // Convert to 12-hour format
  const formattedHours = (hours % 12) || 12; // If hours is 0, display 12
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const currentTime = `${formattedHours}:${formattedMinutes} ${period}`;
  return currentTime;
};

export default function GraphInsight() {


  const dispatch = useDispatch()
  const userId = useSelector((state) => state.profileData.userId)
  const userEmail = useSelector((state) => state.profileData.userEmail)

  const [DATA, SETDATA] = React.useState([[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]])

  const [COLORS, SETCOLORS] = React.useState(['#00baf0', 'green', 'red'])
  const [LABELS, SETLABELS] = React.useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'])

  const [selgraphFilter, setselgraphFilter] = React.useState("")
  const [workflow, setworkflow] = React.useState([])
  const [allTasks, setallTasks] = React.useState([])
  const [copyAllTask, setcopyAllTask] = React.useState([])
  const [countStatics, setcountStatics] = React.useState({
    completed: 0,
    processing: 0,
    totalTask: 0,
    avgRespTime: '0 h 0m',
    resolutionPerc: '0 %'
  })

  const Line = ({ path, color }) => {
    const dx = 100 / (path.length - 1);
    const d = `M0,${path[0]} ${path.slice(1).map((p, i) => (
      `C${dx * i + dx / 2},${path[i]} ` +
      `${dx * (i + 1) - dx / 2},${path[i + 1]} ` +
      `${dx * (i + 1)},${path[i + 1]} `
    )).join(' ')}`;
    return (
      <>
        <path
          stroke={color}
          d={d}
          fill='none'
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: ".2px",
            pointerEvents: "none",
          }}
        />
        <path d={d + ` V0 H0 Z`} fill={`url(#gradient-${color})`}
          style={{
            pointerEvents: 'none'
          }}
        />
        <defs>
          <linearGradient id={`gradient-${color}`} x1='0' x2='0' y1='0' y2='1'>
            <stop offset='0%' stopColor={color} stopOpacity={0} />
            <stop offset='100%' stopColor={color} stopOpacity={0.15} />
          </linearGradient>
        </defs>
      </>
    );
  }




  const Graph = ({ data, colors, range, labels }) => {

    const graph = useRef();

    return (

      <Box
        ref={graph}
        sx={{
          position: "relative",
          backgroundColor: "white",
          width: "927px",
          height: "405px",
          borderRadius: "6px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.01), 0 4px 20px rgba(0,0,0,0.01), 0 6px 40px rgba(0,0,0,0.01), 0 8px 60px rgba(0,0,0,0.01)",
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          color: '#24292E',
          position: 'absolute',
          left: '20px',
          top: '20px'
        }}>
          <Heading>Today's trends</Heading>
          <Text>as of 31 Dec {new Date().getFullYear() + ""},
            {/* 09:41 PM */}
            {"" + getCurrentTime()}
          </Text>
        </Box>


        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          color: '#24292E',
          position: 'absolute',
          right: '255px',
          top: '40px',
          minHeight: '30px',
          maxHeight: '30px',
          height: '30px',
          alignItems: 'center',
          gap: '10px',

        }}>
          <Box sx={{ width: '15px', minWidth: '15px', minHeight: '2px', height: '2px', background: colors[0] }} />
          <Text sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>Processing</Text>
          <Box sx={{ width: '15px', minWidth: '15px', minHeight: '2px', height: '2px', background: colors[1] }} />
          <Text sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>Completed</Text>
          <Box sx={{ width: '15px', minWidth: '15px', minHeight: '2px', height: '2px', background: colors[2] }} />
          <Text sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>Due</Text>

          <ActionMenu>
            <ActionMenu.Anchor>
              <Button
                sx={{
                  display: 'flex',
                  minWidth: '95px',
                  maxWidth: '95px',
                  width: '95px',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  fontSize: '14px',
                  padding: '8px',
                  alignItems: 'center',
                  minHeight: '30px',
                  maxHeight: '30px',
                  height: '30px',
                  border: 'none',
                  background: 'transparent'
                }}
                leadingIcon={() =>
                  <Box sx={{ display: 'flex', cursor: 'pointer', gap: '8px' }}>
                    <CalendarIcon />
                  </Box>
                }
              >
                {selgraphFilter || "date"}
              </Button>
            </ActionMenu.Anchor>
            <ActionMenu.Overlay>
              <ActionList
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '6px',
                  fontFamily: 'Segoe UI',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#24292F'
                }}
              >

                {/* <ActionList.Item
                  onSelect={() => {
                    changeFilterGraphData("Year")
                  }}
                >
                  {"All"}
                </ActionList.Item> */}

                {
                  //  ['7 days', '14 days', '30 days', '60 days'] //removed all and 60 days
                  ['7 days', '14 days', '30 days']
                    .map((row, index) => {
                      return (
                        <ActionList.Item
                          onSelect={() => {
                            changeFilterGraphData(row)
                          }}
                        >
                          {row}
                        </ActionList.Item>
                      )
                    })
                }
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>



        </Box>


        <Box sx={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          bottom: '0px',
          width: '250px',
          display: 'flex',
          flexDirection: 'column',

        }}>

          <Box
            sx={{
              minHeight: '80px',
              height: '80px',
              maxHeight: '80px',
              borderBottom: '1px solid #D0D7DE',
              gap: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: '#24292E',
            }}>
            <Text>Completed</Text>
            <Heading sx={{ fontWeight: '600', fontSize: '25px' }}>{countStatics.completed}</Heading>
          </Box>

          <Box
            sx={{
              minHeight: '80px',
              height: '80px',
              maxHeight: '80px',
              borderBottom: '1px solid #D0D7DE',
              gap: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: '#24292E',
            }}>
            <Text>Processing</Text>
            <Heading sx={{ fontWeight: '600', fontSize: '25px' }}>{countStatics.processing}</Heading>
          </Box>
          <Box
            sx={{
              minHeight: '80px',
              height: '80px',
              maxHeight: '80px',
              borderBottom: '1px solid #D0D7DE',
              gap: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: '#24292E',
            }}>
            <Text>Total task</Text>
            <Heading sx={{ fontWeight: '600', fontSize: '25px' }}>
              {countStatics.totalTask}

            </Heading>
          </Box>
          <Box
            sx={{
              minHeight: '80px',
              height: '80px',
              maxHeight: '80px',
              borderBottom: '1px solid #D0D7DE',
              gap: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: '#24292E',
            }}>
            <Text>Average task completion</Text>
            <Heading sx={{ fontWeight: '600', fontSize: '25px' }}>{countStatics.avgRespTime}</Heading>
          </Box>
          <Box
            sx={{
              minHeight: '80px',
              height: '80px',
              maxHeight: '80px',
              borderBottom: '1px solid #D0D7DE',
              gap: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              color: '#24292E',
            }}>
            <Text>Tasks accuracy</Text>
            <Heading sx={{ fontWeight: '600', fontSize: '25px' }}>{countStatics.resolutionPerc}</Heading>
          </Box>

        </Box>

        <Box sx={{
          position: 'absolute',
          top: '110px',
          left: '0px',
          right: '251px',
          bottom: '0px',
          overflowX: 'auto',
        }}>

          <>
            <svg
              style={{
                width: "100%",
                height: "90%",
                transform: "scaleY(-1)",
                "&:path": {
                  vectorEffect: "non-scaling-stroke"
                },
                "&:stroke": {
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "0.14rem",
                  pointerEvents: "none"
                },
                "&:gradient": {
                  pointerEvents: "none",

                }

              }}
              viewBox={`0 ${range[0]} 100 ${range[1]}`}
              preserveAspectRatio='none'>
              {data.map((path, i) => (
                <Line key={i} path={path} color={colors[i]} />
              ))}
            </svg>

            <Box
              sx={{
                display: "flex",
                width: "100%",
                position: "absolute",
                justifyContent: "space-between",
                left: "0",
                bottom: "0",
                boxSizing: "border-box",
                gap: '0px'
              }}
            >
              {labels.map(label => (
                <Box
                  sx={{
                    minWidth: selgraphFilter === '7 days' ? '13%' : selgraphFilter === '14 days' ? '14%' : selgraphFilter === '30 days' ? '16%' : selgraphFilter === '60 days' ? '15%' : '8%',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#24292E',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  key={label}>
                  {label}
                </Box>
              ))}
            </Box>
          </>
        </Box>

      </Box>
    );
  };


  const getProjectWorkFlowFromDb = (props) => {

    dispatch(getProjectWorkflow({
      userId: userId,
      userEmail: userEmail,
      projectId: localStorage.getItem("projectId")
    })).then((data) => {

      setworkflow(data?.payload?.navArray)
      getAllTaskFromDb(data?.payload?.navArray)

    })

  }


  const getAllTaskFromDb = (props) => {

    dispatch(getAllWorksByProjectId({
      userId,
      userEmail,
      projectId: localStorage.getItem("projectId"),
    })).then((data) => {
      const tasks = data?.payload?.data || [];
      const workflow = [...props];
      const newTask = tasks.map((row) => ({
        ...row,
        workflow_name: workflow.find((wData) => wData.workflow_id === row.workflow_id)?.workflow_name || '',
      }));

      console.log("newTask", newTask);
      setallTasks(newTask);
      setcopyAllTask(newTask);

      const processingCount = newTask.filter((tData) => {
        return !["To do", "Completed", "Verified", "Ignore"].includes(tData.workflow_name) && tData.completed_status === "false";
      }).length;

      const completedCount = newTask.filter((tData) => tData.completed_status === "true").length;

      setcountStatics((prevState) => ({
        ...prevState,
        completed: completedCount,
        processing: processingCount,
        totalTask: newTask.length,
      }));

      const result = getLast7Days();
      SETLABELS(result);
      calculateGraphData(newTask, "7 days");
      setselgraphFilter("7 days");
    });


  }


  // const getAllTaskFromDb = (props) => {

  //   dispatch(getAllWorksByProjectId({
  //     userId: userId,
  //     userEmail: userEmail,
  //     projectId: localStorage.getItem("projectId")
  //   })).then((data) => {

  //     const tasks = [...data?.payload?.data]
  //     const workflow = [...props]
  //     const newTask = tasks.map((row, index) => {

  //       return { ...row, workflow_name: workflow.filter((wData) => wData.workflow_id === row.workflow_id)[0].workflow_name }
  //     })
  //     console.log("newTask", newTask)
  //     setallTasks(newTask)
  //     setcopyAllTask(newTask)

  //     const processingCount = newTask.filter((tData) => tData.workflow_name !== "To do" && tData.workflow_name !== "Completed" && tData.workflow_name !== "Verified" && tData.workflow_name !== "Ignore" && tData.completed_status === "false").length
  //     const completedCount = newTask.filter((tData) => tData.completed_status === "true").length

  //     setcountStatics((prevState) => {
  //       const newState =
  //       {
  //         ...prevState,
  //         completed: completedCount,
  //         processing: processingCount,
  //         totalTask: newTask.length
  //       }
  //       return newState
  //     })
  //     // Method to set graph data  
  //    // calculateGraphData(newTask, "7 days")
  //    // setselgraphFilter("7 days")

  //    var result = getLast7Days();
  //    SETLABELS(result)
  //    calculateGraphData(newTask, "7 days")
  //    setselgraphFilter("7 days")

  //   })

  // }

  //------- last 7 days ---------

  function splitTasksBy7days(tasks) {

    const tasksByLast7Days = Array.from({ length: 7 }, () => []);
    const currentDate = new Date();

    tasks.forEach(task => {
      const taskDate = new Date(task.created_timestamp); // Assuming task date is in a valid date format
      const daysAgo = Math.floor((currentDate - taskDate) / (1000 * 60 * 60 * 24));

      if (daysAgo >= 0 && daysAgo < 7) {
        // If the task was created within the last 7 days, add it to the corresponding day's array
        tasksByLast7Days[daysAgo].push(task);
      }
    });

    return tasksByLast7Days;
  }

  function calculateTaskPercentageLast7Days(tasks) {
    // Create an array to store the percentage of tasks for each month
    const taskPercentagesByMonth = [];
    const totalTasks = tasks.flat().length;
    const currentDate = new Date();
    const last7Days = new Date();
    last7Days.setDate(currentDate.getDate() - 7);

    tasks.forEach(monthTasks => {
      // Filter tasks that fall within the last 7 days
      const tasksInLast7Days = monthTasks.filter(task => {
        const taskDate = new Date(task.created_timestamp); // Assuming each task has a 'date' property
        return taskDate >= last7Days && taskDate <= currentDate;
      });

      const monthTaskCount = tasksInLast7Days.length;
      const taskPercentage = (monthTaskCount / totalTasks) * 100;

      if (isNaN(taskPercentage)) {
        taskPercentagesByMonth.push(Math.round(0)); // Use Math.round to round to the nearest integer
      } else {
        taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
      }


    });

    return taskPercentagesByMonth;
  }

  //-------- last 14 days -------------------- 

  function splitTasksBy14days(tasks) {

    const tasksByLast7Days = Array.from({ length: 14 }, () => []);
    const currentDate = new Date();

    tasks.forEach(task => {
      const taskDate = new Date(task.created_timestamp); // Assuming task date is in a valid date format
      const daysAgo = Math.floor((currentDate - taskDate) / (1000 * 60 * 60 * 24));

      if (daysAgo >= 0 && daysAgo < 14) {
        // If the task was created within the last 7 days, add it to the corresponding day's array
        tasksByLast7Days[daysAgo].push(task);
      }
    });

    return tasksByLast7Days;


  }

  function calculateTaskPercentageLast14Days(tasks) {
    // Create an array to store the percentage of tasks for each month
    const taskPercentagesByMonth = [];
    const totalTasks = tasks.flat().length;
    const currentDate = new Date();
    const last7Days = new Date();
    last7Days.setDate(currentDate.getDate() - 14);

    tasks.forEach(monthTasks => {
      // Filter tasks that fall within the last 7 days
      const tasksInLast7Days = monthTasks.filter(task => {
        const taskDate = new Date(task.created_timestamp); // Assuming each task has a 'date' property
        return taskDate >= last7Days && taskDate <= currentDate;
      });

      const monthTaskCount = tasksInLast7Days.length;
      const taskPercentage = (monthTaskCount / totalTasks) * 100;
      if (isNaN(taskPercentage)) {
        taskPercentagesByMonth.push(Math.round(0)); // Use Math.round to round to the nearest integer
      } else {
        taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
      }
      // taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
    });

    return taskPercentagesByMonth;
  }

  //----- 30 days ----------------------------

  function splitTasksBy30days(tasks) {

    const tasksByLast7Days = Array.from({ length: 30 }, () => []);
    const currentDate = new Date();

    tasks.forEach(task => {
      const taskDate = new Date(task.created_timestamp); // Assuming task date is in a valid date format
      const daysAgo = Math.floor((currentDate - taskDate) / (1000 * 60 * 60 * 24));

      if (daysAgo >= 0 && daysAgo < 30) {
        // If the task was created within the last 7 days, add it to the corresponding day's array
        tasksByLast7Days[daysAgo].push(task);
      }
    });

    return tasksByLast7Days;


  }

  function calculateTaskPercentageLast30Days(tasks) {
    // Create an array to store the percentage of tasks for each month
    const taskPercentagesByMonth = [];
    const totalTasks = tasks.flat().length;
    const currentDate = new Date();
    const last7Days = new Date();
    last7Days.setDate(currentDate.getDate() - 30);

    tasks.forEach(monthTasks => {
      // Filter tasks that fall within the last 7 days
      const tasksInLast7Days = monthTasks.filter(task => {
        const taskDate = new Date(task.created_timestamp); // Assuming each task has a 'date' property
        return taskDate >= last7Days && taskDate <= currentDate;
      });

      const monthTaskCount = tasksInLast7Days.length;
      const taskPercentage = (monthTaskCount / totalTasks) * 100;
      if (isNaN(taskPercentage)) {
        taskPercentagesByMonth.push(Math.round(0)); // Use Math.round to round to the nearest integer
      } else {
        taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
      }
      // taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
    });

    return taskPercentagesByMonth;
  }


  //------------- last 60 days --------------

  function splitTasksBy60days(tasks) {

    const tasksByLast7Days = Array.from({ length: 60 }, () => []);
    const currentDate = new Date();

    tasks.forEach(task => {
      const taskDate = new Date(task.created_timestamp); // Assuming task date is in a valid date format
      const daysAgo = Math.floor((currentDate - taskDate) / (1000 * 60 * 60 * 24));

      if (daysAgo >= 0 && daysAgo < 60) {
        // If the task was created within the last 7 days, add it to the corresponding day's array
        tasksByLast7Days[daysAgo].push(task);
      }
    });

    return tasksByLast7Days;


  }

  function calculateTaskPercentageLast60Days(tasks) {
    // Create an array to store the percentage of tasks for each month
    const taskPercentagesByMonth = [];
    const totalTasks = tasks.flat().length;
    const currentDate = new Date();
    const last7Days = new Date();
    last7Days.setDate(currentDate.getDate() - 60);

    tasks.forEach(monthTasks => {
      // Filter tasks that fall within the last 7 days
      const tasksInLast7Days = monthTasks.filter(task => {
        const taskDate = new Date(task.created_timestamp); // Assuming each task has a 'date' property
        return taskDate >= last7Days && taskDate <= currentDate;
      });

      const monthTaskCount = tasksInLast7Days.length;
      const taskPercentage = (monthTaskCount / totalTasks) * 100;
      if (isNaN(taskPercentage)) {
        taskPercentagesByMonth.push(Math.round(0)); // Use Math.round to round to the nearest integer
      } else {
        taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
      }
      // taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
    });

    return taskPercentagesByMonth;
  }




  //------------------------------------------





  function splitTasksByMonth(tasks) {
    const tasksByMonth = Array.from({ length: 12 }, () => []);
    // Loop through the tasks array
    tasks.forEach(task => {
      const taskDate = new Date(task.created_timestamp); // Assuming task date is in a valid date format
      // Get the month (0-indexed) from the task date
      const month = taskDate.getMonth();
      // Add the task to the corresponding month's array
      tasksByMonth[month].push(task);
    });

    return tasksByMonth;

  }

  function calculateTaskPercentageByMonth(tasks) {
    // Create an array to store the percentage of tasks for each month
    const taskPercentagesByMonth = [];
    const totalTasks = tasks.flat().length;
    tasks.forEach(monthTasks => {
      const monthTaskCount = monthTasks.length;
      const taskPercentage = ((monthTaskCount / totalTasks) * 100)
      if (isNaN(taskPercentage)) {
        taskPercentagesByMonth.push(Math.round(0)); // Use Math.round to round to the nearest integer
      } else {
        taskPercentagesByMonth.push(Math.round(taskPercentage)); // Use Math.round to round to the nearest integer
      }
      // taskPercentagesByMonth.push(parseInt(taskPercentage));
    });

    return taskPercentagesByMonth;
  }



  function calculateAverageCompletedTime(tasks) {
    if (tasks.length === 0) {
      return "0 h 0 m"; // Handle the case of an empty array.
    }

    // Calculate the total time difference in minutes
    const totalMinutes = tasks.reduce((total, task) => {
      const timestamp = new Date(task.created_timestamp);
      const completedTimestamp = new Date(task.completed_timestamp);

      // Calculate the time difference in minutes
      const timeDifferenceMinutes = (completedTimestamp - timestamp) / 1000 / 60;

      return total + timeDifferenceMinutes;
    }, 0);

    // Calculate the average completed time in hours and minutes
    const averageMinutes = totalMinutes / tasks.length;
    const averageHours = Math.floor(averageMinutes / 60);
    const remainingMinutes = Math.round(averageMinutes % 60);

    return `${averageHours} h ${remainingMinutes} m`;
  }

  function calculatePercentageOfYear(tasks, startTimestamp, endTimestamp) {
    const millisecondsInYear = 365 * 24 * 60 * 60 * 1000; // Number of milliseconds in a year

    if (tasks.length === 0 || startTimestamp >= endTimestamp) {
      return "0%"; // Handle the case of an empty array or invalid time range.
    }

    // Calculate the total time difference in milliseconds
    const totalMilliseconds = tasks.reduce((total, task) => {
      const timestamp = new Date(task.created_timestamp);
      const completedTimestamp = new Date(task.completed_timestamp);

      // Ensure that the timestamps are within the specified time range
      if (timestamp >= startTimestamp && completedTimestamp <= endTimestamp) {
        const timeDifferenceMilliseconds = completedTimestamp - timestamp;
        return total + timeDifferenceMilliseconds;
      }
      return total;
    }, 0);

    // Calculate the percentage of the total time within a year
    const percentage = (totalMilliseconds / millisecondsInYear) * 100;

    return `${percentage.toFixed(2)}%`;
  }


  const calculateGraphData = (newTask, selType) => {


    if (selType === "Year") {

      const processingTasks = newTask.filter((tData) => tData.workflow_name !== "To do" && tData.workflow_name !== "Completed" && tData.workflow_name !== "Verified" && tData.workflow_name !== "Ignore" && tData.completed_status === "false")
      const completedTasks = newTask.filter((tData) => tData.completed_status === "true")
      const dueTasks = newTask.filter((tData) => tData.completed_status === "false" && tData.due_date !== "")
      var dueTaskArray = []

      Promise.all(

        dueTasks.map((row, index) => {

          const currentDate = new Date()
          if (currentDate > new Date(row.due_date)) {
            dueTaskArray.push(row)
          } else {

          }
        })

      )

      const processingTasksByMonth = splitTasksByMonth(processingTasks);
      const completedTasksByMonth = splitTasksByMonth(completedTasks);
      const dueTaskByMonth = splitTasksByMonth(dueTaskArray)

      const processingTasksPerceByMonth = calculateTaskPercentageByMonth(processingTasksByMonth)
      const completedTasksPerceByMonth = calculateTaskPercentageByMonth(completedTasksByMonth)
      const dueTasTasksPerceByMonth = calculateTaskPercentageByMonth(dueTaskByMonth)

      const averageResponseTime = calculateAverageCompletedTime(completedTasks)
      const startTimestamp = new Date(new Date().setMonth(0));
      const endTimestamp = new Date(new Date().setMonth(11));
      const averageTimePercentage = calculatePercentageOfYear(completedTasks, startTimestamp, endTimestamp);

      setcountStatics((prevState) => {
        const newState = {
          ...prevState,
          completed: [...completedTasks].length,
          processing: [...processingTasks].length,
          totalTask: newTask.length,
          avgRespTime: averageResponseTime,
          resolutionPerc: averageTimePercentage
        }
        return newState
      })

      SETDATA([processingTasksPerceByMonth, completedTasksPerceByMonth, dueTasTasksPerceByMonth])


    } else if (selType === "7 days") {

      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(currentDate.getDate() - 7);

      const processingTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.workflow_name !== "To do" &&
          tData.workflow_name !== "Completed" &&
          tData.workflow_name !== "Verified" &&
          tData.workflow_name !== "Ignore" &&
          tData.completed_status === "false" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const completedTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "true" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const dueTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "false" &&
          tData.due_date !== "" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      var dueTaskArray = []

      Promise.all(

        dueTasks.map((row, index) => {

          const currentDate = new Date()
          if (currentDate > new Date(row.due_date)) {
            dueTaskArray.push(row)
          } else {

          }
        })

      )




      const processingTasksByMonth = splitTasksBy7days(processingTasks);
      const completedTasksByMonth = splitTasksBy7days(completedTasks);
      const dueTaskByMonth = splitTasksBy7days(dueTaskArray)

      console.log("dueTaskByMonth 7 days", dueTaskByMonth)

      const processingTasksPerceByMonth = calculateTaskPercentageLast7Days(processingTasksByMonth)
      const completedTasksPerceByMonth = calculateTaskPercentageLast7Days(completedTasksByMonth)
      const dueTasTasksPerceByMonth = calculateTaskPercentageLast7Days(dueTaskByMonth)

      console.log("dueTasTasksPerceByMonth 7 days", dueTasTasksPerceByMonth)


      const averageResponseTime = calculateAverageCompletedTime(completedTasks)

      const startTimestamp = new Date(currentDate);
      startTimestamp.setDate(currentDate.getDate() - 7);
      const endTimestamp = new Date(new Date());
      const averageTimePercentage = calculatePercentageOfYear(completedTasks, startTimestamp, endTimestamp);

      setcountStatics((prevState) => {
        const newState = {
          ...prevState,
          completed: [...completedTasks].length,
          processing: [...processingTasks].length,
          avgRespTime: averageResponseTime,
          resolutionPerc: averageTimePercentage
        }
        return newState
      })


      SETDATA([processingTasksPerceByMonth, completedTasksPerceByMonth, dueTasTasksPerceByMonth])


    } else if (selType === "14 days") {

      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(currentDate.getDate() - 14);

      const processingTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.workflow_name !== "To do" &&
          tData.workflow_name !== "Completed" &&
          tData.workflow_name !== "Verified" &&
          tData.workflow_name !== "Ignore" &&
          tData.completed_status === "false" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const completedTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "true" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const dueTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "false" &&
          tData.due_date !== "" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      var dueTaskArray = []

      Promise.all(

        dueTasks.map((row, index) => {

          const currentDate = new Date()
          if (currentDate > new Date(row.due_date)) {
            dueTaskArray.push(row)
          } else {

          }
        })

      )

      const processingTasksByMonth = splitTasksBy14days(processingTasks);
      const completedTasksByMonth = splitTasksBy14days(completedTasks);
      const dueTaskByMonth = splitTasksBy14days(dueTaskArray)

      const processingTasksPerceByMonth = calculateTaskPercentageLast14Days(processingTasksByMonth)
      const completedTasksPerceByMonth = calculateTaskPercentageLast14Days(completedTasksByMonth)
      const dueTasTasksPerceByMonth = calculateTaskPercentageLast14Days(dueTaskByMonth)

      const averageResponseTime = calculateAverageCompletedTime(completedTasks)

      const startTimestamp = new Date(currentDate);
      startTimestamp.setDate(currentDate.getDate() - 14);
      const endTimestamp = new Date(new Date());
      const averageTimePercentage = calculatePercentageOfYear(completedTasks, startTimestamp, endTimestamp);

      setcountStatics((prevState) => {
        const newState = {
          ...prevState,
          completed: [...completedTasks].length,
          processing: [...processingTasks].length,
          avgRespTime: averageResponseTime,
          resolutionPerc: averageTimePercentage
        }
        return newState
      })

      SETDATA([processingTasksPerceByMonth, completedTasksPerceByMonth, dueTasTasksPerceByMonth])



    } else if (selType === "30 days") {


      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(currentDate.getDate() - 30);

      const processingTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.workflow_name !== "To do" &&
          tData.workflow_name !== "Completed" &&
          tData.workflow_name !== "Verified" &&
          tData.workflow_name !== "Ignore" &&
          tData.completed_status === "false" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const completedTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "true" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const dueTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "false" &&
          tData.due_date !== "" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      var dueTaskArray = []

      Promise.all(

        dueTasks.map((row, index) => {

          const currentDate = new Date()
          if (currentDate > new Date(row.due_date)) {
            dueTaskArray.push(row)
          } else {

          }
        })

      )


      const processingTasksByMonth = splitTasksBy30days(processingTasks);
      const completedTasksByMonth = splitTasksBy30days(completedTasks);
      const dueTaskByMonth = splitTasksBy30days(dueTaskArray)

      const processingTasksPerceByMonth = calculateTaskPercentageLast30Days(processingTasksByMonth)
      const completedTasksPerceByMonth = calculateTaskPercentageLast30Days(completedTasksByMonth)
      const dueTasTasksPerceByMonth = calculateTaskPercentageLast30Days(dueTaskByMonth)

      const averageResponseTime = calculateAverageCompletedTime(completedTasks)

      const startTimestamp = new Date(currentDate);
      startTimestamp.setDate(currentDate.getDate() - 30);
      const endTimestamp = new Date(new Date());
      const averageTimePercentage = calculatePercentageOfYear(completedTasks, startTimestamp, endTimestamp);

      setcountStatics((prevState) => {
        const newState = {
          ...prevState,
          completed: [...completedTasks].length,
          processing: [...processingTasks].length,
          avgRespTime: averageResponseTime,
          resolutionPerc: averageTimePercentage
        }
        return newState
      })

      SETDATA([processingTasksPerceByMonth, completedTasksPerceByMonth, dueTasTasksPerceByMonth])



    } else if (selType === "60 days") {

      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate);
      oneWeekAgo.setDate(currentDate.getDate() - 60);

      const processingTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.workflow_name !== "To do" &&
          tData.workflow_name !== "Completed" &&
          tData.workflow_name !== "Verified" &&
          tData.workflow_name !== "Ignore" &&
          tData.completed_status === "false" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const completedTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "true" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      const dueTasks = newTask.filter((tData) => {
        const taskCreatedDate = new Date(tData.created_timestamp);
        return (
          tData.completed_status === "false" &&
          tData.due_date !== "" &&
          taskCreatedDate >= oneWeekAgo &&
          taskCreatedDate <= currentDate
        );
      });

      var dueTaskArray = []

      Promise.all(

        dueTasks.map((row, index) => {

          const currentDate = new Date()
          if (currentDate > new Date(row.due_date)) {
            dueTaskArray.push(row)
          } else {

          }
        })

      )


      const processingTasksByMonth = splitTasksBy60days(processingTasks);
      const completedTasksByMonth = splitTasksBy60days(completedTasks);
      const dueTaskByMonth = splitTasksBy60days(dueTaskArray)

      const processingTasksPerceByMonth = calculateTaskPercentageLast60Days(processingTasksByMonth)
      const completedTasksPerceByMonth = calculateTaskPercentageLast60Days(completedTasksByMonth)
      const dueTasTasksPerceByMonth = calculateTaskPercentageLast60Days(dueTaskByMonth)

      const averageResponseTime = calculateAverageCompletedTime(completedTasks)

      const startTimestamp = new Date(currentDate);
      startTimestamp.setDate(currentDate.getDate() - 60);
      const endTimestamp = new Date(new Date());
      const averageTimePercentage = calculatePercentageOfYear(completedTasks, startTimestamp, endTimestamp);

      setcountStatics((prevState) => {
        const newState = {
          ...prevState,
          completed: [...completedTasks].length,
          processing: [...processingTasks].length,
          avgRespTime: averageResponseTime,
          resolutionPerc: averageTimePercentage
        }
        return newState
      })

      SETDATA([processingTasksPerceByMonth, completedTasksPerceByMonth, dueTasTasksPerceByMonth])

    }




  }

  function getLast7Days() {
    var currentDate = new Date();
    var last7Days = [];
    for (var i = 0; i < 7; i++) {
      var previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      const formatedDate = previousDate.getDate() + "-" + (previousDate.getMonth() + 1) + "-" + previousDate.getFullYear()
      last7Days.push(formatedDate)
      // last7Days.push(previousDate.toLocaleDateString().slice(0, 10));
    }

    return last7Days;
  }

  function getLast14Days() {
    var currentDate = new Date();
    var last14Days = [];
    for (var i = 0; i < 14; i++) {
      var previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      const formatedDate = previousDate.getDate() + "-" + (previousDate.getMonth() + 1) + "-" + previousDate.getFullYear()
      last14Days.push(formatedDate)

      // last14Days.push(previousDate.toLocaleDateString().slice(0, 10));
    }

    return last14Days; // Reversing the array to get the oldest date first
  }

  function getLast30Days() {
    var currentDate = new Date();
    var last30Days = [];
    for (var i = 0; i < 30; i++) {
      var previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      const formatedDate = previousDate.getDate() + "-" + (previousDate.getMonth() + 1) + "-" + previousDate.getFullYear()
      last30Days.push(formatedDate)


    }

    return last30Days; // Reversing the array to get the oldest date first
  }


  function getLast60Days() {
    var currentDate = new Date();
    var last60Days = [];
    for (var i = 0; i < 60; i++) {
      var previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      const formatedDate = previousDate.getDate() + "-" + (previousDate.getMonth() + 1) + "-" + previousDate.getFullYear()
      last60Days.push(formatedDate)


    }

    return last60Days; // Reversing the array to get the oldest date first
  }


  const filterOptions = {
    Year: { range: "Year", label: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'] }, //Range of X-axis
    "7 days": { range: "7 days", label: getLast7Days() },
    "14 days": { range: "14 days", label: getLast14Days() },
    "30 days": { range: "30 days", label: getLast30Days() },
    "60 days": { range: "60 days", label: getLast60Days() }
  };

  const changeFilterGraphData = (props) => {
    if (filterOptions.hasOwnProperty(props)) {
      const { range, label } = filterOptions[props];
      SETLABELS(label);
      calculateGraphData(allTasks, range);
      setselgraphFilter(props);
    }
  };


  React.useState(() => {

    getProjectWorkFlowFromDb("")

  })

  return (<>

    <Box sx={{ maxWidth: '927px' }}>
      <Graph
        data={DATA}
        colors={COLORS}
        range={[0, 100]}
        labels={LABELS}
      />

    </Box>

  </>)

}