
import React from 'react'
import { useLocation, useNavigate,Link } from 'react-router-dom';
import { Box, ActionMenu, ActionList, Button, Avatar, AnchoredOverlay, IconButton, Text } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { scheduleDemoOpenClose } from '../redux/features/scheduleDemoSlice';
import { searchOpenClose } from "../redux/features/searchSlice"
import workplaceTheme from '../Workplace/Workplacetheme'
import { BellIcon, QuestionMarkCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"   //'./Dialogs/ScheduleDemoDialog';
import { Auth } from 'aws-amplify';
import HeaderSubComponent from "../Workplace/HeaderSubComponent" //'./HeaderSubComponent';
import {UnderlineNav} from '@primer/react/drafts'

const logoUrl = "https://d2fcqkt9srr3ti.cloudfront.net/logo.png"
const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"

function HeaderSanbox() {

    let hostName = window.location.hostname 
    let host = window.location.host
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const schedulereturnFocusRef = React.useRef(null)
    const [showUnderlineNav,setshowUnderlineNav] = React.useState(false)
    const [showItHeaderNav,setshowItHeaderNav] = React.useState(false)
    const [selectedIndex,setselectedIndex] = React.useState(0)
    const [selectedItHeadIndex,setselectedItHeadIndex] = React.useState(0)
     
    const [items, setItems] = React.useState([
        { navigation: 'Profile', route: "/gstsanbox", isSelected: false },
        { navigation: 'Return', route: "/returnsanbox", isSelected: false },
        { navigation: 'Demand', route: "/demandsanbox", isSelected: false },
        { navigation: 'Ledger', route: "/ledgersanbox", isSelected: false },
        { navigation: 'Additional Notice', route: "/additionalnoticesanbox", isSelected: false },
        { navigation: 'Notice', route: "/noticesanbox", isSelected: false },
        { navigation: 'Refund', route: "/refundsanbox", isSelected: false },
        { navigation: 'Challan', route: "/challansanbox", isSelected: false },
    ]) 

    const [itHeads, setitHeads] = React.useState([
        { navigation: 'Profile', route: "/Itsanbox", isSelected: false },
        { navigation: 'Return', route: "/ItReturnsanbox", isSelected: false },
        { navigation: 'Demand', route: "/ItDemandsanbox", isSelected: false },
        { navigation: 'Ledger', route: "/ItLedgersanbox", isSelected: false },
        { navigation: 'Proceeding', route: "/proceedingsanbox", isSelected: false },
        { navigation: 'Proceeding notice', route: "/proceedingNoticesanbox", isSelected: false },
        { navigation: 'Challan', route: "/ItChallansanbox", isSelected: false },
    ])
 
    return (


        <Box
            position="fixed"
            width={'100%'}
            height={48}
            background="#FFFFFF"
            borderColor="border.default"
            borderWidth={1}
            borderStyle="solid"
            p={0}
            zIndex={10}

        >
            <Box sx={workplaceTheme.headerInnerStyle}>

                <img
                    style={workplaceTheme.logoStyle}
                    src={logoUrl}
                    onClick={() => { navigate('/workplace') }}
                />


                <Box
                   
                     style={{
                           display:
                           location.pathname==="/noticesanbox"||
                           location.pathname==="/gstsanbox"||
                           location.pathname==="/demandsanbox"||
                           location.pathname==="/additionalnoticesanbox"||
                           location.pathname==="/returnsanbox"||
                           location.pathname==="/refundsanbox"||
                           location.pathname==="/ledgersanbox"||
                           location.pathname==="/challansanbox"

                           ?"flex":"none"
                     }}
                     sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left:'314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',
                        
                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        }}

                    >
                        {items.map((item, index) => (


                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>

                 
                <Box
                    style={{
                        display:
                            location.pathname === "/Itsanbox" ||
                                location.pathname === "/ItDemandsanbox" ||
                                location.pathname === "/proceedingsanbox" ||
                                location.pathname === "/proceedingNoticesanbox" ||
                                location.pathname === "/ItReturnsanbox" ||
                                location.pathname === "/ItLedgersanbox" ||
                                location.pathname === "/ItChallansanbox"
                                ? "flex" : "none"
                    }}
                    sx={{
                        width: '650px',
                        minWidth: '650px',
                        height: 48,
                        minHeight: 48,
                        position: 'absolute',
                        left: '314px',
                        margin: 'auto',
                        top: 0,
                        bottom: 0,
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#121212',

                    }}>
                    <UnderlineNav
                        key={"" + Math.random(10, 10000) + ""}
                        sx={{
                            border: 'none',
                            position: 'absolute',
                            width: '650px',
                            maxWidth: '650px',
                            minWidth: '650px',
                            bottom: 0,
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        }}

                    >
                        {itHeads.map((item, index) => (
                            <UnderlineNav.Item
                                as={Link}
                                to={item.route}
                                key={item.navigation}
                                aria-current={index === selectedItHeadIndex ? 'page' : undefined}
                                selected={item.route === location.pathname}
                                onSelect={e => {
                                    setselectedItHeadIndex(index)
                                    e.preventDefault()
                                }}
                                onClick={() => {
                                    setselectedItHeadIndex(index)
                                    navigate(item.route)
                                }}

                            >
                                {item.navigation}
                            </UnderlineNav.Item>
                        ))}
                    </UnderlineNav>
                </Box>


                <HeaderSubComponent />



            </Box>


            <ScheduleDemoDialog returnFocusRef={schedulereturnFocusRef} />


        </Box>

    )


}

export default HeaderSanbox
