import React from "react";
import { Box, Text, Button, Heading, IconButton, Select, Label, Avatar, FormControl, TextInput, StyledOcticon, Spinner, Tooltip, Dialog } from "@primer/react"
import { UnderlineNav } from '@primer/react/drafts'
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleFillIcon, FeedPersonIcon, FilterIcon, InfoIcon, MentionIcon, ProjectIcon, RowsIcon, SortAscIcon, TasklistIcon } from "@primer/octicons-react";
import { CaretSortIcon, DividerVerticalIcon } from "@radix-ui/react-icons";
import { getIndiviualsCompanies, getOrganization } from "../redux/features/contactSlice";
import { getAllWorksByProjectId, getAllWorksByWorkflowId, getProjectServices, getProjectWorkflow, getSingleWorkplaceProject, getSpaceCollaborators } from "../redux/features/spaceSlice";
import { createTaskOpenClose, openCloseTaskExpansion, updateTaskSortOrder, updateWorkflowId } from "../redux/features/taskSlice";
import TaskExpansion from "./TaskExpansion";
import SearchDialog from "../Workplace/Dialogs/SearchDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TaskComplianceCard from "./TaskComplianceCard";
import Workplacetheme from "../Workplace/Workplacetheme"


function checkDueDateColor(newdueDate) {
    var currentDate = new Date();
    var dueDate = new Date(newdueDate); // Assuming "row.due_date" is the due date value
    var timeDifference = Math.abs(dueDate.getTime() - currentDate.getTime());
    var daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    if (new Date(dueDate).getDate() == new Date(currentDate).getDate()) {
        return true

    } else {
        if (daysDifference >= 0 && daysDifference <= 3 && dueDate > currentDate) {
            return true
        } else {
            return false
        }

    }

}


export default function Works() {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskDataopenClose = useSelector((state) => state.taskData.openClose)
    const taskTrigger = useSelector((state) => state.taskData.taskTrigger)
    const taskDuplicateTrigger = useSelector((state) => state.taskData.taskDuplicateTrigger)
    const taskDeleteTrigger = useSelector((state) => state.taskData.taskDeleteTrigger)

    const initialFocusRef = React.createRef()

    const startDateRef = React.createRef()
    const endDateRef = React.createRef()

    const [pinAssigne, setpinAssigne] = React.useState("")
    const [isSorted, setisSorted] = React.useState(false)
    const [isKanban, setisKanban] = React.useState(false)
    const [isList, setisList] = React.useState(false)
    const [isSaveConfirm, setisSaveConfirm] = React.useState({
        show: false,
        workflow: false,
        sortsorder: false,
        workflowProps: '',
        taskProps: [],
        taskId: ''
    })


    const [filteredWorkflowId, setfilteredWorkflowId] = React.useState("")
    const [filteredAssigneId, setfilteredAssigneId] = React.useState("")
    const [selectedComplianceId, setselectedComplianceId] = React.useState("")
    const [selectedContactId, setselectedContactId] = React.useState("")
    const [filteredPriority, setfilteredPriority] = React.useState("")
    const [priorityArray, setpriorityArray] = React.useState(["Normal", "Medium", "High", "Critical", "Low"])
    const [startDate, setstartDate] = React.useState("")
    const [endDate, setendDate] = React.useState("")

    const [showLoading, setshowLoading] = React.useState(false)

    const [loadingTasks, setloadingTasks] = React.useState(false)
    const [workflow, setworkflow] = React.useState([{ workflow_id: '', workflow_name: 'All', counter: 1, isSelected: true }])


    const [showEmptyTaskComp, setshowEmptyTaskComp] = React.useState(false)
    const [showFilteredEmptyTaskComponent, setshowFilteredEmptyTaskComponent] = React.useState(false)
    const [compliances, setcompliances] = React.useState([])
    const [allLabels, setAllLabels] = React.useState([])
    const [companyIndiviual, setcompanyIndiviual] = React.useState([])
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])

    const [showFilter, setshowFilter] = React.useState(false)
    const [showResetButton, setshowResetButton] = React.useState(false)
    const [selectedIndex, setselectedIndex] = React.useState(0)

    const [allTasks, setallTasks] = React.useState([])
    const [copyAllTask, setcopyAllTask] = React.useState([])
    const [companyType, setcompanyType] = React.useState([
        // {id:'All',name:'All'},{id:'PLC',name:'Private Limited Company'},{id:'OPC',name:'One Person Company'},{id:'LLP',name:'Limited Liability Partnership'}
    ])
    const [selectedCompanyType, setselectedCompanyType] = React.useState("")

    const [taskTypeArray, settaskTypeArray] = React.useState(["Task Created", "Due"])
    const [filteredTasktype, setfilteredTasktype] = React.useState('')

    const [months, setMonths] = React.useState([
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ])
    const [selectedMonth, setselectedMonth] = React.useState("")

    const resetAllFilters = (props) => {

        setselectedComplianceId("")
        setselectedContactId("")

        setfilteredWorkflowId("")
        getAllTaskFromDb("")
        setshowResetButton(false)
        setstartDate("")
        setendDate("")
        setfilteredPriority("")
        setfilteredTasktype("")
        setselectedCompanyType("")
        setfilteredAssigneId("")
        setselectedMonth('')
    }


    const handleClickFilterData = (props) => {

        const filteredArray = copyAllTask
            .filter((tData) => selectedComplianceId !== "" ? tData.compliance_id === selectedComplianceId : copyAllTask)
            .filter((tData) => selectedContactId !== "" ? tData.contact === selectedContactId : copyAllTask)
            .filter((tData) => filteredWorkflowId !== "" ? tData.workflow_id === filteredWorkflowId : copyAllTask)
            .filter((tData) => filteredAssigneId !== "" ? tData.assigne_id === filteredAssigneId : copyAllTask)
            .filter((tData) => filteredPriority !== "" ? tData.tasks_priority === filteredPriority : copyAllTask)
            .filter((tData) =>

                filteredTasktype !== "" ?

                    filteredTasktype === "Due" ?
                        (
                            startDate !== "" && endDate !== "" && tData.due_date !== null ? new Date(tData.due_date) >= new Date(startDate) && new Date(tData.due_date) <= new Date(endDate) : copyAllTask
                        )
                        :
                        filteredTasktype === "Task Created" ?
                            (
                                startDate !== "" && endDate !== "" ? new Date(tData.created_timestamp) >= new Date(startDate) && new Date(tData.created_timestamp) <= new Date(endDate) : copyAllTask

                            )
                            : copyAllTask : copyAllTask

            )


        if (filteredArray.length > 0) {
            setshowFilteredEmptyTaskComponent(false)
        } else {
            setshowFilteredEmptyTaskComponent(true)
        }


        setallTasks(filteredArray)
        setshowResetButton(true)


    }

    const handleSortData = (props) => {

        setisSorted(!isSorted)
        setshowFilter(false)
        const workflowId = workflow[selectedIndex].workflow_id
        if (isKanban) {

            if (!isSorted) {

                const sortByDueDate = copyAllTask
                    .filter((tData) =>
                        tData.due_date !== ""

                    )
                    .sort(function (a, b) {

                        if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
                    })

                setallTasks(sortByDueDate)


            } else {

                const sortByDueDate = copyAllTask.sort(function (a, b) {

                    if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
                })

                setallTasks(sortByDueDate)



            }




        } else {

            if (isSorted) {



                if (workflowId === "") {



                    const sortByDueDate = copyAllTask
                        .filter((tData) =>
                            tData.due_date !== ""

                        )
                        .sort(function (a, b) {

                            if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
                        })

                    setallTasks(sortByDueDate)

                } else {

                    const sortByDueDate = copyAllTask
                        .filter((tData) =>
                            tData.due_date !== ""
                            &&
                            tData.workflow_id === workflowId
                        )
                        .sort(function (a, b) {

                            if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
                        })

                    setallTasks(sortByDueDate)

                }





            } else {

                if (workflowId !== undefined) {
                    getAllTaskByWorkflowId(workflowId)
                }
            }




        }







    }




    const EmptyComponents = (props) => {

        return (<Box sx={{
            width: '434px',
            height: '184px',
            minWidth: '434px',
            minHeight: '184px',
            maxWidth: '434px',
            maxHeight: '184px',
            borderRadius: '6px',
            border: '1px solid #D0D7DE',
            background: '#FFFFFF',
            position: 'absolute',
            top: showFilter === true ? '215px' : '116px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            <Box sx={{
                width: '16px',
                height: '16px',
                minWidth: '16px',
                minHeight: '16px',
                maxWidth: '16px',
                maxHeight: '16px',
                position: 'relative',
                margin: '40px 0px 0px 0px'
            }}>

                <TasklistIcon />

            </Box>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '17px',
                lineHeight: '36px',
                textAlign: 'center',
                color: '#24292F',
                position: 'relative',
                margin: '16px 0px 0px 0px'
            }}>
                Looks like you don't have any tasks yet!
            </Text>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#57606A',
                position: 'relative',
                margin: '4px 32px 0px 32px'
            }}>
                Click the 'Add Task' button to create your first task and start streamlining your organization.
            </Text>





        </Box>)

    }

    const EmptyFilteredComponents = (props) => {

        return (<Box sx={{
            width: '434px',
            height: '184px',
            minWidth: '434px',
            minHeight: '184px',
            maxWidth: '434px',
            maxHeight: '184px',
            borderRadius: '6px',
            border: '1px solid #D0D7DE',
            background: '#FFFFFF',
            position: 'absolute',
            top: '215px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            <Box sx={{
                width: '16px',
                height: '16px',
                minWidth: '16px',
                minHeight: '16px',
                maxWidth: '16px',
                maxHeight: '16px',
                position: 'relative',
                margin: '40px 0px 0px 0px'
            }}>

                <InfoIcon />

            </Box>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '17px',
                lineHeight: '36px',
                textAlign: 'center',
                color: '#24292F',
                position: 'relative',
                margin: '16px 0px 0px 0px'
            }}>
                We couldn't find any results
            </Text>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#57606A',
                position: 'relative',
                margin: '4px 32px 0px 32px'
            }}>
                The data you're looking for is currently not available . Please try your search again with different keywords or phrases.
            </Text>





        </Box>)

    }

    const onSelecWorkFlowHeads = (props) => {


        setloadingTasks(true)
        if (props === "" || props === null) {


            setshowEmptyTaskComp(false)
            setloadingTasks(false)
            getAllTaskFromDb("")

        } else {


            getAllTaskByWorkflowId(props)



        }

    }


    const showTaskExpansion = (props) => {

        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props.taskId
        }))

    }


    const getOrganizationFromDb = (props) => {

        dispatch(getOrganization({
            userId: userId,
            userEmail: userEmail,
            projectId: ''
        })).then((data) => {

            console.log("getOrganization", data.payload.data)
            const newCompanyType = [{ id: 'All', name: 'All' }]

            const responseArray = [...data.payload.data].map((row, index) => {
                const orgTypeArray = [...row.org_types].map((row2, index2) => { newCompanyType.push(row2) })
            })
            setcompanyType(newCompanyType)


        })

    }

    const getAllCategories = (props) => {


        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            setcompliances(sortedArray)




        })

    }

    const getAllCompanyIndiviuals = async (props) => {


        dispatch(getIndiviualsCompanies({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setcompanyIndiviual(data.payload.data)

        })

    }


    const getWorkflowHeads = (props) => {


        dispatch(getSingleWorkplaceProject({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            const newLabels = data.payload?.data[0]?.project_labels
            setAllLabels(newLabels)

        })

    }

    const getSpaceCollaboratorFromDb = (props) => {


        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
        })

    }


    const getAllTaskByWorkflowId = (props) => {


        dispatch(getAllWorksByWorkflowId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            workflowId: props
        })).then((data) => {

            

            const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
            })
            setallTasks(sortedTaskArray)
            setloadingTasks(false)
            if (sortedTaskArray.length == 0) {
                setshowEmptyTaskComp(true)
            } else {
                setshowEmptyTaskComp(false)
            }

        })

    }

    const getAllTaskFromDb = (props) => {


        dispatch(getAllWorksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            if (props === true) {

                const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {

                    return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                }).sort(function (a, b) {

                    return parseInt(a.kanban_v_stage?.sortOrder) - parseInt(b.kanban_v_stage?.sortOrder);
                })

                setallTasks(sortedTaskArray)



            }
            else if (props === "0") {


                const sortedTaskArray0 = [...data.payload?.toDoTasks].sort(function (a, b) {
                    return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                })

                const sortedTaskArray = [...data.payload?.toDoTasks].sort(function (a, b) {
                    return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                })

                console.log("sortedTaskArray", sortedTaskArray)
                setcopyAllTask(sortedTaskArray)
                setallTasks(sortedTaskArray0)
                if (sortedTaskArray.length <= 0) {
                    setshowEmptyTaskComp(true)
                } else {
                    setshowEmptyTaskComp(false)
                }


            }
            else {

                const sortedTaskArray = [...data.payload?.data].sort(function (a, b) {
                    return new Date(b.created_timestamp) - new Date(a.created_timestamp);
                })

                console.log("sortedTaskArray", sortedTaskArray)
                setcopyAllTask(sortedTaskArray)
                setallTasks(sortedTaskArray)
                if (sortedTaskArray.length <= 0) {
                    setshowEmptyTaskComp(true)
                } else {
                    setshowEmptyTaskComp(false)
                }


            }






        })

    }

    const getWorkflowHeadWithTaskCount = (props) => {


        setshowLoading(true)
        dispatch(getProjectWorkflow({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            setworkflow(data?.payload?.navArray)
            setshowLoading(false)





        })

    }


    //----------- kanban board operations -----------------

    const closeSaveConfirmDialog = (props) => {

        setisSaveConfirm({
            show: false,
            workflow: false,
            sortsorder: false,
            workflowProps: '',
            taskProps: []
        })

    }

    const cancelButtonClick = (props) => {

        setisSaveConfirm({
            show: false,
            workflow: false,
            sortsorder: false,
            workflowProps: '',
            taskProps: []
        })

        const sortedTaskArray = [...copyAllTask].sort(function (a, b) {

            return new Date(b.created_timestamp) - new Date(a.created_timestamp);
        }).sort(function (a, b) {

            return parseInt(a.kanban_v_stage?.sortOrder) - parseInt(b.kanban_v_stage?.sortOrder);
        })
        setallTasks(sortedTaskArray)

    }



    const updateKanbanDataIndb = (props) => {

        closeSaveConfirmDialog("")

        if (isSaveConfirm.workflowProps !== "") {




            dispatch(updateWorkflowId({
                userId: userId,
                userEmail: userEmail,
                taskId: isSaveConfirm.workflowProps.taskId,
                workflowId: isSaveConfirm.workflowProps.workflowId
            })).then((data) => {



            })





        }

        if (isSaveConfirm.taskProps.length > 0) {



            dispatch(updateTaskSortOrder({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                sortedTask: isSaveConfirm.taskProps
            })).then((data) => {

                console.log("sort orderresponse", data.payload)


            })



        }




    }


    const updateTaskWorkflowInDb = (props) => {

        setisSaveConfirm((prevState) => {
            return ({ ...prevState, show: true, workflow: true, workflowProps: props })
        })

    }


    const updateTaskSortOrderInDb = (props) => {

        setisSaveConfirm((prevState) => {
            return ({ ...prevState, show: true, sortsorder: true, taskProps: props })
        })

    }

    //----------- kanban drag and drop code-----------------  





    const onDragEnd = (result) => {

        console.log(result)

        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {

            const sourceItem = allTasks.filter((task) => task.workflow_id !== null ? task.workflow_id.includes(source.droppableId) : null)
            const updateTaskId = sourceItem[source.index].task_id;
            const updateworkflowId = destination.droppableId
            setallTasks(prevState => {

                const newState = prevState.map(obj => {

                    if (obj.task_id === updateTaskId.trim()) {

                        //------- update db operation here
                        updateTaskWorkflowInDb({ taskId: updateTaskId, workflowId: updateworkflowId })
                        //---------------------------------
                        return { ...obj, workflow_id: updateworkflowId, };

                    }
                    return obj;
                });



                const position = newState.filter((tData) => tData.workflow_id === destination.droppableId)
                    .findIndex(x => x.task_id.trim() === updateTaskId.trim());


                const unfilteredData = newState.filter((tData) => tData.workflow_id !== destination.droppableId)
                const copyListItems = [...newState.filter((tData) => tData.workflow_id === destination.droppableId)];
                const dragItemContent = copyListItems[position];
                copyListItems.splice(position, 1);
                copyListItems.splice(destination.index, 0, dragItemContent);

                const a = copyListItems.concat(unfilteredData).filter((tData) => tData.workflow_id === destination.droppableId)


                updateTaskSortOrderInDb(copyListItems.concat(unfilteredData))
                return copyListItems.concat(unfilteredData)


            })








        } else {



            const sourceItem = allTasks.filter((task) => task.workflow_id !== null ? task.workflow_id.includes(source.droppableId) : null)
            const updateTaskId = sourceItem[destination.index].task_id;

            setallTasks(prevState => {

                const sourceItem = prevState.filter((task) => task.workflow_id !== null ? task.workflow_id.includes(source.droppableId) : null)
                const updateTaskId = sourceItem[source.index].task_id;
                const unfilteredData = allTasks.filter((tData) => tData.workflow_id !== source.droppableId)
                const copyListItems = [...allTasks.filter((tData) => tData.workflow_id === source.droppableId)]
                const dragItemContent = copyListItems[source.index];
                copyListItems.splice(source.index, 1);
                copyListItems.splice(destination.index, 0, dragItemContent);
                const a = copyListItems.concat(unfilteredData).filter((tData) => tData.workflow_id === source.droppableId)
                const newUpdatedList = copyListItems.concat(unfilteredData)
                return newUpdatedList


            })


            setallTasks((prevTasks) => {

                const newTasks = prevTasks.map((obj, index) => {
                    if (obj.workflow_id === sourceItem[destination.index].workflow_id.trim()) {
                        return { ...obj, sort_order: "" + index + "", };
                    }
                    return obj;

                })
                updateTaskSortOrderInDb(newTasks)
                return newTasks


            })


        }

    }

    //------------------------------------------------------


    React.useEffect(() => {


        if (taskDataopenClose === false) {




            const workflowId = workflow[selectedIndex]?.workflow_id

            if (workflowId !== undefined) {

                if (isKanban) {
                    getAllTaskFromDb(isKanban)
                } else {

                    // if(selectedIndex)
                    getAllTaskByWorkflowId(workflowId)
                }


            }

        }

        if (taskTrigger === true) {
            getWorkflowHeadWithTaskCount("")
        }


    }, [taskTrigger])


    React.useEffect(() => {

        if (taskDeleteTrigger === true && isKanban === true) {

            getAllTaskFromDb(isKanban)

        } else {

            if (taskDeleteTrigger === true) {

                const workflowId = workflow[selectedIndex]?.workflow_id
                if (workflowId !== undefined) {
                    getAllTaskByWorkflowId(workflowId)
                }

            }


        }
    }, [taskDeleteTrigger])


    React.useEffect(() => {


        if (isKanban === true && taskDuplicateTrigger === true) {

            getAllTaskFromDb(isKanban)

        } else {


            if (taskDuplicateTrigger === true) {

                const workflowId = workflow[selectedIndex]?.workflow_id
                if (workflowId !== undefined) {
                    getAllTaskByWorkflowId(workflowId)
                }


            }



        }





    }, [taskDuplicateTrigger])


    React.useEffect(() => {


        if (
            selectedComplianceId !== "" ||
            selectedContactId !== "" ||
            selectedCompanyType !== "" ||
            filteredAssigneId !== "" ||
            filteredWorkflowId !== "" ||
            filteredPriority !== "" ||
            filteredTasktype !== "" ||
            selectedMonth !== "" ||
            startDate !== "" ||
            endDate !== ""
        ) {

            setshowResetButton(true)
        }

    }, [
        selectedComplianceId,
        selectedContactId,
        selectedCompanyType,
        filteredAssigneId,
        filteredWorkflowId,
        filteredPriority,
        filteredTasktype,
        selectedMonth,
        startDate,
        endDate
    ])



    React.useEffect(() => {

        if (isKanban === true) {

            getAllTaskFromDb(isKanban)
 
        }


    }, [isKanban])


    React.useState(() => {

        setselectedIndex(1)
        getAllCompanyIndiviuals("")
        getWorkflowHeads("")
        getSpaceCollaboratorFromDb("")
        getWorkflowHeadWithTaskCount("0")
        getAllTaskFromDb("0")

        getAllCategories("")
        getOrganizationFromDb("")
        setisList(true)
    })

 

    return (

        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"hidden"}
        >
            <Helmet>
                <title>
                    {/* Contacts - deski */}
                    {localStorage.getItem("projectName") || ""} - Board
                </title>
            </Helmet>

            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                //  overflowY={"scroll"}
                overflowY={isKanban === true ? "hidden" : "scroll"}
            >

                <Box sx={{
                    position: 'absolute',
                    top: '27px',
                    left: '26px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    alignItems: 'center'
                }}>

                    <Box

                        sx={{
                            height: '24px',
                            width: '24px',
                            borderRadius: '6px',
                            background: '#2155A4',
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: 'rgba(27, 31, 36, 0.15) 0px 0px 0px 1px'

                        }}
                    >
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {localStorage?.getItem("projectName")?.substring(0, 1) || ""}
                        </Text>


                    </Box>

                    <Text sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#24292F'
                    }}>
                        {localStorage.getItem("projectName")}
                    </Text>


                </Box>

                {/* <IconButton
                    
                    icon={() => 
                    
                       <Tooltip aria-label="Assigned to me" direction="s">
                       <MentionIcon size={16} />
                        </Tooltip>
                
                    }
                    sx={{
                        position: 'absolute',
                        right: isKanban===true?'332px':'315px',
                        top: '23px',
                        border: 'none',
                        color: pinAssigne!==''?'#000000':'#57606A',
                        boxShadow: 'none',
                        height: '32px',
                        width: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}
                    onClick={() => {
                        if (pinAssigne === "") {
                            setpinAssigne(userId)
                        } else {
                            setpinAssigne("")
                        }
                    }}
                /> */}



                <Box sx={{
                    width: 'fit-content',
                    height: '32px',
                    minHeight: '32px',
                    maxHeight: '32px',
                    position: 'absolute',
                    right: isKanban === true ? '78px' : '61px',
                    top: '23px',
                    gap: '50px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>

                    <IconButton

                        icon={() =>

                            <Tooltip aria-label="Assigned to me" direction="s">
                                <MentionIcon size={16} />
                            </Tooltip>

                        }
                        sx={{
                            border: 'none',
                            color: pinAssigne !== '' ? '#000000' : '#57606A',
                            boxShadow: 'none',
                            height: '32px',
                            width: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                        onClick={() => {
                            if (pinAssigne === "") {
                                setpinAssigne(userId)
                            } else {
                                setpinAssigne("")
                            }
                        }}
                    />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '7px',
                        alignItems: 'center',

                    }}>

                        <IconButton
                            icon={() =>

                                <Tooltip aria-label="List view" direction="s">
                                    <RowsIcon />
                                </Tooltip>


                            }
                            sx={{
                                border: 'none',
                                color: isList ? '#000000' : '#57606A',
                                boxShadow: 'none',
                                height: '32px',
                                width: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}

                            onClick={() => {
                                setisKanban(false)
                                setisList(true)
                            }}

                        />
                        <Box>
                            <DividerVerticalIcon style={{ display: 'flex', color: '#000000' }} />
                        </Box>

                        <IconButton
                            icon={() =>

                                <Tooltip aria-label="Kanban" direction="s">
                                    <ProjectIcon />
                                </Tooltip>

                            }
                            sx={{
                                border: 'none',
                                color: isKanban ? '#000000' : '#57606A',
                                boxShadow: 'none',
                                height: '32px',
                                width: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                            }}
                            onClick={() => {
                                setisKanban(true)
                                setisList(false)
                            }}
                        />


                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                        alignItems: 'center',

                    }}>

                        <IconButton
                            icon={() =>

                                <Tooltip aria-label="Sort as due date" direction="s">
                                    <SortAscIcon />
                                </Tooltip>

                            }
                            sx={{
                                border: 'none',
                                color: isSorted ? '#000000' : '#57606A',
                                boxShadow: 'none',
                                height: '32px',
                                width: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}

                            onClick={() => {

                                handleSortData("")

                            }}

                        />
                        <IconButton
                            icon={() =>

                                <Tooltip aria-label="Filter" direction="s">
                                    <FilterIcon />
                                </Tooltip>

                            }
                            sx={{
                                border: 'none',
                                color: showFilter ? '#000000' : '#57606A',
                                boxShadow: 'none',
                                height: '32px',
                                width: '32px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => {
                                setshowFilter(!showFilter)
                                if (showFilter) {
                                    setshowFilteredEmptyTaskComponent(false)
                                    const workflowId = workflow[selectedIndex]?.workflow_id

                                    if (workflowId !== undefined) {
                                        getAllTaskByWorkflowId(workflowId)
                                    }

                                }


                            }}
                        />


                    </Box>



                </Box>



                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '59px',
                    right: '61px',
                    //height: 'fit-content',
                    fontFamily: 'Segoe UI',
                    display: isKanban === true ? 'none' : 'flex',
                    justifyContent: 'flex-start',
                    // new Code added at 5-10-2023
                    height:'40px',
                    minHeight:'40px',
                    maxHeight:'40px',

                }}>



                    <UnderlineNav

                        key={"" + Math.random(100, 20000) + ""}
                        aria-label="workflows"
                        sx={{

                            paddingLeft: '0px',
                            marginLeft: '0px',
                            position: 'absolute',
                            right: '0px',
                            left: '0px',
                            // new Code added at 5-10-2023
                            top:'0px',
                            bottom:'0px',
                            
                        }}

                    >
                        {workflow?.map((item, index) => (
                            <UnderlineNav.Item
                                style={{
                                    fontFamily: 'Avenir LT Std',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: item.isSelected == true ? "#000000" : "#24292E",
                                    minHeight: '24px',
                                    maxHeight: '24px',
                                    height: '24px',
                                    margin: '0px',
                                }}
                                key={item.workflow_id}
                                aria-current={index === selectedIndex ? 'page' : undefined}
                                onSelect={e => {
                                    setselectedIndex(index)
                                    onSelecWorkFlowHeads(item.workflow_id)
                                    e.preventDefault()
                                }}

                                //  selected={item.isSelected}
                                selected={index === selectedIndex ? true : false}
                                counter={item.counter}

                            >

                                {item.workflow_name}
                            </UnderlineNav.Item>

                        ))}
                    </UnderlineNav>






                </Box>


                <Box sx={{
                    position: 'absolute',
                    top: '67px',
                    left: '26px',
                    right: '61px',
                    minHeight: '120px',
                    height: '120px',
                    maxHeight: '120px',
                    border: '1px solid #D0D7DE',
                    borderRadius: '3px',
                    background: '#FFFFFF',
                    display: showFilter ? 'flex' : "none",
                    flexDirection: 'column',
                    minWidth: '1257px',

                }}>

                    <Box sx={{
                        position: 'absolute',
                        left: '21px',
                        right: '21px',
                        top: '22px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        height: '28px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '15px'
                    }}>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400'

                            }}  >

                            <Select
                                block
                                aria-label="Compliance type"
                                // placeholder="Select compliance"
                                //  placeholder="All"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}
                                onChange={(e) => {

                                    if (e.target.value.length > 0) {
                                        setselectedComplianceId(e.target.value)
                                    } else {
                                        setselectedComplianceId("")
                                    }

                                }}

                            >
                                <Select.Option value={""} selected={selectedComplianceId.length <= 0 ? true : false}>Select compliance</Select.Option>
                                <Select.Option value={""} selected={selectedCompanyType.length > 0 && selectedComplianceId === "" ?

                                    true : false}>All</Select.Option>
                                {

                                    compliances.map((row, index) => {
                                        return (

                                            <Select.Option value={row.subcategory.compliance_id} selected={row.subcategory.compliance_id === selectedComplianceId} >{row.subcategory.compliance_name}</Select.Option>
                                        )
                                    })

                                }
                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Label"
                                placeholder="Select organization type"

                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                }}

                                onChange={(e) => {


                                    setselectedCompanyType(e.target.value)
                                    if (selectedComplianceId.length <= 0) {
                                        setselectedComplianceId("")
                                    }
                                }}
                            >
                                {companyType.map((row, index) => {
                                    return (

                                        <Select.Option value={row.id} selected={row.id === selectedCompanyType}>{row.name}</Select.Option>

                                    )
                                })}


                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400'

                            }}  >

                            <Select

                                block
                                aria-label="Organization"
                                placeholder="Select organization"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {

                                    if (e.target.value === "All") {
                                        setselectedContactId("")
                                    }
                                    setselectedContactId(e.target.value)
                                }}
                            >
                                {companyIndiviual
                                    .filter((cData) => selectedCompanyType === "All" ? companyIndiviual : cData.contact_data?.org_type === selectedCompanyType)
                                    .map((row, index) => {
                                        return (
                                            <Select.Option value={row.contact_id} >{row.contact_data?.org_name}</Select.Option>

                                        )
                                    })}



                            </Select>
                        </Box>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Assignee"
                                placeholder="Select assignee"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredAssigneId(e.target.value)
                                }}
                            >

                                {spaceCollaborators?.map((row, index) => {
                                    return (
                                        <Select.Option value={row?.user_id} selected={row?.user_id === filteredAssigneId}>{row?.user_first_name + " " + row?.user_last_name}</Select.Option>
                                    )
                                })}


                            </Select>
                        </Box>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Status"
                                placeholder="Select work status"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredWorkflowId(e.target.value)
                                }}
                            >

                                {
                                    workflow?.map((row, index) => {
                                        return (
                                            <Select.Option value={row.workflow_id} selected={row.workflow_id === "" ? false : row.workflow_id === filteredWorkflowId}>{row.workflow_name}</Select.Option>
                                        )
                                    })
                                }



                            </Select>
                        </Box>





                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Priority"
                                placeholder="Select priority"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredPriority(e.target.value)
                                }}
                            >

                                {priorityArray.map((row, index) => {

                                    return (
                                        <Select.Option value={row} selected={row === filteredPriority}>{row}</Select.Option>
                                    )
                                })}


                            </Select>
                        </Box>




                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        left: '21px',
                        right: '21px',
                        top: '70px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        height: '28px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '15px'
                    }}>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Input placeholder"
                                placeholder="Select task type"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                }}
                                onChange={(e) => {
                                    setfilteredTasktype(e.target.value)
                                }}
                            >

                                {taskTypeArray.map((row, index) => {

                                    return (
                                        <Select.Option value={row} selected={row === filteredTasktype}>{row}</Select.Option>
                                    )
                                })}



                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Wait"
                                placeholder="Select month"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {

                                    setselectedMonth(e.target.value)

                                    if (e.target.value.length > 0) {

                                        setstartDate(new Date(new Date(new Date().getFullYear(), months.indexOf(e.target.value), 2)).toISOString().slice(0, 10))
                                        setendDate(new Date(new Date(new Date().getFullYear(), months.indexOf(e.target.value) + 1, 1)).toISOString().slice(0, 10))

                                    } else {

                                        setstartDate("")
                                        setendDate("")

                                    }



                                }}


                            >

                                {months.map((row, index) => {
                                    return (
                                        <Select.Option value={row} selected={row === selectedMonth} >{row}</Select.Option>
                                    )
                                })}



                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',
                                display: 'flex'
                            }}  >

                            <TextInput
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'
                                }}
                                placeholder="Start date"
                                value={startDate !== null && startDate !== "" ?
                                    new Date(startDate).getDate() + "/" + ((new Date(startDate).getMonth() + 1)) + "/" + new Date(startDate).getFullYear()
                                    : ""}
                                onChange={(e) =>

                                    startDateRef.current.showPicker()
                                }
                                onFocus={(e) => { startDateRef.current.showPicker() }}
                                onClick={() => {
                                    startDateRef.current.showPicker()
                                }}
                            />
                            <input
                                type={"date"}
                                ref={startDateRef}
                                value={startDate}
                                onChange={(e) => {

                                    if (e.target.value === "" || e.target.value == null) {

                                        setstartDate("")


                                    } else {

                                        setstartDate(e.target.value)
                                        if (months.indexOf(selectedMonth) !== new Date(e.target.value).getMonth()) {
                                            setselectedMonth('')
                                        }


                                    }


                                }}
                                style={{

                                    position: 'fixed',
                                    height: '26px',
                                    minHeight: '26px',
                                    maxHeight: '26px',
                                    width: '180px',
                                    minWidth: '180px',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />
                        </Box>




                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',
                                display: 'flex'
                            }}  >

                            <TextInput
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'
                                }}
                                placeholder="End date"
                                value={endDate !== null && endDate !== "" ?
                                    new Date(endDate).getDate() + "/" + ((new Date(endDate).getMonth() + 1)) + "/" + new Date(endDate).getFullYear()
                                    : ""}
                                onChange={(e) =>

                                    endDateRef.current.showPicker()
                                }
                                onFocus={(e) => { endDateRef.current.showPicker() }}
                                onClick={() => {
                                    endDateRef.current.showPicker()
                                }}
                            />
                            <input
                                type={"date"}
                                ref={endDateRef}
                                value={endDate}
                                onChange={(e) => {

                                    if (e.target.value === "" || e.target.value == null) {


                                        setendDate("")


                                    } else {

                                        setendDate(e.target.value)
                                    }


                                }}
                                style={{

                                    position: 'fixed',
                                    height: '26px',
                                    minHeight: '26px',
                                    maxHeight: '26px',
                                    width: '180px',
                                    minWidth: '180px',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />


                        </Box>



                        <Box sx={{
                            height: '28px',
                            minHeight: '28px',
                            width: '190px',
                            minWidth: '190px',
                            maxWidth: '190px',
                            maxHeight: '28px',
                        }}>
                            <Button
                                disabled={!showResetButton}
                                variant="outline"
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    height: '28px',
                                    minHeight: '28px',
                                    width: '190px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    maxHeight: '28px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    resetAllFilters("")
                                }}
                            >
                                Reset All
                            </Button>
                        </Box>

                        <Button variant="outline" sx={{
                            background: '#0965CE',
                            color: '#ffffff',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            height: '28px',
                            minHeight: '28px',
                            width: '190px',
                            minWidth: '190px',
                            maxWidth: '190px',
                            maxHeight: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                            onClick={() => handleClickFilterData("")}
                        >
                            Filter data
                        </Button>







                    </Box>


                </Box>

                {showFilter && (
                    <Box sx={{
                        position: 'absolute',
                        left: '26px',
                        right: '61px',
                        top: '197px',
                        borderTop: '1px solid #D0D7DE',
                        height: '1px',

                    }}>

                    </Box>
                )}


                {showEmptyTaskComp === true && !isKanban ?
                    <EmptyComponents />
                    : ""}


                {showFilteredEmptyTaskComponent === true && !isKanban ?
                    <EmptyFilteredComponents />
                    : ""}




                <Box sx={{
                    position: 'absolute',
                    top: showFilter ? '213px' : '117px',
                    left: '26px',
                    right: '61px',
                    minHeight: 'fit-content',
                    background: 'white',
                    display: isKanban === true ? 'none' : 'flex',
                    flexDirection: 'column',

                }}>
                    {allTasks
                        .filter((tData) => pinAssigne !== "" ? tData.assigne_id === pinAssigne : allTasks)
                        .map((row, index) => {

                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '35px',
                                    borderBottomWidth: 1,
                                    borderBottomStyle: 'solid',
                                    borderColor: '#E0E0E0',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    ':hover': {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    }
                                }}
                                    onClick={() => {
                                        showTaskExpansion({
                                            taskId: row.task_id
                                        })
                                    }}
                                >

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '19px',
                                        color: '#121212',
                                        width: '370x',
                                        maxWidth: '370px',
                                        minWidth: '370px',
                                        minHeight: '19px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        margin: '0px 0px 0px 15px'
                                    }}>
                                        {row.task_name}

                                    </Text>


                                    {row.compliance_id !== null && row.compliance_id !== "" ?

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            width: '62px',
                                            minWidth: '62px',
                                            maxWidth: '62px',

                                        }}>
                                            <Tooltip

                                                aria-label={compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}
                                            >
                                                <Label sx={{
                                                    width: 'fit-content',
                                                    minWidth: 'fit-content',
                                                    maxWidth: 'fit-content',
                                                    height: '20px',
                                                    background: '#2155A4',
                                                    borderRadius: '6px',
                                                    border: 'none',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: '#FFFFFF',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>




                                                    <Text

                                                        sx={{
                                                            minWidth: 'auto',
                                                            width: 'auto',
                                                            maxWidth: '49px',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                        }}>

                                                        {compliances.filter((comp) => comp.service_id === row.compliance_id)[0]?.subcategory.compliance_name}

                                                    </Text>


                                                </Label>
                                            </Tooltip>
                                        </Box>
                                        :

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            width: '62px',
                                            minWidth: '62px',
                                            maxWidth: '62px',
                                        }}>
                                            <Text sx={{
                                                //  position: 'relative',
                                                //  margin: '0px 0px 0px 50px',
                                                minWidth: '49px',
                                                width: '49px',
                                                maxWidth: '49px',
                                                minWidth: '49px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}>

                                            </Text>
                                        </Box>

                                    }


                                    <Box sx={{
                                        position: 'relative',
                                        margin: '0px 0px 0px 50px',
                                        minWidth: '20px',
                                        maxWidth: '20px',
                                        minHeight: '20px',
                                        maxHeight: '20px',
                                        height: '20px',
                                        width: '20px'
                                    }}>
                                        <Tooltip aria-label={row.user_first_name !== null ? row.user_first_name + " " + row.user_last_name : "Unassigned"} direction="se" >
                                            {row.user_image_url === "" || row.user_image_url == null ?

                                                <FeedPersonIcon size={20} fill="#586069" /> :

                                                <Avatar sx={{
                                                    height: '20px',
                                                    width: '20px',
                                                    minHeight: '20px',
                                                    minWidth: '20px',


                                                }}
                                                    src={row.user_image_url} />

                                            }
                                        </Tooltip>
                                    </Box>


                                    {row.contact_data !== null ?

                                        <Tooltip aria-label={row.contact_data.org_name} direction="s">
                                            <Label sx={{
                                                position: 'relative',
                                                margin: '0px 0px 0px 50px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '3px',
                                                minWidth: 'auto',
                                                width: 'auto',
                                                maxWidth: '250px',


                                            }}>
                                                <Text sx={{
                                                    minWidth: 'auto',
                                                    width: 'auto',
                                                    maxWidth: '250px',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}>
                                                    {row.contact_data.org_name}
                                                </Text>

                                            </Label>
                                        </Tooltip>

                                        : <Text sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 50px',
                                            minWidth: '250px',
                                            width: '250px',
                                            maxWidth: '250px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}>
                                            {""}
                                        </Text>


                                    }




                                    <Box sx={{
                                        position: 'absolute',
                                        right: '29px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',

                                    }}>
                                        {row.due_date !== null && row.due_date !== "" ?

                                            <Tooltip aria-label="Due date" direction="s">
                                                <Label sx={{
                                                    position: 'relative',
                                                    margin: '0px 35px 0px 0px',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    color: row.completed_status === "true" ? "#FFFFFF" : checkDueDateColor(new Date(row.due_date)) ? '#FFFFFF' : '#0969DA',
                                                    //  color:checkDueDateColor(new Date(row.due_date)) ?'#FFFFFF':'#0969DA', 
                                                    borderRadius: '6px',
                                                    minWidth: '79px',
                                                    width: '79px',
                                                    maxWidth: '79px',
                                                    background: row.completed_status === "true" ? '#79CE17' : checkDueDateColor(new Date(row.due_date)) ? '#CF222E' : '#DDF4FF',
                                                    // background: checkDueDateColor(new Date(row.due_date)) ?'#CF222E':'#DDF4FF', 
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    {new Date(row.due_date).getDate() + "-" + (new Date(row.due_date).getMonth() + 1) + "-" + new Date(row.due_date).getFullYear()}
                                                </Label>
                                            </Tooltip>

                                            :


                                            <Label sx={{
                                                position: 'relative',
                                                margin: '0px 35px 0px 0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#0969DA',
                                                borderRadius: '6px',
                                                minWidth: '79px',
                                                width: '79px',
                                                maxWidth: '79px',
                                                background: '#DDF4FF',
                                                border: 'none',
                                                display: 'none',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {"24-5-2023"}

                                            </Label>

                                        }

                                        <Tooltip aria-label="Created date">
                                            <Label sx={{
                                                position: 'relative',
                                                margin: '0px 27px 0px 0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                borderRadius: '6px',
                                                minWidth: '78px',
                                                width: '78px',
                                                maxWidth: '78px',
                                                background: 'rgba(175, 184, 193, 0.2)',
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {new Date(row.created_timestamp).getDate() + "-" + (new Date(row.created_timestamp).getMonth() + 1) + "-" + new Date(row.created_timestamp).getFullYear()}

                                            </Label>
                                        </Tooltip>


                                        {row?.completed_status === "true" ?

                                            <Box sx={{
                                                minWidth: '12px',
                                                minHeight: '12px',
                                                width: '12px',
                                                height: '12px',
                                                maxWidth: '12px',
                                                maxHeight: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <Tooltip aria-label="Verified">
                                                    <StyledOcticon size={12} color="#79CE17" icon={CheckCircleFillIcon} />
                                                </Tooltip>
                                            </Box>

                                            :
                                            <Box sx={{
                                                minWidth: '12px',
                                                minHeight: '12px',
                                                width: '12px',
                                                height: '12px',
                                                maxWidth: '12px',
                                                maxHeight: '12px'
                                            }}>

                                            </Box>
                                        }



                                    </Box>













                                </Box>
                            )
                        })}

                    <Box sx={{
                        display: allTasks.length > 5 ? 'flex' : 'none',
                        height: '70px',
                        minHeight: '70px',
                        maxHeight: '70px',
                        width: '100%',
                        background: '#F6F8FA',
                    }}>
                    </Box>

                </Box>

                {/* kanban borad start here */}
                <Box sx={{
                    position: 'absolute',
                    top: showFilter ? '213px' : '68px',
                    left: '26px',
                    //  right: '61px',
                    display: isKanban === true ? 'flex' : 'none',
                    flexDirection: 'row',
                    gap: '15px',
                    paddingBottom: '200px',
                    //new css add
                    //   background:'red',
                    overflowX: 'auto',
                    right: '0px',
                    bottom: '0px',

                }}>



                    <DragDropContext onDragEnd={onDragEnd}
                        onDragStart={() => {

                        }}
                    >
                        {workflow
                            .filter(wData => wData.workflow_id !== "")
                            .map((row, index) => {

                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '270px',
                                        minWidth: '270px',
                                        maxWidth: '270px',
                                        background: '#F2F2F2',
                                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '6px',
                                        minHeight: '534px',
                                        maxHeight: 'fit-content',
                                        height: 'fit-content'
                                    }}>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: 'absolute',
                                            top: '0px',
                                            minHeight: '28px',
                                            maxHeight: '28px',
                                            height: '28px',
                                            width: '270px',
                                            minWidth: '270px',
                                            maxWidth: '270px',

                                        }}>
                                            <Text sx={{
                                                position: 'relative',
                                                margin: '10px 0px 0px 5px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                color: '#24292E',
                                            }}>
                                                {row.workflow_name}
                                            </Text>

                                            <Label variant="secondary" sx={{
                                                position: 'relative',
                                                margin: 'auto 0px 0px 3px',
                                                border: 'none',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                color: '#586069',
                                                background: 'rgba(175, 184, 193, 0.2)',
                                                textAlign: 'center'
                                            }}>
                                                {row.counter}
                                            </Label>


                                        </Box>




                                        <Droppable
                                            droppableId={`${row.workflow_id}`}

                                        >
                                            {(provided, snapshot) => (
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                        margin: '47px 0px 0px 0px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        // gap: '5px',
                                                        alignItems: 'center'
                                                    }}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >

                                                    {allTasks
                                                        .filter((tData) => pinAssigne !== "" ? tData.assigne_id === pinAssigne : allTasks)
                                                        .filter((tData) =>
                                                            tData.workflow_id === row.workflow_id
                                                        ).map((row, index) => (


                                                            <TaskComplianceCard
                                                                item={{ id: row.task_id }}
                                                                index={index}
                                                                tData={row}
                                                                comp={{ comp: compliances }}
                                                                collabs={{ collabs: spaceCollaborators }}
                                                            />

                                                            // <Draggable
                                                            //     draggableId={row.task_id}
                                                            //     index={index}
                                                            //     key={row.task_id}
                                                            // >
                                                            //     {(provided, snapshot) => (
                                                            //         <Box
                                                            //             ref={provided.innerRef}
                                                            //             {...provided.draggableProps}
                                                            //             {...provided.dragHandleProps}
                                                            //             sx={{
                                                            //                 position: 'relative',
                                                            //                 margin: '0px 3px 0px 3px',
                                                            //                 minHeight: '269px',
                                                            //                 maxHeight: '269px',
                                                            //                 height: '269px',
                                                            //                 background: '#FFFFFF',
                                                            //                 border: '1px solid #D0D7DE',
                                                            //                 borderRadius: '6px'

                                                            //             }}

                                                            //         >
                                                            //               Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature
                                                            //         </Box>
                                                            //     )}
                                                            // </Draggable>


                                                        ))}






                                                </Box>
                                            )}
                                        </Droppable>







                                        <Box sx={{
                                            position: 'relative',
                                            margin: 'auto 0px 0px 0px',
                                            minHeight: '35px',
                                        }}>

                                        </Box>

                                    </Box>
                                )

                            })
                        }



                    </DragDropContext>



                </Box>





            </Box>



            <TaskExpansion />
            <SearchDialog />
            <Dialog
                sx={{
                    height: '100px',
                    minHeight: '100px',
                    maxHeight: '100px',
                    width: '554px',
                    minWidth: '554px',
                    maxWidth: '554px',
                    background: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '98px',
                }}
                isOpen={isSaveConfirm.show}
                onDismiss={() => closeSaveConfirmDialog("")}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px',
                    background: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0px 4px 12px rgba(27, 31, 35, 0.15)',
                    borderRadius: '3px'
                }}>


                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            top: '13px',
                            left: '20px',
                            right: '29px',
                            margin: 'auto',
                            width: '505px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292E',

                        }}>
                        Task status changed. Please click 'Update' to make the necessary changes.
                    </Text>

                    <Box sx={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                    }}>

                        <Button
                            variant="invisible"
                            sx={{
                                width: '72px',
                                height: '32px',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={() => {
                                cancelButtonClick("")
                            }}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="outline"
                            sx={{
                                width: '107px',
                                height: '32px',
                                background: '#0965CE',
                                color: 'white',
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px'
                            }}
                            onClick={() => { updateKanbanDataIndb("") }}
                        >
                            Update
                        </Button>

                    </Box>

                </Box>








            </Dialog>


        </Box>

    )
}