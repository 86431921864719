import React from "react";

import { Box,Button,IconButton,Text,Breadcrumbs,Label, ActionMenu, ActionList, Dialog, TextInput, Textarea,FormControl,Radio, Select, Checkbox, Avatar } from "@primer/react"
import {Helmet} from "react-helmet";
import { FeedPersonIcon, GrabberIcon, KebabHorizontalIcon, PencilIcon, XIcon } from "@primer/octicons-react";
import { DragDropContext,Droppable,Draggable } from "react-beautiful-dnd";
import { useDispatch,useSelector } from "react-redux";
import { getComplianceSubtasks, getSpaceCollaborators, updateComplianceSubtask, updateServiceCompliance } from "../redux/features/spaceSlice";
import Workplacetheme from '../Workplace/Workplacetheme'
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { postZohoInvItem } from "../redux/features/contactSlice";
import { getZohoAuth } from "../redux/features/taskSlice";

function generateUUID() {
    let uuid = '', i, random;
  
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
  
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
  
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random))
              .toString(16);
    }
  
    return uuid;
  }

export default function ComplianceExpansion(props){


     const zohoTokens   = JSON.parse(localStorage.getItem("zohoTokens")) || ""

     const dispatch = useDispatch()
     const userId = useSelector((state) => state.profileData.userId)
     const userEmail = useSelector((state) => state.profileData.userEmail)

     const initialFocusRef = React.createRef()

     const dueDatecalandarRef = React.createRef()
     const [dueDate,setdueDate] = React.useState("")
     const [dateRangeSelection,setdateRangeSelection] = React.useState({
         minDate:'2023-01-01',
         maxDate:'2023-12-31'
     })
     const [enableComplianceName, setenableComplianceName] = React.useState(true)
     const [selectedCompliance,setselectedCompliance] = React.useState(props?.data?.complianceType)  
     const [selectedCategory,setselectedCategory] = React.useState(props?.catData?.category_id)
    
     //add to zoho item data 
     const [isZohoInstalled,setisZohoInstalled] = React.useState(false)
     const [isAddtoZohoItem,setisAddtoZohoItem] = React.useState()

     //---------------------

     //-------- main subtask parameters ---------------
     const [mainSubtask,setmainSubtask] =React.useState([])
     const [focusmainSubtask,setfocusmainSubtask] = React.useState("")
     const [mainsubtaskHover,setmainsubtaskHover] = React.useState("")
    
     //------------------------------------------------
    
     const [subtaskArray,setsubtaskArray] = React.useState([])
     //----- focus subtask
     const [focusSubtask,setfocusSubtask] = React.useState("")
     const [subtaskHover,setsubtaskHover] = React.useState("")
     //----- enable save button
     const [enableSaveBtn,setenableSaveBtn] = React.useState(false)
     const [showConfirmDialog,setshowConfirmDialog] = React.useState(false)
     //----- compliance Name Input
     const [complianceNameInput,setcomplianceNameInput] = React.useState(props?.data?.subcategory_name)
     const [complianceNameInputError,setcomplianceNameInputError] = React.useState("")
     const [complianceNameInputHover,setcomplianceNameInputHover] = React.useState(false)
     //----- amount payable
     const [amountPayable,setamountPayable] = React.useState("")
     //----- selected filing period
     const [selectedFilingPeriod,setselectedFilingPeriod] = React.useState("")
     const [selectedBillingType,setselectedBillingType] = React.useState("")
     const [selectedRemindMeType,setselectedRemindMeType] = React.useState("")
     
     const [serviceId,setserviceId] = React.useState("")  
     const [spaceCollaborators,setspaceCollaborators] = React.useState([])
     const [selectedAssigne,setselectedAssigne] = React.useState("")

     const addMainSubtask = (props) => {
          
         setmainSubtask((prevState) => {

             const taskId = "" + generateUUID() + ""
             setfocusSubtask(taskId)
             const newState = [...prevState, { task_id: "" + taskId + "", task_name: '' }]
             return newState
         })

    }

    // onSetCalanderDateRange 
    const onSetCalanderDateRange = (props) => {


        
        setselectedFilingPeriod(props.split("|")[0])
        if (props.split("|")[1] === "Monthly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1)

                const startDate = "" + new Date(currentDate).getFullYear() + "-" + (new Date(currentDate).getMonth() + 1) + "-" + "01"
                const endDate = "" + new Date(lastDayOfCurrentMonth).getFullYear() + "-" + (new Date(lastDayOfCurrentMonth).getMonth() + 1) + "-" + new Date(lastDayOfCurrentMonth).getDate()
                const newState = { ...prevState, minDate: startDate, maxDate: endDate }

                return newState
            })

        } else if (props.split("|")[1] === "Quarterly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

                // Calculate the start date of the current quarter
                const startMonthOfCurrentQuarter = (currentQuarter - 1) * 3;
                const firstDayOfCurrentQuarter = new Date(currentDate.getFullYear(), startMonthOfCurrentQuarter, 1);

                // Calculate the last day of the current quarter
                const endMonthOfCurrentQuarter = startMonthOfCurrentQuarter + 2;
                const lastDayOfCurrentQuarter = new Date(currentDate.getFullYear(), endMonthOfCurrentQuarter + 1, 0);

                const startDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate()}`;
                const endDate = `${lastDayOfCurrentQuarter.getFullYear()}-${(lastDayOfCurrentQuarter.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfCurrentQuarter.getDate()}`;

                const newState = { ...prevState, minDate: startDate, maxDate: endDate };
                console.log("newState", JSON.stringify(newState))

                return newState;

            });

        } else if (props.split("|")[1] === "Half-yearly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                // Calculate the start date of the next half-year
                const startMonthOfNextHalfYear = (currentDate.getMonth() < 6) ? 6 : 12;
                const firstDayOfNextHalfYear = new Date(currentDate.getFullYear(), startMonthOfNextHalfYear - 6, 1);
                // Calculate the last day of the next half-year
                const lastDayOfNextHalfYear = new Date(currentDate.getFullYear(), startMonthOfNextHalfYear, 0);
                const startDate = `${firstDayOfNextHalfYear.getFullYear()}-${(firstDayOfNextHalfYear.getMonth() + 1).toString().padStart(2, '0')}-01`;
                const endDate = `${lastDayOfNextHalfYear.getFullYear()}-${(lastDayOfNextHalfYear.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfNextHalfYear.getDate()}`;
                const newState = { ...prevState, minDate: startDate, maxDate: endDate };

                return newState;
            });

        } else if (props.split("|")[1] === "Financial year") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                // Assuming the financial year starts in April and ends in March
                const startMonthOfNextFinancialYear = (currentDate.getMonth() < 3) ? 3 : 15;
                const firstDayOfNextFinancialYear = new Date(currentDate.getFullYear(), startMonthOfNextFinancialYear - 3, 1);
                // Calculate the last day of the next financial year
                const lastDayOfNextFinancialYear = new Date(currentDate.getFullYear(), startMonthOfNextFinancialYear, 0);
                const startDate = `${firstDayOfNextFinancialYear.getFullYear()}-${(firstDayOfNextFinancialYear.getMonth() + 1).toString().padStart(2, '0')}-01`;
                const endDate = `${lastDayOfNextFinancialYear.getFullYear()}-${(lastDayOfNextFinancialYear.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfNextFinancialYear.getDate()}`;
                const newState = { ...prevState, minDate: startDate, maxDate: endDate };
                return newState;
            });

        }
    }

  
    const onChangeMainSubtaskInput = (props, e) => {

        setmainSubtask((prevState) => {

            const newState = prevState.map((tData) => {

                if (tData.task_id === props) {
                    return ({ ...tData, task_name: e.target.value })
                } else {

                    return (tData)
                }

            })
            return newState
        })


    }  


    const getSpaceCollaboratorFromDb = (props) => {


        
        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
         

        })

    }


    const addToZohoItem = (props) => {

        const itemId =  serviceId
        const itemName = complianceNameInput
        const rate = amountPayable
      
        if(rate!==null&&rate!==""){

            dispatch(postZohoInvItem({
                userId:userId,
                userEmail:userEmail,
                zohoToken: zohoTokens,
                itemId:itemId,
                itemName:itemName,
                rate:rate,
                projectId:localStorage.getItem("projectId")
          })).then((data)=>{
  
              console.log("postZohoInvItem", data.payload)
             
               const itemNames = data?.payload?.createItemResponse?.data?.item?.name
               const message = data?.payload?.createItemResponse?.data.message
               const code = data?.payload?.createItemResponse?.data?.code

               if (itemNames === itemName) {

                  Toaster.create({ position: Position.TOP }).show({
                      message: "Sucessfully created",
                      intent: Intent.SUCCESS
                  });


              } else {

                  if (code == 9017) {

                      Toaster.create({ position: Position.TOP }).show({
                          message: "Zoho " + message,
                          intent: Intent.PRIMARY
                      });

                  }else{

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Already created " + itemName,
                        intent: Intent.PRIMARY
                    });

                  }

                 


              }


          })
            
        }else{

            Toaster.create({ position: Position.TOP }).show({
                message: "Empty amount",
                intent: Intent.PRIMARY
            });
        } 

        


    }


    const addSubtask = (props) => {

        setsubtaskArray((prevState) => {

            const taskId = "" + generateUUID() + ""
            setfocusSubtask(taskId)
            const newState = [...prevState, { task_id: "" + taskId + "", task_name: '' }]
            return newState
        })

    }



    const onChangeSubtaskInput = (props, e) => {

        setsubtaskArray((prevState) => {

            const newState = prevState.map((tData) => {

                if (tData.task_id === props) {
                    return ({ ...tData, task_name: e.target.value })
                } else {

                    return (tData)
                }

            })
            return newState
        })

    }

     const onDragEndMainSubtask = (result) => {

        console.log(result)


        if (!result.destination) return;
        const { source, destination } = result;
    
    
        if (source.droppableId !== destination.droppableId) {

        }else{

            setenableSaveBtn(true)
            const newItems = Array.from(mainSubtask);
            const [removed] = newItems.splice(result.source.index, 1);
            newItems.splice(result.destination.index, 0, removed);
            setmainSubtask(newItems);

        }

    }

     const onDragEnd = (result) => {

        console.log(result)


        if (!result.destination) return;
        const { source, destination } = result;
    
    
        if (source.droppableId !== destination.droppableId) {

        }else{

            setenableSaveBtn(true)
            const newItems = Array.from(subtaskArray);
            const [removed] = newItems.splice(result.source.index, 1);
            newItems.splice(result.destination.index, 0, removed);
            setsubtaskArray(newItems);

        }

    }


    const updateMainSubtaskInDb = (props) => {

         
        dispatch(updateComplianceSubtask({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            complianceId: serviceId,
            mainSubtasks: mainSubtask
        })).then((data) => {

            console.log("updateComplianceSubtask", data.payload)
            const mainSubtask = [...data.payload.data].map((row, index) => {
                return ({
                    task_id: row.subcategory.compliance_id,
                    task_name: row.subcategory.compliance_name,
                    assigne_id:row.assigne_id
                })
            })
            setmainSubtask(mainSubtask)
        })


    }

    const updateComplianceDataIndb = (props) => {



        if (isAddtoZohoItem === true) {

            if (amountPayable !== null && amountPayable !== "") {

                addToZohoItem("")
            }

        }
 

          if(selectedCompliance==="bf1ef6f2-d4d1-4f8c-8558-3bcec89b8efa" && dueDate===""){
              setshowConfirmDialog(false)
              Toaster.create({ position: Position.TOP }).show({
                  message: "Select due date",
                  intent: Intent.PRIMARY
              });

          }else{

            setshowConfirmDialog(false)
          dispatch(updateServiceCompliance({
                 userId:userId,
                 userEmail:userEmail,
                 projectId:localStorage.getItem("projectId"),
                 complianceType:selectedCompliance,
                 serviceId:""+serviceId+"",
                 subcategoryName:complianceNameInput,
                 dueDate:""+dueDate+"",
                 filingPeriod:selectedFilingPeriod,
                 billingType:selectedBillingType,
                 amountPayable:amountPayable,
                 subtasks:subtaskArray,
                 categoryId:selectedCategory,
                 remindMeType:selectedRemindMeType,
                 selectedAssigne:selectedAssigne
           })).then((data)=>{

               
               setenableSaveBtn(false)
               console.log("updateServiceCompliance", data.payload.data)
               if (data?.payload?.data[0] !== undefined && data?.payload?.data[0] !== null) {
                   setselectedAssigne(data?.payload?.data[0]?.assigne_id)
               }

               Toaster.create({ position: Position.TOP }).show({
                   message: "Compliance data successfully updated.",
                   intent: Intent.SUCCESS
               });


              
           })

          }

          


        
    } 



    const getZohoAuthFromDb = (props) => {


         dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

           
            console.log("getZohoAuth In Compliance exp Dialog", data.payload)
            const appCode = data?.payload?.checkApp?.code
            const refreshToken = data?.payload?.data?.refresh_token

            console.log("refreshToken", refreshToken)
            if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {

                setisAddtoZohoItem(true)

            } else {

                setisAddtoZohoItem(false)

            }

            if (appCode === 200) {

                setisZohoInstalled(true)

            } else if (appCode === 404) {

                setisZohoInstalled(false)
            }



        })

    }

    const getComplianceSubtasksFromDb = (props) => {

        dispatch(getComplianceSubtasks({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            complianceId: props
        })).then((data) => {

            console.log("getComplianceSubtasks", data.payload)

            const mainSubtask = [...data.payload.data].map((row, index) => {
                return ({
                    task_id: row.subcategory.compliance_id,
                    task_name: row.subcategory.compliance_name,
                    assigne_id: row.assigne_id
                })
            })
            setmainSubtask(mainSubtask)

        })

    }
    
    const ConfirmDialog = (props) => {
         
          return(
              <Dialog
                  sx={Workplacetheme.deleteWorkSpaceDialog}
                  isOpen={showConfirmDialog}
                  onDismiss={() => setshowConfirmDialog(false) }
                  aria-labelledby="header-id"
                  initialFocusRef={initialFocusRef}
              >
                  <Dialog.Header id="header-id">
                      <Text sx={Workplacetheme.scheduleDalogeHeading}>Are you sure you want to delete organization?</Text>
                      
                  </Dialog.Header>

                  <Box sx={Workplacetheme.deleteWorkplaceDialogContentBox}>

                      <Text sx={Workplacetheme.createSpaceDialodBoxText}
                      style={{
                        color:'#121212'
                      }}
                      >Please note that all associated information, documents, task history and reports have
                          been permanently deleted and cannot be undone.</Text>

                      <Box
                          sx={Workplacetheme.deleteworkplaceDialogFooterLine}
                          borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
                  </Box>

                  <Box
                      ml={24}
                      mr={24}
                      display="flex"
                      flexDirection="row"
                      justifyContent={"right"}
                  >

                      <Button variant="invisible"
                          sx={Workplacetheme.deleteworkplaceDialogFooterBox.cancelBtn}
                          onClick={() => setshowConfirmDialog(false)}
                      >Cancel</Button>
                      <Button
                          ref={initialFocusRef}
                          variant="outline"
                          sx={{
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            position:'relative',
                            margin:'9px 0px 0px 8px',
                            height:32,
                            width:73,
                            fontFamily: 'Segoe UI',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            background:'#0965CE',
                            color:'#FFFFFF'
                          }}
                         
                          onClick={() =>{
                            updateComplianceDataIndb("")
                            updateMainSubtaskInDb("")
                          }}
                      >Save</Button>


                  </Box>





              </Dialog>
          )
    }
  


    React.useEffect(() => {


        setamountPayable(props?.data?.amountPayable)
        setsubtaskArray(props?.subtasks || [])
        setcomplianceNameInput(props?.data?.compliance_name)
        setselectedCategory(props?.catData?.category_id)
        setserviceId(props?.serviceId)
        getComplianceSubtasksFromDb(props?.serviceId)
        getZohoAuthFromDb("")
        getSpaceCollaboratorFromDb("")
        setselectedAssigne(props?.selectedAssigne)

        if (props?.data?.filingPeriod !== "" && props?.data?.filingPeriod !== undefined) {
            setselectedFilingPeriod(props?.data?.filingPeriod)
        } else {
            setselectedFilingPeriod("")
        }

        if (props?.data?.billing_type !== "" && props?.data?.billing_type !== undefined) {
            setselectedBillingType(props?.data?.billing_type)
        } else {
            setselectedBillingType("")
        }

        if (props?.data?.remindMeType !== "" && props?.data?.remindMeType !== undefined) {

            setselectedRemindMeType(props?.data?.remindMeType)

        } else {
            setselectedRemindMeType("")
        }


        if (props?.data?.due_date !== "" && props?.data?.due_date !== undefined) {
            setdueDate(props?.data?.due_date)
        } else {

        }

    }, [props])


     return(<Box sx={{
           position:'absolute',
           left:'26px',
           top:'115px',
           right:'61px',
           height:'fit-content',
           minHeight:'793px',
           maxHeight:'fit-content',
           display:'flex',
           flexDirection:'column',
           overflowX:'hidden',
           overflowY:'hidden',
           background:'#FFFFFF',
           borderBottom:'1px solid #E0E0E0'
     }}

     onClick={()=>{
          
           if(enableSaveBtn===true){

           }else{
              setenableSaveBtn(true)
           }
         
     }} 
     
     >

         <Box sx={{
             position: 'relative',
             margin: '17px 0px 0px 25px',
             minHeight: '20px',
             display: 'flex',
             gap:'24px',
             width:'fit-content'
         }}
       
         >

             {props?.complianceType?.map((row,index)=>{

                   return(
                       <FormControl >
                           <Radio value={row.id} name={row.name} checked={selectedCompliance === row.id ? true : false} onChange={() => { setselectedCompliance(row.id) }} />
                           <FormControl.Label sx={{ fontFamily: 'Segoe UI', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '20px', color: '#24292E' }}>{row.name}</FormControl.Label>
                       </FormControl>
                   )

             })}
           

            </Box>

              
         
         <Box sx={{
             position: 'relative',
             margin: '20px 0px 0px 25px',
             width: '716px',
             height: '40px',
             minWidth: '716px',
             maxWidth: '716px',
             minHeight: '40px',
             maxHeight: '40px',
             display: 'flex',

         }}
             onMouseEnter={() => {
                 setcomplianceNameInputHover(true)
             }}
             onMouseLeave={() => {
                 setcomplianceNameInputHover(false)
             }}

         >

             <TextInput

                 sx={{
                     width: '704px',
                     height: '40px',
                     minWidth: '704px',
                     maxWidth: '704px',
                     minHeight: '40px',
                     maxHeight: '40px',
                     display: 'flex',
                     fontFamily: 'Segoe UI', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '19px', color: '#121212',
                     background: enableComplianceName === true ? '#F6F8FA' : '#ffffff'
                 }}
                 onClick={() => setenableComplianceName(false)}
                 onBlur={() => setenableComplianceName(true)}
                 value={complianceNameInput}
                 validationStatus={complianceNameInputError}
                 onChange={(e) => {
                     setcomplianceNameInput(e.target.value)
                     setcomplianceNameInputError("")
                 }}
                 placeholder="Provided compliance / Services name"
                 trailingVisual={() => complianceNameInputHover === true ? <Box style={{ cursor: 'pointer' }} onClick={() => {
                     setenableComplianceName(false)
                     setcomplianceNameInput("")
                 }} ><PencilIcon /></Box>



                     : ""}
             />


             

         </Box>
           

         <ActionMenu>
             <ActionMenu.Anchor>
                 <IconButton
                     sx={{
                         position: 'absolute',
                       //  right: '410px',
                         left:'750px',
                         top: '65px',
                         position: 'absolute',
                         border: 'none',
                         boxShadow: 'none',
                         background: 'transparent',

                     }}
                     icon={() => <KebabHorizontalIcon />}
                 />
             </ActionMenu.Anchor>
             <ActionMenu.Overlay>

                 <ActionList
                     sx={{
                         width: '280px',
                     }}
                 >
                     {spaceCollaborators
                         .map((row, index) => {

                             return (
                                 <ActionList.Item
                                     sx={{
                                         paddingLeft: '5px',
                                         paddingRight: '0px',
                                         fontFamily: 'Segoe UI'
                                     }}

                                     onSelect={() => {
                                         
                                       
                                         if (selectedAssigne === "" || selectedAssigne === null) {
                                             setselectedAssigne(row.user_id)
                                         } else {
                                            
                                              Toaster.create({ position: Position.TOP }).show({ message: "Already Assigned", intent: Intent.PRIMARY });
                                        

                                         }


                                     }}
                                 >
                                     <ActionList.LeadingVisual>

                                         {
                                             row.user_image_url === "" || row.user_image_url == null ?
                                                 <FeedPersonIcon size={20} fill="#586069" /> :
                                                 <Avatar src={row.user_image_url} />
                                         }


                                     </ActionList.LeadingVisual>
                                     {row.user_first_name + " " + row.user_last_name}
                                     <ActionList.Description
                                         variant="block"
                                     >
                                         {row.user_email}
                                     </ActionList.Description>
                                 </ActionList.Item>

                             )

                         })}
                 </ActionList>

             </ActionMenu.Overlay>

         </ActionMenu>  


         
         

          

         

         <Box sx={{
            display:'flex',
            flexDirection:'row',
            gap:'10px',
            position:'relative',
            margin:'20px 0px 0px 25px',
            minWidth:'718px',
            maxWidth:'718px',
            width:'718px',
            alignItems:'center',
            minHeight:'40px',
            maxHeight:'40px',
            height:'40px'
         }}>

             <ActionMenu >
                 <ActionMenu.Button
                 sx={{
                    display:'flex',
                    alignItems:'center',
                    width:'246px',
                    minWidth:'246px',
                    maxWidth:'246px',
                    minHeight:'40px',
                    maxHeight:'40px',
                    height:'40px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'400',
                    fontSize:'14px',
                    lineHeight:'20px',
                    color:'#24292F',
                    paddingLeft:'20px',
                    
                 }}
                 >
                  <Text sx={{
                     display:'flex',
                     minWidth:'176px',
                     maxWidth:'176px',
                     width:'176px',
                     textOverflow: 'ellipsis',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                    }}>
                        
                         {props?.categories?.filter((cData)=> cData?.category_id===selectedCategory)[0]?.category_name} 
                    </Text>
                 </ActionMenu.Button>

                 <ActionMenu.Overlay sx={{position:'relative',maxWidth:'246px'}}>
                     <ActionList sx={{
                         display:'flex',
                         flexDirection:'column',
                         alignItems:'center',
                         margin:'8px',
                         fontFamily:'Segoe UI',
                         fontStyle:'normal',
                         fontWeight:'400',
                         fontSize:'14px',
                         lineHeight:'20px',
                         color:'#24292F',
                        
                     }}>
                        
                         {props?.categories.map((row,index)=>{
                               return(
                                  <ActionList.Item
                                  onSelect={()=>{
                                     setselectedCategory(row.category_id)
                                  }}
                                  >
                                     <Text sx={{display:'flex',width:'214px',minWidth:'214px',maxWidth:'214px',  textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden',position:'relative'}}> 
                                    {row?.category_name}
                                    </Text>
                                    </ActionList.Item>
                               )
                         })}
                        
                     </ActionList>
                 </ActionMenu.Overlay>
             </ActionMenu>

             <Select
                 block
                 placeholder="Select filing period"
                 style={{
                     width: '248px',
                     height: '40px',
                     minWidth: '248px',
                     maxWidth: '248px',
                     minHeight: '40px',
                     maxHeight: '40px',
                     fontFamily: 'Segoe UI',
                     fontStyle: 'normal',
                     fontWeight: '400',
                     fontSize: '14px',
                     lineHeight: '20px',
                     color: '#24292F',
                 }}
                 onChange={(e) => {
                     onSetCalanderDateRange(e.target.value)
                 }}
             >
                 {props?.filingPeriods.map((row, index) => {
                     return (
                         <Select.Option value={row.id+"|"+row.name} selected={row.id === selectedFilingPeriod}>{row.name}</Select.Option>
                     )
                 })}

             </Select>

            
             <TextInput
                disabled={selectedCompliance==="bf1ef6f2-d4d1-4f8c-8558-3bcec89b8efa"?false:true}
             sx={{
                width:'204px',
                minWidth:'204px',
                maxWidth:'204px',
                minHeight:'40px',
                maxHeight:'40px',
                height:'40px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontWeight:'400',
                fontSize:'14px',
                lineHeight:'20px',
                color:'#24292F',
             }}
             placeholder="Due date"
             onChange={(e)=>{
                
                dueDatecalandarRef.current.showPicker()
             }}
             onClick={()=>{
                dueDatecalandarRef.current.showPicker()
             }}
             value={dueDate!==null&&dueDate!==""?
             new Date(dueDate).getDate() + "/" + (new Date(dueDate).getMonth() + 1) + "/" + new Date(dueDate).getFullYear()
             :''}
             />
             <input
                 type={"date"}
                 ref={dueDatecalandarRef}
                 onChange={(e) => {
                     if (e.target.value === "" || e.target.value == null) { 
                        setdueDate("")
                        selectedRemindMeType("") 
                     }
                     else { 

                        if(dueDate===""){
                              setselectedRemindMeType(props?.remindMeType[0]?.id)
                        }

                        setdueDate(new Date(e.target.value))

                     }
                 }}
                 style={{
                    
                     position: 'absolute',
                     top:'0px',
                     right:'0px',
                     bottom: '25px',
                     height: '38px',
                     width: '28%',
                     zIndex: '0',
                     visibility: 'hidden'
                 }}
                 min={dateRangeSelection.minDate}
                 max={dateRangeSelection.maxDate}
             />


             

            
         </Box>


         <Box sx={{
             display: 'flex',
             flexDirection: 'row',
             gap: '10px',
             position: 'relative',
             margin: '20px 0px 0px 25px',
             minWidth: '718px',
             maxWidth: '718px',
             width: '718px',
             alignItems: 'center',
             minHeight: '40px',
             maxHeight:'40px',
             height:'40px',
         }}>

             <Box sx={{
                 display:'flex',
                 width: '246px',
                 height: '40px',
                 minWidth: '246px',
                 maxWidth: '246px',
                 minHeight: '40px',
                 maxHeight: '40px',
             }}>

                 <Select
                     block
                     placeholder="Billing Type"
                     style={{
                         width: '246px',
                         height: '40px',
                         minWidth: '246px',
                         maxWidth: '246px',
                         minHeight: '40px',
                         maxHeight: '40px',
                         fontFamily: 'Segoe UI',
                         fontStyle: 'normal',
                         fontWeight: '400',
                         fontSize: '14px',
                         lineHeight: '20px',
                         color: '#24292F',
                     }}
                     onChange={(e)=>{
                          setselectedBillingType(e.target.value)
                     }}
                     >
      
                     {props?.billingTypes?.map((row,index)=>{
                        return(
                            <Select.Option value={row.id} selected={row.id===selectedBillingType}>{row.name}</Select.Option>
                        )
                     })}

                 </Select>

             </Box>

            

             <TextInput
             sx={{
                width:'244px',
                minWidth:'244px',
                maxWidth:'244px',
                minHeight:'40px',
                maxHeight:'40px',
                height:'40px',
                fontFamily:'Segoe UI',
                fontStyle:'normal',
                fontWeight:'400',
                fontSize:'14px',
                lineHeight:'20px',
                color:'#24292F',
             }}
             placeholder="amount payable (Optional)"
             value={amountPayable}
             onChange={(e)=>{

                  if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                     setamountPayable(e.target.value)
                  }
                 
             }}
             />


             <Box sx={{
                 display: dueDate!==""&&dueDate!==null?'flex':'none',
                 width: '204px',
                 height: '40px',
                 minWidth: '204px',
                 maxWidth: '204px',
                 minHeight: '40px',
                 maxHeight: '40px',
             }}>

                 <Select
                     block
                     placeholder="Remind Me"
                     style={{

                         width: '204px',
                         height: '40px',
                         minWidth: '204px',
                         maxWidth: '204px',
                         minHeight: '40px',
                         maxHeight: '40px',
                         fontFamily: 'Segoe UI',
                         fontStyle: 'normal',
                         fontWeight: '400',
                         fontSize: '14px',
                         lineHeight: '20px',
                         color: '#24292F',
                     }}
                     onChange={(e) => {
                         setselectedRemindMeType(e.target.value)
                     }}
                     value={selectedRemindMeType} 
                   
                 >

                     {props?.remindMeType?.map((row, index) => {
                         return (

                             <Select.Option
                                 value={row.id}
                                 selected={row.id === selectedRemindMeType}
                             >{row.name}</Select.Option>
                         )
                     })}

                 </Select>

             </Box>

 
         </Box>

         <Box sx={{
             display: isZohoInstalled ? 'flex' : 'none',
             position: 'relative',
             margin: '20px 0px 0px 25px',
             fontFamily: 'Segoe UI',
             fontStyle: 'normal',
             fontWeight: '400',
             fontSize: '14px',
             lineHeight: '20px',
         }}>
             <FormControl>

                 <Checkbox value="true" checked={isAddtoZohoItem} onChange={(e) => { setisAddtoZohoItem(e.target.checked) }} />
                 <FormControl.Label sx={{ fontWeight: '400' }}>Add this compliance as a Zoho item.</FormControl.Label>
             </FormControl>
         </Box>

         <Box sx={{
            display:'flex',
            flexDirection:'row',
            position: 'relative',
            margin: '38px 0px 0px 25px',
            gap:'3px'
            }}>

              <Text sx={{
                   fontFamily:'Segoe UI',
                   fontStyle:'normal',
                   fontWeight:'600',
                   fontSize:'14px',
                   lineHeight:'18px',
                   color:'#24292E',
              }}>
               Subtask: 
              </Text>
              <Text sx={{
                   fontFamily:'Segoe UI',
                   fontStyle:'normal',
                   fontWeight:'400',
                   fontSize:'14px',
                   lineHeight:'18px',
                   color:'#24292E',
              }}>
               This stage could include the main tasks that need to be completed.
              </Text>

         </Box>

        
         <Box sx={{
             position: 'relative',
             margin: mainSubtask.length > 0 ? '30px 0px 15px 25px' : '30px 0px 0px 25px',
             minWidth: '600px',
             maxWidth: '600px',
             width: '600px',
             minHeight: `${(40 * mainSubtask.length) + (17 * (mainSubtask.length - 1))}px`,
             maxHeight: `${(40 * mainSubtask.length) + (17 * (mainSubtask.length - 1))}px`,
             height: `${(40 * mainSubtask.length) + (17 * (mainSubtask.length - 1))}px`,
         }}>
         <DragDropContext onDragEnd={onDragEndMainSubtask}>  
            <Droppable droppableId="droppable" >  
                {(provided, snapshot) => (  
                    <Box 
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'15px',
                             
                        }} 
                        {...provided.droppableProps}  
                        ref={provided.innerRef}  
                    >  
                        {mainSubtask.map((row, index) => (  
                            <Draggable 
                            draggableId={row.task_id} 
                            index={index}
                            key={row.task_id}
                            >  
                                {(provided, snapshot) => (  
                                   <Box  
                                      sx={{
                                        
                                      }}
                                     >  
                                        <Box
                                              ref={provided.innerRef}  
                                              {...provided.draggableProps}  
                                              {...provided.dragHandleProps}  
                                            
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'row',
                                                minWidth:'680px',
                                               // background:'red'
                                              }}
                                            onClick={() => {
                                                setfocusmainSubtask(row.task_id)
                                            }}
                                            onBlur={()=>{
                                                setfocusmainSubtask('')
                                                
                                            }}
                                            onMouseEnter={()=>{
                                                setmainsubtaskHover(row.task_id)
                                            }}
                                            onMouseLeave={()=>{
                                                setmainsubtaskHover('')
                                            }}
                                            
                                        >
                                            <TextInput
                                                autoFocus={row.task_id === focusmainSubtask ? true : false}
                                                contrast={row.task_id === focusmainSubtask ? false : true}
                                                sx={{
                                                    width: '575px',
                                                    minWidth: '575px',
                                                    maxWidth: '575px',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    
                                                }}
                                                value={row.task_name}
                                                onChange={(e) => onChangeMainSubtaskInput(row.task_id, e)}
                                                placeholder="Input placeholder"
                                                leadingVisual={() => row.task_id === focusmainSubtask ? "" : <Box><GrabberIcon /></Box>}
                                                trailingVisual={() => row.task_id === mainsubtaskHover&&row.task_id!==focusmainSubtask ? 
                                                <Box style={{ cursor: 'pointer' }}><PencilIcon /></Box> : ""}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setfocusmainSubtask("")
                                                        setmainsubtaskHover("")
                                                    }
                                                }}
                                            />

                                            {row.task_id === focusmainSubtask ? 
                                            

                                            <> 
                                            <Button variant="outline"
                                                sx={{
                                                    position: 'relative',
                                                    margin: 'auto 0px auto 15px',
                                                    background: '#0965CE',
                                                    color: '#ffffff',
                                                    width: '47px',
                                                    height: '28px',
                                                    minWidth: '47px',
                                                    maxWidth: '47px',
                                                    minHeight: '28px',
                                                    maxHeight: '28px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Segoe UI',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    fontWeight: '600',

                                                }}

                                            >
                                                Save
                                            </Button> 

                                                    <Button
                                                        sx={{
                                                            position: 'relative',
                                                            margin: 'auto 0px auto 5px',
                                                            width: '30px',
                                                            height: '30px',
                                                            minWidth: '30px',
                                                            maxWidth: '30px',
                                                            minHeight: '30px',
                                                            maxHeight: '30px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontSize: '12px',
                                                            lineHeight: '18px',
                                                            fontWeight: '600',
                                                            border: 'none'
                                                        }}
                                                        onClick={() => {
                                                            setmainSubtask((prevState) => {
                                                                const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                                return newState
                                                            })
                                                        }}
                                                    >
                                                        <XIcon/>
                                                    </Button>

                                                    
 

                                            </> 

                                            : 
                                            <Box
                                                 sx={{
                                                    position: 'relative',
                                                    margin: 'auto 0px auto 2px',
                                                    height: '30px',
                                                    width: '30px',
                                                    minWidth: '30px',
                                                    maxWidth: '30x',
                                                    minHeight: '30px',
                                                    maxHeight: '30px',
                                                    visibility: mainsubtaskHover === row.task_id ? 'visible' : 'hidden',
                                                    
                                                }}
                                                
                                             >

                                                <IconButton
                                                    sx={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        background: '#ffffff'
                                                    }}
                                                    icon={() => <XIcon />}
                                                    onClick={() => {
                                                        setmainSubtask((prevState) => {
                                                            const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                            return newState
                                                        })
                                                    }}
                                                />


                                            </Box>

                                            }

                                            
                                            <ActionMenu>
                                                <ActionMenu.Anchor>
                                                    <IconButton
                                                        sx={{
                                                            visibility: mainsubtaskHover === row.task_id ? 'visible' : 'hidden',
                                                            marginLeft:'20px', 
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            background: 'transparent',
                                                            

                                                        }}
                                                        icon={() => <KebabHorizontalIcon />}
                                                    />
                                                </ActionMenu.Anchor>
                                                <ActionMenu.Overlay>

                                                    <ActionList
                                                        sx={{
                                                            width: '280px',
                                                        }}
                                                    >
                                                        {spaceCollaborators
                                                            .map((row2, index2) => {

                                                                return (
                                                                    <ActionList.Item
                                                                        sx={{
                                                                            paddingLeft: '5px',
                                                                            paddingRight: '0px',
                                                                            fontFamily: 'Segoe UI'
                                                                        }}

                                                                        onSelect={() => {


                                                                            if (row.assigne_id === "" || row.assigne_id === null) {
                                                                                // alert(row.assigne_id)

                                                                                    setmainSubtask((prevState)=>{
                                                                                          const newState = prevState.map((tData,tindex)=>{
                                                                                              
                                                                                                if(tData.task_id===row.task_id){
                                                                                                     return{...tData,assigne_id:row2.user_id}
                                                                                                }else{
                                                                                                     return{...tData}
                                                                                                }

                                                                                          }) 
                                                                                          return newState
                                                                                    })


                                                                            } else {
                                                                                Toaster.create({ position: Position.TOP }).show({ message: "Already Assigned", intent: Intent.PRIMARY });
                                                                            }


                                                                        }}
                                                                    >
                                                                        <ActionList.LeadingVisual>

                                                                            {
                                                                                row2.user_image_url === "" || row2.user_image_url == null ?
                                                                                    <FeedPersonIcon size={20} fill="#586069" /> :
                                                                                    <Avatar src={row2.user_image_url} />
                                                                            }


                                                                        </ActionList.LeadingVisual>
                                                                        {row2.user_first_name + " " + row2.user_last_name}
                                                                        <ActionList.Description
                                                                            variant="block"
                                                                        >
                                                                            {row2.user_email}
                                                                        </ActionList.Description>
                                                                    </ActionList.Item>

                                                                )

                                                            })}
                                                    </ActionList>

                                                </ActionMenu.Overlay>

                                            </ActionMenu> 
                                            

                                        </Box>



                                   </Box>  
                                )}  
                            </Draggable>  
                        ))}  
                    </Box>  
                )}  
            </Droppable>  
        </DragDropContext>
         </Box>

         <Button variant="invisible"
         sx={{
            position: 'relative',
            margin: '0px 0px 0px 25px',
            width:'111px',
            height:'32px',
            minWidth:'111px',
            minHeight:'32px',
            maxWidth:'111px',
            maxHeight:'32px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            fontFamily:'Segoe UI',
            fontStyle:'normal',
            fontWeight:'600',
            fontSize:'14px',
            lineHeight:'20px',
            
         }}
         onClick={()=>{
            addMainSubtask("")
         }}
         >
         Add Subtask
        </Button> 


         <Box sx={{
             display: 'flex',
             flexDirection: 'row',
             position: 'relative',
             margin: '38px 0px 0px 25px',
             gap: '3px'
         }}>

             <Text sx={{
                 fontFamily: 'Segoe UI',
                 fontStyle: 'normal',
                 fontWeight: '600',
                 fontSize: '14px',
                 lineHeight: '18px',
                 color: '#24292E',
             }}>
                 Todo:
             </Text>
             <Text sx={{
                 fontFamily: 'Segoe UI',
                 fontStyle: 'normal',
                 fontWeight: '400',
                 fontSize: '14px',
                 lineHeight: '18px',
                 color: '#24292E',
             }}>
                 This stage could include the main tasks that need to be completed.
             </Text>

         </Box>

         <Box sx={{
             position: 'relative',
             margin: subtaskArray.length > 0 ? '30px 0px 15px 25px' : '30px 0px 0px 25px',
             minWidth: '600px',
             maxWidth: '600px',
             width: '600px',
             minHeight: `${(40 * subtaskArray.length) + (17 * (subtaskArray.length - 1))}px`,
             maxHeight: `${(40 * subtaskArray.length) + (17 * (subtaskArray.length - 1))}px`,
             height: `${(40 * subtaskArray.length) + (17 * (subtaskArray.length - 1))}px`,


         }}>
             <DragDropContext onDragEnd={onDragEnd}>
                 <Droppable droppableId="droppable" >
                     {(provided, snapshot) => (
                         <Box
                             sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 gap: '15px',

                             }}
                             {...provided.droppableProps}
                             ref={provided.innerRef}
                         >
                             {subtaskArray.map((row, index) => (
                                 <Draggable
                                     draggableId={row.task_id}
                                     index={index}
                                     key={row.task_id}
                                 >
                                     {(provided, snapshot) => (
                                         <Box

                                         >
                                             <Box
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}

                                                 sx={{
                                                     display: 'flex',
                                                     flexDirection: 'row',
                                                     minWidth:'640px',
                                                    
                                                 }}
                                                 onClick={() => {
                                                     setfocusSubtask(row.task_id)
                                                 }}
                                                 onBlur={() => {
                                                     setfocusSubtask('')

                                                 }}
                                                 onMouseEnter={() => {
                                                     setsubtaskHover(row.task_id)
                                                 }}
                                                 onMouseLeave={() => {
                                                     setsubtaskHover('')
                                                 }}

                                             >
                                                 <TextInput
                                                     autoFocus={row.task_id === focusSubtask ? true : false}
                                                     contrast={row.task_id === focusSubtask ? false : true}
                                                     sx={{
                                                         width: '575px',
                                                         minWidth: '575px',
                                                         maxWidth: '575px',
                                                         minHeight: '40px',
                                                         maxHeight: '40px',
                                                         height: '40px',
                                                         fontFamily: 'Segoe UI',
                                                         fontStyle: 'normal',
                                                         fontWeight: '400',
                                                         fontSize: '14px',
                                                         lineHeight: '20px',

                                                     }}
                                                     value={row.task_name}
                                                     onChange={(e) => onChangeSubtaskInput(row.task_id, e)}
                                                     placeholder="Input placeholder"
                                                     leadingVisual={() => row.task_id === focusSubtask ? "" : <Box  //  sx={{ cursor: 'pointer' }}
                                                      >
                                                         <GrabberIcon />
                                                     </Box>}
                                                     trailingVisual={() => row.task_id === subtaskHover && row.task_id !== focusSubtask ?

                                                         <Box style={{ cursor: 'pointer' }}><PencilIcon /></Box> : ""}
                                                     onKeyDown={(e) => {
                                                         if (e.key === "Enter") {
                                                             setfocusSubtask("")
                                                             setsubtaskHover("")

                                                         }
                                                     }}
                                                 />

                                                 {row.task_id === focusSubtask ? 
                                                 
                                                 <>
                                                 <Button variant="outline"
                                                     sx={{
                                                         position: 'relative',
                                                         margin: 'auto 0px auto 15px',
                                                         background: '#0965CE',
                                                         color: '#ffffff',
                                                         width: '47px',
                                                         height: '28px',
                                                         minWidth: '47px',
                                                         maxWidth: '47px',
                                                         minHeight: '28px',
                                                         maxHeight: '28px',
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'center',
                                                         fontFamily: 'Segoe UI',
                                                         fontStyle: 'normal',
                                                         fontSize: '12px',
                                                         lineHeight: '18px',
                                                         fontWeight: '600',

                                                     }}

                                                 >
                                                     Save
                                                 </Button>

                                                 <Box

                                                     sx={{
                                                         position: 'relative',
                                                         margin: 'auto 0px auto 2px',
                                                         height: '30px',
                                                         width: '30px',
                                                         minWidth: '30px',
                                                         maxWidth: '30x',
                                                         minHeight: '30px',
                                                         maxHeight: '30px',
                                                         
                                                     }}>

                                                     <IconButton
                                                         sx={{

                                                             border: 'none',
                                                             boxShadow: 'none',
                                                             background: '#ffffff'
                                                         }}
                                                         icon={() => <XIcon />}
                                                         onClick={() => {
                                                             setsubtaskArray((prevState) => {
                                                                 const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                                 return newState
                                                             })
                                                         }}
                                                     />


                                                 </Box>


                                                 </>
                                                 
                                                 : <Box

                                                     sx={{
                                                         position: 'relative',
                                                         margin: 'auto 0px auto 2px',
                                                         height: '30px',
                                                         width: '30px',
                                                         minWidth: '30px',
                                                         maxWidth: '30x',
                                                         minHeight: '30px',
                                                         maxHeight: '30px',
                                                         visibility: subtaskHover === row.task_id ? 'visible' : 'hidden'
                                                     }}>

                                                     <IconButton
                                                         sx={{

                                                             border: 'none',
                                                             boxShadow: 'none',
                                                             background: '#ffffff'
                                                         }}
                                                         icon={() => <XIcon />}
                                                         onClick={() => {
                                                             setsubtaskArray((prevState) => {
                                                                 const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                                 return newState
                                                             })
                                                         }}
                                                     />


                                                 </Box>}





                                             </Box>
                                         </Box>
                                     )}
                                 </Draggable>
                             ))}
                         </Box>
                     )}
                 </Droppable>
             </DragDropContext>
         </Box>

         <Button variant="invisible"
             sx={{
                 position: 'relative',
                 margin: '0px 0px 0px 25px',
                 width: '111px',
                 height: '32px',
                 minWidth: '111px',
                 minHeight: '32px',
                 maxWidth: '111px',
                 maxHeight: '32px',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 fontFamily: 'Segoe UI',
                 fontStyle: 'normal',
                 fontWeight: '600',
                 fontSize: '14px',
                 lineHeight: '20px',

             }}
             onClick={() => {
                  addSubtask("")
             }}
         >
             Add Todo
         </Button> 


         <Box sx={{
             position: 'relative',
             margin: '0px 0px 0px 25px',
             minHeight: '100px',

         }}>

         </Box>

          
         {enableSaveBtn && (
             <Button variant="outline"
                 sx={{
                     position: 'absolute',
                     right: '15px',
                     top: '17px',
                     background: '#0965CE',
                     color: '#ffffff',
                     width: '50px',
                     height: '28px',
                     minWidth: '50px',
                     maxWidth: '50px',
                     minHeight: '28px',
                     maxHeight: '28px',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     fontFamily: 'Segoe UI',
                     fontStyle: 'normal',
                     fontSize: '12px',
                     lineHeight: '18px',
                     fontWeight: '600',

                 }}
                 onClick={()=>{

                      if(complianceNameInput===""){
                        setcomplianceNameInputError("error")
                      }else{
                         setshowConfirmDialog(true)
                      }
                      
                      
                 }}
             >
                 Save
             </Button>
         )}  
 
         {ConfirmDialog("")}
           
     </Box>)
}
