import React from "react";
import { Box, Text, Button, Dialog, Heading, IconButton, Spinner } from "@primer/react"
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import { useDispatch, useSelector } from "react-redux";
import { UnderlineNav } from '@primer/react/drafts'
import { ArrowLeftIcon, ArrowRightIcon, BookIcon } from "@primer/octicons-react";
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { getAppIntegration, installAppIntegration, syncGoogleDriveFolder } from "../redux/features/spaceSlice";
import { deleteDropboxAuth, deleteGoogleCalanderAuth, deleteGoogleDriveAuth, deleteGoogleMailAuth, deleteGoogleMeetAuth, deleteZohoAuth, getDropboxAuth, getGoogleAuth, getGoogleCalanderAuth, getGoogleDriveAuth, getGoogleMailAuth, getZohoAuth, postDropboxAuth, postGoogleAuth, postGoogleCalanderAuth, postGoogleDriveAuth, postGoogleMailAuth, postZohoAuth } from "../redux/features/taskSlice";
const appSlider = "https://d2fcqkt9srr3ti.cloudfront.net/Apps/appslider.svg"

const defaultImages = [
    { id: 1, src: appSlider, alt: 'Image 1' },
    { id: 2, src: appSlider, alt: 'Image 2' },
    { id: 3, src: appSlider, alt: 'Image 3' }
]

const zohoInvSliders = [
    { id: 1, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/ZohoInvoice/InvoiceBanner.webp', alt: 'Image 1' },
    { id: 2, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/ZohoInvoice/DashboardImg.png', alt: 'Image 2' },
    { id: 3, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/ZohoInvoice/InvoiceImg.png', alt: 'Image 3' }

]

const googleMeetSlider = [
    { id: 1, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/GoogleMeet/GoogleMeetSlider1.png', alt: 'Image 1' },
    { id: 2, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/GoogleMeet/GoogleMeetSlider2.webp', alt: 'Image 2' },
    { id: 3, src: 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/Apps/GoogleMeet/GoogleMeetSlider3.jpg', alt: 'Image 3' }
]

const googleDriveSlider = [
    
]

const googleCalanderSlider = [ ]

const gmailSlider = []

const dropBoxSlider = [

]


function Apps(props) {

    const gapi = window.gapi
    const google = window.google

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const [selectedIndex, setselectedIndex] = React.useState(0)
    const defaultDialogFocus = React.createRef()
    const [appTabs, setappTabs] = React.useState([{ id: '1', name: 'All apps' }, { id: '2', name: 'Installed' }])
    const [showInstallationDialog, setshowInstallationDialog] = React.useState(false)

    const [appArray, setappArray] = React.useState([])
    const [installedApp, setinstalledApp] = React.useState([])


    const [images, setimages] = React.useState([
        { id: 1, src: appSlider, alt: 'Image 1' },
        { id: 2, src: appSlider, alt: 'Image 2' },
        { id: 3, src: appSlider, alt: 'Image 3' }
    ])

    const [slideIndex, setSlideIndex] = React.useState(0);
    const [showAppLoading, setshowAppLoading] = React.useState(false)

    const [selectedAppId, setselectedAppId] = React.useState("")

    //zoho auth parameters-------
    const [externalPopup, setExternalPopup] = React.useState(null);
    const [zohoaccessCode, setzohoaccessCode] = React.useState('')
    const [stopLooping, setstopLooping] = React.useState(false)
    //--------------------
    //google meet parameters---------
    const [dropboxAuthPopup, setdropboxAuthPopup] = React.useState(null)
    const [googleMeetAuthPopup, setgoogleMeetAuthPopup] = React.useState(null)
    const [googleCalanderAuthPopup, setgoogleCalanderAuthPopup] = React.useState(null)
    const [googleMailAuthPopup, setgoogleMailAuthPopup] = React.useState(null)
    const [googleDriveAuthPop, setgoogleDriveAuthPop] = React.useState(null)



    const prevSlide = () => {

        setSlideIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
    };

    const nextSlide = () => {

        setSlideIndex((prevIndex) => (prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1));

    };


    const handleInstallApp = (props) => {

        setshowAppLoading(true)

        dispatch(installAppIntegration({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            appId: selectedAppId
        })).then((data) => {

            if (data.payload.message === "USER ALREADY INSTALLED APPS") {

                setTimeout(() => {

                    setshowAppLoading(false)
                    setshowInstallationDialog(false)
                    Toaster.create({ position: Position.TOP }).show({ message: "Already installed.", intent: Intent.PRIMARY });


                }, 1000)


            } else if (data.payload.message === "SUCCESSFULLY INSTALLED APPS") {

                setTimeout(() => {

                    setshowAppLoading(false)
                    setshowInstallationDialog(false)
                    if (selectedAppId === "1a30862b-400e-47ac-bb8a-e3fbd5ec6139") {
                        getZohoAuthFromDb("")
                    } else if (selectedAppId === "796df595-030c-4ca2-9b71-42133730a73d") {
                        authGoogleMeet("")
                    } else if (selectedAppId === "ca79a33b-e298-4b26-9a17-b4f34dd9e8b7") {
                        authGooglecalander("")
                    } else if (selectedAppId === "f2dfb70b-23c5-4978-9bec-9186c55cf04c") {
                        authGoogleMail("")
                    } else if (selectedAppId === "ac173e0e-8af0-4381-82b4-e638b54af43c") {

                        authGoogleDrive("")
                    } else if (selectedAppId === "2f02a0f5-964d-4393-8eb5-bc20d41ccdae") {

                        authDropBox("")
                    }


                    Toaster.create({ position: Position.TOP }).show({ message: "Successfully installed.", intent: Intent.SUCCESS });

                }, 3000)


            }

            getAppIntegrationFromDb("")



        })







    }


    //------------- Drop box Section -------------------

    const handleDisconnectDropboxAuth = async (props) => {

        dispatch(deleteDropboxAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteDropboxAuth", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })


    }

    const authDropBox = async (props) => {

        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `DropboxAuth`;

        const APP_KEY = 'r1ex80p99zrn57f';
        const redirectUrl = window.location.origin
        const scope = ''
        const prompt = 'consent'
        const forceReauthentication = true
        // const authurl = `https://www.dropbox.com/oauth2/authorize?client_id=${APP_KEY}&redirect_uri${redirectUrl}&prompt=${prompt}&force_reauthentication=${forceReauthentication}&token_access_type=offline&response_type=code`


        const authurl = `https://www.dropbox.com/oauth2/authorize?client_id=${APP_KEY}&redirect_uri=${redirectUrl}&response_type=code&force_reauthentication=${forceReauthentication}&token_access_type=offline`
        const dropboxAuthWindow = window.open(authurl, title, `width=${width},height=${height},left=${left},top=${top}`)
        setdropboxAuthPopup(dropboxAuthWindow)

    }

    const dropBoxAccessToken = async (props) => {


        const clientId = 'r1ex80p99zrn57f'
        const clientSecret = 'tp2l1u3cfgsxyc8'
        const redirectUrl = window.location.origin

        const url = 'https://api.dropbox.com/oauth2/token';
        const data = new URLSearchParams();
        data.append('code', props);
        data.append('client_id', clientId);
        data.append('client_secret', clientSecret);
        data.append('redirect_uri', redirectUrl);
        data.append('grant_type', 'authorization_code');
        // data.append('access_type','offline')


        const authTokens = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json())


        const refreshToken = authTokens?.refresh_token
        console.log("authTokens drop box", refreshToken)
        if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {



            dispatch(postDropboxAuth({
                userId: userId,
                userEmail: userEmail,
                authCode: { ...authTokens, code: props },
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postDropboxAuth", data.payload)

            })


        }




    }


    const getDropboxAuthFromDb = (props) => {


        dispatch(getDropboxAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getGoogleMailAuth", data.payload)

            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectDropboxAuth("")
                    }
                });

            } else {

                authDropBox("")

            }




        })





    }








    //--------------------------------------------------


    //------------- Google Drive Section ---------------

    const handleDisconnectGoogleDriveAuth = async (props) => {



        dispatch(deleteGoogleDriveAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteGoogleDriveAuth", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })


    }

    const authGoogleDrive = async (props) => {

        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `GoogleDriveAuth`;


        const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const redirectUri = window.location.origin
        const SCOPES = 'https://www.googleapis.com/auth/drive';
        const authurl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPES}&access_type=offline&include_granted_scopes=true&response_type=code&state=apps&redirect_uri=${redirectUri}&client_id=${CLIENT_ID}`
        const googleAuthWindow = window.open(authurl, title, `width=${width},height=${height},left=${left},top=${top}`)
        setgoogleDriveAuthPop(googleAuthWindow)

    }

    const getGoogleDriveAccessToken = async (props) => {


        const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const clientSecret = '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'
        const redirectUrl = window.location.origin

        const url = 'https://oauth2.googleapis.com/token';
        const data = new URLSearchParams();
        data.append('code', props);
        data.append('client_id', clientId);
        data.append('client_secret', clientSecret);
        data.append('redirect_uri', redirectUrl);
        data.append('grant_type', 'authorization_code');
        data.append('access_type', 'offline')


        const authTokens = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json())
        const refreshToken = authTokens?.refresh_token
        console.log("refreshToken", refreshToken)

        if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {


            dispatch(postGoogleDriveAuth({
                userId: userId,
                userEmail: userEmail,
                authCode: { ...authTokens, code: props },
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postGoogleDriveAuth", data.payload)

            })


        }


    }




    const getGoogleDriveAuthFromDb = (props) => {

        dispatch(getGoogleDriveAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getGoogleMailAuth", data.payload)

            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectGoogleDriveAuth("")
                    }
                });

            } else {

                authGoogleDrive("")

            }




        })




    }






    //--------------------------------------------------

    //------------- Google Mail Section ----------------

    const handleDisconnectGoogleMailAuth = async (props) => {


        dispatch(deleteGoogleMailAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteGoogleMailAuth", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })

    }


    const authGoogleMail = async (props) => {


        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `GoogleMailAuth`;


        const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const redirectUri = window.location.origin
        const SCOPES = 'https://mail.google.com/';
        const authurl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPES}&access_type=offline&include_granted_scopes=true&response_type=code&state=apps&redirect_uri=${redirectUri}&client_id=${CLIENT_ID}`
        const googleAuthWindow = window.open(authurl, title, `width=${width},height=${height},left=${left},top=${top}`)
        setgoogleMailAuthPopup(googleAuthWindow)


    }

    const getGoogleMailAccessToken = async (props) => {


        const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const clientSecret = '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'
        const redirectUrl = window.location.origin

        const url = 'https://oauth2.googleapis.com/token';
        const data = new URLSearchParams();
        data.append('code', props);
        data.append('client_id', clientId);
        data.append('client_secret', clientSecret);
        data.append('redirect_uri', redirectUrl);
        data.append('grant_type', 'authorization_code');
        data.append('access_type', 'offline')


        const authTokens = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json())
        const refreshToken = authTokens?.refresh_token
        console.log("refreshToken", refreshToken)

        if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {



            dispatch(postGoogleMailAuth({
                userId: userId,
                userEmail: userEmail,
                authCode: { ...authTokens, code: props },
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postGoogleMailAuth", data.payload)

            })


        }


    }


    const getGoogleMailAuthFromDb = (props) => {


        dispatch(getGoogleMailAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getGoogleMailAuth", data.payload)

            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectGoogleMailAuth("")
                    }
                });

            } else {

                authGoogleMail("")

            }




        })



    }


    //--------------------------------------------------



    //------------- Google Calander Section ------------

    const handleDisconnectGoogleCalanderAuth = (props) => {

        //handleDisconnectGoogleCalanderAuth
        dispatch(deleteGoogleCalanderAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteGoogleCalanderAuth", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })

    }


    const authGooglecalander = async (props) => {


        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `GoogleCalanderAuth`;


        const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const redirectUri = window.location.origin
        const SCOPES = 'https://www.googleapis.com/auth/calendar';
        const authurl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPES}&access_type=offline&include_granted_scopes=true&response_type=code&state=apps&redirect_uri=${redirectUri}&client_id=${CLIENT_ID}`
        const googleAuthWindow = window.open(authurl, title, `width=${width},height=${height},left=${left},top=${top}`)
        setgoogleCalanderAuthPopup(googleAuthWindow)



    }

    const getGoogleCalanderAccessToken = async (props) => {

        //postGoogleCalanderAuth
        const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const clientSecret = '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'
        const redirectUrl = window.location.origin

        const url = 'https://oauth2.googleapis.com/token';
        const data = new URLSearchParams();
        data.append('code', props);
        data.append('client_id', clientId);
        data.append('client_secret', clientSecret);
        data.append('redirect_uri', redirectUrl);
        data.append('grant_type', 'authorization_code');
        data.append('access_type', 'offline')


        const authTokens = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json())

        // console.log("Google auth tokens",authTokens)
        const refreshToken = authTokens?.refresh_token
        console.log("refreshToken", refreshToken)

        if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {

            dispatch(postGoogleCalanderAuth({
                userId: userId,
                userEmail: userEmail,
                authCode: { ...authTokens, code: props },
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postGoogleCalanderAuth", data.payload)

            })


        }



    }

    const getGoogleCalanderAuthFromDb = (props) => {


        dispatch(getGoogleCalanderAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getGoogleAuth", data.payload)

            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectGoogleCalanderAuth("")
                    }
                });

            } else {

                authGooglecalander("")

            }




        })


    }

    //--------- Google meet section ------------------

    const handleDisconnectGoogleMeetAuth = (props) => {

        dispatch(deleteGoogleMeetAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteGoogleMeetAuth", data.payload)
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })


    }


    //------------------------------------------------
    const authGoogleMeet = async (props) => {

        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `GoogleMeetAuth`;

        const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const redirectUri = window.location.origin
        const SCOPES = 'https://www.googleapis.com/auth/calendar';
        const authurl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPES}&access_type=offline&include_granted_scopes=true&response_type=code&state=apps&redirect_uri=${redirectUri}&client_id=${CLIENT_ID}`
        const googleAuthWindow = window.open(authurl, title, `width=${width},height=${height},left=${left},top=${top}`)
        setgoogleMeetAuthPopup(googleAuthWindow)

    }


    const getGoogleMeetAuthAccessToken = async (props) => {


        const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
        const clientSecret = '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'
        const redirectUrl = window.location.origin  //'http://localhost:3000'

        const url = 'https://oauth2.googleapis.com/token';
        const data = new URLSearchParams();
        data.append('code', props);
        data.append('client_id', clientId);
        data.append('client_secret', clientSecret);
        data.append('redirect_uri', redirectUrl);
        data.append('grant_type', 'authorization_code');
        data.append('access_type', 'offline')


        const authTokens = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json())

        // console.log("Google auth tokens",authTokens)
        const refreshToken = authTokens?.refresh_token
        console.log("refreshToken", refreshToken)

        if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {

            dispatch(postGoogleAuth({
                userId: userId,
                userEmail: userEmail,
                authCode: { ...authTokens, code: props },
                projectId: localStorage.getItem("projectId")
            })).then((data) => {

                console.log("postGoogleAuth", data.payload)

            })


        }






    }

    const getGoogleAuthFromDb = (props) => {


        dispatch(getGoogleAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getGoogleAuth", data.payload)

            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectGoogleMeetAuth("")
                    }
                });

            } else {

                authGoogleMeet("")

            }




        })


    }




    //------- google drive app integration

    const dropBoxoptions = {


        success: async function (files) {



            const fileUrl = files[0].link
            const fileName = files[0].name
            const fileSize = files[0].bytes


            const updateData = {
                ...files[0],
                attachment_file_name: fileName,
                attachment_size: '' + fileSize + '',
                attachment_url: fileUrl,
                creator_id: userId,
                attachment_timestamp: "" + new Date() + "",
                attachment_type: "Dropbox"
            }

            uploadGdriveFolderInDb(updateData)


        },
        cancel: function () {

        },

        linkType: "preview",//"preview", // or "direct"
        folderselect: true, // or true


    };



    const handleAuthenticateZohoInvoice = (props) => {

        const clientId = '1000.UH6XAPYSAAB9KI6KCJ8VRPRVOB368O';
        const clientSecret = 'c462776f5c7c24d127a7c2580a84dcee226dcaec0c';
        const authEndpoint = 'https://accounts.zoho.com/oauth/v2/auth';
        const tokenEndpoint = 'https://accounts.zoho.com/oauth/v2/token';
        const scope = ['ZohoInvoice.invoices.all', 'ZohoInvoice.settings.CREATE', 'ZohoInvoice.contacts.CREATE', 'ZohoInvoice.fullaccess.all'];
        const redirectUri = window.location.origin + '/'  // ['http://localhost:3000/',''+window.location.origin+'/'];   http://localhost:3000/zoho-auth-callback
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const accessType = 'offline'
        const prompt = 'consent'
        const state = 'ZohoAuth'
        const width = 700;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2
        const top = window.screenY + (window.outerHeight - height) / 2.5
        const title = `ZohoAuthentication`;

        const authUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=${accessType}&prompt=${prompt}&state=${state}`;
        const popup = window.open(authUrl, title, `width=${width},height=${height},left=${left},top=${top}`);
        setExternalPopup(popup);

    }

    const handleDisconnectZohoAuth = (props) => {

        dispatch(deleteZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("deleteZohoAuth", data.payload)
            localStorage.removeItem("zohoTokens")
            Toaster.create({ position: Position.TOP }).show({ message: 'Successfully disconnected', intent: Intent.SUCCESS });


        })


    }


    const getZohoAuthFromDb = (props) => {

        dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getZohoAuth", data.payload)
            const message = data.payload.data.message

            if (message === "Already authenticated") {

                Toaster.create({ position: Position.TOP }).show({
                    message:
                        <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '14px',

                            }}>{message}</Text>
                        </Box>
                    , intent: Intent.PRIMARY, action: {

                        text: <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '14px',

                        }}>Disconnect.</Text>,
                        onClick: () => handleDisconnectZohoAuth("")
                    }
                });

            } else {
                handleAuthenticateZohoInvoice("")

            }




        })

    }

    const getZohoAuthTokents = (props) => {

        dispatch(postZohoAuth({
            userId: userId,
            userEmail: userEmail,
            authCode: props,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("postZohoAuth", data.payload)
            localStorage.setItem("zohoTokens", JSON.stringify(data.payload.tokenFromDb))


        })

    }

    const uploadGdriveFolderInDb = (props) => {

        dispatch(syncGoogleDriveFolder({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            gdriveData: props
        })).then((data) => {


            console.log("syncGoogleDriveFolder data ", data)

            if (data.payload.message === "Already Synced folder") {
                Toaster.create({ position: Position.TOP }).show({ message: "Already Synced folder.", intent: Intent.PRIMARY });

            } else {
                Toaster.create({ position: Position.TOP }).show({ message: "File successfully uploaded.", intent: Intent.SUCCESS });

            }




        })


    }


    //---------------------------------------------------------------


    const getAppIntegrationFromDb = (props) => {


        dispatch(getAppIntegration({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setappArray(data.payload.data)
            setinstalledApp(data.payload.installed)
        })

    }

    const AllAppsComponent = (props) => {

        return (<Box
            sx={{
                position: 'absolute',
                top: '75px',
                left: '0px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                gap: '53px',
                background: 'transparent',

            }}
        >

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '25px',
                flexWrap: 'wrap'
            }}>

                {appArray.sort((a, b) => {
                    const nameA = a.app.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
                    const nameB = b.app.toUpperCase();

                    if (nameA < nameB) {
                        return -1; // a should come before b
                    } else if (nameA > nameB) {
                        return 1; // a should come after b
                    }

                    return 0; // names are equal
                }).map((row, index) => {
                    return (
                        <Box sx={{
                            display: installedApp.filter((apData) => apData.app_id === row.app_id).length > 0 ? 'none' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '106px',
                            maxWidth: '106px',
                            minWidth: '106px',
                            height: '106px',
                            minHeight: '106px',
                            maxHeight: '106px',
                            background: '#FFFFFF',
                            boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.05)',
                            borderRadius: '25px',
                            cursor: 'pointer',
                            ':hover': {
                                transform: 'scale(1.01)',
                            }
                        }}
                            onClick={() => {

                                if (installedApp.filter((apData) => apData.app_id === row.app_id).length > 0) {
                                    // if (row.app === "Google Calendar") {
                                    //     authGcalander("")
                                    // } else if (row.app === "Gmail") {

                                    //     authGmailApi("")

                                    // } else if (row.app === "Google Drive") {
                                    //     authGoogleDrive("")
                                    // } else if (row.app === "Google Meet") {

                                    //     authGcalander("")

                                    // } else if (row.app === "Dropbox") {

                                    //     authDropBoxApp("")

                                    // }



                                } else {

                                    setshowInstallationDialog(true)
                                    setselectedAppId(row.app_id)

                                    if (row.app === "Zoho Invoice") {

                                        setimages(zohoInvSliders)

                                    }else if(row.app === "Google Meet"){

                                        setimages(googleMeetSlider)

                                    } else {
                                        setimages(defaultImages)
                                    }


                                }

                            }}
                        >
                            {row.app === "Gmail" ?

                                <img style={{
                                    height: '166px',
                                    width: '166px'

                                }} src={row.app_image} />
                                :

                                row.app === "Google Meet" ?
                                    <img style={{ height: '84px', width: '84px' }} src={row.app_image} />
                                    :
                                    row.app === "Dropbox" ?
                                        <img style={{ height: '98px', width: '96px' }} src={row.app_image} />
                                        :
                                        <img style={{}} src={row.app_image} />
                            }
                        </Box>

                    )
                })}

            </Box>


        </Box>)
    }

    const EmptyDataView = (props) => {

        return (
            <Box

                borderColor="border.default"
                borderWidth={1}
                borderStyle="solid"
                sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '184px',
                    width: '434px',
                    borderRadius: '6px',
                    background: '#FFFFFF'
                }}>

                <Box sx={{
                    position: 'relative',
                    margin: '32px 0px 0px 0px'
                }}>
                    <BookIcon size={24} />

                </Box>



                <Heading
                    sx={{
                        position: 'relative',
                        margin: '18px 0px 0px 0px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontSize: '17px',
                        fontWeight: '600',
                        lineHeight: '36px',
                        color: '#24292F',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    Apps not installed yet
                </Heading>
                <Text sx={{
                    width: '370px',
                    height: '40px',
                    position: 'relative',
                    margin: '4px 0px 0px 0px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    color: '#57606A',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    It may be in progress or waiting to be released. Please check back later for updates.
                </Text>


            </Box>
        )

    }


    const InstalledAppsComponent = (props) => {

        return (<Box
            sx={{
                position: 'absolute',
                top: '75px',
                left: '0px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                gap: '53px',
                background: 'transparent',

            }}
        >

            {installedApp.length === 0 ? <EmptyDataView /> : ""}


            <Box sx={{
                display: installedApp.length === 0 ? 'none' : 'flex', //'flex',
                flexDirection: 'row',
                gap: '25px',
                flexWrap: 'wrap'
            }}>

                {installedApp.sort((a, b) => {
                    const nameA = a.app.toUpperCase();
                    const nameB = b.app.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    } else if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }).map((row, index) => {
                    return (

                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '106px',
                            maxWidth: '106px',
                            minWidth: '106px',
                            height: '106px',
                            minHeight: '106px',
                            maxHeight: '106px',
                            background: '#FFFFFF',
                            boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.05)',
                            borderRadius: '25px',
                            cursor: 'pointer',
                            ':hover': {
                                transform: 'scale(1.01)',
                            }
                        }}
                            onClick={() => {

                                if (row.app === "Google Calendar") {
                                    getGoogleCalanderAuthFromDb("")
                                } else if (row.app === "Gmail") {


                                    getGoogleMailAuthFromDb("")

                                } else if (row.app === "Google Drive") {
                                    getGoogleDriveAuthFromDb("")
                                } else if (row.app === "Google Meet") {

                                    getGoogleAuthFromDb("")

                                } else if (row.app === "Dropbox") {

                                    // authDropBoxApp("")
                                    //  authDropBox("")
                                    getDropboxAuthFromDb("")
                                } else if (row.app === "Zoho Invoice") {

                                    getZohoAuthFromDb("")

                                }


                            }}
                        >
                            {row.app === "Gmail" ?

                                <img style={{
                                    height: '166px',
                                    width: '166px'

                                }} src={row.app_image} />
                                :
                                row.app === "Google Meet" ?
                                    <img style={{
                                        height: '84px',
                                        width: '84px'
                                    }} src={row.app_image} />
                                    : row.app === "Dropbox" ?
                                        <img style={{
                                            height: '96px',
                                            width: '96px'
                                        }} src={row.app_image} />
                                        :
                                        <img style={{}} src={row.app_image} />
                            }

                        </Box>

                    )
                })}

            </Box>


        </Box>)
    }

    React.useEffect(() => {

        if (stopLooping === true) {

            // getZohoAuthTokents(zohoaccessCode)
            externalPopup.close();

        }


    }, [stopLooping]);

    React.useEffect(() => {
        console.log("dropboxAuthPopup", dropboxAuthPopup);

        if (!dropboxAuthPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!dropboxAuthPopup) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = dropboxAuthPopup.location.href;
            console.log("currentUrl dropboxAuthPopup", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code dropboxAuthPopup", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                dropboxAuthPopup.close();
                console.log(`dropboxAuthPopup Code = ${code}`);
                dropBoxAccessToken(code)

            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [dropboxAuthPopup]);


    React.useEffect(() => {
        console.log("googleDriveAuthPop", googleDriveAuthPop);

        if (!googleDriveAuthPop) {
            return;
        }

        const timer = setInterval(() => {
            if (!googleDriveAuthPop) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = googleDriveAuthPop.location.href;
            console.log("currentUrl googleDriveAuthPop", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code googleDriveAuthPop", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                googleDriveAuthPop.close();
                console.log(`googleDriveAuthPop Code = ${code}`);
                getGoogleDriveAccessToken(code)

            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [googleDriveAuthPop]);


    React.useEffect(() => {
        console.log("googleMailAuthPopup", googleMailAuthPopup);

        if (!googleMailAuthPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!googleMailAuthPopup) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = googleMailAuthPopup.location.href;
            console.log("currentUrl googleMailAuthPopup", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code googleMailAuthPopup", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                googleMailAuthPopup.close();
                console.log(`googleMailAuthPopup Code = ${code}`);
                getGoogleMailAccessToken(code)

            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [googleMailAuthPopup]);


    React.useEffect(() => {
        console.log("googleCalanderAuthPopup", googleCalanderAuthPopup);

        if (!googleCalanderAuthPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!googleCalanderAuthPopup) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = googleCalanderAuthPopup.location.href;
            console.log("currentUrl googleCalanderAuthPopup", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code googleCalanderAuthPopup", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                googleCalanderAuthPopup.close();
                console.log(`googleCalanderAuthPopup Code = ${code}`);
                getGoogleCalanderAccessToken(code)

            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [googleCalanderAuthPopup]);


    React.useEffect(() => {
        console.log("googleMeetAuthPopup", googleMeetAuthPopup);

        if (!googleMeetAuthPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!googleMeetAuthPopup) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = googleMeetAuthPopup.location.href;
            console.log("currentUrl googleMeetAuthPopup", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code googleMeetAuthPopup", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                googleMeetAuthPopup.close();
                console.log(`googleMeetAuthPopup Code = ${code}`);
                getGoogleMeetAuthAccessToken(code)

            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [googleMeetAuthPopup]);


    React.useEffect(() => {
        console.log("externalPopup", externalPopup);

        if (!externalPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }

            const currentUrl = externalPopup.location.href;
            console.log("currentUrl", currentUrl);

            if (!currentUrl) {
                return;
            }

            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get('code');

            console.log("code", code);

            if (code) {
                clearInterval(timer); // Stop checking when the code is found
                externalPopup.close();
                console.log(`The popup URL has URL code param = ${code}`);
                setzohoaccessCode(code);
                getZohoAuthTokents(code)
                // setstopLooping(true);
            }
        }, 500);

        // Cleanup the interval when the component unmounts or when externalPopup changes
        return () => clearInterval(timer);

    }, [externalPopup]);






    React.useState(() => {

        getAppIntegrationFromDb("")

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet>
                <title>
                    Apps integrations - deski
                </title>

                <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="r1ex80p99zrn57f"></script>


            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
            <SpaceRightSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"auto"}
            >

                <Box sx={{
                    position: 'absolute',
                    height: '230px',
                    minHeight: '230px',
                    maxHeight: '230px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    top: '0px',
                    background: '#0065FF',

                }}>

                    {/* top horizontal Line */}
                    <Box sx={{
                        position: 'absolute',
                        top: '45px',
                        width: '100%',
                        height: '2px',
                        minHeight: '2px',
                        maxHeight: '2px',
                        background: '#E6E9EE',
                        opacity: '20%'
                    }}>

                    </Box>
                    {/* ------------------- */}

                    {/* bottom horizontal Line */}
                    <Box sx={{
                        position: 'absolute',
                        bottom: '45px',
                        width: '100%',
                        height: '2px',
                        minHeight: '2px',
                        maxHeight: '2px',
                        background: '#E6E9EE',
                        opacity: '20%'
                    }}>

                    </Box>
                    {/* ------------------- */}

                    {/* left vertical line */}
                    <Box sx={{
                        position: 'absolute',
                        left: '124px',
                        height: '100%',
                        minHeight: '100%',
                        maxHeight: '100%',
                        width: '2px',
                        background: '#E6E9EE',
                        opacity: '20%'
                    }}>

                    </Box>
                    {/* ------------------ */}

                    {/* right vertical line */}
                    <Box sx={{
                        position: 'absolute',
                        right: '124px',
                        height: '100%',
                        minHeight: '100%',
                        maxHeight: '100%',
                        width: '2px',
                        background: '#E6E9EE',
                        opacity: '20%'
                    }}>

                    </Box>
                    {/* ------------------ */}


                </Box>

                <Heading sx={{
                    position: 'absolute',
                    top: '88px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    maxWidth: 'fit-content',
                    height: '48px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '32px',
                    lineHeight: '48px',
                    color: '#FFF',
                    textAlign: 'center'
                }}>
                    Install Your Favorite Apps, Streamline Your Work
                </Heading>


                <Box sx={{
                    position: 'absolute',
                    top: '268px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    height: '345px',
                    minHeight: '345px',
                    maxHeight: '345px',
                    width: '895px',
                    minWidth: '895px',
                    maxWidth: '895px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Box sx={{
                        height: '48px',
                        minHeight: '48px',
                        maxHeight: '48px',
                        background: 'transparent'
                    }}>

                        <UnderlineNav
                            key={"" + Math.random(100, 20000) + ""}
                            aria-label="apps"
                            sx={{
                                paddingLeft: '0px',
                                marginLeft: '0px',
                                position: 'absolute',
                                height: '48px',
                                minHeight: '48px',
                                maxHeight: '48px',
                                minWidth: '444px',
                                maxWidth: '444px',
                                width: '444px',
                                border: 'none',

                            }}

                        >
                            {appTabs?.map((item, index) => (
                                <UnderlineNav.Item
                                    style={{
                                        fontFamily: 'Avenir LT Std',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        color: index === selectedIndex == true ? "#000000" : "#24292E",
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        height: '24px',
                                        margin: '0px',

                                    }}
                                    key={item.id}
                                    aria-current={index === selectedIndex ? 'page' : undefined}
                                    selected={index === selectedIndex}
                                    onSelect={e => {
                                        setselectedIndex(index)
                                        e.preventDefault()
                                    }}



                                >

                                    {item.name}
                                </UnderlineNav.Item>

                            ))}
                        </UnderlineNav>

                    </Box>

                    {selectedIndex === 0 ?

                        <AllAppsComponent /> : <InstalledAppsComponent />


                    }


                </Box>





            </Box>


            <Dialog
                initialFocusRef={defaultDialogFocus}
                isOpen={showInstallationDialog}
                // isOpen={true}
                onDismiss={() => {
                    setshowInstallationDialog(false)
                    setselectedAppId("")
                    setimages(images)
                }}
                sx={{
                    width: '870px',
                    minWidth: '870px',
                    maxWidth: '870px',
                    height: '582px',
                    minHeight: '582px',
                    maxHeight: '582px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                <Box sx={{
                    position: 'absolute',
                    left: '30px',
                    right: '199px',
                    top: '26px',
                    height: '105px',
                    minHeight: '105px',
                    maxHeight: '105px',
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    <Box
                        ref={defaultDialogFocus}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '105px',
                            maxWidth: '105px',
                            minWidth: '105px',
                            height: '105px',
                            minHeight: '105px',
                            maxHeight: '105px',

                        }}>
                        <img style={{
                            maxHeight: appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app === "Gmail" ? '176px' : '106px',
                            height: appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app === "Gmail" ? '176px' : '106px',
                            width: appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app === "Gmail" ? '176px' : '106px',
                            maxWidth: appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app === "Gmail" ? '176px' : '106px',
                            minWidth: appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app === "Gmail" ? '176px' : '106px',

                        }} src={appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app_image} />
                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 21px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3px'
                    }}>
                        <Heading sx={{
                            display: 'flex',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '30px',
                            lineHeight: '40px',
                            color: '#24292E'
                        }}>
                            {appArray?.filter((aData) => aData.app_id === selectedAppId)[0]?.app}
                        </Heading>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '12px',
                            alignItems: 'center'
                        }}>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '21px',
                                color: '#586069'
                            }}>
                                Desenvolvido por: Discord inc
                            </Text>
                            <Box sx={{
                                width: '2px',
                                height: '19px',
                                background: '#CBCBCB',
                                borderRadius: '90px',

                            }}></Box>
                            <Text sx={{
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '21px',
                                color: '#24292E'
                            }}>
                                Communication
                            </Text>
                            <Box sx={{
                                width: '2px',
                                height: '19px',
                                background: '#CBCBCB',
                                borderRadius: '90px',

                            }}></Box>
                        </Box>




                    </Box>
                    <Button
                        leadingIcon={() => showAppLoading ? <Spinner sx={{ display: 'flex' }} size="small" /> : ""}
                        variant="outline"
                        sx={{
                            position: 'absolute',
                            top: '38px',
                            left: '572px',
                            background: '#0965CE',
                            color: '#ffffff',
                            width: showAppLoading ? '110px' : '70px',
                            height: '32px',
                            minWidth: showAppLoading ? '110px' : '70px',
                            maxWidth: showAppLoading ? '110px' : '70px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: '600',

                        }}
                        onClick={() => {

                            handleInstallApp("")
                        }}
                    >
                        {showAppLoading ? "Installing..." : "Install"}

                    </Button>

                </Box>

                <Box sx={{
                    position: 'absolute',
                    top: '158px',
                    left: '30px',
                    right: '30px',
                    minHeight: '241px',
                    height: '241px',
                    maxHeight: '241px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                }}>
                    <Box sx={{
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                        minHeight: '241px',
                        height: '241px',
                        maxHeight: '241px',
                    }}>
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            transition: 'transform 0.5s ease',
                            gap: '15px',

                        }}
                            style={{ transform: `translateX(-${slideIndex * 44}%)` }}
                        >
                            {images.map((image) => (
                                <Box
                                    sx={{
                                        width: '494px',
                                        height: '241px',
                                        flexShrink: '0',

                                    }}
                                    key={image.id}>
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        style={{
                                            width: '494px',
                                            height: '241px',
                                            minWidth: '494px',
                                            maxWidth: '494px',
                                            minHeight: '241px',
                                            maxHeight: '241px',
                                            borderRadius: '15px'
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    position: 'absolute',
                    top: '416px',
                    left: '35px',
                    right: '16px',
                    minHeight: '27px',
                    maxHeight: '27px',
                    height: '27px',
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '20px',
                        lineHeight: '27px',
                        color: '#24292E'
                    }}>
                        Description
                    </Text>

                    <IconButton

                        icon={() => <ArrowRightIcon size={16} />}
                        sx={{
                            position: 'absolute',
                            right: '3px',
                            border: 'none',
                            color: '#000000',
                            boxShadow: 'none',
                            height: '27px',
                            width: '27px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={nextSlide}
                    />

                    <IconButton

                        icon={() => <ArrowLeftIcon size={16} />}
                        sx={{
                            position: 'absolute',
                            right: '32px',
                            border: 'none',
                            color: '#000000',
                            boxShadow: 'none',
                            height: '27px',
                            width: '27px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={prevSlide}
                    />

                </Box>


                <Text sx={{
                    position: 'absolute',
                    top: '450px',
                    left: '35px',
                    right: '23px',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '16px',
                    color: '#24292E'
                }}>
                    No Discord você poderá criar um lar para suas comunidades e amigos. Onde vocês podem se aproximar e se divertir usando texto, voz e vídeo. Não importa se você faz parte de um clube escolar, um grupo de jogadores, uma comunidade artística mundial ou apenas tem
                </Text>


            </Dialog>

            <ScheduleDemoDialog />
            <SearchDialog />
        </Box>
    )

}

export default Apps