import React from "react"
import {Box,Dialog,Text,TextInput,Select,Textarea, Button } from '@primer/react'
import { useDispatch,useSelector } from "react-redux";
import Workplacetheme from "../Workplacetheme";
import { contactProfileOpenClose, deleteContactDirectory, deleteOrganizationDialogOpenClose } from "../../redux/features/contactSlice";
import { Toaster, Intent, Position,ProgressBar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

const synchingToast = Toaster.create({ position: Position.TOP })

function DeleteOrganization(props){

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const contactProfileData = useSelector((state)=> state.contactProfileData) 
    
    const initialFocusRef = React.createRef()


    const closeDeleteOrganizationDialog = (props) => {
        
        dispatch(deleteOrganizationDialogOpenClose({
             deleteOrganization:false,
             
        }))
      
    }

    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }

    const handleDeleteOrganization = (orgId) => {

        closeDeleteOrganizationDialog("")
        loadingToast(10, "1")
        dispatch(deleteContactDirectory({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            deleteContactId: orgId
        })).then((data) => {

            console.log("deleteContactDirectory", data.payload)

            if (data.payload.data !== null) {

                dispatch(contactProfileOpenClose({
                    openClose: false,
                    title: ""
                }))
                loadingToast(100, "1")
                navigate('/contact')

               //All data in the organization has been cleared
               Toaster.create({ position: Position.TOP }).show({
                message: data.payload.data,
                intent: Intent.SUCCESS,
               });

            }
        })

    }


       

    return(

        <Dialog
            sx={Workplacetheme.deleteWorkSpaceDialog}
            isOpen={contactProfileData.deleteOrganization}
            onDismiss={() =>

                closeDeleteOrganizationDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
             <Dialog.Header id="header-id">
                <Text  sx={Workplacetheme.scheduleDalogeHeading}>Are you sure you want to delete organization?
                     
                </Text>
               
            </Dialog.Header>

            <Box sx={Workplacetheme.deleteWorkplaceDialogContentBox}>

                <Text sx={Workplacetheme.createSpaceDialodBoxText} style={{ color: '#24292E' }} >Please note that all associated information, documents, task history and reports have
                    been permanently deleted and cannot be undone.</Text>
               
                <Box 
                sx={Workplacetheme.deleteworkplaceDialogFooterLine}
                borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
                </Box>

                    <Box  
                    ml={24} 
                    mr={24} 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent={"right"}
                    >

                            <Button variant="invisible" 
                            sx={Workplacetheme.deleteworkplaceDialogFooterBox.cancelBtn}
                            onClick={()=> closeDeleteOrganizationDialog("") }
                            >Cancel</Button>
                            <Button
                           ref={initialFocusRef}
                            variant="danger" 
                            sx={Workplacetheme.deleteworkplaceDialogFooterBox.submitBtn}
                            onClick={()=> 
                            
                                
                                handleDeleteOrganization(props.contactId)
                            }
                            >Delete</Button>
                  
                  
                    </Box>





        </Dialog>

    )


}

export default DeleteOrganization