import { Box, Breadcrumbs, Button, Heading,Text,ProgressBar, Avatar, TextInput, Label, StyledOcticon, NavList, Checkbox, Tooltip } from "@primer/react";
import { Helmet } from "react-helmet";
import Header  from  "../Workplace/Header"
import SideNav from "../Workplace/SideNav"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { ArrowDownIcon, ArrowUpIcon, CheckCircleFillIcon, ChevronRightIcon, EyeClosedIcon, EyeIcon, FeedPersonIcon, PlusIcon, SearchIcon, ShieldIcon, ZapIcon } from "@primer/octicons-react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getSingleSpaceTasks, getSubtaskPrimary, getTaskExpansionDetails, getTaskProjectHistory } from "../redux/features/taskSlice";
import { getSingleWorkplaceProject, getUserOrganizationById } from "../redux/features/spaceSlice";

function formatDate(date) {
    const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

function calculateDaysBetweenDates(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000;
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const durationMs = endDate - startDate
    const numberOfDays = Math.round(durationMs / oneDay);
    return numberOfDays;
}

function getAssigneScore(subtaskArray,userId){

    const newSubtaskPrimaryArray = [...subtaskArray].filter((sData) => sData?.assigne_id == userId)
    var totalCount = newSubtaskPrimaryArray.length
    var completedCount = newSubtaskPrimaryArray.length > 0 ? newSubtaskPrimaryArray?.filter((wData) => wData?.workflow_id === wData?.workflow?.filter(wiData => wiData?.workflow_name === "Completed")[0]?.workflow_id).length
        : 0

    if (!isNaN(completedCount) && !isNaN(totalCount) && totalCount !== 0) {
        const subtaskPercentage = (completedCount / totalCount) * 100;
        return subtaskPercentage
    } else {
        return 0
    }

  
}

function getTotalDaysofSubtasks(subtaskArray,userId){

    const newSubtaskPrimaryArray = [...subtaskArray].filter((sData) => sData?.assigne_id == userId)
    if(newSubtaskPrimaryArray.length>0){

        var totalCount = 0
        Promise.all(
            newSubtaskPrimaryArray.map((tData) => {


                const oneDay = 24 * 60 * 60 * 1000;
                const startDate = new Date(tData?.created_timestamp);
                const endDate = new Date();
                const durationMs = endDate - startDate
                const numberOfDays = Math.round(durationMs / oneDay);
                totalCount = totalCount + numberOfDays
            })

        )
       

        return totalCount

    }else{

        return 0
    } 
 
   
}


export default function TaskExpansionDetails(){

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const [taskDatas, settaskDatas] = React.useState([])
    const [complianceData, setcomplianceData] = React.useState([])
    const [ownerData, setownerData] = React.useState([])
    const [contactData, setcontactData] = React.useState([])
    const [collaborators, setcollaborators] = React.useState([])
    const [showSubtaskOpenBtn, setshowSubtaskOpenBtn] = React.useState("")
    const [searchInput, setsearchInput] = React.useState("")
    const [subtaskArray, setsubtaskArray] = React.useState([])
    const [taskLevel,settaskLevel] = React.useState(0)
    const [pendingTaskCount,setpendingTaskCount] = React.useState(0)
    
    const [navlistArray, setnavlistArray] = React.useState([])

    const [activityArray, setactivityArray] = React.useState([])
    const [historyArray,sethistoryArray] = React.useState([])
    const [selectedSection, setselectedSection] = React.useState('')
    const [showBottonContent, setshowBottonContent] = React.useState(false)

    const getSubtaskActivityFromDb = (props) => {

 
        const newActivity = [...historyArray]
        .filter((hData)=> hData?.history?.task_id===props )
        .map((row, index) => {
            return ({
                id: row.history.history_id,
                desc: row.history.history_data,
                owner: row.user_email,
                date: new Date(row.history.history_timestamp).toUTCString().substring(4,20),
                isChecked: false
            })
        })
        setactivityArray(newActivity)




        // dispatch(getSingleSpaceTasks({
        //     taskId: props
        // })).then((data) => {

        //     console.log("getSingleSpaceTasks",data.payload)
        //     const tActivity = data.payload.taskActivity
        //     console.log("tActivity", tActivity)
        //     const newActivity = [...data.payload.taskActivity].map((row, index) => {
        //         return ({
        //             id: row.activity.activity_id,
        //             desc: row.activity.activity_name,
        //             owner: row.user_email,
        //             date: new Date(row.activity.activity_timestamp).toUTCString().substring(4,20),
        //             isChecked: false
        //         })
        //     })
        //     setactivityArray(newActivity)


        // })

    }

    const handleOpenBtnClick = (props) => {

         setselectedSection(props.workflow_id)
         setshowBottonContent(true)
         getSubtaskActivityFromDb(props.task_id)

         
    }


    const ActivityComponent = (props) => {

        return (
            <Box sx={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                marginBottom:'112px'
            }}>

                <Text sx={{
                    fontFamily: customThemVariable.primartFont,
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '36px',
                    color: customThemVariable.primaryTextColor

                }}>
                    Activity
                </Text>

                <Box sx={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    background: customThemVariable.whiteBgColor,
                    width: '100%',
                    minWidth: '100%',
                    maxWidth: '100%',
                    
                }}>

                    <Box sx={{
                        minHeight: '26px',
                        maxHeight: '26px',
                        height: '26px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        minWidth: '100%',
                        maxWidth: '100%',
                        borderBottom: '1px solid #E3E8EE'
                    }}>

                        <Box sx={{
                            minWidth: '6.1%',
                            maxWidth: '6.1%',
                            width: '6.1%',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Checkbox

                                onChange={(e) =>

                                    setactivityArray((prevState) => {
                                        const newArray = prevState.map((row, index) => {
                                            return ({ ...row, isChecked: e.target.checked })
                                        })
                                        return (newArray)
                                    })

                                }
                                sx={{ marginLeft: '4px' }}
                            />
                        </Box>

                        <Box sx={{
                            minWidth: '59.3%',
                            maxWidth: '59.3%',
                            width: '59.3%',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                sx={{
                                    fontFamily: customThemVariable.primartFont,
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: customThemVariable.secondaryTextColor
                                }}
                            >
                                DESCRIPTION
                            </Text>
                        </Box>
                        <Box sx={{
                            minWidth: '19.7%',
                            maxWidth: '19.7%',
                            width: '19.7%',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                sx={{
                                    fontFamily: customThemVariable.primartFont,
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: customThemVariable.secondaryTextColor
                                }}
                            >
                                OWNER
                            </Text>
                        </Box>
                        <Box sx={{
                            minWidth: '16.0%',
                            maxWidth: '16.0%',
                            width: '16.0%',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                sx={{
                                    fontFamily: customThemVariable.primartFont,
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    color: customThemVariable.secondaryTextColor
                                }}
                            >
                                DATE
                            </Text>
                        </Box>

                    </Box>
                  
                    {activityArray.map((row, index) => {
                        return (
                            <Box sx={{
                                minHeight: '38px',
                                maxHeight: '38px',
                                height: '38px',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                minWidth: '100%',
                                maxWidth: '100%',
                                borderBottom: '1px solid #E3E8EE'
                            }}>

                                <Box sx={{
                                    minWidth: '6.1%',
                                    maxWidth: '6.1%',
                                    width: '6.1%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>
                                    <Checkbox

                                        onChange={(e) => {
                                            setactivityArray((prevState) => {
                                                const newArray = prevState.map((row2, index) => {
                                                    if (row2.id === row.id) {
                                                        return ({ ...row2, isChecked: e.target.checked })
                                                    } else {
                                                        return ({ ...row2 })
                                                    }
                                                })
                                                return (newArray)
                                            })
                                        }}

                                        checked={row.isChecked}
                                        sx={{ marginLeft: '4px' }}
                                    />
                                </Box>

                                <Box sx={{
                                    minWidth: '59.3%',
                                    maxWidth: '59.3%',
                                    width: '59.3%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>
                                    <Text
                                    onho
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            width:'95%',
                                            maxWidth:'95%',
                                            minWidth:'95%',
                                            whiteSpace: "nowrap",
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            color: customThemVariable.primaryTextColor
                                        }}
                                    >
                                        {row.desc?.replace(/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/, "")}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    minWidth: '19.7%',
                                    maxWidth: '19.7%',
                                    width: '19.7%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>
                                    <Text
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            color: customThemVariable.primaryTextColor
                                        }}
                                    >
                                        {row.owner}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    minWidth: '16.0%',
                                    maxWidth: '16.0%',
                                    width: '16.0%',
                                    display: 'flex',
                                    alignItems: 'center',

                                }}>
                                    <Text
                                        sx={{
                                            fontFamily: customThemVariable.primartFont,
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            color: customThemVariable.primaryTextColor
                                        }}
                                    >
                                        {row.date}
                                    </Text>
                                </Box>

                            </Box>
                        )
                    })}







                </Box>



            </Box>
        )

    }


    const getTaskExpansionDetailsFromDb = (props) => {

        dispatch(getTaskExpansionDetails({
            userId: userId,
            userEmail: userEmail,
            taskId: props,
        })).then((data) => {

            console.log("getTaskExpansionDetails", data.payload)
            settaskDatas(data.payload.data)
            setcomplianceData(data.payload.compliaceData)
            setownerData(data.payload.creatorData)
            setcontactData(data.payload.taskContactData)
            const projectId = data?.payload?.data[0]?.project_id
            getSubtasksFromDb(props, projectId)

            const newcollaboratorArray = [...data.payload.collaboratorArray].map((row, index) => {
                return (
                    {
                        id: row.user_id,
                        name: row.user_first_name,
                        score: '0%',
                        days: 0,
                        user_email:row.user_email
                    }
                )
            })
            setcollaborators(newcollaboratorArray)
          
            

        })


    }

    const getSubtasksFromDb = (taskId, projectId) => {

        dispatch(getSubtaskPrimary({
            userId: userId,
            userEmail: userEmail,
            projectId: projectId,
            taskId: taskId
        })).then((data) => {

            const newSubtaskPrimaryArray = [...data.payload.data].sort((a, b) => new Date(b.created_timestamp) - new Date(a.created_timestamp))
            console.log("subtaskindetails", newSubtaskPrimaryArray)
            
            var totalCount = newSubtaskPrimaryArray.length
            var completedCount = newSubtaskPrimaryArray.length > 0 ? newSubtaskPrimaryArray?.filter((wData) => wData?.workflow_id === wData?.workflow?.filter(wiData => wiData?.workflow_name === "Completed")[0]?.workflow_id).length
                : 0

            var pendingCount = newSubtaskPrimaryArray.length > 0 ? newSubtaskPrimaryArray?.filter((wData) => wData?.workflow_id === wData?.workflow?.filter(wiData => wiData?.workflow_name !== "Completed" && wiData?.workflow_name !== "Verified")[0]?.workflow_id).length
                : 0

            setpendingTaskCount(pendingCount) 

            if (!isNaN(completedCount) && !isNaN(totalCount) && totalCount !== 0) {
                const subtaskPercentage = (completedCount / totalCount) * 100;
                settaskLevel(subtaskPercentage.toFixed(1));
            } else {
                settaskLevel(0)
            }

            setsubtaskArray(newSubtaskPrimaryArray)

        })

    }

    const getTaskProjectHistoryFromDb = (props) => {
   
        console.log("proid",props)
        dispatch(getTaskProjectHistory({
            userId: userId,
            userEmail: userEmail,
            projectId: props
        })).then((data) => {

            console.log("getTaskProjectHistory", data.payload)
            const tempHistory = [...data.payload.data]
            sethistoryArray(tempHistory)
            
        })

    } 

     const getUserProjectHeads = (props) => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            const projectId = data?.payload?.data[0]?.project_id
            getTaskProjectHistoryFromDb(projectId)
            dispatch(getSingleWorkplaceProject({
                userId: userId,
                userEmail: userEmail,
                projectId: projectId,
            })).then((data) => {

                console.log("abTest", data.payload)
                const workflowArray = [...data?.payload?.data[0]?.workflow]
                setnavlistArray(workflowArray)


            })
           


        })


     }


       

    //  React.useState(()=>{

    //         const taskId = localStorage.getItem("taskId")
    //         getTaskExpansionDetailsFromDb(taskId)
    //         getUserProjectHeads("")


    //   })

    
    React.useEffect(() => {


        const taskId = localStorage.getItem("taskId")
        getTaskExpansionDetailsFromDb(taskId)
        getUserProjectHeads("")


    }, [localStorage.getItem("taskId")])
       


      return(

        <Box
        position={"relative"}
        display="flex"
        background={"#F6F8FA"}
        height="100vh"
        width={"100vw"}
        overflowY={"auto"}
        
    >

         
        <Helmet>
            <title>
                Task details - deski
           </title>
        </Helmet>
    
        <Header />
        <SideNav />

        <Box sx={{
            position: 'absolute',
            top: '48px',
            bottom: '0px',
            left: '64px',
            right: '0px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            overflowX: 'hidden',
            background:'#F6F8FA',
             
        }}>
 
                  <Box sx={{
                      position: 'fixed',
                      left: '64px',
                      right: '0px',
                      minHeight: '48px',
                      maxHeight: '48px',
                      height: '48px',
                      background: customThemVariable.whiteBgColor,
                      display: 'flex',
                      borderBottom:'1px solid #E5E9EB',
                      zIndex:'1'
                  }}>

                      <Text sx={{
                          marginTop: '12px',
                          marginLeft: '32px',
                          marginBottom: '15px',
                          color: customThemVariable.secondaryTextColor,
                          fontSize: '14px',
                          fontFamily: customThemVariable.primartFont,
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                          cursor: 'pointer'
                      }}
                          onClick={() => {
                              navigate('/feed')
                          }}
                      >
                          Feed
                      </Text>

                      <Box sx={{
                          marginLeft:'6px',
                          marginTop:'14px',
                          marginBottom:'18px',
                          color:'#B0BABF'
                      }}>
                          <ChevronRightIcon/>
                      </Box>

                      <Text
                          sx={{
                              marginTop: '12px',
                              marginLeft: '6px',
                              marginBottom: '15px',
                              color: customThemVariable.secondaryTextColor,
                              fontSize: '14px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                              cursor: 'pointer',
                              minWidth:'auto',
                             // width:'70%',
                              maxWidth:'136px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis'
                          }}
                          onClick={() => {
                              navigate('/feed')
                          }}
                      >
                          {/* Review the client's */}
                          {taskDatas.length>0 ? taskDatas[0].task_name :""}
                      </Text>

                      <Box sx={{
                          marginLeft: '6px',
                          marginTop: '14px',
                          marginBottom: '18px',
                          color: '#B0BABF'
                      }}>
                          <ChevronRightIcon />
                      </Box>

                      <Text sx={{
                          marginTop: '12px',
                          marginLeft: '6px',
                          marginBottom: '15px',
                          color: customThemVariable.secondaryTextColor,
                          fontSize: '14px',
                          fontFamily: customThemVariable.primartFont,
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                          minWidth: 'auto',
                          maxWidth: '134px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',

                      }}>

                          {"Overview"}
                      </Text>
                 
 

                  </Box>

                  <Box 
                  sx={{
                     position:'absolute',
                     left:'26px',
                     top:'68px',
                     right:'385px',
                     display:'flex',
                     flexDirection:'column',
                    
                  }}>

                      <Heading sx={{
                           color:customThemVariable.primaryTextColor,
                           fontSize:'20px',
                           fontFamily:customThemVariable.primartFont,
                           fontStyle:'normal',
                           fontWeight:'600',
                           lineHeight:'36px',
                           width:'100%',
                      }}>
                          {taskDatas.length>0 ? taskDatas[0].task_name :""}
                      </Heading>

                      <Box sx={{
                           width:'100%',
                           height:'1px',
                           marginTop:'7px',
                           background:customThemVariable.borderColorSecondary,
                        
                      }}>

                      </Box>

                      <Text
                          sx={{
                              display: taskDatas.length > 0 ? taskDatas[0].description?.blocks[0]?.text.length > 0 ? 'flex' : 'none' : 'none',
                              marginTop: '9px',
                              color: customThemVariable.primaryTextColor,
                              fontSize: '14px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                              width: '100%',
                              minHeight: 'auto',
                          }}
                      >
                          {taskDatas.length>0 ? taskDatas[0].description?.blocks[0]?.text :""}
                      </Text>

                      <Box
                      sx={{
                         display:taskDatas.length>0 ? taskDatas[0].description?.blocks[0]?.text.length > 0 ?'flex':'none':'none',
                         marginTop:'6px',
                         width:'100%',
                         height:'1px',
                         background:'#D5DBE1'
                      }}
                      ></Box>

                      <Box sx={{
                          marginTop: '15px',
                          minHeight: '24px',
                          maxHeight: '24px',
                          height: '24px',
                          display: 'flex',
                          flexDirection: 'row',
                          gap:'20px'
                      }}>

                           {complianceData.length > 0? 

                              <Label sx={{
                                  position: 'relative',
                                  margin: '0px 0px 0px 0px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  fontSize: '12px',
                                  lineHeight: '16px',
                                  color: customThemVariable.labelTextColor,
                                  borderRadius: '37px',
                                  minHeight: '24px',
                                  maxHeight: '24px',
                                  height: '24px',
                                  background: customThemVariable.labelBgColor,
                                  border: 'none',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  paddingLeft:'10px',
                                  paddingRight:'10px'
                              }}>

                                  <Text sx={{}}>
                                      {complianceData[0]?.subcategory?.compliance_name}
                                  </Text>

                              </Label>
                           
                           
                           :""}
                      
                            
                          <Label sx={{
                              position: 'relative',
                              margin: '0px 0px 0px 0px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle: 'normal',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '16px',
                              color: customThemVariable.labelTextColorsecondary,
                              borderRadius: '37px',
                              minHeight:'24px',
                              maxHeight:'24px',
                              height:'24px',
                              background: customThemVariable.labelBgSecondary,
                              border: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft:'10px',
                              paddingRight:'10px'
                          }}>

                              {ownerData.length > 0 ?

                                  <Tooltip aria-label={ownerData[0]?.user_email}>
                                  <Text sx={{}}>
                                      Owner : {ownerData[0].user_first_name}
                                  </Text>
                                  </Tooltip>
                                  : ""}

                             

                          </Label>

                      </Box>


                      {/* subtask section */}

                      <Box sx={{
                          marginTop: '16px',
                          minHeight: '30px',
                          maxHeight: '30px',
                          height: '30px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems:'center'
                      }}>

                          <Heading sx={{
                               fontFamily: customThemVariable.primartFont,
                               fontStyle: 'normal',
                               fontWeight: '600',
                               fontSize: '20px',
                               lineHeight: '36px',
                               color: customThemVariable.primaryTextColor,
                          }}>
                              Subtask
                          </Heading>

                          <Button
                              leadingIcon={() => <PlusIcon />}
                              sx={{
                                  position: 'absolute',
                                  right: '0px',
                                  height: '28px',
                                  minHeight: '28px',
                                  maxHeight: '28px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  color: customThemVariable.primaryTextColor,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  background: '#fff',
                                  borderRadius: '4px',
                                  paddingLeft:'8px',
                                  paddingRight:'8px',
                                //  border: '1px solid rgba(60, 66, 87, 0.12)',
                                //  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 5px 0px rgba(60, 66, 87, 0.12), 0px -1px 1px 0px rgba(0, 0, 0, 0.12) inset',
                                  ":hover":{
                                    
                                  }
                              }}
                               
                          >
                              Add
                          </Button>

                      </Box>

                      {/* ---- */}

                      {/* subtask dynamic data */}
                  
                       <Box sx={{
                            marginTop:'7px',
                            display:'flex',
                            flexDirection:'column',
                            borderTop:'1px solid #EBEEF1',  
                            borderBottom:'1px solid #EBEEF1'  
                             
                       }}>

                            {subtaskArray.map((row,index)=>{
                                  return(
                                      <Box
                                          sx={{
                                              minHeight: '60px',
                                              maxHeight: '60px',
                                              height: '60px',
                                              borderBottom: index !== subtaskArray.length - 1 ? '1px solid #EBEEF1': '0px solid #EBEEF1',
                                              display:'flex',
                                              flexDirection:'row',
                                              cursor:'pointer'
                                          }}
                                          onMouseEnter={()=>{
                                            setshowSubtaskOpenBtn(row.task_id)
                                          }}
                                          onMouseLeave={()=>{

                                            setshowSubtaskOpenBtn("")
                                          }}
                                      >

                                        <Box
                                        sx={{
                                            color:'#8792A2',
                                            marginTop:'16px',
                                            height:'12px',
                                            width:'12px',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center' 
                                        }}
                                        >   
                                              {   index === 2 ?
                                                  <StyledOcticon color="#1EA672" size={12} icon={CheckCircleFillIcon} />
                                                  :
                                                  index === 3 ?
                                                  <StyledOcticon color="#8792A2" size={12} icon={ShieldIcon} />
                                                  :
                                                  <EyeIcon size={12} />
                                              }
                                          

                                        </Box>

                                          <Box sx={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              marginLeft: '16px',
                                              marginTop:'12px'
                                          }}>
                                              <Text sx={{
                                                  fontFamily: customThemVariable.primartFont,
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  fontSize: '14px',
                                                  lineHeight: '20px',
                                                  color: customThemVariable.primaryTextColor,
                                              }}>
                                                  {row.task_name + " "}
                                                  <span
                                                      style={{
                                                          visibility: showSubtaskOpenBtn === row.task_id ? 'visible' : 'hidden',
                                                          color: '#625AFA',
                                                          cursor: 'pointer'
                                                      }}
                                                      onClick={() => { handleOpenBtnClick(row) }}
                                                  >Open</span>
                                              </Text>
                                              <Text sx={{
                                                  fontFamily: customThemVariable.primartFont,
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  fontSize: '12px',
                                                  lineHeight: '20px',
                                                  color: customThemVariable.tertiaryTextColor,
                                              }}>
                                                  {row.timeStamp}
                                              </Text>

                                          </Box>

                                          
                                             
                                             <Box sx={{
                                                marginTop:'12px',
                                                position: 'absolute',
                                                right: '0px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '7px'
                                            }}>


                                                {row.assigne_id!==null&&row.assigne_id!==""? 
                                                   
                                                  <>
                                                       <Label sx={{
                                                          position: 'relative',
                                                          margin: '0px 0px 0px 0px',
                                                          fontFamily: customThemVariable.primartFont,
                                                          fontStyle: 'normal',
                                                          fontWeight: '600',
                                                          fontSize: '12px',
                                                          lineHeight: '16px',
                                                          color: customThemVariable.labelTextColorsecondary,
                                                          borderRadius: '37px',
                                                          minHeight: '24px',
                                                          maxHeight: '24px',
                                                          height: '24px',
                                                          background: customThemVariable.labelBgSecondary,
                                                          border: 'none',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'center',
                                                          paddingLeft:'10px',
                                                          paddingRight:'10px'
                                                      }}>

                                                          <Tooltip aria-label={row.user_email}>
                                                          <Text sx={{}}>
                                                               
                                                              {row.user_first_name + " " + row.user_last_name}
                                                          </Text>
                                                          </Tooltip>
                                                      </Label>

                                                     

                                                  </>
                                                
                                                :''
                                                
                                                }

                                              <Label sx={{
                                                  position: 'relative',
                                                  margin: '2px 0px 0px 0px',
                                                  fontFamily: customThemVariable.primartFont,
                                                  fontStyle: 'normal',
                                                  fontWeight: '600',
                                                  fontSize: '12px',
                                                  lineHeight: '16px',
                                                  color: customThemVariable.labelTextTertiary,
                                                  borderRadius: '4px',
                                               //   minHeight: '24px',
                                                //  maxHeight: '24px',
                                                //  height: '24px',
                                                  background: customThemVariable.labelBgColorTertiary,
                                                  border: 'none',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  paddingLeft:'6px',
                                                  paddingRight:'6px'
                                              }}>

                                                  <Text sx={{}}>
                                                      {
                                                        row?.workflow?.filter((wData)=> wData?.workflow_id===row?.workflow_id)[0]?.workflow_name
                                                      }
                                                  </Text>
                                              </Label>


                                            </Box>
                                          
                                          
                                         

                                          
                                         


                                            
                                      </Box>
                                  )
                            })}

                             
                        </Box>

                      
                      {/* navlist tabs example */}

                      <Box sx={{
                          marginTop: '18px',
                          width: 'fit-content',
                          minHeight: '20px',
                          maxHeight: '20px',
                          minWidth:'fit-content',
                          maxWidth:'fit-content',
                          background: customThemVariable.whiteBgColor,
                          display: showBottonContent?'flex':'none',
                          flexDirection:'row',
                          gap:'5px'
                       }}>

                           {
                            navlistArray.map((row,index)=>{
                                  return(

                                      <Button
                                          variant="invisible"
                                          sx={{
                                              minHeight: '20px',
                                              maxHeight: '20px',
                                              height: '20px',
                                              borderLeft: selectedSection === row.workflow_id ? '2px solid #635BFF' : 'none',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              fontSize: '14px',
                                              fontFamily: customThemVariable.primartFont,
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              lineHeight: '20px',
                                              color: customThemVariable.primaryTextColor,
                                              borderRadius: '0px'
                                          }}

                                      >
                                          {row.workflow_name}
                                      </Button>
                                  )

                            })
                           }

                       </Box>

                      {/* -------------------- */}

                      {showBottonContent === true ? ActivityComponent("") : ""}
                      
                      {/* -------------- */}

                      
                  </Box>

                   {/* right side view */}

                  <Box sx={{
                      position: 'absolute',
                      right: '0px',
                      top: '48px',
                      minWidth: '305px',
                      maxWidth: '305px',
                      width: '305px',
                      background: customThemVariable.whiteBgColor,
                      minHeight: 'auto',
                      height:'auto',
                      maxHeight:'auto',
                      display:'flex',
                      flexDirection:'column',
                         
                  }}>

                      <Box
                          sx={{
                              position: 'relative',
                              margin: '32px 32px 0px 32px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems:'center'
                          }}
                      >
                          <Text sx={{
                              color: customThemVariable.secondaryTextColor,
                              fontSize: '14px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle:'normal',
                              fontWeight:'400',
                              lineHeight:'20px'
                          }}>
                              Task level
                          </Text>

                          <Button 
                          variant="invisible"
                          sx={{
                            position:'absolute',
                            right:'-16px',
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle:'normal',
                            fontWeight:'400',
                            lineHeight:'20px'
                          }}>
                              {/* Action */}
                              {taskDatas.length > 0 ? taskDatas[0]?.workflow?.filter((wData) => wData?.workflow_id == taskDatas[0]?.workflow_id)[0]?.workflow_name : ''}
                          </Button>  


                      </Box>

                      <Box
                          sx={{
                              position: 'relative',
                              margin: '6px 32px 0px 32px',
                              display: 'flex',
                              flexDirection: 'row',
                          }}>

                          <Heading sx={{
                              fontSize: '32px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle: 'normal',
                              fontWeight: '700',
                              lineHeight: '32px',
                              color: customThemVariable.primaryTextColor,
                              display: 'flex',
                              alignItems: 'center'
                          }}>
                              {taskLevel}
                          </Heading>
                            <Text
                            sx={{
                                fontSize: '20px',
                                fontFamily: customThemVariable.primartFont,
                                fontStyle:'normal',
                                fontWeight:'600',
                                lineHeight:'36px', 
                                color:customThemVariable.primaryTextColor,
                                
                            }}
                            >%</Text>

                          <Box sx={{
                              position:'relative',
                              margin:'auto 0px auto 23px',
                              display:'flex',
                              alignItems:'center',
                              justifyContent:'center',
                              marginLeft: '23px',
                              height: '24px',
                              width: '24px',
                              color:'#E36209',
                          }}>

                                 <ArrowDownIcon size={24}  />
                             

                          </Box>
                          <Text sx={{
                              position: 'relative',
                              margin: 'auto 0px auto 0px',
                              fontSize: '14px',
                              fontFamily: customThemVariable.primartFont,
                              fontStyle: 'normal',
                              fontWeight: '400',
                              color: customThemVariable.primaryTextColor
                          }}>
                              {pendingTaskCount} tasks pending
                          </Text>
                       </Box>

                      <Box
                      sx={{
                        position: 'relative',
                        margin: '8px 32px 0px 32px',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                      >

                          <ProgressBar sx={{
                              width: '100%',
                              minWidth: '100%',
                              height: '2px',
                              position: 'relative',
                              margin: '0px 0px 0px 0px',
                              background:'#D7EDFF'
                          }}
                              bg={'#4094F7'}
                              progress={parseInt(taskLevel)}
                          />

                      </Box>

                      <Box sx={{
                          position: 'relative',
                          margin: '32px 32px 0px 32px',
                          display: 'flex',
                          flexDirection: 'column',
                      }}>

                          <Text
                              sx={{
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.secondaryTextColor
                              }}
                          >Client</Text>

                          <Heading
                              sx={{
                                  marginTop:'6px',
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  color:customThemVariable.primaryTextColor,
                                  lineHeight:'24px',
                                  minWidth:'100%',
                                  maxWidth:'100%',
                                  width:'100%',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis'
                              }}
                          >
                            {/* Hexcode Thnink Private Limited */}
                            {contactData.length > 0 ?
                                  contactData[0]?.contact_data?.org_name
                                  : ""}
                          </Heading>


                      </Box>

                   
                      <Box sx={{
                          position: 'relative',
                          margin: '24px 32px 0px 32px',
                          display: 'flex',
                          flexDirection: 'column',
                      }}>

                          <Text
                              sx={{
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.secondaryTextColor
                              }}
                          >Due date</Text>

                          

                          <Box sx={{
                            display:'flex',
                            flexDirection:'row',
                            marginTop:'6px',
                            
                          }}>
                                {taskDatas.length>0 ? 

                                  taskDatas[0].due_date!==null&&taskDatas[0].due_date!=="" ? 
                                     <>

                                          <Text
                                              sx={{
                                                  display: 'flex',
                                                  fontSize: '14px',
                                                  fontFamily: customThemVariable.primartFont,
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  color: customThemVariable.primaryTextColor,
                                                  lineHeight: '24px'
                                              }}
                                          >
                                              
                                              {formatDate(new Date(taskDatas[0].due_date))}
                                          </Text>
                                         

                                              {
                                                calculateDaysBetweenDates(new Date(),taskDatas[0].due_date) > 0 ?
                                                
                                                  <Box sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      height: '24px',
                                                      width: '24px',
                                                      color: '#22863A',
                                                  }}>
                                                      <ArrowUpIcon size={24} />
                                                  </Box>
                                                  :
                                                  <Box sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      height: '24px',
                                                      width: '24px',
                                                      color: '#E36209',
                                                  }}>
                                                      <ArrowDownIcon size={24} />
                                                  </Box>
                                              }

                                              
                                          
                                          <Text
                                              sx={{
                                                  display: 'flex',
                                                  fontSize: '14px',
                                                  fontFamily: customThemVariable.primartFont,
                                                  fontStyle: 'normal',
                                                  fontWeight: '400',
                                                  color: customThemVariable.primaryTextColor,
                                                  lineHeight: '24px'
                                              }}
                                          >
                                              {
                                                  calculateDaysBetweenDates(new Date(), taskDatas[0].due_date) > 0 ? calculateDaysBetweenDates(new Date(), taskDatas[0].due_date) + " days left"
                                                      :
                                                  Math.abs(parseInt(calculateDaysBetweenDates(new Date(), taskDatas[0].due_date))) + " days due"
                                              }

                                          </Text>
                                     
                                     </>
                                    
                                  :""
                                
                                
                                
                                :""}

                               




                          </Box>



                          


                      </Box>
                      {/* Owner section */}
                      <Box sx={{
                          position: 'relative',
                          margin: '26px 26px 0px 32px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems:'center'
                      }}>

                          

                          <Text
                              sx={{
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.secondaryTextColor
                              }}
                          >Owner</Text>

                          {ownerData.length>0 ?
                          
                             <>
                                 
                                  {ownerData[0].user_image_url === "" ?

                                      <Box sx={{
                                          display: 'flex',
                                          position: 'relative',
                                          margin: '0px auto 0px auto',

                                      }}>
                                          <Tooltip
                                              aria-label={ownerData[0].user_email}>
                                             <FeedPersonIcon size={25} fill="#586069" />
                                          </Tooltip>
                                      </Box>

                                      :
                                      <Tooltip
                                          aria-label={ownerData[0].user_email}
                                          sx={{
                                            position: 'absolute',
                                            left: '99px'
                                          }}
                                          >
                                          <Avatar
                                              sx={{
                                                 // position: 'absolute',
                                                 // left: '99px'
                                              }}
                                              size={24}
                                              src={ownerData[0].user_image_url}
                                          >
                                          </Avatar>
                                      </Tooltip>

                                  }
                                 
                                 <Tooltip
                                  aria-label={ownerData[0].user_email}
                                  direction="w"
                                  sx={{
                                    position: 'absolute',
                                    left: '138px',
                                    minWidth:'100%',
                                    maxWidth:'100%',
                                    width:'100%',
                                  //  overflow: 'hidden',
                                  ///  whiteSpace: 'nowrap',
                                  //  textOverflow: 'ellipsis'
                                }}
                                  >
                                  <Text
                                      sx={{
                                         // position: 'absolute',
                                        //  left: '138px',
                                          fontSize: '14px',
                                          fontFamily: customThemVariable.primartFont,
                                          fontStyle: 'normal',
                                          fontWeight: '400',
                                          color: customThemVariable.primaryTextColor,
                                          minWidth:'100%',
                                          maxWidth:'100%',
                                          width:'100%',
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                          textOverflow: 'ellipsis'
                                      }}
                                  >
                                    
                                   {
                                    ownerData[0].user_first_name+" "+ownerData[0].user_last_name
                                   }
                                   
                                  </Text> 
                                  </Tooltip>
                             
                                  
                             </>
                          
                          :""}

                           
                          
                      </Box>

                      {/* -------- */}

                       {/* subtask count section  */}

                       <Box sx={{
                          position: 'relative',
                          margin: '10px 32px 0px 32px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems:'center'
                      }}>

                          <Text
                              sx={{
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.secondaryTextColor
                              }}
                          >Subtask</Text>
                          <Text
                              sx={{
                                  position: 'absolute',
                                  left:'99px',
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.primaryTextColor
                              }}
                          >
                            
                           {subtaskArray.length}
                          </Text>  
                          
                      </Box>


                       {/* ------ */}

                       {/* created date section */}

                      <Box sx={{
                          position: 'relative',
                          margin: '10px 32px 0px 32px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                      }}>

                          <Text
                              sx={{
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.secondaryTextColor
                              }}
                          >Created</Text>

                          <Text
                              sx={{
                                  position: 'absolute',
                                  left: '99px',
                                  fontSize: '14px',
                                  fontFamily: customThemVariable.primartFont,
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  color: customThemVariable.primaryTextColor
                              }}
                          >

                              {taskDatas.length > 0 ?

                                  formatDate(new Date(taskDatas[0]?.created_timestamp))

                                  : ""}
                          </Text>

                      </Box>


                       {/* -- */}

                       {/* horizontal divider */}
                        
                        <Box sx={{
                            position: 'relative',
                            margin: '26px 0px 0px 0px',
                            background:'#E5E9EB',
                            height:'1px'
                        }}>
                         </Box> 
                     
                       {/*  */}


                       {/* search box */}

                        <Box sx={{
                              position: 'relative',
                              margin: '16px 16px 0px 16px',
                        }}>

                            <TextInput
                            leadingVisual={()=><Box sx={{display:'flex'}}>
                                  {/* <MagnifyingGlassIcon width={24} height={24} /> */}
                                  <SearchIcon size={18} />
                            </Box>}
                            sx={{
                               width:'259px',
                               minWidth:'259px',
                               maxWidth:'259px',
                               height:'32px',
                               minHeight:'32px',
                               maxHeight:'32px',
                               fontSize: '14px',
                               fontFamily: customThemVariable.primartFont,
                               fontStyle: 'normal',
                               fontWeight: '400',
                               color: customThemVariable.primaryTextColor,
                               borderRadius:'3px'
                            }}
                            placeholder="Search assignee "
                            onChange={(e)=>{
                                setsearchInput(e.target.value)
                            }}
                            />

                        </Box>

                       {/* -------- */}

                       {/* assigne list */}

                       <Box sx={{
                          position: 'relative',
                          margin: '4px 16px 0px 16px',
                          display:'flex',
                          flexDirection:'column',
                           
                       }}>

                          <Box sx={{
                              minWidth:'272px',
                              maxWidth:'272px',
                              width:'272px',
                              minHeight:'40px',
                              maxHeight:'40px',
                              height:'40px',
                              borderBottom:'1px solid #E5E9EB',
                              display:'flex',
                              flexDirection:'row',
                              
                          }}>
                              <Box sx={{
                                 minWidth:'128px',
                                 maxWidth:'128px',
                                 width:'128px',
                                 display:'flex',
                                 alignItems:'center',
                                }}>
                                  <Text sx={{
                                     marginLeft:'16px', 
                                     fontSize: '14px',
                                     fontFamily: customThemVariable.primartFont,
                                     fontStyle: 'normal',
                                     fontWeight: '400',
                                     color: customThemVariable.secondaryTextColor,
                                  }}>
                                      Name
                                  </Text>
                                 
                              </Box>
                              <Box sx={{
                                 minWidth:'72px',
                                 maxWidth:'72px',
                                 width:'72px',
                                 display:'flex',
                                 alignItems:'center',
                              }}>

                                  <Text sx={{
                                      marginLeft: '16px',
                                      fontSize: '14px',
                                      fontFamily: customThemVariable.primartFont,
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      color: customThemVariable.secondaryTextColor,
                                  }}>
                                      Score
                                  </Text>
                                 
                              </Box>
                              <Box sx={{
                                 minWidth:'72px',
                                 maxWidth:'72px',
                                 width:'72px',
                                 display:'flex',
                                 alignItems:'center',
                              }}>

                                  <Text sx={{
                                      marginLeft: '16px',
                                      fontSize: '14px',
                                      fontFamily: customThemVariable.primartFont,
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      color: customThemVariable.secondaryTextColor,
                                  }}>
                                      Days
                                  </Text>
                                 
                              </Box>
                          </Box>

                          {/* dynamic array lis */}

                          {collaborators
                          .filter((cDatat)=> searchInput.length===0? cDatat: cDatat.name.toLocaleUpperCase().includes(searchInput.toLocaleUpperCase())  )
                          .map((row,index)=>{
                                return(
                                    <Box sx={{
                                        minWidth:'272px',
                                        maxWidth:'272px',
                                        width:'272px',
                                        minHeight:'40px',
                                        maxHeight:'40px',
                                        height:'40px',
                                        borderBottom:'1px solid #E5E9EB',
                                        display:'flex',
                                        flexDirection:'row',
                                        
                                    }}>
                                        <Box sx={{
                                           minWidth:'128px',
                                           maxWidth:'128px',
                                           width:'128px',
                                           display:'flex',
                                           alignItems:'center',
                                          }}>
                                            <Tooltip aria-label={row.user_email}>
                                                <Text sx={{
                                                    marginLeft: '16px',
                                                    fontSize: '14px',
                                                    fontFamily: customThemVariable.primartFont,
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                    color: customThemVariable.primaryTextColor,
                                                }}>
                                                    {row.name}
                                                </Text>
                                            </Tooltip>
                                           
                                        </Box>
                                        <Box sx={{
                                           minWidth:'72px',
                                           maxWidth:'72px',
                                           width:'72px',
                                           display:'flex',
                                           alignItems:'center',
                                        }}>
          
                                            <Text sx={{
                                                position:'relative',
                                                margin:'0px 18px 0px auto', 
                                                fontSize: '14px',
                                                lineHeight:'24px',
                                                fontFamily: customThemVariable.primartFont,
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                color: customThemVariable.primaryTextColor,
                                            }}>
                                                {/* {row.score} */}
                                                {getAssigneScore(subtaskArray,row.id)+"%"}
                                            </Text>
                                           
                                        </Box>
                                        <Box sx={{
                                           minWidth:'72px',
                                           maxWidth:'72px',
                                           width:'72px',
                                           display:'flex',
                                           alignItems:'center',
                                        }}>
          
                                            <Text sx={{
                                                position:'relative',
                                                margin:'0px 23px 0px auto', 
                                                fontSize: '14px',
                                                lineHeight:'24px',
                                                fontFamily: customThemVariable.primartFont,
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                color: customThemVariable.primaryTextColor,
                                            }}>
                                                {/* {row.days} */}
                                                {getTotalDaysofSubtasks(subtaskArray,row.id)}
                                            </Text>
                                           
                                        </Box>
                                    </Box>
                                )
                          })}

                          {/* ------------------ */}
                          


                       </Box>

                       


                       {/* -------------- */}
                        {/* right side bar bottom space  */}
                      <Box sx={{
                          minWidth: '305px',
                          maxWidth: '305px',
                          minHeight: '36px',
                          maxHeight: '36px',
                         // background:'red'
                          background: '#F6F8FA',

                      }}>
                        
                      </Box>


                  </Box>
             
                 
           
        </Box>

        
        </Box>
       
      )
}
