import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"



export const scheduleDemoOpenClose = createAsyncThunk("openCloseScheduleDemo", async (data) => {

    return ({ openClose: data.openClose, title: data.title })

})




export const scheduleDemoOpenCloseSlice = createSlice({

    name: 'scheduleDemoOpenClose',
    initialState: {
        openClose: false,
        title: '',
        loading: false,

    },
    extraReducers: {

        [scheduleDemoOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [scheduleDemoOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose
            state.title = action.payload.title

        },
        [scheduleDemoOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },

    }


})


export default scheduleDemoOpenCloseSlice.reducer

