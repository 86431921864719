import React from "react"
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Text,Button,Spinner,Link } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import Workplacetheme from "../Workplacetheme";
import Papa from "papaparse";
import { Toaster, Intent,Position,ProgressBar } from "@blueprintjs/core";
import { reloadCompaniesTrigger, uploadBulkComanyOpenClose } from "../../redux/features/contactSlice";
import { UploadIcon } from "@primer/octicons-react";
import { postContactDirectoryBulk } from "../../redux/features/taskSlice";
import { Helmet } from "react-helmet";
//import * as XLSX from 'https://unpkg.com/xlsx/xlsx.mjs'; 

const synchingToast = Toaster.create({ position: Position.TOP })
//const excelUrl = 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/companyBulk.xlsx'
const excelUrl  = "https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/ExcelFiles/companyBulk.xlsx"

function BulkCompanyUploadDialog(props) {

    const XLSX = window.XLSX

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const bulkCompanyOpenClose = useSelector((state) => state.contactProfileData.bulkCompanyOpenClose)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()
    const computerFileRef = React.useRef()
    const [selectedExcelFile,setselectedExcelFile] = React.useState([]) 
    const [selectedFileData,setselectedFileData] = React.useState([])

    const closeUploadBulkDialog = (props) => {

        dispatch(uploadBulkComanyOpenClose({
            bulkCompanyOpenClose: false
        }))

    }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 2000,


        }, key);

    }

    
    const handleDownloadExcel = (props) => {

          var link = document.createElement('a')
          link.href = excelUrl
          link.setAttribute('download', 'companybulkupload.xlsx'); // Specify the desired file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  

    }

    function processWorkbook(workbook) {
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // console.log(jsonData);

        const dataObjects = jsonData.map((row) => {
            const obj = {};
            row.forEach((cell, index) => {
                const header = jsonData[0][index]; // Assuming first row contains headers
                obj[header] = cell;
            });
            return obj;
        });

        return dataObjects
    }

      

    const handlePickComputerFile = async (selectedFile) => {

        setselectedExcelFile(selectedFile)
        console.log("selectedFile", selectedFile)
        // const file = selectedFile;
        // loadingToast(10,"1")
        // if (file) {
        //     setselectedFileData([])
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         try {
        //             const data = new Uint8Array(e.target.result);
        //             const workbook = XLSX.read(data, { type: 'array' });
                    
        //             console.log("workbook",workbook)

        //             // Optimize the processWorkbook function if needed
        //             const results = [...processWorkbook(workbook)]
        //                 .filter(org => Object.keys(org).length > 0)
        //                 .map((row) => ({
        //                     ...row,
        //                     id: Math.floor(10000000 + Math.random() * 90000000),
        //                     compliances: [],
        //                 }));

        //             console.log("results", results);

        //             // Use slice(1) to skip the header if it exists
        //             const processesArray = results.slice(1);
        //             console.log("processesArray", processesArray);
        //             setselectedFileData(processesArray);
        //             loadingToast(100,"1")
        //             computerFileRef.current=""
        //         } catch (error) {
        //             console.error("Error processing the file:", error);
        //         }
        //     };
        //     reader.readAsArrayBuffer(file);
        // }




        // const file = selectedFile;
        // const reader = new FileReader();

        // reader.onload = function (e) {
        //     const data = new Uint8Array(e.target.result);
        //     const workbook = window.XLSX.read(data, { type: 'array' });
        //     const results = processWorkbook(workbook);
        //     console.log("rishad1", results)
        //     const processesArray = [...results.slice(1)].map((row, index) => {

        //         const orgno = row?.CIN || ""
        //         const panNo = row?.PAN || ""
        //         const additionalInformation = row?.AdditionalInformation || ""
        //         const organization = row?.Organization || ""
        //         const organizationType = row?.OrganizationType || ""
        //         const gstIn = row?.GSTIN || "" 
        //         const state = row?.State || ""
        //         const addressline1 = row?.Addressline1 || ""
        //         const addressline2 = row?.Addressline2 || ""
        //         const addressline3 = row?.Addressline3 || ""
        //         const postal = row?.Postal || ""
        //         const stateProvince = row?.StateProvince || ""
        //         var incorporationDate = row?.IncorporationDate || ""
        //         incorporationDate = excelDateToJSDate(incorporationDate)

        //         return ({
        //             category: [],
        //             completedCount: "0",
        //             compliances: [],
        //             contact_data: {
        //                 "org_no": ""+orgno+"",
        //                 "org_pan": ""+panNo+ "",
        //                 "org_city": "",
        //                 "org_name": ""+organization+"",
        //                 "org_type": "" +organizationType+ "",
        //                 "org_gstNo": "" +gstIn+ "",
        //                 "org_state": "" +state+ "",
        //                 "org_address_line1": "" + addressline1 + "",
        //                 "org_address_line2": "" + addressline2 + "",
        //                 "org_address_line3": "" + addressline3 + "",
        //                 "org_no_validation": "",
        //                 "org_pan_validation": "",
        //                 "org_postal_zipcode": ""+postal+"",
        //                 "org_state_province": "" +stateProvince+ "",
        //                 "org_additional_info": "" +additionalInformation+ "",
        //                 "org_name_validation": "",
        //                 "org_state_validation": "",
        //                 "org_incorporation_date": ""+incorporationDate+"",
        //                 "org_address_line1_validation": "",
        //                 "org_additional_info_validation": "",
        //                 "org_incorporation_date_validation": ""
        //             },
        //             contact_id: '',
        //             credentials: [],
        //             directors_info: [],
        //             image_url: null,
        //             inProgressCount: "0",
        //             openTaskCount: "0",
        //             org_files: [],
        //             overdueCount: "0",
        //             project_id: localStorage.getItem("projectId"),
        //             timestamp: "" + new Date() + ""
        //         })

        //     })

        //     console.log("rishad2", processesArray)
        //     setselectedFileData(processesArray)

        // };

        // reader.readAsArrayBuffer(file);

    }

    const postContactDirectoryBulkIndb = (props) => {
       
        closeUploadBulkDialog("")
        const file = props;
        loadingToast(10, "1")
        if (file) {
            setselectedFileData([])
            const reader = new FileReader();
            console.log("before reading")
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });

                    console.log("workbook", workbook)

                    // Optimize the processWorkbook function if needed
                    const results = [...processWorkbook(workbook)]
                        .filter(org => Object.keys(org).length > 0)
                        .map((row) => ({
                            ...row,
                            id: Math.floor(10000000 + Math.random() * 90000000),
                            compliances: [],
                        }));

                    console.log("results", results);

                    // Use slice(1) to skip the header if it exists
                    const processesArray = results.slice(1);
                    console.log("processesArray", processesArray);
                    setselectedFileData(processesArray);
                    setselectedExcelFile([])
                    loadingToast(100, "1")
                   
                    const newBulkCompanies = [...processesArray]
                    localStorage.setItem("bulkType", "Company")
                    localStorage.setItem("newBulkCompanies", JSON.stringify(newBulkCompanies))
                    navigate('/clientbulk')

                   
                } catch (error) {
                    console.error("Error processing the file:", error);
                }
            };
            console.log("after reading")
            reader.readAsArrayBuffer(file);
            console.log("final reading")
        }
             
        console.log("selectedFileData",selectedFileData)

       
         

              

            
        //    const newBulkCompanies = [...props]
          
        //    localStorage.setItem("newBulkCompanies",JSON.stringify(newBulkCompanies))
        //    navigate('/clientbulk')


        // setshowLoadingBtn(true)
        // loadingToast(10, "1")
        // loadingToast(40, "1")
        // dispatch(postContactDirectoryBulk({
        //     userId: userId,
        //     userEmail: userEmail,
        //     "contactType": "",
        //     "contactData": "",
        //     "directorsInfo": "",
        //     "categories": "",
        //     "compliances": "",
        //     "projectId": localStorage.getItem("projectId"),
        //     "bulkCompanyData": props
        // })).then((data) => {

        //     console.log("postContactDirectoryBulk", data.payload)
        //     loadingToast(80, "1")

           


        //     if (data.payload.statusCode == 200) {

        //          if(data?.payload?.data.length==0){

        //             loadingToast(10, "1")

        //             Toaster.create({ position: Position.TOP }).show({
        //                 message: "Incorrect Excel format",
        //                 intent: Intent.PRIMARY
        //             });

        //          }



        //         if (data?.payload?.data.length === props.length) {

                    

        //             loadingToast(100, "1")
        //         }
        //         dispatch(reloadCompaniesTrigger({
        //             reloadCompanies: true
        //         })
        //         )
        //         setselectedFileData([])
        //         setshowLoadingBtn(false)
        //         loadingToast(100, "1")

        //     }
        // })


    }

    
    return (

        <Dialog
            sx={{
                height:'315px',
                width:'564px',
                 
            }}
            isOpen={bulkCompanyOpenClose}
            onDismiss={() =>

                closeUploadBulkDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Helmet>
                {/* <script src="https://unpkg.com/xlsx/dist/xlsx.full.min.js"></script> */}
            </Helmet>
            <Dialog.Header id="header-id">
                <Text 
                sx={Workplacetheme.scheduleDalogeHeading}
                ref={initialFocusRef}
                >Upload Companies in bulk using an Excel sheet.</Text>

            </Dialog.Header>

            <Box sx={{
                  display:'flex',
                  flexDirection:'column',
                  width:564,
                  height:'fit-content',
                  
            }}>

               
                  <Box sx={{
                    position:'relative',
                    margin:'25px auto 0px auto',
                    height:'100px',
                    width:'100px',
                    minHeight:'100px',
                    minWidth:'100px',
                    background:'#FAFBFC'
                  }}>

                    <Box sx={{
                          width:'100px',
                          minHeight:'100px',
                          minWidth:'100px',
                          borderRadius:'50%',
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center',
                          border:'1px solid rgba(27, 31, 36, 0.15)',
                          cursor:'pointer'
                    }}
                    onClick={()=>{
                        computerFileRef.current.click()  
                    }}
                    >
                          <UploadIcon scale={32} style={{display:'flex',height:'32px',width:'32px'}} />
                    </Box>
                    <input 
                    ref={computerFileRef} 
                    type={"file"} 
                   // accept=".csv, text/csv"  
                    accept=".xlsx"
                    onChange={(e) => handlePickComputerFile(e.target.files[0])} 
                    hidden />


                  </Box>

                  <Text
                  sx={{
                      position:'relative',
                      margin:'34px auto 0px auto',
                      fontFamily:'Segoe UI',
                      fontSize:'12px',
                      fontStyle:'normal',
                      fontWeight:'600',
                      lineHeight:'36px'
                  }}
                  >
                      Upload or drag and drop a file
                  </Text>

               

                

                <Link 
                sx={{
                    position:'relative',
                    margin:'-19px 20px 10px 20px',
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'400',
                    fontSize:'14px',
                    lineHeight:'20px',
                    color:'#24292F'
                }}
                underline={true}
                href="#"
                onClick={()=>{
                     handleDownloadExcel("")
                }}
                >Download Excel template</Link>

                <Box

                    sx={Workplacetheme.createSpaceDialogFooterLine}

                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid">

                        
                    </Box>





            </Box>

            <Box
                ml={24}
                mr={24}
                mb={10}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button
                            style={{width:'78px'}}
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() =>  
                             {
                                console.log("selectedExcelFile", selectedExcelFile)

                                if (selectedExcelFile?.size > 0) {

                                    postContactDirectoryBulkIndb(selectedExcelFile)
                                } else {
                                    Toaster.create({ position: Position.TOP }).show({
                                        message: "Select File",
                                        intent: Intent.PRIMARY
                                    });

                                }


                            //    console.log("selectedFileDataonclick",selectedFileData)
                            //    if(selectedFileData.length > 0){
                            //         closeUploadBulkDialog("")
                            //         postContactDirectoryBulkIndb(selectedFileData)
                            //    }else{
                            //       Toaster.create({ position: Position.TOP }).show({
                            //         message: "No data in Excel",
                            //         intent: Intent.PRIMARY
                            //       });
                            //       closeUploadBulkDialog("")
                            //    }
                             }
                            }
                        >
                            Upload

                        </Button>


                }

               

                


            </Box>





        </Dialog>

    )
}

export default BulkCompanyUploadDialog