import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Box, Text, Button, Heading, ActionList, ActionMenu, IconButton, TextInput } from "@primer/react"
import { ChevronDownIcon, DotsHorizontalIcon, Share2Icon, PlusIcon, CheckIcon, Pencil1Icon } from '@radix-ui/react-icons';
import { createBrowserHistory } from 'history';
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import SideNav from './SideNav'
import { Auth } from 'aws-amplify';
import workplaceTheme from './Workplacetheme';
import Header from './Header';
import Workplacetheme from './Workplacetheme';
import { createSpaceOpenClose, deleteWorkplaceOpenClose, getAllWorkplaceProject } from "../redux/features/spaceSlice"
import { sharingOpenClose } from "../redux/features/sharingSlice"
import { moveWorkplaceOpenClose, createWorkplace, getAllWorkplace, updateWorkplaceName, updateWorkplaceSortTrigger } from "../redux/features/workplaceSlice"
import CreateSpaceDialog from './Dialogs/CreateSpaceDialog';
import DeleteWorkplaceDialog from './Dialogs/DeleteWorkplaceDialog';
import ShareDialog from './Dialogs/ShareDialog';
import MoveSpaceDialog from './Dialogs/MoveSpaceDialog'
import SearchDialog from './Dialogs/SearchDialog';
import { getUserSubscriptionData } from '../redux/features/profileSlice';
import LoadingSpinnerLarge from '../LoadingSpinnerLarge';
import SpaceComponent from './SpaceComponent';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { getFeedData } from '../redux/features/feedSlice';
mixpanel.init('42f38a9f4804449b88177fb8bbfec5c6', { debug: true, ip: true });

function Workplace() {

    const history = createBrowserHistory()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const searchData = useSelector((state) => state.searchData)

    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)   
    const workspaceDeleteTrigger = useSelector((state) => state.workplaceData.workspaceDeleteTrigger)

    const wSpaces = useSelector((state) => state.workplaceData?.workplace || [])


    const [showSpinner, setShowSpinner] = React.useState(false)
    const [workplaceNameHovered, setworkplaceNameHovered] = React.useState({
        workplaceId: ''
    })
    const [isWorkplaceNameInput, setisWorkplaceNameInput] = React.useState({
        workplaceId: '',
        workplaceName: '',
        visibility: false,
        isLoading: false,
        trailingVistual: ''

    })

    const [workplace, setworkplace] = React.useState([...wSpaces])
   

    const updateSortTrigger = (props) => {

        dispatch(updateWorkplaceSortTrigger({
            workplaceSortTrigger: props
        }))

    }

    const handleSort = (props) => {


        updateSortTrigger(true)

        setShowSpinner(true)
        if (props === "Oldest to newest") {

            setworkplace((prevState) => {
                const asortedData = [...prevState]
                asortedData.sort((a, b) => new Date(a.workplace_create_date) - new Date(b.workplace_create_date))
                setShowSpinner(false)

                return asortedData
            })


            setTimeout(() => {
                updateSortTrigger(false)
            }, 1000)



        } else if (props === "Newest to oldest") {

            setworkplace((prevState) => {
                const asortedData = [...prevState]
                asortedData.sort((a, b) => new Date(b.workplace_create_date) - new Date(a.workplace_create_date))
                setShowSpinner(false)

                return asortedData
            })

            setTimeout(() => {
                updateSortTrigger(false)
            }, 1000)

        } else if (props === "Alphabetical") {


            setworkplace((prevState) => {

                const asortedData = [...prevState]

                asortedData.sort(function (a, b) {
                    if (a.workplace_name < b.workplace_name) { return -1; }
                    if (a.workplace_name > b.workplace_name) { return 1; }
                    return 0;
                })



                setShowSpinner(false)

                return asortedData
            })

            setTimeout(() => {
                updateSortTrigger(false)
            }, 1000)
        } else if (props === "Opened by you") {

            setShowSpinner(false)
        }




    }



    const showMoveWorkplaceDialog = (props) => {

        dispatch(moveWorkplaceOpenClose({
            openClose: true
        }))

    }

    const showSharingDialog = (props) => {



        dispatch(sharingOpenClose({
            openClose: true,
            workplaceId: props.workplaceId,
            shareType: props.shareType,
            projectId: props.projectId,
            title: props.title
        }))

    }

 
    const showDeleteSpaceDialog = (props) => {

        dispatch(deleteWorkplaceOpenClose({
            openClose: true,
            deleteWorkplaceData: {
                workplaceId: props.workplace_id,
                workplaceName: props.workplace_name
            },
            deleteSpaceData: {
                projectId: '',
                projectName: ''
            }
        })).then((data) => {
             
        })
    }


    const createNewWorkplace = (userId, userEmail) => {



        setShowSpinner(true)
        dispatch(createWorkplace({
            "userId": userId,
            "userEmail": userEmail,
            "workspaceName": "workplace " + ((workplace?.length) + 1)
        })).then((data) => {


             if (data.payload.message === "Workplace created successfully") {
                setShowSpinner(false)
                localStorage.setItem("workplaceStatus", "create")
                localStorage.setItem("workplaceId", data.payload.data[0]?.workplace_id)
                navigate('/createWorkplace')
            }


        })



    }

    const updateWorkplaceNames = (e) => {


        setisWorkplaceNameInput((prevState) => {

            const newState = { ...prevState, workplaceName: e.target.value, isLoading: true }
            return newState
        })


        setworkplace((prevState) => {

            const newState = prevState.map(
                wData => wData.workplace_id === isWorkplaceNameInput.workplaceId ? { ...wData, workplace_name: e.target.value } : wData
            )

            return newState

        })


        dispatch(updateWorkplaceName({

            userId: userId,
            userEmail: userEmail,
            workplaceId: isWorkplaceNameInput.workplaceId,
            workplaceName: e.target.value
        })).then((data) => {

           

            setisWorkplaceNameInput((prevState) => {

                const newState = { ...prevState, isLoading: false, trailingVistual: <CheckIcon /> }
                return newState
            })


        })


    }

    const trackActiveUsers = async (userId, userEmail) => {


       
        const res = await axios.get('https://geolocation-db.com/json/')
        const sourceIp = res.data.IPv4

        const trackActiveUser = mixpanel.track('ActiveUser', {
            distinct_id: userId,
            ip: sourceIp,
            time: new Date().getTime(),
        })

       

    }




    React.useState(() => {



        setShowSpinner(true)

        dispatch(getAllWorkplace({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {
            setShowSpinner(false)
          

            var sortedArray = [...data.payload.data]?.sort((a, b) => new Date(b.workplace_create_date) - new Date(a.workplace_create_date))


            setworkplace(data.payload.data)
        })




        trackActiveUsers(userId, userEmail)

        dispatch(getFeedData({
            userId: userId,
            userEmail: userEmail
        }))

    })

    React.useEffect(() => {

        if (searchData.openClose === true) {

            dispatch(getAllWorkplaceProject({
                userId: userId,
                userEmail: userEmail
            }))

        }


    }, [searchData.openClose])


    React.useEffect(() => {


        setShowSpinner(true)
        dispatch(getAllWorkplace({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {
            setShowSpinner(false)
            setworkplace(data.payload.data)
        })
        // dispatch(getAllWorkplaceProject({
        //     userId: userId,
        //     userEmail: userEmail
        // }))

    }, [workspaceDeleteTrigger])


    React.useState(() => {

 
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {

             dispatch(getUserSubscriptionData({
                userEmail: user.attributes.email
            }))



        }).catch(error => {


        })



    })

    return (<Box
        position={"relative"}
        display="flex"
        background={"#F6F8FA"}
        height="100vh"
        width={"100vw"}
        overflowY={"auto"}

    >
        <Helmet>
            <title>
                Workplace - deski

            </title>
        </Helmet>

        <Header />
        <SideNav />


        <Box sx={Workplacetheme.workplaceMain}

        >



            <Box
                sx={Workplacetheme.workplaceMainSubheader}
            >
                <Heading sx={Workplacetheme.workplaceMainHeading}>All workspaces

                </Heading>



                <Button variant="outline" sx={Workplacetheme.createWorkplaceBtn}
                    onClick={() => {


                        createNewWorkplace(userId, userEmail)

                    }

                    }
                >Create workspace</Button>
            </Box>



            <ActionMenu>
                <ActionMenu.Anchor >
                    <Button
                        variant="invisible"
                        trailingIcon={() => <ChevronDownIcon
                            style={workplaceTheme.worplaceOpenByText.dropDownIcon} />}
                        sx={workplaceTheme.worplaceOpenByText}
                    >
                        Opened by you
                    </Button>
                </ActionMenu.Anchor>

                <ActionMenu.Overlay align="end">
                    <ActionList sx={workplaceTheme.profileActionMenuList}>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => {
                                handleSort("Opened by you")
                            }}
                        >
                            <ActionList.LeadingVisual>
                                <CheckIcon />
                            </ActionList.LeadingVisual>

                            Opened by you
                        </ActionList.Item>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => {
                                handleSort("Alphabetical")
                            }}
                        >
                            <ActionList.LeadingVisual>

                            </ActionList.LeadingVisual>
                            Alphabetical</ActionList.Item>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => {
                                handleSort("Oldest to newest")
                            }}
                        >
                            <ActionList.LeadingVisual>

                            </ActionList.LeadingVisual>
                            Oldest to newest </ActionList.Item>
                        <ActionList.Item
                            sx={workplaceTheme.profileActionMenuListItem}
                            onSelect={() => {
                                handleSort("Newest to oldest")
                            }}
                        >
                            <ActionList.LeadingVisual>

                            </ActionList.LeadingVisual>
                            Newest to oldest
                        </ActionList.Item>

                    </ActionList>
                </ActionMenu.Overlay>
            </ActionMenu>




            <Box sx={workplaceTheme.workplaceListMainBox}>



                {
                    workplace?.map((row, index) => {


                        return (

                            <Box sx={workplaceTheme.workplaceListItemBox}>

                                {
                                    isWorkplaceNameInput.visibility === true && isWorkplaceNameInput.workplaceId === row.workplace_id ?

                                        <Box
                                            sx={{
                                                width: 'fit-content',
                                                height: '52px',
                                                minHeight: '52px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                position: 'relative',
                                                margin: '11px 0px 0px 30px',

                                            }}
                                        >
                                            <TextInput
                                                style={{ visibility: isWorkplaceNameInput.visibility === true && isWorkplaceNameInput.workplaceId === row.workplace_id ? 'visible' : 'hidden' }}
                                                sx={workplaceTheme.WorkplaceNameInput}
                                                autoFocus
                                                placeholder="Add Workplace name"
                                                value={isWorkplaceNameInput.workplaceName}
                                                onChange={(e) => updateWorkplaceNames(e)}
                                                onBlur={(e) =>
                                                    setisWorkplaceNameInput((prevState) => {

                                                        const newState = {
                                                            ...prevState,
                                                            workplaceId: '',
                                                            workplaceName: '',
                                                            visibility: false,
                                                            trailingVistual: '',

                                                        }
                                                        return newState
                                                    })
                                                }
                                                trailingVisual={isWorkplaceNameInput.trailingVistual}
                                                loading={isWorkplaceNameInput.isLoading}
                                            />
                                        </Box>
                                        :

                                        <Box
                                            onMouseEnter={() => setworkplaceNameHovered((prevState) => {
                                                const newState = { ...prevState, workplaceId: row.workplace_id }
                                                return newState
                                            })}
                                            onMouseLeave={() =>
                                                setworkplaceNameHovered((prevState) => {
                                                    const newState = { ...prevState, workplaceId: '' }
                                                    return newState
                                                })
                                            }
                                            sx={{
                                                width: 'fit-content',
                                                height: 'fit-content',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                position: 'relative',
                                                margin: '11px 0px 0px 30px',

                                            }}>
                                            <Heading
                                                sx={workplaceTheme.workplaceNameTxt}
                                                onClick={() => {
                                                    setisWorkplaceNameInput((prevState) => {

                                                        const newState = {
                                                            ...prevState,
                                                            workplaceId: row.workplace_id,
                                                            workplaceName: row.workplace_name,
                                                            visibility: true,

                                                        }
                                                        return newState
                                                    })
                                                }}
                                            >
                                                {row.workplace_name}
                                            </Heading>
                                            <IconButton
                                                sx={{
                                                    height: '30px',
                                                    width: '30px',
                                                    display: workplaceNameHovered.workplaceId === row.workplace_id ? 'flex' : 'none',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    margin: '0px 0px 0px 10px',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                    borderRadius: '3px',
                                                }}
                                                icon={() => <Pencil1Icon style={{ display: 'flex' }} />}
                                                onClick={() => {
                                                    setisWorkplaceNameInput((prevState) => {

                                                        const newState = {
                                                            ...prevState,
                                                            workplaceId: row.workplace_id,
                                                            workplaceName: row.workplace_name,
                                                            visibility: true,

                                                        }
                                                        return newState
                                                    })
                                                }}

                                            />
                                        </Box>



                                }



                                <ActionMenu>
                                    <ActionMenu.Anchor >
                                        <IconButton
                                            size="small"
                                            variant="invisible"
                                            sx={workplaceTheme.workplaceSettingsIcon}
                                            icon={() => <DotsHorizontalIcon />} />

                                    </ActionMenu.Anchor>

                                    <ActionMenu.Overlay>
                                        <ActionList sx={workplaceTheme.profileActionMenuList}>
                                            <ActionList.Item
                                                onSelect={event => showSharingDialog({
                                                    workplaceId: row.workplace_id,
                                                    shareType: 'workplace',
                                                    projectId: '',
                                                    title: row.workplace_name,

                                                })}
                                                sx={workplaceTheme.profileActionMenuListItem}>Share workspace</ActionList.Item>
                                            <ActionList.Item sx={workplaceTheme.profileActionMenuListItem}>Settings</ActionList.Item>
                                            <ActionList.Divider />
                                            <ActionList.Item
                                                onSelect={event => showDeleteSpaceDialog(row)}
                                                variant="danger" sx={workplaceTheme.profileActionMenuListItemDanger}>Delete</ActionList.Item>

                                        </ActionList>
                                    </ActionMenu.Overlay>
                                </ActionMenu>

                                <IconButton
                                    size="small"
                                    variant="invisible"
                                    sx={workplaceTheme.workplaceShareIcon}
                                    icon={() => <Share2Icon />}
                                    onClick={() => showSharingDialog({
                                        workplaceId: row.workplace_id,
                                        shareType: 'workplace',
                                        projectId: '',
                                        title: row.workplace_name,
                                    })}
                                />


                                <SpaceComponent workplaceId={row.workplace_id} isPlusButton={true} />





                            </Box>
                        )
                    })
                }







            </Box>






            <Box>





            </Box>





        </Box>


        <LoadingSpinnerLarge showSpinner={showSpinner} />

        <CreateSpaceDialog />
        <DeleteWorkplaceDialog />
        <ShareDialog />
        <MoveSpaceDialog />
        <SearchDialog />

    </Box>)

}

export default Workplace
