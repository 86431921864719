import Header from "../Header"
import { useNavigate } from 'react-router-dom';
import { Box, Text, TextInput, Button, Heading, Select } from "@primer/react"
import SignIntheme from "./SignIntheme"
import React from "react"


function ResetPassword() {

    const navigate = useNavigate();

    const [emailValidation,setemailValidation] = React.useState("")
    const [emailInput, setemailInput] = React.useState("")
    const [isVerificationSend,setisisVerificationSend] = React.useState(false)

    
    const handlesendRecoverSubmitBtn = (userEmail) => {
 
           if(userEmail===""){
            setemailValidation("error")
           }else{
            setemailValidation("")
            setisisVerificationSend(true)
           }       
    }



    const handleSubmitBtn = (userEmail, verificationCode) => {


           navigate('/workplace')

    }
    const forgetPasswordComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Heading sx={SignIntheme.loginPageBoxHeading}>Reset password</Heading>
        <Text
           sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">
            Choose a new password
            
        </Text>
        <TextInput
            validationStatus={emailValidation}
            sx={SignIntheme.verificationCodeInput}
            name="verificationCode"
            placeholder="Enter your new password "
            type={"password"}
            onChange={(e)=> setemailInput(e.target.value)}
          />
        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handlesendRecoverSubmitBtn(emailInput)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}
            >

                Continue
            </Text>
        </Button>

        <Box
            sx={SignIntheme.verificationloginBoxBottomBox}

        >
            <Text
                as="p"
                sx={SignIntheme.loginBoxBottomBox.signUpText}
                onClick={()=> navigate('/') }
            >
                Return to log in
            </Text>



        </Box>


    </Box>

    const linkSendSuccessComponent = <Box sx={SignIntheme.ResetPasswordPageBox}>
    <Text sx={SignIntheme.successpageImoge} >🎉</Text>
    <Heading sx={SignIntheme.EmailVerificationPageBoxHeading}>Password successfully Changed</Heading>
    <Text
        sx={SignIntheme.emailverificationSuccessSubpara}
        as="p">Congratulations!<br></br>
        Your password has been changed successfully.</Text>
 
    <Button
        variant="outline"
        type="button"
        aria-selected={true}
        sx={SignIntheme.continueBtn}
        onClick={() => handleSubmitBtn("", "")}
    >
        <Text
            as="p"
            color="#FFFFFF"
            sx={SignIntheme.continueBtnText}

        >

            Continue to workspace
        </Text>
    </Button>




</Box>



    return (

        <Box>

            <Header />

            <Box sx={SignIntheme.loginMain}>
                 {isVerificationSend===false ? forgetPasswordComponent: linkSendSuccessComponent}
            </Box>

        </Box>

    )

}
export default ResetPassword
