import Header from "../Header"
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Box, Text, TextInput, Button, Heading, Select } from "@primer/react"
import SignIntheme from "./SignIntheme"
import React from "react"
import { Auth } from 'aws-amplify';
import LoadingSpinner from "../LoadingSpinner";


function ForgetPassword() {

    const navigate = useNavigate();

    const [showSpinner,setshowSpinner] = React.useState(false)

    const [verificationCodeError,setverificationCodeError]  = React.useState("")

    const [confirmationCode,setconfirmationCode] = React.useState("")
    const [confirmationCodeValidation,setconfirmationCodeValidation] = React.useState("")

    const [newPassword,setnewPassword] = React.useState("")
    const [newPasswordValidation,setnewPasswordValidation] = React.useState("")

    const [emailValidation,setemailValidation] = React.useState("")
    const [emailInput, setemailInput] = React.useState("")
    const [isVerificationSend,setisisVerificationSend] = React.useState("0")

    
    const handlesendRecoverSubmitBtn =async (userEmail) => {
 
           if(userEmail===""){
            setemailValidation("error")
           }else{

 

               Auth.forgotPassword(userEmail)
                   .then(data => {
                        console.log("recovevry code send response", data)
                   })
                   .catch(err => {
                       console.log("recovery code send erro", err)
               });

              setemailValidation("")
              setisisVerificationSend("1")
           }       
    }


    const resendConfirmationCode =async (useEmail) => {

        try {
            await Auth.resendSignUp(useEmail);
            
        } catch (err) {
            
        }

    }


    const handlecontinueBtn = (userEmail,verificationCode) => {

          if(verificationCode===""){

            setconfirmationCodeValidation("error")
          }else{
            setisisVerificationSend("2")
          }
           

    }

    const handlenewPasswordSubmitBtn = (userEmail,confirmationCode,newPassword) =>{

             if(newPassword===""){

                setnewPasswordValidation("error")
             }else{

              
         
                setshowSpinner(true)


                Auth.forgotPasswordSubmit(userEmail, confirmationCode, newPassword)
                .then(data => 
                    
                    {
                         
                        if(data==="SUCCESS"){
                            setverificationCodeError("")
                            setshowSpinner(false)
                            setisisVerificationSend("3")
                           
                        }else{

                            setverificationCodeError("Invalid verification code")
                        }
                    }
                
                )
                .catch(err => 
                    
                    console.log("password change error",err)
                
                );
                  

              

             }
             

            

    }



    const handleSubmitBtn = (userEmail, userPassword) => {


        Auth.signIn(userEmail, userPassword)
        .then(user => {

               // history.push('/initialization')  
               //    navigate('/workplace')
               navigate('/initialization')
            
        }).catch(e => {
           
        });


          

    }



    const forgetPasswordComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Heading sx={SignIntheme.loginPageBoxHeading}>Forgot password</Heading>
        <Text
           sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">
            We'll send a recovery link to
            
        </Text>
        <TextInput
            validationStatus={emailValidation}
            sx={SignIntheme.verificationCodeInput}
            name="verificationCode"
            placeholder="Enter email"
            type={"email"}
            value={emailInput}
            onChange={(e)=> setemailInput(e.target.value)}
          />
        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handlesendRecoverSubmitBtn(emailInput)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}
            >

                Send verification code to email
            </Text>
        </Button>

        <Box
            sx={SignIntheme.verificationloginBoxBottomBox}
          
        >
            <Text
                as="p"
                sx={SignIntheme.loginBoxBottomBox.signUpText}
                onClick={()=> navigate('/') }
            >
                Return to log in
                
            </Text>



        </Box>


    </Box>


    const verificationCodeComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Heading sx={SignIntheme.loginPageBoxHeading}>Forgot password</Heading>
        <Text
            sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">
            We'll send a confirmation code to {emailInput}

        </Text>
        <TextInput
            validationStatus={confirmationCodeValidation}
            sx={SignIntheme.verificationCodeInput}
            name="verificationCode"
            placeholder="Enter verification code"
            type={"text"}
            value={confirmationCode}
            onChange={(e) => setconfirmationCode(e.target.value)}
        />
        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handlecontinueBtn(emailInput,confirmationCode)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}
            >

                Continue with verification code
            </Text>
        </Button>

        <Box
            sx={SignIntheme.verificationloginBoxBottomBox}

        >
            <Text
                as="p"
                sx={SignIntheme.loginBoxBottomBox.signUpText}
                onClick={()=> resendConfirmationCode(emailInput) }
            >
                Resend code
            </Text>



        </Box>


    </Box>



    const confirmationCodeSendSuccessComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
    <Text sx={SignIntheme.successpageImoge} >🎉</Text>
    <Heading sx={SignIntheme.EmailVerificationPageBoxHeading}>Forgot password</Heading>
    <Text
            sx={SignIntheme.forgotpasswordSubpara}
            as="p">
            We sent a recovery link to you at<br></br>
            <Text
                sx={SignIntheme.forgotpasswordSubpara.email}
            >{emailInput}</Text>
        </Text>
        <Text
          sx={SignIntheme.forgotpasswordImoge}
        
        >
        📬
        </Text>
       
       <Box
            sx={SignIntheme.forgotpasswordBottomBox}

        >
            <Text
                as="p"
                sx={SignIntheme.forgotpasswordBottomBox.signUpText}

            >
                Return to log in
            </Text>
            <Text
                as="p"
                sx={SignIntheme.forgotpasswordBottomBox.forgotPasswordText}
            >
                Resend recovery link
            </Text>


        </Box>



  
    </Box>


    const newPasswordComponent = <Box sx={SignIntheme.EmailVerificationPageBox}>
        <Heading sx={SignIntheme.loginPageBoxHeading}>Reset password</Heading>
        {verificationCodeError === "" ? "" :

            <Text
                sx={SignIntheme.signupUserExits}
                color="danger.fg">
                {verificationCodeError}

            </Text>

        }
        <Text
            sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">
            Choose a new password

        </Text>
        <TextInput
            validationStatus={newPasswordValidation}
            sx={SignIntheme.verificationCodeInput}
            name="newPassword"
            placeholder="Enter your new password "
            type={"password"}
            value={newPassword}
            onChange={(e) => setnewPassword(e.target.value)}
        />
        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handlenewPasswordSubmitBtn(emailInput,confirmationCode,newPassword)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}
            >

                Continue
            </Text>
        </Button>

        <Box
            sx={SignIntheme.verificationloginBoxBottomBox}

        >
            <Text
                as="p"
                sx={SignIntheme.loginBoxBottomBox.signUpText}
                onClick={() => navigate('/')}
            >
                Return to log in
            </Text>



        </Box>


    </Box>

    const changePasswordSuccessComponent = <Box sx={SignIntheme.ResetPasswordPageBox}>
        <Text sx={SignIntheme.successpageImoge} >🎉</Text>
        <Heading sx={SignIntheme.EmailVerificationPageBoxHeading}>Password successfully Changed</Heading>
        <Text
            sx={SignIntheme.emailverificationSuccessSubpara}
            as="p">Congratulations!<br></br>
            Your password has been changed successfully.</Text>

        <Button
            variant="outline"
            type="button"
            aria-selected={true}
            sx={SignIntheme.continueBtn}
            onClick={() => handleSubmitBtn(emailInput, newPassword)}
        >
            <Text
                as="p"
                color="#FFFFFF"
                sx={SignIntheme.continueBtnText}

            >

                Continue to workspace
            </Text>
        </Button>




    </Box>


    React.useState(()=>{
         
    })


    return (

        <Box>
            <Helmet>
                <title>
                Forgot password - deski
                </title>
            </Helmet>
            <LoadingSpinner showSpinner={showSpinner} />
            <Header />

            <Box sx={SignIntheme.loginMain}>
                   
                    {isVerificationSend==="0" ? forgetPasswordComponent
                    
                      :
                     isVerificationSend==="1" ? verificationCodeComponent 
                      :
                     
                     isVerificationSend==="2" ? newPasswordComponent 
                      :
                      isVerificationSend==="3" ? changePasswordSuccessComponent
                      : 
                      ""
                
                    }

                 {/* {isVerificationSend===false ? forgetPasswordComponent: linkSendSuccessComponent} */}
            </Box>

        </Box>

    )

}
export default ForgetPassword
