import React from "react"
import {Box,Dialog,Text,TextInput,Select,Textarea, Button } from '@primer/react'
import { useDispatch,useSelector } from "react-redux";
import { moveWorkplaceOpenClose } from '../../redux/features/workplaceSlice';
import Workplacetheme from "../Workplacetheme";



function MoveSpaceDialog(props){

    const dispatch = useDispatch()
    const workplaceData = useSelector((state)=> state.workplaceData)
    const initialFocusRef = React.createRef()


    const closeMoverWorkplaceDialog = (props) => {
        
        dispatch(moveWorkplaceOpenClose({
              openClose:false
        }))

    }

    return(<Dialog
        sx={Workplacetheme.moveWorkplaceDialog}
        isOpen={workplaceData.openClose}
        onDismiss={() =>

            closeMoverWorkplaceDialog("")
        }
        aria-labelledby="header-id"
        initialFocusRef={initialFocusRef}
    >
         <Dialog.Header id="header-id">
                <Text  sx={Workplacetheme.scheduleDalogeHeading}>Move Untitled  to another workspace</Text>
         </Dialog.Header>

         <Box sx={Workplacetheme.moveSpaceDialogContentBox}>

             <Text sx={Workplacetheme.createSpaceDialodBoxText}>Choose workspace</Text>

            <Box
                sx={Workplacetheme.moveSpaceDialogContentSelectBox}
            >

                <Select
                    style={Workplacetheme.scheduleDropdownBox.selectComponent}
                    block="true"
                    placeholder="Select your team size"
                >
                    <Select.Option value="Finance">My workplace 2</Select.Option>
                   


                </Select>

            </Box>



         </Box>

                <Box
                    borderColor="border.default" 
                    borderBottomWidth={1} 
                    borderBottomStyle="solid"
                    sx={Workplacetheme.sharePeopleBottomCollabBox.bottomDivider}>
                 </Box>

                 <Box

                position={"absolute"}
                bottom={2}
                left={24}
                right={24}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
                 >

                <Button 
                variant="invisible"
                    sx={Workplacetheme.moveSpaceDialogFooter.cancelBtn}
                 onClick={()=> closeMoverWorkplaceDialog("") }
                >Cancel</Button>
                <Button

                    variant="outline"
                    ref={initialFocusRef}
                    sx={Workplacetheme.moveSpaceDialogFooter.submitBtn}
                    onClick={()=> closeMoverWorkplaceDialog("") }
                >Move project</Button>


                </Box>








           



    </Dialog>)
}

export default MoveSpaceDialog

