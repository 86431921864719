const pageBackground = '#F6F8FA'

export default {



    loginMain:{

         width:'100%',
         minWidth:'100%',
         minHeight:'100vh',
         height:'100%',
         background:pageBackground,
         display:'flex',
         flexDirection:'column',
         
    },
    loginPageBox:{

         display:"flex",
         flexDirection:"column",
         position:"relative",
         margin:"98px auto 49px auto",
         height:550,
         width:430,
         background:"#FFFFFF",
         borderColor:"#D0D7DE",
         borderWidth:1,
         borderStyle:"solid",
         boxShadow:"0px 8px 24px rgba(140, 149, 159, 0.2)",
         borderRadius:"6px",
         
        
    },
    loginPageBoxHeading:{
        
        display:"flex",
        position:"relative",
        margin:"67px auto 0px auto",
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"20px",
        fontWeight:"600",  
        color:"#24292F",
        lineHeight:"20px"
    },
    loginPageEmailInput:{
        height: 40,
        width: 372,
        display: 'flex',
        position: 'relative',
        margin: '50px auto 0px auto',
        borderRadius: '3px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color:'#121212'
       // color: '#6E7781'  
    },
    loginPagePasswordInput:{
        height: 40,
        width: 372,
        display: 'flex',
        position: 'relative',
        margin: '20px auto 0px auto',
        borderRadius: '3px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color:'#121212'
        // color: '#6E7781'  
    },
    continueBtn:{
        height: '40px',
        minHeight: '40px',
        width: '372px',
        display: 'flex',
        position: 'relative',
        margin: '30px auto 0px auto',
        background: '#0965CE',
        color: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
    },
    continueBtnText:{
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        margin: '0px 0px 0px 0px',
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px'
    },
    dividerBox:{
        display:"flex",
        flexDirection:"row",
        position:"relative",
        width:372,
        margin:"5px auto 0px auto",
        alignItems:"center",
        firstDivider:{

            borderColor:"border.default",
            borderBottomWidth:1,
            borderBottomStyle:"solid",
            width:150,
            height:1,
            position:"relative",
            margin:"0px 0px 0px 0px"

        },
        secondDivider:{

            borderColor:"border.default",
            borderBottomWidth:1,
            borderBottomStyle:"solid",
            width:150,
            height:1,
            position:"relative",
            margin:"0px 0px 0px 0px"

        }
    },
    googlBtn:{
     height: '40px',
     minHeight: '40px',
     width: '372px',
     display: 'flex',
     flexDirection: 'row',
     position: 'relative',
     margin: '0px auto 0px auto',
     alignItems: 'center',
     borderRadius: '3px',
     text:{
        width: '135px', 
        height: '20px', 
        display: 'flex', 
        alignItems: 'center', 
        position: 'relative', 
        margin: '0px 0px 0px 74px',
        fontFamily:"Segoe UI",
        fontSize:"14px",
        fontWeight:"600",
        lineHeight:"20px",
        color:"#24292F"
     }

    },
    microSoftBtn:{
       height: '40px',
       minHeight: '40px',
       width: '372px',
       display: 'flex',
       flexDirection: 'row',
       position: 'relative',
       margin: '20px auto 0px auto',
       alignItems: 'center',
       borderRadius: '3px',
       text:{

            width: 'fit-content', 
            height: '20px', 
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: '0px 0px 0px 66px',
            fontFamily:"Segoe UI",
            fontSize:"14px",
            fontWeight:"600",
            lineHeight:"20px",
            color:"#24292F"
       }
    },
    loginBoxBottomBox:{
     display:"flex",
     flexDirection:"row",
     position:"relative",
    // margin:"80px auto 0px auto",
     margin:"20px auto 0px auto",
     alignItems:"center",
     width:259,
     height:52,
     signUpText:{
      
        width: 'fit-content', 
        height: 'fit-content', 
        display: 'flex', 
        alignItems: 'center', 
        position: 'relative', 
        margin: '0px 0px 0px 0px',
        fontFamily:"Segoe UI",
        fontSize:"12px",
        fontWeight:"400",
        lineHeight:"20px",
        color:"#586069",
        cursor:"pointer",
        ":hover":{
             color:'#0965CE'                        
        }
     },
     forgotPasswordText:{

         width: 'fit-content',
         height: 'fit-content', 
         display: 'flex', 
         alignItems: 'center', 
         position: 'relative', 
         margin: '0px 0px 0px 50px',
         fontFamily:"Segoe UI",
         fontSize:"12px",
         fontWeight:"400",
         lineHeight:"20px",
         color:"#586069",
         cursor:"pointer",
         ":hover":{
            color:'#0965CE'                        
         }
     }
    },
    
     //SigUp Style

     SignUpPageBox:{
        display:"flex",
        flexDirection:"column",
        position:"relative",
        margin:"98px auto 98px auto",
        height:610,
        width:430,
        background:"#FFFFFF",
        borderColor:"#D0D7DE",
        borderWidth:1,
        borderStyle:"solid",
        boxShadow:"0px 8px 24px rgba(140, 149, 159, 0.2)",
        borderRadius:"6px"
     },
     firsNameLastNameBox:{
        display:'flex',
        flexDirection:'row',
        position:'relative',
        height: 40,
        width: 373,
        margin:'20px auto 0px auto',
        firstName:{
            display:'flex',
            flexDirection:'row',
            position:'relative',
            height:40,
            width:177,
            margin:'0px 0px 0px 0px',
            borderRadius: '3px',
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
           // color: '#6E7781' 
            color:'#121212'
        },
       lastName:{

        display:'flex',
        flexDirection:'row',
        position:'relative',
        height:40,
        width:177,
        margin:'0px 0px 0px auto',
        borderRadius: '3px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
       // color: '#6E7781', 
        color:'#121212'
       }
     },
     //--------------- email verification theme -------------
     EmailVerificationPageBox:{
       
        display:"flex",
        flexDirection:"column",
        position:"relative",
        margin:"98px auto 0px auto",
        height:409,
        width:430,
        background:"#FFFFFF",
        borderColor:"#D0D7DE",
        borderWidth:1,
        borderStyle:"solid",
        boxShadow:"0px 8px 24px rgba(140, 149, 159, 0.2)",
        borderRadius:"6px"

     },
     emailverificationSubpara:{

        position: 'relative',
        height: 60,
        width: 345,
        margin: '30px auto 0px auto',
        fontFamily: 'Segoe UI',
        fontWeight:400,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '30px',
        color: '#586069',
        textAlign:'center', 
        email:{
            position: 'relative',
            height: "fit-content",
            width: "fit-content",
            margin: '0px 0px 0px 4px',
            fontFamily: 'Segoe UI',
            fontWeight:400,
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '30px',
            color: '#000000',
            textAlign:'center' 
        } 
     },
     verificationCodeInput:{
        height: 40,
        width: 372,
        display: 'flex',
        position: 'relative',
        margin: '30px auto 0px auto',
        borderRadius: '3px',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6E7781'  
     },
     verificationloginBoxBottomBox:{
        display:"flex",
        flexDirection:"row",
        position:"relative",
        margin:"20px 0px 0px 29px",
        alignItems:"center",
        width:259,
        height:52,

     },
     successpageImoge:{
      
        height:'20px',
        width:'27px',
        display:'flex',
        flexDirection:'row',
        position:'relative',
        margin:'27px auto 0px auto',
        fontFamily: 'Segoe UI',
        fontStyle: 'normal',
        fontSize:'27px',
        lineHeight:'20px',
        color:'#000000'

     },
     EmailVerificationPageBoxHeading:{
        display:"flex",
        position:"relative",
        margin:"20px auto 0px auto",
        fontFamily:"Segoe UI",  
        fontStyle:"normal",
        fontSize:"20px",
        fontWeight:"600",  
        color:"#24292F",
        lineHeight:"20px"
     },
     emailverificationSuccessSubpara:{

        position: 'relative',
        height: "fit-content",
        width: 345,
        margin: '30px auto 0px auto',
        fontFamily: 'Segoe UI',
        fontWeight:400,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '30px',
        color: '#586069',
        textAlign:'center', 
     },
     workDropDown:{

        height: 42,
        width: 374,
        display: 'flex',
        position: 'relative',
        margin: '30px auto 0px auto',
        select:{
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
            
        }

     },
     discoverDropDown:{
        height: 42,
        width: 374,
        display: 'flex',
        position: 'relative',
        margin: '20px auto 0px auto',
        select:{
            fontFamily: 'Segoe UI',
            fontStyle: 'normal',
            fontWeight:'400',
            fontSize: '14px',
            color:'#24292F',
            
        }
     },
     forgotpasswordSubpara:{

        position: 'relative',
        height: 60,
        width: 206,
        margin: '30px auto 0px auto',
        fontFamily: 'Segoe UI',
        fontWeight:400,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '30px',
        color: '#586069',
        textAlign:'center', 
        email:{
            position: 'relative',
            height: "fit-content",
            width: "fit-content",
            margin: '0px 0px 0px 0px',
            fontFamily: 'Segoe UI',
            fontWeight:400,
            fontStyle: 'normal',
            fontSize: '14px',
            color: '#000000',
            textAlign:'center', 
             
        }  
     },
     forgotpasswordImoge:{
         height: '20px',
         width: '100px',
         display: 'flex',
         flexDirection: 'row',
         position: 'relative',
         margin: '51px auto 0px auto',
         fontSize: '100px',
         lineHeight: '20px',
         alignContent: 'center',
         justifyContent: 'center' 
     },
     forgotpasswordBottomBox:{

        display:"flex",
        flexDirection:"row",
        position:"relative",
        margin:"59px auto 0px auto",
        alignItems:"center",
        width:259,
        height:52,
        signUpText:{
         
           width: 'fit-content', 
           height: 'fit-content', 
           display: 'flex', 
           alignItems: 'center', 
           position: 'relative', 
           margin: '0px 0px 0px 0px',
           fontFamily:"Segoe UI",
           fontSize:"12px",
           fontWeight:"400",
           lineHeight:"20px",
           color:"#586069",
           cursor:"pointer"
        },
        forgotPasswordText:{
   
            width: 'fit-content',
            height: 'fit-content', 
            display: 'flex', 
            alignItems: 'center', 
            position: 'relative', 
            margin: '0px 0px 0px 20px',
            fontFamily:"Segoe UI",
            fontSize:"12px",
            fontWeight:"400",
            lineHeight:"20px",
            color:"#586069",
            cursor:"pointer"
        }

     },
     ResetPasswordPageBox:{
        display:"flex",
        flexDirection:"column",
        position:"relative",
        margin:"98px auto 0px auto",
        height:330,
        width:430,
        background:"#FFFFFF",
        borderColor:"#D0D7DE",
        borderWidth:1,
        borderStyle:"solid",
        boxShadow:"0px 8px 24px rgba(140, 149, 159, 0.2)",
        borderRadius:"6px"
     },
     signupUserExits:{

        height:'fit-content',
        width:'fit-content',
        position:'absolute',
        top:98,
        fontSize:'10px',
        left:0,
        right:0,
        margin:'0 auto',
        fontFamily:"Segoe UI",
        fontSize:"10px",
        fontWeight:"400",
      
     }
     

}