import { Box, Button, Text, Heading, Label, Select } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { CheckIcon, ChevronRightIcon, DownloadIcon, FileIcon, FilterIcon } from "@primer/octicons-react";
import React from "react"
import { useNavigate } from "react-router-dom"
import { getSingleItportal } from "../../redux/features/portalSlice"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { UnderlineNav } from '@primer/react/drafts'
import Papa from "papaparse";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import RefundAmount from "./RefundAmount"




function ItReturnPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""

    const [items, setitems] = React.useState([
        { navigation: 'Return List' },
        { navigation: 'Return Files' }
    ])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [clientName, setclientName] = React.useState("")
    const [showResetButton, setshowResetButton] = React.useState(false)

    const [showFilterSection, setshowFilterSection] = React.useState(false)
    const [returnList, setreturnList] = React.useState([])
    const [copyreturnList, setcopyreturnList] = React.useState([])
    const [itrDetailsArray, setitrDetailsArray] = React.useState([])
    //---- Filter params --------
    const [verificationArray, setverificationArray] = React.useState(["Complete", "Pending"])
    const [itrArray, setitrArray] = React.useState(["ITR-1", "ITR-2", "ITR-3", "ITR-4", "ITR-5", "ITR-6", "ITR-7", "ITR-8", "All", "ITR-2A", "ITR-4S"])
    const [filingArray, setfilingArray] = React.useState(["Original", "Revised", "Rectification", "Defective", "Modified", "Condonation"])
    const [sortbyArray, setsortbyArray] = React.useState(["Sync Date", "Latest Change", "Acknowledgement Date", "Verification Date", "Demand Amount", "Refund Amount", "Assessment Year"])
    //---------------------------
    const [selectedVerification, setselectedVerification] = React.useState("")
    const [selectedItr, setselectedItr] = React.useState("")
    const [selectedFiling, setselectedFiling] = React.useState("")
    const [selectedSortbyArray, setselectedSortbyArray] = React.useState("")
    //----------------------------

    const getRefundFromJsonUrl = async (jsonUrl, formType) => {


        

        try {
            let refundAmount = '';
            // if (jsonUrl !== null) {
            //     const response = await fetch(jsonUrl);
            //     if (!response.ok) {
            //         throw new Error(`HTTP error! Status: ${response.status}`);
            //     }
            //     const contentType = response.headers.get('Content-Type');
            //     if (contentType && contentType.includes('application/xml')) {
            //         const responseData = await response.text();
            //         const parser = new DOMParser();
            //         const xmlDoc = parser.parseFromString(responseData, 'text/xml');

            //         if (formType === 'ITR-1') {
            //             const refundElement = xmlDoc.getElementsByTagName('RefundDue')[0];
            //             if (refundElement) {
            //                 refundAmount = refundElement.textContent;
            //             } else {
            //                 console.log('Refund amount not found');
            //             }
            //         } else if (formType === 'ITR-2') {
            //             const refundElement = xmlDoc.getElementsByTagName('ITRForm:RefundDue')[0];
            //             if (refundElement) {
            //                 refundAmount = refundElement.textContent;
            //             } else {
            //                 console.log('Refund amount not found');
            //             }
            //         }
            //     } else {
            //         const responseData = await response.json();
            //         if (formType === 'ITR-1') {
            //             refundAmount = responseData?.ITR?.ITR1?.Refund?.RefundDue || '';
            //         } else if (formType === 'ITR-5') {
            //             refundAmount = responseData?.ITR?.ITR5?.PartB_TTI?.Refund?.RefundDue || '';
            //         }
            //     }
            // }
            return refundAmount;
        } catch (error) {
            console.error('Error fetching refund amount:', error);
            return ''; // Return an empty string in case of an error
        }
    };

     

    const getSingleItportalFromDb = (props) => {

        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleItportal", data.payload)
            setclientName(data?.payload?.data[0]?.pan_name)
            setreturnList(data?.payload?.data[0]?.playwright_itr_return)
            setcopyreturnList(data?.payload?.data[0]?.playwright_itr_return)
          
        })

    }




    const downloadReturnExcel = (props) => {

        const csv = Papa.unparse(returnList);
        const newCsvData = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const csvData = newCsvData;
        const link = document.createElement("a");
        link.setAttribute("href", csvData);
        link.setAttribute("download", 'returnlist.csv');
        link.click();


    }

    const resetAllFilters = (props) => {

        setselectedVerification("")
        setselectedItr("")
        setselectedFiling("")
        setselectedSortbyArray("")
        setshowResetButton(false)
        setreturnList(copyreturnList)
    }

    const handleClickFilterData = (props) => {

        console.log("sortparams", {
            selectedVerification: selectedVerification,
            selectedItr: selectedItr,
            selectedFiling: selectedFiling,
            selectedSortbyArray: selectedSortbyArray
        })

        console.log("copyreturnList", copyreturnList)

        const filteredArray = copyreturnList.filter((fData) => {
            if (selectedVerification !== "") {

                if (selectedVerification === "All") {

                    return copyreturnList

                } else {

                    if (selectedVerification === "Complete") {


                        return fData?.timeLineData?.some(status => status.status === 'Refund credited to your Bank A/c')


                    } else {

                        return fData?.timeLineData?.every(status => status.status !== 'Refund Paid')
                    }



                }



            } else {

                return copyreturnList

            }
        })
            .filter((fData) => selectedFiling !== "" ? selectedFiling === "All" ? copyreturnList : fData.filingType === selectedFiling : copyreturnList)
            .filter((fData) => selectedItr !== "" ? selectedItr === "All" ? copyreturnList : fData.itr === selectedItr : copyreturnList)
            .sort((a, b) => selectedSortbyArray === "Acknowledgement Date" ? new Date(a.filingDate) - new Date(b.filingDate) : 0)
            .sort((a, b) => {
                if (selectedSortbyArray === "Assessment Year") {
                    const filingYearA = a?.ay?.replace("A.Y. ", "").split('-')[0]
                    const filingYearB = b.ay?.replace("A.Y. ", "").split('-')[0]

                    if (filingYearA < filingYearB) {
                        return -1;
                    } else if (filingYearA > filingYearB) {
                        return 1;
                    } else {
                        return 0;
                    }

                }
                else if (selectedSortbyArray === "Refund Amount") {

                    // const aRefAmt = (
                    //     a.itr_form === "ITR-3" ?

                    //         itrDetailsArray?.filter((tData) => tData.itr_id === a.itr_id)[0]?.data?.raw_itr?.ITR.ITR3?.PartB_TTI?.Refund?.RefundDue
                    //         :
                    //         a.itr_form === "ITR-1" ?
                    //             itrDetailsArray?.filter((tData) => tData.itr_id === a.itr_id)[0]?.data?.raw_itr?.ITR?.ITR1?.Refund?.RefundDue
                    //             ||
                    //             itrDetailsArray?.filter((tData) => tData.itr_id === a.itr_id)[0]?.data?.raw_itr['ns3:ITR']?.['ns2:ITR1']?.['Refund']?.['RefundDue']
                    //             ||
                    //             ""
                    //             :
                    //             a.itr_form === "ITR-2" ?
                    //                 itrDetailsArray?.filter((tData) => tData.itr_id === a.itr_id)[0]?.data?.raw_itr['ITRETURN:ITR']?.['ITR2FORM:ITR2']?.['ITRForm:PartB_TTI']?.['ITRForm:Refund']?.['ITRForm:RefundDue']
                    //                 :
                    //                 ""
                    // )


                    // const bRefAmt = (
                    //     b.itr_form === "ITR-3" ?

                    //         itrDetailsArray?.filter((tData) => tData.itr_id === b.itr_id)[0]?.data?.raw_itr?.ITR.ITR3?.PartB_TTI?.Refund?.RefundDue
                    //         :
                    //         b.itr_form === "ITR-1" ?
                    //             itrDetailsArray?.filter((tData) => tData.itr_id === b.itr_id)[0]?.data?.raw_itr?.ITR?.ITR1?.Refund?.RefundDue
                    //             ||
                    //             itrDetailsArray?.filter((tData) => tData.itr_id === b.itr_id)[0]?.data?.raw_itr['ns3:ITR']?.['ns2:ITR1']?.['Refund']?.['RefundDue']
                    //             ||
                    //             ""
                    //             :
                    //             b.itr_form === "ITR-2" ?
                    //                 itrDetailsArray?.filter((tData) => tData.itr_id === b.itr_id)[0]?.data?.raw_itr['ITRETURN:ITR']?.['ITR2FORM:ITR2']?.['ITRForm:PartB_TTI']?.['ITRForm:Refund']?.['ITRForm:RefundDue']
                    //                 :
                    //                 ""
                    // )


                    // if (aRefAmt < bRefAmt) {
                    //     return -1;
                    // } else if (aRefAmt > bRefAmt) {
                    //     return 1;
                    // } else {
                    //     return 0;
                    // }


                } else if (selectedSortbyArray === "Sync Date") {
                    const dateA = new Date(a.timeLineData[0].date);
                    const dateB = new Date(b.timeLineData[0].date);
                    return dateA - dateB;

                } else if (selectedSortbyArray === "Latest Change") {

                    const dateA = new Date(b.timeLineData[0].date);
                    const dateB = new Date(a.timeLineData[0].date);
                    return dateA - dateB;

                } else if (selectedSortbyArray === "Verification Date") {

                    console.log("ainsort", a)
                    const dateA = new Date(
                        a.timeLineData?.find(status => status.status.includes('Successfully e-verified')).date
                    );

                    const dateB = new Date(
                        b.timeLineData?.find(status => status.status.includes('Successfully e-verified')).date
                    );
                    return dateA - dateB;

                }
                else {

                    return 0
                }
            }

            )

        setreturnList(filteredArray)
        setshowResetButton(true)


    }


    const RetrunListComponent = (props) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '171px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    bottom: '0px',
                }}
            >


                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 0 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        //   minWidth: '100%',
                        minWidth: '1257px',

                    }}>


                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>AY</Select.Option>


                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >

                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        setselectedVerification(e.target.value)
                                    }}
                                >
                                    <Select.Option value={""}
                                        selected={selectedVerification === "" ? true : false}
                                    >Select Verification</Select.Option>
                                    <Select.Option value={"All"}
                                        selected={selectedVerification === "All" ? true : false}
                                    >All</Select.Option>
                                    {verificationArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedVerification === row}
                                            >{row}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedItr(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""} selected={selectedItr === "" ? true : false}>Select ITR</Select.Option>
                                    <Select.Option value={"All"} selected={selectedItr === "All" ? true : false}>All</Select.Option>
                                    {itrArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedItr === row ? true : false}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { setselectedFiling(e.target.value) }}
                                >
                                    <Select.Option value={""} selected={selectedFiling === "" ? true : false}>Select Filling</Select.Option>
                                    <Select.Option value={"All"} selected={selectedFiling === "All" ? true : false}>All</Select.Option>
                                    {filingArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedFiling === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Include Tags</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Exclude Tags</Select.Option>


                                </Select>
                            </Box>





                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedSortbyArray(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedSortbyArray === "" ? true : false}
                                    >Select Sort By</Select.Option>
                                    {sortbyArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}

                                                selected={selectedSortbyArray === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        resetAllFilters("")
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() => handleClickFilterData("")}
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>




                <Box sx={{
                    height: 'auto',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* table header */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '40px',
                        height: '40px',
                        maxHeight: '40px',
                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                        background: `${customThemVariable.whiteBgColor}`
                    }}>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                CLIENT
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Acknowledgement INFO
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '10.1%',
                            minWidth: '10.1%',
                            maxWidth: '10.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Assment Year
                            </Text>
                        </Box>
                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>
                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',

                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '15.4%',
                            minWidth: '15.4%',
                            maxWidth: '15.4%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Refund Amount
                            </Text>
                        </Box>


                    </Box>
                    {/* ------------ */}

                    {returnList?.map((row, index) => {

                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                height: '40px',
                                maxHeight: '40px',
                                borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    localStorage.setItem("itr_id", row.itr_id)
                                    localStorage.setItem("ackNumber", row?.acknowledgementNo)

                                    navigate('/Itrexpansion')
                                }}
                            >

                                <Box sx={{
                                    width: '16.1%',
                                    minWidth: '16.1%',
                                    maxWidth: '16.1%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >


                                        {clientName}

                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {new Date(row.filingDate).getDate().toString() + " " + new Date(row.filingDate).toLocaleString('en-US', { month: 'short' }) + " " + new Date(row.filingDate).getFullYear().toString()}

                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '10.1%',
                                    minWidth: '10.1%',
                                    maxWidth: '10.1%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row?.timeLineData[0]?.status?.substring(0, 11)}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>



                                    <Label sx={{
                                        marginLeft: '16px',
                                        fontFamily: customThemVariable.primartFont,
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: customThemVariable.labelTextColorgreen,
                                        borderRadius: '6px',
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        height: '24px',
                                        background: customThemVariable.labelBgTertiaryColor,
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                color: customThemVariable.icongreenColor,
                                                marginLeft: '0px'
                                            }}>
                                            <CheckIcon size={19} />
                                        </Box>

                                        <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                            {/* AY{row.filing_year?.split("-")[0]?.substring(2, 4)}-{row.filing_year?.split("-")[1]?.substring(2, 4)} */}
                                            {row.ay}
                                        </Text>




                                    </Label>

                                </Box>
                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {new Date(row?.timeLineData[0]?.date).toDateString().substring(3, 20)}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row.filingType}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row.filingSection}
                                    </Text>
                                </Box>
                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row.itr}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '15.4%',
                                    minWidth: '15.4%',
                                    maxWidth: '15.4%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {/* {row?.refundAmount} */}
                                        {/* {row?.jsonUrl} */}
                                        {/* {getRefundFromJsonUrl(row?.jsonUrl,row?.itr)} */}
                                        <RefundAmount jsonUrl={row?.jsonUrl} formType={row?.itr}   />
                                    </Text>
                                </Box>


                            </Box>
                        )

                    })}




                </Box>

                <Box sx={{
                    minHeight: '116px'
                }}>

                </Box>




            </Box>
        )
    }

    const RetrunFileComponent = (props) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '171px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    bottom: '0px',
                }}
            >


                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 1 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '1257px',

                    }}>


                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}
                                    
                                 >
                                    <Select.Option value={""}>AY</Select.Option>
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >

                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedVerification(e.target.value)
                                    }}
                                >
                                    <Select.Option value={""}
                                        selected={selectedVerification === "" ? true : false}
                                    >Select Verification</Select.Option>
                                    <Select.Option value={"All"}
                                        selected={selectedVerification === "All" ? true : false}
                                    >All</Select.Option>
                                    {verificationArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedVerification === row}
                                            >{row}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedItr(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""} selected={selectedItr === "" ? true : false}>Select ITR</Select.Option>
                                    <Select.Option value={"All"} selected={selectedItr === "All" ? true : false}>All</Select.Option>
                                    {itrArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedItr === row ? true : false}
                                            >{row}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { setselectedFiling(e.target.value) }}
                                >
                                    <Select.Option value={""} selected={selectedFiling === "" ? true : false}>Select Filling</Select.Option>
                                    <Select.Option value={"All"} selected={selectedFiling === "All" ? true : false}>All</Select.Option>
                                    {filingArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}
                                                selected={selectedFiling === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Include Tags</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Exclude Tags</Select.Option>


                                </Select>
                            </Box>





                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedSortbyArray(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedSortbyArray === "" ? true : false}
                                    >Select Sort By</Select.Option>
                                    {sortbyArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}

                                                selected={selectedSortbyArray === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        resetAllFilters("")
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() => handleClickFilterData("")}
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>




                <Box sx={{
                    height: 'auto',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* table header */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '40px',
                        height: '40px',
                        maxHeight: '40px',
                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                        background: `${customThemVariable.whiteBgColor}`
                    }}>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                CLIENT
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Acknowledgement INFO
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                File Type
                            </Text>
                        </Box>
                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Assment Year
                            </Text>
                        </Box>
                        <Box sx={{
                            width: '10.1%',
                            minWidth: '10.1%',
                            maxWidth: '10.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Date
                            </Text>
                        </Box>


                    </Box>
                    {/* ------------ */}

                    {returnList?.filter((rData) => rData?.formUrl !== "error").map((row, index) => {
                        return (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '40px',
                                    height: '40px',
                                    maxHeight: '40px',
                                    borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                    background: `${customThemVariable.whiteBgColor}`,
                                    ":hover": {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    },
                                    cursor: 'pointer'
                                }}
                                >

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => {
                                            localStorage.setItem("itr_id", row.itr_id)
                                            localStorage.setItem("ackNumber", row?.acknowledgementNo)
                                            localStorage.setItem("pageRoute", "")
                                            navigate('/Itrexpansion')
                                        }}
                                                 
                                    >
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >


                                            {clientName}

                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >


                                            {row?.acknowledgementNo}

                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => { window.open(row?.formUrl) }}
                                    >
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >
                                            <FileIcon size={15} /> Form
                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>



                                        <Label sx={{
                                            marginLeft: '16px',
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: customThemVariable.labelTextColorgreen,
                                            borderRadius: '6px',
                                            minHeight: '24px',
                                            maxHeight: '24px',
                                            height: '24px',
                                            background: customThemVariable.labelBgTertiaryColor,
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    color: customThemVariable.icongreenColor,
                                                    marginLeft: '0px'
                                                }}>
                                                <CheckIcon size={19} />
                                            </Box>

                                            <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                                {row.ay}
                                            </Text>




                                        </Label>

                                    </Box>
                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >
                                            {new Date(row?.timeLineData[0]?.date).toDateString().substring(3, 20)}
                                        </Text>
                                    </Box>



                                    <Box sx={{
                                        width: '15.4%',
                                        minWidth: '15.4%',
                                        maxWidth: '15.4%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >

                                        </Text>
                                    </Box>


                                </Box>
                            </>
                        )
                    })}

                    {returnList?.filter((rData) => rData?.receiptUrl !== "error").map((row, index) => {
                        return (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '40px',
                                    height: '40px',
                                    maxHeight: '40px',
                                    borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                    background: `${customThemVariable.whiteBgColor}`,
                                    ":hover": {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    },
                                    cursor: 'pointer'
                                }}
                                >

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => {
                                            localStorage.setItem("itr_id", row.itr_id)
                                            localStorage.setItem("ackNumber", row?.acknowledgementNo)
                                            navigate('/Itrexpansion')
                                        }}
                                    >
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >
                                            {clientName}
                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >


                                            {row?.acknowledgementNo}

                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => { window.open(row?.receiptUrl) }}
                                    >
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >
                                            <FileIcon size={15} /> Receipt
                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>



                                        <Label sx={{
                                            marginLeft: '16px',
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: customThemVariable.labelTextColorgreen,
                                            borderRadius: '6px',
                                            minHeight: '24px',
                                            maxHeight: '24px',
                                            height: '24px',
                                            background: customThemVariable.labelBgTertiaryColor,
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    color: customThemVariable.icongreenColor,
                                                    marginLeft: '0px'
                                                }}>
                                                <CheckIcon size={19} />
                                            </Box>

                                            <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                                {row.ay}
                                            </Text>




                                        </Label>

                                    </Box>
                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >
                                            {new Date(row?.timeLineData[0]?.date).toDateString().substring(3, 20)}
                                        </Text>
                                    </Box>



                                    <Box sx={{
                                        width: '15.4%',
                                        minWidth: '15.4%',
                                        maxWidth: '15.4%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >

                                        </Text>
                                    </Box>


                                </Box>
                            </>
                        )
                    })}

                    {

                        returnList?.filter((rData) => rData?.jsonUrl !== "error").map((row, index) => {
                            return (
                                <>
                                    <Box 
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            minHeight: '40px',
                                            height: '40px',
                                            maxHeight: '40px',
                                            borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                            background: `${customThemVariable.whiteBgColor}`,
                                            ":hover": {
                                                background: 'rgba(234, 238, 242, 0.5)'
                                            },
                                            cursor: 'pointer'
                                        }}
                                    >

                                        <Box 
                                            sx={{
                                                width: '16.1%',
                                                minWidth: '16.1%',
                                                maxWidth: '16.1%',
                                                minHeight: '40px',
                                                maxHeight: '40px',
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                            onClick={() => {
                                                localStorage.setItem("itr_id", row.itr_id)
                                                localStorage.setItem("ackNumber", row?.acknowledgementNo)
                                                navigate('/Itrexpansion')
                                            }}
                                        >
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >{clientName}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '16.1%',
                                            minWidth: '16.1%',
                                            maxWidth: '16.1%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >{row?.acknowledgementNo}
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '11.7%',
                                            minWidth: '11.7%',
                                            maxWidth: '11.7%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                            onClick={() => { window.open(row?.jsonUrl) }}
                                        >
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                <FileIcon size={15} /> JSON
                                            </Text>
                                        </Box>

                                        <Box sx={{
                                            width: '11.7%',
                                            minWidth: '11.7%',
                                            maxWidth: '11.7%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>



                                            <Label sx={{
                                                marginLeft: '16px',
                                                fontFamily: customThemVariable.primartFont,
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: customThemVariable.labelTextColorgreen,
                                                borderRadius: '6px',
                                                minHeight: '24px',
                                                maxHeight: '24px',
                                                height: '24px',
                                                background: customThemVariable.labelBgTertiaryColor,
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        color: customThemVariable.icongreenColor,
                                                        marginLeft: '0px'
                                                    }}>
                                                    <CheckIcon size={19} />
                                                </Box>

                                                <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                                    {row.ay}
                                                </Text>




                                            </Label>

                                        </Box>
                                        <Box sx={{
                                            width: '11.7%',
                                            minWidth: '11.7%',
                                            maxWidth: '11.7%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                                {new Date(row?.timeLineData[0]?.date).toDateString().substring(3, 20)}
                                            </Text>
                                        </Box>
                                        <Box sx={{
                                            width: '15.4%',
                                            minWidth: '15.4%',
                                            maxWidth: '15.4%',
                                            minHeight: '40px',
                                            maxHeight: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    marginLeft: '16px',
                                                    color: `${customThemVariable.darkgreysecond}`
                                                }}
                                            >
                                            </Text>
                                        </Box>


                                    </Box>
                                </>
                            )
                        })

                    }

                       {returnList?.filter((rData) => rData?.orderArray?.length > 0)
                            .map((row, index) => {

                                return (
                                    row?.orderArray.map((row2, index2) => {

                                        return (<>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                minHeight: '40px',
                                                height: '40px',
                                                maxHeight: '40px',
                                                borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                                background: `${customThemVariable.whiteBgColor}`,
                                                ":hover": {
                                                    background: 'rgba(234, 238, 242, 0.5)'
                                                },
                                                cursor: 'pointer'
                                            }}
                                            >

                                                <Box sx={{
                                                    width: '16.1%',
                                                    minWidth: '16.1%',
                                                    maxWidth: '16.1%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                    onClick={() => {
                                                        localStorage.setItem("itr_id", row.itr_id)
                                                        localStorage.setItem("ackNumber", row?.acknowledgementNo)
                                                        navigate('/Itrexpansion')
                                                    }}

                                                >
                                                    <Text
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            marginLeft: '16px',
                                                            color: `${customThemVariable.darkgreysecond}`
                                                        }}
                                                    >
                                                        {clientName}
                                                    </Text>
                                                </Box>

                                                <Box sx={{
                                                    width: '16.1%',
                                                    minWidth: '16.1%',
                                                    maxWidth: '16.1%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Text
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            marginLeft: '16px',
                                                            color: `${customThemVariable.darkgreysecond}`
                                                        }}
                                                    >
                                                    {row?.acknowledgementNo}
                                                    </Text>
                                                </Box>

                                                <Box sx={{
                                                    width: '11.7%',
                                                    minWidth: '11.7%',
                                                    maxWidth: '11.7%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                    onClick={() => { window.open(row2?.orderUrl) }}
                                                >
                                                    <Text
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            marginLeft: '16px',
                                                            color: `${customThemVariable.darkgreysecond}`
                                                        }}
                                                    >
                                                        <FileIcon size={15} /> Intimation order
                                                    </Text>
                                                </Box>

                                                <Box sx={{
                                                    width: '11.7%',
                                                    minWidth: '11.7%',
                                                    maxWidth: '11.7%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Label sx={{
                                                        marginLeft: '16px',
                                                        fontFamily: customThemVariable.primartFont,
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: customThemVariable.labelTextColorgreen,
                                                        borderRadius: '6px',
                                                        minHeight: '24px',
                                                        maxHeight: '24px',
                                                        height: '24px',
                                                        background: customThemVariable.labelBgTertiaryColor,
                                                        border: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                         <Box
                                                            sx={{
                                                                display: 'flex',
                                                                color: customThemVariable.icongreenColor,
                                                                marginLeft: '0px'
                                                            }}>
                                                            <CheckIcon size={19} />
                                                        </Box>
                                                        <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                                            {row.ay}
                                                        </Text>
                                                    </Label>

                                                </Box>
                                                <Box sx={{
                                                    width: '11.7%',
                                                    minWidth: '11.7%',
                                                    maxWidth: '11.7%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Text
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            marginLeft: '16px',
                                                            color: `${customThemVariable.darkgreysecond}`
                                                        }}
                                                    >
                                                        {row2?.orderDate}
                                                    </Text>
                                                </Box>
                                                <Box sx={{
                                                    width: '15.4%',
                                                    minWidth: '15.4%',
                                                    maxWidth: '15.4%',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Text
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            marginLeft: '16px',
                                                            color: `${customThemVariable.darkgreysecond}`
                                                        }}
                                                    >
                                                      
                                                    </Text>
                                                </Box>


                                            </Box>
                                        </>
                                        )
                                    })
                                )


                            })}





                </Box>

                <Box sx={{
                    minHeight: '116px'
                }}>

                </Box>




            </Box>
        )
    }


    React.useState(() => {

        getSingleItportalFromDb("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'IT Return details - taxhero' : 'IT Return details - deski'}
                </title>
            </Helmet>


            {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ?

                <><TaxHeroHeader /></>
                :
                <><Header /><SideNav /></>
            }




            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}>


                {/* fixed header */}

                <Box sx={{
                    top: '48px',
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1'
                }}>

                    <Text sx={{
                        marginTop: '12px',
                        marginLeft: '32px',
                        marginBottom: '15px',
                        color: customThemVariable.textgray,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                        onClick={() => {
                            hostName === "www.app.taxherohq.com"
                                ||
                                hostName === "www.get.taxherohq.com"
                                ?
                                navigate('/taxheroportal')
                                :
                                navigate('/portal')
                        }}
                    >
                        Home
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>

                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/itpanlist')
                        }}
                    >
                        IT
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>

                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => { }}
                    >
                        {clientName.substring(0, 100)}

                    </Text>



                    <Box sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '61px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '3px'
                    }}>
                        <Button

                            leadingIcon={() => <Box><FilterIcon /></Box>}
                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontSize: '14px',
                                fontStyle: customThemVariable.fontStyleNormal,
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingLeft: '13px',
                                paddingRight: '13px',
                                borderRadius: '6px',
                                color: customThemVariable.textgraydark,
                                border: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => {
                                setshowFilterSection(!showFilterSection)
                            }}
                        >

                        </Button>
                        <Button
                            leadingIcon={() => <Box><DownloadIcon /></Box>}

                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontSize: '14px',
                                fontStyle: customThemVariable.fontStyleNormal,
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingLeft: '13px',
                                paddingRight: '13px',
                                borderRadius: '6px',
                                color: customThemVariable.textgraydark,
                                border: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => {
                                downloadReturnExcel("")
                            }}
                        >

                        </Button>



                    </Box>


                </Box>

                {/*  */}


                <Heading
                    className={customTheme.H1}
                    sx={{
                        position: 'absolute',
                        fontSize: '24px',
                        left: '26px',
                        top: '58px'
                    }}
                >
                    Return
                </Heading>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '116px',
                        bottom: '0px',
                        right: '61px',
                        minHeight: 40,
                        maxHeight: 40,
                        height: 40,
                        fontFamily: customThemVariable.primartFont,
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: customThemVariable.$textgraydark,
                        paddingLeft: '0px',
                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {
                                setSelectedIndex(index)
                                e.preventDefault()
                            }}
                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                {selectedIndex == 0 ? <RetrunListComponent /> : selectedIndex == 1 ? <RetrunFileComponent /> : ""}
                



            </Box>

        </Box>
    )

}

export default ItReturnPlaywright