import React from "react"
import { Box, Button,Breadcrumbs,Text } from "@primer/react"
import { Helmet } from "react-helmet"
import EmptyData from "../Space/EmptyData"
import Header from "../Workplace/Header"
import SideNav from "../Workplace/SideNav"
import { useDispatch,useSelector } from "react-redux"
import { addPanOpenClose, gstDataSyncOpenClose } from "../redux/features/contactSlice"
import AddPanDialog from "../Workplace/Dialogs/AddPanDialog"
import UploadPanDialog from "../Workplace/Dialogs/UploadPanDialog"
import GSTSyncDialog from "../Workplace/Dialogs/GSTSyncDialog"
import { useNavigate } from "react-router-dom"
import customTheme from "../../Theme.module.scss"  
import customThemVariable from  "../../ThemeVariable.module.scss" 
import { ChevronRightIcon } from "@primer/octicons-react"
import { getJwtToken, getSanboxGstProf, getSingleGstportal, syncGstPortal, updateGstPortal } from "../redux/features/portalSlice"
import  { Auth } from 'aws-amplify';
import TaxHeroHeader from "./TaxHeroHeaderSanbox"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import axios from "axios"

const synchingToast = Toaster.create({ position: Position.TOP })

function GstSanbox(props){

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()
      
    const userId = useSelector((state) => state.profileData.userId)  
    const userEmail = useSelector((state) => state.profileData.userEmail)  
   
    const clientId = localStorage.getItem("client_id")|| ""
    const insertId = localStorage.getItem("insert_id") || ""
    const gstIn = localStorage.getItem("gstIn") || ""

    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);

    const [profileData,setProfileData] = React.useState(null)
    const [gstData,setgstData] = React.useState(null)

    const getSingleGSTportalFromDb = (props) => {

           
        dispatch(getSingleGstportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId
        })).then((data) => {

            console.log("getSingleGSTportal", data.payload)
            setProfileData(data?.payload?.data[0])
            setgstData(data?.payload.data[0]?.gst_profile_synzy)
        
          //   getGstDataFromApi(data?.payload?.data[0].user_name)
  

        })



    }


    const getGstDataFromApi = async (props) => {

        dispatch(getSanboxGstProf({
            gstIn: gstIn,
            userName: props
        })).then((data) => {
            console.log("getSanboxGstProf", data.payload)
        })
    }


    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
          setisDisabledSyncing(false);
        }, 
        //  10000
          30 * 60 * 1000
        );  
      };

    const showGstSyncDialog = (props) => {

        disableSyncButton("")
        loadingToast(10, "1")

        dispatch(syncGstPortal({
            userId: userId,
            userEmail: userEmail,
            userName: "",
            gstIn: gstIn,
            insertId: insertId
        })).then((data) => {

            console.log("updateGstPortal", data.payload)

            const responseObj = { ...data?.payload }
            console.log("responseObj", responseObj)
            if (responseObj?.statusCode == 200 && responseObj?.data?.result?.gstin === gstIn) {
                loadingToast(100, "1")

            } else {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync Error or invalid credential",
                    intent: Intent.PRIMARY
                });

            }

            getSingleGSTportalFromDb("")




        }) 

 

   }

    const loadingToast = (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message:

                <Box sx={{ marginTop: '4px' }}>
                    <ProgressBar
                        animate={true}
                        stripes={true}
                        intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                        value={progressPercentage / 100}

                    />
                </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },


        }, key);

    }


    const getGSTDataFromAlankit =async (props) => {

        const options = {
            method: 'GET', // Change the method to 'GET'
            headers: {
               // accept: 'text/plain',
               // 'content-type': 'application/json',
               // 'Authorization': `Bearer ${bearerToken}`
               'Ocp-Apim-Subscription-Key':'ALPRD8e3x7x1v7n4Q7p0'
            },
            params:{
                gstin:'32AAIFE6886A1ZJ',
                action:'TP'
            }

        };

        const result = await axios.get(`https://gsp.alankitgst.com/commonapi/v1.1/search`, options)
            .then((response) => {
                 console.log("alankitresponse", response);
               // const responseResult = { data: response.data };
              //  return responseResult;
            })
            .catch((error) => {
               // const responseError = { data: error.response.data }
               // return responseError
            }); 
      //  console.log("alankitresponse2",result)

    }

    React.useState(() => {

    
       getGSTDataFromAlankit(gstIn)

       getSingleGSTportalFromDb("")
      
    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })

    return(
        <Box
         position={"relative"} 
         display="flex" 
         background={"#F6F8FA"} 
         height="100vh"
         width={"100vw"} 
         overflowY={"auto"}
    
    >
        <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" ? 'GST - info - taxhero' : 'GST - info - deski'}
                </title>
        </Helmet>

            {
                hostName === "www.app.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <TaxHeroHeader />
                        <SideNav />
                    </>
            }


        <Box sx={{
                top:'48px',
                position: 'fixed',
                left: hostName === "www.app.taxherohq.com"?'0px':'64px',
                right: '0px',
                minHeight: '48px',
                maxHeight: '48px',
                height: '48px',
                background: customThemVariable.whiteBgColor,
                display: 'flex',
                borderBottom: '1px solid #E5E9EB',
                zIndex: '1'
            }}>

                <Text sx={{
                    marginTop: '12px',
                    marginLeft: '32px',
                    marginBottom: '15px',
                    color: customThemVariable.textgray,
                    fontSize: '14px',
                    fontFamily: customThemVariable.primartFont,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    cursor: 'pointer'
                }}
                    onClick={() => {

                        hostName === "www.app.taxherohq.com"?
                        navigate('/taxherosanbox')
                        :
                        navigate('/taxherosanbox')
                    }}
                >
                    Home
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>
                      
                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/gstpanlistsanbox')
                    }}
                >
                    GST
                </Text>

                <Box sx={{
                    marginLeft: '6px',
                    marginTop: '14px',
                    marginBottom: '18px',
                    color: '#B0BABF'
                }}>
                      
                    <ChevronRightIcon />
                </Box>

                <Text
                    sx={{
                        marginTop: '12px',
                        marginLeft: '6px',
                        marginBottom: '15px',
                        color: customThemVariable.textgraydark,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { }}
                >
                    {/* Eva floor */}
                    {profileData?.pan_name}
                </Text>

               
                  


            </Box>

          
            <Box sx={{
                position:'absolute',
                left:'93px',
                top:'112px',
                right:'80px',
                minHeight:'433px',
                background:'#FFFFFF',
                borderRadius:'3px',
                border:'1px solid #D0D7DE'
            }}>


                <Box sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '25px',
                    right: '25px',
                    minHeight: '36px',
                    maxHeight: '36px',
                    height: '36px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>

                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        {/* 32BUMPR7308R1ZT */}
                        {gstData?.result?.gstin}
                    </Text>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        left: '282px',
                        alignItems: 'center'
                    }}>

                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.textgraydark
                            }}>
                            {/* Eva floor */}
                            {profileData?.pan_name}
                        </Text>

                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '30px',
                                color: customThemVariable.textgray
                            }}>
                            
                            {/* Hexcode */}
                            {profileData?.user_name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{
                                marginLeft: '20px',
                                color: customThemVariable.textgray
                            }}>
                            {profileData?.password}
                            {/* Hexcode123* */}
                        </Text>

                    </Box>

                    <Text
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: '137px',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            color: customThemVariable.textgraydark
                            
                        }}
                    >
                        
                        Last synced on : 
                        {
                            profileData == null ? "Not Synched" :
                                "" + new Date(profileData?.updated_timestamp).toDateString() + " " + new Date(profileData?.updated_timestamp).toLocaleTimeString() + ""
                        }


                    </Text>

                    <Button
                        disabled={isDisabledSyncing}
                        variant="primary"
                        sx={{
                            height: '32px',
                            width: '100px',
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: customThemVariable.primartFont,
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                        onClick={() => { showGstSyncDialog("") }}
                    >
                        SYNC
                    </Button>


                </Box>



                <Box sx={{ position: 'absolute', left: '25px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Legal Name
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Primary email
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Contact
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        GSTIN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        PAN System Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Address
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Aggregate Turn over
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Type
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Status
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Registration Date
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        Central Jurisdiction 
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: '#121212'
                    }}>
                        State Jurisdiction
                    </Text>

                </Box>

                <Box sx={{ position: 'absolute', left: '307px', top: '67px', display: 'flex', flexDirection: 'column', gap: '10px' }}>

                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {gstData?.result?.gstnDetailed?.legalNameOfBusiness} ({gstData?.result?.gstnDetailed?.constitutionOfBusiness})
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* evafloorworld@gmail.com */}
                        {gstData?.result?.gstnDetailed?.principalPlaceAddress?.emailId}
                     </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* 8129664664 */}
                        {gstData?.result?.gstnDetailed?.principalPlaceAddress?.mobile}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                      
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* ₹55,43,108 (Year to date) */}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        maxHeight:'20px',
                        height:'20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* 8/32C, KOZHIPPILLIYIL BUILDING, KEEZHILLAM, PERUMBAVOOR, Ernakulam, Kerala, 683541 */}
                        {gstData?.result?.gstnDetailed?.principalPlaceAddress?.address}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* Slab: Rs. 1.5 Cr. to 5 Cr. (2021-2022) */}
                        {gstData?.result?.annualAggregateTurnOver}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {/* Regular  */}
                        {gstData?.result?.gstnDetailed?.taxPayerType}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: gstData?.result?.gstnDetailed?.gstinStatus==="ACTIVE"?customThemVariable.textgreen:'red',
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                       {/* ACTIVE */}
                       {gstData?.result?.gstnDetailed?.gstinStatus}
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                       {gstData !== null ?

                            new Date(gstData?.result?.gstnDetailed?.registrationDate).getDate() + "/" +
                            (new Date(gstData?.result?.gstnDetailed?.registrationDate).getMonth()+1) + "/" +
                            new Date(gstData?.result?.gstnDetailed?.registrationDate).getFullYear()
                            : ""}
                   
                        
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {gstData !== null ? 
                            gstData?.result?.gstnDetailed?.centreJurisdiction
                        :""}

                         
                    </Text>
                    <Text sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        minHeight: '20px',
                        overflow:'hidden',
                        textOverflow:'ellipsis'
                    }}>
                        {
                            gstData !==null ?
                            gstData?.result?.gstnDetailed?.stateJurisdiction
                            :""
                        }
                         
                    </Text>
                </Box>




                  
 
            </Box>


            
         <AddPanDialog/>
         <UploadPanDialog/>
         <GSTSyncDialog/>
         
       
        </Box>
    )

}

export default GstSanbox