import { Box, Button, Dialog, Heading, Label, Portal, StyledOcticon, Text, TextInput } from "@primer/react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ArrowUpIcon, BookIcon } from "@primer/octicons-react"
import TaxHeroHeader from "./TaxHeroHeader"
import TaxHeroSideNav from "./TaxHeroSideNav"
import { getUserOrganizationById } from "../redux/features/spaceSlice"
import React from "react"
import { updateUserOrganizationArray } from "../redux/features/contactSlice"
import PortalOrgSetup from "./PortalOrgSetup"

function AccountSetupPortal(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId) // get User id
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const getUserDefaults = (props) => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            console.log("getUserOrganizationById", data.payload)

            if (data?.payload?.data[0]?.verification === "true") {
                navigate('/taxheroportal')
            } else {
                //if false will go to accountsetup page
                navigate('/accountsetup')
            }

        })

    }


    React.useState(() => {

        getUserDefaults("")

    })

    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F6F6"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >
            <Helmet>
                <title>
                    Portal - deski

                </title>
            </Helmet>

            <TaxHeroHeader />
            <PortalOrgSetup />

        </Box>
    )

}

export default AccountSetupPortal