import React from 'react';
import { Dialog, Text, TextInput, Button, Box, ActionList,FormControl,Radio } from '@primer/react';
import { useDispatch, useSelector } from 'react-redux';
import { addContactOpenClose, getContactDirectory, getIssueConatctData, postContactDirectory, updateTaskContact } from '../../redux/features/taskSlice';
import { EyeOpenIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { ArrowDownRightIcon, ArrowLeftIcon, ArrowRightIcon, MailIcon, OrganizationIcon } from '@primer/octicons-react'
import GetpersonIcon from './GetpersonIcon';
import { Toaster, Intent,Position } from "@blueprintjs/core";
import { addCredentialOpenClose, addOrgCredentials, editOrgCredentials, getIndiviualsCompaniesById } from '../../redux/features/contactSlice';

function AddCredntials(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
   

    const initialInputRef = React.createRef() 

    const contactProfileData = useSelector((state)=> state.contactProfileData)
    const contactId = useSelector((state)=> state.contactProfileData.contactId)
    const editCredentialId = useSelector((state)=> state.contactProfileData.editCredentialId)

    const expiryDatecalandarRef = React.createRef()

    const [showPasswordInput,setshowPasswordInput] = React.useState(false)

    const initialFocusRef = React.createRef()
    const [selectedRadio,setselectedRadio] = React.useState("Website")
    const [selectedRadio1,setselectedRadio1] = React.useState("In the office")
    const [credentialData,setcredentialData] = React.useState({
         credentialTyp:'Website',
         credentialTypeValue:'',
         credentialTypeValueValidation:'',
         userName:'',
         userNameValidation:'',
         userPassword:'',
         userPasswordValidation:'',
         expiryDate:'',
         expiryDateValidation:'',
         dscType:''
    })

    const closeAddCredentialDialog = (props) => {

        dispatch(addCredentialOpenClose({
            credentialOpenClose: false,
            editCredentialId:'',
            contactId:''
        }))
        
        setcredentialData({
                credentialTyp:'Website',
                credentialTypeValue:'',
                credentialTypeValueValidation:'',
                userName:'',
                userNameValidation:'',
                userPassword:'',
                userPasswordValidation:'',
                expiryDate:'',
                expiryDateValidation:'',
                dscType:''
        })


    }

    const onChangeInput = (props,e) => {

            if(props==="credentialTypeValue"){

                setcredentialData((prevState)=>{
                    const newState = {...prevState,credentialTypeValue:e.target.value,credentialTypeValueValidation:''}
                    return newState
                })

              

            }else if(props==="userName"){

                setcredentialData((prevState)=>{
                    const newState = {...prevState,userName:e.target.value,userNameValidation:''}
                    return newState
                })

            }else if(props==="userPassword"){

                setcredentialData((prevState)=>{
                    const newState = {...prevState,userPassword:e.target.value,userPasswordValidation:''}
                    return newState
                })

            }else if(props==="expiryDate"){

                setcredentialData((prevState)=>{
                    const newState = {...prevState,expiryDate:new Date(e.target.value),expiryDateValidation:''}
                    return newState
                })

            } 

    }






    const addCredentialInDb = (props) => {

        if (credentialData.credentialTypeValue === "") {
            setcredentialData((prevState) => {
                const newState = { ...prevState, credentialTypeValueValidation: 'error' }
                return newState
            })
        } else if (credentialData.userName === "") {

            setcredentialData((prevState) => {
                const newState = { ...prevState, userNameValidation: 'error' }
                return newState
            })


        } else if (credentialData.userPassword === "") {

            setcredentialData((prevState) => {
                const newState = { ...prevState, userPasswordValidation: 'error' }
                return newState
            })


        }else{

               if(selectedRadio==="Website"){

                    const websiteRegex = /^(https?:\/\/)?[a-zA-Z0-9_\-]+(\.[a-zA-Z0-9_\-]+)+([a-zA-Z]{2,6})?(\/\S*)?$/;
                    const inputWebsite = credentialData.credentialTypeValue;
                    if (websiteRegex.test(inputWebsite)) {
                    
                        closeAddCredentialDialog("")
                        const newCredential = { ...credentialData, creatorId: userId, timeStamp: "" + new Date() + "", updatedTimeStamp: "" + new Date() + "" }
                        dispatch(addOrgCredentials({
                            userId: userId,
                            userEmail: userEmail,
                            contactId: contactId,
                            credentialData: newCredential
                        })).then((data) => {
     
                            dispatch(getIndiviualsCompaniesById({
                                userId: userId,
                                userEmail: userEmail,
                                contactId: contactId
                            }))
     
                        })
      
     
                    } else {
     
                        setcredentialData((prevState) => {
                            const newState = { ...prevState, credentialTypeValueValidation: 'error' }
                            return newState
                        })
     
                    }

   

               }else if(selectedRadio==="DSC"){


                   if (credentialData.expiryDate === "") {

                       setcredentialData((prevState) => {
                           const newState = { ...prevState, expiryDateValidation: 'error' }
                           return newState
                       })

                   } else {


                    closeAddCredentialDialog("")
                    const newCredential = { ...credentialData, creatorId: userId, timeStamp: "" + new Date() + "", updatedTimeStamp: "" + new Date() + "" }
                    dispatch(addOrgCredentials({
                        userId: userId,
                        userEmail: userEmail,
                        contactId: contactId,
                        credentialData: newCredential
                    })).then((data) => {
 
                        dispatch(getIndiviualsCompaniesById({
                            userId: userId,
                            userEmail: userEmail,
                            contactId: contactId
                        }))
 
                    })








                   }



               }


 
           }   


    }

    const editCredentialInDb = (props) => {

        if (credentialData.credentialTypeValue === "") {

            setcredentialData((prevState) => {
                const newState = { ...prevState, credentialTypeValueValidation: 'error' }
                return newState
            })

        } else if (credentialData.userName === "") {

            setcredentialData((prevState) => {
                const newState = { ...prevState, userNameValidation: 'error' }
                return newState
            })


        } else if (credentialData.userPassword === "") {

            setcredentialData((prevState) => {
                const newState = { ...prevState, userPasswordValidation: 'error' }
                return newState
            })


        }else{

               if(selectedRadio==="Website"){

                    const websiteRegex = /^(https?:\/\/)?[a-zA-Z0-9_\-]+(\.[a-zA-Z0-9_\-]+)+([a-zA-Z]{2,6})?(\/\S*)?$/;
                    const inputWebsite = credentialData.credentialTypeValue;
                    if (websiteRegex.test(inputWebsite)) {
                    
                        closeAddCredentialDialog("")
                        const newCredential = { ...credentialData, creatorId: userId, timeStamp: "" + new Date() + "", updatedTimeStamp: "" + new Date() + "" }
                      
                        dispatch(editOrgCredentials({
                            userId: userId,
                            userEmail: userEmail,
                            contactId: contactId,
                            editCredentialId: newCredential
                        })).then((data) => {
     
                            dispatch(getIndiviualsCompaniesById({
                                userId: userId,
                                userEmail: userEmail,
                                contactId: contactId
                            }))
     
                        })
      
     
                    } else {
     
                        setcredentialData((prevState) => {
                            const newState = { ...prevState, credentialTypeValueValidation: 'error' }
                            return newState
                        })
     
                    }

   

               }else if(selectedRadio==="DSC"){


                   if (credentialData.expiryDate === "") {

                       setcredentialData((prevState) => {
                           const newState = { ...prevState, expiryDateValidation: 'error' }
                           return newState
                       })

                   } else {


                    closeAddCredentialDialog("")
                    const newCredential = { ...credentialData, creatorId: userId, timeStamp: "" + new Date() + "", updatedTimeStamp: "" + new Date() + "" }
                    dispatch(editOrgCredentials({
                        userId: userId,
                        userEmail: userEmail,
                        contactId: contactId,
                        editCredentialId: newCredential
                    })).then((data) => {
 
                        dispatch(getIndiviualsCompaniesById({
                            userId: userId,
                            userEmail: userEmail,
                            contactId: contactId
                        }))
 
                    })








                   }



               }


 
           }   


    }

    React.useEffect(()=>{

        
          if(contactProfileData.credentialOpenClose===true){

            initialInputRef.current.focus()
          }


    },[contactProfileData.credentialOpenClose])

    
    React.useEffect(()=>{


         

         if(editCredentialId!==null && editCredentialId!==""){

             
             const newData = editCredentialId
             setcredentialData((prevState)=>{
                   const newState = newData 
                   return newState
             })
          
             setselectedRadio(newData.credentialTyp)
             if(newData.dscType!==null&&newData.dscType!==""){
                setselectedRadio1(newData.dscType)
             }

             if(newData.expiryDate!==null&&newData.expiryDate!==""){

                  setcredentialData((prevState)=>{
                      const newState = {...prevState,expiryDate:newData.expiryDate}
                      return newState
                  })  

             }
            

             

         }else{
             // closeAddCredentialDialog("")
         }


    },[editCredentialId])


     

    return (
        <Box>
            <Dialog
                //isOpen={true}
                isOpen={contactProfileData.credentialOpenClose}
                initialFocusRef={initialFocusRef}
                onDismiss={() => closeAddCredentialDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '564px',
                    height: '455px',
                }}
            >

                <Dialog.Header id="header-id">

                    <Text
                        ref={initialFocusRef}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#24292F'
                        }}
                    >
                        Add password
                    </Text>


                </Dialog.Header>


                <Box sx={{ position:'relative',margin:'20px 0px 0px 20px',display:'flex',flexDirection:'row',gap:'24px' }}>
               
                   
                    <FormControl>
                        <Radio value="Website" checked={selectedRadio==="Website"?true:false} onChange={(e)=> 
                        
                            {
                                setselectedRadio("Website")
                                setcredentialData((prevState)=>{
                                    const newState = {...prevState,credentialTyp:"Website",dscType:''}
                                    return newState
                                })
                
                            }
                           
                        } />
                        <FormControl.Label sx={{
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontStyle:'normal',
                            lineHeight:'20px',
                            fontWeight:'400',
                            color:'#24292E',

                        }}>Website</FormControl.Label>
                    </FormControl>

                    <FormControl>
                        <Radio value="DSC"  checked={selectedRadio==="DSC"?true:false}   onChange={(e)=> 
                            {
                                setselectedRadio("DSC")
                                setcredentialData((prevState)=>{
                                    const newState = {...prevState,credentialTyp:"DSC",dscType:'In the office'}
                                    return newState
                                })
                            } 
                            
                            } />
                        <FormControl.Label sx={{
                            fontFamily:'Segoe UI',
                            fontSize:'14px',
                            fontStyle:'normal',
                            lineHeight:'20px',
                            fontWeight:'400',
                            color:'#24292E',
                            
                        }}>DSC / Physical</FormControl.Label>
                    </FormControl>
                 
                </Box>

                <Box sx={{ position:'relative',margin:'35px 20px 0px 20px',display:'flex',flexDirection:'row' }}>
                    
                    <TextInput
                      ref={initialInputRef}
                      sx={{
                        position:'relative',
                        width:'524px',
                        margin:'0px 0px 0px 0px',
                        height:'40px',
                        minHeight:'40px',
                        fontFamily:'Segoe UI',
                        fontSize:'14px',
                        fontStyle:'normal',
                        lineHeight:'20px',
                        color:'#121212'
                      }}
                      placeholder={selectedRadio==="Website"?"https://":"DSC"}
                      value={credentialData.credentialTypeValue}
                      validationStatus={credentialData.credentialTypeValueValidation}
                      onChange={(e)=>onChangeInput("credentialTypeValue",e)}
                     
                    />

                    

                </Box>

                <Box sx={{ position:'relative',margin:'20px 20px 0px 20px',display:'flex',flexDirection:'row',gap:'6px' }}>
                    
                    <TextInput
                        sx={{
                            position: 'relative',
                            width: '259px',
                            margin: '0px 0px 0px 0px',
                            height: '40px',
                            minHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            color: '#121212'
                        }}
                        placeholder={"Username"}
                        value={credentialData.userName}
                        validationStatus={credentialData.userNameValidation}
                        onChange={(e)=>onChangeInput("userName",e)}
                    />

                    <TextInput
                        sx={{
                            position: 'relative',
                            width: '259px',
                            margin: '0px 0px 0px 0px',
                            height: '40px',
                            minHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            color: '#121212'
                        }}
                        placeholder={"Password"}
                        type={showPasswordInput===false?"password":"text"}
                        value={credentialData.userPassword}
                        validationStatus={credentialData.userPasswordValidation}
                        onChange={(e)=>onChangeInput("userPassword",e)}
                        maxLength={40}
                        trailingVisual={()=><EyeOpenIcon style={{display:'flex'}} onClick={()=> { 
                            
                              setshowPasswordInput(!showPasswordInput)
                              setTimeout(()=>{
                                setshowPasswordInput(false)
                              },3000)
                              }
                               
                        } />}
                    />

                    

                </Box>

                <Box sx={{ position:'relative',margin:'20px 20px 0px 20px',display:'flex',flexDirection:'row',gap:'6px',alignItems:'center' }}>


                    {selectedRadio === "DSC" ?

                        <TextInput
                            sx={{
                                position: 'relative',
                                width: '259px',
                                margin: '0px 0px 0px 0px',
                                height: '40px',
                                minHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                lineHeight: '20px',
                                color: '#121212'
                            }}
                            placeholder={"Expiry date"}
                            value={credentialData.expiryDate!==""&&credentialData.expiryDate!==null?
                                new Date(credentialData.expiryDate).getDate()+"/"+(new Date(credentialData.expiryDate).getMonth()+1)+"/"+new Date(credentialData.expiryDate).getFullYear()
                            :""}
                            validationStatus={credentialData.expiryDateValidation}
                            onChange={(e) => 
                             
                                expiryDatecalandarRef.current.showPicker()
                            }
                            onFocus={(e)=>  {
                                expiryDatecalandarRef.current.showPicker()
                             }}
                              
                        />



                        : ""}

                    <input
                        type={"date"}
                        ref={expiryDatecalandarRef}
                        onChange={(e) => {

                            if (e.target.value === "" || e.target.value == null) {

                                
                                onChangeInput("expiryDate", new Date())


                            } else {

                                onChangeInput("expiryDate", e)
                            }


                        }}
                        style={{
                            position: 'absolute',
                            left: '1px',
                            top: '2px',
                            height: '40px',
                            width: '80%',
                            zIndex: '0',
                            visibility: 'hidden'
                        }}

                    />

                        

                   
                    {selectedRadio === "DSC" ?

                        <Box sx={{ position: 'relative', margin: '0px 0px 0px 0px', display: 'flex', flexDirection: 'row', gap: '24px' }}>


                            <FormControl>
                                <Radio value="In the office" checked={selectedRadio1 === "In the office" ? true : false} onChange={(e) => 
                                
                                   {
                                    setselectedRadio1("In the office")
                                    setcredentialData((prevState)=>{
                                        const newState = {...prevState,dscType:'On Client'}
                                        return newState
                                    })
                                   }
                                
                                } />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    lineHeight: '20px',
                                    fontWeight: '400',
                                    color: '#24292E',

                                }}>In the office</FormControl.Label>
                            </FormControl>

                            <FormControl>
                                <Radio value="On Client" checked={selectedRadio1 === "On Client" ? true : false} onChange={(e) => 
                               {
                                setselectedRadio1("On Client")
                                setcredentialData((prevState)=>{
                                    const newState = {...prevState,dscType:'On Client'}
                                    return newState
                                })
                               }

                                } />
                                <FormControl.Label sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    lineHeight: '20px',
                                    fontWeight: '400',
                                    color: '#24292E',

                                }}>On client</FormControl.Label>
                            </FormControl>

                        </Box>


                        : ""}



                </Box>

                  

                <Box
                    borderColor="border.default"
                    borderTopWidth={1}
                    borderTopStyle="solid"
                    sx={{

                        position:'absolute',
                        bottom:'0px',
                        width: '564px',
                        height: '52px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'end'
                    }}>

                    <Button
                        variant="invisible"
                        sx={{
                            width: '73px',
                            height: '32px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}
                        onClick={() =>  {
                            closeAddCredentialDialog("")
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outline"
                        sx={{
                            width: '73px',
                            height: '32px',
                            background: '#0965CE',
                            color: 'white',
                            position: 'relative',
                            margin: '0px 24px 0px 8px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}

                        onClick={()=>{

                            if (editCredentialId == null || editCredentialId==="") {
                                addCredentialInDb("")
                            } else {
                                editCredentialInDb("")
                            }
                            
                        }}
                    >
                        Save
                    </Button>


                </Box>






                



            </Dialog>


             

        </Box>
    )






}

export default AddCredntials