import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { ActionList, Box, Button, Heading, Label, StyledOcticon, Text, Dialog, Checkbox, Tooltip, Spinner } from "@primer/react"
import { createBrowserHistory } from 'history';
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import SideNav from '../Workplace/SideNav';
import Header from '../Workplace/Header';
import './HomeTheme'
import HomeTheme from './HomeTheme';
import { AlertIcon, BeakerIcon, CheckIcon, ChevronRightIcon, LightBulbIcon, OrganizationIcon, TasklistIcon } from '@primer/octicons-react';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import { getHomeStatics, getPaidUsers, getS3Size, getUserOrganizationById, getUserOrganizations } from '../redux/features/spaceSlice';
import { getSubscriptionbyOrganizationId, getSubscriptionbyProjectsId, getUserSubscriptionData, openCloseFreeTrialEndDialog} from '../redux/features/profileSlice';
import AccountSetup from './AccountSetup';
import AccountSetupSuccess from './AccountSetupSuccess';
import FreeTrialEnd from './FreeTrialEnd';
import { accountSetupOpenClose, defaultOrgOpenClose, updateUserDefaultOrgId, upgradePlanOpenClose } from '../redux/features/contactSlice';
import UpgradePlan from './UpgradePlan';
import { isFeedDueTasksStatus, isFeedOpenTasksStatus } from '../redux/features/feedSlice';
import SharedOrganization from './SharedOrganization';
import DefaultOrganizationSucess from './DefaultOrganizationSucess';
mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, { debug: true, ip: true });

function Home() {

    const history = createBrowserHistory()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const defaultOrgisOpen = useSelector((state) => state.contactProfileData.defaultOrgisOpen)
    const defaultSuccessisOpen = useSelector((state) => state.contactProfileData.defaultSuccessisOpen)
    const sharedOrgisOpen = useSelector((state)=> state.contactProfileData.sharedOrgisOpen)
    const accountSetupisOpen = useSelector((state) => state.contactProfileData.accountSetupisOpen)
    const setupSuccessOpenClose = useSelector((state) => state.contactProfileData.setupSuccessOpenClose)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)

    const homeWorkplaceName = localStorage.getItem("homeWorkplaceName") || ""

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
  //  const subscriptionData = useSelector((state) => state.profileData?.subscriptionData)
    const [subscriptionData,setsubscriptionData] = React.useState(null)
    const [showSuccessComponent, setshowSuccessComponent] = React.useState(false)
    const [selectedProjectId, setSelecProjectId] = React.useState("")
    const [copyselectedProjectId,setcopyselectedProjectId]  = React.useState("")
    const [allProjects, setallProjects] = React.useState([])
    const [workplaceProjects, setworkplaceProjects] = React.useState([])
    const [homeStaticCount,sethomeStaticCount] = React.useState({
        openTaskCount:0,
        dueTaskCount:0,
        recurringTaskCount:0
    })

    const defaultDialogFocus = React.createRef(null)

    const getTrialEndDays = (trialEndTimestamp) => {

        const trialEndDate = new Date(trialEndTimestamp * 1000)
        const start = new Date(trialEndDate);
        const end = new Date();
        const diffMs = start - end;
        const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));
        if (diffDays > 0) {

            return diffDays

        } else {

            return 0
        }

    }

    const getActiveSubscriptionEndays = (endDate) => {

       
        const start = new Date(endDate * 1000);
        const end =  new Date();
        const diffMs = start - end;
        const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));
        if (diffDays > 0) {

            return diffDays-1

        } else {

            return 0
        }

    }

    const trackActiveUsers = async (userId, userEmail) => {

        const res = await axios.get('https://geolocation-db.com/json/')
        const sourceIp = res.data.IPv4
        
        const identifyUser = mixpanel.identify(userId)

        const trackActiveUser = mixpanel.track('ActiveUser', {
            distinct_id: userId,
            ip: sourceIp,
            time: new Date().getTime(),
        })

    }

    

    const tractInsititeClick = async (userId) => {

       const res = await axios.get('https://api.ipify.org?format=json')
       const sourceIp = res.data.ip;
        
       const trackInsiteClickByUser = mixpanel.track('Insight', {
            distinct_id: userId,
            ip: sourceIp,
            time: new Date().getTime(),
       })


       
        if (userId !== null && userEmail !== null) {

           

            const postData = {
                api_key: `${process.env.REACT_APP_POSTHOG_APIKEY}`,
                event: 'InsightClick',
                properties: {
                    distinct_id: `${userId}`,
                    user_id: `${userId}`,
                    user_email: `${userEmail}`,
                    email:`${userEmail}`,
                },
                timestamp: new Date().toISOString(),
            };

            const apiUrl = `${process.env.REACT_APP_POSTHOG_APIURL}/`;

            const result = await axios.post(apiUrl, postData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            return result;
        }

       

    }

    const reloadProjects = (props) => {

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            setworkplaceProjects(data.payload?.data)
            localStorage.setItem("homeWorkplaceName", data.payload?.data[0]?.project_name)
            setSelecProjectId(data.payload?.data[0]?.project_id)
            setcopyselectedProjectId(data.payload?.data[0]?.project_id)
        })

    }

    const getAllProjects = (props) => {

        dispatch(getUserOrganizations({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {

            setallProjects(data.payload?.data)
        })

    }

    const getSubscriptionData = (props) => {


        dispatch(getSubscriptionbyProjectsId({
            userEmail: userEmail || localStorage.getItem("userEmail")
        })).then((data) => {
                 console.log("subscription data by project",data.payload)
                 setsubscriptionData(data.payload)
        })

        dispatch(getUserSubscriptionData({
            userEmail: userEmail || localStorage.getItem("userEmail")
        })).then((data) => {
               
                console.log("subscription data by user",data.payload)
        })


    }

    const showDefaultOrgDialog = (props) => {

        dispatch(defaultOrgOpenClose({
            defaultOrgisOpen: true
        }))



    }

    const scloseDefaultOrgDialog = (props) => {

        dispatch(defaultOrgOpenClose({
            defaultOrgisOpen: false
        }))
       
       

        dispatch(getUserOrganizationById({
            userId: userId,
            userEmail: userEmail
        })).then((data) => {
             setSelecProjectId(data.payload?.data[0]?.project_id)
             setcopyselectedProjectId(data.payload?.data[0]?.project_id)
        })

    }

    const explorePlan = (props) => {


        dispatch(upgradePlanOpenClose({
            upgradeisOpen: true
        }))

    }

    const handleClickGotoWorkplace = (props) => {

        if (workplaceProjects[0].verification === "false"){

            dispatch(accountSetupOpenClose({
                accountSetupisOpen: true
            }))

        }else{

            localStorage.setItem("projectId", workplaceProjects[0]?.project_id)
            localStorage.setItem("projectName", workplaceProjects[0]?.project_name)
            localStorage.setItem("workplaceId", workplaceProjects[0]?.workplace_id)
            localStorage.setItem("projectCreatorId", workplaceProjects[0]?.creator_id)
            navigate('/space/' + workplaceProjects[0]?.project_name)

        }

       

    }


    const handleClickSetupOrganization = (props) => {



        if (workplaceProjects[0].verification === "true") {


            showDefaultOrgDialog("")


        } else if (workplaceProjects[0].verification === "false") {

            dispatch(accountSetupOpenClose({
                accountSetupisOpen: true
            }))

        }




    }

    const handleMarkasDefault = (props) => {

        if (selectedProjectId !== null && selectedProjectId !== "") {

            dispatch(getSubscriptionbyOrganizationId({
                userId: userId,
                userEmail: userEmail,
                projectId:selectedProjectId
            })).then((data)=>{

                const customerId = data.payload.body[0]?.customer?.id
                 

                if (data.payload.subscriptionStatus === "active" || data.payload.subscriptionStatus === "in_trial") {

                    dispatch(updateUserDefaultOrgId({
                        userId: userId,
                        userEmail: userEmail,
                        organizationId: selectedProjectId
                    })).then((data) => {
    
                        reloadProjects("")
                        scloseDefaultOrgDialog("")
                        getHomeStaticsFromDb("")
                        setshowLoadingBtn(false)
                    })
                }else{

                     scloseDefaultOrgDialog("")
                     dispatch(openCloseFreeTrialEndDialog({
                        freetrialEndOpen:true,
                        freeTrialCustomerId:customerId
                     }))
                     setshowLoadingBtn(false)
                     
                      
                }

               


                  
                 

            })




          

        }


    }

    const getHomeStaticsFromDb = (props) => {
        

         
           dispatch(getHomeStatics({
               userId:userId,
               userEmail:userEmail
           })).then((data)=>{


               const openTaskCount = data?.payload?.openTaskCount
               const dueTaskCount  = data?.payload?.dueTaskCount
               const recurringTaskCount = data?.payload?.recurringTaskCount


               sethomeStaticCount((prevState) => {
                   return ({
                       ...prevState,
                       openTaskCount: openTaskCount,
                       dueTaskCount: dueTaskCount,
                       recurringTaskCount: recurringTaskCount
                   })
               })
                
           })

    }

    const getPaidUsersFromDb = (props) => {
         
         dispatch(getPaidUsers({
             userId:userId,
             userEmail:userEmail
         }))

    }

    const getS3SizeFromDb = (props) => {

         dispatch(getS3Size({
            userId:userId,
            userEmail:userEmail
         }))
    }


    React.useEffect(() => {


        if (setupSuccessOpenClose === true) {
            setshowSuccessComponent(true)
        }



    }, [setupSuccessOpenClose])


    React.useEffect(() => {

        getAllProjects("")
        getSubscriptionData("")


    }, [defaultOrgisOpen])

    React.useEffect(() => {

        console.log("sharedOrgisOpen",sharedOrgisOpen)

        reloadProjects("")
        getSubscriptionData("")
        getHomeStaticsFromDb("")
       // getPaidUsersFromDb("") // Removed due to error
        getS3SizeFromDb("")

        dispatch(isFeedDueTasksStatus({
            isFeedDueTasks: false
        }))
        dispatch(isFeedOpenTasksStatus({
            isFeedOpenTasks:false
        }))


    }, [sharedOrgisOpen])


    React.useState(() => {


        reloadProjects("")
        trackActiveUsers(userId, userEmail)
        getSubscriptionData("")
        getHomeStaticsFromDb("")
       // getPaidUsersFromDb("") // Removed due to error
        getS3SizeFromDb("")

        dispatch(isFeedDueTasksStatus({
            isFeedDueTasks: false
        }))
        dispatch(isFeedOpenTasksStatus({
            isFeedOpenTasks:false
        }))
       
    })

 

    return (<Box
        position={"relative"}
        display="flex"
        background={"#F6F8FA"}
        height="100vh"
        width={"100vw"}
        overflowY={"auto"}

    >
        <Helmet>
            <title>
                Home - deski

            </title>
        </Helmet>

        <Header />
        <SideNav />

        <Box sx={{
            position: 'absolute',
            top: '48px',
            bottom: '0px',
            left: '64px',
            right: '64px',
            display:'flex',
            flexDirection:'column',
            
             
        }}>
        <Box sx={HomeTheme.parentBox}>


            <Heading sx={HomeTheme.orgHeading}>Workplace</Heading>

            <Box sx={{
                position: 'absolute',
                left:'0px',
               // left: '124px',
                top: '10px',
                width: '1030px',
                minWidth: '1030px',
                display: 'flex',
                flexDirection: 'column',

            }}>


                <Box sx={{
                    position: 'relative',
                    margin: '0px 0px 0px auto',
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'fit-content'
                }}>
                    <Label
                        variant="accent"
                        sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            width: 'fit-content',
                            height: '24px',
                            background: '#DDF4FF',
                            borderRadius: '3px',
                            color: '#0969DA',
                            border: 'none',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '10px',
                            lineHeight: '16px',
                        }}
                    >
                         
                         {subscriptionData?.body?.length > 0 ? subscriptionData?.body[0]?.subscription.status==="active"? getActiveSubscriptionEndays(subscriptionData?.body[0]?.subscription?.current_term_end)  :  getTrialEndDays(subscriptionData?.body[0]?.subscription?.trial_end) + " " : ""}

                         
                        {/* {subscriptionData?.body?.length > 0 ? getTrialEndDays(subscriptionData?.body[0]?.subscription?.trial_end) + " " : ""} */}


                        days left


                    </Label>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '11px',
                        marginTop: '2px'
                    }}>

                        <Heading sx={HomeTheme.headerRightBox.heading}>
                            {subscriptionData?.body?.length > 0 ? subscriptionData?.body[0]?.subscription?.subscription_items[0]?.item_price_id?.slice(0, 4) : ""}

                        </Heading>
                        <Button
                            variant="outline"
                            sx={HomeTheme.headerRightBox.button}
                            onClick={() => {
                                explorePlan("")
                            }}
                        >
                            EXPLORE PLANS
                        </Button>


                    </Box>


                </Box>

            </Box>





            <Box sx={HomeTheme.projectBox}>

                <Box sx={HomeTheme.projectBoxChild}>

                    <Box sx={HomeTheme.projectBoxChildInnerBox1}>

                        <Box sx={HomeTheme.projectBoxChildInnerBox2}>

                            <StyledOcticon icon={CheckIcon} size={20} color="#79CE17" />
                        </Box>

                        <Heading sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '21px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 0px 0px 20px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>

                            {workplaceProjects[0]?.project_name || homeWorkplaceName}
                        </Heading>




                    </Box>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '22px 28px 0px 24px'
                    }}>
                        Stay on top of all your assigned tasks and track your
                        work progress with ease

                    </Text>

                    <Button
                        variant="outline"
                        sx={{
                            color: '#FFFFFF',
                            background: '#0965CE',
                            position: 'relative',
                            margin: 'auto 20px 15px auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '20px',
                            height: '40px',
                            width: '116px'
                        }}
                        onClick={() => { handleClickGotoWorkplace("")} }
                    >
                        Go to works
                    </Button>


                </Box>

                <Box sx={HomeTheme.projectBoxChild}>

                    <Box sx={HomeTheme.projectBoxChildInnerBox1}>

                        <Box sx={HomeTheme.projectBoxChildInnerBox2}>

                            <StyledOcticon icon={CheckIcon} size={20} color="#79CE17" />
                        </Box>

                        <Heading sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '21px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 0px 0px 20px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Configure your firm
                        </Heading>




                    </Box>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '22px 28px 0px 24px'
                    }}>
                        Streamline Your Workflow: Configure Your Firm's
                        System to Optimize Efficiency
                    </Text>

                    <Box sx={{
                        position: 'relative',
                        margin: 'auto 20px 15px 20px',
                        height: '40px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '9px'
                    }}>

                        <Button variant="invisible"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                width: '118px',
                                height: '40px',
                            }}
                            onClick={() => {

                            }}
                        >Watch video</Button>
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292F',
                                width: '161px',
                                height: '40px',

                            }}
                            onClick={() => {

                                handleClickSetupOrganization("")

                            }}
                        >Setup Organization</Button>
                    </Box>

                </Box>

                <Box sx={HomeTheme.projectBoxChild}>

                    <Box sx={HomeTheme.projectBoxChildInnerBox1} >

                        <Box sx={HomeTheme.projectBoxChildInnerBox3} style={{background:'#DCFFE4'}}>

                            <StyledOcticon icon={BeakerIcon} size={16} color="#586069" />
                        </Box>

                        <Heading sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '21px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '-1px 0px 0px 20px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Organization Insights
                        </Heading>




                    </Box>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '22px 28px 0px 24px'
                    }}>
                        Overview  work management, including productivity,
                        efficiency, and timeliness.
                    </Text>
                  
                    <Button
                     variant="invisible"
                        sx={{
                            display: workplaceProjects[0]?.verification==="true"?'flex':'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            width: '83px',
                            height: '40px',
                            position: 'relative',
                            margin: 'auto 20px 15px auto'
                        }}
                        onClick={()=>{
                            tractInsititeClick(userId)
                            localStorage.setItem("projectId", workplaceProjects[0]?.project_id)
                            localStorage.setItem("projectName", workplaceProjects[0]?.project_name)
                            localStorage.setItem("workplaceId", workplaceProjects[0]?.workplace_id)
                            localStorage.setItem("projectCreatorId", workplaceProjects[0]?.creator_id)
                            navigate('/insight')
                        }}

                    >View</Button>

                </Box>


            </Box>


            <Heading sx={{
                fontFamily: 'Avenir LT Std',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19.2px',
                color: '#24292E',
                position: 'absolute',
              //  left: '124px',
                left:'0px',
                top: '316px'
            }}>
                Explore workflow
            </Heading>

            <ActionList sx={{
                position: 'absolute',
              //  left: '124px',
                left:'0px',
                top: '347px',
                width: '1030px',
                minWidth: '1030px',
                maxWidth: '1030px',
                display: 'flex',
                flexDirection: 'column',
                gap: '14px',
                alignItems: 'center',
                paddingBottom: '100px'
            }}>


                <ActionList.Item
                    sx={{
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        background: '#FFFFFF',
                        minHeight: '52px',
                        height: '52px',
                        maxHeight: '52px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                    }}
                    onClick={()=>{
                        localStorage.setItem("filterType", "OpenTask")
                        dispatch(isFeedOpenTasksStatus({
                            isFeedOpenTasks: true
                        }))
                        navigate('/feed')
                    }}
                >

                    <ActionList.LeadingVisual sx={{ marginLeft: '21px', color: '#000000' }}>
                        <TasklistIcon />
                    </ActionList.LeadingVisual>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 22px'
                    }}>
                        Open task is a work item that is currently in progress but has not yet been completed
                    </Text>


                    <ActionList.TrailingVisual sx={{
                        marginRight: '11px'
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                           // fontStyle: 'normal',
                           // fontWeight: '400',
                            
                            fontStyle: 'italic',
                            fontWeight:'600',
                            fontSize: '14px',
                            lineHeight: '19px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 15px 0px 0px',
                            
                        }}>
                            {homeStaticCount.openTaskCount} task
                        </Text>
                        <ChevronRightIcon />
                    </ActionList.TrailingVisual>

                </ActionList.Item>


                <ActionList.Item
                    sx={{
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        background: '#FFFFFF',
                        minHeight: '52px',
                        height: '52px',
                        maxHeight: '52px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                    onClick={()=>{
                        localStorage.setItem("filterType","DueTask")
                        dispatch(isFeedDueTasksStatus({
                            isFeedDueTasks:true
                        }))
                        navigate('/feed')
                    }} 
                >

                    <ActionList.LeadingVisual sx={{ marginLeft: '21px', color: '#000000' }}>
                        <AlertIcon />
                    </ActionList.LeadingVisual>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 22px'
                    }}>
                        Due  is a work item that is currently in progress but has not yet been completed
                    </Text>


                    <ActionList.TrailingVisual sx={{
                        marginRight: '11px'
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'italic',
                            fontWeight:'600',
                            fontSize: '14px',
                            lineHeight: '19px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 15px 0px 0px'
                        }}>
                            <span style={{color:parseInt(homeStaticCount.dueTaskCount)>0?'red':'#24292E'}}>{homeStaticCount.dueTaskCount}</span> task
                        </Text>
                        <ChevronRightIcon />
                    </ActionList.TrailingVisual>

                </ActionList.Item>

                <ActionList.Item
                    sx={{
                        border: '1px solid #D0D7DE',
                        borderRadius: '6px',
                        background: '#FFFFFF',
                        minHeight: '52px',
                        height: '52px',
                        maxHeight: '52px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >

                    <ActionList.LeadingVisual sx={{ marginLeft: '21px', color: '#000000' }}>
                        <LightBulbIcon />
                    </ActionList.LeadingVisual>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '19px',
                        color: '#24292E',
                        position: 'relative',
                        margin: '0px 0px 0px 22px'
                    }}>
                        Recurring task is waiting for action, please take necessary steps
                    </Text>


                    <ActionList.TrailingVisual sx={{
                        marginRight: '11px'
                    }}>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'italic',
                            fontWeight:'600',
                            fontSize: '14px',
                            lineHeight: '19px',
                            color: '#24292E',
                            position: 'relative',
                            margin: '0px 15px 0px 0px'
                        }}>
                            {homeStaticCount.recurringTaskCount} task
                        </Text>
                        <ChevronRightIcon />
                    </ActionList.TrailingVisual>

                </ActionList.Item>





            </ActionList>




        </Box>
        </Box>


        
        {accountSetupisOpen === true ? <AccountSetup /> : ""}
        {showSuccessComponent === true ? <AccountSetupSuccess /> : ""}
        {sharedOrgisOpen === true ? <SharedOrganization allProjects={allProjects} /> : ""}
        {defaultSuccessisOpen === true ? <DefaultOrganizationSucess /> : ""}
        
        <Dialog
            isOpen={defaultOrgisOpen}
            onDismiss={() => {
                scloseDefaultOrgDialog("")
            }}
            sx={{
                position: 'absolute',
                width: '921px',
                height: '526px',
                background: '#FFFFFF',
                border: '1px solid #D0D7DE',
                borderRadius: '3px'
            }}
            initialFocusRef={defaultDialogFocus}
        >

            <Box sx={{
                position: 'absolute',
                top: '35px',
                left: '40px',
                right: '40px',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: '11px',

            }}>

                <Heading sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: '#24292E',
                    letterSpacing: '0.3px'
                }}>
                    Your default organization
                </Heading>

                <Text sx={{
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#24292E',

                }}>
                    This is your default organization for working. You can switch to another organization or create
                </Text>


            </Box>


            <Box sx={{
                position: 'absolute',
                top: '138px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                height: 'fit-content',
                width: '814px',
                minWidth: '814px',
                maxWidth: '814px',
                display: 'flex',
                flexDirection: 'row',
                gap: '50px'
            }}>




                {allProjects?.slice(0, 3)
                    .
                    sort((a, b) => {
                        if (a.project_id === copyselectedProjectId) {
                            return -1; // Place the selected ID at the beginning
                        } else if (b.project_id === copyselectedProjectId) {
                            return 1; // Place the selected ID at the beginning
                        } else {
                            return a.project_id - b.project_id; // Sort the rest of the array by ID
                        }
                    })
                    .map((pDta, index) => {


                        return (

                            <Box
                                onClick={() => 
                                    
                                    {

                                      
                                      if(copyselectedProjectId===pDta.project_id){

                                      }else{
                                         setSelecProjectId(pDta.project_id)
                                      }  
                                     
                                    
                                      
                                     
                                    }
                                }
                                sx={{
                                    height: '216px',
                                    width: '236px',
                                    minHeight: '216px',
                                    minWidth: '236px',
                                    maxHeight: '216px',
                                    maxWidth: '236px',
                                    border: selectedProjectId === pDta.project_id ? '1px solid #0366D6' : '1px solid #D0D7DE',
                                    boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    cursor: 'pointer'

                                }}>


                                <Box sx={{
                                    position: 'relative',
                                    margin: '10px 0px 0px 18px',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>

                                    {pDta.project_type === "s" ?

                                        <Label
                                            sx={{
                                                background: '#FFF8C5',
                                                width: '42px',
                                                height: '24px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',
                                                border: 'none',
                                                borderRadius: '0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E',
                                                paddingLeft: '4px'
                                            }}
                                        >
                                            Shared
                                        </Label> : <Label
                                            sx={{

                                                width: '42px',
                                                height: '24px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',
                                                border: 'none',
                                                borderRadius: '0px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E',
                                                paddingLeft: '4px'
                                            }}
                                        >

                                        </Label>
                                    }




                                    {selectedProjectId === pDta.project_id ?

                                        <Checkbox checked sx={{

                                            position: 'relative',
                                            margin: '0px 20px 0px auto'

                                        }} />

                                        : ""

                                    }

                                </Box>


                                <Box sx={{
                                    height: '100px',
                                    width: '100px',
                                    minHeight: '100px',
                                    minWidth: '100px',
                                    maxHeight: '100px',
                                    maxWidth: '100px',
                                    border: '1px solid rgba(27, 31, 36, 0.15)',
                                    borderRadius: '6px',
                                    position: 'relative',
                                    margin: '6px auto 0px auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>

                                    <OrganizationIcon size={36} />

                                </Box>

                                <Text

                                    aria-label={pDta.project_name}
                                    sx={{
                                        position: 'relative',
                                        margin: '21px auto 0px auto',
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        letterSpacing: '0.3px',
                                        color: '#24292E',
                                        minWidth: '199px',
                                        maxWidth: '199px',
                                        width: '199px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    }}>

                                    <Tooltip
                                        title={pDta.project_name}
                                    >
                                        {pDta.project_name}
                                    </Tooltip>

                                </Text>




                            </Box>

                        )


                    })}





            </Box>

            {showLoadingBtn===true ? 
             <Button
             ref={defaultDialogFocus}
             sx={{
                 position: 'absolute',
                 right: '56px',
                 bottom: '30px',
                 width: '127px',
                 height: '40px',
                 display: selectedProjectId!==copyselectedProjectId?'flex':'none', 
                 alignItems: 'center',
                 justifyContent: 'center',
                 background: '#F6F8FA',
                 borderRadius: '3px',
                 fontFamily: 'Segoe UI',
                 fontStyle: 'normal',
                 fontWeight: '600',
                 fontSize: '14px',
                 lineHeight: '20px',
                 color: '#24292F'
             }}
             onClick={() => { }}

         >
              <Spinner size="small" />
         </Button>
            :
            <Button
            ref={defaultDialogFocus}
            sx={{
                position: 'absolute',
                right: '56px',
                bottom: '30px',
                width: '127px',
                height: '40px',
                display: selectedProjectId!==copyselectedProjectId?'flex':'none', 
                alignItems: 'center',
                justifyContent: 'center',
                background: '#F6F8FA',
                borderRadius: '3px',
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#24292F'
            }}
            onClick={() => {
                setshowLoadingBtn(true)
                handleMarkasDefault("")
            }}

        >
            Make as default
        </Button>
            
            } 
           
           


        </Dialog>




    </Box>)

}

export default Home
