import { Box, Button, Dialog, Heading, IconButton, Label, StyledOcticon, Text} from "@primer/react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import TaxHeroHeader from "./TaxHeroHeaderSanbox"
import React from "react"
import customTheme from  "../../Theme.module.scss"    
import customThemVariable from  "../../ThemeVariable.module.scss" 
import { ChevronRightIcon, TagIcon, XIcon } from "@primer/octicons-react"
import { getChargebeePlan, openCloseSubscriptionPlanDialog, taxHeroSubscriptionUpgrade } from "../redux/features/profileSlice"

export default function SubscriptionPlansSanbox() {

    const dispatch = useDispatch()

    const userId = useSelector((state)=> state.profileData.userId)
    const userEmail = useSelector((state)=> state.profileData.userEmail)
    const taxHeroPlanPrice = useSelector((state)=> state.profileData.taxHeroPlanPrice)

    const openCloseSubscriptionPlan = useSelector((state)=> state.profileData.openCloseSubscriptionPlan) 
    const [selectedYear,setselectedYear] = React.useState("Yearly")
   
    const [planData,setPlanData] = React.useState([])
    const [placePrice,setplacePrice] = React.useState([...taxHeroPlanPrice])


    const closeSubscriptionPlan = (props) => {

        dispatch(openCloseSubscriptionPlanDialog({ openCloseSubscriptionPlan: false }))
    }

    const upgradeSubsctiptionPlan = (props) => {

        //subscription upgrade api
        dispatch(taxHeroSubscriptionUpgrade({
            userId: userId,
            userEmail: userEmail,
            selectedPlan: selectedYear
        })).then((data) => {

            closeSubscriptionPlan("")
            const Url = data?.payload?.data?.hosted_page?.url
            window.open(Url)

        })
  
  
     }

    const getChargebeePlanFromApi = (props) => {

        dispatch(getChargebeePlan({
            userId: userId,
            userEmail: userEmail,
            planId: props
        })).then((data) => {
            console.log("getChargebeePlan", data)
          //  setPlanData([...planData, data.payload.data])
            setplacePrice(data.payload.prices)
        })


    }


    React.useEffect(() => {

        if (openCloseSubscriptionPlan === true) {
            getChargebeePlanFromApi("Taxhero")
        }

    }, [openCloseSubscriptionPlan])

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })


   return (<Dialog
        isOpen={openCloseSubscriptionPlan}
        sx={{
            height: '586px',
            maxHeight: '586px',
            maxWidth: '1000px',
            minWidth: '1000px',
            minHeight: '586px',
            width: '1000px',
            borderRadius: '3px',

        }}
        onDismiss={() => { closeSubscriptionPlan("") }}
    >

        <IconButton
            sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                border: 'none',
                background: 'transparent',
                zIndex: '1'
            }}
            icon={() => <XIcon />}
            onClick={() => {
                closeSubscriptionPlan("")
            }}
        />

             <Box sx={{
                position: 'absolute',
                top: '0px',
                bottom: '0px',
                left: '0px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
            }}>

           
            

                <Box sx={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    margin: 'auto',
                    minWidth: '1000px',
                    maxWidth: '1000px',
                    width: '1000px',
                    height: '586px',
                    minHeight: '586px',
                    maxHeight: '586px',
                    background: '#FFFFFF',
                    borderRadius: '3px',
                    border: '1px solid #D0D7DE',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Text
                        className={customTheme.H1}
                        sx={{
                            position: 'absolute',
                            top: '27px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            width: 'fit-content',
                            height: 'fit-content',
                            fontWeight: '700',
                            color: customThemVariable.primaryTextColor
                        }}
                    >
                        Choose the plan that fits your needs.
                    </Text>

                 
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '95px',
                            left: '0px',
                            right: '0px',
                            margin: 'auto',
                            width: '306px',
                            minWidth: '306px',
                            maxWidth: '306px',
                            height: '47px',
                            minHeight: '47px',
                            maxHeight: '47px',
                            background: '#F6F8FA',
                            borderRadius: '8px',
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            
                        }}
                    >

                        {selectedYear==="Yearly" ? 
                        
                            <>
                                <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        marginLeft: '24px',
                                        color: customThemVariable.secondaryTextColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setselectedYear("monthly")
                                    }}
                                >
                                    Monthly
                                </Text>
                                <Button
                                    variant="default"
                                    sx={{
                                        marginLeft: '24px',
                                        minHeight: '39px',
                                        maxHeight: '39px',
                                        height: '39px',
                                        border: '1px solid #0969DA',
                                        borderColor: '#0969DA',
                                        background: customThemVariable.whiteBgColor,
                                        borderRadius: '8px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingLeft: '21px',
                                        paddingRight: '21px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            className={customTheme.customfontsmall}
                                            sx={{
                                                display: 'flex',
                                                color: customThemVariable.secondaryTextColor
                                            }}
                                        >
                                            Yearly
                                        </Text>

                                        <Box sx={{
                                            marginLeft: '9px',
                                            display: 'flex',
                                            color: '#BF3989'
                                        }}>

                                            <TagIcon />


                                        </Box>

                                        <Text
                                            className={customTheme.customfontsmall}
                                            sx={{
                                                display: 'flex',
                                                marginLeft: '5px',
                                                background: 'linear-gradient(290deg, #9867F0 0%, #ED4E50 100%)',
                                                backgroundClip: 'text',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent'
                                            }}
                                        >
                                            Get 1 month free
                                        </Text>


                                    </Box>






                                </Button>
                            </>
                        
                        
                        :
                            <>
                                <Button
                                    variant="default"
                                    sx={{
                                        marginLeft: '7px',
                                        minHeight: '39px',
                                        maxHeight: '39px',
                                        height: '39px',
                                        border: '1px solid #0969DA',
                                        borderColor: '#0969DA',
                                        background: customThemVariable.whiteBgColor,
                                        borderRadius: '8px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',

                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text
                                            className={customTheme.customfontsmall}
                                            sx={{
                                                display: 'flex',
                                                color: customThemVariable.secondaryTextColor
                                            }}
                                        >
                                            Monthly
                                        </Text>
                                    </Box>

                                </Button>
                                {/* <Text
                                    className={customTheme.customfontsmall}
                                    sx={{
                                        marginLeft: '29px',
                                        minWidth: '180px',
                                        color: customThemVariable.secondaryTextColor,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setselectedYear("Yearly")
                                    }}
                                >
                                    Yearly
                                </Text> */}

                            <Button
                                variant="default"
                                sx={{
                                    marginLeft: '8px',
                                    minHeight: '39px',
                                    maxHeight: '39px',
                                    height: '39px',
                                    border: 'none',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingLeft: '21px',
                                    paddingRight: '21px'
                                }}
                                onClick={() => {
                                    setselectedYear("Yearly")
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text
                                        className={customTheme.customfontsmall}
                                        sx={{
                                            display: 'flex',
                                            color: customThemVariable.secondaryTextColor
                                        }}
                                    >
                                        Yearly
                                    </Text>

                                    <Box sx={{
                                        marginLeft: '9px',
                                        display: 'flex',
                                        color: '#BF3989'
                                    }}>

                                        <TagIcon />


                                    </Box>

                                    <Text
                                        className={customTheme.customfontsmall}
                                        sx={{
                                            display: 'flex',
                                            marginLeft: '5px',
                                            background: 'linear-gradient(290deg, #9867F0 0%, #ED4E50 100%)',
                                            backgroundClip: 'text',
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent'
                                        }}
                                    >
                                        Get 1 month free
                                    </Text>


                                </Box>






                            </Button>


                            </>
                        
                        
                        } 

                     

                       


                    </Box>


                    <Box
                        sx={{
                            position: 'absolute',
                            left: '22px',
                            right: '22px',
                            top: '174px',
                            bottom: '17px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '27px'
                        }}
                    >

                        {/* plan 1 */}

                        <Box sx={{
                            minWidth: '300px',
                            maxWidth: '300px',
                            width: '300px',
                            height: '350px',
                            minHeight: '350px',
                            maxHeight: '350px',
                            borderRadius:'3px',
                            border:'1px solid #D0D7DE',
                            background:'#ffffff',
                            display:'flex',
                            flexDirection:'column'
                        }}>

                            <Text
                                className={customTheme.H2}
                                sx={{
                                    marginTop: '18px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor
                                }}
                            >
                                Free Trial
                            </Text>
                            <Text
                                className={customTheme.H1}
                                sx={{
                                    marginTop: '7px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor,
                                    fontWeight: '700'
                                }}
                            >
                                INR 0.00
                            </Text>
                            <Text
                                className={customTheme.H5}
                                sx={{
                                    marginTop: '15px',
                                    textAlign: 'center',
                                    color: customThemVariable.textblack,
                                    
                                }}
                            >
                                7 days Free trial
                            </Text>

                            <Box
                                sx={{
                                    marginTop: '16px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Automated ITR data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Automated GST data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Return filed details 
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Notices and  demand status
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Proceedings
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'35px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    TDS (Traces coming soon)
                                </Text>

                            </Box>

                        </Box>


                        {/* ------ */}


                        
                        {/* plan 2 */}

                        <Box sx={{
                            minWidth: '300px',
                            maxWidth: '300px',
                            width: '300px',
                            height: '393px',
                            minHeight: '393px',
                            maxHeight: '393px',
                            borderRadius: '3px',
                            background: 'radial-gradient(50% 50% at 50% 50%, #2188FF 0%, #2188FF 48%, #0D74EC 85%, #0D74EC 98%)',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                            <Text
                                className={customTheme.H5}
                                sx={{
                                    marginLeft: '6px',
                                    marginRight: '6px',
                                    marginTop: '5px',
                                    color: customThemVariable.textwhite,
                                    fontSize: '10px',
                                    textAlign: 'center'
                                }}
                            >
                                MOST POPULAR
                            </Text>

                            <Box sx={{
                                marginLeft: '6px',
                                minWidth: '288px',
                                maxWidth: '288px',
                                width: '288px',
                                height: '360px',
                                minHeight: '360px',
                                maxHeight: '360px',
                                borderRadius: '8px',
                                background: '#ffffff',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>

                            <Text
                                className={customTheme.H2}
                                sx={{
                                    marginTop: '19px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor
                                }}
                            >
                                Pro
                            </Text>
                            <Text
                                className={customTheme.H1}
                                sx={{
                                    marginTop: '7px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor,
                                    fontWeight: '700'
                                }}
                            >
                                INR 

                               {selectedYear==="Yearly" ? 
                               
                               placePrice.length > 0 ?
                               placePrice[1]?.item_price?.price / 100
                               : ""
                               
                               :
                               placePrice.length > 0 ?
                               placePrice[0]?.item_price?.price / 100
                               : ""
                               } 
                                

                            </Text>


                            <Box
                                sx={{
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    marginTop: '15px',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    background: '#1B1F23',
                                    border: 'none',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: customThemVariable.textwhite,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    cursor: 'pointer',
                                    borderRadius: '3px'
                                }}

                                onClick={() => {
                                    upgradeSubsctiptionPlan("")
                                }}

                            >
                                Continue with Pro
                            </Box>

                                <Box
                                sx={{
                                    marginTop: '16px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Automated ITR data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Automated GST data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Return filed details 
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Notices and  demand status
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    Proceedings
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    
                                }}>
                                <Box
                                 sx={{
                                    marginLeft:'20px'
                                 }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,
                                        
                                    }}
                                >
                                    TDS (Traces coming soon)
                                </Text>

                            </Box>

                              </Box>


                        </Box>


                        {/* ------ */}



                        {/* plan 3 */}

                        <Box sx={{
                            minWidth: '300px',
                            maxWidth: '300px',
                            width: '300px',
                            height: '368px',
                            minHeight: '368px',
                            maxHeight: '368px',
                            borderRadius:'3px',
                            border:'1px solid #D0D7DE',
                            background:'#ffffff',
                            display:'flex',
                            flexDirection:'column'
                        }}>

                            <Text
                                className={customTheme.H2}
                                sx={{
                                    marginTop: '18px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor
                                }}
                            >
                                Custom
                            </Text>
                            <Text
                                className={customTheme.H1}
                                sx={{
                                    marginTop: '7px',
                                    textAlign: 'center',
                                    color: customThemVariable.primaryTextColor,
                                    fontWeight: '700'
                                }}
                            >
                                Let's talk
                            </Text>

                            <Button
                                sx={{
                                    marginTop: '15px',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    marginLeft: '26px',
                                    marginRight: '26px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    background: customThemVariable.whiteBgColor,
                                    color: customThemVariable.primaryTextColor
                                }}
                            >
                                Contact Sale
                            </Button>

                            <Box
                                sx={{
                                    marginTop: '16px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    Automated ITR data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    Automated GST data fetching
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    Return filed details
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    Notices and  demand status
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    Proceedings
                                </Text>

                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    minWidth: 'auto',
                                    maxWidth: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    flexDirection: 'row',

                                }}>
                                <Box
                                    sx={{
                                        marginLeft: '25px'
                                    }}
                                >
                                    <ChevronRightIcon size={12} />
                                </Box>
                                <Text
                                    className={customTheme.textnormal}
                                    sx={{
                                        marginLeft: '10px',
                                        color: customThemVariable.primaryTextColor,

                                    }}
                                >
                                    TDS (Traces coming soon)
                                </Text>

                            </Box>



                        </Box>

                        {/* ------ */}


                    </Box>





                </Box>




            </Box>


        </Dialog>
        )
} 