import React from "react"
import { useNavigate } from 'react-router-dom'
import {Box, Button, Heading, TextInput,FormControl,Text,Radio,Checkbox, IconButton, StyledOcticon, Dialog, ToggleSwitch } from "@primer/react"
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleFillIcon, XIcon } from "@primer/octicons-react";
import { upgradePlanOpenClose } from "../redux/features/contactSlice";
import { chargebeesubscriptionReniew, getChargebeePlan } from "../redux/features/profileSlice";
import CustomSwitch from "../CustomSwitch";
 

const upgradePlanImg = `${process.env.REACT_APP_S3BUCKETURL}/AccountSetup/upgradeplan.svg`    

const parentBox = {
    position:'fixed',
    left:'0px',
    right:'0px',
    top:'0px',
    margin:'auto',
    height:'526px',
    maxHeight:'526px',
    maxWidth:'921px',
    minWidth:'921px',
    minHeight:'526px',
    width:'921px',
    overflow:'auto',
    background:'#FFFFFF',
    border:'1px solid #D0D7DE',
    borderRadius:'3px',
    zIndex:'1'
    
}

 
export default function UpgradePlan(){


    const dispatch = useDispatch()
    const userId = useSelector((state)=> state.profileData.userId)
    const userEmail = useSelector((state)=> state.profileData.userEmail)
    const upgradeisOpen = useSelector((state) => state.contactProfileData.upgradeisOpen)
    const subscriptionData = useSelector((state) => state.profileData?.subscriptionData)

    const [planType,setPlanType] = React.useState(false)
    const [numberOfUsers,setnumberOfUsers] = React.useState(0)
    
    const closeUpgradePlan = (props) => {

         dispatch(upgradePlanOpenClose({
            upgradeisOpen:false
         }))

    } 


   const upgradeSubsctiptionPlan = (props) => {

      const subscriptionId = subscriptionData?.body[0]?.subscription.id
      dispatch(
         chargebeesubscriptionReniew({
            userId: userId,
            userEmail: userEmail,
            subscriptionId: subscriptionId,
            planType: planType===true?"Yearly":"Monthly",
            projectId: ''
         })).then((data) => {

            console.log("chargebeesubscriptionReniew response", data.payload.data.response.hosted_page.url)
            const Url = data.payload.data.response.hosted_page.url
            window.open(Url)

         })



   }

   const getNumberofUsersFromChargebee = (props) => {


      dispatch(getChargebeePlan({
         userId: userId,
         userEmail: userEmail,
         planId: 'Grow'
      })).then((data) => {

         console.log("getChargebeePlan", data.payload)
         const totalquantity = data?.payload?.data?.metadata?.totalquantity
         setnumberOfUsers(totalquantity)
      })


   }

   

   React.useEffect(() => {

      if (upgradeisOpen === true) {
         getNumberofUsersFromChargebee("")
      }

   }, [upgradeisOpen])


     
 
    return(

        <Dialog 
          isOpen={upgradeisOpen}
          sx={{
             height: '526px',
             maxHeight: '526px',
             maxWidth: '921px',
             minWidth: '921px',
             minHeight: '526px',
             width: '921px',
          }}
          onDismiss={()=>{
            closeUpgradePlan("")
          }}
        >
        <Box sx={parentBox} >

           <Box sx={{
            position:'absolute',
            top:'35px',
            left:'40px',
            right:'40px',
            height:'fit-content',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            
           }}>
           
              <Heading sx={{
                  fontFamily:'Segoe UI',
                  fontStyle:'normal',
                  fontWeight:'600',
                  fontSize:'20px',
                  lineHeight:'30px',
                  color:'#24292E',
                  letterSpacing:'0.3px'
              }}>
               Upgrade to <span style={{color:'#0366D6'}}>Grow</span> 🎉
              </Heading>
              
              <Text sx={{
                 position:'relative',
                 margin:'0px 0px 0px 55px',
                 fontFamily:'Segoe UI',
                 fontStyle:'normal',
                 fontWeight:'600',
                 fontSize:'14px',
                 lineHeight:'18px',
                 color:'#586069',

              }}>Pay monthly</Text>

              
                <Box
                   onChange={() => { setPlanType(!planType) }}
                   sx={{

                      display: 'flex',
                      position: 'relative',
                      margin: '0px 0px 0px 15px'
                   }}>
                   <CustomSwitch checked={planType} />
                </Box>
                       
                 

                <Text sx={{
                   position: 'relative',
                   margin: '0px 0px 0px 15px',
                   fontFamily: 'Segoe UI',
                   fontStyle: 'normal',
                   fontWeight: '600',
                   fontSize: '14px',
                   lineHeight: '18px',
                   color: '#586069',

                }}>Pay annually</Text>

                <Box sx={{
                   position: 'relative',
                   margin: '0px 0px 0px 10px',
                   height: '24px',
                   minHeight: '24px',
                   maxHeight: '24px',
                   width: '68px',
                   minWidth: '68px',
                   maxWidth: '68px',
                   background: '#DAFBE1',
                   borderRadius: '6px',
                   fontFamily:'Segoe UI',
                   fontStyle:'normal',
                   fontWeight:'600',
                   fontSize:'12px',
                   color:'#48C5A9',
                   display:planType?'flex':'none',
                  // display:planType===false?'none':'flex',
                   alignItems:'center',
                   justifyContent:'center'
                }}>

                   20% OFF
                </Box>



           </Box>

            <IconButton
                sx={{
                    position:'absolute',
                    top:'30px',
                    right:'30px',
                    height: '16px',
                    width: '16px',
                    minHeight: '16px',
                    minWidth: '16px',
                    maxHeight: '16px',
                    maxWidth: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none'
                }}
                icon={XIcon}
                onClick={()=>{
                    closeUpgradePlan("")
                }}
            >

            </IconButton>


            <Box sx={{
                position:'absolute',
                top:'95px',
                left:'41px',
                display:'flex',
                flexDirection:'row',
                gap:'11px',
                alignItems:'center'
            }}>

                <Heading sx={{
                   fontFamily: 'Segoe UI',
                   fontStyle: 'normal',
                   fontWeight: '600',
                   fontSize: '20px',
                   lineHeight: '30px',
                   letterSpacing: '0.3px',
                   color: '#24292E'
                }}>
                   {planType === false ? "₹ 2500" : "₹ 1999"}
                </Heading>

                <Text sx={{
                   fontFamily: 'Segoe UI',
                   fontStyle: 'normal',
                   fontWeight: '400',
                   fontSize: '12px',
                   lineHeight: '16px',
                   color: '#121212'
                }}>
                   {numberOfUsers} users / {planType === false ? "month to month billing" : "year to year billing"}
                </Text>

            </Box>


            <Box sx={{
                 position:'absolute',
                 left:'41px',
                 top:'155px',
                 height:'fit-content',
                 width:'fit-content',
                 display:'flex',
                 flexDirection:'column',
                 gap:'17px'
            }}>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Efficient practice  management
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      ITR and GST filing automation
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Automating recurring tasks
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Advanced insights for work stats
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Client directory
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Firm Reports
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      Fetch IT and GST notices
                   </Text>
                </Box>

                <Box sx={{
                     display:'flex',
                     flexDirection:'row',
                     gap:'9px',
                     alignItems:'center'
                }}>
                   <StyledOcticon size={12} icon={CheckCircleFillIcon} color="#79CE17"  />
                   <Text sx={{
                    fontFamily:'Segoe UI',
                    fontStyle:'normal',
                    fontWeight:'600',
                    fontSize:'14px',
                    lineHeight:'19px',
                    color:'#24292E'
                   }}>
                      2GB storage space
                   </Text>
                </Box>


            </Box>

           

            <Box sx={{
                  height:'fit-content',
                  width:'fit-content',
                  position:'absolute',
                  top:'130px',
                  right:'161px'
            }}>
                <img src={upgradePlanImg}  />
            </Box>

            <Box sx={{
                  height:'fit-content',
                  width: '770px',
                  minWidth:'770px',
                  maxWidth:'770px',
                  position: 'relative',
                  margin: '19px auto 0px auto',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center'
            }}>
                 
              
            </Box>

 

           
           
             <Button
                sx={{
                   position: 'absolute',
                   right: '30px',
                   bottom: '30px',
                   width: '128px',
                   height: '40px',
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   background: '#F6F8FA',
                   borderRadius: '3px',
                   fontFamily: 'Segoe UI',
                   fontStyle: 'normal',
                   fontWeight: '600',
                   fontSize: '14px',
                   lineHeight: '20px',
                   color: '#24292F'
                }}
                onClick={() => {
                   upgradeSubsctiptionPlan("")
                }}
             >
                Upgrade Now
             </Button>

          

       

    </Box>
    </Dialog>
    
    )

}
