
import React from "react"
import { Helmet } from "react-helmet";
import { Box, Text, ActionList, ActionMenu, Button, IconButton, Heading, Spinner, Avatar, Tooltip } from "@primer/react"
import { FeedPersonIcon } from '@primer/octicons-react'
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import SpaceTheme from "./SpaceTheme"
import { CheckIcon, GridIcon, InfoCircledIcon, UploadIcon, MagnifyingGlassIcon, ViewGridIcon, ViewHorizontalIcon, CaretSortIcon } from "@radix-ui/react-icons"
import FilesSearchDialog from "../Workplace/Dialogs/FilesSearchDialogs"
import { filesearchOpenClose } from "../redux/features/searchSlice"
import { useDispatch, useSelector } from "react-redux"
import CreateTask from "./CreateTask";
import RightSideBarOpen from "./RightSideBarOpen";
import { getSpaceFiles, uploadProjectFiles } from "../redux/features/spaceSlice";
import AWS from 'aws-sdk'
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { getDropboxAuth, getGoogleDriveAuth } from "../redux/features/taskSlice";
import { useNavigate } from "react-router-dom";
 
const BUCKET_NAME = process.env.REACT_APP_S3BUCKET_NAME;
const IAM_USER_KEY = process.env.REACT_APP_ACCESS_KEY_ID
const IAM_USER_SECRET =process.env.REACT_APP_SECRET_ACCESS_KEY
const REGION = process.env.REACT_APP_COGNITO_REGION


AWS.config.update({
    accessKeyId: IAM_USER_KEY,
    secretAccessKey: IAM_USER_SECRET
})

const deskiplatformBucket = new AWS.S3({
    params: { Bucket: BUCKET_NAME },
    region: REGION,
})

const emptyImg = "https://d2fcqkt9srr3ti.cloudfront.net/fileEmptyImg.svg"
const placeHolderProfile = "https://d2fcqkt9srr3ti.cloudfront.net/profile-avatar.svg"
const docIcon = "https://d2fcqkt9srr3ti.cloudfront.net/docIcon.svg"
const imgIcon = "https://d2fcqkt9srr3ti.cloudfront.net/imgIcon.svg"
const imgLargeIcon = 'https://deskiplatformsconsole.s3.us-east-2.amazonaws.com/FilesIcon/file_img_placeholder.svg'
const mp4Icon = "https://d2fcqkt9srr3ti.cloudfront.net/FilesIcon/multimedia.svg"
const epsIcon = "https://d2fcqkt9srr3ti.cloudfront.net/FilesIcon/epsIcon.svg"
const folderIcon = "https://d2fcqkt9srr3ti.cloudfront.net/FilesIcon/folder.svg"

function formatSize(size) {
    if (size < 1024) {
        return size + ' B';
    } else if (size < (1024 ** 2)) {
        return (size / 1024).toFixed(2) + ' KB';
    } else if (size < (1024 ** 3)) {
        return (size / (1024 ** 2)).toFixed(2) + ' MB';
    } else if (size < (1024 ** 4)) {
        return (size / (1024 ** 3)).toFixed(2) + ' GB';
    } else if (size < (1024 ** 5)) {
        return (size / (1024 ** 4)).toFixed(2) + ' TB';
    } else {
        return (size / (1024 ** 5)).toFixed(2) + ' PB';
    }
}

function Files(props) {

    const gapi = window?.gapi
    const google = window?.google

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const headerData = useSelector((state) => state.headerData)
    const filteredFilesId = useSelector((state) => state.searchData.filteredAttachmentId)
    const computerFileRef = React.useRef()

    const [projectFileSize, setprojectFileSize] = React.useState("0.0")

    const [showLoading, setshowLoading] = React.useState(false)
    const [filesArray, setfilesArray] = React.useState([])
    const [copyFilesArray, setcopyFilesArray] = React.useState([])



    const [showUploadingComponent, setshowUploadingComponent] = React.useState(false)
    const [showEmptyPage, setshowEmptyPage] = React.useState(false)
    const [isGridView, setisGridView] = React.useState(false)

    const showFileSearchDialog = (props) => {

        dispatch(filesearchOpenClose({
            fopenClose: true
        }))
    }

    const handleFileSort = (props) => {

        setshowLoading(true)

        if (props === "Name") {

            setfilesArray((prevState) => {

                const newState = [...prevState]
                const sortedArray = newState.sort((a, b) => {
                    if (a.attachments?.attachment_file_name < b.attachments?.attachment_file_name) {
                        return (a.attachments?.attachment_file_name.localeCompare(b.attachments?.attachment_file_name))
                    } else if (a.attachments?.attachment_file_name > b.attachments?.attachment_file_name) {
                        return (b.attachments?.attachment_file_name.localeCompare(a.attachments?.attachment_file_name))
                    }
                })

                return sortedArray



            })





        } else if (props === "Last Modified") {

            setfilesArray((prevState) => {

                const newState = [...prevState]

                const sortedArray = newState.sort((a, b) => new Date(b.attachments?.attachment_timestamp) - new Date(a.attachments?.attachment_timestamp))


                setshowLoading(false)
                return sortedArray



            })

        } else if (props === "Size") {

            setfilesArray((prevState) => {

                const newState = prevState.sort(function (a, b) {
                    if (a.attachments?.attachment_size < b.attachments?.attachment_size) { return -1; }
                    if (a.attachments?.attachment_size > b.attachments?.attachment_size) { return 1; }
                    return 0;
                })


                setshowLoading(false)
                return newState



            })




        }

    }

    const handlePickComputerFile = (props) => {

        const projectId = localStorage.getItem("projectId")
        const params = {
            'Body': props,
            'Bucket': BUCKET_NAME,
            'Key': 'workspaceprojects/' + projectId + '/' + props.name,
            'ContentType': props.type
        };

        
        setshowUploadingComponent(true)
        deskiplatformBucket.upload(params, function (err, data) {

            if (err) {



            } else {



                const updateData = {
                    attachment_file_name: props.name,
                    attachment_size: '' + props.size + '',
                    attachment_url: data.Location,
                    creator_id: userId,
                    attachment_timestamp: "" + new Date() + "",
                    attachment_type: "Project"
                }


                //  updateTaskAttachmentsIndb(updateData)
                uploadProjectFilesIndb(updateData)

            };



        })


    }

    const handleSelectDropboxPicker = (props) => {

          
        dispatch(getDropboxAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then(async (data)=>{


            console.log("getGoogleAuth",data.payload)
            const code = data?.payload?.checkApp?.code
            const googleTokenData = data?.payload?.data 
            const message = data?.payload?.data?.message

            console.log("code",code)
            console.log("message",message)

            if(code==404){

               Toaster.create({ position: Position.TOP }).show({
                   message: 
                   <Box sx={{minHeight:'100%',marginTop:'4px'}}>
                   <Text sx={{
                       fontFamily: 'Segoe UI',
                       fontStyle: 'normal',
                       fontSize: '14px',
                       fontWeight: '400',
                       lineHeight: '14px',
                       
                   }}>{"App not installed"}</Text>
                   </Box>
                   , intent: Intent.PRIMARY, action: {

                       text: <Text sx={{
                           fontFamily: 'Segoe UI',
                           fontStyle: 'normal',
                           fontSize: '14px',
                           fontWeight: '400',
                           lineHeight: '14px',

                       }}>Open Apps.</Text>,
                       onClick: () => navigate('/apps')
                   }
               });


            }else if(code==200){

                  if(message==="Already authenticated"){

                     
                          const dropBoxoptions = {


                          success: async function (files) {



                              const fileUrl = files[0].link
                              const fileName = files[0].name
                              const fileSize = files[0].bytes


                              const updateData = {
                                  ...files[0],
                                  attachment_file_name: fileName,
                                  attachment_size: '' + fileSize + '',
                                  attachment_url: fileUrl,
                                  creator_id: userId,
                                  attachment_timestamp: "" + new Date() + "",
                                  attachment_type: "Dropbox"
                              }

                            
                            setshowUploadingComponent(!showUploadingComponent)
                            uploadProjectFilesIndb(updateData)

                          },
                          cancel: function () {
        
                          },

                          linkType: "preview",//"preview", // or "direct"
                          folderselect: true, // or true


                      };
                      window.Dropbox.choose(dropBoxoptions);
                  
                   }else{

                      Toaster.create({ position: Position.TOP }).show({
                          message:
                              <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                  <Text sx={{
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      lineHeight: '14px',

                                  }}>{"App not authenticated"}</Text>
                              </Box>
                          , intent: Intent.PRIMARY, action: {

                              text: <Text sx={{
                                  fontFamily: 'Segoe UI',
                                  fontStyle: 'normal',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  lineHeight: '14px',

                              }}>Open Apps.</Text>,
                              onClick: () => navigate('/apps')
                          }
                      });

                  }



            }
             


        })


    }

    async function pickerCallback(data) {

        const google = window.google
        let url = 'nothing';
        if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            let doc = data[google.picker.Response.DOCUMENTS][0];
            url = doc[google.picker.Document.URL];


            let message = `You picked: ${url}`;
            console.log("selected file ", doc)
            console.log("selected google drive folder", data)


            const updateData = {
                ...doc,
                attachment_file_name: doc.name,
                attachment_size: '' + doc.sizeBytes + '',
                attachment_url: doc.embedUrl,
                creator_id: userId,
                attachment_timestamp: "" + new Date() + "",
                attachment_type: "Google Drive"
            }
            setshowUploadingComponent(!showUploadingComponent)
            uploadProjectFilesIndb(updateData)


        }

    }



    const handleSelectDrivePicker = (props) => {

         
        dispatch(getGoogleDriveAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then(async (data)=>{


            console.log("getGoogleAuth",data.payload)
            const code = data?.payload?.checkApp?.code
            const googleTokenData = data?.payload?.data 
            const message = data?.payload?.data?.message

            console.log("code",code)
            console.log("message",message)

            if(code==404){

               Toaster.create({ position: Position.TOP }).show({
                   message: 
                   <Box sx={{minHeight:'100%',marginTop:'4px'}}>
                   <Text sx={{
                       fontFamily: 'Segoe UI',
                       fontStyle: 'normal',
                       fontSize: '14px',
                       fontWeight: '400',
                       lineHeight: '14px',
                       
                   }}>{"App not installed"}</Text>
                   </Box>
                   , intent: Intent.PRIMARY, action: {

                       text: <Text sx={{
                           fontFamily: 'Segoe UI',
                           fontStyle: 'normal',
                           fontSize: '14px',
                           fontWeight: '400',
                           lineHeight: '14px',

                       }}>Open Apps.</Text>,
                       onClick: () => navigate('/apps')
                   }
               });


            }else if(code==200){

                  if(message==="Already authenticated"){

                      const refreshToken = googleTokenData?.refresh_token;

                      const clientId = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                      const clientSecret = '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'
                      const tokenUrl = 'https://oauth2.googleapis.com/token';
                      const payload = {
                          grant_type: 'refresh_token',
                          client_id: clientId,
                          client_secret: clientSecret,
                          refresh_token: refreshToken,
                      };
                      const newTokenResult =await fetch(tokenUrl, {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/x-www-form-urlencoded',
                          },
                          body: new URLSearchParams(payload).toString(),
                      }).then(response => response.json())
                      const newAccessToken = newTokenResult?.access_token
                      console.log("newAccessToken",newAccessToken)
              
                      if(newAccessToken!==null&&newAccessToken!==""&&newAccessToken!==undefined){
               
                              
                             const google = window.google  
                             const API_KEY = 'AIzaSyD8VZ6ObpUM8ic0ZmLdElVXtgQ0l6QoKcM'
                             const CLIENT_ID = '686241795638-c1kbkh0nfr46n4cq5gd4o2mcjc9d08rr.apps.googleusercontent.com'
                             const APP_ID = '686241795638'

                             let docsView = 
                             new google.picker.DocsView(google.picker.ViewId.FOLDERS)
                             .setParent('root')
                             .setSelectFolderEnabled(true);
                   
                             const picker = new google.picker.PickerBuilder()
                               .addView(google.picker.ViewId.DOCS)
                               .addView(docsView)
                               .setOAuthToken(newAccessToken)
                               .setDeveloperKey(API_KEY)
                               .setCallback(pickerCallback)
                               .build();
                             picker.setVisible(true);



                       }else{
                               
                       }
                     
                  
                  
                   }else{

                      Toaster.create({ position: Position.TOP }).show({
                          message:
                              <Box sx={{ minHeight: '100%', marginTop: '4px' }}>
                                  <Text sx={{
                                      fontFamily: 'Segoe UI',
                                      fontStyle: 'normal',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      lineHeight: '14px',

                                  }}>{"App not authenticated"}</Text>
                              </Box>
                          , intent: Intent.PRIMARY, action: {

                              text: <Text sx={{
                                  fontFamily: 'Segoe UI',
                                  fontStyle: 'normal',
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  lineHeight: '14px',

                              }}>Open Apps.</Text>,
                              onClick: () => navigate('/apps')
                          }
                      });

                  }



            }
             


        })
          


    }

    const uploadProjectFilesIndb = (props) => {




        dispatch(uploadProjectFiles({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId"),
            projectAttachments: props
        })).then((data) => {

            Toaster.create({ position: Position.TOP }).show({ message: "File successfully uploaded.", intent: Intent.SUCCESS });
            getAllProjectFiles("")
            setshowUploadingComponent(false)


        })



    }



    const handleDownloadFiles = async (props) => {


        const response = await fetch(props.attachment_url);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = props.attachment_file_name;
        document.body.appendChild(link);
        link.click();

    }

    const UploadComponent = (props) => {
        return (
            <Box sx={{
                width: '236px',
                height: '204px',
                background: 'white',
                position: 'absolute',
                top: '259px',
                left: '0px',
                right: '0px',
                margin: 'auto',
                border: '1px solid #D0D7DE',
                boxShadow: '0px 8px 24px rgba(140, 149, 159, 0.2)',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                zIndex: '1'
            }}>
                <Spinner sx={{
                    height: '60px',
                    width: '60px',
                    position: 'relative',
                    margin: '34px auto 0px auto'
                }} size="large" />

                <Heading sx={{
                    position: 'relative',
                    margin: '18px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontWeight: '600',
                    fontStyle: 'normal',
                    fontSize: '17px',
                    lineHeight: '36px',
                    color: '#24292F'
                }}>
                    Uploading 1 item
                </Heading>
                <Text sx={{
                    position: 'relative',
                    margin: '5px auto 0px auto',
                    fontFamily: 'Segoe UI',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '18px',
                    cursor: 'pointer'
                }}
                    onClick={() => setshowEmptyPage(!showEmptyPage)}
                >
                    Upload anything you want
                </Text>


            </Box>
        )
    }

    const EmptyFileComponent = (porps) => {

        return (<Box sx={{
            width: '434px',
            height: '184px',
            minWidth: '434px',
            minHeight: '184px',
            maxWidth: '434px',
            maxHeight: '184px',
            borderRadius: '6px',
            border: '1px solid #D0D7DE',
            background: '#FFFFFF',
            position: 'absolute',
            top: '130px',
            left: '0px',
            right: '0px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            <Box sx={{
                width: '16px',
                height: '16px',
                minWidth: '16px',
                minHeight: '16px',
                maxWidth: '16px',
                maxHeight: '16px',
                position: 'relative',
                margin: '40px 0px 0px 0px'
            }}>

                <UploadIcon />

            </Box>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '17px',
                lineHeight: '36px',
                textAlign: 'center',
                color: '#24292F',
                position: 'relative',
                margin: '16px 0px 0px 0px'
            }}>
                Upload or drag and drop a file
            </Text>

            <Text sx={{
                fontFamily: 'Segoe UI',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                color: '#57606A',
                position: 'relative',
                margin: '4px 32px 0px 32px'
            }}>
                Attach organization's MCA documents, incorporation certificates, and other valid docs to easily manage them.
            </Text>





        </Box>)
    }

    const calculateProjectFolderSize = async (props) => {

        let folderName = 'workspaceprojects/' + localStorage.getItem("projectId")
        let totalSize = 0;
        let data = await deskiplatformBucket.listObjectsV2({ Bucket: BUCKET_NAME, Prefix: folderName }).promise();
        for (let i = 0; i < data.Contents.length; i++) {
            totalSize += data.Contents[i].Size;
        }

        const totalSizegb = formatSize(totalSize)
        setprojectFileSize(totalSizegb)


    }


    const getAllProjectFiles = (props) => {

        dispatch(getSpaceFiles({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {



            const sortedFilesArray = [...data.payload?.data].sort(function (a, b) {

                return new Date(b.attachments.attachment_timestamp) - new Date(a.attachments.attachment_timestamp);
            })
            setfilesArray(sortedFilesArray)
            setcopyFilesArray(sortedFilesArray)
            if (sortedFilesArray.length == 0) {
                setshowEmptyPage(true)

            } else {
                setshowEmptyPage(false)
            }


        })

    }

    const synchGooogleDriveFiles = async (props) => {


        const googleClientToken = gapi?.client?.getToken()


        if (googleClientToken === undefined) {




        } else {

            lisGoogleDriveFiles("")

        }







    }

    const lisGoogleDriveFiles = async (props) => {

        await gapi.client.drive.files
            .list({
                pageSize: 10,
                fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
                q: '',
            })
            .then(function (response) {

                const res = JSON.parse(response.body);
                console.log(res)

                const gdriveFiles = [...res.files].map((gData) => {

                    return ({
                        attachments: {
                            "creator_id": userId,
                            "attachment_id": gData.id,
                            "attachment_url": gData.name,
                            "attachment_size": "0",
                            "attachment_type": "Project",
                            "attachment_file_name": gData.name,
                            "attachment_timestamp": "" + new Date(gData.modifiedTime) + ""
                        },
                        user_email: userEmail,
                        user_first_name: userEmail,
                        user_id: userId,
                        user_image_url: '',
                        user_last_name: ''

                    })
                })

                console.log("gdriveFiles", gdriveFiles)
                console.log("filesArray", filesArray)

                setfilesArray((prevState) => {
                    const newState = prevState.concat(gdriveFiles)
                    return newState
                })
                setcopyFilesArray((prevState) => {
                    const newState = prevState.concat(gdriveFiles)
                    return newState
                })
                setshowUploadingComponent(false)
                setshowEmptyPage(false)









            });
    }


    React.useEffect(() => {

        if (filteredFilesId !== null && filteredFilesId !== "") {

            setfilesArray((prevState) => {
                const newState = [...copyFilesArray]
                const filteredArray = newState.filter((fData) => fData.attachments.attachment_id === filteredFilesId)
                return filteredArray


            })

        }

    }, [filteredFilesId])





    React.useState(() => {

        calculateProjectFolderSize("")
        getAllProjectFiles("")




    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            minWidth={"100vw"}
            maxWidth={"100vw"}
            overflowY={"auto"}

        >

            <Helmet>

                <title>{localStorage.getItem("projectName")} - Files</title>
                <script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="r1ex80p99zrn57f"></script>
             
            </Helmet>


            <SpaceHeader />
            <SpaceLeftSideNav />
            {/* <SpaceRightSideNav /> */}



            <Box sx={{
                position: 'absolute',
                left: '90px',
                top: '78px'
            }}>
                <Text sx={{
                    fontFamily: 'Avenir LT Std',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '18px',
                    color: '#24292E'
                }}>Files and documents</Text>


            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: '71px',
                    right: '81px',
                    display: 'flex',
                    flexDirection: 'row',
                    height: 'fit-content',
                    width: 'fit-content',
                    gap: '20px',

                }}>

                <ActionMenu>

                    <ActionMenu.Anchor >
                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                height: '15px',
                                width: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}
                            icon={() =>

                                <Tooltip aria-label="Sort" direction="s">
                                    <CaretSortIcon style={{ display: 'flex' }} size={15} />
                                </Tooltip>
                            }

                        />
                    </ActionMenu.Anchor>


                    <ActionMenu.Overlay>
                        <ActionList
                            sx={SpaceTheme.filesActionMenuList}

                        >
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}>
                                <ActionList.LeadingVisual>
                                    <CheckIcon />
                                </ActionList.LeadingVisual>
                                Recent
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}

                                onSelect={(e) => handleFileSort("Name")}
                            >
                                <ActionList.LeadingVisual>

                                </ActionList.LeadingVisual>
                                Name
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                onSelect={(e) => handleFileSort("Last Modified")}
                            >
                                <ActionList.LeadingVisual>

                                </ActionList.LeadingVisual>
                                Last Modified
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                onSelect={(e) => handleFileSort("Size")}
                            >
                                <ActionList.LeadingVisual>

                                </ActionList.LeadingVisual>
                                Size
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                onSelect={(e) => setfilesArray(copyFilesArray)}
                            >
                                <ActionList.LeadingVisual>

                                </ActionList.LeadingVisual>
                                All Project Files
                            </ActionList.Item>
                            {/* <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                onSelect={(e) => {
                                     synchGooogleDriveFiles()
                                }}
                            >
                                <ActionList.LeadingVisual>

                                </ActionList.LeadingVisual>
                                Sync google grive
                            </ActionList.Item> */}

                        </ActionList>
                    </ActionMenu.Overlay>
                </ActionMenu>



                <IconButton
                    size="small"
                    variant="invisible"
                    sx={{
                        color: '#000000',
                        height: '15px',
                        width: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',


                    }}
                    icon={() =>

                        <Tooltip aria-label="Search" direction="s">
                            <MagnifyingGlassIcon style={{ display: 'flex' }} size={15} />
                        </Tooltip>
                    }
                    onClick={() => showFileSearchDialog("")}
                />

                {/* <IconButton
                    size="small"
                    variant="invisible"
                    sx={{
                        color: '#000000',
                        height: 15,
                        width: 15,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',


                    }}
                    icon={() =>

                        <Tooltip aria-label="Upload file" direction="s">
                            <UploadIcon style={{ display: 'flex' }} size={15} />
                        </Tooltip>

                    }
                    onClick={() => { computerFileRef.current.click() }}
                /> */}
                <input ref={computerFileRef} type={"file"} onChange={(e) => handlePickComputerFile(e.target.files[0])} hidden />


                <ActionMenu>

                    <ActionMenu.Anchor >

                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                height: 15,
                                width: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}
                            icon={() => 
                                <Tooltip aria-label="Upload file" direction="s">
                                    <UploadIcon style={{ display: 'flex' }} size={15} />
                                </Tooltip>
                            }
                            
                        />



                    </ActionMenu.Anchor>


                    <ActionMenu.Overlay align="end" >
                        <ActionList sx={SpaceTheme.filesActionMenuList}>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                                onSelect={event => {

                                    computerFileRef.current.click()
                                   // setshowUploadingComponent(!showUploadingComponent)
                                   // setTimeout(() => setshowUploadingComponent(false), 2000)
                                }
                                }
                            >

                                Computer
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                               onSelect={event => {
                                     handleSelectDrivePicker("")
                               }}
                            >

                                Google drive
                            </ActionList.Item>
                            <ActionList.Item sx={SpaceTheme.filesActionMenuListItem}
                               onSelect={event => {
                                   handleSelectDropboxPicker("") 
                               }}
                            >

                                Dropbox
                            </ActionList.Item>
                            
                            

                        </ActionList>
                    </ActionMenu.Overlay>
                </ActionMenu>

                {isGridView === false ?

                    <IconButton
                        size="small"
                        variant="invisible"
                        sx={{
                            color: '#000000',
                            height: 15,
                            width: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',


                        }}
                        icon={() =>

                            <Tooltip aria-label="Grid view" direction="s">
                                <ViewGridIcon style={{ display: 'flex' }} size={15} />
                            </Tooltip>
                        }
                        onClick={() => {
                            setisGridView(true)
                        }
                        }
                    /> :

                    <IconButton
                        size="small"
                        variant="invisible"
                        sx={{
                            color: '#000000',
                            height: 15,
                            width: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',


                        }}
                        icon={() =>

                            <Tooltip aria-label="List view" direction="s">
                                <ViewHorizontalIcon style={{ display: 'flex' }} size={15} />
                            </Tooltip>
                        }
                        onClick={() => {
                            setisGridView(false)
                        }
                        }
                    />
                }


                <ActionMenu>

                    <ActionMenu.Anchor>

                        <IconButton
                            size="small"
                            variant="invisible"
                            sx={{
                                color: '#000000',
                                height: 15,
                                width: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}

                            icon={() =>

                                <Tooltip aria-label="Storage Info" direction="s">
                                    <InfoCircledIcon style={{ display: 'flex' }} size={15} />
                                </Tooltip>
                            }

                        />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay align="end">
                        <Box sx={{
                            width: 236,
                            height: 212,
                            display: 'flex',
                            flexDirection: 'column',

                        }}>


                            <Box sx={{
                                height: '60px',
                                width: '60px',
                                border: '2px solid #24292F',
                                borderRadius: '50%',
                                position: 'relative',
                                background: 'white',
                                opacity: '0.25',
                                margin: '34px auto 0px auto'
                            }} />

                            <Heading sx={{
                                position: 'relative',
                                margin: '18px auto 0px auto',
                                fontFamily: 'Segoe UI',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                fontSize: '17px',
                                lineHeight: '36px',
                                color: '#24292F'
                            }}>
                                {projectFileSize} of 2 GB used
                            </Heading>

                            <Button
                                variant="invisible"
                                sx={{
                                    width: '120px',
                                    height: '28px',
                                    position: 'relative',
                                    margin: '0px auto 0px auto',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    cursor: 'pointer',
                                    color: '#24292F'
                                }}

                            >
                                Get more storage
                            </Button>

                        </Box>
                    </ActionMenu.Overlay>
                </ActionMenu>


            </Box>




            <Box
                borderColor="border.default"
                borderBottomWidth={1}
                borderBottomStyle="solid"
                sx={{
                    position: 'absolute',
                    left: '90px',
                    right: '81px',
                    top: '112px'
                }}>

            </Box>

            

            {isGridView === false ?

                <Box sx={{
                    position: 'absolute',
                    top: '130px',
                    left: '90px',
                    right: '81px',
                    minHeight: 'fit-content',
                    background: 'white',
                    display: filesArray.length > 0 ? 'flex' : 'none',
                    flexDirection: 'column',

                }}>

                    {
                        filesArray?.map((row, index) => {

                            return (

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '35px',
                                    borderBottomWidth: 1,
                                    borderBottomStyle: 'solid',
                                    borderColor: '#E0E0E0',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    ':hover': {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    }
                                }}
                                >

                                    <Box sx={{
                                        minWidth: '56px',
                                        minHeight: '35px',
                                        maxWidth: '56px',
                                        maxHeight: '35px',
                                        height: '35px',
                                        width: '35px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                        {
                                            (
                                                row.attachments?.attachment_file_name?.endsWith('.png') ||
                                                row.attachments?.attachment_file_name?.endsWith('.jpg') ||
                                                row.attachments?.attachment_file_name?.endsWith('.jpeg') ||
                                                row.attachments?.attachment_file_name?.endsWith('.svg')
                                            )
                                                ?

                                                <img
                                                    style={{
                                                        height: '16px',
                                                        width: '16px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px',
                                                        objectFit: "cover",

                                                    }}
                                                    src={imgIcon}

                                                />
                                                : (
                                                    row.attachments?.attachment_file_name?.endsWith('.mp4')
                                                ) ?
                                                    <Box sx={{
                                                        height: '15px',
                                                        width: '12px',
                                                        position: 'relative',
                                                        margin: '0px 0px 0px 0px',
                                                        objectFit: "cover",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={mp4Icon} style={{
                                                            height: '15px',
                                                            width: '12px',
                                                            objectFit: "cover",


                                                        }} />
                                                    </Box> :

                                                    (
                                                        row.attachments?.attachment_file_name?.endsWith('.eps')
                                                    ) ?
                                                        <Box sx={{
                                                            height: '15px',
                                                            width: '12px',
                                                            position: 'relative',
                                                            margin: '0px 0px 0px 0px',
                                                            objectFit: "cover",
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img src={epsIcon} style={{
                                                                height: '15px',
                                                                width: '12px',
                                                                objectFit: "cover",


                                                            }} />
                                                        </Box>


                                                        :
                                                        (
                                                            row.attachments?.attachment_file_name?.endsWith('.doc')

                                                        ) ?

                                                            <Box sx={{
                                                                height: '16px',
                                                                width: '16px',
                                                                position: 'relative',
                                                                margin: '0px 0px 0px 0px',
                                                                objectFit: "cover",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img src={docIcon} style={{
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    objectFit: "cover",


                                                                }} />
                                                            </Box>
                                                            :

                                                            row.attachments?.mimeType === "application/vnd.google-apps.folder" || row.attachments?.isDir===true  ?



                                                                <Box sx={{
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    position: 'relative',
                                                                    margin: '0px 0px 0px 0px',
                                                                    objectFit: "cover",
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <img src={folderIcon} style={{
                                                                        height: '14px',
                                                                        width: '16px',
                                                                        objectFit: "cover",


                                                                    }} />
                                                                </Box> :
                                                                <Box sx={{
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    position: 'relative',
                                                                    margin: '0px 0px 0px 0px',
                                                                    objectFit: "cover",
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <img src={imgIcon} style={{
                                                                        height: '16px',
                                                                        width: '16px',
                                                                        objectFit: "cover",


                                                                    }} />
                                                                </Box>


                                        }



                                    </Box>

                                    <Tooltip aria-label="Click to download !"  >
                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            color: '#272833',
                                            width: '770px',
                                            maxWidth: '770px',
                                            minWidth: '770px',
                                            minHeight: '21px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            margin: '0px 0px 0px 12px'

                                        }}
                                            onClick={() => {

                                                if (row.attachments?.attachment_type === "Google Drive") {

                                                    window.open(row.attachments?.embedUrl, '_blank')

                                                } else if (row.attachments?.attachment_type === "Dropbox") {

                                                    window.open(row.attachments?.link, '_blank')

                                                } else {

                                                    handleDownloadFiles(row.attachments)
                                                }



                                            }}
                                        >
                                            {row.attachments?.attachment_file_name}

                                        </Text>
                                    </Tooltip>


                                    <Box sx={{
                                        minWidth: '176px',
                                        maxWidth: '176px',
                                        width: '176px',
                                        height: '35px',
                                        minHeight: '35px',
                                        maxHeight: '35px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: '156px',
                                        alignItems: 'center'
                                    }}>


                                        {row?.user_image_url !== "" && row?.user_image_url !== null ?

                                            <Tooltip
                                                aria-label={row?.user_first_name + " " + row?.user_last_name}>
                                                <Avatar size={20}
                                                    src={row.user_image_url}
                                                    sx={{
                                                        display: 'flex',
                                                        height: '20px',
                                                        width: '20px',

                                                    }}
                                                />
                                            </Tooltip>
                                            :

                                            <Box sx={{
                                                display: 'flex',
                                                height: '20px',
                                                width: '20px',

                                            }}>
                                                <Tooltip
                                                    aria-label={row?.user_first_name + " " + row?.user_last_name}>
                                                    <FeedPersonIcon size={20} fill="#586069" />
                                                </Tooltip>
                                            </Box>

                                        }
                                        <Tooltip
                                            aria-label={row?.user_first_name + " " + row?.user_last_name}
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#303030',
                                                width: '116px',
                                                height: '21px',
                                                minHeight: '21px',
                                                maxHeight: '21px',
                                                maxWidth: '116px',
                                                minWidth: '116px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                position: 'relative',
                                                margin: '0px 0px 0px 8px'
                                            }}

                                        >
                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#303030',
                                                width: '116px',
                                                height: '21px',
                                                minHeight: '21px',
                                                maxHeight: '21px',
                                                maxWidth: '116px',
                                                minWidth: '116px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                //  position: 'relative',
                                                //  margin: '0px 0px 0px 8px'
                                            }}>
                                                {row?.user_first_name + " " + row?.user_last_name}

                                            </Text>
                                        </Tooltip>


                                    </Box>

                                    <Box sx={{
                                        position: 'absolute',
                                        right: '12px',
                                        width: '120px',
                                        minWidth: '120px',
                                        minHeight: '21px',
                                        maxWidth: '120px',
                                        height: '21px'
                                    }}>
                                        <Tooltip
                                            aria-label={"Uploaded date"}>
                                            <Text sx={{
                                                // position: 'absolute',
                                                //  right: '12px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                color: '#303030',
                                                whiteSpace: "nowrap",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                width: '120px',
                                                minWidth: '120px',
                                                minHeight: '21px',
                                                maxWidth: '120px',
                                                height: '21px'
                                            }}>
                                                {new Date(row.attachments?.attachment_timestamp).getDate() + "/" + (new Date(row.attachments?.attachment_timestamp).getMonth() + 1) + "/" + new Date(row.attachments?.attachment_timestamp).getFullYear()}
                                            </Text>
                                        </Tooltip>
                                    </Box>


                                </Box>

                            )
                        })
                    }


                </Box>

                :

                <Box sx={{
                    position: 'absolute',
                    top: '130px',
                    left: '90px',
                    right: '81px',
                    height: 'auto',
                    minHeight: 'fit-content',
                    display: filesArray.length > 0 ? 'flex' : 'none',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingBottom: '35px'
                }}>

                    {filesArray.map((row, index) => {
                        return (
                            <Tooltip aria-label="Click to download !" direction="n">
                                <Box
                                    borderColor="border.default"
                                    borderWidth={1}
                                    borderStyle="solid"
                                    borderRadius={6}
                                    sx={{
                                        height: '180px',
                                        width: '170px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        position: 'relative',
                                        margin: '0px 13px 13px 0px',
                                        background: '#FFFFFF',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {

                                        if (row.attachments?.attachment_type === "Google Drive") {

                                            window.open(row.attachments?.embedUrl, '_blank')

                                        }else if(row.attachments?.attachment_type === "Dropbox"){

                                            window.open(row.attachments?.link, '_blank')
                                              
                                        }else {

                                            handleDownloadFiles(row.attachments)
                                        }

                                      //  handleDownloadFiles(row.attachments)

                                    }}
                                >
                                    {
                                        (
                                            row.attachments?.attachment_file_name?.endsWith('.png') ||
                                            row.attachments?.attachment_file_name?.endsWith('.jpg') ||
                                            row.attachments?.attachment_file_name?.endsWith('.jpeg') ||
                                            row.attachments?.attachment_file_name?.endsWith('.svg')
                                        )
                                            ?

                                            <img
                                                style={{
                                                    height: '139px',
                                                    width: '162px',
                                                    position: 'relative',
                                                    margin: '4px 4px 0px 4px',
                                                    objectFit: "cover",
                                                    borderRadius: "6px",
                                                }}
                                                src={row.attachments?.attachment_url}
                                            />
                                            :
                                            (
                                                row.attachments?.attachment_file_name?.endsWith('.doc')
                                            ) ?

                                                <Box sx={{
                                                    height: '139px',
                                                    width: '162px',
                                                    position: 'relative',
                                                    margin: '4px 4px 0px 4px',
                                                    objectFit: "cover",
                                                    borderRadius: "6px",
                                                    display: 'flex',

                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <img src={docIcon} style={{
                                                        height: '69.5px',
                                                        width: '69.5px',
                                                        objectFit: "cover",
                                                        borderRadius: "6px",

                                                    }} />
                                                </Box>

                                                :
                                                (
                                                    row.attachments?.attachment_file_name?.endsWith('.mp4')
                                                ) ?

                                                    <Box sx={{
                                                        height: '139px',
                                                        width: '162px',
                                                        position: 'relative',
                                                        margin: '4px 4px 0px 4px',
                                                        objectFit: "cover",
                                                        borderRadius: "6px",
                                                        display: 'flex',

                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <img src={mp4Icon} style={{
                                                            height: '69.5px',
                                                            width: '69.5px',
                                                            objectFit: "cover",
                                                            borderRadius: "6px",

                                                        }} />
                                                    </Box>

                                                    :

                                                    (
                                                        row.attachments?.attachment_file_name?.endsWith('.eps')
                                                    ) ?

                                                        <Box sx={{
                                                            height: '139px',
                                                            width: '162px',
                                                            position: 'relative',
                                                            margin: '4px 4px 0px 4px',
                                                            objectFit: "cover",
                                                            borderRadius: "6px",
                                                            display: 'flex',

                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img src={epsIcon} style={{
                                                                height: '69.5px',
                                                                width: '69.5px',
                                                                objectFit: "cover",
                                                                borderRadius: "6px",

                                                            }} />
                                                        </Box>

                                                        :

                                                        row.attachments?.mimeType === "application/vnd.google-apps.folder" || row.attachments?.isDir===true ?

                                                            <Box sx={{
                                                                height: '139px',
                                                                width: '162px',
                                                                position: 'relative',
                                                                margin: '4px 4px 0px 4px',
                                                                objectFit: "cover",
                                                                borderRadius: "6px",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img src={folderIcon} style={{
                                                                    height: '69.5px',
                                                                    width: '69.5px',
                                                                    objectFit: "cover",
                                                                    borderRadius: "6px",

                                                                }} />
                                                            </Box> :
                                                            <Box sx={{
                                                                height: '139px',
                                                                width: '162px',
                                                                position: 'relative',
                                                                margin: '4px 4px 0px 4px',
                                                                objectFit: "cover",
                                                                borderRadius: "6px",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <img src={imgIcon} style={{
                                                                    height: '69.5px',
                                                                    width: '69.5px',
                                                                    objectFit: "cover",
                                                                    borderRadius: "6px",

                                                                }} />
                                                            </Box>


                                    }



                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        position: 'relative',
                                        margin: '7px 0px 9px 9px'
                                    }}>

                                        <Box sx={
                                            {

                                                height: '16px',
                                                width: '16px',
                                                color: '#6B6C7E',
                                                position: 'relative',
                                                margin: '0px 0px 0px 0px',


                                            }
                                        }>

                                            {
                                                (
                                                    row.attachments?.attachment_file_name?.endsWith('.png') ||
                                                    row.attachments?.attachment_file_name?.endsWith('.jpg') ||
                                                    row.attachments?.attachment_file_name?.endsWith('.jpeg')

                                                ) ?
                                                    <img src={imgIcon} height="16" width="16" />
                                                    :
                                                    (
                                                        row.attachments?.attachment_file_name?.endsWith('.doc')
                                                    )
                                                        ?
                                                        <img src={docIcon} height="16" width="16" />
                                                        :
                                                        (
                                                            row.attachments?.attachment_file_name?.endsWith('.mp4')
                                                        )
                                                            ?
                                                            <img src={mp4Icon} height="16" width="16" /> :
                                                            (
                                                                row.attachments?.attachment_file_name?.endsWith('.eps')
                                                            )
                                                                ?
                                                                <img src={epsIcon} height="16" width="16" />
                                                                :
                                                                row.attachments?.mimeType === "application/vnd.google-apps.folder" || row.attachments?.isDir===true ?
                                                                    <img src={folderIcon} height="14" width="16" /> :
                                                                    <img src={imgIcon} height="16" width="16" />
                                            }



                                        </Box>



                                        <Text
                                            sx={{
                                                width: '114px',
                                                height: '21px',
                                                position: 'relative',
                                                margin: '0px 0px 0px 15px',
                                                fontFamily: 'Segoe UI',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                fontStyle: 'normal',
                                                color: '#303030',
                                                whiteSpace: "nowrap",
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}>
                                            {row.attachments?.attachment_file_name}
                                        </Text>



                                    </Box>

                                </Box>
                            </Tooltip>
                        )
                    })}

                </Box>


            }


            {showUploadingComponent === true ? <UploadComponent /> : ""}
            {showEmptyPage === true ? <EmptyFileComponent /> : ""}



            {/* <RightSideBarOpen/> */}
            <ScheduleDemoDialog />
            <SearchDialog />
            <FilesSearchDialog />
        </Box>
    )

}

export default Files