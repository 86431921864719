import React from "react"
import { useNavigate } from "react-router-dom";
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, Spinner } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { scheduleDemoOpenClose } from '../../redux/features/scheduleDemoSlice';
import Workplacetheme from "../Workplacetheme";
import { createSpaceOpenClose, createworkplaceProject } from "../../redux/features/spaceSlice"
import { Toaster, Intent,Position } from "@blueprintjs/core";

function CreateSpaceDialog(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const createSpaceOpenCloseData = useSelector((state) => state.spaceData)
    const [showLoadingBtn,setshowLoadingBtn] = React.useState(false)
    const initialFocusRef = React.createRef()

    const [currentRef, setcurrentRef] = React.useState("spaceName")

    const [projectNameInput, setprojectNameInput] = React.useState({

        value: '',
        validationStatus: ''
    })

    const [projectDescriptionInput, setprojectDescriptionInput] = React.useState({

        value: '',
        validationStatus: ''
    })

    const closeCreateSpaceDialog = (props) => {

        dispatch(createSpaceOpenClose({
            openClose: false,
            workplaceId: ''
        }))
        setprojectNameInput((prevState) => {
            const newState = { ...prevState, validationStatus: '', value: '' }
            return newState
        })
        setcurrentRef("spaceName")


    }

    const createNewWorkplaceProject = (props) => {

        if (projectNameInput.value === "") {

            setprojectNameInput((prevState) => {
                const newState = { ...prevState, validationStatus: 'error' }
                return newState
            })
        } else {

            setprojectNameInput((prevState) => {
                const newState = { ...prevState, validationStatus: '' }
                return newState
            })

            setshowLoadingBtn(true)

            dispatch(createworkplaceProject({
                userId: userId,
                projectName: projectNameInput.value,
                workplaceId: createSpaceOpenCloseData.workplaceId,
                projectDescription: projectDescriptionInput.value
            })).then(async (data) => {

                    console.log("create project response", data)

                 
                   //-------- testing code --------

                //    console.log("create project response", data)
                //    Toaster.create({position: Position.TOP}).show({
                //        message: "The project has been created successfully.",
                //        intent:Intent.SUCCESS
                //    });


                //    const messageId = data.payload.SendMessageResponse.SendMessageResult.MessageId
                //    console.log("messageId",messageId)


                //    var myHeaders = new Headers();
                //    myHeaders.append('Content-type', 'application/json')


                //    const urlencoded = JSON.stringify({
                //     "messageId": messageId,
                //     })

                // var requestOptions = {
                //     method: 'POST',
                //     headers: myHeaders,
                //     body: urlencoded,
                //     redirect: 'follow'
                // };

                //    const response = await fetch('https://8ha3dpt9zk.execute-api.us-east-2.amazonaws.com/uploadWorkflowResponse/',requestOptions)
                //    const dataSqs = await response.json();
                //    console.log("sqs response data",dataSqs)
                //    const responseData = JSON.parse(dataSqs.projectData).data
                //    console.log("responseData",responseData[0].project_id)

                   // const projectId = responseData[0].project_id
                    // closeCreateSpaceDialog("")
                    // setshowLoadingBtn(false)
                    // localStorage.setItem("projectId",responseData[0]?.project_id)
                    // localStorage.setItem("projectName",responseData[0]?.project_name)
                    // navigate('/space/'+responseData[0]?.project_name)




                   
                //    const response = await fetch('https://b31exo7x5b.execute-api.us-east-2.amazonaws.com/uploadWorkflowTest?Action=RecevieMessage');
                //    const dataSqs = await response.json();
                //    console.log("sqs response data",dataSqs)

                //    const allMessages = dataSqs.ReceiveMessageResponse.ReceiveMessageResult.messages
                //    console.log("allMessages",allMessages)


   
                //    closeCreateSpaceDialog("")
                //    setshowLoadingBtn(false)
                //    localStorage.setItem("projectId",data.payload.data[0]?.project_id)
                //    localStorage.setItem("projectName",data.payload.data[0]?.project_name)
                //    navigate('/space/'+data.payload.data[0]?.project_name)





              
               
                Toaster.create({position: Position.TOP}).show({
                    message: "The project has been created successfully.",
                    intent:Intent.SUCCESS
                });

                closeCreateSpaceDialog("")
                setshowLoadingBtn(false)
                localStorage.setItem("projectId",data.payload.data[0]?.project_id)
                localStorage.setItem("projectName",data.payload.data[0]?.project_name)
                navigate('/space/'+data.payload.data[0]?.project_name)
              
                
            })


        }




    }

    React.useState(() => {

        setcurrentRef("spaceName")
    })



    return (

        <Dialog
            sx={Workplacetheme.sheduleDialog}
            isOpen={createSpaceOpenCloseData.openClose}
            onDismiss={() =>

                closeCreateSpaceDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}
        >
            <Dialog.Header id="header-id">
                <Text sx={Workplacetheme.scheduleDalogeHeading}>Create new project</Text>

            </Dialog.Header>

            <Box sx={Workplacetheme.scheduleDialogContentBox}>

                <Text sx={Workplacetheme.createSpaceDialodBoxText}>You can change these details anytime in your project settings</Text>

                <Box sx={Workplacetheme.createSpaceDialodBoxNameInput}>

                    <TextInput
                        ref={currentRef === "spaceName" ? initialFocusRef : null}
                        onFocus={() => setcurrentRef("spaceName")}
                        onBlur={() => setcurrentRef("")}
                        width={'100%'}
                        aria-label="spaceName"
                        name="spaceName"
                        placeholder="Type your project name"
                        value={projectNameInput.value}
                        validationStatus={projectNameInput.validationStatus}
                        onChange={(e) => {
                            setprojectNameInput((prevState) => {
                                const newState = { ...prevState, value: e.target.value }
                                return newState
                            })
                        }}
                    />



                </Box>

                <Box sx={Workplacetheme.createSpaceDialodBoxTextArea}>

                    <Textarea
                        ref={currentRef === "description" ? initialFocusRef : null}
                        onBlur={() => setcurrentRef("")}
                        onFocus={() => setcurrentRef("description")}
                        sx={Workplacetheme.createSpaceDialodBoxTextArea.textArea}
                        placeholder="Add an optional description , eg: Plans and goals of your dream project "
                        resize="horizontal"
                        value={projectDescriptionInput.value}
                        onChange={(e) => {

                            setprojectDescriptionInput((prevState) => {
                                const newState = { ...prevState, value: e.target.value }
                                return newState
                            })
                        }}
                    />


                </Box>

                <Text sx={Workplacetheme.createSpaceDialodBoxTextTemplate}>
                    Select a template to get started and customize as you go
                </Text>

                <Box

                    sx={Workplacetheme.createSpaceDialogFooterLine}

                    borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>





            </Box>

            <Box
                ml={24}
                mr={24}
                display="flex"
                flexDirection="row"
                justifyContent={"right"}
            >

                <Button variant="invisible"
                    sx={Workplacetheme.createSpaceDialogFooterBox.cancelBtn}
                    onClick={() => closeCreateSpaceDialog("")}
                >Cancel</Button>

                {
                    showLoadingBtn === true ?

                        <Button
                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                        >
                            <Spinner sx={{
                                position: 'absolute',
                                left: '0px',
                                right: '0px',
                                margin: 'auto',
                                top: '0px',
                                bottom: '0px',
                            }} size="small" />
                        </Button>

                        :

                        <Button

                            variant="outline"
                            sx={Workplacetheme.createSpaceDialogFooterBox.submitBtn}
                            onClick={() => createNewWorkplaceProject("")}
                        >
                            Create project

                        </Button>


                }

               

                


            </Box>





        </Dialog>

    )

}

export default CreateSpaceDialog