import React from "react"
import { Box, Button, Dialog, Text, ActionList, TextInput, Portal, Spinner, Label, Checkbox } from "@primer/react"
import { useDispatch, useSelector } from "react-redux"
import { createBulkTaskDialogOpenClose, postcreateIssue } from "../../redux/features/taskSlice"
import { getAllWorksByProjectId, getComplianceTaksByProjectId} from "../../redux/features/spaceSlice"
import { getIndiviualsCompanies } from "../../redux/features/contactSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import Workplacetheme from "../Workplacetheme"

export default function CreateBulkTaskDialog() {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)
    const taskData = useSelector((state) => state.taskData)

    const initialFocusRef = React.createRef()  // references input
    const [showLoadingSubmitBtn, setshowLoadingSubmitBtn] = React.useState(false)
    const [taskInpuError, settaskInpuError] = React.useState('')
    const [companyIndiviual, setcompanyIndiviual] = React.useState([])
    const [copycompanyIndiviual, setcopycompanyIndiviual] = React.useState([])
    const [selectedOrgArray, setselectedOrgArray] = React.useState([])
    const [selectedOrgArrayError, setselectedOrgArrayError] = React.useState("")

    const [allTasks, setAllTasks] = React.useState([])

    const [showActionList, setshowActionList] = React.useState(false)
    const [taskInput, settaskInput] = React.useState("")
    const [taskArray, settaskArray] = React.useState([])
    const [selectedComplianceId, setselectedComplianceId] = React.useState({
        id: '',
        name: ''
    })

    const [showConfirmDialog, setshowConfirmDialog] = React.useState(false)


    const ShowExitingDialog = (props) => {

        return (
            <Dialog
                sx={Workplacetheme.deleteWorkSpaceDialog}
                isOpen={showConfirmDialog}
                onDismiss={() => setshowConfirmDialog(false)}
                aria-labelledby="header-id"
                initialFocusRef={initialFocusRef}
            >
                <Dialog.Header id="header-id">
                    <Text sx={Workplacetheme.scheduleDalogeHeading}>Are you sure you want to delete organization?</Text>

                </Dialog.Header>

                <Box sx={Workplacetheme.deleteWorkplaceDialogContentBox}>

                    <Text sx={Workplacetheme.createSpaceDialodBoxText}>Please note that all associated information, documents, task history and reports have
                        been permanently deleted and cannot be undone.</Text>

                    <Box
                        sx={Workplacetheme.deleteworkplaceDialogFooterLine}
                        borderColor="border.default" borderBottomWidth={1} borderBottomStyle="solid"></Box>
                </Box>

                <Box
                    ml={24}
                    mr={24}
                    display="flex"
                    flexDirection="row"
                    justifyContent={"right"}
                >

                    <Button variant="invisible"
                        sx={Workplacetheme.deleteworkplaceDialogFooterBox.cancelBtn}
                        onClick={() => setshowConfirmDialog(false)}
                    >Cancel</Button>
                    <Button
                        ref={initialFocusRef}
                        variant="outline"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            margin: '9px 0px 0px 8px',
                            height: 32,
                            width: 73,
                            fontFamily: 'Segoe UI',
                            fontWeight: '600',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            background: '#0965CE',
                            color: '#FFFFFF'
                        }}
                        onClick={() => setshowConfirmDialog(false)}

                    >Save</Button>


                </Box>





            </Dialog>
        )

    }


    const closeCreateTaskDialog = (props) => {


        dispatch(createBulkTaskDialogOpenClose({
            bulktaskDialogOpenClose: false
        }))
        settaskInput('')





        setshowActionList(false)
        setselectedOrgArray([])
        setselectedComplianceId({
            id: '',
            name: ''
        })

    }

    //seyamig207@lanxi8.com


    const onSelectCompliance = (props) => {

        

        setselectedComplianceId({ id: props.service_id, name: props.subcategory.compliance_name })
      //  setselectedComplianceId({ id: props.subcategory.compliance_id, name: props.subcategory.compliance_name })
        settaskInput(props.subcategory.compliance_name)
        setshowActionList(false)
       
        
        const selectedCompany = companyIndiviual
        .filter((cpData) => cpData.compliances.filter((cData) => cData.compliance_id === props.service_id && cData.isChecked === true)[0]?.compliance_id === props.service_id)
       // .filter((cpData) => cpData.compliances.filter((cData) => cData.compliance_id === props.subcategory.compliance_id && cData.isChecked === true)[0]?.compliance_id === props.subcategory.compliance_id)
        setselectedOrgArray(selectedCompany)
        setselectedOrgArrayError('')
    }





    const getAllCompanyIndiviuals = async (props) => {


        dispatch(getIndiviualsCompanies({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("getIndiviualsCompanies",data.payload.data)
            setcompanyIndiviual(data.payload.data)
            setcopycompanyIndiviual(data.payload.data)
        })

    }


    const postTaskToDb = (props) => {

        if (taskInput === "" || selectedComplianceId.id === "") {

            settaskInpuError('error')

        } else if (selectedOrgArray.length == 0) {

            setselectedOrgArrayError('error')
        }
        else {


            console.log("all project tasks", allTasks)
            console.log("selected orgs", selectedOrgArray)
            const newselectedOrgArray = []
            var isConfirm = true
            Promise.all(

                selectedOrgArray.map((row, index) => {

                    const checkTasks = allTasks.filter((tData) => tData.contact === row.contact_id && tData.compliance_id === selectedComplianceId.id)
                    if (checkTasks.length > 0) {
                        console.log("existing compliance in company", row.contact_data.org_name, checkTasks)
                        isConfirm = false
                    } else {

                        newselectedOrgArray.push(row)

                    }

                })


            )

            console.log("final selected org array", newselectedOrgArray)

            if (isConfirm === true) {

                postBulkTaskToDb(newselectedOrgArray)

            } else {

                setshowConfirmDialog(true)

            }










        }


    }

    const postBulkTaskToDb = (props) => {

        setshowLoadingSubmitBtn(true)
        const projectId = localStorage.getItem("projectId")
        const taskPriority = "Normal"
        dispatch(postcreateIssue({
            userId: userId,
            userEmail: userEmail,
            projectId: projectId,
            taskName: selectedComplianceId.name, //taskInput,
            taskPriority: taskPriority,
            assigneId: '',
            contact: props,
            dueDate: '',
            description: '',
            complianceId: selectedComplianceId.id
        })).then((data) => {

            const createdTaskCount = data?.payload?.taskId?.length
            Toaster.create({ position: Position.TOP }).show({
                message: "Successfully created " + createdTaskCount + " organizational tasks.",
                intent: Intent.SUCCESS
            });
            closeCreateTaskDialog("")
            setshowLoadingSubmitBtn(false)
        })

    }

    const getAllTasks = (props) => {


        dispatch(getComplianceTaksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            settaskArray(data.payload.data)
        })


    }

    const getAllProjectTasks = (props) => {



        dispatch(getAllWorksByProjectId({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {


            setAllTasks(data.payload?.data)



        })


    }


    React.useEffect(() => {

        if (taskData.bulktaskDialogOpenClose === true) {

            setselectedComplianceId({ id: '', name: '' })
            getAllCompanyIndiviuals("")
            getAllTasks("")
            getAllProjectTasks("")
        }



    }, [taskData.bulktaskDialogOpenClose])


    return (
        <Portal>
            <Dialog
                initialFocusRef={initialFocusRef}
                isOpen={taskData.bulktaskDialogOpenClose}
                onDismiss={() => {
                    closeCreateTaskDialog("")
                }}
                sx={{
                    minHeight: '455px',
                    height: '455px',
                    minWidth: '564px',
                    width: '564px',
                    maxWidth: '564px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>






                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    background: 'white'
                }}>

                    <Text
                        ref={initialFocusRef}
                        sx={{
                            position: 'absolute',
                            left: '16px',
                            top: '16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'
                        }}>
                        Create a task for multiple contacts / clients
                    </Text>

                    <Label sx={{
                        position: 'absolute',
                        right: '18px',
                        top: '14px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '18px',
                        color: selectedOrgArrayError === "error" ? '#ffffff' : '#0969DA',
                        borderRadius: '3px',
                        minWidth: '101px',
                        width: '101px',
                        maxWidth: '101px',
                        minHeight: '24px',
                        maxHeight: '24px',
                        background: selectedOrgArrayError === "error" ? '#A40E26' : '#DDF4FF',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {selectedOrgArray.length}/{companyIndiviual.filter((cpData) =>
                            selectedComplianceId.id !== "" ?
                                cpData.compliances.filter((cData) => cData.compliance_id === selectedComplianceId.id && cData.isChecked === true)[0]?.compliance_id === selectedComplianceId.id
                                : 0
                        ).length} Selected
                    </Label>

                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    top: '52px',
                    bottom: '52px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <TextInput
                        sx={{
                            position: 'relative',
                            margin: '15px 16px 0px 16px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292E',
                            minHeight: '40px'
                        }}
                        value={taskInput}
                        onChange={(e) => {

                            settaskInput(e.target.value)
                            if (e.target.value.length > 0) {
                                setshowActionList(true)
                                settaskInpuError('')
                            } else {
                                setshowActionList(true)
                                setselectedComplianceId({ id: '', name: '' })
                                setselectedOrgArray([])
                            }
                        }}
                        placeholder="Select services or compliance tasks to create in bulk."
                        validationStatus={taskInpuError}
                        onFocus={() => {
                            setshowActionList(true)
                            settaskInput('')
                        }}
                    />

                    <Box

                        sx={{
                            visibility: showActionList === true ? 'visible' : 'hidden',
                            zIndex: '1',
                            position: 'absolute',
                            left: '16px',
                            right: '16px',
                            top: '63px',
                            minHeight: 'auto',
                            maxHeight: '244px',
                            height: 'auto',
                            boxShadow: '0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12)',
                            borderRadius: '6px',
                            background: '#FFFFFF',
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }} >

                        <ActionList sx={{

                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '18px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#24292F'

                        }}>
                            {taskArray
                                .filter((tData) => tData.subcategory.compliance_name?.toLowerCase().includes(taskInput?.toLowerCase()))
                                .map((row, index) => {
                                    return (
                                        <ActionList.Item
                                            onSelect={() => {

                                                onSelectCompliance(row)

                                            }}
                                        >
                                            {row.subcategory.compliance_name}
                                        </ActionList.Item>
                                    )
                                })}




                        </ActionList>

                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '8px 16px 0px 16px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        minHeight: '20px',
                        alignItems: 'center'
                    }}>

                        <Text

                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '12px',
                                lineHeight: '20px',
                                color: '#24292E',
                                minHeight: '20px',
                                minWidth: '532px'
                            }}
                        >
                            Contacts / clients
                        </Text>
                    </Box>

                    <Box sx={{
                        display: selectedComplianceId.id === "" ? 'flex' : 'none',
                        position: 'absolute',
                        left: '16px',
                        right: '16px',
                        top: '141px',
                        minHeight: '40px',
                        maxHeight: '40px',
                        height: '40px',
                        color: '#57606A',
                    }}>
                        <Text sx={{
                            display: 'flex',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#57606A',
                            minWidth: '532px',
                            minHeight: '40px',
                            maxWidth: '532px',
                            width: '532px',
                            textAlign: 'center'
                        }}>
                            Multi-task creation feature! Select compliances and assign to chosen contacts/clients. Easily manage multiple tasks at once. Try it now!
                        </Text>
                    </Box>


                    <Box sx={{
                        position: 'absolute',
                        top: '93px',
                        left: '12px',
                        right: '16px',
                        bottom: '0px',
                        display: selectedComplianceId.id === "" ? 'none' : 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        background: '#ffffff'
                    }}>

                        {
                            companyIndiviual
                                .filter((cpData) =>
                                    selectedComplianceId.id !== "" ?
                                        cpData.compliances.filter((cData) => cData.compliance_id === selectedComplianceId.id && cData.isChecked === true)[0]?.compliance_id === selectedComplianceId.id
                                        : []
                                ).
                                sort((a, b) => a.contact_data?.org_name.localeCompare(b.contact_data?.org_name))
                                .map((row, index) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '5px',
                                            alignItems: 'center',
                                            minHeight: '30px',
                                            maxHeight: '30px',
                                            height: '30px',
                                            ":hover": {
                                                background: 'rgba(208, 215, 222, 0.32)'
                                            },
                                            borderRadius: '6px'

                                        }}>

                                            <Checkbox
                                                sx={{
                                                    display: 'flex'
                                                }}
                                                checked={selectedOrgArray.includes(row)}
                                                onChange={(e) => {

                                                    if (e.target.checked) {

                                                        setselectedOrgArray((prevState) => {
                                                            const newState = [...prevState, row]
                                                            return newState
                                                        })


                                                    } else {

                                                        setselectedOrgArray((prevState) => {

                                                            const newState = prevState.filter((oData) => oData.contact_id !== row.contact_id)
                                                            return newState

                                                        })


                                                    }


                                                }}
                                            />
                                            <Text sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#24292E',
                                                width: 'auto',
                                                maxWidth: '450px',
                                                minWidth: 'auto',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                display: 'flex'

                                            }}>{row.contact_data?.org_name}</Text>

                                        </Box>
                                    )
                                })
                        }

                    </Box>




                </Box>




                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    <Button
                        variant="invisible"
                        sx={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '109px',
                            width: '72px',
                            height: '32px',
                            minWidth: '72px',
                            maxWidth: '72px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',

                        }}
                        onClick={() => {
                            closeCreateTaskDialog("")
                        }}
                    >
                        Cancel
                    </Button>

                    {showLoadingSubmitBtn === true ?
                        <Button variant="outline" sx={{
                            position: 'relative',
                            margin: 'auto 16px 10px auto',
                            background: '#0965CE',
                            color: '#ffffff',
                            width: '78px',
                            height: '32px',
                            minWidth: '78px',
                            maxWidth: '78px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',

                        }}
                            onClick={() => {

                            }}
                        >

                            <Spinner size="small" sx={{ display: 'flex' }} />
                        </Button>
                        :
                        <Button variant="outline" sx={{
                            position: 'relative',
                            margin: 'auto 16px 10px auto',
                            background: '#0965CE',
                            color: '#ffffff',
                            width: '78px',
                            height: '32px',
                            minWidth: '78px',
                            maxWidth: '78px',
                            minHeight: '32px',
                            maxHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            lineHeight: '20px',
                            fontWeight: '600',

                        }}
                            onClick={() => {
                                postTaskToDb("")
                            }}
                        >
                            Create
                        </Button>
                    }

                </Box>

                <ShowExitingDialog />


            </Dialog>

        </Portal>

    )

}