
import React from "react"
import { Box, Dialog, Text, TextInput, Select, Textarea, Button, ActionList, Avatar } from '@primer/react'
import { useDispatch, useSelector } from "react-redux";
import { filesearchOpenClose, filteredFilesId, gstSearchDialogOpenClose } from '../../redux/features/searchSlice';
import Workplacetheme from "../Workplacetheme";
import { ArrowRightIcon, ExclamationTriangleIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { getGstPortal } from "../../redux/features/portalSlice";
import { useNavigate } from "react-router-dom";

function GSTSearchDialogs(props) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const searchData = useSelector((state) => state.searchData)

    const initialFocusRef = React.createRef()
    const [gstList, setgstList] = React.useState([])
    const [copygstList, setcopygstList] = React.useState([])
    const [searchInput, setsearchInput] = React.useState("")
    const [showActionList, setshowActionList] = React.useState(false)
    const [showListCount, setshowListCount] = React.useState(0)

    const handleSearchInput = (e) => {

        setsearchInput(e.target.value)

        if (e.target.value.length > 0) {


            setgstList((prevState) => {

                const newState = prevState.filter((gstData) => gstData?.pan_name?.toUpperCase().includes(e.target.value.toUpperCase()))
                if (newState.length > 0) {
                    setshowActionList(true)
                    return newState
                } else if (newState.length == 0) {
                    setshowActionList(false)
                    return copygstList
                }
            })


        } else {
            setshowActionList(false)

        }



    }

    const closeSearchDialog = (props) => {

        dispatch(gstSearchDialogOpenClose({
            gstsearchopenClose: false
        }))
        setsearchInput("")
        setshowActionList(false)
        setshowListCount(0)

    }


    const getGstPortalFromDb = (props) => {


        dispatch(getGstPortal({
            userId: userId,
            userEmail: userEmail,
        })).then((data) => {

            console.log("SearchgetgstList", data.payload)
            setgstList(data.payload.data)
            setcopygstList(data.payload.data)
        })


    }



    React.useEffect(() => {

        if (searchData.gstsearchopenClose === true) {

            getGstPortalFromDb("")
        }

    }, [searchData.gstsearchopenClose])



    return (
        <Dialog
            sx={Workplacetheme.filessearchDialogMain}
            isOpen={searchData.gstsearchopenClose}
            style={{ height: showListCount === 0 ? 51 : 170 }}
            onDismiss={() =>
                closeSearchDialog("")
            }
            aria-labelledby="header-id"
            initialFocusRef={initialFocusRef}

        >

            <Box sx={Workplacetheme.hideDialogCloseBtn}></Box>

            <TextInput
                ref={initialFocusRef}
                leadingVisual={
                    <MagnifyingGlassIcon
                        style={{
                            height: 20,
                            width: 16,
                            display: "flex",
                            alignContent: 'center'
                        }} />}
                sx={Workplacetheme.filesearchDialogInput}
                aria-label="SearchInput"
                name="searchInput"
                placeholder="Search GST"
                onChange={(e) => handleSearchInput(e)}
                value={searchInput}
            />



            {showActionList && (
                <ActionList showDividers sx={Workplacetheme.filesearchDialogActionList}>
                    {
                        gstList.map((row, index) => {
                            return (

                                <ActionList.Item sx={Workplacetheme.filesearchDialogActionList.actionListItem}
                                    onSelect={(e) => {

                                        closeSearchDialog("")
                                        localStorage.setItem("client_id", row.client_id)
                                        localStorage.setItem("insert_id", row.insert_id)
                                        localStorage.setItem("gstpanName", row.pan_name)
                                        localStorage.setItem("gstIn", row.user_name)
                                        navigate('/gst')

                                    }}
                                >
                                    <Text sx={Workplacetheme.filesearchDialogActionList.actionListItem.textField}>
                                        {row.pan_name}</Text>
                                </ActionList.Item>
                            )
                        })
                    }
                </ActionList>


            )}



        </Dialog>
    )
}

export default GSTSearchDialogs


