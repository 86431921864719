import React from "react"
import {Helmet} from "react-helmet";
import { Box,Button,IconButton,Text,Select,TextInput, Avatar, Label } from "@primer/react"
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import { useDispatch,useSelector } from "react-redux";
import customTheme from "../../Theme.module.scss"     
import customThemVariable from  "../../ThemeVariable.module.scss"
import { FeedPersonIcon, FilterIcon, GitBranchIcon, SortAscIcon, SortDescIcon } from "@primer/octicons-react";
import { getAllWorksByProjectId, getListData, getSpaceCollaborators } from "../redux/features/spaceSlice";
import { getSubtaskPrimary, openCloseTaskExpansion } from "../redux/features/taskSlice";
import TaskExpansion from "./TaskExpansion";
import { getIndiviualsCompanies, getOrganization } from "../redux/features/contactSlice";

function formatDate(date) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
}

export default function ListBeta(props) {

    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const [isAscending, setisAscending] = React.useState(false)
    const [showFilter,setshowFilter] = React.useState(false)
    const [showSubtasks,setshowSubtasks] = React.useState("")

    //--------- filter section parameters------------
    const [showResetButton,setshowResetButton] = React.useState(false)
    const startDateRef = React.createRef()
    const endDateRef = React.createRef()
    const [selectedComplianceId, setselectedComplianceId] = React.useState("")
    const [selectedCompanyType,setselectedCompanyType] = React.useState("")
    const [compliances, setcompliances] = React.useState([])
    const [companyType,setcompanyType] = React.useState([]) 
    const [selectedContactId, setselectedContactId] = React.useState("")
    const [companyIndiviual, setcompanyIndiviual] = React.useState([])
    const [filteredAssigneId, setfilteredAssigneId] = React.useState("")
    const [spaceCollaborators, setspaceCollaborators] = React.useState([])
    const [filteredWorkflowId, setfilteredWorkflowId] = React.useState("")
    const [workflow, setworkflow] = React.useState([{ workflow_id: '', workflow_name: 'All', counter: 1, isSelected: true }])
    const [filteredPriority, setfilteredPriority] = React.useState("")
    const [priorityArray,setpriorityArray] = React.useState(["Normal","Medium","High","Critical","Low"])
    const [filteredTasktype,setfilteredTasktype] = React.useState('')
    const [taskTypeArray,settaskTypeArray] = React.useState(["Task Created","Due"])
    const [startDate, setstartDate] = React.useState("")
    const [endDate, setendDate] = React.useState("")
    const [months,setMonths] = React.useState([
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ])
    const [selectedMonth,setselectedMonth] = React.useState("")
    //-----------------------------------------------
 
    //----------- Table Datas -----------------------
    const [allTasks,setallTasks] = React.useState([])
    const [copyAllTask, setcopyAllTask] = React.useState([])
    const [subtaskArray,setsubtaskArray] = React.useState([])
    //-----------------------------------------------

    const resetAllFilters = (props) => {

        setselectedComplianceId("")
        setselectedContactId("")
        
        setfilteredWorkflowId("")
        setallTasks(copyAllTask)
        setshowResetButton(false)
        setstartDate("")
        setendDate("")
        setfilteredPriority("")
        setfilteredTasktype("")
        setselectedCompanyType("")
        setfilteredAssigneId("")
        setselectedMonth('')
    }

    const handleClickFilterData = (props) => {

        //handle filter with all datas from drop down  
        const filteredArray = copyAllTask
            .filter((tData) => selectedComplianceId !== "" ? tData.compliance_id === selectedComplianceId : copyAllTask) // filter by compliance id
            .filter((tData) => selectedContactId !== "" ? tData.contact === selectedContactId : copyAllTask) // filtered by contact id 
            .filter((tData) => filteredWorkflowId !== "" ? tData.workflow_id === filteredWorkflowId : copyAllTask) // filtered by workflowId
            .filter((tData) => filteredAssigneId !== "" ? tData.assigne_id === filteredAssigneId : copyAllTask) // filter by assigne Id
            .filter((tData) => filteredPriority !== "" ? tData.tasks_priority === filteredPriority : copyAllTask) // filter by priority
            .filter((tData) =>

                filteredTasktype !== "" ?

                    filteredTasktype === "Due" ?
                        (
                            startDate !== "" && endDate !== "" && tData.due_date !== null ? new Date(tData.due_date) >= new Date(startDate) && new Date(tData.due_date) <= new Date(endDate) : copyAllTask
                        )
                        :
                        filteredTasktype === "Task Created" ?
                            (
                                startDate !== "" && endDate !== "" ? new Date(tData.created_timestamp) >= new Date(startDate) && new Date(tData.created_timestamp) <= new Date(endDate) : copyAllTask

                            )
                            : copyAllTask : copyAllTask

            )
        setallTasks(filteredArray)
        setshowResetButton(true)

    }

    const handleSortAscendingDecending = (props) => {

       // setshowSubtasks("")
       
        setisAscending(!isAscending)

        if (!isAscending) {

            // sort by due date 
            const sortByDueDate = copyAllTask
                .filter((tData) =>
                    tData.due_date !== ""

                )
                .sort(function (a, b) {

                    if (new Date(b.due_date) < new Date(a.due_date)) { return -1; }
                })

            setallTasks(sortByDueDate)

        } else {

            // reset sort tasks by initial state
            setallTasks(copyAllTask)

        }

    }

    const showTaskExpansion = (props) => {

        dispatch(openCloseTaskExpansion({
            openCloseExpansion: true,
            expansionTaskId: props.taskId
        }))

    }

    const getSubtaskFromDb = (props) => {

        if (showSubtasks === props) {
            setshowSubtasks("")

        } else {
            setshowSubtasks(props)
        }
    }

    const getAllTaskFromDb = (props) => {


         
        dispatch(getListData({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            console.log("data in list",data.payload)

            const sortedTaskArray = data.payload?.data?.sort(function (a, b) {
                return new Date(b.created_timestamp) - new Date(a.created_timestamp);
            })

            console.log("sortedTaskArray", sortedTaskArray)
            setallTasks(sortedTaskArray)
            setcopyAllTask(sortedTaskArray)
            setcompliances(data.payload.complianceArray)
            setworkflow(data.payload.workflowArray)

            const newSubtaskPrimaryArray = [...data.payload.subtasks].sort((a, b) => new Date(b.created_timestamp) - new Date(a.created_timestamp))
            setsubtaskArray(newSubtaskPrimaryArray)  
        })


    }

    const getOrganizationFromDb = (props) => {

       
        dispatch(getOrganization({
            userId: userId,
            userEmail: userEmail,
            projectId: ''
        })).then((data) => {

            console.log("getOrganization", data.payload.data)
            const newCompanyType = [{ id: 'All', name: 'All' }]

            const responseArray = [...data.payload.data].map((row, index) => {
                const orgTypeArray = [...row.org_types].map((row2, index2) => { newCompanyType.push(row2) })
            })
            setcompanyType(newCompanyType)


        })

    }

    const getAllCompanyIndiviuals = async (props) => {

       
        dispatch(getIndiviualsCompanies({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setcompanyIndiviual(data.payload.data)

        })

    }

    const getSpaceCollaboratorFromDb = (props) => {

       
        dispatch(getSpaceCollaborators({
            userEmail: userEmail,
            userId: userId,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

            setspaceCollaborators(data.payload.collaboratorArray)
        })

    }


    React.useState(()=>{

         getAllTaskFromDb("")
         getOrganizationFromDb("")
         getAllCompanyIndiviuals("")
         getSpaceCollaboratorFromDb("")

    }) 




    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
        >

            <Helmet><title> {localStorage.getItem("projectName") || ""} - List</title></Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />

            <Box
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
                overflowX={"hidden"}
            >

            {/* Heading section */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '24px',

                }}>
                    <Text
                        className={customTheme.H4}
                        sx={{
                            color: customThemVariable.textgraydark
                        }}>
                        List view
                    </Text>
                </Box>
            {/* --------------------- */}

            {/* Right side Header */}

                <Box sx={{
                    position: 'relative',
                    margin: '20px 61px 0px auto',
                    display: 'flex',
                    flexDirection: 'row',
                    width:'fit-content',
                    height:'fit-content',  
                }}>

                    
                    <IconButton
                        sx={{
                            position: 'relative',
                            margin: '0px 14px 0px auto',
                            border: 'none',
                            color: '#57606A',
                            boxShadow: 'none',
                        }}

                        icon={FilterIcon}
                        onClick={()=>{
                            setshowFilter(!showFilter)
                        }}
                    />
                    <IconButton
                        sx={{
                            position: 'relative',
                            margin: '0px 20px 0px auto',
                            border: 'none',
                            color: '#57606A',
                            boxShadow: 'none',
                        }}
                        icon={isAscending === true ? SortDescIcon : SortAscIcon}
                        onClick={() => handleSortAscendingDecending("")}
                    />
                    <Button sx={{
                        position: 'relative',
                        margin: '0px 0px 0px 0px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: customThemVariable.textgraydark,
                        boxShadow: 'none',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                        onClick={() => { }}
                    >
                        Add
                    </Button>
                        
                        
                     




                </Box>   
                {/* --------------------- */}
     
                {/* Filter Section */}
                <Box sx={{
                    position: 'absolute',
                    top: '62px',
                    left: '26px',
                    right: '61px',
                    minHeight: '120px',
                    height: '120px',
                    maxHeight: '120px',
                    border: '1px solid #D0D7DE',
                    borderRadius: '3px',
                    background: '#FFFFFF',
                    display: showFilter ? 'flex' : "none",
                    flexDirection: 'column',
                    minWidth: '1257px',

                }}>

                    <Box sx={{
                        position: 'absolute',
                        left: '21px',
                        right: '21px',
                        top: '22px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        height: '28px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '15px'
                    }}>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400'

                            }}  >

                            <Select
                                block
                                aria-label="Compliance type"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}
                                onChange={(e) => {

                                    if (e.target.value.length > 0) {
                                        setselectedComplianceId(e.target.value)
                                    } else {
                                        setselectedComplianceId("")
                                    }

                                }}
                                
                            >
                                <Select.Option value={""} selected={selectedComplianceId.length<=0?true:false}>Select compliance</Select.Option>
                                <Select.Option  value={""} selected={selectedCompanyType.length>0&&selectedComplianceId==="" ?
                                    
                                    true:false}>All</Select.Option>
                                {

                                    compliances.map((row, index) => {
                                        return (

                                            <Select.Option value={row.subcategory.compliance_id} selected={row.subcategory.compliance_id === selectedComplianceId} >{row.subcategory.compliance_name}</Select.Option>
                                        )
                                    })

                                }
                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Label"
                                placeholder="Select organization type"

                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                }}

                                onChange={(e)=>{

                                   
                                    setselectedCompanyType(e.target.value)
                                    if (selectedComplianceId.length <= 0) {
                                        setselectedComplianceId("")
                                    }
                                }}
                            >
                                {companyType.map((row, index) => {
                                    return (

                                        <Select.Option value={row.id} selected={row.id===selectedCompanyType}>{row.name}</Select.Option>
                                         
                                    )
                                })}


                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400'

                            }}  >

                            <Select

                                block
                                aria-label="Organization"
                                placeholder="Select organization"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {

                                    if(e.target.value==="All"){
                                          setselectedContactId("")
                                    }
                                    setselectedContactId(e.target.value)
                                }}
                            >
                                {companyIndiviual
                                    .filter((cData) => selectedCompanyType === "All" ? companyIndiviual : cData.contact_data?.org_type === selectedCompanyType)
                                    .map((row, index) => {
                                        return (
                                            <Select.Option value={row.contact_id} >{row.contact_data?.org_name}</Select.Option>

                                        )
                                    })}



                            </Select>
                        </Box>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Assignee"
                                placeholder="Select assignee"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredAssigneId(e.target.value)
                                }}
                            >

                                {spaceCollaborators?.map((row, index) => {
                                    return (
                                        <Select.Option value={row?.user_id}  selected={row?.user_id===filteredAssigneId}>{row?.user_first_name + " " + row?.user_last_name}</Select.Option>
                                    )
                                })}


                            </Select>
                        </Box>


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Status"
                                placeholder="Select work status"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredWorkflowId(e.target.value)
                                }}
                            >

                                {
                                    workflow?.map((row, index) => {
                                        return (
                                            <Select.Option value={row.workflow_id} selected={row.workflow_id===""? false : row.workflow_id===filteredWorkflowId}>{row.workflow_name}</Select.Option>
                                        )
                                    })
                                }



                            </Select>
                        </Box>


                        


                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Priority"
                                placeholder="Select priority"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e) => {
                                    setfilteredPriority(e.target.value)
                                }}
                            >

                                {priorityArray.map((row, index) => {

                                    return (
                                        <Select.Option value={row} selected={row === filteredPriority}>{row}</Select.Option>
                                    )
                                })}
 

                            </Select>
                        </Box>

                        
 

                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        left: '21px',
                        right: '21px',
                        top: '70px',
                        minHeight: '28px',
                        maxHeight: '28px',
                        height: '28px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '15px'
                    }}>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Input placeholder"
                                placeholder="Select task type"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                }}
                                onChange={(e)=>{
                                    setfilteredTasktype(e.target.value)
                                }}
                            >

                                {taskTypeArray.map((row,index)=>{

                                      return(
                                        <Select.Option value={row} selected={row===filteredTasktype}>{row}</Select.Option>
                                      )
                                })}

                                

                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',

                            }}  >

                            <Select

                                block
                                aria-label="Wait"
                                placeholder="Select month"
                                style={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400',

                                }}
                                onChange={(e)=>{

                                    setselectedMonth(e.target.value)
                                    
                                    if(e.target.value.length > 0){

                                          setstartDate(new Date(new Date(new Date().getFullYear(), months.indexOf(e.target.value), 2)).toISOString().slice(0,10))
                                          setendDate(new Date(new Date(new Date().getFullYear(), months.indexOf(e.target.value) + 1, 1)).toISOString().slice(0,10))
 
                                    }else{
                                         
                                          setstartDate("")
                                          setendDate("")
                                            
                                    }
                                    
 

                                }}
                                 
                                
                            >

                                {months.map((row,index)=>{
                                      return(
                                        <Select.Option value={row} selected={row===selectedMonth} >{row}</Select.Option>
                                      )
                                })}
                               
                                

                            </Select>
                        </Box>

                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',
                                display: 'flex'
                            }}  >

                            <TextInput
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'
                                }}
                                placeholder="Start date"
                                value={startDate !== null && startDate !== "" ?
                                    new Date(startDate).getDate() + "/" + ((new Date(startDate).getMonth() + 1)) + "/" + new Date(startDate).getFullYear()
                                    : ""}
                                onChange={(e) =>

                                    startDateRef.current.showPicker()
                                }
                                onFocus={(e) => { startDateRef.current.showPicker() }}
                                onClick={() => {
                                    startDateRef.current.showPicker()
                                }}
                            />
                            <input
                                type={"date"}
                                ref={startDateRef}
                                value={startDate}
                                onChange={(e) => {

                                    if (e.target.value === "" || e.target.value == null) {

                                        setstartDate("")


                                    } else {

                                         setstartDate(e.target.value)
                                         if(months.indexOf(selectedMonth)!==new Date(e.target.value).getMonth()){
                                                setselectedMonth('')
                                         }
                                     

                                    }


                                }}
                                style={{

                                    position: 'fixed',
                                    height: '26px',
                                    minHeight: '26px',
                                    maxHeight: '26px',
                                    width: '180px',
                                    minWidth: '180px',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />
                        </Box>




                        <Box
                            className="selectSpana"
                            sx={{
                                minHeight: '28px',
                                maxHeight: '28px',
                                height: '28px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                width: '190px',
                                fontFamily: 'Segoe UI',
                                fontSize: '12px',
                                fontWeight: '400',
                                display: 'flex'
                            }}  >

                            <TextInput
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'
                                }}
                                placeholder="End date"
                                value={endDate !== null && endDate !== "" ?
                                    new Date(endDate).getDate() + "/" + ((new Date(endDate).getMonth() + 1)) + "/" + new Date(endDate).getFullYear()
                                    : ""}
                                onChange={(e) =>

                                    endDateRef.current.showPicker()
                                }
                                onFocus={(e) => { endDateRef.current.showPicker() }}
                                onClick={() => {
                                    endDateRef.current.showPicker()
                                }}
                            />
                            <input
                                type={"date"}
                                ref={endDateRef}
                                value={endDate}
                                onChange={(e) => {

                                    if (e.target.value === "" || e.target.value == null) {


                                        setendDate("")


                                    } else {

                                        setendDate(e.target.value)
                                    }


                                }}
                                style={{

                                    position: 'fixed',
                                    height: '26px',
                                    minHeight: '26px',
                                    maxHeight: '26px',
                                    width: '180px',
                                    minWidth: '180px',
                                    zIndex: '0',
                                    visibility: 'hidden'
                                }}

                            />


                        </Box>



                        <Box sx={{
                              height: '28px',
                              minHeight: '28px',
                              width: '190px',
                              minWidth: '190px',
                              maxWidth: '190px',
                              maxHeight: '28px',
                        }}>
                            <Button 
                            disabled={!showResetButton}
                            variant="outline"
                                sx={{
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    height: '28px',
                                    minHeight: '28px',
                                    width: '190px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    maxHeight: '28px',
                                    display:'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    resetAllFilters("")
                                }}
                            >
                                Reset All
                            </Button>
                        </Box>

                        <Button variant="outline" sx={{
                            background: '#0965CE',
                            color: '#ffffff',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            height: '28px',
                            minHeight: '28px',
                            width: '190px',
                            minWidth: '190px',
                            maxWidth: '190px',
                            maxHeight: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                            onClick={() => handleClickFilterData("")}
                        >
                            Filter data
                        </Button>







                    </Box>


                </Box>


                {/* -------------- */}

                {/* Table Section */}
                <Box sx={{
                    position: 'absolute',
                    top: showFilter?'200px':'62px',
                    left: '26px',
                    right: '61px',
                    minHeight:'400px',
                    display:'flex',
                    flexDirection:'column',
                    paddingBottom:'200px'
                }}>
                    {/* table Header */}

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '40px',
                        maxHeight: '40px',
                        height: '40px',
                        background: customThemVariable.whiteBgColor,
                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`
                    }}>

                        <Box
                            sx={{
                               minWidth:'35.55%',
                               maxWidth:'35.55%',
                               width:'35.55%',
                               display:'flex',
                               alignItems:'center'
                            }}
                        >

                            <Text
                            className={customTheme.H6}
                            sx={{
                                color:customThemVariable.textgray,
                                marginLeft:'16px',
                                textTransform:'uppercase'
                            }}
                            >
                                Task
                            </Text>
                             
                        </Box>
                        <Box
                            sx={{
                               minWidth:'14.8%',
                               maxWidth:'14.8%',
                               width:'14.8%',
                               display:'flex',
                               alignItems:'center'
                            }}
                        >

                            <Text
                                className={customTheme.H6}
                                sx={{
                                    color: customThemVariable.textgray,
                                    marginLeft: '16px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                COMPLIANCE 
                            </Text>
                             
                        </Box>
                        <Box
                            sx={{
                               minWidth:'25.92%',
                               maxWidth:'25.92%',
                               width:'25.92%',
                               display:'flex',
                               alignItems:'center'
                            }}
                        >

                            <Text
                                className={customTheme.H6}
                                sx={{
                                    color: customThemVariable.textgray,
                                    marginLeft: '16px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                CLIENT
                            </Text>
                             
                        </Box>
                        <Box
                            sx={{
                               minWidth:'11.52%',
                               maxWidth:'11.52%',
                               width:'11.52%',
                               display:'flex',
                               alignItems:'center'
                            }}
                        >

                            <Text
                                className={customTheme.H6}
                                sx={{
                                    color: customThemVariable.textgray,
                                    marginLeft: '16px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                STATUS
                            </Text>
                             
                        </Box>
                        <Box
                            sx={{
                               minWidth:'12.12%',
                               maxWidth:'12.12%',
                               width:'12.12%',
                               display:'flex',
                               alignItems:'center'
                            }}
                        >
                             <Text
                                className={customTheme.H6}
                                sx={{
                                    color: customThemVariable.textgray,
                                    marginLeft: '16px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                DUE DATE
                            </Text>
                             
                        </Box>


                    </Box>


                    {/* ------------ */}

                    {/* Dynamic Table Data */}
                    {allTasks.map((row,index)=>{
                            
                            return(
                                 <>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        background: customThemVariable.whiteBgColor,
                                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                        cursor:'pointer',
                                        ":hover":{
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        },
                                    }}
                                        onClick={() => {

                                            getSubtaskFromDb(row.task_id)
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                minWidth: '35.55%',
                                                maxWidth: '35.55%',
                                                width: '35.55%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >

                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    color: customThemVariable.textgraydark,
                                                    marginLeft: '16px',
                                                    maxWidth:'82%',
                                                    minWidth:'82%',
                                                    width:'82%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    
                                                }}
                                                onClick={()=>{
                                                    showTaskExpansion({taskId:row.task_id}) 
                                                }}
                                            >
                                                {row.task_name}
                                            </Text>

                                            <Button
                                            variant="invisible"
                                            leadingIcon={()=> <GitBranchIcon/> }
                                            sx={{
                                                position:'relative',
                                                margin:'0px 10px 0px auto',
                                                fontFamily:customThemVariable.primartFont,
                                                color:customThemVariable.textgraydark,
                                                paddingLeft:'13px',
                                                paddingRight:'13px'
                                            }}
                                            onClick={()=>{
                                                
                                                getSubtaskFromDb(row.task_id)

                                               
                                            }}
                                            >
                                                {subtaskArray.filter((row2,index2)=> row2.subtask_parent===row.task_id ).length || "0"}
                                               
                                            </Button>


                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '14.8%',
                                                maxWidth: '14.8%',
                                                width: '14.8%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >

                                            {row.compliance_id !== "" && row.compliance_id !== null && compliances.length>0 ?

                                                <Text
                                                    className={customTheme.textnormal}
                                                    sx={{
                                                        color: customThemVariable.textgraydark,
                                                        marginLeft: '16px',
                                                        maxWidth:'90%',
                                                        minWidth:'90%',
                                                        width:'90%',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {compliances?.filter((cData)=> cData?.subcategory?.compliance_id===row?.compliance_id )[0]?.subcategory?.compliance_name}
                                                    
                                                </Text>


                                                : ""}

                                            

                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '25.92%',
                                                maxWidth: '25.92%',
                                                width: '25.92%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >

                                            {row.contact !== "" && row.contact !== null ?


                                                <Text
                                                    className={customTheme.textnormal}
                                                    sx={{
                                                        color: customThemVariable.textgraydark,
                                                        marginLeft: '16px',

                                                    }}
                                                >
                                                    {row?.contact_data?.org_name}
                                                </Text>

                                                : ""}


                                            {row.user_image_url === "" || row.user_image_url == null ?

                                                <Box sx={{
                                                    position: 'relative',
                                                    margin: '0px 28px 0px auto',
                                                    height: '24px',
                                                    width: '24px',
                                                    minHeight: '24px',
                                                    minWidth: '24px',
                                                }}>
                                                
                                                    <FeedPersonIcon size={24} fill="#586069" />
                                                </Box>


                                                :

                                                <Avatar sx={{
                                                    position: 'relative',
                                                    margin: '0px 28px 0px auto',
                                                    height: '24px',
                                                    width: '24px',
                                                    minHeight: '24px',
                                                    minWidth: '24px',


                                                }}
                                                    src={row.user_image_url} />

                                            }

                                             

                                            

                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '11.52%',
                                                maxWidth: '11.52%',
                                                width: '11.52%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >

                                            <Label sx={{
                                                marginLeft: '16px',
                                                fontFamily: customThemVariable.primartFont,
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: customThemVariable.labelTextColorgreen,
                                                borderRadius: '6px',
                                                minHeight: '24px',
                                                maxHeight: '24px',
                                                height: '24px',
                                                background: customThemVariable.labelBgTertiaryColor,
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>

                                                {workflow?.filter((wData)=> wData?.workflow_id===row?.workflow_id)[0]?.workflow_name}
                                                
                                            </Label>

                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '12.12%',
                                                maxWidth: '12.12%',
                                                width: '12.12%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Text
                                                className={customTheme.textnormal}
                                                sx={{
                                                    color: customThemVariable.textgraydark,
                                                    marginLeft: '16px',
                                                    
                                                }}
                                            >
                                                {row.due_date !== "" && row.due_date !== null ?

                                                        formatDate(new Date(row.due_date))
                                                    : ''}
                                               
                                            </Text>

                                        </Box>


                                    </Box>

                                    {showSubtasks===row.task_id?

                                        subtaskArray
                                        .filter((row2,index2)=> row2.subtask_parent===row.task_id )
                                        .map((subTask,index)=>{

                                              return(
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    minHeight: '40px',
                                                    maxHeight: '40px',
                                                    height: '40px',
                                                   // background: customThemVariable.whiteBgColor,
                                                    borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                                    cursor:'pointer'
                                                }}
                                                onClick={()=>{
                                                    showTaskExpansion({taskId:subTask.task_id})
                                                }}
                                                
                                                >
            
                                                    <Box
                                                        sx={{
                                                            minWidth: '76.27%',
                                                            maxWidth:  '76.27%',
                                                            width:  '76.27%',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                            
                                                        <Text
                                                            className={customTheme.textnormal}
                                                            sx={{
                                                                display:'flex',
                                                                color: customThemVariable.textgraydark,
                                                                marginLeft: '16px',
                                                            }}
                                                        >
                                                           {index+1}
                                                        </Text>
                                                        <Text
                                                            className={customTheme.textnormal}
                                                            sx={{
                                                                display:'flex',
                                                                color: customThemVariable.textgraydark,
                                                                marginLeft: '32px',
                                                                maxWidth: '82%',
                                                                minWidth: '82%',
                                                                width: '82%',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                           {subTask.task_name}  
                                                        </Text>

                                                          {subTask.user_image_url === "" || subTask.user_image_url == null ?

                                                              <Box sx={{
                                                                  position: 'relative',
                                                                  margin: '0px 28px 0px auto',
                                                                  height: '24px',
                                                                  width: '24px',
                                                                  minHeight: '24px',
                                                                  minWidth: '24px',
                                                              }}>

                                                                  <FeedPersonIcon size={24} fill="#586069" />
                                                              </Box>


                                                              :

                                                              <Avatar sx={{
                                                                  position: 'relative',
                                                                  margin: '0px 28px 0px auto',
                                                                  height: '24px',
                                                                  width: '24px',
                                                                  minHeight: '24px',
                                                                  minWidth: '24px',


                                                              }}
                                                                  src={subTask.user_image_url} />

                                                          }

                                                          
            
                                                    </Box>
                                                      <Box
                                                          sx={{
                                                              minWidth: '11.52%',
                                                              maxWidth: '11.52%',
                                                              width: '11.52%',
                                                              display: 'flex',
                                                              alignItems: 'center'
                                                          }}
                                                      >

                                                          <Label sx={{
                                                              marginLeft: '16px',
                                                              fontFamily: customThemVariable.primartFont,
                                                              fontStyle: 'normal',
                                                              fontWeight: '600',
                                                              fontSize: '14px',
                                                              lineHeight: '20px',
                                                              color: customThemVariable.labelTextColorgreen,
                                                              borderRadius: '6px',
                                                              minHeight: '24px',
                                                              maxHeight: '24px',
                                                              height: '24px',
                                                              background: customThemVariable.labelBgTertiaryColor,
                                                              border: 'none',
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                          }}>

                                                              {workflow?.filter((wData) => wData?.workflow_id === subTask?.workflow_id)[0]?.workflow_name}
                                                              
                                                          </Label>

                                                      </Box>
                                                    <Box
                                                        sx={{
                                                            minWidth: '12.12%',
                                                            maxWidth: '12.12%',
                                                            width: '12.12%',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Text
                                                            className={customTheme.textnormal}
                                                            sx={{
                                                                color: customThemVariable.textgraydark,
                                                                marginLeft: '16px',
                                                                
                                                            }}
                                                        >

                                                              {subTask.due_date !== "" && subTask.due_date !== null ?

                                                                  formatDate(new Date(subTask.due_date))
                                                                  : ''}

                                                            
                                                        </Text>
            
                                                    </Box>
            
            
                                                </Box>

                                              )
                                        })
                                    
                                    
                                    
                                    :""}

                                 
                                 </>
                            ) 

                    })}
                    {/* ------------------ */}


                   
                </Box>
                {/* ------------- */}


            </Box>


           {/* Task Expansion details */}
           
           <TaskExpansion/> 
           <SearchDialog />

        </Box>
    )

}