import { Box, Button, IconButton, Text, Breadcrumbs, Label, ActionMenu, ActionList, Dialog, TextInput, Textarea, FormControl, Radio, Select, Checkbox } from "@primer/react"
import { Helmet } from "react-helmet";
import SpaceHeader from "./SpaceHeader"
import ScheduleDemoDialog from "../Workplace/Dialogs/ScheduleDemoDialog"
import SpaceLeftSideNav from "./SpaceLeftSideNav"
import SpaceRightSideNav from "./SpaceRightSideNav"
import SearchDialog from "../Workplace/Dialogs/SearchDialog"
import EmptyData from "./EmptyData";
import CreateTask from "./CreateTask";
import { SortAscIcon, SortDescIcon, XIcon } from "@primer/octicons-react";
import React from "react";
import ComplianceExpansion from "./ComplianceExpansion";
import { useDispatch, useSelector } from "react-redux";
import { getProjectServices, postServiceCompliance, postServiceComplianceCategory } from "../redux/features/spaceSlice";
import { Toaster, Intent, Position } from "@blueprintjs/core";
import { postZohoInvItem } from "../redux/features/contactSlice";
import { getZohoAuth } from "../redux/features/taskSlice";



function Rules(props) {


    const dispatch = useDispatch()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail)

    const zohoTokens   = JSON.parse(localStorage.getItem("zohoTokens")) || ""
    const [isAddtoZohoItem,setisAddtoZohoItem] = React.useState(false)
    const [isAscending,setisAscending] = React.useState(false)
    const [showComplianceExpansion, setshowComplianceExpansion] = React.useState(false)
    const [selectedBrudcom, setselectedBrudcom] = React.useState({
        category_name: '',
        subcategory_name: ''
    })
    const [dateRangeSelection,setdateRangeSelection] = React.useState({
        minDate:'2023-01-01',
        maxDate:'2023-12-31'
    })

    const [triggerType,settriggerType] = React.useState([
          {id:'automatic',name:'Automatic'},
          {id:'manual',name:'Manual'}
    ])

    const [selectedTriggerType,setselectedTriggerType] = React.useState("automatic")


    const [expansionCatData, setexpansionCatData] = React.useState(null)
    const [expansionData, setexpansionData] = React.useState(null)
    const [expansionSubtask,setexpansionSubtask] = React.useState([])
    const [categories, setcategories] = React.useState([])
    const [compliances,setcompliances] = React.useState([])
    const [complianceType,setcomplianceType] = React.useState([])
    const [filingPeriods,setfilingPeriods] = React.useState([])
    const [billingTypes,setbillingTypes] = React.useState([])
    const [remindMeType,setremindMeType] = React.useState([])
    const [selectedAssigne,setselectedAssigne] = React.useState("")


    const initialFocusRef = React.createRef()
    const initialFocusRef1 = React.createRef()
    const dueDateCalandarRef = React.createRef()
    const serviceNameInputRef = React.createRef()
    const scrollRef = React.createRef()


    const initialInputRef = React.createRef()
    const [showCreateDialog, setshowCreateDialog] = React.useState(false)
    const [showCreateServiceDialog, setshowCreateServiceDialog] = React.useState(false)
    const [subtaskInputshown, setsubtaskInputshown] = React.useState(false)
    const [subtaskArray, setsubtaskArray] = React.useState([])
    const [subtaskInput, setsubtaskInput] = React.useState("")
    const [dueDateInput, setdueDateInput] = React.useState({
        value: '',
        validationStatus: ''
    })
    const [selectedRadio, setselectedRadio] = React.useState("0")

    //------------ category dialod forms
    const [categoryNameInput, setcategoryNameInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [additionalInfo, setadditionalInfo] = React.useState({
        value: '',
    })

    const [selectedCategory, setselectedCategory] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [selectedBillingType, setselectedBillingType] = React.useState("")
    const [amountPayable, setamountPayable] = React.useState("")
    const [serviceId, setserviceId] = React.useState("")


    // -----------------------------------------------

    // compliance dialog form ------------------------

    const [complianceInput, setcomplianceInput] = React.useState({
        value: '',
        validationStatus: ''
    })

    const [selectedFilingPeriod,setselectedFilingPeriod] = React.useState("")
    const [isZohoInstalled,setisZohoInstalled] = React.useState(false)

    const handleSortAscendingDecending = (props) => {

        setisAscending(!isAscending)
        if(isAscending===true){

            setcompliances((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.subcategory.compliance_name < b.subcategory.compliance_name) { return -1; }




                })

                return newState
            })


        }else{

            setcompliances((preveState) => {

                const newState = preveState.sort(function (a, b) {
                    if (a.subcategory.compliance_name > b.subcategory.compliance_name) { return -1; }

                })

                return newState
            })
        }
       
       
     

       

  }

    // ------------------------------------------------
    const getAllCategories = (props) => {

        dispatch(getProjectServices({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

             setcategories(data.payload.category)
             const sortedArray = data?.payload.data?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
             setcompliances(sortedArray)
            
             setcomplianceType(data?.payload?.complianceType)
             setfilingPeriods(data?.payload?.filingPeriods)
             setbillingTypes(data?.payload?.billingTypes)
             setremindMeType(data?.payload?.remindMeTypes)

             if(showCreateServiceDialog===false){

                   const defaultRadio = data?.payload?.complianceType[0]?.id
                   setselectedRadio(defaultRadio)
             }


        })

    }

    const getZohoAuthFromDb = (props) => {

        
        dispatch(getZohoAuth({
            userId: userId,
            userEmail: userEmail,
            projectId: localStorage.getItem("projectId")
        })).then((data) => {

           
            console.log("getZohoAuth In Org Dialog", data.payload)
            const appCode = data?.payload?.checkApp?.code
            const refreshToken = data?.payload?.data?.refresh_token

            console.log("refreshToken",refreshToken)
            if (refreshToken !== null && refreshToken !== "" && refreshToken !== undefined) {

                setisAddtoZohoItem(true)

            } else {

                setisAddtoZohoItem(false)

            }

            if (appCode === 200) {

                setisZohoInstalled(true)

            } else if (appCode === 404) {

                setisZohoInstalled(false)
            }



        })

    }

    const addToZohoItem = (props) => {

        const itemId =  complianceInput.value
        const itemName = complianceInput.value
        
        dispatch(postZohoInvItem({
              userId:userId,
              userEmail:userEmail,
              zohoToken: zohoTokens,
              itemId:itemId,
              itemName:itemName,
              rate:amountPayable,
              projectId:localStorage.getItem("projectId")
        })).then((data)=>{

            console.log("postZohoInvItem", data.payload)
            //createItemResponse
             const itemNames = data?.payload?.createItemResponse?.data?.item?.name
             const message = data?.payload?.createItemResponse?.data.message
             const code = data?.payload?.createItemResponse?.data?.code

            if(itemNames===itemName){

                 
                 Toaster.create({ position: Position.TOP }).show({
                    message: "Sucessfully created in zoho",
                    intent: Intent.SUCCESS
                 });


            }else{

                if (code == 9017) {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Zoho " + message,
                        intent: Intent.PRIMARY
                    });

                }else{

                   

                }

              
            }


        })


    }

    const onChangeFilePeriodSelection = (props) => {

        setselectedFilingPeriod(props.split("|")[0])
        if (props.split("|")[1] === "Monthly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1)

                const startDate = "" + new Date(currentDate).getFullYear() + "-" + (new Date(currentDate).getMonth() + 1) + "-" + "01"
                const endDate = "" + new Date(lastDayOfCurrentMonth).getFullYear() + "-" + (new Date(lastDayOfCurrentMonth).getMonth() + 1) + "-" + new Date(lastDayOfCurrentMonth).getDate()
                const newState = { ...prevState, minDate: startDate, maxDate: endDate }

                return newState
            })

        } else if (props.split("|")[1] === "Quarterly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

                // Calculate the start date of the current quarter
                const startMonthOfCurrentQuarter = (currentQuarter - 1) * 3;
                const firstDayOfCurrentQuarter = new Date(currentDate.getFullYear(), startMonthOfCurrentQuarter, 1);

                // Calculate the last day of the current quarter
                const endMonthOfCurrentQuarter = startMonthOfCurrentQuarter + 2;
                const lastDayOfCurrentQuarter = new Date(currentDate.getFullYear(), endMonthOfCurrentQuarter + 1, 0);

                const startDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate()}`;
                const endDate = `${lastDayOfCurrentQuarter.getFullYear()}-${(lastDayOfCurrentQuarter.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfCurrentQuarter.getDate()}`;

                const newState = { ...prevState, minDate: startDate, maxDate: endDate };
                console.log("newState", JSON.stringify(newState))

                return newState;

            });

        } else if (props.split("|")[1] === "Half-yearly") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                // Calculate the start date of the next half-year
                const startMonthOfNextHalfYear = (currentDate.getMonth() < 6) ? 6 : 12;
                const firstDayOfNextHalfYear = new Date(currentDate.getFullYear(), startMonthOfNextHalfYear - 6, 1);
                // Calculate the last day of the next half-year
                const lastDayOfNextHalfYear = new Date(currentDate.getFullYear(), startMonthOfNextHalfYear, 0);
                const startDate = `${firstDayOfNextHalfYear.getFullYear()}-${(firstDayOfNextHalfYear.getMonth() + 1).toString().padStart(2, '0')}-01`;
                const endDate = `${lastDayOfNextHalfYear.getFullYear()}-${(lastDayOfNextHalfYear.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfNextHalfYear.getDate()}`;
                const newState = { ...prevState, minDate: startDate, maxDate: endDate };

                return newState;
            });

        } else if (props.split("|")[1] === "Financial year") {

            setdateRangeSelection((prevState) => {

                const currentDate = new Date();
                // Assuming the financial year starts in April and ends in March
                const startMonthOfNextFinancialYear = (currentDate.getMonth() < 3) ? 3 : 15;
                const firstDayOfNextFinancialYear = new Date(currentDate.getFullYear(), startMonthOfNextFinancialYear - 3, 1);
                // Calculate the last day of the next financial year
                const lastDayOfNextFinancialYear = new Date(currentDate.getFullYear(), startMonthOfNextFinancialYear, 0);
                const startDate = `${firstDayOfNextFinancialYear.getFullYear()}-${(firstDayOfNextFinancialYear.getMonth() + 1).toString().padStart(2, '0')}-01`;
                const endDate = `${lastDayOfNextFinancialYear.getFullYear()}-${(lastDayOfNextFinancialYear.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfNextFinancialYear.getDate()}`;
                const newState = { ...prevState, minDate: startDate, maxDate: endDate };
                return newState;
            });

        }
    }

    const postServiceIndb = (props) => {

      

        if (complianceInput.value === "") {

            setcomplianceInput((prevState) => { return ({ ...prevState, validationStatus: 'error' }) })

        } else if (selectedCategory.value === "") {

            setselectedCategory((prevState) => { return ({ ...prevState, validationStatus: 'error' }) })

        } else {

            if(isAddtoZohoItem===true){

                if(amountPayable!==""){

                    addToZohoItem("")
                }

               

            }



            if (selectedRadio === "bf1ef6f2-d4d1-4f8c-8558-3bcec89b8efa") {
               
                if (selectedFilingPeriod==="") {

                    Toaster.create({
                        position: Position.TOP,

                    }).show({
                        message: "Select Filing Period",
                        intent: Intent.PRIMARY,
                    });

                    

                }else if(dueDateInput.value === ""){
                   
                    Toaster.create({
                        position: Position.TOP,

                    }).show({
                        message: "Select due date",
                        intent: Intent.PRIMARY,
                    });

                }else{


                    dispatch(postServiceCompliance({
                        userId: userId,
                        userEmail: userEmail,
                        projectId: localStorage.getItem("projectId"),
                        subcategoryName: complianceInput.value,
                        dueDate: dueDateInput.value === "" ? "" : "" + new Date(dueDateInput.value) + "",
                        billingType: selectedBillingType,
                        amountPayable: amountPayable,
                        subtasks: subtaskArray,
                        serviceId: selectedCategory.value,
                        complianceType: selectedRadio,
                        filingPeriod:selectedFilingPeriod,
                        selectedTriggerType:selectedTriggerType
                    })).then((data) => {
        
                        Toaster.create({ position: Position.TOP }).show({
                            message: "Compliance / service successfully created.",
                            intent: Intent.SUCCESS
                        });
        
                        getAllCategories("")
        
                    })
        
                    closeCreateServiceDialog("")


                }


                

            } else {

                dispatch(postServiceCompliance({
                    userId: userId,
                    userEmail: userEmail,
                    projectId: localStorage.getItem("projectId"),
                    subcategoryName: complianceInput.value,
                    dueDate: dueDateInput.value === "" ? "" : "" + new Date(dueDateInput.value) + "",
                    billingType: selectedBillingType,
                    amountPayable: amountPayable,
                    subtasks: subtaskArray,
                    serviceId: selectedCategory.value,
                    complianceType: selectedRadio,
                    filingPeriod:selectedFilingPeriod
                })).then((data) => {
    
                    Toaster.create({ position: Position.TOP }).show({
                        message: "Compliance / service successfully created.",
                        intent: Intent.SUCCESS
                    });
    
                    getAllCategories("")
    
                })
    
                closeCreateServiceDialog("")

                   



            }
              

            

        }


    }


    const postCategroyIndb = (props) => {

        if (categoryNameInput.value === "") {

            setcategoryNameInput((prevState) => {
                const newState = { ...prevState, validationStatus: 'error' }
                return newState
            })

        } else {



            dispatch(postServiceComplianceCategory({
                userId: userId,
                userEmail: userEmail,
                projectId: localStorage.getItem("projectId"),
                categoryName: categoryNameInput.value,
                additionalnfo: additionalInfo.value
            })).then((data) => {

                Toaster.create({ position: Position.TOP }).show({
                    message: "Created successfully.",
                    intent: Intent.SUCCESS
                });

                setcategories(data.payload.data)
            })

            closeCategoryDialog("")


        }


    }

    const closeCategoryDialog = (props) => {

        setshowCreateDialog(false)
        setcategoryNameInput({
            value: '',
            validationStatus: ''
        })
        setadditionalInfo({
            value: ''
        })
    }

    const CreateCategoryDialog = (props) => {

        return (

            <Dialog
                isOpen={showCreateDialog}
                initialFocusRef={initialFocusRef}
                onDismiss={() => closeCategoryDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '800px',
                    height: '508px',

                }}
            >

                <Dialog.Header id="header-id">

                    <Text
                        ref={initialFocusRef}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#24292F'
                        }}
                    >
                        Create category
                    </Text>


                </Dialog.Header>

                <Box sx={{
                    position: 'absolute',
                    left: '27px',
                    right: '27px',
                    top: '88px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>

                    <Text sx={{
                        fontFamily: 'Segoe UI',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '20px',
                        fontWeight: '400',
                        color: '#24292F'
                    }}

                    >
                        Create a category and then list your services and compliances under it to easily manage related tasks.
                    </Text>

                    <TextInput
                        ref={initialInputRef}
                        sx={{
                            position: 'relative',
                            margin: '25px 0px 0px 0px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '400',

                        }}
                        value={categoryNameInput.value}
                        validationStatus={categoryNameInput.validationStatus}
                        onChange={(e) => {
                            setcategoryNameInput((prevState) => { return ({ ...prevState, value: e.target.value, validationStatus: '' }) })
                        }}
                        placeholder="Please enter the name of the service/compliance category"
                    />

                    <Textarea
                        resize="none"
                        sx={{
                            position: 'relative',
                            margin: '17px 0px 0px 0px',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '400',
                            minHeight: '100px',
                            maxHeight: '100px',
                            height: '100px'
                        }}
                        placeholder="Additional information( Optional )"
                        value={additionalInfo.value}
                        onChange={(e) => {
                            setadditionalInfo((prevState) => { return ({ ...prevState, value: e.target.value }) })
                        }}
                    >

                    </Textarea>

                </Box>


                <Box sx={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    borderTop: '1px solid #E1E4E8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    <Button
                        variant="outline"
                        sx={{
                            position: 'relative',
                            margin: '0px 27px 0px 0px',
                            height: '32px',
                            width: '58px',
                            minWidth: '58px',
                            maxWidth: '58px',
                            minHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            background: '#0965CE',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                        }}
                        onClick={() => {
                            postCategroyIndb("")
                        }}
                    >
                        Add
                    </Button>

                </Box>



            </Dialog>
        )
    }

    const closeCreateServiceDialog = (props) => {

        setshowCreateServiceDialog(false)
        setselectedRadio("0")
        setcomplianceInput({
            value: '',
            validationStatus: ''
        })
        setselectedCategory({
            value: '',
            validationStatus: ''
        })
        setdueDateInput({
            value: '',
            validationStatus: ''
        })
        setselectedBillingType('')
        setamountPayable('')
        setsubtaskArray([])
        setisAddtoZohoItem(false)
    }

    const CreateServiceDialog = (props) => {

        return (
            <Dialog
                isOpen={showCreateServiceDialog}
                initialFocusRef={initialFocusRef1}
                onDismiss={() => closeCreateServiceDialog("")}
                aria-labelledby="header-id"
                sx={{
                    width: '800px',
                    height: '508px',
                }}
            >

                <Dialog.Header id="header-id">

                    <Text
                        ref={initialFocusRef1}
                        sx={{
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                            color: '#24292F'
                        }}
                    >
                        Create such a service
                    </Text>


                </Dialog.Header>

                <Box sx={{
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '52px',
                    top: '52px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Box sx={{
                        position: 'relative',
                        margin: '35px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                        
                    }}>

                        {complianceType.map((row,index)=>{
                             return(

                                 <FormControl>
                                     <Radio
                                         value={row.id}
                                         name={row.name}
                                         checked={selectedRadio === row.id ? true : false}
                                         onChange={(e) => { setselectedRadio(e.target.value) }}

                                     />
                                     <FormControl.Label sx={{
                                         fontFamily: 'Segoe UI',
                                         fontStyle: 'normal',
                                         fontWeight: '400',
                                         fontSize: '14px',
                                         lineHeight: '20px',
                                         color: '#24292E',
                                     }}>{row.name}</FormControl.Label>
                                 </FormControl>

                             )
                        })}

                        <Box sx={{
                            position:'absolute',
                            right:'20px',
                            display:selectedRadio==="bf1ef6f2-d4d1-4f8c-8558-3bcec89b8efa"?'flex':'none',
                            flexDirection:'row',
                            gap:'24px'}}>
                            {triggerType.map((row, index) => {
                                return (

                                    <FormControl>
                                        <Radio
                                            value={row.id}
                                            name={row.name}
                                            checked={selectedTriggerType === row.id ? true : false}
                                            onChange={(e) => { setselectedTriggerType(e.target.value) }}

                                        />
                                        <FormControl.Label sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#24292E',
                                        }}>{row.name}</FormControl.Label>
                                    </FormControl>

                                )
                            })}
                        </Box>

                       
                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <TextInput
                            ref={serviceNameInputRef}
                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                minWidth: '488px',
                                maxWidth: '488px',
                                width: '488px',
                                minHeight: '42px',
                                height: '42px',
                                maxHeight: '42px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            value={complianceInput.value}
                            validationStatus={complianceInput.validationStatus}
                            onChange={(e) => {
                                setcomplianceInput((prevState) => { return ({ ...prevState, value: e.target.value, validationStatus: '' }) })
                            }}
                            placeholder="Provided compliance / Services name"
                        />
                        <Box sx={{
                            width: '248px',
                            height: '42px',
                            minWidth: '248px',
                            maxWidth: '248px',
                            minHeight: '42px',
                            maxHeight: '40px',
                        }}>
                            <Select block style={{
                                width: '248px',
                                height: '40px',
                                minWidth: '248px',
                                maxWidth: '248px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                                placeholder="Select category"
                                onChange={(e) => {

                                    if (e.target.value.split("|")[0] === "add") {
                                        setshowCreateDialog(true)
                                        setselectedCategory((prevState) => {
                                            return ({ ...prevState, value: '' })
                                        })
                                    } else {
                                        setselectedCategory((prevState) => {
                                            return ({ ...prevState, value: e.target.value.split("|")[0], validationStatus: '' })
                                        })
                                         

                                    }
                                }}
                                validationStatus={selectedCategory.validationStatus}

                            >

                                {categories.map((row, index) => {
                                    return (
                                        <Select.Option value={row?.category_id + "|" + ''}

                                        >{row?.category_name}</Select.Option>
                                    )
                                })}
                                <Select.Option value="add|''">Add new category</Select.Option>

                            </Select>
                        </Box>
                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <Select block style={{
                            width: '246px',
                            height: '40px',
                            minWidth: '246px',
                            maxWidth: '246px',
                            minHeight: '40px',
                            maxHeight: '40px',
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                        }}
                            placeholder="Select filing period "
                            onChange={(e) => {
                                onChangeFilePeriodSelection(e.target.value)
                            }}
                        >
                            {filingPeriods.map((row, index) => {
                                return (
                                    <Select.Option value={row.id+"|"+row.name}>{row.name}</Select.Option>
                                )
                            })}


                        </Select>

                        <TextInput
                            sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                minWidth: '230px',
                                maxWidth: '230px',
                                width: '230px',
                                minHeight: '42px',
                                height: '42px',
                                maxHeight: '42px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}

                            placeholder="Due date"
                            value={dueDateInput.value !== null && dueDateInput.value !== "" ?
                                new Date(dueDateInput.value).getDate() + "/" + (new Date(dueDateInput.value).getMonth() + 1) + "/" + new Date(dueDateInput.value).getFullYear()
                                : ""}
                            onChange={(e) =>

                                dueDateCalandarRef.current.showPicker()
                            }
                            onFocus={(e) => {
                                dueDateCalandarRef.current.showPicker()
                            }}
                            validationStatus={dueDateInput.validationStatus}
                        />

                        <input
                            type={"date"}
                            ref={dueDateCalandarRef}
                            onChange={(e) => {

                                if (e.target.value === "" || e.target.value == null) {


                                    setdueDateInput((prevState) => {
                                        const newState = { ...prevState, value: '' }
                                        return newState
                                    })


                                } else {

                                    setdueDateInput((prevState) => {
                                        const newState = { ...prevState, value: e.target.value, validationStatus: '' }
                                        return newState
                                    })

                                }


                            }}
                            style={{
                                position: 'absolute',
                                left: '251px',
                                top: '1px',
                                height: '40px',
                                width: '31%',
                                zIndex: '1',
                                visibility: 'hidden'
                            }}
                            min={dateRangeSelection.minDate}
                            max={dateRangeSelection.maxDate}

                        />

                        <Box sx={{
                            width: '247px',
                            height: '40px',
                            minWidth: '247px',
                            maxWidth: '247px',
                            minHeight: '40px',
                            maxHeight: '40px',
                        }}>
                            <Select block style={{
                                width: '230px',
                                height: '40px',
                                minWidth: '230px',
                                maxWidth: '230px',
                                minHeight: '40px',
                                maxHeight: '40px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                                placeholder="Billing Type"
                                onChange={(e) => {
                                    
                                    setselectedBillingType(e.target.value)
                                }}
                            >
                                 {billingTypes.map((row,index)=>{
                                      return(
                                        <Select.Option value={row.id}>{row.name}</Select.Option>
                                      )
                                 })}
                                 
                                
                            </Select>
                        </Box>

                       



                    </Box>

                    <Box sx={{
                        position: 'relative',
                        margin: '13px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',

                    }}>

                        <Box sx={{
                            width: '246px',
                            height: '40px',
                            minWidth: '246px',
                            maxWidth: '246px',
                            minHeight: '40px',
                            maxHeight: '40px',
                        }}>

                            <TextInput
                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px 2px',
                                    minWidth: '242px',
                                    maxWidth: '242px',
                                    width: '242px',
                                    minHeight: '42px',
                                    height: '42px',
                                    maxHeight: '42px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}
                                value={amountPayable}
                                onChange={(e) => {
                                    if (/^-?\d*\.?\d*$/.test(e.target.value)) {
                                        setamountPayable(e.target.value)
                                    }

                                }}
                                placeholder="amount payable (Optional)"
                            />
                            
                        </Box>


                    </Box>

                    <Box sx={{
                        display: isZohoInstalled ? 'flex' : 'none',
                        position: 'relative',
                        margin: '25px 27px 0px 27px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#24292E',
                    }}>
                        <FormControl>
                            <Checkbox value="true" checked={isAddtoZohoItem} onChange={(e) => { setisAddtoZohoItem(e.target.checked) }} />
                            <FormControl.Label sx={{ fontWeight: '400' }}>
                                Add this compliance as a Zoho item.
                            </FormControl.Label>
                        </FormControl>
                    </Box>


                    <Box sx={{
                        position: 'relative',
                        margin: '25px 27px 0px 27px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}>
                            Subtask
                        </Text>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}>
                            : This stage could include the main tasks that need to be completed.
                        </Text>

                    </Box>



                    <Box sx={{
                        position: 'relative',
                        margin: '25px 27px 0px 27px',
                        display: subtaskArray.length > 0 ? 'flex' : 'none',
                        flexDirection: 'column',
                        gap: '15px',

                    }}>
                        {subtaskArray.map((row, index) => {
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minWidth: '522px',
                                    maxWidth: '522px',
                                    width: '522px',
                                    alignItems: 'center',
                                    position: 'relative',
                                    margin: '0px 0px 0px 0px'
                                }}>

                                    <Text sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#24292E',
                                        width: '488px',
                                        maxWidth: '488px',
                                        minWidth: '488px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        position: 'relative',
                                        margin: '0px 0px 0px 0px'
                                    }}>
                                        {row.task_name}
                                    </Text>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            right: '0px',

                                            border: 'none',
                                            boxShadow: 'none',
                                            background: '#ffffff'
                                        }}
                                        icon={() => <XIcon />}
                                        onClick={() => {
                                            setsubtaskArray((prevState) => {
                                                const newState = prevState.filter((sData) => sData.task_id !== row.task_id)
                                                return newState
                                            })
                                        }}
                                    />


                                </Box>
                            )
                        })}
                    </Box>

                    {subtaskInputshown === true ?
                        <Box sx={{
                            position: 'relative',
                            margin: '25px 27px 0px 27px',
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            alignItems: 'center',
                            gap: '1px'
                        }}>

                            <TextInput
                                sx={{
                                    position: 'relative',
                                    margin: '0px 0px 0px -1px',
                                    minWidth: '488px',
                                    maxWidth: '488px',
                                    width: '488px',
                                    minHeight: '42px',
                                    height: '42px',
                                    maxHeight: '42px',
                                    fontFamily: 'Segoe UI',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                }}
                                value={subtaskInput}
                                onChange={(e) => setsubtaskInput(e.target.value)}
                                placeholder="Enter subtask"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setsubtaskArray((prevState) => {
                                            const newState = [...prevState, { task_id: '' + (Math.floor(Math.random() * 1000000) + 1) + '', task_name: subtaskInput }]
                                            setsubtaskInput("")
                                            setsubtaskInputshown(false)
                                            return newState
                                        })
                                    }
                                }}
                            />

                            <IconButton
                                sx={{
                                    border: 'none',
                                    boxShadow: 'none',
                                    background: '#ffffff'
                                }}
                                icon={() => <XIcon />}
                                onClick={() => {
                                    setsubtaskInputshown(false)
                                }}
                            />


                        </Box>
                        : ""}


                    <Button variant="invisible" sx={{
                        position: 'relative',
                        margin: '17px 0px 0px 27px',
                        minHeight: '32px',
                        minWidth: '118px',
                        height: '32px',
                        width: '118px',
                        maxWidth: '118px',
                        maxHeight: '32px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '14px',
                        lineHeight: '20px',
                    }}
                        onClick={() => {
                            setsubtaskInputshown(true)
                        }}
                    >

                        Enter Subtask
                    </Button>


                   



                </Box>


                

                <Box sx={{
                    position: 'absolute',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    minHeight: '52px',
                    maxHeight: '52px',
                    height: '52px',
                    borderTop: '1px solid #E1E4E8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                }}>

                    <Button
                        variant="outline"
                        sx={{
                            position: 'relative',
                            margin: '0px 27px 0px 0px',
                            height: '32px',
                            width: '73px',
                            minWidth: '73px',
                            maxWidth: '73px',
                            minHeight: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#FFFFFF',
                            background: '#0965CE',
                            fontFamily: 'Segoe UI',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                            fontWeight: '600',
                        }}
                        onClick={() => {
                            postServiceIndb()
                        }}
                    >
                        Create
                    </Button>

                </Box>



            </Dialog>

        )
    }




    React.useEffect(() => {

        if (showCreateServiceDialog === true) {


            serviceNameInputRef.current.focus()





        } else {


        }

        getAllCategories("")
        getZohoAuthFromDb("")

    }, [showCreateServiceDialog])


    React.useEffect(() => {

        if (showCreateDialog === true) {

            initialInputRef.current.focus()
        } else {


        }

        getAllCategories("")

    }, [showCreateDialog])

    React.useEffect(()=>{

         if(showComplianceExpansion===true){

            scrollRef.current.scrollIntoView({ behavior: "smooth" });
            
         }

    },[showComplianceExpansion])


    React.useState(() => {

        getAllCategories("")

    })


    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}
          
        >
            <Helmet>
                <title>
                    Rules - deski
                </title>
            </Helmet>
            <SpaceHeader />
            <SpaceLeftSideNav />
            {/* <SpaceRightSideNav/> */}


            {/* <EmptyData
              position="fixed"
              top="158px"
              left="0px"
              right="0px"
             
             /> */}

          


            <Box
               
                position={"absolute"}
                display="flex"
                background={"#F6F8FA"}
                left={"64px"}
                top={"48px"}
                right={"0px"}
                bottom={"0px"}
                overflowY={"scroll"}
               
            >

                <Box
                    ref={scrollRef}
                    sx={{
                        position: 'absolute',
                        top: '1px',
                        right: '1px',
                        height: '10px',
                        width: '10px',
                        zIndex: '100',

                    }}>
                   
                </Box>

                <Box 
               
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '28px',
                    minHeight: '18px',
                    minWidth: '180px',
                    maxWidth: '180px',
                    maxHeight: '18px',
                    height: '18px',
                    width: '180px'
                }}>
                    <Text 
                   
                    sx={{
                        fontFamily: 'Avenir LT Std',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '18px'
                    }}>
                        Compliance and services
                    </Text>
                </Box>


                <Box sx={{

                    position: 'absolute',
                    top: '20px',
                    right: '61px',
                    minHeight: '32px',
                    maxHeight: '32px',
                    height: '32px',
                    display: showComplianceExpansion===true?'none':'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right',
                    gap: '21px',

                }}>

                    <IconButton
                        sx={{
                            border: 'none',
                            display: 'flex',
                            minHeight: '32px',
                            maxHeight: '32px',
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            boxShadow: 'none'
                        }}
                        icon={() => isAscending===true? <SortDescIcon sx={{ display: 'flex', position: 'relative', margin: '9px 0px 0px 0px' }} size={16} />:<SortAscIcon sx={{ display: 'flex', position: 'relative', margin: '9px 0px 0px 0px' }} size={16} />}
                        onClick={()=>handleSortAscendingDecending("")}
                    />


                    <ActionMenu><ActionMenu.Anchor>
                        <Button
                            sx={{
                                width: '58px',
                                minWidth: '58px',
                                maxWidth: '58px',
                                minHeight: '32px',
                                maxHeight: '32px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}

                        >
                            Add
                        </Button>
                    </ActionMenu.Anchor>
                        <ActionMenu.Overlay>
                            <ActionList sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '6px',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#24292F'
                            }}>
                                <ActionList.Item onSelect={() => {
                                    setshowCreateServiceDialog(true)
                                }}>
                                    Service / Compliance
                                </ActionList.Item>
                                <ActionList.Item onSelect={() => {
                                    setshowCreateDialog(true)
                                }}>
                                    Add category
                                </ActionList.Item>
                            </ActionList>
                        </ActionMenu.Overlay>
                    </ActionMenu>




                </Box>

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '62px',
                    height: '1px',
                    background: '#E7E7ED'
                }}>

                </Box>


                {selectedBrudcom.category_name !== null && selectedBrudcom.category_name !== "" ?

                    <Breadcrumbs sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '80px',
                         
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}

                    >
                        <Breadcrumbs.Item onClick={() => {
                            setshowComplianceExpansion(false)
                            setselectedBrudcom({ category_name: '', subcategory_name: '' })
                            setexpansionData(null)
                            setexpansionCatData(null)
                            setserviceId("")
                            setexpansionSubtask([])
                            getAllCategories("")
                        }
                        }>Compliance and services
                        </Breadcrumbs.Item>

                        <Breadcrumbs.Item
                            onClick={() => {
                                setshowComplianceExpansion(false)
                                setselectedBrudcom({ category_name: '', subcategory_name: '' })
                                setexpansionData(null)
                                setexpansionCatData(null)
                                setserviceId("")
                                setexpansionSubtask([])
                                getAllCategories("")
                            }
                            }

                        >{selectedBrudcom.category_name}</Breadcrumbs.Item>
                        <Breadcrumbs.Item selected sx={{fontFamily:'Segoe UI',fontStyle:'normal',fontSize:'14px',fontWeight:'600'}}>
                             
                            {selectedBrudcom.subcategory_name.slice(0,120)+""} 
                             
                        </Breadcrumbs.Item>





                    </Breadcrumbs>

                    :
                    <Breadcrumbs sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '80px',
                        fontFamily: 'Segoe UI',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}

                    >
                        <Breadcrumbs.Item onClick={() => {
                            setshowComplianceExpansion(false)
                            setselectedBrudcom({ category_name: '', subcategory_name: '' })
                            setexpansionData(null)
                            setexpansionCatData(null)
                            setserviceId("")
                            setexpansionSubtask([])
                        }
                        }>Compliance and services
                        </Breadcrumbs.Item>





                    </Breadcrumbs>
                }



                <Box sx={{
                    position: 'absolute',
                    top: '117px',
                    left: '26px',
                    right: '61px',
                    minHeight: 'fit-content',
                    background: 'white',
                    display:showComplianceExpansion===false?'flex':'none',
                    flexDirection: 'column',

                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '35px',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#E0E0E0',
                        alignItems: 'center',

                    }}

                    >


                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '370px',
                            maxWidth: '370px',
                            minWidth: '370px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 15px'
                        }}>
                            Compliance
                        </Text>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '370x',
                            maxWidth: '370px',
                            minWidth: '370px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 20px'
                        }}>
                            Categories
                        </Text>

                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '200px',
                            maxWidth: '200px',
                            minWidth: '200px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 15px',
                            
                        }}>
                            Filing periods
                        </Text>
                        <Text sx={{
                            fontFamily: 'Segoe UI',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#121212',
                            width: '150px',
                            maxWidth: '150px',
                            minWidth: '150px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            position: 'relative',
                            margin: '0px 0px 0px 15px',
                            
                        }}>
                            Automation
                        </Text>

                        

                    </Box>
                 
                    {compliances.map((comp, comindex) => {

                        
                                  return (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        minHeight: '35px',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderColor: '#E0E0E0',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        ':hover': {
                                            background: 'rgba(234, 238, 242, 0.5)'
                                        }
                                    }}
                                        onClick={() => {
                                             setshowComplianceExpansion(true)
                                             setselectedBrudcom({ category_name: categories.filter((cat)=>cat.category_id===comp.subcategory.category_id)[0]?.category_name, subcategory_name: comp.subcategory.compliance_name })
                                             setexpansionData(comp.subcategory)
                                             setexpansionCatData(comp.category)
                                             setserviceId(comp.service_id)
                                             setexpansionSubtask(comp.subtask)
                                             setselectedAssigne(comp.assigne_id)
                                        }}
                                    >


                                        <Text sx={{
                                            fontFamily: 'Segoe UI',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '19px',
                                            color: '#121212',
                                            width: '370x',
                                            maxWidth: '370px',
                                            minWidth: '370px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            position: 'relative',
                                            margin: '0px 0px 0px 15px'
                                        }}>
                                            {comp.subcategory.compliance_name}
                                        </Text>

                                        <Box sx={{
                                            position: 'relative',
                                            margin: '0px 0px 0px 20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            minWidth: '370px',
                                            maxWidth: '370px',
                                            width: '370px',
                                            flexWrap: 'nowrap',
                                            overflow: 'hidden',
                                            
                                        }}>

                                            <Label sx={{
                                                width: 'fit-content',
                                                minWidth: 'fit-content',
                                                maxWidth: 'fit-content',
                                                height: '20px',
                                                borderRadius: '3px',
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '18px',
                                                color: '#24292F',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {categories?.filter((cat)=>cat?.category_id===comp?.subcategory?.category_id)[0]?.category_name}
                                                
                                            </Label>




                                        </Box>

                                          <Text sx={{
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '18px',
                                              color: '#121212',
                                              width: '200px',
                                              maxWidth: '200px',
                                              minWidth: '200px',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              position: 'relative',
                                              margin: '0px 0px 0px 15px'
                                          }}>
                                              {filingPeriods?.find((fData)=> fData?.id===comp?.subcategory?.filingPeriod)?.name}
                                          </Text>

                                          <Text sx={{
                                              fontFamily: 'Segoe UI',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '18px',
                                              color: '#121212',
                                              width: '150px',
                                              maxWidth: '150px',
                                              minWidth: '150px',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              position: 'relative',
                                              margin: '0px 0px 0px 15px',
                                              textTransform:'capitalize'
                                          }}>
                                              {comp?.trigger_type || ""}
                                          </Text>


                                    </Box>
                                )
                            
                       

                    })}



                    <Box sx={{ minHeight: '50x', height: '50px', minWidth: '100%', background: '#F6F8FA' }}>

                    </Box>





                </Box>


                {showComplianceExpansion === true ?
                    <ComplianceExpansion
                     data={expansionData} 
                     catData={expansionCatData} 
                     subtasks={expansionSubtask} 
                     serviceId={serviceId} 
                     categories={categories}
                     complianceType={complianceType}
                     filingPeriods={filingPeriods}
                     billingTypes={billingTypes}
                     compliances={compliances}
                     remindMeType={remindMeType}
                     selectedAssigne={selectedAssigne}/>
                    : ''}
 


            </Box>





            {CreateServiceDialog("")}
            {CreateCategoryDialog("")}

           
            <ScheduleDemoDialog />
            <SearchDialog />
        </Box>
    )

}

export default Rules