import { Box, Button, Breadcrumbs, Text, Heading, StyledOcticon, IconButton, Dialog, TextInput, Link, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import SideNav from "./SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../Theme.module.scss"
import customThemVariable from "../../ThemeVariable.module.scss"
import { ArrowLeftIcon, CheckCircleFillIcon, CheckCircleIcon, CheckIcon, CopyIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getItPortalByItrId } from "../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import TaxHeroHeader from "./TaxHeroHeader"

function ItrExpansion(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")

    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const itrId = localStorage.getItem("itr_id") || ""

    const passwordRef = React.useRef(null)
    const [allDetails, setallDetails] = React.useState([])
    const [itrDetails, setitrDetails] = React.useState([])
    const [fileditrs, setfileditrs] = React.useState([])
    const [timeLineArray, settimeLineArray] = React.useState([])
    const [itrCredentials,setitrCredentials] = React.useState({
          username:'',
          password:'',
          ackNumber:''
    })
    const [pdfPassword,setpdfPassword] = React.useState("")
    const [itrFiles,setitrFiles] = React.useState([])

    const handledCopyPassword = (props) => {

        if (passwordRef.current) {

            navigator.clipboard.writeText(passwordRef.current.innerText)
                .then(() => {

                    Toaster.create({ position: Position.TOP }).show({
                        message: "Copied",
                        intent: Intent.SUCCESS
                    });

                }).catch((error) => {

                });

        }
    }


    const getItPortalByItrIdFromDb = (props) => {

        dispatch(getItPortalByItrId({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            itrId: itrId
        })).then((data) => {

            console.log("getItPortalByItrId", data.payload)
            setallDetails(data?.payload?.data)
            setitrDetails(data?.payload?.itrDetails)
            setfileditrs(data?.payload?.fileditrs)
            settimeLineArray(data?.payload?.fileditrs[0]?.itr_status)
            
            const ackNumber = data?.payload?.fileditrs[0]?.acknowledgement_no || ""  
            const fileArray = data?.payload?.data[0]?.itr_files || []
            setitrFiles((prevState)=>{
                  
                const newState = [...fileArray].filter((row,index)=> row.acknowledgementNo === ackNumber )
                return newState
            })


            const rawDate = new Date(data?.payload?.data[0]?.itr_profile?.data?.data?.profile_details?.pan?.dob?.date);
            let day = rawDate.getDate();
            day = (day < 10 ? '0' : '') + day;  // Add leading zero if day is less than 10
            let month = rawDate.getMonth() + 1;
            month = (month < 10 ? '0' : '') + month;  // Add leading zero if month is less than 10
            const year = rawDate.getFullYear();
            const dateOfBirth = `${day}${month}${year}`;
            setpdfPassword(data?.payload?.data[0]?.pan_number.toLowerCase()+""+dateOfBirth)
            // setitrCredentials((prevState)=>{
            //      return({...prevState,
            //         username:data?.payload?.data[0]?.pan_number,
            //         password:data?.payload?.data[0]?.pan_password,
            //         ackNumber:data?.payload?.fileditrs[0]?.acknowledgement_no
            //     })
            // })
        })

    }

    const handleDownloaditrReceipt =async (props) => {

          // alert(JSON.stringify(itrCredentials))
          // const url = `${process.env.REACT_APP_SCRAPSERVERURL}/syncit?username=${username}&password=${password}`
          const url = `http://13.201.117.159/downloaditrreceipt?username=${itrCredentials.username}&password=${itrCredentials.password}&ackNumber=${itrCredentials.ackNumber}`
          //  const url = `http://127.0.0.1/downloaditrreceipt?username=${itrCredentials.username}&password=${itrCredentials.password}&ackNumber=${itrCredentials.ackNumber}`
          const response = await fetch(url);
          const responseData = await response.json();
          console.log("handleDownloaditrReceipt",responseData)
          window.open(responseData?.fileUrl,'_blank') 
        
          
    }

    const handleDownloadItrForm = async (props) => {

        const url = `http://13.201.117.159/downloaditrform?username=${itrCredentials.username}&password=${itrCredentials.password}&ackNumber=${itrCredentials.ackNumber}`
        const response = await fetch(url);
        const responseData = await response.json();
        console.log("handleDownloaditrform",responseData)
         window.open(responseData?.fileUrl,'_blank') 

    }

    const handleDownloadIntimationOrder = async (props) => {

         //downloaditrintimationorder
         const url = `http://13.201.117.159/downloaditrintimationorder?username=${itrCredentials.username}&password=${itrCredentials.password}&ackNumber=${itrCredentials.ackNumber}`
         const response = await fetch(url);
         const responseData = await response.json();
         console.log("handleDownloadIntimationOrder",responseData)
         window.open(responseData?.fileUrl,'_blank') 

    }



    React.useState(() => {


        getItPortalByItrIdFromDb("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" ? 'ITR expansion - taxhero' : 'ITR expansion - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >

                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >

                            {itrDetails[0]?.data?.name}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >

                            {fileditrs[0]?.pan_no}
                        </Text>


                        <Label sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: customThemVariable.labelTextColorsecondary,
                            borderRadius: '4px',
                            minHeight: '18px',
                            maxHeight: '18px',
                            height: '18px',
                            background: customThemVariable.labelBgSecondary,
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <Text sx={{ display: 'flex' }}>
                                Verified
                            </Text>
                            <Box
                                sx={{
                                    display: 'flex',
                                    color: customThemVariable.labelTertiaryTextColor,
                                    marginLeft: '4px'
                                }}>
                                <CheckIcon size={12} />
                            </Box>



                        </Label>

                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            navigate('/ItReturn')
                        }}
                    >
                        Return


                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>


                {/* -------------------- */}


                {/* ITR Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement no
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {fileditrs[0]?.acknowledgement_no}
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Assessment year
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            AY {fileditrs[0]?.filing_year?.split("-")[0]?.substring(2, 4)}-{fileditrs[0]?.filing_year?.split("-")[1]?.substring(2, 4)}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Acknowledgement date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {new Date(fileditrs[0]?.filing_date).toDateString().substring(3, 30)}

                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Form type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {fileditrs[0]?.itr_form}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            Filing type
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                            }}
                        >
                            {fileditrs[0]?.filing_type}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Filing section
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {fileditrs[0]?.filing_section}
                        </Text>
                    </Box>




                </Box>
                {/* ---------------------- */}


                {/* ITR Details Section 1 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '182px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund claimed
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {itrDetails[0]?.data?.itr_type === "itr_3" ?
                                itrDetails[0]?.data?.raw_itr?.ITR.ITR3?.PartB_TTI?.Refund?.RefundDue
                                :
                                itrDetails[0]?.data?.itr_type === "itr_1" ?
                                    itrDetails[0]?.data?.raw_itr?.ITR?.ITR1?.Refund?.RefundDue
                                    ||
                                    itrDetails[0]?.data?.raw_itr['ns3:ITR']?.['ns2:ITR1']?.['Refund']?.['RefundDue']
                                    ||
                                    ""
                                    :
                                    itrDetails[0]?.data?.itr_type === "itr_2" ?
                                        itrDetails[0]?.data?.raw_itr['ITRETURN:ITR']?.['ITR2FORM:ITR2']?.['ITRForm:PartB_TTI']?.['ITRForm:Refund']?.['ITRForm:RefundDue']
                                        :
                                        ""
                            }

                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Computed refunds
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {/*  need to fetch from api */}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '158px',
                        maxWidth: '158px',
                        width: '158px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Paid refund
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {/* need to fetch from api */}
                        </Text>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '144px',
                        maxWidth: '144px',
                        width: '144px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Refund  date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {
                                new Date(timeLineArray[0]?.date).toDateString().substring(3, 30)
                            }


                        </Text>
                    </Box>



                </Box>

                {/* ----------------------- */}

                {/* Timeline section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '276px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',

                }}>
                    <Text
                        className={customTheme.H3}
                        sx={{ color: customThemVariable.primaryTextColor }}
                    >
                        Timeline
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                    }}>

                        {timeLineArray.map((row, index) => {
                            return (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '60px',
                                    height: '60px',
                                    maxHeight: '60px',
                                    borderBottom: `1px solid ${customThemVariable.borderColor}`
                                }}>

                                    <Box sx={{
                                        marginTop: '11px'

                                    }}>
                                        <StyledOcticon size={12} color="#1EA672" icon={CheckCircleFillIcon} />
                                    </Box>

                                    <Box sx={{
                                        marginTop: '11px',
                                        marginLeft: '16px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                color: customThemVariable.primaryTextColor
                                            }}
                                        >{row.status}</Text>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                fontSize: '12px',
                                                color: customThemVariable.secondaryTextColor
                                            }}
                                        >{new Date(row.date).toDateString()}</Text>
                                    </Box>

                                </Box>
                            )
                        })}

                    </Box>
                    {/* ITR Files Section -------------- */}
                    <Text
                        className={customTheme.H3}
                        sx={{
                            color: customThemVariable.primaryTextColor,
                            marginTop: '35px'
                        }}
                    >
                        Files
                    </Text>

                    <Box sx={{
                        marginTop: '7px',
                        width: '964px',
                        minWidth: '964px',
                        maxWidth: '964px',
                        height: '1px',
                        background: customThemVariable.borderColor
                    }}>
                    </Box>

                    <Box sx={{
                        marginTop: '13px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', minHeight: '20px', maxHeight: '20px', height: '20px', alignItems: 'center' }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                PDF Password
                            </Text>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                                ref={passwordRef}
                            >
                                {/* 4M6QOpkoYyQWcRFjGI */}
                                {pdfPassword}
                            </Text>

                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <CopyIcon size={15} />}

                                onClick={() => {
                                    handledCopyPassword()
                                }}

                            >

                            </IconButton>



                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', minHeight: '20px', maxHeight: '20px', height: '20px', alignItems: 'center' }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                {/* 01 Jan 2023 */}
                                {itrFiles.length>0 ? itrFiles[0]?.orderDate  :""}
                            </Text>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor }}
                            >
                                {/* CPC/2223/A3/322053893 */}
                                {itrFiles.length>0 ? itrFiles[0]?.orderUrlFile  :""}
                            </Text>

                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                onClick={()=>{ 
                                    
                                    
                                  //  handleDownloadIntimationOrder("")
                                      window.open(itrFiles.length>0 ? itrFiles[0]?.orderUrl:'','_blank') 

                                }}
                                /></Box>}
                            >

                            </IconButton>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', minHeight: '20px', maxHeight: '20px', height: '20px', alignItems: 'center' }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                ITR Form
                            </Text>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                   onClick={()=> 
                                    
                                     //handleDownloadItrForm("")
                                       window.open(itrFiles.length>0 ? itrFiles[0]?.downloadFormUrl:'','_blank')  

                                   }
                                /></Box>}
                            >

                            </IconButton>

                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                minHeight: '20px',
                                maxHeight: '20px',
                                height: '20px',
                                alignItems: 'center'
                            }}>

                            <Text
                                className={customTheme.textnormal}
                                sx={{ color: customThemVariable.primaryTextColor, minWidth: '98px' }}>
                                ITR Receipt
                            </Text>
                            <IconButton
                                sx={{
                                    border: 'none',
                                    color: '#000000',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '-8px'
                                }}
                                icon={() => <Box sx={{ display: 'flex' }}><ExternalLinkIcon height={17} width={17}
                                onClick={()=> 
                                    
                                  //  handleDownloaditrReceipt("")
                                    window.open(itrFiles.length>0 ? itrFiles[0]?.downloadReceiptUrl:'','_blank') 

                                }
                                /></Box>}
                            >

                            </IconButton>

                        </Box>


                    </Box>

                    {/* ----------------- -------------- */}

                    <Box
                        sx={{
                            minHeight: '100px'
                        }}
                    >

                    </Box>




                </Box>


                {/* --------------------- */}







            </Box>



        </Box>


    )


}


export default ItrExpansion