import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const sharingOpenClose = createAsyncThunk("sharingOpenClose", async (data) => {


    return ({
        openClose: data.openClose,
        shareType: data.shareType,
        workplaceId: data.workplaceId,
        projectId: data.projectId,
        title: data.title
    })

})


export const flashMessageShowHide = createAsyncThunk("flashMessageShowHide", async (data) => {


    return ({
        flashMessageShowHide: data.flashMessageShowHide,
        flashMessageResponse: data.flashMessageResponse
    })

})


export const shareWorkplaceProject = createAsyncThunk("shareWorkplaceProject", async (data) => {



    var myHeaders = new Headers();
    myHeaders.append('Content-type', 'application/json')


    const urlencoded = JSON.stringify({
        "userId": data.userId,
        "userEmail": data.userEmail,
        "inviteEmail": data.inviteEmail,
        "workplaceId": data.workplaceId,
        "shareType": data.shareType,
        "projectId": data.projectId,
        "inviteId": data.inviteId,
        "shareRole": data.shareRole
    })



    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const result = await fetch(`${process.env.REACT_APP_API_URL}/shareWorkplaceProjects`, requestOptions)
        .then(response => response.json())

    return result


})



export const sharingOpenCloseSlice = createSlice({

    name: 'sharingOpenClose',
    initialState: {
        openClose: false,
        loading: false,
        shareType: "",
        workplaceId: "",
        projectId: "",
        title: "",
        flashMessageShowHide: false,
        flashMessageResponse: "",
        reloadTeamTrigger: false
    },
    extraReducers: {

        [sharingOpenClose.pending]: (state, action) => {
            state.loading = true;
            state.openClose = false

        },
        [sharingOpenClose.fulfilled]: (state, action) => {
            state.loading = action.payload.loading;
            state.openClose = action.payload.openClose
            state.shareType = action.payload.shareType
            state.workplaceId = action.payload.workplaceId
            state.projectId = action.payload.projectId
            state.title = action.payload.title

        },
        [sharingOpenClose.rejected]: (state, action) => {
            state.loading = false
            state.openClose = false
        },
        [flashMessageShowHide.pending]: (state, action) => {
            state.loading = true;
            state.flashMessageShowHide = false

        },
        [flashMessageShowHide.fulfilled]: (state, action) => {

            state.flashMessageShowHide = action.payload.flashMessageShowHide
            state.flashMessageResponse = action.payload.flashMessageResponse

        },
        [flashMessageShowHide.rejected]: (state, action) => {
            state.loading = false
            state.flashMessageShowHide = false
        },
        [shareWorkplaceProject.pending]: (state, action) => {

            state.reloadTeamTrigger = false

        },
        [shareWorkplaceProject.fulfilled]: (state, action) => {

            state.reloadTeamTrigger = true

        },
        [shareWorkplaceProject.rejected]: (state, action) => {

            state.reloadTeamTrigger = false

        }
    }


})


export default sharingOpenCloseSlice.reducer

